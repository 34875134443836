<div class="modal-header row" style="margin-right: 95px;">
  <div class="col-1">
    <button *ngIf="step.numero !== '1'" class="btn" (click)="cambiarStep()">
      <fa-icon [icon]='["fas", "chevron-left"]' class="text-secondary"></fa-icon>
    </button>
  </div>
  <div class="col-10">
    <h5 class="modal-title" style="display: flex; justify-content: center;">Seleccione un medicamento</h5>
  </div>
</div>
<div class="modal-body" style="overflow-y: auto !important; max-height: calc(100vh - 200px);">
  <div class="" style="display: flex; justify-content: center; flex-direction: row;">
    <!-- BUSQUEDA POR MONODROGAS -->
    <div class="buscarMedicamentos">
      <input class="form-control inputSearch" #monodrogaSearchInput type="search" name="search" [(ngModel)]="searchText"
        autocomplete="off" [placeholder]="'Buscar por monodroga'" />
      <div class="input-group-append">
        <span class="input-group-text">
          <fa-icon [icon]='["fas", "search"]' class="text-secondary"></fa-icon>
        </span>
      </div>
    </div>
    <!-- BUSQUEDA POR MEDICAMENTOS -->
    <div class="buscarMedicamentos">
      <input class="form-control inputSearch" #medicamentoSearchInput type="search" name="searchMedicamento"
        [(ngModel)]="searchTextMedicamentos" autocomplete="off" [placeholder]="'Buscar por medicamento'" />
      <div class="input-group-append">
        <span class="input-group-text">
          <fa-icon [icon]='["fas", "search"]' class="text-secondary"></fa-icon>
        </span>
      </div>
    </div>
  </div>
  <div class="container mt-2 mb-2">
    <div class="buttonContainer">
      <button class="btn" [ngClass]="{'btn-primary': comercialClicked, 'btn-secondary': !comercialClicked}"
        (click)="prescribirComercial()">Comercial</button>
      <button class="btn" [ngClass]="{'btn-primary': genericoClicked, 'btn-secondary': !genericoClicked}"
        (click)="prescribirGenerico()">Genérico</button>
    </div>
  </div>

  <div class="container">
    <div class="list-group list-group-flush" *ngIf="step.numero === '1'">
      <div *ngIf="arrayMonodrogas.length == 0">
        <fa-icon [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
        Obteniendo Monodrogas...
      </div>
      <a href="#" class="list-group-item list-group-item-action" (click)="siguiente('2',monodroga)"
        *ngFor="let monodroga of arrayMonodrogas">
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1">{{monodroga.descripcion | uppercase}}</p>
          <fa-icon [icon]='["fas", "chevron-right"]' class="text-secondary"></fa-icon>
        </div>
      </a>
    </div>
    <div class="list-group list-group-flush" *ngIf="step.numero === '2'">
      <div *ngIf="isSearching">
        <fa-icon [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
        Obteniendo Medicamentos...
      </div>
      <ng-container *ngIf="generico; else comercial">
        <a href="#" class="list-group-item list-group-item-action" *ngFor="let monodrogaDet of arrayMonodrogasDetalles"
          (click)="agregarMonodroga(monodrogaDet)">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-1">{{monodrogaDet.descripcion | uppercase}}</p>
            <fa-icon [icon]='["fas", "chevron-right"]' class="text-secondary"></fa-icon>
          </div>
          <p class="mb-0 pb-0 text-secondary">{{monodrogaDet.recetas}}</p>
        </a>
      </ng-container>
      <ng-template #comercial>
        <a href="#" class="list-group-item list-group-item-action" (click)="siguiente('3',monodrogaDet)"
          *ngFor="let monodrogaDet of arrayMonodrogasDetalles">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-1">{{monodrogaDet.descripcion | uppercase}}</p>
            <fa-icon [icon]='["fas", "chevron-right"]' class="text-secondary"></fa-icon>
          </div>
          <p class="mb-0 pb-0 text-secondary">{{monodrogaDet.recetas}}</p>
        </a>
      </ng-template>
    </div>
    <div class="list-group list-group-flush" *ngIf="step.numero !== '1' && step.numero !== '2'">
      <div *ngIf="isSearching">
        <fa-icon [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
        Presentaciones comerciales...
      </div>
      <div *ngFor="let presentacionDet of arrayPresentacionesDetalles">
        <a href="#" class="list-group-item list-group-item-action  mb-1 pb-0 pt-0 mt-1 border-0"
          (click)="setMonodrogaEspecifica(presentacionDet)">
          <span class="ion-text-left btnMonodrogaFiltro"> <fa-icon [icon]='["fas", "chevron-left"]'
              class="btnMonodrogaFiltro mr-2"></fa-icon><strong>{{presentacionDet.monodroga |
              uppercase}}</strong></span>
        </a>
        <a href="#" class="list-group-item list-group-item-action mt-0 pt-0"
          (click)="agregarMedicamento(presentacionDet)"
          style="border-left-style: none; border-right-style: none; border-bottom-style: 1px solid rgb(119, 119, 119);">
          <p class="mb-0 pb-0">{{presentacionDet.laboratorio}}</p>
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-0 pb-0">{{presentacionDet.descripcion}}</p>
            <!-- SE OCULTÓ EL PRECIO PORQUE NO VA A VENIR MÁS -->
            <span class="ion-text-left"> <fa-icon [icon]='["fas", "chevron-right"]'
                class="text-secondary ml-4"></fa-icon></span>
            <!-- <span class="ion-text-left">${{presentacionDet.precio}} <fa-icon [icon]='["fas", "chevron-right"]'
                  class="text-secondary ml-4"></fa-icon></span> -->
          </div>
          <p class="mb-0 pb-0 text-secondary">{{presentacionDet.presentacion}}</p>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="buttonAnularTicket" (click)="closeModal()">Cerrar</button>
</div>
