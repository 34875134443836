<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
</head>
<ng-template #prestaciones let-r="result" let-t="term">
    <ngb-highlight [result]="r.descripcion" [term]="t"></ngb-highlight>
</ng-template>



    <div class="form-infoN prestacion__cPrestacion">
        <input id="cPrestacion" #buscarPresta type="text" id="cPrestacion" class="form-controlN form-controlN-dashboardP" [ngbTypeahead]="search"  [resultTemplate]="prestaciones" [inputFormatter]="formatter" [(ngModel)]="model" (ngModelChange)="submit(null)" />
        <!-- <div class="input-group-append">
            <span class="input-group-text">
        <fa-icon [icon]='["fas", "search"]' class="text-secondary"></fa-icon>
        </span>
        </div> -->
    <!-- </div> -->
    </div>


</html>
