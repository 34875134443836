import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
//import * as $ from 'jquery';
declare var $:any;

@Component({
  selector: 'app-desplegable',
  templateUrl: './desplegable.component.html',
  styleUrls: ['./desplegable.component.scss'],
})
export class DesplegableComponent implements OnInit, AfterViewInit {
  @Input() componentePasado: TemplateRef<any> | null = null;
  @Input() titulo: string = '';
  @Input() identificador: string = 'inicio';
  cambios = 0; //Se usa para cambiar el show una sola vez
  show: boolean = true;
  @Input() required: boolean = false;
  @Input() set showEvent(value: boolean) {
    this.show = value;
    this.toogleBar();
  }

  private subscriptionStore: Subscription = new Subscription();
  siglaFinanciador?: string = '';
  numCredencial?: string = '';
  prestador?: string = '';
  transaccion?: string = '';

  constructor( private store: Store<AppState> ) {}

  ngOnInit(): void {
    this.guardarDatos();
  }

  guardarDatos(){
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.siglaFinanciador = datos.datosInicio.FinanciadorRequest?.SiglaFinanciador;
      this.numCredencial = datos.datosInicio.CredencialRequest?.NumeroCredencial;
      this.prestador = datos.datosInicio.PrestadorRequest?.RazonSocial;
      this.transaccion = datos.datosInicio.TransaccionRequest?.NombreTransaccion;
    });
  }

  ngOnDestroy(){
    this.subscriptionStore.unsubscribe();
  }

  ngAfterViewInit() {
    this.toogleBar();
  }


  toogleBar() {
    let a = $(`#${this.identificador}`);
    if (a.length == 1 && this.cambios < 3) {
      // this.cambios++;
      (<any>a).collapse('toggle');
    }
  }

  showBar() {
    let a = $(`#${this.identificador}`);
    (<any>a).collapse('show');
    this.cambios = 0;
  }
}
