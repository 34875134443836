<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../../sass/estilos.scss"> -->
</head>

<div class="container__panelPrestacion">
<div class="container-CSC">
    <p class="title__CSC">CSC <span class="span-required">*</span></p>
        <form [formGroup]="form" action="aplicaciones" class="form__CSC">
            <div class="form-infoN">
                <label for="nCSC">Número</label>
                <input maxlength="3" id="nCSC" type="text" class="form-controlN" name="codigoDeSeguridad" formControlName="codigoDeSeguridad"
                    autocomplete=off placeholder="Código de seguridad">

                <small class="text-danger animated fadeIn"
                    *ngIf="form.controls['codigoDeSeguridad'].touched && form.controls['codigoDeSeguridad'].errors">El
                    código de seguridad no es válido</small>
            </div>
        </form>
    </div>
</div>

</html>
