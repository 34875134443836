import * as datosTransaccion from '@models/datosTransaccion.models';
import { Action } from '@ngrx/store';

export const SETEXTRADISPENSAREQUEST =
  '[datosTransacciones] Set EXTRADISPENSAREQUEST';
export const UNSETEXTRADISPENSAREQUEST =
  '[datosTransacciones] Unset EXTRADISPENSAREQUEST';

export const SETDETALLEMEDICAMENTOREQUEST =
  '[datosTransacciones] Set DETALLEMEDICAMENTOREQUEST';
export const UNSETDETALLEMEDICAMENTOREQUEST =
  '[datosTransacciones] Unset DETALLEMEDICAMENTOREQUEST';

export const SETDETALLESESTUDIOSREQUEST =
  '[datosTransacciones] Set DETALLESESTUDIOSREQUEST';
export const UNSETDETALLESESTUDIOSREQUEST =
  '[datosTransacciones] Unset DETALLESESTUDIOSREQUEST';

export const SETDETALLEPROCEDIMIENTOSREQUEST =
  '[datosTransacciones] Set DETALLEPROCEDIMIENTOSREQUEST';
export const UNSETDETALLEPROCEDIMIENTOSREQUEST =
  '[datosTransacciones] Unset DETALLEPROCEDIMIENTOSREQUEST';

export const SETACOMPANNANTEREQUEST =
  '[datosTransacciones] Set ACOMPANNANTEREQUEST';
export const UNSETACOMPANNANTEREQUEST =
  '[datosTransacciones] Unset ACOMPANNANTEREQUEST';

export const SETANULACIONREQUEST =
  '[datosTransacciones] Set ANULACIONREQUEST';
export const UNSETANULACIONREQUEST =
  '[datosTransacciones] Unset ANULACIONREQUEST';

export const SETREFERENCIAREQUEST =
  '[datosTransacciones] Set ANULACIONREQUEST';
export const UNSETREFERENCIAREQUEST =
  '[datosTransacciones] Unset ANULACIONREQUEST';

export const SETATENCIONREQUEST = '[datosTransacciones] Set ATENCIONREQUEST';
export const UNSETATENCIONREQUEST =
  '[datosTransacciones] Unset ATENCIONREQUEST';

export const SETBENEFICIARIOREQUEST =
  '[datosTransacciones] Set BENEFICIARIOREQUEST';
export const UNSETBENEFICIARIOREQUEST =
  '[datosTransacciones] Unset BENEFICIARIOREQUEST';

export const SETCODFINALIZACIONTRATAMIENTO =
  '[datosTransacciones] Set CODFINALIZACIONTRATAMIENTO';

export const SETDERIVACIONREQUEST =
  '[datosTransacciones] Set DERIVACIONREQUEST';
export const UNSETDERIVACIONREQUEST =
  '[datosTransacciones] Unset DERIVACIONREQUEST';

export const SETDIAGNOSTICOSREQUEST =
  '[datosTransacciones] Set DIAGNOSTICOSREQUEST';
export const UNSETDIAGNOSTICOSREQUEST =
  '[datosTransacciones] Unset DIAGNOSTICOSREQUEST';

export const SETDOCUMENTACIONREQUEST =
  '[datosTransacciones] Set DOCUMENTACIONREQUEST';
export const UNSETDOCUMENTACIONREQUEST =
  '[datosTransacciones] Unset DOCUMENTACIONREQUEST';

export const SETEFECTORREQUEST = '[datosTransacciones] Set EFECTORREQUEST';
export const UNSETEFECTORREQUEST = '[datosTransacciones] Unset EFECTORREQUEST';

export const SETMENSAJEPARAFINANCIADOR =
  '[datosTransacciones] Set MENSAJEPARAFINANCIADOR';

export const SETPREAUTORIZACIONREQUEST =
  '[datosTransacciones] Set PREAUTORIZACIONREQUEST';
export const UNSETPREAUTORIZACIONREQUEST =
  '[datosTransacciones] Unset PREAUTORIZACIONREQUEST';

export const SETPRESCRIPCIONREQUEST =
  '[datosTransacciones] Set PRESCRIPCIONREQUEST';
export const UNSETPRESCRIPCIONREQUEST =
  '[datosTransacciones] Unset PRESCRIPCIONREQUEST';

export const SETPRESCRIPTORREQUEST =
  '[datosTransacciones] Set PRESCRIPTORREQUEST';
export const UNSETPRESCRIPTORREQUEST =
  '[datosTransacciones] Unset PRESCRIPTORREQUEST';

export const SETFECHAREFERENCIACANCELREQUEST =
  '[datosTransacciones] Set FECHAREFERENCIACANCELREQUEST';
export const UNSETFECHAREFERENCIACANCELREQUEST =
  '[datosTransacciones] Unset FECHAREFERENCIACANCELREQUEST';

export const SETIDMSJREQUEST = '[datosTransacciones] Set IDMSJREQUEST';
export const UNSETIDMSJREQUEST = '[datosTransacciones] Unset IDMSJREQUEST';

export const SETINICIOTRXREQUEST = '[datosTransacciones] Set INICIOTRXREQUEST';
export const UNSETINICIOTRXREQUEST =
  '[datosTransacciones] Unset INICIOTRXREQUEST';

export const SETNROREFERENCIACANCELREQUEST =
  '[datosTransacciones] Set NROREFERENCIACANCELREQUEST';
export const UNSETNROREFERENCIACANCELREQUEST =
  '[datosTransacciones] Unset NROREFERENCIACANCELREQUEST';

export const SETNROREFERENCIAREQUEST =
  '[datosTransacciones] Set SETANROREFERENCIAREQUEST';
export const UNSETNROREFERENCIAREQUEST =
  '[datosTransacciones] Unset SETANROREFERENCIAREQUEST';

export const SETSOFTWAREREQUEST = '[datosTransacciones] Set SOFTWAREREQUEST';
export const UNSETSOFTWAREREQUEST =
  '[datosTransacciones] Unset SOFTWAREREQUEST';

export const SETTERMINALREQUEST = '[datosTransacciones] Set TERMINALREQUEST';
export const UNSETTERMINALREQUEST =
  '[datosTransacciones] Unset TERMINALREQUEST';

export const SETTIPOTRANSACCIONREQUEST =
  '[datosTransacciones] Set TIPOTRANSACCIONREQUEST';
export const UNSETTIPOTRANSACCIONREQUEST =
  '[datosTransacciones] Unset TIPOTRANSACCIONREQUEST';

export const SETVERSIONMSJREQUEST =
  '[datosTransacciones] Set VERSIONMSJREQUEST';
export const UNSETVERSIONMSJREQUEST =
  '[datosTransacciones] Unset VERSIONMSJREQUEST';

export const UNSETDATOSCASITODOS =
  '[datosTransacciones] unset casi todos los datos'; //Casi todos menos prescriptor,efector,detalleprocedimineto

export const UNSETDATOSTRANSACCIONES =
  '[datosTransacciones] unset datos transacciones';

export const SETINFORMARDIAGNOSTICOREQUEST =
  '[datosTransacciones] Set SETINFORMARDIAGNOSTICOREQUEST';

export const UNSETINFORMARDIAGNOSTICOREQUEST =
  '[datosTransacciones] Set UNSETINFORMARDIAGNOSTICOREQUEST';

export class setExtraDispensaRequest implements Action {
  readonly type = SETEXTRADISPENSAREQUEST;
  constructor(
    public extraDispensaRequest: datosTransaccion.ExtrasDispensa
  ) { }
}

export class unsetExtraDispensaRequest implements Action {
  readonly type = UNSETEXTRADISPENSAREQUEST;
}

export class setDetallesMedicamentosRequest implements Action {
  readonly type = SETDETALLEMEDICAMENTOREQUEST;
  constructor(
    public DetalleMedicamentosRequest: Array<datosTransaccion.DetalleMedicamentosRequestModel>
  ) { }
}

export class unsetDetallesMedicamentosRequest implements Action {
  readonly type = UNSETDETALLEMEDICAMENTOREQUEST;
}

export class setDetallesProcedimientosRequest implements Action {
  readonly type = SETDETALLEPROCEDIMIENTOSREQUEST;
  constructor(
    public DetalleProcedimientoRequest: Array<datosTransaccion.DetalleProcedimientoRequestModel>
  ) { }
}

export class unsetDetallesProcedimientosRequest implements Action {
  readonly type = UNSETDETALLEMEDICAMENTOREQUEST;
}

export class setDetallesEstudiosRequest implements Action {
  readonly type = SETDETALLESESTUDIOSREQUEST;
  constructor(
    public DetalleEstudiosRequest: Array<datosTransaccion.DetalleEstudiosRequestModel>
  ) { }
}

export class unsetDetallesEstudiosRequest implements Action {
  readonly type = UNSETDETALLESESTUDIOSREQUEST;
}

export class setAcompannanteRequest implements Action {
  readonly type = SETACOMPANNANTEREQUEST;
  constructor(
    public AcompannanteRequest: datosTransaccion.AcompannanteRequestModel
  ) { }
}

export class unsetAcompannanteRequest implements Action {
  readonly type = UNSETACOMPANNANTEREQUEST;
}

export class setAnulacionRequest implements Action {
  readonly type = SETANULACIONREQUEST;
  constructor(
    public AnulacionRequest: datosTransaccion.AnulacionRequestModel
  ) { }
}

export class unsetAnulacionRequest implements Action {
  readonly type = UNSETANULACIONREQUEST;
}

export class setReferenciaRequest implements Action {
  readonly type = SETREFERENCIAREQUEST;
  constructor(
    public AnulacionRequest: datosTransaccion.NroReferenciaRequestModel
  ) { }
}

export class unsetReferenciaRequest implements Action {
  readonly type = UNSETREFERENCIAREQUEST;
}

export class setAtencionRequest implements Action {
  readonly type = SETATENCIONREQUEST;
  constructor(public AtencionRequest: datosTransaccion.AtencionRequestModel) { }
}

export class unsetAtencionRequest implements Action {
  readonly type = UNSETATENCIONREQUEST;
}

export class setBeneficiarioRequest implements Action {
  readonly type = SETBENEFICIARIOREQUEST;
  constructor(
    public BeneficiarioRequest: datosTransaccion.BeneficiarioRequestModel
  ) { }
}

export class unsetBeneficiarioRequest implements Action {
  readonly type = UNSETBENEFICIARIOREQUEST;
}

export class setCodFinalizacionTratamiento implements Action {
  readonly type = SETCODFINALIZACIONTRATAMIENTO;
  constructor(public CodFinalizacionTratamientoRequest: string) { }
}

export class setDerivacionRequest implements Action {
  readonly type = SETDERIVACIONREQUEST;
  constructor(
    public DerivacionRequest: datosTransaccion.DerivacionRequestModel
  ) { }
}

export class unsetDerivacionRequest implements Action {
  readonly type = UNSETDERIVACIONREQUEST;
}

export class setDiagnosticosRequest implements Action {
  readonly type = SETDIAGNOSTICOSREQUEST;
  constructor(
    public DiagnosticosRequest: Array<datosTransaccion.DiagnosticosRequestModel>
  ) { }
}

export class unsetDiagnosticosRequest implements Action {
  readonly type = UNSETDIAGNOSTICOSREQUEST;
}

export class setDocumentacionRequest implements Action {
  readonly type = SETDOCUMENTACIONREQUEST;
  constructor(
    public DocumentacionRequest: datosTransaccion.DocumentacionRequestModel
  ) { }
}

export class unsetDocumentacionRequest implements Action {
  readonly type = UNSETDOCUMENTACIONREQUEST;
}

export class setEfectorRequest implements Action {
  readonly type = SETEFECTORREQUEST;
  constructor(public EfectorRequest: datosTransaccion.EfectorRequestModel) { }
}

export class unsetEfectorRequest implements Action {
  readonly type = UNSETEFECTORREQUEST;
}

export class setMensajeParaFinanciador implements Action {
  readonly type = SETMENSAJEPARAFINANCIADOR;
  constructor(public MensajeParaFinanciadorRequest: string) { }
}

export class setPreautorizacionRequest implements Action {
  readonly type = SETPREAUTORIZACIONREQUEST;
  constructor(
    public PreautorizacionRequest: datosTransaccion.PreautorizacionRequestModel
  ) { }
}

export class unsetPreautorizacionRequest implements Action {
  readonly type = UNSETPREAUTORIZACIONREQUEST;
}

export class setPrescripcionRequest implements Action {
  readonly type = SETPRESCRIPCIONREQUEST;
  constructor(
    public PrescripcionRequest: datosTransaccion.PrescripcionRequestModel
  ) { }
}

export class unsetPrescripcionRequest implements Action {
  readonly type = UNSETPRESCRIPCIONREQUEST;
}

export class setPrescriptorRequest implements Action {
  readonly type = SETPRESCRIPTORREQUEST;
  constructor(
    public PrescriptorRequest: datosTransaccion.PrescriptorRequestModel
  ) { }
}

export class unsetPrescriptorRequest implements Action {
  readonly type = UNSETPRESCRIPTORREQUEST;
}

export class setfechaReferenciaCancelRequest implements Action {
  readonly type = SETFECHAREFERENCIACANCELREQUEST;
  constructor(public FechaReferenciaCancelRequest: string) { }
}

export class unsetfechaReferenciaCancelRequest implements Action {
  readonly type = UNSETFECHAREFERENCIACANCELREQUEST;
}

export class setIdMsjRequest implements Action {
  readonly type = SETIDMSJREQUEST;
  constructor(public IdMsjRequest: string) { }
}

export class unsetIdMsjRequest implements Action {
  readonly type = UNSETIDMSJREQUEST;
}

export class setInicioTrxRequest implements Action {
  readonly type = SETINICIOTRXREQUEST;
  constructor(
    public InicioTrxRequest: datosTransaccion.InicioTrxRequestModel
  ) { }
}

export class unsetInicioTrxRequest implements Action {
  readonly type = UNSETINICIOTRXREQUEST;
}

export class setNroReferenciaCancelRequest implements Action {
  readonly type = SETNROREFERENCIACANCELREQUEST;
  constructor(public NroReferenciaCancelRequest: string) { }
}

export class unsetNroReferenciaCancelRequest implements Action {
  readonly type = UNSETNROREFERENCIACANCELREQUEST;
}

export class setNroReferenciaRequest implements Action {
  readonly type = SETNROREFERENCIAREQUEST;
  constructor(public NroReferenciaRequest: string) { }
}
export class unsetNroReferenciaRequest implements Action {
  readonly type = UNSETNROREFERENCIAREQUEST;
}

export class setSoftwareRequest implements Action {
  readonly type = SETSOFTWAREREQUEST;
  constructor(public SoftwareRequest: datosTransaccion.SoftwareRequestModel) { }
}

export class unsetSoftwareRequest implements Action {
  readonly type = UNSETSOFTWAREREQUEST;
}

export class setTerminalRequest implements Action {
  readonly type = SETTERMINALREQUEST;
  constructor(public TerminalRequest: datosTransaccion.TerminalRequestModel) { }
}

export class unsetTerminalRequest implements Action {
  readonly type = UNSETTERMINALREQUEST;
}

export class setTipoTransaccionRequest implements Action {
  readonly type = SETTIPOTRANSACCIONREQUEST;
  constructor(public TipoTransaccionRequest: string) { }
}

export class setVersionMsjRequest implements Action {
  readonly type = SETVERSIONMSJREQUEST;
  constructor(public VersionMsjRequest: string) { }
}

export class unsetCasiTodosDatos implements Action {
  readonly type = UNSETDATOSCASITODOS;
}

export class unsetDatosTransacciones implements Action {
  readonly type = UNSETDATOSTRANSACCIONES;
}


export type informarPrestacionActions =
  | setDetallesMedicamentosRequest
  | unsetDetallesMedicamentosRequest
  | setDetallesEstudiosRequest
  | unsetDetallesEstudiosRequest
  | setDetallesProcedimientosRequest
  | unsetDetallesProcedimientosRequest
  | setAcompannanteRequest
  | unsetAcompannanteRequest
  | setAtencionRequest
  | unsetAtencionRequest
  | setBeneficiarioRequest
  | unsetBeneficiarioRequest
  | setCodFinalizacionTratamiento
  | setDerivacionRequest
  | unsetDerivacionRequest
  | setDiagnosticosRequest
  | unsetDiagnosticosRequest
  | setDocumentacionRequest
  | unsetDocumentacionRequest
  | setEfectorRequest
  | unsetEfectorRequest
  | setMensajeParaFinanciador
  | setPreautorizacionRequest
  | unsetPreautorizacionRequest
  | setPrescripcionRequest
  | unsetPrescripcionRequest
  | setPrescriptorRequest
  | unsetPrescriptorRequest
  | setfechaReferenciaCancelRequest
  | unsetfechaReferenciaCancelRequest
  | setIdMsjRequest
  | unsetIdMsjRequest
  | setInicioTrxRequest
  | unsetInicioTrxRequest
  | setNroReferenciaCancelRequest
  | unsetNroReferenciaCancelRequest
  | setNroReferenciaRequest
  | unsetNroReferenciaRequest
  | setSoftwareRequest
  | unsetSoftwareRequest
  | setTerminalRequest
  | unsetTerminalRequest
  | setTipoTransaccionRequest
  | setVersionMsjRequest
  | unsetCasiTodosDatos
  | unsetDatosTransacciones
  | setExtraDispensaRequest
  | unsetExtraDispensaRequest;
