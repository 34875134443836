import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AtencionRequestModel } from '@models/datosTransaccion.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setAtencionRequest,
  setEstadoFormAtencionRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { Subscription } from 'rxjs';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { Fechas } from '@helpers/fechas';
@Component({
  selector: 'app-datos-atencion-request',
  templateUrl: './datos-atencion-request.component.html',
  styleUrls: ['./datos-atencion-request.component.scss'],
})
export class DatosAtencionRequestComponent implements OnInit, OnDestroy {

  @Input() atencionTipo: boolean = false;
  @Input() fechaAtencion: boolean = true;
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  objFechas = new Fechas();
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<AtencionRequestModel> =
    this.formBuilder.group<AtencionRequestModel>({
      Cama: new UntypedFormControl(<ControlState<string>>null, [blankSpaceValidator]),
      FechaAtencion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator

      ]),
      HoraAtencion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Sector: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoAtencion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  tiposAtencion: Array<any> = [];
  codigoTransaccion: string | null = null;
  fechaDiferida: any = null;
  fechaCirugia: any = null;
  fechaAlta: any = null;
  fechaInfoInternacion: any = null;
  fechaAutoCirugia: any = null;

  constructor(
    private store: Store<AppState>,
    private localeService: BsLocaleService
  ) {
    defineLocale('es', esLocale);
  }

  ngOnInit(): void {

    this.listenCambios();
    this.localeService.use('es');
    this.getDatos();
    this.addValidators();
    this.guardar();

  }

  addValidators() {
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {

        case '02D': // si es diferida va la fecha de la atencion
          this.fechaDiferida = new Date();
          this.form.controls['FechaAtencion'].setValue(this.fechaDiferida);
          this.form.controls['FechaAtencion'].setValidators(Validators.required);

          //setea fecha diferida: ayer
          this.fechaDiferida.setDate(this.fechaDiferida.getDate());

          this.store.dispatch(new setEstadoFormAtencionRequest(false));
          break;

        case '02Q': // si es informar cirugia realizada va la fecha
          this.fechaCirugia = new Date();
          this.form.controls['FechaAtencion'].setValue(this.fechaCirugia);
          this.form.controls['FechaAtencion'].setValidators(Validators.required);

          //setea fecha de hoy
          this.fechaCirugia.setDate(this.fechaCirugia.getDate());

          this.store.dispatch(new setEstadoFormAtencionRequest(false));
          break;

        case '02E': // si es alta internación realizada va la fecha;
          this.fechaAlta = new Date();
          this.form.controls['FechaAtencion'].setValue(this.fechaAlta);
          this.form.controls['FechaAtencion'].setValidators(Validators.required);

          //setea fecha de hoy
          this.fechaAlta.setDate(this.fechaAlta.getDate());

          this.store.dispatch(new setEstadoFormAtencionRequest(false));
          break;

        case '02I': // si es alta internación realizada va la fecha;
          this.fechaInfoInternacion = new Date();
          this.form.controls['FechaAtencion'].setValue(this.fechaInfoInternacion);
          this.form.controls['FechaAtencion'].setValidators(Validators.required);

          //setea fecha de hoy
          this.fechaInfoInternacion.setDate(this.fechaInfoInternacion.getDate());

          this.store.dispatch(new setEstadoFormAtencionRequest(false));
          break;

        // case '02S': // si es autorización cirugía realizada va la fecha;
        //   this.fechaAutoCirugia = new Date();
        //   this.form.controls['FechaAtencion'].setValue(null);
        //   this.form.controls['FechaAtencion'].setValidators(Validators.required);

        //   //setea fecha de hoy
        //   this.fechaAutoCirugia.setDate(this.fechaAutoCirugia.getDate());

        //   this.store.dispatch(new setEstadoFormAtencionRequest(false));
        //   break;

        default:
          this.store.dispatch(new setEstadoFormAtencionRequest(null));
          break;
      }

    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      // this.tiposAtencion =
      //   datos.datosInicialesExtra.datosInicialesExtra?.tipoAtencion || [];
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    //Convierto la fecha en AAAA-MM-DD
    let date = new Date('' + datos.FechaAtencion);
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let fechaAtencion = [date.getFullYear(), mnth, day].join("");
    console.log('Fecha: ', fechaAtencion);
    if (this.codigoTransaccion === '02S') {
      let atencionRequest = new AtencionRequestModel(
        datos.Cama,
        null,
        datos.HoraAtencion
          ? new Date(
            new Date().setUTCHours(
              (<any>datos.HoraAtencion).slice(0, 2),
              (<any>datos.HoraAtencion).slice(3, 5),
              0,
              0
            )
          )
          : null,
        datos.Sector,
        datos.TipoAtencion
      );
      this.store.dispatch(new setAtencionRequest(atencionRequest));
      this.store.dispatch(new setEstadoFormAtencionRequest(this.form.valid));
    }else{
      let atencionRequest = new AtencionRequestModel(
        datos.Cama,
        fechaAtencion,
        datos.HoraAtencion
          ? new Date(
            new Date().setUTCHours(
              (<any>datos.HoraAtencion).slice(0, 2),
              (<any>datos.HoraAtencion).slice(3, 5),
              0,
              0
            )
          )
          : null,
        datos.Sector,
        datos.TipoAtencion
      );
      this.store.dispatch(new setAtencionRequest(atencionRequest));
      this.store.dispatch(new setEstadoFormAtencionRequest(this.form.valid));
    }
  }
}
