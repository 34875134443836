import { DatosInicialesExtra } from '@models/datosInicialesExtras.models';
import { Action } from '@ngrx/store';

export const SETDATOSINICIOEXTRA = '[datosInicialesExtra] SETDATOSINICIOEXTRAS';

export const UNSETDATOSINICIOEXTRA =
  '[datosInicialesExtra] UNSETDATOSINICIOEXTRAS';

export class setDatosInicioExtra implements Action {
  readonly type = SETDATOSINICIOEXTRA;
  constructor(public datosExtra: DatosInicialesExtra) {}
}

export class unsetDatosInicioExtra implements Action {
  readonly type = UNSETDATOSINICIOEXTRA;
} 

export type datosInicialesExtraActions =
  | setDatosInicioExtra
  | unsetDatosInicioExtra;
