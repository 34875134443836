import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { PreautorizacionRequestModel } from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setEstadoFormPreautorizacionRequest,
  setPreautorizacionRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';

@Component({
  selector: 'app-datos-preautorizacion-request',
  templateUrl: './datos-preautorizacion-request.component.html',
  styleUrls: ['./datos-preautorizacion-request.component.scss'],
})
export class DatosPreautorizacionRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;
  @Input() nombreCampo: string = ''
  @Input() mostrar: boolean = true;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<PreautorizacionRequestModel> =
    this.formBuilder.group<PreautorizacionRequestModel>({
      CodigoPreautorizacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
        Validators.pattern('[0-9]+')
      ]),
      FechaPreautorizacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  codigoTransaccion: string | null = null;
  constructor(
    private store: Store<AppState>,
    private localeService: BsLocaleService
  ) {
    defineLocale('es', esLocale);
  }

  ngOnInit(): void {
    this.localeService.use('es');
    this.getDatos();
    this.addValidators();
    this.listenCambios();
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          // this.form.controls['TipoPrescriptor'].setValidators(
          //   Validators.required
          // );
          break;
        case '02Q':
          this.form.controls['CodigoPreautorizacion'].setValidators(
            Validators.required
          );
          break;

        default:
          break;
      }
      this.store.dispatch(
        new setEstadoFormPreautorizacionRequest(null)
      );
    }
  }

  addValidators() {
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02Q':
          this.form.controls['CodigoPreautorizacion'].setValidators([
            Validators.required,
            blankSpaceValidator
          ]);
          this.form.updateValueAndValidity();
          this.store.dispatch(new setEstadoFormPreautorizacionRequest(false));
          break;

        default:
          this.store.dispatch(new setEstadoFormPreautorizacionRequest(null));
          break;
      }

    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    let preautorizacionRequest = new PreautorizacionRequestModel(
      datos.CodigoPreautorizacion,
      datos.FechaPreautorizacion
    );
    this.store.dispatch(new setPreautorizacionRequest(preautorizacionRequest));
    this.store.dispatch(
      new setEstadoFormPreautorizacionRequest(this.form.valid)
    );
  }

  rellenarCampo(valor: string) {
    //let valorCodigo=this.form.controls['CodigoPreautorizacion'].value;
    let valorCodigo = valor;
    if (valorCodigo !== null && valorCodigo !== '') {
      this.form.controls['CodigoPreautorizacion'].setValue(valorCodigo!.padStart(8, '0'));
    }

  }
}
