<div class="modal-header row" style="margin-right: 95px;">
  <div class="col-1">
    <button *ngIf="step.numero !== '1'" class="btn" (click)="cambiarStep()">
      <fa-icon [icon]='["fas", "chevron-left"]' class="text-secondary"></fa-icon>
    </button>
  </div>
  <div class="col-10">
    <h5 class="modal-title" style="display: flex; justify-content: center;">Seleccione un estudio</h5>
  </div>
</div>
<div class="modal-body" style="overflow-y: auto !important; max-height: calc(100vh - 200px);">
  <div class="container mt-2 mb-3">
    <div class="buttonContainer">
      <button class="btn" [ngClass]="{'btn-primary': analisisClicked, 'btn-secondary': !analisisClicked}">Análisis de
        laboratorio</button>
      <button class="btn" disabled
        [ngClass]="{'btn-primary': diagnosticoClicked, 'btn-secondary': !diagnosticoClicked}">Diagnóstico por
        imagenes</button>
      <button class="btn" disabled
        [ngClass]="{'btn-primary': certificadoClicked, 'btn-secondary': !certificadoClicked}">Certificado
        Médico</button>
    </div>
  </div>

  <div class="buttonContainer">
    <!-- BUSQUEDA POR ESTUDIOS -->
    <div class="buscarMedicamentos" style="width: 100%;">
      <input class="form-control inputSearch" #estudioSearchInput type="search" name="searchEstudio"
        [(ngModel)]="searchTextEstudio" autocomplete="off" [placeholder]="'Buscar análisis'" />
      <div class="input-group-append">
        <span class="input-group-text">
          <fa-icon [icon]='["fas", "search"]' class="text-secondary"></fa-icon>
        </span>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="list-group list-group-flush" *ngIf="step.numero === '1'">
      <div *ngIf="arrayEstudios.length == 0">
        <fa-icon [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
        Obteniendo Grupo de estudios...
      </div>
      <a href="#" class="list-group-item list-group-item-action" (click)="siguiente('2',estudio)"
        *ngFor="let estudio of arrayEstudios">
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1">{{estudio.nombre | uppercase}}</p>
          <fa-icon [icon]='["fas", "chevron-right"]' class="text-secondary"></fa-icon>
        </div>
      </a>
    </div>
    <div class="list-group list-group-flush" *ngIf="step.numero === '2'">
      <div *ngIf="isSearching">
        <fa-icon [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
        Obteniendo Estudios...
      </div>
      <ng-container>
        <a href="#" class="list-group-item list-group-item-action" *ngFor="let estudio of arrayEstudioDetalles"
          (click)="agregarEstudio(estudio)">
          <div class="d-flex w-100 justify-content-between">
            <p class="mb-1">{{estudio.determinacion| uppercase}}</p>
            <fa-icon [icon]='["fas", "chevron-right"]' class="text-secondary"></fa-icon>
          </div>
          <p class="mb-0 pb-0 text-secondary">{{estudio.grupo}}</p>
        </a>
      </ng-container>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="buttonAnularTicket" (click)="closeModal()">Cerrar</button>
</div>
