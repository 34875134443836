import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ExtrasDispensa } from '@models/datosTransaccion.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { setEstadoFormFirmaRequest, setExtraDispensaRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import SignaturePad from 'signature_pad';
import { PersistenciaService } from '@services/persistencia.service';

@Component({
  selector: 'app-datos-firma-manuscrita',
  templateUrl: './datos-firma-manuscrita.component.html',
  styleUrls: ['./datos-firma-manuscrita.component.scss']
})
export class DatosFirmaManuscritaComponent implements OnInit, AfterViewInit {
  @ViewChild('firmaDigital', { static: true }) signaturePadElement: any;
  signaturePad: any;
  firma: any;
  extraDispensa: ExtrasDispensa | null = null;
  private subscriptionStore: Subscription = new Subscription();
  firmaRealizada: any
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>,
    private persistenciaService: PersistenciaService
  ) { }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  ngOnInit(): void {
    this.subscriptionStore = this.store
      .select('datosTransaccion')
      .subscribe((datos) => {
        this.extraDispensa = datos.extraDispensaRequest || null;
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  limpiarFirma() {
    this.signaturePad.clear();
  }

  async guardarFirma() {
    if (this.signaturePad) {
      //console.log('Firma: ', this.signaturePad.toDataURL());
      let reduct = await this.downscaleImage(this.signaturePad.toDataURL(), 'image/png', 200, 1);
      let extDispensa: ExtrasDispensa = {
        ...this.extraDispensa,
        firma_olografa: reduct
      };
      this.store.dispatch(new setExtraDispensaRequest(extDispensa));
      // this.persistenciaService.setLocal('firmaLocal', reduct);
    }

    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  getImage(dataUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = dataUrl;
      image.onload = () => {
        resolve(image);
      };
      image.onerror = (el: any, err) => {
        reject(err);
      };
    });
  }

  async downscaleImage(dataUrl: string, imageType: string,  // e.g. 'image/jpeg'
    resolution: number, quality: number   // e.g. 0.9 = 90% quality // resolution: max width/height in pixels
  ): Promise<string> {

    // Create a temporary image so that we can compute the height of the image.
    const image = await this.getImage(dataUrl);
    const oldWidth = image.naturalWidth;
    const oldHeight = image.naturalHeight;
    console.log('dims', oldWidth, oldHeight);

    const longestDimension = oldWidth > oldHeight ? 'width' : 'height';
    const currentRes = longestDimension == 'width' ? oldWidth : oldHeight;
    console.log('longest dim', longestDimension, currentRes);

    if (currentRes > resolution) {
      console.log('need to resize...');

      // Calculate new dimensions
      const newSize = longestDimension == 'width'
        ? Math.floor(oldHeight / oldWidth * resolution)
        : Math.floor(oldWidth / oldHeight * resolution);
      const newWidth = longestDimension == 'width' ? resolution : newSize;
      const newHeight = longestDimension == 'height' ? resolution : newSize;
      console.log('new width / height', newWidth, newHeight);

      // Create a temporary canvas to draw the downscaled image on.
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the downscaled image on the canvas and return the new data URL.
      const ctx = canvas.getContext('2d')!;
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      let imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
      let pixels = imgData.data;
      for (var i = 0; i < pixels.length; i += 4) {

        let lightness = parseInt('' + (pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3);
        pixels[i] = lightness;
        pixels[i + 1] = lightness;
        pixels[i + 2] = lightness;
      }
      ctx.putImageData(imgData, 0, 0);
      const newDataUrl = canvas.toDataURL(imageType, quality);
      return newDataUrl;
    }
    else {
      return dataUrl;
    }
  }

}
