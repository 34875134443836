import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmar-modal',
  templateUrl: './confirmar-modal.component.html',
  styleUrls: ['./confirmar-modal.component.scss'],
})
export class ConfirmarModalComponent implements OnInit {
  titulo: string = '¿Esta seguro que quiere realizar está accion?';
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  devolverRespuesta(rta: boolean) {
    if (rta) {
      this.activeModal.close({
        respuesta: true,
      });
    } else {
      this.activeModal.close({ respuesta: false });
    }
  }
}
