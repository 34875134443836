import { Pipe, PipeTransform } from '@angular/core';
import { Financiador } from '@models/financiador.model';
import { Prestador } from '@models/prestador.model';

@Pipe({
  name: 'filterFinanciadorEnable',
  pure: true,
})
export class FilterFinanciadorEnablePipe implements PipeTransform {
  transform(
    [...financiadores]: Array<Financiador>,
    prestadores: Array<Prestador>,
    cuitFinanciadorPrestadorSeleccionado: string | null
  ): Array<any> {
    if (financiadores.length == 0 || prestadores.length == 0) {
      return [];
    }

    // Los financiadores siempre en mostrar: true
    prestadores.forEach((prestador) => {
      if (prestador.financiadores) {
        prestador.financiadores.forEach((financiador) => {
          let financiadorIndex = financiadores.findIndex(
            (x) => x.sigla == financiador.sigla
          );
          if (financiadorIndex != -1) {
            let financiadorNuevo = Object.assign(
              { mostrar: true },
              financiadores[financiadorIndex]
            );
            financiadores.splice(financiadorIndex, 1, financiadorNuevo);
          }
        });
      }
    });

    return financiadores.sort((a, b) => {
      return a.mostrar === b.mostrar ? 0 : a.mostrar ? -1 : 1;
    });
    // if (cuitFinanciadorPrestadorSeleccionado) {
    //   let financiadorIndex = financiadores.findIndex(
    //     (x) => x.cuit == cuitFinanciadorPrestadorSeleccionado
    //   );
    //   if (financiadorIndex != -1) {
    //     console.log('hice click');
    //     let financiadorNuevo = Object.assign(
    //       { mostrar: true },
    //       financiadores[financiadorIndex]
    //     );
    //     financiadores.splice(financiadorIndex, 1, financiadorNuevo);
    //   }
    //   return financiadores.sort((a, b) => {
    //     return a.mostrar === b.mostrar ? 0 : a.mostrar ? -1 : 1;
    //   });
    // } else {
    //   console.log(' click 2')
    //   prestadores.forEach((prestador) => {
    //     prestador.financiadores.forEach((financiador) => {
    //       let financiadorIndex = financiadores.findIndex(
    //         (x) => x.sigla == financiador.sigla
    //       );
    //       if (financiadorIndex != -1) {
    //         let financiadorNuevo = Object.assign(
    //           { mostrar: true },
    //           financiadores[financiadorIndex]
    //         );
    //         financiadores.splice(financiadorIndex, 1, financiadorNuevo);
    //       }
    //     });
    //   });
    //   return financiadores.sort((a, b) => {
    //     return a.mostrar === b.mostrar ? 0 : a.mostrar ? -1 : 1;
    //   });
    // }
  }
}
