<div class="container-xxxl">
  <div class="row justify-content-center p-5">
    <div class="col-12 card p-0 border-0 boxShadow">
      <div class="card-header p-0 bg-navegation rounded-top p-3 text-center" style="color: white;">
        {{lugarAtencion}}
      </div>
      <div class="card-body rounded p-5">
        <div class="row">
          <div class="col-sm-12 col-md-4 border-right mb-5">
            <h4>Nombre y apellido</h4>
            <hr>
            <h6>{{datosPerfil?.nombre}}</h6>
            <br>

            <h4>Email</h4>
            <hr>
            <h6>{{datosPerfil?.email}}</h6>
            <br>

            <h4>Rol</h4>
            <hr>
            <h6>{{datosPerfil?.rol}}</h6>
            <br>

            <ng-container *ngIf="datosPerfil?.cuitPrestador">
              <h4>Cuit prestador asociado</h4>
              <hr>
              <h6>{{datosPerfil?.cuitPrestador}}</h6>
              <br>
            </ng-container>

            <h4>Estado de datos</h4>
            <hr>
            <ng-container *ngIf="!datosPerfil?.modificar" class="mb-4">
              <h6>Aceptados</h6>
            </ng-container>
            <ng-container *ngIf="datosPerfil?.modificar" class="mb-4">
              <h6>Sus datos fueron modificados y deben ser aceptados por alguno de sus administradores
              </h6>
              <button class="btn btn-pcpos" (click)="verAdministradores()"> Ver administradores </button>
            </ng-container>

          </div>
          <div class="col-sm-12 col-md-8 border-left">

            <div *ngIf="operaciones" id="scrollableContent">
              <div class="row">
                <ng-container *ngFor="let operacion of operaciones;">
                  <div class="col-sm-12 col-md-5 col-lg-4 col-xl-3">
                    <div id="card"
                      class="card shadow-sm mb-3 justify-content-center align-items-center text-center shadow"
                      (click)="operacion.operacion()">
                      {{operacion.nombre}}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
