import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DialogsService } from '@services/dialogs.service';
import { AppState } from '@store/app.reducers';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { Subscription } from 'rxjs';
import { SugerenciaModel } from '@models/sugerencia.model';
import { SugerenciaService } from '@services/sugerencia.service';
import {
  setSugerenciasRequest,
  unsetSugerenciasRequest,
} from '../../store/actions/sugerencias.action';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '@services/global.service';
import { UsuarioActualModel } from '../../models/login.models';
import { redirectClass } from '@models/global.models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sugerencia',
  templateUrl: './sugerencia.component.html',
  styleUrls: ['./sugerencia.component.scss'],
})
export class SugerenciaComponent implements OnInit {
  private subscriptionForm: Subscription = new Subscription();
  private subscriptionStore: Subscription = new Subscription();

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<SugerenciaModel> = this.formBuilder.group<SugerenciaModel>({
    nombre: new UntypedFormControl(<ControlState<string>>'', [
      blankSpaceValidator
    ]),
    apellido: new UntypedFormControl(<ControlState<string>>'', [
      blankSpaceValidator
    ]),
    email: new UntypedFormControl(<ControlState<string>>'', [
      blankSpaceValidator,
      Validators.required,
      Validators.email,
    ]),
    telefono: new UntypedFormControl(<ControlState<string>>'', [
      blankSpaceValidator,
      Validators.pattern('[0-9 .?]+'),
    ]),
    mensaje: new UntypedFormControl(<ControlState<string>>'', [
      blankSpaceValidator,
      Validators.required,
      Validators.maxLength(500),
    ]),
  });

  datosUsuario: UsuarioActualModel | null = null;

  constructor(
    private store: Store<AppState>,
    private dialogsService: DialogsService,
    private localeService: BsLocaleService,
    private sugerenciaService: SugerenciaService,
    private globalService: GlobalService,
    private router: Router
  ) {
    defineLocale('es', esLocale);
  }

  ngOnInit(): void {
    this.listenCambios();
    this.setDefaultValues();
    this.localeService.use('es');
  }

  setDefaultValues() {
    this.dialogsService.cargando();
    this.store.dispatch(new ActivarLoading());

    this.subscriptionStore = this.store.subscribe((datos) => {
      this.datosUsuario = datos.configuracion.usuarioActual;
    });

    if (this.datosUsuario) {
      this.form.controls['nombre'].setValue(this.datosUsuario.nombre)
      this.form.controls['nombre'].disable();
      this.form.controls['apellido'].setValue(this.datosUsuario.apellido)
      this.form.controls['apellido'].disable();
      this.form.controls['email'].setValue(this.datosUsuario.email)
      this.form.controls['email'].disable();
      this.dialogsService.cerrar();
      this.store.dispatch(new DesactivarLoading());
    }

  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      let datos = this.form.getRawValue();

      let sugerenciaRequest = new SugerenciaModel(
        datos.nombre,
        datos.apellido,
        datos.email,
        datos.telefono,
        datos.mensaje
      );
      this.store.dispatch(new setSugerenciasRequest(sugerenciaRequest));
      this.store.dispatch(new DesactivarLoading());
    });
  }

  enviarSugerencia() {
    this.store.dispatch(new ActivarLoading());
    console.log(this.form);
    if (this.form.invalid) {
      this.dialogsService.toastError('Complete los campos obligatorios');
      return;
    }
    this.sugerenciaService
      .sendSugerencia(this.form.getRawValue())
      .subscribe((respuesta) => {
        this.store.dispatch(new DesactivarLoading());
        console.log("la respuesta del sendSugerencia", respuesta);
        if (respuesta) {
          this.dialogsService.toastSuccess('Su sugerencia ha sido enviada con éxito!');
          this.store.dispatch(new unsetSugerenciasRequest());
          this.router.navigateByUrl('inicio');
        }
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
          this.store.dispatch(new unsetSugerenciasRequest());
        };
      });
  }

  contacto() {
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }

  ngOnDestroy() {
    this.subscriptionForm.unsubscribe();
    this.subscriptionStore.unsubscribe();
  }
}
