import { Component, OnInit } from '@angular/core';
import { DataUsuario } from '@models/operaciones.models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ver-administradores-modal',
  templateUrl: './ver-administradores-modal.component.html',
  styleUrls: ['./ver-administradores-modal.component.scss'],
})
export class VerAdministradoresModalComponent implements OnInit {
  administradores: Array<DataUsuario> = [];
  titulo: string = 'Estos son los administradores de su lugar de atención';
  funcion: string = 'verAdministradores';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    console.log(this.administradores);
  }

  devolverRespuesta(respuesta: string) {
    if (this.funcion == 'verAdministradores') {
      this.activeModal.close();
    }
    if (this.funcion == 'verDatosSolicitados') {
      switch (respuesta) {
        case 'aceptar':
          this.activeModal.close({
            aceptar: true,
            respuesta: this.administradores[0],
          });
          break;

        case 'rechazar':
          this.activeModal.close({
            aceptar: false,
            respuesta: this.administradores[0],
          });
          break;

        case 'cancelar':
          this.activeModal.close();
          break;

        default:
          this.activeModal.close();
          break;
      }
    }
  }
}
