<form [formGroup]="form">
    <p class="title__dataPrescripcion">Buscar diagnostico (CIE-10) <span class="span-required">*</span></p>
    <div class="rowUpDiagnostico">

        <div class="form-infoN" *ngIf="nomenclador">
            <label>Nomenclador <span class="span-required">*</span>
            </label>
            <select type="text" class="form-controlN" value="CIE-10">
                <option value="CIE-10">CIE-10</option>
            </select>
        </div>

        <div class="data__diagnosticoM">
            <app-input-diagnosticos (selectDiagnostico)="selectDiagnostico($event)" formControlName="DescripcionDiagnostico" ngDefaultControl></app-input-diagnosticos>
        </div>

        <div class="form-infoN">
            <!-- POSIBLE ERROR: el input en el nuevo disenio es un p -->
            <input type="text" class="form-controlN" readonly formControlName="CodDiagnostico">
            <small class="text-danger animated fadeIn mt-0"
                *ngIf="form.get('CodDiagnostico')?.errors && form.get('CodDiagnostico')?.touched">Por favor
                ingrese un diagnostico válido</small>
        </div>

    </div>
</form>