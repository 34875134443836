<div class="container__panelPrestacion">
  <div class="container-rescate-receta">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Fecha</th>
          <th scope="col">Prescriptor</th>
          <th scope="col">Prestaciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let recipe of recipes" (click)="selectRecipe(recipe)">
          <td>{{ formatDate(recipe.Formulario.Fecha) }}</td>
          <td>{{ recipe.Prescriptor.Nombre }} {{ recipe.Prescriptor.Apellido }}</td>
          <td>
            <ng-container *ngFor="let itemRecipe of recipe.DetalleReceta.Item | slice:0:3; let last = last">
              {{ itemRecipe.Codigo }} - {{ itemRecipe.Descripcion | uppercase }}<span *ngIf="!last"><br></span>
            </ng-container>
            <span *ngIf="recipe.DetalleReceta.Item.length > 3"> ...({{ recipe.DetalleReceta.Item.length - 3 }}
              más)</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- <ng-container *ngIf="informarPrestacion">
  <div *ngIf="codigoTransaccion=='02A' && informarPrestacion" class="prestacion__container">
    <app-informar-prestacion-rescate></app-informar-prestacion-rescate>
  </div>

  <div class="containerEfDiag">
    <app-datos-efector-request class="widthContainer" *ngIf="codigoTransaccion=='02A' && informarPrestacion">
    </app-datos-efector-request>
    <app-datos-diagnosticos-request class="widthContainer" *ngIf="codigoTransaccion=='02A' && informarPrestacion">
    </app-datos-diagnosticos-request>
  </div>
</ng-container> -->
