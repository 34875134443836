import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as configuracionInicial from '@configuracioninicial/configuracionInicial.index';
import { AuthConfiguracionInicialGuard } from '@guards/authConfiguracionInicial.guards';
import { PagesRoutingModule } from '@pages/pages.routing';
import { ConfirmarModalComponent } from './modals/confirmar-modal/confirmar-modal.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'introduccion',
    component: configuracionInicial.IntroduccionComponent,
  },
  {
    path: 'instalacion',
    component: configuracionInicial.InstalacionComponent
  },
  {
    path: 'instalacion/seleccionarLugarAtencion',
    component: configuracionInicial.SelectLugarAtencionComponent,
    canActivate: [AuthConfiguracionInicialGuard],
  },
  {
    path: 'login',
    component: configuracionInicial.LoginComponent,
    canActivate: [AuthConfiguracionInicialGuard],
  },
  {
    path: 'signup',
    component: configuracionInicial.SignupComponent,
    canActivate: [AuthConfiguracionInicialGuard],
  },
  {
    path: 'contactanos',
    component: configuracionInicial.ContactanosComponent
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), PagesRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
