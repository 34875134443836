import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import {
  AcompannanteRequestModel,
  PrescripcionRequestModel,
} from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setAcompannanteRequest,
  setEstadoFormAcompannanteRequest,
  setEstadoFormPrescripcionRequest,
  setPrescripcionRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-datos-prescripcion-request',
  templateUrl: './datos-prescripcion-request.component.html',
  styleUrls: ['./datos-prescripcion-request.component.scss'],
})
export class DatosPrescripcionRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<PrescripcionRequestModel> =
    this.formBuilder.group<PrescripcionRequestModel>({
      Intrucciones: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Numero: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      SolicitaAtencionDomiliciaria: new UntypedFormControl(<ControlState<string>>null,
        [blankSpaceValidator]
      ),
    });

  codigoTransaccion: string | null = null;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          // this.form.controls['TipoPrescriptor'].setValidators(
          //   Validators.required
          // );
          break;

        default:
          break;
      }
      this.store.dispatch(
        new setEstadoFormPrescripcionRequest(this.form.valid)
      );
    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    let prescripcionRequest = new PrescripcionRequestModel(
      datos.Intrucciones,
      datos.Numero,
      datos.SolicitaAtencionDomiliciaria
    );
    this.store.dispatch(new setPrescripcionRequest(prescripcionRequest));
    this.store.dispatch(new setEstadoFormPrescripcionRequest(this.form.valid));
  }
}
