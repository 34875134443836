import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { redirectClass } from '@models/global.models';
import { Prestador } from '@models/prestador.model';
import { roles } from '@models/signup.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { errorGenerico } from 'errores';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-seleccionar-prestadores-no-deseados',
  templateUrl: './seleccionar-prestadores-no-deseados.component.html',
  styleUrls: ['./seleccionar-prestadores-no-deseados.component.scss'],
})
export class SeleccionarPrestadoresNoDeseadosComponent
  implements OnInit, OnDestroy
{
  private subscriptionUI: Subscription = new Subscription();
  private subscriptionConfiguracion: Subscription = new Subscription();
  submitted: boolean = false;
  cargando: boolean = false;
  idUsuario: number | null = null;
  roles: Array<roles> = [];
  codigoInstalacion: string = '';
  prestadores: Array<Prestador> = [];
  prestadoresNoDeseadosSeleccionados: Array<string> = [];
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<any> = this.formBuilder.group<any>({
    cuits: this.formBuilder.array<string>([
      this.formBuilder.group({
        cuitPrestador: new UntypedFormControl(<ControlState<string>>'', [
          Validators.required,
          blankSpaceValidator,
        ]),
      }),
    ]),
  });

  constructor(
    private store: Store<AppState>,
    private location: Location,
    private operacionesPerfilService: OperacionesPerfilService,
    private dialogsService: DialogsService,
    private globalService: GlobalService
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.buscarDatosPrestadoresNoDeseados();
    this.setPrestadoresNoDeseadosSeleccionados();
    this.subscriptionUI = this.store.select('ui').subscribe((uiState) => {
      this.cargando = uiState.loading;
    });

    this.subscriptionConfiguracion = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.codigoInstalacion =
            configuracionState.usuarioActual.codigoInstalacion;
          this.getPrestadores();
        } else {
          this.globalService.redirect(
            new redirectClass(
              'contactanos',
              errorGenerico,
              'No hay usuario actual al modificar usuario'
            )
          );
        }
      });
  }

  setPrestadoresNoDeseadosSeleccionados() {
    this.form.controls.cuits.valueChanges.subscribe((respuesta) => {
      if (respuesta) {
        let array: Array<string> = [];
        respuesta.forEach((resp: any) => {
          array.push(resp.cuitPrestador);
        });
        this.prestadoresNoDeseadosSeleccionados = array;
      } else {
        this.prestadoresNoDeseadosSeleccionados = [];
      }
    });
  }

  getPrestadores() {
    this.operacionesPerfilService
      .getPrestadoresDeLugarAtencion(this.codigoInstalacion)
      .subscribe(
        (prestadores) => {
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.prestadores = prestadores;
        },
        (error) => {
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }

  ngOnDestroy() {
    this.subscriptionUI.unsubscribe();
    this.subscriptionConfiguracion.unsubscribe();
  }

  irAtras() {
    this.location.back();
  }

  getCuits() {
    let cuits = this.form.controls.cuits as UntypedFormArray;
    if (cuits) {
      return cuits.controls;
    } else {
      return null;
    }
  }

  addPrestador() {
    const cuits = this.form.controls.cuits as UntypedFormArray;
    cuits.push(
      this.formBuilder.group<any>({
        cuitPrestador: new UntypedFormControl('', [
          Validators.required,
          blankSpaceValidator,
        ]),
      })
    );
  }

  deletePrestador(i: number) {
    const control = <UntypedFormArray>this.form.controls['cuits'];
    control.removeAt(i);
  }

  buscarDatosPrestadoresNoDeseados() {
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService.getPrestadoresNoDeseados().subscribe(
      (respuesta) => {
        let cuitsArray = new UntypedFormArray([]);
        respuesta.forEach((cuitPrestador: string, index: number) => {
          let elem = this.formBuilder.group({
            cuitPrestador: new UntypedFormControl(
              <ControlState<string>>cuitPrestador,
              [Validators.required, blankSpaceValidator]
            ),
          });
          cuitsArray.push(elem);
        });
        this.form.controls['cuits'] = cuitsArray;
        this.form.updateValueAndValidity();
        this.setPrestadoresNoDeseadosSeleccionados();

        this.dialogsService.cerrar();
        this.store.dispatch(new DesactivarLoading());
      },
      (error: HttpErrorResponse) => {
        this.dialogsService.cerrar();
        this.store.dispatch(new DesactivarLoading());
        this.globalService.manageError(error);
      }
    );
  }

  guardarCambios() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService
      .setearPrestadoresNoDeseados(this.form.getRawValue().cuits)
      .subscribe(
        (respuesta) => {
          this.store.dispatch(new DesactivarLoading());
          this.dialogsService.toastSuccess(respuesta.message);

          this.irAtras();
        },
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }
}
