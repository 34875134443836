<div class="data-login">
  <ng-template #login let-r="result" let-t="term">
    <ngb-highlight [result]="r.email" [term]="t"></ngb-highlight>
  </ng-template>

  <label for="email" id="label-email" class="labelForm"><input [ngbTypeahead]="search"
    placeholder="Email" [resultTemplate]="login" [inputFormatter]="formatter"
    [(ngModel)]="model" (ngModelChange)="submit(null)"></label>

  <!-- <div class="reminder reminder__loginE">
    <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger" id="warning__img">
    </div>
    <p class=" reminder__textLoginE">El email ingresado no es v&aacute;lido
    </p>
  </div> -->


</div>
