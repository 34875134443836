import { app } from 'electron';
import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar/navbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RadiocheckboxComponent } from './radiocheckbox/radiocheckbox.component';
import { DesplegableComponent } from './desplegable/desplegable.component';
import { IconsModule } from '../../assets/icons/icons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    NavbarComponent,
    BreadcrumbsComponent,
    RadiocheckboxComponent,
    DesplegableComponent
  ],
  exports: [
    NavbarComponent,
    BreadcrumbsComponent,
    RadiocheckboxComponent,
    DesplegableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    IconsModule,
  ],
})
export class SharedModule {}
