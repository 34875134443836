<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Nuvalid</title>
    <!-- <link rel="stylesheet" href="../../../../sass/estilos.scss"> -->
</head>

<div class="operacion operacionNormalHija" (click)="seleccionarTransaccion()">

    <p class="operacion__text">
        {{transaccion?.nombre}}
    </p>
    <!-- <div *ngIf="seleccionado">
        <img src="./assets/design/checkedOperacion.svg" alt="checkedOperacion" class="checkedOperacion">
    </div> -->
</div>

</html>
