<!DOCTYPE html>

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Nuvalid</title>
    <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>

<div class="containerNOperacion ">
    <div class="card__columnR card__columnR-dashboard" id="card__columnRMore">


        <div>
            <div class="container__operacionGeneral container__operacionGeneralMore">
                <div class="iconsPanel_operacion">
                    <p class="tittle__dashboard tittle__operacionMore">ACTUALIZAR PANEL DE OPERACIONES
                    </p>

                    <div class="container__operacionesMore">
                        <div class="columnOperacion-L">
                            <p class="title__MoreOperaciones">Operaciones actuales</p>
                            <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="ordenNuevoTransacciones"
                                [cdkDropListConnectedTo]="[doneList]" class="container__OperacionesActualesL"
                                (cdkDropListDropped)="drop($event)">
                                <div *ngFor="let item of ordenNuevoTransacciones" cdkDrag
                                    class="operacion operacionNormal operacionNormalMore">
                                    <p class="operacion__text">{{item.nombre}}</p>
                                </div>
                            </div>

                        </div>


                        <div class="columnOperacion-R">
                            <p class="title__MoreOperaciones">Todas las operaciones</p>
                            <div class="container__OperacionesTodasR">
                                <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="transacciones"
                                    [cdkDropListConnectedTo]="[todoList]" class="OperacionesTodasR"
                                    (cdkDropListDropped)="drop($event)">
                                    <div *ngFor="let item of transacciones" cdkDrag
                                        class="operacion operacionNormal operacionNormalMoreUnselected">

                                        <p class="operacion__text">{{item.nombre}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="components componentsOperacionesMore">
                    <button class="botton" (click)="irAtras()" [disabled]="cargando">Atr&aacute;s</button>

                    <button *ngIf="!cargando" class="botton" (click)="guardarCambios()">Guardar</button>
                    <button *ngIf="cargando" class="botton" [disabled]="true">
                        <fa-icon class="mr-1 fa-spin" [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
                        Espere...
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>