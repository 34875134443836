<nav class="shadow navbar navbar-expand-sm navbar-light bg-light">
    <div id="breadcrumbs">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link" href="#cardSocios">Seleccionar Socio <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#cardOperaciones">Operaciones <span class="sr-only">(current)</span></a>
            </li>
        </ul>
    </div>
</nav>