import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { setEstadoFormNroReferenciaCancelRequest } from '../../../../store/actions/estadoFormsinicio.action';

@Component({
  selector: 'app-anular-prestacion',
  templateUrl: './anular-prestacion.component.html'
})
export class AnularPrestacionComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    // this.store.dispatch(new setEstadoFormNroReferenciaCancelRequest(false));
  }

}
