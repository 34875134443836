<div class="prestacion__container">

  <form [formGroup]="form" (ngSubmit)="guardar()">
    <div class="container__panelPrestacion">
      <div class="container-prestacion">
        <div class="container-prestacionDatos" style="flex-wrap: wrap; padding-bottom: 1rem;">
          <div class="container-prestacion1">


            <div class="form-infoN prestacion__cPrestacion">
              <label id="labelCPrestacion" for="cPrestacion">Código o Prestación</label>
              <input class="input__codigoPrestaciones form-controlN" disabled>

            </div>
            <div class="form-infoN prestacion__cantidad">
              <label for="cantidadPrestacion">Cantidad</label>
              <input disabled id="cantidadPrestacion" #cantidadSolicitada
                class="form-controlN form-controlN-dashboardP">

            </div>
            <div class="form-infoN prestacion__tipo">
              <label for="tipoPrestacion">Tipo <span *ngIf="form.get('TipoPrestacion') | controlRequired"
                  class="span-required">*</span></label>
              <select class="form-control" id="tipoPrestacion" disabled>
                <!-- <option [ngValue]="tipoPrestacion.codigo" *ngFor="let tipoPrestacion of tiposPrestaciones">
                  {{ tipoPrestacion.codigo }}-{{tipoPrestacion.nombre}}
                </option> -->
              </select>
            </div>
            <div class="form-infoN prestacion__arancel">
              <label for="arancelPrestacion">Arancel <span *ngIf="form.get('ArancelPrestacion') | controlRequired"
                  class="span-required">*</span></label>
              <select class="form-control" id="arancelPrestacion" disabled>
                <!-- <option [ngValue]="tipoArancel.codigo" *ngFor="let tipoArancel of tiposAranceles">
                  {{ tipoArancel.codigo }}-{{tipoArancel.nombre}}
                </option> -->
              </select>
            </div>


            <app-datos-preautorizacion-request nombreCampo="N° Autorización" class="prestacion__autorizacion">
            </app-datos-preautorizacion-request>

          </div>

          <table class="table__prestaciones" style="width: 98%">
            <thead class="thead__prestaciones">
              <tr class="theadTr__prestaciones">
                <th class="theadTh__prestaciones">Código</th>
                <th class="theadTh__prestaciones">Descripción</th>
                <th class="theadTh__prestaciones">Cantidad</th>
                <th class="theadTh__prestaciones">Tipo</th>
                <th class="theadTh__prestaciones">Arancel</th>
                <th class="theadTh__prestaciones">Acciones</th>
              </tr>
            </thead>
            <tbody class="tbody__prestaciones">
              <ng-container
                *ngIf="detallesProcediminetos && detallesProcediminetos.length > 0; else noDetallesProcedimientos">
                <tr class="tbodyTr__prestaciones"
                  *ngFor="let detalleProcedimiento of detallesProcediminetos; let i=index">
                  <td class="tbodyTd__prestaciones">{{detalleProcedimiento.CodPrestacion}}</td>
                  <td class="tbodyTd__prestaciones">{{detalleProcedimiento.DescripcionPrestacion}}</td>
                  <td class="tbodyTd__prestaciones">{{detalleProcedimiento.CantidadSolicitada}}</td>
                  <td class="tbodyTd__prestaciones">{{detalleProcedimiento.TipoPrestacion}}</td>
                  <td class="tbodyTd__prestaciones">{{detalleProcedimiento.ArancelPrestacion}}</td>
                  <td class="tbodyTd__prestaciones">

                    <figure class="prestacion_butonMore butonDelete">
                      <p><a href="#" (click)="eliminar(i)" data-title="Eliminar item"><img
                            src="./assets/design/close.svg" alt="Add"></a></p>
                    </figure>
                  </td>
                </tr>
              </ng-container>

              <ng-template #noDetallesProcedimientos>
                <tr class="theadTr__prestaciones">
                  <td class="tbodyTd__prestaciones">Por favor cargue al menos una prestación</td>
                </tr>
              </ng-template>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </form>
</div>
