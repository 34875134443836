<ng-template #longContent let-modal>
  <app-datos-monodroga-request></app-datos-monodroga-request>
</ng-template>
<ng-template #estudioContent let-modal>
  <app-datos-estudio-request></app-datos-estudio-request>
</ng-template>
<div class="container__panelPrestacion">
  <div class="container__prescripcion">
    <div id="buttonPrescripcion" class="operacion operacionNormalMore"
      [ngClass]="{operacionNormalMoreSelect : seleccionado, operacionOculta : prescripcion?.cancelar }"
      (click)="prestadorApto ? seleccionarPrescripcion(longContent) : noPuedePrescribir()">
      <p class="operacion__text">
        {{prescripcion?.nombre}}
      </p>
    </div>
    <div class="operacion operacionNormalMore" style="width: 15rem;"
      [ngClass]="{operacionNormalMoreSelect : seleccionado, operacionOculta : prescripcion?.cancelar }"
      (click)="prestadorApto ? seleccionarEstudios(estudioContent) : noPuedePrescribir()">
      <p class="operacion__text">
        Estudios
      </p>
    </div>
  </div>
</div>
