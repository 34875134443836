<!DOCTYPE html>
<div class="container__dataUser" style="padding-right: 0px;">

  <ng-container *ngIf="nuvaFarmacia == true then esFarmacia; else nuvaMed"></ng-container>

  <ng-template #esFarmacia>

    <div class="dataUserL">
      <h2 class="dataUser__title">Hola, <span class="dataUser__titleUFarm">{{nombreUsuario}} {{apellidoUsuario}}</span></h2>
    </div>

    <div class="dataUserR">
      <figure>
        <img src="./assets/design/map.svg" alt="Map" title="Dirección">
      </figure>
      <p class="pr-3">{{nombreFarmacia}}</p>

      <figure>
        <img src="./assets/design/map.svg" alt="Map" title="Dirección">
      </figure>
      <p class="pr-3">{{domicilioUsuario}}</p>

    </div>
  </ng-template>

</div>



<ng-template #nuvaMed>

  <div *ngIf="esTurnea"  [ngClass]="{'menu-bar-turnea': esTurnea, 'dataUserL': !esTurnea}">
    <div class="left">
    <!-- <button class="menubar-btn" id="menu-btn" (click)="nuvalid()">

      <img src="./assets/design/arrowOpenNavTurnea.svg" alt="MenuBurger" id="MenuBurger">

    </button> -->


      <button class="menubar-btn" id="menu-btn" (click)="nuvalid()">
        <!-- <fa-icon [icon]='["fas", "bars"]'></fa-icon> -->
        <img src="./assets/design/arrowOpenNavTurnea.svg" alt="MenuBurger" id="MenuBurger">

      </button>
      <div class="container__DataUser">
        <h2 class="dataUser__title">Ir a Nuvalid</h2>
      </div>
    </div>


  </div>

  <div class="dataUserL" *ngIf="!esTurnea">
    <h2 class="dataUser__title">Hola, <span class="dataUser__titleU">{{nombreUsuario}} {{apellidoUsuario}}</span></h2>
  </div>

  <div class="dataUserR" *ngIf="!esTurnea">
    <figure>
      <img src="./assets/design/map.svg" alt="Map" title="Dirección">
    </figure>
    <p class="pr-3">{{domicilioUsuario}}</p>
  </div>

</ng-template>
