<div class="container-prestacion">
  <app-datos-atencion-request></app-datos-atencion-request>
</div>


<div style="width: 100%; padding-right: 1em;">

  <div class="row">

    <!-- Nro de ticket -->
    <div class="col">
      <app-datos-nro-referencia-request [nOrden]="true"></app-datos-nro-referencia-request>
    </div>

    <!-- Motivo fin del tratamiento -->
    <div class="col" style="margin-top: 15px;">
      <app-datos-cod-finalizacion-tratamiento></app-datos-cod-finalizacion-tratamiento>
    </div>
  </div>

</div>



<!-- <form [formGroup]="form" class="form__diagnostico">
        <div class="form-infoN">
            <label>N° Ticket <span class="span-required">*</span></label>
            <input id="NroTicket" maxlength="10" autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off"
                type="text" class="form-controlN" formControlName="NroTicket">
        </div>
    </form> -->
