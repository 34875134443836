import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { setStepMonodrogaRequest, unsetMonodrogaRequest, unsetStepMonodrogaRequest } from '@store/actions';
import { NumeroStepModel } from '@models/datosDispensa.models';

@Component({
  selector: 'app-datos-monodroga-request',
  templateUrl: './datos-monodroga-request.component.html',
  styleUrls: ['./datos-monodroga-request.component.scss']
})
export class DatosMonodrogaRequestComponent implements OnInit {
  subscriptionStore: Subscription = new Subscription();
  subscriptionDatosInicioStore: Subscription = new Subscription();
  step: NumeroStepModel = {numero: '1', direccion: 'prev'};
  credencialSelecionada: string | undefined;
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.subscriptionStore = this.store
    .select('datosDispensa')
    .subscribe((datos:any) => {
      this.step = datos.stepMonodrogaRequest || '1';
    });

    this.subscriptionDatosInicioStore = this.store
      .select('datosInicio')
      .subscribe(async (datos) => {
        this.credencialSelecionada = datos.CredencialRequest?.NumeroCredencial;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionStore.unsubscribe();
  }

  closeModal() {
    this.store.dispatch(new unsetStepMonodrogaRequest());
    this.store.dispatch(new unsetMonodrogaRequest());
    this.modalService.dismissAll();
  }

  cambiarStep() {
    //let num = this.step.numero;
    this.step = {numero: (Number(this.step.numero)-1)+'', direccion: 'prev'};
    this.store.dispatch(new setStepMonodrogaRequest(this.step));
    /*
    if(num !== '3') {
      this.store.dispatch(new unsetMonodrogaRequest());
    }
    */
  }
}
