import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-info-financiadores',
  templateUrl: './info-financiadores.component.html',
  styleUrls: ['./info-financiadores.component.scss'],
})
export class InfoFinanciadoresComponent implements OnInit {
  titulo: string = 'Financiadores de prestador';
  financiadores: Array<any> = [];
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  devolverRespuesta() {
    this.activeModal.close();
  }
}
