import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@store/app.reducers';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { version } from '../../../../package.json';
import { removeDatosUsuario } from '@store/actions';
import { GlobalService } from '@services/global.service';
import { UsuarioActualModel } from '@models/login.models';
import { Router } from '@angular/router';
import { setUsuarioActual } from '../../store/actions/configuracion.action';

declare const window: any;

@Component({
  selector: 'app-navbar-windows',
  templateUrl: './navbar-windows.component.html',
  styleUrls: ['./navbar-windows.component.scss']
})
export class NavbarWindowsComponent implements OnInit {
  isLogin = false;
  mostrandoMenu:boolean=true;
  private subscriptionStore: Subscription = new Subscription();
  nombreUsuario: string | null = null;
  apellidoUsuario: string | null = null;
  domicilioUsuario: string | null = null;
  versionActual: string = version;
  userActual!: UsuarioActualModel;
  esTurnea!: boolean;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    public globalService:GlobalService,
    public router: Router
  ) { }

  get isElectron(): boolean {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      // Electron-specific code
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.subscriptionStore = this.store
      .select('configuracion')
      //.pipe(take(1))
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.userActual = configuracionState!.usuarioActual!;
          this.esTurnea! = configuracionState.usuarioActual.esTurnea;
          //Si habian datos de un usuario que se habia logeado anteriormente
          this.authService.esTokenValido().subscribe(
            (respuesta) => {
              if (respuesta.token) {
                this.isLogin = true;
              } else {
                this.isLogin = true;
              }
              this.nombreUsuario = configuracionState.usuarioActual!.nombre;
              this.apellidoUsuario = configuracionState.usuarioActual!.apellido;
              this.domicilioUsuario = configuracionState.usuarioActual!.domicilio;
            }
          );
        } else {
          this.nombreUsuario = null;
          this.apellidoUsuario = null;
          this.domicilioUsuario = null;
        }
      });

  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  minimizarAplicacion() {
    window.api.electronIpcSend('minimizarAplicacion');
  }

  maximizarAplicacion() {
    window.api.electronIpcSend('maximizarAplicacion');
  }

  cerrarAplicacion(){
    window.api.electronIpcSend('cerrarAplicacion');
  }

  salir() {
    this.store.dispatch(new removeDatosUsuario());
  }

  nuvalid() {
    this.store.dispatch(new setUsuarioActual({...this.userActual,esTurnea: false}));
    this.router.navigate(['inicio']);
  }


  cerrarMenu(){

    this.globalService.cerrarMenu();
    this.mostrandoMenu=false;
  }
  abrirMenu(){

    this.globalService.abrirMenu();
    this.mostrandoMenu=true;

  }
}
