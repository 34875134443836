import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { borrarDatosInstalacion } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navegation',
  templateUrl: './navegation.component.html',
  styleUrls: ['./navegation.component.scss'],
})
export class NavegationComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();

  cargando: boolean = false;

  volverALogin: boolean = false;
  mostrarSiguiente: boolean = true;

  @Input() nombreBoton: string = 'Continuar';
  @Input() volverAtrasAuth: boolean = false;
  @Input() volverALoginAuth: boolean = false;
  @Input() seleccionar: boolean = false;
  @Input() next: boolean = true;



  @Output() siguienteClick = new EventEmitter<any>();

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe((appstate: AppState) => {
      this.cargando = appstate.ui.loading;
      if (appstate.configuracion.codigosInstalacion) {
        //Si se tiene un codigo de instalacion cargado se le muestra el boton para y directamente al login
        if (appstate.configuracion.codigosInstalacion.length > 0) {
          this.volverALogin = true;
        }
      }
      if (this.router.url == '/instalacion/seleccionarLugarAtencion') {
        console.log('URL correcta');
          this.mostrarSiguiente = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  irAtras() {
    this.location.back();
    console.log('Cliqueado irAtras');
  }

  irALogin() {
    this.router.navigate(['login']);
    console.log('Cliqueado irALogin');
  }

  siguiente() {
    console.log('Cliqueado siguienteClick');
    this.siguienteClick.emit('');
  }
}
