import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DerivacionRequestModel } from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setDerivacionRequest,
  setEstadoFormDerivacionRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-datos-derivacion-request',
  templateUrl: './datos-derivacion-request.component.html',
  styleUrls: ['./datos-derivacion-request.component.scss'],
})
export class DatosDerivacionRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<DerivacionRequestModel> =
    this.formBuilder.group<DerivacionRequestModel>({
      ApellidoDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CodParaFinanciadorDerivacion: new UntypedFormControl(<ControlState<string>>null,
        [blankSpaceValidator]
      ),
      CuitDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      DireccionDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      EspecialidadDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NombreDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroMatriculaDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
        Validators.pattern('[0-9]+')
      ]),
      ProvinciaDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoPrescriptorDerivacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  especialidadesDerivacion: Array<any> = [];
  provinciasDerivacion: Array<any> = [];
  tipoMatriculasDerivacion: Array<any> = [];
  codigoTransaccion: string | null = null;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();

    this.listenCambios();
    this.addValidators();
  }

  addValidators(){



    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02B':
           this.form.controls['NombreDerivacion'].setValidators(
             Validators.required
           );
           this.form.controls['NroMatriculaDerivacion'].setValidators([
             Validators.required,
             Validators.pattern('[0-9]+')

           ]);
           this.store.dispatch(new setEstadoFormDerivacionRequest(false));
          break;

        default:
          this.store.dispatch(new setEstadoFormDerivacionRequest(null));
          break;
      }

    }

  }
  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.provinciasDerivacion =
        datos.datosInicialesExtra.datosInicialesExtra?.provincia || [];
      this.tipoMatriculasDerivacion =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoMatricula || [];
      let especialidades =
        datos.datosInicialesExtra.datosInicialesExtra?.especialidades;
      let especialidadesUnidas = especialidades?.Bioquimica.concat(
        especialidades.Fisioterapia,
        especialidades.Kinesiologia,
        especialidades.Medicina,
        especialidades.Odontologia,
        especialidades.Psicologia
      );
      this.especialidadesDerivacion = especialidadesUnidas || [];
    });
  }

  selectEspecialidad(especialidad: any) {
    //Evento emitido desde el typeahead
    if (especialidad) {
      this.form.controls['EspecialidadDerivacion'].setValue(
        especialidad.codigo
      );
    } else {
      this.form.controls['EspecialidadDerivacion'].setValue(null);
    }
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    let derivacionRequest = new DerivacionRequestModel(
      datos.ApellidoDerivacion,
      datos.CodParaFinanciadorDerivacion,
      datos.CuitDerivacion,
      datos.DireccionDerivacion,
      datos.EspecialidadDerivacion,
      datos.NombreDerivacion,
      datos.NroMatriculaDerivacion,
      datos.ProvinciaDerivacion,
      datos.TipoPrescriptorDerivacion
    );
    this.store.dispatch(new setDerivacionRequest(derivacionRequest));
    this.store.dispatch(new setEstadoFormDerivacionRequest(this.form.valid));
  }
}
