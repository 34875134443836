<div class="container-xxxl">
  <div class="row justify-content-center p-5">
    <div class="col-12 card p-0 border-0 boxShadow">
      <div class="card-header p-0 bg-navegation rounded-top p-3 text-center pointer" style="color: white;"
        (click)="refresh()">
        Modificar usuario <fa-icon class="ml-1" [icon]='["fas", "sync"]'></fa-icon>
      </div>
      <div class="card-body rounded p-5">
        <form [formGroup]="form" class="pb-3 pl-3 pr-3" (ngSubmit)="cambiarDatos()" action="aplicaciones ">
          <div class="form-group">
            <label>Email</label>
            <input class="form-control" type="email" name="email" formControlName="email" placeholder="E-mail">
          </div>
          <div class="form-group">
            <label>Rol</label>
            <select [ngClass]="{disabled: roles.length <= 1}" formControlName="rol" class="form-select form-control">
              <option *ngFor="let rol of roles" value="{{rol}}">
                {{rol}}</option>
            </select>
          </div>

          <div class="form-group" *ngIf="form.controls['rol'].value=='Prestador'">
            <label>Prestador</label>
            <select formControlName="cuitPrestador" class="form-select form-control">
              <option *ngFor="let prestador of prestadores" value="{{prestador.cuit}}">
                {{prestador.razon_social}}</option>
            </select>
          </div>


          <div class="form-group">
            <label>Nombre</label>
            <input class="form-control" type="text " name="nombre" formControlName="nombre"
              placeholder="Ingrese su nombre">
            <small *ngIf="submitted && form.controls['nombre'].errors" class="text-danger animated fadeIn">Este campo es
              obligatorio</small>
          </div>
          <div class="form-group">
            <label>Apellido</label>
            <input class="form-control" type="text " name="apellido" formControlName="apellido"
              placeholder="Ingrese su apellido">
            <small *ngIf="submitted && form.controls['apellido'].errors" class="text-danger animated fadeIn">Este campo
              es obligatorio</small>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-4 text-left">
            <button class="btnEstiloNuvalidModificarA text-left" (click)="irAtras()" [disabled]="cargando">
              <fa-icon [icon]='["fas", "chevron-left"]' class="mr-1"></fa-icon> Atras
            </button>
          </div>
          <div class="col-4 text-center">
            <button class="btnEstiloNuvalidMedio mr-2" (click)="actualizarEmail()" [disabled]="cargando">
              Actualizar email
            </button>
            <button class="btnEstiloNuvalidMedio ml-2" style="margin-left: 10px;" (click)="actualizarContrasenna()"
              [disabled]="cargando">
              Actualizar contraseña
            </button>
          </div>
          <div class="col-4 btnModificarUsuario">
            <button *ngIf="!cargando" type="submit" class="btnEstiloNuvalidModificarA" (click)="cambiarDatos()">Modificar
              <fa-icon [icon]='["fas", "chevron-right"]'></fa-icon>
            </button>
            <button *ngIf="cargando" type="submit" class="btn btn-pcpos text-right" [disabled]="true">
              <fa-icon class="mr-1 fa-spin" [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
              Espere...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
