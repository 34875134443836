<form [formGroup]="form">

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Nombre beneficiario <span class="text-danger"
          *ngIf="form.get('NombreBeneficiario') | controlRequired">*</span> </label>
      <input type="text" class="form-control" name="nombreBeneficiario" formControlName="NombreBeneficiario"
        placeholder="Nombre beneficiario">
    </div>

    <div class="form-group col-12 col-md-6">
      <label>Apellido beneficiario <span class="text-danger"
          *ngIf="form.get('ApellidoBeneficiario') | controlRequired">*</span></label>
      <input type="text" class="form-control" name="apellidoBeneficiario" formControlName="ApellidoBeneficiario"
        placeholder="Apellido del beneficiario">
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Dirección del beneficiario <span class="text-danger"
          *ngIf="form.get('Direccion') | controlRequired">*</span></label>
      <input type="text" class="form-control" name="direccionBeneficiario" formControlName="Direccion"
        placeholder="Direccion del beneficiario">
    </div>

    <div class="form-group col-12 col-md-6">
      <label>Email del beneficiario <span class="text-danger"
          *ngIf="form.get('Email') | controlRequired">*</span></label>
      <input type="text" class="form-control" name="emailBeneficiario" formControlName="Email"
        placeholder="Email del beneficiario">
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Parentesco del beneficiario <span class="text-danger"
          *ngIf="form.get('Parentesco') | controlRequired">*</span></label>
      <select class="form-control" formControlName="Parentesco">
        <option [value]="tipoParentesco.codigo" *ngFor="let tipoParentesco of tiposParentescos">
          {{tipoParentesco.nombre}}
        </option>
      </select>
    </div>

    <div class="form-group col-12 col-md-6">
      <label>Telefono del beneficiario <span class="text-danger"
          *ngIf="form.get('Telefono') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="Telefono" placeholder="Telefono del beneficiario">
    </div>
  </div>


  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Tipo documento del beneficiario <span class="text-danger"
          *ngIf="form.get('TipoDocBeneficiario') | controlRequired">*</span></label>
      <select class="form-control" formControlName="TipoDocBeneficiario">
        <option [value]="tipoDocumento.codigo" *ngFor="let tipoDocumento of tiposDocumentos">
          {{tipoDocumento.nombre}}
        </option>
      </select>
    </div>

    <div class="form-group col-12 col-md-6">
      <label>Documento del beneficiario <span class="text-danger"
          *ngIf="form.get('NroDocBeneficiario') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="NroDocBeneficiario"
        placeholder="Número de documento del beneficiario">
    </div>

  </div>
  
</form>
