import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { InputPrestacionesComponent } from '@components/input-prestaciones/input-prestaciones.component';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DetalleProcedimientoRequestModel } from '@models/datosTransaccion.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { DialogsService } from '@services/dialogs.service';
import { TransaccionesService } from '@services/transacciones.service';
import { ActivarLoading, DesactivarLoading, setDetallesProcedimientosRequest, setEstadoFormDetallesProcedimientosRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { datosInicioState, recetasState } from '@store/models/store.models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-informar-prestacion-rescate',
  templateUrl: './informar-prestacion-rescate.component.html',
  styleUrls: ['./informar-prestacion-rescate.component.scss']
})
export class InformarPrestacionRescateComponent implements OnInit {

  @ViewChild(InputPrestacionesComponent)
  inputPrestacionesComponent!: InputPrestacionesComponent;
  submitted: boolean = false;
  @ViewChild("cantidadSolicitada")
  inputCantidadSolicitada!: ElementRef;
  indexDetalleProcediminetoAEditar: number | null = null;
  errorLength = false;
  detallesProcediminetos: Array<DetalleProcedimientoRequestModel> = [];
  tiposPrestaciones: Array<any> = [];
  tiposAranceles: Array<any> = [];

  private subscriptionStore: Subscription = new Subscription();

  datosInicio: datosInicioState | null = null;
  datosReceta: recetasState | null = null;
  rescateReceta: boolean = false;

  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<DetalleProcedimientoRequestModel> =
    this.formBuilder.group<DetalleProcedimientoRequestModel>({
      NroItem: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CodPrestacion: new UntypedFormControl(<ControlState<string>>{ value: null, disabled: true }, [
        blankSpaceValidator,
      ]),
      CodAlternativo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      PiezaDental: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CaraDental: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoPrestacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      ArancelPrestacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CantidadSolicitada: new UntypedFormControl(<ControlState<string>>'1' || null, [
        Validators.required,
        blankSpaceValidator,
        Validators.pattern('[0-9]+'),
      ]),
      CodDiagnosticoPrestacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CodRta: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      DescripcionPrestacion: new UntypedFormControl({ value: null, disabled: true }),
      Facturacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  constructor(
    private store: Store<AppState>,
    private dialogsService: DialogsService,
    private transaccionesService: TransaccionesService
  ) { }

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe((datos) => {
      if (datos.recetas != null) {
        this.rescateReceta = true;
        this.datosReceta = datos.recetas;
        this.datosInicio = datos.datosInicio;
        this.detallesProcediminetos =
          datos.datosTransaccion.DetalleProcedimientoRequest || [];
        this.tiposPrestaciones =
          datos.datosInicialesExtra.datosInicialesExtra?.tipoPrestacion || [];
        this.tiposAranceles =
          datos.datosInicialesExtra.datosInicialesExtra?.tipoArancel || [];
      }
    });

    if (this.rescateReceta) {
      this.getRecipeData();
    }
  }

  async getRecipeData() {
    try {
      this.store.dispatch(new ActivarLoading());
      this.dialogsService.cargando('Obteniendo datos de la receta, por favor espere.');
      let resp = await this.txRescateReceta();
      if (resp) {
        this.store.dispatch(new DesactivarLoading());
        this.dialogsService.cerrar();
        console.log('resp', resp);

        // Mapear los ítems de la receta a DetalleProcedimientoRequestModel
        const detallesReceta = resp.Mensaje.Recetas.Receta[0].DetalleReceta.Item.map((item: any) => {
          return new DetalleProcedimientoRequestModel(
            item.Nomenclador,
            item.Codigo,
            '',
            '',
            '',
            '1',
            '0',
            item.Cantidad,
            '',
            '',
            item.Descripcion,
            ''
          );
        });

        console.log('detallesReceta', detallesReceta);

        // Actualizar el estado con los detalles de la receta
        this.detallesProcediminetos = detallesReceta;
        this.store.dispatch(new setDetallesProcedimientosRequest(detallesReceta));
        console.log('rescate de una sola receta', resp);
      }
    } catch (error) {
      //TODO Mensaje de error para si no hay respuesta
      console.error(error);
      this.store.dispatch(new DesactivarLoading());
      this.dialogsService.cerrar();
    }
  }



  txRescateReceta() {
    return this.transaccionesService.rescatarReceta(this.datosInicio!, this.datosReceta!)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        this.dialogsService.error(error.status.toString(), error.error.message);
      });
  }

  setDefaultValues() {
    let prestacion = this.tiposPrestaciones.find(
      (x) => x.nombre == 'Ambulatoria'
    );
    this.form.controls['TipoPrestacion'].setValue(prestacion?.codigo || null);

    let arancel = this.tiposAranceles.find(
      (x) => x.nombre == 'Todos los honorarios que correspondan a la prestación'
    );
    this.form.controls['ArancelPrestacion'].setValue(arancel?.codigo || null);
  }

  guardar() {
    this.submitted = true;
    if (!this.form.controls['CodPrestacion'].value?.length) {
      this.errorLength = true;
    }
    if (this.form.invalid || !this.form.controls['CodPrestacion'].value?.length) {
      this.dialogsService.toastError('Los datos ingresados son incorrectos');
      return;
    }
    let datos = this.form.getRawValue();
    let detalleProcedimiento = new DetalleProcedimientoRequestModel(
      datos.NroItem,
      datos.CodPrestacion,
      datos.CodAlternativo,
      datos.PiezaDental,
      datos.CaraDental,
      datos.TipoPrestacion,
      datos.ArancelPrestacion,
      datos.CantidadSolicitada,
      datos.CodDiagnosticoPrestacion,
      datos.CodRta,
      datos.DescripcionPrestacion,
      datos.Facturacion
    );

    if (this.indexDetalleProcediminetoAEditar != null) {
      //Se esta modificando
      let detallesProcedimientos: Array<DetalleProcedimientoRequestModel> = [
        ...this.detallesProcediminetos,
      ];
      detallesProcedimientos[this.indexDetalleProcediminetoAEditar] =
        detalleProcedimiento;
      this.store.dispatch(
        new setDetallesProcedimientosRequest(detallesProcedimientos)
      );
      this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
    } else {
      //Se esta creando
      this.store.dispatch(
        new setDetallesProcedimientosRequest([
          ...this.detallesProcediminetos,
          detalleProcedimiento,
        ])
      );
      this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
    }
    this.reset();
  }

  reset() {
    this.form.reset();
    this.inputPrestacionesComponent.clean();
    this.setDefaultValues();
    this.indexDetalleProcediminetoAEditar = null;
    this.submitted = false;
    this.inputPrestacionesComponent.focus();
  }

  eliminar(index: number) {
    let detallesProcedimientos: Array<DetalleProcedimientoRequestModel> = [
      ...this.detallesProcediminetos!,
    ];
    detallesProcedimientos.splice(index, 1);
    this.store.dispatch(
      new setDetallesProcedimientosRequest(detallesProcedimientos)
    );
    if (detallesProcedimientos.length > 0) {
      this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
    } else {
      this.store.dispatch(
        new setEstadoFormDetallesProcedimientosRequest(false)
      );
    }
  }



}
