import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filteredMarkers } from '@models/global.models';
import { lugarAtencion } from '@models/lugarAtencion.model';

@Component({
  selector: 'app-radiocheckbox',
  templateUrl: './radiocheckbox.component.html',
  styleUrls: ['./radiocheckbox.component.scss'],
})
export class RadiocheckboxComponent implements OnInit {
  @Input() lugar: lugarAtencion | null = null;
  @Input() checked: boolean = false;
  @Output() seleccionarLugar = new EventEmitter<any>();


  constructor() {}

  ngOnInit(): void {

  }

  selectDireccion() {
    this.seleccionarLugar.emit();
  }
}
