import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MonodrogaRequestModel, MedicamentoRequestModel } from '@models/datosDispensa.models';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { DispensaaService } from '@services/dispensaa.service';
import { AppState } from '@store/app.reducers';
import { Store } from '@ngrx/store';
import { setStepMonodrogaRequest, setMonodrogaRequest, setDetallesMedicamentosRequest, setEstadoFormDetallesMedicamentosRequest, unsetMonodrogaRequest, unsetStepMonodrogaRequest } from '@store/actions';
import { NumeroStepModel } from '@models/datosDispensa.models';
import { fromEvent } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetalleMedicamentosRequestModel } from '@models/datosTransaccion.models';

@Component({
  selector: 'app-input-monodroga',
  templateUrl: './input-monodroga.component.html',
  styleUrls: ['./input-monodroga.component.scss']
})
export class InputMonodrogaComponent implements OnInit {
  //model: Monodrogas | null = null;
  @ViewChild('monodrogaSearchInput', { static: true }) monodrogaSearchInput!: ElementRef;
  @ViewChild('medicamentoSearchInput', { static: true }) medicamentoSearchInput!: ElementRef;
  searchText = '';
  searchTextMedicamentos = '';
  apiResponse: any;
  isSearching: boolean;
  isCodigoDroga: boolean = false;

  arrayMonodrogas: Array<MonodrogaRequestModel> = [];
  arrayMonodrogasDetalles: Array<MonodrogaRequestModel> = [];
  arrayPresentacionesDetalles: Array<MedicamentoRequestModel> = [];

  subscriptionStore: Subscription = new Subscription();
  subscriptionStoreMedicamentos: Subscription = new Subscription();
  step: NumeroStepModel = { numero: '1', direccion: 'next' };
  arrayDetalleMedicamentos: Array<DetalleMedicamentosRequestModel> = [];

  generico: boolean = false;
  // buttonText: string = 'Comercial';

  comercialClicked: boolean = true;
  genericoClicked: boolean = false;

  constructor(
    private dispensaaService: DispensaaService,
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {
    this.isSearching = false;
    this.store.dispatch(new setStepMonodrogaRequest(this.step));
    // PRIMERA BUSQUEDA DE MONODROGAS
    this.getMonodrogas({ operacion: 'ranking' });
  }

  async ngOnInit() {
    this.subscriptionStore = this.store
      .select('datosDispensa')
      .subscribe((datos: any) => {
        this.step = datos.stepMonodrogaRequest || '1';
        this.searchText = (this.step.numero === '2') ? this.searchText : '';

        if (this.step.numero === '2' && this.step.direccion === 'prev') {
          this.siguiente('2', datos.monodrogaRequest);
        }

        this.subscriptionStoreMedicamentos = this.store.select('datosTransaccion').subscribe((datos) => {
          this.arrayDetalleMedicamentos =
            datos.DetalleMedicamentosRequest || [];
        });
      });

    // Campo de búsqueda para monodrogas
    fromEvent(this.monodrogaSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((text: string) => {
      this.isSearching = true;
      this.store.dispatch(new setStepMonodrogaRequest({ numero: '2', direccion: 'next' }));
      let param = { operacion: 'monodrogas', monodroga: text };
      this.dispensaaService.getMonoDroga(param).subscribe((res: any) => {
        this.isSearching = false;
        this.arrayMonodrogasDetalles = res;
      }, (err: any) => {
        this.isSearching = false;
        console.log('error', err);
      });
    });

    // Campo de búsqueda para medicamentos
    fromEvent(this.medicamentoSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe((text: string) => {
      this.store.dispatch(new setStepMonodrogaRequest({ numero: '3', direccion: 'next' }));
      this.isSearching = true;
      let param = { operacion: 'medicamentos', monodroga: text }; //monodroga para mandar la descripcion
      this.dispensaaService.getMonoDroga(param).subscribe((res: any) => {
        this.isSearching = false;
        this.arrayPresentacionesDetalles = res;
        // this.searchTextMedicamentos = '';
      }, (err: any) => {
        this.isSearching = false;
        console.log('error', err);
      });
    });
  }

  prescribirComercial() {
    if (!this.comercialClicked) {
      this.comercialClicked = true;
      this.genericoClicked = false;
      this.generico = false;
    }
  }

  prescribirGenerico() {
    if (!this.genericoClicked) {
      this.comercialClicked = false;
      this.genericoClicked = true;
      this.generico = true;
    }
  }

  // prescribirGenerico() {
  //   if (this.buttonText === 'Comercial') {
  //     this.generico = true;
  //     this.buttonText = 'Generico'
  //   } else {
  //     this.generico = false;
  //     this.buttonText = 'Comercial'
  //   }
  // }

  searchGetCall(term: string) {
    if (term === '') {
      return of([]);
    }
    let param = { operacion: 'monodrogas', descripcion: term };
    return this.getMonodrogas(param);
  }

  ngOnDestroy(): void {
    this.subscriptionStore.unsubscribe();
    this.store.dispatch(new unsetStepMonodrogaRequest());
    this.store.dispatch(new unsetMonodrogaRequest());
  }

  agregarMonodroga(monodroga: MonodrogaRequestModel) {
    let monodrogaItem: DetalleMedicamentosRequestModel = {
      cantidad_prescripta: '1',
      droga_codigo: monodroga.codigo_monodroga!,
      droga_descripcion: '',
      generico_presentacion: monodroga.recetas!,
      unidades: monodroga.unidades!,
      nro_linea: '' + (this.arrayDetalleMedicamentos.length + 1),
      laboratorio: '',
      accion: '',
      posologia: '',
      precauciones: '',
      items: [{
        cod_barras: '',
        cod_troquel: '',
        descripcion: monodroga.descripcion,
        estado: '0',
        item_nro: '1'
      }],
    }
    console.log('AGREGAR MONODROGA', monodrogaItem);
    this.store.dispatch(new setEstadoFormDetallesMedicamentosRequest(true));
    this.store.dispatch(new setDetallesMedicamentosRequest([...this.arrayDetalleMedicamentos, monodrogaItem]));
    this.modalService.dismissAll();
  }

  agregarMedicamento(medicamento: any) {
    let medicamentoItem: DetalleMedicamentosRequestModel = {
      cantidad_prescripta: '1',
      droga_codigo: medicamento.codigo_droga,
      droga_descripcion: medicamento.monodroga,
      generico_presentacion: medicamento.presentacion,
      unidades: medicamento.unidades,
      nro_linea: '' + (this.arrayDetalleMedicamentos.length + 1),
      laboratorio: medicamento.laboratorio,
      accion: medicamento.accion,
      posologia: '',
      precauciones: '',
      items: [{
        cod_barras: medicamento.codigo_barra,
        cod_troquel: medicamento.troquel,
        descripcion: medicamento.descripcion + ' ' + medicamento.presentacion,
        estado: '0',
        item_nro: '1'
      }],
    }
    this.store.dispatch(new setEstadoFormDetallesMedicamentosRequest(true));
    this.store.dispatch(new setDetallesMedicamentosRequest([...this.arrayDetalleMedicamentos, medicamentoItem]));
    this.modalService.dismissAll();
  }

  setMonodrogaEspecifica(monodroga: any) {
    this.isCodigoDroga = true;
    this.siguiente('2', { descripcion: monodroga.monodroga, codigo_droga: monodroga.codigo_monodroga });
  }

  siguiente(numero: string, monodroga: any = {}) {
    this.step = { numero, direccion: 'next' };
    this.store.dispatch(new setStepMonodrogaRequest(this.step));

    if (this.step.numero === '2') {
      if (this.isCodigoDroga) { // manganeta por disparidad de nombres codigo_droga / codigo_monodroga
        this.store.dispatch(new setMonodrogaRequest(monodroga));
        let param = { operacion: 'monodrogas', codigo_droga: monodroga.codigo_droga };
        this.isSearching = true;
        this.getMonodrogas(param);
        this.searchText = monodroga.descripcion.toUpperCase();
        this.isCodigoDroga = false;
      } else {
        this.store.dispatch(new setMonodrogaRequest(monodroga));
        let param = { operacion: 'monodrogas', codigo_droga: monodroga.codigo_monodroga };
        this.isSearching = true;
        this.getMonodrogas(param);
        this.searchText = monodroga.descripcion.toUpperCase();
      }
    } else if (this.step.numero === '3') {
      this.store.dispatch(new setMonodrogaRequest({ descripcion: monodroga.descripcion, codigo_droga: monodroga.codigo_droga }));
      let param = { operacion: 'medicamentos', codigo_droga: monodroga.codigo_monodroga, presentacion: monodroga.recetas };
      this.isSearching = true;
      this.getMonodrogas(param);
    }
  }

  getMonodrogas(param: any) {
    this.arrayMonodrogasDetalles = [];
    this.arrayPresentacionesDetalles = [];
    this.dispensaaService.getMonoDroga(param).subscribe((result: any) => {
      if (param.operacion === 'ranking') {
        this.arrayMonodrogas = result || [];
      } else if (param.operacion === 'monodrogas') {
        this.isSearching = false;
        this.arrayMonodrogasDetalles = result;
      } else {
        this.isSearching = false;
        this.arrayPresentacionesDetalles = result;
      }
    }, (err: any) => {
      this.isSearching = false;
      console.log('error', err);
    });
  }

  cambiarStep() {
    //let num = this.step.numero;
    this.step = { numero: (Number(this.step.numero) - 1) + '', direccion: 'prev' };
    this.store.dispatch(new setStepMonodrogaRequest(this.step));
  }

  closeModal() {
    this.store.dispatch(new unsetStepMonodrogaRequest());
    this.store.dispatch(new unsetMonodrogaRequest());
    this.modalService.dismissAll();
  }

}
