<router-outlet></router-outlet>
<!-- <div *ngIf="mostrarChatAsistente" class="asistentechat card  bg-navegation boxShadow animated fadeInUp">
    <div class="card-header">
        <div class="row" style="color: white;">
            <div class="col-6 text-left">
                <p class="m-0">Asistencia virtual</p>
            </div>
            <div class="col-6 text-right">
                <fa-icon class="mr-1 fa-spin pointer" [icon]='["fas", "times"]' (click)="toggleAsistente(false)">
                </fa-icon>
            </div>
        </div>
    </div>
    <div class="card-body bg-white rounded-bottom">
        <p>Hola, soy colo tu asistente virtual. ¿En qué puedo ayudarte?</p>
    </div>
</div>

<div class="asistente">
    <img src="./assets/img/ColoHelp.svg" alt="Asistente" class="pointer" (click)=toggleAsistente(true)>
</div> -->
