import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DownloadFiltrosModel } from '@models/ticket.models';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { BsLocaleService, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { TicketService } from '@services/ticket.service';
import { Financiador } from '@models/financiador.model';
import { Prestador } from '@models/prestador.model';
import { Subscription } from 'rxjs';
import { AppState } from '@store/app.reducers';
import { Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogsService } from '@services/dialogs.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';

@Component({
  selector: 'app-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent implements OnInit {

  private subscriptionStore: Subscription = new Subscription();

  finanDownload: Array<Financiador> = [];
  prestadores: Array<Prestador> = [];

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<DownloadFiltrosModel> =
    this.formBuilder.group<DownloadFiltrosModel>({
      FechaDesde: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator
      ]),
      FechaHasta: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator
      ]),
      Financiador: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator,
      ]),
      Prestador: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator,
      ]),
      TxtFormat: new UntypedFormControl(<ControlState<boolean>>false),
      CsvFormat: new UntypedFormControl(<ControlState<boolean>>false),
    });

  fd: any = null;
  fh: any = null;
  fchDesdeDescarga: string = '';
  fchHastaDescarga: string = '';
  prestaSelected: string = '';
  dateTodayFormated: string = '';


  constructor(
    public activeModal: NgbActiveModal,
    private localeService: BsLocaleService,
    private store: Store<AppState>,
    private ticketService: TicketService,
    private dialogsService: DialogsService
  ) {
    defineLocale('es', esLocale);
  }

  ngOnInit(): void {
    this.localeService.use('es');
    this.setDefaultValues();
    this.getFinanciadores();
    this.getPrestadores();
  }

  setDefaultValues() {
    this.fd = new Date();
    //setea fecha 1 mes atrás
    this.fd.setMonth(this.fd.getMonth() - 1);
    this.form.controls['FechaDesde'].setValue(this.fd);
    this.form.controls['FechaDesde'].setValidators(Validators.required);

    this.fh = new Date();
    //setea fecha de hoy
    this.fh.setDate(this.fh.getDate());
    this.form.controls['FechaHasta'].setValue(this.fh);
    this.form.controls['FechaHasta'].setValidators(Validators.required);
  }

  getFinanciadores() {
    this.ticketService.getFinanciadoresTickets().subscribe(
      (resp) => {
        this.finanDownload = resp
        this.finanDownload = (this.finanDownload ?? []).filter(financiador => financiador.sigla === 'OSDE' || financiador.cuit === '30546741253');

      }
    )
  }

  getPrestadores() {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((datos) => {
        this.prestadores = datos.datosIniciales?.prestadores!
      });
  }

  minDateDesde() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3);
    return currentDate;
  }

  onCheckboxChange(checkboxName: string) {
    if (checkboxName === 'TxtFormat' && this.form.get('TxtFormat')?.value) {
      this.form.get('CsvFormat')?.setValue(false);
    } else if (checkboxName === 'CsvFormat' && this.form.get('CsvFormat')?.value) {
      this.form.get('TxtFormat')?.setValue(false);
    }
  }


  downloadFile() {
    this.store.dispatch(new ActivarLoading());
    this.dialogsService.cargando('Descargando, por favor espere.');

    let datos = this.form.getRawValue();

    //Convierto la fecha en YYYYmmdd
    let datefd = new Date('' + datos.FechaDesde);
    let mnthfd = ("0" + (datefd.getMonth() + 1)).slice(-2);
    let dayfd = ("0" + datefd.getDate()).slice(-2);
    let fechaDesde = [datefd.getFullYear(), mnthfd, dayfd].join("");
    this.fchDesdeDescarga = fechaDesde
    console.log('fechaDesde', this.fchDesdeDescarga);

    //Convierto la fecha en YYYYmmdd
    let datefh = new Date('' + datos.FechaHasta);
    let mnthfh = ("0" + (datefh.getMonth() + 1)).slice(-2);
    let dayfh = ("0" + datefh.getDate()).slice(-2);
    let fechaHasta = [datefh.getFullYear(), mnthfh, dayfh].join("");
    this.fchHastaDescarga = fechaHasta
    console.log('fechaHasta', this.fchHastaDescarga);

    const txtFormat = this.form.get('TxtFormat')?.value;
    const csvFormat = this.form.get('CsvFormat')?.value;

    let datetd = new Date();
    let mnthtd = ("0" + (datetd.getMonth() + 1)).slice(-2);
    let daytd = ("0" + datetd.getDate()).slice(-2);
    let formatedTd = [datetd.getFullYear(), mnthtd, daytd].join("");
    this.dateTodayFormated = formatedTd
    console.log('formatedTd', this.dateTodayFormated);

    this.prestaSelected = datos.Prestador!

    if (!this.fchDesdeDescarga || !this.fchHastaDescarga) {
      this.dialogsService.toastError('No se seleccionaron los datos necesarios para descargar el archivo');
      this.store.dispatch(new DesactivarLoading());
      this.dialogsService.cerrar();
      return;
    }

    this.ticketService.getPrestacionesTransacciones(
      this.fchDesdeDescarga,
      this.fchHastaDescarga,
      this.prestaSelected,
      txtFormat ? 'txt' : csvFormat ? 'csv' : ''
    ).subscribe((resp) => {

      this.store.dispatch(new DesactivarLoading());
      this.dialogsService.cerrar();

      const url = window.URL.createObjectURL(resp);
      const link = document.createElement('a');
      link.href = url;

      // Establecer el nombre del archivo
      link.setAttribute('download', `ReporteFMX${this.dateTodayFormated}.${txtFormat ? 'txt' : csvFormat ? 'csv' : ''}`);
      // Forzar el clic en el enlace para iniciar la descarga
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      this.dialogsService.toastSuccess('Archivo descargado con éxito!');
      this.devolverRespuesta('CERRAR');
    }, (error) => {
      this.dialogsService.cerrar();
      this.dialogsService.toastError(
        'Se produjo un error, vuelva a intentarlo más tarde'
      );
    });
  }

  devolverRespuesta(accion: string) {
    this.activeModal.close({ respuesta: accion });
  }

}
