//CIE-10 
export const diagnosticosCIE_10 = [
    { codigo: 'A00', descripcion: 'COLERA'},
    { codigo: 'A000', descripcion: 'COLERA DEBIDO A VIBRIO CHOLERAE O1, BIOTIPO CHOLERAE'},
    { codigo: 'A001', descripcion: 'COLERA DEBIDO A VIBRIO CHOLERAE O1, BIOTIPO EL TOR'},
    { codigo: 'A009', descripcion: 'COLERA, NO ESPECIFICADO'},
    { codigo: 'A01', descripcion: 'FIEBRES TIFOIDEA Y PARATIFOIDEA'},
    { codigo: 'A010', descripcion: 'FIEBRE TIFOIDEA'},
    { codigo: 'A011', descripcion: 'FIEBRE PARATIFOIDEA A'},
    { codigo: 'A012', descripcion: 'FIEBRE PARATIFOIDEA B'},
    { codigo: 'A013', descripcion: 'FIEBRE PARATIFOIDEA C'},
    { codigo: 'A014', descripcion: 'FIEBRE PARATIFOIDEA, NO ESPECIFICADA'},
    { codigo: 'A02', descripcion: 'OTRAS INFECCIONES DEBIDAS A SALMONELLA'},
    { codigo: 'A020', descripcion: 'ENTERITIS DEBIDA A SALMONELLA'},
    { codigo: 'A021', descripcion: 'SEPTICEMIA DEBIDA A SALMONELLA'},
    { codigo: 'A022', descripcion: 'INFECCIONES LOCALIZADAS DEBIDAS A SALMONELLA +'},
    { codigo: 'A028', descripcion: 'OTRAS INFECCIONES ESPECIFICADAS COMO DEBIDAS A SALMONELLA'},
    { codigo: 'A029', descripcion: 'INFECCION DEBIDA A SALMONELLA, NO ESPECIFICADA'},
    { codigo: 'A03', descripcion: 'SHIGELOSIS'},
    { codigo: 'A030', descripcion: 'SHIGELOSIS DEBIDA A SHIGELLA DYSENTERIAE'},
    { codigo: 'A031', descripcion: 'SHIGELOSIS DEBIDA A SHIGELLA FLEXNERI'},
    { codigo: 'A032', descripcion: 'SHIGELOSIS DEBIDA A SHIGELLA BOYDII'},
    { codigo: 'A033', descripcion: 'SHIGELOSIS DEBIDA A SHIGELLA SONNEI'},
    { codigo: 'A038', descripcion: 'OTRAS SHIGELOSIS'},
    { codigo: 'A039', descripcion: 'SHIGELOSIS DE TIPO NO ESPECIFICADO'},
    { codigo: 'A04', descripcion: 'OTRAS INFECCIONES INTESTINALES BACTERIANAS'},
    { codigo: 'A040', descripcion: 'INFECCION DEBIDA A ESCHERICHIA COLI ENTEROPATOGENA'},
    { codigo: 'A041', descripcion: 'INFECCION DEBIDA A ESCHERICHIA COLI ENTEROTOXIGENA'},
    { codigo: 'A042', descripcion: 'INFECCION DEBIDA A ESCHERICHIA COLI ENTEROINVASIVA'},
    { codigo: 'A043', descripcion: 'INFECCION DEBIDA A ESCHERICHIA COLI ENTEROHEMORRAGICA'},
    { codigo: 'A044', descripcion: 'OTRAS INFECCIONES INTESTINALES DEBIDAS A ESCHERICHIA COLI'},
    { codigo: 'A045', descripcion: 'ENTERITIS DEBIDA A CAMPYLOBACTER'},
    { codigo: 'A046', descripcion: 'ENTERITIS DEBIDA A YERSINIA ENTEROCOLITICA'},
    { codigo: 'A047', descripcion: 'ENTEROCOLITIS DEBIDA A CLOSTRIDIUM DIFFICILE'},
    { codigo: 'A048', descripcion: 'OTRAS INFECCIONES INTESTINALES BACTERIANAS ESPECIFICADAS'},
    { codigo: 'A049', descripcion: 'INFECCION INTESTINAL BACTERIANA, NO ESPECIFICADA'},
    { codigo: 'A05', descripcion: 'OTRAS INTOXICACIONES ALIMENTARIAS BACTERIANAS'},
    { codigo: 'A050', descripcion: 'INTOXICACION ALIMENTARIA ESTAFILOCOCICA'},
    { codigo: 'A051', descripcion: 'BOTULISMO'},
    { codigo: 'A052', descripcion: 'INTOXICACION ALIMENTARIA DEBIDA A CLOSTRIDIUM PERFRINGENS(CLOSTRIDIUM WELCHII)'},
    { codigo: 'A053', descripcion: 'INTOXICACION ALIMENTARIA DEBIDA A VIBRIO PARAHAEMOLYTICUS'},
    { codigo: 'A054', descripcion: 'INTOXICACION ALIMENTARIA DEBIDA A BACILLUS CEREUS'},
    { codigo: 'A058', descripcion: 'OTRAS INTOXICACIONES ALIMENTARIAS DEBIDAS A BACTERIAS ESPECIFICADAS'},
    { codigo: 'A059', descripcion: 'INTOXICACION ALIMENTARIA BACTERIANA, NO ESPECIFICADA'},
    { codigo: 'A06', descripcion: 'AMEBIASIS'},
    { codigo: 'A060', descripcion: 'DISENTERIA AMEBIANA AGUDA'},
    { codigo: 'A061', descripcion: 'AMEBIASIS INTESTINAL CRONICA'},
    { codigo: 'A062', descripcion: 'COLITIS AMEBIANA NO DISENTERICA'},
    { codigo: 'A063', descripcion: 'AMEBOMA INTESTINAL'},
    { codigo: 'A064', descripcion: 'ABSCESO AMEBIANO DEL HIGADO'},
    { codigo: 'A065', descripcion: 'ABSCESO AMEBIANO DEL PULMON (J99.8*) +'},
    { codigo: 'A066', descripcion: 'ABSCESO AMEBIANO DEL CEREBRO (G07*) +'},
    { codigo: 'A067', descripcion: 'AMEBIASIS CUTANEA'},
    { codigo: 'A068', descripcion: 'INFECCION AMEBIANA DE OTRAS LOCALIZACIONES'},
    { codigo: 'A069', descripcion: 'AMEBIASIS, NO ESPECIFICADA'},
    { codigo: 'A07', descripcion: 'OTRAS ENFERMEDADES INTESTINALES DEBIDAS A PROTOZOARIOS'},
    { codigo: 'A070', descripcion: 'BALANTIDIASIS'},
    { codigo: 'A071', descripcion: 'GIARDIASIS (LAMBLIASIS)'},
    { codigo: 'A072', descripcion: 'CRIPTOSPORIDIOSIS'},
    { codigo: 'A073', descripcion: 'ISOSPORIASIS'},
    { codigo: 'A078', descripcion: 'OTRAS ENFERMEDADES INTESTINALES ESPECIFICADAS DEBIDAS A PROTOZOARIOS'},
    { codigo: 'A079', descripcion: 'ENFERMEDAD INTESTINAL DEBIDA A PROTOZOARIOS, NO ESPECIFICADA'},
    { codigo: 'A08', descripcion: 'INFECCIONES INTESTINALES DEBIDAS A VIRUS Y OTROS ORGANISMOS ESPECIFICADOS'},
    { codigo: 'A080', descripcion: 'ENTERITIS DEBIDA A ROTAVIRUS'},
    { codigo: 'A081', descripcion: 'GASTROENTEROPATIA AGUDA DEBIDA AL AGENTE DE NORWALK'},
    { codigo: 'A082', descripcion: 'ENTERITIS DEBIDA A ADENOVIRUS'},
    { codigo: 'A083', descripcion: 'OTRAS ENTERITIS VIRALES'},
    { codigo: 'A084', descripcion: 'INFECCION INTESTINAL VIRAL, SIN OTRA ESPECIFICACION'},
    { codigo: 'A085', descripcion: 'OTRAS INFECCIONES INTESTINALES ESPECIFICADAS'},
    { codigo: 'A09', descripcion: 'DIARREA Y GASTROENTERITIS DE PRESUNTO ORIGEN INFECCIOSO'},
    { codigo: 'A15', descripcion: 'TUBERCULOSIS RESPIRATORIA CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE'},
    { codigo: 'A150', descripcion: 'TUBERCULOSIS DEL PULMON CONFIRMADA POR HALLAZGO MICROSCOPICO DEL BACILO TUBERCULOSO EN ESPUTO CON O SIN CULTIVO'},
    { codigo: 'A151', descripcion: 'TUBERCULOSIS DEL PULMON, CONFIRMADA UNICAMENTE POR CULTIVO'},
    { codigo: 'A152', descripcion: 'TUBERCULOSIS DEL PULMON, CONFIRMADA HISTOLOGICAMENTE'},
    { codigo: 'A153', descripcion: 'TUBERCULOSIS DEL PULMON, CONFIRMADA POR MEDIOS NO ESPECIFICADOS'},
    { codigo: 'A154', descripcion: 'TUBERCULOSIS DE GANGLIOS LINFATICOS INTRATORACICOS, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE'},
    { codigo: 'A155', descripcion: 'TUBERCULOSIS DE LARINGE, TRAQUEA Y BRONQUIOS, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE'},
    { codigo: 'A156', descripcion: 'PLEURESIA TUBERCULOSA, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE'},
    { codigo: 'A157', descripcion: 'TUBERCULOSIS RESPIRATORIA PRIMARIA, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE'},
    { codigo: 'A158', descripcion: 'OTRAS TUBERCULOSIS RESPIRATORIAS, CONFIRMADAS BACTERIOLOGICA E HISTOLOGICAMENTE'},
    { codigo: 'A159', descripcion: 'TUBERCULOSIS RESPIRATORIA NO ESPECIFICADA, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE'},
    { codigo: 'A16', descripcion: 'TUBERCULOSIS RESPIRATORIA, NO CONFIRMADA BACTERIOLOGICA O HISTOLOGICAMEMTE'},
    { codigo: 'A160', descripcion: 'TUBERCULOSIS DEL PULMON, CON EXAMEN BACTERIOLOGICO E HISTOLOGICO NEGATIVOS'},
    { codigo: 'A161', descripcion: 'TUBERCULOSIS DEL PULMON, SIN EXAMEN BACTERIOLOGICO E HISTOLOGICO'},
    { codigo: 'A162', descripcion: 'TUBERCULOSIS DEL PULMON, SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA'},
    { codigo: 'A163', descripcion: 'TUBERCULOSIS DE GANGLIOS LINFATICOS INTRATORACICOS SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA'},
    { codigo: 'A164', descripcion: 'TUBERCULOSIS DE LARINGE, TRAQUEA Y BRONQUIOS SIN MENCION DE CONFIRMACION BACTERIOLOGICA HISTOLOGICA'},
    { codigo: 'A165', descripcion: 'PLEURESIA TUBERCULOSA, SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA'},
    { codigo: 'A167', descripcion: 'TUBERCULOSIS RESPIRATORIA PRIMARIA, SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA'},
    { codigo: 'A168', descripcion: 'OTRAS TUBERCULOSIS RESPIRATORIAS, SIN MENCION DE CONFIRMACION'},
    { codigo: 'A169', descripcion: 'TUBERCULOSIS RESPIRATORIA NO ESPECIFICADA, SIN MENCION DE CONFIRMAC BACTERIOLOGICA O HISTOLOGICA'},
    { codigo: 'A17', descripcion: 'TUBERCULOSIS DEL SISTEMA NERVIOSO'},
    { codigo: 'A170', descripcion: 'MENINGITIS TUBERCULOSA (G01*)'},
    { codigo: 'A171', descripcion: 'TUBERCULOMA MENINGEO (G07*)'},
    { codigo: 'A178', descripcion: 'OTRAS TUBERCULOSIS DEL SISTEMA NERVIOSO'},
    { codigo: 'A179', descripcion: 'TUBERCULOSIS DEL SISTEMA NERVIOSO, NO ESPECIFICADA (G99.8*)'},
    { codigo: 'A18', descripcion: 'TUBERCULOSIS DE OTROS ORGANOS'},
    { codigo: 'A180', descripcion: 'TUBERCULOSIS DE HUESOS Y ARTICULACIONES'},
    { codigo: 'A181', descripcion: 'TUBERCULOSIS DEL APARATO GENITOURINARIO'},
    { codigo: 'A182', descripcion: 'LINFADENOPATIA PERIFERICA TUBERCULOSA'},
    { codigo: 'A183', descripcion: 'TUBERCULOSIS DE LOS INTESTINOS, EL PERITONEO Y LOS GANGLIOS MESENTERICOS'},
    { codigo: 'A184', descripcion: 'TUBERCULOSIS DE LA PIEL Y EL TEJIDO SUBCUTANEO'},
    { codigo: 'A185', descripcion: 'TUBERCULOSIS DEL OJO +'},
    { codigo: 'A186', descripcion: 'TUBERCULOSIS DEL OIDO'},
    { codigo: 'A187', descripcion: 'TUBERCULOSIS DE GLANDULAS SUPRARRENALES (E35.1*)'},
    { codigo: 'A188', descripcion: 'TUBERCULOSIS DE OTROS ORGANOS ESPECIFICADOS'},
    { codigo: 'A19', descripcion: 'TUBERCULOSIS MILIAR'},
    { codigo: 'A190', descripcion: 'TUBERCULOSIS MILIAR AGUDA DE UN SOLO SITIO ESPECIFICADO'},
    { codigo: 'A191', descripcion: 'TUBERCULOSIS MILIAR AGUDA DE SITIOS MULTIPLES'},
    { codigo: 'A192', descripcion: 'TUBERCULOSIS MILIAR AGUDA, NO ESPECIFICADA'},
    { codigo: 'A198', descripcion: 'OTRAS TUBERCULOSIS MILIARES'},
    { codigo: 'A199', descripcion: 'TUBERCULOSIS MILIAR, SIN OTRA ESPECIFICACION'},
    { codigo: 'A20', descripcion: 'PESTE'},
    { codigo: 'A200', descripcion: 'PESTE BUBONICA'},
    { codigo: 'A201', descripcion: 'PESTE CELULOCUTANEA'},
    { codigo: 'A202', descripcion: 'PESTE NEUMONICA'},
    { codigo: 'A203', descripcion: 'MENINGITIS POR PESTE'},
    { codigo: 'A207', descripcion: 'PESTE SEPTICEMICA'},
    { codigo: 'A208', descripcion: 'OTRAS FORMAS DE PESTE'},
    { codigo: 'A209', descripcion: 'PESTE, NO ESPECIFICADA'},
    { codigo: 'A21', descripcion: 'TULAREMIA'},
    { codigo: 'A210', descripcion: 'TULAREMIA ULCEROGLANDULAR'},
    { codigo: 'A211', descripcion: 'TULAREMIA OCULOGLANDULAR'},
    { codigo: 'A212', descripcion: 'TULAREMIA PULMONAR'},
    { codigo: 'A213', descripcion: 'TULAREMIA GASTROINTESTINAL'},
    { codigo: 'A217', descripcion: 'TULAREMIA GENERALIZADA'},
    { codigo: 'A218', descripcion: 'OTRAS FORMAS DE TULAREMIA'},
    { codigo: 'A219', descripcion: 'TULAREMIA, NO ESPECIFICADA'},
    { codigo: 'A22', descripcion: 'CARBUNCO (ANTRAX)'},
    { codigo: 'A220', descripcion: 'CARBUNCO CUTANEO'},
    { codigo: 'A221', descripcion: 'CARBUNCO PULMONAR'},
    { codigo: 'A222', descripcion: 'CARBUNCO GASTROINTESTINAL'},
    { codigo: 'A227', descripcion: 'CARBUNCO SEPTICEMICO'},
    { codigo: 'A228', descripcion: 'OTRAS FORMAS DE CARBUNCO'},
    { codigo: 'A229', descripcion: 'CARBUNCO, NO ESPECIFICADO'},
    { codigo: 'A23', descripcion: 'BRUCELOSIS'},
    { codigo: 'A230', descripcion: 'BRUCELOSIS DEBIDA A BRUCELLA MELITENSIS'},
    { codigo: 'A231', descripcion: 'BRUCELOSIS DEBIDA A BRUCELLA ABORTUS'},
    { codigo: 'A232', descripcion: 'BRUCELOSIS DEBIDA A BRUCELLA SUIS'},
    { codigo: 'A233', descripcion: 'BRUCELOSIS DEBIDA A BRUCELLA CANIS'},
    { codigo: 'A238', descripcion: 'OTRAS BRUCELOSIS'},
    { codigo: 'A239', descripcion: 'BRUCELOSIS, NO ESPECIFICADA'},
    { codigo: 'A24', descripcion: 'MUERMO Y MELIOIDOSIS'},
    { codigo: 'A240', descripcion: 'MUERMO'},
    { codigo: 'A241', descripcion: 'MELIOIDOSIS AGUDA Y FULMINANTE'},
    { codigo: 'A242', descripcion: 'MELIOIDOSIS SUBAGUDA Y CRONICA'},
    { codigo: 'A243', descripcion: 'OTRAS MELIOIDOSIS'},
    { codigo: 'A244', descripcion: 'MELIOIDOSIS, NO ESPECIFICADA'},
    { codigo: 'A25', descripcion: 'FIEBRES POR MORDEDURA DE RATA'},
    { codigo: 'A250', descripcion: 'ESPIRILOSIS'},
    { codigo: 'A251', descripcion: 'ESTREPTOBACILOSIS'},
    { codigo: 'A259', descripcion: 'FIEBRE POR MORDEDURA DE RATA, NO ESPECIFICADA'},
    { codigo: 'A26', descripcion: 'ERISIPELOIDE'},
    { codigo: 'A260', descripcion: 'ERISIPELOIDE CUTANEO'},
    { codigo: 'A267', descripcion: 'SEPTICEMIA POR ERYSIPELOTHRIX'},
    { codigo: 'A268', descripcion: 'OTRAS FORMAS DE ERISIPELOIDE'},
    { codigo: 'A269', descripcion: 'ERISIPELOIDE, NO ESPECIFICADA'},
    { codigo: 'A27', descripcion: 'LEPTOSPIROSIS'},
    { codigo: 'A270', descripcion: 'LEPTOSPIROSIS ICTEROHEMORRAGICA'},
    { codigo: 'A278', descripcion: 'OTRAS FORMAS DE LEPTOSPIROSIS'},
    { codigo: 'A279', descripcion: 'LEPTOSPIROSIS, NO ESPECIFICADA'},
    { codigo: 'A28', descripcion: 'OTRAS ENFERMEDADES ZOONOTICAS BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A280', descripcion: 'PASTEURELOSIS'},
    { codigo: 'A281', descripcion: 'ENFERMEDAD POR RASGU—O DE GATO'},
    { codigo: 'A282', descripcion: 'YERSINIOSIS EXTRAINTESTINAL'},
    { codigo: 'A288', descripcion: 'OTRAS ENFERMEDADES ZOONOTICAS BACTERIANAS ESPECIFICADAS, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A289', descripcion: 'ENFERMEDAD ZOONOTICA BACTERIANA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A30', descripcion: 'LEPRA (ENFERMEDAD DE HANSEN)'},
    { codigo: 'A300', descripcion: 'LEPRA INDETERMINADA'},
    { codigo: 'A301', descripcion: 'LEPRA TUBERCULOIDE'},
    { codigo: 'A302', descripcion: 'LEPRA TUBERCULOIDE LIMITROFE'},
    { codigo: 'A303', descripcion: 'LEPRA LIMITROFE'},
    { codigo: 'A304', descripcion: 'LEPRA LEPROMATOSA LIMITROFE'},
    { codigo: 'A305', descripcion: 'LEPRA LEPROMATOSA'},
    { codigo: 'A308', descripcion: 'OTRAS FORMAS DE LEPRA'},
    { codigo: 'A309', descripcion: 'LEPRA, NO ESPECIFICADA'},
    { codigo: 'A31', descripcion: 'INFECCIONES DEBIDAS A OTRAS MICOBACTERIAS'},
    { codigo: 'A310', descripcion: 'INFECCIONES POR MICOBACTERIAS PULMONARES'},
    { codigo: 'A311', descripcion: 'INFECCION CUTANEA POR MICOBACTERIAS'},
    { codigo: 'A318', descripcion: 'OTRAS INFECCIONES POR MICOBACTERIAS'},
    { codigo: 'A319', descripcion: 'INFECCION POR MICOBACTERIA, NO ESPECIFICADA'},
    { codigo: 'A32', descripcion: 'LISTERIOSIS'},
    { codigo: 'A320', descripcion: 'LISTERIOSIS CUTANEA'},
    { codigo: 'A321', descripcion: 'MENINGITIS Y MENINGOENCEFALITIS LISTERIANA +'},
    { codigo: 'A327', descripcion: 'SEPTICEMIA LISTERIANA'},
    { codigo: 'A328', descripcion: 'OTRAS FORMAS DE LISTERIOSIS'},
    { codigo: 'A329', descripcion: 'LISTERIOSIS, NO ESPECIFICADA'},
    { codigo: 'A33', descripcion: 'TETANOS NEONATAL'},
    { codigo: 'A34', descripcion: 'TETANOS OBSTETRICO'},
    { codigo: 'A35', descripcion: 'OTROS TETANOS'},
    { codigo: 'A36', descripcion: 'DIFTERIA'},
    { codigo: 'A360', descripcion: 'DIFTERIA FARINGEA'},
    { codigo: 'A361', descripcion: 'DIFTERIA NASOFARINGEA'},
    { codigo: 'A362', descripcion: 'DIFTERIA LARINGEA'},
    { codigo: 'A363', descripcion: 'DIFTERIA CUTANEA'},
    { codigo: 'A368', descripcion: 'OTRAS DIFTERIAS'},
    { codigo: 'A369', descripcion: 'DIFTERIA, NO ESPECIFICADA'},
    { codigo: 'A37', descripcion: 'TOS FERINA (TOS CONVULSIVA)'},
    { codigo: 'A370', descripcion: 'TOS FERINA DEBIDA A BORDETELLA PERTUSSIS'},
    { codigo: 'A371', descripcion: 'TOS FERINA DEBIDA A BORDETELLA PARAPERTUSSIS'},
    { codigo: 'A378', descripcion: 'TOS FERINA DEBIDA A OTRAS ESPECIES DE BORDETELLA'},
    { codigo: 'A379', descripcion: 'TOS FERINA, NO ESPECIFICADA'},
    { codigo: 'A38', descripcion: 'ESCARLATINA'},
    { codigo: 'A39', descripcion: 'INFECCION MENINGOCOCICA'},
    { codigo: 'A390', descripcion: 'MENINGITIS MENINGOCOCICA (G01*)'},
    { codigo: 'A391', descripcion: 'SINDROME DE WATERHOUSE-FRIDERICHSEN (E35.1*)'},
    { codigo: 'A392', descripcion: 'MENINGOCOCEMIA AGUDA'},
    { codigo: 'A393', descripcion: 'MENINGOCOCEMIA CRONICA'},
    { codigo: 'A394', descripcion: 'MENINGOCOCEMIA, NO ESPECIFICADA'},
    { codigo: 'A395', descripcion: 'ENFERMEDAD CARDIACA DEBIDA A MENINGOCOCO'},
    { codigo: 'A398', descripcion: 'OTRAS INFECCIONES MENINGOCOCICAS'},
    { codigo: 'A399', descripcion: 'INFECCION MENINGOCOCICA, NO ESPECIFICADA'},
    { codigo: 'A40', descripcion: 'SEPTICEMIA ESTREPTOCOCICA'},
    { codigo: 'A400', descripcion: 'SEPTICEMIA DEBIDA A ESTREPTOCOCO, GRUPO A'},
    { codigo: 'A401', descripcion: 'SEPTICEMIA DEBIDA A ESTREPTOCOCO, GRUPO B'},
    { codigo: 'A402', descripcion: 'SEPTICEMIA DEBIDA A ESTREPTOCOCO, GRUPO D'},
    { codigo: 'A403', descripcion: 'SEPTICEMIA DEBIDA A STREPTOCOCCUS PNEUMONIAE'},
    { codigo: 'A408', descripcion: 'OTRAS SEPTICEMIAS ESTREPTOCOCICAS'},
    { codigo: 'A409', descripcion: 'SEPTICEMIA ESTREPTOCOCICA, NO ESPECIFICADA'},
    { codigo: 'A41', descripcion: 'OTRAS SEPTICEMIAS'},
    { codigo: 'A410', descripcion: 'SEPTICEMIA DEBIDA A STAPHYLOCOCCUS AUREUS'},
    { codigo: 'A411', descripcion: 'SEPTICEMIA DEBIDA A OTRO ESTAFILOCOCO ESPECIFICADO'},
    { codigo: 'A412', descripcion: 'SEPTICEMIA DEBIDA A ESTAFILOCOCO NO ESPECIFICADO'},
    { codigo: 'A413', descripcion: 'SEPTICEMIA DEBIDA A HAEMOPHILUS INFLUENZAE'},
    { codigo: 'A414', descripcion: 'SEPTICEMIA DEBIDA A ANAEROBIOS'},
    { codigo: 'A415', descripcion: 'SEPTICEMIA DEBIDA A OTROS ORGANISMOS GRAMNEGATIVOS'},
    { codigo: 'A418', descripcion: 'OTRAS SEPTICEMIAS ESPECIFICADAS'},
    { codigo: 'A419', descripcion: 'SEPTICEMIA, NO ESPECIFICADA'},
    { codigo: 'A42', descripcion: 'ACTINOMICOSIS'},
    { codigo: 'A420', descripcion: 'ACTINOMICOSIS PULMONAR'},
    { codigo: 'A421', descripcion: 'ACTINOMICOSIS ABDOMINAL'},
    { codigo: 'A422', descripcion: 'ACTINOMICOSIS CERVICOFACIAL'},
    { codigo: 'A427', descripcion: 'SEPTICEMIA ACTINOMICOTICA'},
    { codigo: 'A428', descripcion: 'OTRAS FORMAS DE ACTINOMICOSIS'},
    { codigo: 'A429', descripcion: 'ACTINOMICOSIS, SIN OTRA ESPECIFICACION'},
    { codigo: 'A43', descripcion: 'NOCARDIOSIS'},
    { codigo: 'A430', descripcion: 'NOCARDIOSIS PULMONAR'},
    { codigo: 'A431', descripcion: 'NOCARDIOSIS CUTANEA'},
    { codigo: 'A438', descripcion: 'OTRAS FORMAS DE NOCARDIOSIS'},
    { codigo: 'A439', descripcion: 'NOCARDIOSIS, NO ESPECIFICADA'},
    { codigo: 'A44', descripcion: 'BARTONELOSIS'},
    { codigo: 'A440', descripcion: 'BARTONELOSIS SISTEMICA'},
    { codigo: 'A441', descripcion: 'BARTONELOSIS CUTANEA Y MUCOCUTANEA'},
    { codigo: 'A448', descripcion: 'OTRAS FORMAS DE BARTONELOSIS'},
    { codigo: 'A449', descripcion: 'BARTONELOSIS, NO ESPECIFICADA'},
    { codigo: 'A46', descripcion: 'ERISIPELA'},
    { codigo: 'A48', descripcion: 'OTRAS ENFERMEDADES BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A480', descripcion: 'GANGRENA GASEOSA'},
    { codigo: 'A481', descripcion: 'ENFERMEDAD DE LOS LEGIONARIOS'},
    { codigo: 'A482', descripcion: 'ENFERMEDAD DE LOS LEGIONARIOS NO NEUMONICA (FIEBRE DE PONTIAC)'},
    { codigo: 'A483', descripcion: 'SINDROME DEL CHOQUE TOXICO'},
    { codigo: 'A484', descripcion: 'FIEBRE PURPURICA BRASILE—A'},
    { codigo: 'A488', descripcion: 'OTRAS ENFERMEDADES BACTERIANAS ESPECIFICADAS'},
    { codigo: 'A49', descripcion: 'INFECCION BACTERIANA DE SITIO NO ESPECIFICADO'},
    { codigo: 'A490', descripcion: 'INFECCION ESTAFILOCOCICA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A491', descripcion: 'INFECCION ESTREPTOCOCICA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A492', descripcion: 'INFECCION POR HAEMOPHILUS INFLUENZAE, SIN OTRA ESPECIFICACION'},
    { codigo: 'A493', descripcion: 'INFECCION POR MICOPLASMA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A498', descripcion: 'OTRAS INFECCIONES BACTERIANAS DE SITIO NO ESPECIFICADO'},
    { codigo: 'A499', descripcion: 'INFECCION BACTERIANA, NO ESPECIFICADA'},
    { codigo: 'A50', descripcion: 'SIFILIS CONGENITA'},
    { codigo: 'A500', descripcion: 'SIFILIS CONGENITA PRECOZ, SINTOMATICA'},
    { codigo: 'A501', descripcion: 'SIFILIS CONGENITA PRECOZ, LATENTE'},
    { codigo: 'A502', descripcion: 'SIFILIS CONGENITA PRECOZ, SIN OTRA ESPECIFICACION'},
    { codigo: 'A503', descripcion: 'OCULOPATIA SIFILITICA CONGENITA TARDIA'},
    { codigo: 'A504', descripcion: 'NEUROSIFILIS CONGENITA TARDIA (NEUROSIFILIS JUVENIL)'},
    { codigo: 'A505', descripcion: 'OTRAS FORMAS DE SIFILIS CONGENITA TARDIA, SINTOMATICA'},
    { codigo: 'A506', descripcion: 'SIFILIS CONGENITA TARDIA, LATENTE'},
    { codigo: 'A507', descripcion: 'SIFILIS CONGENITA TARDIA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A509', descripcion: 'SIFILIS CONGENITA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A51', descripcion: 'SIFILIS PRECOZ'},
    { codigo: 'A510', descripcion: 'SIFILIS GENITAL PRIMARIA'},
    { codigo: 'A511', descripcion: 'SIFILIS PRIMARIA ANAL'},
    { codigo: 'A512', descripcion: 'SIFILIS PRIMARIA EN OTROS SITIOS'},
    { codigo: 'A513', descripcion: 'SIFILIS SECUNDARIA DE PIEL Y MEMBRANAS MUCOSAS'},
    { codigo: 'A514', descripcion: 'OTRAS SIFILIS SECUNDARIAS'},
    { codigo: 'A515', descripcion: 'SIFILIS PRECOZ, LATENTE'},
    { codigo: 'A519', descripcion: 'SIFILIS PRECOZ, SIN OTRA ESPECIFICACION'},
    { codigo: 'A52', descripcion: 'SIFILIS TARDIA'},
    { codigo: 'A520', descripcion: 'SIFILIS CARDIOVASCULAR'},
    { codigo: 'A521', descripcion: 'NEUROSIFILIS SINTOMATICA'},
    { codigo: 'A522', descripcion: 'NEUROSIFILIS ASINTOMATICA'},
    { codigo: 'A523', descripcion: 'NEUROSIFILIS NO ESPECIFICADA'},
    { codigo: 'A527', descripcion: 'OTRAS SIFILIS TARDIAS SINTOMATICAS'},
    { codigo: 'A528', descripcion: 'SIFILIS TARDIA, LATENTE'},
    { codigo: 'A529', descripcion: 'SIFILIS TARDIA, NO ESPECIFICADA'},
    { codigo: 'A53', descripcion: 'OTRAS SIFILIS Y LAS NO ESPECIFICADAS'},
    { codigo: 'A530', descripcion: 'SIFILIS LATENTE, NO ESPECIFICADA COMO PRECOZ O TARDIA'},
    { codigo: 'A539', descripcion: 'SIFILIS, NO ESPECIFICADA'},
    { codigo: 'A54', descripcion: 'INFECCION GONOCOCICA'},
    { codigo: 'A540', descripcion: 'INFECCION GONOCOCICA DEL TRACTO GENITOURINARIO INFERIOR SIN ABSCESO PERIURETRAL O DE GLANDULA ACCESORIA'},
    { codigo: 'A541', descripcion: 'INFECCION GONOCOCICA DEL TRACTO GENITOURINARIO INFERIOR CON ABSCESO PERIURETRAL Y DE GLANDULAS ACCESORIAS'},
    { codigo: 'A542', descripcion: 'PELVIPERITONITIS GONOCOCICA Y OTRAS INFECCIONES GONOCOCICAS GENITOURINARIAS'},
    { codigo: 'A543', descripcion: 'INFECCION GONOCOCICA DEL OJO'},
    { codigo: 'A544', descripcion: 'INFECCION GONOCOCICA DEL SISTEMA OSTEOMUSCULAR'},
    { codigo: 'A545', descripcion: 'FARINGITIS GONOCOCICA'},
    { codigo: 'A546', descripcion: 'INFECCION GONOCOCICA DEL ANO Y DEL RECTO'},
    { codigo: 'A548', descripcion: 'OTRAS INFECCIONES GONOCOCICAS'},
    { codigo: 'A549', descripcion: 'INFECCION GONOCOCICA, NO ESPECIFICADA'},
    { codigo: 'A55', descripcion: 'LINFOGRANULOMA (VENEREO) POR CLAMIDIAS'},
    { codigo: 'A56', descripcion: 'OTRAS ENFERMEDADES DE TRANSMISION SEXUAL DEBIDAS A CLAMIDIAS'},
    { codigo: 'A560', descripcion: 'INFECCION DEL TRACTO GENITOURINARIO INFERIOR DEBIDA A CLAMIDIAS'},
    { codigo: 'A561', descripcion: 'INFECCION DEL PELVIPERITONEO Y OTROS ORGANOS GENITOURINARIOS DEBIDA A CLAMIDIAS'},
    { codigo: 'A562', descripcion: 'INFECCIONES DEL TRACTO GENITOURINARIO DEBIDAS A CLAMIDIAS, SIN OTRA ESPECIFICACION'},
    { codigo: 'A563', descripcion: 'INFECCION DEL ANO Y DEL RECTO DEBIDA A CLAMIDIAS'},
    { codigo: 'A564', descripcion: 'INFECCION DE FARINGE DEBIDA A CLAMIDIAS'},
    { codigo: 'A568', descripcion: 'INFECCION DE TRANSMISION SEXUAL DE OTROS SITIOS DEBIDA A CLAMIDIAS'},
    { codigo: 'A57', descripcion: 'CHANCRO BLANDO'},
    { codigo: 'A58', descripcion: 'GRANULOMA INGUINAL'},
    { codigo: 'A59', descripcion: 'TRICOMONIASIS'},
    { codigo: 'A590', descripcion: 'TRICOMONIASIS UROGENITAL'},
    { codigo: 'A598', descripcion: 'TRICOMONIASIS DE OTROS SITIOS'},
    { codigo: 'A599', descripcion: 'TRICOMONIASIS, NO ESPECIFICADA'},
    { codigo: 'A60', descripcion: 'INFECCION ANOGENITAL DEBIDA A VIRUS DEL HERPES (HERPES SIMPLE)'},
    { codigo: 'A600', descripcion: 'INFECCION DE GENITALES Y TRAYECTO UROGENITAL DEBIDA AL VIRUS DEL HERPES (HERPES SIMPLE)'},
    { codigo: 'A601', descripcion: 'INFECCION DE LA PIEL PERIANAL Y RECTO POR VIRUS DEL HERPES SIMPLE'},
    { codigo: 'A609', descripcion: 'INFECCION ANOGENITAL POR VIRUS DEL HERPES SIMPLE, SIN OTRA ESPECIFICACION'},
    { codigo: 'A63', descripcion: 'OTRAS ENFERMEDADES DE TRANSMISION PREDOMINANTEMENTE SEXUAL, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A630', descripcion: 'VERRUGAS (VENEREAS) ANOGENITALES'},
    { codigo: 'A638', descripcion: 'OTRAS ENFERMEDADES DE TRANSMISION PREDOMINANTEMENTE SEXUAL, ESPECIFICADAS'},
    { codigo: 'A64', descripcion: 'ENFERMEDAD DE TRANSMISION SEXUAL NO ESPECIFICADA'},
    { codigo: 'A65', descripcion: 'SIFILIS NO VENEREA'},
    { codigo: 'A66', descripcion: 'FRAMBESIA'},
    { codigo: 'A660', descripcion: 'LESIONES INICIALES DE FRAMBESIA'},
    { codigo: 'A661', descripcion: 'LESIONES PAPILOMATOSAS MULTIPLES Y FRAMBESIA CON PASO DE CANGREJO'},
    { codigo: 'A662', descripcion: 'OTRAS LESIONES PRECOCES DE LA PIEL EN LA FRAMBESIA'},
    { codigo: 'A663', descripcion: 'HIPERQUERATOSIS DE FRAMBESIA'},
    { codigo: 'A664', descripcion: 'GOMA Y ULCERAS DE FRAMBESIA'},
    { codigo: 'A665', descripcion: 'GANGOSA'},
    { codigo: 'A666', descripcion: 'LESIONES FRAMBESICAS DE LOS HUESOS Y DE LAS ARTICULACIONES'},
    { codigo: 'A667', descripcion: 'OTRAS MANIFESTACIONES DE FRAMBESIA'},
    { codigo: 'A668', descripcion: 'FRAMBESIA LATENTE'},
    { codigo: 'A669', descripcion: 'FRAMBESIA, NO ESPECIFICADA'},
    { codigo: 'A67', descripcion: 'PINTA (CARATE)'},
    { codigo: 'A670', descripcion: 'LESIONES PRIMARIAS DE LA PINTA'},
    { codigo: 'A671', descripcion: 'LESIONES INTERMEDIAS DE LA PINTA'},
    { codigo: 'A672', descripcion: 'LESIONES TARDIAS DE LA PINTA'},
    { codigo: 'A673', descripcion: 'LESIONES MIXTAS DE LA PINTA'},
    { codigo: 'A679', descripcion: 'PINTA, NO ESPECIFICADA'},
    { codigo: 'A68', descripcion: 'FIEBRES RECURRENTES'},
    { codigo: 'A680', descripcion: 'FIEBRE RECURRENTE TRANSMITIDA POR PIOJOS'},
    { codigo: 'A681', descripcion: 'FIEBRE RECURRENTE TRANSMITIDA POR GARRAPATAS'},
    { codigo: 'A689', descripcion: 'FIEBRE RECURRENTE, NO ESPECIFICADA'},
    { codigo: 'A69', descripcion: 'OTRAS INFECCIONES CAUSADAS POR ESPIROQUETAS'},
    { codigo: 'A690', descripcion: 'ESTOMATITIS ULCERATIVA NECROTIZANTE'},
    { codigo: 'A691', descripcion: 'OTRAS INFECCIONES DE VINCENT'},
    { codigo: 'A692', descripcion: 'ENFERMEDAD DE LYME'},
    { codigo: 'A698', descripcion: 'OTRAS INFECCIONES ESPECIFICADAS POR ESPIROQUETAS'},
    { codigo: 'A699', descripcion: 'INFECCION POR ESPIROQUETA, NO ESPECIFICADA'},
    { codigo: 'A70', descripcion: 'INFECCION DEBIDA A CHLAMYDIA PSITTACI'},
    { codigo: 'A71', descripcion: 'TRACOMA'},
    { codigo: 'A710', descripcion: 'ESTADO INICIAL DE TRACOMA'},
    { codigo: 'A711', descripcion: 'ESTADO ACTIVO DE TRACOMA'},
    { codigo: 'A719', descripcion: 'TRACOMA, NO ESPECIFICADO'},
    { codigo: 'A74', descripcion: 'OTRAS ENFERMEDADES CAUSADAS POR CLAMIDIAS'},
    { codigo: 'A740', descripcion: 'CONJUNTIVITIS POR CLAMIDIAS (H13.1*)'},
    { codigo: 'A748', descripcion: 'OTRAS ENFERMEDADES POR CLAMIDIAS'},
    { codigo: 'A749', descripcion: 'INFECCION POR CLAMIDIAS, NO ESPECIFICADA'},
    { codigo: 'A75', descripcion: 'TIFUS'},
    { codigo: 'A750', descripcion: 'TIFUS EPIDEMICO DEBIDO A RICKETTSIA PROWAZEKII TRANSMITIDO POR PIOJOS'},
    { codigo: 'A751', descripcion: 'TIFUS RECRUDESCENTE (ENFERMEDAD DE BRILL)'},
    { codigo: 'A752', descripcion: 'TIFUS DEBIDO A RICKETTSIA TYPHI'},
    { codigo: 'A753', descripcion: 'TIFUS DEBIDO A RICKETTSIA TSUTSUGAMUSHI'},
    { codigo: 'A759', descripcion: 'TIFUS, NO ESPECIFICADO'},
    { codigo: 'A77', descripcion: 'FIEBRE MACULOSA (RICKETTSIOSIS TRANSMITIDA POR GARRAPATAS)'},
    { codigo: 'A770', descripcion: 'FIEBRE MACULOSA DEBIDA A RICKETTSIA RICKETTSII'},
    { codigo: 'A771', descripcion: 'FIEBRE MACULOSA DEBIDA A RICKETTSIA CONORII'},
    { codigo: 'A772', descripcion: 'FIEBRE MACULOSA DEBIDA A RICKETTSIA SIBERICA'},
    { codigo: 'A773', descripcion: 'FIEBRE MACULOSA DEBIDA A RICKETTSIA AUSTRALIS'},
    { codigo: 'A778', descripcion: 'OTRAS FIEBRES MACULOSAS'},
    { codigo: 'A779', descripcion: 'FIEBRE MACULOSA, NO ESPECIFICADA'},
    { codigo: 'A78', descripcion: 'FIEBRE Q'},
    { codigo: 'A79', descripcion: 'OTRAS RICKETTSIOSIS'},
    { codigo: 'A790', descripcion: 'FIEBRE DE LAS TRINCHERAS'},
    { codigo: 'A791', descripcion: 'RICKETTSIOSIS PUSTULOSA DEBIDA A RICKETTSIA AKARI'},
    { codigo: 'A798', descripcion: 'OTRAS RICKETTSIOSIS ESPECIFICADAS'},
    { codigo: 'A799', descripcion: 'RICKETTSIOSIS, NO ESPECIFICADA'},
    { codigo: 'A80', descripcion: 'POLIOMIELITIS AGUDA'},
    { codigo: 'A800', descripcion: 'POLIOMIELITIS AGUDA PARALITICA, ASOCIADA A VACUNA'},
    { codigo: 'A801', descripcion: 'POLIOMIELITIS AGUDA PARALITICA DEBIDA A VIRUS SALVAJE IMPORTADO'},
    { codigo: 'A802', descripcion: 'POLIOMIELITIS AGUDA PARALITICA DEBIDA A VIRUS SALVAJE AUTOCTONO'},
    { codigo: 'A803', descripcion: 'OTRAS POLIOMIELITIS AGUDAS PARALITICAS, Y LAS NO ESPECIFICADAS'},
    { codigo: 'A804', descripcion: 'POLIOMIELITIS AGUDA NO PARALITICA'},
    { codigo: 'A809', descripcion: 'POLIOMIELITIS AGUDA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A81', descripcion: 'INFECCIONES DEL SISTEMA NERVIOSO CENTRAL POR VIRUS LENTO'},
    { codigo: 'A810', descripcion: 'ENFERMEDAD DE CREUTZFELDT-JAKOB'},
    { codigo: 'A811', descripcion: 'PANENCEFALITIS ESCLEROSANTE SUBAGUDA'},
    { codigo: 'A812', descripcion: 'LEUCOENCEFALOPATIA MULTIFOCAL PROGRESIVA'},
    { codigo: 'A818', descripcion: 'OTRAS INFECCIONES DEL SISTEMA NERVIOSO CENTRAL POR VIRUS LENTO'},
    { codigo: 'A819', descripcion: 'INFECCIONES DEL SISTEMA NERVIOSO CENTRAL POR VIRUS LENTO, SIN OTRA ESPECIFICACION'},
    { codigo: 'A82', descripcion: 'RABIA'},
    { codigo: 'A820', descripcion: 'RABIA SELVATICA'},
    { codigo: 'A821', descripcion: 'RABIA URBANA'},
    { codigo: 'A829', descripcion: 'RABIA, SIN OTRA ESPECIFICACION'},
    { codigo: 'A83', descripcion: 'ENCEFALITIS VIRAL TRANSMITIDA POR MOSQUITOS'},
    { codigo: 'A830', descripcion: 'ENCEFALITIS JAPONESA'},
    { codigo: 'A831', descripcion: 'ENCEFALITIS EQUINA DEL OESTE'},
    { codigo: 'A832', descripcion: 'ENCEFALITIS EQUINA DEL ESTE'},
    { codigo: 'A833', descripcion: 'ENCEFALITIS DE SAN LUIS'},
    { codigo: 'A834', descripcion: 'ENCEFALITIS AUSTRALIANA'},
    { codigo: 'A835', descripcion: 'ENCEFALITIS DE CALIFORNIA'},
    { codigo: 'A836', descripcion: 'ENFERMEDAD POR VIRUS ROCIO'},
    { codigo: 'A838', descripcion: 'OTRAS ENCEFALITIS VIRALES TRANSMITIDAS POR MOSQUITOS'},
    { codigo: 'A839', descripcion: 'ENCEFALITIS VIRAL TRANSMITIDA POR MOSQUITOS, SIN OTRA ESPECIFICACION'},
    { codigo: 'A84', descripcion: 'ENCEFALITIS VIRAL TRANSMITIDA POR GARRAPATAS'},
    { codigo: 'A840', descripcion: 'ENCEFALITIS DEL LEJANO ORIENTE TRANSMITIDA POR GARRAPATAS (ENCEFALITIS PRIMAVEROESTIVAL RUSA)'},
    { codigo: 'A841', descripcion: 'ENCEFALITIS CENTROEUROPEA TRANSMITIDA POR GARRAPATAS'},
    { codigo: 'A848', descripcion: 'OTRAS ENCEFALITIS VIRALES TRANSMITIDAS POR GARRAPATAS'},
    { codigo: 'A849', descripcion: 'ENCEFALITIS VIRAL TRANSMITIDA POR GARRAPATAS, SIN OTRA ESPECIFICACION'},
    { codigo: 'A85', descripcion: 'OTRAS ENCEFALITIS VIRALES, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A850', descripcion: 'ENCEFALITIS ENTEROVIRAL (G05.1*)'},
    { codigo: 'A851', descripcion: 'ENCEFALITIS POR ADENOVIRUS (G05.1*) +'},
    { codigo: 'A852', descripcion: 'ENCEFALITIS VIRAL TRANSMITIDA POR ARTROPODOS, SIN OTRA ESPECIFICACION'},
    { codigo: 'A858', descripcion: 'OTRAS ENCEFALITIS VIRALES ESPECIFICADAS'},
    { codigo: 'A86', descripcion: 'ENCEFALITIS VIRAL, NO ESPECIFICADA'},
    { codigo: 'A87', descripcion: 'MENINGITIS VIRAL'},
    { codigo: 'A870', descripcion: 'MENINGITIS ENTEROVIRAL (G02.0*)'},
    { codigo: 'A871', descripcion: 'MENINGITIS DEBIDA A ADENOVIRUS (G02.0*)'},
    { codigo: 'A872', descripcion: 'CORIOMENINGITIS LINFOCITICA'},
    { codigo: 'A878', descripcion: 'OTRAS MENINGITIS VIRALES'},
    { codigo: 'A879', descripcion: 'MENINGITIS VIRAL, SIN OTRA ESPECIFICACION'},
    { codigo: 'A88', descripcion: 'OTRAS INFECCIONES VIRALES DEL SISTEMA NERVIOSO CENTRAL, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A880', descripcion: 'FIEBRE EXANTEMATICA ENTEROVIRAL (EXANTEMA DE BOSTON)'},
    { codigo: 'A881', descripcion: 'VERTIGO EPIDEMICO'},
    { codigo: 'A888', descripcion: 'OTRAS INFECCIONES VIRALES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL'},
    { codigo: 'A89', descripcion: 'INFECCION VIRAL DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADA'},
    { codigo: 'A90', descripcion: 'FIEBRE DEL DENGUE (DENGUE CLASICO)'},
    { codigo: 'A91', descripcion: 'FIEBRE DEL DENGUE HEMORRAGICO'},
    { codigo: 'A92', descripcion: 'OTRAS FIEBRES VIRALES TRANSMITIDAS POR MOSQUITOS'},
    { codigo: 'A920', descripcion: 'ENFERMEDAD POR VIRUS CHIKUNGUNYA'},
    { codigo: 'A921', descripcion: 'FIEBRE DE O´NYONG-NYONG'},
    { codigo: 'A922', descripcion: 'FIEBRE EQUINA VENEZOLANA'},
    { codigo: 'A923', descripcion: 'FIEBRE DEL OESTE DEL NILO'},
    { codigo: 'A924', descripcion: 'FIEBRE DEL VALLE DEL RIFT'},
    { codigo: 'A928', descripcion: 'OTRAS FIEBRES VIRALES ESPECIFICADAS TRANSMITIDAS POR MOSQUITOS'},
    { codigo: 'A929', descripcion: 'FIEBRE VIRAL TRANSMITIDA POR MOSQUITO, SIN OTRA ESPECIFICACION'},
    { codigo: 'A93', descripcion: 'OTRAS FIEBRES VIRALES TRANSMITIDAS POR ARTROPODOS, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A930', descripcion: 'ENFERMEDAD POR VIRUS DE OROPOUCHE'},
    { codigo: 'A931', descripcion: 'FIEBRE TRANSMITIDA POR FLEBOTOMOS'},
    { codigo: 'A932', descripcion: 'FIEBRE DE COLORADO TRANSMITIDA POR GARRAPATAS'},
    { codigo: 'A938', descripcion: 'OTRAS FIEBRES VIRALES ESPECIFICADAS TRANSMITIDAS POR ARTROPODOS'},
    { codigo: 'A94', descripcion: 'FIEBRE VIRAL TRANSMITIDA POR ARTROPODOS, NO ESPECIFICADA'},
    { codigo: 'A95', descripcion: 'FIEBRE AMARILLA'},
    { codigo: 'A950', descripcion: 'FIEBRE AMARILLA SELVATICA'},
    { codigo: 'A951', descripcion: 'FIEBRE AMARILLA URBANA'},
    { codigo: 'A959', descripcion: 'FIEBRE AMARILLA, NO ESPECIFICADA'},
    { codigo: 'A96', descripcion: 'FIEBRE HEMORRAGICA POR ARENAVIRUS'},
    { codigo: 'A960', descripcion: 'FIEBRE HEMORRAGICA DE JUNIN'},
    { codigo: 'A961', descripcion: 'FIEBRE HEMORRAGICA DE MACHUPO'},
    { codigo: 'A962', descripcion: 'FIEBRE DE LASSA'},
    { codigo: 'A968', descripcion: 'OTRAS FIEBRES HEMORRAGICAS POR ARENAVIRUS'},
    { codigo: 'A969', descripcion: 'FIEBRE HEMORRAGICA POR ARENAVIRUS, SIN OTRA ESPECIFICACION'},
    { codigo: 'A98', descripcion: 'OTRAS FIEBRES VIRALES HEMORRAGICAS, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'A980', descripcion: 'FIEBRE HEMORRAGICA DE CRIMEA-CONGO'},
    { codigo: 'A981', descripcion: 'FIEBRE HEMORRAGICA DE OMSK'},
    { codigo: 'A982', descripcion: 'ENFERMEDAD DE LA SELVA DE KYASANUR'},
    { codigo: 'A983', descripcion: 'ENFERMEDAD POR EL VIRUS DE MARBURG'},
    { codigo: 'A984', descripcion: 'ENFERMEDAD POR EL VIRUS DE EBOLA'},
    { codigo: 'A985', descripcion: 'FIEBRES HEMORRAGICAS CON SINDROME RENAL'},
    { codigo: 'A988', descripcion: 'OTRAS FIEBRES HEMORRAGICAS VIRALES ESPECIFICADAS'},
    { codigo: 'A99', descripcion: 'FIEBRE VIRAL HEMORRAGICA, NO ESPECIFICADA'},
    { codigo: 'B00', descripcion: 'INFECCIONES HERPETICAS (HERPES SIMPLE)'},
    { codigo: 'B000', descripcion: 'ECZEMA HERPETICO'},
    { codigo: 'B001', descripcion: 'DERMATITIS VESICULAR HERPETICA'},
    { codigo: 'B002', descripcion: 'GINGIVOESTOMATITIS Y FARINGOAMIGDALITIS HERPETICA'},
    { codigo: 'B003', descripcion: 'MENINGITIS HERPETICA (G02.0*)'},
    { codigo: 'B004', descripcion: 'ENCEFALITIS HERPETICA (G05.1*)'},
    { codigo: 'B005', descripcion: 'OCULOPATIA HERPETICA'},
    { codigo: 'B007', descripcion: 'ENFERMEDAD HERPETICA DISEMINADA'},
    { codigo: 'B008', descripcion: 'OTRAS FORMAS DE INFECCIONES HERPETICAS'},
    { codigo: 'B009', descripcion: 'INFECCION DEBIDA AL VIRUS DEL HERPES, NO ESPECIFICADA'},
    { codigo: 'B01', descripcion: 'VARICELA'},
    { codigo: 'B010', descripcion: 'MENINGITIS DEBIDA A VARICELA (G02.0*)'},
    { codigo: 'B011', descripcion: 'ENCEFALITIS DEBIDA A VARICELA (G05.1*)'},
    { codigo: 'B012', descripcion: 'NEUMONIA DEBIDA A VARICELA (J17.1*)'},
    { codigo: 'B018', descripcion: 'VARICELA CON OTRAS COMPLICACIONES'},
    { codigo: 'B019', descripcion: 'VARICELA SIN COMPLICACIONES'},
    { codigo: 'B02', descripcion: 'HERPES ZOSTER'},
    { codigo: 'B020', descripcion: 'ENCEFALITIS DEBIDA A HERPES ZOSTER (G05.1*)'},
    { codigo: 'B021', descripcion: 'MENINGITIS DEBIDA A HERPES ZOSTER (G02.0*)'},
    { codigo: 'B022', descripcion: 'HERPES ZOSTER CON OTROS COMPROMISOS DEL SISTEMA NERVIOSO'},
    { codigo: 'B023', descripcion: 'HERPES ZOSTER OCULAR'},
    { codigo: 'B027', descripcion: 'HERPES ZOSTER DISEMINADO'},
    { codigo: 'B028', descripcion: 'HERPES ZOSTER CON OTRAS COMPLICACIONES'},
    { codigo: 'B029', descripcion: 'HERPES ZOSTER SIN COMPLICACIONES'},
    { codigo: 'B03', descripcion: 'VIRUELA'},
    { codigo: 'B04', descripcion: 'VIRUELA DE LOS MONOS'},
    { codigo: 'B05', descripcion: 'SARAMPION'},
    { codigo: 'B050', descripcion: 'SARAMPION COMPLICADO CON ENCEFALITIS (G05.1*)'},
    { codigo: 'B051', descripcion: 'SARAMPION COMPLICADO CON MENINGITIS (G02.0*)'},
    { codigo: 'B052', descripcion: 'SARAMPION COMPLICADO CON NEUMONIA (J17.1*)'},
    { codigo: 'B053', descripcion: 'SARAMPION COMPLICADO CON OTITIS MEDIA (H67.1*)'},
    { codigo: 'B054', descripcion: 'SARAMPION CON COMPLICACIONES INTESTINALES'},
    { codigo: 'B058', descripcion: 'SARAMPION CON OTRAS COMPLICACIONES'},
    { codigo: 'B059', descripcion: 'SARAMPION SIN COMPLICACIONES'},
    { codigo: 'B06', descripcion: 'RUBEOLA [SARAMPION ALEMAN]'},
    { codigo: 'B060', descripcion: 'RUBEOLA CON COMPLICACIONES NEUROLOGICAS'},
    { codigo: 'B068', descripcion: 'RUBEOLA CON OTRAS COMPLICACIONES'},
    { codigo: 'B069', descripcion: 'RUBEOLA SIN COMPLICACIONES'},
    { codigo: 'B07', descripcion: 'VERRUGAS VIRICAS'},
    { codigo: 'B08', descripcion: 'OTRAS INFECCIONES VIRICAS CARACTERIZADAS POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'B080', descripcion: 'OTRAS INFECCIONES DEBIDAS A ORTOPOXVIRUS'},
    { codigo: 'B081', descripcion: 'MOLUSCO CONTAGIOSO'},
    { codigo: 'B082', descripcion: 'EXANTEMA SUBITO (SEXTA ENFERMEDAD)'},
    { codigo: 'B083', descripcion: 'ERITEMA INFECCIOSO (QUINTA ENFERMEDAD)'},
    { codigo: 'B084', descripcion: 'ESTOMATITIS VESICULAR ENTEROVIRAL CON EXANTEMA'},
    { codigo: 'B085', descripcion: 'FARINGITIS VESICULAR ENTEROVIRICA'},
    { codigo: 'B088', descripcion: 'OTRAS INFECCIONES VIRALES ESPECIFADAS, CARACTERIZADAS POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS'},
    { codigo: 'B09', descripcion: 'INFECCION VIRAL NO ESPECIFICADA, CARACTERIZADA POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS'},
    { codigo: 'B15', descripcion: 'HEPATITIS AGUDA TIPO A'},
    { codigo: 'B150', descripcion: 'HEPATITIS AGUDA TIPO A, CON COMA HEPATICO'},
    { codigo: 'B159', descripcion: 'HEPATITIS AGUDA TIPO A, SIN COMA HEPATICO'},
    { codigo: 'B16', descripcion: 'HEPATITIS AGUDA TIPO B'},
    { codigo: 'B160', descripcion: 'HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCION), CON COMA HEPATICO'},
    { codigo: 'B161', descripcion: 'HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCION), SIN COMA HEPATICO'},
    { codigo: 'B162', descripcion: 'HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA, CON COMA HEPATICO'},
    { codigo: 'B169', descripcion: 'HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA Y SIN COMA HEPATICO'},
    { codigo: 'B17', descripcion: 'OTRAS HEPATITIS VIRALES AGUDAS'},
    { codigo: 'B170', descripcion: 'INFECCION (SUPERINFECCION) AGUDA POR AGENTE DELTA EN EL PORTADOR DE HEPATITIS B'},
    { codigo: 'B171', descripcion: 'HEPATITIS AGUDA TIPO C'},
    { codigo: 'B172', descripcion: 'HEPATITIS AGUDA TIPO E'},
    { codigo: 'B178', descripcion: 'OTRAS HEPATITIS VIRALES AGUDAS ESPECIFICADAS'},
    { codigo: 'B18', descripcion: 'HEPATITIS VIRAL CRONICA'},
    { codigo: 'B180', descripcion: 'HEPATITIS VIRAL TIPO B CRONICA, CON AGENTE DELTA'},
    { codigo: 'B181', descripcion: 'HEPATITIS VIRAL TIPO B CRONICA, SIN AGENTE DELTA'},
    { codigo: 'B182', descripcion: 'HEPATITIS VIRAL TIPO C CRONICA'},
    { codigo: 'B188', descripcion: 'OTRAS HEPATITIS VIRALES CRONICAS'},
    { codigo: 'B189', descripcion: 'HEPATITIS VIRAL CRONICA, SIN OTRA ESPECIFICACION'},
    { codigo: 'B19', descripcion: 'HEPATITIS VIRAL, SIN OTRA ESPECIFICACION'},
    { codigo: 'B190', descripcion: 'HEPATITIS VIRAL NO ESPECIFICADA CON COMA'},
    { codigo: 'B199', descripcion: 'HEPATITIS VIRAL NO ESPECIFICADA SIN COMA'},
    { codigo: 'B20', descripcion: 'ENFERMEDAD POR VIRUS DE INMUNODEFICIENCIA HUMANA (VIH), RESULTANTE EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS'},
    { codigo: 'B200', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN INFECCION POR MICOBACTERIAS'},
    { codigo: 'B201', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS INFECCIONES BACTERIANAS'},
    { codigo: 'B202', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD POR CITOMEGALOVIRUS'},
    { codigo: 'B203', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS INFECCIONES VIRALES'},
    { codigo: 'B204', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN CANDIDIASIS'},
    { codigo: 'B205', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS MICOSIS'},
    { codigo: 'B206', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN NEUMONIA POR PNEUMOCYSTIS CARINII'},
    { codigo: 'B207', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN INFECCIONES MULTIPLES'},
    { codigo: 'B208', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS ENFERMEDADES INFECCIOSAS O PARASITARIAS'},
    { codigo: 'B209', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD INFECCIOSA O PARASITARIA NO ESPECIFICADA'},
    { codigo: 'B21', descripcion: 'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH), RESULTANTE EN TUMORES MALIGNOS'},
    { codigo: 'B210', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN SARCOMA DE KAPOSI'},
    { codigo: 'B211', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN LINFOMA DE BURKITT'},
    { codigo: 'B212', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTROS TIPOS DE LINFOMA NO HODGKIN'},
    { codigo: 'B213', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYETICO Y TEJIDOS RELACIONADOS'},
    { codigo: 'B217', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN TUMORES MALIGNOS MULTIPLES'},
    { codigo: 'B218', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTROS TUMORES MALIGNOS'},
    { codigo: 'B219', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN TUMORES MALIGNOS NO ESPECIFICADOS'},
    { codigo: 'B22', descripcion: 'ENFERMEDAD POR VIRUS DE LA INMUNODEICIENCIAF HUMANA (VIH), RESULTANTE EN OTRAS ENFERMEDADES ESPECIFICADAS'},
    { codigo: 'B220', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN ENCEFALOPATIA'},
    { codigo: 'B221', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN NEUMONITIS LINFOIDE INTERSTICIAL'},
    { codigo: 'B222', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN SINDROME CAQUECTICO'},
    { codigo: 'B227', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDADES MULTIPLES CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'B23', descripcion: 'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH), RESULTANTE EN OTRAS AFECCIONES'},
    { codigo: 'B230', descripcion: 'SINDROME DE INFECCION AGUDA DEBIDA A VIH'},
    { codigo: 'B231', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN LINFADENOPATIA GENERALIZADA (PERSISTENTE)'},
    { codigo: 'B232', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN ANORMALIDADES INMUNOLOGICAS Y HEMATOLOGICAS NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'B238', descripcion: 'ENFERMEDAD POR VIH, RESULTANTE EN OTRAS AFECCIONES ESPECIFICADAS'},
    { codigo: 'B24', descripcion: 'ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH), SIN OTRA ESPECIFICACION'},
    { codigo: 'B25', descripcion: 'ENFERMEDAD DEBIDA A VIRUS CITOMEGALICO'},
    { codigo: 'B250', descripcion: 'NEUMONITIS DEBIDA A VIRUS CITOMEGALICO (J17.1*)'},
    { codigo: 'B251', descripcion: 'HEPATITIS DEBIDA A VIRUS CITOMEGALICO (K77.0*)'},
    { codigo: 'B252', descripcion: 'PANCREATITIS DEBIDA A VIRUS CITOMEGALICO (K87.1*)'},
    { codigo: 'B258', descripcion: 'OTRAS ENFERMEDADES DEBIDAS A VIRUS CITOMEGALICO'},
    { codigo: 'B259', descripcion: 'ENFERMEDAD POR VIRUS CITOMEGALICO, NO ESPECIFICADA'},
    { codigo: 'B26', descripcion: 'PAROTIDITIS INFECCIOSA'},
    { codigo: 'B260', descripcion: 'ORQUITIS POR PAROTIDITIS (N51.1*)'},
    { codigo: 'B261', descripcion: 'MENINGITIS POR PAROTIDITIS (G02.0*)'},
    { codigo: 'B262', descripcion: 'ENCEFALITIS POR PAROTIDITIS (G05.1*)'},
    { codigo: 'B263', descripcion: 'PANCREATITIS POR PAROTIDITIS (K87.1*)'},
    { codigo: 'B268', descripcion: 'PAROTIDITIS INFECCIOSA CON OTRAS COMPLICACIONES'},
    { codigo: 'B269', descripcion: 'PAROTIDITIS, SIN COMPLICACIONES'},
    { codigo: 'B27', descripcion: 'MONONUCLEOSIS INFECCIOSA'},
    { codigo: 'B270', descripcion: 'MONONUCLEOSIS DEBIDA A HERPES VIRUS GAMMA'},
    { codigo: 'B271', descripcion: 'MONONUCLEOSIS POR CITOMEGALOVIRUS'},
    { codigo: 'B278', descripcion: 'OTRAS MONONUCLEOSIS INFECCIOSAS'},
    { codigo: 'B279', descripcion: 'MONONUCLEOSIS INFECCIOSA, NO ESPECIFICADA'},
    { codigo: 'B30', descripcion: 'CONJUNTIVITIS VIRAL'},
    { codigo: 'B300', descripcion: 'QUERATOCONJUNTIVITIS DEBIDA A ADENOVIRUS (H19.2*)'},
    { codigo: 'B301', descripcion: 'CONJUNTIVITIS DEBIDA A ADENOVIRUS (H13.1*)'},
    { codigo: 'B302', descripcion: 'FARINGOCONJUNTIVITIS VIRAL'},
    { codigo: 'B303', descripcion: 'CONJUNTIVITIS EPIDEMICA AGUDA HEMORRAGICA (ENTEROVIRICA) (H13.1*)'},
    { codigo: 'B308', descripcion: 'OTRAS CONJUNTIVITIS VIRALES (H13.1*)'},
    { codigo: 'B309', descripcion: 'CONJUNTIVITIS VIRAL, SIN OTRA ESPECIFICACION'},
    { codigo: 'B33', descripcion: 'OTRAS ENFERMEDADES VIRALES, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'B330', descripcion: 'MIALGIA EPIDEMICA'},
    { codigo: 'B331', descripcion: 'ENFERMEDAD DEL RIO ROSS'},
    { codigo: 'B332', descripcion: 'CARDITIS VIRAL'},
    { codigo: 'B333', descripcion: 'INFECCIONES DEBIDAS A RETROVIRUS, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'B338', descripcion: 'OTRAS ENFERMEDADES VIRALES ESPECIFICADAS'},
    { codigo: 'B34', descripcion: 'INFECCION VIRAL DE SITIO NO ESPECIFICADO'},
    { codigo: 'B340', descripcion: 'INFECCION DEBIDA A ADENOVIRUS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B341', descripcion: 'INFECCION DEBIDA A ENTEROVIRUS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B342', descripcion: 'INFECCION DEBIDA A CORONAVIRUS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B343', descripcion: 'INFECCION DEBIDA A PARVOVIRUS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B344', descripcion: 'INFECCION DEBIDA A PAPOVAVIRUS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B348', descripcion: 'OTRAS INFECCIONES VIRALES DE SITIO NO ESPECIFICADO'},
    { codigo: 'B349', descripcion: 'INFECCION VIRAL, NO ESPECIFICADA'},
    { codigo: 'B35', descripcion: 'DERMATOFITOSIS'},
    { codigo: 'B350', descripcion: 'TI—A DE LA BARBA Y DEL CUERO CABELLUDO'},
    { codigo: 'B351', descripcion: 'TI—A DE LAS U—AS'},
    { codigo: 'B352', descripcion: 'TI—A DE LA MANO'},
    { codigo: 'B353', descripcion: 'TI—A DEL PIE (TINEA PEDIS)'},
    { codigo: 'B354', descripcion: 'TI—A DEL CUERPO (TINEA CORPORIS)'},
    { codigo: 'B355', descripcion: 'TI—A IMBRICADA (TINEA IMBRICATA)'},
    { codigo: 'B356', descripcion: 'TI—A INGUINAL (TINEA CRURIS)'},
    { codigo: 'B358', descripcion: 'OTRAS DERMATOFITOSIS'},
    { codigo: 'B359', descripcion: 'DERMATOFITOSIS, NO ESPECIFICADA'},
    { codigo: 'B36', descripcion: 'OTRAS MICOSIS SUPERFICIALES'},
    { codigo: 'B360', descripcion: 'PITIRIASIS VERSICOLOR'},
    { codigo: 'B361', descripcion: 'TI—A NEGRA'},
    { codigo: 'B362', descripcion: 'PIEDRA BLANCA'},
    { codigo: 'B363', descripcion: 'PIEDRA NEGRA'},
    { codigo: 'B368', descripcion: 'OTRAS MICOSIS SUPERFICIALES ESPECIFICADAS'},
    { codigo: 'B369', descripcion: 'MICOSIS SUPERFICIAL, SIN OTRA ESPECIFICACION'},
    { codigo: 'B37', descripcion: 'CANDIDIASIS'},
    { codigo: 'B370', descripcion: 'ESTOMATITIS CANDIDIASICA'},
    { codigo: 'B371', descripcion: 'CANDIDIASIS PULMONAR'},
    { codigo: 'B372', descripcion: 'CANDIDIASIS DE LA PIEL Y LAS U—AS'},
    { codigo: 'B373', descripcion: 'CANDIDIASIS DE LA VULVA Y DE LA VAGINA (N77.1*) +'},
    { codigo: 'B374', descripcion: 'CANDIDIASIS DE OTRAS LOCALIZACIONES UROGENITALES +'},
    { codigo: 'B375', descripcion: 'MENINGITIS DEBIDA A CANDIDA (G02.1*) +'},
    { codigo: 'B376', descripcion: 'ENDOCARDITIS DEBIDA A CANDIDA (I39.8*) +'},
    { codigo: 'B377', descripcion: 'SEPTICEMIA DEBIDA A CANDIDA'},
    { codigo: 'B378', descripcion: 'CANDIDIASIS DE OTROS SITIOS'},
    { codigo: 'B379', descripcion: 'CANDIDIASIS, NO ESPECIFICADA'},
    { codigo: 'B38', descripcion: 'COCCIDIOIDOMICOSIS'},
    { codigo: 'B380', descripcion: 'COCCIDIOIDOMICOSIS PULMONAR AGUDA'},
    { codigo: 'B381', descripcion: 'COCCIDIOIDOMICOSIS PULMONAR CRONICA'},
    { codigo: 'B382', descripcion: 'COCCIDIOIDOMICOSIS PULMONAR, SIN OTRA ESPECIFICACION'},
    { codigo: 'B383', descripcion: 'COCCIDIOIDOMICOSIS CUTANEA'},
    { codigo: 'B384', descripcion: 'MENINGITIS DEBIDA A COCCIDIOIDOMICOSIS (G02.1*) +'},
    { codigo: 'B387', descripcion: 'COCCIDIOIDOMICOSIS DISEMINADA'},
    { codigo: 'B388', descripcion: 'OTRAS FORMAS DE COCCIDIOIDOMICOSIS'},
    { codigo: 'B389', descripcion: 'COCCIDIOIDOMICOSIS, NO ESPECIFICADA'},
    { codigo: 'B39', descripcion: 'HISTOPLASMOSIS'},
    { codigo: 'B390', descripcion: 'INFECCION PULMONAR AGUDA DEBIDA A HISTOPLASMA CAPSULATUM'},
    { codigo: 'B391', descripcion: 'INFECCION PULMONAR CRONICA DEBIDA A HISTOPLASMA CAPSULATUM'},
    { codigo: 'B392', descripcion: 'INFECCION PULMONAR DEBIDA A HISTOPLASMA CAPSULATUM, SIN OTRA ESPECIFIC'},
    { codigo: 'B393', descripcion: 'INFECCION DISEMINADA DEBIDA A HISTOPLASMA CAPSULATUM'},
    { codigo: 'B394', descripcion: 'HISTOPLASMOSIS DEBIDA A HISTOPLASMA CAPSULATUM, SIN OTRA ESPECIFICAC'},
    { codigo: 'B395', descripcion: 'INFECCION DEBIDA A HISTOPLASMA DUBOISII'},
    { codigo: 'B399', descripcion: 'HISTOPLASMOSIS, NO ESPECIFICADA'},
    { codigo: 'B40', descripcion: 'BLASTOMICOSIS'},
    { codigo: 'B400', descripcion: 'BLASTOMICOSIS PULMONAR AGUDA'},
    { codigo: 'B401', descripcion: 'BLASTOMICOSIS PULMONAR CRONICA'},
    { codigo: 'B402', descripcion: 'BLASTOMICOSIS PULMONAR, SIN OTRA ESPECIFICACION'},
    { codigo: 'B403', descripcion: 'BLASTOMICOSIS CUTANEA'},
    { codigo: 'B407', descripcion: 'BLASTOMICOSIS DISEMINADA'},
    { codigo: 'B408', descripcion: 'OTRAS FORMAS DE BLASTOMICOSIS'},
    { codigo: 'B409', descripcion: 'BLASTOMICOSIS, NO ESPECIFICADA'},
    { codigo: 'B41', descripcion: 'PARACOCCIDIOIDOMICOSIS'},
    { codigo: 'B410', descripcion: 'PARACOCCIDIOIDOMICOSIS PULMONAR'},
    { codigo: 'B417', descripcion: 'PARACOCCIDIOIDOMICOSIS DISEMINADA'},
    { codigo: 'B418', descripcion: 'OTRAS FORMAS DE PARACOCCIDIOIDOMICOSIS'},
    { codigo: 'B419', descripcion: 'PARACOCCIDIOIDOMICOSIS, NO ESPECIFICADA'},
    { codigo: 'B42', descripcion: 'ESPOROTRICOSIS'},
    { codigo: 'B420', descripcion: 'ESPOROTRICOSIS PULMONAR (J99.8*)'},
    { codigo: 'B421', descripcion: 'ESPOROTRICOSIS LINFOCUTANEA'},
    { codigo: 'B427', descripcion: 'ESPOROTRICOSIS DISEMINADA'},
    { codigo: 'B428', descripcion: 'OTRAS FORMAS DE ESPOROTRICOSIS'},
    { codigo: 'B429', descripcion: 'ESPOROTRICOSIS, NO ESPECIFICADA'},
    { codigo: 'B43', descripcion: 'CROMOMICOSIS Y ABSCESO FEOMICOTICO'},
    { codigo: 'B430', descripcion: 'CROMOMICOSIS CUTANEA'},
    { codigo: 'B431', descripcion: 'ABSCESO CEREBRAL FEOMICOTICO'},
    { codigo: 'B432', descripcion: 'ABSCESO Y QUISTE SUBCUTANEO FEOMICOTICO'},
    { codigo: 'B438', descripcion: 'OTRAS FORMAS DE CROMOMICOSIS'},
    { codigo: 'B439', descripcion: 'CROMOMICOSIS, NO ESPECIFICADA'},
    { codigo: 'B44', descripcion: 'ASPERGILOSIS'},
    { codigo: 'B440', descripcion: 'ASPERGILOSIS PULMONAR INVASIVA'},
    { codigo: 'B441', descripcion: 'OTRAS ASPERGILOSIS PULMONARES'},
    { codigo: 'B442', descripcion: 'ASPERGILOSIS AMIGDALINA'},
    { codigo: 'B447', descripcion: 'ASPERGILOSIS DISEMINADA'},
    { codigo: 'B448', descripcion: 'OTRAS FORMAS DE ASPERGILOSIS'},
    { codigo: 'B449', descripcion: 'ASPERGILOSIS, NO ESPECIFICADA'},
    { codigo: 'B45', descripcion: 'CRIPTOCOCOSIS'},
    { codigo: 'B450', descripcion: 'CRIPTOCOCOSIS PULMONAR'},
    { codigo: 'B451', descripcion: 'CRIPTOCOCOSIS CEREBRAL'},
    { codigo: 'B452', descripcion: 'CRIPTOCOCOSIS CUTANEA'},
    { codigo: 'B453', descripcion: 'CRIPTOCOCOSIS OSEA'},
    { codigo: 'B457', descripcion: 'CRIPTOCOCOSIS DISEMINADA'},
    { codigo: 'B458', descripcion: 'OTRAS FORMAS DE CRIPTOCOCOSIS'},
    { codigo: 'B459', descripcion: 'CRIPTOCOCOSIS, NO ESPECIFICADA'},
    { codigo: 'B46', descripcion: 'CIGOMICOSIS'},
    { codigo: 'B460', descripcion: 'MUCORMICOSIS PULMONAR'},
    { codigo: 'B461', descripcion: 'MUCORMICOSIS RINOCEREBRAL'},
    { codigo: 'B462', descripcion: 'MUCORMICOSIS GASTROINTESTINAL'},
    { codigo: 'B463', descripcion: 'MUCORMICOSIS CUTANEA'},
    { codigo: 'B464', descripcion: 'MUCORMICOSIS DISEMINADA'},
    { codigo: 'B465', descripcion: 'MUCORMICOSIS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B468', descripcion: 'OTRAS CIGOMICOSIS'},
    { codigo: 'B469', descripcion: 'CIGOMICOSIS, NO ESPECIFICADA'},
    { codigo: 'B47', descripcion: 'MICETOMA'},
    { codigo: 'B470', descripcion: 'EUMICETOMA'},
    { codigo: 'B471', descripcion: 'ACTINOMICETOMA'},
    { codigo: 'B479', descripcion: 'MICETOMA, NO ESPECIFICADO'},
    { codigo: 'B48', descripcion: 'OTRAS MICOSIS, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'B480', descripcion: 'LOBOMICOSIS'},
    { codigo: 'B481', descripcion: 'RINOSPORIDIOSIS'},
    { codigo: 'B482', descripcion: 'ALESQUERIASIS'},
    { codigo: 'B483', descripcion: 'GEOTRICOSIS'},
    { codigo: 'B484', descripcion: 'PENICILOSIS'},
    { codigo: 'B487', descripcion: 'MICOSIS OPORTUNISTAS'},
    { codigo: 'B488', descripcion: 'OTRAS MICOSIS ESPECIFICADAS'},
    { codigo: 'B49', descripcion: 'MICOSIS, NO ESPECIFICADA'},
    { codigo: 'B50', descripcion: 'PALUDISMO (MALARIA) DEBIDO A PLASMODIUM FALCIPARUM'},
    { codigo: 'B500', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM FALCIPARUM CON COMPLICACIONES CEREBRALES'},
    { codigo: 'B508', descripcion: 'OTRO PALUDISMO GRAVE Y COMPLICADO DEBIDO A PLASMODIUM FALCIPARUM'},
    { codigo: 'B509', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM FALCIPARUM, SIN OTRA ESPECIFICACION'},
    { codigo: 'B51', descripcion: 'PALUDISMO (MALARIA) DEBIDO A PLASMODIUM VIVAX'},
    { codigo: 'B510', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM VIVAX CON RUPTURA ESPLENICA'},
    { codigo: 'B518', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM VIVAX CON OTRAS COMPLICACIONES'},
    { codigo: 'B519', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM VIVAX, SIN COMPLICACIONES'},
    { codigo: 'B52', descripcion: 'PALUDISMO (MALARIA) DEBIDO A PLASMODIUM MALARIAE'},
    { codigo: 'B520', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM MALARIAE CON NEFROPATIA'},
    { codigo: 'B528', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM MALARIAE CON OTRAS COMPLICACIONES'},
    { codigo: 'B529', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM MALARIAE, SIN COMPLICACIONES'},
    { codigo: 'B53', descripcion: 'OTRO PALUDISMO (MALARIA) CONFIRMADO PARASITOLOGICAMENTE'},
    { codigo: 'B530', descripcion: 'PALUDISMO DEBIDO A PLASMODIUM OVALE'},
    { codigo: 'B531', descripcion: 'PALUDISMO DEBIDO A PLASMODIOS DE LOS SIMIOS'},
    { codigo: 'B538', descripcion: 'OTRO PALUDISMO CONFIRMADO PARASITOLOGICAMENTE, NO CEOP'},
    { codigo: 'B54', descripcion: 'PALUDISMO (MALARIA) NO ESPECIFICADO'},
    { codigo: 'B55', descripcion: 'LEISHMANIASIS'},
    { codigo: 'B550', descripcion: 'LEISHMANIASIS VISCERAL'},
    { codigo: 'B551', descripcion: 'LEISHMANIASIS CUTANEA'},
    { codigo: 'B552', descripcion: 'LEISHMANIASIS MUCOCUTANEA'},
    { codigo: 'B559', descripcion: 'LEISHMANIASIS, NO ESPECIFICADA'},
    { codigo: 'B56', descripcion: 'TRIPANOSOMIASIS AFRICANA'},
    { codigo: 'B560', descripcion: 'TRIPANOSOMIASIS GAMBIENSE'},
    { codigo: 'B561', descripcion: 'TRIPANOSOMIASIS RHODESIENSE'},
    { codigo: 'B569', descripcion: 'TRIPANOSOMIASIS AFRICANA, SIN OTRA ESPECIFICACION'},
    { codigo: 'B57', descripcion: 'ENFERMEDAD DE CHAGAS'},
    { codigo: 'B570', descripcion: 'ENFERMEDAD DE CHAGAS AGUDA QUE AFECTA AL CORAZON (I41.2*, I98.1*)'},
    { codigo: 'B571', descripcion: 'ENFERMEDAD DE CHAGAS AGUDA QUE NO AFECTA AL CORAZON'},
    { codigo: 'B572', descripcion: 'ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA AL CORAZON (I41.2*, I98.1*)'},
    { codigo: 'B573', descripcion: 'ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA AL SISTEMA DIGESTIVO'},
    { codigo: 'B574', descripcion: 'ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA AL SISTEMA NERVIOSO'},
    { codigo: 'B575', descripcion: 'ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA OTROS ORGANOS'},
    { codigo: 'B58', descripcion: 'TOXOPLASMOSIS'},
    { codigo: 'B580', descripcion: 'OCULOPATIA DEBIDA A TOXOPLASMA'},
    { codigo: 'B581', descripcion: 'HEPATITIS DEBIDA A TOXOPLASMA (K77.0*)'},
    { codigo: 'B582', descripcion: 'MENINGOENCEFALITIS DEBIDA A TOXOPLASMA (G05.2*)'},
    { codigo: 'B583', descripcion: 'TOXOPLASMOSIS PULMONAR (J17.3*)'},
    { codigo: 'B588', descripcion: 'TOXOPLASMOSIS CON OTRO ORGANO AFECTADO'},
    { codigo: 'B589', descripcion: 'TOXOPLASMOSIS, NO ESPECIFICADA'},
    { codigo: 'B59', descripcion: 'NEUMOCISTOSIS'},
    { codigo: 'B60', descripcion: 'OTRAS ENFERMEDADES DEBIDAS A PROTOZOARIOS, NO CEOP'},
    { codigo: 'B600', descripcion: 'BABESIOSIS'},
    { codigo: 'B601', descripcion: 'ACANTAMEBIASIS'},
    { codigo: 'B602', descripcion: 'NAEGLERIASIS'},
    { codigo: 'B608', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEBIDAS A PROTOZARIOS'},
    { codigo: 'B64', descripcion: 'ENFERMEDAD DEBIDA A PROTOZOARIOS, NO ESPECIFICADA'},
    { codigo: 'B65', descripcion: 'ESQUISTOSOMIASIS (BILHARZIASIS)'},
    { codigo: 'B650', descripcion: 'ESQUISTOSOMIASIS POR SCHISTOSOMA HAEMATOBIUM (ESQUISTOSOMIASIS URINAR)'},
    { codigo: 'B651', descripcion: 'ESQUISTOSOMIASIS POR SCHISTOSOMA MANSONI (ESQUISTOSOMIASIS INTESTINAL)'},
    { codigo: 'B652', descripcion: 'ESQUISTOSOMIASIS DEBIDA A SCHISTOSOMA JAPONICUM'},
    { codigo: 'B653', descripcion: 'DERMATITIS POR CERCARIAS'},
    { codigo: 'B658', descripcion: 'OTRAS ESQUISTOSOMIASIS'},
    { codigo: 'B659', descripcion: 'ESQUISTOSOMIASIS, NO ESPECIFICADA'},
    { codigo: 'B66', descripcion: 'OTRAS INFECCIONES DEBIDAS A TREMATODOS'},
    { codigo: 'B660', descripcion: 'OPISTORQUIASIS'},
    { codigo: 'B661', descripcion: 'CLONORQUIASIS'},
    { codigo: 'B662', descripcion: 'DICROCOELIASIS'},
    { codigo: 'B663', descripcion: 'FASCIOLIASIS'},
    { codigo: 'B664', descripcion: 'PARAGONIMIASIS'},
    { codigo: 'B665', descripcion: 'FASCIOLOPSIASIS'},
    { codigo: 'B668', descripcion: 'OTRAS INFECCIONES ESPECIFICADAS DEBIDAS A TREMATODOS'},
    { codigo: 'B669', descripcion: 'INFECCION DEBIDA A TREMATODOS, NO ESPECIFICADA'},
    { codigo: 'B67', descripcion: 'EQUINOCOCOSIS'},
    { codigo: 'B670', descripcion: 'INFECCION DEL HIGADO DEBIDA A ECHINOCOCCUS GRANULOSUS'},
    { codigo: 'B671', descripcion: 'INFECCION DEL PULMON DEBIDA A ECHINOCOCCUS GRANULOSUS'},
    { codigo: 'B672', descripcion: 'INFECCION DE HUESO DEBIDA A ECHINOCOCCUS GRANULOSUS'},
    { codigo: 'B673', descripcion: 'INFECCION DE OTRO ORGANO Y DE SITIOS MULTIPLES POR ECHINOCOCCUS GRANULOSOS'},
    { codigo: 'B674', descripcion: 'INFECCION DEBIDA A ECHINOCOCCUS GRANULOSUS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B675', descripcion: 'INFECCION DEL HIGADO DEBIDA A ECHINOCOCCUS MULTILOCULARIS'},
    { codigo: 'B676', descripcion: 'INFECCION DE OTRO ORGANO Y DE SITIOS MULTIPLES POR ECHINOCOCCUS MULTILOCULARIS'},
    { codigo: 'B677', descripcion: 'INFECCION DEBIDA A ECHINOCOCCUS MULTILOCULARIS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B678', descripcion: 'EQUINOCOCOSIS DEL HIGADO, NO ESPECIFICADA'},
    { codigo: 'B679', descripcion: 'EQUINOCOCOSIS, OTRA Y LA NO ESPECIFICADA'},
    { codigo: 'B68', descripcion: 'TENIASIS'},
    { codigo: 'B680', descripcion: 'TENIASIS DEBIDA A TAENIA SOLIUM'},
    { codigo: 'B681', descripcion: 'INFECCION DEBIDA A TAENIA SAGINATA'},
    { codigo: 'B689', descripcion: 'TENIASIS, NO ESPECIFICADA'},
    { codigo: 'B69', descripcion: 'CISTICERCOSIS'},
    { codigo: 'B690', descripcion: 'CISTICERCOSIS DEL SISTEMA NERVIOSO CENTRAL'},
    { codigo: 'B691', descripcion: 'CISTICERCOSIS DEL OJO'},
    { codigo: 'B698', descripcion: 'CISTICERCOSIS DE OTROS SITIOS'},
    { codigo: 'B699', descripcion: 'CISTICERCOSIS, NO ESPECIFICADA'},
    { codigo: 'B70', descripcion: 'DIFILOBOTRIASIS Y ESPARGANOSIS'},
    { codigo: 'B700', descripcion: 'DIFILOBOTRIASIS INTESTINAL'},
    { codigo: 'B701', descripcion: 'ESPARGANOSIS'},
    { codigo: 'B71', descripcion: 'OTRAS INFECCIONES DEBIDAS A CESTODOS'},
    { codigo: 'B710', descripcion: 'HIMENOLEPIASIS'},
    { codigo: 'B711', descripcion: 'DIPILIDIASIS'},
    { codigo: 'B718', descripcion: 'OTRAS INFECCIONES DEBIDAS A CESTODOS, ESPECIFICADAS'},
    { codigo: 'B719', descripcion: 'INFECCION DEBIDA A CESTODOS, NO ESPECIFICADA'},
    { codigo: 'B72', descripcion: 'DRACONTIASIS'},
    { codigo: 'B73', descripcion: 'ONCOCERCOSIS'},
    { codigo: 'B74', descripcion: 'FILARIASIS'},
    { codigo: 'B740', descripcion: 'FILARIASIS DEBIDA A WUCHERERIA BANCROFTI'},
    { codigo: 'B741', descripcion: 'FILARIASIS DEBIDA A BRUGIA MALAYI'},
    { codigo: 'B742', descripcion: 'FILARIASIS DEBIDA A BRUGIA TIMORI'},
    { codigo: 'B743', descripcion: 'LOAIASIS'},
    { codigo: 'B744', descripcion: 'MANSONELIASIS'},
    { codigo: 'B748', descripcion: 'OTRAS FILARIASIS'},
    { codigo: 'B749', descripcion: 'FILARIASIS, NO ESPECIFICADA'},
    { codigo: 'B75', descripcion: 'TRIQUINOSIS'},
    { codigo: 'B76', descripcion: 'ANQUILOSTOMIASIS Y NECATORIASIS'},
    { codigo: 'B760', descripcion: 'ANQUILOSTOMIASIS'},
    { codigo: 'B761', descripcion: 'NECATORIASIS'},
    { codigo: 'B768', descripcion: 'OTRAS ENFERMEDADES DEBIDAS A ANQUILOSTOMAS'},
    { codigo: 'B769', descripcion: 'ENFERMEDAD DEBIDA A ANQUILOSTOMAS, NO ESPECIFICADA'},
    { codigo: 'B77', descripcion: 'ASCARIASIS'},
    { codigo: 'B770', descripcion: 'ASCARIASIS CON COMPLICACIONES INTESTINALES'},
    { codigo: 'B778', descripcion: 'ASCARIASIS CON OTRAS COMPLICACIONES'},
    { codigo: 'B779', descripcion: 'ASCARIASIS, NO ESPECIFICADA'},
    { codigo: 'B78', descripcion: 'ESTRONGILOIDIASIS'},
    { codigo: 'B780', descripcion: 'ESTRONGILOIDIASIS INTESTINAL'},
    { codigo: 'B781', descripcion: 'ESTRONGILOIDIASIS CUTANEA'},
    { codigo: 'B787', descripcion: 'ESTRONGILOIDIASIS DISEMINADA'},
    { codigo: 'B789', descripcion: 'ESTRONGILOIDIASIS, NO ESPECIFICADA'},
    { codigo: 'B79', descripcion: 'TRICURIASIS'},
    { codigo: 'B80', descripcion: 'ENTEROBIASIS'},
    { codigo: 'B81', descripcion: 'OTRAS HELMINTIASIS INTESTINALES, NO CLASIFICADAS EN OTRA PARTE'},
    { codigo: 'B810', descripcion: 'ANISAQUIASIS'},
    { codigo: 'B811', descripcion: 'CAPILARIASIS INTESTINAL'},
    { codigo: 'B812', descripcion: 'TRICOESTRONGILIASIS'},
    { codigo: 'B813', descripcion: 'ANGIOESTRONGILIASIS INTESTINAL'},
    { codigo: 'B814', descripcion: 'HELMINTIASIS INTESTINAL MIXTA'},
    { codigo: 'B818', descripcion: 'OTRAS HELMINTIASIS INTESTINALES ESPECIFICADAS'},
    { codigo: 'B82', descripcion: 'PARASITOSIS INTESTINALES, SIN OTRA ESPECIFICACION'},
    { codigo: 'B820', descripcion: 'HELMINTIASIS INTESTINAL, SIN OTRA ESPECIFICACION'},
    { codigo: 'B829', descripcion: 'PARASITOSIS INTESTINAL, SIN OTRA ESPECIFICACION'},
    { codigo: 'B83', descripcion: 'OTRAS HELMINTIASIS'},
    { codigo: 'B830', descripcion: 'LARVA MIGRANS VISCERAL'},
    { codigo: 'B831', descripcion: 'GNATOSTOMIASIS'},
    { codigo: 'B832', descripcion: 'ANGIOESTRONGILIASIS DEBIDA A PARASTRONGYLUS CANTONENSIS'},
    { codigo: 'B833', descripcion: 'SINGAMIASIS'},
    { codigo: 'B834', descripcion: 'HIRUDINIASIS INTERNA'},
    { codigo: 'B838', descripcion: 'OTRAS HELMINTIASIS ESPECIFICADAS'},
    { codigo: 'B839', descripcion: 'HELMINTIASIS, NO ESPECIFICADA'},
    { codigo: 'B85', descripcion: 'PEDICULOSIS Y PHTHIRIASIS'},
    { codigo: 'B850', descripcion: 'PEDICULOSIS DEBIDA A PEDICULUS HUMANUS CAPITIS'},
    { codigo: 'B851', descripcion: 'PEDICULOSIS DEBIDA A PEDICULUS HUMANUS CORPORIS'},
    { codigo: 'B852', descripcion: 'PEDICULOSIS, SIN OTRA ESPECIFICACION'},
    { codigo: 'B853', descripcion: 'PHTHIRIASIS'},
    { codigo: 'B854', descripcion: 'PEDICULOSIS Y PHTHIRIASIS MIXTAS'},
    { codigo: 'B86', descripcion: 'ESCABIOSIS'},
    { codigo: 'B87', descripcion: 'MIASIS'},
    { codigo: 'B870', descripcion: 'MIASIS CUTANEA'},
    { codigo: 'B871', descripcion: 'MIASIS EN HERIDAS'},
    { codigo: 'B872', descripcion: 'MIASIS OCULAR'},
    { codigo: 'B873', descripcion: 'MIASIS NASOFARINGEA'},
    { codigo: 'B874', descripcion: 'MIASIS AURAL'},
    { codigo: 'B878', descripcion: 'MIASIS DE OTROS SITIOS'},
    { codigo: 'B879', descripcion: 'MIASIS, NO ESPECIFICADA'},
    { codigo: 'B88', descripcion: 'OTRAS INFESTACIONES'},
    { codigo: 'B880', descripcion: 'OTRAS ACARIASIS'},
    { codigo: 'B881', descripcion: 'TUNGIASIS (INFECCION DEBIDA A PULGA DE ARENA)'},
    { codigo: 'B882', descripcion: 'OTRAS INFESTACIONES DEBIDAS A ARTROPODOS'},
    { codigo: 'B883', descripcion: 'HIRUDINIASIS EXTERNA'},
    { codigo: 'B888', descripcion: 'OTRAS INFESTACIONES ESPECIFICADAS'},
    { codigo: 'B889', descripcion: 'INFESTACION, NO ESPECIFICADA'},
    { codigo: 'B89', descripcion: 'ENFERMEDAD PARASITARIA, NO ESPECIFICADA'},
    { codigo: 'B90', descripcion: 'SECUELAS DE TUBERCULOSIS'},
    { codigo: 'B900', descripcion: 'SECUELAS DE TUBERCULOSIS DEL SISTEMA NERVIOSO CENTRAL'},
    { codigo: 'B901', descripcion: 'SECUELAS DE TUBERCULOSIS GENITOURINARIA'},
    { codigo: 'B902', descripcion: 'SECUELAS DE TUBERCULOSIS DE HUESOS Y ARTICULACIONES'},
    { codigo: 'B908', descripcion: 'SECUELAS DE TUBERCULOSIS DE OTROS ORGANOS ESPECIFICADOS'},
    { codigo: 'B909', descripcion: 'SECUELAS DE TUBERCULOSIS RESPIRATORIA Y DE TUBERCULOSIS NO ESPECIFICADA'},
    { codigo: 'B91', descripcion: 'SECUELAS DE POLIOMIELITIS'},
    { codigo: 'B92', descripcion: 'SECUELAS DE LEPRA'},
    { codigo: 'B94', descripcion: 'SECUELAS DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS Y DE LAS NO ESPECIFICADAS'},
    { codigo: 'B940', descripcion: 'SECUELAS DE TRACOMA'},
    { codigo: 'B941', descripcion: 'SECUELAS DE ENCEFALITIS VIRAL'},
    { codigo: 'B942', descripcion: 'SECUELAS DE HEPATITIS VIRAL'},
    { codigo: 'B948', descripcion: 'SECUELAS DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS'},
    { codigo: 'B949', descripcion: 'SECUELAS DE ENFERMEDADES INFECCIOSAS Y PARASITARIAS NO ESPECIFICADAS'},
    { codigo: 'B95', descripcion: 'ESTREPTOCOCOS Y ESTAFILOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B950', descripcion: 'ESTREPTOCOCO, GRUPO A, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B951', descripcion: 'ESTREPTOCOCO, GRUPO B, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B952', descripcion: 'ESTREPTOCOCO, GRUPO D, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B953', descripcion: 'STREPTOCOCCUS PNEUMONIAE COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B954', descripcion: 'OTROS ESTREPTOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B955', descripcion: 'ESTREPTOCOCO NO ESPECIFICADO COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B956', descripcion: 'STAPHYLOCOCCUS AUREUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B957', descripcion: 'OTROS ESTAFILOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B958', descripcion: 'ESTAFILOCOCO NO ESPECIFICADO, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B96', descripcion: 'OTROS AGENTES BACTERIANOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B960', descripcion: 'MYCOPLASMA PNEUMONIAE (M. PNEUMONIAE) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B961', descripcion: 'KLEBSIELLA PNEUMONIAE (K. PNEUMONIAE) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B962', descripcion: 'ESCHERICHIA COLI (E. COLI) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B963', descripcion: 'HAEMOPHILUS INFLUENZAE (H. INFLUENZAE) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B964', descripcion: 'PROTEUS (MIRABILIS) (MORGANII) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B965', descripcion: 'PSEUDOMONAS (AERUGINOSA) (MALLEI) (PSEUDOMALLEI) POR CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B966', descripcion: 'BACILLUS FRAGILIS (B. FRAGILIS) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B967', descripcion: 'CLOSTRIDIUM PERFRINGENS (C. PERFRINGENS) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B968', descripcion: 'OTROS AGENTES BACTERIANOS ESPECIFICADOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B97', descripcion: 'AGENTES VIRALES COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B970', descripcion: 'ADENOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B971', descripcion: 'ENTEROVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B972', descripcion: 'CORONAVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B973', descripcion: 'RETROVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B974', descripcion: 'VIRUS SINCICIAL RESPIRATORIO COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B975', descripcion: 'REOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B976', descripcion: 'PARVOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B977', descripcion: 'PAPILOMAVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B978', descripcion: 'OTROS AGENTES VIRALES COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS'},
    { codigo: 'B99', descripcion: 'OTRAS ENFERMEDADES INFECCIOSAS Y LAS NO ESPECIFICADAS'},
    { codigo: 'C00', descripcion: 'TUMOR MALIGNO DEL LABIO'},
{ codigo: 'C000', descripcion: 'TUMOR MALIGNO DEL LABIO SUPERIOR, CARA EXTERNA'},
{ codigo: 'C001', descripcion: 'TUMOR MALIGNO DEL LABIO INFERIOR, CARA EXTERNA'},
{ codigo: 'C002', descripcion: 'TUMOR MALIGNO DEL LABIO, CARA EXTERNA, SIN OTRA ESPECIFICACION'},
{ codigo: 'C003', descripcion: 'TUMOR MALIGNO DEL LABIO SUPERIOR, CARA INTERNA'},
{ codigo: 'C004', descripcion: 'TUMOR MALIGNO DEL LABIO INFERIOR, CARA INTERNA'},
{ codigo: 'C005', descripcion: 'TUMOR MALIGNO DEL LABIO, CARA INTERNA, SIN OTRA ESPECIFICACION'},
{ codigo: 'C006', descripcion: 'TUMOR MALIGNO DE LA COMISURA LABIAL'},
{ codigo: 'C008', descripcion: 'LESION DE SITIOS CONTIGUOS DEL LABIO'},
{ codigo: 'C009', descripcion: 'TUMOR MALIGNO DEL LABIO, PARTE NO ESPECIFICADA'},
{ codigo: 'C01', descripcion: 'TUMOR MALIGNO DE LA BASE DE LA LENGUA'},
{ codigo: 'C02', descripcion: 'TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA LENGUA'},
{ codigo: 'C020', descripcion: 'TUMOR MALIGNO DE LA CARA DORSAL DE LA LENGUA'},
{ codigo: 'C021', descripcion: 'TUMOR MALIGNO DEL BORDE DE LA LENGUA'},
{ codigo: 'C022', descripcion: 'TUMOR MALIGNO DE LA CARA VENTRAL DE LA LENGUA'},
{ codigo: 'C023', descripcion: 'TUMOR MALIGNO DE LOS DOS TERCIOS ANTERIORES DE LENGUA, PARTE NO ESPECIFICADA'},
{ codigo: 'C024', descripcion: 'TUMOR MALIGNO DE LA AMIGDALA LINGUAL'},
{ codigo: 'C028', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA LENGUA'},
{ codigo: 'C029', descripcion: 'TUMOR MALIGNO DE LA LENGUA, PARTE NO ESPECIFICADA'},
{ codigo: 'C03', descripcion: 'TUMOR MALIGNO DE LA ENCIA'},
{ codigo: 'C030', descripcion: 'TUMOR MALIGNO DE LA ENCIA SUPERIOR'},
{ codigo: 'C031', descripcion: 'TUMOR MALIGNO DE LA ENCIA INFERIOR'},
{ codigo: 'C039', descripcion: 'TUMOR MALIGNO DE LA ENCIA, PARTE NO ESPECIFICADA'},
{ codigo: 'C04', descripcion: 'TUMOR MALIGNO DEL PISO DE LA BOCA'},
{ codigo: 'C040', descripcion: 'TUMOR MALIGNO DE LA PARTE ANTERIOR DEL PISO DE LA BOCA'},
{ codigo: 'C041', descripcion: 'TUMOR MALIGNO DE LA PARTE LATERAL DEL PISO DE LA BOCA'},
{ codigo: 'C048', descripcion: 'LESION DE SITIOS CONTIGUOS DEL PISO DE LA BOCA'},
{ codigo: 'C049', descripcion: 'TUMOR MALIGNO DEL PISO DE LA BOCA, PARTE NO ESPECIFICADA'},
{ codigo: 'C05', descripcion: 'TUMOR MALIGNO DEL PALADAR'},
{ codigo: 'C050', descripcion: 'TUMOR MALIGNO DEL PALADAR DURO'},
{ codigo: 'C051', descripcion: 'TUMOR MALIGNO DEL PALADAR BLANDO'},
{ codigo: 'C052', descripcion: 'TUMOR MALIGNO DE LA UVULA'},
{ codigo: 'C058', descripcion: 'LESION DE SITIOS CONTIGUOS DEL PALADAR'},
{ codigo: 'C059', descripcion: 'TUMOR MALIGNO DEL PALADAR, PARTE NO ESPECIFICADA'},
{ codigo: 'C06', descripcion: 'TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA'},
{ codigo: 'C060', descripcion: 'TUMOR MALIGNO DE LA MUCOSA DE LA MEJILLA'},
{ codigo: 'C061', descripcion: 'TUMOR MALIGNO DEL VESTIBULO DE LA BOCA'},
{ codigo: 'C062', descripcion: 'TUMOR MALIGNO DEL AREA RETROMOLAR'},
{ codigo: 'C068', descripcion: 'LESION DE SITIOS CONTIGUOS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA'},
{ codigo: 'C069', descripcion: 'TUMOR MALIGNO DE LA BOCA, PARTE NO ESPECIFICADA'},
{ codigo: 'C07', descripcion: 'TUMOR MALIGNO DE LA GLANDULA PAROTIDA'},
{ codigo: 'C08', descripcion: 'TUMOR MALIGNO DE OTRAS GLANDULAS SALIVALES MAYORES Y DE LAS NO ESPECIFICADAS'},
{ codigo: 'C080', descripcion: 'TUMOR MALIGNO DE LA GLANDULA SUBMAXILAR'},
{ codigo: 'C081', descripcion: 'TUMOR MALIGNO DE LA GLANDULA SUBLINGUAL'},
{ codigo: 'C088', descripcion: 'LESION DE SITIOS CONTIGUOS DE LAS GLANDULAS SALIVALES MAYORES'},
{ codigo: 'C089', descripcion: 'TUMOR MALIGNO DE GLANDULA SALIVAL MAYOR, NO ESPECIFICADA'},
{ codigo: 'C09', descripcion: 'TUMOR MALIGNO DE LA AMIGDALA'},
{ codigo: 'C090', descripcion: 'TUMOR MALIGNO DE LA FOSA AMIGDALINA'},
{ codigo: 'C091', descripcion: 'TUMOR MALIGNO DEL PILAR AMIGDALINO (ANTERIOR) (POSTERIOR)'},
{ codigo: 'C098', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA AMIGDALA'},
{ codigo: 'C099', descripcion: 'TUMOR MALIGNO DE LA AMIGDALA, PARTE NO ESPECIFICADA'},
{ codigo: 'C10', descripcion: 'TUMOR MALIGNO DE LA OROFARINGE'},
{ codigo: 'C100', descripcion: 'TUMOR MALIGNO DE LA VALECULA'},
{ codigo: 'C101', descripcion: 'TUMOR MALIGNO DE LA CARA ANTERIOR DE LA EPIGLOTIS'},
{ codigo: 'C102', descripcion: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA OROFARINGE'},
{ codigo: 'C103', descripcion: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA OROFARINGE'},
{ codigo: 'C104', descripcion: 'TUMOR MALIGNO DE LA HENDIDURA BRANQUIAL'},
{ codigo: 'C108', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA OROFARINGE'},
{ codigo: 'C109', descripcion: 'TUMOR MALIGNO DE LA OROFARINGE, PARTE NO ESPECIFICADA'},
{ codigo: 'C11', descripcion: 'TUMOR MALIGNO DE LA NASOFARINGE'},
{ codigo: 'C110', descripcion: 'TUMOR MALIGNO DE LA PARED SUPERIOR DE LA NASOFARINGE'},
{ codigo: 'C111', descripcion: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA NASOFARINGE'},
{ codigo: 'C112', descripcion: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA NASOFARINGE'},
{ codigo: 'C113', descripcion: 'TUMOR MALIGNO DE LA PARED ANTERIOR DE LA NASOFARINGE'},
{ codigo: 'C118', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA NASOFARINGE'},
{ codigo: 'C119', descripcion: 'TUMOR MALIGNO DE LA NASOFARINGE, PARTE NO ESPECIFICADA'},
{ codigo: 'C12', descripcion: 'TUMOR MALIGNO DEL SENO PIRIFORME'},
{ codigo: 'C13', descripcion: 'TUMOR MALIGNO DE LA HIPOFARINGE'},
{ codigo: 'C130', descripcion: 'TUMOR MALIGNO DE LA REGION POSTCRICOIDEA'},
{ codigo: 'C131', descripcion: 'TUMOR MALIGNO DEL PLIEGUE ARITENOEPIGLOTICO, CARA HIPOFARINGEA'},
{ codigo: 'C132', descripcion: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA HIPOFARINGE'},
{ codigo: 'C138', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA HIPOFARINGE'},
{ codigo: 'C139', descripcion: 'TUMOR MALIGNO DE LA HIPOFARINGE, PARTE NO ESPECIFICADA'},
{ codigo: 'C14', descripcion: 'TUMOR MALIGNO DE OTROS SITIOS Y DE LOS MAL DEFINIDOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE'},
{ codigo: 'C140', descripcion: 'TUMOR MALIGNO DE LA FARINGE, PARTE NO ESPECIFICADA'},
{ codigo: 'C142', descripcion: 'TUMOR MALIGNO DEL ANILLO DE WALDEYER'},
{ codigo: 'C148', descripcion: 'LESION DE SITIOS CONTIGUOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE FARINGE'},
{ codigo: 'C15', descripcion: 'TUMOR MALIGNO DEL ESOFAGO'},
{ codigo: 'C150', descripcion: 'TUMOR MALIGNO DEL ESOFAGO, PORCION CERVICAL'},
{ codigo: 'C151', descripcion: 'TUMOR MALIGNO DEL ESOFAGO, PORCION TORACICA'},
{ codigo: 'C152', descripcion: 'TUMOR MALIGNO DEL ESOFAGO, PORCION ABDOMINAL'},
{ codigo: 'C153', descripcion: 'TUMOR MALIGNO DEL TERCIO SUPERIOR DEL ESOFAGO'},
{ codigo: 'C154', descripcion: 'TUMOR MALIGNO DEL TERCIO MEDIO DEL ESOFAGO'},
{ codigo: 'C155', descripcion: 'TUMOR MALIGNO DEL TERCIO INFERIOR DEL ESOFAGO'},
{ codigo: 'C158', descripcion: 'LESION DE SITIOS CONTIGUOS DEL ESOFAGO'},
{ codigo: 'C159', descripcion: 'TUMOR MALIGNO DEL ESOFAGO, PARTE NO ESPECIFICADA'},
{ codigo: 'C16', descripcion: 'TUMOR MALIGNO DEL ESTOMAGO'},
{ codigo: 'C160', descripcion: 'TUMOR MALIGNO DEL CARDIAS'},
{ codigo: 'C161', descripcion: 'TUMOR MALIGNO DEL FUNDUS GASTRICO'},
{ codigo: 'C162', descripcion: 'TUMOR MALIGNO DEL CUERPO DEL ESTOMAGO'},
{ codigo: 'C163', descripcion: 'TUMOR MALIGNO DEL ANTRO PILORICO'},
{ codigo: 'C164', descripcion: 'TUMOR MALIGNO DEL PILORO'},
{ codigo: 'C165', descripcion: 'TUMOR MALIGNO DE LA CURVATURA MENOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION'},
{ codigo: 'C166', descripcion: 'TUMOR MALIGNO DE LA CURVATURA MAYOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION'},
{ codigo: 'C168', descripcion: 'LESION DE SITIOS CONTIGUOS DEL ESTOMAGO'},
{ codigo: 'C169', descripcion: 'TUMOR MALIGNO DEL ESTOMAGO, PARTE NO ESPECIFICADA'},
{ codigo: 'C17', descripcion: 'TUMOR MALIGNO DEL INTESTINO DELGADO'},
{ codigo: 'C170', descripcion: 'TUMOR MALIGNO DEL DUODENO'},
{ codigo: 'C171', descripcion: 'TUMOR MALIGNO DEL YEYUNO'},
{ codigo: 'C172', descripcion: 'TUMOR MALIGNO DEL ILEON'},
{ codigo: 'C173', descripcion: 'TUMOR MALIGNO DEL DIVERTICULO DE MECKEL'},
{ codigo: 'C178', descripcion: 'LESION DE SITIOS CONTIGUOS DEL INTESTINO DELGADO'},
{ codigo: 'C179', descripcion: 'TUMOR MALIGNO DEL INTESTINO DELGADO, PARTE NO ESPECIFICADA'},
{ codigo: 'C18', descripcion: 'TUMOR MALIGNO DEL COLON'},
{ codigo: 'C180', descripcion: 'TUMOR MALIGNO DEL CIEGO'},
{ codigo: 'C181', descripcion: 'TUMOR MALIGNO DEL APENDICE'},
{ codigo: 'C182', descripcion: 'TUMOR MALIGNO DEL COLON ASCENDENTE'},
{ codigo: 'C183', descripcion: 'TUMOR MALIGNO DEL ANGULO HEPATICO'},
{ codigo: 'C184', descripcion: 'TUMOR MALIGNO DEL COLON TRANSVERSO'},
{ codigo: 'C185', descripcion: 'TUMOR MALIGNO DEL ANGULO ESPLENICO'},
{ codigo: 'C186', descripcion: 'TUMOR MALIGNO DEL COLON DESCENDENTE'},
{ codigo: 'C187', descripcion: 'TUMOR MALIGNO DEL COLON SIGMOIDE'},
{ codigo: 'C188', descripcion: 'LESION DE SITIOS CONTIGUOS DEL COLON'},
{ codigo: 'C189', descripcion: 'TUMOR MALIGNO DEL COLON, PARTE NO ESPECIFICADA'},
{ codigo: 'C19', descripcion: 'TUMOR MALIGNO DE LA UNION RECTOSIGMOIDEA'},
{ codigo: 'C20', descripcion: 'TUMOR MALIGNO DEL RECTO'},
{ codigo: 'C21', descripcion: 'TUMOR MALIGNO DEL ANO Y DEL CONDUCTO ANAL'},
{ codigo: 'C210', descripcion: 'TUMOR MALIGNO DEL ANO, PARTE NO ESPECIFICADA'},
{ codigo: 'C211', descripcion: 'TUMOR MALIGNO DEL CONDUCTO ANAL'},
{ codigo: 'C212', descripcion: 'TUMOR MALIGNO DE LA ZONA CLOACOGENICA'},
{ codigo: 'C218', descripcion: 'LESION DE SITIOS CONTIGUOS DEL ANO, DEL CONDUCTO ANAL Y DEL RECTO'},
{ codigo: 'C22', descripcion: 'TUMOR MALIGNO DEL HIGADO Y DE LAS VIAS BILIARES INTRAHEPATICAS'},
{ codigo: 'C220', descripcion: 'CARCINOMA DE CELULAS HEPATICAS'},
{ codigo: 'C221', descripcion: 'CARCINOMA DE VIAS BILIARES INTRAHEPATICAS'},
{ codigo: 'C222', descripcion: 'HEPATOBLASTOMA'},
{ codigo: 'C223', descripcion: 'ANGIOSARCOMA DEL HIGADO'},
{ codigo: 'C224', descripcion: 'OTROS SARCOMAS DEL HIGADO'},
{ codigo: 'C227', descripcion: 'OTROS CARCINOMAS ESPECIFICADOS DEL HIGADO'},
{ codigo: 'C229', descripcion: 'TUMOR MALIGNO DEL HIGADO, NO ESPECIFICADO'},
{ codigo: 'C23', descripcion: 'TUMOR MALIGNO DE LA VESICULA BILIAR'},
{ codigo: 'C24', descripcion: 'TUMOR MALIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LAS VIAS BILIARES'},
{ codigo: 'C240', descripcion: 'TUMOR MALIGNO DE LAS VIAS BILIARES EXTRAHEPATICAS'},
{ codigo: 'C241', descripcion: 'TUMOR MALIGNO DE LA AMPOLLA DE VATER'},
{ codigo: 'C248', descripcion: 'LESION DE SITIOS CONTIGUOS DE LAS VIAS BILIARES'},
{ codigo: 'C249', descripcion: 'TUMOR MALIGNO DE LAS VIAS BILIARES, PARTE NO ESPECIFICADA'},
{ codigo: 'C25', descripcion: 'TUMOR MALIGNO DEL PANCREAS'},
{ codigo: 'C250', descripcion: 'TUMOR MALIGNO DE LA CABEZA DEL PANCREAS'},
{ codigo: 'C251', descripcion: 'TUMOR MALIGNO DEL CUERPO DEL PANCREAS'},
{ codigo: 'C252', descripcion: 'TUMOR MALIGNO DE LA COLA DEL PANCREAS'},
{ codigo: 'C253', descripcion: 'TUMOR MALIGNO DEL CONDUCTO PANCREATICO'},
{ codigo: 'C254', descripcion: 'TUMOR MALIGNO DEL PANCREAS ENDOCRINO'},
{ codigo: 'C257', descripcion: 'TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DEL PANCREAS'},
{ codigo: 'C258', descripcion: 'LESION DE SITIOS CONTIGUOS DEL PANCREAS'},
{ codigo: 'C259', descripcion: 'TUMOR MALIGNO DEL PANCREAS, PARTE NO ESPECIFICADA'},
{ codigo: 'C26', descripcion: 'TUMOR MALIGNO DE OTROS SITIOS Y DE LOS MAL DEFINIDOS DE LOS ORGANOS DIGESTIVOS'},
{ codigo: 'C260', descripcion: 'TUMOR MALIGNO DEL INTESTINO, PARTE NO ESPECIFICADA'},
{ codigo: 'C261', descripcion: 'TUMOR MALIGNO DEL BAZO'},
{ codigo: 'C268', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS ORGANOS DIGESTIVOS'},
{ codigo: 'C269', descripcion: 'TUMOR MALIGNO DE SITIOS MAL DEFINIDOS DE LOS ORGANOS DIGESTIVOS'},
{ codigo: 'C30', descripcion: 'TUMOR MALIGNO DE LAS FOSAS NASALES Y DEL OIDO MEDIO'},
{ codigo: 'C300', descripcion: 'TUMOR MALIGNO DE LA FOSA NASAL'},
{ codigo: 'C301', descripcion: 'TUMOR MALIGNO DEL OIDO MEDIO'},
{ codigo: 'C31', descripcion: 'TUMOR MALIGNO DE LOS SENOS PARANASALES'},
{ codigo: 'C310', descripcion: 'TUMOR MALIGNO DEL SENO MAXILAR'},
{ codigo: 'C311', descripcion: 'TUMOR MALIGNO DEL SENO ETMOIDAL'},
{ codigo: 'C312', descripcion: 'TUMOR MALIGNO DEL SENO FRONTAL'},
{ codigo: 'C313', descripcion: 'TUMOR MALIGNO DEL SENO ESFENOIDAL'},
{ codigo: 'C318', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS SENOS PARANASALES'},
{ codigo: 'C319', descripcion: 'TUMOR MALIGNO DE SENO PARANASAL NO ESPECIFICADO'},
{ codigo: 'C32', descripcion: 'TUMOR MALIGNO DE LA LARINGE'},
{ codigo: 'C320', descripcion: 'TUMOR MALIGNO DE LA GLOTIS'},
{ codigo: 'C321', descripcion: 'TUMOR MALIGNO DE LA REGION SUPRAGLOTICA'},
{ codigo: 'C322', descripcion: 'TUMOR MALIGNO DE LA REGION SUBGLOTICA'},
{ codigo: 'C323', descripcion: 'TUMOR MALIGNO DEL CARTILAGO LARINGEO'},
{ codigo: 'C328', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA LARINGE'},
{ codigo: 'C329', descripcion: 'TUMOR MALIGNO DE LA LARINGE, PARTE NO ESPECIFICADA'},
{ codigo: 'C33', descripcion: 'TUMOR MALIGNO DE LA TRAQUEA'},
{ codigo: 'C34', descripcion: 'TUMOR MALIGNO DE LOS BRONQUIOS Y DEL PULMON'},
{ codigo: 'C340', descripcion: 'TUMOR MALIGNO DEL BRONQUIO PRINCIPAL'},
{ codigo: 'C341', descripcion: 'TUMOR MALIGNO DEL LOBULO SUPERIOR, BRONQUIO O PULMON'},
{ codigo: 'C342', descripcion: 'TUMOR MALIGNO DEL LOBULO MEDIO, BRONQUIO O PULMON'},
{ codigo: 'C343', descripcion: 'TUMOR MALIGNO DEL LOBULO INFERIOR, BRONQUIO O PULMON'},
{ codigo: 'C348', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS BRONQUIOS Y DEL PULMON'},
{ codigo: 'C349', descripcion: 'TUMOR MALIGNO DE LOS BRONQUIOS O DEL PULMON, PARTE NO ESPECIFICADA'},
{ codigo: 'C37', descripcion: 'TUMOR MALIGNO DEL TIMO'},
{ codigo: 'C38', descripcion: 'TUMOR MALIGNO DEL CORAZON, DEL MEDIASTINO Y DE LA PLEURA'},
{ codigo: 'C380', descripcion: 'TUMOR MALIGNO DEL CORAZON'},
{ codigo: 'C381', descripcion: 'TUMOR MALIGNO DEL MEDIASTINO ANTERIOR'},
{ codigo: 'C382', descripcion: 'TUMOR MALIGNO DEL MEDIASTINO POSTERIOR'},
{ codigo: 'C383', descripcion: 'TUMOR MALIGNO DEL MEDIASTINO, PARTE NO ESPECIFICADA'},
{ codigo: 'C384', descripcion: 'TUMOR MALIGNO DE LA PLEURA'},
{ codigo: 'C388', descripcion: 'LESION DE SITIOS CONTIGUOS DEL CORAZON, DEL MEDIASTINO Y DE LA PLEURA'},
{ codigo: 'C39', descripcion: 'TUMOR MALIGNO DE OTROS SITIOS Y DE MAL DEFINIDOS DEL SISTEMA RESPIRATORIO Y DE LOS ORGANOS INTRATORACICOS'},
{ codigo: 'C390', descripcion: 'TUMOR MALIGNO DE LAS VIAS RESPIRATORIAS SUPERIORES, PARTE NO ESPECIFICADA'},
{ codigo: 'C398', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS ORGANOS RESPIRATORIOS E INTRATORACICOS'},
{ codigo: 'C399', descripcion: 'TUMOR MALIGNO DE SITIOS MAL DEFINIDOS DEL SISTEMA RESPIRATORIO'},
{ codigo: 'C40', descripcion: 'TUMOR MALIGNO DE HUESOS Y DE CARTILAGOS ARTICULARES DE LOS MIEMBROS'},
{ codigo: 'C400', descripcion: 'TUMOR MALIGNO DEL OMOPLATO Y DE LOS HUESOS LARGOS DEL MIEMBRO SUPERIOR'},
{ codigo: 'C401', descripcion: 'TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO SUPERIOR'},
{ codigo: 'C402', descripcion: 'TUMOR MALIGNO DE LOS HUESOS LARGOS DEL MIEMBRO INFERIOR'},
{ codigo: 'C403', descripcion: 'TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO INFERIOR'},
{ codigo: 'C408', descripcion: 'LESIONES SITIOS CONTIGUOS DE HUESOS Y CARTILAGOS ARTICULARES DE LOS MIEMBROS'},
{ codigo: 'C409', descripcion: 'TUMOR MALIGNO DE HUESOS Y DE CARTILAGOS ARTICULARES DE LOS MIEMBROS SIN OTRA ESPECIFICACION'},
{ codigo: 'C41', descripcion: 'TUMOR MALIGNO DE HUESOS Y DE CARTILAGOS ARTICULARES, DE OTROS SITIOS Y DE SITIOS NO ESPECIFICADOS'},
{ codigo: 'C410', descripcion: 'TUMOR MALIGNO DE LOS HUESOS DEL CRANEO Y DE LA CARA'},
{ codigo: 'C411', descripcion: 'TUMOR MALIGNO DEL HUESO DEL MAXILAR INFERIOR'},
{ codigo: 'C412', descripcion: 'TUMOR MALIGNO DE LA COLUMNA VERTEBRAL'},
{ codigo: 'C413', descripcion: 'TUMOR MALIGNO DE LA COSTILLA, ESTERNON Y CLAVICULA'},
{ codigo: 'C414', descripcion: 'TUMOR MALIGNO DE LOS HUESOS DE LA PELVIS, SACRO Y COCCIX'},
{ codigo: 'C418', descripcion: 'LESION DE SITIOS CONTIGUOS DEL HUESO Y DEL CARTILAGO ARTICULAR'},
{ codigo: 'C419', descripcion: 'TUMOR MALIGNO DEL HUESO Y DEL CARTILAGO ARTICULAR, NO ESPECIFICADO'},
{ codigo: 'C43', descripcion: 'MELANOMA MALIGNO DE LA PIEL'},
{ codigo: 'C430', descripcion: 'MELANOMA MALIGNO DEL LABIO'},
{ codigo: 'C431', descripcion: 'MELANOMA MALIGNO DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL'},
{ codigo: 'C432', descripcion: 'MELANOMA MALIGNO DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO'},
{ codigo: 'C433', descripcion: 'MELANOMA MALIGNO DE LAS OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA CARA'},
{ codigo: 'C434', descripcion: 'MELANOMA MALIGNO DEL CUERO CABELLUDO Y DEL CUELLO'},
{ codigo: 'C435', descripcion: 'MELANOMA MALIGNO DEL TRONCO'},
{ codigo: 'C436', descripcion: 'MELANOMA MALIGNO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO'},
{ codigo: 'C437', descripcion: 'MELANOMA MALIGNO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA'},
{ codigo: 'C438', descripcion: 'MELANOMA MALIGNO DE SITIOS CONTIGUOS DE LA PIEL'},
{ codigo: 'C439', descripcion: 'MELANOMA MALIGNO DE PIEL, SITIO NO ESPECIFICADO'},
{ codigo: 'C44', descripcion: 'OTROS TUMORES MALIGNOS DE LA PIEL'},
{ codigo: 'C440', descripcion: 'TUMOR MALIGNO DE LA PIEL DEL LABIO'},
{ codigo: 'C441', descripcion: 'TUMOR MALIGNO DE LA PIEL DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL'},
{ codigo: 'C442', descripcion: 'TUMOR MALIGNO DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO'},
{ codigo: 'C443', descripcion: 'TUMOR MALIGNO DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPEC DE LA CARA'},
{ codigo: 'C444', descripcion: 'TUMOR MALIGNO DE LA PIEL DEL CUERO CABELLUDO Y DEL CUELLO'},
{ codigo: 'C445', descripcion: 'TUMOR MALIGNO DE LA PIEL DEL TRONCO'},
{ codigo: 'C446', descripcion: 'TUMOR MALIGNO DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO'},
{ codigo: 'C447', descripcion: 'TUMOR MALIGNO DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA'},
{ codigo: 'C448', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA PIEL'},
{ codigo: 'C449', descripcion: 'TUMOR MALIGNO DE LA PIEL, SITIO NO ESPECIFICADO'},
{ codigo: 'C45', descripcion: 'MESOTELIOMA'},
{ codigo: 'C450', descripcion: 'MESOTELIOMA DE LA PLEURA'},
{ codigo: 'C451', descripcion: 'MESOTELIOMA DEL PERITONEO'},
{ codigo: 'C452', descripcion: 'MESOTELIOMA DEL PERICARDIO'},
{ codigo: 'C457', descripcion: 'MESOTELIOMA DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'C459', descripcion: 'MESOTELIOMA, DE SITIO NO ESPECIFICADO'},
{ codigo: 'C46', descripcion: 'SARCOMA DE KAPOSI'},
{ codigo: 'C460', descripcion: 'SARCOMA DE KAPOSI DE LA PIEL'},
{ codigo: 'C461', descripcion: 'SARCOMA DE KAPOSI DEL TEJIDO BLANDO'},
{ codigo: 'C462', descripcion: 'SARCOMA DE KAPOSI DEL PALADAR'},
{ codigo: 'C463', descripcion: 'SARCOMA DE KAPOSI DE LOS GANGLIOS LINFATICOS'},
{ codigo: 'C467', descripcion: 'SARCOMA DE KAPOSI DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'C468', descripcion: 'SARCOMA DE KAPOSI DE MULTIPLES ORGANOS'},
{ codigo: 'C469', descripcion: 'SARCOMA DE KAPOSI, DE SITIO NO ESPECIFICADO'},
{ codigo: 'C47', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'C470', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DE LA CABEZA, CARA Y CUELLO'},
{ codigo: 'C471', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL MIEMBRO SUPERIOR, INCLUIDO HOMBRO'},
{ codigo: 'C472', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL MIEMBRO INFERIOR, INCLUIDA CADERA'},
{ codigo: 'C473', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL TORAX'},
{ codigo: 'C474', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL ABDOMEN'},
{ codigo: 'C475', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DE LA PELVIS'},
{ codigo: 'C476', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL TRONCO, SIN OTRA ESPECIFICACION'},
{ codigo: 'C478', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'C479', descripcion: 'TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO PARTE NO ESPEFICADA'},
{ codigo: 'C48', descripcion: 'TUMOR MALIGNO DEL PERITONEO Y DEL RETROPERITONEO'},
{ codigo: 'C480', descripcion: 'TUMOR MALIGNO DEL RETROPERITONEO'},
{ codigo: 'C481', descripcion: 'TUMOR MALIGNO DE PARTE ESPECIFICADA DEL PERITONEO'},
{ codigo: 'C482', descripcion: 'TUMOR MALIGNO DEL PERITONEO, SIN OTRA ESPECIFICACION'},
{ codigo: 'C488', descripcion: 'LESION DE SITIOS CONTIGUOS DEL PERITONEO Y DEL RETROPERITONEO'},
{ codigo: 'C49', descripcion: 'TUMOR MALIGNO DE OTROS TEJIDOS CONJUNTIVOS Y DE TEJIDOS BLANDOS'},
{ codigo: 'C490', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DE CABEZA CARA Y CUELLO'},
{ codigo: 'C491', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL MIEMBRO SUPERIOR INCLUIDO HOMBRO'},
{ codigo: 'C492', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL MIEMBRO INFERIOR INCLUIDA CADERA'},
{ codigo: 'C493', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL TORAX'},
{ codigo: 'C494', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL ABDOMEN'},
{ codigo: 'C495', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DE LA PELVIS'},
{ codigo: 'C496', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL TRONCO, SIN OTRA ESPECIFICACION'},
{ codigo: 'C498', descripcion: 'LESION DE SITIOS CONTIGUOS DEL TEJIDO CONJUNTIVO Y DEL TEJIDO BLANDO'},
{ codigo: 'C499', descripcion: 'TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO, DE SITIO NO ESPECIFICADO'},
{ codigo: 'C50', descripcion: 'TUMOR MALIGNO DE LA MAMA'},
{ codigo: 'C500', descripcion: 'TUMOR MALIGNO DEL PEZON Y AREOLA MAMARIA'},
{ codigo: 'C501', descripcion: 'TUMOR MALIGNO DE LA PORCION CENTRAL DE LA MAMA'},
{ codigo: 'C502', descripcion: 'TUMOR MALIGNO DEL CUADRANTE SUPERIOR INTERNO DE LA MAMA'},
{ codigo: 'C503', descripcion: 'TUMOR MALIGNO DEL CUADRANTE INFERIOR INTERNO DE LA MAMA'},
{ codigo: 'C504', descripcion: 'TUMOR MALIGNO DEL CUADRANTE SUPERIOR EXTERNO DE LA MAMA'},
{ codigo: 'C505', descripcion: 'TUMOR MALIGNO DEL CUADRANTE INFERIOR EXTERNO DE LA MAMA'},
{ codigo: 'C506', descripcion: 'TUMOR MALIGNO DE LA PROLONGACION AXILAR DE LA MAMA'},
{ codigo: 'C508', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA MAMA'},
{ codigo: 'C509', descripcion: 'TUMOR MALIGNO DE LA MAMA, PARTE NO ESPECIFICADA'},
{ codigo: 'C51', descripcion: 'TUMOR MALIGNO DE LA VULVA'},
{ codigo: 'C510', descripcion: 'TUMOR MALIGNO DEL LABIO MAYOR'},
{ codigo: 'C511', descripcion: 'TUMOR MALIGNO DEL LABIO MENOR'},
{ codigo: 'C512', descripcion: 'TUMOR MALIGNO DEL CLITORIS'},
{ codigo: 'C518', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA VULVA'},
{ codigo: 'C519', descripcion: 'TUMOR MALIGNO DE LA VULVA, PARTE NO ESPECIFICADA'},
{ codigo: 'C52', descripcion: 'TUMOR MALIGNO DE LA VAGINA'},
{ codigo: 'C53', descripcion: 'TUMOR MALIGNO DEL CUELLO DEL UTERO'},
{ codigo: 'C530', descripcion: 'TUMOR MALIGNO DEL ENDOCERVIX'},
{ codigo: 'C531', descripcion: 'TUMOR MALIGNO DEL EXOCERVIX'},
{ codigo: 'C538', descripcion: 'LESION DE SITIOS CONTIGUOS DEL CUELLO DEL UTERO'},
{ codigo: 'C539', descripcion: 'TUMOR MALIGNO DEL CUELLO DEL UTERO, SIN OTRA ESPECIFICACION'},
{ codigo: 'C54', descripcion: 'TUMOR MALIGNO DEL CUERPO DEL UTERO'},
{ codigo: 'C540', descripcion: 'TUMOR MALIGNO DEL ISTMO UTERINO'},
{ codigo: 'C541', descripcion: 'TUMOR MALIGNO DEL ENDOMETRIO'},
{ codigo: 'C542', descripcion: 'TUMOR MALIGNO DEL MIOMETRIO'},
{ codigo: 'C543', descripcion: 'TUMOR MALIGNO DEL FONDO DEL UTERO'},
{ codigo: 'C548', descripcion: 'LESION DE SITIOS CONTIGUOS DEL CUERPO DEL UTERO'},
{ codigo: 'C549', descripcion: 'TUMOR MALIGNO DEL CUERPO DEL UTERO, PARTE NO ESPECIFICADA'},
{ codigo: 'C55', descripcion: 'TUMOR MALIGNO DEL UTERO, PARTE NO ESPECIFICADA'},
{ codigo: 'C56', descripcion: 'TUMOR MALIGNO DEL OVARIO'},
{ codigo: 'C57', descripcion: 'TUMOR MALIGNO DE OTROS ORGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'C570', descripcion: 'TUMOR MALIGNO DE LA TROMPA DE FALOPIO'},
{ codigo: 'C571', descripcion: 'TUMOR MALIGNO DEL LIGAMENTO ANCHO'},
{ codigo: 'C572', descripcion: 'TUMOR MALIGNO DEL LIGAMENTO REDONDO'},
{ codigo: 'C573', descripcion: 'TUMOR MALIGNO DEL PARAMETRIO'},
{ codigo: 'C574', descripcion: 'TUMOR MALIGNO DE LOS ANEXOS UTERINOS, SIN OTRA ESPECIFICACION'},
{ codigo: 'C577', descripcion: 'TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DE LOS ORGANOS GENITALES FEMENINOS'},
{ codigo: 'C578', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS ORGANOS GENITALES FEMENINOS'},
{ codigo: 'C579', descripcion: 'TUMOR MALIGNO DE ORGANO GENITAL FEMENINO, PARTE NO ESPECIFICADA'},
{ codigo: 'C58', descripcion: 'TUMOR MALIGNO DE LA PLACENTA'},
{ codigo: 'C60', descripcion: 'TUMOR MALIGNO DEL PENE'},
{ codigo: 'C600', descripcion: 'TUMOR MALIGNO DEL PREPUCIO'},
{ codigo: 'C601', descripcion: 'TUMOR MALIGNO DEL GLANDE'},
{ codigo: 'C602', descripcion: 'TUMOR MALIGNO DEL CUERPO DEL PENE'},
{ codigo: 'C608', descripcion: 'LESION DE SITIOS CONTIGUOS DEL PENE'},
{ codigo: 'C609', descripcion: 'TUMOR MALIGNO DEL PENE, PARTE NO ESPECIFICADA'},
{ codigo: 'C61', descripcion: 'TUMOR MALIGNO DE LA PROSTATA'},
{ codigo: 'C62', descripcion: 'TUMOR MALIGNO DEL TESTICULO'},
{ codigo: 'C620', descripcion: 'TUMOR MALIGNO DEL TESTICULO NO DESCENDIDO'},
{ codigo: 'C621', descripcion: 'TUMOR MALIGNO DEL TESTICULO DESCENDIDO'},
{ codigo: 'C629', descripcion: 'TUMOR MALIGNO DEL TESTICULO, NO ESPECIFICADO'},
{ codigo: 'C63', descripcion: 'TUMOR MALIGNO DE OTROS ORGANOS GENITALES MASCULINOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'C630', descripcion: 'TUMOR MALIGNO DEL EPIDIDIMO'},
{ codigo: 'C631', descripcion: 'TUMOR MALIGNO DEL CORDON ESPERMATICO'},
{ codigo: 'C632', descripcion: 'TUMOR MALIGNO DEL ESCROTO'},
{ codigo: 'C637', descripcion: 'TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'C638', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'C639', descripcion: 'TUMOR MALIGNO DE ORGANO GENITAL MASCULINO, PARTE NO ESPECIFICADA'},
{ codigo: 'C64', descripcion: 'TUMOR MALIGNO DEL RI—ON, EXCEPTO DE LA PELVIS RENAL'},
{ codigo: 'C65', descripcion: 'TUMOR MALIGNO DE LA PELVIS RENAL'},
{ codigo: 'C66', descripcion: 'TUMOR MALIGNO DEL URETER'},
{ codigo: 'C67', descripcion: 'TUMOR MALIGNO DE LA VEJIGA URINARIA'},
{ codigo: 'C670', descripcion: 'TUMOR MALIGNO DEL TRIGONO VESICAL'},
{ codigo: 'C671', descripcion: 'TUMOR MALIGNO DE LA CUPULA VESICAL'},
{ codigo: 'C672', descripcion: 'TUMOR MALIGNO DE LA PARED LATERAL DE LA VEJIGA'},
{ codigo: 'C673', descripcion: 'TUMOR MALIGNO DE LA PARED ANTERIOR DE LA VEJIGA'},
{ codigo: 'C674', descripcion: 'TUMOR MALIGNO DE LA PARED POSTERIOR DE LA VEJIGA'},
{ codigo: 'C675', descripcion: 'TUMOR MALIGNO DEL CUELLO DE LA VEJIGA'},
{ codigo: 'C676', descripcion: 'TUMOR MALIGNO DEL ORIFICIO URETERAL'},
{ codigo: 'C677', descripcion: 'TUMOR MALIGNO DEL URACO'},
{ codigo: 'C678', descripcion: 'LESION DE SITIOS CONTIGUOS DE LA VEJIGA'},
{ codigo: 'C679', descripcion: 'TUMOR MALIGNO DE LA VEJIGA URINARIA, PARTE NO ESPECIFICADA'},
{ codigo: 'C68', descripcion: 'TUMOR MALIGNO DE OTROS ORGANOS URINARIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'C680', descripcion: 'TUMOR MALIGNO DE LA URETRA'},
{ codigo: 'C681', descripcion: 'TUMOR MALIGNO DE LAS GLANDULAS PARAURETRALES'},
{ codigo: 'C688', descripcion: 'LESION DE SITIOS CONTIGUOS DE LOS ORGANOS URINARIOS'},
{ codigo: 'C689', descripcion: 'TUMOR MALIGNO DE ORGANO URINARIO NO ESPECIFICADO'},
{ codigo: 'C69', descripcion: 'TUMOR MALIGNO DEL OJO Y SUS ANEXOS'},
{ codigo: 'C690', descripcion: 'TUMOR MALIGNO DE LA CONJUNTIVA'},
{ codigo: 'C691', descripcion: 'TUMOR MALIGNO DE LA CORNEA'},
{ codigo: 'C692', descripcion: 'TUMOR MALIGNO DE LA RETINA'},
{ codigo: 'C693', descripcion: 'TUMOR MALIGNO DE LA COROIDES'},
{ codigo: 'C694', descripcion: 'TUMOR MALIGNO DEL CUERPO CILIAR'},
{ codigo: 'C695', descripcion: 'TUMOR MALIGNO DE LA GLANDULA Y CONDUCTO LAGRIMALES'},
{ codigo: 'C696', descripcion: 'TUMOR MALIGNO DE LA ORBITA'},
{ codigo: 'C698', descripcion: 'LESION DE SITIOS CONTIGUOS DEL OJO Y SUS ANEXOS'},
{ codigo: 'C699', descripcion: 'TUMOR MALIGNO DEL OJO, PARTE NO ESPECIFICADA'},
{ codigo: 'C70', descripcion: 'TUMOR MALIGNO DE LAS MENINGES'},
{ codigo: 'C700', descripcion: 'TUMOR MALIGNO DE LAS MENINGES CEREBRALES'},
{ codigo: 'C701', descripcion: 'TUMOR MALIGNO DE LAS MENINGES RAQUIDEAS'},
{ codigo: 'C709', descripcion: 'TUMOR MALIGNO DE LAS MENINGES, PARTE NO ESPECIFICADA'},
{ codigo: 'C71', descripcion: 'TUMOR MALIGNO DEL ENCEFALO'},
{ codigo: 'C710', descripcion: 'TUMOR MALIGNO DEL CEREBRO, EXCEPTO LOBULOS Y VENTRICULOS'},
{ codigo: 'C711', descripcion: 'TUMOR MALIGNO DEL LOBULO FRONTAL'},
{ codigo: 'C712', descripcion: 'TUMOR MALIGNO DEL LOBULO TEMPORAL'},
{ codigo: 'C713', descripcion: 'TUMOR MALIGNO DEL LOBULO PARIETAL'},
{ codigo: 'C714', descripcion: 'TUMOR MALIGNO DEL LOBULO OCCIPITAL'},
{ codigo: 'C715', descripcion: 'TUMOR MALIGNO DEL VENTRICULO CEREBRAL'},
{ codigo: 'C716', descripcion: 'TUMOR MALIGNO DEL CEREBELO'},
{ codigo: 'C717', descripcion: 'TUMOR MALIGNO DEL PEDUNCULO CEREBRAL'},
{ codigo: 'C718', descripcion: 'LESION DE SITIOS CONTIGUOS DEL ENCEFALO'},
{ codigo: 'C719', descripcion: 'TUMOR MALIGNO DEL ENCEFALO, PARTE NO ESPECIFICADA'},
{ codigo: 'C72', descripcion: 'TUMOR MALIGNO DE LA MEDULA ESPINAL, DE LOS NERVIOS CRANEALES Y OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'C720', descripcion: 'TUMOR MALIGNO DE LA MEDULA ESPINAL'},
{ codigo: 'C721', descripcion: 'TUMOR MALIGNO DE LA COLA DE CABALLO'},
{ codigo: 'C722', descripcion: 'TUMOR MALIGNO DEL NERVIO OLFATORIO'},
{ codigo: 'C723', descripcion: 'TUMOR MALIGNO DEL NERVIO OPTICO'},
{ codigo: 'C724', descripcion: 'TUMOR MALIGNO DEL NERVIO ACUSTICO'},
{ codigo: 'C725', descripcion: 'TUMOR MALIGNO DE OTROS NERVIOS CRANEALES Y LOS NO ESPECIFICADOS'},
{ codigo: 'C728', descripcion: 'LESION DE SITIOS CONTIGUOS DEL ENCEFALO Y OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'C729', descripcion: 'TUMOR MALIGNO DEL SISTEMA NERVIOSO CENTRAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'C73', descripcion: 'TUMOR MALIGNO DE LA GLANDULA TIROIDES'},
{ codigo: 'C74', descripcion: 'TUMOR MALIGNO DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'C740', descripcion: 'TUMOR MALIGNO DE LA CORTEZA DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'C741', descripcion: 'TUMOR MALIGNO DE LA MEDULA DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'C749', descripcion: 'TUMOR MALIGNO DE LA GLANDULA SUPRARRENAL, PARTE NO ESPECIFICADA'},
{ codigo: 'C75', descripcion: 'TUMOR MALIGNO DE OTRAS GLANDULAS ENDOCRINAS Y DE ESTRUCTURAS AFINES'},
{ codigo: 'C750', descripcion: 'TUMOR MALIGNO DE LA GLANDULA PARATIROIDES'},
{ codigo: 'C751', descripcion: 'TUMOR MALIGNO DE LA HIPOFISIS'},
{ codigo: 'C752', descripcion: 'TUMOR MALIGNO DEL CONDUCTO CRANEOFARINGEO'},
{ codigo: 'C753', descripcion: 'TUMOR MALIGNO DE LA GLANDULA PINEAL'},
{ codigo: 'C754', descripcion: 'TUMOR MALIGNO DEL CUERPO CAROTIDEO'},
{ codigo: 'C755', descripcion: 'TUMOR MALIGNO DEL CUERPO AORTICO Y OTROS CUERPOS CROMAFINES'},
{ codigo: 'C758', descripcion: 'TUMOR MALIGNO PLURIGLANDULAR, NO ESPECIFICADO'},
{ codigo: 'C759', descripcion: 'TUMOR MALIGNO DE GLANDULA ENDOCRINA NO ESPECIFICADA'},
{ codigo: 'C76', descripcion: 'TUMOR MALIGNO DE OTROS SITIOS Y DE SITIOS MAL DEFINIDOS'},
{ codigo: 'C760', descripcion: 'TUMOR MALIGNO DE LA CABEZA, CARA Y CUELLO'},
{ codigo: 'C761', descripcion: 'TUMOR MALIGNO DEL TORAX'},
{ codigo: 'C762', descripcion: 'TUMOR MALIGNO DEL ABDOMEN'},
{ codigo: 'C763', descripcion: 'TUMOR MALIGNO DE LA PELVIS'},
{ codigo: 'C764', descripcion: 'TUMOR MALIGNO DEL MIEMBRO SUPERIOR'},
{ codigo: 'C765', descripcion: 'TUMOR MALIGNO DEL MIEMBRO INFERIOR'},
{ codigo: 'C767', descripcion: 'TUMOR MALIGNO DE OTROS SITIOS MAL DEFINIDOS'},
{ codigo: 'C768', descripcion: 'LESION DE SITIOS CONTIGUOS MAL DEFINIDOS'},
{ codigo: 'C77', descripcion: 'TUMOR MALIGNO SECUNDARIO Y EL NO ESPECIFICADO DE LOS GANGLIOS LINFATICOS'},
{ codigo: 'C770', descripcion: 'TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE LA CABEZA, CARA Y CUELLO'},
{ codigo: 'C771', descripcion: 'TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS INTRATORACICOS'},
{ codigo: 'C772', descripcion: 'TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS INTRAABDOMINALES'},
{ codigo: 'C773', descripcion: 'TUMOR MALIGNO DE GANGLIOS LINFATICOS DE AXILA Y DEL MIEMBRO SUPERIOR'},
{ codigo: 'C774', descripcion: 'TUMOR MALIGNO DE GANGLIOS LINFATICOS DE LA REGION INGUINAL Y DE MIEMBRO INFERIOR'},
{ codigo: 'C775', descripcion: 'TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE LA PELVIS'},
{ codigo: 'C778', descripcion: 'TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE REGIONES MULTIPLES'},
{ codigo: 'C779', descripcion: 'TUMOR MALIGNO DEL GANGLIO LINFATICO, SITIO NO ESPECIFICADO'},
{ codigo: 'C78', descripcion: 'TUMOR MALIGNO SECUNDARIO DE LOS ORGANOS RESPIRATORIOS Y DIGESTIVOS'},
{ codigo: 'C780', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL PULMON'},
{ codigo: 'C781', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL MEDIASTINO'},
{ codigo: 'C782', descripcion: 'TUMOR MALIGNO SECUNDARIO DE LA PLEURA'},
{ codigo: 'C783', descripcion: 'TUMOR MALIGNO SECUNDARIO DE OTROS ORGANOS RESPIRATORIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'C784', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL INTESTINO DELGADO'},
{ codigo: 'C785', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL INTESTINO GRUESO Y DEL RECTO'},
{ codigo: 'C786', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL PERITONEO Y DEL RETROPERITONEO'},
{ codigo: 'C787', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL HIGADO'},
{ codigo: 'C788', descripcion: 'TUMOR MALIGNO SECUNDARIO DE OTROS ORGANOS DIGESTIVOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'C79', descripcion: 'TUMOR MALIGNO SECUNDARIO DE OTROS SITIOS'},
{ codigo: 'C790', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL RI—ON Y DE LA PELVIS RENAL'},
{ codigo: 'C791', descripcion: 'TUMOR MALIGNO SECUNDARIO DE LA VEJIGA Y DE OTROS ORGANOS Y LOS NO ESPECIFICADOS DE LAS VIAS URINARIAS'},
{ codigo: 'C792', descripcion: 'TUMOR MALIGNO SECUNDARIO DE LA PIEL'},
{ codigo: 'C793', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL ENCEFALO Y DE LAS MENINGES CEREBRALES'},
{ codigo: 'C794', descripcion: 'TUMOR MALIGNO SECUNDARIO DE OTRAS PARTES DEL SISTEMA NERVIOSO Y DE LAS NO ESPECIFICADAS'},
{ codigo: 'C795', descripcion: 'TUMOR MALIGNO SECUNDARIO DE LOS HUESOS Y DE LA MEDULA OSEA'},
{ codigo: 'C796', descripcion: 'TUMOR MALIGNO SECUNDARIO DEL OVARIO'},
{ codigo: 'C797', descripcion: 'TUMOR MALIGNO SECUNDARIO DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'C798', descripcion: 'TUMOR MALIGNO SECUNDARIO DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'C80', descripcion: 'TUMOR MALIGNO DE SITIOS NO ESPECIFICADOS'},
{ codigo: 'C81', descripcion: 'ENFERMEDAD DE HODGKIN'},
{ codigo: 'C810', descripcion: 'ENFERMEDAD DE HODGKIN CON PREDOMINIO LINFOCITICO'},
{ codigo: 'C811', descripcion: 'ENFERMEDAD DE HODGKIN CON ESCLEROSIS NODULAR'},
{ codigo: 'C812', descripcion: 'ENFERMEDAD DE HODGKIN CON CELULARIDAD MIXTA'},
{ codigo: 'C813', descripcion: 'ENFERMEDAD DE HODGKIN CON DEPLECION LINFOCITICA'},
{ codigo: 'C817', descripcion: 'OTROS TIPOS DE ENFERMEDAD DE HODGKIN'},
{ codigo: 'C819', descripcion: 'ENFERMEDAD DE HODGKIN, NO ESPECIFICADA'},
{ codigo: 'C82', descripcion: 'LINFOMA NO HODGKIN FOLICULAR (NODULAR)'},
{ codigo: 'C820', descripcion: 'LINFOMA NO HODGKIN DE CELULAS PEQUE—AS HENDIDAS, FOLICULAR'},
{ codigo: 'C821', descripcion: 'LINFOMA NO HODKIN MIXTO, PEQ CELULAS HENDIDAS Y GRAND CELUL, FOLICULAR'},
{ codigo: 'C822', descripcion: 'LINFOMA NO HODGKIN DE CELULAS GRANDES, FOLICULAR'},
{ codigo: 'C827', descripcion: 'OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN FOLICULAR'},
{ codigo: 'C829', descripcion: 'LINFOMA NO HODGKIN FOLICULAR, SIN OTRA ESPECIFICACION'},
{ codigo: 'C83', descripcion: 'LINFOMA NO HODGKIN DIFUSO'},
{ codigo: 'C830', descripcion: 'LINFOMA NO HODGKIN DE CELULAS PEQUE—AS (DIFUSO)'},
{ codigo: 'C831', descripcion: 'LINFOMA NO HODGKIN DE CELULAS PEQUE—AS HENDIDAS (DIFUSO)'},
{ codigo: 'C832', descripcion: 'LINFOMA NO HODGKIN MIXTO, DE CELULAS PEQUE—AS Y GRANDES (DIFUSO)'},
{ codigo: 'C833', descripcion: 'LINFOMA NO HODGKIN DE CELULAS GRANDES (DIFUSO)'},
{ codigo: 'C834', descripcion: 'LINFOMA NO HODGKIN INMUNOBLASTICO (DIFUSO)'},
{ codigo: 'C835', descripcion: 'LINFOMA NO HODGKIN LINFOBLASTICO (DIFUSO)'},
{ codigo: 'C836', descripcion: 'LINFOMA NO HODGKIN INDIFERENCIADO (DIFUSO)'},
{ codigo: 'C837', descripcion: 'TUMOR DE BURKITT'},
{ codigo: 'C838', descripcion: 'OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN DIFUSO'},
{ codigo: 'C839', descripcion: 'LINFOMA NO HODGKIN DIFUSO, SIN OTRA ESPECIFICACION'},
{ codigo: 'C84', descripcion: 'LINFOMA DE CELULAS T, PERIFERICO Y CUTANEO'},
{ codigo: 'C840', descripcion: 'MICOSIS FUNGOIDE'},
{ codigo: 'C841', descripcion: 'ENFERMEDAD DE SEZARY'},
{ codigo: 'C842', descripcion: 'LINFOMA DE ZONA T'},
{ codigo: 'C843', descripcion: 'LINFOMA LINFOEPITELIOIDE'},
{ codigo: 'C844', descripcion: 'LINFOMA DE CELULAS T PERIFERICO'},
{ codigo: 'C845', descripcion: 'OTROS LINFOMAS DE CELULAS T Y LOS NO ESPECIFICADOS'},
{ codigo: 'C85', descripcion: 'LINFOMA NO HODGKIN DE OTRO TIPO Y EL NO ESPECIFICADO'},
{ codigo: 'C850', descripcion: 'LINFOSARCOMA'},
{ codigo: 'C851', descripcion: 'LINFOMA DE CELULAS B, SIN OTRA ESPECIFICACION'},
{ codigo: 'C857', descripcion: 'OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN'},
{ codigo: 'C859', descripcion: 'LINFOMA NO HODGKIN, NO ESPECIFICADO'},
{ codigo: 'C88', descripcion: 'ENFERMEDADES INMUNOPROLIFERATIVAS MALIGNAS'},
{ codigo: 'C880', descripcion: 'MACROGLOBULINEMIA DE WALDENSTROM'},
{ codigo: 'C881', descripcion: 'ENFERMEDAD DE CADENA PESADA ALFA'},
{ codigo: 'C882', descripcion: 'ENFERMEDAD DE CADENA PESADA GAMMA'},
{ codigo: 'C883', descripcion: 'ENFERMEDAD INMUNOPROLIFERATIVA DEL INTESTINO DELGADO'},
{ codigo: 'C887', descripcion: 'OTRAS ENFERMEDADES INMUNOPROLIFERATIVAS MALIGNAS'},
{ codigo: 'C889', descripcion: 'ENFERMEDAD INMUNOPROLIFERATIVA MALIGNA, SIN OTRA ESPECIFICACION'},
{ codigo: 'C90', descripcion: 'MIELOMA MULTIPLE Y TUMORES MALIGNOS DE CELULAS PLASMATICAS'},
{ codigo: 'C900', descripcion: 'MIELOMA MULTIPLE'},
{ codigo: 'C901', descripcion: 'LEUCEMIA DE CELULAS PLASMATICAS'},
{ codigo: 'C902', descripcion: 'PLASMOCITOMA, EXTRAMEDULAR'},
{ codigo: 'C91', descripcion: 'LEUCEMIA LINFOIDE'},
{ codigo: 'C910', descripcion: 'LEUCEMIA LINFOBLASTICA AGUDA'},
{ codigo: 'C911', descripcion: 'LEUCEMIA LINFOCITICA CRONICA'},
{ codigo: 'C912', descripcion: 'LEUCEMIA LINFOCITICA SUBAGUDA'},
{ codigo: 'C913', descripcion: 'LEUCEMIA PROLINFOCITICA'},
{ codigo: 'C914', descripcion: 'LEUCEMIA DE CELULAS VELLOSAS'},
{ codigo: 'C915', descripcion: 'LEUCEMIA DE CELULAS T ADULTAS'},
{ codigo: 'C917', descripcion: 'OTRAS LEUCEMIAS LINFOIDES'},
{ codigo: 'C919', descripcion: 'LEUCEMIA LINFOIDE, SIN OTRA ESPECIFICACION'},
{ codigo: 'C92', descripcion: 'LEUCEMIA MIELOIDE'},
{ codigo: 'C920', descripcion: 'LEUCEMIA MIELOIDE AGUDA'},
{ codigo: 'C921', descripcion: 'LEUCEMIA MIELOIDE CRONICA'},
{ codigo: 'C922', descripcion: 'LEUCEMIA MIELOIDE SUBAGUDA'},
{ codigo: 'C923', descripcion: 'SARCOMA MIELOIDE'},
{ codigo: 'C924', descripcion: 'LEUCEMIA PROMIELOCITICA AGUDA'},
{ codigo: 'C925', descripcion: 'LEUCEMIA MIELOMONOCITICA AGUDA'},
{ codigo: 'C927', descripcion: 'OTRAS LEUCEMIAS MIELOIDES'},
{ codigo: 'C929', descripcion: 'LEUCEMIA MIELOIDE, SIN OTRA ESPECIFICACION'},
{ codigo: 'C93', descripcion: 'LEUCEMIA MONOCITICA'},
{ codigo: 'C930', descripcion: 'LEUCEMIA MONOCITICA AGUDA'},
{ codigo: 'C931', descripcion: 'LEUCEMIA MONOCITICA CRONICA'},
{ codigo: 'C932', descripcion: 'LEUCEMIA MONOCITICA SUBAGUDA'},
{ codigo: 'C937', descripcion: 'OTRAS LEUCEMIAS MONOCITICAS'},
{ codigo: 'C939', descripcion: 'LEUCEMIA MONOCITICA, SIN OTRA ESPECIFICACION'},
{ codigo: 'C94', descripcion: 'OTRAS LEUCEMIAS DE TIPO CELULAR ESPECIFICADO'},
{ codigo: 'C940', descripcion: 'ERITREMIA AGUDA Y ERITROLEUCEMIA'},
{ codigo: 'C941', descripcion: 'ERITREMIA CRONICA'},
{ codigo: 'C942', descripcion: 'LEUCEMIA MEGACARIOBLASTICA AGUDA'},
{ codigo: 'C943', descripcion: 'LEUCEMIA DE MASTOCITOS'},
{ codigo: 'C944', descripcion: 'PANMIELOSIS AGUDA'},
{ codigo: 'C945', descripcion: 'MIELOFIBROSIS AGUDA'},
{ codigo: 'C947', descripcion: 'OTRAS LEUCEMIAS ESPECIFICADAS'},
{ codigo: 'C95', descripcion: 'LEUCEMIA DE CELULAS DE TIPO NO ESPECIFICADO'},
{ codigo: 'C950', descripcion: 'LEUCEMIA AGUDA, CELULAS DE TIPO NO ESPECIFICADO'},
{ codigo: 'C951', descripcion: 'LEUCEMIA CRONICA, CELULAS DE TIPO NO ESPECIFICADO'},
{ codigo: 'C952', descripcion: 'LEUCEMIA SUBAGUDA, CELULAS DE TIPO NO ESPECIFICADO'},
{ codigo: 'C957', descripcion: 'OTRAS LEUCEMIAS DE CELULAS DE TIPO NO ESPECIFICADO'},
{ codigo: 'C959', descripcion: 'LEUCEMIA, NO ESPECIFICADA'},
{ codigo: 'C96', descripcion: 'OTROS TUMORES MALIGNOS Y LOS NO ESPECIFICADOS DEL TEJIDO LINFATICO, DE ORGANOS HEMATOPOYETICOS Y TEJIDOS AFINES'},
{ codigo: 'C960', descripcion: 'ENFERMEDAD DE LETTERER-SIWE'},
{ codigo: 'C961', descripcion: 'HISTIOCITOSIS MALIGNA'},
{ codigo: 'C962', descripcion: 'TUMOR MALIGNO DE MASTOCITOS'},
{ codigo: 'C963', descripcion: 'LINFOMA HISTIOCITICO VERDADERO'},
{ codigo: 'C967', descripcion: 'OTROS TUMORES MALIGNOS ESPECIFICADOS DEL TEJIDO LINFATICO, HEMATOPOYETICO Y TEJIDOS AFINES'},
{ codigo: 'C969', descripcion: 'TUMOR MALIGNO DEL TEJIDO LINFATICO, HEMATOPOYETICO Y TEJIDOS AFINES, SIN OTRA ESPECIFICACION'},
{ codigo: 'C97', descripcion: 'TUMORES MALIGNOS (PRIMARIOS) DE SITIOS MULTIPLES INDEPENDIENTES'},
{ codigo: 'D000', descripcion: 'CARCINOMA IN SITU DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE'},
{ codigo: 'D001', descripcion: 'CARCINOMA IN SITU DEL ESOFAGO'},
{ codigo: 'D002', descripcion: 'CARCINOMA IN SITU DEL ESTOMAGO'},
{ codigo: 'D01', descripcion: 'CARCINOMA IN SITU DE OTROS ORGANOS DIGESTIVOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D010', descripcion: 'CARCINOMA IN SITU DEL COLON'},
{ codigo: 'D011', descripcion: 'CARCINOMA IN SITU DE LA UNION RECTOSIGMOIDEA'},
{ codigo: 'D012', descripcion: 'CARCINOMA IN SITU DEL RECTO'},
{ codigo: 'D013', descripcion: 'CARCINOMA IN SITU DEL ANO Y DEL CONDUCTO ANAL'},
{ codigo: 'D014', descripcion: 'CARCINOMA IN SITU DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL INTESTINO'},
{ codigo: 'D015', descripcion: 'CARCINOMA IN SITU DEL HIGADO, DE VESICULA BILIAR Y DEL CONDUCTO BILIAR'},
{ codigo: 'D017', descripcion: 'CARCINOMA IN SITU DE OTRAS PARTES ESPECIFICADAS DE ORGANOS DIGESTIVOS'},
{ codigo: 'D019', descripcion: 'CARCINOMA IN SITU DE ORGANOS DIGESTIVOS NO ESPECIFICADOS'},
{ codigo: 'D02', descripcion: 'CARCINOMA IN SITU DEL SISTEMA RESPIRATORIO Y DEL OIDO MEDIO'},
{ codigo: 'D020', descripcion: 'CARCINOMA IN SITU DE LA LARINGE'},
{ codigo: 'D021', descripcion: 'CARCINOMA IN SITU DE LA TRAQUEA'},
{ codigo: 'D022', descripcion: 'CARCINOMA IN SITU DEL BRONQUIO Y DEL PULMON'},
{ codigo: 'D023', descripcion: 'CARCINOMA IN SITU DE OTRAS PARTES DEL SISTEMA RESPIRATORIO'},
{ codigo: 'D024', descripcion: 'CARCINOMA IN SITU DE ORGANOS RESPIRATORIOS NO ESPECIFICADOS'},
{ codigo: 'D03', descripcion: 'MELANOMA IN SITU'},
{ codigo: 'D030', descripcion: 'MELANOMA IN SITU DEL LABIO'},
{ codigo: 'D031', descripcion: 'MELANOMA IN SITU DEL PARPADO Y DE LA COMISURA PALPEBRAL'},
{ codigo: 'D032', descripcion: 'MELANOMA IN SITU DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO'},
{ codigo: 'D033', descripcion: 'MELANOMA IN SITU DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA'},
{ codigo: 'D034', descripcion: 'MELANOMA IN SITU DEL CUERO CABELLUDO Y DEL CUELLO'},
{ codigo: 'D035', descripcion: 'MELANOMA IN SITU DEL TRONCO'},
{ codigo: 'D036', descripcion: 'MELANOMA IN SITU DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO'},
{ codigo: 'D037', descripcion: 'MELANOMA IN SITU DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA'},
{ codigo: 'D038', descripcion: 'MELANOMA IN SITU DE OTROS SITIOS'},
{ codigo: 'D039', descripcion: 'MELANOMA IN SITU, SITIO NO ESPECIFICADO'},
{ codigo: 'D04', descripcion: 'CARCINOMA IN SITU DE LA PIEL'},
{ codigo: 'D040', descripcion: 'CARCINOMA IN SITU DE LA PIEL DEL LABIO'},
{ codigo: 'D041', descripcion: 'CARCINOMA IN SITU DE LA PIEL DEL PARPADO Y DE LA COMISURA PALPEBRAL'},
{ codigo: 'D042', descripcion: 'CARCINOMA IN SITU DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTER'},
{ codigo: 'D043', descripcion: 'CARCINOMA IN SITU DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA'},
{ codigo: 'D044', descripcion: 'CARCINOMA IN SITU DE LA PIEL DEL CUERO CABELLUDO Y CUELLO'},
{ codigo: 'D045', descripcion: 'CARCINOMA IN SITU DE LA PIEL DEL TRONCO'},
{ codigo: 'D046', descripcion: 'CARCINOMA IN SITU DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO'},
{ codigo: 'D047', descripcion: 'CARCINOMA IN SITU DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA'},
{ codigo: 'D048', descripcion: 'CARCINOMA IN SITU DE LA PIEL DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'D049', descripcion: 'CARCINOMA IN SITU DE LA PIEL, SITIO NO ESPECIFICADO'},
{ codigo: 'D05', descripcion: 'CARCINOMA IN SITU DE LA MAMA'},
{ codigo: 'D050', descripcion: 'CARCINOMA IN SITU LOBULAR'},
{ codigo: 'D051', descripcion: 'CARCINOMA IN SITU INTRACANALICULAR'},
{ codigo: 'D057', descripcion: 'OTROS CARCINOMAS IN SITU DE LA MAMA'},
{ codigo: 'D059', descripcion: 'CARCINOMA IN SITU DE LA MAMA, PARTE NO ESPECIFICADA'},
{ codigo: 'D06', descripcion: 'CARCINOMA IN SITU DEL CUELLO DEL UTERO'},
{ codigo: 'D060', descripcion: 'CARCINOMA IN SITU DEL ENDOCERVIX'},
{ codigo: 'D061', descripcion: 'CARCINOMA IN SITU DEL EXOCERVIX'},
{ codigo: 'D067', descripcion: 'CARCINOMA IN SITU DE OTRAS PARTES ESPECIFICADAS DEL CUELLO DEL UTERO'},
{ codigo: 'D069', descripcion: 'CARCINOMA IN SITU DEL CUELLO DEL UTERO, PARTE NO ESPECIFICADA'},
{ codigo: 'D07', descripcion: 'CARCINOMA IN SITU DE OTROS ORGANOS GENITALES Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D070', descripcion: 'CARCINOMA IN SITU DEL ENDOMETRIO'},
{ codigo: 'D071', descripcion: 'CARCINOMA IN SITU DE LA VULVA'},
{ codigo: 'D072', descripcion: 'CARCINOMA IN SITU DE LA VAGINA'},
{ codigo: 'D073', descripcion: 'CARCINOMA IN SITU DE OTROS SITIOS DE ORGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D074', descripcion: 'CARCINOMA IN SITU DEL PENE'},
{ codigo: 'D075', descripcion: 'CARCINOMA IN SITU DE LA PROSTATA'},
{ codigo: 'D076', descripcion: 'CARCINOMA IN SITU DE OTROS ORGANOS GENITALES MASCULINOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D09', descripcion: 'CARCINOMA IN SITU DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D090', descripcion: 'CARCINOMA IN SITU DE LA VEJIGA'},
{ codigo: 'D091', descripcion: 'CARCINOMA IN SITU DE OTROS ORGANOS URINARIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D092', descripcion: 'CARCINOMA IN SITU DEL OJO'},
{ codigo: 'D093', descripcion: 'CARCINOMA IN SITU DE LA GLANDULA TIROIDES Y DE OTRAS GLANDULA ENDOCRINAS'},
{ codigo: 'D097', descripcion: 'CARCINOMA IN SITU DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'D099', descripcion: 'CARCINOMA IN SITU, SITIO NO ESPECIFICADO'},
{ codigo: 'D10', descripcion: 'TUMOR BENIGNO DE LA BOCA Y DE LA FARINGE'},
{ codigo: 'D100', descripcion: 'TUMOR BENIGNO DEL LABIO'},
{ codigo: 'D101', descripcion: 'TUMOR BENIGNO DE LA LENGUA'},
{ codigo: 'D102', descripcion: 'TUMOR BENIGNO DEL PISO DE LA BOCA'},
{ codigo: 'D103', descripcion: 'TUMOR BENIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA'},
{ codigo: 'D104', descripcion: 'TUMOR BENIGNO DE LA AMIGDALA'},
{ codigo: 'D105', descripcion: 'TUMOR BENIGNO DE OTRAS PARTES DE LA OROFARINGE'},
{ codigo: 'D106', descripcion: 'TUMOR BENIGNO DE LA NASOFARINGE'},
{ codigo: 'D107', descripcion: 'TUMOR BENIGNO DE LA HIPOFARINGE'},
{ codigo: 'D109', descripcion: 'TUMOR BENIGNO DE LA FARINGE, PARTE NO ESPECIFICADA'},
{ codigo: 'D11', descripcion: 'TUMOR BENIGNO DE LAS GLANDULAS SALIVALES MAYORES'},
{ codigo: 'D110', descripcion: 'TUMOR BENIGNO DE LA GLANDULA PAROTIDA'},
{ codigo: 'D117', descripcion: 'TUMOR BENIGNO DE OTRAS GLANDULAS SALIVALES MAYORES ESPECIFICADAS'},
{ codigo: 'D119', descripcion: 'TUMOR BENIGNO DE LA GLANDULA SALIVAL MAYOR, SIN OTRA ESPECIFICACION'},
{ codigo: 'D12', descripcion: 'TUMOR BENIGNO DEL COLON, DEL RECTO, DEL CONDUCTO ANAL Y DEL ANO'},
{ codigo: 'D120', descripcion: 'TUMOR BENIGNO DEL CIEGO'},
{ codigo: 'D121', descripcion: 'TUMOR BENIGNO DEL APENDICE'},
{ codigo: 'D122', descripcion: 'TUMOR BENIGNO DEL COLON ASCENDENTE'},
{ codigo: 'D123', descripcion: 'TUMOR BENIGNO DEL COLON TRANSVERSO'},
{ codigo: 'D124', descripcion: 'TUMOR BENIGNO DEL COLON DESCENDENTE'},
{ codigo: 'D125', descripcion: 'TUMOR BENIGNO DEL COLON SIGMOIDE'},
{ codigo: 'D126', descripcion: 'TUMOR BENIGNO DEL COLON, PARTE NO ESPECIFICADA'},
{ codigo: 'D127', descripcion: 'TUMOR BENIGNO DE LA UNION RECTOSIGMOIDEA'},
{ codigo: 'D128', descripcion: 'TUMOR BENIGNO DEL RECTO'},
{ codigo: 'D129', descripcion: 'TUMOR BENIGNO DEL CONDUCTO ANAL Y DEL ANO'},
{ codigo: 'D13', descripcion: 'TUMOR BENIGNO DE OTRAS PARTES Y DE LAS MAL DEFINIDAS DEL SISTEMA DIGESTIVO'},
{ codigo: 'D130', descripcion: 'TUMOR BENIGNO DEL ESOFAGO'},
{ codigo: 'D131', descripcion: 'TUMOR BENIGNO DEL ESTOMAGO'},
{ codigo: 'D132', descripcion: 'TUMOR BENIGNO DEL DUODENO'},
{ codigo: 'D133', descripcion: 'TUMOR BENIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL INTESTINO DELGADO'},
{ codigo: 'D134', descripcion: 'TUMOR BENIGNO DEL HIGADO'},
{ codigo: 'D135', descripcion: 'TUMOR BENIGNO DE LAS VIAS BILIARES EXTRAHEPATICAS'},
{ codigo: 'D136', descripcion: 'TUMOR BENIGNO DEL PANCREAS'},
{ codigo: 'D137', descripcion: 'TUMOR BENIGNO DEL PANCREAS ENDOCRINO'},
{ codigo: 'D139', descripcion: 'TUMOR BENIGNO DE SITIOS MAL DEFINIDOS DEL SISTEMA DIGESTIVO'},
{ codigo: 'D14', descripcion: 'TUMOR BENIGNO DEL OIDO MEDIO Y DEL SISTEMA RESPIRATORIO'},
{ codigo: 'D140', descripcion: 'TUMOR BENIG OIDO MEDIO, DE LA CAVIDAD NASAL Y DE LOS SENOS PARANASALES'},
{ codigo: 'D141', descripcion: 'TUMOR BENIGNO DE LA LARINGE'},
{ codigo: 'D142', descripcion: 'TUMOR BENIGNO DE LA TRAQUEA'},
{ codigo: 'D143', descripcion: 'TUMOR BENIGNO DE LOS BRONQUIOS Y DEL PULMON'},
{ codigo: 'D144', descripcion: 'TUMOR BENIGNO DEL SISTEMA RESPIRATORIO, SITIO NO ESPECIFICADO'},
{ codigo: 'D15', descripcion: 'TUMOR BENIGNO DE OTROS ORGANOS INTRATORACICOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D150', descripcion: 'TUMOR BENIGNO DEL TIMO'},
{ codigo: 'D151', descripcion: 'TUMOR BENIGNO DEL CORAZON'},
{ codigo: 'D152', descripcion: 'TUMOR BENIGNO DEL MEDIASTINO'},
{ codigo: 'D157', descripcion: 'TUMOR BENIGNO DE OTROS ORGANOS INTRATORACICOS ESPECIFICADOS'},
{ codigo: 'D159', descripcion: 'TUMOR BENIGNO DE ORGANO INTRATORACICO NO ESPECIFICADO'},
{ codigo: 'D16', descripcion: 'TUMOR BENIGNO DEL HUESO Y DEL CARTILAGO ARTICULAR'},
{ codigo: 'D160', descripcion: 'TUMOR BENIGNO DEL OMOPLATO Y HUESOS LARGOS DEL MIEMBRO SUPERIOR'},
{ codigo: 'D161', descripcion: 'TUMOR BENIGNO DE LOS HUESOS CORTOS DEL MIEMBRO SUPERIOR'},
{ codigo: 'D162', descripcion: 'TUMOR BENIGNO DE LOS HUESOS LARGOS DEL MIEMBRO INFERIOR'},
{ codigo: 'D163', descripcion: 'TUMOR BENIGNO DE LOS HUESOS CORTOS DEL MIEMBRO INFERIOR'},
{ codigo: 'D164', descripcion: 'TUMOR BENIGNO DE LOS HUESOS DEL CRANEO Y DE LA CARA'},
{ codigo: 'D165', descripcion: 'TUMOR BENIGNO DEL MAXILAR INFERIOR'},
{ codigo: 'D166', descripcion: 'TUMOR BENIGNO DE LA COLUMNA VERTEBRAL'},
{ codigo: 'D167', descripcion: 'TUMOR BENIGNO DE LAS COSTILLAS, ESTERNON Y CLAVICULA'},
{ codigo: 'D168', descripcion: 'TUMOR BENIGNO DE LOS HUESOS PELVICOS, SACRO Y COCCIX'},
{ codigo: 'D169', descripcion: 'TUMOR BENIGNO DEL HUESO Y DEL CARTILAGO ARTICULAR, SITIO NO ESPECIFICADO'},
{ codigo: 'D17', descripcion: 'TUMORES BENIGNOS LIPOMATOSOS'},
{ codigo: 'D170', descripcion: 'TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DE CABEZA, CARA Y CUELLO'},
{ codigo: 'D171', descripcion: 'TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DEL TRONCO'},
{ codigo: 'D172', descripcion: 'TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DE MIEMBROS'},
{ codigo: 'D173', descripcion: 'TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D174', descripcion: 'TUMOR BENIGNO LIPOMATOSO DE LOS ORGANOS INTRATORACICOS'},
{ codigo: 'D175', descripcion: 'TUMOR BENIGNO LIPOMATOSO DE LOS ORGANOS INTRAABDOMINALES'},
{ codigo: 'D176', descripcion: 'TUMOR BENIGNO LIPOMATOSO DEL CORDON ESPERMATICO'},
{ codigo: 'D177', descripcion: 'TUMOR BENIGNO LIPOMATOSO DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'D179', descripcion: 'TUMOR BENIGNO LIPOMATOSO, DE SITIO NO ESPECIFICADO'},
{ codigo: 'D18', descripcion: 'HEMANGIOMA Y LINFANGIOMA DE CUALQUIER SITIO'},
{ codigo: 'D180', descripcion: 'HEMANGIOMA, DE CUALQUIER SITIO'},
{ codigo: 'D181', descripcion: 'LINFANGIOMA, DE CUALQUIER SITIO'},
{ codigo: 'D19', descripcion: 'TUMORES BENIGNOS DEL TEJIDO MESOTELIAL'},
{ codigo: 'D190', descripcion: 'TUMOR BENIGNO DEL TEJIDO MESOTELIAL DE LA PLEURA'},
{ codigo: 'D191', descripcion: 'TUMOR BENIGNO DEL TEJIDO MESOTELIAL DEL PERITONEO'},
{ codigo: 'D197', descripcion: 'TUMOR BENIGNO DEL TEJIDO MESOTELIAL DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'D199', descripcion: 'TUMOR BENIGNO DEL TEJIDO MESOTELIAL, DE SITIO NO ESPECIFICADO'},
{ codigo: 'D20', descripcion: 'TUMOR BENIGNO DEL TEJIDO BLANDO DEL PERITONEO Y DEL RETROPERITONEO'},
{ codigo: 'D200', descripcion: 'TUMOR BENIGNO DEL RETROPERITONEO'},
{ codigo: 'D201', descripcion: 'TUMOR BENIGNO DEL PERITONEO'},
{ codigo: 'D21', descripcion: 'OTROS TUMORES BENIGNOS DEL TEJIDO CONJUNTIVO Y DE LOS TEJIDOS BLANDOS'},
{ codigo: 'D210', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DE CABEZA, CARA Y CUELLO'},
{ codigo: 'D211', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO'},
{ codigo: 'D212', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA'},
{ codigo: 'D213', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL TORAX'},
{ codigo: 'D214', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DEL ABDOMEN'},
{ codigo: 'D215', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DE LA PELVIS'},
{ codigo: 'D216', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS DEL TRONCO, SIN OTRA ESPECIFICACION'},
{ codigo: 'D219', descripcion: 'TUMOR BENIGNO DEL TEJIDO CONJUNTIVO Y OTROS TEJIDOS BLANDOS, DE SITIO NO ESPECIFICADO'},
{ codigo: 'D22', descripcion: 'NEVO MELANOCITICO'},
{ codigo: 'D220', descripcion: 'NEVO MELANOCITICO DEL LABIO'},
{ codigo: 'D221', descripcion: 'NEVO MELANOCITICO DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL'},
{ codigo: 'D222', descripcion: 'NEVO MELANOCITICO DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO'},
{ codigo: 'D223', descripcion: 'NEVO MELANOCITICO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA'},
{ codigo: 'D224', descripcion: 'NEVO MELANOCITICO DEL CUERO CABELLUDO Y DEL CUELLO'},
{ codigo: 'D225', descripcion: 'NEVO MELANOCITICO DEL TRONCO'},
{ codigo: 'D226', descripcion: 'NEVO MELANOCITICO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO'},
{ codigo: 'D227', descripcion: 'NEVO MELANOCITICO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA'},
{ codigo: 'D229', descripcion: 'NEVO MELANOCITICO, SITIO NO ESPECIFICADO'},
{ codigo: 'D23', descripcion: 'OTROS TUMORES BENIGNOS DE LA PIEL'},
{ codigo: 'D230', descripcion: 'TUMOR BENIGNO DE LA PIEL DEL LABIO'},
{ codigo: 'D231', descripcion: 'TUMOR BENIGNO DE LA PIEL DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL'},
{ codigo: 'D232', descripcion: 'TUMOR BENIGNO DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO'},
{ codigo: 'D233', descripcion: 'TUMOR BENIGNO DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA'},
{ codigo: 'D234', descripcion: 'TUMOR BENIGNO DE LA PIEL DEL CUERO CABELLUDO Y DEL CUELLO'},
{ codigo: 'D235', descripcion: 'TUMOR BENIGNO DE LA PIEL DEL TRONCO'},
{ codigo: 'D236', descripcion: 'TUMOR BENIGNO DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO'},
{ codigo: 'D237', descripcion: 'TUMOR BENIGNO DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA'},
{ codigo: 'D239', descripcion: 'TUMOR BENIGNO DE LA PIEL, SITIO NO ESPECIFICADO'},
{ codigo: 'D24', descripcion: 'TUMOR BENIGNO DE LA MAMA'},
{ codigo: 'D25', descripcion: 'LEIOMIOMA DEL UTERO'},
{ codigo: 'D250', descripcion: 'LEIOMIOMA SUBMUCOSO DEL UTERO'},
{ codigo: 'D251', descripcion: 'LEIOMIOMA INTRAMURAL DEL UTERO'},
{ codigo: 'D252', descripcion: 'LEIOMIOMA SUBSEROSO DEL UTERO'},
{ codigo: 'D259', descripcion: 'LEIOMIOMA DEL UTERO, SIN OTRA ESPECIFICACION'},
{ codigo: 'D26', descripcion: 'OTROS TUMORES BENIGNOS DEL UTERO'},
{ codigo: 'D260', descripcion: 'TUMOR BENIGNO DEL CUELLO DEL UTERO'},
{ codigo: 'D261', descripcion: 'TUMOR BENIGNO DEL CUERPO DEL UTERO'},
{ codigo: 'D267', descripcion: 'TUMOR BENIGNO DE OTRAS PARTES ESPECIFICADAS DEL UTERO'},
{ codigo: 'D269', descripcion: 'TUMOR BENIGNO DEL UTERO, PARTE NO ESPECIFICADA'},
{ codigo: 'D27', descripcion: 'TUMOR BENIGNO DEL OVARIO'},
{ codigo: 'D28', descripcion: 'TUMOR BENIGNO DE OTROS ORGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D280', descripcion: 'TUMOR BENIGNO DE LA VULVA'},
{ codigo: 'D281', descripcion: 'TUMOR BENIGNO DE LA VAGINA'},
{ codigo: 'D282', descripcion: 'TUMOR BENIGNO DE LA TROMPA DE FALOPIO Y DE LOS LIGAMENTOS UTERINOS'},
{ codigo: 'D287', descripcion: 'TUMOR BENIGNO DE OTROS SITIOS ESPECIFICADOS DE LOS ORGANOS GENITALES FEMENINOS'},
{ codigo: 'D289', descripcion: 'TUMOR BENIGNO DE ORGANO GENITAL FEMENINO, SITIO NO ESPECIFICADO'},
{ codigo: 'D29', descripcion: 'TUMOR BENIGNO DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'D290', descripcion: 'TUMOR BENIGNO DEL PENE'},
{ codigo: 'D291', descripcion: 'TUMOR BENIGNO DE LA PROSTATA'},
{ codigo: 'D292', descripcion: 'TUMOR BENIGNO DE LOS TESTICULOS'},
{ codigo: 'D293', descripcion: 'TUMOR BENIGNO DEL EPIDIDIMO'},
{ codigo: 'D294', descripcion: 'TUMOR BENIGNO DEL ESCROTO'},
{ codigo: 'D297', descripcion: 'TUMOR BENIGNO DE OTROS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'D299', descripcion: 'TUMOR BENIGNO DE ORGANO GENITAL MASCULINO, SITIO NO ESPECIFICADO'},
{ codigo: 'D30', descripcion: 'TUMOR BENIGNO DE LOS ORGANOS URINARIOS'},
{ codigo: 'D300', descripcion: 'TUMOR BENIGNO DEL RI—ON'},
{ codigo: 'D301', descripcion: 'TUMOR BENIGNO DE LA PELVIS RENAL'},
{ codigo: 'D302', descripcion: 'TUMOR BENIGNO DEL URETER'},
{ codigo: 'D303', descripcion: 'TUMOR BENIGNO DE LA VEJIGA'},
{ codigo: 'D304', descripcion: 'TUMOR BENIGNO DE LA URETRA'},
{ codigo: 'D307', descripcion: 'TUMOR BENIGNO DE OTROS ORGANOS URINARIOS'},
{ codigo: 'D309', descripcion: 'TUMOR BENIGNO DE ORGANO URINARIO NO ESPECIFICADO'},
{ codigo: 'D31', descripcion: 'TUMOR BENIGNO DEL OJO Y SUS ANEXOS'},
{ codigo: 'D310', descripcion: 'TUMOR BENIGNO DE LA CONJUNTIVA'},
{ codigo: 'D311', descripcion: 'TUMOR BENIGNO DE LA CORNEA'},
{ codigo: 'D312', descripcion: 'TUMOR BENIGNO DE LA RETINA'},
{ codigo: 'D313', descripcion: 'TUMOR BENIGNO DE LA COROIDES'},
{ codigo: 'D314', descripcion: 'TUMOR BENIGNO DEL CUERPO CILIAR'},
{ codigo: 'D315', descripcion: 'TUMOR BENIGNO DE LAS GLANDULAS Y DE LOS CONDUCTOS LAGRIMALES'},
{ codigo: 'D316', descripcion: 'TUMOR BENIGNO DE LA ORBITA, PARTE NO ESPECIFICADA'},
{ codigo: 'D319', descripcion: 'TUMOR BENIGNO DEL OJO, PARTE NO ESPECIFICADA'},
{ codigo: 'D32', descripcion: 'TUMORES BENIGNOS DE LAS MENINGES'},
{ codigo: 'D320', descripcion: 'TUMOR BENIGNO DE LAS MENINGES CEREBRALES'},
{ codigo: 'D321', descripcion: 'TUMOR BENIGNO DE LAS MENINGES RAQUIDEAS'},
{ codigo: 'D329', descripcion: 'TUMOR BENIGNO DE LAS MENINGES, PARTE NO ESPECIFICADA'},
{ codigo: 'D33', descripcion: 'TUMOR BENIGNO DEL ENCEFALO Y DE OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'D330', descripcion: 'TUMOR BENIGNO DEL ENCEFALO, SUPRATENTORIAL'},
{ codigo: 'D331', descripcion: 'TUMOR BENIGNO DEL ENCEFALO, INFRATENTORIAL'},
{ codigo: 'D332', descripcion: 'TUMOR BENIGNO DEL ENCEFALO, PARTE NO ESPECIFICADA'},
{ codigo: 'D333', descripcion: 'TUMOR BENIGNO DE LOS NERVIOS CRANEALES'},
{ codigo: 'D334', descripcion: 'TUMOR BENIGNO DE LA MEDULA ESPINAL'},
{ codigo: 'D337', descripcion: 'TUMOR BENIGNO DE OTRAS PARTES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'D339', descripcion: 'TUMOR BENIGNO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIFICADO'},
{ codigo: 'D34', descripcion: 'TUMOR BENIGNO DE LA GLANDULA TIROIDES'},
{ codigo: 'D35', descripcion: 'TUMOR BENIGNO DE OTRAS GLANDULAS ENDOCRINAS Y DE LAS NO ESPECIFICADAS'},
{ codigo: 'D350', descripcion: 'TUMOR BENIGNO DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'D351', descripcion: 'TUMOR BENIGNO DE LA GLANDULA PARATIROIDES'},
{ codigo: 'D352', descripcion: 'TUMOR BENIGNO DE LA HIPOFISIS'},
{ codigo: 'D353', descripcion: 'TUMOR BENIGNO DEL CONDUCTO CRANEOFARINGEO'},
{ codigo: 'D354', descripcion: 'TUMOR BENIGNO DE LA GLANDULA PINEAL'},
{ codigo: 'D355', descripcion: 'TUMOR BENIGNO DEL CUERPO CAROTIDEO'},
{ codigo: 'D356', descripcion: 'TUMOR BENIGNO DEL CUERPO AORTICO Y DE OTROS CUERPOS CROMAFINES'},
{ codigo: 'D357', descripcion: 'TUMOR BENIGNO DE OTRAS GLANDULAS ENDOCRINAS ESPECIFICADAS'},
{ codigo: 'D358', descripcion: 'TUMOR BENIGNO PLURIGLANDULAR'},
{ codigo: 'D359', descripcion: 'TUMOR BENIGNO DE GLANDULA ENDOCRINA NO ESPECIFICADA'},
{ codigo: 'D36', descripcion: 'TUMOR BENIGNO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D360', descripcion: 'TUMOR BENIGNO DE LOS GANGLIOS LINFATICOS'},
{ codigo: 'D361', descripcion: 'TUMOR BENIGNO DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'D367', descripcion: 'TUMOR BENIGNO DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'D369', descripcion: 'TUMOR BENIGNO DE SITIO NO ESPECIFICADO'},
{ codigo: 'D37', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA CAVIDAD BUCAL Y DE LOS ORGANOS DIGESTIVOS'},
{ codigo: 'D370', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL LABIO, DE LA CAVIDAD BUCAL Y LA FARINGE'},
{ codigo: 'D371', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ESTOMAGO'},
{ codigo: 'D372', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL INTESTINO DELGADO'},
{ codigo: 'D373', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL APENDICE'},
{ codigo: 'D374', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL COLON'},
{ codigo: 'D375', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RECTO'},
{ codigo: 'D376', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL HIGADO, LA VESICULA Y DEL CONDUCTO BILIAR'},
{ codigo: 'D377', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS DIGESTIVOS ESPECIFICADOS'},
{ codigo: 'D379', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANOS DIGESTIVOS, SITIO NO ESPECIFICADO'},
{ codigo: 'D38', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL OIDO MEDIO Y DE LOS ORGANOS RESPIRATORIOS INTRATORACICOS'},
{ codigo: 'D380', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LARINGE'},
{ codigo: 'D381', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE TRAQUEA, BRONQUIOS Y DE PULMON'},
{ codigo: 'D382', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PLEURA'},
{ codigo: 'D383', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL MEDIASTINO'},
{ codigo: 'D384', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TIMO'},
{ codigo: 'D385', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS RESPIRATORIOS Y DEL OIDO MEDIO'},
{ codigo: 'D386', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANOS RESPIRATORIOS NO ESPECIFICADOS'},
{ codigo: 'D39', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS ORGANOS GENITALES FEMENINOS'},
{ codigo: 'D390', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL UTERO'},
{ codigo: 'D391', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL OVARIO'},
{ codigo: 'D392', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PLACENTA'},
{ codigo: 'D397', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS GENITALES FEMENINOS'},
{ codigo: 'D399', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANO GENITAL FEMENINO NO ESPECIFICADO'},
{ codigo: 'D40', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANOS GENITALES MASCULINOS'},
{ codigo: 'D400', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PROSTATA'},
{ codigo: 'D401', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TESTICULO'},
{ codigo: 'D407', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'D409', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANO GENITAL MASCULINO NO ESPECIFICADO'},
{ codigo: 'D41', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS ORGANOS URINARIOS'},
{ codigo: 'D410', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RI—ON'},
{ codigo: 'D411', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PELVIS RENAL'},
{ codigo: 'D412', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL URETER'},
{ codigo: 'D413', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA URETRA'},
{ codigo: 'D414', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA VEJIGA'},
{ codigo: 'D417', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS URINARIOS'},
{ codigo: 'D419', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANO URINARIO NO ESPECIFICADO'},
{ codigo: 'D42', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES'},
{ codigo: 'D420', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES CEREBRALES'},
{ codigo: 'D421', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES RAQUIDEAS'},
{ codigo: 'D429', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES, PARTE NO ESPECIFICADA'},
{ codigo: 'D43', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO Y DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'D430', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, SUPRATENTORIAL'},
{ codigo: 'D431', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, INFRATENTORIAL'},
{ codigo: 'D432', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, PARTE NO ESPECIFICADA'},
{ codigo: 'D433', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS NERVIOS CRANEALES'},
{ codigo: 'D434', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MEDULA ESPINAL'},
{ codigo: 'D437', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTRAS PARTES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'D439', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIFICADO'},
{ codigo: 'D44', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS GLANDULAS ENDOCRINAS'},
{ codigo: 'D440', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA TIROIDES'},
{ codigo: 'D441', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'D442', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA PARATIROIDES'},
{ codigo: 'D443', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA HIPOFISIS'},
{ codigo: 'D444', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CONDUCTO CRANEOFARINGEO'},
{ codigo: 'D445', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA PINEAL'},
{ codigo: 'D446', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CUERPO CAROTIDEO'},
{ codigo: 'D447', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CUERPO AORTICO Y CUERPOS CROMAFINES'},
{ codigo: 'D448', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO CON AFECTACION PLURIGLANDULAR'},
{ codigo: 'D449', descripcion: 'TUMOR DE COMPORTAM INCIERTO O DESCONOCIDO DE GLANDULA ENDOCRINA NO ESPECIFICADA'},
{ codigo: 'D45', descripcion: 'POLICITEMIA VERA'},
{ codigo: 'D46', descripcion: 'SINDROMES MIELODISPLASICOS'},
{ codigo: 'D460', descripcion: 'ANEMIA REFRACTARIA SIN SIDEROBLASTOS, ASI DESCRITA'},
{ codigo: 'D461', descripcion: 'ANEMIA REFRACTARIA CON SIDEROBLASTOS'},
{ codigo: 'D462', descripcion: 'ANEMIA REFRACTARIA CON EXCESO DE BLASTOS'},
{ codigo: 'D463', descripcion: 'ANEMIA REFRACTARIA CON EXCESO DE BLASTOS CON TRANSFORMACION'},
{ codigo: 'D464', descripcion: 'ANEMIA REFRACTARIA, SIN OTRA ESPECIFICACION'},
{ codigo: 'D467', descripcion: 'OTROS SINDROMES MIELODISPLASICOS'},
{ codigo: 'D469', descripcion: 'SINDROME MIELODISPLASICO, SIN OTRA ESPECIFICACION'},
{ codigo: 'D47', descripcion: 'OTROS TUMORES DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFATICO, ORG HEMATOPOYETICOS Y DE TEJIDOS AFINES'},
{ codigo: 'D470', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS MASTOCITOS E HISTIOCITOS'},
{ codigo: 'D471', descripcion: 'ENFERMEDAD MIELOPROLIFERATIVA CRONICA'},
{ codigo: 'D472', descripcion: 'GAMMOPATIA MONOCLONAL'},
{ codigo: 'D473', descripcion: 'TROMBOCITOPENIA (HEMORRAGICA) ESENCIAL'},
{ codigo: 'D477', descripcion: 'OTROS TUMORES ESPECIFICADOS DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFATICO, ORGANOS HEMATOPOYETICOS Y TEJIDOS AFINES'},
{ codigo: 'D479', descripcion: 'TUMORES DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFATTICO, ORGANOS HEMATOPOYETICOS Y TEJIDOS AFINES NO ESPECIFICADOS'},
{ codigo: 'D48', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'D480', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL HUESO Y CARTILAGO ARTICULAR'},
{ codigo: 'D481', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO CONJUNTIVO Y OTRO TEJIDO BLANDO'},
{ codigo: 'D482', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'D483', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RETROPERITONEO'},
{ codigo: 'D484', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL PERITONEO'},
{ codigo: 'D485', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PIEL'},
{ codigo: 'D486', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MAMA'},
{ codigo: 'D487', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'D489', descripcion: 'TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO, DE SITIO NO ESPECIFICADO'},
{ codigo: 'D50', descripcion: 'ANEMIAS POR DEFICIENCIA DE HIERRO'},
{ codigo: 'D500', descripcion: 'ANEMIA POR DEFICIENCIA DE HIERRO SECUNDARIA A PERDIDA DE SANGRE (CRONICA)'},
{ codigo: 'D501', descripcion: 'DISFAGIA SIDEROPENICA'},
{ codigo: 'D508', descripcion: 'OTRAS ANEMIAS POR DEFICIENCIA DE HIERRO'},
{ codigo: 'D509', descripcion: 'ANEMIA POR DEFICIENCIA DE HIERRO SIN OTRA ESPECIFICACION'},
{ codigo: 'D51', descripcion: 'ANEMIA POR DEFICIENCIA DE VITAMINA B12'},
{ codigo: 'D510', descripcion: 'ANEMIA POR DEFICIENCIA DE VITAMINA B12 DEBIDA A DEFICIENCIA DEL FACTOR INTRINSECO'},
{ codigo: 'D511', descripcion: 'ANEMIA POR DEFICIENCIA DE VITAMINA B12 DEBIDA A MALA ABSORCION SELECTIVA DE VITAMINA B12 CON PROTEINURIA'},
{ codigo: 'D512', descripcion: 'DEFICIENCIA DE TRASCOBALAMINA II'},
{ codigo: 'D513', descripcion: 'OTRAS ANEMIAS POR DEFICIENCIA DIETETICA DE VITAMINA B12'},
{ codigo: 'D518', descripcion: 'OTRAS ANEMIAS POR DEFICIENCIA DE VITAMINA B12'},
{ codigo: 'D519', descripcion: 'ANEMIA POR DEFICIENCIA DE VITAMINA B12, SIN OTRA ESPECIFICACION'},
{ codigo: 'D52', descripcion: 'ANEMIA POR DEFICIENCIA DE FOLATOS'},
{ codigo: 'D520', descripcion: 'ANEMIA POR DEFICIENCIA DIETETICA DE FOLATOS'},
{ codigo: 'D521', descripcion: 'ANEMIA POR DEFICIENCIA DE FOLATOS INDUCIDA POR DROGAS'},
{ codigo: 'D528', descripcion: 'OTRAS ANEMIAS POR DEFICIENCIA DE FOLATOS'},
{ codigo: 'D529', descripcion: 'ANEMIA POR DEFICIENCIA DE FOLATOS, SIN OTRA ESPECIFICACION'},
{ codigo: 'D53', descripcion: 'OTRAS ANEMIAS NUTRICIONALES'},
{ codigo: 'D530', descripcion: 'ANEMIA POR DEFICIENCIA DE PROTEINAS'},
{ codigo: 'D531', descripcion: 'OTRAS ANEMIAS MEGALOBLASTICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'D532', descripcion: 'ANEMIA ESCORBUTICA'},
{ codigo: 'D538', descripcion: 'OTRAS ANEMIAS NUTRICIONALES ESPECIFICADAS'},
{ codigo: 'D539', descripcion: 'ANEMIA NUTRICIONAL, NO ESPECIFICADA'},
{ codigo: 'D55', descripcion: 'ANEMIA DEBIDA A TRASTORNOS ENZIMATICOS'},
{ codigo: 'D550', descripcion: 'ANEMIA DEBIDA A DEFICIENCIA DE GLUCOSA-6-FOSFATO DESHIDROGENASA (G6FD)'},
{ codigo: 'D551', descripcion: 'ANEMIA DEBIDA A OTROS TRASTORNOS DEL METABOLISMO DEL GLUTATION'},
{ codigo: 'D552', descripcion: 'ANEMIA DEBIDA A TRASTORNOS DE LAS ENZIMAS GLUCOLITICAS'},
{ codigo: 'D553', descripcion: 'ANEMIA DEBIDA A TRASTORNOS DEL METABOLISMO DE LOS NUCLEOTIDOS'},
{ codigo: 'D558', descripcion: 'OTRAS ANEMIAS DEBIDAS A TRASTORNOS ENZIMATICOS'},
{ codigo: 'D559', descripcion: 'ANEMIA DEBIDA A TRASTORNOS ENZIMATICOS, SIN OTRA ESPECIFICACION'},
{ codigo: 'D56', descripcion: 'TALASEMIA'},
{ codigo: 'D560', descripcion: 'ALFA TALASEMIA'},
{ codigo: 'D561', descripcion: 'BETA TALASEMIA'},
{ codigo: 'D562', descripcion: 'DELTA-BETA TALASEMIA'},
{ codigo: 'D563', descripcion: 'RASGO TALASEMICO'},
{ codigo: 'D564', descripcion: 'PERSISTENCIA HEREDITARIA DE LA HEMOGLOBINA FETAL (PHHF)'},
{ codigo: 'D568', descripcion: 'OTRAS TALASEMIAS'},
{ codigo: 'D569', descripcion: 'TALASEMIA, NO ESPECIFICADA'},
{ codigo: 'D57', descripcion: 'TRASTORNOS FALCIFORMES'},
{ codigo: 'D570', descripcion: 'ANEMIA FALCIFORME CON CRISIS'},
{ codigo: 'D571', descripcion: 'ANEMIA FALCIFORME SIN CRISIS'},
{ codigo: 'D572', descripcion: 'TRASTORNOS FALCIFORMES HETEROCIGOTOS DOBLES'},
{ codigo: 'D573', descripcion: 'RASGO DREPANOCITICO'},
{ codigo: 'D578', descripcion: 'OTROS TRASTORNOS FALCIFORMES'},
{ codigo: 'D58', descripcion: 'OTRAS ANEMIAS HEMOLITICAS HEREDITARIAS'},
{ codigo: 'D580', descripcion: 'ESFEROCITOSIS HEREDITARIA'},
{ codigo: 'D581', descripcion: 'ELIPTOCITOSIS HEREDITARIA'},
{ codigo: 'D582', descripcion: 'OTRAS HEMOGLOBINOPATIAS'},
{ codigo: 'D588', descripcion: 'OTRAS ANEMIAS HEMOLITICAS HEREDITARIAS ESPECIFICADAS'},
{ codigo: 'D589', descripcion: 'ANEMIA HEMOLITICA HEREDITARIA, SIN OTRA ESPECIFICACION'},
{ codigo: 'D59', descripcion: 'ANEMIA HEMOLITICA ADQUIRIDA'},
{ codigo: 'D590', descripcion: 'ANEMIA HEMOLITICA AUTOINMUNE INDUCIDA POR DROGAS'},
{ codigo: 'D591', descripcion: 'OTRAS ANEMIAS HEMOLITICAS AUTOINMUNES'},
{ codigo: 'D592', descripcion: 'ANEMIA HEMOLITICA NO AUTOINMUNE INDUCIDA POR DROGAS'},
{ codigo: 'D593', descripcion: 'SINDROME HEMOLITICO-UREMICO'},
{ codigo: 'D594', descripcion: 'OTRAS ANEMIAS HEMOLITICAS NO AUTOINMUNES'},
{ codigo: 'D595', descripcion: 'HEMOGLOBINURIA PAROXISTICA NOCTURNA (MARCHIAFAVA-MICHELI)'},
{ codigo: 'D596', descripcion: 'HEMOGLOBINURIA DEBIDA A HEMOLISIS POR OTRAS CAUSAS EXTERNAS'},
{ codigo: 'D598', descripcion: 'OTRAS ANEMIAS HEMOLITICAS ADQUIRIDAS'},
{ codigo: 'D599', descripcion: 'ANEMIA HEMOLITICA ADQUIRIDA, SIN OTRA ESPECIFICACION'},
{ codigo: 'D60', descripcion: 'APLASIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA (ERITROBLASTOPENIA)'},
{ codigo: 'D600', descripcion: 'APLASIA CRONICA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA'},
{ codigo: 'D601', descripcion: 'APLASIA TRANSITORIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA'},
{ codigo: 'D608', descripcion: 'OTRAS APLASIAS ADQUIRIDAS, EXCLUSIVAS DE LA SERIE ROJA'},
{ codigo: 'D609', descripcion: 'APLASIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA, NO ESPECIFICADA'},
{ codigo: 'D61', descripcion: 'OTRAS ANEMIAS APLASTICAS'},
{ codigo: 'D610', descripcion: 'ANEMIA APLASTICA CONSTITUCIONAL'},
{ codigo: 'D611', descripcion: 'ANEMIA APLASTICA INDUCIDA POR DROGAS'},
{ codigo: 'D612', descripcion: 'ANEMIA APLASTICA DEBIDA A OTROS AGENTES EXTERNOS'},
{ codigo: 'D613', descripcion: 'ANEMIA APLASTICA IDIOPATICA'},
{ codigo: 'D618', descripcion: 'OTRAS ANEMIAS APLASTICAS ESPECIFICADAS'},
{ codigo: 'D619', descripcion: 'ANEMIA APLASTICA, SIN OTRA ESPECIFICACION'},
{ codigo: 'D62', descripcion: 'ANEMIA POSTHEMORRAGICA AGUDA'},
{ codigo: 'D63', descripcion: 'ANEMIA EN ENFERMEDADES CRONICAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'D630', descripcion: 'ANEMIA EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'D638', descripcion: 'ANEMIA EN OTRAS ENFERMEDADES CRONICAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'D64', descripcion: 'OTRAS ANEMIAS'},
{ codigo: 'D640', descripcion: 'ANEMIA SIDEROBLASTICA HEREDITARIA'},
{ codigo: 'D641', descripcion: 'ANEMIA SIDEROBLASTICA SECUNDARIA A OTRA ENFERMEDAD'},
{ codigo: 'D642', descripcion: 'ANEMIA SIDEROBLASTICA SECUNDARIA, DEBIDA A DROGAS Y TOXINAS'},
{ codigo: 'D643', descripcion: 'OTRAS ANEMIAS SIDEROBLASTICAS'},
{ codigo: 'D644', descripcion: 'ANEMIA DISERITROPOYETICA CONGENITA'},
{ codigo: 'D648', descripcion: 'OTRAS ANEMIAS ESPECIFICADAS'},
{ codigo: 'D649', descripcion: 'ANEMIA DE TIPO NO ESPECIFICADO'},
{ codigo: 'D65', descripcion: 'COAGULACION INTRAVASCULAR DISEMINADA (SINDROME DE DESFIBRINACION)'},
{ codigo: 'D66', descripcion: 'DEFICIENCIA HEREDITARIA DEL FACTOR VIII'},
{ codigo: 'D67', descripcion: 'DEFICIENCIA HEREDITARIA DEL FACTOR IX'},
{ codigo: 'D68', descripcion: 'OTROS DEFECTOS DE LA COAGULACION'},
{ codigo: 'D680', descripcion: 'ENFERMEDAD DE VON WILLEBRAND'},
{ codigo: 'D681', descripcion: 'DEFICIENCIA HEREDITARIA DEL FACTOR XI'},
{ codigo: 'D682', descripcion: 'DEFICIENCIA HEREDITARIA DE OTROS FACTORES DE LA COAGULACION'},
{ codigo: 'D683', descripcion: 'TRASTORNO HEMORRAGICO DEBIDO A ANTICOAGULANTES CIRCULANTES'},
{ codigo: 'D684', descripcion: 'DEFICIENCIA ADQUIRIDA DE FACTORES DE LA COAGULACION'},
{ codigo: 'D688', descripcion: 'OTROS DEFECTOS ESPECIFICADOS DE LA COAGULACION'},
{ codigo: 'D689', descripcion: 'DEFECTO DE LA COAGULACION, NO ESPECIFICADO'},
{ codigo: 'D69', descripcion: 'PURPURA Y OTRAS AFECCIONES HEMORRAGICAS'},
{ codigo: 'D690', descripcion: 'PURPURA ALERGICA'},
{ codigo: 'D691', descripcion: 'DEFECTOS CUALITATIVOS DE LAS PLAQUETAS'},
{ codigo: 'D692', descripcion: 'OTRAS PURPURAS NO TROMBOCITOPENICAS'},
{ codigo: 'D693', descripcion: 'PUPURA TROMBOCITOPENICA IDIOPATICA'},
{ codigo: 'D694', descripcion: 'OTRAS TROMBOCITOPENIAS PRIMARIAS'},
{ codigo: 'D695', descripcion: 'TOMBOCITOPENIA SECUNDARIA'},
{ codigo: 'D696', descripcion: 'TROMBOCITOPENIA NO ESPECIFICADA'},
{ codigo: 'D698', descripcion: 'OTRAS AFECCIONES HEMORRAGICAS ESPECIFICADAS'},
{ codigo: 'D699', descripcion: 'AFECCION HEMORRAGICA, NO ESPECIFICADA'},
{ codigo: 'D70', descripcion: 'AGRANULOCITOSIS'},
{ codigo: 'D71', descripcion: 'TRASTORNOS FUNCIONALES DE LOS POLIMORFONUCLEARES NEUTROFILOS'},
{ codigo: 'D72', descripcion: 'OTROS TRASTORNOS DE LOS LEUCOCITOS'},
{ codigo: 'D720', descripcion: 'ANOMALIAS GENETICAS DE LOS LEUCOCITOS'},
{ codigo: 'D721', descripcion: 'EOSINOFILIA'},
{ codigo: 'D728', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LOS LEUCOCITOS'},
{ codigo: 'D729', descripcion: 'TRASTORNO DE LOS LEUCOCITOS, NO ESPECIFICADO'},
{ codigo: 'D73', descripcion: 'ENFERMEDADES DEL BAZO'},
{ codigo: 'D730', descripcion: 'HIPOESPLENISMO'},
{ codigo: 'D731', descripcion: 'HIPERESPLENISMO'},
{ codigo: 'D732', descripcion: 'ESPLENOMEGALIA CONGESTIVA CRONICA'},
{ codigo: 'D733', descripcion: 'ABSCESO DEL BAZO'},
{ codigo: 'D734', descripcion: 'QUISTE DEL BAZO'},
{ codigo: 'D735', descripcion: 'INFARTO DEL BAZO'},
{ codigo: 'D738', descripcion: 'OTRAS ENFERMEDADES DEL BAZO'},
{ codigo: 'D739', descripcion: 'ENFERMEDAD DEL BAZO, NO ESPECIFICADA'},
{ codigo: 'D74', descripcion: 'METAHEMOGLOBINEMIA'},
{ codigo: 'D740', descripcion: 'METAHEMOGLOBINEMIA CONGENITA'},
{ codigo: 'D748', descripcion: 'OTRAS METAHEMOGLOBINEMIAS'},
{ codigo: 'D749', descripcion: 'METAHEMOGLOBINEMIA, NO ESPECIFICADA'},
{ codigo: 'D75', descripcion: 'OTRAS ENFERMEDADES DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS'},
{ codigo: 'D750', descripcion: 'ERITROCITOSIS FAMILIAR'},
{ codigo: 'D751', descripcion: 'POLICITEMIA SECUNDARIA'},
{ codigo: 'D752', descripcion: 'TROMBOCITOSIS ESENCIAL'},
{ codigo: 'D758', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS'},
{ codigo: 'D759', descripcion: 'ENFERMEDAD DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS, NO ESPECIFICADA'},
{ codigo: 'D76', descripcion: 'CIERTAS ENFERMEDADES QUE AFECTAN AL TEJIDO LINFORRETICULAR Y AL SISTEMA RETICULOENDOTELIAL'},
{ codigo: 'D760', descripcion: 'HISTIOCITOSIS DE LAS CELULAS DE LANGERHANS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'D761', descripcion: 'LINFOHISTIOCITOSIS HEMOFAGOCITICA'},
{ codigo: 'D762', descripcion: 'SINDROME HEMOFAGOCITICO ASOCIADO A INFECCION'},
{ codigo: 'D763', descripcion: 'OTROS SINDROMES HISTIOCITICOS'},
{ codigo: 'D77', descripcion: 'OTROS TRASTORNOS DE LA SANGRE Y DE ORGANOS HEMATOPOYETICOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'D80', descripcion: 'INMUNODEFICIENCIA CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS'},
{ codigo: 'D800', descripcion: 'HIPOGAMMAGLOBULINEMIA HEREDITARIA'},
{ codigo: 'D801', descripcion: 'HIPOGAMMAGLOBULINEMIA NO FAMILIAR'},
{ codigo: 'D802', descripcion: 'DEFICIENCIA SELECTIVA DE INMUNOGLOBULINA A (IGA)'},
{ codigo: 'D803', descripcion: 'DEFICIENCIA SELECTIVA DE SUBCLASES DE LA INMUNOGLOBULINA G (IGG)'},
{ codigo: 'D804', descripcion: 'DEFICIENCIA SELECTIVA DE INMUNOGLOBULINA M (IGM)'},
{ codigo: 'D805', descripcion: 'INMUNODEFICIENCIA CON INCREMENTO DE INMUNOGLOBULINA M (IGM)'},
{ codigo: 'D806', descripcion: 'DEFICIENCIA DE ANTICUERPOS INMUNOGLOBULINAS CASI NORMAL O CON HIPERINMUNOGLOBULINEMIA'},
{ codigo: 'D807', descripcion: 'HIPOGAMMAGLOBULINEMIA TRANSITORIA DE LA INFANCIA'},
{ codigo: 'D808', descripcion: 'OTRAS INMUNODEFICIENCIAS CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS'},
{ codigo: 'D809', descripcion: 'INMUNODEFICIENCIA CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS, NO ESPECIFICADA'},
{ codigo: 'D81', descripcion: 'INMUNODEFICIENCIAS COMBINADAS'},
{ codigo: 'D810', descripcion: 'INMUNODEFICIENCIA COMBINADA SEVERA (IDCS) CON DISGENESIA RETICULAR'},
{ codigo: 'D811', descripcion: 'INMUNODEFICIENCIA COMBINADA SEVERA (IDCS) CON LINFOCITOPENIA T Y B'},
{ codigo: 'D812', descripcion: 'INMUNODEFICIENCIA COMBINADA SEVERA (IDCS) CON CIFRA BAJA O NORMAL DE LINFOCITOS B'},
{ codigo: 'D813', descripcion: 'DEFICIENCIA DE LA ADENOSINA DEAMINASA (ADA)'},
{ codigo: 'D814', descripcion: 'SINDROME DE NEZELOF'},
{ codigo: 'D815', descripcion: 'DEFICIENCIA DE LA FOSFORILASA PURINONUCLEOSIDA (FPN)'},
{ codigo: 'D816', descripcion: 'DEFICIENCIA DE LA CLASE I DEL COMPLEJO DE HISTOCOMPATIBILIDAD MAYOR'},
{ codigo: 'D817', descripcion: 'DEFICIENCIA DE LA CLASE II DEL COMPLEJO DE HISTOCOMPATIBILIDAD MAYOR'},
{ codigo: 'D818', descripcion: 'OTRAS INMUNODEFICIENCIAS COMBINADAS'},
{ codigo: 'D819', descripcion: 'INMUNODEFICIENCIA COMBINADA, NO ESPECIFICADA'},
{ codigo: 'D82', descripcion: 'INMUNODEFICIENCIA ASOCIADA CON OTROS DEFECTOS MAYORES'},
{ codigo: 'D820', descripcion: 'SINDROME DE WISKOTT-ALDRICH'},
{ codigo: 'D821', descripcion: 'SINDROME DE DI GEORGE'},
{ codigo: 'D822', descripcion: 'INMUNODEFICIENCIA CON ENANISMO MICROMELICO (MIEMBROS CORTOS)'},
{ codigo: 'D823', descripcion: 'INMUNODEFICIENCIA CONSECUTIVA A RESPUESTA DEFECTUOSA HEREDITARIA CONTRA EL VIRUS EPSTEIN-BARR'},
{ codigo: 'D824', descripcion: 'SINDROME DE HIPERINMUNOGLOBULINA E (IGE)'},
{ codigo: 'D828', descripcion: 'INMUNODEFICIENCIA ASOCIADA CON OTROS DEFECTOS MAYORES ESPECIFICADOS'},
{ codigo: 'D829', descripcion: 'INMUNODEFICIENCIA ASOCIADA CON DEFECTOS MAYORES NO ESPECIFICADOS'},
{ codigo: 'D83', descripcion: 'INMUNODEFICIENCIA VARIABLE COMUN'},
{ codigo: 'D830', descripcion: 'INMUNODEFICIENCIA VARIABLE COMUN CON PREDOMINIO DE ANORMALIDADES EN EL NUMERO Y LA FUNCION DE LOS LINFOCITOS B'},
{ codigo: 'D831', descripcion: 'INMUNODEFICIENCIA VARIABLE COMUN CON PREDOMINIO DE TRASTORNOS INMUNORREGULADORES DE LOS LINFOCITOS T'},
{ codigo: 'D832', descripcion: 'INMUNODEFICIENCIA VARIABLE COMUN CON AUTOANTICUERPOS ANTI-B O ANTI-T'},
{ codigo: 'D838', descripcion: 'OTRAS INMUNODEFICIENCIAS VARIABLES COMUNES'},
{ codigo: 'D839', descripcion: 'INMUNODEFICIENCIA VARIABLE COMUN, NO ESPECIFICADA'},
{ codigo: 'D84', descripcion: 'OTRAS INMUNODEFICIENCIAS'},
{ codigo: 'D840', descripcion: 'DEFECTO DE LA FUNCION DEL ANTIGENO-1 DEL LINFOCITO (LFA-1)'},
{ codigo: 'D841', descripcion: 'DEFECTO DEL SISTEMA DEL COMPLEMENTO'},
{ codigo: 'D848', descripcion: 'OTRAS INMUNODEFICIENCIAS ESPECIFICADAS'},
{ codigo: 'D849', descripcion: 'INMUNODEFICIENCIA, NO ESPECIFICADA'},
{ codigo: 'D86', descripcion: 'SARCOIDOSIS'},
{ codigo: 'D860', descripcion: 'SARCOIDOSIS DEL PULMON'},
{ codigo: 'D861', descripcion: 'SARCOIDOSIS DE LOS GANGLIOS LINFATICOS'},
{ codigo: 'D862', descripcion: 'SARCOIDOSIS DEL PULMON Y DE LOS GANGLIOS LINFATICOS'},
{ codigo: 'D863', descripcion: 'SARCOIDOSIS DE LA PIEL'},
{ codigo: 'D868', descripcion: 'SARCOIDOSIS DE OTROS SITIOS ESPECIFICADOS O DE SITIOS COMBINADOS'},
{ codigo: 'D869', descripcion: 'SARCOIDOSIS DE SITIO NO ESPECIFICADO'},
{ codigo: 'D89', descripcion: 'OTROS TRASTORNOS QUE AFECTAN EL MECANISMO DE LA INMUNIDAD, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'D890', descripcion: 'HIPERGAMMAGLOBULINEMIA POLICLONAL'},
{ codigo: 'D891', descripcion: 'CRIOGLOBULINEMIA'},
{ codigo: 'D892', descripcion: 'HIPERGAMMAGLOBULINEMIA, NO ESPECIFICADA'},
{ codigo: 'D898', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS QUE AFECTAN EL MECANISMO DE LA INMUNIDAD NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'D899', descripcion: 'TRASTORNO QUE AFECTA AL MECANISMO DE LA INMUNIDAD, NO ESPECIFICADO'},
{ codigo: 'E000', descripcion: 'SINDROME CONGENITO DE DEFICIENCIA DE YODO, TIPO NEUROLOGICO'},
{ codigo: 'E001', descripcion: 'SINDROME DE DEFICIENCIA CONGENITA DE YODO, TIPO MIXEDEMATOSO'},
{ codigo: 'E002', descripcion: 'SINDROME CONGENITO DE DEFICIENCIA DE YODO, TIPO MIXTO'},
{ codigo: 'E009', descripcion: 'SINDROME CONGENITO DE DEFICIENCIA DE YODO, NO ESPECIFICADO'},
{ codigo: 'E01', descripcion: 'TRASTORNOS TIROIDEOS VINCULADOS A DEFICIENCIA DE YODO Y AFECCIONES RELACIONADAS'},
{ codigo: 'E010', descripcion: 'BOCIO DIFUSO (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO'},
{ codigo: 'E011', descripcion: 'BOCIO MULTINODULAR (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO'},
{ codigo: 'E012', descripcion: 'BOCIO (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO, NO ESPECIFICADO'},
{ codigo: 'E018', descripcion: 'OTROS TRASTORNOS DE LA TIROIDES RELACIONADOS CON DEFICIENCIA DE YODO Y AFECCIONES SIMILARES'},
{ codigo: 'E02', descripcion: 'HIPOTIROIDISMO SUBCLINICO POR DEFICIENCIA DE YODO'},
{ codigo: 'E03', descripcion: 'OTROS HIPOTIROIDISMOS'},
{ codigo: 'E030', descripcion: 'HIPOTIROIDISMO CONGENITO CON BOCIO DIFUSO'},
{ codigo: 'E031', descripcion: 'HIPOTIROIDISMO CONGENITO SIN BOCIO'},
{ codigo: 'E032', descripcion: 'HIPOTIROIDISMO DEBIDO A MEDICAMENTOS Y A OTRAS SUSTANCIAS EXOGENAS'},
{ codigo: 'E033', descripcion: 'HIPOTIROIDISMO POSTINFECCIOSO'},
{ codigo: 'E034', descripcion: 'ATROFIA DE TIROIDES (ADQUIRIDA)'},
{ codigo: 'E035', descripcion: 'COMA MIXEDEMATOSO'},
{ codigo: 'E038', descripcion: 'OTROS HIPOTIROIDISMOS ESPECIFICADOS'},
{ codigo: 'E039', descripcion: 'HIPOTIROIDISMO, NO ESPECIFICADO'},
{ codigo: 'E04', descripcion: 'OTROS BOCIOS NO TOXICOS'},
{ codigo: 'E040', descripcion: 'BOCIO DIFUSO NO TOXICO'},
{ codigo: 'E041', descripcion: 'NODULO TIROIDEO SOLITARIO NO TOXICO'},
{ codigo: 'E042', descripcion: 'BOCIO MULTINODULAR NO TOXICO'},
{ codigo: 'E048', descripcion: 'OTROS BOCIOS NO TOXICOS ESPECIFICADOS'},
{ codigo: 'E049', descripcion: 'BOCIO NO TOXICO, NO ESPECIFICADO'},
{ codigo: 'E05', descripcion: 'TIROTOXICOSIS (HIPERTIROIDISMO)'},
{ codigo: 'E050', descripcion: 'TIROTOXICOSIS CON BOCIO DIFUSO'},
{ codigo: 'E051', descripcion: 'TIROTOXICOSIS CON NODULO SOLITARIO TIROIDEO TOXICO'},
{ codigo: 'E052', descripcion: 'TIROTOXICOSIS CON BOCIO MULTINODULAR TOXICO'},
{ codigo: 'E053', descripcion: 'TIROTOXICOSIS POR TEJIDO TIROIDEO ECTOPICO'},
{ codigo: 'E054', descripcion: 'TIROTOXICOSIS FACTICIA'},
{ codigo: 'E055', descripcion: 'CRISIS O TORMENTA TIROTOXICA'},
{ codigo: 'E058', descripcion: 'OTRAS TIROTOXICOSIS'},
{ codigo: 'E059', descripcion: 'TIROTOXICOSIS, NO ESPECIFICADA'},
{ codigo: 'E06', descripcion: 'TIROIDITIS'},
{ codigo: 'E060', descripcion: 'TIROIDITIS AGUDA'},
{ codigo: 'E061', descripcion: 'TIROIDITIS SUBAGUDA'},
{ codigo: 'E062', descripcion: 'TIROIDITIS CRONICA CON TIROTOXICOSIS TRANSITORIA'},
{ codigo: 'E063', descripcion: 'TIROIDITIS AUTOINMUNE'},
{ codigo: 'E064', descripcion: 'TIROIDITIS INDUCIDA POR DROGAS'},
{ codigo: 'E065', descripcion: 'OTRAS TIROIDITIS CRONICAS'},
{ codigo: 'E069', descripcion: 'TIROIDITIS, NO ESPECIFICADA'},
{ codigo: 'E07', descripcion: 'OTROS TRASTORNOS TIROIDEOS'},
{ codigo: 'E070', descripcion: 'HIPERSECRECION DE CALCITONINA'},
{ codigo: 'E071', descripcion: 'BOCIO DISHORMOGENETICO'},
{ codigo: 'E078', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA GLANDULA TIROIDES'},
{ codigo: 'E079', descripcion: 'TRASTORNO DE LA GLANDULA TIROIDES, NO ESPECIFICADO'},
{ codigo: 'E10', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE'},
{ codigo: 'E100', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMA'},
{ codigo: 'E101', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON CETOACIDOSIS'},
{ codigo: 'E102', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMPLICACIONES RENALES'},
{ codigo: 'E103', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMPLICACIONES OFTALMICAS'},
{ codigo: 'E104', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMPLICACIONES NEUROLOGICAS'},
{ codigo: 'E105', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMPLICACIONES CIRCULATORIAS PERIFERICAS'},
{ codigo: 'E106', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON OTRAS COMPLICACIONES ESPECIFICADAS'},
{ codigo: 'E107', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMPLICACIONES MULTIPLES'},
{ codigo: 'E108', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, CON COMPLICACIONES NO ESPECIFICADAS'},
{ codigo: 'E109', descripcion: 'DIABETES MELLITUS INSULINODEPENDIENTE, SIN MENCION DE COMPLICACION'},
{ codigo: 'E11', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE'},
{ codigo: 'E110', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMA'},
{ codigo: 'E111', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON CETOACIDOSIS'},
{ codigo: 'E112', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES RENALES'},
{ codigo: 'E113', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACION OFTALMICAS'},
{ codigo: 'E114', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, COMPLICACIONES NEUROLOGICAS'},
{ codigo: 'E115', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, COMPLICACIONES CIRCULATORIAS PERIFERICAS'},
{ codigo: 'E116', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON OTRAS COMPLICACION ESPECIFICADAS'},
{ codigo: 'E117', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES MULTIPLES'},
{ codigo: 'E118', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, CON COMPLICACIONES NO ESPECIFICADAS'},
{ codigo: 'E119', descripcion: 'DIABETES MELLITUS NO INSULINODEPENDIENTE, SIN MENCION DE COMPLICACION'},
{ codigo: 'E12', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION'},
{ codigo: 'E120', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMA'},
{ codigo: 'E121', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON CETOACIDOSIS'},
{ codigo: 'E122', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMPLICACIONES RENALES'},
{ codigo: 'E123', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES OFTALMICAS'},
{ codigo: 'E124', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES NEUROLOGICAS'},
{ codigo: 'E125', descripcion: 'DIABETES MELLITUS ASOCIADA A DESNUTRICION, CON COMPLICACIONES CIRCULATORIAS PERIFERICAS'},
{ codigo: 'E126', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON OTRAS COMPLICACIONES ESPECIFICADAS'},
{ codigo: 'E127', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES MULTIPLES'},
{ codigo: 'E128', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, CON COMPLICACIONES NO ESPECIFICADAS'},
{ codigo: 'E129', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION, SIN MENCION DE COMPLICACION'},
{ codigo: 'E13', descripcion: 'DIABETES MELLITUS ASOCIADA CON DESNUTRICION'},
{ codigo: 'E130', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON COMA'},
{ codigo: 'E131', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON CETOACIDOSIS'},
{ codigo: 'E132', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES RENALES'},
{ codigo: 'E133', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES OFTALMICAS'},
{ codigo: 'E134', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES NEUROLOGICAS'},
{ codigo: 'E135', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES CIRCULATORIAS PERIFERICAS'},
{ codigo: 'E136', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON OTRAS COMPLICACIONES ESPECIFICADAS'},
{ codigo: 'E137', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES MULTIPLES'},
{ codigo: 'E138', descripcion: 'DIABETES MELLITUS ESPECIFICADA, CON COMPLICACIONES NO ESPECIFICADAS'},
{ codigo: 'E139', descripcion: 'DIABETES MELLITUS ESPECIFICADA, SIN MENCION DE COMPLICACION'},
{ codigo: 'E14', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA'},
{ codigo: 'E140', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON COMA'},
{ codigo: 'E141', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON CETOACIDOSIS'},
{ codigo: 'E142', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON COMPLICACIONES RENALES'},
{ codigo: 'E143', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON COMPLICACIONES OFTALMICAS'},
{ codigo: 'E144', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON COMPLICACIONES NEUROLOGICAS'},
{ codigo: 'E145', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON COMPLICACIONES CIRCULATORIAS PERIFERICA'},
{ codigo: 'E146', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON OTRAS COMPLICACIONES ESPECIFICADAS'},
{ codigo: 'E147', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON COMPLICACIONES MULTIPLES'},
{ codigo: 'E148', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, CON COMPLICACIONES NO ESPECIFICADAS'},
{ codigo: 'E149', descripcion: 'DIABETES MELLITUS, NO ESPECIFICADA, SIN MENCION DE COMPLICACION'},
{ codigo: 'E15', descripcion: 'COMA HIPOGLICEMICO NO DIABETICO'},
{ codigo: 'E16', descripcion: 'OTROS TRASTORNOS DE LA SECRECION INTERNA DEL PANCREAS'},
{ codigo: 'E160', descripcion: 'HIPOGLICEMIA SIN COMA, INDUCIDA POR DROGAS'},
{ codigo: 'E161', descripcion: 'OTRAS HIPOGLICEMIAS'},
{ codigo: 'E162', descripcion: 'HIPOGLICEMIA, NO ESPECIFICADA'},
{ codigo: 'E163', descripcion: 'SECRECION EXAGERADA DEL GLUCAGON'},
{ codigo: 'E168', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA SECRECION INTERNA DEL PANCREAS'},
{ codigo: 'E169', descripcion: 'TRASTORNOS DE LA SECRECION INTERNA DEL PANCREAS, SIN OTRA ESPECIFICACION'},
{ codigo: 'E20', descripcion: 'HIPOPARATIROIDISMO'},
{ codigo: 'E200', descripcion: 'HIPOPARATIROIDISMO IDIOPATICO'},
{ codigo: 'E201', descripcion: 'PSEUDOHIPOPARATIROIDISMO'},
{ codigo: 'E208', descripcion: 'OTROS TIPOS DE HIPOPARATIROIDISMO'},
{ codigo: 'E209', descripcion: 'HIPOPARATIROIDISMO, NO ESPECIFICADO'},
{ codigo: 'E21', descripcion: 'HIPERPARATIROIDISMO Y OTROS TRASTORNOS DE LA GLANDULA PARATIROIDES'},
{ codigo: 'E210', descripcion: 'HIPERPARATIROIDISMO PRIMARIO'},
{ codigo: 'E211', descripcion: 'HIPERPARATIROIDISMO SECUNDARIO NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'E212', descripcion: 'OTROS TIPOS DE HIPERPARATIROIDISMO'},
{ codigo: 'E213', descripcion: 'HIPERPARATIROIDISMO, SIN OTRA ESPECIFICACION'},
{ codigo: 'E214', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA GLANDULA PARATIROIDES'},
{ codigo: 'E215', descripcion: 'TRASTORNO DE LA GLANDULA PARATIROIDES, NO ESPECIFICADO'},
{ codigo: 'E22', descripcion: 'HIPERFUNCION DE LA GLANDULA HIPOFISIS'},
{ codigo: 'E220', descripcion: 'ACROMEGALIA Y GIGANTISMO HIPOFISARIO'},
{ codigo: 'E221', descripcion: 'HIPERPROLACTINEMIA'},
{ codigo: 'E222', descripcion: 'SINDROME DE SECRECION INAPROPIADA DE HORMONA ANTIDIURETICA'},
{ codigo: 'E228', descripcion: 'OTRAS HIPERFUNCIONES DE LA GLANDULA HIPOFISIS'},
{ codigo: 'E229', descripcion: 'HIPERFUNCION DE LA GLANDULA HIPOFISIS, NO ESPECIFICADA'},
{ codigo: 'E23', descripcion: 'HIPOFUNCION Y OTROS TRASTORNOS DE LA GLANDULA HIPOFISIS'},
{ codigo: 'E230', descripcion: 'HIPOPITUITARISMO'},
{ codigo: 'E231', descripcion: 'HIPOPITUITARISMO INDUCIDO POR DROGAS'},
{ codigo: 'E232', descripcion: 'DIABETES INSIPIDA'},
{ codigo: 'E233', descripcion: 'DISFUNCION HIPOTALAMICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'E236', descripcion: 'OTROS TRASTORNOS DE LA GLANDULA HIPOFISIS'},
{ codigo: 'E237', descripcion: 'TRASTORNO DE LA GLANDULA HIPOFISIS, NO ESPECIFICADO'},
{ codigo: 'E24', descripcion: 'SINDROME DE CUSHING'},
{ codigo: 'E240', descripcion: 'ENFERMEDAD DE CUSHING DEPENDIENTE DE LA HIPOFISIS'},
{ codigo: 'E241', descripcion: 'SINDROME DE NELSON'},
{ codigo: 'E242', descripcion: 'SINDROME DE CUSHING INDUCIDO POR DROGAS'},
{ codigo: 'E243', descripcion: 'SINDROME DE ACTH ECTOPICO'},
{ codigo: 'E244', descripcion: 'SINDROME DE SEUDO-CUSHING INDUCIDO POR ALCOHOL'},
{ codigo: 'E248', descripcion: 'OTROS TIPOS DE SINDROME DE CUSHING'},
{ codigo: 'E249', descripcion: 'SINDROME DE CUSHING, NO ESPECIFICADO'},
{ codigo: 'E25', descripcion: 'TRASTORNOS ADRENOGENITALES'},
{ codigo: 'E250', descripcion: 'TRASTORNOS ADRENOGENITALES CONGENITOS CON DEFICIENCIA ENZIMATICA'},
{ codigo: 'E258', descripcion: 'OTROS TRASTORNOS ADRENOGENITALES'},
{ codigo: 'E259', descripcion: 'TRASTORNO ADRENOGENITAL, NO ESPECIFICADO'},
{ codigo: 'E26', descripcion: 'HIPERALDOSTERONISMO'},
{ codigo: 'E260', descripcion: 'HIPERALDOSTERONISMO PRIMARIO'},
{ codigo: 'E261', descripcion: 'HIPERALDOSTERONISMO SECUNDARIO'},
{ codigo: 'E268', descripcion: 'OTROS TIPOS DE HIPERALDOSTERONISMO'},
{ codigo: 'E269', descripcion: 'HIPERALDOSTERONISMO, NO ESPECIFICADO'},
{ codigo: 'E27', descripcion: 'OTROS TRASTORNOS DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'E270', descripcion: 'OTRA HIPERACTIVIDAD CORTICOSUPRARRENAL'},
{ codigo: 'E271', descripcion: 'INSUFICIENCIA CORTICOSUPRARRENAL PRIMARIA'},
{ codigo: 'E272', descripcion: 'CRISIS ADDISONIANA'},
{ codigo: 'E273', descripcion: 'INSUFICIENCIA CORTICOSUPRARRENAL INDUCIDA POR DROGAS'},
{ codigo: 'E274', descripcion: 'OTRAS INSUFICIENCIAS CORTICOSUPRARRENALES Y LAS NO ESPECIFICADAS'},
{ codigo: 'E275', descripcion: 'HIPERFUNCION DE LA MEDULA SUPRARRENAL'},
{ codigo: 'E278', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'E279', descripcion: 'TRASTORNO DE LA GLANDULA SUPRARRENAL, NO ESPECIFICADO'},
{ codigo: 'E28', descripcion: 'DISFUNCION OVARICA'},
{ codigo: 'E280', descripcion: 'EXCESO DE ESTROGENOS'},
{ codigo: 'E281', descripcion: 'EXCESO DE ANDROGENOS'},
{ codigo: 'E282', descripcion: 'SINDROME DE OVARIO POLIQUISTICO'},
{ codigo: 'E283', descripcion: 'INSUFICIENCIA OVARICA PRIMARIA'},
{ codigo: 'E288', descripcion: 'OTRAS DISFUNCIONES OVARICAS'},
{ codigo: 'E289', descripcion: 'DISFUNCION OVARICA, NO ESPECIFICADA'},
{ codigo: 'E29', descripcion: 'DISFUNCION TESTICULAR'},
{ codigo: 'E290', descripcion: 'HIPERFUNCION TESTICULAR'},
{ codigo: 'E291', descripcion: 'HIPOFUNCION TESTICULAR'},
{ codigo: 'E298', descripcion: 'OTRAS DISFUNCIONES TESTICULARES'},
{ codigo: 'E299', descripcion: 'DISFUNCION TESTICULAR, NO ESPECIFICADA'},
{ codigo: 'E30', descripcion: 'TRASTORNOS DE LA PUBERTAD, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'E300', descripcion: 'PUBERTAD RETARDADA'},
{ codigo: 'E301', descripcion: 'PUBERTAD PRECOZ'},
{ codigo: 'E308', descripcion: 'OTROS TRASTORNOS DE LA PUBERTAD'},
{ codigo: 'E309', descripcion: 'TRASTORNO DE LA PUBERTAD, NO ESPECIFICADO'},
{ codigo: 'E31', descripcion: 'DISFUNCION POLIGLANDULAR'},
{ codigo: 'E310', descripcion: 'INSUFICIENCIA POLIGLANDULAR AUTOINMUNE'},
{ codigo: 'E311', descripcion: 'HIPERFUNCION POLIGLANDULAR'},
{ codigo: 'E318', descripcion: 'OTRAS DISFUNCIONES POLIGLANDULARES'},
{ codigo: 'E319', descripcion: 'DISFUNCION POLIGLANDULAR, NO ESPECIFICADA'},
{ codigo: 'E32', descripcion: 'ENFERMEDADES DEL TIMO'},
{ codigo: 'E320', descripcion: 'HIPERPLASIA PERSISTENTE DEL TIMO'},
{ codigo: 'E321', descripcion: 'ABSCESO DEL TIMO'},
{ codigo: 'E328', descripcion: 'OTRAS ENFERMEDADES DEL TIMO'},
{ codigo: 'E329', descripcion: 'ENFERMEDAD DEL TIMO, NO ESPECIFICADA'},
{ codigo: 'E34', descripcion: 'OTROS TRASTORNOS ENDOCRINOS'},
{ codigo: 'E340', descripcion: 'SINDROME CARCINOIDE'},
{ codigo: 'E341', descripcion: 'OTRAS HIPERSECRECIONES DE HORMONAS INTESTINALES'},
{ codigo: 'E342', descripcion: 'SECRECION HORMONAL ECTOPICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'E343', descripcion: 'ENANISMO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'E344', descripcion: 'ESTATURA ALTA CONSTITUCIONAL'},
{ codigo: 'E345', descripcion: 'SINDROME DE RESISTENCIA ANDROGENICA'},
{ codigo: 'E348', descripcion: 'OTROS TRASTORNOS ENDOCRINOS ESPECIFICADOS'},
{ codigo: 'E349', descripcion: 'TRASTORNO ENDOCRINO, NO ESPECIFICADO'},
{ codigo: 'E35', descripcion: 'TRASTORNOS ENDOCRINOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'E350', descripcion: 'TRASTORNOS DE LA GLANDULA TIROIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'E351', descripcion: 'TRASTORNOS DE LAS GLANDULAS SUPRARRENALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'E358', descripcion: 'TRASTORNOS DE OTRAS GLANDULAS ENDOCRINAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'E40', descripcion: 'KWASHIORKOR'},
{ codigo: 'E41', descripcion: 'MARASMO NUTRICIONAL'},
{ codigo: 'E42', descripcion: 'KWASHIORKOR MARASMATICO'},
{ codigo: 'E43', descripcion: 'DESNUTRICION PROTEICOCALORICA SEVERA, NO ESPECIFICADA'},
{ codigo: 'E44', descripcion: 'DESNUTRICION PROTEICOCALORICA DE GRADO MODERADO Y LEVE'},
{ codigo: 'E440', descripcion: 'DESNUTRICION PROTEICOCALORICA MODERADA'},
{ codigo: 'E441', descripcion: 'DESNUTRICION PROTEICOCALORICA LEVE'},
{ codigo: 'E45', descripcion: 'RETARDO DEL DESARROLLO DEBIDO A DESNUTRICION PROTEICOCALORICA'},
{ codigo: 'E46', descripcion: 'DESNUTRICION PROTEICOCALORICA, NO ESPECIFICADA'},
{ codigo: 'E50', descripcion: 'DEFICIENCIA DE VITAMINA A'},
{ codigo: 'E500', descripcion: 'DEFICIENCIA DE VITAMINA A CON XEROSIS CONJUNTIVAL'},
{ codigo: 'E501', descripcion: 'DEFICIENCIA DE VITAMINA A CON MANCHA DE BITOT Y XEROSIS CONJUNTIVAL'},
{ codigo: 'E502', descripcion: 'DEFICIENCIA DE VITAMINA A CON XEROSIS CORNEAL'},
{ codigo: 'E503', descripcion: 'DEFICIENCIA DE VITAMINA A CON ULCERACION CORNEAL Y XEROSIS'},
{ codigo: 'E504', descripcion: 'DEFICIENCIA DE VITAMINA A CON QUERATOMALACIA'},
{ codigo: 'E505', descripcion: 'DEFICIENCIA DE VITAMINA A CON CEGUERA NOCTURNA'},
{ codigo: 'E506', descripcion: 'DEFICIENCIA DE VITAMINA A CON CICATRICES XEROFTALMICAS DE LA CORNEA'},
{ codigo: 'E507', descripcion: 'OTRAS MANIFESTACIONES OCULARES DE DEFICIENCIA DE VITAMINA A'},
{ codigo: 'E508', descripcion: 'OTRAS MANIFESTACIONES DE DEFICIENCIA DE VITAMINA A'},
{ codigo: 'E509', descripcion: 'DEFICIENCIA DE VITAMINA A, NO ESPECIFICADA'},
{ codigo: 'E51', descripcion: 'DEFICIENCIA DE TIAMINA'},
{ codigo: 'E511', descripcion: 'BERIBERI'},
{ codigo: 'E512', descripcion: 'ENCEFALOPATIA DE WERNICKE'},
{ codigo: 'E518', descripcion: 'OTRAS MANIFESTACIONES DE LA DEFICIENCIA DE TIAMINA'},
{ codigo: 'E519', descripcion: 'DEFICIENCIA DE TIAMINA, NO ESPECIFICADA'},
{ codigo: 'E52', descripcion: 'DEFICIENCIA DE NIACINA (PELAGRA)'},
{ codigo: 'E53', descripcion: 'DEFICIENCIAS DE OTRAS VITAMINAS DEL GRUPO B'},
{ codigo: 'E530', descripcion: 'DEFICIENCIA DE RIBOFLAVINA'},
{ codigo: 'E531', descripcion: 'DEFICIENCIA DE PIRIDOXINA'},
{ codigo: 'E538', descripcion: 'DEFICIENCIA DE OTRAS VITAMINAS DEL GRUPO B'},
{ codigo: 'E539', descripcion: 'DEFICIENCIA DE VITAMINA B, NO ESPECIFICADA'},
{ codigo: 'E54', descripcion: 'DEFICIENCIA DE ACIDO ASCORBICO'},
{ codigo: 'E55', descripcion: 'DEFICIENCIA DE VITAMINA D'},
{ codigo: 'E550', descripcion: 'RAQUITISMO ACTIVO'},
{ codigo: 'E559', descripcion: 'DEFICIENCIA DE VITAMINA D, NO ESPECIFICADA'},
{ codigo: 'E56', descripcion: 'OTRAS DEFICIENCIAS DE VITAMINAS'},
{ codigo: 'E560', descripcion: 'DEFICIENCIA DE VITAMINA E'},
{ codigo: 'E561', descripcion: 'DEFICIENCIA DE VITAMINA K'},
{ codigo: 'E568', descripcion: 'DEFICIENCIA DE OTRAS VITAMINAS'},
{ codigo: 'E569', descripcion: 'DEFICIENCIA DE VITAMINA, NO ESPECIFICADA'},
{ codigo: 'E58', descripcion: 'DEFICIENCIA DIETETICA DE CALCIO'},
{ codigo: 'E59', descripcion: 'DEFICIENCIA DIETETICA DE SELENIO'},
{ codigo: 'E60', descripcion: 'DEFICIENCIA DIETETICA DE ZINC'},
{ codigo: 'E61', descripcion: 'DEFICIENCIAS DE OTROS ELEMENTOS NUTRICIONALES'},
{ codigo: 'E610', descripcion: 'DEFICIENCIA DE COBRE'},
{ codigo: 'E611', descripcion: 'DEFICIENCIA DE HIERRO'},
{ codigo: 'E612', descripcion: 'DEFICIENCIA DE MAGNESIO'},
{ codigo: 'E613', descripcion: 'DEFICIENCIA DE MANGANESO'},
{ codigo: 'E614', descripcion: 'DEFICIENCIA DE CROMO'},
{ codigo: 'E615', descripcion: 'DEFICIENCIA DE MOLIBDENO'},
{ codigo: 'E616', descripcion: 'DEFICIENCIA DE VANADIO'},
{ codigo: 'E617', descripcion: 'DEFICIENCIA DE MULTIPLES ELEMENTOS NUTRICIONALES'},
{ codigo: 'E618', descripcion: 'DEFICIENCIA DE OTROS ELEMENTOS NUTRICIONALES ESPECIFICADOS'},
{ codigo: 'E619', descripcion: 'DEFICIENCIA DE OTRO ELEMENTO NUTRICIONAL, NO ESPECIFICADO'},
{ codigo: 'E63', descripcion: 'OTRAS DEFICIENCIAS NUTRICIONALES'},
{ codigo: 'E630', descripcion: 'DEFICIENCIA DE ACIDOS GRASOS ESENCIALES (AGE)'},
{ codigo: 'E631', descripcion: 'DESEQUILIBRIO DE LOS CONSTITUYENTES EN LA DIETA'},
{ codigo: 'E638', descripcion: 'OTRAS DEFICIENCIAS NUTRICIONALES ESPECIFICADAS'},
{ codigo: 'E639', descripcion: 'DEFICIENCIA NUTRICIONAL, NO ESPECIFICADA'},
{ codigo: 'E64', descripcion: 'SECUELAS DE LA DESNUTRICION Y DE OTRAS DEFICIENCIAS NUTRICIONALES'},
{ codigo: 'E640', descripcion: 'SECUELAS DE LA DESNUTRICION PROTEICOCALORICA'},
{ codigo: 'E641', descripcion: 'SECUELAS DE LA DEFICIENCIA DE VITAMINA A'},
{ codigo: 'E642', descripcion: 'SECUELAS DE LA DEFICIENCIA DE VITAMINA C'},
{ codigo: 'E643', descripcion: 'SECUELAS DEL RAQUITISMO'},
{ codigo: 'E648', descripcion: 'SECUELAS DE OTRAS DEFICIENCIAS NUTRICIONALES'},
{ codigo: 'E649', descripcion: 'SECUELAS DE LA DEFICIENCIA NUTRICIONAL NO ESPECIFICADA'},
{ codigo: 'E65', descripcion: 'ADIPOSIDAD LOCALIZADA'},
{ codigo: 'E66', descripcion: 'OBESIDAD'},
{ codigo: 'E660', descripcion: 'OBESIDAD DEBIDA A EXCESO DE CALORIAS'},
{ codigo: 'E661', descripcion: 'OBESIDAD INDUCIDA POR DROGAS'},
{ codigo: 'E662', descripcion: 'OBESIDAD EXTREMA CON HIPOVENTILACION ALVEOLAR'},
{ codigo: 'E668', descripcion: 'OTROS TIPOS DE OBESIDAD'},
{ codigo: 'E669', descripcion: 'OBESIDAD, NO ESPECIFICADA'},
{ codigo: 'E67', descripcion: 'OTROS TIPOS DE HIPERALIMENTACION'},
{ codigo: 'E670', descripcion: 'HIPERVITAMINOSIS A'},
{ codigo: 'E671', descripcion: 'HIPERCAROTINEMIA'},
{ codigo: 'E672', descripcion: 'SINDROME DE MEGAVITAMINA B6'},
{ codigo: 'E673', descripcion: 'HIPERVITAMINOSIS D'},
{ codigo: 'E678', descripcion: 'OTROS TIPOS DE HIPERALIMENTACION ESPECIFICADOS'},
{ codigo: 'E68', descripcion: 'SECUELAS DE HIPERALIMENTACION'},
{ codigo: 'E70', descripcion: 'TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS AROMATICOS'},
{ codigo: 'E700', descripcion: 'FENILCETONURIA CLASICA'},
{ codigo: 'E701', descripcion: 'OTRAS HIPERFENILALANINEMIAS'},
{ codigo: 'E702', descripcion: 'TRASTORNOS DEL METABOLISMO DE LA TIROSINA'},
{ codigo: 'E703', descripcion: 'ALBINISMO'},
{ codigo: 'E708', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS AROMATICOS'},
{ codigo: 'E709', descripcion: 'TRASTORNO DEL METABOLISMO DE LOS AMINOACIDOS AROMATICOS, NO ESPECIFICADO'},
{ codigo: 'E71', descripcion: 'TRASTORNOS DEL METABOLISMO DE AMINOACIDOS DE CADENA RAMIFICADA Y DE ACIDOS GRASOS'},
{ codigo: 'E710', descripcion: 'ENFERMEDAD DE LA ORINA EN JARABE DE ARCE'},
{ codigo: 'E711', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS DE CADENA RAMIFICADA'},
{ codigo: 'E712', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE AMINOACIDOS CADENA RAMIFICADA, NO ESPECIFICADOS'},
{ codigo: 'E713', descripcion: 'TRASTORNOS DEL METABOLISMO DE LOS ACIDOS GRASOS'},
{ codigo: 'E72', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS'},
{ codigo: 'E720', descripcion: 'TRASTORNOS DEL TRANSPORTE DE LOS AMINOACIDOS'},
{ codigo: 'E721', descripcion: 'TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS AZUFRADOS'},
{ codigo: 'E722', descripcion: 'TRASTORNOS DEL METABOLISMO DEL CICLO DE LA UREA'},
{ codigo: 'E723', descripcion: 'TRASTORNOS DEL METABOLISMO DE LA LISINA Y LA HIDROXILISINA'},
{ codigo: 'E724', descripcion: 'TRASTORNOS DEL METABOLISMO DE LA ORNITINA'},
{ codigo: 'E725', descripcion: 'TRASTORNOS DEL METABOLISMO DE LA GLICINA'},
{ codigo: 'E728', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO DE LOS AMINOACIDOS'},
{ codigo: 'E729', descripcion: 'TRASTORNO DEL METABOLISMO DE LOS AMINOACIDOS, NO ESPECIFICADO'},
{ codigo: 'E73', descripcion: 'INTOLERANCIA A LA LACTOSA'},
{ codigo: 'E730', descripcion: 'DEFICIENCIA CONGENITA DE LACTASA'},
{ codigo: 'E731', descripcion: 'DEFICIENCIA SECUNDARIA DE LACTASA'},
{ codigo: 'E738', descripcion: 'OTROS TIPOS DE INTOLERANCIA A LA LACTOSA'},
{ codigo: 'E739', descripcion: 'INTOLERANCIA A LA LACTOSA, NO ESPECIFICADA'},
{ codigo: 'E74', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LOS CARBOHIDRATOS'},
{ codigo: 'E740', descripcion: 'ENFERMEDAD DEL ALMACENAMIENTO DE GLUCOGENO'},
{ codigo: 'E741', descripcion: 'TRASTORNOS DEL METABOLISMO DE LA FRUCTOSA'},
{ codigo: 'E742', descripcion: 'TRASTORNO DEL METABOLISMO DE LA GALACTOSA'},
{ codigo: 'E743', descripcion: 'OTROS TRASTORNOS DE LA ABSORCION INTESTINAL DE CARBOHIDRATOS'},
{ codigo: 'E744', descripcion: 'TRASTORNOS DEL METABOLISMO DEL PIRUVATO Y DE LA GLUCONEOGENESIS'},
{ codigo: 'E748', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO DE LOS CARBOHIDRATOS'},
{ codigo: 'E749', descripcion: 'TRASTORNO DEL METABOLISMO DE LOS CARBOHIDRATOS, NO ESPECIFICADO'},
{ codigo: 'E75', descripcion: 'TRASTORNOS DEL METABOLISMO DE LOS ESFINGOLIPIDOS Y OTROS TRASTORNOS POR ALMACENAMIENTO DE LIPIDOS'},
{ codigo: 'E750', descripcion: 'GANGLIOSIDOSIS GM2'},
{ codigo: 'E751', descripcion: 'OTRAS GANGLIOSIDOSIS'},
{ codigo: 'E752', descripcion: 'OTRAS ESFINGOLIPIDOSIS'},
{ codigo: 'E753', descripcion: 'ESFINGOLIPIDOSIS, NO ESPECIFICADA'},
{ codigo: 'E754', descripcion: 'LIPOFUSCINOSIS CEROIDE NEURONAL'},
{ codigo: 'E755', descripcion: 'OTROS TRASTORNOS DEL ALMACENAMIENTO DE LIPIDOS'},
{ codigo: 'E756', descripcion: 'TRASTORNO DEL ALMACENAMIENTO DE LIPIDOS, NO ESPECIFICADO'},
{ codigo: 'E76', descripcion: 'TRASTORNOS DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS'},
{ codigo: 'E760', descripcion: 'MUCOPOLISACARIDOSIS TIPO I'},
{ codigo: 'E761', descripcion: 'MUCOPOLISACARIDOSIS TIPO II'},
{ codigo: 'E762', descripcion: 'OTRAS MUCOPOLISACARIDOSIS'},
{ codigo: 'E763', descripcion: 'MUCOPOLISACARIDOSIS NO ESPECIFICADA'},
{ codigo: 'E768', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS'},
{ codigo: 'E769', descripcion: 'TRASTORNO DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS, NO ESPECIFICADO'},
{ codigo: 'E77', descripcion: 'TRASTORNOS DEL METABOLISMO DE LAS GLUCOPROTEINAS'},
{ codigo: 'E770', descripcion: 'DEFECTOS EN LA MODIFICACION POSTRASLACIONAL DE ENZIMAS LISOSOMALES'},
{ codigo: 'E771', descripcion: 'DEFECTOS DE LA DEGRADACION DE GLUCOPROTEINAS'},
{ codigo: 'E778', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LAS GLUCOPROTEINAS'},
{ codigo: 'E779', descripcion: 'TRASTORNO DEL METABOLISMO DE LAS GLUCOPROTEINAS, NO ESPECIFICADO'},
{ codigo: 'E78', descripcion: 'TRASTORNOS DEL METABOLISMO DE LAS LIPOPROTEINAS Y OTRAS LIPIDEMIAS'},
{ codigo: 'E780', descripcion: 'HIPERCOLESTEROLEMIA PURA'},
{ codigo: 'E781', descripcion: 'HIPERGLICERIDEMIA PURA'},
{ codigo: 'E782', descripcion: 'HIPERLIPIDEMIA MIXTA'},
{ codigo: 'E783', descripcion: 'HIPERQUILOMICRONEMIA'},
{ codigo: 'E784', descripcion: 'OTRA HIPERLIPIDEMIA'},
{ codigo: 'E785', descripcion: 'HIPERLIPIDEMIA NO ESPECIFICADA'},
{ codigo: 'E786', descripcion: 'DEFICIENCIA DE LIPOPROTEINAS'},
{ codigo: 'E788', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LAS LIPOPROTEINAS'},
{ codigo: 'E789', descripcion: 'TRASTORNO DEL METABOLISMO DE LAS LIPOPROTEINAS, NO ESPECIFICADO'},
{ codigo: 'E79', descripcion: 'TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS'},
{ codigo: 'E790', descripcion: 'HIPERURICEMIA SIN SIGNOS DE ARTRITIS INFLAMATORIA Y ENFERMEDAD TOFACEA'},
{ codigo: 'E791', descripcion: 'SINDROME DE LESCH-NYHAN'},
{ codigo: 'E798', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS'},
{ codigo: 'E799', descripcion: 'TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE PIRIMIDINAS, NO ESPECIFICADOS'},
{ codigo: 'E80', descripcion: 'TRASTORNOS DEL METABOLISMO DE LAS PORFIRINAS Y DE LA BILIRRUBINA'},
{ codigo: 'E800', descripcion: 'PORFIRIA ERITROPOYETICA HEREDITARIA'},
{ codigo: 'E801', descripcion: 'PORFIRIA CUTANEA TARDIA'},
{ codigo: 'E802', descripcion: 'OTRAS PORFIRIAS'},
{ codigo: 'E803', descripcion: 'DEFECTOS DE CATALASA Y PEROXIDASA'},
{ codigo: 'E804', descripcion: 'SINDROME DE GILBERT'},
{ codigo: 'E805', descripcion: 'SINDROME DE CRIGLER-NAJJAR'},
{ codigo: 'E806', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LA BILIRRUBINA'},
{ codigo: 'E807', descripcion: 'TRASTORNO DEL METABOLISMO DE LA BILIRRUBINA, NO ESPECIFICADO'},
{ codigo: 'E83', descripcion: 'TRASTORNOS DEL METABOLISMO DE LOS MINERALES'},
{ codigo: 'E830', descripcion: 'TRASTORNOS DEL METABOLISMO DEL COBRE'},
{ codigo: 'E831', descripcion: 'TRASTORNOS DEL METABOLISMO DEL HIERRO'},
{ codigo: 'E832', descripcion: 'TRASTORNOS DEL METABOLISMO DEL ZINC'},
{ codigo: 'E833', descripcion: 'TRASTORNOS DEL METABOLISMO DEL FOSFORO'},
{ codigo: 'E834', descripcion: 'TRASTORNOS DEL METABOLISMO DEL MAGNESIO'},
{ codigo: 'E835', descripcion: 'TRASTORNOS DEL METABOLISMO DEL CALCIO'},
{ codigo: 'E838', descripcion: 'OTROS TRASTORNOS DEL METABOLISMO DE LOS MINERALES'},
{ codigo: 'E839', descripcion: 'TRASTORNO DEL METABOLISMO DE LOS MINERALES, NO ESPECIFICADO'},
{ codigo: 'E84', descripcion: 'FIBROSIS QUISTICA'},
{ codigo: 'E840', descripcion: 'FIBROSIS QUISTICA CON MANIFESTACIONES PULMONARES'},
{ codigo: 'E841', descripcion: 'FIBROSIS QUISTICA CON MANIFESTACIONES INTESTINALES'},
{ codigo: 'E848', descripcion: 'FIBROSIS QUISTICA CON OTRAS MANIFESTACIONES'},
{ codigo: 'E849', descripcion: 'FIBROSIS QUISTICA, SIN OTRA ESPECIFICACION'},
{ codigo: 'E85', descripcion: 'AMILOIDOSIS'},
{ codigo: 'E850', descripcion: 'AMILOIDOSIS HEREDOFAMILIAR NO NEUROPATICA'},
{ codigo: 'E851', descripcion: 'AMILOIDOSIS HEREDOFAMILIAR NEUROPATICA'},
{ codigo: 'E852', descripcion: 'AMILOIDOSIS HEREDOFAMILIAR NO ESPECIFICADA'},
{ codigo: 'E853', descripcion: 'AMILOIDOSIS SISTEMICA SECUNDARIA'},
{ codigo: 'E854', descripcion: 'AMILOIDOSIS LIMITADA A UN ORGANO'},
{ codigo: 'E858', descripcion: 'OTRAS AMILOIDOSIS'},
{ codigo: 'E859', descripcion: 'AMILOIDOSIS, NO ESPECIFICADA'},
{ codigo: 'E86', descripcion: 'DEPLECION DEL VOLUMEN'},
{ codigo: 'E87', descripcion: 'OTROS TRASTORNOS DE LIQUIDOS, ELECTROLITOS Y DEL EQUIPO ACIDO BASICO'},
{ codigo: 'E870', descripcion: 'HIPEROSMOLARIDAD E HIPERNATREMIA'},
{ codigo: 'E871', descripcion: 'HIPOSMOLARIDAD E HIPONATREMIA'},
{ codigo: 'E872', descripcion: 'ACIDOSIS'},
{ codigo: 'E873', descripcion: 'ALCALOSIS'},
{ codigo: 'E874', descripcion: 'TRASTORNOS MIXTOS DEL BALANCE ACIDO-BASICO'},
{ codigo: 'E875', descripcion: 'HIPERPOTASEMIA'},
{ codigo: 'E876', descripcion: 'HIPOPOTASMIA'},
{ codigo: 'E877', descripcion: 'SOBRECARGA DE LIQUIDOS'},
{ codigo: 'E878', descripcion: 'OTROS TRASTORNOS DEL EQUILIBRIO DE LOS ELECTROLITOS Y DE LOS LIQUIDOS,NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'E88', descripcion: 'OTROS TRASTORNOS METABOLICOS'},
{ codigo: 'E880', descripcion: 'TRASTORNOS DEL METABOLISMO DE LAS PROTEINAS PLASMATICAS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'E881', descripcion: 'LIPODISTROFIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'E882', descripcion: 'LIPOMATOSIS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'E888', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO'},
{ codigo: 'E889', descripcion: 'TRASTORNO METABOLICO, NO ESPECIFICADO'},
{ codigo: 'E89', descripcion: 'TRASTORNOS ENDOCRINOS Y METABOLICOS CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'E890', descripcion: 'HIPOTIROIDISMO CONSECUTIVO A PROCEDIMIENTOS'},
{ codigo: 'E891', descripcion: 'HIPOINSULINEMIA CONSECUTIVA A PROCEDIMIENTOS'},
{ codigo: 'E892', descripcion: 'HIPOPARATIROIDISMO CONSECUTIVO A PROCEDIMIENTOS'},
{ codigo: 'E893', descripcion: 'HIPOPITUITARISMO CONSECUTIVO A PROCEDIMIENTOS'},
{ codigo: 'E894', descripcion: 'INSUFICIENCIA OVARICA CONSECUTIVA A PROCEDIMIENTOS'},
{ codigo: 'E895', descripcion: 'HIPOFUNCION TESTICULAR CONSECUTIVA A PROCEDIMIENTOS'},
{ codigo: 'E896', descripcion: 'HIPOFUNCION ADRENOCORTICAL (MEDULA SUPRARRENAL) CONSECUTIVA A PROCEDIMIENTOS'},
{ codigo: 'E898', descripcion: 'OTROS TRASTORNOS METABOLICOS Y ENDOCRINOS CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'E899', descripcion: 'TRASTORNO ENDOCRINO Y METABOLICO CONSECUTIVO A PROCEDIMIENTOS NO ESPECIFICADO'},
{ codigo: 'E90', descripcion: 'TRASTORNOS NUTRICIONALES Y METABOLICOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'F00', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER (G30.-+)'},
{ codigo: 'F000', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER,DE COMIENZO TEMPRANO(G30.0+) +'},
{ codigo: 'F001', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, DE COMIENZO TARDIO (G30.1+)'},
{ codigo: 'F002', descripcion: 'DEMENCIA EN LA ENFERM DE ALZHEIMER, ATIPICA O DE TIPO MIXTO (G30.8+)'},
{ codigo: 'F009', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA (G30.9+)'},
{ codigo: 'F01', descripcion: 'DEMENCIA VASCULAR'},
{ codigo: 'F010', descripcion: 'DEMENCIA VASCULAR DE COMIENZO AGUDO'},
{ codigo: 'F011', descripcion: 'DEMENCIA VASCULAR POR INFARTOS MULTIPLES'},
{ codigo: 'F012', descripcion: 'DEMENCIA VASCULAR SUBCORTICAL'},
{ codigo: 'F013', descripcion: 'DEMENCIA VASCULAR MIXTA, CORTICAL Y SUBCORTICAL'},
{ codigo: 'F018', descripcion: 'OTRAS DEMENCIAS VASCULARES'},
{ codigo: 'F019', descripcion: 'DEMENCIA VASCULAR, NO ESPECIFICADA'},
{ codigo: 'F02', descripcion: 'DEMENCIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'F020', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE PICK (G3L.0+)'},
{ codigo: 'F021', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE CREUTZFELDT-JAKOB (A81.0+)'},
{ codigo: 'F022', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE HUNTINGTON (GL0+)'},
{ codigo: 'F023', descripcion: 'DEMENCIA EN LA ENFERMEDAD DE PARKINSON (G20+)'},
{ codigo: 'F024', descripcion: 'DEMENCIA EN LA ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH) (B22.0+)'},
{ codigo: 'F028', descripcion: 'DEMENCIA EN OTRAS ENFERMEDADES ESPECIFICADAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'F03', descripcion: 'DEMENCIA, NO ESPECIFICADA'},
{ codigo: 'F04', descripcion: 'SINDROME AMNESICO ORGANICO NO INDUCIDO POR ALCOHOL O POR OTRAS SUSTANCIAS PSICOACTIVAS'},
{ codigo: 'F05', descripcion: 'DELIRIO, NO INDUCIDO POR ALCOHOL O POR OTRAS SUSTANCIAS PSICOACTIVAS'},
{ codigo: 'F050', descripcion: 'DELIRIO NO SUPERPUESTO A UN CUADRO DE DEMENCIA, ASI DESCRITO'},
{ codigo: 'F051', descripcion: 'DELIRIO SUPERPUESTO A UN CUADRO DE DEMENCIA'},
{ codigo: 'F058', descripcion: 'OTROS DELIRIOS'},
{ codigo: 'F059', descripcion: 'DELIRIO, NO ESPECIFICADO'},
{ codigo: 'F06', descripcion: 'OTROS TRASTORNOS MENTALES DEBIDOS A LESION Y DISFUNCION CEREBRAL, Y A ENFERMEDAD FISICA'},
{ codigo: 'F060', descripcion: 'ALUCINOSIS ORGANICA'},
{ codigo: 'F061', descripcion: 'TRASTORNO CATATONICO, ORGANICO'},
{ codigo: 'F062', descripcion: 'TRASTORNO DELIRANTE (ESQUIZOFRENIFORME), ORGANICO'},
{ codigo: 'F063', descripcion: 'TRASTORNOS DEL HUMOR (AFECTIVOS), ORGANICOS'},
{ codigo: 'F064', descripcion: 'TRASTORNO DE ANSIEDAD, ORGANICO'},
{ codigo: 'F065', descripcion: 'TRASTORNO DISOCIATIVO, ORGANICO'},
{ codigo: 'F066', descripcion: 'TRASTORNO DE LABILIDAD EMOCIONAL (ASTENICO) ORGANICO'},
{ codigo: 'F067', descripcion: 'TRASTORNO COGNOSCITIVO LEVE'},
{ codigo: 'F068', descripcion: 'OTROS TRASTORNOS MENTALES ESPECIFICADOS DEBIDOS A LESION Y DISFUNCION CEREBRAL Y A ENFERMEDAD FISICA'},
{ codigo: 'F069', descripcion: 'TRASTORNO MENTAL NO ESPECIFICADO DEBIDO A LESION Y DISFUNCION CEREBRAL Y A ENFERMEDAD FISICA'},
{ codigo: 'F07', descripcion: 'TRASTORNOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO DEBIDOS A ENFERMEDAD, LESION O DISFUNCION CEREBRAL'},
{ codigo: 'F070', descripcion: 'TRASTORNO DE LA PERSONALIDAD, ORGANICO'},
{ codigo: 'F071', descripcion: 'SINDROME POSTENCEFALITICO'},
{ codigo: 'F072', descripcion: 'SINDROME POSTCONCUSIONAL'},
{ codigo: 'F078', descripcion: 'OTROS TRASTORNOS ORGANICOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO DEBIDOS A ENFERMEDAD, LESION Y DISFUCION CEREBRALES'},
{ codigo: 'F079', descripcion: 'TRASTORNO ORGANICICO DE LA PERSONALIDAD Y DEL COMPORTAMIENTO NO ESPECIFICADO DEBIDO A ENFERMEDAD, LESION Y DISFUNCION CEREBRAL'},
{ codigo: 'F09', descripcion: 'TRASTORNO MENTAL ORGANICO O SINTOMATICO, NO ESPECIFICADO'},
{ codigo: 'F10', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE ALCOHOL'},
{ codigo: 'F100', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE ALCOHOL, INTOXICACION AGUDA'},
{ codigo: 'F101', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALCOHOL, USO NOCIVO'},
{ codigo: 'F102', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE ALCOHOL SINDROME DE DEPENDENCIA'},
{ codigo: 'F103', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE ALCOHOL, ESTADO ABSTINENCIA'},
{ codigo: 'F104', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO ALCOHOL, ESTADO ABSTINENCIA CON DELIRIO'},
{ codigo: 'F105', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE ALCOHOL, TRASTORNO PSICOTICO'},
{ codigo: 'F106', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE ALCOHOL, SINDROME AMNESICO'},
{ codigo: 'F107', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO ALCOHOL, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F108', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO ALCOHOL, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F109', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE ALCOHOL, TRASTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F11', descripcion: 'TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS'},
{ codigo: 'F110', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS, INTOXICACION AGUDA'},
{ codigo: 'F111', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS, USO NOCIVO'},
{ codigo: 'F112', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OPIACEOS, SINDROME DE DEPENDENCIA'},
{ codigo: 'F113', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE OPIACEOS, ESTADO DE ABSTINENCIA'},
{ codigo: 'F114', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO OPIACEOS ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F115', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE OPIACEOS, TRASTORNO PSICOTICO'},
{ codigo: 'F116', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO OPIACEOS, SINDROME AMNESICO'},
{ codigo: 'F117', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO OPIACEOS, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F118', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE OPIACEOS, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F119', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO OPIACEOS, TRASTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F12', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES'},
{ codigo: 'F120', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES, INTOXICACION AGUDA'},
{ codigo: 'F121', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES, USO NOCIVO'},
{ codigo: 'F122', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES, SINDROME DEPENDECIA'},
{ codigo: 'F123', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO CANNABINOIDES, ESTADO ABSTINENCIA'},
{ codigo: 'F124', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO CANNABINOIDES, ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F125', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES, TRASTORNO PSICOTICO'},
{ codigo: 'F126', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES, SINDROME AMNESICO'},
{ codigo: 'F127', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F128', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F129', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE CANNABINOIDES, TRANSTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F13', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO SEDANTES O HIPNOTICOS'},
{ codigo: 'F130', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, INTOXICACION AGUDA'},
{ codigo: 'F131', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, USO NOCIVO'},
{ codigo: 'F132', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, SINDROME DE DEPENDENCIA'},
{ codigo: 'F133', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, ESTADO ABSTINENCIA'},
{ codigo: 'F134', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F135', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, TRASTORNO PSICOTICO'},
{ codigo: 'F136', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, SINDROME AMNESICO'},
{ codigo: 'F137', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F138', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F139', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE SEDANTES O HIPNOTICOS, TRASTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F14', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO DE COCAINA'},
{ codigo: 'F140', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, INTOXICACION AGUDA'},
{ codigo: 'F141', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, USO NOCIVO'},
{ codigo: 'F142', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, SINDROME DEPENDENCIA'},
{ codigo: 'F143', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, ESTADO ABSTINENCIA'},
{ codigo: 'F144', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, ESTADO ABSTINENCIA CON DELIRIO'},
{ codigo: 'F145', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, TRASTORNO PSICOTICO'},
{ codigo: 'F146', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, SINDROME AMNESICO'},
{ codigo: 'F147', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F148', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F149', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR USO DE COCAINA, TRASTORNOS MENTALES Y DE COMPORTAMIENTO, NO ESPECIFICADOS'},
{ codigo: 'F15', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA'},
{ codigo: 'F150', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, INTOXICACION AGUDA'},
{ codigo: 'F151', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, USO NOCIVO'},
{ codigo: 'F152', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, SINDROME DEPENDENCIA'},
{ codigo: 'F153', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, ESTADO ABSTINENCIA'},
{ codigo: 'F154', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA,ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F155', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, TRASTORNO PSICOTICO'},
{ codigo: 'F156', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, SINDROME AMNESICO'},
{ codigo: 'F157', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F158', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F159', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE OTROS ESTIMULANTES INCLUIDA CAFEINA, TRASTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F16', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDO AL USO DE ALUCINOGENOS'},
{ codigo: 'F160', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, INTOXICACION AGUDA'},
{ codigo: 'F161', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, USO NOCIVO'},
{ codigo: 'F162', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, SINDROME DEPENDENCIA'},
{ codigo: 'F163', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, ESTADO ABSTINENCIA'},
{ codigo: 'F164', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F165', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, TRASTORNO PSICOTICO'},
{ codigo: 'F166', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, SINDROME AMNESICO'},
{ codigo: 'F167', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F168', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F169', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE ALUCINOGENOS, TRASTORNOS MENTALES Y DE COMPORTAMIENTO, NO ESPECIFICADOS'},
{ codigo: 'F17', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO DE TABACO'},
{ codigo: 'F170', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, INTOXICACION AGUDA'},
{ codigo: 'F171', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, USO NOCIVO'},
{ codigo: 'F172', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, SINDROME DEPENDENCIA'},
{ codigo: 'F173', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, ESTADO DE ABSTINENCIA'},
{ codigo: 'F174', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F175', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, TRASTORNO PSICOTICO'},
{ codigo: 'F176', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, SINDROME AMNESICO'},
{ codigo: 'F177', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F178', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F179', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE TABACO, TRASTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F18', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES'},
{ codigo: 'F180', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, INTOXICACION AGUDA'},
{ codigo: 'F181', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, USO NOCIVO'},
{ codigo: 'F182', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, SINDROME DEPENDENCIA'},
{ codigo: 'F183', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, ESTADO ABSTINENCIA'},
{ codigo: 'F184', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F185', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, TRASTORNO PSICOTICO'},
{ codigo: 'F186', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, SINDROME AMNESICO'},
{ codigo: 'F187', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, TRASTORNO PSICOTICO RESIDUAL C TARDIO'},
{ codigo: 'F188', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F189', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE DISOLVENTES VOLATILES, TRASTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F19', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS'},
{ codigo: 'F190', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, INTOXICACION AGUDA'},
{ codigo: 'F191', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, USO NOCIVO'},
{ codigo: 'F192', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, SINDROME DEPENDENCIA'},
{ codigo: 'F193', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, ESTADO ABSTINENCIA'},
{ codigo: 'F194', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, ESTADO ABSTINENCIA DELIRIO'},
{ codigo: 'F195', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, TRASTORNO PSICOTICO'},
{ codigo: 'F196', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, SINDROME AMNESICO'},
{ codigo: 'F197', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, TRASTORNO PSICOT RESIDUAL C TARDIO'},
{ codigo: 'F198', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO'},
{ codigo: 'F199', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO POR EL USO DE MULTIPLES DROGAS Y USO DE OTRAS SUSTANCIAS PSICOACTIVAS, OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO NO ESPECIFICADOS'},
{ codigo: 'F20', descripcion: 'ESQUIZOFRENIA'},
{ codigo: 'F200', descripcion: 'ESQUIZOFRENIA PARANOIDE'},
{ codigo: 'F201', descripcion: 'ESQUIZOFRENIA HEBEFRENICA'},
{ codigo: 'F202', descripcion: 'ESQUIZOFRENIA CATATONICA'},
{ codigo: 'F203', descripcion: 'ESQUIZOFRENIA INDIFERENCIADA'},
{ codigo: 'F204', descripcion: 'DEPRESION POSTESQUIZOFRENICA'},
{ codigo: 'F205', descripcion: 'ESQUIZOFRENIA RESIDUAL'},
{ codigo: 'F206', descripcion: 'ESQUIZOFRENIA SIMPLE'},
{ codigo: 'F208', descripcion: 'OTRAS ESQUIZOFRENIAS'},
{ codigo: 'F209', descripcion: 'ESQUIZOFRENIA, NO ESPECIFICADA'},
{ codigo: 'F21', descripcion: 'TRASTORNO ESQUIZOTIPICO'},
{ codigo: 'F22', descripcion: 'TRASTORNOS DELIRANTES PERSISTENTES'},
{ codigo: 'F220', descripcion: 'TRASTORNO DELIRANTE'},
{ codigo: 'F228', descripcion: 'OTROS TRASTORNOS DELIRANTES PERSISTENTES'},
{ codigo: 'F229', descripcion: 'TRASTORNO DELIRANTE PERSISTENTE, NO ESPECIFICADO'},
{ codigo: 'F23', descripcion: 'TRASTORNOS PSICOTICOS AGUDOS Y TRANSITORIOS'},
{ codigo: 'F230', descripcion: 'TRASTORNO PSICOTICO AGUDO POLIMORFO, SIN SINTOMAS DE ESQUIZOFRENIA'},
{ codigo: 'F231', descripcion: 'TRASTORNO PSICOTICO AGUDO POLIMORFO, CON SINTOMAS DE ESQUIZOFRENIA'},
{ codigo: 'F232', descripcion: 'TRASTORNO PSICOTICO AGUDO DE TIPO ESQUIZOFRENICO'},
{ codigo: 'F233', descripcion: 'OTRO TRASTORNO PSICOTICO AGUDO, CON PREDOMINIO DE IDEAS DELIRANTES'},
{ codigo: 'F238', descripcion: 'OTROS TRASTORNOS PSICOTICOS AGUDOS Y TRANSITORIOS'},
{ codigo: 'F239', descripcion: 'TRASTORNO PSICOTICO AGUDO Y TRANSITORIO, NO ESPECIFICADO'},
{ codigo: 'F24', descripcion: 'TRASTORNO DELIRANTE INDUCIDO'},
{ codigo: 'F25', descripcion: 'TRASTORNOS ESQUIZOAFECTIVOS'},
{ codigo: 'F250', descripcion: 'TRASTORNO ESQUIZOAFECTIVO DE TIPO MANIACO'},
{ codigo: 'F251', descripcion: 'TRASTORNO ESQUIZOAFECTIVO DE TIPO DEPRESIVO'},
{ codigo: 'F252', descripcion: 'TRASTORNO ESQUIZOAFECTIVO DE TIPO MIXTO'},
{ codigo: 'F258', descripcion: 'OTROS TRASTORNOS ESQUIZOAFECTIVOS'},
{ codigo: 'F259', descripcion: 'TRASTORNO ESQUIZOAFECTIVO, NO ESPECIFICADO'},
{ codigo: 'F28', descripcion: 'OTROS TRASTORNOS PSICOTICOS DE ORIGEN NO ORGANICO'},
{ codigo: 'F29', descripcion: 'PSICOSIS DE ORIGEN NO ORGANICO, NO ESPECIFICADA'},
{ codigo: 'F30', descripcion: 'EPISODIO MANIACO'},
{ codigo: 'F300', descripcion: 'HIPOMANIA'},
{ codigo: 'F301', descripcion: 'MANIA SIN SINTOMAS PSICOTICOS'},
{ codigo: 'F302', descripcion: 'MANIA CON SINTOMAS PSICOTICOS'},
{ codigo: 'F308', descripcion: 'OTROS EPISODIOS MANIACOS'},
{ codigo: 'F309', descripcion: 'EPISODIO MANIACO, NO ESPECIFICADO'},
{ codigo: 'F31', descripcion: 'TRASTORNO AFECTIVO BIPOLAR'},
{ codigo: 'F310', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO HIPOMANIACO PRESENTE'},
{ codigo: 'F311', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANIACO PRESENTE SIN SINTOMAS PSICOTICOS'},
{ codigo: 'F312', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANIACO PRESENTE CON SINTOMAS PSICOTICOS'},
{ codigo: 'F313', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO PRESENTE LEVE O MODERADO'},
{ codigo: 'F314', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SINTOMA PSICOTICO'},
{ codigo: 'F315', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE CON SINTOMA PSICOTICO'},
{ codigo: 'F316', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, EPISODIO MIXTO PRESENTE'},
{ codigo: 'F317', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, ACTUALMENTE EN REMISION'},
{ codigo: 'F318', descripcion: 'OTROS TRASTORNOS AFECTIVOS BIPOLARES'},
{ codigo: 'F319', descripcion: 'TRASTORNO AFECTIVO BIPOLAR, NO ESPECIFICADO'},
{ codigo: 'F32', descripcion: 'EPISODIO DEPRESIVO'},
{ codigo: 'F320', descripcion: 'EPISODIO DEPRESIVO LEVE'},
{ codigo: 'F321', descripcion: 'EPISODIO DEPRESIVO MODERADO'},
{ codigo: 'F322', descripcion: 'EPISODIO DEPRESIVO GRAVE SIN SINTOMAS PSICOTICOS'},
{ codigo: 'F323', descripcion: 'EPISODIO DEPRESIVO GRAVE CON SINTOMAS PSICOTICOS'},
{ codigo: 'F328', descripcion: 'OTROS EPISODIOS DEPRESIVOS'},
{ codigo: 'F329', descripcion: 'EPISODIO DEPRESIVO, NO ESPECIFICADO'},
{ codigo: 'F33', descripcion: 'TRASTORNO DEPRESIVO RECURRENTE'},
{ codigo: 'F330', descripcion: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO LEVE PRESENTE'},
{ codigo: 'F331', descripcion: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO MODERADO PRESENTE'},
{ codigo: 'F332', descripcion: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SINTOMA PSICOTICO'},
{ codigo: 'F333', descripcion: 'TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE, CON SINTOMA PSICOTICO'},
{ codigo: 'F334', descripcion: 'TRASTORNO DEPRESIVO RECURRENTE ACTUALMENTE EN REMISION'},
{ codigo: 'F338', descripcion: 'OTROS TRASTORNOS DEPRESIVOS RECURRENTES'},
{ codigo: 'F339', descripcion: 'TRASTORNO DEPRESIVO RECURRENTE, NO ESPECIFICADO'},
{ codigo: 'F34', descripcion: 'TRASTORNOS DEL HUMOR (AFECTIVOS) PERSISTENTE'},
{ codigo: 'F340', descripcion: 'CICLOTIMIA'},
{ codigo: 'F341', descripcion: 'DISTIMIA'},
{ codigo: 'F348', descripcion: 'OTROS TRASTORNOS DEL HUMOR (AFECTIVOS) PERSISTENTES'},
{ codigo: 'F349', descripcion: 'TRASTORNO PERSISTENTE DEL HUMOR (AFECTIVO), NO ESPECIFICADO'},
{ codigo: 'F38', descripcion: 'OTROS TRASTORNOS DEL HUMOR (AFECTIVOS)'},
{ codigo: 'F380', descripcion: 'OTROS TRASTORNOS DEL HUMOR (AFECTIVOS), AISLADOS'},
{ codigo: 'F381', descripcion: 'OTROS TRASTORNOS DEL HUMOR (AFECTIVOS), RECURRENTES'},
{ codigo: 'F388', descripcion: 'OTROS TRASTORNOS DEL HUMOR (AFECTIVOS), ESPECIFICADOS'},
{ codigo: 'F39', descripcion: 'TRASTORNO DEL HUMOR (AFECTIVO), NO ESPECIFICADO'},
{ codigo: 'F40', descripcion: 'TRASTORNOS FOBICOS DE ANSIEDAD'},
{ codigo: 'F400', descripcion: 'AGORAFOBIA'},
{ codigo: 'F401', descripcion: 'FOBIAS SOCIALES'},
{ codigo: 'F402', descripcion: 'FOBIAS ESPECIFICAS (AISLADAS)'},
{ codigo: 'F408', descripcion: 'OTROS TRASTORNOS FOBICOS DE ANSIEDAD'},
{ codigo: 'F409', descripcion: 'TRASTORNO FOBICO DE ANSIEDAD, NO ESPECIFICADO'},
{ codigo: 'F41', descripcion: 'OTROS TRASTORNOS DE ANSIEDAD'},
{ codigo: 'F410', descripcion: 'TRASTORNO DE PANICO (ANSIEDAD PAROXISTICA EPISODICA)'},
{ codigo: 'F411', descripcion: 'TRASTORNO DE ANSIEDAD GENERALIZADA'},
{ codigo: 'F412', descripcion: 'TRASTORNO MIXTO DE ANSIEDAD Y DEPRESION'},
{ codigo: 'F413', descripcion: 'OTROS TRASTORNOS DE ANSIEDAD MIXTOS'},
{ codigo: 'F418', descripcion: 'OTROS TRASTORNOS DE ANSIEDAD ESPECIFICADOS'},
{ codigo: 'F419', descripcion: 'TRASTORNO DE ANSIEDAD, NO ESPECIFICADO'},
{ codigo: 'F42', descripcion: 'TRASTORNO OBSESIVO-COMPULSIVO'},
{ codigo: 'F420', descripcion: 'PREDOMINIO DE PENSAMIENTOS O RUMIACIONES OBSESIVAS'},
{ codigo: 'F421', descripcion: 'PREDOMINIO DE ACTOS COMPULSIVOS (RITUALES OBSESIVOS)'},
{ codigo: 'F422', descripcion: 'ACTOS E IDEAS OBSESIVAS MIXTOS'},
{ codigo: 'F428', descripcion: 'OTROS TRASTORNOS OBSESIVO-COMPULSIVOS'},
{ codigo: 'F429', descripcion: 'TRASTORNO OBSESIVO-COMPULSIVO, NO ESPECIFICADO'},
{ codigo: 'F43', descripcion: 'REACCION AL ESTRES GRAVE Y TRASTORNOS DE ADAPTACION'},
{ codigo: 'F430', descripcion: 'REACCION AL ESTRES AGUDO'},
{ codigo: 'F431', descripcion: 'TRASTORNO DE ESTRES POSTRAUMATICO'},
{ codigo: 'F432', descripcion: 'TRASTORNOS DE ADAPTACION'},
{ codigo: 'F438', descripcion: 'OTRAS REACCIONES AL ESTRES GRAVE'},
{ codigo: 'F439', descripcion: 'REACCION AL ESTRES GRAVE, NO ESPECIFICADA'},
{ codigo: 'F44', descripcion: 'TRASTORNOS DISOCIATIVOS (DE CONVERSION)'},
{ codigo: 'F440', descripcion: 'AMNESIA DISOCIATIVA'},
{ codigo: 'F441', descripcion: 'FUGA DISOCIATIVA'},
{ codigo: 'F442', descripcion: 'ESTUPOR DISOCIATIVO'},
{ codigo: 'F443', descripcion: 'TRASTORNOS DE TRANCE Y DE POSESION'},
{ codigo: 'F444', descripcion: 'TRASTORNOS DISOCIATIVOS DEL MOVIMIENTO'},
{ codigo: 'F445', descripcion: 'CONVULSIONES DISOCIATIVAS'},
{ codigo: 'F446', descripcion: 'ANESTESIA DISOCIATIVA Y PERDIDA SENSORIAL'},
{ codigo: 'F447', descripcion: 'TRASTORNOS DISOCIATIVOS MIXTOS (Y DE CONVERSION)'},
{ codigo: 'F448', descripcion: 'OTROS TRASTORNOS DISOCIATIVOS (DE CONVERSION)'},
{ codigo: 'F449', descripcion: 'TRASTORNO DISOCIATIVO (DE CONVERSION), NO ESPECIFICADO'},
{ codigo: 'F45', descripcion: 'TRASTORNOS SOMATOMORFOS'},
{ codigo: 'F450', descripcion: 'TRASTORNO DE SOMATIZACION'},
{ codigo: 'F451', descripcion: 'TRASTORNO SOMATOMORFO INDIFERENCIADO'},
{ codigo: 'F452', descripcion: 'TRASTORNO HIPOCONDRIACO'},
{ codigo: 'F453', descripcion: 'DISFUNCION AUTONOMICA SOMATOMORFA'},
{ codigo: 'F454', descripcion: 'TRASTORNO DE DOLOR PERSISTENTE SOMATOMORFO'},
{ codigo: 'F458', descripcion: 'OTROS TRASTORNOS SOMATOMORFOS'},
{ codigo: 'F459', descripcion: 'TRASTORNO SOMATOMORFO, NO ESPECIFICADO'},
{ codigo: 'F48', descripcion: 'OTROS TRASTORNOS NEUROTICOS'},
{ codigo: 'F480', descripcion: 'NEURASTENIA'},
{ codigo: 'F481', descripcion: 'SINDROME DE DESPERSONALIZACION Y DESVINCULACION DE LA REALIDAD'},
{ codigo: 'F488', descripcion: 'OTROS TRASTORNOS NEUROTICOS ESPECIFICADOS'},
{ codigo: 'F489', descripcion: 'TRASTORNO NEUROTICO, NO ESPECIFICADO'},
{ codigo: 'F50', descripcion: 'TRASTORNOS DE LA INGESTION DE ALIMENTOS'},
{ codigo: 'F500', descripcion: 'ANOREXIA NERVIOSA'},
{ codigo: 'F501', descripcion: 'ANOREXIA NERVIOSA ATIPICA'},
{ codigo: 'F502', descripcion: 'BULIMIA NERVIOSA'},
{ codigo: 'F503', descripcion: 'BULIMIA NERVIOSA ATIPICA'},
{ codigo: 'F504', descripcion: 'HIPERFAGIA ASOCIADA CON OTRAS ALTERACIONES PSICOLOGICAS'},
{ codigo: 'F505', descripcion: 'VOMITOS ASOCIADOS CON OTRAS ALTERACIONES PSICOLOGICAS'},
{ codigo: 'F508', descripcion: 'OTROS TRASTORNOS DE LA INGESTION DE ALIMENTOS'},
{ codigo: 'F509', descripcion: 'TRASTORNO DE LA INGESTION DE ALIMENTOS, NO ESPECIFICADO'},
{ codigo: 'F51', descripcion: 'TRASTORNOS NO ORGANICOS DEL SUE—O'},
{ codigo: 'F510', descripcion: 'INSOMNIO NO ORGANICO'},
{ codigo: 'F511', descripcion: 'HIPERSOMNIO NO ORGANICO'},
{ codigo: 'F512', descripcion: 'TRASTORNO NO ORGANICO DEL CICLO SUE—O-VIGILIA'},
{ codigo: 'F513', descripcion: 'SONAMBULISMO'},
{ codigo: 'F514', descripcion: 'TERRORES DEL SUE—O (TERRORES NOCTURNOS)'},
{ codigo: 'F515', descripcion: 'PESADILLAS'},
{ codigo: 'F518', descripcion: 'OTROS TRASTORNOS NO ORGANICOS DEL SUE—O'},
{ codigo: 'F519', descripcion: 'TRASTORNO NO ORGANICO DEL SUE—O, NO ESPECIFICADO'},
{ codigo: 'F52', descripcion: 'DISFUNCION SEXUAL NO OCASIONADA POR TRASTORNO NI ENFERMEDAD ORGANICOS'},
{ codigo: 'F520', descripcion: 'FALTA O PERDIDA DEL DESEO SEXUAL'},
{ codigo: 'F521', descripcion: 'AVERSION AL SEXO Y FALTA DE GOCE SEXUAL'},
{ codigo: 'F522', descripcion: 'FALLA DE LA RESPUESTA GENITAL'},
{ codigo: 'F523', descripcion: 'DISFUNCION ORGASMICA'},
{ codigo: 'F524', descripcion: 'EYACULACION PRECOZ'},
{ codigo: 'F525', descripcion: 'VAGINISMO NO ORGANICO'},
{ codigo: 'F526', descripcion: 'DISPAREUNIA NO ORGANICA'},
{ codigo: 'F527', descripcion: 'IMPULSO SEXUAL EXCESIVO'},
{ codigo: 'F528', descripcion: 'OTRAS DISFUNCIONES SEXUALES, NO OCASIONADAS POR TRASTORNO NI ENFERMEDAD ORGANICOS'},
{ codigo: 'F529', descripcion: 'DISFUNCION SEXUAL NO OCASIONADA POR TRASTORNO NI ENFERMEDAD ORGANICOS, NO ESPECIFICADA'},
{ codigo: 'F53', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'F530', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO LEVES ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'F531', descripcion: 'TRASTORNOS MENTALES Y DE COMPORTAMIENTO GRAVES, ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'F538', descripcion: 'OTROS TRASTORNOS MENTALES Y DE COMPORTAMIENTO ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'F539', descripcion: 'TRASTORNO MENTAL PUERPERAL, NO ESPECIFICADO'},
{ codigo: 'F54', descripcion: 'FACTORES PSICOLOGICOS Y DEL COMPORTAMIENTO ASOCIADOS CON TRASTORNOS O ENFERMEDADES CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'F55', descripcion: 'ABUSO DE SUSTANCIAS QUE NO PRODUCEN DEPENDENCIA'},
{ codigo: 'F59', descripcion: 'SINDROMES DEL COMPORTAMIENTO ASOCIADOS CON ALTERACIONES FISIOLOGICAS Y FACTOES FISICOS NO ESPECIFICADOS'},
{ codigo: 'F60', descripcion: 'TRASTORNOS ESPECIFICOS DE LA PERSONALIDAD'},
{ codigo: 'F600', descripcion: 'TRASTORNO PARANOIDE DE LA PERSONALIDAD'},
{ codigo: 'F601', descripcion: 'TRASTORNO ESQUIZOIDE DE LA PERSONALIDAD'},
{ codigo: 'F602', descripcion: 'TRASTORNO ASOCIAL DE LA PERSONALIDAD'},
{ codigo: 'F603', descripcion: 'TRASTORNO DE LA PERSONALIDAD EMOCIONALMENTE INESTABLE'},
{ codigo: 'F604', descripcion: 'TRASTORNO HISTRIONICO DE LA PERSONALIDAD'},
{ codigo: 'F605', descripcion: 'TRASTORNO ANANCASTICO DE LA PERSONALIDAD'},
{ codigo: 'F606', descripcion: 'TRASTORNO DE LA PERSONALIDAD ANSIOSA (EVASIVA, ELUSIVA)'},
{ codigo: 'F607', descripcion: 'TRASTORNO DE LA PERSONALIDAD DEPENDIENTE'},
{ codigo: 'F608', descripcion: 'OTROS TRASTORNOS ESPECIFICOS DE LA PERSONALIDAD'},
{ codigo: 'F609', descripcion: 'TRASTORNO DE LA PERSONALIDAD, NO ESPECIFICADO'},
{ codigo: 'F61', descripcion: 'TRASTORNOS MIXTOS Y OTROS TRASTORNOS DE LA PERSONALIDAD'},
{ codigo: 'F62', descripcion: 'CAMBIOS PERDURABLES DE LA PERSONALIDAD, NO ATRIBUIBLES A LESION O A ENFERMEDAD CEREBRAL'},
{ codigo: 'F620', descripcion: 'CAMBIO PERDURABLE DE PERSONALIDAD DESPUES DE EXPERIENCIA CATASTROFICA'},
{ codigo: 'F621', descripcion: 'CAMBIO PERDURABLE DE PERSONALIDAD CONSECUTIVO A UNA ENFERMEDAD PSIQUIATRICA'},
{ codigo: 'F628', descripcion: 'OTROS CAMBIOS PERDURABLES DE LA PERSONALIDAD'},
{ codigo: 'F629', descripcion: 'CAMBIO PERDURABLE DE LA PERSONALIDAD, NO ESPECIFICADO'},
{ codigo: 'F63', descripcion: 'TRASTORNOS DE LOS HABITOS Y DE LOS IMPULSOS'},
{ codigo: 'F630', descripcion: 'JUEGO PATOLOGICO'},
{ codigo: 'F631', descripcion: 'PIROMANIA'},
{ codigo: 'F632', descripcion: 'HURTO PATOLOGICO (CLEPTOMANIA)'},
{ codigo: 'F633', descripcion: 'TRICOTILOMANIA'},
{ codigo: 'F638', descripcion: 'OTROS TRASTORNOS DE LOS HABITOS Y DE LOS IMPULSOS'},
{ codigo: 'F639', descripcion: 'TRASTORNO DE LOS HABITOS Y DE LOS IMPULSOS, NO ESPECIFICADO'},
{ codigo: 'F64', descripcion: 'TRASTORNOS DE LA IDENTIDAD DE GENERO'},
{ codigo: 'F640', descripcion: 'TRANSEXUALISMO'},
{ codigo: 'F641', descripcion: 'TRANSVESTISMO DE ROL DUAL'},
{ codigo: 'F642', descripcion: 'TRASTORNO DE LA IDENTIDAD DE GENERO EN LA NI—EZ'},
{ codigo: 'F648', descripcion: 'OTROS TRASTORNOS DE LA IDENTIDAD DE GENERO'},
{ codigo: 'F649', descripcion: 'TRASTORNO DE LA IDENTIDAD DE GENERO, NO ESPECIFICADO'},
{ codigo: 'F65', descripcion: 'TRASTORNOS DE LA PREFERENCIA SEXUAL'},
{ codigo: 'F650', descripcion: 'FETICHISMO'},
{ codigo: 'F651', descripcion: 'TRANSVESTISMO FETICHISTA'},
{ codigo: 'F652', descripcion: 'EXHIBICIONISMO'},
{ codigo: 'F653', descripcion: 'VOYEURISMO'},
{ codigo: 'F654', descripcion: 'PEDOFILIA'},
{ codigo: 'F655', descripcion: 'SADOMASOQUISMO'},
{ codigo: 'F656', descripcion: 'TRASTORNOS MULTIPLES DE LA PREFERENCIA SEXUAL'},
{ codigo: 'F658', descripcion: 'OTROS TRASTORNOS DE LA PREFERENCIA SEXUAL'},
{ codigo: 'F659', descripcion: 'TRASTORNO DE LA PREFERENCIA SEXUAL, NO ESPECIFICADO'},
{ codigo: 'F66', descripcion: 'TRASTORNOS PSICOLOGICOS Y DEL COMPORTAMIENTO ASOCIADOS CON DESARROLLO Y ORIENTACION SEXUAL'},
{ codigo: 'F660', descripcion: 'TRASTORNO DE LA MADURACION SEXUAL'},
{ codigo: 'F661', descripcion: 'ORIENTACION SEXUAL EGODISTONICA'},
{ codigo: 'F662', descripcion: 'TRASTORNO DE LA RELACION SEXUAL'},
{ codigo: 'F668', descripcion: 'OTROS TRASTORNOS DEL DESARROLLO PSICOSEXUAL'},
{ codigo: 'F669', descripcion: 'TRASTORNO DEL DESARROLLO PSICOSEXUAL, NO ESPECIFICADO'},
{ codigo: 'F68', descripcion: 'OTROS TRASTORNOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS'},
{ codigo: 'F680', descripcion: 'ELABORACION DE SINTOMAS FISICOS POR CAUSAS PSICOLOGICAS'},
{ codigo: 'F681', descripcion: 'PRODUCCION INTENCIONAL O SIMULACION DE SINTOMAS O DE INCAPACIDADADES TANTO FISICAS COMO PSICOLOGICAS (TRASTORNO FACTICIO)'},
{ codigo: 'F688', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS'},
{ codigo: 'F69', descripcion: 'TRASTORNO DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS, NO ESPECIFICADO'},
{ codigo: 'F70', descripcion: 'RETRASO METAL LEVE'},
{ codigo: 'F700', descripcion: 'RETRASO MENTAL LEVE, DETERIORO DEL COMPORTAMIENTO NULO O MINIMO'},
{ codigo: 'F701', descripcion: 'RETRASO MENTAL LEVE DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, REQUIERE ATENCION O TRATAMIENTO'},
{ codigo: 'F708', descripcion: 'RETRASO MENTAL LEVE, OTROS DETERIOROS DEL COMPORTAMIENTO'},
{ codigo: 'F709', descripcion: 'RETRASO MENTAL LEVE, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO'},
{ codigo: 'F71', descripcion: 'RETRASO METAL MODERADO'},
{ codigo: 'F710', descripcion: 'RETRASO MENTAL MODERADO, DETERIORO DEL COMPORTAMIENTO NULO O MINIMO'},
{ codigo: 'F711', descripcion: 'RETRASO MENTAL MODERADO, DETERIORO DEL COMPORT SIGNIFICATIVO. REQUIERE ATENCION O TRATAMIENTO'},
{ codigo: 'F718', descripcion: 'RETRASO MENTAL MODERADO, OTROS DETERIOROS DEL COMPORTAMIENTO'},
{ codigo: 'F719', descripcion: 'RETRASO MENTAL MODERADO, DETERIORO DE COMPORTAMIENTO DE GRADO NO ESPECIFICADO'},
{ codigo: 'F72', descripcion: 'RETRASO MENTAL GRAVE'},
{ codigo: 'F720', descripcion: 'RETRASO MENTAL GRAVE, DETERIORO DEL COMPORTAMIENTO NULO O MINIMO'},
{ codigo: 'F721', descripcion: 'RETRASO MENTAL GRAVE, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, REQUIERE ATENCION O TRATAMIENTO'},
{ codigo: 'F728', descripcion: 'RETRASO MENTAL GRAVE, OTROS DETERIOROS DEL COMPORTAMIENTO'},
{ codigo: 'F729', descripcion: 'RETRASO MENTAL GRAVE, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO'},
{ codigo: 'F73', descripcion: 'RETRASO MENTAL PROFUNDO'},
{ codigo: 'F730', descripcion: 'RETRASO MENTAL PROFUNDO, DETERIORO DEL COMPORTAMIENTO NULO O MINIMO'},
{ codigo: 'F731', descripcion: 'RETRASO MENTAL PROFUNDO, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, REQUIERE ATENCION O TRATAMINETO'},
{ codigo: 'F738', descripcion: 'RETRASO MENTAL PROFUNDO, OTROS DETERIOROS DEL COMPORTAMIENTO'},
{ codigo: 'F739', descripcion: 'RETRASO MENTAL PROFUNDO, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO'},
{ codigo: 'F78', descripcion: 'OTROS TIPOS DE RETRASO MENTAL'},
{ codigo: 'F780', descripcion: 'OTROS TIPOS DE RETRASO MENTAL, DETERIORO DEL COMPORTAMIENTO NULO O MINIMO'},
{ codigo: 'F781', descripcion: 'OTROS TIPOS DE RETRASO MENTAL, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, REQUIERE ATENCION O TRATAMIENTO'},
{ codigo: 'F788', descripcion: 'OTROS TIPOS DE RETRASO MENTAL, OTROS DETERIOROS DEL COMPORTAMIENTO'},
{ codigo: 'F789', descripcion: 'OTROS TIPOS DE RETRASO MENTAL, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO'},
{ codigo: 'F79', descripcion: 'RETRASO MENTAL, NO ESPECIFICADO'},
{ codigo: 'F790', descripcion: 'RETRASO MENTAL, NO ESPECIFICADO, DETERIORO DEL COMPORTAMIENTO NULO O MINIMO'},
{ codigo: 'F791', descripcion: 'RETRASO MENTAL, NO ESPECIFICADO, DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, REQUIERE ATENCION O TRATAMIENTO'},
{ codigo: 'F798', descripcion: 'RETRASO MENTAL, NO ESPECIFICADO, OTROS DETERIOROS DEL COMPORTAMIENTO'},
{ codigo: 'F799', descripcion: 'RETRASO MENTAL, NO ESPECIFICADO, DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO'},
{ codigo: 'F80', descripcion: 'TRASTORNOS ESPECIFICOS DEL DESARROLLO DEL HABLA Y DEL LENGUAJE'},
{ codigo: 'F800', descripcion: 'TRASTORNO ESPECIFICO DE LA PRONUNCIACION'},
{ codigo: 'F801', descripcion: 'TRASTORNO DEL LENGUAJE EXPRESIVO'},
{ codigo: 'F802', descripcion: 'TRASTORNO DE LA RECEPCION DEL LENGUAJE'},
{ codigo: 'F803', descripcion: 'AFASIA ADQUIRIDA CON EPILEPSIA (LANDAU-KLEFFNER)'},
{ codigo: 'F808', descripcion: 'OTROS TRASTORNOS DEL DESARROLLO DEL HABLA Y DEL LENGUAJE'},
{ codigo: 'F809', descripcion: 'TRASTORNO DEL DESARROLLO DEL HABLA Y DEL LENGUAJE NO ESPECIFICADO'},
{ codigo: 'F81', descripcion: 'TRASTORNOS ESPECIFICOS DEL DESARROLLO DE LAS HABILIDADES ESCOLARES'},
{ codigo: 'F810', descripcion: 'TRASTORNO ESPECIFICO DE LA LECTURA'},
{ codigo: 'F811', descripcion: 'TRASTORNO ESPECIFICO DEL DELETREO (ORTOGRAFIA)'},
{ codigo: 'F812', descripcion: 'TRASTORNO ESPECIFICO DE LAS HABILIDADES ARITMETICAS'},
{ codigo: 'F813', descripcion: 'TRASTORNO MIXTO DE LAS HABILIDADES ESCOLARES'},
{ codigo: 'F818', descripcion: 'OTROS TRASTORNOS DEL DESARROLLO DE LAS HABILIDADES ESCOLARES'},
{ codigo: 'F819', descripcion: 'TRASTORNO DEL DESARROLLO DE LAS HABILIDADES ESCOLARES, NO ESPECIFICADO'},
{ codigo: 'F82', descripcion: 'TRASTORNO ESPECIFICO DEL DESARROLLO DE LA FUNCION MOTRIZ'},
{ codigo: 'F83', descripcion: 'TRASTORNOS ESPECIFICOS MIXTOS DEL DESARROLLO'},
{ codigo: 'F84', descripcion: 'TRASTORNOS GENERALIZADOS DEL DESARROLLO'},
{ codigo: 'F840', descripcion: 'AUTISMO EN LA NI—EZ'},
{ codigo: 'F841', descripcion: 'AUTISMO ATIPICO'},
{ codigo: 'F842', descripcion: 'SINDROME DE RETT'},
{ codigo: 'F843', descripcion: 'OTRO TRASTORNO DESINTEGRATIVO DE LA NI—EZ'},
{ codigo: 'F844', descripcion: 'TRASTORNO HIPERACTIVO ASOCIADO A RETRASO MENTAL Y MOVIMIENTOS ESTEREOTIPADO'},
{ codigo: 'F845', descripcion: 'SINDROME DE ASPERGER'},
{ codigo: 'F848', descripcion: 'OTROS TRASTORNOS GENERALIZADOS DEL DESARROLLO'},
{ codigo: 'F849', descripcion: 'TRASTORNO GENERALIZADO DEL DESARROLLO NO ESPECIFICADO'},
{ codigo: 'F88', descripcion: 'OTROS TRASTORNOS DEL DESARROLLO PSICOLOGICO'},
{ codigo: 'F89', descripcion: 'TRASTORNO DEL DESARROLLO PSICOLOGICO, NO ESPECIFICADO'},
{ codigo: 'F90', descripcion: 'TRASTORNOS HIPERCINETICOS'},
{ codigo: 'F900', descripcion: 'PERTURBACION DE LA ACTIVIDAD Y DE LA ATENCION'},
{ codigo: 'F901', descripcion: 'TRASTORNO HIPERCINETICO DE LA CONDUCTA'},
{ codigo: 'F908', descripcion: 'OTROS TRASTORNOS HIPERCINETICOS'},
{ codigo: 'F909', descripcion: 'TRASTORNO HIPERCINETICO, NO ESPECIFICADO'},
{ codigo: 'F91', descripcion: 'TRASTORNOS DE LA CONDUCTA'},
{ codigo: 'F910', descripcion: 'TRASTORNO DE LA CONDUCTA LIMITADO AL CONTEXTO FAMILIAR'},
{ codigo: 'F911', descripcion: 'TRASTORNO DE LA CONDUCTA INSOCIABLE'},
{ codigo: 'F912', descripcion: 'TRASTORNO DE LA CONDUCTA SOCIABLE'},
{ codigo: 'F913', descripcion: 'TRASTORNO OPOSITOR DESAFIANTE'},
{ codigo: 'F918', descripcion: 'OTROS TRASTORNOS DE LA CONDUCTA'},
{ codigo: 'F919', descripcion: 'TRASTORNO DE LA CONDUCTA, NO ESPECIFICADO'},
{ codigo: 'F92', descripcion: 'TRASTORNOS MIXTOS DE LA CONDUCTA Y DE LAS EMOCIONES'},
{ codigo: 'F920', descripcion: 'TRASTORNO DEPRESIVO DE LA CONDUCTA'},
{ codigo: 'F928', descripcion: 'OTROS TRASTORNOS MIXTOS DE LA CONDUCTA Y DE LAS EMOCIONES'},
{ codigo: 'F929', descripcion: 'TRASTORNO MIXTO DE LA CONDUCTA Y DE LAS EMOCIONES, NO ESPECIFICADO'},
{ codigo: 'F93', descripcion: 'TRASTORNOS EMOCIONALES DE COMIENZO ESPECIFICO EN LA NI—EZ'},
{ codigo: 'F930', descripcion: 'TRASTORNO DE ANSIEDAD DE SEPARACION EN LA NI—EZ'},
{ codigo: 'F931', descripcion: 'TRASTORNO DE ANSIEDAD FOBICA EN LA NI—EZ'},
{ codigo: 'F932', descripcion: 'TRASTORNO DE ANSIEDAD SOCIAL EN LA NI—EZ'},
{ codigo: 'F933', descripcion: 'TRASTORNO DE RIVALIDAD ENTRE HERMANOS'},
{ codigo: 'F938', descripcion: 'OTROS TRASTORNOS EMOCIONALES EN LA NI—EZ'},
{ codigo: 'F939', descripcion: 'TRASTORNO EMOCIONAL EN LA NI—EZ, NO ESPECIFICADO'},
{ codigo: 'F94', descripcion: 'TRASTORNOS DEL COMPORTAMIENTO SOCIAL DE COMIENZO ESPECIFICO EN LA NI—EZ Y EN LA ADOLESCENCIA'},
{ codigo: 'F940', descripcion: 'MUTISMO ELECTIVO'},
{ codigo: 'F941', descripcion: 'TRASTORNO DE VINCULACION REACTIVA EN LA NI—EZ'},
{ codigo: 'F942', descripcion: 'TRASTORNO DE VINCULACION DESINHIBIDA EN LA NI—EZ'},
{ codigo: 'F948', descripcion: 'OTROS TRASTORNOS DEL COMPORTAMIENTO SOCIAL EN LA NI—EZ'},
{ codigo: 'F949', descripcion: 'TRASTORNO DEL COMPORTAMIENTO SOCIAL EN LA NI—EZ, NO ESPECIFICADO'},
{ codigo: 'F95', descripcion: 'TRASTORNOS POR TICS'},
{ codigo: 'F950', descripcion: 'TRASTORNO POR TIC TRANSITORIO'},
{ codigo: 'F951', descripcion: 'TRASTORNO POR TIC MOTOR O VOCAL CRONICO'},
{ codigo: 'F952', descripcion: 'TRAST POR TICS MOTORES Y VOCALES MULTIPLES COMBINADOS (DE LA TOURETTE)'},
{ codigo: 'F958', descripcion: 'OTROS TRASTORNOS POR TICS'},
{ codigo: 'F959', descripcion: 'TRASTORNO POR TIC, NO ESPECIFICADO'},
{ codigo: 'F98', descripcion: 'OTROS TRASTORNOS EMOCIONALES Y DEL COMPORTAMIENTO QUE APARECEN EN NI—EZ Y EN LA ADOLESCENCIA'},
{ codigo: 'F980', descripcion: 'ENURESIS NO ORGANICA'},
{ codigo: 'F981', descripcion: 'ENCOPRESIS NO ORGANICA'},
{ codigo: 'F982', descripcion: 'TRASTORNO DE LA INGESTION ALIMENTARIA EN LA INFANCIA Y LA NI—EZ'},
{ codigo: 'F983', descripcion: 'PICA EN LA INFANCIA Y LA NI—EZ'},
{ codigo: 'F984', descripcion: 'TRASTORNOS DE LOS MOVIMIENTOS ESTEREOTIPADOS'},
{ codigo: 'F985', descripcion: 'TARTAMUDEZ (ESPASMOFEMIA)'},
{ codigo: 'F986', descripcion: 'FARFULLEO'},
{ codigo: 'F988', descripcion: 'OTROS TRASTORNOS EMOCIONALES Y DE COMPORTAMIENTO QUE APARECEN HABITUALMENTE EN LA NI—EZ Y LA ADOLESCENCIA'},
{ codigo: 'F989', descripcion: 'TRASTORNOS NO ESPECIFICADOS, EMOCIONALES Y DEL COMPORTAMIENTO QUE APARECEN HABITUALMENTE EN LA NI—EZ Y LA ADOLESCENCIA'},
{ codigo: 'F99', descripcion: 'TRASTORNO MENTAL, NO ESPECIFICADO'},
{ codigo: 'G00', descripcion: 'MENINGITIS BACTERIANA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'G000', descripcion: 'MENINGITIS POR HEMOFILOS'},
{ codigo: 'G001', descripcion: 'MENINGITIS NEUMOCOCICA'},
{ codigo: 'G002', descripcion: 'MENINGITIS ESTREPTOCOCICA'},
{ codigo: 'G003', descripcion: 'MENINGITIS ESTAFILOCOCICA'},
{ codigo: 'G008', descripcion: 'OTRAS MENINGITIS BACTERIANAS'},
{ codigo: 'G009', descripcion: 'MENINGITIS BACTERIANA, NO ESPECIFICADA'},
{ codigo: 'G01', descripcion: 'MENINGITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G02', descripcion: 'MENINGITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G020', descripcion: 'MENINGITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G021', descripcion: 'MENINGITIS EN MICOSIS'},
{ codigo: 'G028', descripcion: 'MENINGITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G03', descripcion: 'MENINGITIS DEBIDA A OTRAS CAUSAS Y A LAS NO ESPECIFICADAS'},
{ codigo: 'G030', descripcion: 'MENINGITIS APIOGENA'},
{ codigo: 'G031', descripcion: 'MENINGITIS CRONICA'},
{ codigo: 'G032', descripcion: 'MENINGITIS RECURRENTE BENIGNA (MOLLARET)'},
{ codigo: 'G038', descripcion: 'MENINGITIS DEBIDAS A OTRAS CAUSAS ESPECIFICADAS'},
{ codigo: 'G039', descripcion: 'MENINGITIS, NO ESPECIFICADA'},
{ codigo: 'G04', descripcion: 'ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS'},
{ codigo: 'G040', descripcion: 'ENCEFALITIS AGUDA DISEMINADA'},
{ codigo: 'G041', descripcion: 'PARAPLEJIA ESPASTICA TROPICAL'},
{ codigo: 'G042', descripcion: 'MENINGOENCEFALITIS Y MENINGOMIELITIS BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G048', descripcion: 'OTRAS ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS'},
{ codigo: 'G049', descripcion: 'ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS, NO ESPECIFICADAS'},
{ codigo: 'G05', descripcion: 'ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G050', descripcion: 'ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G051', descripcion: 'ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G052', descripcion: 'ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN OTRAS ENFERMEDADES INFECCIOSAS PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G058', descripcion: 'ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G06', descripcion: 'ABSCESO Y GRANULOMA INTRACRANEAL E INTRARRAQUIDEO'},
{ codigo: 'G060', descripcion: 'ABSCESO Y GRANULOMA INTRACRANEAL'},
{ codigo: 'G061', descripcion: 'ABSCESO Y GRANULOMA INTRARRAQUIDEO'},
{ codigo: 'G062', descripcion: 'ABSCESO EXTRADURAL Y SUBDURAL, NO ESPECIFICADO'},
{ codigo: 'G07', descripcion: 'ABSCESO Y GRANULOMA INTRACRANEAL E INTRARRAQUIDEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G08', descripcion: 'FLEBITIS Y TROMBOFLEBITIS INTRACRANEAL E INTRARRAQUIDEA'},
{ codigo: 'G09', descripcion: 'SECUELAS DE ENFERMEDADES INFLAMATORIAS DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'G10', descripcion: 'ENFERMEDAD DE HUNTINGTON'},
{ codigo: 'G11', descripcion: 'ATAXIA HEREDITARIA'},
{ codigo: 'G110', descripcion: 'ATAXIA CONGENITA NO PROGRESIVA'},
{ codigo: 'G111', descripcion: 'ATAXIA CEREBELOSA DE INICIACION TEMPRANA'},
{ codigo: 'G112', descripcion: 'ATAXIA CEREBELOSA DE INICIACION TARDIA'},
{ codigo: 'G113', descripcion: 'ATAXIA CEREBELOSA CON REPARACION DEFECTUOSA DEL ADN'},
{ codigo: 'G114', descripcion: 'PARAPLEJIA ESPASTICA HEREDITARIA'},
{ codigo: 'G118', descripcion: 'OTRAS ATAXIAS HEREDITARIAS'},
{ codigo: 'G119', descripcion: 'ATAXIA HEREDITARIA, NO ESPECIFICADA'},
{ codigo: 'G12', descripcion: 'ATROFIA MUSCULAR ESPINAL Y SINDROMES AFINES'},
{ codigo: 'G120', descripcion: 'ATROFIA MUSCULAR ESPINAL INFANTIL, TIPO I (WERDNIG-HOFFMAN)'},
{ codigo: 'G121', descripcion: 'OTRAS ATROFIAS MUSCULARES ESPINALES HEREDITARIAS'},
{ codigo: 'G122', descripcion: 'ENFERMEDADES DE LAS NEURONAS MOTORAS'},
{ codigo: 'G128', descripcion: 'OTRAS ATROFIAS MUSCULARES ESPINALES Y SINDROMES AFINES'},
{ codigo: 'G129', descripcion: 'ATROFIA MUSCULAR ESPINAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'G13', descripcion: 'ATROFIAS SISTEMICAS AFECTAN PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G130', descripcion: 'NEUROMIOPATIA Y NEUROPATIA PARANEOPLASICA'},
{ codigo: 'G131', descripcion: 'OTRAS ATROFIAS SISTEMICAS QUE AFECTAN EL SISTEMA NERVIOSO CENTRAL EN ENFERMEDAD NEOPLASICA'},
{ codigo: 'G132', descripcion: 'ATROFIA SISTEMICA QUE AFECTA PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN MIXEDEMA(E00.1+, E03.-+)'},
{ codigo: 'G138', descripcion: 'ATROFIA SISTEMICA QUE AFECTA PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G20', descripcion: 'ENFERMEDAD DE PARKINSON'},
{ codigo: 'G21', descripcion: 'PARKINSONISMO SECUNDARIO'},
{ codigo: 'G210', descripcion: 'SINDROME NEUROLEPTICO MALIGNO'},
{ codigo: 'G211', descripcion: 'OTRO PARKINSONISMO SECUNDARIO INDUCIDO POR DROGAS'},
{ codigo: 'G212', descripcion: 'PARKINSONISMO SECUNDARIO DEBIDO A OTROS AGENTES EXTERNOS'},
{ codigo: 'G213', descripcion: 'PARKINSONISMO POSTENCEFALITICO'},
{ codigo: 'G218', descripcion: 'OTROS TIPOS DE PARKINSONISMO SECUNDARIO'},
{ codigo: 'G219', descripcion: 'PARKINSONISMO SECUNDARIO, NO ESPECIFICADO'},
{ codigo: 'G22', descripcion: 'PARKINSONISMO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G23', descripcion: 'OTRAS ENFERMEDADES DEGENERATIVAS DE LOS NUCLEOS DE LA BASE'},
{ codigo: 'G230', descripcion: 'ENFERMEDAD DE HALLERVORDEN-SPATZ'},
{ codigo: 'G231', descripcion: 'OFTALMOPLEJIA SUPRANUCLEAR PROGRESIVA (STEELE-RICHARDSON-OLSZEWSKI)'},
{ codigo: 'G232', descripcion: 'DEGENERACION NIGROESTRIADA'},
{ codigo: 'G238', descripcion: 'OTRAS ENFERMEDADES DEGENERATIVAS ESPECIFICAS DE LOS NUCLEOS DE LA BASE'},
{ codigo: 'G239', descripcion: 'ENFERMEDAD DEGENERATIVA DE LOS NUCLEOS DE LA BASE, NO ESPECIFICADA'},
{ codigo: 'G24', descripcion: 'DISTONIA'},
{ codigo: 'G240', descripcion: 'DISTONIA INDUCIDA POR DROGAS'},
{ codigo: 'G241', descripcion: 'DISTONIA IDIOPATICA FAMILIAR'},
{ codigo: 'G242', descripcion: 'DISTONIA IDIOPATICA NO FAMILIAR'},
{ codigo: 'G243', descripcion: 'TORTICOLIS ESPASMODICA'},
{ codigo: 'G244', descripcion: 'DISTONIA BUCOFACIAL IDIOPATICA'},
{ codigo: 'G245', descripcion: 'BLEFAROSPASMO'},
{ codigo: 'G248', descripcion: 'OTRAS DISTONIAS'},
{ codigo: 'G249', descripcion: 'DISTONIA, NO ESPECIFICADA'},
{ codigo: 'G25', descripcion: 'OTROS TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO'},
{ codigo: 'G250', descripcion: 'TEMBLOR ESENCIAL'},
{ codigo: 'G251', descripcion: 'TEMBLOR INDUCIDO POR DROGAS'},
{ codigo: 'G252', descripcion: 'OTRAS FORMAS ESPECIFICADAS DE TEMBLOR'},
{ codigo: 'G253', descripcion: 'MIOCLONIA'},
{ codigo: 'G254', descripcion: 'COREA INDUCIDA POR DROGAS'},
{ codigo: 'G255', descripcion: 'OTRAS COREAS'},
{ codigo: 'G256', descripcion: 'TICS INDUCIDOS POR DROGAS Y OTROS TICS DE ORIGEN ORGANICO'},
{ codigo: 'G258', descripcion: 'OTROS TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO'},
{ codigo: 'G259', descripcion: 'TRASTORNO EXTRAPIRAMIDAL Y DEL MOVIMIENTO, NO ESPECIFICADO'},
{ codigo: 'G26', descripcion: 'TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G30', descripcion: 'ENFERMEDAD DE ALZHEIMER'},
{ codigo: 'G300', descripcion: 'ENFERMEDAD DE ALZHEIMER DE COMIENZO TEMPRANO'},
{ codigo: 'G301', descripcion: 'ENFERMEDAD DE ALZHEIMER DE COMIENZO TARDIO'},
{ codigo: 'G308', descripcion: 'OTROS TIPOS DE ENFERMEDAD DE ALZHEIMER'},
{ codigo: 'G309', descripcion: 'ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA'},
{ codigo: 'G31', descripcion: 'OTRAS ENFERMEDADES DEGENERATIVAS DEL SISTEMA NERVIOSO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G310', descripcion: 'ATROFIA CEREBRAL CIRCUNSCRITA'},
{ codigo: 'G311', descripcion: 'DEGENERACION CEREBRAL SENIL NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'G312', descripcion: 'DEGENERACION DEL SISTEMA NERVIOSO DEBIDA AL ALCOHOL'},
{ codigo: 'G318', descripcion: 'OTRAS ENFERMEDADES DEGENERATIVAS ESPECIFICADAS DEL SISTEMA NERVIOSO'},
{ codigo: 'G319', descripcion: 'DEGENERACION DEL SISTEMA NERVIOSO, NO ESPECIFICADA'},
{ codigo: 'G32', descripcion: 'OTROS TRASTORNOS DEGENERATIVOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G320', descripcion: 'DEGENERACION COMBINADA SUBAGUDA DE LA MEDULA ESPINAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G328', descripcion: 'OTROS TRASTORNOS DEGENERATIVOS ESPECIFICOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G35', descripcion: 'ESCLEROSIS MULTIPLE'},
{ codigo: 'G36', descripcion: 'OTRAS DESMIELINIZACIONES DISEMINADAS AGUDAS'},
{ codigo: 'G360', descripcion: 'NEUROMIELITIS OPTICA (DEVIC)'},
{ codigo: 'G361', descripcion: 'LEUCOENCEFALITIS HEMORRAGICA AGUDA Y SUBAGUDA (HURST)'},
{ codigo: 'G368', descripcion: 'OTRAS DESMIELINIZACIONES AGUDAS DISEMINADAS ESPECIFICADAS'},
{ codigo: 'G369', descripcion: 'DESMIELINIZACION DISEMINADA AGUDA, SIN OTRA ESPECIFICACION'},
{ codigo: 'G37', descripcion: 'OTRAS ENFERMEDADES DESMIELINIZANTES DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'G370', descripcion: 'ESCLEROSIS DIFUSA'},
{ codigo: 'G371', descripcion: 'DESMIELINIZACION CENTRAL DEL CUERPO CALLOSO'},
{ codigo: 'G372', descripcion: 'MIELINOLISIS CENTRAL PONTINA'},
{ codigo: 'G373', descripcion: 'MIELITIS TRANSVERSA AGUDA EN ENFERMEDAD DESMIELINIZANTE DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'G374', descripcion: 'MIELITIS NECROTIZANTE SUBAGUDA'},
{ codigo: 'G375', descripcion: 'ESCLEROSIS CONCENTRICA (BALO)'},
{ codigo: 'G378', descripcion: 'OTRAS ENFERMEDADES DESMIELINIZANTES DEL SISTEMA NERVIOSO CENTRAL, ESPECIFICADAS'},
{ codigo: 'G379', descripcion: 'ENFERMEDAD DESMIELINIZANTE DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADA'},
{ codigo: 'G40', descripcion: 'EPILEPSIA'},
{ codigo: 'G400', descripcion: 'EPILEPSIA Y SINDROMES EPILEPTICOS IDIOPATICOS RELACIONADOS CON LOCALIZACACIONES (FOCALES) (PARCIALES) Y CON ATAQUES DE INICIO LOCALIZADO'},
{ codigo: 'G401', descripcion: 'EPILEPSIA Y SINDROMES EPILEPTICOS SINTOMATICOS RELACIONADOS CON LOCALIZACIONES (FOCALES) (PARCIALES) Y CON ATAQUES PARCIALES SIMPLES'},
{ codigo: 'G402', descripcion: 'EPILEPSIA Y SINDROMES EPILEPTICOS SINTOMATICOS RELACIONADOS CON LOCALIZACACIONES (FOCALES) (PARCIALES) Y CON ATAQUES PARCIALES COMPLEJOS'},
{ codigo: 'G403', descripcion: 'EPILEPSIA Y SINDROMES EPILEPTICOS IDIOPATICOS GENERALIZADOS'},
{ codigo: 'G404', descripcion: 'OTRAS EPILEPSIAS Y SINDROMES EPILEPTICOS GENERALIZADOS'},
{ codigo: 'G405', descripcion: 'SINDROMES EPILEPTICOS ESPECIALES'},
{ codigo: 'G406', descripcion: 'ATAQUES DE GRAN MAL, NO ESPECIFICADOS (CON O SIN PEQUE—O MAL)'},
{ codigo: 'G407', descripcion: 'PEQUE—O MAL, NO ESPECIFICADO (SIN ATAQUE DE GRAN MAL)'},
{ codigo: 'G408', descripcion: 'OTRAS EPILEPSIAS'},
{ codigo: 'G409', descripcion: 'EPILEPSIA, TIPO NO ESPECIFICADO'},
{ codigo: 'G41', descripcion: 'ESTADO DE MAL EPILEPTICO'},
{ codigo: 'G410', descripcion: 'ESTADO DE GRAN MAL EPILEPTICO'},
{ codigo: 'G411', descripcion: 'ESTADO DE PEQUE—O MAL EPILEPTICO'},
{ codigo: 'G412', descripcion: 'ESTADO DE MAL EPILEPTICO PARCIAL COMPLEJO'},
{ codigo: 'G418', descripcion: 'OTROS ESTADOS EPILEPTICOS'},
{ codigo: 'G419', descripcion: 'ESTADO DE MAL EPILEPTICO DE TIPO NO ESPECIFICADO'},
{ codigo: 'G43', descripcion: 'MIGRA—A'},
{ codigo: 'G430', descripcion: 'MIGRA—A SIN AURA (MIGRA—A COMUN)'},
{ codigo: 'G431', descripcion: 'MIGRA—A CON AURA (MIGRA—A CLASICA)'},
{ codigo: 'G432', descripcion: 'ESTADO MIGRA—OSO'},
{ codigo: 'G433', descripcion: 'MIGRA—A COMPLICADA'},
{ codigo: 'G438', descripcion: 'OTRAS MIGRA—AS'},
{ codigo: 'G439', descripcion: 'MIGRA—A, NO ESPECIFICADA'},
{ codigo: 'G44', descripcion: 'OTROS SINDROMES DE CEFALEA'},
{ codigo: 'G440', descripcion: 'SINDROME DE CEFALEA EN RACIMOS'},
{ codigo: 'G441', descripcion: 'CEFALEA VASCULAR, NCOP'},
{ codigo: 'G442', descripcion: 'CEFALEA DEBIDA A TENSION'},
{ codigo: 'G443', descripcion: 'CEFALEA POSTRAUMATICA CRONICA'},
{ codigo: 'G444', descripcion: 'CEFALEA INDUCIDA POR DROGAS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'G448', descripcion: 'OTROS SINDROMES DE CEFALEA ESPECIFICADOS'},
{ codigo: 'G45', descripcion: 'ATAQUES DE ISQUEMIA CEREBRAL TRANSITORIA Y SINDROMES AFINES'},
{ codigo: 'G450', descripcion: 'SINDROME ARTERIAL VERTEBRO-BASILAR'},
{ codigo: 'G451', descripcion: 'SINDROME DE LA ARTERIA CAROTIDA (HEMISFERICO)'},
{ codigo: 'G452', descripcion: 'SINDROMES ARTERIALES PRECEREBRALES BILATERALES Y MULTIPLES'},
{ codigo: 'G453', descripcion: 'AMAUROSIS FUGAZ'},
{ codigo: 'G454', descripcion: 'AMNESIA GLOBAL TRANSITORIA'},
{ codigo: 'G458', descripcion: 'OTRAS ISQUEMIAS CEREBRALES TRANSITORIAS Y SINDROMES AFINES'},
{ codigo: 'G459', descripcion: 'ISQUEMIA CEREBRAL TRANSITORIA, SIN OTRA ESPECIFICACION'},
{ codigo: 'G46', descripcion: 'SINDROMES VASCULARES ENCEFALICOS EN ENF CEREBROVASCULARES (I60- 167+)'},
{ codigo: 'G460', descripcion: 'SINDROME DE LA ARTERIA CEREBRAL MEDIA (I66.0+)'},
{ codigo: 'G461', descripcion: 'SINDROME DE LA ARTERIA CEREBRAL ANTERIOR (I66.1+)'},
{ codigo: 'G462', descripcion: 'SINDROME DE LA ARTERIA CEREBRAL POSTERIOR (I66.2+)'},
{ codigo: 'G463', descripcion: 'SINDROMES APOPLETICOS DEL TALLO ENCEFALICO (I60-I67+)'},
{ codigo: 'G464', descripcion: 'SINDROME DE INFARTO CEREBELOSO (I60-I67+)'},
{ codigo: 'G465', descripcion: 'SINDROME LACUNAR MOTOR PURO (I60-I67+)'},
{ codigo: 'G466', descripcion: 'SINDROME LACUNAR SENSORIAL PURO (I60-I67+)'},
{ codigo: 'G467', descripcion: 'OTROS SINDROMES LACUNARES (I60-I67+)'},
{ codigo: 'G468', descripcion: 'OTROS SD VASCULARES ENCEFALICOS EN ENF CEREBROVASCULARES (I160-167+)'},
{ codigo: 'G47', descripcion: 'TRASTORNOS DEL SUE—O'},
{ codigo: 'G470', descripcion: 'TRASTORNOS DEL INICIO Y DEL MANTENIMIENTO DEL SUE—O (INSOMNIOS)'},
{ codigo: 'G471', descripcion: 'TRASTORNOS DE SOMNOLENCIA EXCESIVA (HIPERSOMNIOS)'},
{ codigo: 'G472', descripcion: 'TRASTORNOS DEL RITMO NICTAMERAL'},
{ codigo: 'G473', descripcion: 'APNEA DEL SUE—O'},
{ codigo: 'G474', descripcion: 'NARCOLEPSIA Y CATAPLEXIA'},
{ codigo: 'G478', descripcion: 'OTROS TRASTORNOS DEL SUE—O'},
{ codigo: 'G479', descripcion: 'TRASTORNO DEL SUE—O, NO ESPECIFICADO'},
{ codigo: 'G50', descripcion: 'TRASTORNOS DEL NERVIO TRIGEMINO'},
{ codigo: 'G500', descripcion: 'NEURALGIA DEL TRIGEMINO'},
{ codigo: 'G501', descripcion: 'DOLOR FACIAL ATIPICO'},
{ codigo: 'G508', descripcion: 'OTROS TRASTORNOS DEL TRIGEMINO'},
{ codigo: 'G509', descripcion: 'TRASTORNO DEL TRIGEMINO, NO ESPECIFICADO'},
{ codigo: 'G51', descripcion: 'TRASTORNOS DEL NERVIO FACIAL'},
{ codigo: 'G510', descripcion: 'PARALISIS DE BELL'},
{ codigo: 'G511', descripcion: 'GANGLIONITIS GENICULADA'},
{ codigo: 'G512', descripcion: 'SINDROME DE MELKERSSON'},
{ codigo: 'G513', descripcion: 'ESPASMO HEMIFACIAL CLONICO'},
{ codigo: 'G514', descripcion: 'MIOQUIMIA FACIAL'},
{ codigo: 'G518', descripcion: 'OTROS TRASTORNOS DEL NERVIO FACIAL'},
{ codigo: 'G519', descripcion: 'TRASTORNO DEL NERVIO FACIAL, NO ESPECIFICADO'},
{ codigo: 'G52', descripcion: 'TRASTORNOS DE OTROS NERVIOS CRANEALES'},
{ codigo: 'G520', descripcion: 'TRASTORNOS DEL NERVIO OLFATORIO'},
{ codigo: 'G521', descripcion: 'TRASTORNOS DEL NERVIO GLOSOFARINGEO'},
{ codigo: 'G522', descripcion: 'TRASTORNOS DEL NERVIO VAGO'},
{ codigo: 'G523', descripcion: 'TRASTORNOS DEL NERVIO HIPOGLOSO'},
{ codigo: 'G527', descripcion: 'TRASTORNOS DE MULTIPLES NERVIOS CRANEALES'},
{ codigo: 'G528', descripcion: 'TRASTORNOS DE OTROS NERVIOS CRANEALES ESPECIFICADOS'},
{ codigo: 'G529', descripcion: 'TRASTORNO DE NERVIO CRANEAL, NO ESPECIFICADO'},
{ codigo: 'G53', descripcion: 'TRASTORNOS DE LOS NERVIOS CRANEALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G530', descripcion: 'NEURALGIA POSTHERPES ZOSTER (B02.2+)'},
{ codigo: 'G531', descripcion: 'PARALISIS MULTIPLE DE LOS NERVIOS CRANEALES EN ENFERMEDADES INFECCCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE (A00-B99+)'},
{ codigo: 'G532', descripcion: 'PARALISIS MULTIPLE DE NERVIOS CRANEALES, EN LA SARCOIDOSIS (D86.8+)'},
{ codigo: 'G533', descripcion: 'PARALISIS MULTIPLE DE NERVIOS CRANEALES, EN ENFERMEDADES NEOPLASICAS (C00-D48+)'},
{ codigo: 'G538', descripcion: 'OTROS TRASTORNOS DE LOS NERVIOS CRANEALES EN OTRAS ENFERMEDADES CLAASIFICADAS EN OTRA PARTE'},
{ codigo: 'G54', descripcion: 'TRASTORNOS DE LAS RAICES Y DE LOS PLEXOS NERVIOSOS'},
{ codigo: 'G540', descripcion: 'TRASTORNOS DEL PLEXO BRAQUIAL'},
{ codigo: 'G541', descripcion: 'TRASTORNOS DEL PLEXO LUMBOSACRO'},
{ codigo: 'G542', descripcion: 'TRASTORNOS DE LA RAIZ CERVICAL, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'G543', descripcion: 'TRASTORNOS DE LA RAIZ TORACICA, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'G544', descripcion: 'TRASTORNOS DE LA RAIZ LUMBOSACRA, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'G545', descripcion: 'AMIOTROFIA NEURALGICA'},
{ codigo: 'G546', descripcion: 'SINDROME DEL MIEMBRO FANTASMA CON DOLOR'},
{ codigo: 'G547', descripcion: 'SINDROME DEL MIEMBRO FANTASMA SIN DOLOR'},
{ codigo: 'G548', descripcion: 'OTROS TRASTORNOS DE LAS RAICES Y PLEXOS NERVIOSOS'},
{ codigo: 'G549', descripcion: 'TRASTORNO DE LA RAIZ Y PLEXOS NERVIOSOS, NO ESPECIFICADO'},
{ codigo: 'G55', descripcion: 'COMPRESIONES DE LAS RAICES Y DE PLEXOS NERVIOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G550', descripcion: 'COMPRESIONES DE RAICES Y PLEXOS NERVIOSOS EN ENFERMEDADES NEOPLASICAS (C00-D48+'},
{ codigo: 'G551', descripcion: 'COMPRESIONES DE RAICES Y PLEXOS NERVIOSOS EN TRASTORNOS DE LOS DISCOS INTERVERTEBRALES (M50-M51+)'},
{ codigo: 'G552', descripcion: 'COMPRESIONES DE RAICES Y PLEXOS NERVIOSOS EN LA ESPONDILOSIS (M47.-+)'},
{ codigo: 'G553', descripcion: 'COMPRESIONES DE RAICES Y PLEXOS NERVIOSOS EN OTRAS DORSOPATIAS(M45M46+M48+M5M46+'},
{ codigo: 'G558', descripcion: 'COMPRESIONES DE RAICES Y PLEXOS NERVIOSOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G56', descripcion: 'MONONEUROPATIAS DEL MIEMBRO SUPERIOR'},
{ codigo: 'G560', descripcion: 'SINDROME DEL TUNEL CARPIANO'},
{ codigo: 'G561', descripcion: 'OTRAS LESIONES DEL NERVIO MEDIANO'},
{ codigo: 'G562', descripcion: 'LESION DEL NERVIO CUBITAL'},
{ codigo: 'G563', descripcion: 'LESION DEL NERVIO RADIAL'},
{ codigo: 'G564', descripcion: 'CAUSALGIA'},
{ codigo: 'G568', descripcion: 'OTRAS MONONEUROPATIAS DEL MIEMBRO SUPERIOR'},
{ codigo: 'G569', descripcion: 'MONONEUROPATIA DEL MIEMBRO SUPERIOR, SIN OTRA ESPECIFICACION'},
{ codigo: 'G57', descripcion: 'MONONEUROPATIAS DEL MIEMBRO INFERIOR'},
{ codigo: 'G570', descripcion: 'LESION DEL NERVIO CIATICO'},
{ codigo: 'G571', descripcion: 'MERALGIA PARESTESICA'},
{ codigo: 'G572', descripcion: 'LESION DEL NERVIO CRURAL'},
{ codigo: 'G573', descripcion: 'LESION DEL NERVIO CIATICO POPLITEO EXTERNO'},
{ codigo: 'G574', descripcion: 'LESION DEL NERVIO CIATICO POPLITEO INTERNO'},
{ codigo: 'G575', descripcion: 'SINDROME DEL TUNEL CALCANEO'},
{ codigo: 'G576', descripcion: 'LESION DEL NERVIO PLANTAR'},
{ codigo: 'G578', descripcion: 'OTRAS MONONEUROPATIAS DEL MIEMBRO INFERIOR'},
{ codigo: 'G579', descripcion: 'MONONEUROPATIA DEL MIEMBRO INFERIOR, SIN OTRA ESPECIFICACION'},
{ codigo: 'G58', descripcion: 'OTRAS MONONEUROPATIAS'},
{ codigo: 'G580', descripcion: 'NEUROPATIA INTERCOSTAL'},
{ codigo: 'G587', descripcion: 'MONONEURITIS MULTIPLE'},
{ codigo: 'G588', descripcion: 'OTRAS MONONEUROPATIAS ESPECIFICADAS'},
{ codigo: 'G589', descripcion: 'MONONEUROPATIA, NO ESPECIFICADA'},
{ codigo: 'G59', descripcion: 'MONONEUROPATIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G590', descripcion: 'MONONEUROPATIA DIABETICA (E10-E14+ CON CUARTO CARACTER COMUN .4) *'},
{ codigo: 'G598', descripcion: 'OTRAS MONONEUROPATIAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE *'},
{ codigo: 'G60', descripcion: 'NEUROPATIA HEREDITARIA E IDIOPATICA'},
{ codigo: 'G600', descripcion: 'NEUROPATIA HEREDITARIA MOTORA Y SENSORIAL'},
{ codigo: 'G601', descripcion: 'ENFERMEDAD DE REFSUM'},
{ codigo: 'G602', descripcion: 'NEUROPATIA ASOCIADA CON ATAXIA HEREDITARIA'},
{ codigo: 'G603', descripcion: 'NEUROPATIA PROGRESIVA IDIOPATICA'},
{ codigo: 'G608', descripcion: 'OTRAS NEUROPATIAS HEREDITARIAS E IDIOPATICAS'},
{ codigo: 'G609', descripcion: 'NEUROPATIA HEREDITARIA E IDIOPATICA, SIN OTRA ESPECIFICACION'},
{ codigo: 'G61', descripcion: 'POLINEUROPATIA INFLAMATORIA'},
{ codigo: 'G610', descripcion: 'SINDROME DE GUILLAIN-BARRE'},
{ codigo: 'G611', descripcion: 'NEUROPATIA AL SUERO'},
{ codigo: 'G618', descripcion: 'OTRAS POLINEUROPATIAS INFLAMATORIAS'},
{ codigo: 'G619', descripcion: 'POLINEUROPATIA INFLAMATORIA, NO ESPECIFICADA'},
{ codigo: 'G62', descripcion: 'OTRAS POLINEUROPATIAS'},
{ codigo: 'G620', descripcion: 'POLINEUROPATIA INDUCIDA POR DROGAS'},
{ codigo: 'G621', descripcion: 'POLINEUROPATIA ALCOHOLICA'},
{ codigo: 'G622', descripcion: 'POLINEUROPATIA DEBIDA A OTRO AGENTE TOXICO'},
{ codigo: 'G628', descripcion: 'OTRAS POLINEUROPATIAS ESPECIFICADAS'},
{ codigo: 'G629', descripcion: 'POLINEUROPATIA, NO ESPECIFICADA'},
{ codigo: 'G63', descripcion: 'POLINEUROPATIAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G630', descripcion: 'POLINEUROPATIA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G631', descripcion: 'POLINEUROPATIA EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'G632', descripcion: 'POLINEUROPATIA DIABETICA (E10-E14+ CON CUARTO CARACTER COMUN .4)'},
{ codigo: 'G633', descripcion: 'POLINEUROPATIA EN OTRAS ENFERMEDADES ENDOCRINAS Y METABOLICAS (E00E07E15E16E20E34'},
{ codigo: 'G634', descripcion: 'POLINEUROPATIA EN DEFICIENCIA NUTRICIONAL (E40-E64+)'},
{ codigo: 'G635', descripcion: 'POLINEUROPATIA EN TRASTORNOS DEL TEJIDO CONECTIVO SISTEMICO (M30-M35+)'},
{ codigo: 'G636', descripcion: 'POLINEUROPATIA EN OTROS TRASTORNOS OSTEOMUSCULARES(M00-M25+, M40-M96+)'},
{ codigo: 'G638', descripcion: 'POLINEUROPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G64', descripcion: 'OTROS TRASTORNOS DEL SISTEMA NERVIOSO PERIFERICO'},
{ codigo: 'G70', descripcion: 'MIASTENIA GRAVIS Y OTROS TRASTORNOS NEUROMUSCULARES'},
{ codigo: 'G700', descripcion: 'MIASTENIA GRAVIS'},
{ codigo: 'G701', descripcion: 'TRASTORNOS TOXICOS NEUROMUSCULARES'},
{ codigo: 'G702', descripcion: 'MIASTENIA CONGENITA O DEL DESARROLLO'},
{ codigo: 'G708', descripcion: 'OTROS TRASTORNOS NEUROMUSCULARES ESPECIFICADOS'},
{ codigo: 'G709', descripcion: 'TRASTORNO NEUROMUSCULAR, NO ESPECIFICADO'},
{ codigo: 'G71', descripcion: 'TRASTORNOS MUSCULARES PRIMARIOS'},
{ codigo: 'G710', descripcion: 'DISTROFIA MUSCULAR'},
{ codigo: 'G711', descripcion: 'TRASTORNOS MIOTONICOS'},
{ codigo: 'G712', descripcion: 'MIOPATIAS CONGENITAS'},
{ codigo: 'G713', descripcion: 'MIOPATIA MITOCONDRICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'G718', descripcion: 'OTROS TRASTORNOS PRIMARIOS DE LOS MUSCULOS'},
{ codigo: 'G719', descripcion: 'TRASTORNO PRIMARIO DEL MUSCULO, TIPO NO ESPECIFICADO'},
{ codigo: 'G72', descripcion: 'OTRAS MIOPATIAS'},
{ codigo: 'G720', descripcion: 'MIOPATIA INDUCIDA POR DROGAS'},
{ codigo: 'G721', descripcion: 'MIOPATIA ALCOHOLICA'},
{ codigo: 'G722', descripcion: 'MIOPATIA DEBIDA A OTROS AGENTES TOXICOS'},
{ codigo: 'G723', descripcion: 'PARALISIS PERIODICA'},
{ codigo: 'G724', descripcion: 'MIOPATIA INFLAMATORIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'G728', descripcion: 'OTRAS MIOPATIAS ESPECIFICADAS'},
{ codigo: 'G729', descripcion: 'MIOPATIA, NO ESPECIFICADA'},
{ codigo: 'G73', descripcion: 'TRASTORNOS DEL MUSCULO Y DE LA UNION NEUROMUSCULAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G730', descripcion: 'SINDROMES MIASTENICOS EN ENFERMEDADES ENDOCRINAS'},
{ codigo: 'G731', descripcion: 'SINDROME DE EATON-LAMBERT (C80+)'},
{ codigo: 'G732', descripcion: 'OTROS SINDROMES MIASTENICOS EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'G733', descripcion: 'SINDROMES MIASTENICOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G734', descripcion: 'MIOPATIA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G735', descripcion: 'MIOPATIA EN ENFERMEDADES ENDOCRINAS'},
{ codigo: 'G736', descripcion: 'MIOPATIA EN ENFERMEDADES METABOLICAS'},
{ codigo: 'G737', descripcion: 'MIOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G80', descripcion: 'PARALISIS CEREBRAL INFANTIL'},
{ codigo: 'G800', descripcion: 'PARALISIS CEREBRAL ESPASTICA'},
{ codigo: 'G801', descripcion: 'DIPLEJIA ESPASTICA'},
{ codigo: 'G802', descripcion: 'HEMIPLEJIA INFANTIL'},
{ codigo: 'G803', descripcion: 'PARALISIS CEREBRAL DISCINETICA'},
{ codigo: 'G804', descripcion: 'PARALISIS CEREBRAL ATAXICA'},
{ codigo: 'G808', descripcion: 'OTROS TIPOS DE PARALISIS CEREBRAL INFANTIL'},
{ codigo: 'G809', descripcion: 'PARALISIS CEREBRAL INFANTIL, SIN OTRA ESPECIFICACION'},
{ codigo: 'G81', descripcion: 'HEMIPLEJIA'},
{ codigo: 'G810', descripcion: 'HEMIPLEJIA FLACIDA'},
{ codigo: 'G811', descripcion: 'HEMIPLEJIA ESPASTICA'},
{ codigo: 'G819', descripcion: 'HEMIPLEJIA, NO ESPECIFICADA'},
{ codigo: 'G82', descripcion: 'PARAPLEJIA Y CUADRIPLEJIA'},
{ codigo: 'G820', descripcion: 'PARAPLEJIA FLACIDA'},
{ codigo: 'G821', descripcion: 'PARAPLEJIA ESPASTICA'},
{ codigo: 'G822', descripcion: 'PARAPLEJIA, NO ESPECIFICADA'},
{ codigo: 'G823', descripcion: 'CUADRIPLEJIA FLACIDA'},
{ codigo: 'G824', descripcion: 'CUADRIPLEJIA ESPASTICA'},
{ codigo: 'G825', descripcion: 'CUADRIPLEJIA, NO ESPECIFICADA'},
{ codigo: 'G83', descripcion: 'OTROS SINDROMES PARALITICOS'},
{ codigo: 'G830', descripcion: 'DIPLEJIA DE LOS MIEMBROS SUPERIORES'},
{ codigo: 'G831', descripcion: 'MONOPLEJIA DE MIEMBRO INFERIOR'},
{ codigo: 'G832', descripcion: 'MONOPLEJIA DE MIEMBRO SUPERIOR'},
{ codigo: 'G833', descripcion: 'MONOPLEJIA, NO ESPECIFICADA'},
{ codigo: 'G834', descripcion: 'SINDROME DE LA COLA DE CABALLO'},
{ codigo: 'G838', descripcion: 'OTROS SINDROMES PARALITICOS ESPECIFICADOS'},
{ codigo: 'G839', descripcion: 'SINDROME PARALITICO, NO ESPECIFICADO'},
{ codigo: 'G90', descripcion: 'TRASTORNOS DEL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'G900', descripcion: 'NEUROPATIA AUTONOMA PERIFERICA IDIOPATICA'},
{ codigo: 'G901', descripcion: 'DISAUTONOMIA FAMILIAR( SINDROME DE RILEY-DAY)'},
{ codigo: 'G902', descripcion: 'SINDROME DE HORNER'},
{ codigo: 'G903', descripcion: 'DEGENERACION DE SISTEMAS MULTIPLES'},
{ codigo: 'G908', descripcion: 'OTROS TRASTORNOS DEL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'G909', descripcion: 'TRASTORNO DEL SISTEMA NERVIOSO AUTONOMO, NO ESPECIFICADO'},
{ codigo: 'G91', descripcion: 'HIDROCEFALO'},
{ codigo: 'G910', descripcion: 'HIDROCEFALO COMUNICANTE'},
{ codigo: 'G911', descripcion: 'HIDROCEFALO OBSTRUCTIVO'},
{ codigo: 'G912', descripcion: 'HIDROCEFALO DE PRESION NORMAL'},
{ codigo: 'G913', descripcion: 'HIDROCEFALO POSTRAUMATICO, SIN OTRA ESPECIFICACION'},
{ codigo: 'G918', descripcion: 'OTROS TIPOS DE HIDROCEFALO'},
{ codigo: 'G919', descripcion: 'HIDROCEFALO, NO ESPECIFICADO'},
{ codigo: 'G92', descripcion: 'ENCEFALOPATIA TOXICA'},
{ codigo: 'G93', descripcion: 'OTROS TRASTORNOS DEL ENCEFALO'},
{ codigo: 'G930', descripcion: 'QUISTE CEREBRAL'},
{ codigo: 'G931', descripcion: 'LESION CEREBRAL ANOXICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'G932', descripcion: 'HIPERTENSION INTRACRANEAL BENIGNA'},
{ codigo: 'G933', descripcion: 'SINDROME DE FATIGA POSTVIRAL'},
{ codigo: 'G934', descripcion: 'ENCEFALOPATIA NO ESPECIFICADA'},
{ codigo: 'G935', descripcion: 'COMPRESION DEL ENCEFALO'},
{ codigo: 'G936', descripcion: 'EDEMA CEREBRAL'},
{ codigo: 'G937', descripcion: 'SINDROME DE REYE'},
{ codigo: 'G938', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL ENCEFALO'},
{ codigo: 'G939', descripcion: 'TRASTORNO DEL ENCEFALO, NO ESPECIFICADO'},
{ codigo: 'G94', descripcion: 'OTROS TRASTORNOS DEL ENCEFALO EN ENFERMEDADES CLASIFICAOS EN OTRA PARTE'},
{ codigo: 'G940', descripcion: 'HIDROCEFALO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE (A00-B99+)'},
{ codigo: 'G941', descripcion: 'HIDROCEFALO EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'G942', descripcion: 'HIDROCEFALO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G948', descripcion: 'OTROS TRASTORNOS ENCEFALICOS ESPECIFICADOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G95', descripcion: 'OTRAS ENFERMEDADES DE LA MEDULA ESPINAL'},
{ codigo: 'G950', descripcion: 'SIRINGOMIELIA Y SIRINGOBULBIA'},
{ codigo: 'G951', descripcion: 'MIELOPATIAS VASCULARES'},
{ codigo: 'G952', descripcion: 'COMPRESION MEDULAR, NO ESPECIFICADA'},
{ codigo: 'G958', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LA MEDULA ESPINAL'},
{ codigo: 'G959', descripcion: 'ENFERMEDAD DE LA MEDULA ESPINAL, NO ESPECIFICADA'},
{ codigo: 'G96', descripcion: 'OTROS TRASTORNOS DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'G960', descripcion: 'PERDIDA DE LIQUIDO CEFALORRAQUIDEO'},
{ codigo: 'G961', descripcion: 'TRASTORNOS DE LAS MENINGES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'G968', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'G969', descripcion: 'TRASTORNO DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADO'},
{ codigo: 'G97', descripcion: 'TRASTORNOS DEL SISTEMA NERVIOSO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'G970', descripcion: 'PERDIDA DE LIQUIDO CEFALORRAQUIDEO POR PUNCION ESPINAL'},
{ codigo: 'G971', descripcion: 'OTRA REACCION A LA PUNCION ESPINAL Y LUMBAR'},
{ codigo: 'G972', descripcion: 'HIPOTENSION INTRACRANEAL POSTERIOR A ANASTOMOSIS VENTRICULAR'},
{ codigo: 'G978', descripcion: 'OTROS TRASTORNOS DEL SISTEMA NERVIOSO CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'G979', descripcion: 'TRASTORNOS NO ESPECIFICADOS DEL SISTEMA NERVIOSO, CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'G98', descripcion: 'OTROS TRASTORNOS DEL SISTEMA NERVIOSO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'G99', descripcion: 'OTROS TRASTORNOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G990', descripcion: 'NEUROPATIA AUTONOMICA EN ENFERMEDADES METABOLICAS Y ENDOCRINAS'},
{ codigo: 'G991', descripcion: 'OTROS TRASTORNOS DEL SISTEMA NERVIOSO AUTONOMO EN OTRAS ENFERMEDADES CLAASIFICADAS EN OTRA PARTE'},
{ codigo: 'G992', descripcion: 'MIELOPATIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'G998', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H00', descripcion: 'ORZUELO Y CALACIO'},
{ codigo: 'H000', descripcion: 'ORZUELO Y OTRAS INFLAMACIONES PROFUNDAS DEL PARPADO'},
{ codigo: 'H001', descripcion: 'CALACIO (CHALAZION)'},
{ codigo: 'H01', descripcion: 'OTRAS INFLAMACIONES DEL PARPADO'},
{ codigo: 'H010', descripcion: 'BLEFARITIS'},
{ codigo: 'H011', descripcion: 'DERMATOSIS NO INFECCIOSA DEL PARPADO'},
{ codigo: 'H018', descripcion: 'OTRAS INFLAMACIONES ESPECIFICADAS DEL PARPADO'},
{ codigo: 'H019', descripcion: 'INFLAMACION DEL PARPADO, NO ESPECIFICADA'},
{ codigo: 'H02', descripcion: 'OTROS TRASTORNOS DE LOS PARPADOS'},
{ codigo: 'H020', descripcion: 'ENTROPION Y TRIQUIASIS PALPEBRAL'},
{ codigo: 'H021', descripcion: 'ECTROPION DEL PARPADO'},
{ codigo: 'H022', descripcion: 'LAGOFTALMOS'},
{ codigo: 'H023', descripcion: 'BLEFAROCALASIA'},
{ codigo: 'H024', descripcion: 'BLEFAROPTOSIS'},
{ codigo: 'H025', descripcion: 'OTROS TRASTORNOS FUNCIONALES DEL PARPADO'},
{ codigo: 'H026', descripcion: 'XANTELASMA DEL PARPADO'},
{ codigo: 'H027', descripcion: 'OTROS TRASTORNOS DEGENERATIVOS DEL PARPADO Y DEL AREA PERIOCULAR'},
{ codigo: 'H028', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL PARPADO'},
{ codigo: 'H029', descripcion: 'TRASTORNO DEL PARPADO, NO ESPECIFICADO'},
{ codigo: 'H03', descripcion: 'TRASTORNOS DEL PARPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H030', descripcion: 'INFECCION E INFESTACION PARASITARIAS DEL PARPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H031', descripcion: 'COMPROMISO DEL PARPADO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H038', descripcion: 'COMPROMISO DEL PARPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H04', descripcion: 'TRASTORNOS DEL APARATO LAGRIMAL'},
{ codigo: 'H040', descripcion: 'DACRIOADENITIS'},
{ codigo: 'H041', descripcion: 'OTROS TRASTORNOS DE LA GLANDULA LAGRIMAL'},
{ codigo: 'H042', descripcion: 'EPIFORA'},
{ codigo: 'H043', descripcion: 'INFLAMACION AGUDA Y LA NO ESPECIFICADA DE LAS VIAS LAGRIMALES'},
{ codigo: 'H044', descripcion: 'INFLAMACION CRONICA DE LAS VIAS LAGRIMALES'},
{ codigo: 'H045', descripcion: 'ESTENOSIS E INSUFICIENCIA DE LAS VIAS LAGRIMALES'},
{ codigo: 'H046', descripcion: 'OTROS CAMBIOS DE LAS VIAS LAGRIMALES'},
{ codigo: 'H048', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL APARATO LAGRIMAL'},
{ codigo: 'H049', descripcion: 'TRASTORNO DEL APARATO LAGRIMAL, NO ESPECIFICADO'},
{ codigo: 'H05', descripcion: 'TRASTORNOS DE LA ORBITA'},
{ codigo: 'H050', descripcion: 'INFLAMACION AGUDA DE LA ORBITA'},
{ codigo: 'H051', descripcion: 'TRASTORNOS INFLAMATORIOS CRONICOS DE LA ORBITA'},
{ codigo: 'H052', descripcion: 'AFECCIONES EXOFTALMICAS'},
{ codigo: 'H053', descripcion: 'DEFORMIDAD DE LA ORBITA'},
{ codigo: 'H054', descripcion: 'ENOFTALMIA'},
{ codigo: 'H055', descripcion: 'RETENCION CUERPO EXTRA—O (ANTIGUO), CONSEC A HERIDA PENETRANTE ORBITA'},
{ codigo: 'H058', descripcion: 'OTROS TRASTORNOS DE LA ORBITA'},
{ codigo: 'H059', descripcion: 'TRASTORNO DE LA ORBITA, NO ESPECIFICADO'},
{ codigo: 'H06', descripcion: 'TRASTORNOS DEL APARATO LAGRIMAL Y DE LA ORBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PART'},
{ codigo: 'H060', descripcion: 'TRASTORNOS DEL APARATO LAGRIMAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H061', descripcion: 'INFECCION E INFESTACION PARASITARIAS DE LA ORBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H062', descripcion: 'EXOFTALMIA HIPERTIROIDEA (E05.-+)'},
{ codigo: 'H063', descripcion: 'OTROS TRASTORNOS DE LA ORBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H10', descripcion: 'CONJUNTIVITIS'},
{ codigo: 'H100', descripcion: 'CONJUNTIVITIS MUCOPURULENTA'},
{ codigo: 'H101', descripcion: 'CONJUNTIVITIS ATOPICA AGUDA'},
{ codigo: 'H102', descripcion: 'OTRAS CONJUNTIVITIS AGUDAS'},
{ codigo: 'H103', descripcion: 'CONJUNTIVITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'H104', descripcion: 'CONJUNTIVITIS CRONICA'},
{ codigo: 'H105', descripcion: 'BLEFAROCONJUNTIVITIS'},
{ codigo: 'H108', descripcion: 'OTRAS CONJUNTIVITIS'},
{ codigo: 'H109', descripcion: 'CONJUNTIVITIS, NO ESPECIFICADA'},
{ codigo: 'H11', descripcion: 'OTROS TRASTORNOS DE LA CONJUNTIVA'},
{ codigo: 'H110', descripcion: 'PTERIGION'},
{ codigo: 'H111', descripcion: 'DEGENERACIONES Y DEPOSITOS CONJUNTIVALES'},
{ codigo: 'H112', descripcion: 'CICATRICES CONJUNTIVALES'},
{ codigo: 'H113', descripcion: 'HEMORRAGIA CONJUNTIVAL'},
{ codigo: 'H114', descripcion: 'OTROS TRASTORNOS VASCULARES Y QUISTES CONJUNTIVALES'},
{ codigo: 'H118', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA CONJUNTIVA'},
{ codigo: 'H119', descripcion: 'TRASTORNO DE LA CONJUNTIVA, NO ESPECIFICADO'},
{ codigo: 'H13', descripcion: 'TRASTORNOS DE LA CONJUNTIVA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H130', descripcion: 'INFECCION FILARICA DE LA CONJUNTIVA (B74.-+) *'},
{ codigo: 'H131', descripcion: 'CONJUNTIVITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H132', descripcion: 'CONJUNTIVITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H133', descripcion: 'PENFIGOIDE OCULAR (L12.-+)'},
{ codigo: 'H138', descripcion: 'OTROS TRASTORNOS DE LA CONJUNTIVA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H15', descripcion: 'TRASTORNOS DE LA ESCLEROTICA'},
{ codigo: 'H150', descripcion: 'ESCLERITIS'},
{ codigo: 'H151', descripcion: 'EPISCLERITIS'},
{ codigo: 'H158', descripcion: 'OTROS TRASTORNOS DE LA ESCLEROTICA'},
{ codigo: 'H159', descripcion: 'TRASTORNO DE LA ESCLEROTICA, NO ESPECIFICADO'},
{ codigo: 'H16', descripcion: 'QUERATITIS'},
{ codigo: 'H160', descripcion: 'ULCERA DE LA CORNEA'},
{ codigo: 'H161', descripcion: 'OTRAS QUERATITIS SUPERFICIALES SIN CONJUNTIVITIS'},
{ codigo: 'H162', descripcion: 'QUERATOCONJUNTIVITIS'},
{ codigo: 'H163', descripcion: 'QUERATITIS INTERSTICIAL Y PROFUNDA'},
{ codigo: 'H164', descripcion: 'NEOVASCULARIZACION DE LA CORNEA'},
{ codigo: 'H168', descripcion: 'OTRAS QUERATITIS'},
{ codigo: 'H169', descripcion: 'QUERATITIS, NO ESPECIFICADA'},
{ codigo: 'H17', descripcion: 'OPACIDADES Y CICATRICES CORNEALES'},
{ codigo: 'H170', descripcion: 'LEUCOMA ADHERENTE'},
{ codigo: 'H171', descripcion: 'OTRAS OPACIDADES CENTRALES DE LA CORNEA'},
{ codigo: 'H178', descripcion: 'OTRAS OPACIDADES O CICATRICES DE LA CORNEA'},
{ codigo: 'H179', descripcion: 'CICATRIZ U OPACIDAD DE LA CORNEA, NO ESPECIFICADA'},
{ codigo: 'H18', descripcion: 'OTROS TRASTORNOS DE LA CORNEA'},
{ codigo: 'H180', descripcion: 'PIGMENTACIONES Y DEPOSITOS EN LA CORNEA'},
{ codigo: 'H181', descripcion: 'QUERATOPATIA VESICULAR'},
{ codigo: 'H182', descripcion: 'OTROS EDEMAS DE LA CORNEA'},
{ codigo: 'H183', descripcion: 'CAMBIOS EN LAS MEMBRANAS DE LA CORNEA'},
{ codigo: 'H184', descripcion: 'DEGENERACION DE LA CORNEA'},
{ codigo: 'H185', descripcion: 'DISTROFIA HEREDITARIA DE LA CORNEA'},
{ codigo: 'H186', descripcion: 'QUERATOCONO'},
{ codigo: 'H187', descripcion: 'OTRAS DEFORMIDADES DE LA CORNEA'},
{ codigo: 'H188', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA CORNEA'},
{ codigo: 'H189', descripcion: 'TRASTORNO DE LA CORNEA, NO ESPECIFICADO'},
{ codigo: 'H19', descripcion: 'TRASTORNOS DE LA ESCLEROTICA Y DE LA CORNEA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H190', descripcion: 'ESCLERITIS Y EPISCLERITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H191', descripcion: 'QUERATITIS Y QUERATOCONJUNTIVITIS POR HERPES SIMPLE (B00.5+)'},
{ codigo: 'H192', descripcion: 'QUERATITIS Y QUERATOCONJUNTIVITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS, CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H193', descripcion: 'QUERATITIS Y QUERATOCONJUNTIVITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H198', descripcion: 'OTROS TRASTORNOS DE LA ESCLEROTICA Y DE LA CORNEA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H20', descripcion: 'IRIDOCICLITIS'},
{ codigo: 'H200', descripcion: 'IRIDOCICLITIS AGUDA Y SUBAGUDA'},
{ codigo: 'H201', descripcion: 'IRIDOCICLITIS CRONICA'},
{ codigo: 'H202', descripcion: 'IRIDOCICLITIS INDUCIDA POR TRASTORNO DEL CRISTALINO'},
{ codigo: 'H208', descripcion: 'OTRAS IRIDOCICLITIS ESPECIFICADAS'},
{ codigo: 'H209', descripcion: 'IRIDOCICLITIS, NO ESPECIFICADA'},
{ codigo: 'H21', descripcion: 'OTROS TRASTORNOS DEL IRIS Y DEL CUERPO CILIAR'},
{ codigo: 'H210', descripcion: 'HIFEMA'},
{ codigo: 'H211', descripcion: 'OTROS TRASTORNOS VASCULARES DEL IRIS Y DEL CUERPO CILIAR'},
{ codigo: 'H212', descripcion: 'DEGENERACION DEL IRIS Y DEL CUERPO CILIAR'},
{ codigo: 'H213', descripcion: 'QUISTE DEL IRIS, DEL CUERPO CILIAR Y DE LA CAMARA ANTERIOR'},
{ codigo: 'H214', descripcion: 'MEMBRANAS PUPILARES'},
{ codigo: 'H215', descripcion: 'OTRAS ADHERENCIAS Y DESGARROS DEL IRIS Y DEL CUERPO CILIAR'},
{ codigo: 'H218', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL IRIS Y DEL CUERPO CILIAR'},
{ codigo: 'H219', descripcion: 'TRASTORNO DEL IRIS Y DEL CUERPO CILIAR, NO ESPECIFICADO'},
{ codigo: 'H22', descripcion: 'TRASTORNOS DEL IRIS Y DEL CUERPO CILIAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H220', descripcion: 'IRIDOCICLITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H221', descripcion: 'IRIDOCICLITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H228', descripcion: 'OTROS TRASTORNOS DEL IRIS Y DEL CUERPO CILIAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H25', descripcion: 'CATARATA SENIL'},
{ codigo: 'H250', descripcion: 'CATARATA SENIL INCIPIENTE'},
{ codigo: 'H251', descripcion: 'CATARATA SENIL NUCLEAR'},
{ codigo: 'H252', descripcion: 'CATARATA SENIL, TIPO MORGAGNIAN'},
{ codigo: 'H258', descripcion: 'OTRAS CATARATAS SENILES'},
{ codigo: 'H259', descripcion: 'CATARATA SENIL, NO ESPECIFICADA'},
{ codigo: 'H26', descripcion: 'OTRAS CATARATAS'},
{ codigo: 'H260', descripcion: 'CATARATA INFANTIL, JUVENIL Y PRESENIL'},
{ codigo: 'H261', descripcion: 'CATARATA TRAUMATICA'},
{ codigo: 'H262', descripcion: 'CATARATA COMPLICADA'},
{ codigo: 'H263', descripcion: 'CATARATA INDUCIDA POR DROGAS'},
{ codigo: 'H264', descripcion: 'CATARATA RESIDUAL'},
{ codigo: 'H268', descripcion: 'OTRAS FORMAS ESPECIFICADAS DE CATARATA'},
{ codigo: 'H269', descripcion: 'CATARATA, NO ESPECIFICADA'},
{ codigo: 'H27', descripcion: 'OTROS TRASTORNOS DEL CRISTALINO'},
{ codigo: 'H270', descripcion: 'AFAQUIA'},
{ codigo: 'H271', descripcion: 'LUXACION DEL CRISTALINO'},
{ codigo: 'H278', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL CRISTALINO'},
{ codigo: 'H279', descripcion: 'TRASTORNO DEL CRISTALINO, NO ESPECIFICADO'},
{ codigo: 'H28', descripcion: 'CATARATA Y OTROS TRASTORNOS DEL CRISTALINO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H280', descripcion: 'CATARATA DIABETICA (E10-E14+ CON CUARTO CARACTER COMUN .3)'},
{ codigo: 'H281', descripcion: 'CATARATA EN OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H282', descripcion: 'CATARATA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H288', descripcion: 'OTROS TRASTORNOS DEL CRISTALINO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H30', descripcion: 'INFLAMACION CORIORRETINIANA'},
{ codigo: 'H300', descripcion: 'CORIORRETINITIS FOCAL'},
{ codigo: 'H301', descripcion: 'CORIORRETINITIS DISEMINADA'},
{ codigo: 'H302', descripcion: 'CICLITIS POSTERIOR'},
{ codigo: 'H308', descripcion: 'OTRAS CORIORRETINITIS'},
{ codigo: 'H309', descripcion: 'CORIORRETINITIS, NO ESPECIFICADA'},
{ codigo: 'H31', descripcion: 'OTROS TRASTORNOS DE LA COROIDES'},
{ codigo: 'H310', descripcion: 'CICATRICES CORIORRETINIANAS'},
{ codigo: 'H311', descripcion: 'DEGENERACION COROIDEA'},
{ codigo: 'H312', descripcion: 'DISTROFIA COROIDEA HEREDITARIA'},
{ codigo: 'H313', descripcion: 'HEMORRAGIA Y RUPTURA DE LA COROIDES'},
{ codigo: 'H314', descripcion: 'DESPRENDIMIENTO DE LA COROIDES'},
{ codigo: 'H318', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA COROIDES'},
{ codigo: 'H319', descripcion: 'TRASTORNO DE LA COROIDES, NO ESPECIFICADO'},
{ codigo: 'H32', descripcion: 'TRASTORNOS CORIORRETINIANOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H320', descripcion: 'INFLAMACION CORIORRETINIANA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H328', descripcion: 'OTROS TRASTORNOS CORIORRETINIANOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H33', descripcion: 'DESPRENDIMIENTO Y DESGARRO DE LA RETINA'},
{ codigo: 'H330', descripcion: 'DESPRENDIMIENTO DE LA RETINA CON RUPTURA'},
{ codigo: 'H331', descripcion: 'RETINOSQUISIS Y QUISTES DE LA RETINA'},
{ codigo: 'H332', descripcion: 'DESPRENDIMIENTO SEROSO DE LA RETINA'},
{ codigo: 'H333', descripcion: 'DESGARRO DE LA RETINA SIN DESPRENDIMIENTO'},
{ codigo: 'H334', descripcion: 'DESPRENDIMIENTO DE LA RETINA POR TRACCION'},
{ codigo: 'H335', descripcion: 'OTROS DESPRENDIMIENTOS DE LA RETINA'},
{ codigo: 'H34', descripcion: 'OCLUSION VASCULAR DE LA RETINA'},
{ codigo: 'H340', descripcion: 'OCLUSION ARTERIAL TRANSITORIA DE LA RETINA'},
{ codigo: 'H341', descripcion: 'OCLUSION DE LA ARTERIA CENTRAL DE LA RETINA'},
{ codigo: 'H342', descripcion: 'OTRAS FORMAS DE OCLUSION DE LA ARTERIA DE LA RETINA'},
{ codigo: 'H348', descripcion: 'OTRAS OCLUSIONES VASCULARES RETINIANAS'},
{ codigo: 'H349', descripcion: 'OCLUSION VASCULAR RETINIANA, SIN OTRA ESPECIFICACION'},
{ codigo: 'H35', descripcion: 'OTROS TRASTORNOS DE LA RETINA'},
{ codigo: 'H350', descripcion: 'RETINOPATIAS DEL FONDO Y CAMBIOS VASCULARES RETINIANOS'},
{ codigo: 'H351', descripcion: 'RETINOPATIA DE LA PREMATURIDAD'},
{ codigo: 'H352', descripcion: 'OTRAS RETINOPATIAS PROLIFERATIVAS'},
{ codigo: 'H353', descripcion: 'DEGENERACION DE LA MACULA Y DEL POLO POSTERIOR DEL OJO'},
{ codigo: 'H354', descripcion: 'DEGENERACION PERIFERICA DE LA RETINA'},
{ codigo: 'H355', descripcion: 'DISTROFIA HEREDITARIA DE LA RETINA'},
{ codigo: 'H356', descripcion: 'HEMORRAGIA RETINIANA'},
{ codigo: 'H357', descripcion: 'SEPARACION DE LAS CAPAS DE LA RETINA'},
{ codigo: 'H358', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA RETINA'},
{ codigo: 'H359', descripcion: 'TRASTORNO DE LA RETINA, NO ESPECIFICADO'},
{ codigo: 'H36', descripcion: 'TRASTORNOS DE LA RETINA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H360', descripcion: 'RETINOPATIA DIABETICA (E10-E14+ CON CUARTO CARACTER COMUN .3)'},
{ codigo: 'H368', descripcion: 'OTROS TRASTORNOS DE LA RETINA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H40', descripcion: 'GLAUCOMA'},
{ codigo: 'H400', descripcion: 'SOSPECHA DE GLAUCOMA'},
{ codigo: 'H401', descripcion: 'GLAUCOMA PRIMARIO DE ANGULO ABIERTO'},
{ codigo: 'H402', descripcion: 'GLAUCOMA PRIMARIO DE ANGULO CERRADO'},
{ codigo: 'H403', descripcion: 'GLAUCOMA SECUNDARIO A TRAUMATISMO OCULAR'},
{ codigo: 'H404', descripcion: 'GLAUCOMA SECUNDARIO A INFLAMACION OCULAR'},
{ codigo: 'H405', descripcion: 'GLAUCOMA SECUNDARIO A OTROS TRASTORNOS DEL OJO'},
{ codigo: 'H406', descripcion: 'GLAUCOMA SECUNDARIO A DROGAS'},
{ codigo: 'H408', descripcion: 'OTROS GLAUCOMAS'},
{ codigo: 'H409', descripcion: 'GLAUCOMA, NO ESPECIFICADO'},
{ codigo: 'H42', descripcion: 'GLAUCOMA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H420', descripcion: 'GLAUCOMA EN ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS, CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H428', descripcion: 'GLAUCOMA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H43', descripcion: 'TRASTORNOS DEL CUERPO VITREO'},
{ codigo: 'H430', descripcion: 'PROLAPSO DEL VITREO'},
{ codigo: 'H431', descripcion: 'HEMORRAGIA DEL VITREO'},
{ codigo: 'H432', descripcion: 'DEPOSITOS CRISTALINOS EN EL CUERPO VITREO'},
{ codigo: 'H433', descripcion: 'OTRAS OPACIDADES VITREAS'},
{ codigo: 'H438', descripcion: 'OTROS TRASTORNOS DEL CUERPO VITREO'},
{ codigo: 'H439', descripcion: 'TRASTORNO DEL CUERPO VITREO, NO ESPECIFICADO'},
{ codigo: 'H44', descripcion: 'TRASTORNOS DEL GLOBO OCULAR'},
{ codigo: 'H440', descripcion: 'ENDOFTALMITIS PURULENTA'},
{ codigo: 'H441', descripcion: 'OTRAS ENDOFTALMITIS'},
{ codigo: 'H442', descripcion: 'MIOPIA DEGENERATIVA'},
{ codigo: 'H443', descripcion: 'OTROS TRASTORNOS DEGENERATIVOS DEL GLOBO OCULAR'},
{ codigo: 'H444', descripcion: 'HIPOTONIA OCULAR'},
{ codigo: 'H445', descripcion: 'AFECCIONES DEGENERATIVAS DEL GLOBO OCULAR'},
{ codigo: 'H446', descripcion: 'RETENCION INTRAOCULAR DE CUERPO EXTRA—O MAGNETICO (ANTIGUO)'},
{ codigo: 'H447', descripcion: 'RETENCION INTRAOCULAR DE CUERPO EXTRA—O NO MAGNETICO (ANTIGUO)'},
{ codigo: 'H448', descripcion: 'OTROS TRASTORNOS DEL GLOBO OCULAR'},
{ codigo: 'H449', descripcion: 'TRASTORNO DEL GLOBO OCULAR, NO ESPECIFICADO'},
{ codigo: 'H45', descripcion: 'TRASTORNOS DEL CUERPO VITREO Y DEL GLOBO OCULAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H450', descripcion: 'HEMORRAGIA DEL VITREO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H451', descripcion: 'ENDOFTALMITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H458', descripcion: 'OTROS TRASTORNOS DEL CUERPO VITREO Y DEL GLOBO OCULAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H46', descripcion: 'NEURITIS OPTICA'},
{ codigo: 'H47', descripcion: 'OTROS TRASTORNOS DEL NERVIO OPTICO (II PAR) Y DE LAS VIAS OPTICAS'},
{ codigo: 'H470', descripcion: 'TRASTORNOS DEL NERVIO OPTICO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'H471', descripcion: 'PAPILEDEMA, NO ESPECIFICADO'},
{ codigo: 'H472', descripcion: 'ATROFIA OPTICA'},
{ codigo: 'H473', descripcion: 'OTROS TRASTORNOS DEL DISCO OPTICO'},
{ codigo: 'H474', descripcion: 'TRASTORNOS DEL QUIASMA OPTICO'},
{ codigo: 'H475', descripcion: 'TRASTORNOS DE OTRAS VIAS OPTICAS'},
{ codigo: 'H476', descripcion: 'TRASTORNOS DE LA CORTEZA VISUAL'},
{ codigo: 'H477', descripcion: 'TRASTORNO DE LAS VIAS OPTICAS, NO ESPECIFICADO'},
{ codigo: 'H48', descripcion: 'TRASTORNO DEL NERVIO OPTICO (II PAR) Y DE LAS VIAS OPTICAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H480', descripcion: 'ATROFIA OPTICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H481', descripcion: 'NEURITIS RETROBULBAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H488', descripcion: 'OTROS TRASTORNOS DEL NERVIO OPTICO Y DE LAS VIAS OPTICAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H49', descripcion: 'ESTRABISMO PARALITICO'},
{ codigo: 'H490', descripcion: 'PARALISIS DEL NERVIO MOTOR OCULAR COMUN (III PAR)'},
{ codigo: 'H491', descripcion: 'PARALISIS DEL NERVIO PATETICO (IV PAR)'},
{ codigo: 'H492', descripcion: 'PARALISIS DEL NERVIO MOTOR OCULAR EXTERNO (VI PAR)'},
{ codigo: 'H493', descripcion: 'OFTALMOPLEJIA TOTAL (EXTERNA)'},
{ codigo: 'H494', descripcion: 'OFTALMOPLEJIA EXTERNA PROGRESIVA'},
{ codigo: 'H498', descripcion: 'OTROS ESTRABISMOS PARALITICOS'},
{ codigo: 'H499', descripcion: 'ESTRABISMO PARALITICO, NO ESPECIFICADO'},
{ codigo: 'H50', descripcion: 'OTROS ESTRABISMOS'},
{ codigo: 'H500', descripcion: 'ESTRABISMO CONCOMITANTE CONVERGENTE'},
{ codigo: 'H501', descripcion: 'ESTRABISMO CONCOMITANTE DIVERGENTE'},
{ codigo: 'H502', descripcion: 'ESTRABISMO VERTICAL'},
{ codigo: 'H503', descripcion: 'HETEROTROPIA INTERMITENTE'},
{ codigo: 'H504', descripcion: 'OTRAS HETEROTROPIAS O LAS NO ESPECIFICADAS'},
{ codigo: 'H505', descripcion: 'HETEROFORIA'},
{ codigo: 'H506', descripcion: 'ESTRABISMO MECANICO'},
{ codigo: 'H508', descripcion: 'OTROS ESTRABISMOS ESPECIFICADOS'},
{ codigo: 'H509', descripcion: 'ESTRABISMO, NO ESPECIFICADO'},
{ codigo: 'H51', descripcion: 'OTROS TRASTORNOS DE LOS MOVIMIENTOS BINOCULARES'},
{ codigo: 'H510', descripcion: 'PARALISIS DE LA CONJUGACION DE LA MIRADA'},
{ codigo: 'H511', descripcion: 'EXCESO E INSUFICIENCIA DE LA CONVERGENCIA OCULAR'},
{ codigo: 'H512', descripcion: 'OFTALMOPLEJIA INTERNUCLEAR'},
{ codigo: 'H518', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LOS MOVIMIENTOS BINOCULARES'},
{ codigo: 'H519', descripcion: 'TRASTORNO DEL MOVIMIENTO BINOCULAR, NO ESPECIFICADO'},
{ codigo: 'H52', descripcion: 'TRASTORNOS DE LA ACOMODACION Y DE LA REFRACCION'},
{ codigo: 'H520', descripcion: 'HIPERMETROPIA'},
{ codigo: 'H521', descripcion: 'MIOPIA'},
{ codigo: 'H522', descripcion: 'ASTIGMATISMO'},
{ codigo: 'H523', descripcion: 'ANISOMETROPIA Y ANISEICONIA'},
{ codigo: 'H524', descripcion: 'PRESBICIA'},
{ codigo: 'H525', descripcion: 'TRASTORNOS DE LA ACOMODACION'},
{ codigo: 'H526', descripcion: 'OTROS TRASTORNOS DE LA REFRACCION'},
{ codigo: 'H527', descripcion: 'TRASTORNO DE LA REFRACCION, NO ESPECIFICADO'},
{ codigo: 'H53', descripcion: 'ALTERACIONES DE LA VISION'},
{ codigo: 'H530', descripcion: 'AMBLIOPIA EX ANOPSIA'},
{ codigo: 'H531', descripcion: 'ALTERACIONES VISUALES SUBJETIVAS'},
{ codigo: 'H532', descripcion: 'DIPLOPIA'},
{ codigo: 'H533', descripcion: 'OTROS TRASTORNOS DE LA VISION BINOCULAR'},
{ codigo: 'H534', descripcion: 'DEFECTOS DEL CAMPO VISUAL'},
{ codigo: 'H535', descripcion: 'DEFICIENCIA DE LA VISION CROMATICA'},
{ codigo: 'H536', descripcion: 'CEGUERA NOCTURNA'},
{ codigo: 'H538', descripcion: 'OTRAS ALTERACIONES VISUALES'},
{ codigo: 'H539', descripcion: 'ALTERACION VISUAL, NO ESPECIFICADA'},
{ codigo: 'H54', descripcion: 'CEGUERA Y DISMINUCION DE LA AGUDEZA VISUAL'},
{ codigo: 'H540', descripcion: 'CEGUERA DE AMBOS OJOS'},
{ codigo: 'H541', descripcion: 'CEGUERA DE UN OJO, VISION SUBNORMAL DEL OTRO'},
{ codigo: 'H542', descripcion: 'VISION SUBNORMAL DE AMBOS OJOS'},
{ codigo: 'H543', descripcion: 'DISMINUCION INDETERMINADA DE LA AGUDEZA VISUAL EN AMBOS OJOS'},
{ codigo: 'H544', descripcion: 'CEGUERA DE UN OJO'},
{ codigo: 'H545', descripcion: 'VISION SUBNORMAL DE UN OJO'},
{ codigo: 'H546', descripcion: 'DISMINUCION INDETERMINADA DE LA AGUDEZA VISUAL DE UN OJO'},
{ codigo: 'H547', descripcion: 'DISMINUCION DE LA AGUDEZA VISUAL, SIN ESPECIFICACION'},
{ codigo: 'H55', descripcion: 'NISTAGMO Y OTROS MOVIMIENTOS OCULARES IRREGULARES'},
{ codigo: 'H57', descripcion: 'OTROS TRASTORNOS DEL OJO Y SUS ANEXOS'},
{ codigo: 'H570', descripcion: 'ANOMALIAS DE LA FUNCION PUPILAR'},
{ codigo: 'H571', descripcion: 'DOLOR OCULAR'},
{ codigo: 'H578', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OJO Y SUS ANEXOS'},
{ codigo: 'H579', descripcion: 'TRASTORNO DEL OJO Y SUS ANEXOS, NO ESPECIFICADO'},
{ codigo: 'H58', descripcion: 'OTROS TRASTORNOS DEL OJO Y SUS ANEXOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H580', descripcion: 'ANOMALIAS DE LA FUNCION PUPILAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H581', descripcion: 'ALTERACIONES DE LA VISION EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H588', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OJO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H59', descripcion: 'TRASTORNOS DEL OJO Y SUS ANEXOS CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H590', descripcion: 'SINDROME VITREO CONSECUTIVO A CIRUGIA DE CATARATA'},
{ codigo: 'H598', descripcion: 'OTROS TRASTORNOS DEL OJO Y SUS ANEXOS, CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'H599', descripcion: 'TRASTORNO NO ESPECIFICADO DEL OJO Y SUS ANEXOS, CONSECUTIVO A PROCEDIMIENTOS'},
{ codigo: 'H60', descripcion: 'OTITIS EXTERNA'},
{ codigo: 'H600', descripcion: 'ABSCESO DEL OIDO EXTERNO'},
{ codigo: 'H601', descripcion: 'CELULITIS DEL OIDO EXTERNO'},
{ codigo: 'H602', descripcion: 'OTITIS EXTERNA MALIGNA'},
{ codigo: 'H603', descripcion: 'OTRAS OTITIS EXTERNAS INFECCIOSAS'},
{ codigo: 'H604', descripcion: 'COLESTEATOMA DEL OIDO EXTERNO'},
{ codigo: 'H605', descripcion: 'OTITIS EXTERNA AGUDA, NO INFECCIOSA'},
{ codigo: 'H608', descripcion: 'OTRAS OTITIS EXTERNAS'},
{ codigo: 'H609', descripcion: 'OTITIS EXTERNA, SIN OTRA ESPECIFICACION'},
{ codigo: 'H61', descripcion: 'OTROS TRASTORNOS DEL OIDO EXTERNO'},
{ codigo: 'H610', descripcion: 'PERICONDRITIS DEL OIDO EXTERNO'},
{ codigo: 'H611', descripcion: 'AFECCIONES NO INFECCIOSAS DEL PABELLON AUDITIVO'},
{ codigo: 'H612', descripcion: 'CERUMEN IMPACTADO'},
{ codigo: 'H613', descripcion: 'ESTENOSIS ADQUIRIDA DEL CONDUCTO AUDITIVO EXTERNO'},
{ codigo: 'H618', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OIDO EXTERNO'},
{ codigo: 'H619', descripcion: 'TRASTORNO DEL OIDO EXTERNO, NO ESPECIFICADO'},
{ codigo: 'H62', descripcion: 'TRASTORNOS DEL OIDO EXTERNO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H620', descripcion: 'OTITIS EXTERNA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H621', descripcion: 'OTITIS EXTERNA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H622', descripcion: 'OTITIS EXTERNA EN MICOSIS'},
{ codigo: 'H623', descripcion: 'OTITIS EXTERNA EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H624', descripcion: 'OTITIS EXTERNA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H628', descripcion: 'OTROS TRASTORNOS DEL OIDO EXTERNO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H65', descripcion: 'OTITIS MEDIA NO SUPURATIVA'},
{ codigo: 'H650', descripcion: 'OTITIS MEDIA AGUDA SEROSA'},
{ codigo: 'H651', descripcion: 'OTRA OTITIS MEDIA AGUDA, NO SUPURATIVA'},
{ codigo: 'H652', descripcion: 'OTITIS MEDIA CRONICA SEROSA'},
{ codigo: 'H653', descripcion: 'OTITIS MEDIA CRONICA MUCOIDE'},
{ codigo: 'H654', descripcion: 'OTRAS OTITIS MEDIAS CRONICAS NO SUPURATIVAS'},
{ codigo: 'H659', descripcion: 'OTITIS MEDIA NO SUPURATIVA, SIN OTRA ESPECIFICACION'},
{ codigo: 'H66', descripcion: 'OTITIS MEDIA SUPURATIVA Y LA NO ESPECIFICADA'},
{ codigo: 'H660', descripcion: 'OTITIS MEDIA SUPURATIVA AGUDA'},
{ codigo: 'H661', descripcion: 'OTITIS MEDIA TUBOTIMPANICA SUPURATIVA CRONICA'},
{ codigo: 'H662', descripcion: 'OTITIS MEDIA SUPURATIVA CRONICA ATICOANTRAL'},
{ codigo: 'H663', descripcion: 'OTRAS OTITIS MEDIAS CRONICAS SUPURATIVAS'},
{ codigo: 'H664', descripcion: 'OTITIS MEDIA SUPURATIVA, SIN OTRA ESPECIFICACION'},
{ codigo: 'H669', descripcion: 'OTITIS MEDIA, NO ESPECIFICADA'},
{ codigo: 'H67', descripcion: 'OTITIS MEDIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H670', descripcion: 'OTITIS MEDIA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE *'},
{ codigo: 'H671', descripcion: 'OTITIS MEDIA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE *'},
{ codigo: 'H678', descripcion: 'OTITIS MEDIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE *'},
{ codigo: 'H68', descripcion: 'INFLAMACION Y OBSTRUCCION DE LA TROMPA DE EUSTAQUIO'},
{ codigo: 'H680', descripcion: 'SALPINGITIS EUSTAQUIANA'},
{ codigo: 'H681', descripcion: 'OBSTRUCCION DE LA TROMPA DE EUSTAQUIO'},
{ codigo: 'H69', descripcion: 'OTROS TRASTORNOS DE LA TROMPA DE EUSTAQUIO'},
{ codigo: 'H690', descripcion: 'DISTENSION DE LA TROMPA DE EUSTAQUIO'},
{ codigo: 'H698', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA TROMPA DE EUSTAQUIO'},
{ codigo: 'H699', descripcion: 'TRASTORNO DE LA TROMPA DE EUSTAQUIO, NO ESPECIFICADO'},
{ codigo: 'H70', descripcion: 'MASTOIDITIS Y AFECCIONES RELACIONADAS'},
{ codigo: 'H700', descripcion: 'MASTOIDITIS AGUDA'},
{ codigo: 'H701', descripcion: 'MASTOIDITIS CRONICA'},
{ codigo: 'H702', descripcion: 'PETROSITIS'},
{ codigo: 'H708', descripcion: 'OTRAS MASTOIDITIS Y AFECCIONES RELACIONADAS'},
{ codigo: 'H709', descripcion: 'MASTOIDITIS, NO ESPECIFICADA'},
{ codigo: 'H71', descripcion: 'COLESTEATOMA DEL OIDO MEDIO'},
{ codigo: 'H72', descripcion: 'PERFORACION DE LA MEMBRANA TIMPANICA'},
{ codigo: 'H720', descripcion: 'PERFORACION CENTRAL DE LA MEMBRANA TIMPANICA'},
{ codigo: 'H721', descripcion: 'PERFORACION ATICA DE LA MEMBRANA TIMPANICA'},
{ codigo: 'H722', descripcion: 'OTRAS PERFORACIONES MARGINALES DE LA MEMBRANA TIMPANICA'},
{ codigo: 'H728', descripcion: 'OTRAS PERFORACIONES DE LA MEMBRANA TIMPANICA'},
{ codigo: 'H729', descripcion: 'PERFORACION DE LA MEMBRANA TIMPANICA, SIN OTRA ESPECIFICACION'},
{ codigo: 'H73', descripcion: 'OTROS TRASTORNOS DE LA MEMBRANA TIMPANICA'},
{ codigo: 'H730', descripcion: 'MIRINGITIS AGUDA'},
{ codigo: 'H731', descripcion: 'MIRINGITIS CRONICA'},
{ codigo: 'H738', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA MEMBRANA TIMPANICA'},
{ codigo: 'H739', descripcion: 'TRASTORNO DE LA MEMBRANA TIMPANICA, NO ESPECIFICADO'},
{ codigo: 'H74', descripcion: 'OTROS TRASTORNOS DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES'},
{ codigo: 'H740', descripcion: 'TIMPANOSCLEROSIS'},
{ codigo: 'H741', descripcion: 'ENFERMEDAD ADHESIVA DEL OIDO MEDIO'},
{ codigo: 'H742', descripcion: 'DISCONTINUIDAD Y DISLOCACION DE LOS HUESECILLOS DEL OIDO'},
{ codigo: 'H743', descripcion: 'OTRAS ANORMALIDADES ADQUIRIDAS DE LOS HUESECILLOS DEL OIDO'},
{ codigo: 'H744', descripcion: 'POLIPO DEL OIDO MEDIO'},
{ codigo: 'H748', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES'},
{ codigo: 'H749', descripcion: 'TRASTORNO DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES, NO ESPECIFICADO'},
{ codigo: 'H75', descripcion: 'OTROS TRASTORNOS DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H750', descripcion: 'MASTOIDITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H758', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H80', descripcion: 'OTOSCLEROSIS'},
{ codigo: 'H800', descripcion: 'OTOSCLEROSIS QUE AFECTA LA VENTANA OVAL, NO OBLITERANTE'},
{ codigo: 'H801', descripcion: 'OTOSCLEROSIS QUE AFECTA LA VENTANA OVAL, OBLITERANTE'},
{ codigo: 'H802', descripcion: 'OTOSCLEROSIS COCLEAR'},
{ codigo: 'H808', descripcion: 'OTRAS OTOSCLEROSIS'},
{ codigo: 'H809', descripcion: 'OTOSCLEROSIS, NO ESPECIFICADA'},
{ codigo: 'H81', descripcion: 'TRASTORNOS DE LA FUNCION VESTIBULAR'},
{ codigo: 'H810', descripcion: 'ENFERMEDAD DE MENIERE'},
{ codigo: 'H811', descripcion: 'VERTIGO PAROXISTICO BENIGNO'},
{ codigo: 'H812', descripcion: 'NEURONITIS VESTIBULAR'},
{ codigo: 'H813', descripcion: 'OTROS VERTIGOS PERIFERICOS'},
{ codigo: 'H814', descripcion: 'VERTIGO DE ORIGEN CENTRAL'},
{ codigo: 'H818', descripcion: 'OTROS TRASTORNOS DE LA FUNCION VESTIBULAR'},
{ codigo: 'H819', descripcion: 'TRASTORNO DE LA FUNCION VESTIBULAR, NO ESPECIFICADO'},
{ codigo: 'H82', descripcion: 'SINDROMES VERTIGINOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H83', descripcion: 'OTROS TRASTORNOS DEL OIDO INTERNO'},
{ codigo: 'H830', descripcion: 'LABERINTITIS'},
{ codigo: 'H831', descripcion: 'FISTULA DEL LABERINTO'},
{ codigo: 'H832', descripcion: 'DISFUNCION DEL LABERINTO'},
{ codigo: 'H833', descripcion: 'EFECTOS DEL RUIDO SOBRE EL OIDO INTERNO'},
{ codigo: 'H838', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OIDO INTERNO'},
{ codigo: 'H839', descripcion: 'TRASTORNO DEL OIDO INTERNO, NO ESPECIFICADO'},
{ codigo: 'H90', descripcion: 'HIPOACUSIA CONDUCTIVA Y NEUROSENSORIAL'},
{ codigo: 'H900', descripcion: 'HIPOACUSIA CONDUCTIVA BILATERAL'},
{ codigo: 'H901', descripcion: 'HIPOACUSIA CONDUCTIVA, UNILATERAL CON AUDICION IRRESTRICTA CONTRALATERAL'},
{ codigo: 'H902', descripcion: 'HIPOACUSIA CONDUCTIVA, SIN OTRA ESPECIFICACION'},
{ codigo: 'H903', descripcion: 'HIPOACUSIA NEUROSENSORIAL, BILATERAL'},
{ codigo: 'H904', descripcion: 'HIPOACUSIA NEUROSENSORIAL, UNILATERAL CON AUDICION IRRESTRICTA CONTRALATERAL'},
{ codigo: 'H905', descripcion: 'HIPOACUSIA NEUROSENSORIAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'H906', descripcion: 'HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, BILATERAL'},
{ codigo: 'H907', descripcion: 'HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL UNILATERAL CON AUDICION IRRRESTRICTA CONTRALATERAL'},
{ codigo: 'H908', descripcion: 'HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, NO ESPECIFICADA'},
{ codigo: 'H91', descripcion: 'OTRAS HIPOACUSIAS'},
{ codigo: 'H910', descripcion: 'HIPOACUSIA OTOTOXICA'},
{ codigo: 'H911', descripcion: 'PRESBIACUSIA'},
{ codigo: 'H912', descripcion: 'HIPOACUSIA SUBITA IDIOPATICA'},
{ codigo: 'H913', descripcion: 'SORDOMUDEZ, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'H918', descripcion: 'OTRAS HIPOACUSIAS ESPECIFICADAS'},
{ codigo: 'H919', descripcion: 'HIPOACUSIA, NO ESPECIFICADA'},
{ codigo: 'H92', descripcion: 'OTALGIA Y SECRECION DEL OIDO'},
{ codigo: 'H920', descripcion: 'OTALGIA'},
{ codigo: 'H921', descripcion: 'OTORREA'},
{ codigo: 'H922', descripcion: 'OTORRAGIA'},
{ codigo: 'H93', descripcion: 'OTROS TRASTORNOS DEL OIDO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'H930', descripcion: 'TRASTORNOS DEGENERATIVOS Y VASCULARES DEL OIDO'},
{ codigo: 'H931', descripcion: 'TINNITUS'},
{ codigo: 'H932', descripcion: 'OTRAS PERCEPCIONES AUDITIVAS ANORMALES'},
{ codigo: 'H933', descripcion: 'TRASTORNOS DEL NERVIO AUDITIVO'},
{ codigo: 'H938', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OIDO'},
{ codigo: 'H939', descripcion: 'TRASTORNO DEL OIDO, NO ESPECIFICADO'},
{ codigo: 'H94', descripcion: 'OTROS TRASTORNOS DEL OIDO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H940', descripcion: 'NEURITIS DEL NERVIO AUDITIVO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H948', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL OIDO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H95', descripcion: 'TRASTORNOS DEL OIDO Y DE LA APOFISIS MASTOIDES CONSECUTIVOS A PROCEDIMIENTOS NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'H950', descripcion: 'COLESTEATOMA RECURRENTE DE LA CAVIDAD RESULTANTE DE LA MASTOIDECTOMIA'},
{ codigo: 'H951', descripcion: 'OTROS TRASTORNOS POSTERIORES A LA MASTOIDECTOMIA'},
{ codigo: 'H958', descripcion: 'OTROS TRASTORNOS DEL OIDO Y DE LA APOFISIS MASTOIDES, CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'H959', descripcion: 'TRASTORNOS NO ESPECIFICADOS DEL OIDO Y DE LA APOFISIS MASTOIDES, CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'I00', descripcion: 'FIEBRE REUMATICA SIN MENCION DE COMPLICACION CARDIACA'},
{ codigo: 'I01', descripcion: 'FIEBRE REUMATICA CON COMPLICACION CARDIACA'},
{ codigo: 'I010', descripcion: 'PERICARDITIS REUMATICA AGUDA'},
{ codigo: 'I011', descripcion: 'ENDOCARDITIS REUMATICA AGUDA'},
{ codigo: 'I012', descripcion: 'MIOCARDITIS REUMATICA AGUDA'},
{ codigo: 'I018', descripcion: 'OTRAS ENFERMEDADES REUMATICAS AGUDAS DEL CORAZON'},
{ codigo: 'I019', descripcion: 'ENFERMEDAD REUMATICA AGUDA DEL CORAZON, NO ESPECIFICADA'},
{ codigo: 'I02', descripcion: 'COREA REUMATICA'},
{ codigo: 'I020', descripcion: 'COREA REUMATICA CON COMPLICACION CARDIACA'},
{ codigo: 'I029', descripcion: 'COREA REUMATICA SIN MENCION DE COMPLICACION CARDIACA'},
{ codigo: 'I05', descripcion: 'ENFERMEDADES REUMATICAS DE LA VALVULA MITRAL'},
{ codigo: 'I050', descripcion: 'ESTENOSIS MITRAL'},
{ codigo: 'I051', descripcion: 'INSUFICIENCIA MITRAL REUMATICA'},
{ codigo: 'I052', descripcion: 'ESTENOSIS MITRAL CON INSUFICIENCIA'},
{ codigo: 'I058', descripcion: 'OTRAS ENFERMEDADES DE LA VALVULA MITRAL'},
{ codigo: 'I059', descripcion: 'ENFERMEDAD VALVULAR MITRAL, NO ESPECIFICADA'},
{ codigo: 'I06', descripcion: 'ENFERMEDADES REUMATICAS DE LA VALVULA AORTICA'},
{ codigo: 'I060', descripcion: 'ESTENOSIS AORTICA REUMATICA'},
{ codigo: 'I061', descripcion: 'INSUFICIENCIA AORTICA REUMATICA'},
{ codigo: 'I062', descripcion: 'ESTENOSIS AORTICA REUMATICA CON INSUFICIENCIA'},
{ codigo: 'I068', descripcion: 'OTRAS ENFERMEDADES REUMATICAS DE LA VALVULA AORTICA'},
{ codigo: 'I069', descripcion: 'ENFERMEDAD VALVULAR AORTICA REUMATICA, NO ESPECIFICADA'},
{ codigo: 'I07', descripcion: 'ENFERMEDADES REUMATICAS DE LA VALVULA TRICUSPIDE'},
{ codigo: 'I070', descripcion: 'ESTENOSIS TRICUSPIDE'},
{ codigo: 'I071', descripcion: 'INSUFICIENCIA TRICUSPIDE'},
{ codigo: 'I072', descripcion: 'ESTENOSIS E INSUFICIENCIA TRICUSPIDE'},
{ codigo: 'I078', descripcion: 'OTRAS ENFERMEDADES DE LA VALVULA TRICUSPIDE'},
{ codigo: 'I079', descripcion: 'ENFERMEDAD DE LA VALVULA TRICUSPIDE, NO ESPECIFICADA'},
{ codigo: 'I08', descripcion: 'ENFERMEDADES VALVULARES MULTIPLES'},
{ codigo: 'I080', descripcion: 'TRASTORNOS DE LAS VALVULAS MITRAL Y AORTICA'},
{ codigo: 'I081', descripcion: 'TRASTORNOS DE LAS VALVULAS MITRAL Y TRICUSPIDE'},
{ codigo: 'I082', descripcion: 'TRASTORNOS DE LAS VALVULAS AORTICA Y TRICUSPIDE'},
{ codigo: 'I083', descripcion: 'TRASTORNOS COMBINADOS DE LAS VALVULAS MITRAL, TRICUSPIDE Y AORTICA'},
{ codigo: 'I088', descripcion: 'OTRAS ENFERMEDADES DE MULTIPLES VALVULAS'},
{ codigo: 'I089', descripcion: 'ENFERMEDAD DE MULTIPLES VALVULAS, NO ESPECIFICADA'},
{ codigo: 'I09', descripcion: 'OTRAS ENFERMEDADES REUMATICAS DEL CORAZON'},
{ codigo: 'I090', descripcion: 'MIOCARDITIS REUMATICA'},
{ codigo: 'I091', descripcion: 'ENFERMEDADES REUMATICAS DEL ENDOCARDIO, VALVULA NO ESPECIFICADA'},
{ codigo: 'I092', descripcion: 'PERICARDITIS REUMATICA CRONICA'},
{ codigo: 'I098', descripcion: 'OTRAS ENFERMEDADES REUMATICAS ESPECIFICADAS DEL CORAZON'},
{ codigo: 'I099', descripcion: 'ENFERMEDAD REUMATICA DEL CORAZON, NO ESPECIFICADA'},
{ codigo: 'I10', descripcion: 'HIPERTENSION ESENCIAL (PRIMARIA)'},
{ codigo: 'I11', descripcion: 'ENFERMEDAD CARDIACA HIPERTENSIVA'},
{ codigo: 'I110', descripcion: 'ENFERMEDAD CARDIACA HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA)'},
{ codigo: 'I119', descripcion: 'ENFERMEDAD CARDIACA HIPERTENSIVA SIN INSUFICIENCIA CARDIACA (CONGESTIVA)'},
{ codigo: 'I12', descripcion: 'ENFERMEDAD RENAL HIPERTENSIVA'},
{ codigo: 'I120', descripcion: 'ENFERMEDAD RENAL HIPERTENSIVA CON INSUFICIENCIA RENAL'},
{ codigo: 'I129', descripcion: 'ENFERMEDAD RENAL HIPERTENSIVA SIN INSUFICIENCIA RENAL'},
{ codigo: 'I13', descripcion: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA'},
{ codigo: 'I130', descripcion: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA)'},
{ codigo: 'I131', descripcion: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA RENAL'},
{ codigo: 'I132', descripcion: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA) E INSUFICIENCIA RENAL'},
{ codigo: 'I139', descripcion: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA, NO ESPECIFICADA'},
{ codigo: 'I15', descripcion: 'HIPERTENSION SECUNDARIA'},
{ codigo: 'I150', descripcion: 'HIPERTENSION RENOVASCULAR'},
{ codigo: 'I151', descripcion: 'HIPERTENSION SECUNDARIA A OTROS TRASTORNOS RENALES'},
{ codigo: 'I152', descripcion: 'HIPERTENSION SECUNDARIA A TRASTORNOS ENDOCRINOS'},
{ codigo: 'I158', descripcion: 'OTROS TIPOS DE HIPERTENSION SECUNDARIA'},
{ codigo: 'I159', descripcion: 'HIPERTENSION SECUNDARIA, NO ESPECIFICADA'},
{ codigo: 'I20', descripcion: 'ANGINA DE PECHO'},
{ codigo: 'I200', descripcion: 'ANGINA INESTABLE'},
{ codigo: 'I201', descripcion: 'ANGINA DE PECHO CON ESPASMO DOCUMENTADO'},
{ codigo: 'I208', descripcion: 'OTRAS FORMAS ESPECIFICADAS DE ANGINA DE PECHO'},
{ codigo: 'I209', descripcion: 'ANGINA DE PECHO, NO ESPECIFICADA'},
{ codigo: 'I21', descripcion: 'INFARTO AGUDO DEL MIOCARDIO'},
{ codigo: 'I210', descripcion: 'INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED ANTERIOR'},
{ codigo: 'I211', descripcion: 'INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED INFERIOR'},
{ codigo: 'I212', descripcion: 'INFARTO AGUDO TRANSMURAL DEL MIOCARDIO DE OTROS SITIOS'},
{ codigo: 'I213', descripcion: 'INFARTO TRANSMURAL AGUDO DEL MIOCARDIO, DE SITIO NO ESPECIFICADO'},
{ codigo: 'I214', descripcion: 'INFARTO SUBENDOCARDICO AGUDO DEL MIOCARDIO'},
{ codigo: 'I219', descripcion: 'INFARTO AGUDO DEL MIOCARDIO, SIN OTRA ESPECIFICACION'},
{ codigo: 'I22', descripcion: 'INFARTO SUBSECUENTE DEL MIOCARDIO'},
{ codigo: 'I220', descripcion: 'INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED ANTERIOR'},
{ codigo: 'I221', descripcion: 'INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED INFERIOR'},
{ codigo: 'I228', descripcion: 'INFARTO SUBSECUENTE DEL MIOCARDIO DE OTROS SITIOS'},
{ codigo: 'I229', descripcion: 'INFARTO SUBSECUENTE DEL MIOCARDIO, DE PARTE NO ESPECIFICADA'},
{ codigo: 'I23', descripcion: 'CIERTAS COMPLICACIONES PRESENTES POSTERIORES AL INFARTO AGUDO DEL MIOCARDIO'},
{ codigo: 'I230', descripcion: 'HEMOPERICARDIO COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO'},
{ codigo: 'I231', descripcion: 'DEFECTO TABIQUE AURICULAR COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO MIOCARDIO'},
{ codigo: 'I232', descripcion: 'DEFECTO TABIQUE VENTRICULAR COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO MIOCARDIO'},
{ codigo: 'I233', descripcion: 'RUPTURA PARED CARDIACA SIN HEMOPERICARDIO COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO MIOCARDIO'},
{ codigo: 'I234', descripcion: 'RUPTURA DE CUERDAS TENDINOSAS COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO MIOCARDIO'},
{ codigo: 'I235', descripcion: 'RUPTURA DE MUSCULO PAPILAR COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO MIOCARDIO'},
{ codigo: 'I236', descripcion: 'TROMBOSIS DE LA AURICULA APENDICE AURICULAR Y VENTRICULO COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO MIOCARDIO'},
{ codigo: 'I238', descripcion: 'OTRAS COMPLICACIONES PRESENTES POSTERIORES AL INFARTO AGUDO MIOCARIO'},
{ codigo: 'I24', descripcion: 'OTRAS ENFERMEDADES ISQUEMICAS AGUDAS DEL CORAZON'},
{ codigo: 'I240', descripcion: 'TROMBOSIS CORONARIA QUE NO RESULTA EN INFARTO DEL MIOCARDIO'},
{ codigo: 'I241', descripcion: 'SINDROME DE DRESSLER'},
{ codigo: 'I248', descripcion: 'OTRAS FORMAS DE ENFERMEDAD ISQUEMICA AGUDA DEL CORAZON'},
{ codigo: 'I249', descripcion: 'ENFERMEDAD ISQUEMICA AGUDA DEL CORAZON, NO ESPECIFICADA'},
{ codigo: 'I25', descripcion: 'ENFERMEDAD ISQUEMICA CRONICA DEL CORAZON'},
{ codigo: 'I250', descripcion: 'ENFERMEDAD CARDIOVASCULAR ATEROSCLEROTICA, ASI DESCRITA'},
{ codigo: 'I251', descripcion: 'ENFERMEDAD ATEROSCLEROTICA DEL CORAZON'},
{ codigo: 'I252', descripcion: 'INFARTO ANTIGUO DEL MIOCARDIO'},
{ codigo: 'I253', descripcion: 'ANEURISMA CARDIACO'},
{ codigo: 'I254', descripcion: 'ANEURISMA DE ARTERIA CORONARIA'},
{ codigo: 'I255', descripcion: 'CARDIOMIOPATIA ISQUEMICA'},
{ codigo: 'I256', descripcion: 'ISQUEMIA SILENTE DEL MIOCARDIO'},
{ codigo: 'I258', descripcion: 'OTRAS FORMAS DE ENFERMEDAD ISQUEMICA CRONICA DEL CORAZON'},
{ codigo: 'I259', descripcion: 'ENFERMEDAD ISQUEMICA CRONICA DEL CORAZON, NO ESPECIFICADA'},
{ codigo: 'I26', descripcion: 'EMBOLIA PULMONAR'},
{ codigo: 'I260', descripcion: 'EMBOLIA PULMONAR CON MENCION DE CORAZON PULMONAR AGUDO'},
{ codigo: 'I269', descripcion: 'EMBOLIA PULMONAR SIN MENCION DE CORAZON PULMONAR AGUDO'},
{ codigo: 'I27', descripcion: 'OTRAS ENFERMEDADES CARDIOPULMONARES'},
{ codigo: 'I270', descripcion: 'HIPERTENSION PULMONAR PRIMARIA'},
{ codigo: 'I271', descripcion: 'ENFERMEDAD CIFOSCOLIOTICA DEL CORAZON'},
{ codigo: 'I278', descripcion: 'OTRAS ENFERMEDADES CARDIOPULMONARES ESPECIFICADAS'},
{ codigo: 'I279', descripcion: 'ENFERMEDAD PULMONAR DEL CORAZON, NO ESPECIFICADA'},
{ codigo: 'I28', descripcion: 'OTRAS ENFERMEDADES DE LOS VASOS PULMONARES'},
{ codigo: 'I280', descripcion: 'FISTULA ARTERIOVENOSA DE LOS VASOS PULMONARES'},
{ codigo: 'I281', descripcion: 'ANEURISMA DE LA ARTERIA PULMONAR'},
{ codigo: 'I288', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LOS VASOS PULMONARES'},
{ codigo: 'I289', descripcion: 'ENFERMEDAD DE LOS VASOS PULMONARES, NO ESPECIFICADA'},
{ codigo: 'I30', descripcion: 'PERICARDITIS AGUDA'},
{ codigo: 'I300', descripcion: 'PERICARDITIS IDIOPATICA AGUDA INESPECIFICA'},
{ codigo: 'I301', descripcion: 'PERICARDITIS INFECCIOSA'},
{ codigo: 'I308', descripcion: 'OTRAS FORMAS DE PERICARDITIS AGUDA'},
{ codigo: 'I309', descripcion: 'PERICARDITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'I31', descripcion: 'OTRAS ENFERMEDADES DEL PERICARDIO'},
{ codigo: 'I310', descripcion: 'PERICARDITIS CRONICA ADHESIVA'},
{ codigo: 'I311', descripcion: 'PERICARDITIS CONSTRICTIVA CRONICA'},
{ codigo: 'I312', descripcion: 'HEMOPERICARDIO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'I313', descripcion: 'DERRAME PERICARDICO (NO INFLAMATORIO)'},
{ codigo: 'I318', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL PERICARDIO'},
{ codigo: 'I319', descripcion: 'ENFERMEDAD DEL PERICARDIO, NO ESPECIFICADA'},
{ codigo: 'I32', descripcion: 'PERICARDITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I320', descripcion: 'PERICARDITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE *'},
{ codigo: 'I321', descripcion: 'PERICARDITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CEOP'},
{ codigo: 'I328', descripcion: 'PERICARDITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I33', descripcion: 'ENDOCARDITIS AGUDA Y SUBAGUDA'},
{ codigo: 'I330', descripcion: 'ENDOCARDITIS INFECCIOSA AGUDA Y SUBAGUDA'},
{ codigo: 'I339', descripcion: 'ENDOCARDITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'I34', descripcion: 'TRASTORNOS NO REUMATICOS DE LA VALVULA MITRAL'},
{ codigo: 'I340', descripcion: 'INSUFICIENCIA (DE LA VALVULA) MITRAL'},
{ codigo: 'I341', descripcion: 'PROLAPSO (DE LA VALVULA) MITRAL'},
{ codigo: 'I342', descripcion: 'ESTENOSIS (DE LA VALVULA) MITRAL, NO REUMATICA'},
{ codigo: 'I348', descripcion: 'OTROS TRASTORNOS NO REUMATICOS DE LA VALVULA MITRAL'},
{ codigo: 'I349', descripcion: 'TRASTORNO MITRAL NO REUMATICO, NO ESPECIFICADO'},
{ codigo: 'I35', descripcion: 'TRASTORNOS NO REUMATICOS DE LA VALVULA AORTICA'},
{ codigo: 'I350', descripcion: 'ESTENOSIS (DE LA VALVULA) AORTICA'},
{ codigo: 'I351', descripcion: 'INSUFICIENCIA (DE LA VALVULA) AORTICA'},
{ codigo: 'I352', descripcion: 'ESTENOSIS (DE LA VALVULA) AORTICA CON INSUFICIENCIA'},
{ codigo: 'I358', descripcion: 'OTROS TRASTORNOS DE LA VALVULA AORTICA'},
{ codigo: 'I359', descripcion: 'TRASTORNO DE LA VALVULA AORTICA, NO ESPECIFICADO'},
{ codigo: 'I36', descripcion: 'TRASTORNOS NO REUMATICOS DE LA VALVULA TRICUSPIDE'},
{ codigo: 'I360', descripcion: 'ESTENOSIS NO REUMATICA (DE LA VALVULA) TRICUSPIDE'},
{ codigo: 'I361', descripcion: 'INSUFICIENCIA NO REUMATICA (DE LA VALVULA) TRICUSPIDE'},
{ codigo: 'I362', descripcion: 'ESTENOSIS CON INSUFICIENCIA NO REUMATICA (DE LA VALVULA) TRICUSPIDE'},
{ codigo: 'I368', descripcion: 'OTROS TRASTORNOS NO REUMATICOS DE LA VALVULA TRICUSPIDE'},
{ codigo: 'I369', descripcion: 'TRASTORNO NO REUMATICO DE LA VALVULA TRICUSPIDE, NO ESPECIFICADO'},
{ codigo: 'I37', descripcion: 'TRASTORNOS DE LA VALVULA PULMONAR'},
{ codigo: 'I370', descripcion: 'ESTENOSIS DE LA VALVULA PULMONAR'},
{ codigo: 'I371', descripcion: 'INSUFICIENCIA DE LA VALVULA PULMONAR'},
{ codigo: 'I372', descripcion: 'ESTENOSIS DE LA VALVULA PULMONAR CON INSUFICIENCIA'},
{ codigo: 'I378', descripcion: 'OTROS TRASTORNOS DE LA VALVULA PULMONAR'},
{ codigo: 'I379', descripcion: 'TRASTORNO DE LA VALVULA PULMONAR, NO ESPECIFICADO'},
{ codigo: 'I38', descripcion: 'ENDOCARDITIS, VALVULA NO ESPECIFICADA'},
{ codigo: 'I39', descripcion: 'ENDOCARDITIS Y TRASTORNOS VALVULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I390', descripcion: 'TRASTORNOS DE LA VALVULA MITRAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I391', descripcion: 'TRASTORNOS DE LA VALVULA AORTICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I392', descripcion: 'TRASTORNOS DE LA VALVULA TRICUSPIDE EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I393', descripcion: 'TRASTORNOS DE LA VALVULA PULMONAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I394', descripcion: 'TRASTORNOS VALVULARES MULTIPLES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I398', descripcion: 'ENDOCARDITIS, VALVULA NO ESPECIFICADA, EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I40', descripcion: 'MIOCARDITIS AGUDA'},
{ codigo: 'I400', descripcion: 'MIOCARDITIS INFECCIOSA'},
{ codigo: 'I401', descripcion: 'MIOCARDITIS AISLADA'},
{ codigo: 'I408', descripcion: 'OTRAS MIOCARDITIS AGUDAS'},
{ codigo: 'I409', descripcion: 'MIOCARDITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'I41', descripcion: 'MIOCARDITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I410', descripcion: 'MIOCARDITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I411', descripcion: 'MIOCARDITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I412', descripcion: 'MIOCARDITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I418', descripcion: 'MIOCARDITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I42', descripcion: 'CARDIOMIOPATIA'},
{ codigo: 'I420', descripcion: 'CARDIOMIOPATIA DILATADA'},
{ codigo: 'I421', descripcion: 'CARDIOMIOPATIA HIPERTROFICA OBSTRUCTIVA'},
{ codigo: 'I422', descripcion: 'OTRAS CARDIOMIOPATIAS HIPERTROFICAS'},
{ codigo: 'I423', descripcion: 'ENFERMEDAD ENDOMIOCARDICA (EOSINOFILICA)'},
{ codigo: 'I424', descripcion: 'FIBROELASTOSIS ENDOCARDICA'},
{ codigo: 'I425', descripcion: 'OTRAS CARDIOMIOPATIAS RESTRICTIVAS'},
{ codigo: 'I426', descripcion: 'CARDIOMIOPATIA ALCOHOLICA'},
{ codigo: 'I427', descripcion: 'CARDIOMIOPATIA DEBIDA A DROGAS Y OTROS AGENTES EXTERNOS'},
{ codigo: 'I428', descripcion: 'OTRAS CARDIOMIOPATIAS'},
{ codigo: 'I429', descripcion: 'CARDIOMIOPATIA, NO ESPECIFICADA'},
{ codigo: 'I43', descripcion: 'CARDIOMIOPATIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I430', descripcion: 'CARDIOMIOPATIA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I431', descripcion: 'CARDIOMIOPATIA EN ENFERMEDADES METABOLICAS'},
{ codigo: 'I432', descripcion: 'CARDIOMIOPATIA EN ENFERMEDADES NUTRICIONALES'},
{ codigo: 'I438', descripcion: 'CARDIOMIOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I44', descripcion: 'BLOQUEO AURICULOVENTRICULAR Y DE RAMA IZQUIERDA DEL HAZ'},
{ codigo: 'I440', descripcion: 'BLOQUEO AURICULOVENTRICULAR DE PRIMER GRADO'},
{ codigo: 'I441', descripcion: 'BLOQUEO AURICULOVENTRICULAR DE SEGUNDO GRADO'},
{ codigo: 'I442', descripcion: 'BLOQUEO AURICULOVENTRICULAR COMPLETO'},
{ codigo: 'I443', descripcion: 'OTROS TIPOS DE BLOQUEO AURICULOVENTRICULAR Y LOS NO ESPECIFICADOS'},
{ codigo: 'I444', descripcion: 'BLOQUEO FASCICULAR ANTERIOR IZQUIERDO'},
{ codigo: 'I445', descripcion: 'BLOQUEO FASCICULAR POSTERIOR IZQUIERDO'},
{ codigo: 'I446', descripcion: 'OTROS TIPOS DE BLOQUEO FASCICULAR Y LOS NO ESPECIFICADOS'},
{ codigo: 'I447', descripcion: 'BLOQUEO DE RAMA IZQUIERDA DEL HAZ, SIN OTRA ESPECIFICACION'},
{ codigo: 'I45', descripcion: 'OTROS TRASTORNOS DE LA CONDUCCION'},
{ codigo: 'I450', descripcion: 'BLOQUEO FASCICULAR DERECHO'},
{ codigo: 'I451', descripcion: 'OTROS TIPOS DE BLOQUEO DE RAMA DERECHA DEL HAZ Y LOS NO ESPECIFICADOS'},
{ codigo: 'I452', descripcion: 'BLOQUEO BIFASCICULAR'},
{ codigo: 'I453', descripcion: 'BLOQUEO TRIFASCICULAR'},
{ codigo: 'I454', descripcion: 'BLOQUEO INTRAVENTRICULAR NO ESPECIFICADO'},
{ codigo: 'I455', descripcion: 'OTROS TIPOS ESPECIFICADOS DE BLOQUEO DEL CORAZON'},
{ codigo: 'I456', descripcion: 'SINDROME DE PREEXCITACION'},
{ codigo: 'I458', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA CONDUCCION'},
{ codigo: 'I459', descripcion: 'TRASTORNO DE LA CONDUCCION, NO ESPECIFICADO'},
{ codigo: 'I46', descripcion: 'PARO CARDIACO'},
{ codigo: 'I460', descripcion: 'PARO CARDIACO CON RESUCITACION EXITOSA'},
{ codigo: 'I461', descripcion: 'MUERTE CARDIACA SUBITA, ASI DESCRITA'},
{ codigo: 'I469', descripcion: 'PARO CARDIACO, NO ESPECIFICADO'},
{ codigo: 'I47', descripcion: 'TAQUICARDIA PAROXISTICA'},
{ codigo: 'I470', descripcion: 'ARRITMIA POR REENTRADA VENTRICULAR'},
{ codigo: 'I471', descripcion: 'TAQUICARDIA SUPRAVENTRICULAR'},
{ codigo: 'I472', descripcion: 'TAQUICARDIA VENTRICULAR'},
{ codigo: 'I479', descripcion: 'TAQUICARDIA PAROXISTICA, NO ESPECIFICADA'},
{ codigo: 'I48', descripcion: 'FIBRILACION Y ALETEO AURICULAR'},
{ codigo: 'I49', descripcion: 'OTRAS ARRITMIAS CARDIACAS'},
{ codigo: 'I490', descripcion: 'FIBRILACION Y ALETEO VENTRICULAR'},
{ codigo: 'I491', descripcion: 'DESPOLARIZACION AURICULAR PREMATURA'},
{ codigo: 'I492', descripcion: 'DESPOLARIZACION PREMATURA NODAL'},
{ codigo: 'I493', descripcion: 'DESPOLARIZACION VENTRICULAR PREMATURA'},
{ codigo: 'I494', descripcion: 'OTROS TIPOS DE DESPOLARIZACION PREMATURA Y LOS NO ESPECIFICADOS'},
{ codigo: 'I495', descripcion: 'SINDROME DEL SENO ENFERMO'},
{ codigo: 'I498', descripcion: 'OTRAS ARRITMIAS CARDIACAS ESPECIFICADAS'},
{ codigo: 'I499', descripcion: 'ARRITMIA CARDIACA, NO ESPECIFICADA'},
{ codigo: 'I50', descripcion: 'INSUFICIENCIA CARDIACA'},
{ codigo: 'I500', descripcion: 'INSUFICIENCIA CARDIACA CONGESTIVA'},
{ codigo: 'I501', descripcion: 'INSUFICIENCIA VENTRICULAR IZQUIERDA'},
{ codigo: 'I509', descripcion: 'INSUFICIENCIA CARDIACA, NO ESPECIFICADA'},
{ codigo: 'I51', descripcion: 'COMPLICACIONES Y DESCRIPCIONES MAL DEFINIDAS DE ENFERMEDAD CARDIACA'},
{ codigo: 'I510', descripcion: 'DEFECTO DEL TABIQUE CARDIACO, ADQUIRIDO'},
{ codigo: 'I511', descripcion: 'RUPTURA DE CUERDA TENDINOSA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'I512', descripcion: 'RUPTURA DE MUSCULO PAPILAR, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'I513', descripcion: 'TROMBOSIS INTRACARDIACA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'I514', descripcion: 'MIOCARDITIS, NO ESPECIFICADA'},
{ codigo: 'I515', descripcion: 'DEGENERACION MIOCARDICA'},
{ codigo: 'I516', descripcion: 'ENFERMEDAD CARDIOVASCULAR, NO ESPECIFICADA'},
{ codigo: 'I517', descripcion: 'CARDIOMEGALIA'},
{ codigo: 'I518', descripcion: 'OTRAS ENFERMEDADES CARDIACAS MAL DEFINIDAS'},
{ codigo: 'I519', descripcion: 'ENFERMEDAD CARDIACA, NO ESPECIFICADA'},
{ codigo: 'I52', descripcion: 'OTROS TRASTORNOS CARDIACOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I520', descripcion: 'OTROS TRASTORNOS CARDIACOS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I521', descripcion: 'OTROS TRASTORNOS CARDIACOS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I528', descripcion: 'OTROS TRASTORNOS CARDIACOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I60', descripcion: 'HEMORRAGIA SUBARACNOIDEA'},
{ codigo: 'I600', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE SIFON Y BIFURCACION CAROTIDEA'},
{ codigo: 'I601', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA CEREBRAL MEDIA'},
{ codigo: 'I602', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE ANTERIOR'},
{ codigo: 'I603', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE POSTERIOR'},
{ codigo: 'I604', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA BASILAR'},
{ codigo: 'I605', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA VERTEBRAL'},
{ codigo: 'I606', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE OTRAS ARTERIAS INTRACRANEALES'},
{ codigo: 'I607', descripcion: 'HEMORRAGIA SUBARACNOIDEA DE ARTERIA INTRACRANEAL NO ESPECIFICADA'},
{ codigo: 'I608', descripcion: 'OTRAS HEMORRAGIAS SUBARACNOIDEAS'},
{ codigo: 'I609', descripcion: 'HEMORRAGIA SUBARACNOIDEA, NO ESPECIFICADA'},
{ codigo: 'I61', descripcion: 'HEMORRAGIA INTRAENCEFALICA'},
{ codigo: 'I610', descripcion: 'HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, SUBCORTICAL'},
{ codigo: 'I611', descripcion: 'HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, CORTICAL'},
{ codigo: 'I612', descripcion: 'HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, NO ESPECIFICADA'},
{ codigo: 'I613', descripcion: 'HEMORRAGIA INTRAENCEFALICA EN TALLO CEREBRAL'},
{ codigo: 'I614', descripcion: 'HEMORRAGIA INTRAENCEFALICA EN CEREBELO'},
{ codigo: 'I615', descripcion: 'HEMORRAGIA INTRAENCEFALICA, INTRAVENTRICULAR'},
{ codigo: 'I616', descripcion: 'HEMORRAGIA INTRAENCEFALICA DE LOCALIZACIONES MULTIPLES'},
{ codigo: 'I618', descripcion: 'OTRAS HEMORRAGIAS INTRAENCEFALICAS'},
{ codigo: 'I619', descripcion: 'HEMORRAGIA INTRAENCEFALICA, NO ESPECIFICADA'},
{ codigo: 'I62', descripcion: 'OTRAS HEMORRAGIAS INTRACRANEALES NO TRAUMATICAS'},
{ codigo: 'I620', descripcion: 'HEMORRAGIA SUBDURAL (AGUDA) (NO TRAUMATICA)'},
{ codigo: 'I621', descripcion: 'HEMORRAGIA EXTRADURAL NO TRAUMATICA'},
{ codigo: 'I629', descripcion: 'HEMORRAGIA INTRACRANEAL (NO TRAUMATICA), NO ESPECIFICADA'},
{ codigo: 'I63', descripcion: 'INFARTO CEREBRAL'},
{ codigo: 'I630', descripcion: 'INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS PRECEREBRALES'},
{ codigo: 'I631', descripcion: 'INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS PRECEREBRALES'},
{ codigo: 'I632', descripcion: 'INFARTO CEREBRAL DEBIDO A OCLUSION O ESTENOSIS NO ESPECIFICADA DE ARTERIAS PRECEREBRALES'},
{ codigo: 'I633', descripcion: 'INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS CEREBRALES'},
{ codigo: 'I634', descripcion: 'INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS CEREBRALES'},
{ codigo: 'I635', descripcion: 'INFARTO CEREBRAL DEBIDO A OCLUSION O ESTENOSIS NO ESPECIFICADA DE ARTERIAS CEREBRALES'},
{ codigo: 'I636', descripcion: 'INFARTO CEREBRAL DEBIDO A TROMBOSIS DE VENAS CEREBRALES, NO PIOGENO'},
{ codigo: 'I638', descripcion: 'OTROS INFARTOS CEREBRALES'},
{ codigo: 'I639', descripcion: 'INFARTO CEREBRAL, NO ESPECIFICADO'},
{ codigo: 'I64', descripcion: 'ACC VASCULAR ENCEFALICO AGUDO, NO ESPEC COMO HEMORRAGICO O ISQUEMICO'},
{ codigo: 'I65', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIAS PRECEREB SIN OCASIONAR INFARTO CEREBRAL'},
{ codigo: 'I650', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIA VERTEBRAL'},
{ codigo: 'I651', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIA BASILAR'},
{ codigo: 'I652', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIA CAROTIDA'},
{ codigo: 'I653', descripcion: 'OCLUSION Y ESTENOSIS MULTIPLE BILATERAL DE ARTERIAS PRECEREBRALES'},
{ codigo: 'I658', descripcion: 'OCLUSION Y ESTENOSIS DE OTRAS ARTERIAS PRECEREBRALES'},
{ codigo: 'I659', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIA PRECEREBRAL NO ESPECIFICADA'},
{ codigo: 'I66', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIAS CEREB SIN OCASIONAR INFARTO CEREBRAL'},
{ codigo: 'I660', descripcion: 'OCLUSION Y ESTENOSIS DE LA ARTERIA CEREBRAL MEDIA'},
{ codigo: 'I661', descripcion: 'OCLUSION Y ESTENOSIS DE LA ARTERIA CEREBRAL ANTERIOR'},
{ codigo: 'I662', descripcion: 'OCLUSION Y ESTENOSIS DE LA ARTERIA CEREBRAL POSTERIOR'},
{ codigo: 'I663', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIAS CEREBELOSAS'},
{ codigo: 'I664', descripcion: 'OCLUSION Y ESTENOSIS MULTIPLE BILATERAL DE ARTERIAS CEREBRALES'},
{ codigo: 'I668', descripcion: 'OCLUSION Y ESTENOSIS DE OTRAS ARTERIAS CEREBRALES'},
{ codigo: 'I669', descripcion: 'OCLUSION Y ESTENOSIS DE ARTERIA CEREBRAL NO ESPECIFICADA'},
{ codigo: 'I67', descripcion: 'OTRAS ENFERMEDADES CEREBROVASCULARES'},
{ codigo: 'I670', descripcion: 'DISECCION DE ARTERIAS CEREBRALES, SIN RUPTURA'},
{ codigo: 'I671', descripcion: 'ANEURISMA CEREBRAL, SIN RUPTURA'},
{ codigo: 'I672', descripcion: 'ATEROSCLEROSIS CEREBRAL'},
{ codigo: 'I673', descripcion: 'LEUCOENCEFALOPATIA VASCULAR PROGRESIVA'},
{ codigo: 'I674', descripcion: 'ENCEFALOPATIA HIPERTENSIVA'},
{ codigo: 'I675', descripcion: 'ENFERMEDAD DE MOYAMOYA'},
{ codigo: 'I676', descripcion: 'TROMBOSIS APIOGENA DEL SISTEMA VENOSO INTRACRANEAL'},
{ codigo: 'I677', descripcion: 'ARTERITIS CEREBRAL, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'I678', descripcion: 'OTRAS ENFERMEDADES CEREBROVASCULARES ESPECIFICADAS'},
{ codigo: 'I679', descripcion: 'ENFERMEDAD CEREBROVASCULAR, NO ESPECIFICADA'},
{ codigo: 'I68', descripcion: 'TRASTORNOS CEREBROVASCULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I680', descripcion: 'ANGIOPATIA CEREBRAL AMILOIDE (E85.-+)'},
{ codigo: 'I681', descripcion: 'ARTERITIS CEREBRAL EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I682', descripcion: 'ARTERITIS CEREBRAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I688', descripcion: 'OTROS TRASTORNOS CEREBROVASCULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I69', descripcion: 'SECUELAS DE ENFERMEDAD CEREBROVASCULAR'},
{ codigo: 'I690', descripcion: 'SECUELAS DE HEMORRAGIA SUBARACNOIDEA'},
{ codigo: 'I691', descripcion: 'SECUELAS DE HEMORRAGIA INTRAENCEFALICA'},
{ codigo: 'I692', descripcion: 'SECUELAS DE OTRAS HEMORRAGIAS INTRACRANEALES NO TRAUMATICAS'},
{ codigo: 'I693', descripcion: 'SECUELAS DE INFARTO CEREBRAL'},
{ codigo: 'I694', descripcion: 'SECUELAS DE ENFERMEDAD CEREBROVASCULAR, NO ESPECIFICADA COMO HEMORRAGICA U OCLUSIVA'},
{ codigo: 'I698', descripcion: 'SECUELAS DE OTRAS ENFERMEDADES CEREBROVASCULARES Y DE LAS NO ESPECIFICADAS'},
{ codigo: 'I70', descripcion: 'ATEROSCLEROSIS'},
{ codigo: 'I700', descripcion: 'ATEROSCLEROSIS DE LA AORTA'},
{ codigo: 'I701', descripcion: 'ATEROSCLEROSIS DE LA ARTERIA RENAL'},
{ codigo: 'I702', descripcion: 'ATEROSCLEROSIS DE LAS ARTERIAS DE LOS MIEMBROS'},
{ codigo: 'I708', descripcion: 'ATEROSCLEROSIS DE OTRAS ARTERIAS'},
{ codigo: 'I709', descripcion: 'ATEROSCLEROSIS GENERALIZADA Y LA NO ESPECIFICADA'},
{ codigo: 'I71', descripcion: 'ANEURISMA Y DISECCION AORTICOS'},
{ codigo: 'I710', descripcion: 'DISECCION DE AORTA (CUALQUIER PARTE)'},
{ codigo: 'I711', descripcion: 'RUPTURA DE ANEURISMA DE LA AORTA TORACICA'},
{ codigo: 'I712', descripcion: 'ANEURISMA DE LA AORTA TORACICA, SIN MENCION DE RUPTURA'},
{ codigo: 'I713', descripcion: 'RUPTURA DE ANEURISMA DE LA AORTA ABDOMINAL'},
{ codigo: 'I714', descripcion: 'ANEURISMA DE LA AORTA ABDOMINAL, SIN MENCION DE RUPTURA'},
{ codigo: 'I715', descripcion: 'RUPTURA DE ANEURISMA DE LA AORTA TORACOABDOMINAL'},
{ codigo: 'I716', descripcion: 'ANEURISMA DE LA AORTA TORACOABDOMINAL, SIN MENCION DE RUPTURA'},
{ codigo: 'I718', descripcion: 'RUPTURA DE ANEURISMA AORTICO, SITIO NO ESPECIFICADO'},
{ codigo: 'I719', descripcion: 'ANEURISMA DE LA AORTA, SITIO NO ESPECIFICADO, SIN MENCION DE RUPTURA'},
{ codigo: 'I72', descripcion: 'OTROS ANEURISMAS'},
{ codigo: 'I720', descripcion: 'ANEURISMA DE LA ARTERIA CAROTIDA'},
{ codigo: 'I721', descripcion: 'ANEURISMA DE ARTERIA DEL MIEMBRO SUPERIOR'},
{ codigo: 'I722', descripcion: 'ANEURISMA DE ARTERIA RENAL'},
{ codigo: 'I723', descripcion: 'ANEURISMA DE ARTERIA ILIACA'},
{ codigo: 'I724', descripcion: 'ANEURISMA DE ARTERIA DEL MIEMBRO INFERIOR'},
{ codigo: 'I728', descripcion: 'ANEURISMA DE OTRAS ARTERIAS ESPECIFICADAS'},
{ codigo: 'I729', descripcion: 'ANEURISMA DE SITIO NO ESPECIFICADO'},
{ codigo: 'I73', descripcion: 'OTRAS ENFERMEDADES VASCULARES PERIFERICAS'},
{ codigo: 'I730', descripcion: 'SINDROME DE RAYNAUD'},
{ codigo: 'I731', descripcion: 'TROMBOANGEITIS OBLITERANTE (BUERGER)'},
{ codigo: 'I738', descripcion: 'OTRAS ENFERMEDADES VASCULARES PERIFERICAS ESPECIFICADAS'},
{ codigo: 'I739', descripcion: 'ENFERMEDAD VASCULAR PERIFERICA, NO ESPECIFICADA'},
{ codigo: 'I74', descripcion: 'EMBOLIA Y TROMBOSIS ARTERIALES'},
{ codigo: 'I740', descripcion: 'EMBOLIA Y TROMBOSIS DE LA AORTA ABDOMINAL'},
{ codigo: 'I741', descripcion: 'EMBOLIA Y TROMBOSIS DE OTRAS PORCIONES Y LAS NO ESPECIFICADAS DE LA AORTA'},
{ codigo: 'I742', descripcion: 'EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS SUPERIORES'},
{ codigo: 'I743', descripcion: 'EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS INFERIORES'},
{ codigo: 'I744', descripcion: 'EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS, NO ESPECIFICADAS'},
{ codigo: 'I745', descripcion: 'EMBOLIA Y TROMBOSIS DE ARTERIA ILIACA'},
{ codigo: 'I748', descripcion: 'EMBOLIA Y TROMBOSIS DE OTRAS ARTERIAS'},
{ codigo: 'I749', descripcion: 'EMBOLIA Y TROMBOSIS DE ARTERIA NO ESPECIFICADA'},
{ codigo: 'I77', descripcion: 'OTROS TRASTORNOS ARTERIALES O ARTERIOLARES'},
{ codigo: 'I770', descripcion: 'FISTULA ARTERIOVENOSA, ADQUIRIDA'},
{ codigo: 'I771', descripcion: 'ESTRECHEZ ARTERIAL'},
{ codigo: 'I772', descripcion: 'RUPTURA ARTERIAL'},
{ codigo: 'I773', descripcion: 'DISPLASIA FIBROMUSCULAR ARTERIAL'},
{ codigo: 'I774', descripcion: 'SINDROME DE COMPRESION DEL TRONCO CELIACO'},
{ codigo: 'I775', descripcion: 'NECROSIS ARTERIAL'},
{ codigo: 'I776', descripcion: 'ARTERITIS, NO ESPECIFICADA'},
{ codigo: 'I778', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE ARTERIAS Y ARTERIOLAS'},
{ codigo: 'I779', descripcion: 'TRASTORNO DE ARTERIAS Y ARTERIOLAS, NO ESPECIFICADO'},
{ codigo: 'I78', descripcion: 'ENFERMEDADES DE LOS VASOS CAPILARES'},
{ codigo: 'I780', descripcion: 'TELANGIECTASIA HEMORRAGICA HEREDITARIA'},
{ codigo: 'I781', descripcion: 'NEVO, NO NEOPLASICO'},
{ codigo: 'I788', descripcion: 'OTRAS ENFERMEDADES DE LOS CAPILARES'},
{ codigo: 'I789', descripcion: 'ENFERMEDAD DE LOS VASOS CAPILARES, NO ESPECIFICADA'},
{ codigo: 'I79', descripcion: 'TRASTORNOS DE ARTERIAS, DE ARTERIOLAS Y DE VASOS CAPILARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I790', descripcion: 'ANEURISMA DE LA AORTA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I791', descripcion: 'AORTITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I792', descripcion: 'ANGIOPATIA PERIFERICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I798', descripcion: 'OTROS TRASTORNOS DE ARTERIAS, ARTERIOLAS Y VASOS CAPILARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I80', descripcion: 'FLEBITIS Y TROMBOFLEBITIS'},
{ codigo: 'I800', descripcion: 'FLEBITIS Y TROMBOFLEBITIS VASOS SUPERFICIALES DE MIEMBROS INFERIORES'},
{ codigo: 'I801', descripcion: 'FLEBITIS Y TROMBOFLEBITIS DE LA VENA FEMORAL'},
{ codigo: 'I802', descripcion: 'FLEBITIS Y TROMBOFLEBITIS DE OTROS VASOS PROFUNDOS MIEMBROS INFERIORES'},
{ codigo: 'I803', descripcion: 'FLEBITIS Y TROMBOFLEBITIS DE LOS MIEMBROS INFERIORES, NO ESPECIFICADA'},
{ codigo: 'I808', descripcion: 'FLEBITIS Y TROMBOFLEBITIS DE OTROS SITIOS'},
{ codigo: 'I809', descripcion: 'FLEBITIS Y TROMBOFLEBITIS DE SITIO NO ESPECIFICADO'},
{ codigo: 'I81', descripcion: 'TROMBOSIS DE LA VENA PORTA'},
{ codigo: 'I82', descripcion: 'OTRAS EMBOLIAS Y TROMBOSIS VENOSAS'},
{ codigo: 'I820', descripcion: 'SINDROME DE BUDD-CHIARI'},
{ codigo: 'I821', descripcion: 'TROMBOFLEBITIS MIGRATORIA'},
{ codigo: 'I822', descripcion: 'EMBOLIA Y TROMBOSIS DE VENA CAVA'},
{ codigo: 'I823', descripcion: 'EMBOLIA Y TROMBOSIS DE VENA RENAL'},
{ codigo: 'I828', descripcion: 'EMBOLIA Y TROMBOSIS DE OTRAS VENAS ESPECIFICADAS'},
{ codigo: 'I829', descripcion: 'EMBOLIA Y TROMBOSIS DE VENA NO ESPECIFICADA'},
{ codigo: 'I83', descripcion: 'VENAS VARICOSAS DE LOS MIEMBROS INFERIORES'},
{ codigo: 'I830', descripcion: 'VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON ULCERA'},
{ codigo: 'I831', descripcion: 'VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON INFLAMACION'},
{ codigo: 'I832', descripcion: 'VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON ULCERA E INFLAMACION'},
{ codigo: 'I839', descripcion: 'VENAS VARICOSAS DE LOS MIEMBROS INFERIORES SIN ULCERA NI INFLAMACION'},
{ codigo: 'I84', descripcion: 'HEMORROIDES'},
{ codigo: 'I840', descripcion: 'HEMORROIDES INTERNAS TROMBOSADAS'},
{ codigo: 'I841', descripcion: 'HEMORROIDES INTERNAS CON OTRAS COMPLICACIONES'},
{ codigo: 'I842', descripcion: 'HEMORROIDES INTERNAS SIN COMPLICACION'},
{ codigo: 'I843', descripcion: 'HEMORROIDES EXTERNAS TROMBOSADAS'},
{ codigo: 'I844', descripcion: 'HEMORROIDES EXTERNAS CON OTRAS COMPLICACIONES'},
{ codigo: 'I845', descripcion: 'HEMORROIDES EXTERNAS SIN COMPLICACION'},
{ codigo: 'I846', descripcion: 'PROMINENCIAS CUTANEAS, RESIDUO DE HEMORROIDES'},
{ codigo: 'I847', descripcion: 'HEMORROIDES TROMBOSADAS NO ESPECIFICADAS'},
{ codigo: 'I848', descripcion: 'HEMORROIDES NO ESPECIFICADAS, CON OTRAS COMPLICACIONES'},
{ codigo: 'I849', descripcion: 'HEMORROIDES NO ESPECIFICADAS, SIN COMPLICACION'},
{ codigo: 'I85', descripcion: 'VARICES ESOFAGICAS'},
{ codigo: 'I850', descripcion: 'VARICES ESOFAGICAS CON HEMORRAGIA'},
{ codigo: 'I859', descripcion: 'VARICES ESOFAGICAS SIN HEMORRAGIA'},
{ codigo: 'I86', descripcion: 'VARICES DE OTROS SITIOS'},
{ codigo: 'I860', descripcion: 'VARICES SUBLINGUALES'},
{ codigo: 'I861', descripcion: 'VARICES ESCROTALES'},
{ codigo: 'I862', descripcion: 'VARICES PELVICAS'},
{ codigo: 'I863', descripcion: 'VARICES DE LA VULVA'},
{ codigo: 'I864', descripcion: 'VARICES GASTRICAS'},
{ codigo: 'I868', descripcion: 'VARICES EN OTROS SITIOS ESPECIFICADOS'},
{ codigo: 'I87', descripcion: 'OTROS TRASTORNOS DE LAS VENAS'},
{ codigo: 'I870', descripcion: 'SINDROME POSTFLEBITICO'},
{ codigo: 'I871', descripcion: 'COMPRESION DE VENA'},
{ codigo: 'I872', descripcion: 'INSUFICIENCIA VENOSA (CRONICA) (PERIFERICA)'},
{ codigo: 'I878', descripcion: 'OTROS TRASTORNOS VENOSOS ESPECIFICADOS'},
{ codigo: 'I879', descripcion: 'TRASTORNO VENOSO, NO ESPECIFICADO'},
{ codigo: 'I88', descripcion: 'LINFADENITIS INESPECIFICA'},
{ codigo: 'I880', descripcion: 'LINFADENITIS MESENTERICA INESPECIFICA'},
{ codigo: 'I881', descripcion: 'LINFADENITIS CRONICA, EXCEPTO LA MESENTERICA'},
{ codigo: 'I888', descripcion: 'OTRAS LINFADENITIS INESPECIFICAS'},
{ codigo: 'I889', descripcion: 'LINFADENITIS INESPECIFICA NO ESPECIFICADA'},
{ codigo: 'I89', descripcion: 'OTROS TRASTORNOS NO INFECCIOSOS DE LOS VASOS Y GANGLIOS LINFATICOS'},
{ codigo: 'I890', descripcion: 'LINFEDEMA, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'I891', descripcion: 'LINFANGITIS'},
{ codigo: 'I898', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS NO INFECCIOSOS DE LOS VASOS Y GANGLIOS LINFATICOS'},
{ codigo: 'I899', descripcion: 'TRASTORNO NO INFECCIOSO DE VASOS Y GANGLIOS LINFATICOS, NO ESPECIFICADO'},
{ codigo: 'I95', descripcion: 'HIPOTENSION'},
{ codigo: 'I950', descripcion: 'HIPOTENSION IDIOPATICA'},
{ codigo: 'I951', descripcion: 'HIPOTENSION ORTOSTATICA'},
{ codigo: 'I952', descripcion: 'HIPOTENSION DEBIDA A DROGAS'},
{ codigo: 'I958', descripcion: 'OTROS TIPOS DE HIPOTENSION'},
{ codigo: 'I959', descripcion: 'HIPOTENSION, NO ESPECIFICADA'},
{ codigo: 'I97', descripcion: 'TRASTORNOS DEL SISTEMA CIRCULATORIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I970', descripcion: 'SINDROME DE POSTCARDIOTOMIA'},
{ codigo: 'I971', descripcion: 'OTRAS ALTERACIONES FUNCIONALES CONSECUTIVAS A CIRUGIA CARDIACA'},
{ codigo: 'I972', descripcion: 'SINDROME DE LINFEDEMA POSTMASTECTOMIA'},
{ codigo: 'I978', descripcion: 'OTROS TRASTORNOS SISTEMA CIRCULATORIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I979', descripcion: 'TRASTORNO NO ESPECIFICADO DEL SISTEMA CIRCULATORIO CONSECUTIVO A PROCEDIMIENTO'},
{ codigo: 'I98', descripcion: 'OTROS TRASTORNOS DEL SISTEMA CIRCULATORIO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I980', descripcion: 'SIFILIS CARDIOVASCULAR'},
{ codigo: 'I981', descripcion: 'TRASTORNOS CARDIOVASCULARES EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I982', descripcion: 'VARICES ESOFAGICAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I988', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL APARATO CIRCULATORIO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'I99', descripcion: 'OTROS TRASTORNOS Y LOS NO ESPECIFICADOS DEL SISTEMA CIRCULATORIO'},
{ codigo: 'J00', descripcion: 'RINOFARINGITIS AGUDA (RESFRIADO COMUN)'},
{ codigo: 'J01', descripcion: 'SINUSITIS AGUDA'},
{ codigo: 'J010', descripcion: 'SINUSITIS MAXILAR AGUDA'},
{ codigo: 'J011', descripcion: 'SINUSITIS FRONTAL AGUDA'},
{ codigo: 'J012', descripcion: 'SINUSITIS ETMOIDAL AGUDA'},
{ codigo: 'J013', descripcion: 'SINUSITIS ESFENOIDAL AGUDA'},
{ codigo: 'J014', descripcion: 'PANSINUSITIS AGUDA'},
{ codigo: 'J018', descripcion: 'OTRAS SINUSITIS AGUDAS'},
{ codigo: 'J019', descripcion: 'SINUSITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'J02', descripcion: 'FARINGITIS AGUDA'},
{ codigo: 'J020', descripcion: 'FARINGITIS ESTREPTOCOCICA'},
{ codigo: 'J028', descripcion: 'FARINGITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS'},
{ codigo: 'J029', descripcion: 'FARINGITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'J03', descripcion: 'AMIGDALITIS AGUDA'},
{ codigo: 'J030', descripcion: 'AMIGDALITIS ESTREPTOCOCICA'},
{ codigo: 'J038', descripcion: 'AMIGDALITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS'},
{ codigo: 'J039', descripcion: 'AMIGDALITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'J04', descripcion: 'LARINGITIS Y TRAQUEITIS AGUDAS'},
{ codigo: 'J040', descripcion: 'LARINGITIS AGUDA'},
{ codigo: 'J041', descripcion: 'TRAQUEITIS AGUDA'},
{ codigo: 'J042', descripcion: 'LARINGOTRAQUEITIS AGUDA'},
{ codigo: 'J05', descripcion: 'LARINGITIS OBSTRUCTIVA AGUDA (CRUP) Y EPIGLOTITI'},
{ codigo: 'J050', descripcion: 'LARINGITIS OBSTRUCTIVA, AGUDA (CRUP)'},
{ codigo: 'J051', descripcion: 'EPIGLOTITIS AGUDA'},
{ codigo: 'J06', descripcion: 'INFECCIONES AGUDAS DE LAS VIAS RESPIRATORIAS SUPERIORES, DE SITIOS MULTIPLES O NO ESPECIFICADOS'},
{ codigo: 'J060', descripcion: 'LARINGOFARINGITIS AGUDA'},
{ codigo: 'J068', descripcion: 'OTRAS INFECCIONES AGUDAS DE SITIOS MULTIPLES DE VIAS RESPIRATORIAS SUPERIORES'},
{ codigo: 'J069', descripcion: 'INFECCION AGUDA DE LAS VIAS RESPIRATORIAS SUPERIORES, NO ESPECIFICADA'},
{ codigo: 'J10', descripcion: 'INFLUENZA DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO'},
{ codigo: 'J100', descripcion: 'INFLUENZA CON NEUMONIA, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO'},
{ codigo: 'J101', descripcion: 'INFLUENZA CON OTRAS MANIFESTAC RESPIRATORIAS, POR VIRUS INFLUENZA IDENTIFICADO'},
{ codigo: 'J108', descripcion: 'INFLUENZA, CON OTRAS MANIFESTACIONES, POR VIRUS DE INFLUENZA IDENTIFICADO'},
{ codigo: 'J11', descripcion: 'INFLUENZA DEBIDA A VIRUS NO IDENTIFICADO'},
{ codigo: 'J110', descripcion: 'INFLUENZA CON NEUMONIA, VIRUS NO IDENTIFICADO'},
{ codigo: 'J111', descripcion: 'INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, VIRUS NO IDENTIFICADO'},
{ codigo: 'J118', descripcion: 'INFLUENZA CON OTRAS MANIFESTACIONES, VIRUS NO IDENTIFICADO'},
{ codigo: 'J12', descripcion: 'NEUMONIA VIRAL, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'J120', descripcion: 'NEUMONIA DEBIDA A ADENOVIRUS'},
{ codigo: 'J121', descripcion: 'NEUMONIA DEBIDA A VIRUS SINCITIAL RESPIRATORIO'},
{ codigo: 'J122', descripcion: 'NEUMONIA DEBIDA A VIRUS PARAINFLUENZA'},
{ codigo: 'J128', descripcion: 'NEUMONIA DEBIDA A OTROS VIRUS'},
{ codigo: 'J129', descripcion: 'NEUMONIA VIRAL, NO ESPECIFICADA'},
{ codigo: 'J13', descripcion: 'NEUMONIA DEBIDA A STREPTOCOCCUS PNEUMONIAE'},
{ codigo: 'J14', descripcion: 'NEUMONIA DEBIDA A HAEMOPHILUS INFLUENZAE'},
{ codigo: 'J15', descripcion: 'NEUMONIA BACTERIANA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'J150', descripcion: 'NEUMONIA DEBIDA A KLEBSIELLA PNEUMONIAE'},
{ codigo: 'J151', descripcion: 'NEUMONIA DEBIDA A PSEUDOMONAS'},
{ codigo: 'J152', descripcion: 'NEUMONIA DEBIDA A ESTAFILOCOCOS'},
{ codigo: 'J153', descripcion: 'NEUMONIA DEBIDA A ESTREPTOCOCOS DEL GRUPO B'},
{ codigo: 'J154', descripcion: 'NEUMONIA DEBIDA A OTROS ESTREPTOCOCOS'},
{ codigo: 'J155', descripcion: 'NEUMONIA DEBIDA A ESCHERICHIA COLI'},
{ codigo: 'J156', descripcion: 'NEUMONIA DEBIDA A OTRAS BACTERIAS AEROBICAS GRAMNEGATIVAS'},
{ codigo: 'J157', descripcion: 'NEUMONIA DEBIDA A MYCOPLASMA PNEUMONIAE'},
{ codigo: 'J158', descripcion: 'OTRAS NEUMONIAS BACTERIANAS'},
{ codigo: 'J159', descripcion: 'NEUMONIA BACTERIANA, NO ESPECIFICADA'},
{ codigo: 'J16', descripcion: 'NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J160', descripcion: 'NEUMONIA DEBIDA A CLAMIDIAS'},
{ codigo: 'J168', descripcion: 'NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS ESPECIFICADOS'},
{ codigo: 'J17', descripcion: 'NEUMONIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J170', descripcion: 'NEUMONIA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J171', descripcion: 'NEUMONIA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J172', descripcion: 'NEUMONIA EN MICOSIS'},
{ codigo: 'J173', descripcion: 'NEUMONIA EN ENFERMEDADES PARASITARIAS'},
{ codigo: 'J178', descripcion: 'NEUMONIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J18', descripcion: 'NEUMONIA, ORGANISMO NO ESPECIFICADO'},
{ codigo: 'J180', descripcion: 'BRONCONEUMONIA, NO ESPECIFICADA'},
{ codigo: 'J181', descripcion: 'NEUMONIA LOBAR, NO ESPECIFICADA'},
{ codigo: 'J182', descripcion: 'NEUMONIA HIPOSTATICA, NO ESPECIFICADA'},
{ codigo: 'J188', descripcion: 'OTRAS NEUMONIAS, DE MICROORGANISMO NO ESPECIFICADO'},
{ codigo: 'J189', descripcion: 'NEUMONIA, NO ESPECIFICADA'},
{ codigo: 'J20', descripcion: 'BRONQUITIS AGUDA'},
{ codigo: 'J200', descripcion: 'BRONQUITIS AGUDA DEBIDA A MYCOPLASMA PNEUMONIAE'},
{ codigo: 'J201', descripcion: 'BRONQUITIS AGUDA DEBIDA A HAEMOPHILUS INFLUENZAE'},
{ codigo: 'J202', descripcion: 'BRONQUITIS AGUDA DEBIDA A ESTREPTOCOCOS'},
{ codigo: 'J203', descripcion: 'BRONQUITIS AGUDA DEBIDA A VIRUS COXSACKIE'},
{ codigo: 'J204', descripcion: 'BRONQUITIS AGUDA DEBIDA A VIRUS PARAINFLUENZA'},
{ codigo: 'J205', descripcion: 'BRONQUITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO'},
{ codigo: 'J206', descripcion: 'BRONQUITIS AGUDA DEBIDA A RINOVIRUS'},
{ codigo: 'J207', descripcion: 'BRONQUITIS AGUDA DEBIDA A VIRUS ECHO'},
{ codigo: 'J208', descripcion: 'BRONQUITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS'},
{ codigo: 'J209', descripcion: 'BRONQUITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'J21', descripcion: 'BRONQUIOLITIS AGUDA'},
{ codigo: 'J210', descripcion: 'BRONQUIOLITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO'},
{ codigo: 'J218', descripcion: 'BRONQUIOLITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS'},
{ codigo: 'J219', descripcion: 'BRONQUIOLITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'J22', descripcion: 'INFECCION AGUDA NO ESPECIFICADA DE LAS VIAS RESPIRATORIAS INFERIORES'},
{ codigo: 'J30', descripcion: 'RINITIS ALERGICA Y VASOMOTORA'},
{ codigo: 'J300', descripcion: 'RINITIS VASOMOTORA'},
{ codigo: 'J301', descripcion: 'RINITIS ALERGICA DEBIDA AL POLEN'},
{ codigo: 'J302', descripcion: 'OTRA RINITIS ALERGICA ESTACIONAL'},
{ codigo: 'J303', descripcion: 'OTRAS RINITIS ALERGICAS'},
{ codigo: 'J304', descripcion: 'RINITIS ALERGICA, NO ESPECIFICADA'},
{ codigo: 'J31', descripcion: 'RINITIS, RINOFARINGITIS Y FARINGITIS CRONICAS'},
{ codigo: 'J310', descripcion: 'RINITIS CRONICA'},
{ codigo: 'J311', descripcion: 'RINOFARINGITIS CRONICA'},
{ codigo: 'J312', descripcion: 'FARINGITIS CRONICA'},
{ codigo: 'J32', descripcion: 'SINUSITIS CRONICA'},
{ codigo: 'J320', descripcion: 'SINUSITIS MAXILAR CRONICA'},
{ codigo: 'J321', descripcion: 'SINUSITIS FRONTAL CRONICA'},
{ codigo: 'J322', descripcion: 'SINUSITIS ETMOIDAL CRONICA'},
{ codigo: 'J323', descripcion: 'SINUSITIS ESFENOIDAL CRONICA'},
{ codigo: 'J324', descripcion: 'PANSINUSITIS CRONICA'},
{ codigo: 'J328', descripcion: 'OTRAS SINUSITIS CRONICAS'},
{ codigo: 'J329', descripcion: 'SINUSITIS CRONICA, NO ESPECIFICADA'},
{ codigo: 'J33', descripcion: 'POLIPO NASAL'},
{ codigo: 'J330', descripcion: 'POLIPO DE LA CAVIDAD NASAL'},
{ codigo: 'J331', descripcion: 'DEGENERACION POLIPOIDE DE SENO PARANASAL'},
{ codigo: 'J338', descripcion: 'OTROS POLIPOS DE LOS SENOS PARANASALES'},
{ codigo: 'J339', descripcion: 'POLIPO NASAL, NO ESPECIFICADO'},
{ codigo: 'J34', descripcion: 'OTROS TRASTORNOS DE LA NARIZ Y DE LOS SENOS PARANASALES'},
{ codigo: 'J340', descripcion: 'ABSCESO, FURUNCULO Y CARBUNCO DE LA NARIZ'},
{ codigo: 'J341', descripcion: 'QUISTE Y MUCOCELE DE SENO PARANASAL'},
{ codigo: 'J342', descripcion: 'DESVIACION DEL TABIQUE NASAL'},
{ codigo: 'J343', descripcion: 'HIPERTROFIA DE LOS CORNETES NASALES'},
{ codigo: 'J348', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA NARIZ Y DE LOS SENOS PARANASALES'},
{ codigo: 'J35', descripcion: 'ENFERMEDADES CRONICAS DE LAS AMIGDALAS Y DE LAS ADENOIDES'},
{ codigo: 'J350', descripcion: 'AMIGDALITIS CRONICA'},
{ codigo: 'J351', descripcion: 'HIPERTROFIA DE LAS AMIGDALAS'},
{ codigo: 'J352', descripcion: 'HIPERTROFIA DE LAS ADENOIDES'},
{ codigo: 'J353', descripcion: 'HIPERTROFIA DE LAS AMIGDALAS CON HIPERTROFIA DE LAS ADENOIDES'},
{ codigo: 'J358', descripcion: 'OTRAS ENFERMEDADES CRONICAS DE LAS AMIGDALAS Y DE LAS ADENOIDES'},
{ codigo: 'J359', descripcion: 'ENFERMEDAD CRONICA DE LAS AMIGDALAS Y DE LAS ADENOIDES, NO ESPECIFICADA'},
{ codigo: 'J36', descripcion: 'ABSCESO PERIAMIGDALINO'},
{ codigo: 'J37', descripcion: 'LARINGITIS Y LARINGOTRAQUEITIS CRONICAS'},
{ codigo: 'J370', descripcion: 'LARINGITIS CRONICA'},
{ codigo: 'J371', descripcion: 'LARINGOTRAQUEITIS CRONICA'},
{ codigo: 'J38', descripcion: 'ENFERMEDADES DE LAS CUERDAS VOCALES Y DE LA LARINGE, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J380', descripcion: 'PARALISIS DE LAS CUERDAS VOCALES Y DE LA LARINGE'},
{ codigo: 'J381', descripcion: 'POLIPO DE LAS CUERDAS VOCALES Y DE LA LARINGE'},
{ codigo: 'J382', descripcion: 'NODULOS DE LAS CUERDAS VOCALES'},
{ codigo: 'J383', descripcion: 'OTRAS ENFERMEDADES DE LAS CUERDAS VOCALES'},
{ codigo: 'J384', descripcion: 'EDEMA DE LARINGE'},
{ codigo: 'J385', descripcion: 'ESPASMO LARINGEO'},
{ codigo: 'J386', descripcion: 'ESTENOSIS LARINGEA'},
{ codigo: 'J387', descripcion: 'OTRAS ENFERMEDADES DE LA LARINGE'},
{ codigo: 'J39', descripcion: 'OTRAS ENFERMEDADES DE LAS VIAS RESPIRATORIAS SUPERIORES'},
{ codigo: 'J390', descripcion: 'ABSCESO RETROFARINGEO Y PARAFARINGEO'},
{ codigo: 'J391', descripcion: 'OTROS ABSCESOS DE LA FARINGE'},
{ codigo: 'J392', descripcion: 'OTRAS ENFERMEDADES DE LA FARINGE'},
{ codigo: 'J393', descripcion: 'REACCION DE HIPERSENSIBILIDAD VIAS RESPIRATORIAS SUPERIORES, SITIO NO ESPECIFADO'},
{ codigo: 'J398', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LAS VIAS RESPIRATORIAS SUPERIORES'},
{ codigo: 'J399', descripcion: 'ENFERMEDAD DE LAS VIAS RESPIRATORIAS SUPERIORES, NO ESPECIFICADA'},
{ codigo: 'J40', descripcion: 'BRONQUITIS, NO ESPECIFICADA COMO AGUDA O CRONICA'},
{ codigo: 'J41', descripcion: 'BRONQUITIS CRONICA SIMPLE Y MUCOPURULENTA'},
{ codigo: 'J410', descripcion: 'BRONQUITIS CRONICA SIMPLE'},
{ codigo: 'J411', descripcion: 'BRONQUITIS CRONICA MUCOPURULENTA'},
{ codigo: 'J418', descripcion: 'BRONQUITIS CRONICA MIXTA SIMPLE Y MUCOPURULENTA'},
{ codigo: 'J42', descripcion: 'BRONQUITIS CRONICA NO ESPECIFICADA'},
{ codigo: 'J43', descripcion: 'ENFISEMA'},
{ codigo: 'J430', descripcion: 'SINDROME DE MACLEOD'},
{ codigo: 'J431', descripcion: 'ENFISEMA PANLOBULAR'},
{ codigo: 'J432', descripcion: 'ENFISEMA CENTROLOBULAR'},
{ codigo: 'J438', descripcion: 'OTROS TIPOS DE ENFISEMA'},
{ codigo: 'J439', descripcion: 'ENFISEMA, NO ESPECIFICADO'},
{ codigo: 'J44', descripcion: 'OTRAS ENFERMEDADES PULMONARES OBSTRUCTIVAS CRONICAS'},
{ codigo: 'J440', descripcion: 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA CON INFECCION AGUDA DE LAS VIAS RESPIRATORIAS INFERIORES'},
{ codigo: 'J441', descripcion: 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA CON EXACERBACION AGUDA, NO ESPECIFICADA'},
{ codigo: 'J448', descripcion: 'OTRAS ENFERMEDADES PULMONARES OBSTRUCTIVAS CRONICAS ESPECIFICADAS'},
{ codigo: 'J449', descripcion: 'ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA, NO ESPECIFICADA'},
{ codigo: 'J45', descripcion: 'ASMA'},
{ codigo: 'J450', descripcion: 'ASMA PREDOMINANTEMENTE ALERGICA'},
{ codigo: 'J451', descripcion: 'ASMA NO ALERGICA'},
{ codigo: 'J458', descripcion: 'ASMA MIXTA'},
{ codigo: 'J459', descripcion: 'ASMA, NO ESPECIFICADA'},
{ codigo: 'J46', descripcion: 'ESTADO ASMATICO'},
{ codigo: 'J47', descripcion: 'BRONQUIECTASIA'},
{ codigo: 'J60', descripcion: 'NEUMOCONIOSIS DE LOS MINEROS DEL CARBON'},
{ codigo: 'J61', descripcion: 'NEUMOCONIOSIS DEBIDA AL ASBESTO Y A OTRAS FIBRAS MINERALES'},
{ codigo: 'J62', descripcion: 'NEUMOCONIOSIS DEBIDA A POLVO DE SILICE'},
{ codigo: 'J620', descripcion: 'NEUMOCONIOSIS DEBIDA A POLVO DE TALCO'},
{ codigo: 'J628', descripcion: 'NEUMOCONIOSIS DEBIDA A OTROS POLVOS QUE CONTIENEN SILICE'},
{ codigo: 'J63', descripcion: 'NEUMOCONIOSIS DEBIDA A OTROS POLVOS INORGANICOS'},
{ codigo: 'J630', descripcion: 'ALUMINOSIS (DEL PULMON)'},
{ codigo: 'J631', descripcion: 'FIBROSIS (DEL PULMON) DEBIDA A BAUXITA'},
{ codigo: 'J632', descripcion: 'BERILIOSIS'},
{ codigo: 'J633', descripcion: 'FIBROSIS (DEL PULMON) DEBIDA A GRAFITO'},
{ codigo: 'J634', descripcion: 'SIDEROSIS'},
{ codigo: 'J635', descripcion: 'ESTA—OSIS'},
{ codigo: 'J638', descripcion: 'NEUMOCONIOSIS DEBIDA A OTROS POLVOS INORGANICOS ESPECIFICADOS'},
{ codigo: 'J64', descripcion: 'NEUMOCONIOSIS, NO ESPECIFICADA'},
{ codigo: 'J65', descripcion: 'NEUMOCONIOSIS ASOCIADA CON TUBERCULOSIS'},
{ codigo: 'J66', descripcion: 'ENFERMEDADES DE LAS VIAS AEREAS DEBIDAS A POLVOS ORGANICOS ESPECIFICOS'},
{ codigo: 'J660', descripcion: 'BISINOSIS'},
{ codigo: 'J661', descripcion: 'ENFERMEDAD DE LOS TRABAJADORES DEL LINO'},
{ codigo: 'J662', descripcion: 'CANABINOSIS'},
{ codigo: 'J668', descripcion: 'ENFERMEDAD DE LAS VIAS AEREAS DEBIDA A OTROS POLVOS ORGANICOS ESPECIFICOS'},
{ codigo: 'J67', descripcion: 'NEUMONITIS DEBIDA A HIPERSENSIBILIDAD AL POLVO ORGANICO'},
{ codigo: 'J670', descripcion: 'PULMON DEL GRANJERO'},
{ codigo: 'J671', descripcion: 'BAGAZOSIS'},
{ codigo: 'J672', descripcion: 'PULMON DEL ORNITOFILO'},
{ codigo: 'J673', descripcion: 'SUBEROSIS'},
{ codigo: 'J674', descripcion: 'PULMON DEL MANIPULADOR DE MALTA'},
{ codigo: 'J675', descripcion: 'PULMON DEL MANIPULADOR DE HONGOS'},
{ codigo: 'J676', descripcion: 'PULMON DEL DESCORTEZADOR DEL ARCE'},
{ codigo: 'J677', descripcion: 'NEUMONITIS DE VENTILACION POR ACONDICIONADOR Y HUMIDIFICADOR DEL AIRE'},
{ codigo: 'J678', descripcion: 'NEUMONITIS DEBIDAS A HIPERSENSIBILIDAD A OTROS POLVOS ORGANICOS'},
{ codigo: 'J679', descripcion: 'NEUMONITIS DEBIDA A HIPERSENSIBILIDAD A POLVO ORGANICO NO ESPECIFICADO'},
{ codigo: 'J68', descripcion: 'AFECCIONES RESPIRATORIAS POR INHALACION GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS'},
{ codigo: 'J680', descripcion: 'BRONQUITIS Y NEUMONITIS DEBIDAS A INHALACION GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS'},
{ codigo: 'J681', descripcion: 'EDEMA PULMONAR AGUDO DEBIDO AINHALACION GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS'},
{ codigo: 'J682', descripcion: 'INFLAMACION RESPIRATORIA SUPERIOR DEBIDO A INHALACION GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J683', descripcion: 'OTRAS AFECCIONES RESPIRATORIAS AGUDAS Y SUBAGUDAS DEBIDAS A INHALACION GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS'},
{ codigo: 'J684', descripcion: 'AFECCIONES RESPIRATORIAS CRONICAS DEBIDAS A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS'},
{ codigo: 'J688', descripcion: 'OTRAS AFECCIONES RESPIRATORIAS DEBIDAS A INHALACION GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS'},
{ codigo: 'J689', descripcion: 'AFECCION RESPIRATORIA NO ESPECIFICADA, DEBIDA A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS'},
{ codigo: 'J69', descripcion: 'NEUMONITIS DEBIDA A SOLIDOS Y LIQUIDOS'},
{ codigo: 'J690', descripcion: 'NEUMONITIS DEBIDA A ASPIRACION DE ALIMENTO O VOMITO'},
{ codigo: 'J691', descripcion: 'NEUMONITIS DEBIDA A ASPIRACION DE ACEITES Y ESENCIAS'},
{ codigo: 'J698', descripcion: 'NEUMONITIS DEBIDA A ASPIRACION DE OTROS SOLIDOS Y LIQUIDOS'},
{ codigo: 'J70', descripcion: 'AFECCIONES RESPIRATORIAS DEBIDAS A OTROS AGENTES EXTERNOS'},
{ codigo: 'J700', descripcion: 'MANIFESTACIONES PULMONARES AGUDAS DEBIDAS A RADIACION'},
{ codigo: 'J701', descripcion: 'MANIFESTACIONES PULMONARES CRONICAS Y OTRAS MANIFESTACIONES POR RADIACION'},
{ codigo: 'J702', descripcion: 'TRASTORNOS PULMONARES INTERSTICIALES AGUDOS INDUCIDOS POR DROGAS'},
{ codigo: 'J703', descripcion: 'TRASTORNOS PULMONARES INTERSTICIALES CRONICOS INDUCIDOS POR DROGAS'},
{ codigo: 'J704', descripcion: 'TRASTORNOS PULMONARES INTERSTICIALES NO ESPECIFICADOS INDUCIDOS POR DROGAS'},
{ codigo: 'J708', descripcion: 'AFECCIONES RESPIRATORIAS DEBIDAS A OTROS AGENTES EXTERNOS ESPECIFICADOS'},
{ codigo: 'J709', descripcion: 'AFECCIONES RESPIRATORIAS DEBIDAS A AGENTES EXTERNOS NO ESPECIFICADOS'},
{ codigo: 'J80', descripcion: 'SINDROME DE DIFICULTAD RESPIRATORIA DEL ADULTO'},
{ codigo: 'J81', descripcion: 'EDEMA PULMONAR'},
{ codigo: 'J82', descripcion: 'EOSINOFILIA PULMONAR, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'J84', descripcion: 'OTRAS ENFERMEDADES PULMONARES INTERSTICIALES'},
{ codigo: 'J840', descripcion: 'AFECCIONES ALVEOLARES Y ALVEOLOPARIETALES'},
{ codigo: 'J841', descripcion: 'OTRAS ENFERMEDADES PULMONARES INTERSTICIALES CON FIBROSIS'},
{ codigo: 'J848', descripcion: 'OTRAS ENFERMEDADES PULMONARES INTERSTICIALES ESPECIFICADAS'},
{ codigo: 'J849', descripcion: 'ENFERMEDAD PULMONAR INTERSTICIAL, NO ESPECIFICADA'},
{ codigo: 'J85', descripcion: 'ABSCESO DEL PULMON Y DEL MEDIASTINO'},
{ codigo: 'J850', descripcion: 'GANGRENA Y NECROSIS DEL PULMON'},
{ codigo: 'J851', descripcion: 'ABSCESO DEL PULMON CON NEUMONIA'},
{ codigo: 'J852', descripcion: 'ABSCESO DEL PULMON SIN NEUMONIA'},
{ codigo: 'J853', descripcion: 'ABSCESO DEL MEDIASTINO'},
{ codigo: 'J86', descripcion: 'PIOTORAX'},
{ codigo: 'J860', descripcion: 'PIOTORAX CON FISTULA'},
{ codigo: 'J869', descripcion: 'PIOTORAX SIN FISTULA'},
{ codigo: 'J90', descripcion: 'DERRAME PLEURAL NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'J91', descripcion: 'DERRAME PLEURAL EN AFECCIONES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J92', descripcion: 'PAQUIPLEURITIS'},
{ codigo: 'J920', descripcion: 'PAQUIPLEURITIS CON ASBESTOSIS'},
{ codigo: 'J929', descripcion: 'PAQUIPLEURITIS SIN ASBESTOSIS'},
{ codigo: 'J93', descripcion: 'NEUMOTORAX'},
{ codigo: 'J930', descripcion: 'NEUMOTORAX ESPONTANEO A PRESION'},
{ codigo: 'J931', descripcion: 'OTROS TIPOS DE NEUMOTORAX ESPONTANEO'},
{ codigo: 'J938', descripcion: 'OTROS NEUMOTORAX'},
{ codigo: 'J939', descripcion: 'NEUMOTORAX, NO ESPECIFICADO'},
{ codigo: 'J94', descripcion: 'OTRAS AFECCIONES DE LA PLEURA'},
{ codigo: 'J940', descripcion: 'QUILOTORAX'},
{ codigo: 'J941', descripcion: 'FIBROTORAX'},
{ codigo: 'J942', descripcion: 'HEMOTORAX'},
{ codigo: 'J948', descripcion: 'OTRAS AFECCIONES ESPECIFICADAS DE LA PLEURA'},
{ codigo: 'J949', descripcion: 'AFECCION PLEURAL, NO ESPECIFICADA'},
{ codigo: 'J95', descripcion: 'TRASTORNOS DEL SISTEMA RESPIRATORIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J950', descripcion: 'FUNCIONAMIENTO DEFECTUOSO DE LA TRAQUEOSTOMIA'},
{ codigo: 'J951', descripcion: 'INSUFICIENCIA PULMONAR AGUDA CONSECUTIVA A CIRUGIA TORACICA'},
{ codigo: 'J952', descripcion: 'INSUFICIENCIA PULMONAR AGUDA CONSECUTIVA A CIRUGIA EXTRATORACICA'},
{ codigo: 'J953', descripcion: 'INSUFICIENCIA PULMONAR CRONICA CONSECUTIVA A CIRUGIA'},
{ codigo: 'J954', descripcion: 'SINDROME DE MENDELSON'},
{ codigo: 'J955', descripcion: 'ESTENOSIS SUBGLOTICA CONSECUTIVA A PROCEDIMIENTOS'},
{ codigo: 'J958', descripcion: 'OTROS TRASTORNOS RESPIRATORIOS CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'J959', descripcion: 'TRASTORNO NO ESPECIFICADO DEL SISTEMA RESPIRATORIO, CONSECUTIVO A PROCEDIMIENTO'},
{ codigo: 'J96', descripcion: 'INSUFICIENCIA RESPIRATORIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'J960', descripcion: 'INSUFICIENCIA RESPIRATORIA AGUDA'},
{ codigo: 'J961', descripcion: 'INSUFICIENCIA RESPIRATORIA CRONICA'},
{ codigo: 'J969', descripcion: 'INSUFICIENCIA RESPIRATORIA, NO ESPECIFICADA'},
{ codigo: 'J98', descripcion: 'OTROS TRASTORNOS RESPIRATORIOS'},
{ codigo: 'J980', descripcion: 'ENFERMEDADES DE LA TRAQUEA Y DE LOS BRONQUIOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J981', descripcion: 'COLAPSO PULMONAR'},
{ codigo: 'J982', descripcion: 'ENFISEMA INTERSTICIAL'},
{ codigo: 'J983', descripcion: 'ENFISEMA COMPENSATORIO'},
{ codigo: 'J984', descripcion: 'OTROS TRASTORNOS DEL PULMON'},
{ codigo: 'J985', descripcion: 'ENFERMEDADES DEL MEDIASTINO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J986', descripcion: 'TRASTORNOS DEL DIAFRAGMA'},
{ codigo: 'J988', descripcion: 'OTROS TRASTORNOS RESPIRATORIOS ESPECIFICADOS'},
{ codigo: 'J989', descripcion: 'TRASTORNO RESPIRATORIO, NO ESPECIFICADO'},
{ codigo: 'J99', descripcion: 'TRASTORNOS RESPIRATORIOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'J990', descripcion: 'ENFERMEDAD PULMONAR REUMATOIDE (M05.1+)'},
{ codigo: 'J991', descripcion: 'TRASTORNOS RESPIRATORIOS EN OTROS TRAST DIFUSOS DEL TEJIDO CONJUNTIVO'},
{ codigo: 'J998', descripcion: 'TRASTORNOS RESPIRATORIOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K00', descripcion: 'TRASTORNOS DEL DESARROLLO Y DE LA ERUPCION DE LOS DIENTES'},
{ codigo: 'K000', descripcion: 'ANODONCIA'},
{ codigo: 'K001', descripcion: 'DIENTES SUPERNUMERARIOS'},
{ codigo: 'K002', descripcion: 'ANOMALIAS DEL TAMA—O Y DE LA FORMA DEL DIENTE'},
{ codigo: 'K003', descripcion: 'DIENTES MOTEADOS'},
{ codigo: 'K004', descripcion: 'ALTERACIONES EN LA FORMACION DENTARIA'},
{ codigo: 'K005', descripcion: 'ALTERACIONES HEREDITARIAS DE LA ESTRUCTURA DENTARIA, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K006', descripcion: 'ALTERACIONES EN LA ERUPCION DENTARIA'},
{ codigo: 'K007', descripcion: 'SINDROME DE LA ERUPCION DENTARIA'},
{ codigo: 'K008', descripcion: 'OTROS TRASTORNOS DEL DESARROLLO DE LOS DIENTES'},
{ codigo: 'K009', descripcion: 'TRASTORNO DEL DESARROLLO DE LOS DIENTES, NO ESPECIFICADO'},
{ codigo: 'K01', descripcion: 'DIENTES INCLUIDOS E IMPACTADOS'},
{ codigo: 'K010', descripcion: 'DIENTES INCLUIDOS'},
{ codigo: 'K011', descripcion: 'DIENTES IMPACTADOS'},
{ codigo: 'K02', descripcion: 'CARIES DENTAL'},
{ codigo: 'K020', descripcion: 'CARIES LIMITADA AL ESMALTE'},
{ codigo: 'K021', descripcion: 'CARIES DE LA DENTINA'},
{ codigo: 'K022', descripcion: 'CARIES DEL CEMENTO'},
{ codigo: 'K023', descripcion: 'CARIES DENTARIA DETENIDA'},
{ codigo: 'K024', descripcion: 'ODONTOCLASIA'},
{ codigo: 'K028', descripcion: 'OTRAS CARIES DENTALES'},
{ codigo: 'K029', descripcion: 'CARIES DENTAL, NO ESPECIFICADA'},
{ codigo: 'K03', descripcion: 'OTRAS ENFERMEDADES DE LOS TEJIDOS DUROS DE LOS DIENTES'},
{ codigo: 'K030', descripcion: 'ATRICION EXCESIVA DE LOS DIENTES'},
{ codigo: 'K031', descripcion: 'ABRASION DE LOS DIENTES'},
{ codigo: 'K032', descripcion: 'EROSION DE LOS DIENTES'},
{ codigo: 'K033', descripcion: 'REABSORCION PATOLOGICA DE LOS DIENTES'},
{ codigo: 'K034', descripcion: 'HIPERCEMENTOSIS'},
{ codigo: 'K035', descripcion: 'ANQUILOSIS DENTAL'},
{ codigo: 'K036', descripcion: 'DEPOSITOS [ACRECIONES] EN LOS DIENTES'},
{ codigo: 'K037', descripcion: 'CAMBIOS POSTERUPTIVOS DEL COLOR DE LOS TEJIDOS DENTALES DUROS'},
{ codigo: 'K038', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LOS TEJIDOS DUROS DE LOS DIENTES'},
{ codigo: 'K039', descripcion: 'ENFERMEDAD NO ESPECIFICADA DE LOS TEJIDOS DENTALES DUROS'},
{ codigo: 'K04', descripcion: 'ENFERMEDADES DE LA PULPA Y DE LOS TEJIDOS PERIAPICALES'},
{ codigo: 'K040', descripcion: 'PULPITIS'},
{ codigo: 'K041', descripcion: 'NECROSIS DE LA PULPA'},
{ codigo: 'K042', descripcion: 'DEGENERACION DE LA PULPA'},
{ codigo: 'K043', descripcion: 'FORMACION ANORMAL DE TEJIDO DURO EN LA PULPA'},
{ codigo: 'K044', descripcion: 'PERIODONTITIS APICAL AGUDA ORIGINADA EN LA PULPA'},
{ codigo: 'K045', descripcion: 'PERIODONTITIS APICAL CRONICA'},
{ codigo: 'K046', descripcion: 'ABSCESO PERIAPICAL CON FISTULA'},
{ codigo: 'K047', descripcion: 'ABSCESO PERIAPICAL SIN FISTULA'},
{ codigo: 'K048', descripcion: 'QUISTE RADICULAR'},
{ codigo: 'K049', descripcion: 'OTRAS ENFERMEDADES Y LAS NO ESPEC DE LA PULPA Y DEL TEJIDO PERIAPICAL'},
{ codigo: 'K05', descripcion: 'GINGIVITIS Y ENFERMEDADES PERIODONTALES'},
{ codigo: 'K050', descripcion: 'GINGIVITIS AGUDA'},
{ codigo: 'K051', descripcion: 'GINGIVITIS CRONICA'},
{ codigo: 'K052', descripcion: 'PERIODONTITIS AGUDA'},
{ codigo: 'K053', descripcion: 'PERIODONTITIS CRONICA'},
{ codigo: 'K054', descripcion: 'PERIODONTOSIS'},
{ codigo: 'K055', descripcion: 'OTRAS ENFERMEDADES PERIODONTALES'},
{ codigo: 'K056', descripcion: 'ENFERMEDAD DEL PERIODONTO, NO ESPECIFICADA'},
{ codigo: 'K06', descripcion: 'OTROS TRASTORNOS DE LA ENCIA Y DE LA ZONA EDENTULA'},
{ codigo: 'K060', descripcion: 'RETRACCION GINGIVAL'},
{ codigo: 'K061', descripcion: 'HIPERPLASIA GINGIVAL'},
{ codigo: 'K062', descripcion: 'LESIONES DE LA ENCIA Y DE LA ZONA EDENTULA ASOCIADAS CON TRAUMATISMO'},
{ codigo: 'K068', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA ENCIA Y DE LA ZONA EDENTULA'},
{ codigo: 'K069', descripcion: 'TRASTORNO NO ESPECIFICADO DE LA ENCIA Y DE LA ZONA EDENTULA'},
{ codigo: 'K07', descripcion: 'ANOMALIAS DENTOFACIALES (INCLUSO LA MALOCLUSION)'},
{ codigo: 'K070', descripcion: 'ANOMALIAS EVIDENTES DEL TAMA—O DE LOS MAXILARES'},
{ codigo: 'K071', descripcion: 'ANOMALIAS DE LA RELACION MAXILOBASILAR'},
{ codigo: 'K072', descripcion: 'ANOMALIAS DE LA RELACION ENTRE LOS ARCOS DENTARIOS'},
{ codigo: 'K073', descripcion: 'ANOMALIAS DE LA POSICION DEL DIENTE'},
{ codigo: 'K074', descripcion: 'MALOCLUSION DE TIPO NO ESPECIFICADO'},
{ codigo: 'K075', descripcion: 'ANOMALIAS DENTOFACIALES FUNCIONALES'},
{ codigo: 'K076', descripcion: 'TRASTORNOS DE LA ARTICULACION TEMPOROMAXILAR'},
{ codigo: 'K078', descripcion: 'OTRAS ANOMALIAS DENTOFACIALES'},
{ codigo: 'K079', descripcion: 'ANOMALIA DENTOFACIAL, NO ESPECIFICADA'},
{ codigo: 'K08', descripcion: 'OTROS TRASTORNOS DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTEN'},
{ codigo: 'K080', descripcion: 'EXFOLIACION DE LOS DIENTES DEBIDA A CAUSAS SISTEMICAS'},
{ codigo: 'K081', descripcion: 'PERDIDA DE DIENTES POR ACCIDENTE, EXTRACCION O ENFERMEDAD PERIODONTAL LOCAL'},
{ codigo: 'K082', descripcion: 'ATROFIA DEL REBORDE ALVEOLAR DESDENTADO'},
{ codigo: 'K083', descripcion: 'RAIZ DENTAL RETENIDA'},
{ codigo: 'K088', descripcion: 'OTRAS AFECCIONES ESPECIFICADAS DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTEN'},
{ codigo: 'K089', descripcion: 'TRASTORNO DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTEN, NO ESPECIFICADAS'},
{ codigo: 'K09', descripcion: 'QUISTES DE LA REGION BUCAL, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'K090', descripcion: 'QUISTES ORIGINADOS POR EL DESARROLLO DE LOS DIENTES'},
{ codigo: 'K091', descripcion: 'QUISTES DE LAS FISURAS (NO ODONTOGENICOS)'},
{ codigo: 'K092', descripcion: 'OTROS QUISTES DE LOS MAXILARES'},
{ codigo: 'K098', descripcion: 'OTROS QUISTES DE LA REGION BUCAL, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'K099', descripcion: 'QUISTE DE LA REGION BUCAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'K10', descripcion: 'OTRAS ENFERMEDADES DE LOS MAXILARES'},
{ codigo: 'K100', descripcion: 'TRASTORNOS DEL DESARROLLO DE LOS MAXILARES'},
{ codigo: 'K101', descripcion: 'GRANULOMA CENTRAL DE CELULAS GIGANTES'},
{ codigo: 'K102', descripcion: 'AFECCIONES INFLAMATORIAS DE LOS MAXILARES'},
{ codigo: 'K103', descripcion: 'ALVEOLITIS DEL MAXILAR'},
{ codigo: 'K108', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LOS MAXILARES'},
{ codigo: 'K109', descripcion: 'ENFERMEDAD DE LOS MAXILARES, NO ESPECIFICADA'},
{ codigo: 'K11', descripcion: 'ENFERMEDADES DE LAS GLANDULAS SALIVALES'},
{ codigo: 'K110', descripcion: 'ATROFIA DE GLANDULA SALIVAL'},
{ codigo: 'K111', descripcion: 'HIPERTROFIA DE GLANDULA SALIVAL'},
{ codigo: 'K112', descripcion: 'SIALADENITIS'},
{ codigo: 'K113', descripcion: 'ABSCESO DE GLANDULA SALIVAL'},
{ codigo: 'K114', descripcion: 'FISTULA DE GLANDULA SALIVAL'},
{ codigo: 'K115', descripcion: 'SIALOLITIASIS'},
{ codigo: 'K116', descripcion: 'MUCOCELE DE GLANDULA SALIVAL'},
{ codigo: 'K117', descripcion: 'ALTERACIONES DE LA SECRECION SALIVAL'},
{ codigo: 'K118', descripcion: 'OTRAS ENFERMEDADES DE LAS GLANDULAS SALIVALES'},
{ codigo: 'K119', descripcion: 'ENFERMEDAD DE GLANDULA SALIVAL, NO ESPECIFICADA'},
{ codigo: 'K12', descripcion: 'ESTOMATITIS Y LESIONES AFINES'},
{ codigo: 'K120', descripcion: 'ESTOMATITIS AFTOSA RECURRENTE'},
{ codigo: 'K121', descripcion: 'OTRAS FORMAS DE ESTOMATITIS'},
{ codigo: 'K122', descripcion: 'CELULITIS Y ABCESO DE BOCA'},
{ codigo: 'K13', descripcion: 'OTRAS ENFERMEDADES DE LOS LABIOS Y DE LA MUCOSA BUCAL'},
{ codigo: 'K130', descripcion: 'ENFERMEDADES DE LOS LABIOS'},
{ codigo: 'K131', descripcion: 'MORDEDURA DEL LABIO Y DE LA MEJILLA'},
{ codigo: 'K132', descripcion: 'LEUCOPLASIA Y OTRAS ALTERACIONES DEL EPITELIO BUCAL, INCLUYENDO LA LENGUA'},
{ codigo: 'K133', descripcion: 'LEUCOPLASIA PILOSA'},
{ codigo: 'K134', descripcion: 'GRANULOMA Y LESIONES SEMEJANTES DE LA MUCOSA BUCAL'},
{ codigo: 'K135', descripcion: 'FIBROSIS DE LA SUBMUCOSA BUCAL'},
{ codigo: 'K136', descripcion: 'HIPERPLASIA IRRRITATIVA DE LA MUCOSA BUCAL'},
{ codigo: 'K137', descripcion: 'OTRAS LESIONES Y LAS NO ESPECIFICADAS DE LA MUCOSA BUCAL'},
{ codigo: 'K14', descripcion: 'ENFERMEDADES DE LA LENGUA'},
{ codigo: 'K140', descripcion: 'GLOSITIS'},
{ codigo: 'K141', descripcion: 'LENGUA GEOGRAFICA'},
{ codigo: 'K142', descripcion: 'GLOSITIS ROMBOIDEA MEDIANA'},
{ codigo: 'K143', descripcion: 'HIPERTROFIA DE LAS PAPILAS LINGUALES'},
{ codigo: 'K144', descripcion: 'ATROFIA DE LAS PAPILAS LINGUALES'},
{ codigo: 'K145', descripcion: 'LENGUA PLEGADA'},
{ codigo: 'K146', descripcion: 'GLOSODINIA'},
{ codigo: 'K148', descripcion: 'OTRAS ENFERMEDADES DE LA LENGUA'},
{ codigo: 'K149', descripcion: 'ENFERMEDAD DE LA LENGUA, NO ESPECIFICADA'},
{ codigo: 'K20', descripcion: 'ESOFAGITIS'},
{ codigo: 'K21', descripcion: 'ENFERMEDAD DEL REFLUJO GASTROESOFAGICO'},
{ codigo: 'K210', descripcion: 'ENFERMEDAD DEL REFLUJO GASTROESOFAGICO CON ESOFAGITIS'},
{ codigo: 'K219', descripcion: 'ENFERMEDAD DEL REFLUJO GASTROESOFAGICO SIN ESOFAGITIS'},
{ codigo: 'K22', descripcion: 'OTRAS ENFERMEDADES DEL ESOFAGO'},
{ codigo: 'K220', descripcion: 'ACALASIA DEL CARDIAS'},
{ codigo: 'K221', descripcion: 'ULCERA DEL ESOFAGO'},
{ codigo: 'K222', descripcion: 'OBSTRUCCION DEL ESOFAGO'},
{ codigo: 'K223', descripcion: 'PERFORACION DEL ESOFAGO'},
{ codigo: 'K224', descripcion: 'DISQUINESIA DEL ESOFAGO'},
{ codigo: 'K225', descripcion: 'DIVERTICULO DEL ESOFAGO, ADQUIRIDO'},
{ codigo: 'K226', descripcion: 'SINDROME DE LACERACION Y HEMORRAGIA GASTROESOFAGICAS'},
{ codigo: 'K228', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL ESOFAGO'},
{ codigo: 'K229', descripcion: 'ENFERMEDAD DEL ESOFAGO, NO ESPECIFICADA'},
{ codigo: 'K23', descripcion: 'TRASTORNOS DEL ESOFAGO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K230', descripcion: 'ESOFAGITIS TUBERCULOSA (A18.8+)'},
{ codigo: 'K231', descripcion: 'MEGAESOFAGO EN LA ENFERMEDAD DE CHAGAS (B57.3+)'},
{ codigo: 'K238', descripcion: 'TRASTORNOS DEL ESOFAGO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K25', descripcion: 'ULCERA GASTRICA'},
{ codigo: 'K250', descripcion: 'ULCERA GASTRICA, AGUDA CON HEMORRAGIA'},
{ codigo: 'K251', descripcion: 'ULCERA GASTRICA, AGUDA CON PERFORACION'},
{ codigo: 'K252', descripcion: 'ULCERA GASTRICA, AGUDA CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K253', descripcion: 'ULCERA GASTRICA, AGUDA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K254', descripcion: 'ULCERA GASTRICA, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA'},
{ codigo: 'K255', descripcion: 'ULCERA GASTRICA, CRONICA O NO ESPECIFICADA, CON PERFORACION'},
{ codigo: 'K256', descripcion: 'ULCERA GASTRICA, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K257', descripcion: 'ULCERA GASTRICA, CRONICA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K259', descripcion: 'ULCERA GASTRICA, NO ESPECIFICADA COMO AGUDA NI COMO CRONICA, SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K26', descripcion: 'ULCERA DUODENAL'},
{ codigo: 'K260', descripcion: 'ULCERA DUODENAL, AGUDA CON HEMORRAGIA'},
{ codigo: 'K261', descripcion: 'ULCERA DUODENAL, AGUDA CON PERFORACION'},
{ codigo: 'K262', descripcion: 'ULCERA DUODENAL, AGUDA CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K263', descripcion: 'ULCERA DUODENAL, AGUDA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K264', descripcion: 'ULCERA DUODENAL, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA'},
{ codigo: 'K265', descripcion: 'ULCERA DUODENAL, CRONICA O NO ESPECIFICADA, CON PERFORACION'},
{ codigo: 'K266', descripcion: 'ULCERA DUODENAL, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K267', descripcion: 'ULCERA DUODENAL, CRONICA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K269', descripcion: 'ULCERA DUODENAL, NO ESPECIFICADA COMO AGUDA NI COMO CRONICA, SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K27', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO'},
{ codigo: 'K270', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO, AGUDA CON HEMORRAGIA'},
{ codigo: 'K271', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO, AGUDA CON PERFORACION'},
{ codigo: 'K272', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO, AGUDA CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K273', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECOIFOCADO, AGUDA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K274', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA'},
{ codigo: 'K275', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO, CRONICA O NO ESPECIFICADA, CON PERFORACION'},
{ codigo: 'K276', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K277', descripcion: 'ULCERA PEPTICA, DE SITIO NO ESPECIFICADO, CRONICA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K279', descripcion: 'ULCERA PEPTICA, NO ESPECIFICADA COMO AGUDA NI COMO CRONICA,SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K28', descripcion: 'ULCERA GASTROYEYUNAL'},
{ codigo: 'K280', descripcion: 'ULCERA GASTROYEYUNAL, AGUDA CON HEMORRAGIA'},
{ codigo: 'K281', descripcion: 'ULCERA GASTROYEYUNAL, AGUDA CON PERFORACION'},
{ codigo: 'K282', descripcion: 'ULCERA GASTROYEYUNAL, AGUDA CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K283', descripcion: 'ULCERA GASTROYEYUNAL, AGUDA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K284', descripcion: 'ULCERA GASTROYEYUNAL, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA'},
{ codigo: 'K285', descripcion: 'ULCERA GASTROYEYUNAL, CRONICA O NO ESPECIFICADA, CON PERFORACION'},
{ codigo: 'K286', descripcion: 'ULCERA GASTROYEYUNAL, CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION'},
{ codigo: 'K287', descripcion: 'ULCERA GASTROYEYUNAL, CRONICA SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K289', descripcion: 'ULCERA GASTROYEYUNAL, NO ESPECIFICADA COMO AGUDA NI COMO CRONICA, SIN HEMORRAGIA NI PERFORACION'},
{ codigo: 'K29', descripcion: 'GASTRITIS Y DUODENITIS'},
{ codigo: 'K290', descripcion: 'GASTRITIS AGUDA HEMORRAGICA'},
{ codigo: 'K291', descripcion: 'OTRAS GASTRITIS AGUDAS'},
{ codigo: 'K292', descripcion: 'GASTRITIS ALCOHOLICA'},
{ codigo: 'K293', descripcion: 'GASTRITIS CRONICA SUPERFICIAL'},
{ codigo: 'K294', descripcion: 'GASTRITIS CRONICA ATROFICA'},
{ codigo: 'K295', descripcion: 'GASTRITIS CRONICA, NO ESPECIFICADA'},
{ codigo: 'K296', descripcion: 'OTRAS GASTRITIS'},
{ codigo: 'K297', descripcion: 'GASTRITIS, NO ESPECIFICADA'},
{ codigo: 'K298', descripcion: 'DUODENITIS'},
{ codigo: 'K299', descripcion: 'GASTRODUODENITIS, NO ESPECIFICADA'},
{ codigo: 'K30', descripcion: 'DISPEPSIA'},
{ codigo: 'K31', descripcion: 'OTRAS ENFERMEDADES DEL ESTOMAGO Y DEL DUODENO'},
{ codigo: 'K310', descripcion: 'DILATACION AGUDA DEL ESTOMAGO'},
{ codigo: 'K311', descripcion: 'ESTENOSIS PILORICA HIPERTROFICA DEL ADULTO'},
{ codigo: 'K312', descripcion: 'ESTRECHEZ O ESTENOSIS DEL ESTOMAGO EN RELOJ DE ARENA'},
{ codigo: 'K313', descripcion: 'ESPASMO DEL PILORO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'K314', descripcion: 'DIVERTICULO GASTRICO'},
{ codigo: 'K315', descripcion: 'OBSTRUCCION DEL DUODENO'},
{ codigo: 'K316', descripcion: 'FISTULA DEL ESTOMAGO Y DEL DUODENO'},
{ codigo: 'K318', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL ESTOMAGO Y DEL DUODENO'},
{ codigo: 'K319', descripcion: 'ENFERMEDAD DEL ESTOMAGO Y DEL DUODENO, NO ESPECIFICADA'},
{ codigo: 'K35', descripcion: 'APENDICITIS AGUDA'},
{ codigo: 'K350', descripcion: 'APENDICITIS AGUDA CON PERITONITIS GENERALIZADA'},
{ codigo: 'K351', descripcion: 'APENDICITIS AGUDA CON ABSCESO PERITONEAL'},
{ codigo: 'K359', descripcion: 'APENDICITIS AGUDA, NO ESPECIFICADA'},
{ codigo: 'K36', descripcion: 'OTROS TIPOS DE APENDICITIS'},
{ codigo: 'K37', descripcion: 'APENDICITIS, NO ESPECIFICADA'},
{ codigo: 'K38', descripcion: 'OTRAS ENFERMEDADES DEL APENDICE'},
{ codigo: 'K380', descripcion: 'HIPERPLASIA DEL APENDICE'},
{ codigo: 'K381', descripcion: 'CONCRECIONES APENDICULARES'},
{ codigo: 'K382', descripcion: 'DIVERTICULO DEL APENDICE'},
{ codigo: 'K383', descripcion: 'FISTULA DEL APENDICE'},
{ codigo: 'K388', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL APENDICE'},
{ codigo: 'K389', descripcion: 'ENFERMEDAD DEL APENDICE, NO ESPECIFICADA'},
{ codigo: 'K40', descripcion: 'HERNIA INGUINAL'},
{ codigo: 'K400', descripcion: 'HERNIA INGUINAL BILATERAL CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K401', descripcion: 'HERNIA INGUINAL BILATERAL, CON GANGRENA'},
{ codigo: 'K402', descripcion: 'HERNIA INGUINAL BILATERAL, SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K403', descripcion: 'HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K404', descripcion: 'HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, CON GANGRENA'},
{ codigo: 'K409', descripcion: 'HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K41', descripcion: 'HERNIA FEMORAL'},
{ codigo: 'K410', descripcion: 'HERNIA FEMORAL BILATERAL, CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K411', descripcion: 'HERNIA FEMORAL BILATERAL, CON GANGRENA'},
{ codigo: 'K412', descripcion: 'HERNIA FEMORAL BILATERAL, SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K413', descripcion: 'HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K414', descripcion: 'HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, CON GANGRENA'},
{ codigo: 'K419', descripcion: 'HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K42', descripcion: 'HERNIA UMBILICAL'},
{ codigo: 'K420', descripcion: 'HERNIA UMBILICAL CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K421', descripcion: 'HERNIA UMBILICAL CON GANGRENA'},
{ codigo: 'K429', descripcion: 'HERNIA UMBILICAL SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K43', descripcion: 'HERNIA VENTRAL'},
{ codigo: 'K430', descripcion: 'HERNIA VENTRAL CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K431', descripcion: 'HERNIA VENTRAL CON GANGRENA'},
{ codigo: 'K439', descripcion: 'HERNIA VENTRAL SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K44', descripcion: 'HERNIA DIAFRAGMATICA'},
{ codigo: 'K440', descripcion: 'HERNIA DIAFRAGMATICA CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K441', descripcion: 'HERNIA DIAFRAGMATICA CON GANGRENA'},
{ codigo: 'K449', descripcion: 'HERNIA DIAFRAGMATICA SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K45', descripcion: 'OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL'},
{ codigo: 'K450', descripcion: 'OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K451', descripcion: 'OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, CON GANGRENA'},
{ codigo: 'K458', descripcion: 'OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K46', descripcion: 'HERNIA NO ESPECIFICADA DE LA CAVIDAD ABDOMINAL'},
{ codigo: 'K460', descripcion: 'HERNIA ABDOMINAL NO ESPECIFICADA, CON OBSTRUCCION, SIN GANGRENA'},
{ codigo: 'K461', descripcion: 'HERNIA ABDOMINAL NO ESPECIFICADA, CON GANGRENA'},
{ codigo: 'K469', descripcion: 'HERNIA ABDOMINAL NO ESPECIFICADA, SIN OBSTRUCCION NI GANGRENA'},
{ codigo: 'K50', descripcion: 'ENFERMEDAD DE CROHN (ENTERITIS REGIONAL)'},
{ codigo: 'K500', descripcion: 'ENFERMEDAD DE CROHN DEL INTESTINO DELGADO'},
{ codigo: 'K501', descripcion: 'ENFERMEDAD DE CROHN DEL INTESTINO GRUESO'},
{ codigo: 'K508', descripcion: 'OTROS TIPOS DE ENFERMEDAD DE CROHN'},
{ codigo: 'K509', descripcion: 'ENFERMEDAD DE CROHN, NO ESPECIFICADA'},
{ codigo: 'K51', descripcion: 'COLITIS ULCERATIVA'},
{ codigo: 'K510', descripcion: 'ENTEROCOLITIS (CRONICA) ULCERATIVA'},
{ codigo: 'K511', descripcion: 'ILEOCOLITIS (CRONICA) ULCERATIVA'},
{ codigo: 'K512', descripcion: 'PROCTITIS (CRONICA) ULCERATIVA'},
{ codigo: 'K513', descripcion: 'RECTOSIGMOIDITIS (CRONICA) ULCERATIVA'},
{ codigo: 'K514', descripcion: 'SEUDOPOLIPOSIS DEL COLON'},
{ codigo: 'K515', descripcion: 'PROCTOCOLITIS MUCOSA'},
{ codigo: 'K518', descripcion: 'OTRAS COLITIS ULCERATIVAS'},
{ codigo: 'K519', descripcion: 'COLITIS ULCERATIVA, SIN OTRA ESPECIFICACION'},
{ codigo: 'K52', descripcion: 'OTRAS COLITIS Y GASTROENTERITIS NO INFECCIOSAS'},
{ codigo: 'K520', descripcion: 'COLITIS Y GASTROENTERITIS DEBIDAS A RADIACION'},
{ codigo: 'K521', descripcion: 'COLITIS Y GASTROENTERITIS TOXICAS'},
{ codigo: 'K522', descripcion: 'COLITIS Y GASTROENTERITIS ALERGICAS Y DIETETICAS'},
{ codigo: 'K528', descripcion: 'OTRAS COLITIS Y GASTROENTERITIS NO INFECCIOSAS ESPECIFICADAS'},
{ codigo: 'K529', descripcion: 'COLITIS Y GASTROENTERITIS NO INFECCIOSAS, NO ESPECIFICADAS'},
{ codigo: 'K55', descripcion: 'TRASTORNOS VASCULARES DE LOS INTESTINOS'},
{ codigo: 'K550', descripcion: 'TRASTORNO VASCULAR AGUDO DE LOS INTESTINOS'},
{ codigo: 'K551', descripcion: 'TRASTORNO VASCULAR CRONICO DEL INTESTINO'},
{ codigo: 'K552', descripcion: 'ANGIODISPLASIA DEL COLON'},
{ codigo: 'K558', descripcion: 'OTROS TRASTORNOS VASCULARES DEL INTESTINO'},
{ codigo: 'K559', descripcion: 'TRASTORNO VASCULAR DEL INTESTINO, NO ESPECIFICADO'},
{ codigo: 'K56', descripcion: 'ILEO PARALITICO Y OBSTRUCCION INTESTINAL SIN HERNIA'},
{ codigo: 'K560', descripcion: 'ILEO PARALITICO'},
{ codigo: 'K561', descripcion: 'INVAGINACION'},
{ codigo: 'K562', descripcion: 'VOLVULO'},
{ codigo: 'K563', descripcion: 'ILEO POR CALCULO BILIAR'},
{ codigo: 'K564', descripcion: 'OTRAS OBSTRUCCIONES DEL INTESTINO'},
{ codigo: 'K565', descripcion: 'ADHERENCIAS (BRIDAS) INTESTINALES CON OBSTRUCCION'},
{ codigo: 'K566', descripcion: 'OTRAS OBSTRUCCIONES INTESTINALES Y LAS NO ESPECIFICADAS'},
{ codigo: 'K567', descripcion: 'ILEO, NO ESPECIFICADO'},
{ codigo: 'K57', descripcion: 'ENFERMEDAD DIVERTICULAR DEL INTESTINO'},
{ codigo: 'K570', descripcion: 'ENFERMEDAD DIVERTICULAR DE INTESTINO DELGADO CON PERFORACION Y ABSCESO'},
{ codigo: 'K571', descripcion: 'ENFERMEDAD DIVERTICULAR DEL INTESTINO DELGADO SIN PERFORACION NI ABSCESO'},
{ codigo: 'K572', descripcion: 'ENFERMEDAD DIVERTICULAR DEL INTESTINO GRUESO CON PERFORACION Y ABSCESO'},
{ codigo: 'K573', descripcion: 'ENFERMEDAD DIVERTICULAR DE INTESTINO GRUESO SIN PERFORACION NI ABSCESO'},
{ codigo: 'K574', descripcion: 'ENFERMEDAD DIVERTICULAR DE AMBOS INTESTINOS CON PERFORACION Y ABSCESO'},
{ codigo: 'K575', descripcion: 'ENFERMEDAD DIVERTICULAR DE AMBOS INTESTINOS,SIN PERFORACION NI ABSCESO'},
{ codigo: 'K578', descripcion: 'ENFERMEDAD DIVERTICULAR DEL INTESTINO, PARTE NO ESPECIFICADAD, CON PERFORACION Y ABSCESO'},
{ codigo: 'K579', descripcion: 'ENFERMEDAD DIVERTICULAR DEL INTESTINO, PARTE NO ESPECIFICADA, SIN PERFORACION NI ABCESO'},
{ codigo: 'K58', descripcion: 'SINDROME DEL COLON IRRITABLE'},
{ codigo: 'K580', descripcion: 'SINDROME DEL COLON IRRITABLE CON DIARREA'},
{ codigo: 'K589', descripcion: 'SINDROME DEL COLON IRRITABLE SIN DIARREA'},
{ codigo: 'K59', descripcion: 'OTROS TRASTORNOS FUNCIONALES DEL INTESTINO'},
{ codigo: 'K590', descripcion: 'CONSTIPACION'},
{ codigo: 'K591', descripcion: 'DIARREA FUNCIONAL'},
{ codigo: 'K592', descripcion: 'INTESTINO NEUROGENICO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'K593', descripcion: 'MEGACOLON, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'K594', descripcion: 'ESPASMO ANAL'},
{ codigo: 'K598', descripcion: 'OTROS TRASTORNOS FUNCIONALES ESPECIFICADOS DEL INTESTINO'},
{ codigo: 'K599', descripcion: 'TRASTORNO FUNCIONAL INTESTINAL, NO ESPECIFICADO'},
{ codigo: 'K60', descripcion: 'FISURA Y FISTULA DE LAS REGIONES ANAL Y RECTAL'},
{ codigo: 'K600', descripcion: 'FISURA ANAL AGUDA'},
{ codigo: 'K601', descripcion: 'FISURA ANAL CRONICA'},
{ codigo: 'K602', descripcion: 'FISURA ANAL, NO ESPECIFICADA'},
{ codigo: 'K603', descripcion: 'FISTULA ANAL'},
{ codigo: 'K604', descripcion: 'FISTULA RECTAL'},
{ codigo: 'K605', descripcion: 'FISTULA ANORRECTAL'},
{ codigo: 'K61', descripcion: 'ABSCESO DE LAS REGIONES ANAL Y RECTAL'},
{ codigo: 'K610', descripcion: 'ABSCESO ANAL'},
{ codigo: 'K611', descripcion: 'ABSCESO RECTAL'},
{ codigo: 'K612', descripcion: 'ABSCESO ANORRECTAL'},
{ codigo: 'K613', descripcion: 'ABSCESO ISQUIORRECTAL'},
{ codigo: 'K614', descripcion: 'ABSCESO INTRAESFINTERIANO'},
{ codigo: 'K62', descripcion: 'OTRAS ENFERMEDADES DEL ANO Y DEL RECTO'},
{ codigo: 'K620', descripcion: 'POLIPO ANAL'},
{ codigo: 'K621', descripcion: 'POLIPO RECTAL'},
{ codigo: 'K622', descripcion: 'PROLAPSO ANAL'},
{ codigo: 'K623', descripcion: 'PROLAPSO RECTAL'},
{ codigo: 'K624', descripcion: 'ESTENOSIS DEL ANO Y DEL RECTO'},
{ codigo: 'K625', descripcion: 'HEMORRAGIA DEL ANO Y DEL RECTO'},
{ codigo: 'K626', descripcion: 'ULCERA DEL ANO Y DEL RECTO'},
{ codigo: 'K627', descripcion: 'PROCTITIS POR RADIACION'},
{ codigo: 'K628', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL ANO Y DEL RECTO'},
{ codigo: 'K629', descripcion: 'ENFERMEDAD DEL ANO Y DEL RECTO, NO ESPECIFICADA'},
{ codigo: 'K63', descripcion: 'OTRAS ENFERMEDADES DE LOS INTESTINOS'},
{ codigo: 'K630', descripcion: 'ABSCESO DEL INTESTINO'},
{ codigo: 'K631', descripcion: 'PERFORACION DEL INTESTINO (NO TRAUMATICA)'},
{ codigo: 'K632', descripcion: 'FISTULA DEL INTESTINO'},
{ codigo: 'K633', descripcion: 'ULCERA DEL INTESTINO'},
{ codigo: 'K634', descripcion: 'ENTEROPTOSIS'},
{ codigo: 'K638', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL INTESTINO'},
{ codigo: 'K639', descripcion: 'ENFERMEDAD DEL INTESTINO, NO ESPECIFICADA'},
{ codigo: 'K65', descripcion: 'PERITONITIS'},
{ codigo: 'K650', descripcion: 'PERITONITIS AGUDA'},
{ codigo: 'K658', descripcion: 'OTRAS PERITONITIS'},
{ codigo: 'K659', descripcion: 'PERITONITIS, NO ESPECIFICADA'},
{ codigo: 'K66', descripcion: 'OTROS TRASTORNOS DEL PERITONEO'},
{ codigo: 'K660', descripcion: 'ADHERENCIAS PERITONEALES'},
{ codigo: 'K661', descripcion: 'HEMOPERITONEO'},
{ codigo: 'K668', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL PERITONEO'},
{ codigo: 'K669', descripcion: 'TRASTORNO DEL PERITONEO, NO ESPECIFICADO'},
{ codigo: 'K67', descripcion: 'TRASTORNOS DEL PERITONEO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K670', descripcion: 'PERITONITIS POR CLAMIDIAS (A74.8+)'},
{ codigo: 'K671', descripcion: 'PERITONITIS GONOCOCICA (A54.8+)'},
{ codigo: 'K672', descripcion: 'PERITONITIS SIFILITICA (A52.7+)'},
{ codigo: 'K673', descripcion: 'PERITONITIS TUBERCULOSA (A18.3+)'},
{ codigo: 'K678', descripcion: 'OTROS TRASTORNOS DEL PERITONEO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K70', descripcion: 'ENFERMEDAD ALCOHOLICA DEL HIGADO'},
{ codigo: 'K700', descripcion: 'HIGADO ALCOHOLICO ADIPOSO'},
{ codigo: 'K701', descripcion: 'HEPATITIS ALCOHOLICA'},
{ codigo: 'K702', descripcion: 'FIBROSIS Y ESCLEROSIS DEL HIGADO, ALCOHOLICA'},
{ codigo: 'K703', descripcion: 'CIRROSIS HEPATICA ALCOHOLICA'},
{ codigo: 'K704', descripcion: 'INSUFICIENCIA HEPATICA ALCOHOLICA'},
{ codigo: 'K709', descripcion: 'ENFERMEDAD HEPATICA ALCOHOLICA, NO ESPECIFICADA'},
{ codigo: 'K71', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO'},
{ codigo: 'K710', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON COLESTASIS'},
{ codigo: 'K711', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON NECROSIS HEPATICA'},
{ codigo: 'K712', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON HEPATITIS AGUDA'},
{ codigo: 'K713', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON HEPATITIS CRONICA PERSISTENTE'},
{ codigo: 'K714', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON HEPATITIS CRONICA LOBULAR'},
{ codigo: 'K715', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON HEPATITIS CRONICA ACTIVA'},
{ codigo: 'K716', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON HEPATITIS NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K717', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON CIRROSIS Y FIBROSIS DEL HIGADO'},
{ codigo: 'K718', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO CON OTROS TRASTORNOS HEPATICOS'},
{ codigo: 'K719', descripcion: 'ENFERMEDAD TOXICA DEL HIGADO, NO ESPECIFICADA'},
{ codigo: 'K72', descripcion: 'INSUFICIENCIA HEPATICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K720', descripcion: 'INSUFICIENCIA HEPATICA AGUDA O SUBAGUDA'},
{ codigo: 'K721', descripcion: 'INSUFICIENCIA HEPATICA CRONICA'},
{ codigo: 'K729', descripcion: 'INSUFICIENCIA HEPATICA, NO ESPECIFICADA'},
{ codigo: 'K73', descripcion: 'HEPATITIS CRONICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K730', descripcion: 'HEPATITIS CRONICA PERSISTENTE, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K731', descripcion: 'HEPATITIS CRONICA LOBULAR, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K732', descripcion: 'HEPATITIS CRONICA ACTIVA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K738', descripcion: 'OTRAS HEPATITIS CRONICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K739', descripcion: 'HEPATITIS CRONICA, NO ESPECIFICADA'},
{ codigo: 'K74', descripcion: 'FIBROSIS Y CIRROSIS DEL HIGADO'},
{ codigo: 'K740', descripcion: 'FIBROSIS HEPATICA'},
{ codigo: 'K741', descripcion: 'ESCLEROSIS HEPATICA'},
{ codigo: 'K742', descripcion: 'FIBROSIS HEPATICA CON ESCLEROSIS HEPATICA'},
{ codigo: 'K743', descripcion: 'CIRROSIS BILIAR PRIMARIA'},
{ codigo: 'K744', descripcion: 'CIRROSIS BILIAR SECUNDARIA'},
{ codigo: 'K745', descripcion: 'CIRROSIS BILIAR, NO ESPECIFICADA'},
{ codigo: 'K746', descripcion: 'OTRAS CIRROSIS DEL HIGADO Y LAS NO ESPECIFICADAS'},
{ codigo: 'K75', descripcion: 'OTRAS ENFERMEDADES INFLAMATORIAS DEL HIGADO'},
{ codigo: 'K750', descripcion: 'ABSCESO DEL HIGADO'},
{ codigo: 'K751', descripcion: 'FLEBITIS DE LA VENA PORTA'},
{ codigo: 'K752', descripcion: 'HEPATITIS REACTIVA NO ESPECIFICA'},
{ codigo: 'K753', descripcion: 'HEPATITIS GRANULOMATOSA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K758', descripcion: 'OTRAS ENFERMEDADES INFLAMATORIAS DEL HIGADO, ESPECIFICADAS'},
{ codigo: 'K759', descripcion: 'ENFERMEDAD INFLAMATORIA DEL HIGADO, NO ESPECIFICADA'},
{ codigo: 'K76', descripcion: 'OTRAS ENFERMEDADES DEL HIGADO'},
{ codigo: 'K760', descripcion: 'DEGENERACION GRASA DEL HIGADO, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K761', descripcion: 'CONGESTION PASIVA CRONICA DEL HIGADO'},
{ codigo: 'K762', descripcion: 'NECROSIS HEMORRAGICA CENTRAL DEL HIGADO'},
{ codigo: 'K763', descripcion: 'INFARTO DEL HIGADO'},
{ codigo: 'K764', descripcion: 'PELIOSIS HEPATICA'},
{ codigo: 'K765', descripcion: 'ENFERMEDAD VENO-OCLUSIVA DEL HIGADO'},
{ codigo: 'K766', descripcion: 'HIPERTENSION PORTAL'},
{ codigo: 'K767', descripcion: 'SINDROME HEPATORRENAL'},
{ codigo: 'K768', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL HIGADO'},
{ codigo: 'K769', descripcion: 'ENFERMEDAD DEL HIGADO, NO ESPECIFICADA'},
{ codigo: 'K77', descripcion: 'TRASTORNOS DEL HIGADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K770', descripcion: 'TRASTORNOS DEL HIGADO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K778', descripcion: 'TRASTORNOS DEL HIGADO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K80', descripcion: 'COLELITIASIS'},
{ codigo: 'K800', descripcion: 'CALCULO DE LA VESICULA BILIAR CON COLECISTITIS AGUDA'},
{ codigo: 'K801', descripcion: 'CALCULO DE LA VESICULA BILIAR CON OTRA COLECISTITIS'},
{ codigo: 'K802', descripcion: 'CALCULO DE LA VESICULA BILIAR SIN COLECISTITIS'},
{ codigo: 'K803', descripcion: 'CALCULO DE CONDUCTO BILIAR CON COLANGITIS'},
{ codigo: 'K804', descripcion: 'CALCULO DE CONDUCTO BILIAR CON COLECISTITIS'},
{ codigo: 'K805', descripcion: 'CALCULO DE CONDUCTO BILIAR SIN COLANGITIS NI COLECISTITIS'},
{ codigo: 'K808', descripcion: 'OTRAS COLELITIASIS'},
{ codigo: 'K81', descripcion: 'COLECISTITIS'},
{ codigo: 'K810', descripcion: 'COLECISTITIS AGUDA'},
{ codigo: 'K811', descripcion: 'COLECISTITIS CRONICA'},
{ codigo: 'K818', descripcion: 'OTRAS COLECISTITIS'},
{ codigo: 'K819', descripcion: 'COLECISTITIS, NO ESPECIFICADA'},
{ codigo: 'K82', descripcion: 'OTRAS ENFERMEDADES DE LA VESICULA BILIAR'},
{ codigo: 'K820', descripcion: 'OBSTRUCCION DE LA VESICULA BILIAR'},
{ codigo: 'K821', descripcion: 'HIDROPESIA DE LA VESICULA BILIAR'},
{ codigo: 'K822', descripcion: 'PERFORACION DE LA VESICULA BILIAR'},
{ codigo: 'K823', descripcion: 'FISTULA DE LA VESICULA BILIAR'},
{ codigo: 'K824', descripcion: 'COLESTEROLOSIS DE LA VESICULA BILIAR'},
{ codigo: 'K828', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LA VESICULA BILIAR'},
{ codigo: 'K829', descripcion: 'ENFERMEDAD DE LA VESICULA BILIAR, NO ESPECIFICADA'},
{ codigo: 'K83', descripcion: 'OTRAS ENFERMEDADES DE LAS VIAS BILIARES'},
{ codigo: 'K830', descripcion: 'COLANGITIS'},
{ codigo: 'K831', descripcion: 'OBSTRUCCION DEL CONDUCTO BILIAR'},
{ codigo: 'K832', descripcion: 'PERFORACION DEL CONDUCTO BILIAR'},
{ codigo: 'K833', descripcion: 'FISTULA DEL CONDUCTO BILIAR'},
{ codigo: 'K834', descripcion: 'ESPASMO DEL ESFINTER DE ODDI'},
{ codigo: 'K835', descripcion: 'QUISTE BILIAR'},
{ codigo: 'K838', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DE LAS VIAS BILIARES'},
{ codigo: 'K839', descripcion: 'ENFERMEDAD DE LAS VIAS BILIARES, NO ESPECIFICADA'},
{ codigo: 'K85', descripcion: 'PANCREATITIS AGUDA'},
{ codigo: 'K86', descripcion: 'OTRAS ENFERMEDADES DEL PANCREAS'},
{ codigo: 'K860', descripcion: 'PANCREATITIS CRONICA INDUCIDA POR EL ALCOHOL'},
{ codigo: 'K861', descripcion: 'OTRAS PANCREATITIS CRONICAS'},
{ codigo: 'K862', descripcion: 'QUISTE DEL PANCREAS'},
{ codigo: 'K863', descripcion: 'SEUDOQUISTE DEL PANCREAS'},
{ codigo: 'K868', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL PANCREAS'},
{ codigo: 'K869', descripcion: 'ENFERMEDAD DEL PANCREAS, NO ESPECIFICADA'},
{ codigo: 'K87', descripcion: 'TRASTORNOS DE LA VESICULA BILIAR, DE VIAS BILIARES Y DEL PANCREAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K870', descripcion: 'TRASTORNOS DE LA VESICULA BILIAR Y DE LAS VIAS BILIARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K871', descripcion: 'TRASTORNOS DEL PANCREAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K90', descripcion: 'MALABSORCION INTESTINAL'},
{ codigo: 'K900', descripcion: 'ENFERMEDAD CELIACA'},
{ codigo: 'K901', descripcion: 'ESPRUE TROPICAL'},
{ codigo: 'K902', descripcion: 'SINDROME DEL ASA CIEGA, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'K903', descripcion: 'ESTEATORREA PANCREATICA'},
{ codigo: 'K904', descripcion: 'MALABSORCION DEBIDA A INTOLERANCIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K908', descripcion: 'OTROS TIPOS DE MALABSORCION INTESTINAL'},
{ codigo: 'K909', descripcion: 'MALABSORCION INTESTINAL, NO ESPECIFICADA'},
{ codigo: 'K91', descripcion: 'TRASTORNOS DEL SISTEMA DIGESTIVO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K910', descripcion: 'VOMITO POSTCIRUGIA GASTROINTESTINAL'},
{ codigo: 'K911', descripcion: 'SINDROMES CONSECUTIVOS A LA CIRUGIA GASTRICA'},
{ codigo: 'K912', descripcion: 'MALABSORCION POSTQUIRURGICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'K913', descripcion: 'OBSTRUCCION INTESTINAL POSTOPERATORIA'},
{ codigo: 'K914', descripcion: 'DISFUNCION DE COLOSTOMIA O ENTEROSTOMIA'},
{ codigo: 'K915', descripcion: 'SINDROME POSTCOLECISTECTOMIA'},
{ codigo: 'K918', descripcion: 'OTROS TRASTORNOS DEL SISTEMA DIGESTIVO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K919', descripcion: 'TRASTORNO NO ESPECIFICADO DEL SISTEMA DIGESTIVO CONSECUTIVO A PROCEDIMIENTO'},
{ codigo: 'K92', descripcion: 'OTRAS ENFERMEDADES DEL SISTEMA DIGESTIVO'},
{ codigo: 'K920', descripcion: 'HEMATEMESIS'},
{ codigo: 'K921', descripcion: 'MELENA'},
{ codigo: 'K922', descripcion: 'HEMORRAGIA GASTROINTESTINAL, NO ESPECIFICADA'},
{ codigo: 'K928', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS DEL SISTEMA DIGESTIVO'},
{ codigo: 'K929', descripcion: 'ENFERMEDAD DEL SISTEMA DIGESTIVO, NO ESPECIFICADA'},
{ codigo: 'K93', descripcion: 'TRASTORNOS DE OTROS ORGANOS DIGESTIVOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'K930', descripcion: 'TRASTORNOS TUBERCULOSOS DEL INTESTINO, PERITONEO Y GANGLIOS MESENTERICOS (A18.3+)'},
{ codigo: 'K931', descripcion: 'MEGACOLON EN LA ENFERMEDAD DE CHAGAS (B57.3+)'},
{ codigo: 'K938', descripcion: 'TRASTORNOS DE OTROS ORGANOS DIGESTIVOS ESPECIFICADOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L00', descripcion: 'SINDROME ESTAFILOCOCICO DE LA PIEL ESCALDADA'},
{ codigo: 'L01', descripcion: 'IMPETIGO'},
{ codigo: 'L010', descripcion: 'IMPETIGO (CUALQUIER SITIO ANATOMICO) (CUALQUIER ORGANISMO)'},
{ codigo: 'L011', descripcion: 'IMPETIGINIZACION DE OTRAS DERMATOSIS'},
{ codigo: 'L02', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO'},
{ codigo: 'L020', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO DE LA CARA'},
{ codigo: 'L021', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO DEL CUELLO'},
{ codigo: 'L022', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO DEL TRONCO'},
{ codigo: 'L023', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO DE GLUTEOS'},
{ codigo: 'L024', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO DE MIEMBRO'},
{ codigo: 'L028', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO DE OTROS SITIOS'},
{ codigo: 'L029', descripcion: 'ABSCESO CUTANEO, FURUNCULO Y CARBUNCO DE SITIO NO ESPECIFICADO'},
{ codigo: 'L03', descripcion: 'CELULITIS'},
{ codigo: 'L030', descripcion: 'CELULITIS DE LOS DEDOS DE LA MANO Y DEL PIE'},
{ codigo: 'L031', descripcion: 'CELULITIS DE OTRAS PARTES DE LOS MIEMBROS'},
{ codigo: 'L032', descripcion: 'CELULITIS DE LA CARA'},
{ codigo: 'L033', descripcion: 'CELULITIS DEL TRONCO'},
{ codigo: 'L038', descripcion: 'CELULITIS DE OTROS SITIOS'},
{ codigo: 'L039', descripcion: 'CELULITIS DE SITIO NO ESPECIFICADO'},
{ codigo: 'L04', descripcion: 'LINFADENITIS AGUDA'},
{ codigo: 'L040', descripcion: 'LINFADENITIS AGUDA DE CARA, CABEZA Y CUELLO'},
{ codigo: 'L041', descripcion: 'LINFADENITIS AGUDA DEL TRONCO'},
{ codigo: 'L042', descripcion: 'LINFADENITIS AGUDA DEL MIEMBRO SUPERIOR'},
{ codigo: 'L043', descripcion: 'LINFADENITIS AGUDA DEL MIEMBRO INFERIOR'},
{ codigo: 'L048', descripcion: 'LINFADENITIS AGUDA DE OTROS SITIOS'},
{ codigo: 'L049', descripcion: 'LINFADENITIS AGUDA DE SITIO NO ESPECIFICADO'},
{ codigo: 'L05', descripcion: 'QUISTE PILONIDAL'},
{ codigo: 'L050', descripcion: 'QUISTE PILONIDAL CON ABSCESO'},
{ codigo: 'L059', descripcion: 'QUISTE PILONIDAL SIN ABSCESO'},
{ codigo: 'L08', descripcion: 'OTRAS INFECCIONES LOCALES DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L080', descripcion: 'PIODERMA'},
{ codigo: 'L081', descripcion: 'ERITRASMA'},
{ codigo: 'L088', descripcion: 'OTRAS INFECCIONES LOCALES ESPECIFICADAS DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L089', descripcion: 'INFECCION LOCAL DE LA PIEL Y DEL TEJIDO SUBCUTANEO, NO ESPECIFICADA'},
{ codigo: 'L10', descripcion: 'PENFIGO'},
{ codigo: 'L100', descripcion: 'PENFIGO VULGAR'},
{ codigo: 'L101', descripcion: 'PENFIGO VEGETANTE'},
{ codigo: 'L102', descripcion: 'PENFIGO FOLIACEO'},
{ codigo: 'L103', descripcion: 'PENFIGO BRASILE—O (FOGO SELVAGEM)'},
{ codigo: 'L104', descripcion: 'PENFIGO ERITEMATOSO'},
{ codigo: 'L105', descripcion: 'PENFIGO INDUCIDO POR DROGAS'},
{ codigo: 'L108', descripcion: 'OTROS PENFIGOS'},
{ codigo: 'L109', descripcion: 'PENFIGO, NO ESPECIFICADO'},
{ codigo: 'L11', descripcion: 'OTROS TRASTORNOS ACANTOLITICOS'},
{ codigo: 'L110', descripcion: 'QUERATOSIS FOLICULAR ADQUIRIDA'},
{ codigo: 'L111', descripcion: 'DERMATOSIS ACANTOLITICA TRANSITORIA (GROVER)'},
{ codigo: 'L118', descripcion: 'OTROS TRASTORNOS ACANTOLITICOS ESPECIFICADOS'},
{ codigo: 'L119', descripcion: 'TRASTORNO ACANTOLITICO, NO ESPECIFICADO'},
{ codigo: 'L12', descripcion: 'PENFIGOIDE'},
{ codigo: 'L120', descripcion: 'PENFIGOIDE FLICTENULAR'},
{ codigo: 'L121', descripcion: 'PENFIGOIDE CICATRICIAL'},
{ codigo: 'L122', descripcion: 'ENFERMEDAD FLICTENULAR CRONICA DE LA INFANCIA'},
{ codigo: 'L123', descripcion: 'EPIDERMOLISIS BULLOSA ADQUIRIDA'},
{ codigo: 'L128', descripcion: 'OTROS PENFIGOIDES'},
{ codigo: 'L129', descripcion: 'PENFIGOIDE, NO ESPECIFICADO'},
{ codigo: 'L13', descripcion: 'OTROS TRASTORNOS FLICTENULARES'},
{ codigo: 'L130', descripcion: 'DERMATITIS HERPETIFORME'},
{ codigo: 'L131', descripcion: 'DERMATITIS PUSTULOSA SUBCORNEAL'},
{ codigo: 'L138', descripcion: 'OTROS TRASTORNOS FLICTENULARES ESPECIFICADOS'},
{ codigo: 'L139', descripcion: 'TRASTORNO FLICTENULAR, NO ESPECIFICADO'},
{ codigo: 'L14', descripcion: 'TRASTORNOS FLICTENULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L20', descripcion: 'DERMATITIS ATOPICA'},
{ codigo: 'L200', descripcion: 'PRURIGO DE BESNIER'},
{ codigo: 'L208', descripcion: 'OTRAS DERMATITIS ATOPICAS'},
{ codigo: 'L209', descripcion: 'DERMATITIS ATOPICA, NO ESPECIFICADA'},
{ codigo: 'L21', descripcion: 'DERMATITIS SEBORREICA'},
{ codigo: 'L210', descripcion: 'SEBORREA CAPITIS'},
{ codigo: 'L211', descripcion: 'DERMATITIS SEBORREICA INFANTIL'},
{ codigo: 'L218', descripcion: 'OTRAS DERMATITIS SEBORREICAS'},
{ codigo: 'L219', descripcion: 'DERMATITIS SEBORREICA, NO ESPECIFICADA'},
{ codigo: 'L22', descripcion: 'DERMATITIS DEL PA—AL'},
{ codigo: 'L23', descripcion: 'DERMATITIS ALERGICA DE CONTACTO'},
{ codigo: 'L230', descripcion: 'DERMATITIS ALERGICA DE CONTACTO DEBIDA A METALES'},
{ codigo: 'L231', descripcion: 'DERMATITIS ALERGICA DE CONTACTO DEBIDA A ADHESIVOS'},
{ codigo: 'L232', descripcion: 'DERMATITIS ALERGICA DE CONTACTO DEBIDA A COSMETICOS'},
{ codigo: 'L233', descripcion: 'DERMATITIS ALERGICA DE CONTACTO POR DROGAS EN CONTACTO CON LA PIEL'},
{ codigo: 'L234', descripcion: 'DERMATITIS ALERGICA DE CONTACTO DEBIDA A COLORANTES'},
{ codigo: 'L235', descripcion: 'DERMATITIS ALERGICA DE CONTACTO DEBIDA A OTROS PRODUCTOS QUIMICOS'},
{ codigo: 'L236', descripcion: 'DERMATITIS ALERGICA DE CONTACTO POR ALIMENTOS EN CONTACTO CON LA PIEL'},
{ codigo: 'L237', descripcion: 'DERMATITIS ALERGICA DE CONTACTO POR PLANTAS, EXCEPTO LAS ALIMENTICIAS'},
{ codigo: 'L238', descripcion: 'DERMATITIS ALERGICA DE CONTACTO DEBIDA A OTROS AGENTES'},
{ codigo: 'L239', descripcion: 'DERMATITIS ALERGICA DE CONTACTO, DE CAUSA NO ESPECIFICADA'},
{ codigo: 'L24', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES'},
{ codigo: 'L240', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DETERGENTES'},
{ codigo: 'L241', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A ACEITES Y GRASAS'},
{ codigo: 'L242', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DISOLVENTES'},
{ codigo: 'L243', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A COSMETICOS'},
{ codigo: 'L244', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, POR DROGAS EN CONTACTO CON PIEL'},
{ codigo: 'L245', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, POR OTROS PRODUCTOS QUIMICOS'},
{ codigo: 'L246', descripcion: 'DERMATITIS CONTACTO POR IRRITANTES, POR ALIMENTOS EN CONTACTO CON PIEL'},
{ codigo: 'L247', descripcion: 'DERMATITIS CONTACTO POR IRRITANTES, POR PLANTAS, EXC LAS ALIMENTICIAS'},
{ codigo: 'L248', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A OTROS AGENTES'},
{ codigo: 'L249', descripcion: 'DERMATITIS DE CONTACTO POR IRRITANTES, DE CAUSA NO ESPECIFICADA'},
{ codigo: 'L25', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA'},
{ codigo: 'L250', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A COSMETICOS'},
{ codigo: 'L251', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, POR DROGAS EN CONTACTO CON PIEL'},
{ codigo: 'L252', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A COLORANTES'},
{ codigo: 'L253', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, POR OTROS PRODUCTOS QUIMICOS'},
{ codigo: 'L254', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, POR ALIMENTOS EN CONTACTO CON LA PIEL'},
{ codigo: 'L255', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, POR PLANTAS, EXCEPTO LAS ALIMENTICIAS'},
{ codigo: 'L258', descripcion: 'DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A OTROS AGENTES'},
{ codigo: 'L259', descripcion: 'DERMATITIS DE CONTACTO, FORMA Y CAUSA NO ESPECIFICADAS'},
{ codigo: 'L26', descripcion: 'DERMATITIS EXFOLIATIVA'},
{ codigo: 'L27', descripcion: 'DERMATITIS DEBIDA A SUSTANCIAS INGERIDAS'},
{ codigo: 'L270', descripcion: 'ERUPCION CUTANEA GENERALIZADA DEBIDA A DROGAS Y MEDICAMENTOS'},
{ codigo: 'L271', descripcion: 'ERUPCION CUTANEA LOCALIZADA DEBIDA A DROGAS Y MEDICAMENTOS'},
{ codigo: 'L272', descripcion: 'DERMATITIS DEBIDA A INGESTION DE ALIMENTOS'},
{ codigo: 'L278', descripcion: 'DERMATITIS DEBIDA A OTRAS SUSTANCIAS INGERIDAS'},
{ codigo: 'L279', descripcion: 'DERMATITIS DEBIDA A SUSTANCIAS INGERIDAS NO ESPECIFICADAS'},
{ codigo: 'L28', descripcion: 'LIQUEN SIMPLE CRONICO Y PRURIGO'},
{ codigo: 'L280', descripcion: 'LIQUEN SIMPLE CRONICO'},
{ codigo: 'L281', descripcion: 'PRURIGO NODULAR'},
{ codigo: 'L282', descripcion: 'OTROS PRURIGOS'},
{ codigo: 'L29', descripcion: 'PRURITO'},
{ codigo: 'L290', descripcion: 'PRURITO ANAL'},
{ codigo: 'L291', descripcion: 'PRURITO ESCROTAL'},
{ codigo: 'L292', descripcion: 'PRURITO VULVAR'},
{ codigo: 'L293', descripcion: 'PRURITO ANOGENITAL, NO ESPECIFICADO'},
{ codigo: 'L298', descripcion: 'OTROS PRURITOS'},
{ codigo: 'L299', descripcion: 'PRURITO, NO ESPECIFICADO'},
{ codigo: 'L30', descripcion: 'OTRAS DERMATITIS'},
{ codigo: 'L300', descripcion: 'DERMATITIS NUMULAR'},
{ codigo: 'L301', descripcion: 'DISHIDROSIS (PONFOLIX)'},
{ codigo: 'L302', descripcion: 'AUTOSENSIBILIZACION CUTANEA'},
{ codigo: 'L303', descripcion: 'DERMATITIS INFECCIOSA'},
{ codigo: 'L304', descripcion: 'ERITEMA INTERTRIGO'},
{ codigo: 'L305', descripcion: 'PITIRIASIS ALBA'},
{ codigo: 'L308', descripcion: 'OTRAS DERMATITIS ESPECIFICADAS'},
{ codigo: 'L309', descripcion: 'DERMATITIS, NO ESPECIFICADA'},
{ codigo: 'L40', descripcion: 'PSORIASIS'},
{ codigo: 'L400', descripcion: 'PSORIASIS VULGAR'},
{ codigo: 'L401', descripcion: 'PSORIASIS PUSTULOSA GENERALIZADA'},
{ codigo: 'L402', descripcion: 'ACRODERMATITIS CONTINUA'},
{ codigo: 'L403', descripcion: 'PUSTULOSIS PALMAR Y PLANTAR'},
{ codigo: 'L404', descripcion: 'PSORIASIS GUTTATA'},
{ codigo: 'L405', descripcion: 'ARTROPATIA PSORIASICA (M07.0*-M07.3*, M09.0*) +'},
{ codigo: 'L408', descripcion: 'OTRAS PSORIASIS'},
{ codigo: 'L409', descripcion: 'PSORIASIS, NO ESPECIFICADA'},
{ codigo: 'L41', descripcion: 'PARAPSORIASIS'},
{ codigo: 'L410', descripcion: 'PITIRIASIS LIQUENOIDE Y VARIOLIFORME AGUDA'},
{ codigo: 'L411', descripcion: 'PITIRIASIS LIQUENOIDE CRONICA'},
{ codigo: 'L412', descripcion: 'PAPULOSIS LINFOMATOIDE'},
{ codigo: 'L413', descripcion: 'PARAPSORIASIS EN PLACAS PEQUE—AS'},
{ codigo: 'L414', descripcion: 'PARAPSORIASIS EN PLACAS GRANDES'},
{ codigo: 'L415', descripcion: 'PARAPSORIASIS RETIFORME'},
{ codigo: 'L418', descripcion: 'OTRAS PARAPSORIASIS'},
{ codigo: 'L419', descripcion: 'PARAPSORIASIS, NO ESPECIFICADA'},
{ codigo: 'L42', descripcion: 'PITIRIASIS ROSADA'},
{ codigo: 'L43', descripcion: 'LIQUEN PLANO'},
{ codigo: 'L430', descripcion: 'LIQUEN PLANO HIPERTROFICO'},
{ codigo: 'L431', descripcion: 'LIQUEN PLANO FLICTENULAR'},
{ codigo: 'L432', descripcion: 'REACCION LIQUENOIDE DEBIDA A DROGAS'},
{ codigo: 'L433', descripcion: 'LIQUEN PLANO SUBAGUDO (ACTIVO)'},
{ codigo: 'L438', descripcion: 'OTROS LIQUENES PLANOS'},
{ codigo: 'L439', descripcion: 'LIQUEN PLANO, NO ESPECIFICADO'},
{ codigo: 'L44', descripcion: 'OTROS TRASTORNOS PAPULOESCAMOSOS'},
{ codigo: 'L440', descripcion: 'PITIRIASIS RUBRA PILARIS'},
{ codigo: 'L441', descripcion: 'LIQUEN NITIDO'},
{ codigo: 'L442', descripcion: 'LIQUEN ESTRIADO'},
{ codigo: 'L443', descripcion: 'LIQUEN ROJO MONILIFORME'},
{ codigo: 'L444', descripcion: 'ACRODERMATITIS PAPULAR INFANTIL (GIANNOTTI-CROSTI)'},
{ codigo: 'L448', descripcion: 'OTROS TRASTORNOS PAPULOESCAMOSOS ESPECIFICADOS'},
{ codigo: 'L449', descripcion: 'TRASTORNO PAPULOESCAMOSO, NO ESPECIFICADO'},
{ codigo: 'L45', descripcion: 'TRASTORNOS PAPULOESCAMOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L50', descripcion: 'URTICARIA'},
{ codigo: 'L500', descripcion: 'URTICARIA ALERGICA'},
{ codigo: 'L501', descripcion: 'URTICARIA IDIOPATICA'},
{ codigo: 'L502', descripcion: 'URTICARIA DEBIDA AL CALOR Y AL FRIO'},
{ codigo: 'L503', descripcion: 'URTICARIA DERMATOGRAFICA'},
{ codigo: 'L504', descripcion: 'URTICARIA VIBRATORIA'},
{ codigo: 'L505', descripcion: 'URTICARIA COLINERGICA'},
{ codigo: 'L506', descripcion: 'URTICARIA POR CONTACTO'},
{ codigo: 'L508', descripcion: 'OTRAS URTICARIAS'},
{ codigo: 'L509', descripcion: 'URTICARIA, NO ESPECIFICADA'},
{ codigo: 'L51', descripcion: 'ERITEMA MULTIFORME'},
{ codigo: 'L510', descripcion: 'ERITEMA MULTIFORME NO FLICTENULAR'},
{ codigo: 'L511', descripcion: 'ERITEMA MULTIFORME FLICTENULAR'},
{ codigo: 'L512', descripcion: 'NECROLISIS EPIDERMICA TOXICA (LYELL)'},
{ codigo: 'L518', descripcion: 'OTROS ERITEMAS MULTIFORMES'},
{ codigo: 'L519', descripcion: 'ERITEMA MULTIFORME, NO ESPECIFICADO'},
{ codigo: 'L52', descripcion: 'ERITEMA NUDOSO'},
{ codigo: 'L53', descripcion: 'OTRAS AFECCIONES ERITEMATOSAS'},
{ codigo: 'L530', descripcion: 'ERITEMA TOXICO'},
{ codigo: 'L531', descripcion: 'ERITEMA ANULAR CENTRIFUGO'},
{ codigo: 'L532', descripcion: 'ERITEMA MARGINADO'},
{ codigo: 'L533', descripcion: 'OTROS ERITEMAS FIGURADOS CRONICOS'},
{ codigo: 'L538', descripcion: 'OTRAS AFECCIONES ERITEMATOSAS ESPECIFICADAS'},
{ codigo: 'L539', descripcion: 'AFECCION ERITEMATOSA, NO ESPECIFICADA'},
{ codigo: 'L54', descripcion: 'ERITEMA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L540', descripcion: 'ERITEMA MARGINADO EN LA FIEBRE REUMATICA AGUDA (I00+)'},
{ codigo: 'L548', descripcion: 'ERITEMA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L55', descripcion: 'QUEMADURA SOLAR'},
{ codigo: 'L550', descripcion: 'QUEMADURA SOLAR DE PRIMER GRADO'},
{ codigo: 'L551', descripcion: 'QUEMADURA SOLAR DE SEGUNDO GRADO'},
{ codigo: 'L552', descripcion: 'QUEMADURA SOLAR DE TERCER GRADO'},
{ codigo: 'L558', descripcion: 'OTRAS QUEMADURAS SOLARES'},
{ codigo: 'L559', descripcion: 'QUEMADURA SOLAR, NO ESPECIFICADA'},
{ codigo: 'L56', descripcion: 'OTROS CAMBIOS AGUDOS DE LA PIEL DEBIDOS A RADIACION ULTRAVIOLETA'},
{ codigo: 'L560', descripcion: 'RESPUESTA FOTOTOXICA A DROGAS'},
{ codigo: 'L561', descripcion: 'RESPUESTA FOTOALERGICA A DROGAS'},
{ codigo: 'L562', descripcion: 'DERMATITIS POR FOTOCONTACTO (DERMATITIS DE BERLOQUE)'},
{ codigo: 'L563', descripcion: 'URTICARIA SOLAR'},
{ codigo: 'L564', descripcion: 'ERUPCION POLIMORFA A LA LUZ'},
{ codigo: 'L568', descripcion: 'OTROS CAMBIOS AGUDOS ESPECIFICADOS DE LA PIEL DEBIDOS A RADIACION ULTRAVIOLETA'},
{ codigo: 'L569', descripcion: 'CAMBIO AGUDO DE PIEL POR RADIACION ULTRAVIOLETA, SIN OTRA ESPECIFICACION'},
{ codigo: 'L57', descripcion: 'CAMBIOS DE LA PIEL POR EXPOSICION CRONICA A RADIACION NO IONIZANTE'},
{ codigo: 'L570', descripcion: 'QUERATOSIS ACTINICA'},
{ codigo: 'L571', descripcion: 'RETICULOIDE ACTINICO'},
{ codigo: 'L572', descripcion: 'PIEL ROMBOIDAL DE LA NUCA'},
{ codigo: 'L573', descripcion: 'POIQUILODERMIA DE CIVATTE'},
{ codigo: 'L574', descripcion: 'PIEL LAXA SENIL'},
{ codigo: 'L575', descripcion: 'GRANULOMA ACTINICO'},
{ codigo: 'L578', descripcion: 'OTROS CAMBIOS DE PIEL POR EXPOSICION CRONICA A RADIACION NO IONIZANTE'},
{ codigo: 'L579', descripcion: 'CAMBIOS DE PIEL POR EXPOSICION CRONICA A RADIACION NO IONIZANTE, SIN OTRA ESPECIFICACION'},
{ codigo: 'L58', descripcion: 'RADIODERMATITIS'},
{ codigo: 'L580', descripcion: 'RADIODERMATITIS AGUDA'},
{ codigo: 'L581', descripcion: 'RADIODERMATITIS CRONICA'},
{ codigo: 'L589', descripcion: 'RADIODERMATITIS, NO ESPECIFICADA'},
{ codigo: 'L59', descripcion: 'OTROS TRAST DE PIEL Y DEL TEJIDO SUBCUTANEO RELACIONADOS CON RADIACION'},
{ codigo: 'L590', descripcion: 'ERITEMA AB IGNE (DERMATITIS AB IGNE)'},
{ codigo: 'L598', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA PIEL Y DE TEJIDO SUBCUTANEO RELACIONADOS CON RADIACION'},
{ codigo: 'L599', descripcion: 'TRASTORNOS NO ESPECIFICADOS DE LA PIEL Y DE TEJIDO SUBCUTANEO RELACIONADOS CON RADIACION'},
{ codigo: 'L60', descripcion: 'TRASTORNOS DE LAS U—AS'},
{ codigo: 'L600', descripcion: 'U—A ENCARNADA'},
{ codigo: 'L601', descripcion: 'ONICOLISIS'},
{ codigo: 'L602', descripcion: 'ONICOGRIPOSIS'},
{ codigo: 'L603', descripcion: 'DISTROFIA UNGUEAL'},
{ codigo: 'L604', descripcion: 'LINEAS DE BEAU'},
{ codigo: 'L605', descripcion: 'SINDROME DE LA U—A AMARILLA'},
{ codigo: 'L608', descripcion: 'OTROS TRASTORNOS DE LAS U—AS'},
{ codigo: 'L609', descripcion: 'TRASTORNO DE LA U—A, NO ESPECIFICADO'},
{ codigo: 'L62', descripcion: 'TRASTORNOS DE LAS U—AS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L620', descripcion: 'U—A DEFORME DE LA PAQUIDERMOPERIOSTOSIS (M89.4+) *'},
{ codigo: 'L628', descripcion: 'TRASTORNOS DE LAS U—AS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L63', descripcion: 'ALOPECIA AREATA'},
{ codigo: 'L630', descripcion: 'ALOPECIA (CAPITIS) TOTAL'},
{ codigo: 'L631', descripcion: 'ALOPECIA UNIVERSAL'},
{ codigo: 'L632', descripcion: 'OFIASIS'},
{ codigo: 'L638', descripcion: 'OTRAS ALOPECIAS AREATAS'},
{ codigo: 'L639', descripcion: 'ALOPECIA AREATA, NO ESPECIFICADA'},
{ codigo: 'L64', descripcion: 'ALOPECIA ANDROGENA'},
{ codigo: 'L640', descripcion: 'ALOPECIA ANDROGENA, INDUCIDA POR DROGAS'},
{ codigo: 'L648', descripcion: 'OTRAS ALOPECIAS ANDROGENAS'},
{ codigo: 'L649', descripcion: 'ALOPECIA ANDROGENA, NO ESPECIFICADA'},
{ codigo: 'L65', descripcion: 'OTRA PERDIDA NO CICATRICIAL DEL PELO'},
{ codigo: 'L650', descripcion: 'PERDIDA CAPILAR TELOGENA'},
{ codigo: 'L651', descripcion: 'PERDIDA CAPILAR ANAGENA'},
{ codigo: 'L652', descripcion: 'ALOPECIA MUCINOSA'},
{ codigo: 'L658', descripcion: 'OTRAS PERDIDAS ESPECIFICADAS NO CICATRICIALES DEL PELO'},
{ codigo: 'L659', descripcion: 'PERDIDA NO CICATRICIAL DEL PELO, SIN OTRA ESPECIFICACION'},
{ codigo: 'L66', descripcion: 'ALOPECIA CICATRICIAL (PERDIDA CICATRICIAL DEL PELO)'},
{ codigo: 'L660', descripcion: 'SEUDOPELADA'},
{ codigo: 'L661', descripcion: 'LIQUEN PLANO PILARIS'},
{ codigo: 'L662', descripcion: 'FOLICULITIS DECALVANTE'},
{ codigo: 'L663', descripcion: 'PERIFOLICULITIS CAPITIS ABSCEDENS'},
{ codigo: 'L664', descripcion: 'FOLICULITIS ULERITEMATOSA RETICULADA'},
{ codigo: 'L668', descripcion: 'OTRAS ALOPECIAS CICATRICIALES'},
{ codigo: 'L669', descripcion: 'ALOPECIA CICATRICIAL, NO ESPECIFICADA'},
{ codigo: 'L67', descripcion: 'ANORMALIDADES DEL TALLO Y DEL COLOR DEL PELO'},
{ codigo: 'L670', descripcion: 'TRICORREXIS NUDOSA'},
{ codigo: 'L671', descripcion: 'VARIACION DEL COLOR DEL PELO'},
{ codigo: 'L678', descripcion: 'OTRAS ANORMALIDADES DEL TALLO Y DEL COLOR DEL PELO'},
{ codigo: 'L679', descripcion: 'ANORMALIDAD NO ESPECIFICADA DEL TALLO Y DEL COLOR DEL PELO'},
{ codigo: 'L68', descripcion: 'HIPERTRICOSIS'},
{ codigo: 'L680', descripcion: 'HIRSUTISMO'},
{ codigo: 'L681', descripcion: 'HIPERTRICOSIS LANUGINOSA ADQUIRIDA'},
{ codigo: 'L682', descripcion: 'HIPERTRICOSIS LOCALIZADA'},
{ codigo: 'L683', descripcion: 'POLITRIQUIA'},
{ codigo: 'L688', descripcion: 'OTRAS HIPERTRICOSIS'},
{ codigo: 'L689', descripcion: 'HIPERTRICOSIS, NO ESPECIFICADA'},
{ codigo: 'L70', descripcion: 'ACNE'},
{ codigo: 'L700', descripcion: 'ACNE VULGAR'},
{ codigo: 'L701', descripcion: 'ACNE CONGLOBADO'},
{ codigo: 'L702', descripcion: 'ACNE VARIOLIFORME'},
{ codigo: 'L703', descripcion: 'ACNE TROPICAL'},
{ codigo: 'L704', descripcion: 'ACNE INFANTIL'},
{ codigo: 'L705', descripcion: 'ACNE EXCORIADO DE LA MUJER JOVEN'},
{ codigo: 'L708', descripcion: 'OTROS ACNES'},
{ codigo: 'L709', descripcion: 'ACNE, NO ESPECIFICADO'},
{ codigo: 'L71', descripcion: 'ROSACEA'},
{ codigo: 'L710', descripcion: 'DERMATITIS PERIBUCAL'},
{ codigo: 'L711', descripcion: 'RINOFIMA'},
{ codigo: 'L718', descripcion: 'OTRAS ROSACEAS'},
{ codigo: 'L719', descripcion: 'ROSACEA, NO ESPECIFICADA'},
{ codigo: 'L72', descripcion: 'QUISTE FOLICULAR DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L720', descripcion: 'QUISTE EPIDERMICO'},
{ codigo: 'L721', descripcion: 'QUISTE TRICODERMICO'},
{ codigo: 'L722', descripcion: 'ESTEATOCISTOMA MULTIPLE'},
{ codigo: 'L728', descripcion: 'OTROS QUISTES FOLICULARES DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L729', descripcion: 'QUISTE FOLICULAR DE LA PIEL Y DEL TEJIDO SUBCUTANEO, SIN OTRA ESPECIFICACION'},
{ codigo: 'L73', descripcion: 'OTROS TRASTORNOS FOLICULARES'},
{ codigo: 'L730', descripcion: 'ACNE QUELOIDE'},
{ codigo: 'L731', descripcion: 'SEUDOFOLICULITIS DE LA BARBA'},
{ codigo: 'L732', descripcion: 'HIDRADENITIS SUPURATIVA'},
{ codigo: 'L738', descripcion: 'OTROS TRASTORNOS FOLICULARES ESPECIFICADOS'},
{ codigo: 'L739', descripcion: 'TRASTORNO FOLICULAR, NO ESPECIFICADO'},
{ codigo: 'L74', descripcion: 'TRASTORNOS SUDORIPAROS ECRINOS'},
{ codigo: 'L740', descripcion: 'MILIARIA RUBRA'},
{ codigo: 'L741', descripcion: 'MILIARIA CRISTALINA'},
{ codigo: 'L742', descripcion: 'MILIARIA PROFUNDA'},
{ codigo: 'L743', descripcion: 'MILIARIA, NO ESPECIFICADA'},
{ codigo: 'L744', descripcion: 'ANHIDROSIS'},
{ codigo: 'L748', descripcion: 'OTROS TRASTORNOS SUDORIPAROS ECRINOS'},
{ codigo: 'L749', descripcion: 'TRASTORNO SUDORIPARO ECRINO, NO ESPECIFICADO'},
{ codigo: 'L75', descripcion: 'TRASTORNOS SUDORIPAROS APOCRINOS'},
{ codigo: 'L750', descripcion: 'BROMHIDROSIS'},
{ codigo: 'L751', descripcion: 'CROMHIDROSIS'},
{ codigo: 'L752', descripcion: 'MILIARIA APOCRINA'},
{ codigo: 'L758', descripcion: 'OTROS TRASTORNOS SUDORIPAROS APOCRINOS'},
{ codigo: 'L759', descripcion: 'TRASTORNO SUDORIPARO APOCRINO, NO ESPECIFICADO'},
{ codigo: 'L80', descripcion: 'VITILIGO'},
{ codigo: 'L81', descripcion: 'OTROS TRASTORNOS DE LA PIGMENTACION'},
{ codigo: 'L810', descripcion: 'HIPERPIGMENTACION POSTINFLAMATORIA'},
{ codigo: 'L811', descripcion: 'CLOASMA'},
{ codigo: 'L812', descripcion: 'EFELIDE'},
{ codigo: 'L813', descripcion: 'MANCHAS CAFE CON LECHE'},
{ codigo: 'L814', descripcion: 'OTROS TIPOS DE HIPERPIGMENTACION MELANODERMICA'},
{ codigo: 'L815', descripcion: 'LEUCODERMIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'L816', descripcion: 'OTROS TRASTORNOS DE DISMINUCION DE LA FORMACION DE LA MELANINA'},
{ codigo: 'L817', descripcion: 'DERMATOSIS PURPURICA PIGMENTADA'},
{ codigo: 'L818', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA PIGMENTACION'},
{ codigo: 'L819', descripcion: 'TRASTORNO DE LA PIGMENTACION, NO ESPECIFICADO'},
{ codigo: 'L82', descripcion: 'QUERATOSIS SEBORREICA'},
{ codigo: 'L83', descripcion: 'ACANTOSIS NIGRICANS'},
{ codigo: 'L84', descripcion: 'CALLOS Y CALLOSIDADES'},
{ codigo: 'L85', descripcion: 'OTROS TIPOS DE ENGROSAMIENTO EPIDERMICO'},
{ codigo: 'L850', descripcion: 'ICTIOSIS ADQUIRIDA'},
{ codigo: 'L851', descripcion: 'QUERATOSIS (QUERATODERMIA) PALMAR Y PLANTAR ADQUIRIDA'},
{ codigo: 'L852', descripcion: 'QUERATOSIS PUNCTATA (PALMAR Y PLANTAR)'},
{ codigo: 'L853', descripcion: 'XEROSIS DEL CUTIS'},
{ codigo: 'L858', descripcion: 'OTROS ENGROSAMIENTOS EPIDERMICOS ESPECIFICADOS'},
{ codigo: 'L859', descripcion: 'ENGROSAMIENTO EPIDERMICO, NO ESPECIFICADO'},
{ codigo: 'L86', descripcion: 'QUERATODERMA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L87', descripcion: 'TRASTORNOS DE LA ELIMINACION TRANSEPIDERMICA'},
{ codigo: 'L870', descripcion: 'QUERATOSIS FOLICULAR Y PARAFOLICULAR PENETRANTE DEL CUTIS (KYRLE)'},
{ codigo: 'L871', descripcion: 'COLAGENOSIS PERFORANTE REACTIVA'},
{ codigo: 'L872', descripcion: 'ELASTOSIS SERPIGINOSA PERFORANTE'},
{ codigo: 'L878', descripcion: 'OTROS TRASTORNOS DE LA ELIMINACION TRANSEPIDERMICA'},
{ codigo: 'L879', descripcion: 'TRASTORNO DE LA ELIMINACION TRANSEPIDERMICA, NO ESPECIFICADO'},
{ codigo: 'L88', descripcion: 'PIODERMA GANGRENOSO'},
{ codigo: 'L89', descripcion: 'ULCERA DE DECUBITO'},
{ codigo: 'L90', descripcion: 'TRASTORNOS ATROFICOS DE LA PIEL'},
{ codigo: 'L900', descripcion: 'LIQUEN ESCLEROSO Y ATROFICO'},
{ codigo: 'L901', descripcion: 'ANETODERMIA DE SCHWENINGER-BUZZI'},
{ codigo: 'L902', descripcion: 'ANETODERMIA DE JADASSOHN-PELLIZZARI'},
{ codigo: 'L903', descripcion: 'ATROFODERMA DE PASINI Y PIERINI'},
{ codigo: 'L904', descripcion: 'ACRODERMATITIS CRONICA ATROFICA'},
{ codigo: 'L905', descripcion: 'FIBROSIS Y AFECCIONES CICATRICIALES DE LA PIEL'},
{ codigo: 'L906', descripcion: 'ESTRIAS ATROFICAS'},
{ codigo: 'L908', descripcion: 'OTROS TRASTORNOS ATROFICOS DE LA PIEL'},
{ codigo: 'L909', descripcion: 'TRASTORNO ATROFICO DE LA PIEL, NO ESPECIFICADO'},
{ codigo: 'L91', descripcion: 'TRASTORNOS HIPERTROFICOS DE LA PIEL'},
{ codigo: 'L910', descripcion: 'CICATRIZ QUELOIDE'},
{ codigo: 'L918', descripcion: 'OTROS TRASTORNOS HIPERTROFICOS DE LA PIEL'},
{ codigo: 'L919', descripcion: 'TRASTORNO HIPERTROFICO DE LA PIEL, NO ESPECIFICADO'},
{ codigo: 'L92', descripcion: 'TRASTORNOS GRANULOMATOSOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L920', descripcion: 'GRANULOMA ANULAR'},
{ codigo: 'L921', descripcion: 'NECROBIOSIS LIPIDICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'L922', descripcion: 'GRANULOMA FACIAL (GRANULOMA EOSINOFILO DE LA PIEL)'},
{ codigo: 'L923', descripcion: 'GRANULOMA POR CUERPO EXTRA—O EN LA PIEL Y EN EL TEJIDO SUBCUTANEO'},
{ codigo: 'L928', descripcion: 'OTROS TRASTORNOS GRANULOMATOSOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L929', descripcion: 'TRASTORNO GRANULOMATOSO DE LA PIEL Y DEL TEJIDO SUBCUTANEO, NO ESPECIFICADO'},
{ codigo: 'L93', descripcion: 'LUPUS ERITEMATOSO'},
{ codigo: 'L930', descripcion: 'LUPUS ERITEMATOSO DISCOIDE'},
{ codigo: 'L931', descripcion: 'LUPUS ERITEMATOSO CUTANEO SUBAGUDO'},
{ codigo: 'L932', descripcion: 'OTROS LUPUS ERITEMATOSOS LOCALIZADOS'},
{ codigo: 'L94', descripcion: 'OTROS TRASTORNOS LOCALIZADOS DEL TEJIDO CONJUNTIVO'},
{ codigo: 'L940', descripcion: 'ESCLERODERMA LOCALIZADO (MORFEA)'},
{ codigo: 'L941', descripcion: 'ESCLERODERMA LINEAL'},
{ codigo: 'L942', descripcion: 'CALCINOSIS DE LA PIEL'},
{ codigo: 'L943', descripcion: 'ESCLERODACTILIA'},
{ codigo: 'L944', descripcion: 'PAPULAS DE GOTTRON'},
{ codigo: 'L945', descripcion: 'POIQUILODERMIA VASCULAR ATROFICA'},
{ codigo: 'L946', descripcion: 'AINHUM'},
{ codigo: 'L948', descripcion: 'OTROS TRASTORNOS LOCALIZADOS ESPECIFICADOS DEL TEJIDO CONJUNTIVO'},
{ codigo: 'L949', descripcion: 'TRASTORNO LOCALIZADO DEL TEJIDO CONJUNTIVO, NO ESPECIFICADO'},
{ codigo: 'L95', descripcion: 'VASCULITIS LIMITADA A LA PIEL, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'L950', descripcion: 'VASCULITIS LIVEDOIDE'},
{ codigo: 'L951', descripcion: 'ERITEMA ELEVATUM DIUTINUM'},
{ codigo: 'L958', descripcion: 'OTRAS VASCULITIS LIMITADAS A LA PIEL'},
{ codigo: 'L959', descripcion: 'VASCULITIS LIMITADA A LA PIEL, SIN OTRA ESPECIFICACION'},
{ codigo: 'L97', descripcion: 'ULCERA DE MIEMBRO INFERIOR, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'L98', descripcion: 'OTROS TRASTORNOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L980', descripcion: 'GRANULOMA PIOGENO'},
{ codigo: 'L981', descripcion: 'DERMATITIS FACTICIA'},
{ codigo: 'L982', descripcion: 'DERMATOSIS NEUTROFILA FEBRIL (SWEET)'},
{ codigo: 'L983', descripcion: 'CELULITIS EOSINOFILA (WELLS)'},
{ codigo: 'L984', descripcion: 'ULCERA CRONICA DE LA PIEL, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'L985', descripcion: 'MUCINOSIS DE LA PIEL'},
{ codigo: 'L986', descripcion: 'OTROS TRASTORNOS INFILTRATIVOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L988', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'L989', descripcion: 'TRASTORNO DE LA PIEL Y DEL TEJIDO SUBCUTANEO, NO ESPECIFICADO'},
{ codigo: 'L99', descripcion: 'OTROS TRASTORNOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'L990', descripcion: 'AMILOIDOSIS DE LA PIEL (E85.-+)'},
{ codigo: 'L998', descripcion: 'OTROS TRASTORNOS DE PIEL Y DEL TEJIDO SUBCUTANEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M00', descripcion: 'ARTRITIS PIOGENA'},
{ codigo: 'M000', descripcion: 'ARTRITIS Y POLIARTRITIS ESTAFILOCOCICA'},
{ codigo: 'M001', descripcion: 'ARTRITIS Y POLIARTRITIS NEUMOCOCICA'},
{ codigo: 'M002', descripcion: 'OTRAS ARTRITIS Y POLIARTRITIS ESTREPTOCOCICAS'},
{ codigo: 'M008', descripcion: 'ARTRITIS Y POLIARTRITIS DEBIDAS A OTROS AGENTES BACTERIANOS ESPECIFICADOS'},
{ codigo: 'M009', descripcion: 'ARTRITIS PIOGENA, NO ESPECIFICADA'},
{ codigo: 'M01', descripcion: 'INFECCIONES DIRECTAS DE ARTICULACION EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M010', descripcion: 'ARTRITIS MENINGOCOCICA (A39.8+)'},
{ codigo: 'M011', descripcion: 'ARTRITIS TUBERCULOSA (A18.0+)'},
{ codigo: 'M012', descripcion: 'ARTRITIS EN LA ENFERMEDAD DE LYME (A69.2+)'},
{ codigo: 'M013', descripcion: 'ARTRITIS EN OTRAS ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M014', descripcion: 'ARTRITIS EN RUBEOLA (B06.8+)'},
{ codigo: 'M015', descripcion: 'ARTRITIS EN OTRAS ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M016', descripcion: 'ARTRITIS EN MICOSIS (B35-B49+)'},
{ codigo: 'M018', descripcion: 'ARTRITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M02', descripcion: 'ARTROPATIAS REACTIVAS'},
{ codigo: 'M020', descripcion: 'ARTROPATIA CONSECUTIVA A DERIVACION INTESTINAL'},
{ codigo: 'M021', descripcion: 'ARTROPATIA POSTDISENTERICA'},
{ codigo: 'M022', descripcion: 'ARTROPATIA POSTINMUNIZACION'},
{ codigo: 'M023', descripcion: 'ENFERMEDAD DE REITER'},
{ codigo: 'M028', descripcion: 'OTRAS ARTROPATIAS REACTIVAS'},
{ codigo: 'M029', descripcion: 'ARTROPATIA REACTIVA, NO ESPECIFICADA'},
{ codigo: 'M03', descripcion: 'ARTROPATIAS POSTINFECCIOSAS Y REACTIVAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M030', descripcion: 'ARTRITIS POSTMENINGOCOCICA (A39.8+)'},
{ codigo: 'M031', descripcion: 'ARTROPATIA POSTINFECCIOSA EN SIFILIS'},
{ codigo: 'M032', descripcion: 'OTRAS ARTROPATIAS POSTINFECCIOSAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M036', descripcion: 'ARTROPATIA REACTIVA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M05', descripcion: 'ARTRITIS REUMATOIDE SEROPOSITIVA'},
{ codigo: 'M050', descripcion: 'SINDROME DE FELTY'},
{ codigo: 'M051', descripcion: 'ENFERMEDAD REUMATOIDE DEL PULMON (J99.0*) +'},
{ codigo: 'M052', descripcion: 'VASCULITIS REUMATOIDE'},
{ codigo: 'M053', descripcion: 'ARTRITIS REUMATOIDE CON COMPROMISO DE OTROS ORGANOS O SISTEMAS +'},
{ codigo: 'M058', descripcion: 'OTRAS ARTRITIS REUMATOIDEAS SEROPOSITIVAS'},
{ codigo: 'M059', descripcion: 'ARTRITIS REUMATOIDEA SEROPOSITIVA, SIN OTRA ESPECIFICACION'},
{ codigo: 'M06', descripcion: 'OTRAS ARTRITIS REUMATOIDES'},
{ codigo: 'M060', descripcion: 'ARTRITIS REUMATOIDE SERONEGATIVA'},
{ codigo: 'M061', descripcion: 'ENFERMEDAD DE STILL DE COMIENZO EN EL ADULTO'},
{ codigo: 'M062', descripcion: 'BURSITIS REUMATOIDE'},
{ codigo: 'M063', descripcion: 'NODULO REUMATOIDE'},
{ codigo: 'M064', descripcion: 'POLIARTROPATIA INFLAMATORIA'},
{ codigo: 'M068', descripcion: 'OTRAS ARTRITIS REUMATOIDES ESPECIFICADAS'},
{ codigo: 'M069', descripcion: 'ARTRITIS REUMATOIDE, NO ESPECIFICADA'},
{ codigo: 'M07', descripcion: 'ARTROPATIAS PSORIASICAS Y ENTEROPATICAS'},
{ codigo: 'M070', descripcion: 'ARTROPATIA PSORIASICA INTERFALANGICA DISTAL (L40.5+) *'},
{ codigo: 'M071', descripcion: 'ARTRITIS MUTILANTE (L40.5+) *'},
{ codigo: 'M072', descripcion: 'ESPONDILITIS PSORIASICA (L40.5+) *'},
{ codigo: 'M073', descripcion: 'OTRAS ARTROPATIAS PSORIASICAS (L40.5+) *'},
{ codigo: 'M074', descripcion: 'ARTROPATIA EN LA ENFERMEDAD DE CROHN (ENTERITIS REGIONAL) (K50.-+)'},
{ codigo: 'M075', descripcion: 'ARTROPATIA EN LA COLITIS ULCERATIVA (K51.-+)'},
{ codigo: 'M076', descripcion: 'OTRAS ARTROPATIAS ENTEROPATICAS'},
{ codigo: 'M08', descripcion: 'ARTRITIS JUVENIL'},
{ codigo: 'M080', descripcion: 'ARTRITIS REUMATOIDE JUVENIL'},
{ codigo: 'M081', descripcion: 'ESPONDILITIS ANQUILOSANTE JUVENIL'},
{ codigo: 'M082', descripcion: 'ARTRITIS JUVENIL DE COMIENZO GENERALIZADO'},
{ codigo: 'M083', descripcion: 'POLIARTRITIS JUVENIL (SERONEGATIVA)'},
{ codigo: 'M084', descripcion: 'ARTRITIS JUVENIL PAUCIARTICULAR'},
{ codigo: 'M088', descripcion: 'OTRAS ARTRITIS JUVENILES'},
{ codigo: 'M089', descripcion: 'ARTRITIS JUVENIL, NO ESPECIFICADA'},
{ codigo: 'M09', descripcion: 'ARTRITIS JUVENIL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M090', descripcion: 'ARTRITIS JUVENIL EN LA PSORIASIS (L40.5+) *'},
{ codigo: 'M091', descripcion: 'ARTRITIS JUVENIL EN ENFERMEDAD DE CROHN (ENTERITIS REGIONAL) (K50.-+)'},
{ codigo: 'M092', descripcion: 'ARTRITIS JUVENIL EN LA COLITIS ULCERATIVA (K51.-+)'},
{ codigo: 'M098', descripcion: 'ARTRITIS JUVENIL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M10', descripcion: 'GOTA'},
{ codigo: 'M100', descripcion: 'GOTA IDIOPATICA'},
{ codigo: 'M101', descripcion: 'GOTA SATURNINA'},
{ codigo: 'M102', descripcion: 'GOTA INDUCIDA POR DROGAS'},
{ codigo: 'M103', descripcion: 'GOTA DEBIDA A ALTERACION RENAL'},
{ codigo: 'M104', descripcion: 'OTRAS GOTAS SECUNDARIAS'},
{ codigo: 'M109', descripcion: 'GOTA, NO ESPECIFICADA'},
{ codigo: 'M11', descripcion: 'OTRAS ARTROPATIAS POR CRISTALES'},
{ codigo: 'M110', descripcion: 'ENFERMEDAD POR DEPOSITO DE HIDROXIAPATITA'},
{ codigo: 'M111', descripcion: 'CONDROCALCINOSIS FAMILIAR'},
{ codigo: 'M112', descripcion: 'OTRAS CONDROCALCINOSIS'},
{ codigo: 'M118', descripcion: 'OTRAS ARTROPATIAS POR CRISTALES, ESPECIFICADAS'},
{ codigo: 'M119', descripcion: 'ARTROPATIA POR CRISTALES, NO ESPECIFICADA'},
{ codigo: 'M12', descripcion: 'OTRAS ARTROPATIAS ESPECIFICAS'},
{ codigo: 'M120', descripcion: 'ARTROPATIA POSTREUMATICA CRONICA (DE JACCOUD)'},
{ codigo: 'M121', descripcion: 'ENFERMEDAD DE KASCHIN-BECK'},
{ codigo: 'M122', descripcion: 'SINOVITIS VELLONODULAR (PIGMENTADA)'},
{ codigo: 'M123', descripcion: 'REUMATISMO PALINDROMICO'},
{ codigo: 'M124', descripcion: 'HIDRARTROSIS INTERMITENTE'},
{ codigo: 'M125', descripcion: 'ARTROPATIA TRAUMATICA'},
{ codigo: 'M128', descripcion: 'OTRAS ARTROPATIAS ESPECIFICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M13', descripcion: 'OTRAS ARTRITIS'},
{ codigo: 'M130', descripcion: 'POLIARTRITIS, NO ESPECIFICADA'},
{ codigo: 'M131', descripcion: 'MONOARTRITIS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M138', descripcion: 'OTRAS ARTRITIS ESPECIFICADAS'},
{ codigo: 'M139', descripcion: 'ARTRITIS, NO ESPECIFICADA'},
{ codigo: 'M14', descripcion: 'ARTROPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M140', descripcion: 'ARTROPATIA GOTOSA DEBIDA A DEFECTOS ENZIMATICOS Y A OTROS TRASTORNOS HEREDITARIOS, CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M141', descripcion: 'ARTROPATIA POR CRISTALES EN OTROS TRASTORNOS METABOLICOS'},
{ codigo: 'M142', descripcion: 'ARTROPATIA DIABETICA (E10-E14+ CON CUARTO CARACTER COMUN .6)'},
{ codigo: 'M143', descripcion: 'DERMATOARTRITIS LIPOIDE (E78.8+)'},
{ codigo: 'M144', descripcion: 'ARTROPATIA EN LA AMILOIDOSIS (E85.-+)'},
{ codigo: 'M145', descripcion: 'ARTROPATIA EN OTROS TRASTORNOS ENDOCRINOS, METABOLICOS Y NUTRICIONALES'},
{ codigo: 'M146', descripcion: 'ARTROPATIA NEUROPATICA'},
{ codigo: 'M148', descripcion: 'ARTROPATIA EN OTRAS ENFERMEDADES ESPECIFICADAS, CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M15', descripcion: 'POLIARTROSIS'},
{ codigo: 'M150', descripcion: '(OSTEO)ARTROSIS PRIMARIA GENERALIZADA'},
{ codigo: 'M151', descripcion: 'NODULOS DE HEBERDEN (CON ARTROPATIA)'},
{ codigo: 'M152', descripcion: 'NODULOS DE BOUCHARD (CON ARTROPATIA)'},
{ codigo: 'M153', descripcion: 'ARTROSIS SECUNDARIA MULTIPLE'},
{ codigo: 'M154', descripcion: '(OSTEO)ARTROSIS EROSIVA'},
{ codigo: 'M158', descripcion: 'OTRAS POLIARTROSIS'},
{ codigo: 'M159', descripcion: 'POLIARTROSIS, NO ESPECIFICADA'},
{ codigo: 'M16', descripcion: 'COXARTROSIS (ARTROSIS DE LA CADERA)'},
{ codigo: 'M160', descripcion: 'COXARTROSIS PRIMARIA, BILATERAL'},
{ codigo: 'M161', descripcion: 'OTRAS COXARTROSIS PRIMARIAS'},
{ codigo: 'M162', descripcion: 'COXARTROSIS A CONSECUENCIA DE DISPLASIA, BILATERAL'},
{ codigo: 'M163', descripcion: 'OTRAS COXARTROSIS DISPLASICAS'},
{ codigo: 'M164', descripcion: 'COARTROSIS POSTRAUMATICA, BILATERAL'},
{ codigo: 'M165', descripcion: 'OTRA COXARTROSIS POSTRAUMATICA'},
{ codigo: 'M166', descripcion: 'OTRA COXARTROSIS SECUNDARIA, BILATERAL'},
{ codigo: 'M167', descripcion: 'OTRAS COXARTROSIS SECUNDARIAS'},
{ codigo: 'M169', descripcion: 'COXARTROSIS, NO ESPECIFICADA'},
{ codigo: 'M17', descripcion: 'GONARTROSIS (ARTROSIS DE LA RODILLA)'},
{ codigo: 'M170', descripcion: 'GONARTROSIS PRIMARIA, BILATERAL'},
{ codigo: 'M171', descripcion: 'OTRAS GONARTROSIS PRIMARIAS'},
{ codigo: 'M172', descripcion: 'GONARTROSIS POSTRAUMATICA, BILATERAL'},
{ codigo: 'M173', descripcion: 'OTRAS GONARTROSIS POSTRAUMATICAS'},
{ codigo: 'M174', descripcion: 'OTRAS GONARTROSIS SECUNDARIAS, BILATERALES'},
{ codigo: 'M175', descripcion: 'OTRAS GONARTROSIS SECUNDARIAS'},
{ codigo: 'M179', descripcion: 'GONARTROSIS, NO ESPECIFICADA'},
{ codigo: 'M18', descripcion: 'ARTROSIS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA'},
{ codigo: 'M180', descripcion: 'ARTROSIS PRIMARIA DE PRIMERA ARTICULACION CARPOMETACARPIANA, BILATERAL'},
{ codigo: 'M181', descripcion: 'OTRAS ARTROSIS PRIMARIAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA'},
{ codigo: 'M182', descripcion: 'ARTROSIS POSTRAUMATICA DE LA PRIMERA ARTICULACION CARPOMETACARPIANA BILATERAL'},
{ codigo: 'M183', descripcion: 'OTRAS ARTROSIS POSTRAUMATICAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA'},
{ codigo: 'M184', descripcion: 'OTRAS ARTROSIS SECUNDARIAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA, BILATERALES'},
{ codigo: 'M185', descripcion: 'OTRAS ARTROSIS SECUNDARIAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA'},
{ codigo: 'M189', descripcion: 'ARTROSIS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA, SIN OTRA ESPECIFICACION'},
{ codigo: 'M19', descripcion: 'OTRAS ARTROSIS'},
{ codigo: 'M190', descripcion: 'ARTROSIS PRIMARIA DE OTRAS ARTICULACIONES'},
{ codigo: 'M191', descripcion: 'ARTROSIS POSTRAUMATICA DE OTRAS ARTICULACIONES'},
{ codigo: 'M192', descripcion: 'ARTROSIS SECUNDARIA DE OTRAS ARTICULACIONES'},
{ codigo: 'M198', descripcion: 'OTRAS ARTROSIS ESPECIFICADAS'},
{ codigo: 'M199', descripcion: 'ARTROSIS, NO ESPECIFICADA'},
{ codigo: 'M20', descripcion: 'DEFORMIDADES ADQUIRIDAS DE LOS DEDOS DE LA MANO Y DEL PIE'},
{ codigo: 'M200', descripcion: 'DEFORMIDAD DE DEDO(S) DE LA MANO'},
{ codigo: 'M201', descripcion: 'HALLUX VALGUS (ADQUIRIDO)'},
{ codigo: 'M202', descripcion: 'HALLUX RIGIDUS'},
{ codigo: 'M203', descripcion: 'OTRAS DEFORMIDADES DEL HALLUX (ADQUIRIDAS)'},
{ codigo: 'M204', descripcion: 'OTRO(S) DEDO(S) DEL PIE EN MARTILLO (ADQUIRIDOS)'},
{ codigo: 'M205', descripcion: 'OTRAS DEFORMIDADES (ADQUIRIDAS) DEL (DE LOS) DEDO(S) DEL PIE'},
{ codigo: 'M206', descripcion: 'DEFORMIDADES ADQUIRIDAS DE LOS DEDOS DEL PIE, NO ESPECIFICADAS'},
{ codigo: 'M21', descripcion: 'OTRAS DEFORMIDADES ADQUIRIDAS DE LOS MIEMBROS'},
{ codigo: 'M210', descripcion: 'DEFORMIDAD EN VALGO, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M211', descripcion: 'DEFORMIDAD EN VARO, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M212', descripcion: 'DEFORMIDAD EN FLEXION'},
{ codigo: 'M213', descripcion: 'MU—ECA O PIE EN PENDULO (ADQUIRIDO)'},
{ codigo: 'M214', descripcion: 'PIE PLANO (PES PLANUS) (ADQUIRIDO)'},
{ codigo: 'M215', descripcion: 'MANO O PIE EN GARRA O EN TALIPES, PIE EQUINOVARO O ZAMBO ADQUIRIDOS'},
{ codigo: 'M216', descripcion: 'OTRAS DEFORMIDADES ADQUIRIDAS DEL TOBILLO Y DEL PIE'},
{ codigo: 'M217', descripcion: 'LONGITUD DESIGUAL DE LOS MIEMBROS (ADQUIRIDA)'},
{ codigo: 'M218', descripcion: 'OTRAS DEFORMIDADES ADQUIRIDAS DE LOS MIEMBROS, ESPECIFICADAS'},
{ codigo: 'M219', descripcion: 'DEFORMIDAD ADQUIRIDA DEL MIEMBRO, NO ESPECIFICADA'},
{ codigo: 'M22', descripcion: 'TRASTORNOS DE LA ROTULA'},
{ codigo: 'M220', descripcion: 'LUXACION RECIDIVANTE DE LA ROTULA'},
{ codigo: 'M221', descripcion: 'SUBLUXACION RECIDIVANTE DE LA ROTULA'},
{ codigo: 'M222', descripcion: 'TRASTORNOS ROTULOFEMORALES'},
{ codigo: 'M223', descripcion: 'OTROS DESARREGLOS DE LA ROTULA'},
{ codigo: 'M224', descripcion: 'CONDROMALACIA DE LA ROTULA'},
{ codigo: 'M228', descripcion: 'OTROS TRASTORNOS DE LA ROTULA'},
{ codigo: 'M229', descripcion: 'TRASTORNO DE LA ROTULA, NO ESPECIFICADO'},
{ codigo: 'M23', descripcion: 'TRASTORNO INTERNO DE LA RODILLA'},
{ codigo: 'M230', descripcion: 'MENISCO QUISTICO'},
{ codigo: 'M231', descripcion: 'MENISCO DISCOIDE (CONGENITO)'},
{ codigo: 'M232', descripcion: 'TRASTORNO DE MENISCO DEBIDO A DESGARRO O LESION ANTIGUA'},
{ codigo: 'M233', descripcion: 'OTROS TRASTORNOS DE LOS MENISCOS'},
{ codigo: 'M234', descripcion: 'CUERPO FLOTANTE EN LA RODILLA'},
{ codigo: 'M235', descripcion: 'INESTABILIDAD CRONICA DE LA RODILLA'},
{ codigo: 'M236', descripcion: 'OTRA RUPTURA ESPONTANEA DEL (DE LOS) LIGAMENTO(S) DE LA RODILLA'},
{ codigo: 'M238', descripcion: 'OTROS TRASTORNOS INTERNOS DE LA RODILLA'},
{ codigo: 'M239', descripcion: 'TRASTORNO INTERNO DE LA RODILLA, NO ESPECIFICADO'},
{ codigo: 'M24', descripcion: 'OTROS TRASTORNOS ARTICULARES ESPECIFICOS'},
{ codigo: 'M240', descripcion: 'CUERPO FLOTANTE ARTICULAR'},
{ codigo: 'M241', descripcion: 'OTROS TRASTORNOS DEL CARTILAGO ARTICULAR'},
{ codigo: 'M242', descripcion: 'TRASTORNO DEL LIGAMENTO'},
{ codigo: 'M243', descripcion: 'LUXACION Y SUBLUXACION PATOLOGICA DE LA ARTICULACION, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M244', descripcion: 'LUXACION Y SUBLUXACION RECIDIVANTE DE LA ARTICULACION'},
{ codigo: 'M245', descripcion: 'CONTRACTURA ARTICULAR'},
{ codigo: 'M246', descripcion: 'ANQUILOSIS ARTICULAR'},
{ codigo: 'M247', descripcion: 'PROTRUSION DE ACETABULO'},
{ codigo: 'M248', descripcion: 'OTRAS LESIONES ARTICULARES ESPECIFICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M249', descripcion: 'DESARREGLO ARTICULAR, NO ESPECIFICADO'},
{ codigo: 'M25', descripcion: 'OTROS TRASTORNOS ARTICULARES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'M250', descripcion: 'HEMARTROSIS'},
{ codigo: 'M251', descripcion: 'FISTULA ARTICULAR'},
{ codigo: 'M252', descripcion: 'ARTICULACION INESTABLE'},
{ codigo: 'M253', descripcion: 'OTRAS INESTABILIDADES ARTICULARES'},
{ codigo: 'M254', descripcion: 'DERRAME ARTICULAR'},
{ codigo: 'M255', descripcion: 'DOLOR EN ARTICULACION'},
{ codigo: 'M256', descripcion: 'RIGIDEZ ARTICULAR, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M257', descripcion: 'OSTEOFITO'},
{ codigo: 'M258', descripcion: 'OTROS TRASTORNOS ARTICULARES ESPECIFICADOS'},
{ codigo: 'M259', descripcion: 'TRASTORNO ARTICULAR, NO ESPECIFICADO'},
{ codigo: 'M30', descripcion: 'POLIARTERITIS NUDOSA Y AFECCIONES RELACIONADAS'},
{ codigo: 'M300', descripcion: 'POLIARTERITIS NUDOSA'},
{ codigo: 'M301', descripcion: 'POLIARTERITIS CON COMPROMISO PULMONAR (CHURG-STRAUSS)'},
{ codigo: 'M302', descripcion: 'POLIARTERITIS JUVENIL'},
{ codigo: 'M303', descripcion: 'SINDROME MUCOCUTANEO LINFONODULAR (KAWASAKI)'},
{ codigo: 'M308', descripcion: 'OTRAS AFECCIONES RELACIONADAS CON LA POLIARTERITIS NUDOSA'},
{ codigo: 'M31', descripcion: 'OTRAS VASCULOPATIAS NECROTIZANTES'},
{ codigo: 'M310', descripcion: 'ANGIITIS DEBIDA A HIPERSENSIBILIDAD'},
{ codigo: 'M311', descripcion: 'MICROANGIOPATIA TROMBOTICA'},
{ codigo: 'M312', descripcion: 'GRANULOMA LETAL DE LA LINEA MEDIA'},
{ codigo: 'M313', descripcion: 'GRANULOMATOSIS DE WEGENER'},
{ codigo: 'M314', descripcion: 'SINDROME DEL CAYADO DE LA AORTA (TAKAYASU)'},
{ codigo: 'M315', descripcion: 'ARTERITIS DE CELULAS GIGANTES CON POLIMIALGIA REUMATICA'},
{ codigo: 'M316', descripcion: 'OTRAS ARTERITIS DE CELULAS GIGANTES'},
{ codigo: 'M318', descripcion: 'OTRAS VASCULOPATIAS NECROTIZANTES ESPECIFICADAS'},
{ codigo: 'M319', descripcion: 'VASCULOPATIA NECROTIZANTE, NO ESPECIFICADA'},
{ codigo: 'M32', descripcion: 'LUPUS ERITEMATOSO SISTEMICO'},
{ codigo: 'M320', descripcion: 'LUPUS ERITEMATOSO SISTEMICO, INDUCIDO POR DROGAS'},
{ codigo: 'M321', descripcion: 'LUPUS ERITEMATOSO SISTEMICO CON COMPROMISO DE ORGANOS O SISTEMAS +'},
{ codigo: 'M328', descripcion: 'OTRAS FORMAS DE LUPUS ERITEMATOSO SISTEMICO'},
{ codigo: 'M329', descripcion: 'LUPUS ERITEMATOSO SISTEMICO, SIN OTRA ESPECIFICACION'},
{ codigo: 'M33', descripcion: 'DERMATOPOLIMIOSITIS'},
{ codigo: 'M330', descripcion: 'DERMATOMIOSITIS JUVENIL'},
{ codigo: 'M331', descripcion: 'OTRAS DERMATOMIOSITIS'},
{ codigo: 'M332', descripcion: 'POLIMIOSITIS'},
{ codigo: 'M339', descripcion: 'DERMATOPOLIMIOSITIS, NO ESPECIFICADA'},
{ codigo: 'M34', descripcion: 'ESCLEROSIS SISTEMICA'},
{ codigo: 'M340', descripcion: 'ESCLEROSIS SISTEMICA PROGRESIVA'},
{ codigo: 'M341', descripcion: 'SINDROME CR(E)ST'},
{ codigo: 'M342', descripcion: 'ESCLEROSIS SISTEMICA INDUCIDA POR DROGAS O PRODUCTOS QUIMICOS'},
{ codigo: 'M348', descripcion: 'OTRAS FORMAS DE ESCLEROSIS SISTEMICA'},
{ codigo: 'M349', descripcion: 'ESCLEROSIS SISTEMICA, NO ESPECIFICADA'},
{ codigo: 'M35', descripcion: 'OTRO COMPROMISO SISTEMICO DEL TEJIDO CONJUNTIVO'},
{ codigo: 'M350', descripcion: 'SINDROME SECO [SJOGREN]'},
{ codigo: 'M351', descripcion: 'OTROS SINDROMES SUPERPUESTOS'},
{ codigo: 'M352', descripcion: 'ENFERMEDAD DE BEH«ET'},
{ codigo: 'M353', descripcion: 'POLIMIALGIA REUMATICA'},
{ codigo: 'M354', descripcion: 'FASCITIS DIFUSA (EOSINOFILICA)'},
{ codigo: 'M355', descripcion: 'FIBROSCLEROSIS MULTIFOCAL'},
{ codigo: 'M356', descripcion: 'PANICULITIS RECIDIVANTE (WEBER-CHRISTIAN)'},
{ codigo: 'M357', descripcion: 'SINDROME DE HIPERMOVILIDAD'},
{ codigo: 'M358', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS CON COMPROMISO SISTEMICO DEL TEJIDO CONJUNTIVO'},
{ codigo: 'M359', descripcion: 'COMPROMISO SISTEMICO DEL TEJIDO CONJUNTIVO, NO ESPECIFICADO'},
{ codigo: 'M36', descripcion: 'TRASTORNOS SISTEMICOS DEL TEJIDO CONJUNTIVO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M360', descripcion: 'DERMATO(POLI)MIOSITIS EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'M361', descripcion: 'ARTROPATIA EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'M362', descripcion: 'ARTROPATIA HEMOFILICA (D66-D68+)'},
{ codigo: 'M363', descripcion: 'ARTROPATIA EN OTROS TRASTORNOS DE LA SANGRE (D50-D76+)'},
{ codigo: 'M364', descripcion: 'ARTROPATIA EN REACCIONES DE HIPERSENSIBILIDAD CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M368', descripcion: 'TRASTORNOS SISTEMICOS DEL TEJIDO CONJUNTIVO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M40', descripcion: 'CIFOSIS Y LORDOSIS'},
{ codigo: 'M400', descripcion: 'CIFOSIS POSTURAL'},
{ codigo: 'M401', descripcion: 'OTRAS CIFOSIS SECUNDARIAS'},
{ codigo: 'M402', descripcion: 'OTRAS CIFOSIS Y LAS NO ESPECIFICADAS'},
{ codigo: 'M403', descripcion: 'SINDROME DE ESPALDA PLANA'},
{ codigo: 'M404', descripcion: 'OTRAS LORDOSIS'},
{ codigo: 'M405', descripcion: 'LORDOSIS, NO ESPECIFICADA'},
{ codigo: 'M41', descripcion: 'ESCOLIOSIS'},
{ codigo: 'M410', descripcion: 'ESCOLIOSIS IDIOPATICA INFANTIL'},
{ codigo: 'M411', descripcion: 'ESCOLIOSIS IDIOPATICA JUVENIL'},
{ codigo: 'M412', descripcion: 'OTRAS ESCOLIOSIS IDIOPATICAS'},
{ codigo: 'M413', descripcion: 'ESCOLIOSIS TORACOGENICA'},
{ codigo: 'M414', descripcion: 'ESCOLIOSIS NEUROMUSCULAR'},
{ codigo: 'M415', descripcion: 'OTRAS ESCOLIOSIS SECUNDARIAS'},
{ codigo: 'M418', descripcion: 'OTRAS FORMAS DE ESCOLIOSIS'},
{ codigo: 'M419', descripcion: 'ESCOLIOSIS, NO ESPECIFICADA'},
{ codigo: 'M42', descripcion: 'OSTEOCONDROSIS DE LA COLUMNA VERTEBRAL'},
{ codigo: 'M420', descripcion: 'OSTEOCONDROSIS JUVENIL DE LA COLUMNA VERTEBRAL'},
{ codigo: 'M421', descripcion: 'OSTEOCONDROSIS DE LA COLUMNA VERTEBRAL DEL ADULTO'},
{ codigo: 'M429', descripcion: 'OSTEOCONDROSIS VERTEBRAL, NO ESPECIFICADA'},
{ codigo: 'M43', descripcion: 'OTRAS DORSOPATIAS DEFORMANTES'},
{ codigo: 'M430', descripcion: 'ESPONDILOLISIS'},
{ codigo: 'M431', descripcion: 'ESPONDILOLISTESIS'},
{ codigo: 'M432', descripcion: 'OTRAS FUSIONES DE LA COLUMNA VERTEBRAL'},
{ codigo: 'M433', descripcion: 'SUBLUXACION ATLANTO-AXOIDEA RECURRENTE, CON MIELOPATIA'},
{ codigo: 'M434', descripcion: 'OTRAS SUBLUXACIONES ATLANTO-AXOIDEAS RECURRENTES'},
{ codigo: 'M435', descripcion: 'OTRAS SUBLUXACIONES VERTEBRALES RECURRENTES'},
{ codigo: 'M436', descripcion: 'TORTICOLIS'},
{ codigo: 'M438', descripcion: 'OTRAS DORSOPATIAS DEFORMANTES DE LA COLUMNA VERTEBRAL ESPECIFICADAS'},
{ codigo: 'M439', descripcion: 'DORSOPATIA DEFORMANTE, NO ESPECIFICADA'},
{ codigo: 'M45', descripcion: 'ESPONDILITIS ANQUILOSANTE'},
{ codigo: 'M46', descripcion: 'OTRAS ESPONDILOPATIAS INFLAMATORIAS'},
{ codigo: 'M460', descripcion: 'ENTESOPATIA VERTEBRAL'},
{ codigo: 'M461', descripcion: 'SACROILIITIS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M462', descripcion: 'OSTEOMIELITIS DE VERTEBRA'},
{ codigo: 'M463', descripcion: 'INFECCION DE DISCO INTERVERTEBRAL (PIOGENA)'},
{ codigo: 'M464', descripcion: 'DISCITIS, NO ESPECIFICADA'},
{ codigo: 'M465', descripcion: 'OTRAS ESPONDILOPATIAS INFECCIOSAS'},
{ codigo: 'M468', descripcion: 'OTRAS ESPONDILOPATIAS INFLAMATORIAS ESPECIFICADAS'},
{ codigo: 'M469', descripcion: 'ESPONDILOPATIA INFLAMATORIA, NO ESPECIFICADA'},
{ codigo: 'M47', descripcion: 'ESPONDILOSIS'},
{ codigo: 'M470', descripcion: 'SINDROMES DE COMPRESION ARTERIA ESPINAL O VERTEBRAL ANTERIOR (G99.2*)'},
{ codigo: 'M471', descripcion: 'OTRAS ESPONDILOSIS CON MIELOPATIA'},
{ codigo: 'M472', descripcion: 'OTRAS ESPONDILOSIS CON RADICULOPATIA'},
{ codigo: 'M478', descripcion: 'OTRAS ESPONDILOSIS'},
{ codigo: 'M479', descripcion: 'ESPONDILOSIS, NO ESPECIFICADA'},
{ codigo: 'M48', descripcion: 'OTRAS ESPONDILOPATIAS'},
{ codigo: 'M480', descripcion: 'ESTENOSIS ESPINAL'},
{ codigo: 'M481', descripcion: 'HIPEROSTOSIS ANQUILOSANTE (FORESTIER)'},
{ codigo: 'M482', descripcion: '"ESPONDILOPATIA INTERESPINOSA (VERTEBRAS ""EN BESO"")"'},
{ codigo: 'M483', descripcion: 'ESPONDILOPATIA TRAUMATICA'},
{ codigo: 'M484', descripcion: 'FRACTURA DE VERTEBRA POR FATIGA'},
{ codigo: 'M485', descripcion: 'VERTEBRA COLAPSADA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M488', descripcion: 'OTRAS ESPONDILOPATIAS ESPECIFICADAS'},
{ codigo: 'M489', descripcion: 'ESPONDILOPATIA, NO ESPECIFICADA'},
{ codigo: 'M49', descripcion: 'ESPONDILOPATIAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M490', descripcion: 'TUBERCULOSIS DE LA COLUMNA VERTEBRAL (A18.0+) *'},
{ codigo: 'M491', descripcion: 'ESPONDILITIS POR BRUCELOSIS (A23.-+) *'},
{ codigo: 'M492', descripcion: 'ESPONDILITIS POR ENTEROBACTERIAS (A01-A04+) *'},
{ codigo: 'M493', descripcion: 'ESPONDILOPATIA EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M494', descripcion: 'ESPONDILOPATIA NEUROPATICA'},
{ codigo: 'M495', descripcion: 'VERTEBRA COLAPSADA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M498', descripcion: 'ESPONDILOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M50', descripcion: 'TRASTORNOS DE DISCO CERVICAL'},
{ codigo: 'M500', descripcion: 'TRASTORNO DE DISCO CERVICAL CON MIELOPATIA (G99.2*)'},
{ codigo: 'M501', descripcion: 'TRASTORNO DE DISCO CERVICAL CON RADICULOPATIA'},
{ codigo: 'M502', descripcion: 'OTROS DESPLAZAMIENTOS DE DISCO CERVICAL'},
{ codigo: 'M503', descripcion: 'OTRAS DEGENERACIONES DE DISCO CERVICAL'},
{ codigo: 'M508', descripcion: 'OTROS TRASTORNOS DE DISCO CERVICAL'},
{ codigo: 'M509', descripcion: 'TRASTORNO DE DISCO CERVICAL, NO ESPECIFICADO'},
{ codigo: 'M51', descripcion: 'OTROS TRASTORNOS DE LOS DISCOS INTERVERTEBRALES'},
{ codigo: 'M510', descripcion: 'TRASTORNOS DE DISCOS INTERVERTEBRALES LUMBARES Y OTROS, MIELOPATIA (G99.2*'},
{ codigo: 'M511', descripcion: 'TRASTORNOS DE DISCO LUMBAR Y OTROS, CON RADICULOPATIA'},
{ codigo: 'M512', descripcion: 'OTROS DESPLAZAMIENTOS ESPECIFICADOS DE DISCO INTERVERTEBRAL'},
{ codigo: 'M513', descripcion: 'OTRAS DEGENERACIONES ESPECIFICADAS DE DISCO INTERVERTEBRAL'},
{ codigo: 'M514', descripcion: 'NODULOS DE SCHMORL'},
{ codigo: 'M518', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DISCOS INTERVERTEBRALES'},
{ codigo: 'M519', descripcion: 'TRASTORNOS DISCOS INTERVERTEBRALES NO ESPECIFICADOS'},
{ codigo: 'M53', descripcion: 'OTRAS DORSOPATIAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M530', descripcion: 'SINDROME CERVICOCRANEAL'},
{ codigo: 'M531', descripcion: 'SINDROME CERVICOBRAQUIAL'},
{ codigo: 'M532', descripcion: 'INESTABILIDAD DE LA COLUMNA VERTEBRAL'},
{ codigo: 'M533', descripcion: 'TRASTORNOS SACROCOCCIGEOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'M538', descripcion: 'OTRAS DORSOPATIAS ESPECIFICADAS'},
{ codigo: 'M539', descripcion: 'DORSOPATIA, NO ESPECIFICADA'},
{ codigo: 'M54', descripcion: 'DORSALGIA'},
{ codigo: 'M540', descripcion: 'PANICULITIS QUE AFECTA REGIONES DEL CUELLO Y DE LA ESPALDA'},
{ codigo: 'M541', descripcion: 'RADICULOPATIA'},
{ codigo: 'M542', descripcion: 'CERVICALGIA'},
{ codigo: 'M543', descripcion: 'CIATICA'},
{ codigo: 'M544', descripcion: 'LUMBAGO CON CIATICA'},
{ codigo: 'M545', descripcion: 'LUMBAGO NO ESPECIFICADO'},
{ codigo: 'M546', descripcion: 'DOLOR EN LA COLUMNA DORSAL'},
{ codigo: 'M548', descripcion: 'OTRAS DORSALGIAS'},
{ codigo: 'M549', descripcion: 'DORSALGIA, NO ESPECIFICADA'},
{ codigo: 'M60', descripcion: 'MIOSITIS'},
{ codigo: 'M600', descripcion: 'MIOSITIS INFECCIOSA'},
{ codigo: 'M601', descripcion: 'MIOSITIS INTERSTICIAL'},
{ codigo: 'M602', descripcion: 'GRANULOMA POR CUERPO EXTRA—O EN TEJIDO BLANDO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M608', descripcion: 'OTRAS MIOSITIS'},
{ codigo: 'M609', descripcion: 'MIOSITIS, NO ESPECIFICADA'},
{ codigo: 'M61', descripcion: 'CALCIFICACION Y OSIFICACION DEL MUSCULO'},
{ codigo: 'M610', descripcion: 'MIOSITIS OSIFICANTE TRAUMATICA'},
{ codigo: 'M611', descripcion: 'MIOSITIS OSIFICANTE PROGRESIVA'},
{ codigo: 'M612', descripcion: 'CALCIFICACION Y OSIFICACION PARALITICA DEL MUSCULO'},
{ codigo: 'M613', descripcion: 'CALCIFICACION Y OSIFICACION DE LOS MUSCULOS ASOCIADAS CON QUEMADURAS'},
{ codigo: 'M614', descripcion: 'OTRAS CALCIFICACIONES DEL MUSCULO'},
{ codigo: 'M615', descripcion: 'OTRAS OSIFICACIONES DEL MUSCULO'},
{ codigo: 'M619', descripcion: 'CALCIFICACION Y OSIFICACION DEL MUSCULO, NO ESPECIFICADA'},
{ codigo: 'M62', descripcion: 'OTROS TRASTORNOS DE LOS MUSCULOS'},
{ codigo: 'M620', descripcion: 'DIASTASIS DEL MUSCULO'},
{ codigo: 'M621', descripcion: 'OTROS DESGARROS (NO TRAUMATICOS) DEL MUSCULO'},
{ codigo: 'M622', descripcion: 'INFARTO ISQUEMICO DEL MUSCULO'},
{ codigo: 'M623', descripcion: 'SINDROME DE INMOVILIDAD (PARAPLEJICO)'},
{ codigo: 'M624', descripcion: 'CONTRACTURA MUSCULAR'},
{ codigo: 'M625', descripcion: 'ATROFIA Y DESGASTE MUSCULARES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'M626', descripcion: 'DISTENSION MUSCULAR'},
{ codigo: 'M628', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LOS MUSCULOS'},
{ codigo: 'M629', descripcion: 'TRASTORNO MUSCULAR, NO ESPECIFICADO'},
{ codigo: 'M63', descripcion: 'TRASTORNOS DE LOS MUSCULOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M630', descripcion: 'MIOSITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M631', descripcion: 'MIOSITIS EN INFECCIONES POR PROTOZOARIOS Y PARASITOS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M632', descripcion: 'MIOSITIS EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M633', descripcion: 'MIOSITIS EN SARCOIDOSIS (D86.8+)'},
{ codigo: 'M638', descripcion: 'OTROS TRASTORNOS DE LOS MUSCULOS EN ENFERMEDADES CEOP'},
{ codigo: 'M65', descripcion: 'SINOVITIS Y TENOSINOVITIS'},
{ codigo: 'M650', descripcion: 'ABSCESO DE VAINA TENDINOSA'},
{ codigo: 'M651', descripcion: 'OTRAS (TENO)SINOVITIS INFECCIOSAS'},
{ codigo: 'M652', descripcion: 'TENDINITIS CALCIFICADA'},
{ codigo: 'M653', descripcion: 'DEDO EN GATILLO'},
{ codigo: 'M654', descripcion: 'TENOSINOVITIS DE ESTILOIDES RADIAL (DE QUERVAIN)'},
{ codigo: 'M658', descripcion: 'OTRAS SINOVITIS Y TENOSINOVITIS'},
{ codigo: 'M659', descripcion: 'SINOVITIS Y TENOSINOVITIS, NO ESPECIFICADA'},
{ codigo: 'M66', descripcion: 'RUPTURA ESPONTANEA DE LA SINOVIA Y DEL TENDON'},
{ codigo: 'M660', descripcion: 'RUPTURA DE QUISTE SINOVIAL POPLITEO'},
{ codigo: 'M661', descripcion: 'RUPTURA DE LA SINOVIA'},
{ codigo: 'M662', descripcion: 'RUPTURA ESPONTANEA DE TENDONES EXTENSORES'},
{ codigo: 'M663', descripcion: 'RUPTURA ESPONTANEA DE TENDONES FLEXORES'},
{ codigo: 'M664', descripcion: 'RUPTURA ESPONTANEA DE OTROS TENDONES'},
{ codigo: 'M665', descripcion: 'RUPTURA ESPONTANEA DE TENDON NO ESPECIFICADO'},
{ codigo: 'M67', descripcion: 'OTROS TRASTORNOS DE LA SINOVIA Y DEL TENDON'},
{ codigo: 'M670', descripcion: 'ACORTAMIENTO DEL TENDON DE AQUILES (ADQUIRIDO)'},
{ codigo: 'M671', descripcion: 'OTRAS CONTRACTURAS DE TENDON (VAINA)'},
{ codigo: 'M672', descripcion: 'HIPERTROFIA SINOVIAL, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M673', descripcion: 'SINOVITIS TRANSITORIA'},
{ codigo: 'M674', descripcion: 'GANGLION'},
{ codigo: 'M678', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA SINOVIA Y DEL TENDON'},
{ codigo: 'M679', descripcion: 'TRASTORNO SINOVIAL Y TENDINOSO, NO ESPECIFICADO'},
{ codigo: 'M68', descripcion: 'TRASTORNOS DE LOS TENDONES Y DE LA SINOVIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M680', descripcion: 'SINOVITIS Y TENOSINOVITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M688', descripcion: 'OTROS TRASTORNOS SINOVIALES Y TENDINOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M70', descripcion: 'TRASTORNOS DE LOS TEJIDOS BLANDOS RELACIONADOS CON EL USO, EL USO EXCESIVO Y LA PRESION'},
{ codigo: 'M700', descripcion: 'SINOVITIS CREPITANTE CRONICA DE LA MANO Y DE LA MU—ECA'},
{ codigo: 'M701', descripcion: 'BURSITIS DE LA MANO'},
{ codigo: 'M702', descripcion: 'BURSITIS DEL OLECRANON'},
{ codigo: 'M703', descripcion: 'OTRAS BURSITIS DEL CODO'},
{ codigo: 'M704', descripcion: 'OTRAS BURSITIS PRERROTULIANAS'},
{ codigo: 'M705', descripcion: 'OTRAS BURSITIS DE LA RODILLA'},
{ codigo: 'M706', descripcion: 'BURSITIS DEL TROCANTER'},
{ codigo: 'M707', descripcion: 'OTRAS BURSITIS DE LA CADERA'},
{ codigo: 'M708', descripcion: 'OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS RELACIONADOS CON EL USO, EL USO EXCESIVO Y PRESION'},
{ codigo: 'M709', descripcion: 'TRASTORNOS NO ESPECIFICADOS DE LOS TEJIDOS BLANDOS RELACIONADOS CON EL USO, EL USO EXCESIVO Y PRESION'},
{ codigo: 'M71', descripcion: 'OTRAS BURSOPATIAS'},
{ codigo: 'M710', descripcion: 'ABSCESO DE LA BOLSA SINOVIAL'},
{ codigo: 'M711', descripcion: 'OTRAS BURSITIS INFECCIOSAS'},
{ codigo: 'M712', descripcion: 'QUISTE SINOVIAL DEL HUECO POPLITEO (DE BAKER)'},
{ codigo: 'M713', descripcion: 'OTROS QUISTES DE LA BOLSA SEROSA'},
{ codigo: 'M714', descripcion: 'DEPOSITO DE CALCIO EN LA BOLSA SEROSA'},
{ codigo: 'M715', descripcion: 'OTRAS BURSITIS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M718', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA BOLSA SEROSA'},
{ codigo: 'M719', descripcion: 'BURSOPATIA, NO ESPECIFICADA'},
{ codigo: 'M72', descripcion: 'TRASTORNOS FIBROBLASTICOS'},
{ codigo: 'M720', descripcion: 'FIBROMATOSIS DE LA APONEUROSIS PALMAR (DUPUYTREN)'},
{ codigo: 'M721', descripcion: 'NODULOS INTERFALANGICOS'},
{ codigo: 'M722', descripcion: 'FIBROMATOSIS DE LA APONEUROSIS PLANTAR'},
{ codigo: 'M723', descripcion: 'FASCITIS NODULAR'},
{ codigo: 'M724', descripcion: 'FIBROMATOSIS SEUDOSARCOMATOSA'},
{ codigo: 'M725', descripcion: 'FASCITIS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M728', descripcion: 'OTROS TRASTORNOS FIBROBLASTICOS'},
{ codigo: 'M729', descripcion: 'TRASTORNO FIBROBLASTICO, NO ESPECIFICADO'},
{ codigo: 'M73', descripcion: 'TRASTORNOS DE LOS TEJIDOS BLANDOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M730', descripcion: 'BURSITIS GONOCOCICA (A54.4+)'},
{ codigo: 'M731', descripcion: 'BURSITIS SIFILITICA (A52.7+)'},
{ codigo: 'M738', descripcion: 'OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M75', descripcion: 'LESIONES DEL HOMBRO'},
{ codigo: 'M750', descripcion: 'CAPSULITIS ADHESIVA DEL HOMBRO'},
{ codigo: 'M751', descripcion: 'SINDROME DEL MANGUITO ROTATORIO'},
{ codigo: 'M752', descripcion: 'TENDINITIS DEL BICEPS'},
{ codigo: 'M753', descripcion: 'TENDINITIS CALCIFICANTE DEL HOMBRO'},
{ codigo: 'M754', descripcion: 'SINDROME DE ABDUCCION DOLOROSA DEL HOMBRO'},
{ codigo: 'M755', descripcion: 'BURSITIS DEL HOMBRO'},
{ codigo: 'M758', descripcion: 'OTRAS LESIONES DEL HOMBRO'},
{ codigo: 'M759', descripcion: 'LESION DEL HOMBRO, NO ESPECIFICADA'},
{ codigo: 'M76', descripcion: 'ENTESOPATIAS DEL MIEMBRO INFERIOR, EXCLUIDO EL PIE'},
{ codigo: 'M760', descripcion: 'TENDINITIS DEL GLUTEO'},
{ codigo: 'M761', descripcion: 'TENDINITIS DEL PSOAS'},
{ codigo: 'M762', descripcion: 'ESPOLON DE LA CRESTA ILIACA'},
{ codigo: 'M763', descripcion: 'SINDROME DEL TENDON DEL TENSOR DE LA FASCIA LATA'},
{ codigo: 'M764', descripcion: 'BURSITIS TIBIAL COLATERAL (PELLEGRINI-STIEDA)'},
{ codigo: 'M765', descripcion: 'TENDINITIS ROTULIANA'},
{ codigo: 'M766', descripcion: 'TENDINITIS AQUILIANA'},
{ codigo: 'M767', descripcion: 'TENDINITIS PERONEAL'},
{ codigo: 'M768', descripcion: 'OTRAS ENTESOPATIAS DEL MIEMBRO INFERIOR, EXCLUIDO EL PIE'},
{ codigo: 'M769', descripcion: 'ENTESOPATIA DEL MIEMBRO INFERIOR, NO ESPECIFICADA'},
{ codigo: 'M77', descripcion: 'OTRAS ENTESOPATIAS'},
{ codigo: 'M770', descripcion: 'EPICONDILITIS MEDIA'},
{ codigo: 'M771', descripcion: 'EPICONDILITIS LATERAL'},
{ codigo: 'M772', descripcion: 'PERIARTRITIS DE LA MU—ECA'},
{ codigo: 'M773', descripcion: 'ESPOLON CALCANEO'},
{ codigo: 'M774', descripcion: 'METATARSALGIA'},
{ codigo: 'M775', descripcion: 'OTRAS ENTESOPATIAS DEL PIE'},
{ codigo: 'M778', descripcion: 'OTRAS ENTESOPATIAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M779', descripcion: 'ENTESOPATIA, NO ESPECIFICADA'},
{ codigo: 'M79', descripcion: 'OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'M790', descripcion: 'REUMATISMO, NO ESPECIFICADO'},
{ codigo: 'M791', descripcion: 'MIALGIA'},
{ codigo: 'M792', descripcion: 'NEURALGIA Y NEURITIS, NO ESPECIFICADAS'},
{ codigo: 'M793', descripcion: 'PANICULITIS, NO ESPECIFICADA'},
{ codigo: 'M794', descripcion: 'HIPERTROFIA DE PAQUETE ADIPOSO (INFRARROTULIANO)'},
{ codigo: 'M795', descripcion: 'CUERPO EXTRA—O RESIDUAL EN TEJIDO BLANDO'},
{ codigo: 'M796', descripcion: 'DOLOR EN MIEMBRO'},
{ codigo: 'M798', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LOS TEJIDOS BLANDOS'},
{ codigo: 'M799', descripcion: 'TRASTORNO DE LOS TEJIDOS BLANDOS, NO ESPECIFICADO'},
{ codigo: 'M80', descripcion: 'OSTEOPOROSIS CON FRACTURA PATOLOGICA'},
{ codigo: 'M800', descripcion: 'OSTEOPOROSIS POSTMENOPAUSICA, CON FRACTURA PATOLOGICA'},
{ codigo: 'M801', descripcion: 'OSTEOPOROSIS POSTOOFORECTOMIA, CON FRACTURA PATOLOGICA'},
{ codigo: 'M802', descripcion: 'OSTEOPOROSIS POR DESUSO, CON FRACTURA PATOLOGICA'},
{ codigo: 'M803', descripcion: 'OSTEOPOROSIS POR MALABSORCION POSTQUIRURGICA, CON FRACTURA PATOLOGICA'},
{ codigo: 'M804', descripcion: 'OSTEOPOROSIS INDUCIDA POR DROGAS, CON FRACTURA PATOLOGICA'},
{ codigo: 'M805', descripcion: 'OSTEOPOROSIS IDIOPATICA, CON FRACTURA PATOLOGICA'},
{ codigo: 'M808', descripcion: 'OTRAS OSTEOPOROSIS, CON FRACTURA PATOLOGICA'},
{ codigo: 'M809', descripcion: 'OSTEOPOROSIS NO ESPECIFICADA, CON FRACTURA PATOLOGICA'},
{ codigo: 'M81', descripcion: 'OSTEOPOROSIS SIN FRACTURA PATOLOGICA'},
{ codigo: 'M810', descripcion: 'OSTEOPOROSIS POSTMENOPAUSICA, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M811', descripcion: 'OSTEOPOROSIS POSTOOFORECTOMIA, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M812', descripcion: 'OSTEOPOROSIS POR DESUSO, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M813', descripcion: 'OSTEOPOROSIS POR MALABSORCION POSTQUIRURGICA, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M814', descripcion: 'OSTEOPOROSIS INDUCIDA POR DROGAS, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M815', descripcion: 'OSTEOPOROSIS IDIOPATICA, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M816', descripcion: 'OSTEOPOROSIS LOCALIZADA [LEQUESNE], SIN FRACTURA PATOLOGICA'},
{ codigo: 'M818', descripcion: 'OTRAS OSTEOPOROSIS, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M819', descripcion: 'OSTEOPOROSIS NO ESPECIFICADA, SIN FRACTURA PATOLOGICA'},
{ codigo: 'M82', descripcion: 'OSTEOPOROSIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M820', descripcion: 'OSTEOPOROSIS EN MIELOMATOSIS MULTIPLE (C90.0+)'},
{ codigo: 'M821', descripcion: 'OSTEOPOROSIS EN TRASTORNOS ENDOCRINOS (E00-E34+)'},
{ codigo: 'M828', descripcion: 'OSTEOPOROSIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M83', descripcion: 'OSTEOMALACIA DEL ADULTO'},
{ codigo: 'M830', descripcion: 'OSTEOMALACIA PUERPERAL'},
{ codigo: 'M831', descripcion: 'OSTEOMALACIA SENIL'},
{ codigo: 'M832', descripcion: 'OSTEOMALACIA DEL ADULTO DEBIDA A MALABSORCION'},
{ codigo: 'M833', descripcion: 'OSTEOMALACIA DEL ADULTO DEBIDA A DESNUTRICION'},
{ codigo: 'M834', descripcion: 'ENFERMEDAD DE LOS HUESOS POR ALUMINIO'},
{ codigo: 'M835', descripcion: 'OTRAS OSTEOMALACIAS DEL ADULTO INDUCIDAS POR DROGAS'},
{ codigo: 'M838', descripcion: 'OTRAS OSTEOMALACIAS DEL ADULTO'},
{ codigo: 'M839', descripcion: 'OSTEOMALACIA DEL ADULTO, NO ESPECIFICADA'},
{ codigo: 'M84', descripcion: 'TRASTORNOS DE LA CONTINUIDAD DEL HUESO'},
{ codigo: 'M840', descripcion: 'CONSOLIDACION DEFECTUOSA DE FRACTURA'},
{ codigo: 'M841', descripcion: 'FALTA DE CONSOLIDACION DE FRACTURA (SEUDOARTROSIS)'},
{ codigo: 'M842', descripcion: 'CONSOLIDACION RETARDADA DE FRACTURA'},
{ codigo: 'M843', descripcion: 'FRACTURA POR TENSION, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M844', descripcion: 'FRACTURA PATOLOGICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'M848', descripcion: 'OTROS TRASTORNOS DE LA CONTINUIDAD DEL HUESO'},
{ codigo: 'M849', descripcion: 'TRASTORNO DE LA CONTINUIDAD DEL HUESO, NO ESPECIFICADO'},
{ codigo: 'M85', descripcion: 'OTROS TRASTORNOS DE LA DENSIDAD Y DE LA ESTRUCTURA OSEAS'},
{ codigo: 'M850', descripcion: 'DISPLASIA FIBROSA (MONOSTOTICA)'},
{ codigo: 'M851', descripcion: 'FLUOROSIS DEL ESQUELETO'},
{ codigo: 'M852', descripcion: 'HIPEROSTOSIS DEL CRANEO'},
{ codigo: 'M853', descripcion: 'OSTEITIS CONDENSANTE'},
{ codigo: 'M854', descripcion: 'QUISTE OSEO SOLITARIO'},
{ codigo: 'M855', descripcion: 'QUISTE OSEO ANEURISMATICO'},
{ codigo: 'M856', descripcion: 'OTROS QUISTES OSEOS'},
{ codigo: 'M858', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA DENSIDAD Y DE LA ESTRUCTURA OSEAS'},
{ codigo: 'M859', descripcion: 'TRASTORNO DE LA DENSIDAD Y DE LA ESTRUCTURA OSEAS, NO ESPECIFICADO'},
{ codigo: 'M86', descripcion: 'OSTEOMIELITIS'},
{ codigo: 'M860', descripcion: 'OSTEOMIELITIS HEMATOGENA AGUDA'},
{ codigo: 'M861', descripcion: 'OTRAS OSTEOMIELITIS AGUDAS'},
{ codigo: 'M862', descripcion: 'OSTEOMIELITIS SUBAGUDA'},
{ codigo: 'M863', descripcion: 'OSTEOMIELITIS MULTIFOCAL CRONICA'},
{ codigo: 'M864', descripcion: 'OSTEOMIELITIS CRONICA CON DRENAJE DEL SENO'},
{ codigo: 'M865', descripcion: 'OTRAS OSTEOMIELITIS HEMATOGENAS CRONICAS'},
{ codigo: 'M866', descripcion: 'OTRAS OSTEOMIELITIS CRONICAS'},
{ codigo: 'M868', descripcion: 'OTRAS OSTEOMIELITIS'},
{ codigo: 'M869', descripcion: 'OSTEOMIELITIS, NO ESPECIFICADA'},
{ codigo: 'M87', descripcion: 'OSTEONECROSIS'},
{ codigo: 'M870', descripcion: 'NECROSIS ASEPTICA IDIOPATICA OSEA'},
{ codigo: 'M871', descripcion: 'OSTEONECROSIS DEBIDA A DROGAS'},
{ codigo: 'M872', descripcion: 'OSTEONECROSIS DEBIDA A TRAUMATISMO PREVIO'},
{ codigo: 'M873', descripcion: 'OTRAS OSTEONECROSIS SECUNDARIAS'},
{ codigo: 'M878', descripcion: 'OTRAS OSTEONECROSIS'},
{ codigo: 'M879', descripcion: 'OSTEONECROSIS, NO ESPECIFICADA'},
{ codigo: 'M88', descripcion: 'ENFERMEDAD DE PAGET DE LOS HUESOS (OSTEITIS DEFORMANTE)'},
{ codigo: 'M880', descripcion: 'ENFERMEDAD DE PAGET DEL CRANEO'},
{ codigo: 'M888', descripcion: 'ENFERMEDAD DE PAGET DE OTROS HUESOS'},
{ codigo: 'M889', descripcion: 'ENFERMEDAD OSEA DE PAGET, HUESOS NO ESPECIFICADOS'},
{ codigo: 'M89', descripcion: 'OTROS TRASTORNOS DEL HUESO'},
{ codigo: 'M890', descripcion: 'ALGONEURODISTROFIA'},
{ codigo: 'M891', descripcion: 'DETENCION DEL CRECIMIENTO EPIFISARIO'},
{ codigo: 'M892', descripcion: 'OTROS TRASTORNOS DEL DESARROLLO Y CRECIMIENTO OSEO'},
{ codigo: 'M893', descripcion: 'HIPERTROFIA DEL HUESO'},
{ codigo: 'M894', descripcion: 'OTRAS OSTEOARTROPATIAS HIPERTROFICAS'},
{ codigo: 'M895', descripcion: 'OSTEOLISIS'},
{ codigo: 'M896', descripcion: 'OSTEOPATIA A CONSECUENCIA DE POLIOMIELITIS'},
{ codigo: 'M898', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL HUESO'},
{ codigo: 'M899', descripcion: 'TRASTORNO DEL HUESO, NO ESPECIFICADO'},
{ codigo: 'M90', descripcion: 'OSTEOPATIAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M900', descripcion: 'TUBERCULOSIS OSEA (A18.0+) *'},
{ codigo: 'M901', descripcion: 'PERIOSTITIS EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE *'},
{ codigo: 'M902', descripcion: 'OSTEOPATIA EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M903', descripcion: 'OSTEONECROSIS EN LA ENFERMEDAD CAUSADA POR DESCOMPRESION (T70.3+)'},
{ codigo: 'M904', descripcion: 'OSTEONECROSIS DEBIDA A HEMOGLOBINOPATIA (D50-D64+)'},
{ codigo: 'M905', descripcion: 'OSTEONECROSIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M906', descripcion: 'OSTEITIS DEFORMANTE EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'M907', descripcion: 'FRACTURA OSEA EN ENFERMEDAD NEOPLASICA (C00-D48+)'},
{ codigo: 'M908', descripcion: 'OSTEOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M91', descripcion: 'OSTEOCONDROSIS JUVENIL DE LA CADERA Y DE LA PELVIS'},
{ codigo: 'M910', descripcion: 'OSTEOCONDROSIS JUVENIL DE LA PELVIS'},
{ codigo: 'M911', descripcion: 'OSTEOCONDROSIS JUVENIL DE LA CABEZA DEL FEMUR (LEGG-CALVE-PERTHES)'},
{ codigo: 'M912', descripcion: 'COXA PLANA'},
{ codigo: 'M913', descripcion: 'PSEUDOCOXALGIA'},
{ codigo: 'M918', descripcion: 'OTRAS OSTEOCONDROSIS JUVENILES DE LA CADERA Y DE LA PELVIS'},
{ codigo: 'M919', descripcion: 'OSTEOCONDROSIS JUVENIL DE CADERA Y DE LA PELVIS, SIN OTRA ESPECIFICACION'},
{ codigo: 'M92', descripcion: 'OTRAS OSTEOCONDROSIS JUVENILES'},
{ codigo: 'M920', descripcion: 'OSTEOCONDROSIS JUVENIL DEL HUMERO'},
{ codigo: 'M921', descripcion: 'OSTEOCONDROSIS JUVENIL DEL CUBITO Y DEL RADIO'},
{ codigo: 'M922', descripcion: 'OSTEOCONDROSIS JUVENIL DE LA MANO'},
{ codigo: 'M923', descripcion: 'OTRAS OSTEOCONDROSIS JUVENILES DEL MIEMBRO SUPERIOR'},
{ codigo: 'M924', descripcion: 'OSTEOCONDROSIS JUVENIL DE LA ROTULA'},
{ codigo: 'M925', descripcion: 'OSTEOCONDROSIS JUVENIL DE LA TIBIA Y DEL PERONE'},
{ codigo: 'M926', descripcion: 'OSTEOCONDROSIS JUVENIL DEL TARSO'},
{ codigo: 'M927', descripcion: 'OSTEOCONDROSIS JUVENIL DEL METATARSO'},
{ codigo: 'M928', descripcion: 'OTRAS OSTEOCONDROSIS JUVENILES ESPECIFICADAS'},
{ codigo: 'M929', descripcion: 'OSTEOCONDROSIS JUVENIL, NO ESPECIFICADA'},
{ codigo: 'M93', descripcion: 'OTRAS OSTEOCONDROPATIAS'},
{ codigo: 'M930', descripcion: 'DESLIZAMIENTO DE LA EPIFISIS FEMORAL SUPERIOR (NO TRAUMATICO)'},
{ codigo: 'M931', descripcion: 'ENFERMEDAD DE KIENBOCK DEL ADULTO'},
{ codigo: 'M932', descripcion: 'OSTEOCONDRITIS DISECANTE'},
{ codigo: 'M938', descripcion: 'OTRAS OSTEOCONDROPATIAS ESPECIFICADAS'},
{ codigo: 'M939', descripcion: 'OSTEOCONDROPATIA, NO ESPECIFICADA'},
{ codigo: 'M94', descripcion: 'OTROS TRASTORNOS DEL CARTILAGO'},
{ codigo: 'M940', descripcion: 'SINDROME DE LA ARTICULACION CONDROCOSTAL (TIETZE)'},
{ codigo: 'M941', descripcion: 'POLICONDRITIS RECIDIVANTE'},
{ codigo: 'M942', descripcion: 'CONDROMALACIA'},
{ codigo: 'M943', descripcion: 'CONDROLISIS'},
{ codigo: 'M948', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL CARTILAGO'},
{ codigo: 'M949', descripcion: 'TRASTORNO DEL CARTILAGO, NO ESPECIFICADO'},
{ codigo: 'M95', descripcion: 'OTRAS DEFORMIDADES ADQUIRIDAS DEL SISTEMA OSTEOMUSCULAR Y DEL TEJIDO CONJUNTIVO'},
{ codigo: 'M950', descripcion: 'DEFORMIDAD ADQUIRIDA DE LA NARIZ'},
{ codigo: 'M951', descripcion: 'OREJA EN COLIFLOR'},
{ codigo: 'M952', descripcion: 'OTRAS DEFORMIDADES ADQUIRIDAS DE LA CABEZA'},
{ codigo: 'M953', descripcion: 'DEFORMIDAD ADQUIRIDA DEL CUELLO'},
{ codigo: 'M954', descripcion: 'DEFORMIDAD ADQUIRIDA DE COSTILLAS Y TORAX'},
{ codigo: 'M955', descripcion: 'DEFORMIDAD ADQUIRIDA DE LA PELVIS'},
{ codigo: 'M958', descripcion: 'OTRAS DEFORMIDADES ADQUIRIDAS ESPECIFICADAS DEL SISTEMA OSTEOMUSCULAR'},
{ codigo: 'M959', descripcion: 'DEFORMIDAD ADQUIRIDA DEL SISTEMA OSTEOMUSCULAR, NO ESPECIFICADA'},
{ codigo: 'M96', descripcion: 'TRASTORNOS OSTEOMUSCULARES CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M960', descripcion: 'SEUDOARTROSIS CONSECUTIVA A FUSION O ARTRODESIS'},
{ codigo: 'M961', descripcion: 'SINDROME POSTLAMINECTOMIA, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'M962', descripcion: 'CIFOSIS POSTRADIACION'},
{ codigo: 'M963', descripcion: 'CIFOSIS POSTLAMINECTOMIA'},
{ codigo: 'M964', descripcion: 'LORDOSIS POSTQUIRURGICA'},
{ codigo: 'M965', descripcion: 'ESCOLIOSIS POSTRADIACION'},
{ codigo: 'M966', descripcion: 'FRACTURA DE HUESO POSTERIOR A INSERCION IMPLANTE ORTOPEDICO, PROTESIS ARTICULAR O PLACA OSEA'},
{ codigo: 'M968', descripcion: 'OTROS TRASTORNOS OSTEOMUSCULARES CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'M969', descripcion: 'TRASTORNOS OSTEOMUSCULARES NO ESPECIFICADOS CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'M99', descripcion: 'LESIONES BIOMECANICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'M990', descripcion: 'DISFUNCION SEGMENTAL O SOMATICA'},
{ codigo: 'M991', descripcion: 'COMPLEJO DE SUBLUXACION (VERTEBRAL)'},
{ codigo: 'M992', descripcion: 'SUBLUXACION CON ESTENOSIS DEL CANAL NEURAL'},
{ codigo: 'M993', descripcion: 'ESTENOSIS OSEA DEL CANAL NEURAL'},
{ codigo: 'M994', descripcion: 'ESTENOSIS DEL CANAL NEURAL POR TEJIDO CONJUNTIVO'},
{ codigo: 'M995', descripcion: 'ESTENOSIS DEL CANAL NEURAL POR DISCO INTERVERTEBRAL'},
{ codigo: 'M996', descripcion: 'ESTENOSIS OSEA Y SUBLUXACION DE LOS AGUJEROS INTERVERTEBRALES'},
{ codigo: 'M997', descripcion: 'ESTENOSIS DE AGUJEROS INTERVERTEBRALES POR TEJIDO CONJUNTIVO O DISCO INTERVERTEBRAL'},
{ codigo: 'M998', descripcion: 'OTRAS LESIONES BIOMECANICAS'},
{ codigo: 'M999', descripcion: 'LESION BIOMECANICA, NO ESPECIFICADA'},
{ codigo: 'N000', descripcion: 'SINDROME NEFRITICO AGUDO, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N001', descripcion: 'SINDROME NEFRITICO AGUDO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N002', descripcion: 'SINDROME NEFRITICO AGUDO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA'},
{ codigo: 'N003', descripcion: 'SINDROME NEFRITICO AGUDO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N004', descripcion: 'SINDROME NEFRITICO AGUDO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA'},
{ codigo: 'N005', descripcion: 'SINDROME NEFRITICO AGUDO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N006', descripcion: 'SINDROME NEFRITICO AGUDO, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N007', descripcion: 'SINDROME NEFRITICO AGUDO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N008', descripcion: 'SINDROME NEFRITICO AGUDO, OTRAS'},
{ codigo: 'N009', descripcion: 'SINDROME NEFRITICO AGUDO, NO ESPECIFICADA'},
{ codigo: 'N01', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO'},
{ codigo: 'N010', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N011', descripcion: 'SINDROME NEFRITICO PROGRESIVA, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N012', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVA, GLOMERULONEFRITIS MEMBRANOSA DIFUSA'},
{ codigo: 'N013', descripcion: 'SINDROME NEFRITICO PROGRESIVA, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N014', descripcion: 'SINDROME NEFRITICO PROGRESIVA, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA'},
{ codigo: 'N015', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N016', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N017', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N018', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO, OTRAS'},
{ codigo: 'N019', descripcion: 'SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO, NO ESPECIFICADA'},
{ codigo: 'N02', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE'},
{ codigo: 'N020', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N021', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N022', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS MEMBRANOSA DIFUSA'},
{ codigo: 'N023', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N024', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS PROLIFERATIVA DOCAPILAR DIFUSA'},
{ codigo: 'N025', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N026', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N027', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N028', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, OTRAS'},
{ codigo: 'N029', descripcion: 'HEMATURIA RECURRENTE Y PERSISTENTE, NO ESPECIFICADA'},
{ codigo: 'N03', descripcion: 'SINDROME NEFRITICO CRONICO'},
{ codigo: 'N030', descripcion: 'SINDROME NEFRITICO CRONICO, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N031', descripcion: 'SINDROME NEFRITICO CRONICO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N032', descripcion: 'SINDROME NEFRITICO CRONICO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA'},
{ codigo: 'N033', descripcion: 'SINDROME NEFRITICO CRONICO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N034', descripcion: 'SINDROME NEFRITICO CRONICO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA'},
{ codigo: 'N035', descripcion: 'SINDROME NEFRITICO CRONICO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N036', descripcion: 'SINDROME NEFRITICO CRONICO, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N037', descripcion: 'SINDROME NEFRITICO CRONICO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N038', descripcion: 'SINDROME NEFRITICO CRONICO, OTRAS'},
{ codigo: 'N039', descripcion: 'SINDROME NEFRITICO CRONICO, NO ESPECIFICADA'},
{ codigo: 'N04', descripcion: 'SINDROME NEFROTICO'},
{ codigo: 'N040', descripcion: 'SINDROME NEFROTICO, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N041', descripcion: 'SINDROME NEFROTICO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N042', descripcion: 'SINDROME NEFROTICO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA'},
{ codigo: 'N043', descripcion: 'SINDROME NEFROTICO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N044', descripcion: 'SINDROME NEFROTICO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA'},
{ codigo: 'N045', descripcion: 'SINDROME NEFROTICO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N046', descripcion: 'SINDROME NEFROTICO, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N047', descripcion: 'SINDROME NEFROTICO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N048', descripcion: 'SINDROME NEFROTICO, OTRAS'},
{ codigo: 'N049', descripcion: 'SINDROME NEFROTICO, NO ESPECIFICADA'},
{ codigo: 'N05', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO'},
{ codigo: 'N050', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N051', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N052', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, GLOMERULONEFRITIS MEMBRANOSA DIFUSA'},
{ codigo: 'N053', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N054', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA'},
{ codigo: 'N055', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N056', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N057', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N058', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, OTRAS'},
{ codigo: 'N059', descripcion: 'SINDROME NEFRITICO NO ESPECIFICADO, NO ESPECIFICADA'},
{ codigo: 'N06', descripcion: 'PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA'},
{ codigo: 'N060', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N061', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N062', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, GLOMERULONEFRITIS MEMBRANOSA DIFUSA'},
{ codigo: 'N063', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N064', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA'},
{ codigo: 'N065', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N066', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N067', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N068', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, OTRAS'},
{ codigo: 'N069', descripcion: 'PROTEINURIA AISLADA LESION MORFOLOGICA ESPECIFICADA, NO ESPECIFICADA'},
{ codigo: 'N07', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N070', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, ANOMALIA GLOMERULAR MINIMA'},
{ codigo: 'N071', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS'},
{ codigo: 'N072', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, GROMERULONEFRITIS MENBRANOSA DIFUSA'},
{ codigo: 'N073', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA'},
{ codigo: 'N074', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUS'},
{ codigo: 'N075', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA'},
{ codigo: 'N076', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, ENFERMEDAD POR DEPOSITOS DENSOS'},
{ codigo: 'N077', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA'},
{ codigo: 'N078', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, OTRAS'},
{ codigo: 'N079', descripcion: 'NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE, NO ESPECIFICADA'},
{ codigo: 'N08', descripcion: 'TRASTORNOS GLOMERULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N080', descripcion: 'TRASTORNOS GLOMERULARES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N081', descripcion: 'TRASTORNOS GLOMERULARES EN ENFERMEDADES NEOPLASICAS'},
{ codigo: 'N082', descripcion: 'TRASTORNOS GLOMERULARES EN ENFERMEDADES DE LA SANGRE Y OTROS TRASTORNOS QUE AFECTAN MECANISMO INMUNITARIO'},
{ codigo: 'N083', descripcion: 'TRASTORNOS GLOMERULARES DM (E10-E14+ CON CUARTO CARACTER COMUN .2)'},
{ codigo: 'N084', descripcion: 'TRASTORNOS GLOMERULARES EN OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS'},
{ codigo: 'N085', descripcion: 'TRASTORNOS GLOMERULARES EN TRASTORNOS SISTEMICOS DEL TEJIDO CONJUNTIVO'},
{ codigo: 'N088', descripcion: 'TRASTORNOS GLOMERULARES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N10', descripcion: 'NEFRITIS TUBULOINTERSTICIAL AGUDA'},
{ codigo: 'N11', descripcion: 'NEFRITIS TUBULOINTERSTICIAL CRONICA'},
{ codigo: 'N110', descripcion: 'PIELONEFRITIS CRONICA NO OBSTRUCTIVA ASOCIADA CON REFLUJO'},
{ codigo: 'N111', descripcion: 'PIELONEFRITIS CRONICA OBSTRUCTIVA'},
{ codigo: 'N118', descripcion: 'OTRAS NEFRITIS TUBULOINTERSTICIALES CRONICAS'},
{ codigo: 'N119', descripcion: 'NEFRITIS TUBULOINTERSTICIAL CRONICA, SIN OTRA ESPECIFICACION'},
{ codigo: 'N12', descripcion: 'NEFRITIS TUBULOINTERSTICIAL, NO ESPECIFICADA COMO AGUDA O CRONICA'},
{ codigo: 'N13', descripcion: 'UROPATIA OBSTRUCTIVA Y POR REFLUJO'},
{ codigo: 'N130', descripcion: 'HIDRONEFROSIS CON OBSTRUCCION DE LA UNION URETERO-PELVICA'},
{ codigo: 'N131', descripcion: 'HIDRONEFROSIS CON ESTRECHEZ URETERAL, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N132', descripcion: 'HIDRONEFROSIS CON OBSTRUCCION POR CALCULOS DEL RI—ON Y DEL URETER'},
{ codigo: 'N133', descripcion: 'OTRAS HIDRONEFROSIS Y LAS NO ESPECIFICADAS'},
{ codigo: 'N134', descripcion: 'HIDROURETER'},
{ codigo: 'N135', descripcion: 'TORSION Y ESTRECHEZ DEL URETER SIN HIDRONEFROSIS'},
{ codigo: 'N136', descripcion: 'PIONEFROSIS'},
{ codigo: 'N137', descripcion: 'UROPATIA ASOCIADA CON REFLUJO VESICOURETERAL'},
{ codigo: 'N138', descripcion: 'OTRAS UROPATIAS OBSTRUCTIVAS Y POR REFLUJO'},
{ codigo: 'N139', descripcion: 'UROPATIA OBSTRUCTIVA Y POR REFLUJO, SIN OTRA ESPECIFICACION'},
{ codigo: 'N14', descripcion: 'AFECCIONES TUBULARES TUBULOINTERSTICIALES POR DROGAS Y METALES PESADOS'},
{ codigo: 'N140', descripcion: 'NEFROPATIA INDUCIDA POR ANALGESICOS'},
{ codigo: 'N141', descripcion: 'NEFROPATIA INDUCIDA POR OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'N142', descripcion: 'NEFROPATIA POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS'},
{ codigo: 'N143', descripcion: 'NEFROPATIA INDUCIDA POR METALES PESADOS'},
{ codigo: 'N144', descripcion: 'NEFROPATIA TOXICA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N15', descripcion: 'OTRAS ENFERMEDADES RENALES TUBULOINTERSTICIALES'},
{ codigo: 'N150', descripcion: 'NEFROPATIA DE LOS BALCANES'},
{ codigo: 'N151', descripcion: 'ABSCESO RENAL Y PERIRRENAL'},
{ codigo: 'N158', descripcion: 'OTRAS ENFERMEDADES RENALES TUBULOINTERSTICIALES ESPECIFICADAS'},
{ codigo: 'N159', descripcion: 'ENFERMEDAD RENAL TUBULOINTERSTICIAL, NO ESPECIFICADA'},
{ codigo: 'N16', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N160', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS'},
{ codigo: 'N161', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES NEOPLASICAS'},
{ codigo: 'N162', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES DE LA SANGRE Y EN TRASTORNOS QUE AFECTAN MECANISMO INMUNITARIO'},
{ codigo: 'N163', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES METABOLICAS'},
{ codigo: 'N164', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES DEL TEJIDO CONJUNTIVO'},
{ codigo: 'N165', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN RECHAZO DE TRASPLANTE (T86.-+)'},
{ codigo: 'N168', descripcion: 'TRASTORNOS RENALES TUBULOINTERSTICIALES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N17', descripcion: 'INSUFICIENCIA RENAL AGUDA'},
{ codigo: 'N170', descripcion: 'INSUFICIENCIA RENAL AGUDA CON NECROSIS TUBULAR'},
{ codigo: 'N171', descripcion: 'INSUFICIENCIA RENAL AGUDA CON NECROSIS CORTICAL AGUDA'},
{ codigo: 'N172', descripcion: 'INSUFICIENCIA RENAL AGUDA CON NECROSIS MEDULAR'},
{ codigo: 'N178', descripcion: 'OTRAS INSUFICIENCIAS RENALES AGUDAS'},
{ codigo: 'N179', descripcion: 'INSUFICIENCIA RENAL AGUDA, NO ESPECIFICADA'},
{ codigo: 'N18', descripcion: 'INSUFICIENCIA RENAL CRONICA'},
{ codigo: 'N180', descripcion: 'INSUFICIENCIA RENAL TERMINAL'},
{ codigo: 'N188', descripcion: 'OTRAS INSUFICIENCIAS RENALES CRONICAS'},
{ codigo: 'N189', descripcion: 'INSUFICIENCIA RENAL CRONICA, NO ESPECIFICADA'},
{ codigo: 'N19', descripcion: 'INSUFICIENCIA RENAL NO ESPECIFICADA'},
{ codigo: 'N20', descripcion: 'CALCULO DEL RI—ON Y DEL URETER'},
{ codigo: 'N200', descripcion: 'CALCULO DEL RI—ON'},
{ codigo: 'N201', descripcion: 'CALCULO DEL URETER'},
{ codigo: 'N202', descripcion: 'CALCULO DEL RI—ON CON CALCULO DEL URETER'},
{ codigo: 'N209', descripcion: 'CALCULO URINARIO, NO ESPECIFICADO'},
{ codigo: 'N21', descripcion: 'CALCULO DE LAS VIAS URINARIAS INFERIORES'},
{ codigo: 'N210', descripcion: 'CALCULO EN LA VEJIGA'},
{ codigo: 'N211', descripcion: 'CALCULO EN LA URETRA'},
{ codigo: 'N218', descripcion: 'OTROS CALCULOS DE LAS VIAS URINARIAS INFERIORES'},
{ codigo: 'N219', descripcion: 'CALCULO DE LAS VIAS URINARIAS INFERIORES, NO ESPECIFICADO'},
{ codigo: 'N22', descripcion: 'CALCULO DE LAS VIAS URINARIAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N220', descripcion: 'LITIASIS URINARIA EN ESQUISTOSOMIASIS (BILHARZIASIS) (B65.-+)'},
{ codigo: 'N228', descripcion: 'CALCULO DE LAS VIAS URINARIAS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N23', descripcion: 'COLICO RENAL, NO ESPECIFICADO'},
{ codigo: 'N25', descripcion: 'TRASTORNOS RESULTANTES DE LA FUNCION TUBULAR RENAL ALTERADA'},
{ codigo: 'N250', descripcion: 'OSTEODISTROFIA RENAL'},
{ codigo: 'N251', descripcion: 'DIABETES INSIPIDA NEFROGENA'},
{ codigo: 'N258', descripcion: 'OTROS TRASTORNOS RESULTANTES DE LA FUNCION TUBULAR RENAL ALTERADA'},
{ codigo: 'N259', descripcion: 'TRASTORNO NO ESPECIFICADO, RESULTANTE DE LA FUNCION TUBULAR RENAL ALTERADA'},
{ codigo: 'N26', descripcion: 'RI—ON CONTRAIDO, NO ESPECIFICADO'},
{ codigo: 'N27', descripcion: 'RI—ON PEQUE—O DE CAUSA DESCONOCIDA'},
{ codigo: 'N270', descripcion: 'RI—ON PEQUE—O, UNILATERAL'},
{ codigo: 'N271', descripcion: 'RI—ON PEQUE—O, BILATERAL'},
{ codigo: 'N279', descripcion: 'RI—ON PEQUE—O, NO ESPECIFICADO'},
{ codigo: 'N28', descripcion: 'OTROS TRASTORNOS DEL RI—ON Y DEL URETER, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'N280', descripcion: 'ISQUEMIA E INFARTO DEL RI—ON'},
{ codigo: 'N281', descripcion: 'QUISTE DE RI—ON, ADQUIRIDO'},
{ codigo: 'N288', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL RI—ON Y DEL URETER'},
{ codigo: 'N289', descripcion: 'TRASTORNO DEL RI—ON Y DEL URETER, NO ESPECIFICADO'},
{ codigo: 'N29', descripcion: 'OTROS TRASTORNOS DEL RI—ON Y DEL URETER EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N290', descripcion: 'SIFILIS RENAL TARDIA (A52.7+)'},
{ codigo: 'N291', descripcion: 'OTROS TRASTORNOS RI—ON Y URETER EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N298', descripcion: 'OTROS TRASTORNOS DEL RI—ON Y DEL URETER EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N30', descripcion: 'CISTITIS'},
{ codigo: 'N300', descripcion: 'CISTITIS AGUDA'},
{ codigo: 'N301', descripcion: 'CISTITIS INTERSTICIAL (CRONICA)'},
{ codigo: 'N302', descripcion: 'OTRAS CISTITIS CRONICAS'},
{ codigo: 'N303', descripcion: 'TRIGONITIS'},
{ codigo: 'N304', descripcion: 'CISTITIS POR IRRADIACION'},
{ codigo: 'N308', descripcion: 'OTRAS CISTITIS'},
{ codigo: 'N309', descripcion: 'CISTITIS, NO ESPECIFICADA'},
{ codigo: 'N31', descripcion: 'DISFUNCION NEUROMUSCULAR DE LA VEJIGA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N310', descripcion: 'VEJIGA NEUROPATICA NO INHIBIDA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N311', descripcion: 'VEJIGA NEUROPATICA REFLEJA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N312', descripcion: 'VEJIGA NEUROPATICA FLACIDA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N318', descripcion: 'OTRAS DISFUNCIONES NEUROMUSCULARES DE LA VEJIGA'},
{ codigo: 'N319', descripcion: 'DISFUNCION NEUROMUSCULAR DE LA VEJIGA, NO ESPECIFICADA'},
{ codigo: 'N32', descripcion: 'OTROS TRASTORNOS DE LA VEJIGA'},
{ codigo: 'N320', descripcion: 'OBSTRUCCION DE CUELLO DE LA VEJIGA'},
{ codigo: 'N321', descripcion: 'FISTULA VESICOINTESTINAL'},
{ codigo: 'N322', descripcion: 'FISTULA DE LA VEJIGA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N323', descripcion: 'DIVERTICULO DE LA VEJIGA'},
{ codigo: 'N324', descripcion: 'RUPTURA DE LA VEJIGA, NO TRAUMATICA'},
{ codigo: 'N328', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA VEJIGA'},
{ codigo: 'N329', descripcion: 'TRASTORNO DE LA VEJIGA, NO ESPECIFICADO'},
{ codigo: 'N33', descripcion: 'TRASTORNOS DE LA VEJIGA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N330', descripcion: 'CISTITIS TUBERCULOSA (A18.1+)'},
{ codigo: 'N338', descripcion: 'TRASTORNOS DE LA VEJIGA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N34', descripcion: 'URETRITIS Y SINDROME URETRAL'},
{ codigo: 'N340', descripcion: 'ABSCESO URETRAL'},
{ codigo: 'N341', descripcion: 'URETRITIS NO ESPECIFICA'},
{ codigo: 'N342', descripcion: 'OTRAS URETRITIS'},
{ codigo: 'N343', descripcion: 'SINDROME URETRAL, NO ESPECIFICADO'},
{ codigo: 'N35', descripcion: 'ESTRECHEZ URETRAL'},
{ codigo: 'N350', descripcion: 'ESTRECHEZ URETRAL POSTRAUMATICA'},
{ codigo: 'N351', descripcion: 'ESTRECHEZ URETRAL POSTINFECCION, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N358', descripcion: 'OTRAS ESTRECHECES URETRALES'},
{ codigo: 'N359', descripcion: 'ESTRECHEZ URETRAL, NO ESPECIFICADA'},
{ codigo: 'N36', descripcion: 'OTROS TRASTORNOS DE LA URETRA'},
{ codigo: 'N360', descripcion: 'FISTULA DE LA URETRA'},
{ codigo: 'N361', descripcion: 'DIVERTICULO DE LA URETRA'},
{ codigo: 'N362', descripcion: 'CARUNCULA URETRAL'},
{ codigo: 'N363', descripcion: 'PROLAPSO DE LA MUCOSA URETRAL'},
{ codigo: 'N368', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA URETRA'},
{ codigo: 'N369', descripcion: 'TRASTORNO DE LA URETRA, NO ESPECIFICADO'},
{ codigo: 'N37', descripcion: 'TRASTORNOS DE LA URETRA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N370', descripcion: 'URETRITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N378', descripcion: 'OTROS TRASTORNOS URETRALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N39', descripcion: 'OTROS TRASTORNOS DEL SISTEMA URINARIO'},
{ codigo: 'N390', descripcion: 'INFECCION DE VIAS URINARIAS, SITIO NO ESPECIFICADO'},
{ codigo: 'N391', descripcion: 'PROTEINURIA PERSISTENTE, NO ESPECIFICADA'},
{ codigo: 'N392', descripcion: 'PROTEINURIA ORTOSTATICA, NO ESPECIFICADA'},
{ codigo: 'N393', descripcion: 'INCONTINENCIA URINARIA POR TENSION'},
{ codigo: 'N394', descripcion: 'OTRAS INCONTINENCIAS URINARIAS ESPECIFICADAS'},
{ codigo: 'N398', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA URINARIO'},
{ codigo: 'N399', descripcion: 'TRASTORNO DEL SISTEMA URINARIO, NO ESPECIFICADO'},
{ codigo: 'N40', descripcion: 'HIPERPLASIA DE LA PROSTATA'},
{ codigo: 'N41', descripcion: 'ENFERMEDADES INFLAMATORIAS DE LA PROSTATA'},
{ codigo: 'N410', descripcion: 'PROSTATITIS AGUDA'},
{ codigo: 'N411', descripcion: 'PROSTATITIS CRONICA'},
{ codigo: 'N412', descripcion: 'ABSCESO DE LA PROSTATA'},
{ codigo: 'N413', descripcion: 'PROSTATOCISTITIS'},
{ codigo: 'N418', descripcion: 'OTRAS ENFERMEDADES INFLAMATORIAS DE LA PROSTATA'},
{ codigo: 'N419', descripcion: 'ENFERMEDAD INFLAMATORIA DE LA PROSTATA, NO ESPECIFICADA'},
{ codigo: 'N42', descripcion: 'OTROS TRASTORNOS DE LA PROSTATA'},
{ codigo: 'N420', descripcion: 'CALCULO DE LA PROSTATA'},
{ codigo: 'N421', descripcion: 'CONGESTION Y HEMORRAGIA DE LA PROSTATA'},
{ codigo: 'N422', descripcion: 'ATROFIA DE LA PROSTATA'},
{ codigo: 'N428', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA PROSTATA'},
{ codigo: 'N429', descripcion: 'TRASTORNO DE LA PROSTATA, NO ESPECIFICADO'},
{ codigo: 'N43', descripcion: 'HIDROCELE Y ESPERMATOCELE'},
{ codigo: 'N430', descripcion: 'HIDROCELE ENQUISTADO'},
{ codigo: 'N431', descripcion: 'HIDROCELE INFECTADO'},
{ codigo: 'N432', descripcion: 'OTROS HIDROCELES'},
{ codigo: 'N433', descripcion: 'HIDROCELE, NO ESPECIFICADO'},
{ codigo: 'N434', descripcion: 'ESPERMATOCELE'},
{ codigo: 'N44', descripcion: 'TORSION DEL TESTICULO'},
{ codigo: 'N45', descripcion: 'ORQUITIS Y EPIDIDIMITIS'},
{ codigo: 'N450', descripcion: 'ORQUITIS, EPIDIDIMITIS Y ORQUIEPIDIDIMITIS CON ABSCESO'},
{ codigo: 'N459', descripcion: 'ORQUITIS, EPIDIDIMITIS Y ORQUIEPIDIDIMITIS SIN ABSCESO'},
{ codigo: 'N46', descripcion: 'ESTERILIDAD EN EL VARON'},
{ codigo: 'N47', descripcion: 'PREPUCIO REDUNDANTE, FIMOSIS Y PARAFIMOSIS'},
{ codigo: 'N48', descripcion: 'OTROS TRASTORNOS DEL PENE'},
{ codigo: 'N480', descripcion: 'LEUCOPLASIA DEL PENE'},
{ codigo: 'N481', descripcion: 'BALANOPOSTITIS'},
{ codigo: 'N482', descripcion: 'OTROS TRASTORNOS INFLAMATORIOS DEL PENE'},
{ codigo: 'N483', descripcion: 'PRIAPISMO'},
{ codigo: 'N484', descripcion: 'IMPOTENCIA DE ORIGEN ORGANICO'},
{ codigo: 'N485', descripcion: 'ULCERA DEL PENE'},
{ codigo: 'N486', descripcion: 'BALANITIS XEROTICA OBLITERANTE'},
{ codigo: 'N488', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DEL PENE'},
{ codigo: 'N489', descripcion: 'TRASTORNO DEL PENE, NO ESPECIFICADO'},
{ codigo: 'N49', descripcion: 'TRASTORNOS INFLAMATORIOS DE ORGANOS GENITALES MASCULINOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N490', descripcion: 'TRASTORNOS INFLAMATORIOS DE VESICULA SEMINAL'},
{ codigo: 'N491', descripcion: 'TRASTORNOS INFLAMATORIOS CORDON ESPERMATICO, TUNICA VAGINAL Y CONDUCTO DEFERENTE'},
{ codigo: 'N492', descripcion: 'TRASTORNOS INFLAMATORIOS DEL ESCROTO'},
{ codigo: 'N498', descripcion: 'OTROS TRASTORNOS INFLAMATORIOS DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'N499', descripcion: 'TRASTORNO INFLAMATORIO DE ORGANO GENITAL MASCULINO, NO ESPECIFICADO'},
{ codigo: 'N50', descripcion: 'OTROS TRASTORNOS DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'N500', descripcion: 'ATROFIA DEL TESTICULO'},
{ codigo: 'N501', descripcion: 'TRASTORNOS VASCULARES DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'N508', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'N509', descripcion: 'TRASTORNO NO ESPECIFICADO DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'N51', descripcion: 'TRASTORNOS DE LOS ORGANOS GENITALES MASCULINOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N510', descripcion: 'TRASTORNOS DE PROSTATA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N511', descripcion: 'TRASTORNOS DEL TESTICULO Y DEL EPIDIDIMO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N512', descripcion: 'BALANITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N518', descripcion: 'OTROS TRASTORNOS DE ORGANOS GENITALES MASCULINOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N60', descripcion: 'DISPLASIA MAMARIA BENIGNA'},
{ codigo: 'N600', descripcion: 'QUISTE SOLITARIO DE LA MAMA'},
{ codigo: 'N601', descripcion: 'MASTOPATIA QUISTICA DIFUSA'},
{ codigo: 'N602', descripcion: 'FIBROADENOSIS DE MAMA'},
{ codigo: 'N603', descripcion: 'FIBROESCLEROSIS DE MAMA'},
{ codigo: 'N604', descripcion: 'ECTASIA DE CONDUCTO MAMARIO'},
{ codigo: 'N608', descripcion: 'OTRAS DISPLASIAS MAMARIAS BENIGNAS'},
{ codigo: 'N609', descripcion: 'DISPLASIA MAMARIA BENIGNA, SIN OTRA ESPECIFICACION'},
{ codigo: 'N61', descripcion: 'TRASTORNOS INFLAMATORIOS DE LA MAMA'},
{ codigo: 'N62', descripcion: 'HIPERTROFIA DE LA MAMA'},
{ codigo: 'N63', descripcion: 'MASA NO ESPECIFICADA EN LA MAMA'},
{ codigo: 'N64', descripcion: 'OTROS TRASTORNOS DE LA MAMA'},
{ codigo: 'N640', descripcion: 'FISURA Y FISTULA DEL PEZON'},
{ codigo: 'N641', descripcion: 'NECROSIS GRASA DE LA MAMA'},
{ codigo: 'N642', descripcion: 'ATROFIA DE LA MAMA'},
{ codigo: 'N643', descripcion: 'GALACTORREA NO ASOCIADA CON EL PARTO'},
{ codigo: 'N644', descripcion: 'MASTODINIA'},
{ codigo: 'N645', descripcion: 'OTROS SIGNOS Y SINTOMAS RELATIVOS A LA MAMA'},
{ codigo: 'N648', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS DE LA MAMA'},
{ codigo: 'N649', descripcion: 'TRASTORNO DE LA MAMA, NO ESPECIFICADO'},
{ codigo: 'N70', descripcion: 'SALPINGITIS Y OOFORITIS'},
{ codigo: 'N700', descripcion: 'SALPINGITIS Y OOFORITIS AGUDA'},
{ codigo: 'N701', descripcion: 'SALPINGITIS Y OOFORITIS CRONICA'},
{ codigo: 'N709', descripcion: 'SALPINGITIS Y OOFORITIS, NO ESPECIFICADAS'},
{ codigo: 'N71', descripcion: 'ENFERMEDAD INFLAMATORIA DEL UTERO, EXCEPTO DEL CUELLO UTERINO'},
{ codigo: 'N710', descripcion: 'ENFERMEDAD INFLAMATORIA AGUDA DEL UTERO'},
{ codigo: 'N711', descripcion: 'ENFERMEDAD INFLAMATORIA CRONICA DEL UTERO'},
{ codigo: 'N719', descripcion: 'ENFERMEDAD INFLAMATORIA DEL UTERO, NO ESPECIFICADA'},
{ codigo: 'N72', descripcion: 'ENFERMEDAD INFLAMATORIA DEL CUELLO UTERINO'},
{ codigo: 'N73', descripcion: 'OTRAS ENFERMEDADES PELVICAS INFLAMATORIAS FEMENINAS'},
{ codigo: 'N730', descripcion: 'PARAMETRITIS Y CELULITIS PELVICA AGUDA'},
{ codigo: 'N731', descripcion: 'PARAMETRITIS Y CELULITIS PELVICA CRONICA'},
{ codigo: 'N732', descripcion: 'PARAMETRITIS Y CELULITIS PELVICA NO ESPECIFICADA'},
{ codigo: 'N733', descripcion: 'PERITONITIS PELVICA AGUDA, FEMENINA'},
{ codigo: 'N734', descripcion: 'PERITONITIS PELVICA CRONICA, FEMENINA'},
{ codigo: 'N735', descripcion: 'PERITONITIS PELVICA FEMENINA, NO ESPECIFICADA'},
{ codigo: 'N736', descripcion: 'ADHERENCIAS PERITONEALES PELVICAS FEMENINAS'},
{ codigo: 'N738', descripcion: 'OTRAS ENFERMEDADES INFLAMATORIAS PELVICAS FEMENINAS'},
{ codigo: 'N739', descripcion: 'ENFERMEDAD INFLAMATORIA PELVICA FEMENINA, NO ESPECIFICADA'},
{ codigo: 'N74', descripcion: 'TRASTORNOS INFLAMATORIOS DE LA PELVIS FEMENINA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N740', descripcion: 'INFECCION TUBERCULOSA DEL CUELLO DEL UTERO (A18.1+)'},
{ codigo: 'N741', descripcion: 'ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR TUBERCULOSIS (A18.1+)'},
{ codigo: 'N742', descripcion: 'ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR SIFILIS (A51.4+, A52.7+)'},
{ codigo: 'N743', descripcion: 'ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR GONOCOCOS (A54.2+)'},
{ codigo: 'N744', descripcion: 'ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR CLAMIDIAS (A56.1+)'},
{ codigo: 'N748', descripcion: 'TRASTORNOS INFLAMATORIOS PELVICOS FEMENINOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N75', descripcion: 'ENFERMEDADES DE LA GLANDULA DE BARTHOLIN'},
{ codigo: 'N750', descripcion: 'QUISTE DE LA GLANDULA DE BARTHOLIN'},
{ codigo: 'N751', descripcion: 'ABSCESO DE LA GLANDULA DE BARTHOLIN'},
{ codigo: 'N758', descripcion: 'OTRAS ENFERMEDADES DE LA GLANDULA DE BARTHOLIN'},
{ codigo: 'N759', descripcion: 'ENFERMEDAD DE LA GLANDULA DE BARTHOLIN, NO ESPECIFICADA'},
{ codigo: 'N76', descripcion: 'OTRAS AFECCIONES INFLAMATORIAS DE LA VAGINA Y DE LA VULVA'},
{ codigo: 'N760', descripcion: 'VAGINITIS AGUDA'},
{ codigo: 'N761', descripcion: 'VAGINITIS SUBAGUDA Y CRONICA'},
{ codigo: 'N762', descripcion: 'VULVITIS AGUDA'},
{ codigo: 'N763', descripcion: 'VULVITIS SUBAGUDA Y CRONICA'},
{ codigo: 'N764', descripcion: 'ABSCESO VULVAR'},
{ codigo: 'N765', descripcion: 'ULCERACION DE LA VAGINA'},
{ codigo: 'N766', descripcion: 'ULCERACION DE LA VULVA'},
{ codigo: 'N768', descripcion: 'OTRAS INFLAMACIONES ESPECIFICADAS DE LA VAGINA Y DE LA VULVA'},
{ codigo: 'N77', descripcion: 'ULCERACION E INFLAMACION VULVOVAGINAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N770', descripcion: 'ULCERACION DE LA VULVA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N771', descripcion: 'VAGINITIS, VULVITIS Y VULVOVAGINITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N778', descripcion: 'ULCERACION E INFLAMACION VULVOVAGINAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N80', descripcion: 'ENDOMETRIOSIS'},
{ codigo: 'N800', descripcion: 'ENDOMETRIOSIS DEL UTERO'},
{ codigo: 'N801', descripcion: 'ENDOMETRIOSIS DEL OVARIO'},
{ codigo: 'N802', descripcion: 'ENDOMETRIOSIS DE LA TROMPA DE FALOPIO'},
{ codigo: 'N803', descripcion: 'ENDOMETRIOSIS DEL PERITONEO PELVICO'},
{ codigo: 'N804', descripcion: 'ENDOMETRIOSIS DEL TABIQUE RECTOVAGINAL Y DE LA VAGINA'},
{ codigo: 'N805', descripcion: 'ENDOMETRIOSIS DEL INTESTINO'},
{ codigo: 'N806', descripcion: 'ENDOMETRIOSIS EN CICATRIZ CUTANEA'},
{ codigo: 'N808', descripcion: 'OTRAS ENDOMETRIOSIS'},
{ codigo: 'N809', descripcion: 'ENDOMETRIOSIS, NO ESPECIFICADA'},
{ codigo: 'N81', descripcion: 'PROLAPSO GENITAL FEMENINO'},
{ codigo: 'N810', descripcion: 'URETROCELE FEMENINO'},
{ codigo: 'N811', descripcion: 'CISTOCELE'},
{ codigo: 'N812', descripcion: 'PROLAPSO UTEROVAGINAL INCOMPLETO'},
{ codigo: 'N813', descripcion: 'PROLAPSO UTEROVAGINAL COMPLETO'},
{ codigo: 'N814', descripcion: 'PROLAPSO UTEROVAGINAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'N815', descripcion: 'ENTEROCELE VAGINAL'},
{ codigo: 'N816', descripcion: 'RECTOCELE'},
{ codigo: 'N818', descripcion: 'OTROS PROLAPSOS GENITALES FEMENINOS'},
{ codigo: 'N819', descripcion: 'PROLAPSO GENITAL FEMENINO, NO ESPECIFICADO'},
{ codigo: 'N82', descripcion: 'FISTULAS QUE AFECTAN EL TRACTO GENITAL FEMENINO'},
{ codigo: 'N820', descripcion: 'FISTULA VESICOVAGINAL'},
{ codigo: 'N821', descripcion: 'OTRAS FISTULAS DE LAS VIAS GENITOURINARIAS FEMENINAS'},
{ codigo: 'N822', descripcion: 'FISTULA DE LA VAGINA AL INTESTINO DELGADO'},
{ codigo: 'N823', descripcion: 'FISTULA DE LA VAGINA AL INTESTINO GRUESO'},
{ codigo: 'N824', descripcion: 'OTRAS FISTULAS DEL TRACTO GENITAL FEMENINO AL TRACTO INTESTINAL'},
{ codigo: 'N825', descripcion: 'FISTULA DEL TRACTO GENITAL FEMENINO A LA PIEL'},
{ codigo: 'N828', descripcion: 'OTRAS FISTULAS DEL TRACTO GENITAL FEMENINO'},
{ codigo: 'N829', descripcion: 'FISTULA DEL TRACTO GENITAL FEMENINO, SIN OTRA ESPECIFICACION'},
{ codigo: 'N83', descripcion: 'TRASTORNOS NO INFLAMATORIOS DEL OVARIO, TROMPA DE FALOPIO Y LIGAMENTO ANCHO'},
{ codigo: 'N830', descripcion: 'QUISTE FOLICULAR DEL OVARIO'},
{ codigo: 'N831', descripcion: 'QUISTE DEL CUERPO AMARILLO'},
{ codigo: 'N832', descripcion: 'OTROS QUISTES OVARICOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'N833', descripcion: 'ATROFIA ADQUIRIDA DEL OVARIO Y DE LA TROMPA DE FALOPIO'},
{ codigo: 'N834', descripcion: 'PROLAPSO Y HERNIA DEL OVARIO Y DE LA TROMPA DE FALOPIO'},
{ codigo: 'N835', descripcion: 'TORSION DE OVARIO, PEDICULO DE OVARIO Y TROMPA DE FALOPIO'},
{ codigo: 'N836', descripcion: 'HEMATOSALPINX'},
{ codigo: 'N837', descripcion: 'HEMATOMA DEL LIGAMENTO ANCHO'},
{ codigo: 'N838', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS OVARIO, TROMPA FALOPIO Y LIGAMENTO ANCHO'},
{ codigo: 'N839', descripcion: 'ENFERMEDAD NO INFLAMATORIA OVARIO, TROMPA FALOPIO Y LIGAMENTO ANCHO, NO ESPECIFICADA'},
{ codigo: 'N84', descripcion: 'POLIPO DEL TRACTO GENITAL FEMENINO'},
{ codigo: 'N840', descripcion: 'POLIPO DEL CUERPO DEL UTERO'},
{ codigo: 'N841', descripcion: 'POLIPO DEL CUELLO DEL UTERO'},
{ codigo: 'N842', descripcion: 'POLIPO DE LA VAGINA'},
{ codigo: 'N843', descripcion: 'POLIPO DE LA VULVA'},
{ codigo: 'N848', descripcion: 'POLIPOS DE OTRAS PARTES DEL TRACTO GENITAL FEMENINO'},
{ codigo: 'N849', descripcion: 'POLIPO DEL TRACTO GENITAL FEMENINO, NO ESPECIFICADO'},
{ codigo: 'N85', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS DEL UTERO, EXCEPTO DEL CUELLO'},
{ codigo: 'N850', descripcion: 'HIPERPLASIA DE GLANDULA DEL ENDOMETRIO'},
{ codigo: 'N851', descripcion: 'HIPERPLASIA ADENOMATOSA DEL ENDOMETRIO'},
{ codigo: 'N852', descripcion: 'HIPERTROFIA DEL UTERO'},
{ codigo: 'N853', descripcion: 'SUBINVOLUCION DEL UTERO'},
{ codigo: 'N854', descripcion: 'MALA POSICION DEL UTERO'},
{ codigo: 'N855', descripcion: 'INVERSION DEL UTERO'},
{ codigo: 'N856', descripcion: 'SINEQUIAS INTRAUTERINAS'},
{ codigo: 'N857', descripcion: 'HEMATOMETRA'},
{ codigo: 'N858', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DEL UTERO'},
{ codigo: 'N859', descripcion: 'TRASTORNO NO INFLAMATORIO DEL UTERO, NO ESPECIFICADO'},
{ codigo: 'N86', descripcion: 'EROSION Y ECTROPION DEL CUELLO DEL UTERO'},
{ codigo: 'N87', descripcion: 'DISPLASIA DEL CUELLO UTERINO'},
{ codigo: 'N870', descripcion: 'DISPLASIA CERVICAL LEVE'},
{ codigo: 'N871', descripcion: 'DISPLASIA CERVICAL MODERADA'},
{ codigo: 'N872', descripcion: 'DISPLASIA CERVICAL SEVERA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N879', descripcion: 'DISPLASIA DEL CUELLO DEL UTERO, NO ESPECIFICADA'},
{ codigo: 'N88', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS DEL CUELLO DEL UTERO'},
{ codigo: 'N880', descripcion: 'LEUCOPLASIA DEL CUELLO DEL UTERO'},
{ codigo: 'N881', descripcion: 'LACERACION ANTIGUA DEL CUELLO DEL UTERO'},
{ codigo: 'N882', descripcion: 'ESTRECHEZ Y ESTENOSIS DEL CUELLO DEL UTERO'},
{ codigo: 'N883', descripcion: 'INCOMPETENCIA DEL CUELLO DEL UTERO'},
{ codigo: 'N884', descripcion: 'ELONGACION HIPERTROFICA DEL CUELLO DEL UTERO'},
{ codigo: 'N888', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DEL CUELLO DEL UTERO'},
{ codigo: 'N889', descripcion: 'TRASTORNO NO INFLAMATORIO DEL CUELLO DEL UTERO, NO ESPECIFICADO'},
{ codigo: 'N89', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS DE LA VAGINA'},
{ codigo: 'N890', descripcion: 'DISPLASIA VAGINAL LEVE'},
{ codigo: 'N891', descripcion: 'DISPLASIA VAGINAL MODERADA'},
{ codigo: 'N892', descripcion: 'DISPLASIA VAGINAL SEVERA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N893', descripcion: 'DISPLASIA DE LA VAGINA, NO ESPECIFICADA'},
{ codigo: 'N894', descripcion: 'LEUCOPLASIA DE LA VAGINA'},
{ codigo: 'N895', descripcion: 'ESTRECHEZ Y ATRESIA DE LA VAGINA'},
{ codigo: 'N896', descripcion: 'ANILLO DE HIMEN ESTRECHO'},
{ codigo: 'N897', descripcion: 'HEMATOCOLPOS'},
{ codigo: 'N898', descripcion: 'OTROS TRASTORNOS ESPECIFICADOS NO INFLAMATORIOS DE LA VAGINA'},
{ codigo: 'N899', descripcion: 'TRASTORNO NO INFLAMATORIO DE LA VAGINA, NO ESPECIFICADO'},
{ codigo: 'N90', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS DE LA VULVA Y DEL PERINEO'},
{ codigo: 'N900', descripcion: 'DISPLASIA VULVAR LEVE'},
{ codigo: 'N901', descripcion: 'DISPLASIA VULVAR MODERADA'},
{ codigo: 'N902', descripcion: 'DISPLASIA VULVAR SEVERA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'N903', descripcion: 'DISPLASIA DE LA VULVA, NO ESPECIFICADA'},
{ codigo: 'N904', descripcion: 'LEUCOPLASIA DE LA VULVA'},
{ codigo: 'N905', descripcion: 'ATROFIA DE LA VULVA'},
{ codigo: 'N906', descripcion: 'HIPERTROFIA DE LA VULVA'},
{ codigo: 'N907', descripcion: 'QUISTE DE LA VULVA'},
{ codigo: 'N908', descripcion: 'OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DE LA VULVA Y DEL PERINEO'},
{ codigo: 'N909', descripcion: 'TRASTORNO NO INFLAMATORIO DE LA VULVA Y DEL PERINEO, NO ESPECIFICADO'},
{ codigo: 'N91', descripcion: 'MENSTRUACION AUSENTE, ESCASA O RARA'},
{ codigo: 'N910', descripcion: 'AMENORREA PRIMARIA'},
{ codigo: 'N911', descripcion: 'AMENORREA SECUNDARIA'},
{ codigo: 'N912', descripcion: 'AMENORREA, SIN OTRA ESPECIFICACION'},
{ codigo: 'N913', descripcion: 'OLIGOMENORREA PRIMARIA'},
{ codigo: 'N914', descripcion: 'OLIGOMENORREA SECUNDARIA'},
{ codigo: 'N915', descripcion: 'OLIGOMENORREA, NO ESPECIFICADA'},
{ codigo: 'N92', descripcion: 'MENSTRUACION EXCESIVA, FRECUENTE E IRREGULAR'},
{ codigo: 'N920', descripcion: 'MENSTRUACION EXCESIVA Y FRECUENTE CON CICLO REGULAR'},
{ codigo: 'N921', descripcion: 'MENSTRUACION EXCESIVA Y FRECUENTE CON CICLO IRREGULAR'},
{ codigo: 'N922', descripcion: 'MENSTRUACION EXCESIVA EN LA PUBERTAD'},
{ codigo: 'N923', descripcion: 'HEMORRAGIA POR OVULACION'},
{ codigo: 'N924', descripcion: 'HEMORRAGIA EXCESIVA EN PERIODO PREMENOPAUSICO'},
{ codigo: 'N925', descripcion: 'OTRAS MENSTRUACIONES IRREGULARES ESPECIFICADAS'},
{ codigo: 'N926', descripcion: 'MENSTRUACION IRREGULAR, NO ESPECIFICADA'},
{ codigo: 'N93', descripcion: 'OTRAS HEMORRAGIAS UTERINAS O VAGINALES ANORMALES'},
{ codigo: 'N930', descripcion: 'HEMORRAGIA POSTCOITO Y POSTCONTACTO'},
{ codigo: 'N938', descripcion: 'OTRAS HEMORRAGIAS UTERINAS O VAGINALES ANORMALES ESPECIFICADAS'},
{ codigo: 'N939', descripcion: 'HEMORRAGIA VAGINAL Y UTERINA ANORMAL, NO ESPECIFICADA'},
{ codigo: 'N94', descripcion: 'DOLOR Y OTRAS AFEC RELAC CON ORG GENITALES FEMENINOS Y CICLO MESTRUAL'},
{ codigo: 'N940', descripcion: 'DOLOR INTERMENSTRUAL'},
{ codigo: 'N941', descripcion: 'DISPAREUNIA'},
{ codigo: 'N942', descripcion: 'VAGINISMO'},
{ codigo: 'N943', descripcion: 'SINDROME DE TENSION PREMENSTRUAL'},
{ codigo: 'N944', descripcion: 'DISMENORREA PRIMARIA'},
{ codigo: 'N945', descripcion: 'DISMENORREA SECUNDARIA'},
{ codigo: 'N946', descripcion: 'DISMENORREA, NO ESPECIFICADA'},
{ codigo: 'N948', descripcion: 'OTRAS AFECCIONES ESPECIFICADAS ASOCIADAS CON ORGANOS GENITALES FEMENINO Y EL CICLO MESTRUAL'},
{ codigo: 'N949', descripcion: 'AFECCIONES NO ESPECIFICADAS ASOCIADAS CON ORGANOS GENITALES FEMENINOS Y EL CICLO MESTRUAL'},
{ codigo: 'N95', descripcion: 'OTROS TRASTORNOS MENOPAUSICOS Y PERIMENOPAUSICOS'},
{ codigo: 'N950', descripcion: 'HEMORRAGIA POSTMENOPAUSICA'},
{ codigo: 'N951', descripcion: 'ESTADOS MENOPAUSICOS Y CLIMATERICOS FEMENINOS'},
{ codigo: 'N952', descripcion: 'VAGINITIS ATROFICA POSTMENOPAUSICA'},
{ codigo: 'N953', descripcion: 'ESTADOS ASOCIADOS CON MENOPAUSIA ARTIFICIAL'},
{ codigo: 'N958', descripcion: 'OTROS TRASTORNOS MENOPAUSICOS Y PERIMENOPAUSICOS ESPECIFICADOS'},
{ codigo: 'N959', descripcion: 'TRASTORNO MENOPAUSICO Y PERIMENOPAUSICO, NO ESPECIFICADO'},
{ codigo: 'N96', descripcion: 'ABORTADORA HABITUAL'},
{ codigo: 'N97', descripcion: 'INFERTILIDAD FEMENINA'},
{ codigo: 'N970', descripcion: 'INFERTILIDAD FEMENINA ASOCIADA CON FALTA DE OVULACION'},
{ codigo: 'N971', descripcion: 'INFERTILIDAD FEMENINA DE ORIGEN TUBARICO'},
{ codigo: 'N972', descripcion: 'INFERTILIDAD FEMENINA DE ORIGEN UTERINO'},
{ codigo: 'N973', descripcion: 'INFERTILIDAD FEMENINA DE ORIGEN CERVICAL'},
{ codigo: 'N974', descripcion: 'INFERTILIDAD FEMENINA ASOCIADA CON FACTORES MASCULINOS'},
{ codigo: 'N978', descripcion: 'INFERTILIDAD FEMENINA DE OTRO ORIGEN'},
{ codigo: 'N979', descripcion: 'INFERTILIDAD FEMENINA, NO ESPECIFICADA'},
{ codigo: 'N98', descripcion: 'COMPLICACIONES ASOCIADAS CON LA FECUNDACION ARTIFICIAL'},
{ codigo: 'N980', descripcion: 'INFECCION ASOCIADA CON INSEMINACION ARTIFICIAL'},
{ codigo: 'N981', descripcion: 'HIPERESTIMULACION DE OVARIOS'},
{ codigo: 'N982', descripcion: 'COMPLICACIONES EN EL INTENTO DE INTRODUCCION DEL HUEVO FECUNDADO EN LA FERTILIZACION IN VITRO'},
{ codigo: 'N983', descripcion: 'COMPLICACIONES EN EL INTENTO DE INTRODUCCION DEL EMBRION EN LA TRANSFERENCIA DE EMBRIONES'},
{ codigo: 'N988', descripcion: 'OTRAS COMPLICACIONES ASOCIADAS CON LA FECUNDACION ARTIFICIAL'},
{ codigo: 'N989', descripcion: 'COMPLICACION NO ESPECIFICADA ASOCIADA CON LA FECUNDACION ARTIFICIAL'},
{ codigo: 'N99', descripcion: 'TRASTORNOS DEL SISTEMA GENITOURINARIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'N990', descripcion: 'INSUFICIENCIA RENAL CONSECUTIVA A PROCEDIMIENTOS'},
{ codigo: 'N991', descripcion: 'ESTRECHEZ URETRAL CONSECUTIVA A PROCEDIMIENTOS'},
{ codigo: 'N992', descripcion: 'ADHERENCIAS POSTOPERATORIAS DE LA VAGINA'},
{ codigo: 'N993', descripcion: 'PROLAPSO DE LA CUPULA VAGINAL DESPUES DE HISTERECTOMIA'},
{ codigo: 'N994', descripcion: 'ADHERENCIAS PERITONEALES PELVICAS CONSECUTIVAS A PROCEDIMIENTOS'},
{ codigo: 'N995', descripcion: 'MAL FUNCIONAMIENTO DE ESTOMA EXTERNO DE VIAS URINARIAS'},
{ codigo: 'N998', descripcion: 'OTROS TRASTORNOS DEL SISTEMA GENITOURINARIO CONSECUTIVOS A PROCEDIMIENTOS'},
{ codigo: 'N999', descripcion: 'TRASTORNO NO ESPECIFICADO DEL SISTEMA GENITOURINARIO CONSECUTIVO A PROCEDIMINETOS'},
{ codigo: 'O00', descripcion: 'EMBARAZO ECTOPICO'},
{ codigo: 'O000', descripcion: 'EMBARAZO ABDOMINAL'},
{ codigo: 'O001', descripcion: 'EMBARAZO TUBARICO'},
{ codigo: 'O002', descripcion: 'EMBARAZO OVARICO'},
{ codigo: 'O008', descripcion: 'OTROS EMBARAZOS ECTOPICOS'},
{ codigo: 'O009', descripcion: 'EMBARAZO ECTOPICO, NO ESPECIFICADO'},
{ codigo: 'O01', descripcion: 'MOLA HIDATIFORME'},
{ codigo: 'O010', descripcion: 'MOLA HIDATIFORME CLASICA'},
{ codigo: 'O011', descripcion: 'MOLA HIDATIFORME, INCOMPLETA O PARCIAL'},
{ codigo: 'O019', descripcion: 'MOLA HIDATIFORME, NO ESPECIFICADA'},
{ codigo: 'O02', descripcion: 'OTROS PRODUCTOS ANORMALES DE LA CONCEPCION'},
{ codigo: 'O020', descripcion: 'DETENCION DEL DESARROLLO DEL HUEVO Y MOLA NO HIDATIFORME'},
{ codigo: 'O021', descripcion: 'ABORTO RETENIDO'},
{ codigo: 'O028', descripcion: 'OTROS PRODUCTOS ANORMALES ESPECIFICADOS DE LA CONCEPCION'},
{ codigo: 'O029', descripcion: 'PRODUCTO ANORMAL DE LA CONCEPCION, NO ESPECIFICADO'},
{ codigo: 'O03', descripcion: 'ABORTO ESPONTANEO'},
{ codigo: 'O030', descripcion: 'ABORTO ESPONTANEO, INCOMPLETO, COMPLICADO INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O031', descripcion: 'ABORTO ESPONTANEO, INCOMPLETO, COMPLICADO HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O032', descripcion: 'ABORTO ESPONTANEO, INCOMPLETO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O033', descripcion: 'ABORTO ESPONTANEO, INCOMPLETO, OTRAS COMPLICACIONES ESPECIFICADAS Y NO ESPECIFICADAS'},
{ codigo: 'O034', descripcion: 'ABORTO ESPONTANEO, INCOMPLETO, SIN COMPLICACION'},
{ codigo: 'O035', descripcion: 'ABORTO ESPONTANEO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O036', descripcion: 'ABORTO ESPONTANEO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O037', descripcion: 'ABORTO ESPONTANEO, COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O038', descripcion: 'ABORTO ESPONTANEO, COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y NO ESPECIFICADAS'},
{ codigo: 'O039', descripcion: 'ABORTO ESPONTANEO, COMPLETO O NO ESPECIFICADO, SIN COMPLICACION'},
{ codigo: 'O04', descripcion: 'ABORTO MEDICO'},
{ codigo: 'O040', descripcion: 'ABORTO MEDICO, INCOMPLETO, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O041', descripcion: 'ABORTO MEDICO, INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O042', descripcion: 'ABORTO MEDICO, INCOMPLETO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O043', descripcion: 'ABORTO MEDICO, INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y NO ESPECIFICADAS'},
{ codigo: 'O044', descripcion: 'ABORTO MEDICO, INCOMPLETO, SIN COMPLICACION'},
{ codigo: 'O045', descripcion: 'ABORTO MEDICO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O046', descripcion: 'ABORTO MEDICO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O047', descripcion: 'ABORTO MEDICO, COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O048', descripcion: 'ABORTO MEDICO, COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS'},
{ codigo: 'O049', descripcion: 'ABORTO MEDICO, COMPLETO O NO ESPECIFICADO, SIN COMPLICACION'},
{ codigo: 'O05', descripcion: 'OTRO ABORTO'},
{ codigo: 'O050', descripcion: 'OTRO ABORTO, INCOMPLETO, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O051', descripcion: 'OTRO ABORTO, INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O052', descripcion: 'OTRO ABORTO, INCOMPLETO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O053', descripcion: 'OTRO ABORTO, INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS'},
{ codigo: 'O054', descripcion: 'OTRO ABORTO, INCOMPLETO, SIN COMPLICACION'},
{ codigo: 'O055', descripcion: 'OTRO ABORTO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O056', descripcion: 'OTRO ABORTO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O057', descripcion: 'OTRO ABORTO, COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O058', descripcion: 'OTRO ABORTO, COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y NO ESPECIFICADAS'},
{ codigo: 'O059', descripcion: 'OTRO ABORTO, COMPLETO O NO ESPECIFICADO, SIN COMPLICACION'},
{ codigo: 'O06', descripcion: 'ABORTO NO ESPECIFICADO'},
{ codigo: 'O060', descripcion: 'ABORTO NO ESPECIFICADO, INCOMPLETO, COMPLICADO INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O061', descripcion: 'ABORTO NO ESPECIFICADO, INCOMPLETO, COMPLICADO HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O062', descripcion: 'ABORTO NO ESPECIFICADO, INCOMPLETO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O063', descripcion: 'ABORTO NO ESPECIFICADO, INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y NO ESPECIFICADAS'},
{ codigo: 'O064', descripcion: 'ABORTO NO ESPECIFICADO, INCOMPLETO, SIN COMPLICACION'},
{ codigo: 'O065', descripcion: 'ABORTO NO ESPECIFICADO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O066', descripcion: 'ABORTO NO ESPECIFICADO, COMPLETO O NO ESPECIFICADO, COMPLICADO CON HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O067', descripcion: 'ABORTO NO ESPECIFICADO, COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O068', descripcion: 'ABORTO NO ESPECIFICADO, COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y NO ESPECIFICADAS'},
{ codigo: 'O069', descripcion: 'ABORTO NO ESPECIFICADO, COMPLETO O NO ESPECIFICADO, SIN COMPLICACION'},
{ codigo: 'O07', descripcion: 'INTENTO FALLIDO DE ABORTO'},
{ codigo: 'O070', descripcion: 'FALLA INDUCCION MEDICA DEL ABORTO, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O071', descripcion: 'FALLA INDUCCION MEDICA DEL ABORTO, COMPLICADO CON HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O072', descripcion: 'FALLA INDUCCION MEDICA DEL ABORTO, COMPLICADO POR EMBOLIA'},
{ codigo: 'O073', descripcion: 'FALLA INDUCCION MEDICA DEL ABORTO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS'},
{ codigo: 'O074', descripcion: 'FALLA INDUCCION MEDICA DEL ABORTO, SIN COMPLICACION'},
{ codigo: 'O075', descripcion: 'OTROS INTENTOS FALLIDOS DE ABORTO Y NO ESPECIFICADOS, COMPLICADO CON INFECCION GENITAL Y PELVIANA'},
{ codigo: 'O076', descripcion: 'OTROS INTENTOS FALLIDOS DE ABORTO Y NO ESPECIFICADOS, COMPLICADO CON HEMORRAGIA EXCESIVA O TARDIA'},
{ codigo: 'O077', descripcion: 'OTROS INTENTOS FALLIDOS DE ABORTO Y NO ESPECIFICADOS, COMPLICADOS POR EMBOLIA'},
{ codigo: 'O078', descripcion: 'OTROS INTENTOS FALLIDOS DE ABORTO Y NO ESPECIFICADOS, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS'},
{ codigo: 'O079', descripcion: 'OTROS INTENTOS FALLIDOS DE ABORTO Y NO ESPECIFICADOS, SIN COMPLICACION'},
{ codigo: 'O08', descripcion: 'COMPLICACIONES CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O080', descripcion: 'INFECCION GENITAL Y PELVIANA CONSECUTIVO AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O081', descripcion: 'HEMORRAGIA EXCESIVA O TARDIA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O082', descripcion: 'EMBOLIA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O083', descripcion: 'CHOQUE CONSECUTIVO AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O084', descripcion: 'INSUFICIENCIA RENAL CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O085', descripcion: 'TRASTORNO METABOLICO CONSECUTIVO AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O086', descripcion: 'LESION DE ORGANOS O TEJIDOS DE LA PELVIS CONSECUTIVO AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O087', descripcion: 'OTRAS COMPLICACIONES VENOSAS CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O088', descripcion: 'OTRAS COMPLICACIONES CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O089', descripcion: 'COMPLICACIONES NO ESPECIFICADAS CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR'},
{ codigo: 'O10', descripcion: 'HIPERTENSION PREEXISTENTE QUE COMPLICA EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O100', descripcion: 'HIPERTENSION ESENCIAL PREEXISTENTE QUE COMPLICA EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O101', descripcion: 'ENFERMEDAD CARDIACA HIPERTENSIVA PREEXISTENTE QUE COMPLICA EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O102', descripcion: 'ENFERMEDAD RENAL HIPERTENSIVA PREEXISTENTE QUE COMPLICA EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O103', descripcion: 'ENFERMEDAD CARDIORRENAL HIPERTENSIVA PREEXISTENTE QUE COMPLICA EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O104', descripcion: 'HIPERTENSION SECUNDARIA PREEXISTENTE QUE COMPLICA EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O109', descripcion: 'HIPERTENSION PREEXISTENTE NO ESPECIFICADA QUE COMPLICA EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O11', descripcion: 'TRASTORNOS HIPERTENSIVOS PREEXISTENTES, CON PROTEINURIA AGREGADA'},
{ codigo: 'O12', descripcion: 'EDEMA Y PROTEINURIA GESTACIONALES (INDUCIDOS POR EL EMBARAZO) SIN HIPERTENSION'},
{ codigo: 'O120', descripcion: 'EDEMA GESTACIONAL'},
{ codigo: 'O121', descripcion: 'PROTEINURIA GESTACIONAL'},
{ codigo: 'O122', descripcion: 'EDEMA GESTACIONAL CON PROTEINURIA'},
{ codigo: 'O13', descripcion: 'HIPERTENSION GESTACIONAL (INDUCIDA EMBARAZO)SIN PROTEINURIA SIGNIFICATIVA'},
{ codigo: 'O14', descripcion: 'HIPERTENSION GESTACIONAL (INDUCIDA EMBARAZO)CON PROTEINURIA SIGNIFICATIVA'},
{ codigo: 'O140', descripcion: 'PREECLAMPSIA MODERADA'},
{ codigo: 'O141', descripcion: 'PREECLAMPSIA SEVERA'},
{ codigo: 'O149', descripcion: 'PREECLAMPSIA, NO ESPECIFICADA'},
{ codigo: 'O15', descripcion: 'ECLAMPSIA'},
{ codigo: 'O150', descripcion: 'ECLAMPSIA EN EL EMBARAZO'},
{ codigo: 'O151', descripcion: 'ECLAMPSIA DURANTE EL TRABAJO DE PARTO'},
{ codigo: 'O152', descripcion: 'ECLAMPSIA EN EL PUERPERIO'},
{ codigo: 'O159', descripcion: 'ECLAMPSIA, EN PERIODO NO ESPECIFICADO'},
{ codigo: 'O16', descripcion: 'HIPERTENSION MATERNA, NO ESPECIFICADA'},
{ codigo: 'O20', descripcion: 'HEMORRAGIA PRECOZ DEL EMBARAZO'},
{ codigo: 'O200', descripcion: 'AMENAZA DE ABORTO'},
{ codigo: 'O208', descripcion: 'OTRAS HEMORRAGIAS PRECOCES DEL EMBARAZO'},
{ codigo: 'O209', descripcion: 'HEMORRAGIA PRECOZ DEL EMBARAZO, SIN OTRA ESPECIFICACION'},
{ codigo: 'O21', descripcion: 'VOMITOS EXCESIVOS EN EL EMBARAZO'},
{ codigo: 'O210', descripcion: 'HIPEREMESIS GRAVIDICA LEVE'},
{ codigo: 'O211', descripcion: 'HIPEREMESIS GRAVIDICA CON TRASTORNOS METABOLICOS'},
{ codigo: 'O212', descripcion: 'HIPEREMESIS GRAVIDICA TARDIA'},
{ codigo: 'O218', descripcion: 'OTROS VOMITOS QUE COMPLICAN EL EMBARAZO'},
{ codigo: 'O219', descripcion: 'VOMITOS DEL EMBARAZO, NO ESPECIFICADOS'},
{ codigo: 'O22', descripcion: 'COMPLICACIONES VENOSAS EN EL EMBARAZO'},
{ codigo: 'O220', descripcion: 'VENAS VARICOSAS DE LOS MIEMBROS INFERIORES EN EL EMBARAZO'},
{ codigo: 'O221', descripcion: 'VARICES GENITALES EN EL EMBARAZO'},
{ codigo: 'O222', descripcion: 'TROMBOFLEBITIS SUPERFICIAL EN EL EMBARAZO'},
{ codigo: 'O223', descripcion: 'FLEBOTROMBOSIS PROFUNDA EN EL EMBARAZO'},
{ codigo: 'O224', descripcion: 'HEMORROIDES EN EL EMBARAZO'},
{ codigo: 'O225', descripcion: 'TROMBOSIS VENOSA CEREBRAL EN EL EMBARAZO'},
{ codigo: 'O228', descripcion: 'OTRAS COMPLICACIONES VENOSAS EN EL EMBARAZO'},
{ codigo: 'O229', descripcion: 'COMPLICACION VENOSA NO ESPECIFICADA EN EL EMBARAZO'},
{ codigo: 'O23', descripcion: 'INFECCION DE LAS VIAS GENITOURINARIAS EN EL EMBARAZO'},
{ codigo: 'O230', descripcion: 'INFECCION DEL RI—ON EN EL EMBARAZO'},
{ codigo: 'O231', descripcion: 'INFECCION DE LA VEJIGA URINARIA EN EL EMBARAZO'},
{ codigo: 'O232', descripcion: 'INFECCION DE LA URETRA EN EL EMBARAZO'},
{ codigo: 'O233', descripcion: 'INFECCION DE OTRAS PARTES DE LAS VIAS URINARIAS EN EL EMBARAZO'},
{ codigo: 'O234', descripcion: 'INFECCION NO ESPECIFICADA DE LAS VIAS URINARIAS EN EL EMBARAZO'},
{ codigo: 'O235', descripcion: 'INFECCION GENITAL EN EL EMBARAZO'},
{ codigo: 'O239', descripcion: 'OTRAS INFECCIONES Y LAS NO ESPECIFICADAS DE VIAS GENITOURINARIAS EN EL EMBARAZO'},
{ codigo: 'O24', descripcion: 'DIABETES MELLITUS EN EL EMBARAZO'},
{ codigo: 'O240', descripcion: 'DIABETES MELLITUS PREEXISTENTE INSULINODEPENDIENTE, EN EL EMBARAZO'},
{ codigo: 'O241', descripcion: 'DIABETES MELLITUS PREEXISTENTE NO INSULINODEPENDIENTE, EN EL EMBARAZO'},
{ codigo: 'O242', descripcion: 'DIABETES MELLITUS PREEXISTENTE RELACIONADA CON DESNUTRICION, EN EL EMBARAZO'},
{ codigo: 'O243', descripcion: 'DIABETES MELLITUS PREEXISTENTE, SIN OTRA ESPECIFICACION, EN EL EMBARAZO'},
{ codigo: 'O244', descripcion: 'DIABETES MELLITUS QUE SE ORIGINA CON EL EMBARAZO'},
{ codigo: 'O249', descripcion: 'DIABETES MELLITUS NO ESPECIFICADA, EN EL EMBARAZO'},
{ codigo: 'O25', descripcion: 'DESNUTRICION EN EL EMBARAZO'},
{ codigo: 'O26', descripcion: 'ATENCION A LA MADRE POR OTRAS COMPLICACIONES PRINCIPALMENTE RELACIONADAS CON EL EMBARAZO'},
{ codigo: 'O260', descripcion: 'AUMENTO EXCESIVO DE PESO EN EL EMBARAZO'},
{ codigo: 'O261', descripcion: 'AUMENTO PEQUE—O DE PESO EN EL EMBARAZO'},
{ codigo: 'O262', descripcion: 'ATENCION DEL EMBARAZO EN UNA ABORTADORA HABITUAL'},
{ codigo: 'O263', descripcion: 'RETENCION DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO EN EL EMBARAZO'},
{ codigo: 'O264', descripcion: 'HERPES GESTACIONAL'},
{ codigo: 'O265', descripcion: 'SINDROME DE HIPOTENSION MATERNA'},
{ codigo: 'O266', descripcion: 'TRASTORNOS DEL HIGADO EN EL EMBARAZO, EL PARTO Y EL PUERPERIO'},
{ codigo: 'O267', descripcion: 'SUBLUXACION DE LA SINFISIS (DEL PUBIS) EN EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O268', descripcion: 'OTRAS COMPLICACIONES ESPECIFICADAS RELACIONADAS CON EL EMBARAZO'},
{ codigo: 'O269', descripcion: 'COMPLICACION RELACIONADA CON EL EMBARAZO, NO ESPECIFICADA'},
{ codigo: 'O28', descripcion: 'HALLAZGOS ANORMALES EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O280', descripcion: 'HALLAZGO HEMATOLOGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O281', descripcion: 'HALLAZGO BIOQUIMICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O282', descripcion: 'HALLAZGO CITOLOGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O283', descripcion: 'HALLAZGO ULTRASONICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O284', descripcion: 'HALLAZGO RADIOLOGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O285', descripcion: 'HALLAZGO CROMOSOMICO O GENETICO ANORMAL EN EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O288', descripcion: 'OTROS HALLAZGOS ANORMALES EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O289', descripcion: 'HALLAZGO ANORMAL NO ESPECIFICADO EN EL EXAMEN PRENATAL DE LA MADRE'},
{ codigo: 'O29', descripcion: 'COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO'},
{ codigo: 'O290', descripcion: 'COMPLICACIONES PULMONARES DE ANESTESIA ADMINISTRADA DURANTE EMBARAZO'},
{ codigo: 'O291', descripcion: 'COMPLICACIONES CARDIACAS DE LA ANESTESIA ADMINISTRADA DURANTE EMBARAZO'},
{ codigo: 'O292', descripcion: 'COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL POR LA ANESTESIA ADMINISTRADA DURANTE EMBARAZO'},
{ codigo: 'O293', descripcion: 'REACCION TOXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL EMBARAZO'},
{ codigo: 'O294', descripcion: 'CEFALALGIA INDUCIDA POR ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS EN EMBARAZO'},
{ codigo: 'O295', descripcion: 'OTRAS COMPLICACIONES DE LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS EN EMBARAZO'},
{ codigo: 'O296', descripcion: 'FALLA O DIFICULTAD EN LA INTUBACION DURANTE EL EMBARAZO'},
{ codigo: 'O298', descripcion: 'OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO'},
{ codigo: 'O299', descripcion: 'COMPLICACION NO ESPECIFICADA DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO'},
{ codigo: 'O30', descripcion: 'EMBARAZO MULTIPLE'},
{ codigo: 'O300', descripcion: 'EMBARAZO DOBLE'},
{ codigo: 'O301', descripcion: 'EMBARAZO TRIPLE'},
{ codigo: 'O302', descripcion: 'EMBARAZO CUADRUPLE'},
{ codigo: 'O308', descripcion: 'OTROS EMBARAZOS MULTIPLES'},
{ codigo: 'O309', descripcion: 'EMBARAZO MULTIPLE, NO ESPECIFICADO'},
{ codigo: 'O31', descripcion: 'COMPLICACIONES ESPECIFICAS DEL EMBARAZO MULTIPLE'},
{ codigo: 'O310', descripcion: 'FETO PAPIRACEO'},
{ codigo: 'O311', descripcion: 'EMBARAZO QUE CONTINUA DESPUES DEL ABORTO DE UN FETO O MAS'},
{ codigo: 'O312', descripcion: 'EMBARAZO QUE CONTINUA DESPUES DE MUERTE INTRAUTERINA DE UN FETO O MAS'},
{ codigo: 'O318', descripcion: 'OTRAS COMPLICACIONES ESPECIFICAS DEL EMBARAZO MULTIPLE'},
{ codigo: 'O32', descripcion: 'ATENCION MATERNA POR PRESENTACION ANORMAL DE FETO, CONOCIDA O PRESUNTA'},
{ codigo: 'O320', descripcion: 'ATENCION MATERNA POR POSICION FETAL INESTABLE'},
{ codigo: 'O321', descripcion: 'ATENCION MATERNA POR PRESENTACION DE NALGAS'},
{ codigo: 'O322', descripcion: 'ATENCION MATERNA POR POSICION FETAL OBLICUA O TRANSVERSA'},
{ codigo: 'O323', descripcion: 'ATENCION MATERNA POR PRESENTACION DE CARA, DE FRENTE O DE MENTON'},
{ codigo: 'O324', descripcion: 'ATENCION MATERNA POR CABEZA ALTA EN GESTACION A TERMINO'},
{ codigo: 'O325', descripcion: 'ATENCION MATERNA POR EMBARAZO MULTIPLE CON PRESENTACION ANORMAL DE UN FETO O MAS'},
{ codigo: 'O326', descripcion: 'ATENCION MATERNA POR PRESENTACION COMPUESTA'},
{ codigo: 'O328', descripcion: 'ATENCION MATERNA POR OTRAS PRESENTACIONES ANORMALES DEL FETO'},
{ codigo: 'O329', descripcion: 'ATENCION MATERNA POR PRESENTACION ANORMAL NO ESPECIFICADA DEL FETO'},
{ codigo: 'O33', descripcion: 'ATENCION MATERNA POR DESPROPORCION CONOCIDA O PRESUNTA'},
{ codigo: 'O330', descripcion: 'ATENCION MATERNA POR DESPROPORCION POR DEFORMIDAD PELVIS OSEA EN LA MADRE'},
{ codigo: 'O331', descripcion: 'ATENCION MATERNA POR DESPROPORCION DEBIDA A ESTRECHEZ GENERAL PELVIS'},
{ codigo: 'O332', descripcion: 'ATENCION MATERNA POR DESPROPORCION POR DISMINUCION DEL ESTRECHO SUPERIOR DE LA PELVIS'},
{ codigo: 'O333', descripcion: 'ATENCION MATERNA POR DESPROPORCION POR DISMINUCION DEL ESTRECHO INFERIOR DE LA PELVIS'},
{ codigo: 'O334', descripcion: 'ATENCION MATERNA POR DESPROPORCION FETOPELVIANA ORIGEN MIXTO, MATERNO Y FETAL'},
{ codigo: 'O335', descripcion: 'ATENCION MATERNA POR DESPROPORCION DEBIDA A FETO DEMASIADO GRANDE'},
{ codigo: 'O336', descripcion: 'ATENCION MATERNA POR DESPROPORCION DEBIDA A FETO HIDROCEFALICO'},
{ codigo: 'O337', descripcion: 'ATENCION MATERNA POR DESPROPORCION DEBIDA A OTRA DEFORMIDAD FETAL'},
{ codigo: 'O338', descripcion: 'ATENCION MATERNA POR DESPROPORCION DE OTRO ORIGEN'},
{ codigo: 'O339', descripcion: 'ATENCION MATERNA POR DESPROPORCION DE ORIGEN NO ESPECIFICADO'},
{ codigo: 'O34', descripcion: 'ATENCION MATERNA POR ANORMALIDADES CONOCIDAS O PRESUNTAS DE LOS ORGANOS PELVIANOS DE LA MADRE'},
{ codigo: 'O340', descripcion: 'ATENCION MATERNA POR ANOMALIA CONGENITA DEL UTERO'},
{ codigo: 'O341', descripcion: 'ATENCION MATERNA POR TUMOR DEL CUERPO DEL UTERO'},
{ codigo: 'O342', descripcion: 'ATENCION MATERNA POR CICATRIZ UTERINA DEBIDA A CIRUGIA PREVIA'},
{ codigo: 'O343', descripcion: 'ATENCION MATERNA POR INCOMPETENCIA DEL CUELLO UTERINO'},
{ codigo: 'O344', descripcion: 'ATENCION MATERNA POR OTRA ANORMALIDAD DEL CUELLO UTERINO'},
{ codigo: 'O345', descripcion: 'ATENCION MATERNA POR OTRAS ANORMALIDADES DEL UTERO GRAVIDO'},
{ codigo: 'O346', descripcion: 'ATENCION MATERNA POR ANORMALIDAD DE LA VAGINA'},
{ codigo: 'O347', descripcion: 'ATENCION MATERNA POR ANORMALIDAD DE LA VULVA Y DEL PERINEO'},
{ codigo: 'O348', descripcion: 'ATENCION MATERNA POR OTRAS ANORMALIDADES DE LOS ORGANOS PELVIANOS'},
{ codigo: 'O349', descripcion: 'ATENCION MATERNA POR ANORMALIDAD NO ESPECIFICADA DE ORGANO PELVIANO'},
{ codigo: 'O35', descripcion: 'ATENCION MATERNA POR ANORMALIDAD O LESION FETAL, CONOCIDA O PRESUNTA'},
{ codigo: 'O350', descripcion: 'ATENCION MATERNA POR (PRESUNTA) MALFORMACION DEL SISTEMA NERVIOSO CENTRAL EN EL FETO'},
{ codigo: 'O351', descripcion: 'ATENCION MATERNA POR (PRESUNTA) ANORMALIDAD CROMOSOMICA EN EL FETO'},
{ codigo: 'O352', descripcion: 'ATENCION MATERNA POR (PRESUNTA) ENFERMEDAD HEREDITARIA EN EL FETO'},
{ codigo: 'O353', descripcion: 'ATENCION MATERNA POR (PRESUNTA) LESION FETAL POR ENFERMEDAD VIRICA EN LA MADRE'},
{ codigo: 'O354', descripcion: 'ATENCION MATERNA POR (PRESUNTA) LESION AL FETO DEBIDA AL ALCOHOL'},
{ codigo: 'O355', descripcion: 'ATENCION MATERNA POR (PRESUNTA) LESION FETAL DEBIDA A DROGAS'},
{ codigo: 'O356', descripcion: 'ATENCION MATERNA POR (PRESUNTA) LESION AL FETO DEBIDA A RADIACION'},
{ codigo: 'O357', descripcion: 'ATENCION MATERNA POR (PRESUNTA) LESION FETAL POR OTROS PROCEDIMIENTOS MEDICOS'},
{ codigo: 'O358', descripcion: 'ATENCION MATERNA POR OTRAS (PRESUNTAS) ANORMALIDADES Y LESIONES FETALES'},
{ codigo: 'O359', descripcion: 'ATENCION MATERNA POR (PRESUNTA) ANORMALIDAD Y LESION FETAL NO ESPECIFICADA'},
{ codigo: 'O36', descripcion: 'ATENCION MATERNA POR OTROS PROBLEMAS FETALES CONOCIDOS O PRESUNTOS'},
{ codigo: 'O360', descripcion: 'ATENCION MATERNA POR ISOINMUNIZACION RHESUS'},
{ codigo: 'O361', descripcion: 'ATENCION MATERNA POR OTRA ISOINMUNIZACION'},
{ codigo: 'O362', descripcion: 'ATENCION MATERNA POR HIDROPESIA FETAL'},
{ codigo: 'O363', descripcion: 'ATENCION MATERNA POR SIGNOS DE HIPOXIA FETAL'},
{ codigo: 'O364', descripcion: 'ATENCION MATERNA POR MUERTE INTRAUTERINA'},
{ codigo: 'O365', descripcion: 'ATENCION MATERNA POR DEFICIT DEL CRECIMIENTO FETAL'},
{ codigo: 'O366', descripcion: 'ATENCION MATERNA POR CRECIMIENTO FETAL EXCESIVO'},
{ codigo: 'O367', descripcion: 'ATENCION MATERNA POR FETO VIABLE EN EMBARAZO ABDOMINAL'},
{ codigo: 'O368', descripcion: 'ATENCION MATERNA POR OTROS PROBLEMAS FETALES ESPECIFICADOS'},
{ codigo: 'O369', descripcion: 'ATENCION MATERNA POR PROBLEMAS FETALES NO ESPECIFICADOS'},
{ codigo: 'O40', descripcion: 'POLIHIDRAMNIOS'},
{ codigo: 'O41', descripcion: 'OTROS TRASTORNOS DEL LIQUIDO AMNIOTICO Y DE LAS MEMBRANAS'},
{ codigo: 'O410', descripcion: 'OLIGOHIDRAMNIOS'},
{ codigo: 'O411', descripcion: 'INFECCION DE LA BOLSA AMNIOTICA O DE LAS MEMBRANAS'},
{ codigo: 'O418', descripcion: 'OTROS TRASTORNOS ESPECIF DEL LIQUIDO AMNIOTICO Y DE LAS MEMBRANAS'},
{ codigo: 'O419', descripcion: 'TRASTORNO DEL LIQUIDO AMNIOTICO Y DE LAS MEMBRANAS, NO ESPECIFICADO'},
{ codigo: 'O42', descripcion: 'RUPTURA PREMATURA DE LAS MEMBRANAS'},
{ codigo: 'O420', descripcion: 'RUPTURA PREMATURA DE LAS MEMBRANAS, INICIO TRABAJO DE PARTO DENTRO DE LAS 24 HORAS'},
{ codigo: 'O421', descripcion: 'RUPTURA PREMATURA DE LAS MEMBRANAS, INICIO DE TRABAJO DE PARTO DESPUES DE 24 HORAS'},
{ codigo: 'O422', descripcion: 'RUPTURA PREMATURA DE LAS MEMBRANAS,TRABAJO DE PARTO RETRASADO POR TERAPEUTICA'},
{ codigo: 'O429', descripcion: 'RUPTURA PREMATURA DE LAS MEMBRANAS, SIN OTRA ESPECIFICACION'},
{ codigo: 'O43', descripcion: 'TRASTORNOS PLACENTARIOS'},
{ codigo: 'O430', descripcion: 'SINDROME DE TRANSFUSION PLACENTARIA'},
{ codigo: 'O431', descripcion: 'MALFORMACION DE LA PLACENTA'},
{ codigo: 'O438', descripcion: 'OTROS TRASTORNOS PLACENTARIOS'},
{ codigo: 'O439', descripcion: 'TRASTORNO DE LA PLACENTA, NO ESPECIFICADO'},
{ codigo: 'O44', descripcion: 'PLACENTA PREVIA'},
{ codigo: 'O440', descripcion: 'PLACENTA PREVIA CON ESPECIFICACION DE QUE NO HUBO HEMORRAGIA'},
{ codigo: 'O441', descripcion: 'PLACENTA PREVIA CON HEMORRAGIA'},
{ codigo: 'O45', descripcion: 'DESPRENDIMIENTO PREMATURO DE LA PLACENTA (ABRUPTIO PLACENTAE)'},
{ codigo: 'O450', descripcion: 'DESPRENDIMIENTO PREMATURO DE LA PLACENTA CON DEFECTO DE LA COAGULACION'},
{ codigo: 'O458', descripcion: 'OTROS DESPRENDIMIENTOS PREMATUROS DE LA PLACENTA'},
{ codigo: 'O459', descripcion: 'DESPRENDIMIENTO PREMATURO DE LA PLACENTA, SIN OTRA ESPECIFICACION'},
{ codigo: 'O46', descripcion: 'HEMORRAGIA ANTEPARTO, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'O460', descripcion: 'HEMORRAGIA ANTEPARTO CON DEFECTO DE LA COAGULACION'},
{ codigo: 'O468', descripcion: 'OTRAS HEMORRAGIAS ANTEPARTO'},
{ codigo: 'O469', descripcion: 'HEMORRAGIA ANTEPARTO, NO ESPECIFICADA'},
{ codigo: 'O47', descripcion: 'FALSO TRABAJO DE PARTO'},
{ codigo: 'O470', descripcion: 'FALSO TRABAJO DE PARTO ANTES DE LAS 37 SEMANAS COMPLETAS DE GESTACION'},
{ codigo: 'O471', descripcion: 'FALSO TRABAJO DE PARTO A LAS 37 Y MAS SEMANAS COMPLETAS DE GESTACION'},
{ codigo: 'O479', descripcion: 'FALSO TRABAJO DE PARTO, SIN OTRA ESPECIFICACION'},
{ codigo: 'O48', descripcion: 'EMBARAZO PROLONGADO'},
{ codigo: 'O60', descripcion: 'PARTO PREMATURO'},
{ codigo: 'O61', descripcion: 'FRACASO DE LA INDUCCION DEL TRABAJO DE PARTO'},
{ codigo: 'O610', descripcion: 'FRACASO DE LA INDUCCION MEDICA DEL TRABAJO DE PARTO'},
{ codigo: 'O611', descripcion: 'FRACASO DE LA INDUCCION INSTRUMENTAL DEL TRABAJO DE PARTO'},
{ codigo: 'O618', descripcion: 'OTROS FRACASOS DE LA INDUCCION DEL TRABAJO DE PARTO'},
{ codigo: 'O619', descripcion: 'FRACASO NO ESPECIFICADO DE LA INDUCCION DEL TRABAJO DE PARTO'},
{ codigo: 'O62', descripcion: 'ANORMALIDADES DE LA DINAMICA DEL TRABAJO DE PARTO'},
{ codigo: 'O620', descripcion: 'CONTRACCIONES PRIMARIAS INADECUADAS'},
{ codigo: 'O621', descripcion: 'INERCIA UTERINA SECUNDARIA'},
{ codigo: 'O622', descripcion: 'OTRAS INERCIAS UTERINAS'},
{ codigo: 'O623', descripcion: 'TRABAJO DE PARTO PRECIPITADO'},
{ codigo: 'O624', descripcion: 'CONTRACCIONES UTERINAS HIPERTONICAS, INCOORDINADAS Y PROLONGADAS'},
{ codigo: 'O628', descripcion: 'OTRAS ANOMALIAS DINAMICAS DEL TRABAJO DE PARTO'},
{ codigo: 'O629', descripcion: 'ANOMALIA DINAMICA DEL TRABAJO DE PARTO, NO ESPECIFICADA'},
{ codigo: 'O63', descripcion: 'TRABAJO DE PARTO PROLONGADO'},
{ codigo: 'O630', descripcion: 'PROLONGACION DEL PRIMER PERIODO (DEL TRABAJO DE PARTO)'},
{ codigo: 'O631', descripcion: 'PROLONGACION DEL SEGUNDO PERIODO (DEL TRABAJO DE PARTO)'},
{ codigo: 'O632', descripcion: 'RETRASO DE LA EXPULSION DEL SEGUNDO GEMELO, DEL TERCERO, ETC.'},
{ codigo: 'O639', descripcion: 'TRABAJO DE PARTO PROLONGADO, NO ESPECIFICADO'},
{ codigo: 'O64', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A MALA POSICION Y PRESENTACION ANORMAL DEL FETO'},
{ codigo: 'O640', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A ROTACION INCOMPLETA DE LA CABEZA FETAL'},
{ codigo: 'O641', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE NALGAS'},
{ codigo: 'O642', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE CARA'},
{ codigo: 'O643', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE FRENTE'},
{ codigo: 'O644', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE HOMBRO'},
{ codigo: 'O645', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION COMPUESTA'},
{ codigo: 'O648', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS PRESENTACIONES ANORMALES DEL FETO'},
{ codigo: 'O649', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION ANORMAL DEL FETO NO ESPECIFICADA'},
{ codigo: 'O65', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANORMALIDAD DE LA PELVIS MATERNA'},
{ codigo: 'O650', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A DEFORMIDAD DE LA PELVIS'},
{ codigo: 'O651', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A ESTRECHEZ GENERAL DE LA PELVIS'},
{ codigo: 'O652', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISMINUCION DEL ESTRECHO SUP DE PELVIS'},
{ codigo: 'O653', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISMINUCION DEL ESTRECHO INFERIOR DE PELVIS'},
{ codigo: 'O654', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A DESPROPORCION FETOPELVIANA, SIN OTRA ESPECIFICACION'},
{ codigo: 'O655', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANOMALIAS DE ORGANOS PELVIANOS MATERNOS'},
{ codigo: 'O658', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS ANOMALIAS PELVIANAS MATERNAS'},
{ codigo: 'O659', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANOMALIA PELVIANA NO ESPECIFICADA'},
{ codigo: 'O66', descripcion: 'OTRAS OBSTRUCCIONES DEL TRABAJO DE PARTO'},
{ codigo: 'O660', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA DE HOMBROS'},
{ codigo: 'O661', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA GEMELAR'},
{ codigo: 'O662', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA POR FETO INUSUALMENTE GRANDE'},
{ codigo: 'O663', descripcion: 'TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS ANORMALIDADES DEL FETO'},
{ codigo: 'O664', descripcion: 'FRACASO DE LA PRUEBA DEL TRABAJO DE PARTO, NO ESPECIFICADA'},
{ codigo: 'O665', descripcion: 'FRACASO NO ESPECIFICADO DE LA APLICACION DE FORCEPS O DE VENTOSA EXTRACTORA'},
{ codigo: 'O668', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DEL TRABAJO DE PARTO'},
{ codigo: 'O669', descripcion: 'TRABAJO DE PARTO OBSTRUIDO, SIN OTRA ESPECIFICACION'},
{ codigo: 'O67', descripcion: 'TRABAJO DE PARTO Y PARTOS COMPLICADOS POR HEMORRAGIA INTRAPARTO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'O670', descripcion: 'HEMORRAGIA INTRAPARTO CON DEFECTOS DE LA COAGULACION'},
{ codigo: 'O678', descripcion: 'OTRAS HEMORRAGIAS INTRAPARTO'},
{ codigo: 'O679', descripcion: 'HEMORRAGIA INTRAPARTO, NO ESPECIFICADA'},
{ codigo: 'O68', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR SUFRIMIENTO FETAL'},
{ codigo: 'O680', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR ANOMALIA DE FRECUENCIA CARDIACA FETAL'},
{ codigo: 'O681', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR PRESENCIA MECONIO EN LIQUIDO AMNIOTICO'},
{ codigo: 'O682', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR ANOMALIA EN FRECUENCIA CARDIACA FETAL ASOCIADA CON PRESENCIA DE MECONIO EN LIQUIDO AMNIOTICO'},
{ codigo: 'O683', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR EVIDENCIA BIOQUIMICA DE SUFRIMIENTO FETAL'},
{ codigo: 'O688', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTRAS EVIDENCIAS DE SUFRIMIENTO FETAL'},
{ codigo: 'O689', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR SUFRIMIENTO FETAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'O69', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROBLEMAS DE CORDON UMBILICAL'},
{ codigo: 'O690', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROLAPSO DEL CORDON UMBILICAL'},
{ codigo: 'O691', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR CIRCULAR PERICERVICAL CORDON, CON COMPRESION'},
{ codigo: 'O692', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTROS ENREDOS DEL CORDON'},
{ codigo: 'O693', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR CORDON UMBILICAL CORTO'},
{ codigo: 'O694', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR VASA PREVIA'},
{ codigo: 'O695', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR LESION VASCULAR DEL CORDON'},
{ codigo: 'O698', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTROS PROBLEMAS DE CORDON UMBILICAL'},
{ codigo: 'O699', descripcion: 'TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROBLEMAS NO ESPECIFICADOS DE CORDON UMBILICAL'},
{ codigo: 'O70', descripcion: 'DESGARRO PERINEAL DURANTE EL PARTO'},
{ codigo: 'O700', descripcion: 'DESGARRO PERINEAL DE PRIMER GRADO DURANTE EL PARTO'},
{ codigo: 'O701', descripcion: 'DESGARRO PERINEAL DE SEGUNDO GRADO DURANTE EL PARTO'},
{ codigo: 'O702', descripcion: 'DESGARRO PERINEAL DE TERCER GRADO DURANTE EL PARTO'},
{ codigo: 'O703', descripcion: 'DESGARRO PERINEAL DE CUARTO GRADO DURANTE EL PARTO'},
{ codigo: 'O709', descripcion: 'DESGARRO PERINEAL DURANTE EL PARTO, DE GRADO NO ESPECIFICADO'},
{ codigo: 'O71', descripcion: 'OTRO TRAUMA OBSTETRICO'},
{ codigo: 'O710', descripcion: 'RUPTURA DEL UTERO ANTES DEL INICIO DEL TRABAJO DE PARTO'},
{ codigo: 'O711', descripcion: 'RUPTURA DEL UTERO DURANTE EL TRABAJO DE PARTO'},
{ codigo: 'O712', descripcion: 'INVERSION DEL UTERO, POSTPARTO'},
{ codigo: 'O713', descripcion: 'DESGARRO OBSTETRICO DEL CUELLO UTERINO'},
{ codigo: 'O714', descripcion: 'DESGARRO VAGINAL OBSTETRICO ALTO, SOLO'},
{ codigo: 'O715', descripcion: 'OTROS TRAUMATISMOS OBSTETRICOS DE LOS ORGANOS PELVIANOS'},
{ codigo: 'O716', descripcion: 'TRAUMATISMO OBSTETRICO DE LOS LIGAMENTOS Y ARTICULACIONES DE LA PELVIS'},
{ codigo: 'O717', descripcion: 'HEMATOMA OBSTETRICO DE LA PELVIS'},
{ codigo: 'O718', descripcion: 'OTROS TRAUMAS OBSTETRICOS ESPECIFICADOS'},
{ codigo: 'O719', descripcion: 'TRAUMA OBSTETRICO, NO ESPECIFICADO'},
{ codigo: 'O72', descripcion: 'HEMORRAGIA POSTPARTO'},
{ codigo: 'O720', descripcion: 'HEMORRAGIA DEL TERCER PERIODO DEL PARTO'},
{ codigo: 'O721', descripcion: 'OTRAS HEMORRAGIAS POSTPARTO INMEDIATAS'},
{ codigo: 'O722', descripcion: 'HEMORRAGIA POSTPARTO SECUNDARIA O TARDIA'},
{ codigo: 'O723', descripcion: 'DEFECTO DE LA COAGULACION POSTPARTO'},
{ codigo: 'O73', descripcion: 'RETENCION DE LA PLACENTA O DE LAS MEMBRANAS, SIN HEMORRAGIA'},
{ codigo: 'O730', descripcion: 'RETENCION DE LA PLACENTA SIN HEMORRAGIA'},
{ codigo: 'O731', descripcion: 'RETENCION DE FRAGMENTOS DE LA PLACENTA O DE LAS MEMBRANAS, SIN HEMORRAGIA'},
{ codigo: 'O74', descripcion: 'COMPLICAIONES DE ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O740', descripcion: 'NEUMONITIS ASPIRACION ANESTESIA ADMINISTRADA EN TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O741', descripcion: 'OTRAS COMPLICACIONES PULMONARES POR ANESTESIA ADMINISTRADA EN TRABAJO DE PARTO Y PARTO'},
{ codigo: 'O742', descripcion: 'COMPLICACIONES CARDIACAS DE ANESTESIA ADMINISTRADA EN TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O743', descripcion: 'COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL POR ANESTESIA ADMINISTRADA EN EL TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O744', descripcion: 'REACCION TOXICA A ANESTESIA LOCAL ADMINISTRADA EN TRABAJO DE PARTO Y PARTO'},
{ codigo: 'O745', descripcion: 'CEFALALGIA INDUCIDA POR ANESTESIA ESPINAL O EPIDURAL ADMINISTRADA EN TRABAJO DE PARTO Y PARTO'},
{ codigo: 'O746', descripcion: 'OTRAS COMPLICACIONES DE ANESTESIA ESPINAL O EPIDURAL ADMINISTRADA EN TRABAJO DE PARTO Y PARTO'},
{ codigo: 'O747', descripcion: 'FALLA O DIFICULTAD EN INTUBACION DURANTE EL TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O748', descripcion: 'OTRAS COMPLICACIONES DE ANESTESIA ADMINISTRADA EN EL TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O749', descripcion: 'COMPLICACIONES NO ESPECIFICADAS DE ANESTESIA ADMINISTRADA EN TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O75', descripcion: 'OTRAS COMPLICACIONES DEL TRABAJO DE PARTO Y DEL PARTO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'O750', descripcion: 'SUFRIMIENTO MATERNO DURANTE EL TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O751', descripcion: 'CHOQUE DURANTE O DESPUES DEL TRABAJO DE PARTO Y EL PARTO'},
{ codigo: 'O752', descripcion: 'PIREXIA DURANTE EL TRABAJO DE PARTO, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'O753', descripcion: 'OTRAS INFECCIONES DURANTE EL TRABAJO DE PARTO'},
{ codigo: 'O754', descripcion: 'OTRAS COMPLICACIONES DE LA CIRUGIA Y DE OTROS PROCEDIMIENTOS OBSTETRICOS'},
{ codigo: 'O755', descripcion: 'RETRASO DEL PARTO DESPUES DE LA RUPTURA ARTIFICIAL DE LAS MEMBRANAS'},
{ codigo: 'O756', descripcion: 'RETRASO DEL PARTO DESPUES DE LA RUPTURA ESPONTANEA O NO ESPECIFICADA DE MEMBRANA'},
{ codigo: 'O757', descripcion: 'PARTO VAGINAL POSTERIOR A UNA CESAREA PREVIA'},
{ codigo: 'O758', descripcion: 'OTRAS COMPLICACIONES ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO'},
{ codigo: 'O759', descripcion: 'COMPLICACION NO ESPECIFICADA DEL TRABAJO DE PARTO Y DEL PARTO'},
{ codigo: 'O80', descripcion: 'PARTO UNICO ESPONTANEO'},
{ codigo: 'O800', descripcion: 'PARTO UNICO ESPONTANEO, PRESENTACION CEFALICA DE VERTICE'},
{ codigo: 'O801', descripcion: 'PARTO UNICO ESPONTANEO, PRESENTACION DE NALGAS O PODALICA'},
{ codigo: 'O808', descripcion: 'PARTO UNICO ESPONTANEO, OTRAS PRESENTACIONES'},
{ codigo: 'O809', descripcion: 'PARTO UNICO ESPONTANEO, SIN OTRA ESPECIFICACION'},
{ codigo: 'O81', descripcion: 'PARTO UNICO CON FORCEPS Y VENTOSA EXTRACTORA'},
{ codigo: 'O810', descripcion: 'PARTO CON FORCEPS BAJO'},
{ codigo: 'O811', descripcion: 'PARTO CON FORCEPS MEDIO'},
{ codigo: 'O812', descripcion: 'PARTO CON FORCEPS MEDIO CON ROTACION'},
{ codigo: 'O813', descripcion: 'PARTO CON FORCEPS DE OTROS TIPOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'O814', descripcion: 'PARTO CON VENTOSA EXTRACTORA'},
{ codigo: 'O815', descripcion: 'PARTO CON COMBINACION DE FORCEPS Y VENTOSA EXTRACTORA'},
{ codigo: 'O82', descripcion: 'PARTO UNICO POR CESAREA'},
{ codigo: 'O820', descripcion: 'PARTO POR CESAREA ELECTIVA'},
{ codigo: 'O821', descripcion: 'PARTO POR CESAREA DE EMERGENCIA'},
{ codigo: 'O822', descripcion: 'PARTO POR CESAREA CON HISTERECTOMIA'},
{ codigo: 'O828', descripcion: 'OTROS PARTOS UNICOS POR CESAREA'},
{ codigo: 'O829', descripcion: 'PARTO POR CESAREA, SIN OTRA ESPECIFICACION'},
{ codigo: 'O83', descripcion: 'OTROS PARTOS UNICOS ASISTIDOS'},
{ codigo: 'O830', descripcion: 'EXTRACCION DE NALGAS'},
{ codigo: 'O831', descripcion: 'OTROS PARTOS UNICOS ASISTIDOS, DE NALGAS'},
{ codigo: 'O832', descripcion: 'OTROS PARTOS UNICOS CON AYUDA DE MANIPULACION OBSTETRICA'},
{ codigo: 'O833', descripcion: 'PARTO DE FETO VIABLE EN EMBARAZO ABDOMINAL'},
{ codigo: 'O834', descripcion: 'OPERACION DESTRUCTIVA PARA FACILITAR EL PARTO'},
{ codigo: 'O838', descripcion: 'OTROS PARTOS UNICOS ASISTIDOS ESPECIFICADOS'},
{ codigo: 'O839', descripcion: 'PARTO UNICO ASISTIDO, SIN OTRA ESPECIFICACION'},
{ codigo: 'O84', descripcion: 'PARTO MULTIPLE'},
{ codigo: 'O840', descripcion: 'PARTO MULTIPLE, TODOS ESPONTANEOS'},
{ codigo: 'O841', descripcion: 'PARTO MULTIPLE, TODOS POR FORCEPS Y VENTOSA EXTRACTORA'},
{ codigo: 'O842', descripcion: 'PARTO MULTIPLE, TODOS POR CESAREA'},
{ codigo: 'O848', descripcion: 'OTROS PARTOS MULTIPLES'},
{ codigo: 'O849', descripcion: 'PARTO MULTIPLE, NO ESPECIFICADO'},
{ codigo: 'O85', descripcion: 'SEPSIS PUERPERAL'},
{ codigo: 'O86', descripcion: 'OTRAS INFECCIONES PUERPERALES'},
{ codigo: 'O860', descripcion: 'INFECCION DE HERIDA QUIRURGICA OBSTETRICA'},
{ codigo: 'O861', descripcion: 'OTRAS INFECCIONES GENITALES CONSECUTIVAS AL PARTO'},
{ codigo: 'O862', descripcion: 'INFECCION DE LAS VIAS URINARIAS CONSECUTIVA AL PARTO'},
{ codigo: 'O863', descripcion: 'OTRAS INFECCIONES DE LAS VIAS GENITOURINARIAS CONSECUTIVAS AL PARTO'},
{ codigo: 'O864', descripcion: 'PIREXIA DE ORIGEN DESCONOCIDO CONSECUTIVA AL PARTO'},
{ codigo: 'O868', descripcion: 'OTRAS INFECCIONES PUERPERALES ESPECIFICADAS'},
{ codigo: 'O87', descripcion: 'COMPLICACIONES VENOSAS EN EL PUERPERIO'},
{ codigo: 'O870', descripcion: 'TROMBOFLEBITIS SUPERFICIAL EN EL PUERPERIO'},
{ codigo: 'O871', descripcion: 'FLEBOTROMBOSIS PROFUNDA EN EL PUERPERIO'},
{ codigo: 'O872', descripcion: 'HEMORROIDES EN EL PUERPERIO'},
{ codigo: 'O873', descripcion: 'TROMBOSIS VENOSA CEREBRAL EN EL PUERPERIO'},
{ codigo: 'O878', descripcion: 'OTRAS COMPLICACIONES VENOSAS EN EL PUERPERIO'},
{ codigo: 'O879', descripcion: 'COMPLICACION VENOSA EN EL PUERPERIO, NO ESPECIFICADA'},
{ codigo: 'O88', descripcion: 'EMBOLIA OBSTETRICA'},
{ codigo: 'O880', descripcion: 'EMBOLIA GASEOSA, OBSTETRICA'},
{ codigo: 'O881', descripcion: 'EMBOLIA DE LIQUIDO AMNIOTICO'},
{ codigo: 'O882', descripcion: 'EMBOLIA DE COAGULO SANGUINEO, OBSTETRICA'},
{ codigo: 'O883', descripcion: 'EMBOLIA SEPTICA Y PIEMICA, OBSTETRICA'},
{ codigo: 'O888', descripcion: 'OTRAS EMBOLIAS OBSTETRICAS'},
{ codigo: 'O89', descripcion: 'COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO'},
{ codigo: 'O890', descripcion: 'COMPLICACIONES PULMONARES DE LA ANESTESIA ADMINISTRADA EN EL PUERPERIO'},
{ codigo: 'O891', descripcion: 'COMPLICACIONES CARDIACAS DE LA ANESTESIA ADMINISTRADA EN EL PUERPERIO'},
{ codigo: 'O892', descripcion: 'COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL POR LA ANESTESIA ADMINISTRADA EN EL PUERPERIO'},
{ codigo: 'O893', descripcion: 'REACCION TOXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL PUERPERIO'},
{ codigo: 'O894', descripcion: 'CEFALALGIA POR ANESTESIA ESPINAL O EPIDURAL ADMINISTRADA EN PUERPERIO'},
{ codigo: 'O895', descripcion: 'OTRAS COMPLICACIONES DE ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS EN PUERPERIO'},
{ codigo: 'O896', descripcion: 'FALLA O DIFICULTAD DE INTUBACION DURANTE EL PUERPERIO'},
{ codigo: 'O898', descripcion: 'OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO'},
{ codigo: 'O899', descripcion: 'COMPLICACIONES NO ESPECIFICADAS DE ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO'},
{ codigo: 'O90', descripcion: 'COMPLICACIONES DEL PUERPERIO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'O900', descripcion: 'DEHISCENCIA DE SUTURA DE CESAREA'},
{ codigo: 'O901', descripcion: 'DEHISCENCIA DE SUTURA OBSTETRICA PERINEAL'},
{ codigo: 'O902', descripcion: 'HEMATOMA DE HERIDA QUIRURGICA OBSTETRICA'},
{ codigo: 'O903', descripcion: 'CARDIOMIOPATIA EN EL PUERPERIO'},
{ codigo: 'O904', descripcion: 'INSUFICIENCIA RENAL AGUDA POSTPARTO'},
{ codigo: 'O905', descripcion: 'TIROIDITIS POSTPARTO'},
{ codigo: 'O908', descripcion: 'OTRAS COMPLICACIONES PUERPERALES, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'O909', descripcion: 'COMPLICACION PUERPERAL, NO ESPECIFICADA'},
{ codigo: 'O91', descripcion: 'INFECCIONES DE LA MAMA ASOCIADAS CON EL PARTO'},
{ codigo: 'O910', descripcion: 'INFECCIONES DEL PEZON ASOCIADAS CON EL PARTO'},
{ codigo: 'O911', descripcion: 'ABSCESO DE LA MAMA ASOCIADO CON EL PARTO'},
{ codigo: 'O912', descripcion: 'MASTITIS NO PURULENTA ASOCIADA CON EL PARTO'},
{ codigo: 'O92', descripcion: 'OTROS TRASTORNOS DE LA MAMA Y DE LA LACTANCIA ASOCIADOS CON EL PARTO'},
{ codigo: 'O920', descripcion: 'RETRACCION DEL PEZON ASOCIADA CON EL PARTO'},
{ codigo: 'O921', descripcion: 'FISURAS DEL PEZON ASOCIADAS CON EL PARTO'},
{ codigo: 'O922', descripcion: 'OTROS TRASTORNOS DE LA MAMA Y LOS NO ESPECIFICADOS ASOCIADOS AL PARTO'},
{ codigo: 'O923', descripcion: 'AGALACTIA'},
{ codigo: 'O924', descripcion: 'HIPOGALACTIA'},
{ codigo: 'O925', descripcion: 'SUPRESION DE LA LACTANCIA'},
{ codigo: 'O926', descripcion: 'GALACTORREA'},
{ codigo: 'O927', descripcion: 'OTROS TRASTORNOS Y LOS NO ESPECIFICADOS DE LA LACTANCIA'},
{ codigo: 'O95', descripcion: 'MUERTE OBSTETRICA DE CAUSA NO ESPECIFICADA'},
{ codigo: 'O96', descripcion: 'MUERTE MATERNA DEBIDA A CUALQUIER CAUSA OBSTETRICA QUE OCURRE DESPUES DE 42 DIAS PERO ANTES DE UN A—O DEL PARTO'},
{ codigo: 'O97', descripcion: 'MUERTE POR SECUELAS DE CAUSAS OBSTETRICAS DIRECTAS'},
{ codigo: 'O98', descripcion: 'ENFERMEDADES MATERNAS INFECCIOSAS Y PARASITARIAS CLASIFICABLES EN OTRA PARTE, PERO QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O980', descripcion: 'TUBERCULOSIS QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO'},
{ codigo: 'O981', descripcion: 'SIFILIS QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO'},
{ codigo: 'O982', descripcion: 'GONORREA QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO'},
{ codigo: 'O983', descripcion: 'OTRAS INFECCIONES CON UN MODO DE TRANSMISION PREDOMINANTEMENTE SEXUAL QUE COMPLICA EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O984', descripcion: 'HEPATITIS VIRAL QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO'},
{ codigo: 'O985', descripcion: 'OTRAS ENFERMEADES VIRALES QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO'},
{ codigo: 'O986', descripcion: 'ENFERMEDADES CAUSADAS POR PROTOZOARIOS QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O988', descripcion: 'OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS MATERNAS QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O989', descripcion: 'ENFERMEDAD INFECCIOSA Y PARASITARIA MATERNA NO ESPECIFICADA QUE COMPLICA EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O99', descripcion: 'OTRAS ENFERMEDADES MATERNAS CLASIFICABLES EN OTRA PARTE, PERO QUE COMPLICAN EL EMBARAZO, PARTO, Y PUERPERIO'},
{ codigo: 'O990', descripcion: 'ANEMIA QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO'},
{ codigo: 'O991', descripcion: 'OTRAS ENFERMEDADES DE LA SANGRE Y LOS ORGANOS HEMATOPOYETICOS Y CIERTOS TRASTORNOS QUE AFECTAN EL SISTEMA INMUNITARIO CUANDO COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O992', descripcion: 'ENFERMEDADES ENDOCRINAS, DE NUTRICION Y DEL METABOLISMO QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O993', descripcion: 'TRASTORNOS MENTALES Y ENFERMEDADES DEL SISTEMA NERVIOSO QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O994', descripcion: 'ENFERMEDADES DEL SISTEMA CIRCULATORIO QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O995', descripcion: 'ENFERMEDADES DEL SISTEMA RESPIRATORIO QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O996', descripcion: 'ENFERMEDADES DEL SISTEMA DIGESTIVO QUE COMPLICAN EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'O997', descripcion: 'ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTANEO QUE COMPLICAN EL EMBARAZO, PART Y PUERPERIO'},
{ codigo: 'O998', descripcion: 'OTRAS ENFERMEDADES ESPECIFICADAS Y AFECCIONES QUE COMPLICAN EL EMBARAZO, PARTO Y PUERPERIO'},
{ codigo: 'P00', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR CONDICIONES DE LA MADRE NO NECESARIAMENTE RELACIONADAS CON EMBARAZO PRESENTE'},
{ codigo: 'P000', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR TRASTORNOS HIPERTENSIVOS DE MADRE'},
{ codigo: 'P001', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR ENFERMEDADES RENALES Y VIAS URINARIAS DE MADRE'},
{ codigo: 'P002', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR ENFERMEDADES INFECCIOSAS Y PARASITARIAS DE LA MADRE'},
{ codigo: 'P003', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS ENFERMEDADES CIRCULATORIAS Y RESPIRATORIAS DE LA MADRE'},
{ codigo: 'P004', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR TRASTORNOS NUTRICIONALES DE LA MADRE'},
{ codigo: 'P005', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR TRAUMATISMO DE LA MADRE'},
{ codigo: 'P006', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PROCEDIMIENTO QUIRURGICO EN LA MADRE'},
{ codigo: 'P007', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTROS PROCEDIMIENTOS MEDICO EN LA MADRE NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'P008', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS AFECCIONES MATERNAS'},
{ codigo: 'P009', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR AFECCION MATERNA NO ESPECIFICADA'},
{ codigo: 'P01', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR COMPLICACIONES MATERNAS DE EMBARAZO'},
{ codigo: 'P010', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR INCOMPETENCIA DEL CUELLO UTERINO'},
{ codigo: 'P011', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR RUPTURA PREMATURA DE LAS MEMBRANAS'},
{ codigo: 'P012', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OLIGOHIDRAMNIOS'},
{ codigo: 'P013', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR POLIHIDRAMNIOS'},
{ codigo: 'P014', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR EMBARAZO ECTOPICO'},
{ codigo: 'P015', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR EMBARAZO MULTIPLE'},
{ codigo: 'P016', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR MUERTE MATERNA'},
{ codigo: 'P017', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PRESENTACION ANOMALA ANTES DEL TRABAJO DE PARTO'},
{ codigo: 'P018', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES MATERNAS DEL EMBARAZO'},
{ codigo: 'P019', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR COMPLICACIONES MATERNAS NO ESPECIFICADAS DEL EMBARAZO'},
{ codigo: 'P02', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR COMPLICACIONES DE PLACENTA, CORDON UMBILICAL Y MEMBRANAS'},
{ codigo: 'P020', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PLACENTA PREVIA'},
{ codigo: 'P021', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS FORMAS DE DESPRENDIMIENTO Y HEMORRAGIA PLACENTARIOS'},
{ codigo: 'P022', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS ANORMALIDADES MORFOLOGICAS Y FUNCIONALES DE LA PLACENTA Y LAS NO ESPECIFICADAS'},
{ codigo: 'P023', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR SINDROMES DE TRANSFUSION PLACENTARIA'},
{ codigo: 'P024', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PROLAPSO DEL CORDON UMBILICAL'},
{ codigo: 'P025', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRA COMPRESION DE CORDON UMBILICAL'},
{ codigo: 'P026', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES DEL CORDON UMBILICAL Y LAS NO ESPECIFICADAS'},
{ codigo: 'P027', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR CORIOAMNIONITIS'},
{ codigo: 'P028', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS ANORMALIDADES DE MEMBRANAS'},
{ codigo: 'P029', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR ANORMALIDAD NO ESPECIFICADA DE MEMBRANAS'},
{ codigo: 'P03', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES DEL TRABAJO DE PARTO Y DEL PARTO'},
{ codigo: 'P030', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PARTO Y EXTRACCION DE NALGAS'},
{ codigo: 'P031', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRA PRESENTACION ANOMALA, POSICION ANOMALA Y DESPROPORCION DEL TRABAJO DE PARTO Y DEL PARTO'},
{ codigo: 'P032', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PARTO CON FORCEPS'},
{ codigo: 'P033', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PARTO CON VENTOSA EXTRACTORA'},
{ codigo: 'P034', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PARTO POR CESAREA'},
{ codigo: 'P035', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR PARTO PRECIPITADO'},
{ codigo: 'P036', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR CONTRACCIONES UTERINAS ANORMALES'},
{ codigo: 'P038', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES ESPECIFICAS DEL TRABAJO DE PARTO Y DEL PARTO'},
{ codigo: 'P039', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR COMPLICACIONES NO ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO'},
{ codigo: 'P04', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR INFLUENCIAS NOCIVAS TRANSMITIDAS A TRAVES DE LA PLACENTA O LECHE MATERNA'},
{ codigo: 'P040', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR ANESTESIA Y ANALGESIA MATERNA EN EL EMBARAZO, TRABAJO DE PARTO Y PARTO'},
{ codigo: 'P041', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS MEDICACIONES MATERNAS'},
{ codigo: 'P042', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR TABAQUISMO DE LA MADRE'},
{ codigo: 'P043', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR ALCOHOLISMO DE LA MADRE'},
{ codigo: 'P044', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR DROGRADICCION MATERNA'},
{ codigo: 'P045', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR USO MATERNO SUSTANCIAS QUIMICAS NUTRICIONALES'},
{ codigo: 'P046', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR EXPOSICION MATERNA A SUSTANCIAS QUIMICAS AMBIEMTALES'},
{ codigo: 'P048', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR OTRAS INFLUENCIAS NOCIVAS DE LA MADRE'},
{ codigo: 'P049', descripcion: 'FETO Y RECIEN NACIDO AFECTADOS POR INFLUENCIAS NOCIVAS DE LA MADRE, NO ESPECIFICADAS'},
{ codigo: 'P05', descripcion: 'RETARDO DEL CRECIMIENTO FETAL Y DESNUTRICION FETAL'},
{ codigo: 'P050', descripcion: 'BAJO PESO PARA LA EDAD GESTACIONAL'},
{ codigo: 'P051', descripcion: 'PEQUE—O PARA LA EDAD GESTACIONAL'},
{ codigo: 'P052', descripcion: 'DESNUTRICION FETAL, SIN MENCION DE PESO O TALLA BAJOS PARA EDAD GESTACIONAL'},
{ codigo: 'P059', descripcion: 'RETARDO DEL CRECIMIENTO FETAL, NO ESPECIFICADO'},
{ codigo: 'P07', descripcion: 'TRASTORNOS RELACIONADOS CON DURACION CORTA DE GESTACION Y BAJO PESO AL NACER, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'P070', descripcion: 'PESO EXTREMADAMENTE BAJO AL NACER'},
{ codigo: 'P071', descripcion: 'OTRO PESO BAJO AL NACER'},
{ codigo: 'P072', descripcion: 'INMATURIDAD EXTREMA'},
{ codigo: 'P073', descripcion: 'OTROS RECIEN NACIDOS PRETERMINO'},
{ codigo: 'P08', descripcion: 'TRASTORNOS RELACIONADOS CON EL EMBARAZO PROLONGADO Y CON SOBREPESO AL NACER'},
{ codigo: 'P080', descripcion: 'RECIEN NACIDO EXCEPCIONALMENTE GRANDE'},
{ codigo: 'P081', descripcion: 'OTROS RECIEN NACIDOS CON SOBREPESO PARA LA EDAD GESTACIONAL'},
{ codigo: 'P082', descripcion: 'RECIEN NACIDO POSTERMINO SIN SOBREPESO PARA SU EDAD GESTACIONAL'},
{ codigo: 'P10', descripcion: 'HEMORRAGIA Y LACERACION INTRACRANEAL POR TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P100', descripcion: 'HEMORRAGIA SUBDURAL DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P101', descripcion: 'HEMORRAGIA CEREBRAL DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P102', descripcion: 'HEMORRAGIA INTRAVENTRICULAR DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P103', descripcion: 'HEMORRAGIA SUBARACNOIDEA DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P104', descripcion: 'DESGARRO TENTORIAL DEBIDO A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P108', descripcion: 'OTRAS HEMORRAGIAS Y LACERACIONES INTRACRANEALES DEBIDAS A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P109', descripcion: 'HEMORRAGIA Y LACERACION INTRACRANEAL NO ESPECIFICADA, DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P11', descripcion: 'OTROS TRAUMATISMOS DEL NACIMIENTO EN EL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'P110', descripcion: 'EDEMA CEREBRAL DEBIDO A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P111', descripcion: 'OTRAS LESIONES ESPECIFICADAS DEL ENCEFALO, POR TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P112', descripcion: 'LESION NO ESPECIFICADA DEL ENCEFALO, POR TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P113', descripcion: 'TRAUMATISMO DEL NACIMIENTO EN EL NERVIO FACIAL'},
{ codigo: 'P114', descripcion: 'TRAUMATISMO DEL NACIMIENTO EN OTROS NERVIOS CRANEALES'},
{ codigo: 'P115', descripcion: 'TRAUMATISMO DEL NACIMIENTO EN LA COLUMNA VERTEBRAL Y EN MEDULA ESPINAL'},
{ codigo: 'P119', descripcion: 'TRAUMATISMO DEL NACIMIENTO EN EL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADAS'},
{ codigo: 'P12', descripcion: 'TRAUMATISMO DEL NACIMIENTO EN EL CUERO CABELLUDO'},
{ codigo: 'P120', descripcion: 'CEFALOHEMATOMA DEBIDO A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P121', descripcion: 'CAPUT SUCCEDANEUM DEBIDO A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P122', descripcion: 'HEMORRAGIA EPICRANEAL SUBAPONEUROTICA POR TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P123', descripcion: 'EQUIMOSIS DEL CUERO CABELLUDO DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P124', descripcion: 'TRAUMATISMO EN CUERO CABELLUDO DEL RECIEN NACIDO POR MONITOREO FETAL'},
{ codigo: 'P128', descripcion: 'OTROS TRAUMATISMOS DEL NACIMIENTO EN EL CUERO CABELLUDO'},
{ codigo: 'P129', descripcion: 'TRAUMATISMO DEL NACIMIENTO EN EL CUERO CABELLUDO, NO ESPECIFICADO'},
{ codigo: 'P13', descripcion: 'TRAUMATISMO DEL ESQUELETO DURANTE EL NACIMIENTO'},
{ codigo: 'P130', descripcion: 'FRACTURA DEL CRANEO DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P131', descripcion: 'OTROS TRAUMATISMOS DEL CRANEO DURANTE EL NACIMIENTO'},
{ codigo: 'P132', descripcion: 'TRAUMATISMO DEL FEMUR DURANTE EL NACIMIENTO'},
{ codigo: 'P133', descripcion: 'TRAUMATISMO DE OTROS HUESOS LARGOS DURANTE EL NACIMIENTO'},
{ codigo: 'P134', descripcion: 'FRACTURA DE LA CLAVICULA DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P138', descripcion: 'TRAUMATISMO DEL NACIMIENTO EN OTRAS PARTES DEL ESQUELETO'},
{ codigo: 'P139', descripcion: 'TRAUMATISMO NO ESPECIFICADO DEL ESQUELETO DURANTE EL NACIMIENTO'},
{ codigo: 'P14', descripcion: 'TRAUMATISMO DEL SISTEMA NERVIOSO PERIFERICO DURANTE EL NACIMIENTO'},
{ codigo: 'P140', descripcion: 'PARALISIS DE ERB DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P141', descripcion: 'PARALISIS DE KLUMPKE DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P142', descripcion: 'PARALISIS DEL NERVIO FRENICO DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P143', descripcion: 'OTRO TRAUMATISMO DEL PLEXO BRAQUIAL DURANTE EL NACIMIENTO'},
{ codigo: 'P148', descripcion: 'TRAUMATISMO EN NACIMIENTO EN OTRAS PARTES DEL SISTEMA NERVIOSO PERIFERICO'},
{ codigo: 'P149', descripcion: 'TRAUMATISMO NO ESPECIFICADO DEL SISTEMA NERVIOSO PERIFERICO EN EL NACIENTO'},
{ codigo: 'P15', descripcion: 'OTROS TRAUMATISMOS DEL NACIMIENTO'},
{ codigo: 'P150', descripcion: 'LESION DEL HIGADO DURANTE EL NACIMIENTO'},
{ codigo: 'P151', descripcion: 'LESION DEL BAZO DURANTE EL NACIMIENTO'},
{ codigo: 'P152', descripcion: 'TRAUMATISMO DEL MUSCULO ESTERNOCLEIDOMASTOIDEO DURANTE EL NACIMIENTO'},
{ codigo: 'P153', descripcion: 'TRAUMATISMO OCULAR DURANTE EL NACIMIENTO'},
{ codigo: 'P154', descripcion: 'TRAUMATISMO FACIAL DURANTE EL NACIMIENTO'},
{ codigo: 'P155', descripcion: 'TRAUMATISMO DE LOS GENITALES EXTERNOS DURANTE EL NACIMIENTO'},
{ codigo: 'P156', descripcion: 'NECROSIS GRASA SUBCUTANEA DEBIDA A TRAUMATISMO DEL NACIMIENTO'},
{ codigo: 'P158', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS, DURANTE EL NACIMIENTO'},
{ codigo: 'P159', descripcion: 'TRAUMATISMO NO ESPECIFICADO, DURANTE EL NACIMIENTO'},
{ codigo: 'P20', descripcion: 'HIPOXIA INTRAUTERINA'},
{ codigo: 'P200', descripcion: 'HIPOXIA INTRAUTERINA NOTADA POR PRIMERA VEZ ANTES DEL INICIO DEL TRABAJO DE PARTO'},
{ codigo: 'P201', descripcion: 'HIPOXIA INTRAUTERINA NOTADA POR PRIMERA VEZ EN EL TRABAJO DE PARTO Y DEL PARTO'},
{ codigo: 'P209', descripcion: 'HIPOXIA INTRAUTERINA, NO ESPECIFICADA'},
{ codigo: 'P21', descripcion: 'ASFIXIA DEL NACIMIENTO'},
{ codigo: 'P210', descripcion: 'ASFIXIA DEL NACIMIENTO, SEVERA'},
{ codigo: 'P211', descripcion: 'ASFIXIA DEL NACIMIENTO, LEVE Y MODERADA'},
{ codigo: 'P219', descripcion: 'ASFIXIA DEL NACIMIENTO, NO ESPECIFICADA'},
{ codigo: 'P22', descripcion: 'DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO'},
{ codigo: 'P220', descripcion: 'SINDROME DE DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO'},
{ codigo: 'P221', descripcion: 'TAQUIPNEA TRANSITORIA DEL RECIEN NACIDO'},
{ codigo: 'P228', descripcion: 'OTRAS DIFICULTADES RESPIRATORIAS DEL RECIEN NACIDO'},
{ codigo: 'P229', descripcion: 'DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO, NO ESPECIFICADA'},
{ codigo: 'P23', descripcion: 'NEUMONIA CONGENITA'},
{ codigo: 'P230', descripcion: 'NEUMONIA CONGENITA DEBIDA A AGENTE VIRAL'},
{ codigo: 'P231', descripcion: 'NEUMONIA CONGENITA DEBIDA A CHLAMYDIA'},
{ codigo: 'P232', descripcion: 'NEUMONIA CONGENITA DEBIDA A ESTAFILOCOCOS'},
{ codigo: 'P233', descripcion: 'NEUMONIA CONGENITA DEBIDA A ESTREPTOCOCOS DEL GRUPO B'},
{ codigo: 'P234', descripcion: 'NEUMONIA CONGENITA DEBIDA A ESCHERICHIA COLI'},
{ codigo: 'P235', descripcion: 'NEUMONIA CONGENITA DEBIDA A PSEUDOMONAS'},
{ codigo: 'P236', descripcion: 'NEUMONIA CONGENITA DEBIDA A OTROS AGENTES BACTERIANOS'},
{ codigo: 'P238', descripcion: 'NEUMONIA CONGENITA DEBIDA A OTROS ORGANISMOS'},
{ codigo: 'P239', descripcion: 'NEUMONIA CONGENITA, ORGANISMO NO ESPECIFICADO'},
{ codigo: 'P24', descripcion: 'SINDROMES DE ASPIRACION NEONATAL'},
{ codigo: 'P240', descripcion: 'ASPIRACION NEONATAL DE MECONIO'},
{ codigo: 'P241', descripcion: 'ASPIRACION NEONATAL DE LIQUIDO AMNIOTICO Y DE MOCO'},
{ codigo: 'P242', descripcion: 'ASPIRACION NEONATAL DE SANGRE'},
{ codigo: 'P243', descripcion: 'ASPIRACION NEONATAL DE LECHE Y ALIMENTO REGURGITADO'},
{ codigo: 'P248', descripcion: 'OTROS SINDROMES DE ASPIRACION NEONATAL'},
{ codigo: 'P249', descripcion: 'SINDROME DE ASPIRACION NEONATAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'P25', descripcion: 'ENFISEMA INTERSTICIAL Y AFECCIONES RELACIONADAS, ORIGINADAS EN EL PERIODO PERINATAL'},
{ codigo: 'P250', descripcion: 'ENFISEMA INTERSTICIAL ORIGINADO EN EL PERIODO PERINATAL'},
{ codigo: 'P251', descripcion: 'NEUMOTORAX ORIGINADO EN EL PERIODO PERINATAL'},
{ codigo: 'P252', descripcion: 'NEUMOMEDIASTINO ORIGINADO EN EL PERIODO PERINATAL'},
{ codigo: 'P253', descripcion: 'NEUMOPERICARDIO ORIGINADO EN EL PERIODO PERINATAL'},
{ codigo: 'P258', descripcion: 'OTRAS AFECCIONES RELACIONADS CON ENFISEMA INTERSTICIAL, ORIGINADAS EN PERIODO PERINATAL'},
{ codigo: 'P26', descripcion: 'HEMORRAGIA PULMONAR ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'P260', descripcion: 'HEMORRAGIA TRAQUEOBRONQUIAL ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'P261', descripcion: 'HEMORRAGIA PULMONAR MASIVA ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'P268', descripcion: 'OTRAS HEMORRAGIAS PULMONARES ORIGINADAS EN EL PERIODO PERINATAL'},
{ codigo: 'P269', descripcion: 'HEMORRAGIA PULMONAR NO ESPECIFICADA, ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'P27', descripcion: 'ENFERMEDAD RESPIRATORIA CRONICA ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'P270', descripcion: 'SINDROME DE WILSON-MIKITY'},
{ codigo: 'P271', descripcion: 'DISPLASIA BRONCOPULMONAR ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'P278', descripcion: 'OTRAS ENFERMEDADES RESPIRATORIAS CRONICAS ORIGINADAS EN EL PERIODO PERINATAL'},
{ codigo: 'P279', descripcion: 'ENFERMEDAD RESPIRATORIA CRONICA NO ESPECIFICADA ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'P28', descripcion: 'OTROS PROBLEMAS RESPIRATORIOS DEL RECIEN NACIDO, ORIGADOS EN EL PERIODO PERINATAL'},
{ codigo: 'P280', descripcion: 'ATELECTASIA PRIMARIA DEL RECIEN NACIDO'},
{ codigo: 'P281', descripcion: 'OTRAS ATELECTASIAS DEL RECIEN NACIDO Y LAS NO ESPECIFICADAS'},
{ codigo: 'P282', descripcion: 'ATAQUE CIANOTICO DEL RECIEN NACIDO'},
{ codigo: 'P283', descripcion: 'APNEA PRIMARIA DEL SUE—O DEL RECIEN NACIDO'},
{ codigo: 'P284', descripcion: 'OTRAS APNEAS DEL RECIEN NACIDO'},
{ codigo: 'P285', descripcion: 'INSUFICIENCIA RESPIRATORIA DEL RECIEN NACIDO'},
{ codigo: 'P288', descripcion: 'OTROS PROBLEMAS RESPIRATORIOS ESPECIFICADOS DEL RECIEN NACIDO'},
{ codigo: 'P289', descripcion: 'AFECCION RESPIRATORIA NO ESPECIFICADA DEL RECIEN NACIDO'},
{ codigo: 'P29', descripcion: 'TRASTORNOS CARDIOVASCULARES ORIGINADOS EN EL PERIODO PERINATAL'},
{ codigo: 'P290', descripcion: 'INSUFICIENCIA CARDIACA NEONATAL'},
{ codigo: 'P291', descripcion: 'DISRITMIA CARDIACA NEONATAL'},
{ codigo: 'P292', descripcion: 'HIPERTENSION NEONATAL'},
{ codigo: 'P293', descripcion: 'PERSISTENCIA DE LA CIRCULACION FETAL'},
{ codigo: 'P294', descripcion: 'ISQUEMIA MIOCARDICA TRANSITORIA DEL RECIEN NACIDO'},
{ codigo: 'P298', descripcion: 'OTROS TRASTORNOS CARDIOVASCULARES ORIGINADOS EN EL PERIODO PERINATAL'},
{ codigo: 'P299', descripcion: 'TRASTORNO CARDIOVASCULAR NO ESPECIFICADO, ORIGINADO EN EL PERIODO PERINATAL'},
{ codigo: 'P35', descripcion: 'ENFERMEDADES VIRALES CONGENITAS'},
{ codigo: 'P350', descripcion: 'SINDROME DE RUBEOLA CONGENITA'},
{ codigo: 'P351', descripcion: 'INFECCION CITOMEGALOVIRICA CONGENITA'},
{ codigo: 'P352', descripcion: 'INFECCIONES CONGENITAS POR VIRUS DEL HERPES SIMPLE'},
{ codigo: 'P353', descripcion: 'HEPATITIS VIRAL CONGENITA'},
{ codigo: 'P358', descripcion: 'OTRAS ENFERMEDADES VIRALES CONGENITAS'},
{ codigo: 'P359', descripcion: 'ENFERMEDAD VIRAL CONGENITA, SIN OTRA ESPECIFICACION'},
{ codigo: 'P36', descripcion: 'SEPSIS BACTERIANA DEL RECIEN NACIDO'},
{ codigo: 'P360', descripcion: 'SEPSIS DEL RECIEN NACIDO DEBIDA A ESTREPTOCOCO DEL GRUPO B'},
{ codigo: 'P361', descripcion: 'SEPSIS DEL RECIEN NACIDO DEBIDA A OTROS ESTREPTOCOCOS Y A LOS NO ESPECIFICADOS'},
{ codigo: 'P362', descripcion: 'SEPSIS DEL RECIEN NACIDO DEBIDA A STAPHYLOCOCCUS AUREUS'},
{ codigo: 'P363', descripcion: 'SEPSIS DEL RECIEN NACIDO DEBIDA A OTROS ESTAFILOCOCOS Y A LOS NO ESPECIFICADOS'},
{ codigo: 'P364', descripcion: 'SEPSIS DEL RECIEN NACIDO DEBIDA A ESCHERICHIA COLI'},
{ codigo: 'P365', descripcion: 'SEPSIS DEL RECIEN NACIDO DEBIDA A ANAEROBIOS'},
{ codigo: 'P368', descripcion: 'SEPSIS DEL RECIEN NACIDO DEBIDA A OTRAS BACTERIAS'},
{ codigo: 'P369', descripcion: 'SEPSIS BACTERIANA DEL RECIEN NACIDO, NO ESPECIFICADA'},
{ codigo: 'P37', descripcion: 'OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CONGENITAS'},
{ codigo: 'P370', descripcion: 'TUBERCULOSIS CONGENITA'},
{ codigo: 'P371', descripcion: 'TOXOPLASMOSIS CONGENITA'},
{ codigo: 'P372', descripcion: 'LISTERIOSIS CONGENITA (DISEMINADA)'},
{ codigo: 'P373', descripcion: 'PALUDISMO CONGENITO POR PLASMODIUM FALCIPARUM'},
{ codigo: 'P374', descripcion: 'OTROS PALUDISMOS CONGENITOS'},
{ codigo: 'P375', descripcion: 'CANDIDIASIS NEONATAL'},
{ codigo: 'P378', descripcion: 'OTRAS ENFERMEDADES NEONATALES INFECCIOSAS O PARASITARIAS ESPECIFICADAS'},
{ codigo: 'P379', descripcion: 'ENFERMEDAD INFECCIOSA Y PARASITARIA CONGENITA, NO ESPECIFICADA'},
{ codigo: 'P38', descripcion: 'ONFALITIS DEL RECIEN NACIDO CON O SIN HEMORRAGIA LEVE'},
{ codigo: 'P39', descripcion: 'OTRAS INFECCIONES ESPECIFICAS DEL PERIODO PERINATAL'},
{ codigo: 'P390', descripcion: 'MASTITIS INFECCIOSA NEONATAL'},
{ codigo: 'P391', descripcion: 'CONJUNTIVITIS Y DACRIOCISTITIS NEONATALES'},
{ codigo: 'P392', descripcion: 'INFECCION INTRAAMNIOTICA DEL FETO, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'P393', descripcion: 'INFECCION NEONATAL DE LAS VIAS URINARIAS'},
{ codigo: 'P394', descripcion: 'INFECCION CUTANEA NEONATAL'},
{ codigo: 'P398', descripcion: 'OTRAS INFECCIONES ESPECIFICADAS PROPIAS DEL PERIODO PERINATAL'},
{ codigo: 'P399', descripcion: 'INFECCION PROPIA DEL PERIODO PERINATAL, NO ESPECIFICADA'},
{ codigo: 'P50', descripcion: 'PERDIDA DE SANGRE FETAL'},
{ codigo: 'P500', descripcion: 'PERDIDA DE SANGRE FETAL POR VASA PREVIA'},
{ codigo: 'P501', descripcion: 'PERDIDA DE SANGRE FETAL POR RUPTURA DEL CORDON UMBILICAL'},
{ codigo: 'P502', descripcion: 'PERDIDA DE SANGRE FETAL POR LA PLACENTA'},
{ codigo: 'P503', descripcion: 'HEMORRAGIA FETAL HACIA EL OTRO GEMELO'},
{ codigo: 'P504', descripcion: 'HEMORRAGIA FETAL HACIA LA CIRCULACION MATERNA'},
{ codigo: 'P505', descripcion: 'PERDIDA SANGRE FETAL POR CORTE DEL CORDON UMBILICAL EN EL OTRO GEMELO'},
{ codigo: 'P508', descripcion: 'OTRAS PERDIDAS DE SANGRE FETAL'},
{ codigo: 'P509', descripcion: 'PERDIDA DE SANGRE FETAL, NO ESPECIFICADA'},
{ codigo: 'P51', descripcion: 'HEMORRAGIA UMBILICAL DEL RECIEN NACIDO'},
{ codigo: 'P510', descripcion: 'HEMORRAGIA UMBILICAL MASIVA DEL RECIEN NACIDO'},
{ codigo: 'P518', descripcion: 'OTRAS HEMORRAGIAS UMBILICALES DEL RECIEN NACIDO'},
{ codigo: 'P519', descripcion: 'HEMORRAGIA UMBILICAL DEL RECIEN NACIDO, SIN OTRA ESPECIFICACION'},
{ codigo: 'P52', descripcion: 'HEMORRAGIA INTRACRANEAL NO TRAUMATICA DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P520', descripcion: 'HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA )GRADO 1, DE FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P521', descripcion: 'HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA) GRADO 2, DE FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P522', descripcion: 'HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA) GRADO 3, DE FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P523', descripcion: 'HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA) DE FETO Y DEL RECIEN NACIDO, SIN OTRA ESPECIFICACION'},
{ codigo: 'P524', descripcion: 'HEMORRAGIA INTRACEREBRAL (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P525', descripcion: 'HEMORRAGIA SUBARACNOIDEA (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P526', descripcion: 'HEMORRAGIA CEREBELOSA Y DE LA FOSA POSTERIOR (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P528', descripcion: 'OTRAS HEMORRAGIAS INTRACRANEALES (NO TRAUMATICAS) DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P529', descripcion: 'HEMORRAGIA INTRACRANEAL (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO, SIN OTRA ESPECIFICACION'},
{ codigo: 'P53', descripcion: 'ENFERMEDAD HEMORRAGICA DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P54', descripcion: 'OTRAS HEMORRAGIAS NEONATALES'},
{ codigo: 'P540', descripcion: 'HEMATEMESIS NEONATAL'},
{ codigo: 'P541', descripcion: 'MELENA NEONATAL'},
{ codigo: 'P542', descripcion: 'HEMORRAGIA RECTAL NEONATAL'},
{ codigo: 'P543', descripcion: 'OTRAS HEMORRAGIAS GASTROINTESTINALES NEONATALES'},
{ codigo: 'P544', descripcion: 'HEMORRAGIA SUPRARRENAL NEONATAL'},
{ codigo: 'P545', descripcion: 'HEMORRAGIA CUTANEA NEONATAL'},
{ codigo: 'P546', descripcion: 'HEMORRAGIA VAGINAL NEONATAL'},
{ codigo: 'P548', descripcion: 'OTRAS HEMORRAGIAS FETALES Y NEONATALES ESPECIFICADAS'},
{ codigo: 'P549', descripcion: 'HEMORRAGIA FETAL Y NEONATAL, NO ESPECIFICADA'},
{ codigo: 'P55', descripcion: 'ENFERMEDAD HEMOLITICA DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P550', descripcion: 'INCOMPATIBILIDAD RH DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P551', descripcion: 'INCOMPATIBILIDAD ABO DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P558', descripcion: 'OTRAS ENFERMEDADES HEMOLITICAS DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P559', descripcion: 'ENFERMEDAD HEMOLITICA DEL FETO Y DEL RECIEN NACIDO, NO ESPECIFICADA'},
{ codigo: 'P56', descripcion: 'HIDROPESIA FETAL DEBIDA A ENFERMEDAD HEMOLITICA'},
{ codigo: 'P560', descripcion: 'HIDROPESIA FETAL DEBIDA A INCOMPATIBILIDAD'},
{ codigo: 'P569', descripcion: 'HIDROPESIA FETAL POR OTRAS ENFERMEDADES HEMOLITICAS ESPECIFICADAS Y A LAS NO ESPECIFICADAS'},
{ codigo: 'P57', descripcion: 'KERNICTERUS'},
{ codigo: 'P570', descripcion: 'KERNICTERUS DEBIDO A INCOMPATIBILIDAD'},
{ codigo: 'P578', descripcion: 'KERNICTERUS DEBIDO A OTRAS CAUSAS ESPECIFICADAS'},
{ codigo: 'P579', descripcion: 'KERNICTERUS, NO ESPECIFICADO'},
{ codigo: 'P58', descripcion: 'ICTERICIA NEONATAL DEBIDA A OTRAS HEMOLISIS EXCESIVAS'},
{ codigo: 'P580', descripcion: 'ICTERICIA NEONATAL DEBIDA A CONTUSION'},
{ codigo: 'P581', descripcion: 'ICTERICIA NEONATAL DEBIDA A HEMORRAGIA'},
{ codigo: 'P582', descripcion: 'ICTERICIA NEONATAL DEBIDA A INFECCION'},
{ codigo: 'P583', descripcion: 'ICTERICIA NEONATAL DEBIDA A POLICITEMIA'},
{ codigo: 'P584', descripcion: 'ICTERICIA NEONATAL DEBIDA A DROGAS O TOXINAS TRANSMITIDAS POR LA MADRE O ADMINISTRADAS AL RECIEN NACIDO'},
{ codigo: 'P585', descripcion: 'ICTERICIA NEONATAL DEBIDA A DEGLUCION DE SANGRE MATERNA'},
{ codigo: 'P588', descripcion: 'ICTERICIA NEONATAL DEBIDA A OTRAS HEMOLISIS EXCESIVAS ESPECIFICADAS'},
{ codigo: 'P589', descripcion: 'ICTERICIA NEONATAL DEBIDA A HEMOLISIS EXCESIVA, SIN OTRA ESPECIFICACION'},
{ codigo: 'P59', descripcion: 'ICTERICIA NEONATAL POR OTRAS CAUSAS Y POR LAS NO ESPECIFICADAS'},
{ codigo: 'P590', descripcion: 'ICTERICIA NEONATAL ASOCIADA CON EL PARTO ANTES DE TERMINO'},
{ codigo: 'P591', descripcion: 'SINDROME DE LA BILIS ESPESA'},
{ codigo: 'P592', descripcion: 'ICTERICIA NEONATAL DEBIDA A OTRA LESION HEPATICA ESPECIFICADA O NO'},
{ codigo: 'P593', descripcion: 'ICTERICIA NEONATAL POR INHIBIDOR DE LA LECHE MATERNA'},
{ codigo: 'P598', descripcion: 'ICTERICIA NEONATAL POR OTRAS CAUSAS ESPECIFICADAS'},
{ codigo: 'P599', descripcion: 'ICTERICIA NEONATAL, NO ESPECIFICADA'},
{ codigo: 'P60', descripcion: 'COAGULACION INTRAVASCULAR DISEMINADA EN EL FETO Y EL RECIEN NACIDO'},
{ codigo: 'P61', descripcion: 'OTROS TRASTORNOS HEMATOLOGICOS PERINATALES'},
{ codigo: 'P610', descripcion: 'TROMBOCITOPENIA NEONATAL TRANSITORIA'},
{ codigo: 'P611', descripcion: 'POLICITEMIA NEONATAL'},
{ codigo: 'P612', descripcion: 'ANEMIA DE LA PREMATURIDAD'},
{ codigo: 'P613', descripcion: 'ANEMIA CONGENITA DEBIDA A PERDIDA DE SANGRE FETAL'},
{ codigo: 'P614', descripcion: 'OTRAS ANEMIAS CONGENITAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'P615', descripcion: 'NEUTROPENIA NEONATAL TRANSITORIA'},
{ codigo: 'P616', descripcion: 'OTROS TRASTORNOS NEONATALES TRANSITORIOS DE LA COAGULACION'},
{ codigo: 'P618', descripcion: 'OTROS TRASTORNOS HEMATOLOGICOS PERINATALES ESPECIFICADOS'},
{ codigo: 'P619', descripcion: 'TRASTORNO HEMATOLOGICO PERINATAL, NO ESPECIFICADO'},
{ codigo: 'P70', descripcion: 'TRASTORNOS TRANSITORIOS DEL METABOLISMO DE LOS CARBOHIDRATOS ESPECIFICOS DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P700', descripcion: 'SINDROME DEL RECIEN NACIDO DE MADRE CON DIABETES GESTACIONAL'},
{ codigo: 'P701', descripcion: 'SINDROME DEL RECIEN NACIDO DE MADRE DIABETICA'},
{ codigo: 'P702', descripcion: 'DIABETES MELLITUS NEONATAL'},
{ codigo: 'P703', descripcion: 'HIPOGLICEMIA NEONATAL YATROGENICA'},
{ codigo: 'P704', descripcion: 'OTRAS HIPOGLICEMIAS NEONATALES'},
{ codigo: 'P708', descripcion: 'OTROS TRASTORNOS TRANSITORIOS DEL METABOLISMO DE LOS CARBOHIDRATOS EN FETO Y RECIEN NACIDO'},
{ codigo: 'P709', descripcion: 'TRASTORNOS TRANSITORIOS NO ESPECIFICADOS DEL METABOLISMO DE LOS CARBOHIDRATOS EN FETO Y RECIEN NACIDO'},
{ codigo: 'P71', descripcion: 'TRASTORNOS NEONATALES TRANSITORIOS DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO'},
{ codigo: 'P710', descripcion: 'HIPOCALCEMIA DEL RECIEN NACIDO DEBIDA A LA LECHE DE VACA'},
{ codigo: 'P711', descripcion: 'OTRA HIPOCALCEMIA NEONATAL'},
{ codigo: 'P712', descripcion: 'HIPOMAGNESEMIA NEONATAL'},
{ codigo: 'P713', descripcion: 'TETANIA NEONATAL SIN MENCION DE DEFICIENCIA DE CALCIO O DE MAGNESIO'},
{ codigo: 'P714', descripcion: 'HIPOPARATIROIDISMO NEONATAL TRANSITORIO'},
{ codigo: 'P718', descripcion: 'OTROS TRASTORNOS NEONATALES TRANSITORIOS DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO'},
{ codigo: 'P719', descripcion: 'TRASTORNO NEONATAL TRANSITORIO NO ESPECIFICADO DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO'},
{ codigo: 'P72', descripcion: 'OTROS TRASTORNOS ENDOCRINOS NEONATALES TRANSITORIOS'},
{ codigo: 'P720', descripcion: 'BOCIO NEONATAL, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'P721', descripcion: 'HIPERTIROIDISMO NEONATAL TRANSITORIO'},
{ codigo: 'P722', descripcion: 'OTROS TRASTORNOS NEONATALES TRANSITORIOS DE LA FUNCION TIROIDEA, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'P728', descripcion: 'OTROS TRASTORNOS ENDOCRINOS NEONATALES TRANSITORIOS ESPECIFICADOS'},
{ codigo: 'P729', descripcion: 'TRASTORNO ENDOCRINO NEONATAL TRANSITORIO, NO ESPECIFICADO'},
{ codigo: 'P74', descripcion: 'OTRAS ALTERACIONES METABOLICAS Y ELECTROLITICAS NEONATALES TRANSITORIAS'},
{ codigo: 'P740', descripcion: 'ACIDOSIS METABOLICA TARDIA DEL RECIEN NACIDO'},
{ codigo: 'P741', descripcion: 'DESHIDRATACION DEL RECIEN NACIDO'},
{ codigo: 'P742', descripcion: 'ALTERACIONES DEL EQUILIBRIO DEL SODIO EN EL RECIEN NACIDO'},
{ codigo: 'P743', descripcion: 'ALTERACIONES DEL EQUILIBRIO DEL POTASIO EN EL RECIEN NACIDO'},
{ codigo: 'P744', descripcion: 'OTRAS ALTERACIONES ELECTROLITICAS TRANSITORIAS DEL RECIEN NACIDO'},
{ codigo: 'P745', descripcion: 'TIROSINEMIA TRANSITORIA DEL RECIEN NACIDO'},
{ codigo: 'P748', descripcion: 'OTRAS ALTERACIONES METABOLICAS TRANSITORIAS DEL RECIEN NACIDO'},
{ codigo: 'P749', descripcion: 'TRASTORNO METABOLICO TRANSITORIO DEL RECIEN NACIDO, NO ESPECIFICADO'},
{ codigo: 'P75', descripcion: 'ILEO MECONIAL (E84.1+)'},
{ codigo: 'P76', descripcion: 'OTRAS OBSTRUCCIONES INTESTINALES DEL RECIEN NACIDO'},
{ codigo: 'P760', descripcion: 'SINDROME DEL TAPON DE MECONIO'},
{ codigo: 'P761', descripcion: 'ILEO TRANSITORIO DEL RECIEN NACIDO'},
{ codigo: 'P762', descripcion: 'OBSTRUCCION INTESTINAL DEBIDA A LA LECHE ESPESA'},
{ codigo: 'P768', descripcion: 'OTRAS OBSTRUCCIONES INTESTINALES ESPECIFICADAS DEL RECIEN NACIDO'},
{ codigo: 'P769', descripcion: 'OBSTRUCCION INTESTINAL DEL RECIEN NACIDO, NO ESPECIFICADA'},
{ codigo: 'P77', descripcion: 'ENTEROCOLITIS NECROTIZANTE DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P78', descripcion: 'OTROS TRASTORNOS PERINATALES DEL SISTEMA DIGESTIVO'},
{ codigo: 'P780', descripcion: 'PERFORACION INTESTINAL PERINATAL'},
{ codigo: 'P781', descripcion: 'OTRAS PERITONITIS NEONATALES'},
{ codigo: 'P782', descripcion: 'HEMATEMESIS Y MELENA NEONATALES POR LA DEGLUCION DE SANGRE MATERNA'},
{ codigo: 'P783', descripcion: 'DIARREA NEONATAL NO INFECCIOSA'},
{ codigo: 'P788', descripcion: 'OTROS TRASTORNOS PERINATALES ESPECIFICOS DEL SISTEMA DIGESTIVO'},
{ codigo: 'P789', descripcion: 'TRASTORNO PERINATAL DEL SISTEMA DIGESTIVO, NO ESPECIFICADO'},
{ codigo: 'P80', descripcion: 'HIPOTERMIA DEL RECIEN NACIDO'},
{ codigo: 'P800', descripcion: 'SINDROME DEL ENFRIAMIENTO'},
{ codigo: 'P808', descripcion: 'OTRAS HIPOTERMIAS DEL RECIEN NACIDO'},
{ codigo: 'P809', descripcion: 'HIPOTERMIA DEL RECIEN NACIDO, NO ESPECIFICADA'},
{ codigo: 'P81', descripcion: 'OTRAS ALTERACIONES DE LA REGULACION DE LA TEMPERATURA EN RECIEN NACIDO'},
{ codigo: 'P810', descripcion: 'HIPERTERMIA DEL RECIEN NACIDO INDUCIDA POR LAS CONDICIONES AMBIENTALES'},
{ codigo: 'P818', descripcion: 'OTRAS ALTERACIONES ESPECIFICADAS DE LA REGULACION DE TEMPERATURA DEL RECIEN NACIDO'},
{ codigo: 'P819', descripcion: 'ALTERACION NO ESPECIFICDA DE LA REGULACION DE TEMPERATURA EN RECIEN NACIDO'},
{ codigo: 'P83', descripcion: 'OTRAS AFECCIONES DE LA PIEL ESPECIFICAS DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P830', descripcion: 'ESCLEREMA NEONATAL'},
{ codigo: 'P831', descripcion: 'ERITEMA TOXICO NEONATAL'},
{ codigo: 'P832', descripcion: 'HIDROPESIA FETAL NO DEBIDA A ENFERMEDAD HEMOLITICA'},
{ codigo: 'P833', descripcion: 'OTROS EDEMAS Y LOS NO ESPECIFICADOS, PROPIOS DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P834', descripcion: 'INGURGITACION MAMARIA DEL RECIEN NACIDO'},
{ codigo: 'P835', descripcion: 'HIDROCELE CONGENITO'},
{ codigo: 'P836', descripcion: 'POLIPO UMBILICAL DEL RECIEN NACIDO'},
{ codigo: 'P838', descripcion: 'OTRAS AFECCIONES ESPECIFICDAS DE PIEL, PROPIAS DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P839', descripcion: 'AFECCIONES NO ESPECIFICADAS DE LA PIEL, PROPIA DEL FETO Y DEL RECIEN NACIDO'},
{ codigo: 'P90', descripcion: 'CONVULSIONES DEL RECIEN NACIDO'},
{ codigo: 'P91', descripcion: 'OTRAS ALTERACIONES CEREBRALES DEL RECIEN NACIDO'},
{ codigo: 'P910', descripcion: 'ISQUEMIA CEREBRAL NEONATAL'},
{ codigo: 'P911', descripcion: 'QUISTES PERIVENTRICULARES ADQUIRIDOS DEL RECIEN NACIDO'},
{ codigo: 'P912', descripcion: 'LEUCOMALACIA CEREBRAL NEONATAL'},
{ codigo: 'P913', descripcion: 'IRRITABILIDAD CEREBRAL NEONATAL'},
{ codigo: 'P914', descripcion: 'DEPRESION CEREBRAL NEONATAL'},
{ codigo: 'P915', descripcion: 'COMA NEONATAL'},
{ codigo: 'P918', descripcion: 'OTRAS ALTERACIONES CEREBRALES ESPECIFICADAS DEL RECIEN NACIDO'},
{ codigo: 'P919', descripcion: 'ALTERACION CEREBRAL NO ESPECIFICADA DEL RECIEN NACIDO'},
{ codigo: 'P92', descripcion: 'PROBLEMAS DE LA INGESTION DE ALIMENTOS DEL RECIEN NACIDO'},
{ codigo: 'P920', descripcion: 'VOMITOS DEL RECIEN NACIDO'},
{ codigo: 'P921', descripcion: 'REGURGITACION Y RUMIACION DEL RECIEN NACIDO'},
{ codigo: 'P922', descripcion: 'LENTITUD EN LA INGESTION DE ALIMENTOS DEL RECIEN NACIDO'},
{ codigo: 'P923', descripcion: 'HIPOALIMENTACION DEL RECIEN NACIDO'},
{ codigo: 'P924', descripcion: 'HIPERALIMENTACION DEL RECIEN NACIDO'},
{ codigo: 'P925', descripcion: 'DIFICULTAD NEONATAL EN LA LACTANCIA MATERNA'},
{ codigo: 'P928', descripcion: 'OTROS PROBLEMAS DE ALIMENTACION DEL RECIEN NACIDO'},
{ codigo: 'P929', descripcion: 'PROBLEMA NO ESPECIFICADO DE LA ALIMENTACION DEL RECIEN NACIDO'},
{ codigo: 'P93', descripcion: 'REACCIONES E INTOXICACIONES DEBIDO A DROGAS ADMINISTRADAS AL FETO Y AL RECIEN NACIDO'},
{ codigo: 'P94', descripcion: 'TRASTORNOS DEL TONO MUSCULAR EN EL RECIEN NACIDO'},
{ codigo: 'P940', descripcion: 'MIASTENIA GRAVE NEONATAL TRANSITORIA'},
{ codigo: 'P941', descripcion: 'HIPERTONIA CONGENITA'},
{ codigo: 'P942', descripcion: 'HIPOTONIA CONGENITA'},
{ codigo: 'P948', descripcion: 'OTROS TRASTORNOS DEL TONO MUSCULAR EN EL RECIEN NACIDO'},
{ codigo: 'P949', descripcion: 'TRASTORNO NO ESPECIFICADO DEL TONO MUSCULAR EN EL RECIEN NACIDO'},
{ codigo: 'P95', descripcion: 'MUERTE FETAL DE CAUSA NO ESPECIFICADA'},
{ codigo: 'P96', descripcion: 'OTRAS AFECCIONES ORIGINADAS EN EL PERIODO PERINATAL'},
{ codigo: 'P960', descripcion: 'INSUFICIENCIA RENAL CONGENITA'},
{ codigo: 'P961', descripcion: 'SINTOMAS NEONATALES DE ABSTINENCIA POR DROGADICCION MATERNA'},
{ codigo: 'P962', descripcion: 'SINTOMAS DE ABSTINENCIA POR USO TERAPEUTICO DE DROGAS EN EL RECIEN NACIDO'},
{ codigo: 'P963', descripcion: 'AMPLITUD DE LAS SUTURAS CRANEALES DEL RECIEN NACIDO'},
{ codigo: 'P964', descripcion: 'TERMINACION DEL EMBARAZO, FETO Y RECIEN NACIDO'},
{ codigo: 'P965', descripcion: 'COMPLICACIONES DE PROCEDIMIENTOS INTRAUTERINOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'P968', descripcion: 'OTRAS AFECCIONES ESPECIFICADAS ORIGINADAS EN EL PERIODO PERINATAL'},
{ codigo: 'P969', descripcion: 'AFECCION NO ESPECIFICADA ORIGINADA EN EL PERIODO PERINATAL'},
{ codigo: 'Q00', descripcion: 'ANENCEFALIA Y MALFORMACIONES CONGENITAS SIMILARES'},
{ codigo: 'Q000', descripcion: 'ANENCEFALIA'},
{ codigo: 'Q001', descripcion: 'CRANEORRAQUISQUISIS'},
{ codigo: 'Q002', descripcion: 'INIENCEFALIA'},
{ codigo: 'Q01', descripcion: 'ENCEFALOCELE'},
{ codigo: 'Q010', descripcion: 'ENCEFALOCELE FRONTAL'},
{ codigo: 'Q011', descripcion: 'ENCEFALOCELE NASOFRONTAL'},
{ codigo: 'Q012', descripcion: 'ENCEFALOCELE OCCIPITAL'},
{ codigo: 'Q018', descripcion: 'ENCEFALOCELE DE OTROS SITIOS'},
{ codigo: 'Q019', descripcion: 'ENCEFALOCELE, NO ESPECIFICADO'},
{ codigo: 'Q02', descripcion: 'MICROCEFALIA'},
{ codigo: 'Q03', descripcion: 'HIDROCEFALO CONGENITO'},
{ codigo: 'Q030', descripcion: 'MALFORMACIONES DEL ACUEDUCTO DE SILVIO'},
{ codigo: 'Q031', descripcion: 'ATRESIA DE LOS AGUJEROS DE MAGENDIE Y DE LUSCHKA'},
{ codigo: 'Q038', descripcion: 'OTROS HIDROCEFALOS CONGENITOS'},
{ codigo: 'Q039', descripcion: 'HIDROCEFALO CONGENITO, NO ESPECIFICADO'},
{ codigo: 'Q04', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL ENCEFALO'},
{ codigo: 'Q040', descripcion: 'MALFORMACIONES CONGENITAS DEL CUERPO CALLOSO'},
{ codigo: 'Q041', descripcion: 'ARRINENCEFALIA'},
{ codigo: 'Q042', descripcion: 'HOLOPROSENCEFALIA'},
{ codigo: 'Q043', descripcion: 'OTRAS ANOMALIAS HIPOPLASICAS DEL ENCEFALO'},
{ codigo: 'Q044', descripcion: 'DISPLASIA OPTICOSEPTAL'},
{ codigo: 'Q045', descripcion: 'MEGALENCEFALIA'},
{ codigo: 'Q046', descripcion: 'QUISTES CEREBRALES CONGENITOS'},
{ codigo: 'Q048', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL ENCEFALO, ESPECIFICADAS'},
{ codigo: 'Q049', descripcion: 'MALFORMACION CONGENITA DEL ENCEFALO, NO ESPECIFICADA'},
{ codigo: 'Q05', descripcion: 'ESPINA BIFIDA'},
{ codigo: 'Q050', descripcion: 'ESPINA BIFIDA CERVICAL CON HIDROCEFALO'},
{ codigo: 'Q051', descripcion: 'ESPINA BIFIDA TORACICA CON HIDROCEFALO'},
{ codigo: 'Q052', descripcion: 'ESPINA BIFIDA LUMBAR CON HIDROCEFALO'},
{ codigo: 'Q053', descripcion: 'ESPINA BIFIDA SACRA CON HIDROCEFALO'},
{ codigo: 'Q054', descripcion: 'ESPINA BIFIDA CON HIDROCEFALO, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q055', descripcion: 'ESPINA BIFIDA CERVICAL SIN HIDROCEFALO'},
{ codigo: 'Q056', descripcion: 'ESPINA BIFIDA TORACICA SIN HIDROCEFALO'},
{ codigo: 'Q057', descripcion: 'ESPINA BIFIDA LUMBAR SIN HIDROCEFALO'},
{ codigo: 'Q058', descripcion: 'ESPINA BIFIDA SACRA SIN HIDROCEFALO'},
{ codigo: 'Q059', descripcion: 'ESPINA BIFIDA, NO ESPECIFICADA'},
{ codigo: 'Q06', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA MEDULA ESPINAL'},
{ codigo: 'Q060', descripcion: 'AMIELIA'},
{ codigo: 'Q061', descripcion: 'HIPOPLASIA Y DISPLASIA DE LA MEDULA ESPINAL'},
{ codigo: 'Q062', descripcion: 'DIASTEMATOMIELIA'},
{ codigo: 'Q063', descripcion: 'OTRAS ANOMALIAS CONGENITAS DE LA COLA DE CABALLO'},
{ codigo: 'Q064', descripcion: 'HIDROMIELIA'},
{ codigo: 'Q068', descripcion: 'OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DE LA MEDULA ESPINAL'},
{ codigo: 'Q069', descripcion: 'MALFORMACION CONGENITA DE LA MEDULA ESPINAL, NO ESPECIFICADA'},
{ codigo: 'Q07', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA NERVIOSO'},
{ codigo: 'Q070', descripcion: 'SINDROME DE ARNOLD-CHIARI'},
{ codigo: 'Q078', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA NERVIOSO, ESPECIFICADAS'},
{ codigo: 'Q079', descripcion: 'MALFORMACION CONGENITA DEL SISTEMA NERVIOSO, NO ESPECIFICADA'},
{ codigo: 'Q10', descripcion: 'MALFORMACIONES CONGENITAS DE PARPADOS, DEL APARATO LAGRIMAL Y DE LA ORBITA'},
{ codigo: 'Q100', descripcion: 'BLEFAROPTOSIS CONGENITA'},
{ codigo: 'Q101', descripcion: 'ECTROPION CONGENITO'},
{ codigo: 'Q102', descripcion: 'ENTROPION CONGENITO'},
{ codigo: 'Q103', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS PARPADOS'},
{ codigo: 'Q104', descripcion: 'AUSENCIA Y AGENESIA DEL APARATO LAGRIMAL'},
{ codigo: 'Q105', descripcion: 'ESTENOSIS Y ESTRECHEZ CONGENITAS DEL CONDUCTO LAGRIMAL'},
{ codigo: 'Q106', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL APARATO LAGRIMAL'},
{ codigo: 'Q107', descripcion: 'MALFORMACION CONGENITA DE LA ORBITA'},
{ codigo: 'Q11', descripcion: 'ANOFTALMIA, MICROFTALMIA Y MACROFTALMIA'},
{ codigo: 'Q110', descripcion: 'GLOBO OCULAR QUISTICO'},
{ codigo: 'Q111', descripcion: 'OTRAS ANOFTALMIAS'},
{ codigo: 'Q112', descripcion: 'MICROFTALMIA'},
{ codigo: 'Q113', descripcion: 'MACROFTALMIA'},
{ codigo: 'Q12', descripcion: 'MALFORMACIONES CONGENITAS DEL CRISTALINO'},
{ codigo: 'Q120', descripcion: 'CATARATA CONGENITA'},
{ codigo: 'Q121', descripcion: 'DESPLAZAMIENTO CONGENITO DEL CRISTALINO'},
{ codigo: 'Q122', descripcion: 'COLOBOMA DEL CRISTALINO'},
{ codigo: 'Q123', descripcion: 'AFAQUIA CONGENITA'},
{ codigo: 'Q124', descripcion: 'ESFEROFAQUIA'},
{ codigo: 'Q128', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL CRISTALINO'},
{ codigo: 'Q129', descripcion: 'MALFORMACION CONGENITA DEL CRISTALINO, NO ESPECIFICADA'},
{ codigo: 'Q13', descripcion: 'MALFORMACIONES CONGENITAS DEL SEGMENTO ANTERIOR DEL OJO'},
{ codigo: 'Q130', descripcion: 'COLOBOMA DEL IRIS'},
{ codigo: 'Q131', descripcion: 'AUSENCIA DEL IRIS'},
{ codigo: 'Q132', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL IRIS'},
{ codigo: 'Q133', descripcion: 'OPACIDAD CORNEAL CONGENITA'},
{ codigo: 'Q134', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA CORNEA'},
{ codigo: 'Q135', descripcion: 'ESCLEROTICA AZUL'},
{ codigo: 'Q138', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SEGMENTO ANTERIOR DEL OJO'},
{ codigo: 'Q139', descripcion: 'MALFORMACION CONGENITA DEL SEGMENTO ANTERIOR DEL OJO, NO ESPECIFICADA'},
{ codigo: 'Q14', descripcion: 'MALFORMACIONES CONGENITAS DEL SEGMENTO POSTERIOR DEL OJO'},
{ codigo: 'Q140', descripcion: 'MALFORMACION CONGENITA DEL HUMOR VITREO'},
{ codigo: 'Q141', descripcion: 'MALFORMACION CONGENITA DE LA RETINA'},
{ codigo: 'Q142', descripcion: 'MALFORMACION CONGENITA DEL DISCO OPTICO'},
{ codigo: 'Q143', descripcion: 'MALFORMACION CONGENITA DE LA COROIDES'},
{ codigo: 'Q148', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SEGMENTO POSTERIOR DEL OJO'},
{ codigo: 'Q149', descripcion: 'MALFORMACION CONGENITA DEL SEGMENTO POSTERIOR DEL OJO, NO ESPECIFICADA'},
{ codigo: 'Q15', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL OJO'},
{ codigo: 'Q150', descripcion: 'GLAUCOMA CONGENITO'},
{ codigo: 'Q158', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL OJO, ESPECIFICADAS'},
{ codigo: 'Q159', descripcion: 'MALFORMACIONES CONGENITAS DEL OJO, NO ESPECIFICADAS'},
{ codigo: 'Q16', descripcion: 'MALFORMACIONES CONGENITAS DEL OIDO QUE CAUSAN ALTERACION DE AUDICION'},
{ codigo: 'Q160', descripcion: 'AUSENCIA CONGENITA DEL PABELLON (DE LA OREJA)'},
{ codigo: 'Q161', descripcion: 'AUSENCIA CONGENITA, ATRESIA O ESTRECHEZ DE CONDUCTO AUDITIVO (EXTERNO)'},
{ codigo: 'Q162', descripcion: 'AUSENCIA DE LA TROMPA DE EUSTAQUIO'},
{ codigo: 'Q163', descripcion: 'MALFORMACION CONGENITA DE LOS HUESECILLOS DEL OIDO'},
{ codigo: 'Q164', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL OIDO MEDIO'},
{ codigo: 'Q165', descripcion: 'MALFORMACION CONGENITA DEL OIDO INTERNO'},
{ codigo: 'Q169', descripcion: 'MALFORMACION CONGENITA DEL OIDO QUE CAUSA ALTERACION DE LA AUDICION, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q17', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL OIDO'},
{ codigo: 'Q170', descripcion: 'OREJA SUPERNUMERARIA'},
{ codigo: 'Q171', descripcion: 'MACROTIA'},
{ codigo: 'Q172', descripcion: 'MICROTIA'},
{ codigo: 'Q173', descripcion: 'OTRAS DEFORMIDADES DEL PABELLON AURICULAR'},
{ codigo: 'Q174', descripcion: 'ANOMALIA DE LA POSICION DE LA OREJA'},
{ codigo: 'Q175', descripcion: 'OREJA PROMINENTE'},
{ codigo: 'Q178', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL OIDO, ESPECIFICADAS'},
{ codigo: 'Q179', descripcion: 'MALFORMACION CONGENITA DEL OIDO, NO ESPECIFICADA'},
{ codigo: 'Q18', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA CARA Y DEL CUELLO'},
{ codigo: 'Q180', descripcion: 'SENO, FISTULA O QUISTE DE LA HENDIDURA BRANQUIAL'},
{ codigo: 'Q181', descripcion: 'SENO Y QUISTE PREAURICULAR'},
{ codigo: 'Q182', descripcion: 'OTRAS MALFORMACIONES DE LAS HENDIDURAS BRANQUIALES'},
{ codigo: 'Q183', descripcion: 'PTERIGION DEL CUELLO'},
{ codigo: 'Q184', descripcion: 'MACROSTOMIA'},
{ codigo: 'Q185', descripcion: 'MICROSTOMIA'},
{ codigo: 'Q186', descripcion: 'MACROQUEILIA'},
{ codigo: 'Q187', descripcion: 'MICROQUEILIA'},
{ codigo: 'Q188', descripcion: 'OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DE CARA Y CUELLO'},
{ codigo: 'Q189', descripcion: 'MALFORMACION CONGENITA DE LA CARA Y DEL CUELLO, NO ESPECIFICADA'},
{ codigo: 'Q20', descripcion: 'MALFORMACIONES CONGENITAS DE LAS CAMARAS CARDIACAS Y SUS CONEXIONES'},
{ codigo: 'Q200', descripcion: 'TRONCO ARTERIOSO COMUN'},
{ codigo: 'Q201', descripcion: 'TRANSPOSICION DE LOS GRANDES VASOS EN VENTRICULO DERECHO'},
{ codigo: 'Q202', descripcion: 'TRANSPOSICION DE LOS GRANDES VASOS EN VENTRICULO IZQUIERDO'},
{ codigo: 'Q203', descripcion: 'DISCORDANCIA DE LA CONEXION VENTRICULOARTERIAL'},
{ codigo: 'Q204', descripcion: 'VENTRICULO CON DOBLE ENTRADA'},
{ codigo: 'Q205', descripcion: 'DISCORDANCIA DE LA CONEXION AURICULOVENTRICULAR'},
{ codigo: 'Q206', descripcion: 'ISOMERISMO DE LOS APENDICES AURICULARES'},
{ codigo: 'Q208', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE CAMARAS CARDIACAS Y SUS CONEXIONES'},
{ codigo: 'Q209', descripcion: 'MALFORMACION CONGENITA DE CAMARAS CARDIACAS Y SUS CONEXIONES, NO ESPECIFICADA'},
{ codigo: 'Q21', descripcion: 'MALFORMACIONES CONGENITAS DE LOS TABIQUES CARDIACOS'},
{ codigo: 'Q210', descripcion: 'DEFECTO DEL TABIQUE VENTRICULAR'},
{ codigo: 'Q211', descripcion: 'DEFECTO DEL TABIQUE AURICULAR'},
{ codigo: 'Q212', descripcion: 'DEFECTO DEL TABIQUE AURICULOVENTRICULAR'},
{ codigo: 'Q213', descripcion: 'TETRALOGIA DE FALLOT'},
{ codigo: 'Q214', descripcion: 'DEFECTO DEL TABIQUE AORTOPULMONAR'},
{ codigo: 'Q218', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS TABIQUES CARDIACOS'},
{ codigo: 'Q219', descripcion: 'MALFORMACION CONGENITA DEL TABIQUE CARDIACO, NO ESPECIFICADA'},
{ codigo: 'Q22', descripcion: 'MALFORMACIONES CONGENITAS DE LAS VALVULAS PULMONAR Y TRICUSPIDE'},
{ codigo: 'Q220', descripcion: 'ATRESIA DE LA VALVULA PULMONAR'},
{ codigo: 'Q221', descripcion: 'ESTENOSIS CONGENITA DE LA VALVULA PULMONAR'},
{ codigo: 'Q222', descripcion: 'INSUFICIENCIA CONGENITA DE LA VALVULA PULMONAR'},
{ codigo: 'Q223', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA VALVULA PULMONAR'},
{ codigo: 'Q224', descripcion: 'ESTENOSIS CONGENITA DE LA VALVULA TRICUSPIDE'},
{ codigo: 'Q225', descripcion: 'ANOMALIA DE EBSTEIN'},
{ codigo: 'Q226', descripcion: 'SINDROME DE HIPOPLASIA DEL CORAZON DERECHO'},
{ codigo: 'Q228', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA VALVULA TRICUSPIDE'},
{ codigo: 'Q229', descripcion: 'MALFORMACION CONGENITA DE LA VALVULA TRICUSPIDE, NO ESPECIFICADA'},
{ codigo: 'Q23', descripcion: 'MALFORMACIONES CONGENITAS DE LAS VALVULAS AORTICA Y MITRAL'},
{ codigo: 'Q230', descripcion: 'ESTENOSIS CONGENITA DE LA VALVULA AORTICA'},
{ codigo: 'Q231', descripcion: 'INSUFICIENCIA CONGENITA DE LA VALVULA AORTICA'},
{ codigo: 'Q232', descripcion: 'ESTENOSIS MITRAL CONGENITA'},
{ codigo: 'Q233', descripcion: 'INSUFICIENCIA MITRAL CONGENITA'},
{ codigo: 'Q234', descripcion: 'SINDROME DE HIPOPLASIA DEL CORAZON IZQUIERDO'},
{ codigo: 'Q238', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LAS VALVULAS AORTICA Y MITRAL'},
{ codigo: 'Q239', descripcion: 'MALFORMACION CONGENITA DE LAS VALVULAS AORTICA Y MITRAL, NO ESPECIFICADA'},
{ codigo: 'Q24', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL CORAZON'},
{ codigo: 'Q240', descripcion: 'DEXTROCARDIA'},
{ codigo: 'Q241', descripcion: 'LEVOCARDIA'},
{ codigo: 'Q242', descripcion: 'CORAZON TRIAURICULAR'},
{ codigo: 'Q243', descripcion: 'ESTENOSIS DEL INFUNDIBULO PULMONAR'},
{ codigo: 'Q244', descripcion: 'ESTENOSIS SUBAORTICA CONGENITA'},
{ codigo: 'Q245', descripcion: 'MALFORMACION DE LOS VASOS CORONARIOS'},
{ codigo: 'Q246', descripcion: 'BLOQUEO CARDIACO CONGENITO'},
{ codigo: 'Q248', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL CORAZON, ESPECIFICADAS'},
{ codigo: 'Q249', descripcion: 'MALFORMACION CONGENITA DEL CORAZON, NO ESPECIFICADA'},
{ codigo: 'Q25', descripcion: 'MALFORMACIONES CONGENITAS DE LAS GRANDES ARTERIAS'},
{ codigo: 'Q250', descripcion: 'CONDUCTO ARTERIOSO PERMEABLE'},
{ codigo: 'Q251', descripcion: 'COARTACION DE LA AORTA'},
{ codigo: 'Q252', descripcion: 'ATRESIA DE LA AORTA'},
{ codigo: 'Q253', descripcion: 'ESTENOSIS DE LA AORTA'},
{ codigo: 'Q254', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA AORTA'},
{ codigo: 'Q255', descripcion: 'ATRESIA DE LA ARTERIA PULMONAR'},
{ codigo: 'Q256', descripcion: 'ESTENOSIS DE LA ARTERIA PULMONAR'},
{ codigo: 'Q257', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA ARTERIA PULMONAR'},
{ codigo: 'Q258', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LAS GRANDES ARTERIAS'},
{ codigo: 'Q259', descripcion: 'MALFORMACION CONGENITA DE LAS GRANDES ARTERIAS, NO ESPECIFICADA'},
{ codigo: 'Q26', descripcion: 'MALFORMACIONES CONGENITAS DE LAS GRANDES VENAS'},
{ codigo: 'Q260', descripcion: 'ESTENOSIS CONGENITA DE LA VENA CAVA'},
{ codigo: 'Q261', descripcion: 'PERSISTENCIA DE LA VENA CAVA SUPERIOR IZQUIERDA'},
{ codigo: 'Q262', descripcion: 'CONEXION ANOMALA TOTAL DE LAS VENAS PULMONARES'},
{ codigo: 'Q263', descripcion: 'CONEXION ANOMALA PARCIAL DE LAS VENAS PULMONARES'},
{ codigo: 'Q264', descripcion: 'CONEXION ANOMALA DE LAS VENAS PULMONARES, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q265', descripcion: 'CONEXION ANOMALA DE LA VENA PORTA'},
{ codigo: 'Q266', descripcion: 'FISTULA ARTERIA HEPATICA-VENA PORTA'},
{ codigo: 'Q268', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LAS GRANDES VENAS'},
{ codigo: 'Q269', descripcion: 'MALFORMACION CONGENITA DE LAS GRANDES VENAS, NO ESPECIFICADA'},
{ codigo: 'Q27', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA VASCULAR PERIFERICO'},
{ codigo: 'Q270', descripcion: 'AUSENCIA E HIPOPLASIA CONGENITA DE LA ARTERIA UMBILICAL'},
{ codigo: 'Q271', descripcion: 'ESTENOSIS CONGENITA DE LA ARTERIA RENAL'},
{ codigo: 'Q272', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA ARTERIA RENAL'},
{ codigo: 'Q273', descripcion: 'MALFORMACION ARTERIOVENOSA PERIFERICA'},
{ codigo: 'Q274', descripcion: 'FLEBECTASIA CONGENITA'},
{ codigo: 'Q278', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA VASCULAR PERIFERICO, ESPECIFICADAS'},
{ codigo: 'Q279', descripcion: 'MALFORMACION CONGENITA DEL SISTEMA VASCULAR PERIFERICO, NO ESPECIFICADA'},
{ codigo: 'Q28', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA CIRCULATORIO'},
{ codigo: 'Q280', descripcion: 'MALFORMACION ARTERIOVENOSA DE LOS VASOS PRECEREBRALES'},
{ codigo: 'Q281', descripcion: 'OTRAS MALFORMACIONES DE LOS VASOS PRECEREBRALES'},
{ codigo: 'Q282', descripcion: 'MALFORMACION ARTERIOVENOSA DE LOS VASOS CEREBRALES'},
{ codigo: 'Q283', descripcion: 'OTRAS MALFORMACIONES DE LOS VASOS CEREBRALES'},
{ codigo: 'Q288', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA CIRCULATORIO, ESPECIFICADA'},
{ codigo: 'Q289', descripcion: 'MALFORMACION CONGENITA DEL SISTEMA CIRCULATORIO, NO ESPECIFICADA'},
{ codigo: 'Q30', descripcion: 'MALFORMACIONES CONGENITAS DE LA NARIZ'},
{ codigo: 'Q300', descripcion: 'ATRESIA DE LAS COANAS'},
{ codigo: 'Q301', descripcion: 'AGENESIA O HIPOPLASIA DE LA NARIZ'},
{ codigo: 'Q302', descripcion: 'HENDIDURA, FISURA O MUESCA DE LA NARIZ'},
{ codigo: 'Q303', descripcion: 'PERFORACION CONGENITA DEL TABIQUE NASAL'},
{ codigo: 'Q308', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA NARIZ'},
{ codigo: 'Q309', descripcion: 'MALFORMACION CONGENITA DE LA NARIZ, NO ESPECIFICADA'},
{ codigo: 'Q31', descripcion: 'MALFORMACIONES CONGENITAS DE LA LARINGE'},
{ codigo: 'Q310', descripcion: 'PTERIGION DE LA LARINGE'},
{ codigo: 'Q311', descripcion: 'ESTENOSIS SUBGLOTICA CONGENITA'},
{ codigo: 'Q312', descripcion: 'HIPOPLASIA LARINGEA'},
{ codigo: 'Q313', descripcion: 'LARINGOCELE'},
{ codigo: 'Q314', descripcion: 'ESTRIDOR LARINGEO CONGENITO'},
{ codigo: 'Q318', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA LARINGE'},
{ codigo: 'Q319', descripcion: 'MALFORMACION CONGENITA DE LA LARINGE, NO ESPECIFICADA'},
{ codigo: 'Q32', descripcion: 'MALFORMACIONES CONGENITAS DE LA TRAQUEA Y DE LOS BRONQUIOS'},
{ codigo: 'Q320', descripcion: 'TRAQUEOMALACIA CONGENITA'},
{ codigo: 'Q321', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA TRAQUEA'},
{ codigo: 'Q322', descripcion: 'BRONCOMALACIA CONGENITA'},
{ codigo: 'Q323', descripcion: 'ESTENOSIS CONGENITA DE LOS BRONQUIOS'},
{ codigo: 'Q324', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS BRONQUIOS'},
{ codigo: 'Q33', descripcion: 'MALFORMACIONES CONGENITAS DEL PULMON'},
{ codigo: 'Q330', descripcion: 'QUISTE PULMONAR CONGENITO'},
{ codigo: 'Q331', descripcion: 'LOBULO PULMONAR SUPERNUMERARIO'},
{ codigo: 'Q332', descripcion: 'SECUESTRO DEL PULMON'},
{ codigo: 'Q333', descripcion: 'AGENESIA DEL PULMON'},
{ codigo: 'Q334', descripcion: 'BRONQUIECTASIA CONGENITA'},
{ codigo: 'Q335', descripcion: 'TEJIDO ECTOPICO EN EL PULMON'},
{ codigo: 'Q336', descripcion: 'HIPOPLASIA Y DISPLASIA PULMONAR'},
{ codigo: 'Q338', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL PULMON'},
{ codigo: 'Q339', descripcion: 'MALFORMACION CONGENITA DEL PULMON, NO ESPECIFICADA'},
{ codigo: 'Q34', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA RESPIRATORIO'},
{ codigo: 'Q340', descripcion: 'ANOMALIA DE LA PLEURA'},
{ codigo: 'Q341', descripcion: 'QUISTE CONGENITO DEL MEDIASTINO'},
{ codigo: 'Q348', descripcion: 'OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DEL SISTEMA RESPIRATORIO'},
{ codigo: 'Q349', descripcion: 'MALFORMACION CONGENITA DEL SISTEMA RESPIRATORIO, NO ESPECIFICADA'},
{ codigo: 'Q35', descripcion: 'FISURA DEL PALADAR'},
{ codigo: 'Q350', descripcion: 'FISURA DEL PALADAR DURO, BILATERAL'},
{ codigo: 'Q351', descripcion: 'FISURA DEL PALADAR DURO, UNILATERAL'},
{ codigo: 'Q352', descripcion: 'FISURA DEL PALADAR BLANDO, BILATERAL'},
{ codigo: 'Q353', descripcion: 'FISURA DEL PALADAR BLANDO, UNILATERAL'},
{ codigo: 'Q354', descripcion: 'FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO, BILATERAL'},
{ codigo: 'Q355', descripcion: 'FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO, UNILATERAL'},
{ codigo: 'Q356', descripcion: 'FISURA DEL PALADAR, LINEA MEDIA'},
{ codigo: 'Q357', descripcion: 'FISURA DE LA UVULA'},
{ codigo: 'Q358', descripcion: 'FISURA DEL PALADAR BILATERAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q359', descripcion: 'FISURA DEL PALADAR UNILATERAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q36', descripcion: 'LABIO LEPORINO'},
{ codigo: 'Q360', descripcion: 'LABIO LEPORINO, BILATERAL'},
{ codigo: 'Q361', descripcion: 'LABIO LEPORINO, LINEA MEDIA'},
{ codigo: 'Q369', descripcion: 'LABIO LEPORINO, UNILATERAL'},
{ codigo: 'Q37', descripcion: 'FISURA DEL PALADAR CON LABIO LEPORINO'},
{ codigo: 'Q370', descripcion: 'FISURA DEL PALADAR DURO CON LABIO LEPORINO, BILATERAL'},
{ codigo: 'Q371', descripcion: 'FISURA DEL PALADAR DURO CON LABIO LEPORINO, UNILATERAL'},
{ codigo: 'Q372', descripcion: 'FISURA DEL PALADAR BLANDO CON LABIO LEPORINO, BILATERAL'},
{ codigo: 'Q373', descripcion: 'FISURA DEL PALADAR BLANDO CON LABIO LEPORINO, UNILATERAL'},
{ codigo: 'Q374', descripcion: 'FISURA DEL PALADAR DURO Y PALADAR BLANDO CON LABIO LEPORINO, BILATERAL'},
{ codigo: 'Q375', descripcion: 'FISURA DE PALADAR DURO Y PALADAR BLANDO CON LABIO LEPORINO, UNILATERAL'},
{ codigo: 'Q378', descripcion: 'FISURA DEL PALADAR CON LABIO LEPORINO BILATERAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q379', descripcion: 'FISURA DEL PALADAR CON LABIO LEPORINO UNILATERAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q38', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA LENGUA, DE LA BOCA Y DE FARINGE'},
{ codigo: 'Q380', descripcion: 'MALFORMACIONES CONGENITAS DE LOS LABIOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Q381', descripcion: 'ANQUILOGLOSIA'},
{ codigo: 'Q382', descripcion: 'MACROGLOSIA'},
{ codigo: 'Q383', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA LENGUA'},
{ codigo: 'Q384', descripcion: 'MALFORMACIONES CONGENITAS DE GLANDULAS Y DE LOS CONDUCTOS SALIVALES'},
{ codigo: 'Q385', descripcion: 'MALFORMACIONES CONGENITAS DEL PALADAR, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Q386', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA BOCA'},
{ codigo: 'Q387', descripcion: 'DIVERTICULO FARINGEO'},
{ codigo: 'Q388', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA FARINGE'},
{ codigo: 'Q39', descripcion: 'MALFORMACIONES CONGENITAS DEL ESOFAGO'},
{ codigo: 'Q390', descripcion: 'ATRESIA DEL ESOFAGO SIN MENCION DE FISTULA'},
{ codigo: 'Q391', descripcion: 'ATRESIA DEL ESOFAGO CON FISTULA TRAQUEOESOFAGICA'},
{ codigo: 'Q392', descripcion: 'FISTULA TRAQUEOESOFAGICA CONGENITA SIN MENCION DE ATRESIA'},
{ codigo: 'Q393', descripcion: 'ESTRECHEZ O ESTENOSIS CONGENITA DEL ESOFAGO'},
{ codigo: 'Q394', descripcion: 'PTERIGION DEL ESOFAGO'},
{ codigo: 'Q395', descripcion: 'DILATACION CONGENITA DEL ESOFAGO'},
{ codigo: 'Q396', descripcion: 'DIVERTICULO DEL ESOFAGO'},
{ codigo: 'Q398', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL ESOFAGO'},
{ codigo: 'Q399', descripcion: 'MALFORMACION CONGENITA DEL ESOFAGO, NO ESPECIFICADA'},
{ codigo: 'Q40', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA PARTE SUPERIOR DE TUBO DIGESTIVO'},
{ codigo: 'Q400', descripcion: 'ESTENOSIS HIPERTROFICA CONGENITA DEL PILORO'},
{ codigo: 'Q401', descripcion: 'HERNIA HIATAL CONGENITA'},
{ codigo: 'Q402', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL ESTOMAGO, ESPECIFICADAS'},
{ codigo: 'Q403', descripcion: 'MALFORMACION CONGENITA DEL ESTOMAGO, NO ESPECIFICADA'},
{ codigo: 'Q408', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA PARTE SUPERIOR DE TUBO DIGESTIVO'},
{ codigo: 'Q409', descripcion: 'MALFORMACION CONGENITA DE PARTE SUPERIOR DEL TUBO DIGESTIVO, NO ESPECIFICADA'},
{ codigo: 'Q41', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL INTESTINO DELGADO'},
{ codigo: 'Q410', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL DUODENO'},
{ codigo: 'Q411', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL YEYUNO'},
{ codigo: 'Q412', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL ILEON'},
{ codigo: 'Q418', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DE OTRAS PARTES ESPECIFICADAS DEL INTESTINO DELGADO'},
{ codigo: 'Q419', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA INTESTINO DELGADO, PARTE NO ESPECIFICADA'},
{ codigo: 'Q42', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL INTESTINO GRUESO'},
{ codigo: 'Q420', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL RECTO, CON FISTULA'},
{ codigo: 'Q421', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL RECTO, SIN FISTULA'},
{ codigo: 'Q422', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL ANO, CON FISTULA'},
{ codigo: 'Q423', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL ANO, SIN FISTULA'},
{ codigo: 'Q428', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DE OTRAS PARTES DEL INTESTINO GRUESO'},
{ codigo: 'Q429', descripcion: 'AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL INTESTINO GRUESO, PARTE NO ESPECIFICADA'},
{ codigo: 'Q43', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL INTESTINO'},
{ codigo: 'Q430', descripcion: 'DIVERTICULO DE MECKEL'},
{ codigo: 'Q431', descripcion: 'ENFERMEDAD DE HIRSCHSPRUNG'},
{ codigo: 'Q432', descripcion: 'OTROS TRASTORNOS FUNCIONALES CONGENITOS DEL COLON'},
{ codigo: 'Q433', descripcion: 'MALFORMACIONES CONGENITAS DE LA FIJACION DEL INTESTINO'},
{ codigo: 'Q434', descripcion: 'DUPLICACION DEL INTESTINO'},
{ codigo: 'Q435', descripcion: 'ANO ECTOPICO'},
{ codigo: 'Q436', descripcion: 'FISTULA CONGENITA DEL RECTO Y DEL ANO'},
{ codigo: 'Q437', descripcion: 'PERSISTENCIA DE LA CLOACA'},
{ codigo: 'Q438', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL INTESTINO, ESPECIFICADAS'},
{ codigo: 'Q439', descripcion: 'MALFORMACION CONGENITA DEL INTESTINO, NO ESPECIFICADA'},
{ codigo: 'Q44', descripcion: 'MALFORMACIONES CONGENITAS DE VESICULA BILIAR, COND BILIARES Y HIGADO'},
{ codigo: 'Q440', descripcion: 'AGENESIA, APLASIA E HIPOPLASIA DE LA VESICULA BILIAR'},
{ codigo: 'Q441', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA VESICULA BILIAR'},
{ codigo: 'Q442', descripcion: 'ATRESIA DE LOS CONDUCTOS BILIARES'},
{ codigo: 'Q443', descripcion: 'ESTRECHEZ Y ESTENOSIS CONGENITA DE LOS CONDUCTOS BILIARES'},
{ codigo: 'Q444', descripcion: 'QUISTE DEL COLEDOCO'},
{ codigo: 'Q445', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS CONDUCTOS BILIARES'},
{ codigo: 'Q446', descripcion: 'ENFERMEDAD QUISTICA DEL HIGADO'},
{ codigo: 'Q447', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL HIGADO'},
{ codigo: 'Q45', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA DIGESTIVO'},
{ codigo: 'Q450', descripcion: 'AGENESIA, APLASIA E HIPOPLASIA DEL PANCREAS'},
{ codigo: 'Q451', descripcion: 'PANCREAS ANULAR'},
{ codigo: 'Q452', descripcion: 'QUISTE CONGENITO DEL PANCREAS'},
{ codigo: 'Q453', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE PANCREAS Y DEL CONDUCTO PANCREATICO'},
{ codigo: 'Q458', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA DIGESTIVO, ESPECIFICADAS'},
{ codigo: 'Q459', descripcion: 'MALFORMACION CONGENITA DEL SISTEMA DIGESTIVO, NO ESPECIFICADA'},
{ codigo: 'Q50', descripcion: 'MALFORMACIONES CONGENITAS DE OVARIOS, TROMPAS FALOPIO Y LIGAMENTOS ANCHO'},
{ codigo: 'Q500', descripcion: 'AUSENCIA CONGENITA DE OVARIO'},
{ codigo: 'Q501', descripcion: 'QUISTE EN DESARROLLO DEL OVARIO'},
{ codigo: 'Q502', descripcion: 'TORSION CONGENITA DEL OVARIO'},
{ codigo: 'Q503', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS OVARIOS'},
{ codigo: 'Q504', descripcion: 'QUISTE EMBRIONARIO DE LA TROMPA DE FALOPIO'},
{ codigo: 'Q505', descripcion: 'QUISTE EMBRIONARIO DEL LIGAMENTO ANCHO'},
{ codigo: 'Q506', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE TROMPA DE FALOPIO Y LIGAMENTO ANCHO'},
{ codigo: 'Q51', descripcion: 'MALFORMACIONES CONGENITAS DEL UTERO Y DEL CUELLO UTERINO'},
{ codigo: 'Q510', descripcion: 'AGENESIA Y APLASIA DEL UTERO'},
{ codigo: 'Q511', descripcion: 'DUPLICACION DEL UTERO CON DUPLICACION DEL CUELLO UTERINO Y DE VAGINA'},
{ codigo: 'Q512', descripcion: 'OTRA DUPLICACION DEL UTERO'},
{ codigo: 'Q513', descripcion: 'UTERO BICORNE'},
{ codigo: 'Q514', descripcion: 'UTERO UNICORNE'},
{ codigo: 'Q515', descripcion: 'AGENESIA Y APLASIA DEL CUELLO UTERINO'},
{ codigo: 'Q516', descripcion: 'QUISTE EMBRIONARIO DEL CUELLO UTERINO'},
{ codigo: 'Q517', descripcion: 'FISTULA CONGENITA ENTRE EL UTERO Y EL TRACTO DIGESTIVO Y URINARIO'},
{ codigo: 'Q518', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL UTERO Y DEL CUELLO UTERINO'},
{ codigo: 'Q519', descripcion: 'MALFORMACION CONGENITA DEL UTERO Y DEL CUELLO UTERINO, NO ESPECIFICADA'},
{ codigo: 'Q52', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS ORGANOS GENITALES FEMENINOS'},
{ codigo: 'Q520', descripcion: 'AUSENCIA CONGENITA DE LA VAGINA'},
{ codigo: 'Q521', descripcion: 'DUPLICACION DE LA VAGINA'},
{ codigo: 'Q522', descripcion: 'FISTULA RECTOVAGINAL CONGENITA'},
{ codigo: 'Q523', descripcion: 'HIMEN IMPERFORADO'},
{ codigo: 'Q524', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA VAGINA'},
{ codigo: 'Q525', descripcion: 'FUSION DE LABIOS DE LA VULVA'},
{ codigo: 'Q526', descripcion: 'MALFORMACION CONGENITA DEL CLITORIS'},
{ codigo: 'Q527', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA VULVA'},
{ codigo: 'Q528', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS ORGANOS GENITALES FEMENINOS, ESPECIFICADAS'},
{ codigo: 'Q529', descripcion: 'MALFORMACION CONGENITA DE LOS GENITALES FEMENINOS, NO ESPECIFICADA'},
{ codigo: 'Q53', descripcion: 'TESTICULO NO DESCENDIDO'},
{ codigo: 'Q530', descripcion: 'ECTOPIA TESTICULAR'},
{ codigo: 'Q531', descripcion: 'TESTICULO NO DESCENDIDO, UNILATERAL'},
{ codigo: 'Q532', descripcion: 'TESTICULO NO DESCENDIDO, BILATERAL'},
{ codigo: 'Q539', descripcion: 'TESTICULO NO DESCENDIDO, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q54', descripcion: 'HIPOSPADIAS'},
{ codigo: 'Q540', descripcion: 'HIPOSPADIAS DEL GLANDE'},
{ codigo: 'Q541', descripcion: 'HIPOSPADIAS PENEANA'},
{ codigo: 'Q542', descripcion: 'HIPOSPADIAS PENOSCROTAL'},
{ codigo: 'Q543', descripcion: 'HIPOSPADIAS PERINEAL'},
{ codigo: 'Q544', descripcion: 'ENCORDAMIENTO CONGENITO DEL PENE'},
{ codigo: 'Q548', descripcion: 'OTRAS HIPOSPADIAS'},
{ codigo: 'Q549', descripcion: 'HIPOSPADIAS, NO ESPECIFICADA'},
{ codigo: 'Q55', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS ORGANOS GENITALES MASCULINOS'},
{ codigo: 'Q550', descripcion: 'AUSENCIA Y APLASIA DEL TESTICULO'},
{ codigo: 'Q551', descripcion: 'HIPOPLASIA DEL TESTICULO Y DEL ESCROTO'},
{ codigo: 'Q552', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS TESTICULOS Y DEL ESCROTO'},
{ codigo: 'Q553', descripcion: 'ATRESIA DEL CONDUCTO DEFERENTE'},
{ codigo: 'Q554', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS CONDUCTOS DEFERENTES DEL EPIDIDIMO, DE LAS VESICULAS SEMINALES Y DE LA PROSTATA'},
{ codigo: 'Q555', descripcion: 'APLASIA Y AUSENCIA CONGENITA DEL PENE'},
{ codigo: 'Q556', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL PENE'},
{ codigo: 'Q558', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS ORGANOS GENITALES MASCULINOS, ESPECIFICADAS'},
{ codigo: 'Q559', descripcion: 'MALFORMACION CONGENITA DE LOS ORGANOS GENITALES MASCULINOS, NO ESPECIFICADAS'},
{ codigo: 'Q56', descripcion: 'SEXO INDETERMINADO Y SEUDOHERMAFRODITISMO'},
{ codigo: 'Q560', descripcion: 'HERMAFRODITISMO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'Q561', descripcion: 'SEUDOHERMAFRODITISMO MASCULINO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'Q562', descripcion: 'SEUDOHERMAFRODITISMO FEMENINO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'Q563', descripcion: 'SEUDOHERMAFRODITISMO, NO ESPECIFICADO'},
{ codigo: 'Q564', descripcion: 'SEXO INDETERMINADO, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q60', descripcion: 'AGENESIA RENAL Y OTRAS MALFORMACIONES HIPOPLASICAS DEL RI—ON'},
{ codigo: 'Q600', descripcion: 'AGENESIA RENAL, UNILATERAL'},
{ codigo: 'Q601', descripcion: 'AGENESIA RENAL, BILATERAL'},
{ codigo: 'Q602', descripcion: 'AGENESIA RENAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q603', descripcion: 'HIPOPLASIA RENAL, UNILATERAL'},
{ codigo: 'Q604', descripcion: 'HIPOPLASIA RENAL, BILATERAL'},
{ codigo: 'Q605', descripcion: 'HIPOPLASIA RENAL, NO ESPECIFICADA'},
{ codigo: 'Q606', descripcion: 'SINDROME DE POTTER'},
{ codigo: 'Q61', descripcion: 'ENFERMEDAD QUISTICA DEL RI—ON'},
{ codigo: 'Q610', descripcion: 'QUISTE RENAL SOLITARIO CONGENITO'},
{ codigo: 'Q611', descripcion: 'RI—ON POLIQUISTICO, TIPO INFANTIL'},
{ codigo: 'Q612', descripcion: 'RI—ON POLIQUISTICO, TIPO ADULTO'},
{ codigo: 'Q613', descripcion: 'RI—ON POLIQUISTICO, TIPO NO ESPECIFICADO'},
{ codigo: 'Q614', descripcion: 'DISPLASIA RENAL'},
{ codigo: 'Q615', descripcion: 'RI—ON QUISTICO MEDULAR'},
{ codigo: 'Q618', descripcion: 'OTRAS ENFERMEDADES RENALES QUISTICAS'},
{ codigo: 'Q619', descripcion: 'ENFERMEDAD QUISTICA DEL RI—ON, NO ESPECIFICADA'},
{ codigo: 'Q62', descripcion: 'DEFECTOS OBSTRUCTIVOS CONGENITOS DE LA PELVIS RENAL Y MALFORMACIONES CONGENITAS DEL URETER'},
{ codigo: 'Q620', descripcion: 'HIDRONEFROSIS CONGENITA'},
{ codigo: 'Q621', descripcion: 'ATRESIA Y ESTENOSIS DEL URETER'},
{ codigo: 'Q622', descripcion: 'MEGALOURETER CONGENITO'},
{ codigo: 'Q623', descripcion: 'OTROS DEFECTOS OBSTRUCTIVOS DE LA PELVIS RENAL Y DEL URETER'},
{ codigo: 'Q624', descripcion: 'AGENESIA DEL URETER'},
{ codigo: 'Q625', descripcion: 'DUPLICACION DEL URETER'},
{ codigo: 'Q626', descripcion: 'MALA POSICION DEL URETER'},
{ codigo: 'Q627', descripcion: 'REFLUJO VESICO-URETERO-RENAL CONGENITO'},
{ codigo: 'Q628', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL URETER'},
{ codigo: 'Q63', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL RI—ON'},
{ codigo: 'Q630', descripcion: 'RI—ON SUPERNUMERARIO'},
{ codigo: 'Q631', descripcion: 'RI—ON LOBULADO, FUSIONADO Y EN HERRADURA'},
{ codigo: 'Q632', descripcion: 'RI—ON ECTOPICO'},
{ codigo: 'Q633', descripcion: 'HIPERPLASIA RENAL Y RI—ON GIGANTE'},
{ codigo: 'Q638', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL RI—ON, ESPECIFICADAS'},
{ codigo: 'Q639', descripcion: 'MALFORMACION CONGENITA DEL RI—ON, NO ESPECIFICADA'},
{ codigo: 'Q64', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA URINARIO'},
{ codigo: 'Q640', descripcion: 'EPISPADIAS'},
{ codigo: 'Q641', descripcion: 'EXTROFIA DE LA VEJIGA URINARIA'},
{ codigo: 'Q642', descripcion: 'VALVULAS URETRALES POSTERIORES CONGENITAS'},
{ codigo: 'Q643', descripcion: 'OTRAS ATRESIAS Y ESTENOSIS DE LA URETRA Y DEL CUELLO DE LA VEJIGA'},
{ codigo: 'Q644', descripcion: 'MALFORMACION DEL URACO'},
{ codigo: 'Q645', descripcion: 'AUSENCIA CONGENITA DE LA VEJIGA Y DE LA URETRA'},
{ codigo: 'Q646', descripcion: 'DIVERTICULO CONGENITO DE LA VEJIGA'},
{ codigo: 'Q647', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA VEJIGA Y DE LA URETRA'},
{ codigo: 'Q648', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL APARATO URINARIO, ESPECIFICADAS'},
{ codigo: 'Q649', descripcion: 'MALFORMACION CONGENITA DEL APARATO URINARIO, NO ESPECIFICADA'},
{ codigo: 'Q65', descripcion: 'DEFORMIDADES CONGENITAS DE LA CADERA'},
{ codigo: 'Q650', descripcion: 'LUXACION CONGENITA DE LA CADERA, UNILATERAL'},
{ codigo: 'Q651', descripcion: 'LUXACION CONGENITA DE LA CADERA, BILATERAL'},
{ codigo: 'Q652', descripcion: 'LUXACION CONGENITA DE LA CADERA, NO ESPECIFICADA'},
{ codigo: 'Q653', descripcion: 'SUBLUXACION CONGENITA DE LA CADERA, UNILATERAL'},
{ codigo: 'Q654', descripcion: 'SUBLUXACION CONGENITA DE LA CADERA, BILATERAL'},
{ codigo: 'Q655', descripcion: 'SUBLUXACION CONGENITA DE LA CADERA, NO ESPECIFICADA'},
{ codigo: 'Q656', descripcion: 'CADERA INESTABLE'},
{ codigo: 'Q658', descripcion: 'OTRAS DEFORMIDADES CONGENITAS DE LA CADERA'},
{ codigo: 'Q659', descripcion: 'DEFORMIDAD CONGENITA DE LA CADERA, NO ESPECIFICADA'},
{ codigo: 'Q66', descripcion: 'DEFORMIDADES CONGENITAS DE LOS PIES'},
{ codigo: 'Q660', descripcion: 'TALIPES EQUINOVARUS'},
{ codigo: 'Q661', descripcion: 'TALIPES CALCANEOVARUS'},
{ codigo: 'Q662', descripcion: 'METATARSUS VARUS'},
{ codigo: 'Q663', descripcion: 'OTRAS DEFORMIDADES VARUS CONGENITAS DE LOS PIES'},
{ codigo: 'Q664', descripcion: 'TALIPES CALCANEOVALGUS'},
{ codigo: 'Q665', descripcion: 'PIE PLANO CONGENITO'},
{ codigo: 'Q666', descripcion: 'OTRAS DEFORMIDADES VALGUS CONGENITAS DE LOS PIES'},
{ codigo: 'Q667', descripcion: 'PIE CAVUS'},
{ codigo: 'Q668', descripcion: 'OTRAS DEFORMIDADES CONGENITAS DE LOS PIES'},
{ codigo: 'Q669', descripcion: 'DEFORMIDAD CONGENITA DE LOS PIES, NO ESPECIFICADA'},
{ codigo: 'Q67', descripcion: 'DEFORMIDADES OSTEOMUSCULARES CONGENITAS DE LA CABEZA, CARA, COLUMNA VERTEBRAL Y TORAX'},
{ codigo: 'Q670', descripcion: 'ASIMETRIA FACIAL'},
{ codigo: 'Q671', descripcion: 'FACIES COMPRIMIDA'},
{ codigo: 'Q672', descripcion: 'DOLICOCEFALIA'},
{ codigo: 'Q673', descripcion: 'PLAGIOCEFALIA'},
{ codigo: 'Q674', descripcion: 'OTRAS DEFORMIDADES CONGENITAS DEL CRANEO, DE LA CARA Y DE LA MANDIBULA'},
{ codigo: 'Q675', descripcion: 'DEFORMIDAD CONGENITA DE LA COLUMNA VERTEBRAL'},
{ codigo: 'Q676', descripcion: 'TORAX EXCAVADO'},
{ codigo: 'Q677', descripcion: 'TORAX EN QUILLA'},
{ codigo: 'Q678', descripcion: 'OTRAS DEFORMIDADES CONGENITAS DEL TORAX'},
{ codigo: 'Q68', descripcion: 'OTRAS DEFORMIDADES OSTEOMUSCULARES CONGENITAS'},
{ codigo: 'Q680', descripcion: 'DEFORMIDAD CONGENITA DEL MUSCULO ESTERNOCLEIDOMASTOIDEO'},
{ codigo: 'Q681', descripcion: 'DEFORMIDAD CONGENITA DE LA MANO'},
{ codigo: 'Q682', descripcion: 'DEFORMIDAD CONGENITA DE LA RODILLA'},
{ codigo: 'Q683', descripcion: 'CURVATURA CONGENITA DEL FEMUR'},
{ codigo: 'Q684', descripcion: 'CURVATURA CONGENITA DE LA TIBIA Y DEL PERONE'},
{ codigo: 'Q685', descripcion: 'CURVATURA CONGENITA DE HUESO(S) LARGO(S) DEL MIEMBRO INFERIOR, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q688', descripcion: 'OTRAS DEFORMIDADES CONGENITAS OSTEOMUSCULARES, ESPECIFICADAS'},
{ codigo: 'Q69', descripcion: 'POLIDACTILIA'},
{ codigo: 'Q690', descripcion: 'DEDO(S) SUPERNUMERARIO(S) DE LA MANO'},
{ codigo: 'Q691', descripcion: 'PULGAR(ES) SUPERNUMERARIO(S)'},
{ codigo: 'Q692', descripcion: 'DEDO(S) SUPERNUMERARIO(S) DEL PIE'},
{ codigo: 'Q699', descripcion: 'POLIDACTILIA, NO ESPECIFICADA'},
{ codigo: 'Q70', descripcion: 'SINDACTILIA'},
{ codigo: 'Q700', descripcion: 'FUSION DE LOS DEDOS DE LA MANO'},
{ codigo: 'Q701', descripcion: 'MEMBRANA INTERDIGITAL DE LA MANO'},
{ codigo: 'Q702', descripcion: 'FUSION DE LOS DEDOS DEL PIE'},
{ codigo: 'Q703', descripcion: 'MEMBRANA INTERDIGITAL DEL PIE'},
{ codigo: 'Q704', descripcion: 'POLISINDACTILIA'},
{ codigo: 'Q709', descripcion: 'SINDACTILIA, NO ESPECIFICADA'},
{ codigo: 'Q71', descripcion: 'DEFECTOS POR REDUCCION DEL MIEMBRO SUPERIOR'},
{ codigo: 'Q710', descripcion: 'AUSENCIA CONGENITA COMPLETA DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)'},
{ codigo: 'Q711', descripcion: 'AUSENCIA CONGENITA DEL BRAZO Y DEL ANTEBRAZO CON PRESENCIA DE LA MANO'},
{ codigo: 'Q712', descripcion: 'AUSENCIA CONGENITA DEL ANTEBRAZO Y DE LA MANO'},
{ codigo: 'Q713', descripcion: 'AUSENCIA CONGENITA DE LA MANO Y EL (LOS) DEDO(S)'},
{ codigo: 'Q714', descripcion: 'DEFECTO POR REDUCCION LONGITUDINAL DEL RADIO'},
{ codigo: 'Q715', descripcion: 'DEFECTO POR REDUCCION LONGITUDINAL DEL CUBITO'},
{ codigo: 'Q716', descripcion: 'MANO EN PINZA DE LANGOSTA'},
{ codigo: 'Q718', descripcion: 'OTROS DEFECTOS POR REDUCCION DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)'},
{ codigo: 'Q719', descripcion: 'DEFECTO POR REDUCCION DEL MIEMBRO SUPERIOR, NO ESPECIFICADO'},
{ codigo: 'Q72', descripcion: 'DEFECTOS POR REDUCCION DEL MIEMBRO INFERIOR'},
{ codigo: 'Q720', descripcion: 'AUSENCIA CONGENITA COMPLETA DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'Q721', descripcion: 'AUSENCIA CONGENITA DEL MUSLO Y DE LA PIERNA CON PRESENCIA DEL PIE'},
{ codigo: 'Q722', descripcion: 'AUSENCIA CONGENITA DE LA PIERNA Y DEL PIE'},
{ codigo: 'Q723', descripcion: 'AUSENCIA CONGENITA DEL PIE Y DEDO(S) DEL PIE'},
{ codigo: 'Q724', descripcion: 'DEFECTO POR REDUCCION LONGITUDINAL DEL FEMUR'},
{ codigo: 'Q725', descripcion: 'DEFECTO POR REDUCCION LONGITUDINAL DE LA TIBIA'},
{ codigo: 'Q726', descripcion: 'DEFECTO POR REDUCCION LONGITUDINAL DEL PERONE'},
{ codigo: 'Q727', descripcion: 'PIE HENDIDO'},
{ codigo: 'Q728', descripcion: 'OTROS DEFECTOS POR REDUCCION DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'Q729', descripcion: 'DEFECTO POR REDUCCION DEL MIEMBRO INFERIOR, NO ESPECIFICADO'},
{ codigo: 'Q73', descripcion: 'DEFECTOS POR REDUCCION DE MIEMBRO NO ESPECIFICADO'},
{ codigo: 'Q730', descripcion: 'AUSENCIA COMPLETA DE MIEMBRO(S) NO ESPECIFICADO(S)'},
{ codigo: 'Q731', descripcion: 'FOCOMELIA, MIEMBRO(S) NO ESPECIFICADO(S)'},
{ codigo: 'Q738', descripcion: 'OTROS DEFECTOS POR REDUCCION DE MIEMBRO(S) NO ESPECIFICADO(S)'},
{ codigo: 'Q74', descripcion: 'OTRAS ANOMALIAS CONGENITAS DEL (DE LOS) MIEMBRO(S)'},
{ codigo: 'Q740', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES), INCLUIDA LA CINTURA ESCAPULAR'},
{ codigo: 'Q741', descripcion: 'MALFORMACION CONGENITA DE LA RODILLA'},
{ codigo: 'Q742', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL (DE LOS) MIEMBRO(S) INFERIOR(ES), INCLUIDA LA CINTURA PELVIANA'},
{ codigo: 'Q743', descripcion: 'ARTROGRIPOSIS MULTIPLE CONGENITA'},
{ codigo: 'Q748', descripcion: 'OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DEL (DE LOS) MIEMBRO(S)'},
{ codigo: 'Q749', descripcion: 'MALFORMACION CONGENITA DE MIEMBRO(S), NO ESPECIFICADA'},
{ codigo: 'Q75', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LOS HUESOS DEL CRANEO Y DE LA CARA'},
{ codigo: 'Q750', descripcion: 'CRANEOSINOSTOSIS'},
{ codigo: 'Q751', descripcion: 'DISOSTOSIS CRANEOFACIAL'},
{ codigo: 'Q752', descripcion: 'HIPERTELORISMO'},
{ codigo: 'Q753', descripcion: 'MACROCEFALIA'},
{ codigo: 'Q754', descripcion: 'DISOSTOSIS MAXILOFACIAL'},
{ codigo: 'Q755', descripcion: 'DISOSTOSIS OCULOMAXILAR'},
{ codigo: 'Q758', descripcion: 'OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DE LOS HUESOS DEL CRANEO Y DE LA CARA'},
{ codigo: 'Q759', descripcion: 'MALFORMACION CONGENITA NO ESPECIFICADA DE LOS HUESOS DEL CRANEO Y DE LA CARA'},
{ codigo: 'Q76', descripcion: 'MALFORMACIONES CONGENITAS DE LA COLUMNA VERTEBRAL Y TORAX OSEO'},
{ codigo: 'Q760', descripcion: 'ESPINA BIFIDA OCULTA'},
{ codigo: 'Q761', descripcion: 'SINDROME DE KLIPPEL-FEIL'},
{ codigo: 'Q762', descripcion: 'ESPONDILOLISTESIS CONGENITA'},
{ codigo: 'Q763', descripcion: 'ESCOLIOSIS CONGENITA DEBIDA A MALFORMACION CONGENITA OSEA'},
{ codigo: 'Q764', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA COLUMNA VERTEBRAL, NO ASOCIADA A ESCOLIOSIS'},
{ codigo: 'Q765', descripcion: 'COSTILLA CERVICAL'},
{ codigo: 'Q766', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LAS COSTILLAS'},
{ codigo: 'Q767', descripcion: 'MALFORMACION CONGENITA DEL ESTERNON'},
{ codigo: 'Q768', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL TORAX OSEO'},
{ codigo: 'Q769', descripcion: 'MALFORMACION CONGENITA DEL TORAX OSEO, NO ESPECIFICADA'},
{ codigo: 'Q77', descripcion: 'OSTEOCONDRODISPLASIA CON DEFECTO DEL CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL'},
{ codigo: 'Q770', descripcion: 'ACONDROGENESIS'},
{ codigo: 'Q771', descripcion: 'ENANISMO TANATOFORICO'},
{ codigo: 'Q772', descripcion: 'SINDROME DE COSTILLA CORTA'},
{ codigo: 'Q773', descripcion: 'CONDRODISPLASIA PUNCTATA'},
{ codigo: 'Q774', descripcion: 'ACONDROPLASIA'},
{ codigo: 'Q775', descripcion: 'DISPLASIA DISTROFICA'},
{ codigo: 'Q776', descripcion: 'DISPLASIA CONDROECTODERMICA'},
{ codigo: 'Q777', descripcion: 'DISPLASIA ESPONDILOEPIFISARIA'},
{ codigo: 'Q778', descripcion: 'OTRAS OSTEOCONDRODISPLASIAS CON DEFECTOS DEL CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL'},
{ codigo: 'Q779', descripcion: 'OSTEOCONDRODISPLASIA CON DEFECTOS DE CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q78', descripcion: 'OTRAS OSTEOCONDRODISPLASIAS'},
{ codigo: 'Q780', descripcion: 'OSTEOGENESIS IMPERFECTA'},
{ codigo: 'Q781', descripcion: 'DISPLASIA POLIOSTOTICA FIBROSA'},
{ codigo: 'Q782', descripcion: 'OSTEOPETROSIS'},
{ codigo: 'Q783', descripcion: 'DISPLASIA DIAFISARIA PROGRESIVA'},
{ codigo: 'Q784', descripcion: 'ENCONDROMATOSIS'},
{ codigo: 'Q785', descripcion: 'DISPLASIA METAFISARIA'},
{ codigo: 'Q786', descripcion: 'EXOSTOSIS CONGENITA MULTIPLE'},
{ codigo: 'Q788', descripcion: 'OTRAS OSTEOCONDRODISPLASIAS ESPECIFICADAS'},
{ codigo: 'Q789', descripcion: 'OSTEOCONDRODISPLASIA, NO ESPECIFICADA'},
{ codigo: 'Q79', descripcion: 'MALFORMACIONES CONGENITAS DEL SISTEMA OSTEOMUSCULAR, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q790', descripcion: 'HERNIA DIAFRAGMATICA CONGENITA'},
{ codigo: 'Q791', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL DIAFRAGMA'},
{ codigo: 'Q792', descripcion: 'EXONFALOS'},
{ codigo: 'Q793', descripcion: 'GASTROSQUISIS'},
{ codigo: 'Q794', descripcion: 'SINDROME DEL ABDOMEN EN CIRUELA PASA'},
{ codigo: 'Q795', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA PARED ABDOMINAL'},
{ codigo: 'Q796', descripcion: 'SINDROME DE EHLERS-DANLOS'},
{ codigo: 'Q798', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA OSTEOMUSCULAR'},
{ codigo: 'Q799', descripcion: 'MALFORMACION CONGENITA DEL SISTEMA OSTEOMUSCULAR, NO ESPECIFICADA'},
{ codigo: 'Q80', descripcion: 'ICTIOSIS CONGENITA'},
{ codigo: 'Q800', descripcion: 'ICTIOSIS VULGAR'},
{ codigo: 'Q801', descripcion: 'ICTIOSIS LIGADA AL CROMOSOMA X'},
{ codigo: 'Q802', descripcion: 'ICTIOSIS LAMELAR'},
{ codigo: 'Q803', descripcion: 'ERITRODERMIA ICTIOSIFORME VESICULAR CONGENITA'},
{ codigo: 'Q804', descripcion: 'FETO ARLEQUIN'},
{ codigo: 'Q808', descripcion: 'OTRAS ICTIOSIS CONGENITAS'},
{ codigo: 'Q809', descripcion: 'ICTIOSIS CONGENITA, NO ESPECIFICADA'},
{ codigo: 'Q81', descripcion: 'EPIDERMOLISIS BULLOSA'},
{ codigo: 'Q810', descripcion: 'EPIDERMOLISIS BULLOSA SIMPLE'},
{ codigo: 'Q811', descripcion: 'EPIDERMOLISIS BULLOSA LETAL'},
{ codigo: 'Q812', descripcion: 'EPIDERMOLISIS BULLOSA DISTROFICA'},
{ codigo: 'Q818', descripcion: 'OTRAS EPIDERMOLISIS BULLOSAS'},
{ codigo: 'Q819', descripcion: 'EPIDERMOLISIS BULLOSA, NO ESPECIFICADA'},
{ codigo: 'Q82', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA PIEL'},
{ codigo: 'Q820', descripcion: 'LINFEDEMA HEREDITARIO'},
{ codigo: 'Q821', descripcion: 'XERODERMA PIGMENTOSO'},
{ codigo: 'Q822', descripcion: 'MASTOCITOSIS'},
{ codigo: 'Q823', descripcion: 'INCONTINENCIA PIGMENTARIA'},
{ codigo: 'Q824', descripcion: 'DISPLASIA ECTODERMICA (ANHIDROTICA)'},
{ codigo: 'Q825', descripcion: 'NEVO NO NEOPLASICO, CONGENITO'},
{ codigo: 'Q828', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA PIEL, ESPECIFICADAS'},
{ codigo: 'Q829', descripcion: 'MALFORMACION CONGENITA DE LA PIEL, NO ESPECIFICADA'},
{ codigo: 'Q83', descripcion: 'MALFORMACIONES CONGENITAS DE LA MAMA'},
{ codigo: 'Q830', descripcion: 'AUSENCIA CONGENITA DE LA MAMA CON AUSENCIA DEL PEZON'},
{ codigo: 'Q831', descripcion: 'MAMA SUPERNUMERARIA'},
{ codigo: 'Q832', descripcion: 'AUSENCIA DE PEZON'},
{ codigo: 'Q833', descripcion: 'PEZON SUPERNUMERARIO'},
{ codigo: 'Q838', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LA MAMA'},
{ codigo: 'Q839', descripcion: 'MALFORMACION CONGENITA DE LA MAMA, NO ESPECIFICADA'},
{ codigo: 'Q84', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LAS FANERAS'},
{ codigo: 'Q840', descripcion: 'ALOPECIA CONGENITA'},
{ codigo: 'Q841', descripcion: 'ALTERACIONES MORFOLOGICAS CONGENITAS DEL PELO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q842', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DEL PELO'},
{ codigo: 'Q843', descripcion: 'ANONIQUIA'},
{ codigo: 'Q844', descripcion: 'LEUCONIQUIA CONGENITA'},
{ codigo: 'Q845', descripcion: 'AGRANDAMIENTO E HIPERTROFIA DE LAS U—AS'},
{ codigo: 'Q846', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LAS U—AS'},
{ codigo: 'Q848', descripcion: 'OTRAS MALFORMACIONES CONGENITAS DE LAS FANERAS, ESPECIFICADAS'},
{ codigo: 'Q849', descripcion: 'MALFORMACION CONGENITA DE LAS FANERAS, NO ESPECIFICADA'},
{ codigo: 'Q85', descripcion: 'FACOMATOSIS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'Q850', descripcion: 'NEUROFIBROMATOSIS (NO MALIGNA)'},
{ codigo: 'Q851', descripcion: 'ESCLEROSIS TUBEROSA'},
{ codigo: 'Q858', descripcion: 'OTRAS FACOMATOSIS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Q859', descripcion: 'FACOMATOSIS, NO ESPECIFICADA'},
{ codigo: 'Q86', descripcion: 'SINDROMES DE MALFORMACIONES CONGENITAS POR CAUSAS EXOGENAS CONOCIDAS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q860', descripcion: 'SINDROME FETAL (DISMORFICO) DEBIDO AL ALCOHOL'},
{ codigo: 'Q861', descripcion: 'SINDROME DE HIDANTOINA FETAL'},
{ codigo: 'Q862', descripcion: 'DISMORFISMO DEBIDO A WARFARINA'},
{ codigo: 'Q868', descripcion: 'OTROS SINDROMES DE MALFORMACIONES CONGENITAS DEBIDOS A CAUSAS EXOGENAS CONOCIDAS'},
{ codigo: 'Q87', descripcion: 'OTROS SINDROMES DE MALFORMACIONES CONGENITAS ESPECIFICADOS QUE AFECTAN MULTIPLES SISTEMAS'},
{ codigo: 'Q870', descripcion: 'SINDROMES DE MALFORMACIONES CONGENITAS QUE AFECTAN PRINCIPALMENTE LA APARIENCIA FACIAL'},
{ codigo: 'Q871', descripcion: 'SINDROMES DE MALFORMACIONES CONGENITAS ASOCIADAS PRINCIPALMENTE CON ESTATURA BAJA'},
{ codigo: 'Q872', descripcion: 'SINDROMES DE MALFORMACIONES CONGENITAS QUE AFECTAN PRINCIPALMENTE LOS MIEMBROS'},
{ codigo: 'Q873', descripcion: 'SINDROMES DE MALFORMACIONES CONGENITAS CON EXCESO DE CRECIMIENTO PRECOZ'},
{ codigo: 'Q874', descripcion: 'SINDROME DE MARFAN'},
{ codigo: 'Q875', descripcion: 'OTROS SINDROMES DE MALFORMACIONES CONGENITAS CON OTROS CAMBIOS ESQUELETICOS'},
{ codigo: 'Q878', descripcion: 'OTROS SINDROMES DE MALFORMACIONES CONGENITAS ESPECIFICADOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q89', descripcion: 'OTRAS MALFORMACIONES CONGENITAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Q890', descripcion: 'MALFORMACIONES CONGENITAS DEL BAZO'},
{ codigo: 'Q891', descripcion: 'MALFORMACIONES CONGENITAS DE LA GLANDULA SUPRARRENAL'},
{ codigo: 'Q892', descripcion: 'MALFORMACIONES CONGENITAS DE OTRAS GLANDULAS ENDOCRINAS'},
{ codigo: 'Q893', descripcion: 'SITUS INVERSUS'},
{ codigo: 'Q894', descripcion: 'GEMELOS SIAMESES'},
{ codigo: 'Q897', descripcion: 'MALFORMACIONES CONGENITAS MULTIPLES, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Q898', descripcion: 'OTRAS MALFORMACIONES CONGENITAS, ESPECIFICADAS'},
{ codigo: 'Q899', descripcion: 'MALFORMACION CONGENITA, NO ESPECIFICADA'},
{ codigo: 'Q90', descripcion: 'SINDROME DE DOWN'},
{ codigo: 'Q900', descripcion: 'TRISOMIA 21, POR FALTA DE DISYUNCION MEIOTICA'},
{ codigo: 'Q901', descripcion: 'TRISOMIA 21, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)'},
{ codigo: 'Q902', descripcion: 'TRISOMIA 21, POR TRANSLOCACION'},
{ codigo: 'Q909', descripcion: 'SINDROME DE DOWN, NO ESPECIFICADO'},
{ codigo: 'Q91', descripcion: 'SINDROME DE EDWARDS Y SINDROME DE PATAU'},
{ codigo: 'Q910', descripcion: 'TRISOMIA 18, POR FALTA DE DISYUNCION MEIOTICA'},
{ codigo: 'Q911', descripcion: 'TRISOMIA 18, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)'},
{ codigo: 'Q912', descripcion: 'TRISOMIA 18, POR TRANSLOCACION'},
{ codigo: 'Q913', descripcion: 'SINDROME DE EDWARDS, NO ESPECIFICADO'},
{ codigo: 'Q914', descripcion: 'TRISOMIA 13, POR FALTA DE DISYUNCION MEIOTICA'},
{ codigo: 'Q915', descripcion: 'TRISOMIA 13, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)'},
{ codigo: 'Q916', descripcion: 'TRISOMIA 13, POR TRANSLOCACION'},
{ codigo: 'Q917', descripcion: 'SINDROME DE PATAU, NO ESPECIFICADO'},
{ codigo: 'Q92', descripcion: 'OTRAS TRISOMIAS Y TRISOMIAS PARCIALES DE LOS AUTOSOMAS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q920', descripcion: 'TRISOMIA DE UN CROMOSOMA COMPLETO, POR FALTA DE DISYUNCION MEIOTICA'},
{ codigo: 'Q921', descripcion: 'TRISOMIA DE CROMOSOMA COMPLETO, MOSAICO (FALTA DE DISYUNCION MITOTICA)'},
{ codigo: 'Q922', descripcion: 'TRISOMIA PARCIAL MAYOR'},
{ codigo: 'Q923', descripcion: 'TRISOMIA PARCIAL MENOR'},
{ codigo: 'Q924', descripcion: 'DUPLICACIONES VISIBLES SOLO EN LA PROMETAFASE'},
{ codigo: 'Q925', descripcion: 'DUPLICACIONES CON OTROS REORDENAMIENTOS COMPLEJOS'},
{ codigo: 'Q926', descripcion: 'CROMOSOMAS MARCADORES SUPLEMENTARIOS'},
{ codigo: 'Q927', descripcion: 'TRIPLOIDIA Y POLIPLOIDIA'},
{ codigo: 'Q928', descripcion: 'OTRAS TRISOMIAS Y TRISOMIAS PARCIALES DE LOS AUTOSOMAS, ESPECIFICADAS'},
{ codigo: 'Q929', descripcion: 'TRISOMIA Y TRISOMIA PARCIAL DE LOS AUTOSOMAS, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q93', descripcion: 'MONOSOMIAS Y SUPRESIONES DE LOS AUTOSOMAS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q930', descripcion: 'MONOSOMIA COMPLETA DE UN CROMOSOMA, POR FALTA DE DISYUNCION MEIOTICA'},
{ codigo: 'Q931', descripcion: 'MONOSOMIA COMPLETA DE CROMOSOMA, MOSAICO (FALTA DE DISYUNCION MITOTICA)'},
{ codigo: 'Q932', descripcion: 'CROMOSOMA REEMPLAZADO POR ANILLO O DICENTRICO'},
{ codigo: 'Q933', descripcion: 'SUPRESION DEL BRAZO CORTO DEL CROMOSOMA 4'},
{ codigo: 'Q934', descripcion: 'SUPRESION DEL BRAZO CORTO DEL CROMOSOMA 5'},
{ codigo: 'Q935', descripcion: 'OTRAS SUPRESIONES DE PARTE DE UN CROMOSOMA'},
{ codigo: 'Q936', descripcion: 'SUPRESIONES VISIBLES SOLO EN LA PROMETAFASE'},
{ codigo: 'Q937', descripcion: 'SUPRESIONES CON OTROS REORDENAMIENTOS COMPLEJOS'},
{ codigo: 'Q938', descripcion: 'OTRAS SUPRESIONES DE LOS AUTOSOMAS'},
{ codigo: 'Q939', descripcion: 'SUPRESION DE LOS AUTOSOMAS, NO ESPECIFICADA'},
{ codigo: 'Q95', descripcion: 'REORDENAMIENTOS EQUILIBRADOS Y MARCADORES ESTRUCTURALES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q950', descripcion: 'TRANSLOCACION EQUILIBRADA E INSERCION EN INDIVIDUO NORMAL'},
{ codigo: 'Q951', descripcion: 'INVERSION CROMOSOMICA EN INDIVIDUO NORMAL'},
{ codigo: 'Q952', descripcion: 'REORDENAMIENTO AUTOSOMICO EQUILIBRADO EN INDIVIDUO ANORMAL'},
{ codigo: 'Q953', descripcion: 'REORDENAMIENTO AUTOSOMICO/SEXUAL EQUILIBRADO EN INDIVIDUO ANORMAL'},
{ codigo: 'Q954', descripcion: 'INDIVIDUOS CON HETEROCROMATINA MARCADORA'},
{ codigo: 'Q955', descripcion: 'INDIVIDUOS CON SITIO FRAGIL AUTOSOMICO'},
{ codigo: 'Q958', descripcion: 'OTROS REORDENAMIENTOS EQUILIBRADOS Y MARCADORES ESTRUCTURALES'},
{ codigo: 'Q959', descripcion: 'REORDENAMIENTO EQUILIBRADO Y MARCADOR ESTRUCTURAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q96', descripcion: 'SINDROME DE TURNER'},
{ codigo: 'Q960', descripcion: 'CARIOTIPO 45,X'},
{ codigo: 'Q961', descripcion: 'CARIOTIPO 46,X ISO (XQ)'},
{ codigo: 'Q962', descripcion: 'CARIOTIPO 46,X CON CROMOSOMA SEXUAL ANORMAL EXCEPTO ISO (XQ)'},
{ codigo: 'Q963', descripcion: 'MOSAICO 45,X/46,XX O XY'},
{ codigo: 'Q964', descripcion: 'MOSAICO 45,X/OTRA(S) LINEA(S) CELULAR(ES) CON CROMOSOMA SEXUAL ANORMAL'},
{ codigo: 'Q968', descripcion: 'OTRAS VARIANTES DEL SINDROME DE TURNER'},
{ codigo: 'Q969', descripcion: 'SINDROME DE TURNER, NO ESPECIFICADO'},
{ codigo: 'Q97', descripcion: 'OTRAS ANOMALIAS DE CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q970', descripcion: 'CARIOTIPO 47,XXX'},
{ codigo: 'Q971', descripcion: 'MUJER CON MAS DE TRES CROMOSOMAS X'},
{ codigo: 'Q972', descripcion: 'MOSAICO, LINEAS CON NUMERO VARIABLE DE CROMOSOMAS X'},
{ codigo: 'Q973', descripcion: 'MUJER CON CARIOTIPO 46,XY'},
{ codigo: 'Q978', descripcion: 'OTRAS ANOMALIAS DE CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, ESPECIFICADAS'},
{ codigo: 'Q979', descripcion: 'ANOMALIA DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q98', descripcion: 'OTRAS ANOMALIAS DE CROMOSOMAS SEXUALES CON FENOTIPO MASCULINO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Q980', descripcion: 'SINDROME DE KLINEFELTER, CARIOTIPO 47,XXY'},
{ codigo: 'Q981', descripcion: 'SINDROME DE KLINEFELTER, HOMBRE CON MAS DE DOS CROMOSOMAS X'},
{ codigo: 'Q982', descripcion: 'SINDROME DE KLINEFELTER, HOMBRE CON CARIOTIPO 46,XX'},
{ codigo: 'Q983', descripcion: 'OTRO HOMBRE CON CARIOTIPO 46,XX'},
{ codigo: 'Q984', descripcion: 'SINDROME DE KLINEFELTER, NO ESPECIFICADO'},
{ codigo: 'Q985', descripcion: 'CARIOTIPO 47,XYY'},
{ codigo: 'Q986', descripcion: 'HOMBRE CON CROMOSOMA SEXUAL ESTRUCTURALMENTE ANORMAL'},
{ codigo: 'Q987', descripcion: 'HOMBRE CON MOSAICO DE CROMOSOMAS SEXUALES'},
{ codigo: 'Q988', descripcion: 'OTRAS ANOMALIAS DE CROMOSOMAS SEXUALES, CON FENOTIPO MASCULINO, ESPECIFICADAS'},
{ codigo: 'Q989', descripcion: 'ANOMALIA DE LOS CROMOSOMAS SEXUALES, FENOTIPO MASCULINO, SIN OTRA ESPECIFICACION'},
{ codigo: 'Q99', descripcion: 'OTRAS ANOMALIAS CROMOSOMICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Q990', descripcion: 'QUIMERA 46,XX/46,XY'},
{ codigo: 'Q991', descripcion: 'HERMAFRODITA VERDADERO 46,XX'},
{ codigo: 'Q992', descripcion: 'CROMOSOMA X FRAGIL'},
{ codigo: 'Q998', descripcion: 'OTRAS ANOMALIAS DE LOS CROMOSOMAS, ESPECIFICADAS'},
{ codigo: 'Q999', descripcion: 'ANOMALIA CROMOSOMICA, NO ESPECIFICADA'},
{ codigo: 'R00', descripcion: 'ANORMALIDADES DEL LATIDO CARDIACO'},
{ codigo: 'R000', descripcion: 'TAQUICARDIA, NO ESPECIFICADA'},
{ codigo: 'R001', descripcion: 'BRADICARDIA, NO ESPECIFICADA'},
{ codigo: 'R002', descripcion: 'PALPITACIONES'},
{ codigo: 'R008', descripcion: 'OTRAS ANORMALIDADES DEL LATIDO CARDIACO Y LAS NO ESPECIFICADAS'},
{ codigo: 'R01', descripcion: 'SOPLOS Y OTROS SONIDOS CARDIACOS'},
{ codigo: 'R010', descripcion: 'SOPLOS CARDIACOS BENIGNOS O INOCENTES'},
{ codigo: 'R011', descripcion: 'SOPLO CARDIACO, NO ESPECIFICADO'},
{ codigo: 'R012', descripcion: 'OTROS SONIDOS CARDIACOS'},
{ codigo: 'R02', descripcion: 'GANGRENA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'R03', descripcion: 'LECTURA DE PRESION SANGUINEA ANORMAL, SIN DIAGNOSTICO'},
{ codigo: 'R030', descripcion: 'LECTURA ELEVADA DE LA PRESION SANGUINEA SIN DIAGNOSTICO DE HIPERTENSION'},
{ codigo: 'R031', descripcion: 'LECTURA DE PRESION BAJA NO ESPECIFICA'},
{ codigo: 'R04', descripcion: 'HEMORRAGIAS DE LAS VIAS RESPIRATORIAS'},
{ codigo: 'R040', descripcion: 'EPISTAXIS'},
{ codigo: 'R041', descripcion: 'HEMORRAGIA DE LA GARGANTA'},
{ codigo: 'R042', descripcion: 'HEMOPTISIS'},
{ codigo: 'R048', descripcion: 'HEMORRAGIA DE OTROS SITIOS DE LAS VIAS RESPIRATORIAS'},
{ codigo: 'R049', descripcion: 'HEMORRAGIA DE LAS VIAS RESPIRATORIAS, NO ESPECIFICADA'},
{ codigo: 'R05', descripcion: 'TOS'},
{ codigo: 'R06', descripcion: 'ANORMALIDADES DE LA RESPIRACION'},
{ codigo: 'R060', descripcion: 'DISNEA'},
{ codigo: 'R061', descripcion: 'ESTRIDOR'},
{ codigo: 'R062', descripcion: 'SILBIDO'},
{ codigo: 'R063', descripcion: 'RESPIRACION PERIODICA'},
{ codigo: 'R064', descripcion: 'HIPERVENTILACION'},
{ codigo: 'R065', descripcion: 'RESPIRACION CON LA BOCA'},
{ codigo: 'R066', descripcion: 'HIPO'},
{ codigo: 'R067', descripcion: 'ESTORNUDO'},
{ codigo: 'R068', descripcion: 'OTRAS ANORMALIDADES DE LA RESPIRACION Y LAS NO ESPECIFICADAS'},
{ codigo: 'R07', descripcion: 'DOLOR DE GARGANTA Y EN EL PECHO'},
{ codigo: 'R070', descripcion: 'DOLOR DE GARGANTA'},
{ codigo: 'R071', descripcion: 'DOLOR EN EL PECHO AL RESPIRAR'},
{ codigo: 'R072', descripcion: 'DOLOR PRECORDIAL'},
{ codigo: 'R073', descripcion: 'OTROS DOLORES EN EL PECHO'},
{ codigo: 'R074', descripcion: 'DOLOR EN EL PECHO, NO ESPECIFICADO'},
{ codigo: 'R09', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LOS SISTEMAS CIRCULATORIO Y RESPIRATORIO'},
{ codigo: 'R090', descripcion: 'ASFIXIA'},
{ codigo: 'R091', descripcion: 'PLEURESIA'},
{ codigo: 'R092', descripcion: 'PARO RESPIRATORIO'},
{ codigo: 'R093', descripcion: 'ESPUTO ANORMAL'},
{ codigo: 'R098', descripcion: 'OTROS SINTOMAS Y SIGNOS ESPECIFICADOS QUE INVOLUCRAN LOS SISTEMAS CIRCULATORIO Y RESPIRATORIO'},
{ codigo: 'R10', descripcion: 'DOLOR ABDOMINAL Y PELVICO'},
{ codigo: 'R100', descripcion: 'ABDOMEN AGUDO'},
{ codigo: 'R101', descripcion: 'DOLOR ABDOMINAL LOCALIZADO EN PARTE SUPERIOR'},
{ codigo: 'R102', descripcion: 'DOLOR PELVICO Y PERINEAL'},
{ codigo: 'R103', descripcion: 'DOLOR LOCALIZADO EN OTRAS PARTES INFERIORES DEL ABDOMEN'},
{ codigo: 'R104', descripcion: 'OTROS DOLORES ABDOMINALES Y LOS NO ESPECIFICADOS'},
{ codigo: 'R11', descripcion: 'NAUSEA Y VOMITO'},
{ codigo: 'R12', descripcion: 'ACIDEZ'},
{ codigo: 'R13', descripcion: 'DISFAGIA'},
{ codigo: 'R14', descripcion: 'FLATULENCIA Y AFECCIONES AFINES'},
{ codigo: 'R15', descripcion: 'INCONTINENCIA FECAL'},
{ codigo: 'R16', descripcion: 'HEPATOMEGALIA Y ESPLENOMEGALIA, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'R160', descripcion: 'HEPATOMEGALIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'R161', descripcion: 'ESPLENOMEGALIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'R162', descripcion: 'HEPATOMEGALIA CON ESPLENOMEGALIA, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'R17', descripcion: 'ICTERICIA NO ESPECIFICADA'},
{ codigo: 'R18', descripcion: 'ASCITIS'},
{ codigo: 'R19', descripcion: 'OTR SINTOMAS Y SIGNOS QUE INVOLUCRAN EL SISTEMA DIGESTIVO Y EL ABDOMEN'},
{ codigo: 'R190', descripcion: 'TUMEFACCION, MASA O PROMINENCIA INTRAABDOMINAL Y PELVICA'},
{ codigo: 'R191', descripcion: 'SONIDOS INTESTINALES ANORMALES'},
{ codigo: 'R192', descripcion: 'PERISTALSIS VISIBLE'},
{ codigo: 'R193', descripcion: 'RIGIDEZ ABDOMINAL'},
{ codigo: 'R194', descripcion: 'CAMBIOS EN LOS HABITOS INTESTINALES'},
{ codigo: 'R195', descripcion: 'OTRAS ANORMALIDADES FECALES'},
{ codigo: 'R196', descripcion: 'HALITOSIS'},
{ codigo: 'R198', descripcion: 'OTROS SINTOMAS Y SIGNOS ESPECIFICADOS QUE INVOLUCRAN EL SISTEMA DIGESTIVO Y ABDOMEN'},
{ codigo: 'R20', descripcion: 'ALTERACIONES DE LA SENSIBILIDAD CUTANEA'},
{ codigo: 'R200', descripcion: 'ANESTESIA DE LA PIEL'},
{ codigo: 'R201', descripcion: 'HIPOESTESIA DE LA PIEL'},
{ codigo: 'R202', descripcion: 'PARESTESIA DE LA PIEL'},
{ codigo: 'R203', descripcion: 'HIPERESTESIA'},
{ codigo: 'R208', descripcion: 'OTRAS ALTERACIONES DE LA SENSIBILIDAD CUTANEA Y LAS NO ESPECIFICADAS'},
{ codigo: 'R21', descripcion: 'SALPULLIDO Y OTRAS ERUPCIONES CUTANEAS NO ESPECIFICADAS'},
{ codigo: 'R22', descripcion: 'TUMEFACCION, MASA O PROMINENCIA DE PIEL Y DEL TEJIDO SUBCUTANEO LOCALIZADAS'},
{ codigo: 'R220', descripcion: 'TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN LA CABEZA'},
{ codigo: 'R221', descripcion: 'TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL CUELLO'},
{ codigo: 'R222', descripcion: 'TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL TRONCO'},
{ codigo: 'R223', descripcion: 'TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL MIEMBRO SUPERIOR'},
{ codigo: 'R224', descripcion: 'TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL MIEMBRO INFERIOR'},
{ codigo: 'R227', descripcion: 'TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN SITIOS MULTIPLES'},
{ codigo: 'R229', descripcion: 'TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN PARTE NO ESPECIFICADA'},
{ codigo: 'R23', descripcion: 'OTROS CAMBIOS EN LA PIEL'},
{ codigo: 'R230', descripcion: 'CIANOSIS'},
{ codigo: 'R231', descripcion: 'PALIDEZ'},
{ codigo: 'R232', descripcion: 'RUBOR'},
{ codigo: 'R233', descripcion: 'EQUIMOSIS ESPONTANEA'},
{ codigo: 'R234', descripcion: 'CAMBIOS EN LA TEXTURA DE LA PIEL'},
{ codigo: 'R238', descripcion: 'OTROS CAMBIOS DE LA PIEL Y LOS NO ESPECIFICADOS'},
{ codigo: 'R25', descripcion: 'MOVIMIENTOS INVOLUNTARIOS ANORMALES'},
{ codigo: 'R250', descripcion: 'MOVIMIENTOS ANORMALES DE LA CABEZA'},
{ codigo: 'R251', descripcion: 'TEMBLOR NO ESPECIFICADO'},
{ codigo: 'R252', descripcion: 'CALAMBRES Y ESPASMOS'},
{ codigo: 'R253', descripcion: 'FASCICULACION'},
{ codigo: 'R258', descripcion: 'OTROS MOVIMIENTOS ANORMALES INVOLUNTARIOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'R26', descripcion: 'ANORMALIDADES DE LA MARCHA Y DE LA MOVILIDAD'},
{ codigo: 'R260', descripcion: 'MARCHA ATAXICA'},
{ codigo: 'R261', descripcion: 'MARCHA PARALITICA'},
{ codigo: 'R262', descripcion: 'DIFICULTAD PARA CAMINAR, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'R268', descripcion: 'OTRAS ANORMALIDADES DE LA MARCHA Y DE LA MOVILIDAD Y LAS NO ESPECIFICADAS'},
{ codigo: 'R27', descripcion: 'OTRAS FALLAS DE COORDINACION'},
{ codigo: 'R270', descripcion: 'ATAXIA, NO ESPECIFICADA'},
{ codigo: 'R278', descripcion: 'OTRAS FALLAS DE LA COORDINACION Y LAS NO ESPECIFICADAS'},
{ codigo: 'R29', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LOS SISTEMAS NERVIOSO Y OSTEOMUSCULAR'},
{ codigo: 'R290', descripcion: 'TETANIA'},
{ codigo: 'R291', descripcion: 'MENINGISMO'},
{ codigo: 'R292', descripcion: 'REFLEJOS ANORMALES'},
{ codigo: 'R293', descripcion: 'POSTURA ANORMAL'},
{ codigo: 'R294', descripcion: 'CHASQUIDO DE LA CADERA'},
{ codigo: 'R298', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LOS SISTEMAS NERVIOSO Y OSTEOMUSCULAR Y LOS NO ESPECIFICADOS'},
{ codigo: 'R30', descripcion: 'DOLOR ASOCIADO CON LA MICCION'},
{ codigo: 'R300', descripcion: 'DISURIA'},
{ codigo: 'R301', descripcion: 'TENESMO VESICAL'},
{ codigo: 'R309', descripcion: 'MICCION DOLOROSA, NO ESPECIFICADA'},
{ codigo: 'R31', descripcion: 'HEMATURIA, NO ESPECIFICADA'},
{ codigo: 'R32', descripcion: 'INCONTINENCIA URINARIA, NO ESPECIFICADA'},
{ codigo: 'R33', descripcion: 'RETENCION DE ORINA'},
{ codigo: 'R34', descripcion: 'ANURIA Y OLIGURIA'},
{ codigo: 'R35', descripcion: 'POLIURIA'},
{ codigo: 'R36', descripcion: 'DESCARGA URETRAL'},
{ codigo: 'R39', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN EL SISTEMA URINARIO'},
{ codigo: 'R390', descripcion: 'EXTRAVASACION DE LA ORINA'},
{ codigo: 'R391', descripcion: 'OTRAS DIFICULTADES DE LA MICCION'},
{ codigo: 'R392', descripcion: 'UREMIA EXTRARRENAL'},
{ codigo: 'R398', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN EL SISTEMA URINARIO Y LOS NO ESPECIFICADOS'},
{ codigo: 'R40', descripcion: 'SOMNOLENCIA, ESTUPOR Y COMA'},
{ codigo: 'R400', descripcion: 'SOMNOLENCIA'},
{ codigo: 'R401', descripcion: 'ESTUPOR'},
{ codigo: 'R402', descripcion: 'COMA, NO ESPECIFICADO'},
{ codigo: 'R41', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LA FUNCION COGNOSCITIVA Y LA CONCIENCIA'},
{ codigo: 'R410', descripcion: 'DESORIENTACION NO ESPECIFICADA'},
{ codigo: 'R411', descripcion: 'AMNESIA ANTEROGRADA'},
{ codigo: 'R412', descripcion: 'AMNESIA RETROGRADA'},
{ codigo: 'R413', descripcion: 'OTRA AMNESIA'},
{ codigo: 'R418', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LA FUNCION COGNOSCITIVA Y LA CONCIENCIA Y LOS NO ESPECIFICADOS'},
{ codigo: 'R42', descripcion: 'MAREO Y DESVANECIMIENTO'},
{ codigo: 'R43', descripcion: 'TRASTORNOS DEL OLFATO Y DEL GUSTO'},
{ codigo: 'R430', descripcion: 'ANOSMIA'},
{ codigo: 'R431', descripcion: 'PAROSMIA'},
{ codigo: 'R432', descripcion: 'PARAGEUSIA'},
{ codigo: 'R438', descripcion: 'OTRAS ALTERACIONES DEL GUSTO Y DEL OLFATO Y LAS NO ESPECIFICADAS'},
{ codigo: 'R44', descripcion: 'OTR SINTOMAS Y SIGNOS QUE INVOLUCRAN SENSACIONES Y PERCEPCIONES GENERALES'},
{ codigo: 'R440', descripcion: 'ALUCINACIONES AUDITIVAS'},
{ codigo: 'R441', descripcion: 'ALUCINACIONES VISUALES'},
{ codigo: 'R442', descripcion: 'OTRAS ALUCINACIONES'},
{ codigo: 'R443', descripcion: 'ALUCINACIONES, NO ESPECIFICADAS'},
{ codigo: 'R448', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LAS SENSACIONES Y PERCEPCIONES GENERALES Y LOS NO ESPECIFICADOS'},
{ codigo: 'R45', descripcion: 'SINTOMAS Y SIGNOS QUE INVOLUCRAN EL ESTADO EMOCIONAL'},
{ codigo: 'R450', descripcion: 'NERVIOSISMO'},
{ codigo: 'R451', descripcion: 'INQUIETUD Y AGITACION'},
{ codigo: 'R452', descripcion: 'INFELICIDAD'},
{ codigo: 'R453', descripcion: 'DESMORALIZACION Y APATIA'},
{ codigo: 'R454', descripcion: 'IRRITABILIDAD Y ENOJO'},
{ codigo: 'R455', descripcion: 'HOSTILIDAD'},
{ codigo: 'R456', descripcion: 'VIOLENCIA FISICA'},
{ codigo: 'R457', descripcion: 'TENSION Y ESTADO DE CHOQUE EMOCIONAL, NO ESPECIFICADO'},
{ codigo: 'R458', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN EL ESTADO EMOCIONAL'},
{ codigo: 'R46', descripcion: 'SINTOMAS Y SIGNOS QUE INVOLUCRAN LA APARIENCIA Y EL COMPORTAMIENTO'},
{ codigo: 'R460', descripcion: 'MUY BAJO NIVEL DE HIGIENE PERSONAL'},
{ codigo: 'R461', descripcion: 'APARIENCIA PERSONAL EXTRA—A'},
{ codigo: 'R462', descripcion: 'CONDUCTA EXTRA—A E INEXPLICABLE'},
{ codigo: 'R463', descripcion: 'HIPERACTIVIDAD'},
{ codigo: 'R464', descripcion: 'LENTITUD Y POBRE RESPUESTA'},
{ codigo: 'R465', descripcion: 'SUSPICACIA Y EVASIVIDAD MARCADAS'},
{ codigo: 'R466', descripcion: 'PREOCUPACION INDEBIDA POR SUCESOS QUE CAUSAN TENSION'},
{ codigo: 'R467', descripcion: 'VERBOSIDAD Y DETALLES CIRCUNSTANCIALES QUE OSCURECEN LA RAZON DE LA CONSULTA O EL CONTACTO'},
{ codigo: 'R468', descripcion: 'OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LA APARIENCIA Y EL COMPORTAMIENTO'},
{ codigo: 'R47', descripcion: 'ALTERACIONES DEL HABLA, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'R470', descripcion: 'DISFASIA Y AFASIA'},
{ codigo: 'R471', descripcion: 'DISARTRIA Y ANARTRIA'},
{ codigo: 'R478', descripcion: 'OTRAS ALTERACIONES DEL HABLA Y LAS NO ESPECIFICADAS'},
{ codigo: 'R48', descripcion: 'DISLEXIA Y OTRAS DISFUNCIONES SIMBOLICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'R480', descripcion: 'DISLEXIA Y ALEXIA'},
{ codigo: 'R481', descripcion: 'AGNOSIA'},
{ codigo: 'R482', descripcion: 'APRAXIA'},
{ codigo: 'R488', descripcion: 'OTRAS DISFUNCIONES SIMBOLICAS Y LAS NO ESPECIFICADAS'},
{ codigo: 'R49', descripcion: 'ALTERACIONES DE LA VOZ'},
{ codigo: 'R490', descripcion: 'DISFONIA'},
{ codigo: 'R491', descripcion: 'AFONIA'},
{ codigo: 'R492', descripcion: 'HIPERNASALIDAD E HIPONASALIDAD'},
{ codigo: 'R498', descripcion: 'OTRAS ALTERACIONES DE LA VOZ Y LAS NO ESPECIFICADAS'},
{ codigo: 'R50', descripcion: 'FIEBRE DE ORIGEN DESCONOCIDO'},
{ codigo: 'R500', descripcion: 'FIEBRE CON ESCALOFRIO'},
{ codigo: 'R501', descripcion: 'FIEBRE PERSISTENTE'},
{ codigo: 'R509', descripcion: 'FIEBRE, NO ESPECIFICADA'},
{ codigo: 'R51', descripcion: 'CEFALEA'},
{ codigo: 'R52', descripcion: 'DOLOR, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'R520', descripcion: 'DOLOR AGUDO'},
{ codigo: 'R521', descripcion: 'DOLOR CRONICO INTRATABLE'},
{ codigo: 'R522', descripcion: 'OTRO DOLOR CRONICO'},
{ codigo: 'R529', descripcion: 'DOLOR, NO ESPECIFICADO'},
{ codigo: 'R53', descripcion: 'MALESTAR Y FATIGA'},
{ codigo: 'R54', descripcion: 'SENILIDAD'},
{ codigo: 'R55', descripcion: 'SINCOPE Y COLAPSO'},
{ codigo: 'R56', descripcion: 'CONVULSIONES, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'R560', descripcion: 'CONVULSIONES FEBRILES'},
{ codigo: 'R568', descripcion: 'OTRAS CONVULSIONES Y LAS NO ESPECIFICADAS'},
{ codigo: 'R57', descripcion: 'CHOQUE, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'R570', descripcion: 'CHOQUE CARDIOGENICO'},
{ codigo: 'R571', descripcion: 'CHOQUE HIPOVOLEMICO'},
{ codigo: 'R578', descripcion: 'OTRAS FORMAS DE CHOQUE'},
{ codigo: 'R579', descripcion: 'CHOQUE, NO ESPECIFICADO'},
{ codigo: 'R58', descripcion: 'HEMORRAGIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'R59', descripcion: 'ADENOMEGALIA'},
{ codigo: 'R590', descripcion: 'ADENOMEGALIA LOCALIZADA'},
{ codigo: 'R591', descripcion: 'ADENOMEGALIA GENERALIZADA'},
{ codigo: 'R599', descripcion: 'ADENOMEGALIA, NO ESPECIFICADA'},
{ codigo: 'R60', descripcion: 'EDEMA, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'R600', descripcion: 'EDEMA LOCALIZADO'},
{ codigo: 'R601', descripcion: 'EDEMA GENERALIZADO'},
{ codigo: 'R609', descripcion: 'EDEMA, NO ESPECIFICADO'},
{ codigo: 'R61', descripcion: 'HIPERHIDROSIS'},
{ codigo: 'R610', descripcion: 'HIPERHIDROSIS LOCALIZADA'},
{ codigo: 'R611', descripcion: 'HIPERHIDROSIS GENERALIZADA'},
{ codigo: 'R619', descripcion: 'HIPERHIDROSIS, NO ESPECIFICADA'},
{ codigo: 'R62', descripcion: 'FALTA DEL DESARROLLO FISIOLOGICO NORMAL ESPERADO'},
{ codigo: 'R620', descripcion: 'RETARDO DEL DESARROLLO'},
{ codigo: 'R628', descripcion: 'OTRAS FALTAS DEL DESARROLLO FISIOLOGICO NORMAL ESPERADO'},
{ codigo: 'R629', descripcion: 'FALTA DEL DESARROLLO FISIOLOGICO NORMAL ESPERADO, SIN OTRA ESPECIFICACION'},
{ codigo: 'R63', descripcion: 'SINTOMAS Y SIGNOS CONCERNIENTES A LA ALIMENTACION Y A LA INGESTION DE LIQUIDOS'},
{ codigo: 'R630', descripcion: 'ANOREXIA'},
{ codigo: 'R631', descripcion: 'POLIDIPSIA'},
{ codigo: 'R632', descripcion: 'POLIFAGIA'},
{ codigo: 'R633', descripcion: 'DIFICULTADES Y MALA ADMINISTRACION DE LA ALIMENTACION'},
{ codigo: 'R634', descripcion: 'PERDIDA ANORMAL DE PESO'},
{ codigo: 'R635', descripcion: 'AUMENTO ANORMAL DE PESO'},
{ codigo: 'R638', descripcion: 'OTROS SINTOMAS Y SIGNOS CONCERNIENTES A LA ALIMENTACION Y INGESTION DE LIQUIDOS'},
{ codigo: 'R64', descripcion: 'CAQUEXIA'},
{ codigo: 'R68', descripcion: 'OTROS SINTOMAS Y SIGNOS GENERALES'},
{ codigo: 'R680', descripcion: 'HIPOTERMIA NO ASOCIADA CON BAJA TEMPERATURA DEL AMBIENTE'},
{ codigo: 'R681', descripcion: 'SINTOMAS NO ESPECIFICOS PROPIOS DE LA INFANCIA'},
{ codigo: 'R682', descripcion: 'BOCA SECA, NO ESPECIFICADA'},
{ codigo: 'R683', descripcion: 'DEDOS DE LA MANO DEFORMES'},
{ codigo: 'R688', descripcion: 'OTROS SINTOMAS Y SIGNOS GENERALES ESPECIFICADOS'},
{ codigo: 'R69', descripcion: 'CAUSAS DE MORBILIDAD DESCONOCIDAS Y NO ESPECIFICADAS'},
{ codigo: 'R70', descripcion: 'VELOCIDAD DE ERITROSEDIMENTACION ELEVADA Y OTRAS ANORMALIDADES DE LA VISCOSIDAD DEL PLASMA'},
{ codigo: 'R700', descripcion: 'VELOCIDAD DE ERITROSEDIMENTACION ELEVADA'},
{ codigo: 'R701', descripcion: 'VISCOSIDAD PLASMATICA ANORMAL'},
{ codigo: 'R71', descripcion: 'ANORMALIDAD DE LOS ERITROCITOS'},
{ codigo: 'R72', descripcion: 'ANORMALIDADES DE LOS LEUCOCITOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'R73', descripcion: 'NIVEL ELEVADO DE GLUCOSA EN SANGRE'},
{ codigo: 'R730', descripcion: 'ANORMALIDADES EN LA PRUEBA DE TOLERANCIA A LA GLUCOSA'},
{ codigo: 'R739', descripcion: 'HIPERGLICEMIA, NO ESPECIFICADA'},
{ codigo: 'R74', descripcion: 'NIVEL ANORMAL DE ENZIMAS EN SUERO'},
{ codigo: 'R740', descripcion: 'ELEVACION DE NIVELES DE TRANSAMINASAS O DESHIDROGENASA LACTICA (DHL)'},
{ codigo: 'R748', descripcion: 'NIVELES ANORMALES DE OTRAS ENZIMAS EN SUERO'},
{ codigo: 'R749', descripcion: 'NIVEL ANORMAL DE ENZIMAS EN SUERO, NO ESPECIFICADO'},
{ codigo: 'R75', descripcion: 'EVIDENCIAS DE LABORATORIO DEL VIRUS DE INMUNODEFICIENCIA HUMANA (VIH)'},
{ codigo: 'R76', descripcion: 'OTROS HALLAZGOS INMUNOLOGICOS ANORMALES EN SUERO'},
{ codigo: 'R760', descripcion: 'TITULACION ELEVADA DE ANTICUERPOS'},
{ codigo: 'R761', descripcion: 'REACCION ANORMAL A LA PRUEBA CON TUBERCULINA'},
{ codigo: 'R762', descripcion: 'FALSO POSITIVO EN LA PRUEBA SEROLOGICA PARA SIFILIS'},
{ codigo: 'R768', descripcion: 'OTROS HALLAZGOS INMUNOLOGICOS ANORMALES ESPECIFICADOS EN SUERO'},
{ codigo: 'R769', descripcion: 'HALLAZGOS INMUNOLOGICOS ANORMALES NO ESPECIFICADOS EN SUERO'},
{ codigo: 'R77', descripcion: 'OTRAS ANORMALIDADES DE LAS PROTEINAS PLASMATICAS'},
{ codigo: 'R770', descripcion: 'ANORMALIDAD DE LA ALBUMINA'},
{ codigo: 'R771', descripcion: 'ANORMALIDAD DE LA GLOBULINA'},
{ codigo: 'R772', descripcion: 'ANORMALIDAD DE LA ALFAFETOPROTEINA'},
{ codigo: 'R778', descripcion: 'OTRAS ANORMALIDADES ESPECIFICADAS DE LAS PROTEINAS PLASMATICAS'},
{ codigo: 'R779', descripcion: 'ANORMALIDADES NO ESPECIFICADAS DE LAS PROTEINAS PLASMATICAS'},
{ codigo: 'R78', descripcion: 'HALLAZGO DE DROGAS Y OTRAS SUSTANCIAS QUE NORMALMENTE NO SE ENCUENTRAN EN LA SANGRE'},
{ codigo: 'R780', descripcion: 'HALLAZGO DE ALCOHOL EN LA SANGRE'},
{ codigo: 'R781', descripcion: 'HALLAZGO DE DROGAS OPIACEAS EN LA SANGRE'},
{ codigo: 'R782', descripcion: 'HALLAZGO DE COCAINA EN LA SANGRE'},
{ codigo: 'R783', descripcion: 'HALLAZGO DE ALUCINOGENOS EN LA SANGRE'},
{ codigo: 'R784', descripcion: 'HALLAZGO DE OTRAS DROGAS POTENCIALMENTE ADICTIVAS EN LA SANGRE'},
{ codigo: 'R785', descripcion: 'HALLAZGO DE DROGAS PSICOTROPICAS EN LA SANGRE'},
{ codigo: 'R786', descripcion: 'HALLAZGO DE AGENTES ESTEROIDES EN LA SANGRE'},
{ codigo: 'R787', descripcion: 'HALLAZGO DE NIVELES ANORMALES DE METALES PESADOS EN LA SANGRE'},
{ codigo: 'R788', descripcion: 'HALLAZGO DE OTRAS SUSTANCIAS ESPECIFICDAS QUE NORMALMENTE NO SE ENCUENTRAN EN LA SANGRE'},
{ codigo: 'R789', descripcion: 'HALLAZGO DE SUSTANCIAS NO ESPECIFICADAS QUE NORMALMENTE NO SE ENCUENTRAN EN LA SANGRE'},
{ codigo: 'R79', descripcion: 'OTROS HALLAZGOS ANORMALES EN LA QUIMICA SANGUINEA'},
{ codigo: 'R790', descripcion: 'NIVEL ANORMAL DE MINERAL EN LA SANGRE'},
{ codigo: 'R798', descripcion: 'OTROS HALLAZGOS ANORMALES ESPECIFICADOS EN LA QUIMICA SANGUINEA'},
{ codigo: 'R799', descripcion: 'HALLAZGO ANORMAL EN LA QUIMICA SANGUINEA, SIN OTRA ESPECIFICACION'},
{ codigo: 'R80', descripcion: 'PROTEINURIA AISLADA'},
{ codigo: 'R81', descripcion: 'GLUCOSURIA'},
{ codigo: 'R82', descripcion: 'OTROS HALLAZGOS ANORMALES EN LA ORINA'},
{ codigo: 'R820', descripcion: 'QUILURIA'},
{ codigo: 'R821', descripcion: 'MIOGLOBINURIA'},
{ codigo: 'R822', descripcion: 'BILIURIA'},
{ codigo: 'R823', descripcion: 'HEMOGLOBINURIA'},
{ codigo: 'R824', descripcion: 'ACETONURIA'},
{ codigo: 'R825', descripcion: 'ELEVACION DE NIVELES DE DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS EN LA ORINA'},
{ codigo: 'R826', descripcion: 'NIVELES ANORMALES EN ORINA DE SUSTANCIAS DE ORIGEN PRINCIPALMENTE NO MEDICINAL'},
{ codigo: 'R827', descripcion: 'HALLAZGOS ANORMALES EN EL EXAMEN MICROBIOLOGICO DE LA ORINA'},
{ codigo: 'R828', descripcion: 'HALLAZGOS ANORMALES EN EL EXAMEN CITOLOGICO E HISTOLOGICO DE LA ORINA'},
{ codigo: 'R829', descripcion: 'OTROS HALLAZGOS ANORMALES EN LA ORINA Y LOS NO ESPECIFICADOS'},
{ codigo: 'R83', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO'},
{ codigo: 'R830', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, NIVEL ANORMAL DE ENZIMAS'},
{ codigo: 'R831', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, NIVEL ANORMAL DE HORMONAS'},
{ codigo: 'R832', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'R833', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL'},
{ codigo: 'R834', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, HALLAZGOS INMUNOLOGICOS ANORMALES'},
{ codigo: 'R835', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, HALLAZGOS MICROBIOLOGICOS ANORMALES Y POSITIVOS EN CULTIVO'},
{ codigo: 'R836', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, HALLAZGOS CITOLOGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU'},
{ codigo: 'R837', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, HALLAZGOS HISTOLOGICOS ANORMALES'},
{ codigo: 'R838', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES'},
{ codigo: 'R839', descripcion: 'HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO, HALLAZGOS ANORMALES, NO ESPECIFICADOS'},
{ codigo: 'R84', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS'},
{ codigo: 'R840', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, NIVEL ANORMAL DE ENZIMAS'},
{ codigo: 'R841', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, NIVEL ANORMAL HORMONAS'},
{ codigo: 'R842', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, NIVEL ANORMAL DE OTRAAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'R843', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL'},
{ codigo: 'R844', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, HALLAZGOS INMUNOLOGICOS ANORMALES'},
{ codigo: 'R845', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, HALLAZGOS MICROBIOLOGICOS ANORMALES Y POSITIVOS EN CULTIVO'},
{ codigo: 'R846', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, HALLAZGOS CITOLOGICOS ANORMALES Y FROTIS ANORMAL DE PAPANICOLAOU'},
{ codigo: 'R847', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, HALLAZGOS HISTOLOGICOS ANORMALES'},
{ codigo: 'R848', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES'},
{ codigo: 'R849', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS, HALLAZGOS ANORMALES, NO ESPECIFICADOS'},
{ codigo: 'R85', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL'},
{ codigo: 'R850', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, NIVEL ANORMAL ENZIMAS'},
{ codigo: 'R851', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, NIVEL ANORMAL DE HORMONAS'},
{ codigo: 'R852', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'R853', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL'},
{ codigo: 'R854', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, HALLAZGOS INMUNOLOGICOS ANORMALES'},
{ codigo: 'R855', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, HALLAZGOS MICROBIOLOGICOS ANORMALES Y POSITIVOS EN CULTIVO'},
{ codigo: 'R856', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, HALLAZGOS CITOLOGICOS ANORMALES Y FROTIS ANORMAL DE PAPANICOLAOU'},
{ codigo: 'R857', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, HALLAZGOS HISTOLOGICOS ANORMALES'},
{ codigo: 'R858', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES'},
{ codigo: 'R859', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y CAVIDAD ABDOMINAL, HALLAZGOS ANORMALES, NO ESPECIFICADOS'},
{ codigo: 'R86', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS'},
{ codigo: 'R860', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE ENZIMAS'},
{ codigo: 'R861', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE HORMONAS'},
{ codigo: 'R862', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE OTRAS DROGRAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'R863', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL'},
{ codigo: 'R864', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, HALLAZGOS INMUNOLOGICOS ANORMALES'},
{ codigo: 'R865', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, HALLAZGOS MICROBIOLOGICOS ANORMALES Y POSITIVOS EN CULTIVO'},
{ codigo: 'R866', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, HALLAZGOS CITOLOGICOS ANORMALES Y FROTIS ANORMAL DE PAPANICOLAOU'},
{ codigo: 'R867', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, HALLAZGOS HISTOLOGICOS ANORMALES'},
{ codigo: 'R868', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, OTR HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES'},
{ codigo: 'R869', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS, HALLAZGOS ANORMALES, NO ESPECIFICADOS'},
{ codigo: 'R87', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS'},
{ codigo: 'R870', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE ENZIMAS'},
{ codigo: 'R871', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE HORMONAS'},
{ codigo: 'R872', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'R873', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL'},
{ codigo: 'R874', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, HALLAZGOS INMUNOLOGICOS ANORMALES'},
{ codigo: 'R875', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, HALLAZGOS MICROBIOLOGICOS ANORMALES, POSITIVOS EN CULTIVO'},
{ codigo: 'R876', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, HALLAZGOS CITOLOGICOS ANORMALES Y FROTIS ANORMAL DE PAPANICOLAOU'},
{ codigo: 'R877', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, HALLAZGOS HISTOLOGICOS ANORMALES'},
{ codigo: 'R878', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES'},
{ codigo: 'R879', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS, HALLAZGOS ANORMALES, NO ESPECIFICADOS'},
{ codigo: 'R89', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS'},
{ codigo: 'R890', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL ENZIMAS'},
{ codigo: 'R891', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL HORMONAS'},
{ codigo: 'R892', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'R893', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL'},
{ codigo: 'R894', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS INMUNOLOGICOS ANORMALES'},
{ codigo: 'R895', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS MICROBIOLIGICOS ANORMALES Y POSITIVOS EN CULTIVO'},
{ codigo: 'R896', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS CITOLOGICOS ANORMALES Y FROTIS ANORMAL DE PAPANICOLAOU'},
{ codigo: 'R897', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS HISTOLOGICOS ANORMALES'},
{ codigo: 'R898', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, OTROS HALLAZGOSANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES'},
{ codigo: 'R899', descripcion: 'HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS, HALLAZGOS ANORMALES, NO ESPECIFICADOS'},
{ codigo: 'R90', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'R900', descripcion: 'LESION QUE OCUPA EL ESPACIO INTRACRANEAL'},
{ codigo: 'R908', descripcion: 'OTROS HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'R91', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL PULMON'},
{ codigo: 'R92', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE LA MAMA'},
{ codigo: 'R93', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS ESTRUCTURAS DEL CUERPO'},
{ codigo: 'R930', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL CRANEO Y DE LA CABEZA, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'R931', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE CORAZON Y CIRCULACION CORONARIA'},
{ codigo: 'R932', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL HIGADO Y VIAS BILIARES'},
{ codigo: 'R933', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS PARTES DE VIAS DIGESTIVAS'},
{ codigo: 'R934', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE LOS ORGANOS URINARIOS'},
{ codigo: 'R935', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS REGIONES ABDOMINALES, INCLUIDO RETROPERITONEO'},
{ codigo: 'R936', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE LOS MIEMBROS'},
{ codigo: 'R937', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS PARTES DEL SISTEMA OSTEOMUSCULAR'},
{ codigo: 'R938', descripcion: 'HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS ESTRUCTURAS ESPECIFICADAS DEL CUERPO'},
{ codigo: 'R94', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES'},
{ codigo: 'R940', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'R941', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL SISTEMA NERVIOSO PERIFERICO Y SENTIDOS ESPECIALES'},
{ codigo: 'R942', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL PULMON'},
{ codigo: 'R943', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES CARDIOVASCULARES'},
{ codigo: 'R944', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL RI—ON'},
{ codigo: 'R945', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL HIGADO'},
{ codigo: 'R946', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DE LA TIROIDES'},
{ codigo: 'R947', descripcion: 'RESULTADOS ANORMALES EN OTROS ESTUDIOS FUNCIONALES ENDOCRINOS'},
{ codigo: 'R948', descripcion: 'RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DE OTROS ORGANOS Y SISTEMAS'},
{ codigo: 'R95', descripcion: 'SINDROME DE LA MUERTE SUBITA INFANTIL'},
{ codigo: 'R96', descripcion: 'OTRAS MUERTES SUBITAS DE CAUSA DESCONOCIDA'},
{ codigo: 'R960', descripcion: 'MUERTE INSTANTANEA'},
{ codigo: 'R961', descripcion: 'MUERTE QUE OCURRE EN MENOS DE 24 HORAS DEL INICIO DE LOS SINTOMAS, NO EXPLICADA DE OTRA FORMA'},
{ codigo: 'R98', descripcion: 'MUERTE SIN ASISTENCIA'},
{ codigo: 'R99', descripcion: 'OTRAS CAUSAS MAL DEFINIDAS Y LAS NO ESPECIFICADAS DE MORTALIDAD'},
{ codigo: 'S00', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA CABEZA'},
{ codigo: 'S000', descripcion: 'TRAUMATISMO SUPERFICIAL DEL CUERO CABELLUDO'},
{ codigo: 'S001', descripcion: 'CONTUSION DE LOS PARPADOS Y DE LA REGION PERIOCULAR'},
{ codigo: 'S002', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DEL PARPADO Y DE LA REGION PERIOCULAR'},
{ codigo: 'S003', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA NARIZ'},
{ codigo: 'S004', descripcion: 'TRAUMATISMO SUPERFICIAL DEL OIDO'},
{ codigo: 'S005', descripcion: 'TRAUMATISMO SUPERFICIAL DEL LABIO Y DE LA CAVIDAD BUCAL'},
{ codigo: 'S007', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA CABEZA'},
{ codigo: 'S008', descripcion: 'TRAUMATISMO SUPERFICIAL DE OTRAS PARTES DE LA CABEZA'},
{ codigo: 'S009', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA CABEZA, PARTE NO ESPECIFICADA'},
{ codigo: 'S01', descripcion: 'HERIDA DE LA CABEZA'},
{ codigo: 'S010', descripcion: 'HERIDA DEL CUERO CABELLUDO'},
{ codigo: 'S011', descripcion: 'HERIDA DEL PARPADO Y DE LA REGION PERIOCULAR'},
{ codigo: 'S012', descripcion: 'HERIDA DE LA NARIZ'},
{ codigo: 'S013', descripcion: 'HERIDA DEL OIDO'},
{ codigo: 'S014', descripcion: 'HERIDA DE LA MEJILLA Y DE LA REGION TEMPOROMANDIBULAR'},
{ codigo: 'S015', descripcion: 'HERIDA DEL LABIO Y DE LA CAVIDAD BUCAL'},
{ codigo: 'S017', descripcion: 'HERIDAS MULTIPLES DE LA CABEZA'},
{ codigo: 'S018', descripcion: 'HERIDA DE OTRAS PARTES DE LA CABEZA'},
{ codigo: 'S019', descripcion: 'HERIDA DE LA CABEZA, PARTE NO ESPECIFICADA'},
{ codigo: 'S02', descripcion: 'FRACTURA DE HUESOS DEL CRANEO Y DE LA CARA'},
{ codigo: 'S020', descripcion: 'FRACTURA DE LA BOVEDA DEL CRANEO'},
{ codigo: 'S021', descripcion: 'FRACTURA DE LA BASE DEL CRANEO'},
{ codigo: 'S022', descripcion: 'FRACTURA DE LOS HUESOS DE LA NARIZ'},
{ codigo: 'S023', descripcion: 'FRACTURA DEL SUELO DE LA ORBITA'},
{ codigo: 'S024', descripcion: 'FRACTURA DEL MALAR Y DEL HUESO MAXILAR SUPERIOR'},
{ codigo: 'S025', descripcion: 'FRACTURA DE LOS DIENTES'},
{ codigo: 'S026', descripcion: 'FRACTURA DEL MAXILAR INFERIOR'},
{ codigo: 'S027', descripcion: 'FRACTURAS MULTIPLES QUE COMPROMETEN EL CRANEO Y LOS HUESOS DE LA CARA'},
{ codigo: 'S028', descripcion: 'FRACTURA DE OTROS HUESOS DEL CRANEO Y DE LA CARA'},
{ codigo: 'S029', descripcion: 'FRACTURA DEL CRANEO Y DE LOS HUESOS DE LA CARA, PARTE NO ESPECIFICADA'},
{ codigo: 'S03', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS CABEZA'},
{ codigo: 'S030', descripcion: 'LUXACION DEL MAXILAR'},
{ codigo: 'S031', descripcion: 'LUXACION DEL CARTILAGO SEPTAL DE LA NARIZ'},
{ codigo: 'S032', descripcion: 'LUXACION DE DIENTE'},
{ codigo: 'S033', descripcion: 'LUXACION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CABEZA'},
{ codigo: 'S034', descripcion: 'ESGUINCES Y TORCEDURAS DEL MAXILAR'},
{ codigo: 'S035', descripcion: 'ESGUINCE Y TORCEDURAS DE ARTICULACIONES Y LIGAMENTOS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA CABEZA'},
{ codigo: 'S04', descripcion: 'TRAUMATISMO DE NERVIOS CRANEALES'},
{ codigo: 'S040', descripcion: 'TRAUMATISMO DEL NERVIO OPTICO [II PAR] Y DE LAS VIAS OPTICAS'},
{ codigo: 'S041', descripcion: 'TRAUMATISMO DEL NERVIO MOTOR OCULAR COMUN (III PAR)'},
{ codigo: 'S042', descripcion: 'TRAUMATISMO DEL NERVIO PATETICO (IV PAR)'},
{ codigo: 'S043', descripcion: 'TRAUMATISMO DEL NERVIO TRIGEMINO (V PAR)'},
{ codigo: 'S044', descripcion: 'TRAUMATISMO DEL NERVIO MOTOR OCULAR EXTERNO (VI PAR)'},
{ codigo: 'S045', descripcion: 'TRAUMATISMO DEL NERVIO FACIAL (VII PAR)'},
{ codigo: 'S046', descripcion: 'TRAUMATISMO DEL NERVIO ACUSTICO (VIII PAR)'},
{ codigo: 'S047', descripcion: 'TRAUMATISMO DEL NERVIO ESPINAL (XI PAR)'},
{ codigo: 'S048', descripcion: 'TRAUMATISMO DE OTROS NERVIOS CRANEALES'},
{ codigo: 'S049', descripcion: 'TRAUMATISMO DE NERVIOS CRANEALES, NO ESPECIFICADO'},
{ codigo: 'S05', descripcion: 'TRAUMATISMO DEL OJO Y DE LA ORBITA'},
{ codigo: 'S050', descripcion: 'TRAUMATISMO CONJUNTIVA Y ABRASION CORNEAL SIN MENCION CUERPO EXTRA—O'},
{ codigo: 'S051', descripcion: 'CONTUSION DEL GLOBO OCULAR Y DEL TEJIDO ORBITARIO'},
{ codigo: 'S052', descripcion: 'LACERACION Y RUPTURA OCULAR CON PROLAPSO O PERDIDA TEJIDO INTRAOCULAR'},
{ codigo: 'S053', descripcion: 'LACERACION OCULAR SIN PROLAPSO O PERDIDA DEL TEJIDO INTRAOCULAR'},
{ codigo: 'S054', descripcion: 'HERIDA PENETRANTE DE LA ORBITA CON O SIN CUERPO EXTRA—O'},
{ codigo: 'S055', descripcion: 'HERIDA PENETRANTE DEL GLOBO OCULAR CON CUERPO EXTRA—O'},
{ codigo: 'S056', descripcion: 'HERIDA PENETRANTE DEL GLOBO OCULAR SIN CUERPO EXTRA—O'},
{ codigo: 'S057', descripcion: 'AVULSION DE OJO'},
{ codigo: 'S058', descripcion: 'OTROS TRAUMATISMOS DEL OJO Y DE LA ORBITA'},
{ codigo: 'S059', descripcion: 'TRAUMATISMO DEL OJO Y DE LA ORBITA, NO ESPECIFICADO'},
{ codigo: 'S06', descripcion: 'TRAUMATISMO INTRACRANEAL'},
{ codigo: 'S060', descripcion: 'CONCUSION'},
{ codigo: 'S061', descripcion: 'EDEMA CEREBRAL TRAUMATICO'},
{ codigo: 'S062', descripcion: 'TRAUMATISMO CEREBRAL DIFUSO'},
{ codigo: 'S063', descripcion: 'TRAUMATISMO CEREBRAL FOCAL'},
{ codigo: 'S064', descripcion: 'HEMORRAGIA EPIDURAL'},
{ codigo: 'S065', descripcion: 'HEMORRAGIA SUBDURAL TRAUMATICA'},
{ codigo: 'S066', descripcion: 'HEMORRAGIA SUBARACNOIDEA TRAUMATICA'},
{ codigo: 'S067', descripcion: 'TRAUMATISMO INTRACRANEAL CON COMA PROLONGADO'},
{ codigo: 'S068', descripcion: 'OTROS TRAUMATISMOS INTRACRANEALES'},
{ codigo: 'S069', descripcion: 'TRAUMATISMO INTRACRANEAL, NO ESPECIFICADO'},
{ codigo: 'S07', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA'},
{ codigo: 'S070', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA CARA'},
{ codigo: 'S071', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL CRANEO'},
{ codigo: 'S078', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DE LA CABEZA'},
{ codigo: 'S079', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA, PARTE NO ESPECIFICADA'},
{ codigo: 'S08', descripcion: 'AMPUTACION TRAUMATICA DE PARTE DE LA CABEZA'},
{ codigo: 'S080', descripcion: 'AVULSION DEL CUERO CABELLUDO'},
{ codigo: 'S081', descripcion: 'AMPUTACION TRAUMATICA DE LA OREJA'},
{ codigo: 'S088', descripcion: 'AMPUTACION TRAUMATICA DE OTRAS PARTES DE LA CABEZA'},
{ codigo: 'S089', descripcion: 'AMPUTACION TRAUMATICA DE PARTE NO ESPECIFICADA DE LA CABEZA'},
{ codigo: 'S09', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA CABEZA'},
{ codigo: 'S090', descripcion: 'TRAUMATISMO DE LOS VASOS SANGUINEOS DE LA CABEZA NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'S091', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULOS DE LA CABEZA'},
{ codigo: 'S092', descripcion: 'RUPTURA TRAUMATICA DEL TIMPANO DEL OIDO'},
{ codigo: 'S097', descripcion: 'TRAUMATISMOS MULTIPLES DE LA CABEZA'},
{ codigo: 'S098', descripcion: 'OTROS TRAUMATISMOS DE LA CABEZA, ESPECIFICADOS'},
{ codigo: 'S099', descripcion: 'TRAUMATISMO DE LA CABEZA, NO ESPECIFICADO'},
{ codigo: 'S10', descripcion: 'TRAUMATISMO SUPERFICIAL DEL CUELLO'},
{ codigo: 'S100', descripcion: 'CONTUSION DE LA GARGANTA'},
{ codigo: 'S101', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES Y LOS NO ESPECIFICADOS DE LA GARGANTA'},
{ codigo: 'S107', descripcion: 'TRAUMATISMO SUPERFICIAL MULTIPLE DEL CUELLO'},
{ codigo: 'S108', descripcion: 'TRAUMATISMO SUPERFICIAL DE OTRAS PARTES DEL CUELLO'},
{ codigo: 'S109', descripcion: 'TRAUMATISMO SUPERFICIAL DEL CUELLO, PARTE NO ESPECIFICADA'},
{ codigo: 'S11', descripcion: 'HERIDA DEL CUELLO'},
{ codigo: 'S110', descripcion: 'HERIDA QUE COMPROMETE LA LARINGE Y LA TRAQUEA'},
{ codigo: 'S111', descripcion: 'HERIDA QUE COMPROMETE LA GLANDULA TIROIDES'},
{ codigo: 'S112', descripcion: 'HERIDA QUE COMPROMETE LA FARINGE Y EL ESOFAGO CERVICAL'},
{ codigo: 'S117', descripcion: 'HERIDAS MULTIPLES DEL CUELLO'},
{ codigo: 'S118', descripcion: 'HERIDAS DE OTRAS PARTES DEL CUELLO'},
{ codigo: 'S119', descripcion: 'HERIDA DE CUELLO, PARTE NO ESPECIFICADA'},
{ codigo: 'S12', descripcion: 'FRACTURA DEL CUELLO'},
{ codigo: 'S120', descripcion: 'FRACTURA DE LA PRIMERA VERTEBRA CERVICAL'},
{ codigo: 'S121', descripcion: 'FRACTURA DE LA SEGUNDA VERTEBRA CERVICAL'},
{ codigo: 'S122', descripcion: 'FRACTURA DE OTRAS VERTEBRAS CERVICALES ESPECIFICADAS'},
{ codigo: 'S127', descripcion: 'FRACTURAS MULTIPLES DE COLUMNA CERVICAL'},
{ codigo: 'S128', descripcion: 'FRACTURA DE OTRAS PARTES DEL CUELLO'},
{ codigo: 'S129', descripcion: 'FRACTURA DEL CUELLO, PARTE NO ESPECIFICADA'},
{ codigo: 'S13', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS CUELLO'},
{ codigo: 'S130', descripcion: 'RUPTURA TRAUMATICA DE DISCO CERVICAL INTERVERTEBRAL'},
{ codigo: 'S131', descripcion: 'LUXACION DE VERTEBRA CERVICAL'},
{ codigo: 'S132', descripcion: 'LUXACIONES DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL CUELLO'},
{ codigo: 'S133', descripcion: 'LUXACIONES MULTIPLES DEL CUELLO'},
{ codigo: 'S134', descripcion: 'ESGUINCES Y TORCEDURAS DE LA COLUMNA CERVICAL'},
{ codigo: 'S135', descripcion: 'ESGUINCES Y TORCEDURAS DE LA REGION TIROIDEA'},
{ codigo: 'S136', descripcion: 'ESGUINCES Y TORCEDURAS DE ARTICULACIONES Y LIGAMENTOS DE OTROS SITIOS ESPECIFICADOS Y DE LOS NO ESPECIFICADOS DEL CUELLO'},
{ codigo: 'S14', descripcion: 'TRAUMATISMO DE LA MEDULA ESPINAL Y DE NERVIOS A NIVEL DEL CUELLO'},
{ codigo: 'S140', descripcion: 'CONCUSION Y EDEMA DE LA MEDULA ESPINAL CERVICAL'},
{ codigo: 'S141', descripcion: 'OTROS TRAUMATISMOS DE LA MEDULA ESPINAL CERVICAL Y LOS NO ESPECIFICADOS'},
{ codigo: 'S142', descripcion: 'TRAUMATISMO DE RAIZ NERVIOSA DE COLUMNA CERVICAL'},
{ codigo: 'S143', descripcion: 'TRAUMATISMO DEL PLEXO BRAQUIAL'},
{ codigo: 'S144', descripcion: 'TRAUMATISMO DE NERVIOS PERIFERICOS DEL CUELLO'},
{ codigo: 'S145', descripcion: 'TRAUMATISMO DE NERVIOS CERVICALES SIMPATICOS'},
{ codigo: 'S146', descripcion: 'TRAUMATISMO DE OTROS NERVIOS Y DE LOS NO ESPECIFICADOS DEL CUELLO'},
{ codigo: 'S15', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DEL CUELLO'},
{ codigo: 'S150', descripcion: 'TRAUMATISMO DE LA ARTERIA CAROTIDA'},
{ codigo: 'S151', descripcion: 'TRAUMATISMO DE LA ARTERIA VERTEBRAL'},
{ codigo: 'S152', descripcion: 'TRAUMATISMO DE LA VENA YUGULAR EXTERNA'},
{ codigo: 'S153', descripcion: 'TRAUMATISMO DE LA VENA YUGULAR INTERNA'},
{ codigo: 'S157', descripcion: 'TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL CUELLO'},
{ codigo: 'S158', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL CUELLO'},
{ codigo: 'S159', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS A NIVEL DEL CUELLO'},
{ codigo: 'S16', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULOS A NIVEL DEL CUELLO'},
{ codigo: 'S17', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL CUELLO'},
{ codigo: 'S170', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA LARINGE Y DE LA TRAQUEA'},
{ codigo: 'S178', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL CUELLO'},
{ codigo: 'S179', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL CUELLO, PARTE NO ESPECIFICADA'},
{ codigo: 'S18', descripcion: 'AMPUTACION TRAUMATICA A NIVEL DEL CUELLO'},
{ codigo: 'S19', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL CUELLO'},
{ codigo: 'S197', descripcion: 'TRAUMATISMOS MULTIPLES DEL CUELLO'},
{ codigo: 'S198', descripcion: 'OTROS TRAUMATISMOS DEL CUELLO, ESPECIFICADOS'},
{ codigo: 'S199', descripcion: 'TRAUMATISMO DEL CUELLO, NO ESPECIFICADO'},
{ codigo: 'S20', descripcion: 'TRAUMATISMO SUPERFICIAL DEL TORAX'},
{ codigo: 'S200', descripcion: 'CONTUSION DE LA MAMA'},
{ codigo: 'S201', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES Y LOS NO ESPECIFICADOS DE LA MAMA'},
{ codigo: 'S202', descripcion: 'CONTUSION DEL TORAX'},
{ codigo: 'S203', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DE LA PARED ANTERIOR DEL TORAX'},
{ codigo: 'S204', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DE LA PARED POSTERIOR DEL TORAX'},
{ codigo: 'S207', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DEL TORAX'},
{ codigo: 'S208', descripcion: 'TRAUMATISMO SUPERFICIAL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TORAX'},
{ codigo: 'S21', descripcion: 'HERIDA DEL TORAX'},
{ codigo: 'S210', descripcion: 'HERIDA DE LA MAMA'},
{ codigo: 'S211', descripcion: 'HERIDA DE LA PARED ANTERIOR DEL TORAX'},
{ codigo: 'S212', descripcion: 'HERIDA DE LA PARED POSTERIOR DEL TORAX'},
{ codigo: 'S217', descripcion: 'HERIDA MULTIPLE DE LA PARED TORACICA'},
{ codigo: 'S218', descripcion: 'HERIDA DE OTRAS PARTES DEL TORAX'},
{ codigo: 'S219', descripcion: 'HERIDA DEL TORAX, PARTE NO ESPECIFICADA'},
{ codigo: 'S22', descripcion: 'FRACTURA DE COSTILLAS, DEL ESTERNON Y DE LA COLUMNA TORACICA (DORSAL)'},
{ codigo: 'S220', descripcion: 'FRACTURA DE VERTEBRA TORACICA'},
{ codigo: 'S221', descripcion: 'FRACTURAS MULTIPLES DE COLUMNA TORACICA'},
{ codigo: 'S222', descripcion: 'FRACTURA DEL ESTERNON'},
{ codigo: 'S223', descripcion: 'FRACTURA DE COSTILLA'},
{ codigo: 'S224', descripcion: 'FRACTURAS MULTIPLES DE COSTILLAS'},
{ codigo: 'S225', descripcion: 'TORAX AZOTADO'},
{ codigo: 'S228', descripcion: 'FRACTURA DE OTRAS PARTES DEL TORAX OSEO'},
{ codigo: 'S229', descripcion: 'FRACTURA DEL TORAX OSEO, PARTE NO ESPECIFICADA'},
{ codigo: 'S23', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE TORAX'},
{ codigo: 'S230', descripcion: 'RUPTURA TRAUMATICA DE DISCO INTERVERTEBRAL TORACICO'},
{ codigo: 'S231', descripcion: 'LUXACION DE VERTEBRA TORACICA'},
{ codigo: 'S232', descripcion: 'LUXACION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TORAX'},
{ codigo: 'S233', descripcion: 'ESGUINCES Y TORCEDURAS DE COLUMNA TORACICA'},
{ codigo: 'S234', descripcion: 'ESGUINCES Y TORCEDURAS DE COSTILLAS Y ESTERNON'},
{ codigo: 'S235', descripcion: 'ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TORAX'},
{ codigo: 'S24', descripcion: 'TRAUMATISMO DE NERVIOS Y DE LA MEDULA ESPINAL A NIVEL DEL TORAX'},
{ codigo: 'S240', descripcion: 'CONCUSION Y EDEMA DE LA MEDULA ESPINAL TORACICA'},
{ codigo: 'S241', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA MEDULA ESPINAL TORACICA'},
{ codigo: 'S242', descripcion: 'TRAUMATISMO DE RAICES NERVIOSAS DE LA COLUMNA TORACICA'},
{ codigo: 'S243', descripcion: 'TRAUMATISMO DE NERVIOS PERIFERICOS DEL TORAX'},
{ codigo: 'S244', descripcion: 'TRAUMATISMO DE NERVIOS SIMPATICOS TORACICOS'},
{ codigo: 'S245', descripcion: 'TRAUMATISMO DE OTROS NERVIOS DEL TORAX'},
{ codigo: 'S246', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO DEL TORAX'},
{ codigo: 'S25', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS DEL TORAX'},
{ codigo: 'S250', descripcion: 'TRAUMATISMO DE LA AORTA TORACICA'},
{ codigo: 'S251', descripcion: 'TRAUMATISMO DE LA ARTERIA INNOMINADA O SUBCLAVIA'},
{ codigo: 'S252', descripcion: 'TRAUMATISMO DE VENA CAVA SUPERIOR'},
{ codigo: 'S253', descripcion: 'TRAUMATISMO DE LA VENA INNOMINADA O SUBCLAVIA'},
{ codigo: 'S254', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS PULMONARES'},
{ codigo: 'S255', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS INTERCOSTALES'},
{ codigo: 'S257', descripcion: 'TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS DEL TORAX'},
{ codigo: 'S258', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS DEL TORAX'},
{ codigo: 'S259', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS DEL TORAX'},
{ codigo: 'S26', descripcion: 'TRAUMATISMO DEL CORAZON'},
{ codigo: 'S260', descripcion: 'TRAUMATISMO DEL CORAZON CON HEMOPERICARDIO'},
{ codigo: 'S268', descripcion: 'OTROS TRAUMATISMOS DEL CORAZON'},
{ codigo: 'S269', descripcion: 'TRAUMATISMO DEL CORAZON, NO ESPECIFICADO'},
{ codigo: 'S27', descripcion: 'TRAUMATISMO DE OTROS ORGANOS INTRATORACICOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'S270', descripcion: 'NEUMOTORAX TRAUMATICO'},
{ codigo: 'S271', descripcion: 'HEMOTORAX TRAUMATICO'},
{ codigo: 'S272', descripcion: 'HEMONEUMOTORAX TRAUMATICO'},
{ codigo: 'S273', descripcion: 'OTROS TRAUMATISMOS DEL PULMON'},
{ codigo: 'S274', descripcion: 'TRAUMATISMO DE LOS BRONQUIOS'},
{ codigo: 'S275', descripcion: 'TRAUMATISMO DE LA TRAQUEA TORACICA'},
{ codigo: 'S276', descripcion: 'TRAUMATISMO DE LA PLEURA'},
{ codigo: 'S277', descripcion: 'TRAUMATISMOS MULTIPLES DE ORGANOS INTRATORACICOS'},
{ codigo: 'S278', descripcion: 'TRAUMATISMO DE OTROS ORGANOS INTRATORACICOS, ESPECIFICADOS'},
{ codigo: 'S279', descripcion: 'TRAUMATISMO DE ORGANO INTRATORACICO, NO ESPECIFICADO'},
{ codigo: 'S28', descripcion: 'TRAUMATISMO POR APLASTAMIENTO TORAX AMPUTACION TRAUMATICA PARTE TORAX'},
{ codigo: 'S280', descripcion: 'APLASTAMIENTO DEL TORAX'},
{ codigo: 'S281', descripcion: 'AMPUTACION TRAUMATICA DE PARTE DEL TORAX'},
{ codigo: 'S29', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL TORAX'},
{ codigo: 'S290', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULOS A NIVEL DEL TORAX'},
{ codigo: 'S297', descripcion: 'TRAUMATISMOS MULTIPLES DEL TORAX'},
{ codigo: 'S298', descripcion: 'OTROS TRAUMATISMOS DEL TORAX, ESPECIFICADOS'},
{ codigo: 'S299', descripcion: 'TRAUMATISMO DEL TORAX, NO ESPECIFICADO'},
{ codigo: 'S30', descripcion: 'TRAUMATISMO SUPERFICIAL DEL ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S300', descripcion: 'CONTUSION DE LA REGION LUMBOSACRA Y DE LA PELVIS'},
{ codigo: 'S301', descripcion: 'CONTUSION DE LA PARED ABDOMINAL'},
{ codigo: 'S302', descripcion: 'CONTUSION DE ORGANOS GENITALES EXTERNOS'},
{ codigo: 'S307', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DEL ABDOMEN, REGION LUMBOSACRA Y PELVIS'},
{ codigo: 'S308', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DEL ABDOMEN, REGION LUMBOSACRA Y PELVIS'},
{ codigo: 'S309', descripcion: 'TRAUMATISMO SUPERFICIAL DEL ABDOMEN, REGION LUMBOSACRA Y PELVIS, PARTE NO ESPECIFICADA'},
{ codigo: 'S31', descripcion: 'HERIDA DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS'},
{ codigo: 'S310', descripcion: 'HERIDA DE LA REGION LUMBOSACRA Y DE LA PELVIS'},
{ codigo: 'S311', descripcion: 'HERIDA DE LA PARED ABDOMINAL'},
{ codigo: 'S312', descripcion: 'HERIDA DEL PENE'},
{ codigo: 'S313', descripcion: 'HERIDA DEL ESCROTO Y DE LOS TESTICULOS'},
{ codigo: 'S314', descripcion: 'HERIDA DE LA VAGINA Y DE LA VULVA'},
{ codigo: 'S315', descripcion: 'HERIDA DE OTROS ORGANOS GENITALES EXTERNOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'S317', descripcion: 'HERIDAS MULTIPLES DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS'},
{ codigo: 'S318', descripcion: 'HERIDAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN'},
{ codigo: 'S32', descripcion: 'FRACTURA DE LA COLUMNA LUMBAR Y DE LA PELVIS'},
{ codigo: 'S320', descripcion: 'FRACTURA DE VERTEBRA LUMBAR'},
{ codigo: 'S321', descripcion: 'FRACTURA DEL SACRO'},
{ codigo: 'S322', descripcion: 'FRACTURA DEL COCCIX'},
{ codigo: 'S323', descripcion: 'FRACTURA DEL HUESO ILIACO'},
{ codigo: 'S324', descripcion: 'FRACTURA DEL ACETABULO'},
{ codigo: 'S325', descripcion: 'FRACTURA DEL PUBIS'},
{ codigo: 'S327', descripcion: 'FRACTURAS MULTIPLES DE LA COLUMNA LUMBAR Y DE LA PELVIS'},
{ codigo: 'S328', descripcion: 'FRACTURAAS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y LA PELVIS'},
{ codigo: 'S33', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE LAS ARTICULACIONES Y LIGAMENTOS DE LA COLUMNA LUMBAR Y LA PELVIS'},
{ codigo: 'S330', descripcion: 'RUPTURA TRAUMATICA DE DISCO INTERVERTEBRAL LUMBAR'},
{ codigo: 'S331', descripcion: 'LUXACION DE VERTEBRA LUMBAR'},
{ codigo: 'S332', descripcion: 'LUXACION DE ARTICULACION SACROCOCCIGEA Y SACROILIACA'},
{ codigo: 'S333', descripcion: 'LUXACION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y LA PELVIS'},
{ codigo: 'S334', descripcion: 'RUPTURA TRAUMATICA DE LA SINFISIS DEL PUBIS'},
{ codigo: 'S335', descripcion: 'ESGUINCES Y TORCEDURAS DE LA COLUMNA LUMBAR'},
{ codigo: 'S336', descripcion: 'ESGUINCES Y TORCEDURAS DE LA ARTICULACION SACROILIACA'},
{ codigo: 'S337', descripcion: 'ESGUINCES Y TORCEDURAS OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y LA PELVIS'},
{ codigo: 'S34', descripcion: 'TRAUMATISMO DE LOS NERVIOS Y DE LA MEDULA ESPINAL LUMBAR A NIVEL DE ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S340', descripcion: 'CONCUSION Y EDEMA DE LA MEDULA ESPINAL LUMBAR'},
{ codigo: 'S341', descripcion: 'OTRO TRAUMATISMO DE LA MEDULA ESPINAL LUMBAR'},
{ codigo: 'S342', descripcion: 'TRAUMATISMO DE RAIZ NERVIOSA DE LA COLUMNA LUMBAR Y SACRA'},
{ codigo: 'S343', descripcion: 'TRAUMATISMO DE LA COLA DE CABALLO'},
{ codigo: 'S344', descripcion: 'TRAUMATISMO DEL PLEXO LUMBOSACRO'},
{ codigo: 'S345', descripcion: 'TRAUMATISMO NERVIOS(S) SIMPATICO(S) LUMBAR(ES), SACRO(S) Y PELVICO(S)'},
{ codigo: 'S346', descripcion: 'TRAUMATISMO NERVIO(S) PERIFERICO(S) DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S348', descripcion: 'TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'S35', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S350', descripcion: 'TRAUMATISMO DE LA AORTA ABDOMINAL'},
{ codigo: 'S351', descripcion: 'TRAUMATISMO DE LA VENA CAVA INFERIOR'},
{ codigo: 'S352', descripcion: 'TRAUMATISMO DE ARTERIAS CELIACAS Y MESENTERICAS'},
{ codigo: 'S353', descripcion: 'TRAUMATISMO DE VENAS PORTA Y ESPLENICA'},
{ codigo: 'S354', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS RENALES'},
{ codigo: 'S355', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS ILIACOS'},
{ codigo: 'S357', descripcion: 'TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S358', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S359', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS NO ESPECIFICADOS A NIVEL DEL ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S36', descripcion: 'TRAUMATISMO DE ORGANOS INTRAABDOMINALES'},
{ codigo: 'S360', descripcion: 'TRAUMATISMO DEL BAZO'},
{ codigo: 'S361', descripcion: 'TRAUMATISMO DEL HIGADO Y DE LA VESICULA BILIAR'},
{ codigo: 'S362', descripcion: 'TRAUMATISMO DEL PANCREAS'},
{ codigo: 'S363', descripcion: 'TRAUMATISMO DEL ESTOMAGO'},
{ codigo: 'S364', descripcion: 'TRAUMATISMO DEL INTESTINO DELGADO'},
{ codigo: 'S365', descripcion: 'TRAUMATISMO DEL COLON'},
{ codigo: 'S366', descripcion: 'TRAUMATISMO DEL RECTO'},
{ codigo: 'S367', descripcion: 'TRAUMATISMO DE MULTIPLES ORGANOS INTRAABDOMINALES'},
{ codigo: 'S368', descripcion: 'TRAUMATISMO DE OTROS ORGANOS INTRAABDOMINALES'},
{ codigo: 'S369', descripcion: 'TRAUMATISMO DE ORGANO INTRAABDOMINAL NO ESPECIFICADO'},
{ codigo: 'S37', descripcion: 'TRAUMATISMO DE ORGANOS PELVICOS'},
{ codigo: 'S370', descripcion: 'TRAUMATISMO DEL RI—ON'},
{ codigo: 'S371', descripcion: 'TRAUMATISMO DEL URETER'},
{ codigo: 'S372', descripcion: 'TRAUMATISMO DE LA VEJIGA'},
{ codigo: 'S373', descripcion: 'TRAUMATISMO DE LA URETRA'},
{ codigo: 'S374', descripcion: 'TRAUMATISMO DEL OVARIO'},
{ codigo: 'S375', descripcion: 'TRAUMATISMO DE LA TROMPA DE FALOPIO'},
{ codigo: 'S376', descripcion: 'TRAUMATISMO DEL UTERO'},
{ codigo: 'S377', descripcion: 'TRAUMATISMO DE MULTIPLES ORGANOS PELVICOS'},
{ codigo: 'S378', descripcion: 'TRAUMATISMO DE OTROS ORGANOS PELVICOS'},
{ codigo: 'S379', descripcion: 'TRAUMATISMO DE ORGANO PELVICO NO ESPECIFICADO'},
{ codigo: 'S38', descripcion: 'TRAUMATATISMO POR APLASTAMIENTO Y AMPUTACION TRAUMATICA DE PARTE DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S380', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE ORGANOS GENITALES EXTERNOS'},
{ codigo: 'S381', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S382', descripcion: 'AMPUTACION TRAUMATICA DE ORGANOS GENITALES EXTERNOS'},
{ codigo: 'S383', descripcion: 'AMPUTACION TRAUMATICA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S39', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S390', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULOS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S396', descripcion: 'TRAUMATISMO DE ORGANO(S) INTRAABDOMINAL(ES) CON ORGANO(S) PELVICO(S)'},
{ codigo: 'S397', descripcion: 'OTROS TRAUMATISMOS MULTIPLES DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS'},
{ codigo: 'S398', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S399', descripcion: 'TRAUMATISMO NO ESPECIFICADO DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'S40', descripcion: 'TRAUMATISMO SUPERFICIAL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S400', descripcion: 'CONTUSION DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S407', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S408', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S409', descripcion: 'TRAUMATISMO SUPERFICIAL NO ESPECIFICADO DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S41', descripcion: 'HERIDA DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S410', descripcion: 'HERIDA DEL HOMBRO'},
{ codigo: 'S411', descripcion: 'HERIDA DEL BRAZO'},
{ codigo: 'S417', descripcion: 'HERIDAS MULTIPLES DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S418', descripcion: 'HERIDA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S42', descripcion: 'FRACTURA DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S420', descripcion: 'FRACTURA DE LA CLAVICULA'},
{ codigo: 'S421', descripcion: 'FRACTURA DEL OMOPLATO'},
{ codigo: 'S422', descripcion: 'FRACTURA DE LA EPIFISIS SUPERIOR DEL HUMERO'},
{ codigo: 'S423', descripcion: 'FRACTURA DE LA DIAFISIS DEL HUMERO'},
{ codigo: 'S424', descripcion: 'FRACTURA DE LA EPIFISIS INFERIOR DEL HUMERO'},
{ codigo: 'S427', descripcion: 'FRACTURAS MULTIPLES DE LA CLAVICULA, DEL OMOPLATO Y DEL HUMERO'},
{ codigo: 'S428', descripcion: 'FRACTURA DE OTRAS PARTES DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S429', descripcion: 'FRACTURA DEL HOMBRO Y DEL BRAZO, PARTE NO ESPECIFICADA'},
{ codigo: 'S43', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULAC Y LIGAMENTOS DE LA CINTURA ESCAPULAR'},
{ codigo: 'S430', descripcion: 'LUXACION DE LA ARTICULACION DEL HOMBRO'},
{ codigo: 'S431', descripcion: 'LUXACION DE LA ARTICULACION ACROMIOCLAVICULAR'},
{ codigo: 'S432', descripcion: 'LUXACION DE LA ARTICULACION ESTERNOCLAVICULAR'},
{ codigo: 'S433', descripcion: 'LUXACION DE OTRAS PARTES DE LA CINTURA ESCAPULAR Y DE LAS NO ESPECIFICADAS'},
{ codigo: 'S434', descripcion: 'ESGUINCES Y TORCEDURAS DE LA ARTICULACION DEL HOMBRO'},
{ codigo: 'S435', descripcion: 'ESGUINCES Y TORCEDURAS DE LA ARTICULACION ACROMIOCLAVICULAR'},
{ codigo: 'S436', descripcion: 'ESGUINCES Y TORCEDURAS DE LA ARTICULACION ESTERNOCLAVICULAR'},
{ codigo: 'S437', descripcion: 'ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE CINTURA ESCAPULAR'},
{ codigo: 'S44', descripcion: 'TRAUMATISMO DE NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S440', descripcion: 'TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DEL BRAZO'},
{ codigo: 'S441', descripcion: 'TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DEL BRAZO'},
{ codigo: 'S442', descripcion: 'TRAUMATISMO DEL NERVIO RADIAL A NIVEL DEL BRAZO'},
{ codigo: 'S443', descripcion: 'TRAUMATISMO DEL NERVIO AXILAR'},
{ codigo: 'S444', descripcion: 'TRAUMATISMO DEL NERVIO MUSCULOCUTANEO'},
{ codigo: 'S445', descripcion: 'TRAUMATISMO DEL NERVIO SENSITIVO CUTANEO A NIVEL DEL HOMBRO Y DE BRAZO'},
{ codigo: 'S447', descripcion: 'TRAUMATISMO DE MULTIPLES NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S448', descripcion: 'TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S449', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S45', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S450', descripcion: 'TRAUMATISMO DE LA ARTERIA AXILAR'},
{ codigo: 'S451', descripcion: 'TRAUMATISMO DE LA ARTERIA BRAQUIAL'},
{ codigo: 'S452', descripcion: 'TRAUMATISMO DE LA VENA AXILAR O BRAQUIAL'},
{ codigo: 'S453', descripcion: 'TRAUMATISMO DE VENA SUPERFICIAL A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S457', descripcion: 'TRAUMATISMO MULTIPLES VASOS SANGUINEOS A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S458', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S459', descripcion: 'TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S46', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S460', descripcion: 'TRAUMATISMO DEL TENDON DEL MANGUITO ROTATORIO DEL HOMBRO'},
{ codigo: 'S461', descripcion: 'TRAUMATISMO DEL TENDON Y MUSCULO DE LA CABEZA LARGA DEL BICEPS'},
{ codigo: 'S462', descripcion: 'TRAUMATISMO DEL TENDON Y MUSCULO DE OTRAS PARTES DEL BICEPS'},
{ codigo: 'S463', descripcion: 'TRAUMATISMO DEL TENDON Y MUSCULO DEL TRICEPS'},
{ codigo: 'S467', descripcion: 'TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DEL HOMBRO Y BRAZO'},
{ codigo: 'S468', descripcion: 'TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DEL HOMBRO Y DE BRAZO'},
{ codigo: 'S469', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADO, A NIVEL DE HOMBRO Y DE BRAZO'},
{ codigo: 'S47', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S48', descripcion: 'AMPUTACION TRAUMATICA DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S480', descripcion: 'AMPUTACION TRAUMATICA EN LA ARTICULACION DEL HOMBRO'},
{ codigo: 'S481', descripcion: 'AMPUTACION TRAUMATICA A NIVEL ENTRE EL HOMBRO Y EL CODO'},
{ codigo: 'S489', descripcion: 'AMPUTACION TRAUMATICA DEL HOMBRO Y DEL BRAZO, NIVEL NO ESPECIFICADO'},
{ codigo: 'S49', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S497', descripcion: 'TRAUMATISMOS MULTIPLES DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S498', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S499', descripcion: 'TRAUMATISMOS NO ESPECIFICADOS DEL HOMBRO Y DEL BRAZO'},
{ codigo: 'S50', descripcion: 'TRAUMATISMO SUPERFICIAL DEL ANTEBRAZO Y DEL CODO'},
{ codigo: 'S500', descripcion: 'CONTUSION DEL CODO'},
{ codigo: 'S501', descripcion: 'CONTUSION DE OTRAS PARTES DEL ANTEBRAZO Y DE LAS NO ESPECIFICADAS'},
{ codigo: 'S507', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DEL ANTEBRAZO'},
{ codigo: 'S508', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DEL ANTEBRAZO'},
{ codigo: 'S509', descripcion: 'TRAUMATISMO SUPERFICIAL DEL ANTEBRAZO, NO ESPECIFICADO'},
{ codigo: 'S51', descripcion: 'HERIDA DEL ANTEBRAZO Y DEL CODO'},
{ codigo: 'S510', descripcion: 'HERIDA DEL CODO'},
{ codigo: 'S517', descripcion: 'HERIDAS MULTIPLES DEL ANTEBRAZO'},
{ codigo: 'S518', descripcion: 'HERIDA DE OTRAS PARTES DEL ANTEBRAZO'},
{ codigo: 'S519', descripcion: 'HERIDA DEL ANTEBRAZO, PARTE NO ESPECIFICADA'},
{ codigo: 'S52', descripcion: 'FRACTURA DEL ANTEBRAZO'},
{ codigo: 'S520', descripcion: 'FRACTURA DE LA EPIFISIS SUPERIOR DEL CUBITO'},
{ codigo: 'S521', descripcion: 'FRACTURA DE LA EPIFISIS SUPERIOR DEL RADIO'},
{ codigo: 'S522', descripcion: 'FRACTURA DE LA DIAFISIS DEL CUBITO'},
{ codigo: 'S523', descripcion: 'FRACTURA DE LA DIAFISIS DEL RADIO'},
{ codigo: 'S524', descripcion: 'FRACTURA DE LA DIAFISIS DEL CUBITO Y DEL RADIO'},
{ codigo: 'S525', descripcion: 'FRACTURA DE LA EPIFISIS INFERIOR DEL RADIO'},
{ codigo: 'S526', descripcion: 'FRACTURA DE LA EPIFISIS INFERIOR DEL CUBITO Y DEL RADIO'},
{ codigo: 'S527', descripcion: 'FRACTURAS MULTIPLES DEL ANTEBRAZO'},
{ codigo: 'S528', descripcion: 'FRACTURA DE OTRAS PARTES DEL ANTEBRAZO'},
{ codigo: 'S529', descripcion: 'FRACTURA DEL ANTEBRAZO, PARTE NO ESPECIFICADA'},
{ codigo: 'S53', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DEL CODO'},
{ codigo: 'S530', descripcion: 'LUXACION DE LA CABEZA DEL RADIO'},
{ codigo: 'S531', descripcion: 'LUXACION DEL CODO, NO ESPECIFICADA'},
{ codigo: 'S532', descripcion: 'RUPTURA TRAUMATICA DEL LIGAMENTO LATERAL DEL RADIO'},
{ codigo: 'S533', descripcion: 'RUPTURA TRAUMATICA DEL LIGAMENTO LATERAL DEL CUBITO'},
{ codigo: 'S534', descripcion: 'ESGUINCES Y TORCEDURAS DEL CODO'},
{ codigo: 'S54', descripcion: 'TRAUMATISMO DE NERVIOS A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S540', descripcion: 'TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S541', descripcion: 'TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S542', descripcion: 'TRAUMATISMO DEL NERVIO RADIAL A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S543', descripcion: 'TRAUMATISMO DEL NERVIO SENSORIAL CUTANEO A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S547', descripcion: 'TRAUMATISMO DE MULTIPLES NERVIOS A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S548', descripcion: 'TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S549', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S55', descripcion: 'TRAUMATISMO DE LOS VASOS SANGUINEOS A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S550', descripcion: 'TRAUMATISMO DE LA ARTERIA CUBITAL A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S551', descripcion: 'TRAUMATISMO DE LA ARTERIA RADIAL A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S552', descripcion: 'TRAUMATISMO DE VENA A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S557', descripcion: 'TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S558', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S559', descripcion: 'TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S56', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S560', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO FLEXOR DEL PULGAR A NIVEL DE ANTEBRAZO'},
{ codigo: 'S561', descripcion: 'TRAUMATISMO TENDON Y MUSCULO FLEXOR DE OTRO(S) DEDO(S) NIVEL ANTEBRAZO'},
{ codigo: 'S562', descripcion: 'TRAUMATISMO DE OTRO TENDON Y MUSCULO FLEXOR A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S563', descripcion: 'TRAUMATISMO DE TENDONES Y MUSCULOS ABDUCTORES Y EXTENSORES DEL PULGAR A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S564', descripcion: 'TRAUMATISMO TENDON Y MUSCULO EXTENSOR OTRO(S) DEDO(S) NIVEL ANTEBRAZO'},
{ codigo: 'S565', descripcion: 'TRAUMATISMO DE OTRO TENDON Y MUSCULO EXTENSOR A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S567', descripcion: 'TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DEL ANTEBRAZO'},
{ codigo: 'S568', descripcion: 'TRAUMATISMO DE OTROS TENDONES Y MUSCULOS Y DE LOS NO ESPECIFICADOS,A NIVEL ANTEBRAZO'},
{ codigo: 'S57', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL ANTEBRAZO'},
{ codigo: 'S570', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL CODO'},
{ codigo: 'S578', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL ANTEBRAZO'},
{ codigo: 'S579', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL ANTEBRAZO, PARTE NO ESPECIFICADA'},
{ codigo: 'S58', descripcion: 'AMPUTACION TRAUMATICA DEL ANTEBRAZO'},
{ codigo: 'S580', descripcion: 'AMPUTACION TRAUMATICA A NIVEL DEL CODO'},
{ codigo: 'S581', descripcion: 'AMPUTACION TRAUMATICA NIVEL ENTRE EL CODO Y LA MU—ECA'},
{ codigo: 'S589', descripcion: 'AMPUTACION TRAUMATICA DEL ANTEBRAZO, NIVEL NO ESPECIFICADO'},
{ codigo: 'S59', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL ANTEBRAZO'},
{ codigo: 'S597', descripcion: 'TRAUMATISMOS MULTIPLES DEL ANTEBRAZO'},
{ codigo: 'S598', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DEL ANTEBRAZO'},
{ codigo: 'S599', descripcion: 'TRAUMATISMO NO ESPECIFICADO DEL ANTEBRAZO'},
{ codigo: 'S60', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S600', descripcion: 'CONTUSION DE DEDO(S) DE LA MANO, SIN DA—O DE LA(S) U—A(S)'},
{ codigo: 'S601', descripcion: 'CONTUSION DE DEDO(S) DE LA MANO CON DA—O DE LA(S) U—A(S)'},
{ codigo: 'S602', descripcion: 'CONTUSION DE OTRAS PARTES DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S607', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S608', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S609', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA MU—ECA Y DE LA MANO, NO ESPECIFICADO'},
{ codigo: 'S61', descripcion: 'HERIDA DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S610', descripcion: 'HERIDA DE DEDO(S) DE LA MANO, SIN DA—O DE LA(S) U—A(S)'},
{ codigo: 'S611', descripcion: 'HERIDA DE DEDO(S) DE LA MANO, CON DA—O DE LA(S) U—A(S)'},
{ codigo: 'S617', descripcion: 'HERIDAS MULTIPLES DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S618', descripcion: 'HERIDA DE OTRAS PARTES DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S619', descripcion: 'HERIDA DE LA MU—ECA Y DE LA MANO, PARTE NO ESPECIFICADA'},
{ codigo: 'S62', descripcion: 'FRACTURA A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S620', descripcion: 'FRACTURA DEL HUESO ESCAFOIDES (NAVICULAR) DE LA MANO'},
{ codigo: 'S621', descripcion: 'FRACTURA DE OTRO(S) HUESO(S) DEL CARPO'},
{ codigo: 'S622', descripcion: 'FRACTURA DEL PRIMER METACARPIANO'},
{ codigo: 'S623', descripcion: 'FRACTURA DE OTROS HUESOS METACARPIANOS'},
{ codigo: 'S624', descripcion: 'FRACTURAS MULTIPLES DE HUESOS METACARPIANOS'},
{ codigo: 'S625', descripcion: 'FRACTURA DEL PULGAR'},
{ codigo: 'S626', descripcion: 'FRACTURA DE OTRO DEDO DE LA MANO'},
{ codigo: 'S627', descripcion: 'FRACTURAS MULTIPLES DE LOS DEDOS DE LA MANO'},
{ codigo: 'S628', descripcion: 'FRACTURA DE OTRAS PARTES Y DE LAS NO ESPECIFICADS DE LA MU—ECA Y LA MANO'},
{ codigo: 'S63', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S630', descripcion: 'LUXACION DE LA MU—ECA'},
{ codigo: 'S631', descripcion: 'LUXACION DE DEDOS DE LA MANO'},
{ codigo: 'S632', descripcion: 'LUXACIONES MULTIPLES DE DEDOS DE LA MANO'},
{ codigo: 'S633', descripcion: 'RUPTURA TRAUMATICA DE LIGAMENTOS DE LA MU—ECA Y DEL CARPO'},
{ codigo: 'S634', descripcion: 'RUPTURA TRAUMATICA DE LIGAMENTOS DEL DEDO DE LA MANO EN LA(S) ARTICULACION(ES) METACARPOFALANGICA E INTERFALANGICA'},
{ codigo: 'S635', descripcion: 'ESGUINCE Y TORCEDURA DE LA MU—ECA'},
{ codigo: 'S636', descripcion: 'ESGUINCES Y TORCEDURAS DE DEDO(S) DE LA MANO'},
{ codigo: 'S637', descripcion: 'ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE MU—ECA Y MANO'},
{ codigo: 'S64', descripcion: 'TRAUMATISMO DE NERVIOS A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S640', descripcion: 'TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S641', descripcion: 'TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S642', descripcion: 'TRAUMATISMO DEL NERVIO RADIAL A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S643', descripcion: 'TRAUMATISMO DEL NERVIO DIGITAL DEL PULGAR'},
{ codigo: 'S644', descripcion: 'TRAUMATISMO DEL NERVIO DIGITAL DE OTRO DEDO'},
{ codigo: 'S647', descripcion: 'TRAUMATISMO DE MULTIPLES NERVIOS A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S648', descripcion: 'TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S649', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S65', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S650', descripcion: 'TRAUMATISMO DE LA ARTERIA CUBITAL A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S651', descripcion: 'TRAUMATISMO DE LA ARTERIA RADIAL A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S652', descripcion: 'TRAUMATISMO DEL ARCO PALMAR SUPERFICIAL'},
{ codigo: 'S653', descripcion: 'TRAUMATISMO DEL ARCO PALMAR PROFUNDO'},
{ codigo: 'S654', descripcion: 'TRAUMATISMO DE VASO(S) SANGUINEO(S) DEL PULGAR'},
{ codigo: 'S655', descripcion: 'TRAUMATISMO DE VASO(S) SANGUINEO(S) DE OTRO DEDO'},
{ codigo: 'S657', descripcion: 'TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DE LA MU—ECA Y MANO'},
{ codigo: 'S658', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S659', descripcion: 'TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO, A NIVEL DE LA MU—ECA Y MANO'},
{ codigo: 'S66', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S660', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO FLEXOR LARGO DEL PULGAR A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S661', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO FLEXOR DE OTRO DEDO A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S662', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO EXTENSOR DEL PULGAR A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S663', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO EXTENSOR DE OTRO(S) DEDO(S) A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S664', descripcion: 'TRAUMATISMO DEL MUSCULO Y TENDON INTRINSECO DEL PULGAR A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S665', descripcion: 'TRAUMATISMO DEL MUSCULO Y TENDON INTRINSECO DE OTRO(S) DEDO(S) A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S666', descripcion: 'TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS FLEXORES A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S667', descripcion: 'TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS EXTENSORES A NIVEL DE LA MU—ECA Y LA MANO'},
{ codigo: 'S668', descripcion: 'TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DE LA MU—ECA Y MANO'},
{ codigo: 'S669', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADO, A NIVEL DE LA MU—ECA'},
{ codigo: 'S67', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S670', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL PULGAR Y OTRO(S) DEDO(S)'},
{ codigo: 'S678', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y LAS NO ESPECICADAS DE LA MU—ECA Y LA MANO'},
{ codigo: 'S68', descripcion: 'AMPUTACION TRAUMATICA DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S680', descripcion: 'AMPUTACION TRAUMATICA DEL PULGAR (COMPLETA) (PARCIAL)'},
{ codigo: 'S681', descripcion: 'AMPUTACION TRAUMATICA DE OTRO DEDO UNICO (COMPLETA) (PARCIAL)'},
{ codigo: 'S682', descripcion: 'AMPUTACION TRAUMATICA DE DOS O MAS DEDOS SOLAMENTE (COMPLETA)(PARCIAL)'},
{ codigo: 'S683', descripcion: 'AMPUTACION TRAUMATICA COMBINADA DE PARTE(S) DEDO(S) CON OTRAS PARTES DE LA MU—ECA Y LA MANO'},
{ codigo: 'S684', descripcion: 'AMPUTACION TRAUMATICA DE LA MANO A NIVEL DE LA MU—ECA'},
{ codigo: 'S688', descripcion: 'AMPUTACION TRAUMATICA DE OTRAS PARTES DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S689', descripcion: 'AMPUTACION TRAUMATICA DE LA MU—ECA Y DE LA MANO, NIVEL NO ESPECIFICADO'},
{ codigo: 'S69', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S697', descripcion: 'TRAUMATISMOS MULTIPLES DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S698', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S699', descripcion: 'TRAUMATISMO NO ESPECIFICADO DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'S70', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S700', descripcion: 'CONTUSION DE LA CADERA'},
{ codigo: 'S701', descripcion: 'CONTUSION DEL MUSLO'},
{ codigo: 'S707', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S708', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S709', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA CADERA Y DEL MUSLO, NO ESPECIFICADO'},
{ codigo: 'S71', descripcion: 'HERIDA DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S710', descripcion: 'HERIDA DE LA CADERA'},
{ codigo: 'S711', descripcion: 'HERIDA DEL MUSLO'},
{ codigo: 'S717', descripcion: 'HERIDAS MULTIPLES DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S718', descripcion: 'HERIDA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CINTURA PELVICA'},
{ codigo: 'S72', descripcion: 'FRACTURA DEL FEMUR'},
{ codigo: 'S720', descripcion: 'FRACTURA DEL CUELLO DE FEMUR'},
{ codigo: 'S721', descripcion: 'FRACTURA PERTROCANTERIANA'},
{ codigo: 'S722', descripcion: 'FRACTURA SUBTROCANTERIANA'},
{ codigo: 'S723', descripcion: 'FRACTURA DE LA DIAFISIS DEL FEMUR'},
{ codigo: 'S724', descripcion: 'FRACTURA DE LA EPIFISIS INFERIOR DEL FEMUR'},
{ codigo: 'S727', descripcion: 'FRACTURAS MULTIPLES DEL FEMUR'},
{ codigo: 'S728', descripcion: 'FRACTURAS DE OTRAS PARTES DEL FEMUR'},
{ codigo: 'S729', descripcion: 'FRACTURA DEL FEMUR, PARTE NO ESPECIFICADA'},
{ codigo: 'S73', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACION Y LIGAMENTOS DE CADERA'},
{ codigo: 'S730', descripcion: 'LUXACION DE LA CADERA'},
{ codigo: 'S731', descripcion: 'ESGUINCES Y TORCEDURAS DE LA CADERA'},
{ codigo: 'S74', descripcion: 'TRAUMATISMO DE NERVIOS A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S740', descripcion: 'TRAUMATISMO DEL NERVIO CIATICO A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S741', descripcion: 'TRAUMATISMO DEL NERVIO FEMOROCUTANEO A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S742', descripcion: 'TRAUMATISMO DEL NERVIO SENSORIAL CUTANEO A NIVEL DE CADERA Y MUSCULO'},
{ codigo: 'S747', descripcion: 'TRAUMATISMO DE NERVIOS MULTIPLES A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S748', descripcion: 'TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S749', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S75', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S750', descripcion: 'TRAUMATISMO DE LA ARTERIA FEMORAL'},
{ codigo: 'S751', descripcion: 'TRAUMATISMO DE LA VENA FEMORAL A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S752', descripcion: 'TRAUMATISMO DE LA GRAN VENA SAFENA A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S757', descripcion: 'TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DE LA CADERA Y MUSLO'},
{ codigo: 'S758', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S759', descripcion: 'TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DE LA CADERA Y DE MUSLO'},
{ codigo: 'S76', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S760', descripcion: 'TRAUMATISMO DEL TENDON Y MUSCULO DE LA CADERA'},
{ codigo: 'S761', descripcion: 'TRAUMATISMO DEL TENDON Y MUSCULO CUADRICEPS'},
{ codigo: 'S762', descripcion: 'TRAUMATISMO DEL TENDON Y MUSCULO ABDUCTOR MAYOR DEL MUSLO'},
{ codigo: 'S763', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO DEL GRUPO MUSCULAR POSTERIOR A NIVEL DEL MUSLO'},
{ codigo: 'S764', descripcion: 'TRAUMATISMO DE OTROS TENDONES Y MUSCULOS Y DE LOS NO ESPECIFICADOS A NIVEL DEL MUSLO'},
{ codigo: 'S767', descripcion: 'TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DE CADERA Y MUSLO'},
{ codigo: 'S77', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S770', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA CADERA'},
{ codigo: 'S771', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL MUSLO'},
{ codigo: 'S772', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA CADERA CON EL MUSLO'},
{ codigo: 'S78', descripcion: 'AMPUTACION TRAUMATICA DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S780', descripcion: 'AMPUTACION TRAUMATICA DE LA ARTICULACION DE LA CADERA'},
{ codigo: 'S781', descripcion: 'AMPUTACION TRAUMATICA EN ALGUN NIVEL ENTRE LA CADERA Y LA RODILLA'},
{ codigo: 'S789', descripcion: 'AMPUTACION TRAUMATICA DE CADERA Y MUSLO, NIVEL NO ESPECIFICADO'},
{ codigo: 'S79', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S797', descripcion: 'TRAUMATISMOS MULTIPLES DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S798', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S799', descripcion: 'TRAUMATISMO NO ESPECIFICADO DE LA CADERA Y DEL MUSLO'},
{ codigo: 'S80', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA PIERNA'},
{ codigo: 'S800', descripcion: 'CONTUSION DE LA RODILLA'},
{ codigo: 'S801', descripcion: 'CONTUSION DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA PIERNA'},
{ codigo: 'S807', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA PIERNA'},
{ codigo: 'S808', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DE LA PIERNA'},
{ codigo: 'S809', descripcion: 'TRAUMATISMO SUPERFICIAL DE LA PIERNA, NO ESPECIFICADO'},
{ codigo: 'S81', descripcion: 'HERIDA DE LA PIERNA'},
{ codigo: 'S810', descripcion: 'HERIDA DE LA RODILLA'},
{ codigo: 'S817', descripcion: 'HERIDAS MULTIPLES DE LA PIERNA'},
{ codigo: 'S818', descripcion: 'HERIDA DE OTRAS PARTES DE LA PIERNA'},
{ codigo: 'S819', descripcion: 'HERIDA DE LA PIERNA, PARTE NO ESPECIFICADA'},
{ codigo: 'S82', descripcion: 'FRACTURA DE LA PIERNA, INCLUSIVE EL TOBILLO'},
{ codigo: 'S820', descripcion: 'FRACTURA DE LA ROTULA'},
{ codigo: 'S821', descripcion: 'FRACTURA DE LA EPIFISIS SUPERIOR DE LA TIBIA'},
{ codigo: 'S822', descripcion: 'FRACTURA DE LA DIAFISIS DE LA TIBIA'},
{ codigo: 'S823', descripcion: 'FRACTURA DE LA EPIFISIS INFERIOR DE LA TIBIA'},
{ codigo: 'S824', descripcion: 'FRACTURA DEL PERONE SOLAMENTE'},
{ codigo: 'S825', descripcion: 'FRACTURA DEL MALEOLO INTERNO'},
{ codigo: 'S826', descripcion: 'FRACTURA DEL MALEOLO EXTERNO'},
{ codigo: 'S827', descripcion: 'FRACTURAS MULTIPLES DE LA PIERNA'},
{ codigo: 'S828', descripcion: 'FRACTURA DE OTRAS PARTES DE LA PIERNA'},
{ codigo: 'S829', descripcion: 'FRACTURA DE LA PIERNA, PARTE NO ESPECIFICADA'},
{ codigo: 'S83', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE LA RODILLA'},
{ codigo: 'S830', descripcion: 'LUXACION DE LA ROTULA'},
{ codigo: 'S831', descripcion: 'LUXACION DE LA RODILLA'},
{ codigo: 'S832', descripcion: 'DESGARRO DE MENISCOS, PRESENTE'},
{ codigo: 'S833', descripcion: 'DESGARRO DEL CARTILAGO ARTICULAR DE LA RODILLA, PRESENTE'},
{ codigo: 'S834', descripcion: 'ESGUINCES Y TORCEDURAS QUE COMPROMETEN LOS LIGAMENTOS LATERALES (EXTERNO)(INTERNOS) DE RODILLA'},
{ codigo: 'S835', descripcion: 'ESGUINCES Y TORCEDURAS QUE COMPROMETEN EL LIGAMENTO CRUZADO (ANTERIOR) (POSTERIOR) DE LA RODILLA'},
{ codigo: 'S836', descripcion: 'ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA RODILLA'},
{ codigo: 'S837', descripcion: 'TRAUMATISMO DE ESTRUCTURAS MULTIPLES DE LA RODILLA'},
{ codigo: 'S84', descripcion: 'TRAUMATISMO DE NERVIOS A NIVEL DE LA PIERNA'},
{ codigo: 'S840', descripcion: 'TRAUMATISMO DEL NERVIO TIBIAL A NIVEL DE LA PIERNA'},
{ codigo: 'S841', descripcion: 'TRAUMATISMO DEL NERVIO PERONEO A NIVEL DE LA PIERNA'},
{ codigo: 'S842', descripcion: 'TRAUMATISMO DEL NERVIO SENSORIAL CUTANEO A NIVEL DE LA PIERNA'},
{ codigo: 'S847', descripcion: 'TRAUMATISMO DE NERVIOS MULTIPLES A NIVEL DE LA PIERNA'},
{ codigo: 'S848', descripcion: 'TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA PIERNA'},
{ codigo: 'S849', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA PIERNA'},
{ codigo: 'S85', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DE LA PIERNA'},
{ codigo: 'S850', descripcion: 'TRAUMATISMO DE LA ARTERIA POPLITEA'},
{ codigo: 'S851', descripcion: 'TRAUMATISMO DE LA ARTERIA TIBIAL (ANTERIOR) (POSTERIOR)'},
{ codigo: 'S852', descripcion: 'TRAUMATISMO DE LA ARTERIA PERONEA'},
{ codigo: 'S853', descripcion: 'TRAUMATISMO DE LA GRAN VENA SAFENA A NIVEL DE LA PIERNA'},
{ codigo: 'S854', descripcion: 'TRAUMATISMO DE LA VENA SAFENA EXTERNA A NIVEL DE LA PIERNA'},
{ codigo: 'S855', descripcion: 'TRAUMATISMO DE LA VENA POPLITEA'},
{ codigo: 'S857', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS MULTIPLES A NIVEL DE LA PIERNA'},
{ codigo: 'S858', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DE LA PIERNA'},
{ codigo: 'S859', descripcion: 'TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DE LA PIERNA'},
{ codigo: 'S86', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DE LA PIERNA'},
{ codigo: 'S860', descripcion: 'TRAUMATISMO DEL TENDON DE AQUILES'},
{ codigo: 'S861', descripcion: 'TRAUMATISMO OTRO(S) TENDON(ES) Y MUSCULO(S) DEL GRUPO MUSCULAR POSTERIOR A NIVEL DE LA PIERNA'},
{ codigo: 'S862', descripcion: 'TRAUMATISMO TENDON(ES) Y MUSCULO(S) DEL GRUPO MUSCULAR ANTERIOR A NIVEL DE LA PIERNA'},
{ codigo: 'S863', descripcion: 'TRAUMATISMO TENDON(ES) Y MUSCULO(S) DEL GRUPO MUSCULAR PERONEO A NIVEL DE LA PIERNA'},
{ codigo: 'S867', descripcion: 'TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DE LA PIERNA'},
{ codigo: 'S868', descripcion: 'TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DE LA PIERNA'},
{ codigo: 'S869', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADO A NIVEL DE LA PIERNA'},
{ codigo: 'S87', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA PIERNA'},
{ codigo: 'S870', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE LA RODILLA'},
{ codigo: 'S878', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE NO ESPECIFICADAS DE LA PIERNA'},
{ codigo: 'S88', descripcion: 'AMPUTACION TRAUMATICA DE LA PIERNA'},
{ codigo: 'S880', descripcion: 'AMPUTACION TRAUMATICA A NIVEL DE LA RODILLA'},
{ codigo: 'S881', descripcion: 'AMPUTACION TRAUMATICA EN ALGUN NIVEL ENTRE LA RODILLA Y EL TOBILLO'},
{ codigo: 'S889', descripcion: 'AMPUTACION TRAUMATICA DE LA PIERNA, NIVEL NO ESPECIFICADO'},
{ codigo: 'S89', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA PIERNA'},
{ codigo: 'S897', descripcion: 'TRAUMATISMOS MULTIPLES DE LA PIERNA'},
{ codigo: 'S898', descripcion: 'OTROS TRAUMATISMOS DE LA PIERNA, ESPECIFICADOS'},
{ codigo: 'S899', descripcion: 'TRAUMATISMO DE LA PIERNA, NO ESPECIFICADO'},
{ codigo: 'S90', descripcion: 'TRAUMATISMO SUPERFICIAL DEL TOBILLO Y DEL PIE'},
{ codigo: 'S900', descripcion: 'CONTUSION DEL TOBILLO'},
{ codigo: 'S901', descripcion: 'CONTUSION DE DEDO(S) DEL PIE SIN DA—O DE LA(S) U—A(S)'},
{ codigo: 'S902', descripcion: 'CONTUSION DE DEDO(S) DEL PIE CON DA—O DE LA(S) U—A(S)'},
{ codigo: 'S903', descripcion: 'CONTUSION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL PIE'},
{ codigo: 'S907', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES DEL PIE Y DEL TOBILLO'},
{ codigo: 'S908', descripcion: 'OTROS TRAUMATISMOS SUPERFICIALES DEL PIE Y DEL TOBILLO'},
{ codigo: 'S909', descripcion: 'TRAUMATISMO SUPERFICIAL DEL PIE Y DEL TOBILLO, NO ESPECIFICADO'},
{ codigo: 'S91', descripcion: 'HERIDA DEL TOBILLO Y DEL PIE'},
{ codigo: 'S910', descripcion: 'HERIDA DEL TOBILLO'},
{ codigo: 'S911', descripcion: 'HERIDA DE DEDO(S) DEL PIE SIN DA—O DE LA(S) U—A(S)'},
{ codigo: 'S912', descripcion: 'HERIDA DE DEDO(S) DEL PIE CON DA—O DE LA(S) U—A(S)'},
{ codigo: 'S913', descripcion: 'HERIDA DE OTRAS PARTES DEL PIE'},
{ codigo: 'S917', descripcion: 'HERIDAS MULTIPLES DEL TOBILLO Y DEL PIE'},
{ codigo: 'S92', descripcion: 'FRACTURA DEL PIE, EXCEPTO DEL TOBILLO'},
{ codigo: 'S920', descripcion: 'FRACTURA DEL CALCANEO'},
{ codigo: 'S921', descripcion: 'FRACTURA DEL ASTRAGALO'},
{ codigo: 'S922', descripcion: 'FRACTURA DE OTRO(S) HUESO(S) DEL TARSO'},
{ codigo: 'S923', descripcion: 'FRACTURA DE HUESO DEL METATARSO'},
{ codigo: 'S924', descripcion: 'FRACTURA DE LOS HUESOS DEL DEDO GORDO DEL PIE'},
{ codigo: 'S925', descripcion: 'FRACTURA DE LOS HUESOS DE OTRO(S) DEDO(S) DEL PIE'},
{ codigo: 'S927', descripcion: 'FRACTURAS MULTIPLES DEL PIE'},
{ codigo: 'S929', descripcion: 'FRACTURA DEL PIE, NO ESPECIFICADA'},
{ codigo: 'S93', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE ARTICULACIONES Y LIGAMENTOS DE TOBILLO Y PIE'},
{ codigo: 'S930', descripcion: 'LUXACION DE LA ARTICULACION DEL TOBILLO'},
{ codigo: 'S931', descripcion: 'LUXACION DE DEDO(S) DEL PIE'},
{ codigo: 'S932', descripcion: 'RUPTURA DE LIGAMENTOS A NIVEL DEL TOBILLO Y DEL PIE'},
{ codigo: 'S933', descripcion: 'LUXACION DE OTROS SITIOS Y LOS NO ESPECIFICADOS DEL PIE'},
{ codigo: 'S934', descripcion: 'ESGUINCES Y TORCEDURAS DEL TOBILLO'},
{ codigo: 'S935', descripcion: 'ESGUINCES Y TORCEDURAS DE DEDO(S) DEL PIE'},
{ codigo: 'S936', descripcion: 'ESGUINCES Y TORCEDURAS DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS DEL PIE'},
{ codigo: 'S94', descripcion: 'TRAUMATISMO DE NERVIOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S940', descripcion: 'TRAUMATISMO DEL NERVIO PLANTAR EXTERNO'},
{ codigo: 'S941', descripcion: 'TRAUMATISMO DEL NERVIO PLANTAR INTERNO'},
{ codigo: 'S942', descripcion: 'TRAUMATISMO DEL NERVIO PERONEAL PROFUNDO A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S943', descripcion: 'TRAUMATISMO DE NERVIO SENSORIAL CUTANEO A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S947', descripcion: 'TRAUMATISMO DE MULTIPLES NERVIOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S948', descripcion: 'TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S949', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S95', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S950', descripcion: 'TRAUMATISMO DE LA ARTERIA DORSAL DEL PIE'},
{ codigo: 'S951', descripcion: 'TRAUMATISMO DE LA ARTERIA PLANTAR DEL PIE'},
{ codigo: 'S952', descripcion: 'TRAUMATISMO DE LA VENA DORSAL DEL PIE'},
{ codigo: 'S957', descripcion: 'TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL PIE Y DE TOBILLO'},
{ codigo: 'S958', descripcion: 'TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S959', descripcion: 'TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S96', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S960', descripcion: 'TRAUMATISMO TENDON Y MUSCULO DEL FLEXOR LARGO DE DEDO A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S961', descripcion: 'TRAUMATISMO TENDON Y MUSCULO EXTENSOR LARGO DEL (DE LOS) DEDO(S) A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S962', descripcion: 'TRAUMATISMO DE TENDONES Y MUSCULOS INTRINSECOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S967', descripcion: 'TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S968', descripcion: 'TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S969', descripcion: 'TRAUMATISMO DE TENDONES Y MUSCULOS NO ESPECIFICADOS A NIVEL DEL PIE Y DEL TOBILLO'},
{ codigo: 'S97', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL PIE Y DEL TOBILLO'},
{ codigo: 'S970', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DEL TOBILLO'},
{ codigo: 'S971', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE DEDO(S) DEL PIE'},
{ codigo: 'S978', descripcion: 'TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL PIE Y DEL TOBILLO'},
{ codigo: 'S98', descripcion: 'AMPUTACION TRAUMATICA DEL PIE Y DEL TOBILLO'},
{ codigo: 'S980', descripcion: 'AMPUTACION TRAUMATICA DEL PIE A NIVEL DEL TOBILLO'},
{ codigo: 'S981', descripcion: 'AMPUTACION TRAUMATICA DE UN DEDO DEL PIE'},
{ codigo: 'S982', descripcion: 'AMPUTACION TRAUMATICA DE DOS O MAS DEDOS DEL PIE'},
{ codigo: 'S983', descripcion: 'AMPUTACION TRAUMATICA DE OTRAS PARTES DEL PIE'},
{ codigo: 'S984', descripcion: 'AMPUTACION DEL PIE, NIVEL NO ESPECIFICADO'},
{ codigo: 'S99', descripcion: 'OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DEL PIE Y DEL TOBILLO'},
{ codigo: 'S997', descripcion: 'TRAUMATISMOS MULTIPLES DEL PIE Y DEL TOBILLO'},
{ codigo: 'S998', descripcion: 'OTROS TRAUMATISMOS DEL PIE Y DEL TOBILLO, ESPECIFICADOS'},
{ codigo: 'S999', descripcion: 'TRAUMATISMO DEL PIE Y DEL TOBILLO, NO ESPECIFICADO'},
{ codigo: 'T00', descripcion: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T000', descripcion: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN LA CABEZA CON EL CUELLO'},
{ codigo: 'T001', descripcion: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN EL TORAX CON ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'T002', descripcion: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) SUPERIOR(ES)'},
{ codigo: 'T003', descripcion: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL (OS) MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T006', descripcion: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T008', descripcion: 'TRAUMATISMOS SUPERFICIALES QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO'},
{ codigo: 'T009', descripcion: 'TRAUMATISMOS SUPERFICIALES MULTIPLES, NO ESPECIFICADOS'},
{ codigo: 'T01', descripcion: 'HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T010', descripcion: 'HERIDAS QUE AFECTAN LA CABEZA CON EL CUELLO'},
{ codigo: 'T011', descripcion: 'HERIDAS QUE AFECTAN EL TORAX CON ABDOMEN, REGION LUMBOSACRA Y PELVIS'},
{ codigo: 'T012', descripcion: 'HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) SUPERIOR(ES)'},
{ codigo: 'T013', descripcion: 'HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T016', descripcion: 'HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T018', descripcion: 'HERIDAS QUE AFECTAN OTRAS COMBINACIONES DE LAS REGIONES DEL CUERPO'},
{ codigo: 'T019', descripcion: 'HERIDAS MULTIPLES, NO ESPECIFICADAS'},
{ codigo: 'T02', descripcion: 'FRACTURAS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T020', descripcion: 'FRACTURAS QUE AFECTAN LA CABEZA CON EL CUELLO'},
{ codigo: 'T021', descripcion: 'FRACTURAS QUE AFECTAN EL TORAX CON LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'T022', descripcion: 'FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE UN MIEMBRO SUPERIOR'},
{ codigo: 'T023', descripcion: 'FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE UN MIEMBRO INFERIOR'},
{ codigo: 'T024', descripcion: 'FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE AMBOS MIEMBROS SUPERIORES'},
{ codigo: 'T025', descripcion: 'FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE AMBOS MIEMBROS INFERIORES'},
{ codigo: 'T026', descripcion: 'FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T027', descripcion: 'FRACTURAS QUE AFECTAN EL TORAX CON REGION LUMBOSACRA Y PELVIS CON MIEMBRO(S)'},
{ codigo: 'T028', descripcion: 'FRACTURAS QUE AFECTAN OTRAS COMBINACIONES DE LAS REGIONES DEL CUERPO'},
{ codigo: 'T029', descripcion: 'FRACTURAS MULTIPLES, NO ESPECIFICADAS'},
{ codigo: 'T03', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T030', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN LA CABEZA CON EL CUELLO'},
{ codigo: 'T031', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN EL TORAX CON LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'T032', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)'},
{ codigo: 'T033', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) INFERIOR(ES'},
{ codigo: 'T034', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T038', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO'},
{ codigo: 'T039', descripcion: 'LUXACIONES, TORCEDURAS Y ESGUINCES MULTIPLES, NO ESPECIFICADOS'},
{ codigo: 'T04', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T040', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN LA CABEZA CON EL CUELLO'},
{ codigo: 'T041', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN EL TORAX CON EL ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'T042', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DE MIEMBRO(S) SUPERIOR(ES)'},
{ codigo: 'T043', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DE MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T044', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DE MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)'},
{ codigo: 'T047', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO DEL TORAX, EL ABDOMEN, AL REGION LUMBOSACRA Y LA PELVIS CON MIEMBRO(S)'},
{ codigo: 'T048', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO'},
{ codigo: 'T049', descripcion: 'TRAUMATISMOS POR APLASTAMIENTO MULTIPLE, NO ESPECIFICADOS'},
{ codigo: 'T05', descripcion: 'AMPUTACIONES TRAUMATICAS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T050', descripcion: 'AMPUTACION TRAUMATICA DE AMBAS MANOS'},
{ codigo: 'T051', descripcion: 'AMPUTACION TRAUMATICA MANO Y EL OTRO BRAZO (CUALQUIER NIVEL, EXCEPTO MANO)'},
{ codigo: 'T052', descripcion: 'AMPUTACION TRAUMATICA DE AMBOS BRAZOS (CUALQUIER NIVEL)'},
{ codigo: 'T053', descripcion: 'AMPUTACION TRAUMATICA DE AMBOS PIES'},
{ codigo: 'T054', descripcion: 'AMPUTACION TRAUMATICA PIE Y LA OTRA PIERNA (CUALQUIER NIVEL, EXCEPTO PIE)'},
{ codigo: 'T055', descripcion: 'AMPUTACION TRAUMATICA DE AMBAS PIERNAS (CUALQUIER NIVEL)'},
{ codigo: 'T056', descripcion: 'AMPUTACION TRAUMATICA DE MIEMBRO(S) SUPERIOR(ES) E INFERIOR(ES), CUALQUIER COMBINACION (CUALQUIER NIVEL)'},
{ codigo: 'T058', descripcion: 'AMPUTACION TRAUMATICA QUE AFECTA OTRAS COMBINACIONES DE REGIONES DEL CUERPO'},
{ codigo: 'T059', descripcion: 'AMPUTACIONES TRAUMATICAS MULTIPLES, NO ESPECIFICADAS'},
{ codigo: 'T06', descripcion: 'OTROS TRAUMATISMOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T060', descripcion: 'TRAUMATISMOS DEL ENCEFALO Y NERVIOS CRANEALES CON TRAUMATISMO DE NERVIOS Y MEDULA ESPINAL A NIVEL DE CUELLO'},
{ codigo: 'T061', descripcion: 'TRAUMATISMO DE NERVIOS Y MEDULA ESPINAL QUE AFECTAN OTRAS MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T062', descripcion: 'TRAUMATISMOS DE NERVIOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T063', descripcion: 'TRAUMATISMOS DE VASOS SANGUINEOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T064', descripcion: 'TRAUMATISMOS DE TENDONES Y MUSCULOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T065', descripcion: 'TRAUMATISMOS DE ORGANOS INTRATORACICOS CON ORGANOS INTRAABDOMINALES Y PELVICOS'},
{ codigo: 'T068', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T07', descripcion: 'TRAUMATISMOS MULTIPLES, NO ESPECIFICADOS'},
{ codigo: 'T08', descripcion: 'FRACTURA DE LA COLUMNA VERTEBRAL, NIVEL NO ESPECIFICADO'},
{ codigo: 'T09', descripcion: 'OTROS TRAUMATISMOS DE COLUMNA VERTEBRAL Y DEL TRONCO, NIVEL NO ESPECIFICADO'},
{ codigo: 'T090', descripcion: 'TRAUMATISMO SUPERFICIAL DEL TRONCO, NIVEL NO ESPECIFICADO'},
{ codigo: 'T091', descripcion: 'HERIDA DEL TRONCO, NIVEL NO ESPECIFICADO'},
{ codigo: 'T092', descripcion: 'LUXACION, ESGUINCE O TORCEDURA DE ARTICULACION Y LIGAMENTOS DEL TRONCO, NO ESPECIFICADOS'},
{ codigo: 'T093', descripcion: 'TRAUMATISMO DE LA MEDULA ESPINAL, NIVEL NO ESPECIFICADO'},
{ codigo: 'T094', descripcion: 'TRAUMATISMO DE NERVIOS, RAIZ NERVIO ESPINAL Y PLEXOS DE TRONCO NO ESPECIFICADOS'},
{ codigo: 'T095', descripcion: 'TRAUMATISMO DE TENDONES Y MUSCULOS DEL TRONCO NO ESPECIFICADOS'},
{ codigo: 'T096', descripcion: 'AMPUTACION TRAUMATICA DEL TRONCO, NIVEL NO ESPECIFICADO'},
{ codigo: 'T098', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DEL TRONCO, NIVEL NO ESPECIFICADO'},
{ codigo: 'T099', descripcion: 'TRAUMATISMO NO ESPECIFICADO DEL TRONCO, NIVEL NO ESPECIFICADO'},
{ codigo: 'T10', descripcion: 'FRACTURA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T11', descripcion: 'OTROS TRAUMATISMOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T110', descripcion: 'TRAUMATISMO SUPERFICIAL DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T111', descripcion: 'HERIDA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T112', descripcion: 'LUXACION, ESGUINCE O TORCEDURA DE ARTICULACION O LIGAMENTO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T113', descripcion: 'TRAUMATISMO DE NERVIO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T114', descripcion: 'TRAUMATISMO VASOS SANGUINEOS NO ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T115', descripcion: 'TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T116', descripcion: 'AMPUTACION TRAUMATICA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T118', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T119', descripcion: 'TRAUMATISMO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T12', descripcion: 'FRACTURA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T13', descripcion: 'OTROS TRAUMATISMOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T130', descripcion: 'TRAUMATISMO SUPERFICIAL DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T131', descripcion: 'HERIDA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T132', descripcion: 'LUXACION, ESGUINCE O TORCEDURA DE ARTICULACION Y LIGAMENTO NO ESPECIFICADO DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T133', descripcion: 'TRAUMATISMO DE NERVIOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T134', descripcion: 'TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T135', descripcion: 'TRAUMATISMO DE TENDONES Y MUSCULOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T136', descripcion: 'AMPUTACION TRAUMATICA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T138', descripcion: 'OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T139', descripcion: 'TRAUMATISMO NO ESPECIFICADO DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO'},
{ codigo: 'T14', descripcion: 'TRAUMATISMO DE REGIONES NO ESPECIFICADAS DEL CUERPO'},
{ codigo: 'T140', descripcion: 'TRAUMATISMO SUPERFICIAL DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T141', descripcion: 'HERIDA DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T142', descripcion: 'FRACTURA DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T143', descripcion: 'LUXACION, ESGUINCE Y TORCEDURA DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T144', descripcion: 'TRAUMATISMO DE NERVIO(S) DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T145', descripcion: 'TRAUMATISMO DE VASO(S) SANGUINEO(S) DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T146', descripcion: 'TRAUMATISMO DE TENDONES Y MUSCULOS DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T147', descripcion: 'TRAUMATISMO POR APLASTAMIENTO Y AMPUTACION TRAUMATATICA DE REGIONES NO ESPECIFICADAS DEL CUERPO'},
{ codigo: 'T148', descripcion: 'OTROS TRAUMATISMOS DE REGION NO ESPECIFICADA DEL CUERPO'},
{ codigo: 'T149', descripcion: 'TRAUMATISMO, NO ESPECIFICADO'},
{ codigo: 'T15', descripcion: 'CUERPO EXTRA—O EN PARTE EXTERNA DEL OJO'},
{ codigo: 'T150', descripcion: 'CUERPO EXTRA—O EN LA CORNEA'},
{ codigo: 'T151', descripcion: 'CUERPO EXTRA—O EN EL SACO CONJUNTIVAL'},
{ codigo: 'T158', descripcion: 'CUERPO EXTRA—O EN OTRAS Y EN MULTIPLES PARTES DE PARTE EXTERNA DEL OJO'},
{ codigo: 'T159', descripcion: 'CUERPO EXTRA—O EN PARTE EXTERNA DEL OJO, SITIO NO ESPECIFICADO'},
{ codigo: 'T16', descripcion: 'CUERPO EXTRA—O EN EL OIDO'},
{ codigo: 'T17', descripcion: 'CUERPO EXTRA—O EN LAS VIAS RESPIRATORIAS'},
{ codigo: 'T170', descripcion: 'CUERPO EXTRA—O EN SENO PARANASAL'},
{ codigo: 'T171', descripcion: 'CUERPO EXTRA—O EN EL ORIFICIO NASAL'},
{ codigo: 'T172', descripcion: 'CUERPO EXTRA—O EN LA FARINGE'},
{ codigo: 'T173', descripcion: 'CUERPO EXTRA—O EN LA LARINGE'},
{ codigo: 'T174', descripcion: 'CUERPO EXTRA—O EN LA TRAQUEA'},
{ codigo: 'T175', descripcion: 'CUERPO EXTRA—O EN BRONQUIOS'},
{ codigo: 'T178', descripcion: 'CUERPO EXTRA—O EN OTRAS Y EN MULTIPLES PARTES DE VIAS RESPIRATORIAS'},
{ codigo: 'T179', descripcion: 'CUERPO EXTRA—O EN LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA'},
{ codigo: 'T18', descripcion: 'CUERPO EXTRA—O EN EL TUBO DIGESTIVO'},
{ codigo: 'T180', descripcion: 'CUERPO EXTRA—O EN LA BOCA'},
{ codigo: 'T181', descripcion: 'CUERPO EXTRA—O EN EL ESOFAGO'},
{ codigo: 'T182', descripcion: 'CUERPO EXTRA—O EN EL ESTOMAGO'},
{ codigo: 'T183', descripcion: 'CUERPO EXTRA—O EN EL INTESTINO DELGADO'},
{ codigo: 'T184', descripcion: 'CUERPO EXTRA—O EN EL COLON'},
{ codigo: 'T185', descripcion: 'CUERPO EXTRA—O EN EL ANO Y EN EL RECTO'},
{ codigo: 'T188', descripcion: 'CUERPO EXTRA—O EN OTRAS Y EN MULTIPLES PARTES DEL TUBO DIGESTIVO'},
{ codigo: 'T189', descripcion: 'CUERPO EXTRA—O EN EL TUBO DIGESTIVO, PARTE NO ESPECIFICADA'},
{ codigo: 'T19', descripcion: 'CUERPO EXTRA—O EN LAS VIAS GENITOURINARIAS'},
{ codigo: 'T190', descripcion: 'CUERPO EXTRA—O EN LA URETRA'},
{ codigo: 'T191', descripcion: 'CUERPO EXTRA—O EN LA VEJIGA'},
{ codigo: 'T192', descripcion: 'CUERPO EXTRA—O EN LA VULVA Y EN LA VAGINA'},
{ codigo: 'T193', descripcion: 'CUERPO EXTRA—O EN EL UTERO (CUALQUIER PARTE)'},
{ codigo: 'T198', descripcion: 'CUERPO EXTRA—O EN OTRAS Y EN MULTIPLES PARTES DE VIAS GENITOURINARIAS'},
{ codigo: 'T199', descripcion: 'CUERPO EXTRA—O EN LAS VIAS GENITOURINARIAS, PARTE NO ESPECIFICADA'},
{ codigo: 'T20', descripcion: 'QUEMADURA Y CORROSION DE LA CABEZA Y DEL CUELLO'},
{ codigo: 'T200', descripcion: 'QUEMADURA DE LA CABEZA Y DEL CUELLO, GRADO NO ESPECIFICADO'},
{ codigo: 'T201', descripcion: 'QUEMADURA DE LA CABEZA Y DEL CUELLO, DE PRIMER GRADO'},
{ codigo: 'T202', descripcion: 'QUEMADURA DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO'},
{ codigo: 'T203', descripcion: 'QUEMADURA DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO'},
{ codigo: 'T204', descripcion: 'CORROSION DE LA CABEZA Y DEL CUELLO, GRADO NO ESPECIFICADO'},
{ codigo: 'T205', descripcion: 'CORROSION DE LA CABEZA Y DEL CUELLO, DE PRIMER GRADO'},
{ codigo: 'T206', descripcion: 'CORROSION DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO'},
{ codigo: 'T207', descripcion: 'CORROSION DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO'},
{ codigo: 'T21', descripcion: 'QUEMADURA Y CORROSION DEL TRONCO'},
{ codigo: 'T210', descripcion: 'QUEMADURA DEL TRONCO, GRADO NO ESPECIFICADO'},
{ codigo: 'T211', descripcion: 'QUEMADURA DEL TRONCO, DE PRIMER GRADO'},
{ codigo: 'T212', descripcion: 'QUEMADURA DEL TRONCO, DE SEGUNDO GRADO'},
{ codigo: 'T213', descripcion: 'QUEMADURA DEL TRONCO, DE TERCER GRADO'},
{ codigo: 'T214', descripcion: 'CORROSION DEL TRONCO, GRADO NO ESPECIFICADO'},
{ codigo: 'T215', descripcion: 'CORROSION DEL TRONCO, DE PRIMER GRADO'},
{ codigo: 'T216', descripcion: 'CORROSION DEL TRONCO, DE SEGUNDO GRADO'},
{ codigo: 'T217', descripcion: 'CORROSION DEL TRONCO, DE TERCER GRADO'},
{ codigo: 'T22', descripcion: 'QUEMADURA Y CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T220', descripcion: 'QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, GRADO NO ESPECIFICADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T221', descripcion: 'QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE PRIMER GRADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T222', descripcion: 'QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, SEGUNDO GRADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T223', descripcion: 'QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T224', descripcion: 'CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, GRADO NO ESPECIFICADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T225', descripcion: 'CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, DE PRIMER GRADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T226', descripcion: 'CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, SEGUNDO GRADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T227', descripcion: 'CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO MU—ECA Y MANO'},
{ codigo: 'T23', descripcion: 'QUEMADURA Y CORROSION DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'T230', descripcion: 'QUEMADURA DE LA MU—ECA Y DE LA MANO, GRADO NO ESPECIFICADO'},
{ codigo: 'T231', descripcion: 'QUEMADURA DE LA MU—ECA Y DE LA MANO, DE PRIMER GRADO'},
{ codigo: 'T232', descripcion: 'QUEMADURA DE LA MU—ECA Y DE LA MANO, DE SEGUNDO GRADO'},
{ codigo: 'T233', descripcion: 'QUEMADURA DE LA MU—ECA Y DE LA MANO, DE TERCER GRADO'},
{ codigo: 'T234', descripcion: 'CORROSION DE LA MU—ECA Y DE LA MANO, GRADO NO ESPECIFICADO'},
{ codigo: 'T235', descripcion: 'CORROSION DE LA MU—ECA Y DE LA MANO, DE PRIMER GRADO'},
{ codigo: 'T236', descripcion: 'CORROSION DE LA MU—ECA Y DE LA MANO, DE SEGUNDO GRADO'},
{ codigo: 'T237', descripcion: 'CORROSION DE LA MU—ECA Y DE LA MANO, DE TERCER GRADO'},
{ codigo: 'T24', descripcion: 'QUEMADURA Y CORROSION DE CADERA Y MIEMBRO INFER, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T240', descripcion: 'QUEMADURA DE CADERA Y MIEMBRO INFERIOR, GRADO NO ESPECIFICADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T241', descripcion: 'QUEMADURA DE CADERA Y MIEMBRO INFERIOR, PRIMER GRADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T242', descripcion: 'QUEMADURA DE CADERA Y MIEMBRO INFERIOR, SEGUNDO GRADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T243', descripcion: 'QUEMADURA DE CADERA Y MIEMBRO INFERIOR, TERCER GRADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T244', descripcion: 'CORROSION DE CADERA Y MIEMBRO INFERIOR, GRADO NO ESPECIFICADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T245', descripcion: 'CORROSION DE CADERA Y MIEMBRO INFERIOR, PRIMER GRADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T246', descripcion: 'CORROSION DE CADERA Y MIEMBRO INFERIOR, SEGUNDO GRADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T247', descripcion: 'CORROSION DE CADERA Y MIEMBRO INFERIOR, TERCER GRADO, EXCEPTO TOBILLO Y PIE'},
{ codigo: 'T25', descripcion: 'QUEMADURA Y CORROSION DEL TOBILLO Y DEL PIE'},
{ codigo: 'T250', descripcion: 'QUEMADURA DEL TOBILLO Y DEL PIE, GRADO NO ESPECIFICADO'},
{ codigo: 'T251', descripcion: 'QUEMADURA DEL TOBILLO Y DEL PIE, DE PRIMER GRADO'},
{ codigo: 'T252', descripcion: 'QUEMADURA DEL TOBILLO Y DEL PIE, DE SEGUNDO GRADO'},
{ codigo: 'T253', descripcion: 'QUEMADURA DEL TOBILLO Y DEL PIE, DE TERCER GRADO'},
{ codigo: 'T254', descripcion: 'CORROSION DEL TOBILLO Y DEL PIE, GRADO NO ESPECIFICADO'},
{ codigo: 'T255', descripcion: 'CORROSION DEL TOBILLO Y DEL PIE, DE PRIMER GRADO'},
{ codigo: 'T256', descripcion: 'CORROSION DEL TOBILLO Y DEL PIE, DE SEGUNDO GRADO'},
{ codigo: 'T257', descripcion: 'CORROSION DEL TOBILLO Y DEL PIE, DE TERCER GRADO'},
{ codigo: 'T26', descripcion: 'QUEMADURA Y CORROSION LIMITADA AL OJO Y SUS ANEXOS'},
{ codigo: 'T260', descripcion: 'QUEMADURA DEL PARPADO Y AREA PERIOCULAR'},
{ codigo: 'T261', descripcion: 'QUEMADURA DE LA CORNEA Y SACO CONJUNTIVAL'},
{ codigo: 'T262', descripcion: 'QUEMADURA CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR'},
{ codigo: 'T263', descripcion: 'QUEMADURA DE OTRAS PARTES DEL OJO Y SUS ANEXOS'},
{ codigo: 'T264', descripcion: 'QUEMADURA DEL OJO Y ANEXOS, PARTE NO ESPECIFICADA'},
{ codigo: 'T265', descripcion: 'CORROSION DEL PARPADO Y AREA PERIOCULAR'},
{ codigo: 'T266', descripcion: 'CORROSION DE LA CORNEA Y SACO CONJUNTIVAL'},
{ codigo: 'T267', descripcion: 'CORROSION CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR'},
{ codigo: 'T268', descripcion: 'CORROSION DE OTRAS PARTES DEL OJO Y SUS ANEXOS'},
{ codigo: 'T269', descripcion: 'CORROSION DEL OJO Y SUS ANEXOS, PARTE NO ESPECIFICADA'},
{ codigo: 'T27', descripcion: 'QUEMADURA Y CORROSION DE LAS VIAS RESPIRATORIAS'},
{ codigo: 'T270', descripcion: 'QUEMADURA DE LA LARINGE Y DE LA TRAQUEA'},
{ codigo: 'T271', descripcion: 'QUEMADURA QUE AFECTA LA LARINGE Y LA TRAQUEA CON EL PULMON'},
{ codigo: 'T272', descripcion: 'QUEMADURA DE OTRAS PARTES DE LAS VIAS RESPIRATORIAS'},
{ codigo: 'T273', descripcion: 'QUEMADURA DE LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA'},
{ codigo: 'T274', descripcion: 'CORROSION DE LA LARINGE Y DE LA TRAQUEA'},
{ codigo: 'T275', descripcion: 'CORROSION QUE AFECTA LA LARINGE Y LA TRAQUEA CON EL PULMON'},
{ codigo: 'T276', descripcion: 'CORROSION DE OTRAS PARTES DE LAS VIAS RESPIRATORIAS'},
{ codigo: 'T277', descripcion: 'CORROSION DE LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA'},
{ codigo: 'T28', descripcion: 'QUEMADURA Y CORROSION DE OTROS ORGANOS INTERNOS'},
{ codigo: 'T280', descripcion: 'QUEMADURA DE LA BOCA Y DE LA FARINGE'},
{ codigo: 'T281', descripcion: 'QUEMADURA DEL ESOFAGO'},
{ codigo: 'T282', descripcion: 'QUEMADURA DE OTRAS PARTES DEL TUBO DIGESTIVO'},
{ codigo: 'T283', descripcion: 'QUEMADURA DE ORGANOS GENITOURINARIOS INTERNOS'},
{ codigo: 'T284', descripcion: 'QUEMADURA DE OTROS ORGANOS INTERNOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'T285', descripcion: 'CORROSION DE LA BOCA Y DE LA FARINGE'},
{ codigo: 'T286', descripcion: 'CORROSION DEL ESOFAGO'},
{ codigo: 'T287', descripcion: 'CORROSION DE OTRAS PARTES DEL TUBO DIGESTIVO'},
{ codigo: 'T288', descripcion: 'CORROSION DE ORGANOS GENITOURINARIOS INTERNOS'},
{ codigo: 'T289', descripcion: 'CORROSION DE OTROS ORGANOS INTERNOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'T29', descripcion: 'QUEMADURAS Y CORROSIONES DE MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T290', descripcion: 'QUEMADURAS DE MULTIPLES REGIONES, GRADO NO ESPECIFICADO'},
{ codigo: 'T291', descripcion: 'QUEMADURAS DE MULTIPLES REGIONES, MENCIONADAS COMO DE NO MAS DE PRIMER GRADO'},
{ codigo: 'T292', descripcion: 'QUEMADURAS DE MULTIPLES REGIONES, MENCIONADAS COMO DE NO MAS DE SEGUNDO GRADO'},
{ codigo: 'T293', descripcion: 'QUEMADURAS DE MULTIPLES, CON MENCION AL MENOS DE UNA QUEMADURA DE TERCER GRADO'},
{ codigo: 'T294', descripcion: 'CORROSIONES DE MULTIPLES REGIONES, GRADO NO ESPECIFICADO'},
{ codigo: 'T295', descripcion: 'CORROSIONES MULTIPLES, MENCIONADAS COMO DE NO MAS DE PRIMER GRADO'},
{ codigo: 'T296', descripcion: 'CORROSIONES MULTIPLES, MENCIONADAS COMO DE NO MAS DE SEGUNDO GRADO'},
{ codigo: 'T297', descripcion: 'CORROSIONES MULTIPLES, CON MENCION AL MENOS DE UNA CORROSION TERCER GRADO'},
{ codigo: 'T30', descripcion: 'QUEMADURA Y CORROSION, REGION DEL CUERPO NO ESPECIFICADA'},
{ codigo: 'T300', descripcion: 'QUEMADURA DE REGION DEL CUERPO Y GRADO NO ESPECIFICADOS'},
{ codigo: 'T301', descripcion: 'QUEMADURA DE PRIMER GRADO, REGION DEL CUERPO NO ESPECIFICADA'},
{ codigo: 'T302', descripcion: 'QUEMADURA DE SEGUNDO GRADO, REGION DEL CUERPO NO ESPECIFICADA'},
{ codigo: 'T303', descripcion: 'QUEMADURA DE TERCER GRADO, REGION DEL CUERPO NO ESPECIFICADA'},
{ codigo: 'T304', descripcion: 'CORROSION DE REGION DEL CUERPO Y GRADO NO ESPECIFICADOS'},
{ codigo: 'T305', descripcion: 'CORROSION DE PRIMER GRADO, REGION DEL CUERPO NO ESPECIFICADA'},
{ codigo: 'T306', descripcion: 'CORROSION DE SEGUNDO GRADO, REGION DEL CUERPO NO ESPECIFICADA'},
{ codigo: 'T307', descripcion: 'CORROSION DE TERCER GRADO, REGION DEL CUERPO NO ESPECIFICADA'},
{ codigo: 'T31', descripcion: 'QUEMADURAS CLASIFICADAS SEGUN LA EXTENSION DE LA SUPERFICIE DEL CUERPO AFECTADA'},
{ codigo: 'T310', descripcion: 'QUEMADURAS EN MENOS 10% CUERPO'},
{ codigo: 'T311', descripcion: 'QUEMADURAS QUE AFECTAN DEL 10 AL 19% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T312', descripcion: 'QUEMADURAS QUE AFECTAN DEL 20 AL 29% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T313', descripcion: 'QUEMADURAS QUE AFECTAN DEL 30 AL 39% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T314', descripcion: 'QUEMADURAS QUE AFECTAN DEL 40 AL 49% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T315', descripcion: 'QUEMADURAS QUE AFECTAN DEL 50 AL 59% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T316', descripcion: 'QUEMADURAS QUE AFECTAN DEL 60 AL 69% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T317', descripcion: 'QUEMADURAS QUE AFECTAN DEL 70 AL 79% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T318', descripcion: 'QUEMADURAS QUE AFECTAN DEL 80 AL 89% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T319', descripcion: 'QUEMADURAS QUE AFECTAN EL 90% O MAS DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T32', descripcion: 'CORROSIONES CLASIFICADAS SEGUN LA EXTENSION DE LA SUPERFICIE DEL CUERPO AFECTADA'},
{ codigo: 'T320', descripcion: 'CORROSIONES QUE AFECTAN MENOS DEL 10% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T321', descripcion: 'CORROSIONES QUE AFECTAN DEL 10 AL 19% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T322', descripcion: 'CORROSIONES QUE AFECTAN DEL 20 AL 29% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T323', descripcion: 'CORROSIONES QUE AFECTAN DEL 30 AL 39% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T324', descripcion: 'CORROSIONES QUE AFECTAN DEL 40 AL 49% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T325', descripcion: 'CORROSIONES QUE AFECTAN DEL 50 AL 59% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T326', descripcion: 'CORROSIONES QUE AFECTAN DEL 60 AL 69% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T327', descripcion: 'CORROSIONES QUE AFECTAN DEL 70 AL 79% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T328', descripcion: 'CORROSIONES QUE AFECTAN DEL 80 AL 89% DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T329', descripcion: 'CORROSIONES QUE AFECTAN EL 90% O MAS DE LA SUPERFICIE DEL CUERPO'},
{ codigo: 'T33', descripcion: 'CONGELAMIENTO SUPERFICIAL'},
{ codigo: 'T330', descripcion: 'CONGELAMIENTO SUPERFICIAL DE LA CABEZA'},
{ codigo: 'T331', descripcion: 'CONGELAMIENTO SUPERFICIAL DEL CUELLO'},
{ codigo: 'T332', descripcion: 'CONGELAMIENTO SUPERFICIAL DEL TORAX'},
{ codigo: 'T333', descripcion: 'CONGELAMIENTO SUPERFICIAL DE LA PARED ABDOMINAL, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'T334', descripcion: 'CONGELAMIENTO SUPERFICIAL DEL BRAZO'},
{ codigo: 'T335', descripcion: 'CONGELAMIENTO SUPERFICIAL DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'T336', descripcion: 'CONGELAMIENTO SUPERFICIAL DE LA CADERA Y DEL MUSLO'},
{ codigo: 'T337', descripcion: 'CONGELAMIENTO SUPERFICIAL DE LA RODILLA Y DE LA PIERNA'},
{ codigo: 'T338', descripcion: 'CONGELAMIENTO SUPERFICIAL DEL TOBILLO Y DEL PIE'},
{ codigo: 'T339', descripcion: 'CONGELAMIENTO SUPERFICIAL DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'T34', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR'},
{ codigo: 'T340', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DE LA CABEZA'},
{ codigo: 'T341', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DEL CUELLO'},
{ codigo: 'T342', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DEL TORAX'},
{ codigo: 'T343', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DE LA PARED ABDOMINAL, REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'T344', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DEL BRAZO'},
{ codigo: 'T345', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'T346', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DE LA CADERA Y DEL MUSLO'},
{ codigo: 'T347', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DE LA RODILLA Y DE LA PIERNA'},
{ codigo: 'T348', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DEL TOBILLO Y DEL PIE'},
{ codigo: 'T349', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS'},
{ codigo: 'T35', descripcion: 'CONGELAMIENTO QUE AFECTA MULTIPLES REGIONES DE CUERPO Y CONGELAMIENTO NO ESPECIFICADO'},
{ codigo: 'T350', descripcion: 'CONGELAMIENTO SUPERFICIAL QUE AFECTA MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T351', descripcion: 'CONGELAMIENTO CON NECROSIS TISULAR QUE AFECTA MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T352', descripcion: 'CONGELAMIENTO NO ESPECIFICADO DE LA CABEZA Y DEL CUELLO'},
{ codigo: 'T353', descripcion: 'CONGELAMIENTO NO ESPECIFICADO DE TORAX, DEL ABDOMEN, DE LA REGION LUMBOSACRA Y LA PELVIS'},
{ codigo: 'T354', descripcion: 'CONGELAMIENTO NO ESPECIFICADO DEL MIEMBRO SUPERIOR'},
{ codigo: 'T355', descripcion: 'CONGELAMIENTO NO ESPECIFICADO DEL MIEMBRO INFERIOR'},
{ codigo: 'T356', descripcion: 'CONGELAMIENTO NO ESPECIFICADO QUE AFECTA MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T357', descripcion: 'CONGELAMIENTO NO ESPECIFICADO, DE SITIO NO ESPECIFICADO'},
{ codigo: 'T36', descripcion: 'ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS'},
{ codigo: 'T360', descripcion: 'PENICILINAS'},
{ codigo: 'T361', descripcion: 'CEFALOSPORINAS Y OTROS ANTIBIOTICOS BETA-LACTAMICOS'},
{ codigo: 'T362', descripcion: 'GRUPO DEL CLORAMFENICOL'},
{ codigo: 'T363', descripcion: 'MACROLIDOS'},
{ codigo: 'T364', descripcion: 'TETRACICLINAS'},
{ codigo: 'T365', descripcion: 'AMINOGLUCOSIDOS'},
{ codigo: 'T366', descripcion: 'RIFAMICINAS'},
{ codigo: 'T367', descripcion: 'ANTIBIOTICOS ANTIMICOTICOS USADOS SISTEMICAMENTE'},
{ codigo: 'T368', descripcion: 'OTROS ANTIBIOTICOS SISTEMICOS'},
{ codigo: 'T369', descripcion: 'ANTIBIOTICOS SISTEMICOS, NO ESPECIFICADOS'},
{ codigo: 'T37', descripcion: 'ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS'},
{ codigo: 'T370', descripcion: 'SULFONAMIDAS'},
{ codigo: 'T371', descripcion: 'DROGAS ANTIMICOBACTERIANAS'},
{ codigo: 'T372', descripcion: 'ANTIPALUDICOS Y DROGAS DE ACCION CONTRA OTROS PROTOZOARIOS SANGUINEOS'},
{ codigo: 'T373', descripcion: 'OTRAS DROGAS ANTIPROTOZOARIAS'},
{ codigo: 'T374', descripcion: 'ANTIHELMINTICOS'},
{ codigo: 'T375', descripcion: 'DROGAS ANTIVIRALES'},
{ codigo: 'T378', descripcion: 'OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS ESPECIFICADOS'},
{ codigo: 'T379', descripcion: 'ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS, NO ESPECIFICADOS'},
{ codigo: 'T38', descripcion: 'ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T380', descripcion: 'GLUCOCORTICOIDES Y ANALOGOS SINTETICOS'},
{ codigo: 'T381', descripcion: 'HORMONAS TIROIDEAS Y SUSTITUTOS'},
{ codigo: 'T382', descripcion: 'DROGAS ANTITIROIDEAS'},
{ codigo: 'T383', descripcion: 'INSULINA Y DROGAS HIPOGLUCEMIANTES ORALES (ANTIDIABETICAS)'},
{ codigo: 'T384', descripcion: 'ANTICONCEPTIVOS ORALES'},
{ codigo: 'T385', descripcion: 'OTROS ESTROGENOS Y PROGESTOGENOS'},
{ codigo: 'T386', descripcion: 'ANTIGONADOTROFINAS, ANTIESTROGENOS Y ANTIANDROGENOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T387', descripcion: 'ANDROGENOS Y SUS CONGENERES ANABOLICOS'},
{ codigo: 'T388', descripcion: 'OTRAS HORMONAS Y SUSTITUTOS SINTETICOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'T389', descripcion: 'OTROS ANTAGONISTAS DE LAS HORMONAS Y LOS NO ESPECIFICADOS'},
{ codigo: 'T39', descripcion: 'ENV POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS'},
{ codigo: 'T390', descripcion: 'SALICILATOS'},
{ codigo: 'T391', descripcion: 'DERIVADOS DEL PARAAMINOFENOL'},
{ codigo: 'T392', descripcion: 'DERIVADOS DE LA PIRAZOLONA'},
{ codigo: 'T393', descripcion: 'OTRAS DROGAS ANTIINFLAMATORIAS NO ESTEROIDEAS (DAINE)'},
{ codigo: 'T394', descripcion: 'ANTIRREUMATICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T398', descripcion: 'OTROS ANALGESICOS NO NARCOTICOS Y ANTIPIRETICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T399', descripcion: 'ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, NO ESPECIFICADOS'},
{ codigo: 'T40', descripcion: 'ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS)'},
{ codigo: 'T400', descripcion: 'OPIO'},
{ codigo: 'T401', descripcion: 'HEROINA'},
{ codigo: 'T402', descripcion: 'OTROS OPIACEOS'},
{ codigo: 'T403', descripcion: 'METADONA'},
{ codigo: 'T404', descripcion: 'OTROS NARCOTICOS SINTETICOS'},
{ codigo: 'T405', descripcion: 'COCAINA'},
{ codigo: 'T406', descripcion: 'OTROS NARCOTICOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'T407', descripcion: 'CANNABIS (DERIVADOS)'},
{ codigo: 'T408', descripcion: 'ACIDO LISERGICO (LSD)'},
{ codigo: 'T409', descripcion: 'OTROS PSICODISLEPTICOS Y LOS NO ESPECIFICADOS (ALUCINOGENOS)'},
{ codigo: 'T41', descripcion: 'ENVENENAMIENTO POR ANESTESICOS Y GASES TERAPEUTICOS'},
{ codigo: 'T410', descripcion: 'ANESTESICOS POR INHALACION'},
{ codigo: 'T411', descripcion: 'ANESTESICOS INTRAVENOSOS'},
{ codigo: 'T412', descripcion: 'OTROS ANESTESICOS GENERALES Y LOS NO ESPECIFICADOS'},
{ codigo: 'T413', descripcion: 'ANESTESICOS LOCALES'},
{ codigo: 'T414', descripcion: 'ANESTESICOS, NO ESPECIFICADOS'},
{ codigo: 'T415', descripcion: 'GASES TERAPEUTICOS'},
{ codigo: 'T42', descripcion: 'ENV ANTIEPILEPTICOS, HIPNOTICOS-SEDANTES Y DROGAS ANTIPARKINSONIANAS'},
{ codigo: 'T420', descripcion: 'DERIVADOS DE LA HIDANTOINA'},
{ codigo: 'T421', descripcion: 'IMINOSTILBENOS'},
{ codigo: 'T422', descripcion: 'SUCCINAMIDAS Y DERIVADOS DE LA OXAZOLIDINA'},
{ codigo: 'T423', descripcion: 'BARBITURICOS'},
{ codigo: 'T424', descripcion: 'BENZODIAZEPINAS'},
{ codigo: 'T425', descripcion: 'ANTIEPILEPTICOS MIXTOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T426', descripcion: 'OTROS ANTIEPILEPTICOS Y DROGAS HIPNOTICO-SEDANTES'},
{ codigo: 'T427', descripcion: 'ANTIEPILEPTICOS Y DROGAS HIPNOTICO-SEDANTES, NO ESPECIFICADOS'},
{ codigo: 'T428', descripcion: 'DROGAS ANTIPARKINSONIANAS Y OTROS DEPRESORES DEL TONO MUSCULAR CENTRAL'},
{ codigo: 'T43', descripcion: 'ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T430', descripcion: 'ANTIDEPRESIVOS TRICICLICOS Y TETRACICLICOS'},
{ codigo: 'T431', descripcion: 'ANTIDEPRESIVOS INHIBIDORES DE LA MONOAMINOXIDASA'},
{ codigo: 'T432', descripcion: 'OTROS ANTIDEPRESIVOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'T433', descripcion: 'ANTIPSICOTICOS Y NEUROLEPTICOS FENOTIACINICOS'},
{ codigo: 'T434', descripcion: 'BUTIROFENONA Y NEUROLEPTICOS TIOXANTENICOS'},
{ codigo: 'T435', descripcion: 'OTROS ANTIPSICOTICOS Y NEUROLEPTICOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'T436', descripcion: 'PSICOESTIMULANTES CON ABUSO POTENCIAL'},
{ codigo: 'T438', descripcion: 'OTRAS DROGAS PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T439', descripcion: 'DROGA PSICOTROPICA, NO ESPECIFICADA'},
{ codigo: 'T44', descripcion: 'ENV POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'T440', descripcion: 'AGENTES ANTICOLINESTERASA'},
{ codigo: 'T441', descripcion: 'OTROS PARASIMPATICOMIMETICOS (COLINERGICOS)'},
{ codigo: 'T442', descripcion: 'DROGAS BLOQUEADORAS GANGLIONARES, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T443', descripcion: 'OTROS PARASIMPATICOLITICOS(ANTICOLINERGICOS ANTIMUSCARINICOS) Y ESPASMOLITICOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T444', descripcion: 'AGONISTAS, PREDOMINANTEMENTE ALFA-ADRENERGICOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T445', descripcion: 'AGONISTAS, PREDOMINANTEMENTE BETA-ADRENERGICOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T446', descripcion: 'ANTAGONISTAS ALFA-ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T447', descripcion: 'ANTAGONISTAS BETA-ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T448', descripcion: 'AGENTES ACCION CENTRAL Y BLOQUEADORES NEURONALES ADRENERGICOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T449', descripcion: 'OTRAS DROGAS Y LAS NO ESPECIFICADAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'T45', descripcion: 'ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T450', descripcion: 'DROGAS ANTIALERGICAS Y ANTIEMETICAS'},
{ codigo: 'T451', descripcion: 'DROGAS ANTINEOPLASICAS E INMUNOSUPRESORAS'},
{ codigo: 'T452', descripcion: 'VITAMINAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T453', descripcion: 'ENZIMAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T454', descripcion: 'HIERRO Y SUS COMPUESTOS'},
{ codigo: 'T455', descripcion: 'ANTICOAGULANTES'},
{ codigo: 'T456', descripcion: 'DROGAS QUE AFECTAN LA FIBRINOLISIS'},
{ codigo: 'T457', descripcion: 'ANTAGONISTAS DE ANTICOAGULANTES, VITAMINA K Y OTROS COAGULANTES'},
{ codigo: 'T458', descripcion: 'OTROS AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS'},
{ codigo: 'T459', descripcion: 'AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO ESPECIFICADOS'},
{ codigo: 'T46', descripcion: 'ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE SISTEMA CARDIOVASCULAR'},
{ codigo: 'T460', descripcion: 'GLUCOSIDOS CARDIOTONICOS Y MEDICAMENTOS DE ACCION SIMILAR'},
{ codigo: 'T461', descripcion: 'BLOQUEADORES DEL CANAL DEL CALCIO'},
{ codigo: 'T462', descripcion: 'OTRAS DROGAS ANTIARRITMICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T463', descripcion: 'VASODILATADORES CORONARIOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T464', descripcion: 'INHIBIDORES DE LA ENZIMA CONVERTIDORA DE LA ANGIOTENSINA'},
{ codigo: 'T465', descripcion: 'OTRAS DROGAS ANTIHIPERTENSIVAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T466', descripcion: 'DROGAS ANTILIPEMICAS Y ANTIARTERIOSCLEROTICAS'},
{ codigo: 'T467', descripcion: 'VASODILATADORES PERIFERICOS'},
{ codigo: 'T468', descripcion: 'DROGAS ANTIVARICOSAS, INCLUSIVE AGENTES ESCLEROSANTES'},
{ codigo: 'T469', descripcion: 'OTROS AGENTES Y LOS NO ESPECIFICADOS QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR'},
{ codigo: 'T47', descripcion: 'ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL'},
{ codigo: 'T470', descripcion: 'ANTAGONISTAS DEL RECEPTOR H2 DE HISTAMINA'},
{ codigo: 'T471', descripcion: 'OTRAS DROGAS ANTIACIDAS Y QUE INHIBEN LA SECRECION GASTRICA'},
{ codigo: 'T472', descripcion: 'LAXANTES ESTIMULANTES'},
{ codigo: 'T473', descripcion: 'LAXANTES SALINOS Y OSMOTICOS'},
{ codigo: 'T474', descripcion: 'OTROS LAXANTES'},
{ codigo: 'T475', descripcion: 'DIGESTIVOS'},
{ codigo: 'T476', descripcion: 'DROGAS ANTIDIARREICAS'},
{ codigo: 'T477', descripcion: 'EMETICOS'},
{ codigo: 'T478', descripcion: 'OTROS AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL'},
{ codigo: 'T479', descripcion: 'AGENTES NO ESPECIFICADOS QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL'},
{ codigo: 'T48', descripcion: 'ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO'},
{ codigo: 'T480', descripcion: 'DROGAS OXITOCICAS'},
{ codigo: 'T481', descripcion: 'RELAJANTES MUSCULOESQUELETICOS [AGENTES BLOQUEADORES NEUROMUSCULARES]'},
{ codigo: 'T482', descripcion: 'OTROS MEDICAMENTOS Y LOS NO ESPECIFICADOS DE ACCION PRINCIPAL SOBRE LOS MUSCULOS'},
{ codigo: 'T483', descripcion: 'ANTITUSIGENOS'},
{ codigo: 'T484', descripcion: 'EXPECTORANTES'},
{ codigo: 'T485', descripcion: 'DROGAS CONTRA EL CATARRO COMUN'},
{ codigo: 'T486', descripcion: 'ANTIASMATICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T487', descripcion: 'OTROS AGENTES Y LOS NO ESPECIFICADOS DE ACCION PRINCIPAL EN SISTEMA RESPIRATORIO'},
{ codigo: 'T49', descripcion: 'ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES'},
{ codigo: 'T490', descripcion: 'DROGAS LOCALES ANTIMICOTICAS, ANTIINFECCIOSAS Y ANTIINFLAMATORIAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T491', descripcion: 'ANTIPRURITICOS'},
{ codigo: 'T492', descripcion: 'ASTRINGENTES Y DETERGENTES LOCALES'},
{ codigo: 'T493', descripcion: 'EMOLIENTES, DEMULCENTES Y PROTECTORES'},
{ codigo: 'T494', descripcion: 'QUERATOLITICOS, QUERATOPLASTICOS, DROGAS Y OTRAS PREPARACIONES PARA EL TRATAMIENTO DEL CABELLO'},
{ codigo: 'T495', descripcion: 'DROGAS Y PREPARACIONES OFTALMOLOGICAS'},
{ codigo: 'T496', descripcion: 'DROGAS Y PREPARACIONES OTORRINOLARINGOLOGICAS'},
{ codigo: 'T497', descripcion: 'DROGAS DENTALES, APLICADAS TOPICAMENTE'},
{ codigo: 'T498', descripcion: 'OTROS AGENTES TOPICOS'},
{ codigo: 'T499', descripcion: 'AGENTES TOPICOS, NO ESPECIFICADOS'},
{ codigo: 'T50', descripcion: 'ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS'},
{ codigo: 'T500', descripcion: 'MINERALOCORTICOIDES Y SUS ANTAGONISTAS'},
{ codigo: 'T501', descripcion: 'DIURETICOS DEL ASA (DINTEL ALTO)'},
{ codigo: 'T502', descripcion: 'INHIBIDORES ANHIDRASA DE ACIDO CARBONICO, BENZOTIAZIDAS OTR DIURETICOS'},
{ codigo: 'T503', descripcion: 'AGENTES DEL EQUILIBRIO HIDROLITICO, ELECTROLITICO Y CALORICO'},
{ codigo: 'T504', descripcion: 'DROGAS QUE AFECTAN EL METABOLISMO DEL ACIDO URICO'},
{ codigo: 'T505', descripcion: 'DEPRESORES DEL APETITO'},
{ codigo: 'T506', descripcion: 'ANTIDOTOS Y AGENTES QUELANTES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T507', descripcion: 'ANALEPTICOS Y ANTAGONISTAS DEL OPIO'},
{ codigo: 'T508', descripcion: 'AGENTES DIAGNOSTICOS'},
{ codigo: 'T509', descripcion: 'OTRAS DROGAS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'T51', descripcion: 'EFECTO TOXICO DEL ALCOHOL'},
{ codigo: 'T510', descripcion: 'ETANOL'},
{ codigo: 'T511', descripcion: 'METANOL'},
{ codigo: 'T512', descripcion: 'PROPANOL-2'},
{ codigo: 'T513', descripcion: 'LICOR DE ALCOHOL INSUFICIENTEMENTE DESTILADO'},
{ codigo: 'T518', descripcion: 'OTROS ALCOHOLES'},
{ codigo: 'T519', descripcion: 'ALCOHOL, NO ESPECIFICADO'},
{ codigo: 'T52', descripcion: 'EFECTO TOXICO DE DISOLVENTES ORGANICOS'},
{ codigo: 'T520', descripcion: 'PRODUCTOS DEL PETROLEO'},
{ codigo: 'T521', descripcion: 'BENCENO'},
{ codigo: 'T522', descripcion: 'HOMOLOGOS DEL BENCENO'},
{ codigo: 'T523', descripcion: 'GLICOLES'},
{ codigo: 'T524', descripcion: 'CETONAS'},
{ codigo: 'T528', descripcion: 'OTROS DISOLVENTES ORGANICOS'},
{ codigo: 'T529', descripcion: 'DISOLVENTES ORGANICOS, NO ESPECIFICADOS'},
{ codigo: 'T53', descripcion: 'EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS'},
{ codigo: 'T530', descripcion: 'TETRACLORURO DE CARBONO'},
{ codigo: 'T531', descripcion: 'CLOROFORMO'},
{ codigo: 'T532', descripcion: 'TRICLOROETILENO'},
{ codigo: 'T533', descripcion: 'TETRACLOROETILENO'},
{ codigo: 'T534', descripcion: 'DICLOROETANO'},
{ codigo: 'T535', descripcion: 'CLOROFLUOROCARBUROS'},
{ codigo: 'T536', descripcion: 'OTROS DERIVADOS HALOGENADOS DE HIDROCARBUROS ALIFATICOS'},
{ codigo: 'T537', descripcion: 'OTROS DERIVADOS HALOGENADOS DE HIDROCARBUROS AROMATICOS'},
{ codigo: 'T539', descripcion: 'DERIVADOS HALOGENADOS DE HIDROCARBUROS ALIFATICOS Y AROMATICOS, NO ESPECIFICADOS'},
{ codigo: 'T54', descripcion: 'EFECTO TOXICO DE SUSTANCIAS CORROSIVAS'},
{ codigo: 'T540', descripcion: 'FENOL Y HOMOLOGOS DEL FENOL'},
{ codigo: 'T541', descripcion: 'OTROS COMPUESTOS ORGANICOS CORROSIVOS'},
{ codigo: 'T542', descripcion: 'ACIDOS CORROSIVOS Y SUSTANCIAS ACIDAS SIMILARES'},
{ codigo: 'T543', descripcion: 'ALCALIS CAUSTICOS Y SUSTANCIAS ALCALINAS SIMILARES'},
{ codigo: 'T549', descripcion: 'EFECTO TOXICO DE SUSTANCIA CORROSIVA, NO ESPECIFICADA'},
{ codigo: 'T55', descripcion: 'EFECTO TOXICO DE DETERGENTES Y JABONES'},
{ codigo: 'T56', descripcion: 'EFECTO TOXICO DE METALES'},
{ codigo: 'T560', descripcion: 'PLOMO Y SUS COMPUESTOS'},
{ codigo: 'T561', descripcion: 'MERCURIO Y SUS COMPUESTOS'},
{ codigo: 'T562', descripcion: 'CROMO Y SUS COMPUESTOS'},
{ codigo: 'T563', descripcion: 'CADMIO Y SUS COMPUESTOS'},
{ codigo: 'T564', descripcion: 'COBRE Y SUS COMPUESTOS'},
{ codigo: 'T565', descripcion: 'ZINC Y SUS COMPUESTOS'},
{ codigo: 'T566', descripcion: 'ESTA—O Y SUS COMPUESTOS'},
{ codigo: 'T567', descripcion: 'BERILIO Y SUS COMPUESTOS'},
{ codigo: 'T568', descripcion: 'OTROS METALES'},
{ codigo: 'T569', descripcion: 'METAL, NO ESPECIFICADO'},
{ codigo: 'T57', descripcion: 'EFECTO TOXICO DE OTRAS SUSTANCIAS INORGANICAS'},
{ codigo: 'T570', descripcion: 'ARSENICO Y SUS COMPUESTOS'},
{ codigo: 'T571', descripcion: 'FOSFORO Y SUS COMPUESTOS'},
{ codigo: 'T572', descripcion: 'MANGANESO Y SUS COMPUESTOS'},
{ codigo: 'T573', descripcion: 'ACIDO CIANHIDRICO'},
{ codigo: 'T578', descripcion: 'OTRAS SUSTANCIAS INORGANICAS, ESPECIFICADAS'},
{ codigo: 'T579', descripcion: 'SUSTANCIA INORGANICA, NO ESPECIFICADA'},
{ codigo: 'T58', descripcion: 'EFECTO TOXICO DEL MONOXIDO DE CARBONO'},
{ codigo: 'T59', descripcion: 'EFECTO TOXICO DE OTROS GASES, HUMOS Y VAPORES'},
{ codigo: 'T590', descripcion: 'OXIDOS DE NITROGENO'},
{ codigo: 'T591', descripcion: 'DIOXIDO DE SULFURO'},
{ codigo: 'T592', descripcion: 'FORMALDEHIDO'},
{ codigo: 'T593', descripcion: 'GAS LACRIMOGENO'},
{ codigo: 'T594', descripcion: 'CLORO GASEOSO'},
{ codigo: 'T595', descripcion: 'GAS DE FLUOR Y FLUORURO DE HIDROGENO'},
{ codigo: 'T596', descripcion: 'SULFURO DE HIDROGENO'},
{ codigo: 'T597', descripcion: 'DIOXIDO DE CARBONO'},
{ codigo: 'T598', descripcion: 'OTROS GASES, HUMOS Y VAPORES ESPECIFICADOS'},
{ codigo: 'T599', descripcion: 'GASES, HUMOS Y VAPORES NO ESPECIFICADOS'},
{ codigo: 'T60', descripcion: 'EFECTO TOXICO DE PLAGUICIDAS (PESTICIDAS)'},
{ codigo: 'T600', descripcion: 'INSECTICIDAS ORGANOFOSFORADOS Y CARBAMATOS'},
{ codigo: 'T601', descripcion: 'INSECTICIDAS HALOGENADOS'},
{ codigo: 'T602', descripcion: 'OTROS INSECTICIDAS'},
{ codigo: 'T603', descripcion: 'HERBICIDAS Y FUNGICIDAS'},
{ codigo: 'T604', descripcion: 'RODENTICIDAS'},
{ codigo: 'T608', descripcion: 'OTROS PLAGUICIDAS'},
{ codigo: 'T609', descripcion: 'PLAGUICIDA, NO ESPECIFICADO'},
{ codigo: 'T61', descripcion: 'EFECTO TOXICO DE SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTOS MARINOS'},
{ codigo: 'T610', descripcion: 'ENVENENAMIENTO CIGUATERO POR PESCADO'},
{ codigo: 'T611', descripcion: 'ENVENENAMIENTO ESCOMBROIDEO POR PESCADO'},
{ codigo: 'T612', descripcion: 'OTROS ENVENENAMIENTOS POR PESCADOS Y MARISCOS'},
{ codigo: 'T618', descripcion: 'EFECTO TOXICO DE OTROS ALIMENTOS MARINOS'},
{ codigo: 'T619', descripcion: 'EFECTO TOXICO DE ALIMENTOS MARINOS NO ESPECIFICADOS'},
{ codigo: 'T62', descripcion: 'EFECTO TOXICO DE OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO'},
{ codigo: 'T620', descripcion: 'HONGOS INGERIDOS'},
{ codigo: 'T621', descripcion: 'BAYAS INGERIDAS'},
{ codigo: 'T622', descripcion: 'OTRA(S) (PARTES DE) PLANTA(S) INGERIDA(S)'},
{ codigo: 'T628', descripcion: 'OTRAS SUSTANCIAS NOCIVAS ESPECIFICADAS INGERIDAS COMO ALIMENTO'},
{ codigo: 'T629', descripcion: 'SUSTANCIA NOCIVA INGERIDA COMO ALIMENTO, NO ESPECIFICADA'},
{ codigo: 'T63', descripcion: 'EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS'},
{ codigo: 'T630', descripcion: 'VENENO DE SERPIENTE'},
{ codigo: 'T631', descripcion: 'VENENO DE OTROS REPTILES'},
{ codigo: 'T632', descripcion: 'VENENO DE ESCORPION'},
{ codigo: 'T633', descripcion: 'VENENO DE ARA—AS'},
{ codigo: 'T634', descripcion: 'VENENO DE OTROS ARTROPODOS'},
{ codigo: 'T635', descripcion: 'EFECTO TOXICO DEL CONTACTO CON PECES'},
{ codigo: 'T636', descripcion: 'EFECTO TOXICO DEL CONTACTO CON OTROS ANIMALES MARINOS'},
{ codigo: 'T638', descripcion: 'EFECTOS TOXICOS DEL CONTACTO CON OTROS ANIMALES VENENOSOS'},
{ codigo: 'T639', descripcion: 'EFECTO TOXICO DEL CONTACTO CON ANIMAL VENENOSO NO ESPECIFICADO'},
{ codigo: 'T64', descripcion: 'EFECTO TOXICO AFLATOXINA Y OTR MICOTOXINAS CONTAMINANTES DE ALIMENTOS'},
{ codigo: 'T65', descripcion: 'EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS'},
{ codigo: 'T650', descripcion: 'CIANURO'},
{ codigo: 'T651', descripcion: 'ESTRICNINA Y SUS SALES'},
{ codigo: 'T652', descripcion: 'TABACO Y NICOTINA'},
{ codigo: 'T653', descripcion: 'NITRODERIVADOS Y AMINODERIVADOS DEL BENCENO Y SUS HOMOLOGOS'},
{ codigo: 'T654', descripcion: 'BISULFURO DE CARBONO'},
{ codigo: 'T655', descripcion: 'NITROGLICERINA Y OTROS ACIDOS Y ESTERES NITRICOS'},
{ codigo: 'T656', descripcion: 'PINTURAS Y COLORANTES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T658', descripcion: 'EFECTOS TOXICOS DE OTRAS SUSTANCIAS ESPECIFICADAS'},
{ codigo: 'T659', descripcion: 'EFECTO TOXICO DE SUSTANCIA NO ESPECIFICADA'},
{ codigo: 'T66', descripcion: 'EFECTOS NO ESPECIFICADOS DE LA RADIACION'},
{ codigo: 'T67', descripcion: 'EFECTOS DEL CALOR Y DE LA LUZ'},
{ codigo: 'T670', descripcion: 'GOLPE DE CALOR E INSOLACION'},
{ codigo: 'T671', descripcion: 'SINCOPE POR CALOR'},
{ codigo: 'T672', descripcion: 'CALAMBRE POR CALOR'},
{ codigo: 'T673', descripcion: 'AGOTAMIENTO POR CALOR, ANHIDROTICO'},
{ codigo: 'T674', descripcion: 'AGOTAMIENTO POR CALOR DEBIDO A DEPLECION DE SAL'},
{ codigo: 'T675', descripcion: 'AGOTAMIENTO POR CALOR, NO ESPECIFICADO'},
{ codigo: 'T676', descripcion: 'FATIGA POR CALOR, TRANSITORIA'},
{ codigo: 'T677', descripcion: 'EDEMA POR CALOR'},
{ codigo: 'T678', descripcion: 'OTROS EFECTOS DEL CALOR Y DE LA LUZ'},
{ codigo: 'T679', descripcion: 'EFECTO DEL CALOR Y DE LA LUZ, NO ESPECIFICADO'},
{ codigo: 'T68', descripcion: 'HIPOTERMIA'},
{ codigo: 'T69', descripcion: 'OTROS EFECTOS DE LA REDUCCION DE LA TEMPERATURA'},
{ codigo: 'T690', descripcion: 'MANO Y PIE DE INMERSION'},
{ codigo: 'T691', descripcion: 'SABA—ON(ES)'},
{ codigo: 'T698', descripcion: 'OTROS EFECTOS ESPECIFICADOS DE LA REDUCCION DE LA TEMPERATURA'},
{ codigo: 'T699', descripcion: 'EFECTO DE LA REDUCCION DE LA TEMPERATURA, NO ESPECIFICADO'},
{ codigo: 'T70', descripcion: 'EFECTOS DE LA PRESION DEL AIRE Y DE LA PRESION DEL AGUA'},
{ codigo: 'T700', descripcion: 'BAROTRAUMA OTITICO'},
{ codigo: 'T701', descripcion: 'BAROTRAUMA SINUSAL'},
{ codigo: 'T702', descripcion: 'OTROS EFECTOS Y LOS NO ESPECIFICADOS DE LA GRAN ALTITUD'},
{ codigo: 'T703', descripcion: 'ENFERMEDAD POR DESCOMPRESION (DE LOS CAJONES SUMERGIDOS)'},
{ codigo: 'T704', descripcion: 'EFECTOS DE LIQUIDOS CON ALTA PRESION'},
{ codigo: 'T708', descripcion: 'OTROS EFECTOS DE LA PRESION DEL AIRE Y DEL AGUA'},
{ codigo: 'T709', descripcion: 'EFECTO DE LA PRESION DEL AIRE Y DEL AGUA, NO ESPECIFICADO'},
{ codigo: 'T71', descripcion: 'ASFIXIA'},
{ codigo: 'T73', descripcion: 'EFECTOS DE OTRAS PRIVACIONES'},
{ codigo: 'T730', descripcion: 'EFECTOS DEL HAMBRE'},
{ codigo: 'T731', descripcion: 'EFECTOS DE LA SED'},
{ codigo: 'T732', descripcion: 'AGOTAMIENTO DEBIDO A EXPOSICION A LA INTEMPERIE'},
{ codigo: 'T733', descripcion: 'AGOTAMIENTO DEBIDO A ESFUERZO EXCESIVO'},
{ codigo: 'T738', descripcion: 'OTROS EFECTOS DE PRIVACION'},
{ codigo: 'T739', descripcion: 'EFECTOS DE PRIVACION, NO ESPECIFICADOS'},
{ codigo: 'T74', descripcion: 'SINDROMES DEL MALTRATO'},
{ codigo: 'T740', descripcion: 'NEGLIGENCIA O ABANDONO'},
{ codigo: 'T741', descripcion: 'ABUSO FISICO'},
{ codigo: 'T742', descripcion: 'ABUSO SEXUAL'},
{ codigo: 'T743', descripcion: 'ABUSO PSICOLOGICO'},
{ codigo: 'T748', descripcion: 'OTROS SINDROMES DEL MALTRATO'},
{ codigo: 'T749', descripcion: 'SINDROME DEL MALTRATO, NO ESPECIFICADO'},
{ codigo: 'T75', descripcion: 'EFECTOS DE OTRAS CAUSAS EXTERNAS'},
{ codigo: 'T750', descripcion: 'EFECTOS DEL RAYO'},
{ codigo: 'T751', descripcion: 'AHOGAMIENTO Y SUMERSION NO MORTAL'},
{ codigo: 'T752', descripcion: 'EFECTOS DE LA VIBRACION'},
{ codigo: 'T753', descripcion: 'MAL DEL MOVIMIENTO'},
{ codigo: 'T754', descripcion: 'EFECTOS DE LA CORRIENTE ELECTRICA'},
{ codigo: 'T758', descripcion: 'OTROS EFECTOS ESPECIFICADOS DE CAUSAS EXTERNAS'},
{ codigo: 'T78', descripcion: 'EFECTOS ADVERSOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T780', descripcion: 'CHOQUE ANAFILACTICO DEBIDO A REACCION ADVERSA A ALIMENTOS'},
{ codigo: 'T781', descripcion: 'OTRA REACCION ADVERSA A ALIMENTOS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'T782', descripcion: 'CHOQUE ANAFILACTICO, NO ESPECIFICADO'},
{ codigo: 'T783', descripcion: 'EDEMA ANGIONEUROTICO'},
{ codigo: 'T784', descripcion: 'ALERGIA NO ESPECIFICADA'},
{ codigo: 'T788', descripcion: 'OTROS EFECTOS ADVERSOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'T789', descripcion: 'EFECTO ADVERSO NO ESPECIFICADO'},
{ codigo: 'T79', descripcion: 'ALGUNAS COMPLICACIONES PRECOCES DE TRAUMATISMOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T790', descripcion: 'EMBOLIA GASEOSA (TRAUMATICA)'},
{ codigo: 'T791', descripcion: 'EMBOLIA GRASA (TRAUMATICA)'},
{ codigo: 'T792', descripcion: 'HEMORRAGIA TRAUMATICA SECUNDARIA Y RECURRENTE'},
{ codigo: 'T793', descripcion: 'INFECCION POSTRAUMATICA DE HERIDA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'T794', descripcion: 'CHOQUE TRAUMATICO'},
{ codigo: 'T795', descripcion: 'ANURIA TRAUMATICA'},
{ codigo: 'T796', descripcion: 'ISQUEMIA TRAUMATICA DE MUSCULO'},
{ codigo: 'T797', descripcion: 'ENFISEMA SUBCUTANEO TRAUMATICO'},
{ codigo: 'T798', descripcion: 'OTRAS COMPLICACIONES PRECOCES DE LOS TRAUMATISMOS'},
{ codigo: 'T799', descripcion: 'COMPLICACIONES PRECOCES NO ESPECIFICADAS DE LOS TRAUMATISMOS'},
{ codigo: 'T80', descripcion: 'COMPLICACIONES CONSECUTIVAAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA'},
{ codigo: 'T800', descripcion: 'EMBOLIA GASEOSA CONSECUTIVA A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA'},
{ codigo: 'T801', descripcion: 'COMPLICACIONES VASCULARES CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA'},
{ codigo: 'T802', descripcion: 'INFECCIONES CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA'},
{ codigo: 'T803', descripcion: 'REACCION DE INCOMPATIBILIDAD AL GRUPO ABO'},
{ codigo: 'T804', descripcion: 'REACCION DE INCOMPATIBILIDAD A RH'},
{ codigo: 'T805', descripcion: 'CHOQUE ANAFILACTICO DEBIDO A SUERO'},
{ codigo: 'T806', descripcion: 'OTRAS REACCIONES AL SUERO'},
{ codigo: 'T808', descripcion: 'OTRAS COMPLICACIONES CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA'},
{ codigo: 'T809', descripcion: 'COMPLICACIONES NO ESPECIFICADAS CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA'},
{ codigo: 'T81', descripcion: 'COMPLICACIONES DE PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T810', descripcion: 'HEMORRAGIA Y HEMATOMA QUE COMPLICAN UN PROCEDIMIENTO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T811', descripcion: 'CHOQUE DURANTE O RESULTANTE DE UN PROCEDIMIENTO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T812', descripcion: 'PUNCION O LACERACION ACCIDENTAL DURANTE UN PROCEDIMIENTO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T813', descripcion: 'DESGARRO DE HERIDA OPERATORIA, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'T814', descripcion: 'INFECCION CONSECUTIVA A PROCEDIMIENTO, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'T815', descripcion: 'CUERPO EXTRA—O DEJADO ACCIDENTALMENTE EN CAVIDAD CORPORAL O EN HERIDA OPERATORIA CONSCUTIVA A PROCEDIMIENTO'},
{ codigo: 'T816', descripcion: 'REACCION AGUDA A SUSTANCIA EXTRA—A DEJADA ACCIDENTALMENTE DURANTE UN PROCEDIMIENTO'},
{ codigo: 'T817', descripcion: 'COMPLICACIONES VASCULARES CONSECUTIVAS A PROCEDIMIENTO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T818', descripcion: 'OTRAS COMPLICACIONES DE PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T819', descripcion: 'COMPLICACION DE PROCEDIMIENTOS, NO ESPECIFICADA'},
{ codigo: 'T82', descripcion: 'COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS CARDIOVASCULARES'},
{ codigo: 'T820', descripcion: 'COMPLICACION MECANICA DE PROTESIS DE VALVULA CARDIACA'},
{ codigo: 'T821', descripcion: 'COMPLICACION MECANICA DE DISPOSITIVO ELECTRONICO CARDIACO'},
{ codigo: 'T822', descripcion: 'COMPLICACION MECANICA DERIVACION ARTERIA CORONARIA E INJERTO VALVULAR'},
{ codigo: 'T823', descripcion: 'COMPLICACION MECANICA DE OTROS INJERTOS VASCULARES'},
{ codigo: 'T824', descripcion: 'COMPLICACION MECANICA DE CATETER PARA DIALISIS VASCULAR'},
{ codigo: 'T825', descripcion: 'COMPLICACION MECANICA DE OTROS DISPOSITIVOS E IMPLANTES CARDIOVASCULARES'},
{ codigo: 'T826', descripcion: 'INFECCION Y REACCION INFLAMATORIA POR PROTESIS DE VALVULA CARDIACA'},
{ codigo: 'T827', descripcion: 'INFECCION Y REACCION INFLAMATORIA POR OTROS DISPOSITIVOS, IMPLANTES E INJERTOS CARDIOVASCULARES'},
{ codigo: 'T828', descripcion: 'OTRAS COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS CARDIOVASCULARES'},
{ codigo: 'T829', descripcion: 'COMPLICACIONES NO ESPECIFICADAS DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS CARDIOVASCULARES'},
{ codigo: 'T83', descripcion: 'COMPLICACIONES DE DISPOSITIVOS, IMPLANTES E INJERTOS GENITOURINARIOS'},
{ codigo: 'T830', descripcion: 'COMPLICACION MECANICA DE CATETER URINARIO (FIJO)'},
{ codigo: 'T831', descripcion: 'COMPLICACION MECANICA DE OTROS DISPOSITIVOS E IMPLANTES URINARIOS'},
{ codigo: 'T832', descripcion: 'COMPLICACION MECANICA DE INJERTO EN ORGANO URINARIO'},
{ codigo: 'T833', descripcion: 'COMPLICACION MECANICA DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO'},
{ codigo: 'T834', descripcion: 'COMPLICACION MECANICA DE OTROS DISPOSITIVOS, IMPLANTES E INJERTOS EN TRACTO GENITAL'},
{ codigo: 'T835', descripcion: 'INFECCION Y REACCION INFLAMATORIA DEBIDAS A DISPOSITIVO PROTESICO, IMPLANTE E INJERTO EN EL SISTEMA URINARIO'},
{ codigo: 'T836', descripcion: 'INFECCION Y REACCION INFLAMATORIA DEBIDAS A DISPOSITIVO PROTESICO, IMPLANTE E INJERTO EN EL TRACTO GENITAL'},
{ codigo: 'T838', descripcion: 'OTRAS COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS GENITOURINARIOS'},
{ codigo: 'T839', descripcion: 'COMPLICACION NO ESPECIFICADA DE DISPOSITIVO PROTESICO, IMPLANTE, E INJERTO GENITOURINARIO'},
{ codigo: 'T84', descripcion: 'COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS'},
{ codigo: 'T840', descripcion: 'COMPLICACION MECANICA DE PROTESIS ARTICULAR INTERNA'},
{ codigo: 'T841', descripcion: 'COMPLICACION MECANICA DE DISPOSITIVO DE FIJACION INTERNA DE HUESOS DE UN MIEMBRO'},
{ codigo: 'T842', descripcion: 'COMPLICACION MECANICA DE DISPOSITIVO DE FIJACION INTERNA DE OTROS HUESOS'},
{ codigo: 'T843', descripcion: 'COMPLICACION MECANICA DE OTROS DISPOSITIVOS OSEOS, IMPLANTES E INJERTOS'},
{ codigo: 'T844', descripcion: 'COMPLICACION MECANICA DE OTROS DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS'},
{ codigo: 'T845', descripcion: 'INFECCION Y REACCION INFLAMATORIA DEBIDAS A PROTESIS ARTICULAR INTERNA'},
{ codigo: 'T846', descripcion: 'INFECCION Y REACCION INFLAMATORIA DEBIDAS A DISPOSITIVO DE FIJACION INTERNA (CUALQUIER SITIO)'},
{ codigo: 'T847', descripcion: 'INFECCION Y REACCION INFLAMATORIA DEBIDAS A OTROS DISPOSITIVOS PROTESICOS, IMPLANTEE E INJERTOS ORTOPEDICOS INTERNOS'},
{ codigo: 'T848', descripcion: 'OTRAS COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS'},
{ codigo: 'T849', descripcion: 'COMPLICACIONES NO ESPECIFICADAS DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS'},
{ codigo: 'T85', descripcion: 'COMPLICAIONES DE OTROS DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS INTERNOS'},
{ codigo: 'T850', descripcion: 'COMPLICACION MECANICA DE DERIVACION (ANASTOMOTICA) VENTRICULAR INTRACRANEAL'},
{ codigo: 'T851', descripcion: 'COMPLICACION MECANICA DE IMPLANTE ESTIMULADOR ELECTRONICO DE SISTEMA NERVIOSO'},
{ codigo: 'T852', descripcion: 'COMPLICACION MECANICA DE LENTES INTRAOCULARES'},
{ codigo: 'T853', descripcion: 'COMPLICACION MECANICA DE OTROS DISPOSITIVOS PROTESICO, IMPLANTES E INJERTOS OCULARES'},
{ codigo: 'T854', descripcion: 'COMPLICACION MECANICA DE PROTESIS E IMPLANTE DE MAMA'},
{ codigo: 'T855', descripcion: 'COMPLICACION MECANICA DE DISPOSITIVO PROTESICO, IMPLANTE E INJERTO GASTROINTESTINAL'},
{ codigo: 'T856', descripcion: 'COMPLICAION MECANICA DE OTROS DISPOSITIVOS PROTESICOS, IMPLANTE E INJERTOS INTERNOS ESPECIFICADOS'},
{ codigo: 'T857', descripcion: 'INFECION Y REACCION INFLAMATORIA DEBIDA A OTROS DISPOSITIVOS PROTESICOS, IMPLANTE E INJERTO INTERNO'},
{ codigo: 'T858', descripcion: 'OTRAS COMPLICACIONES DE DISPOSITIVOS PROTESICO, IMPLANTE E INJERTO INTERNO, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T859', descripcion: 'COMPLICACION NO ESPECIFICADA DE DISPOSITIVO PROTESICO, IMPLANTE E INJERTO INTERNO'},
{ codigo: 'T86', descripcion: 'FALLA Y RECHAZO DEL TRASPLANTE DE ORGANOS Y TEJIDOS'},
{ codigo: 'T860', descripcion: 'RECHAZO DE TRASPLANTE DE MEDULA OSEA'},
{ codigo: 'T861', descripcion: 'FALLA Y RECHAZO DE TRASPLANTE DE RI—ON'},
{ codigo: 'T862', descripcion: 'FALLA Y RECHAZO DE TRASPLANTE DE CORAZON'},
{ codigo: 'T863', descripcion: 'FALLA Y RECHAZO DE TRASPLANTE DE PULMON-CORAZON'},
{ codigo: 'T864', descripcion: 'FALLA Y RECHAZO DE TRASPLANTE DE HIGADO'},
{ codigo: 'T868', descripcion: 'FALLA Y RECHAZO DE OTROS ORGANOS Y TEJIDOS TRASPLANTADOS'},
{ codigo: 'T869', descripcion: 'FALLA Y RECHAZO DE TRASPLANTE DE ORGANO Y TEJIDO NO ESPECIFICADO'},
{ codigo: 'T87', descripcion: 'COMPLICACIONES PECULIARES DE LA REINSERCION Y AMPUTACION'},
{ codigo: 'T870', descripcion: 'COMPLICACIONES DE LA REINSERCION (DE PARTE) DE EXTREMIDAD SUPERIOR'},
{ codigo: 'T871', descripcion: 'COMPLICACIONES DE LA REINSERCION (DE PARTE) DE EXTREMIDAD INFERIOR'},
{ codigo: 'T872', descripcion: 'COMPLICACIONES DE OTRAS PARTES DEL CUERPO REINSERTADAS'},
{ codigo: 'T873', descripcion: 'NEUROMA DE MU—ON DE AMPUTACION'},
{ codigo: 'T874', descripcion: 'INFECCION DE MU—ON DE AMPUTACION'},
{ codigo: 'T875', descripcion: 'NECROSIS DE MU—ON DE AMPUTACION'},
{ codigo: 'T876', descripcion: 'OTRAS COMPLICACIONES Y LAS NO ESPECIFICADAS DE MU—ON DE AMPUTACION'},
{ codigo: 'T88', descripcion: 'OTRAS COMPLICACIONES DE LA ATENCION MEDICA Y QUIRURGICA, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T880', descripcion: 'INFECCION CONSECUTIVA A INMUNIZACION'},
{ codigo: 'T881', descripcion: 'OTRAS COMPLICACIONES CONSECUTIVAS A INMUNIZACION, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T882', descripcion: 'CHOQUE DEBIDO A LA ANESTESIA'},
{ codigo: 'T883', descripcion: 'HIPERTERMIA MALIGNA DEBIDA A LA ANESTESIA'},
{ codigo: 'T884', descripcion: 'FALLA O DIFICULTAD DE LA INTUBACION'},
{ codigo: 'T885', descripcion: 'OTRAS COMPLICACIONES DE LA ANESTESIA'},
{ codigo: 'T886', descripcion: 'CHOQUE ANAFILACTICO DEBIDO A EFECTO ADVERSO DE DROGA O MEDICAMENTO CORRECTO ADMINISTRADO APROPIADAMENTE'},
{ codigo: 'T887', descripcion: 'EFECTO ADVERSO NO ESPECIFICADO DE DROGA O MEDICAMENTO'},
{ codigo: 'T888', descripcion: 'OTRAS COMPLICACIONES ESPECIFICADAS DE LA ATENCION MEDICA Y QUIRURGICA, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'T889', descripcion: 'COMPLICACIONES NO ESPECIFICADAS DE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'T90', descripcion: 'SECUELAS DE TRAUMATISMOS DE LA CABEZA'},
{ codigo: 'T900', descripcion: 'SECUELAS DE TRAUMATISMO SUPERFICIAL DE LA CABEZA'},
{ codigo: 'T901', descripcion: 'SECUELAS DE HERIDA DE LA CABEZA'},
{ codigo: 'T902', descripcion: 'SECUELAS DE FRACTURA DEL CRANEO Y DE HUESOS FACIALES'},
{ codigo: 'T903', descripcion: 'SECUELAS DE TRAUMATISMO DE NERVIOS CRANEALES'},
{ codigo: 'T904', descripcion: 'SECUELAS DE TRAUMATISMO DEL OJO Y DE LA ORBITA'},
{ codigo: 'T905', descripcion: 'SECUELAS DE TRAUMATISMO INTRACRANEAL'},
{ codigo: 'T908', descripcion: 'SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE LA CABEZA'},
{ codigo: 'T909', descripcion: 'SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE LA CABEZA'},
{ codigo: 'T91', descripcion: 'SECUELAS DE TRAUMATISMOS DEL CUELLO Y DEL TRONCO'},
{ codigo: 'T910', descripcion: 'SECUELAS DE TRAUMATISMO SUPERFICIAL Y DE HERIDAS DEL CUELLO Y TRONCO'},
{ codigo: 'T911', descripcion: 'SECUELAS DE FRACTURA DE LA COLUMNA VERTEBRAL'},
{ codigo: 'T912', descripcion: 'SECUELAS DE OTRA FRACTURA DEL TORAX Y DE LA PELVIS'},
{ codigo: 'T913', descripcion: 'SECUELAS DE TRAUMATISMO DE LA MEDULA ESPINAL'},
{ codigo: 'T914', descripcion: 'SECUELAS DE TRAUMATISMO DE ORGANOS INTRATORACICOS'},
{ codigo: 'T915', descripcion: 'SECUELAS DE TRAUMATISMO DE ORGANOS INTRAABDOMINALES Y PELVICOS'},
{ codigo: 'T918', descripcion: 'SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DEL CUELLO Y DEL TRONCO'},
{ codigo: 'T919', descripcion: 'SECUELAS DE TRAUMATISMO NO ESPECIFICADO DEL CUELLO Y DEL TRONCO'},
{ codigo: 'T92', descripcion: 'SECUELAS DE TRAUMATISMOS DE MIEMBRO SUPERIOR'},
{ codigo: 'T920', descripcion: 'SECUELAS DE HERIDA DE MIEMBRO SUPERIOR'},
{ codigo: 'T921', descripcion: 'SECUELAS DE FRACTURA DEL BRAZO'},
{ codigo: 'T922', descripcion: 'SECUELAS DE FRACTURA DE LA MU—ECA Y DE LA MANO'},
{ codigo: 'T923', descripcion: 'SECUELAS DE LUXACION, TORCEDURA Y ESGUINCE DE MIEMBRO SUPERIOR'},
{ codigo: 'T924', descripcion: 'SECUELAS DE TRAUMATISMO DE NERVIO DE MIEMBRO SUPERIOR'},
{ codigo: 'T925', descripcion: 'SECUELAS DE TRAUMATISMO DE TENDON Y MUSCULO DE MIEMBRO SUPERIOR'},
{ codigo: 'T926', descripcion: 'SECUELAS DE APLASTAMIENTO Y AMPUTACION TRAUMATICAS DE MIEMBRO SUPERIOR'},
{ codigo: 'T928', descripcion: 'SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO SUPERIOR'},
{ codigo: 'T929', descripcion: 'SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE MIEMBRO SUPERIOR'},
{ codigo: 'T93', descripcion: 'SECUELAS DE TRAUMATISMOS DE MIEMBRO INFERIOR'},
{ codigo: 'T930', descripcion: 'SECUELAS DE HERIDA DE MIEMBRO INFERIOR'},
{ codigo: 'T931', descripcion: 'SECUELAS DE FRACTURA DEL FEMUR'},
{ codigo: 'T932', descripcion: 'SECUELAS DE OTRAS FRACTURAS DE MIEMBRO INFERIOR'},
{ codigo: 'T933', descripcion: 'SECUELAS DE LUXACION, TORCEDURA Y ESGUINCE DE MIEMBRO INFERIOR'},
{ codigo: 'T934', descripcion: 'SECUELAS DE TRAUMATISMO DE NERVIO DE MIEMBRO INFERIOR'},
{ codigo: 'T935', descripcion: 'SECUELAS DE TRAUMATISMO DE TENDON Y MUSCULO DE MIEMBRO INFERIOR'},
{ codigo: 'T936', descripcion: 'SECUELAS DE APLASTAMIENTO Y AMPUTACION TRAUMATICAS DE MIEMBRO INFERIOR'},
{ codigo: 'T938', descripcion: 'SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO INFERIOR'},
{ codigo: 'T939', descripcion: 'SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE MIEMBRO INFERIOR'},
{ codigo: 'T94', descripcion: 'SECUELAS DE TRAUMATISMOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO Y LAS NO ESPECIFICADAS'},
{ codigo: 'T940', descripcion: 'SECUELAS DE TRAUMATISMOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO'},
{ codigo: 'T941', descripcion: 'SECUELAS DE TRAUMATISMOS DE REGIONES NO ESPECIFICADAS DEL CUERPO'},
{ codigo: 'T95', descripcion: 'SECUELAS DE QUEMADURAS, CORROSIONES Y CONGELAMIENTOS'},
{ codigo: 'T950', descripcion: 'SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DE LA CABEZA Y CUELLO'},
{ codigo: 'T951', descripcion: 'SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DEL TRONCO'},
{ codigo: 'T952', descripcion: 'SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DE MIEMBRO SUPERIOR'},
{ codigo: 'T953', descripcion: 'SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DE MIEMBRO INFERIOR'},
{ codigo: 'T954', descripcion: 'SECUELAS QUEMADURA Y CORROSION CLASIFICABLES SOLO DE ACUERDOCON LA EXTENSION DE LA SUPERFICIE DEL CUERPO AFECTADA'},
{ codigo: 'T958', descripcion: 'SECUELAS DE OTRAS QUEMADURAS, CORROSIONES Y CONGELAMIENTOS ESPECIFICADOS'},
{ codigo: 'T959', descripcion: 'SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO NO ESPECIFICADOS'},
{ codigo: 'T96', descripcion: 'SECUELAS DE ENVENENAMIENTOS POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'T97', descripcion: 'SECUELAS DE EFECTOS TOXICOS DE SUSTANCIAS DE PROCEDENCIA PRINCIPALMENTE NO MEDICINAL'},
{ codigo: 'T98', descripcion: 'SECUELAS DE OTROS EFECTOS Y LOS NO ESPECIFICADOS DE CAUSAS EXTERNAS'},
{ codigo: 'T980', descripcion: 'SECUELAS DE EFECTOS DE CUERPOS EXTRA—OS QUE PENETRAN ORIFICIOS NATURALES'},
{ codigo: 'T981', descripcion: 'SECUELAS DE OTROS EFECTOS Y LOS NO ESPECIFICADOS DE CAUSAS EXTERNAS'},
{ codigo: 'T982', descripcion: 'SECUELAS DE CIERTAS COMPLICACIONES PRECOCES DE LOS TRAUMATISMOS'},
{ codigo: 'T983', descripcion: 'SECUELAS DE COMPLICACIONES DE LA ATENCION MEDICA Y QUIRURGICA, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'V01', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V010', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO PEDAL, ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V011', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO DE PEDAL, ACCIDENTE DE TRANSITO'},
{ codigo: 'V019', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO DE PEDAL, SIN ESPECIFICACION'},
{ codigo: 'V02', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS'},
{ codigo: 'V020', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, ACCIDENTE NO TRANSITO'},
{ codigo: 'V021', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, ACCIDENTE DE TRANSITO'},
{ codigo: 'V029', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO MOTOR DOS TRES RUEDAS, SIN ESPECIFICACION'},
{ codigo: 'V03', descripcion: 'PEATON LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA'},
{ codigo: 'V030', descripcion: 'PEATON LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, ACCIDENTE NO TRANSITO'},
{ codigo: 'V031', descripcion: 'PEATON LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, ACCIDENTE DE TRANSITO'},
{ codigo: 'V039', descripcion: 'PEATON LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, SIN ESPECIFICACION'},
{ codigo: 'V04', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V040', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V041', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, ACCIDENTE TRANSITO'},
{ codigo: 'V049', descripcion: 'PEATON LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, SIN ESPECIFICACION'},
{ codigo: 'V05', descripcion: 'PEATON LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V050', descripcion: 'PEATON LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, ACCIDENTE NO TRANSITO'},
{ codigo: 'V051', descripcion: 'PEATON LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, ACCIDENTE TRANSITO'},
{ codigo: 'V059', descripcion: 'PEATON LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, SIN ESPECIFICACION'},
{ codigo: 'V06', descripcion: 'PEATON LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V060', descripcion: 'PEATON LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, ACCIDENTE NO TRANSITO'},
{ codigo: 'V061', descripcion: 'PEATON LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, ACCIDENTE TRANSITO'},
{ codigo: 'V069', descripcion: 'PEATON LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, SIN ESPECIFICACION'},
{ codigo: 'V09', descripcion: 'PEATON LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V090', descripcion: 'PEATON LESIONADO EN ACCIDENTE NO DE TRANSITO QUE INVOLUCRA OTROS VEHICULOS DE MOTOR, SIN ESPECIFICACION'},
{ codigo: 'V091', descripcion: 'PEATON LESIONADO EN ACCIDENTE NO DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V092', descripcion: 'PEATON LESIONADO EN ACCIDENTE DE TRANSITO QUE INVOLUCRA OTROS VEHICULOS DE MOTOR, SIN ESPECIFICACION'},
{ codigo: 'V093', descripcion: 'PEATON LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V099', descripcion: 'PEATON LESIONADO EN ACCIDENTE DE TRANSPORTE NO ESPECIFICADO'},
{ codigo: 'V10', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V100', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCCIDENTE NO DE TRANSITO'},
{ codigo: 'V101', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V102', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V103', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DE VEHICULO'},
{ codigo: 'V104', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIENTE DE TRANSITO'},
{ codigo: 'V105', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V109', descripcion: 'CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V11', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA'},
{ codigo: 'V110', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V111', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V112', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V113', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V114', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V115', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V119', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V12', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS'},
{ codigo: 'V120', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V121', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V122', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V123', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V124', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V125', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V129', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DOS O TRES RUEDAS, CICLISTA SIN ESPECIFICCION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V13', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA'},
{ codigo: 'V130', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V131', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V132', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V133', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSOAN LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V134', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V135', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V139', descripcion: 'CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CICLISTAS SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V14', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V140', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V141', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V142', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V143', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V144', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V145', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V149', descripcion: 'CICLISTA LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V15', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V150', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V151', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V152', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V153', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V154', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V155', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V159', descripcion: 'CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CICLIST SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V16', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V160', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V161', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V162', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CICLIST SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V163', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V164', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V165', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V169', descripcion: 'CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V17', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO'},
{ codigo: 'V170', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V171', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V172', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V173', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V174', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V175', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V179', descripcion: 'CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V18', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION'},
{ codigo: 'V180', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V181', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V182', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V183', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V184', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V185', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V189', descripcion: 'CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V19', descripcion: 'CICLISTA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V190', descripcion: 'CONDUCTOR DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V191', descripcion: 'PASAJERO DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V192', descripcion: 'CICLISTA NO ESPECIFICADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V193', descripcion: 'CICLISTA (CUALQUIERA) LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO'},
{ codigo: 'V194', descripcion: 'CONDUCTOR DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y LOS NO ESPECIFICADOS, ACCIDENTE DE TRANSITO'},
{ codigo: 'V195', descripcion: 'PASAJERO DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y LOS NO ESPECIFICADOS, ACCIDENTE DE TRANSITO'},
{ codigo: 'V196', descripcion: 'CICLISTA NO ESPECIFICADO LESIONDO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y LOS NO ESPECIFICADOS, ACCIDENTE DE TRANSITO'},
{ codigo: 'V198', descripcion: 'CICLISTA (CUALQUIERA) LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS'},
{ codigo: 'V199', descripcion: 'CICLISTA (CUALQUIERA) LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V20', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V200', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V201', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V202', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V203', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V204', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V205', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V209', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V21', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V210', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V211', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V212', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V213', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V214', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V215', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V219', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V22', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS'},
{ codigo: 'V220', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V221', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V222', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V223', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V224', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V225', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V229', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V23', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA'},
{ codigo: 'V230', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V231', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V232', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V233', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V234', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V235', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V239', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON AUTO, CAMIONETA O FURGONETA, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V24', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V240', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V241', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V242', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V243', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V244', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V245', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V249', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V25', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V250', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V251', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V252', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V253', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V254', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V255', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V259', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V26', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V260', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V261', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V262', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V263', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V264', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V265', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V269', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, MOTOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V27', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO'},
{ codigo: 'V270', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V271', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V272', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V273', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V274', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V275', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V279', descripcion: 'MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V28', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION'},
{ codigo: 'V280', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V281', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V282', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, MOTOCICLISTA NO ESPECIFICADO, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V283', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V284', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V285', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V289', descripcion: 'MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, MOTOCICLISTA SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V29', descripcion: 'MOTOCICLISTA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V290', descripcion: 'CONDUCTOR DE MOTOCICLETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V291', descripcion: 'PASAJERO DE MOTOCICLETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITOPASAJERO MOTOCICLETA LES, COLIS OTROS VEHIC M, Y SAI, ACC NO TRANSI'},
{ codigo: 'V292', descripcion: 'MOTOCICLISTA NO ESPECIFICADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOSS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V293', descripcion: 'MOTOCICLISTA (CUALQUIERA) LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO'},
{ codigo: 'V294', descripcion: 'CONDUCTOR DE MOTOCICLETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V295', descripcion: 'PASAJERO DE MOTOCICLETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V296', descripcion: 'MOTOCICLISTA NO ESPECIFICADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOSS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V298', descripcion: 'MOTOCICLISTA (CUALQUIERA) LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS'},
{ codigo: 'V299', descripcion: 'MOTOCICLISTA (CUALQUIERA) LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V30', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V300', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V301', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V302', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V303', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMALoCUPANTE NO ESPECIFICADO, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V304', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V305', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V306', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V307', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA DE VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V309', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V31', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V310', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V311', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V312', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V313', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE NO ESPECIFICADO, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V314', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V315', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V316', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V317', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V319', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE NO ESPECIFICADO, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V32', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS'},
{ codigo: 'V320', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V321', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V322', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V323', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V324', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V325', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V326', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V327', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V329', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO MOTOR DE TRES RUEDAS, OCUPANTE SIN ESPECIFIACION, LESIONADO ACCIENTE DE TRANSITO'},
{ codigo: 'V33', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA'},
{ codigo: 'V330', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V331', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V332', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V333', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V334', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V335', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V336', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V337', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V339', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V34', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V340', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V341', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V342', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHIUCLO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V343', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V344', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V345', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V346', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V347', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V349', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V35', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V350', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V351', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V352', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V353', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V354', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V355', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V356', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V357', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V359', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V36', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V360', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V361', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V362', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V363', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V364', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V365', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V366', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V367', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V369', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V37', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO'},
{ codigo: 'V370', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V371', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V372', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V373', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V374', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V375', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V376', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V377', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LEIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V379', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V38', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION'},
{ codigo: 'V380', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V381', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V382', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V383', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V384', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V385', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V386', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V387', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V389', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V39', descripcion: 'OCUPANTE DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V390', descripcion: 'CONDUCTOR DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V391', descripcion: 'PASAJERO DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V392', descripcion: 'OCUPANTE NO ESPECIFICADO DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V393', descripcion: 'OCUPANTE (CUALQUIERA) DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE NO DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V394', descripcion: 'CONDUCTOR DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V395', descripcion: 'PASAJERO DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V396', descripcion: 'OCUPANTE SIN ESPECIFICACION DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V398', descripcion: 'OCUPANTE (CUALQUIERA) DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS'},
{ codigo: 'V399', descripcion: 'OCUPANTE (CUALQUIERA) DE VEHICULO MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V40', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V400', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V401', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V402', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V403', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V404', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V405', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V406', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V407', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V409', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V41', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V410', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V411', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V412', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V413', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V414', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA LESIONAD AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V415', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V416', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V417', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V419', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V42', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS'},
{ codigo: 'V420', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V421', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V422', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V423', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V424', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V425', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V426', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V427', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V429', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V43', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA'},
{ codigo: 'V430', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V431', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V432', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V433', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V434', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V435', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V436', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V437', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V439', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V44', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V440', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V441', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V442', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V443', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V444', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V445', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V446', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V447', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V449', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V45', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V450', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V451', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V452', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V453', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V454', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V455', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V456', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V457', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V459', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V46', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V460', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V461', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V462', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V463', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V464', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V465', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V466', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V467', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V469', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V47', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO'},
{ codigo: 'V470', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V471', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V472', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V473', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V474', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V475', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V476', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V477', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V479', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V48', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION'},
{ codigo: 'V480', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V481', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V482', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V483', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V484', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V485', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V486', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V487', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V489', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V49', descripcion: 'OCUPANTE DE AUTOMOVIL LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V490', descripcion: 'CONDUCTOR DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V491', descripcion: 'PASAJERO DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V492', descripcion: 'OCUPANTE NO ESPECIFICADO DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V493', descripcion: 'OCUPANTE (CUALQUIERA) DE AUTOMOVIL LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO'},
{ codigo: 'V494', descripcion: 'CONDUCTOR DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V495', descripcion: 'PASAJERO DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V496', descripcion: 'OCUPANTE SIN ESPECIFICACION DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V498', descripcion: 'OCUPANTE (CUALQUIERA) DE AUTOMOVIL LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS'},
{ codigo: 'V499', descripcion: 'OCUPANTE (CUALQUIERA) DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V50', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V500', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V501', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V502', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V503', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V504', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V505', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V506', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V507', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V509', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V51', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V510', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V511', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V512', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V513', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V514', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V515', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V516', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V517', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V519', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V52', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS'},
{ codigo: 'V520', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V521', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V522', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V523', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V524', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V525', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V526', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V527', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V529', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V53', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA'},
{ codigo: 'V530', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V531', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V532', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V533', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V534', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V535', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V536', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V537', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V539', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V54', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V540', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V541', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V542', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V543', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V544', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V545', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V546', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V547', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V549', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V55', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V550', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V551', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V552', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V553', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICAION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V554', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V555', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V556', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V557', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V559', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V56', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V560', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V561', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJ LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V562', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V563', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V564', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V565', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V566', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V567', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V569', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN DEFINICION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V57', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO'},
{ codigo: 'V570', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V571', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V572', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V573', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V574', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V575', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V576', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V577', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V579', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V58', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION'},
{ codigo: 'V580', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V581', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V582', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V583', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V584', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V585', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V586', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V587', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V589', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V59', descripcion: 'OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V590', descripcion: 'CONDUCTOR DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V591', descripcion: 'PASAJERO DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V592', descripcion: 'OCUPANTE SIN ESPECIFICACION DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V593', descripcion: 'OCUPANTE (CUALQUIERA) DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSITO, NO ESPECIFICADO'},
{ codigo: 'V594', descripcion: 'CONDUCTOR DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V595', descripcion: 'PASAJERO DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V596', descripcion: 'OCUPANTE SIN ESPECIFIACION DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V598', descripcion: 'OCUPANTE (CUALQUIERA) DE CAMIONETA O FURGONETA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS'},
{ codigo: 'V599', descripcion: 'OCUPANTE (CUALQUIERA) DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE NO ESPECIFICADO'},
{ codigo: 'V60', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V600', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V601', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V602', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V603', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V604', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V605', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V606', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V607', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V609', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V61', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V610', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V611', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V612', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V613', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V614', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V615', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V616', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V617', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V619', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V62', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS'},
{ codigo: 'V620', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V621', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V622', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V623', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V624', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V625', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V626', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V627', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V629', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V63', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA'},
{ codigo: 'V630', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V631', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V632', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V633', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V634', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V635', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V636', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V637', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V639', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V64', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V640', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V641', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACICDENTE NO DE TRANSITO'},
{ codigo: 'V642', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V643', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V644', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V645', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V646', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V647', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V649', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V65', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V650', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V651', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V652', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V653', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V654', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V655', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V656', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V657', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V659', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUP SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V66', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V660', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V661', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V662', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V663', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V664', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V665', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V666', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V667', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V669', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V67', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO'},
{ codigo: 'V670', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V671', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V672', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO NO DE TRANSITO'},
{ codigo: 'V673', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V674', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V675', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V676', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V677', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V679', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V68', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION'},
{ codigo: 'V680', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V681', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V682', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V683', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V684', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V685', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V686', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V687', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V689', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V69', descripcion: 'OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V690', descripcion: 'CONDUCTOR DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V691', descripcion: 'PASAJERO DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V692', descripcion: 'OCUPANTE SIN ESPECIFICACION DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V693', descripcion: 'OCUPANTE (CUALQUIERA) DE VEHICULO DE TRANSPORTE PESADO LESIONADO ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO'},
{ codigo: 'V694', descripcion: 'CONDUCTOR DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V695', descripcion: 'PASAJERO DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V696', descripcion: 'OCUPANTE SIN ESPECIFICACION DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V698', descripcion: 'OCUPANTE (CUALQUIERA) DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN OTROS ACCIDENTES DE TRANSP ESPECIFICADOS'},
{ codigo: 'V699', descripcion: 'OCUPANTE (CUALQUIERA) DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V70', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V700', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V701', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V702', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V703', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V704', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V705', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V706', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V707', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V709', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V71', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V710', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V711', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V712', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V713', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V714', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V715', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V716', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V717', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V719', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V72', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS'},
{ codigo: 'V720', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V721', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V722', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V723', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V724', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V725', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V726', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V727', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V729', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V73', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA'},
{ codigo: 'V730', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V731', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V732', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V733', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V734', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V735', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V736', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PASAJERO LESIONADO ACCIDENTEDE TRANSITO'},
{ codigo: 'V737', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, PERSONA QUE VIAJA FUERA VEHICULO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V739', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V74', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS'},
{ codigo: 'V740', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V741', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V742', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V743', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V744', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V745', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V746', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V747', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V749', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V75', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V750', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V751', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V752', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V753', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V754', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V755', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V756', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V757', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, PERSONA QUE VIAJA FUERA VEHICULO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V759', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V76', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V760', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V761', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V762', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V763', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V764', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V765', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V766', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V767', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V769', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V77', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO'},
{ codigo: 'V770', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V771', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V772', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V773', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V774', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V775', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V776', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V777', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V779', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V78', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION'},
{ codigo: 'V780', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V781', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V782', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V783', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V784', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO'},
{ codigo: 'V785', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, CONDUCTOR LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V786', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PASAJERO LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V787', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, PERSONA QUE VIAJA FUERA VEHICULO LESIONADA ACCIDENTE DE TRANSITO'},
{ codigo: 'V789', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION, OCUPANTE SIN ESPECIFICACION, LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V79', descripcion: 'OCUPANTE DE AUTOBUS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V790', descripcion: 'CONDUCTOR DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS MOTOR, Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V791', descripcion: 'PASAJERO DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS MOTOR, Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V792', descripcion: 'OCUPANTE NO ESPECIFICADO DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS MOTOR, Y LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V793', descripcion: 'OCUPANTE (CUALQUIERA) DE AUTOBUS LESIONADO EN ACCIDENTE NO DE TRANSITO, SIN ESPECIFICACION'},
{ codigo: 'V794', descripcion: 'CONDUCTOR DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS MOTOR, Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V795', descripcion: 'PASAJERO DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS MOTOR, Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V796', descripcion: 'OCUPANTE SIN ESPECIFICACION DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS MOTOR, Y LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V798', descripcion: 'OCUPANTE (CUALQUIERA) DE AUTOBUS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS'},
{ codigo: 'V799', descripcion: 'OCUPANTE (CUALQUIERA) DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V80', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO EN ACCIDENTE DE TRANSPORTE'},
{ codigo: 'V800', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR CAIDA(O DESPEDIDO) DEL ANIMAL O DEL VEHICULO DE TRACCION ANIMAL EN ACCIDENTE SIN COLISION'},
{ codigo: 'V801', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON PEATON O ANIMAL'},
{ codigo: 'V802', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON VEHICULO DE PEDAL'},
{ codigo: 'V803', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON VEHICULO MOTOR DE DOS O TRES RUEDAS'},
{ codigo: 'V804', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, VEHICULO TRANSPORTE PESADO, O AUTOBUS'},
{ codigo: 'V805', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON OTROS VEHICULOS MOTOR ESPECIFICADOS'},
{ codigo: 'V806', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES'},
{ codigo: 'V807', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR'},
{ codigo: 'V808', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO'},
{ codigo: 'V809', descripcion: 'JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO EN OTROS ACCIDENTES TRANSPORTE, Y EN LOS NO ESPECIFICADOS'},
{ codigo: 'V81', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO EN ACCIDENTE DE TRANSPORTE'},
{ codigo: 'V810', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON VEHICULO MOTOR, EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V811', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON VEHICULO MOTOR, EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V812', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON, O GOLPEADO POR VAGON'},
{ codigo: 'V813', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON OTROS OBJETOS'},
{ codigo: 'V814', descripcion: 'PERSONA LESIONADA AL SUBIR O BAJAR DEL TREN O VEHICULO DE RIELES'},
{ codigo: 'V815', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADA POR CAIDA DENTRO TREN O VEHICULO DE RIELES'},
{ codigo: 'V816', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR CAIDA DESDE TREN O VEHICULO DE RIELES'},
{ codigo: 'V817', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO EN DESCARRILAMIENTO SIN COLISION ANTERIOR'},
{ codigo: 'V818', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO EN OTROS ACCIDENTE FERROVIARIOS ESPECIFICADOS'},
{ codigo: 'V819', descripcion: 'OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO EN ACCIDENTE FERROVIARIO NO ESPECIFICADO'},
{ codigo: 'V82', descripcion: 'OCUPANTE DE TRANVIA LESIONADO EN ACCIDENTE DE TRANSPORTE'},
{ codigo: 'V820', descripcion: 'OCUPANTE DE TRANVIA LESIONADO POR COLISION CON VEHICULO MOTOR EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V821', descripcion: 'OCUPANTE DE TRANVIA LESIONADO POR COLISION CON VEHICULO MOTOR EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V822', descripcion: 'OCUPANTE DE TRANVIA LESIONADO POR COLISION CON, O GOLPEADO POR VAGON'},
{ codigo: 'V823', descripcion: 'OCUPANTE DE TRANVIA LESIONADO POR COLISION CON OTROS OBJETOS'},
{ codigo: 'V824', descripcion: 'PERSONA LESIONADA AL SUBIR O BAJAR DEL TRANVIA'},
{ codigo: 'V825', descripcion: 'OCUPANTE DE TRANVIA LESIONADO POR CAIDA DENTRO DEL TRANVIA'},
{ codigo: 'V826', descripcion: 'OCUPANTE DE TRANVIA LESIONADO POR CAIDA DESDE EL TRANVIA'},
{ codigo: 'V827', descripcion: 'OCUPANTE DE TRANVIA LESIONADO POR DESCARRILAMIENTO, SIN COLISION ANTERIOR'},
{ codigo: 'V828', descripcion: 'OCUPANTE DE TRANVIA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, ESPECIFICADOS'},
{ codigo: 'V829', descripcion: 'OCUPANTE DE TRANVIA LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO'},
{ codigo: 'V83', descripcion: 'OCUPANTE VEHICULO ESPECIAL (DE MOTOR) PARA USO PRINCIPALMENTE EN PLANTAS INDUSTRIALES LESIONADO EN ACCIDENTE DE TRANSPORTE'},
{ codigo: 'V830', descripcion: 'CONDUCTOR DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V831', descripcion: 'PASAJERO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRASITO'},
{ codigo: 'V832', descripcion: 'PERSONA QUE VIAJA FUERA VEHICULO INDUSTRIAL ESPECIAL LESIONADA EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V833', descripcion: 'OCUPANTE NO ESPECIFICADO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V834', descripcion: 'PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO INDUSTRIAL ESPECIAL'},
{ codigo: 'V835', descripcion: 'CONDUCTOR DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V836', descripcion: 'PASAJERO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V837', descripcion: 'PERSONA QUE VIAJA FUERA VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V839', descripcion: 'OCUPANTE NO ESPECIFICADO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V84', descripcion: 'OCUPANTE DE VEHICULO ESPECIAL (MOTOR) PARA USO PRINCIPALMENTE EN AGRICULTURA LESIONADO EN ACCIDENTE DE TRANSPORTE'},
{ codigo: 'V840', descripcion: 'CONDUCTOR DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V841', descripcion: 'PASAJERO DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V842', descripcion: 'PERSONA QUE VIAJA FUERA VEHICULO AGRICOLA ESPECIAL LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V843', descripcion: 'OCUPANTE NO ESPECIFICADO DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO'},
{ codigo: 'V844', descripcion: 'PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO AGRICOLA ESPECIAL'},
{ codigo: 'V845', descripcion: 'CONDUCTOR DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V846', descripcion: 'PASAJERO DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V847', descripcion: 'PERSONA QUE VIAJA FUERA VEHICULO AGRICOLA ESPECIAL LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V849', descripcion: 'OCUPANTE NO ESPECIFICADO DE VEHICULO AGRICOLA ESPECIAL LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V85', descripcion: 'OCUPANTE DE VEHICULO ESPECIAL (MOTOR) PARA CONSTRUCCION LESIONADO EN ACCIDENTE DE TRANSPORTE'},
{ codigo: 'V850', descripcion: 'CONDUCTOR DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V851', descripcion: 'PASAJERO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V852', descripcion: 'PERSONA VIAJA FUERA VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V853', descripcion: 'OCUPANTE NO ESPECIFICADO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V854', descripcion: 'PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO ESPECIAL PARA CONSTRUCION'},
{ codigo: 'V855', descripcion: 'CONDUCTOR DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V856', descripcion: 'PASAJERO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V857', descripcion: 'PERSONA QUE VIAJA FUERA VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V859', descripcion: 'OCUPANTE NO ESPECIFICADO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V86', descripcion: 'OCUPANTE DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRANSPORTE'},
{ codigo: 'V860', descripcion: 'CONDUCTOR DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V861', descripcion: 'PASAJERO DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V862', descripcion: 'PERSONA QUE VIAJA FUERAE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V863', descripcion: 'OCUPANTE SIN ESPECIFICACION DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE DE TRANSITO'},
{ codigo: 'V864', descripcion: 'PERSONA LESIONADA EN ACCIDENTE DE TRANSITO AL SUBIR O BAJAR DE VEHICULO TODO TERRENO O DE OTRO VEHICULO MOTOR PARA USO FUERA DE CARRETERA'},
{ codigo: 'V865', descripcion: 'CONDUCTOR DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V866', descripcion: 'PASAJERO DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V867', descripcion: 'PERSONA QUE VIAJA FUERA DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V869', descripcion: 'OCUPANTE SIN ESPECIFICACION DE VEHICULO ESPECIAL PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE CARRETERA LESIONADO ACCIDENTE NO DE TRANSITO'},
{ codigo: 'V87', descripcion: 'ACCIDENTE DE TRANSITO DE TIPO ESPECIFICADO, PERO DONDE SE DESCONOCE EL MODO DE TRANSPORTE DE LA VICTIMA'},
{ codigo: 'V870', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO MOTOR DOS O TRES RUEDAS (TRANSITO)'},
{ codigo: 'V871', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS MOTOR Y VEHICULO MOTOR DOS O TRES RUEDAS (TRANSITO)'},
{ codigo: 'V872', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y CAMIONETA O FURGONETA (TRANSITO)'},
{ codigo: 'V873', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y AUTOBUS (TRANSITO)'},
{ codigo: 'V874', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO DE TRANSPORTE PESADO (TRANSITO)'},
{ codigo: 'V875', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE VEHICULO TRANSPORTE PESADO Y AUTOBUSBUS (TRANSITO)'},
{ codigo: 'V876', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE TREN O VEHICULO DE RIELES Y AUTOMOVIL (TRANSITO)'},
{ codigo: 'V877', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS DE MOTOR ESPECIFICADOS (TRANSITO)'},
{ codigo: 'V878', descripcion: 'PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULO MOTOR SIN COLISION (TRANSITO)'},
{ codigo: 'V879', descripcion: 'PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULOS SIN MOTOR (CON COLISION) (SIN COLISION) (TRANSITO)'},
{ codigo: 'V88', descripcion: 'ACCIDENTE NO DE TRANSITO DE TIPO ESPECIFICADO, PERO SE DESCONOCE MODO TRANSPORTE DE LA VICTIMA'},
{ codigo: 'V880', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO MOTOR DOS O TRES RUEDAS, NO DE TRANSITO'},
{ codigo: 'V881', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS MOTOR Y VEHICULO MOTOR DOS O TRES RUEDAS, NO DE TRANSITO'},
{ codigo: 'V882', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y CAMIONETA O FURGONETA, NO DE TRANSITO'},
{ codigo: 'V883', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y AUTOBUS, NO DE TRANSITO'},
{ codigo: 'V884', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO DE TRANSPORTE PESADO, NO DE TRANSITO'},
{ codigo: 'V885', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE VEHICULO DE TRANSPORTE PESADO Y AUTOBUS, NO DE TRANSITO'},
{ codigo: 'V886', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE TREN O VEHICULO DE RIELES Y AUTOMOVIL, NO DE TRANSITO'},
{ codigo: 'V887', descripcion: 'PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS MOTOR ESPECIFICADOS, NO DE TRANSITO'},
{ codigo: 'V888', descripcion: 'PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULOS MOTOR, SIN COLISION, NO DE TRANSITO'},
{ codigo: 'V889', descripcion: 'PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULOS SIN MOTOR (CON COLISION) (SIN COLISION), NO DE TRANSITO'},
{ codigo: 'V89', descripcion: 'ACCIDENTE DE VEHICULO DE MOTOR O SIN MOTOR, TIPO DE VEHICULO NO ESPECIFICADO'},
{ codigo: 'V890', descripcion: 'PERSONA LESIONADA EN ACCIDENTE NO DE TRANSITO, DE VEHICULO DE MOTOR NO ESPECIFICADO'},
{ codigo: 'V891', descripcion: 'PERSONA LESIONADA EN ACCIDENTE NO DE TRANSITO, DE VEHICULO SIN MOTOR NO ESPECIFICDO'},
{ codigo: 'V892', descripcion: 'PERSONA LESIONADA EN ACCIDENTE DE TRANSITO, DE VEHICULO DE MOTOR NO ESPECIFIFICADO'},
{ codigo: 'V893', descripcion: 'PERSONA LESIONADA EN ACCIDENTE DE TRANSITO, DE VEHICULO SIN MOTOR NO ESPECIFICADO'},
{ codigo: 'V899', descripcion: 'PERSONA LESIONADA EN ACCIDENTE DE VEHICULO NO ESPECIFICADO'},
{ codigo: 'V90', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION'},
{ codigo: 'V900', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, BARCO MERCANTE'},
{ codigo: 'V901', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, BARCO DE PASAJEROS'},
{ codigo: 'V902', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, BOTE DE PESCA'},
{ codigo: 'V903', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, OTRO VEHICULO ACUATICO DE MOTOR'},
{ codigo: 'V904', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, VELERO'},
{ codigo: 'V905', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, CANOA O KAYAK'},
{ codigo: 'V906', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, BALSA INFLABLE (SIN MOTOR)'},
{ codigo: 'V907', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, ESQUI ACUATICO'},
{ codigo: 'V908', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, OTRO VEHICULO ACUATICO SIN MOTOR'},
{ codigo: 'V909', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION, VEHICULO ACUATICO NO ESPECIFICADO'},
{ codigo: 'V91', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO'},
{ codigo: 'V910', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BARCO MERCANTE'},
{ codigo: 'V911', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BARCO DE PASAJEROS'},
{ codigo: 'V912', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BOTE DE PESCA'},
{ codigo: 'V913', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, OTRO VEHICULO ACUATICO DE MOTOR'},
{ codigo: 'V914', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, VELERO'},
{ codigo: 'V915', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, CANOA O KAYAK'},
{ codigo: 'V916', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, BALSA INFLAMABLE (SIN MOTOR)'},
{ codigo: 'V917', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, ESQUI ACUATICO'},
{ codigo: 'V918', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, OTRO VEHICULO ACUATICO SIN MOTOR'},
{ codigo: 'V919', descripcion: 'ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO, VEHICULO ACUATICO NO ESPECIFICADO'},
{ codigo: 'V92', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION'},
{ codigo: 'V920', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, BARCO MERCANTE'},
{ codigo: 'V921', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, BARCO PASAJEROS'},
{ codigo: 'V922', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, BOTE DE PESCA'},
{ codigo: 'V923', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, OTRO VEHICULO ACUATICO MOTOR'},
{ codigo: 'V924', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, VELERO'},
{ codigo: 'V925', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, CANOA O KAYAK'},
{ codigo: 'V926', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, BALSA INFLABLE (SIN MOTOR)'},
{ codigo: 'V927', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, ESQUI ACUATICO'},
{ codigo: 'V928', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, OTRO VEHICULO ACUATICO SIN MOTOR'},
{ codigo: 'V929', descripcion: 'AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION, VEHICULO ACUATICO NO ESPECIFICADO'},
{ codigo: 'V93', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION'},
{ codigo: 'V930', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, BARCO MERCATE'},
{ codigo: 'V931', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, BARCO PASAJEROS'},
{ codigo: 'V932', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, BOTE DE PESCA'},
{ codigo: 'V933', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, OTRO VEHICULO ACUATICO DE MOTOR'},
{ codigo: 'V934', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, VELERO'},
{ codigo: 'V935', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, CANOA O KAYAK'},
{ codigo: 'V936', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, BALSA INFLABLE (SIN MOTOR)'},
{ codigo: 'V937', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, ESQUI ACUATICO'},
{ codigo: 'V938', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, OTRO VEHICULO ACUATICO SIN MOTOR'},
{ codigo: 'V939', descripcion: 'ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE CAUSA AHOGAMIENTO O SUMERSION, VEHICULO ACUATICO NO ESPECIFICADO'},
{ codigo: 'V94', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS'},
{ codigo: 'V940', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BARCO MERCANTE'},
{ codigo: 'V941', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BARCO DE PASAJEROS'},
{ codigo: 'V942', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BOTE DE PESCA'},
{ codigo: 'V943', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, OTRO VEHICULO ACUATICO MOTOR'},
{ codigo: 'V944', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, VELERO'},
{ codigo: 'V945', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, CANOA O KAYAK'},
{ codigo: 'V946', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, BALSA INFLABLE (SIN MOTOR)'},
{ codigo: 'V947', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, ESQUI ACUATICO'},
{ codigo: 'V948', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, OTRO VEHICULO ACUATICO SIN MOTOR'},
{ codigo: 'V949', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS, VEHICULO ACUATICO NO ESPECIFICADO'},
{ codigo: 'V95', descripcion: 'ACCIDENTE DE AERONAVE DE MOTOR, CON OCUPANTE LESIONADO'},
{ codigo: 'V950', descripcion: 'ACCIDENTE DE HELICOPTERO CON OCUPANTE LESIONADO'},
{ codigo: 'V951', descripcion: 'ACCIDENTE DE PLANEADOR ULTRA LIVIANO, MICRO LIVIANO O MOTORIZADO, CON OCUPANTE LESIONADO'},
{ codigo: 'V952', descripcion: 'ACCIDENTE DE OTROS VEHICULOS AEREOS DE ALAS FIJAS, PRIVADOS, CON OCUPANTE LESIONADO'},
{ codigo: 'V953', descripcion: 'ACCIDENTE DE VEHICULO AEREO DE ALAS FIJAS, COMERCIAL, CON OCUPANTE LESIONADO'},
{ codigo: 'V954', descripcion: 'ACCIDENTE DE NAVE ESPACIAL, CON OCUPANTE LESIONADO'},
{ codigo: 'V958', descripcion: 'ACCIDENTE DE OTRAS AERONAVES, CON OCUPANTE LESIONADO'},
{ codigo: 'V959', descripcion: 'ACCIDENTE DE AERONAVE NO ESPECIFICADA, CON OCUPANTE LESIONADO'},
{ codigo: 'V96', descripcion: 'ACCIDENTE DE AERONAVE SIN MOTOR, CON OCUPANTE LESIONADO'},
{ codigo: 'V960', descripcion: 'ACCIDENTE DE GLOBO AEROSTATICO, CON OCUPANTE LESIONADO'},
{ codigo: 'V961', descripcion: 'ACCIDENTE DE ALA DELTA, CON OCUPANTE LESIONADO'},
{ codigo: 'V962', descripcion: 'ACCIDENTE DE PLANEADOR (SIN MOTOR), CON OCUPANTE LESIONADO'},
{ codigo: 'V968', descripcion: 'ACCIDENTE DE OTRAS AERONAVES SIN MOTOR, CON OCUPANTE LESIONADO'},
{ codigo: 'V969', descripcion: 'ACCIDENTE DE AERONAVE SIN MOTOR NO ESPECIFICADA, CON OCUPANTE LESIONADO'},
{ codigo: 'V97', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE AEREO ESPECIFICADOS'},
{ codigo: 'V970', descripcion: 'OCUPANTE DE AERONAVE LESIONADO EN OTROS ACCIDENTES ESPECIFICDOS DE TRANSPORTE AEREO'},
{ codigo: 'V971', descripcion: 'PERSONA LESIONADA AL SUBIR O BAJAR DE UNA AERONAVE'},
{ codigo: 'V972', descripcion: 'PARACAIDISTA LESIONADO EN ACCIDENTE DE TRANSPORTE AEREO'},
{ codigo: 'V973', descripcion: 'PERSONA EN TIERRA LESIONADA POR ACCIDENTE DE TRANSPORTE AEREO'},
{ codigo: 'V978', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE AEREO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'V98', descripcion: 'OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS'},
{ codigo: 'V99', descripcion: 'ACCIDENTE DE TRANSPORTE NO ESPECIFICADO'},
{ codigo: 'W00', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE'},
{ codigo: 'W000', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN VIVIENDA'},
{ codigo: 'W001', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W002', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN ESCUELAS Y OTRAS INSTUCIONES O AREAS DE LA ADMINISTRACION PUBLICA'},
{ codigo: 'W003', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W004', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN CALLES Y CARRETERAS'},
{ codigo: 'W005', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W006', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W007', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN GRANJA'},
{ codigo: 'W008', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W009', descripcion: 'CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W01', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESPLAZAMIENTO, TROPEZON Y TRASPIE'},
{ codigo: 'W010', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN VIVIENDA'},
{ codigo: 'W011', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W012', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE LA ADMINISTRACION PUBLICA'},
{ codigo: 'W013', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W014', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN CALLES Y CARRETERAS'},
{ codigo: 'W015', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W016', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W017', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN GRANJA'},
{ codigo: 'W018', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W019', descripcion: 'CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W02', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA'},
{ codigo: 'W020', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN VIVIENDA'},
{ codigo: 'W021', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W022', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE LA ADMINISTRACION PUBLICA'},
{ codigo: 'W023', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W024', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN CALLES Y CARRETERAS'},
{ codigo: 'W025', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W026', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W027', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN GRANJA'},
{ codigo: 'W028', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W029', descripcion: 'CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA , EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W03', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA'},
{ codigo: 'W030', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN VIVIENDA'},
{ codigo: 'W031', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W032', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN ESCUELAS Y OTRAS INSTITUCIONES Y ARERAS DE LA ADMINISTRACION PUBLICA'},
{ codigo: 'W033', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W034', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN CALLES Y CARRETERAS'},
{ codigo: 'W035', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN COMERCIO Y AREAS SERVICIOS'},
{ codigo: 'W036', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W037', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN GRANJA'},
{ codigo: 'W038', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W039', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W04', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS'},
{ codigo: 'W040', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN VIVIENDA'},
{ codigo: 'W041', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W042', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN ESCUELAS Y OTRAS INSTUCIONES Y AREAS DE ADMINISTRACIONES PUBLICAS'},
{ codigo: 'W043', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W044', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W045', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W046', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONASCAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W047', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN GRANJA'},
{ codigo: 'W048', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W049', descripcion: 'CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W05', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS'},
{ codigo: 'W050', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN VIVIENDA'},
{ codigo: 'W051', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W052', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE LA ADMINISTRACION PUBLICA'},
{ codigo: 'W053', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W054', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W055', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W056', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W057', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN GRANJA'},
{ codigo: 'W058', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W059', descripcion: 'CAIDA QUE IMPLICA SILLA DE RUEDAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W06', descripcion: 'CAIDA QUE IMPLICA CAMA'},
{ codigo: 'W060', descripcion: 'CAIDA QUE IMPLICA CAMA, EN VIVIENDA'},
{ codigo: 'W061', descripcion: 'CAIDA QUE IMPLICA CAMA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W062', descripcion: 'CAIDA QUE IMPLICA CAMA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACIONES PUBLICAS'},
{ codigo: 'W063', descripcion: 'CAIDA QUE IMPLICA CAMA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W064', descripcion: 'CAIDA QUE IMPLICA CAMA, EN CALLES Y CARRETERAS'},
{ codigo: 'W065', descripcion: 'CAIDA QUE IMPLICA CAMA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W066', descripcion: 'CAIDA QUE IMPLICA CAMA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W067', descripcion: 'CAIDA QUE IMPLICA CAMA, EN GRANJA'},
{ codigo: 'W068', descripcion: 'CAIDA QUE IMPLICA CAMA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W069', descripcion: 'CAIDA QUE IMPLICA CAMA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W07', descripcion: 'CAIDA QUE IMPLICA SILLA'},
{ codigo: 'W070', descripcion: 'CAIDA QUE IMPLICA SILLA, EN VIVIENDA'},
{ codigo: 'W071', descripcion: 'CAIDA QUE IMPLICA SILLA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W072', descripcion: 'CAIDA QUE IMPLICA SILLA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE LA ADMINISTRACION PUBLICA'},
{ codigo: 'W073', descripcion: 'CAIDA QUE IMPLICA SILLA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W074', descripcion: 'CAIDA QUE IMPLICA SILLA, EN CALLES Y CARRETERAS'},
{ codigo: 'W075', descripcion: 'CAIDA QUE IMPLICA SILLA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W076', descripcion: 'CAIDA QUE IMPLICA SILLA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W077', descripcion: 'CAIDA QUE IMPLICA SILLA, EN GRANJA'},
{ codigo: 'W078', descripcion: 'CAIDA QUE IMPLICA SILLA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W079', descripcion: 'CAIDA QUE IMPLICA SILLA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W08', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE'},
{ codigo: 'W080', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN VIVIENDA'},
{ codigo: 'W081', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W082', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACIONES PUBLICAS'},
{ codigo: 'W083', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W084', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN CALLES Y CARRETERAS'},
{ codigo: 'W085', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W086', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W087', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN GRANJA'},
{ codigo: 'W088', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W089', descripcion: 'CAIDA QUE IMPLICA OTRO MUEBLE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W09', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES'},
{ codigo: 'W090', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN VIVIENDA'},
{ codigo: 'W091', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W092', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W093', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W094', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN CALLES Y CARRETERAS'},
{ codigo: 'W095', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W096', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W097', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN GRANJA'},
{ codigo: 'W098', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W099', descripcion: 'CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W10', descripcion: 'CAIDA EN O DESDE ESCALERA O ESCALONES'},
{ codigo: 'W100', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN VIVIENDA'},
{ codigo: 'W101', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W102', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN ESCUELAS Y OTRAS INSTUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W103', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W104', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN CALLES Y CARRETERAS'},
{ codigo: 'W105', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W106', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W107', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN GRANJA'},
{ codigo: 'W108', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W109', descripcion: 'CAIDA EN O DESDE ESCALERA Y ESCALONES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W11', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES'},
{ codigo: 'W110', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN VIVIENDA'},
{ codigo: 'W111', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W112', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN ESCUELAS Y OTRAS INSTUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W113', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W114', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN CALLES Y CARRETERAS'},
{ codigo: 'W115', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W116', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W117', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN GRANJA'},
{ codigo: 'W118', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W119', descripcion: 'CAIDA EN O DESDE ESCALERAS MANUALES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W12', descripcion: 'CAIDA EN O DESDE ANDAMIO'},
{ codigo: 'W120', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN VIVIENDA'},
{ codigo: 'W121', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W122', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN ESCUELAS Y OTRAS INSTUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W123', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W124', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN CALLES Y CARRETERAS'},
{ codigo: 'W125', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W126', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W127', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN GRANJA'},
{ codigo: 'W128', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W129', descripcion: 'CAIDA EN O DESDE ANDAMIO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W13', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION'},
{ codigo: 'W130', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN VIVIENDA'},
{ codigo: 'W131', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W132', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W133', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W134', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN CALLES Y CARRETERAS'},
{ codigo: 'W135', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W136', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W137', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN GRANJA'},
{ codigo: 'W138', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W139', descripcion: 'CAIDA DESDE, FUERA O A TRAVES DE UN EDIFICIO U OTRA CONSTRUCCION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W14', descripcion: 'CAIDA DESDE UN ARBOL'},
{ codigo: 'W140', descripcion: 'CAIDA DESDE UN ARBOL, EN VIVIENDA'},
{ codigo: 'W141', descripcion: 'CAIDA DESDE UN ARBOL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W142', descripcion: 'CAIDA DESDE UN ARBOL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W143', descripcion: 'CAIDA DESDE UN ARBOL, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W144', descripcion: 'CAIDA DESDE UN ARBOL, EN CALLES Y CARRETERAS'},
{ codigo: 'W145', descripcion: 'CAIDA DESDE UN ARBOL, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W146', descripcion: 'CAIDA DESDE UN ARBOL, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W147', descripcion: 'CAIDA DESDE UN ARBOL, EN GRANJA'},
{ codigo: 'W148', descripcion: 'CAIDA DESDE UN ARBOL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W149', descripcion: 'CAIDA DESDE UN ARBOL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W15', descripcion: 'CAIDA DESDE PE—ASCO'},
{ codigo: 'W150', descripcion: 'CAIDA DESDE PE—ASCO, EN VIVIENDA'},
{ codigo: 'W151', descripcion: 'CAIDA DESDE PE—ASCO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W152', descripcion: 'CAIDA DESDE PE—ASCO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W153', descripcion: 'CAIDA DESDE PE—ASCO, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W154', descripcion: 'CAIDA DESDE PE—ASCO, EN CALLES Y CARRETERAS'},
{ codigo: 'W155', descripcion: 'CAIDA DESDE PE—ASCO, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W156', descripcion: 'CAIDA DESDE PE—ASCO, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W157', descripcion: 'CAIDA DESDE PE—ASCO, EN GRANJA'},
{ codigo: 'W158', descripcion: 'CAIDA DESDE PE—ASCO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W159', descripcion: 'CAIDA DESDE PE—ASCO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W16', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO'},
{ codigo: 'W160', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN VIVIENDA'},
{ codigo: 'W161', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W162', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W163', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN AREA DE DEPORTE Y ATLETISMO'},
{ codigo: 'W164', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN CALLES Y CARRETERAS'},
{ codigo: 'W165', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W166', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN AREAS INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W167', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN GRANJA'},
{ codigo: 'W168', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W169', descripcion: 'SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W17', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO'},
{ codigo: 'W170', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN VIVIENDA'},
{ codigo: 'W171', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W172', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W173', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W174', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN CALLES Y CARRETERAS'},
{ codigo: 'W175', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W176', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN AREA INDUSTRIAL Y DE CONSTRUCCION'},
{ codigo: 'W177', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN GRANJA'},
{ codigo: 'W178', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W179', descripcion: 'OTRAS CAIDAS DE UN NIVEL A OTRO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W18', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL'},
{ codigo: 'W180', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN VIVIENDA'},
{ codigo: 'W181', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W182', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W183', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W184', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN CALLES Y CARRETERAS'},
{ codigo: 'W185', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W186', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN AREA INDUSTRIAL Y DE CONSTRUCCION'},
{ codigo: 'W187', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN GRANJA'},
{ codigo: 'W188', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W189', descripcion: 'OTRAS CAIDAS EN EL MISMO NIVEL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W19', descripcion: 'CAIDA NO ESPECIFICADA'},
{ codigo: 'W190', descripcion: 'CAIDA NO ESPECIFICADA, EN VIVIENDA'},
{ codigo: 'W191', descripcion: 'CAIDA NO ESPECIFICADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W192', descripcion: 'CAIDA NO ESPECIFICADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACIONES PUBLICAS'},
{ codigo: 'W193', descripcion: 'CAIDA NO ESPECIFICADA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W194', descripcion: 'CAIDA NO ESPECIFICADA, EN CALLES Y CARRETERAS'},
{ codigo: 'W195', descripcion: 'CAIDA NO ESPECIFICADA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W196', descripcion: 'CAIDA NO ESPECIFICADA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W197', descripcion: 'CAIDA NO ESPECIFICADA, EN GRANJA'},
{ codigo: 'W198', descripcion: 'CAIDA NO ESPECIFICADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W199', descripcion: 'CAIDA NO ESPECIFICADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W20', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE'},
{ codigo: 'W200', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN VIVIENDA'},
{ codigo: 'W201', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W202', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W203', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W204', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN CALLES Y CARRETERAS'},
{ codigo: 'W205', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W206', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W207', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN GRANJA'},
{ codigo: 'W208', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W209', descripcion: 'GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W21', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES'},
{ codigo: 'W210', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN VIVIENDA'},
{ codigo: 'W211', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W212', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREA DE ADMINISTRACION PUBLICA'},
{ codigo: 'W213', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W214', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN CALLES Y CARRETERAS'},
{ codigo: 'W215', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W216', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W217', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN GRANJA'},
{ codigo: 'W218', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W219', descripcion: 'GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W22', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS'},
{ codigo: 'W220', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN VIVIENDA'},
{ codigo: 'W221', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W222', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W223', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W224', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W225', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W226', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W227', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN GRANJA'},
{ codigo: 'W228', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W229', descripcion: 'GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W23', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS'},
{ codigo: 'W230', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN VIVIENDA'},
{ codigo: 'W231', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN INSTITUCION RESDENCIAL'},
{ codigo: 'W232', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W233', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'W234', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W235', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W236', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W237', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN GRANJA'},
{ codigo: 'W238', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W239', descripcion: 'ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W24', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'W240', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN VIVIENDA'},
{ codigo: 'W241', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W242', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W243', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN AREAS DEPORTES Y ATLETISMO'},
{ codigo: 'W244', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN CALLES Y CARRETERAS'},
{ codigo: 'W245', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W246', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W247', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN GRANJA'},
{ codigo: 'W248', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W249', descripcion: 'CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION NO CLASIFICADOS EN OTRA PARTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W25', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE'},
{ codigo: 'W250', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN VIVIENDA'},
{ codigo: 'W251', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W252', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTTRACION PUBLICA'},
{ codigo: 'W253', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W254', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN CALLES Y CARRETERAS'},
{ codigo: 'W255', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W256', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W257', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN GRANJA'},
{ codigo: 'W258', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W259', descripcion: 'CONTACTO TRAUMATICO CON VIDRIO CORTANTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W26', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL'},
{ codigo: 'W260', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN VIVIENDA'},
{ codigo: 'W261', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W262', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W263', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W264', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN CALLES Y CARRETERAS'},
{ codigo: 'W265', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W266', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W267', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN GRANJA'},
{ codigo: 'W268', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W269', descripcion: 'CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PU—AL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W27', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR'},
{ codigo: 'W270', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN VIVIENDA'},
{ codigo: 'W271', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W272', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W273', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W274', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN CALLES Y CARRETERAS'},
{ codigo: 'W275', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W276', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W277', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN GRANJA'},
{ codigo: 'W278', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W279', descripcion: 'CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W28', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR'},
{ codigo: 'W280', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN VIVIENDA'},
{ codigo: 'W281', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W282', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W283', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W284', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN CALLES Y CARRETERAS'},
{ codigo: 'W285', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W286', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W287', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN GRANJA'},
{ codigo: 'W288', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W289', descripcion: 'CONTACTO TRAUMATICO CON CORTADORA DE CESPED CON MOTOR, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W29', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR'},
{ codigo: 'W290', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN VIVIENDA'},
{ codigo: 'W291', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W292', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W293', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'W294', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN CALLES Y CARRETERAS'},
{ codigo: 'W295', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN COMERC Y AREA SERVICIOS'},
{ codigo: 'W296', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W297', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN GRANJA'},
{ codigo: 'W298', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W299', descripcion: 'CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR CON MOTOR, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W30', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA'},
{ codigo: 'W300', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN VIVIENDA'},
{ codigo: 'W301', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W302', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W303', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W304', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN CALLES Y CARRETERAS'},
{ codigo: 'W305', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W306', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W307', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN GRANJA'},
{ codigo: 'W308', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W309', descripcion: 'CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W31', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'W310', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'W311', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W312', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTUCIONES Y AREAS DE LAS ADMINISTRACIONES PUBLICAS'},
{ codigo: 'W313', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W314', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W315', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W316', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W317', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'W318', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W319', descripcion: 'CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS, Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W32', descripcion: 'DISPARO DE ARMA CORTA'},
{ codigo: 'W320', descripcion: 'DISPARO DE ARMA CORTA, EN VIVIENDA'},
{ codigo: 'W321', descripcion: 'DISPARO DE ARMA CORTA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W322', descripcion: 'DISPARO DE ARMA CORTA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W323', descripcion: 'DISPARO DE ARMA CORTA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W324', descripcion: 'DISPARO DE ARMA CORTA, EN CALLES Y CARRETERAS'},
{ codigo: 'W325', descripcion: 'DISPARO DE ARMA CORTA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W326', descripcion: 'DISPARO DE ARMA CORTA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W327', descripcion: 'DISPARO DE ARMA CORTA, EN GRANJA'},
{ codigo: 'W328', descripcion: 'DISPARO DE ARMA CORTA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W329', descripcion: 'DISPARO DE ARMA CORTA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W33', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA'},
{ codigo: 'W330', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN VIVIENDA'},
{ codigo: 'W331', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W332', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W333', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W334', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN CALLES Y CARRETERAS'},
{ codigo: 'W335', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W336', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W337', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN GRANJA'},
{ codigo: 'W338', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W339', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W34', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS'},
{ codigo: 'W340', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'W341', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W342', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W343', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W344', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W345', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W346', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W347', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'W348', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W349', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W35', descripcion: 'EXPLOSION Y ROTURA DE CALDERA'},
{ codigo: 'W350', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN VIVIENDA'},
{ codigo: 'W351', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W352', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W353', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W354', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN CALLES Y CARRETERAS'},
{ codigo: 'W355', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W356', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W357', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN GRANJA'},
{ codigo: 'W358', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W359', descripcion: 'EXPLOSION Y ROTURA DE CALDERA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W36', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS'},
{ codigo: 'W360', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN VIVIENDA'},
{ codigo: 'W361', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W362', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN ESCUELAS Y OTRAS INSTUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W363', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W364', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W365', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W366', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W367', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN GRANJA'},
{ codigo: 'W368', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W369', descripcion: 'EXPLOSION Y ROTURA DE CILINDRO CON GAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W37', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA'},
{ codigo: 'W370', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN VIVIENDA'},
{ codigo: 'W371', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W372', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACIONES PUBLICAS'},
{ codigo: 'W373', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W374', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN CALLES Y CARRETERAS'},
{ codigo: 'W375', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W376', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W377', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN GRANJA'},
{ codigo: 'W378', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W379', descripcion: 'EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W38', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS'},
{ codigo: 'W380', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'W381', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN INSTUCION RESIDENCIAL'},
{ codigo: 'W382', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W383', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'W384', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W385', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W386', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W387', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN GRANJA'},
{ codigo: 'W388', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W389', descripcion: 'EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W39', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES'},
{ codigo: 'W390', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN VIVIENDA'},
{ codigo: 'W391', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W392', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W393', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W394', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN CALLES Y CARRETERAS'},
{ codigo: 'W395', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W396', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W397', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN GRANJA'},
{ codigo: 'W398', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W399', descripcion: 'EXPLOSION DE FUEGOS ARTIFICIALES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W40', descripcion: 'EXPLOSION DE OTROS MATERIALES'},
{ codigo: 'W400', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN VIVIENDA'},
{ codigo: 'W401', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W402', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W403', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W404', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN CALLES Y CARRETERAS'},
{ codigo: 'W405', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W406', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W407', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN GRANJA'},
{ codigo: 'W408', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W409', descripcion: 'EXPLOSION DE OTROS MATERIALES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W41', descripcion: 'EXPLOSION A CHORRO DE ALTA PRESION'},
{ codigo: 'W410', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN VIVIENDA'},
{ codigo: 'W411', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W412', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W413', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W414', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN CALLES Y CARRETERAS'},
{ codigo: 'W415', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W416', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN AREA INDUSTRIAL Y DE CONSTRUCCION'},
{ codigo: 'W417', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN GRANJA'},
{ codigo: 'W418', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W419', descripcion: 'EXPOSICION A CHORRO DE ALTA PRESION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W42', descripcion: 'EXPOSICION AL RUIDO'},
{ codigo: 'W420', descripcion: 'EXPOSICION AL RUIDO, EN VIVIENDA'},
{ codigo: 'W421', descripcion: 'EXPOSICION AL RUIDO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W422', descripcion: 'EXPOSICION AL RUIDO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W423', descripcion: 'EXPOSICION AL RUIDO, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W424', descripcion: 'EXPOSICION AL RUIDO, EN CALLES Y CARRETERAS'},
{ codigo: 'W425', descripcion: 'EXPOSICION AL RUIDO, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W426', descripcion: 'EXPOSICION AL RUIDO, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W427', descripcion: 'EXPOSICION AL RUIDO, EN GRANJA'},
{ codigo: 'W428', descripcion: 'EXPOSICION AL RUIDO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W429', descripcion: 'EXPOSICION AL RUIDO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W43', descripcion: 'EXPLOSION A VIBRACIONES'},
{ codigo: 'W430', descripcion: 'EXPOSICION A VIBRACIONES, EN VIVIENDA'},
{ codigo: 'W431', descripcion: 'EXPOSICION A VIBRACIONES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W432', descripcion: 'EXPOSICION A VIBRACIONES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W433', descripcion: 'EXPOSICION A VIBRACIONES, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W434', descripcion: 'EXPOSICION A VIBRACIONES, EN CALLES Y CARRETERAS'},
{ codigo: 'W435', descripcion: 'EXPOSICION A VIBRACIONES, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W436', descripcion: 'EXPOSICION A VIBRACIONES, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W437', descripcion: 'EXPOSICION A VIBRACIONES, EN GRANJA'},
{ codigo: 'W438', descripcion: 'EXPOSICION A VIBRACIONES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W439', descripcion: 'EXPOSICION A VIBRACIONES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W44', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL'},
{ codigo: 'W440', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN VIVIENDA'},
{ codigo: 'W441', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W442', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W443', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W444', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN CALLES Y CARRETERAS'},
{ codigo: 'W445', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W446', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W447', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN GRANJA'},
{ codigo: 'W448', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W449', descripcion: 'CUERPO EXTRA—O QUE PENETRA POR EL OJO U ORIFICIO NATURAL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W45', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL'},
{ codigo: 'W450', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN VIVIENDA'},
{ codigo: 'W451', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W452', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W453', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W454', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN CALLES Y CARRETERAS'},
{ codigo: 'W455', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W456', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W457', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN GRANJA'},
{ codigo: 'W458', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W459', descripcion: 'CUERPO EXTRA—O QUE PENETRA A TRAVES DE LA PIEL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W49', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'W490', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'W491', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W492', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W493', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W494', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W495', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W496', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W497', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'W498', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W499', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W50', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA'},
{ codigo: 'W500', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN VIVIENDA'},
{ codigo: 'W501', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W502', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W503', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W504', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN CALLES Y CARRETERAS'},
{ codigo: 'W505', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W506', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W507', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN GRANJA'},
{ codigo: 'W508', descripcion: 'APORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN LUGAR ESPECIFICADO'},
{ codigo: 'W509', descripcion: 'VAPORREO, GOLPE, MORDEDURA, PATADA, RASGU—O, TORCEDURA, INFLINGIDOS POR OTRA PERSONA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W51', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA'},
{ codigo: 'W510', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN VIVIENDA'},
{ codigo: 'W511', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W512', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS ADMINISTRACION PUBLICA'},
{ codigo: 'W513', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W514', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN CALLES Y CARRETERAS'},
{ codigo: 'W515', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W516', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W517', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN GRANJA'},
{ codigo: 'W518', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W519', descripcion: 'CHOQUE O EMPELLON CONTRA OTRA PERSONA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W52', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA'},
{ codigo: 'W520', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN VIVIENDA'},
{ codigo: 'W521', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W522', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN ESCUEALS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W523', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W524', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN CALLES Y CARRETERAS'},
{ codigo: 'W525', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W526', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W527', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN GRANJA'},
{ codigo: 'W528', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W529', descripcion: 'PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W53', descripcion: 'MORDEDURA DE RATA'},
{ codigo: 'W530', descripcion: 'MORDEDURA DE RATA, EN VIVIENDA'},
{ codigo: 'W531', descripcion: 'MORDEDURA DE RATA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W532', descripcion: 'MORDEDURA DE RATA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREA DE ADMINISTRACION PUBLICA'},
{ codigo: 'W533', descripcion: 'MORDEDURA DE RATA, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W534', descripcion: 'MORDEDURA DE RATA, EN CALLES Y CARRETERAS'},
{ codigo: 'W535', descripcion: 'MORDEDURA DE RATA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W536', descripcion: 'MORDEDURA DE RATA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W537', descripcion: 'MORDEDURA DE RATA, EN GRANJA'},
{ codigo: 'W538', descripcion: 'MORDEDURA DE RATA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W539', descripcion: 'MORDEDURA DE RATA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W54', descripcion: 'MORDEDURA O ATAQUE DE PERRO'},
{ codigo: 'W540', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN VIVIENDA'},
{ codigo: 'W541', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W542', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREA DE ADMINISTRACION PUBLICA'},
{ codigo: 'W543', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W544', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN CALLES Y CARRETERAS'},
{ codigo: 'W545', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W546', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'W547', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN GRANJA'},
{ codigo: 'W548', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W549', descripcion: 'MORDEDURA O ATAQUE DE PERRO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W55', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS'},
{ codigo: 'W550', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN VIVIENDA'},
{ codigo: 'W551', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W552', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREA DE ADMINISTRACION PUBLICA'},
{ codigo: 'W553', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W554', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN CALLES Y CARRETERAS'},
{ codigo: 'W555', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W556', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W557', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN GRANJA'},
{ codigo: 'W558', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W559', descripcion: 'MORDEDURA O ATAQUE DE OTROS MAMIFEROS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W56', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS'},
{ codigo: 'W560', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN VIVIENDA'},
{ codigo: 'W561', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W562', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREA DE ADMINISTRACION PUBLICA'},
{ codigo: 'W563', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN AREAS DE DEPORTE Y ATLET'},
{ codigo: 'W564', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W565', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W566', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W567', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN GRANJA'},
{ codigo: 'W568', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W569', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES MARINOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W57', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS'},
{ codigo: 'W570', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN VIVIENDA'},
{ codigo: 'W571', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN INSTITUCION RESIDDENCIAL'},
{ codigo: 'W572', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W573', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W574', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W575', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W576', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W577', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN GRANJA'},
{ codigo: 'W578', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W579', descripcion: 'MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W58', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN'},
{ codigo: 'W580', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN VIVIENDA'},
{ codigo: 'W581', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W582', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W583', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'W584', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN CALLES Y CARRETERAS'},
{ codigo: 'W585', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W586', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W587', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN GRANJA'},
{ codigo: 'W588', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W589', descripcion: 'MORDEDURA O ATAQUE DE COCODRILO O CAIMAN, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W59', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES'},
{ codigo: 'W590', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN VIVIENDA'},
{ codigo: 'W591', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W592', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W593', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W594', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN CALLES Y CARRETERAS'},
{ codigo: 'W595', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W596', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W597', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN GRANJA'},
{ codigo: 'W598', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W599', descripcion: 'MORDEDURA O APLASTAMIENTO POR OTROS REPTILES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W60', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS'},
{ codigo: 'W600', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN VIVIENDA'},
{ codigo: 'W601', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W602', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W603', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W604', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W605', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W606', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W607', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN GRANJA'},
{ codigo: 'W608', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W609', descripcion: 'CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W64', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'W640', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'W641', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W642', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W643', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W644', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W645', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W646', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W647', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'W648', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W649', descripcion: 'EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W65', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA'},
{ codigo: 'W650', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN VIVIENDA'},
{ codigo: 'W651', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W652', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W653', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W654', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN CALLES Y CARRETERAS'},
{ codigo: 'W655', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W656', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W657', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN GRANJA'},
{ codigo: 'W658', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W659', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BA—ERA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W66', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA'},
{ codigo: 'W660', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN VIVIENDA'},
{ codigo: 'W661', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W662', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W663', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W664', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN CALLES Y CARRETERAS'},
{ codigo: 'W665', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W666', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W667', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN GRANJA'},
{ codigo: 'W668', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W669', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BA—ERA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W67', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA'},
{ codigo: 'W670', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN VIVIENDA'},
{ codigo: 'W671', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W672', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W673', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W674', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN CALLES Y CARRETERAS'},
{ codigo: 'W675', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W676', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W677', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN GRANJA'},
{ codigo: 'W678', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W679', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UMA PISCINA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W68', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA'},
{ codigo: 'W680', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN VIVIENDA'},
{ codigo: 'W681', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN INSTITITUCION RESIDENCIAL'},
{ codigo: 'W682', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREA DE ADMINISTRACION PUBLICA'},
{ codigo: 'W683', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W684', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN CALLES Y CARRETERAS'},
{ codigo: 'W685', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W686', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W687', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN GRANJA'},
{ codigo: 'W688', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W689', descripcion: 'AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W69', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES'},
{ codigo: 'W690', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN VIVIENDA'},
{ codigo: 'W691', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W692', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W693', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W694', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN CALLES Y CARRETERAS'},
{ codigo: 'W695', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W696', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W697', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN GRANJA'},
{ codigo: 'W698', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W699', descripcion: 'AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W70', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES'},
{ codigo: 'W700', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN VIVIENDA'},
{ codigo: 'W701', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W702', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W703', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W704', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN CALLES Y CARRETERAS'},
{ codigo: 'W705', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W706', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W707', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN GRANJA'},
{ codigo: 'W708', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W709', descripcion: 'AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W73', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS'},
{ codigo: 'W730', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'W731', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W732', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W733', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W734', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W735', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W736', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W737', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN GRANJA'},
{ codigo: 'W738', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W739', descripcion: 'OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W74', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS'},
{ codigo: 'W740', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'W741', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W742', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W743', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W744', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W745', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W746', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN AREA INDUSTRIAL Y DE CONSTRUCCION'},
{ codigo: 'W747', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'W748', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W749', descripcion: 'AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W75', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA'},
{ codigo: 'W750', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN VIVIENDA'},
{ codigo: 'W751', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W752', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W753', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W754', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN CALLES Y CARRETERAS'},
{ codigo: 'W755', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN COMERCIO Y AREAS SERVICIOS'},
{ codigo: 'W756', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W757', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN GRANJA'},
{ codigo: 'W758', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W759', descripcion: 'SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W76', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES'},
{ codigo: 'W760', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN VIVIENDA'},
{ codigo: 'W761', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W762', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W763', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W764', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN CALLES Y CARRETERAS'},
{ codigo: 'W765', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W766', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W767', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN GRANJA'},
{ codigo: 'W768', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W769', descripcion: 'OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W77', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS'},
{ codigo: 'W770', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN VIVIENDA'},
{ codigo: 'W771', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W772', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W773', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W774', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W775', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W776', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W777', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN GRANJA'},
{ codigo: 'W778', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W779', descripcion: 'OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W78', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS'},
{ codigo: 'W780', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN VIVIENDA'},
{ codigo: 'W781', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W782', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W783', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W784', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN CALLES Y CARRETERAS'},
{ codigo: 'W785', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W786', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W787', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN GRANJA'},
{ codigo: 'W788', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W789', descripcion: 'INHALACION DE CONTENIDOS GASTRICOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W79', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS'},
{ codigo: 'W790', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN VIVIENDA'},
{ codigo: 'W791', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W792', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W793', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W794', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W795', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W796', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W797', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN GRANJA'},
{ codigo: 'W798', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W799', descripcion: 'INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION VIAS RESPIRATORIAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W80', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS'},
{ codigo: 'W800', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN VIVIENDA'},
{ codigo: 'W801', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W802', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W803', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W804', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W805', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W806', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN AREA INSDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W807', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN GRANJA'},
{ codigo: 'W808', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W809', descripcion: 'INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION VIAS RESPIRATORIAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W81', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO'},
{ codigo: 'W810', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN VIVIENDA'},
{ codigo: 'W811', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W812', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W813', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W814', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN CALLES Y CARRETERAS'},
{ codigo: 'W815', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W816', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN AREA INSDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W817', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN GRANJA'},
{ codigo: 'W818', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W819', descripcion: 'CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W83', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION'},
{ codigo: 'W830', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN VIVIENDA'},
{ codigo: 'W831', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W832', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W833', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W834', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN CALLES Y CARRETERAS'},
{ codigo: 'W835', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W836', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W837', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN GRANJA'},
{ codigo: 'W838', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W839', descripcion: 'OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W84', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION'},
{ codigo: 'W840', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN VIVIENDA'},
{ codigo: 'W841', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W842', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W843', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W844', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN CALLES Y CARRETERAS'},
{ codigo: 'W845', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W846', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W847', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN GRANJA'},
{ codigo: 'W848', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W849', descripcion: 'OBSTRUCCION NO ESPECIFICADA DE LA RESPIRACION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W85', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA'},
{ codigo: 'W850', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN VIVIENDA'},
{ codigo: 'W851', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W852', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W853', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W854', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN CALLES Y CARRETERAS'},
{ codigo: 'W855', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W856', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W857', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN GRANJA'},
{ codigo: 'W858', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W859', descripcion: 'EXPOSICION A LINEAS DE TRANSMISION ELECTRICA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W86', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS'},
{ codigo: 'W860', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'W861', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W862', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W863', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W864', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'W865', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN COMERCIO Y ARREA SERVICIOS'},
{ codigo: 'W866', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W867', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN GRANJA'},
{ codigo: 'W868', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W869', descripcion: 'EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W87', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA'},
{ codigo: 'W870', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN VIVIENDA'},
{ codigo: 'W871', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W872', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W873', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W874', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN CALLES Y CARRETERAS'},
{ codigo: 'W875', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W876', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W877', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN GRANJA'},
{ codigo: 'W878', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W879', descripcion: 'EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W88', descripcion: 'EXPOSICION A RADIACION IONIZANTE'},
{ codigo: 'W880', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN VIVIENDA'},
{ codigo: 'W881', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W882', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W883', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W884', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN CALLES Y CARRETERAS'},
{ codigo: 'W885', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W886', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN AREA INDUSTRIAL Y DE CONSTRUCCION'},
{ codigo: 'W887', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN GRANJA'},
{ codigo: 'W888', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W889', descripcion: 'EXPOSICION A RADIACION IONIZANTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W89', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL'},
{ codigo: 'W890', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN VIVIENDA'},
{ codigo: 'W891', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN INSTITUCION RESIDDENCIAL'},
{ codigo: 'W892', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W893', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W894', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN CALLES Y CARRETERAS'},
{ codigo: 'W895', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W896', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W897', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN GRANJA'},
{ codigo: 'W898', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W899', descripcion: 'EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA DE ORIGEN ARTIFICIAL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W90', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE'},
{ codigo: 'W900', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN VIVIENDA'},
{ codigo: 'W901', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W902', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W903', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W904', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN CALLES Y CARRETERAS'},
{ codigo: 'W905', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W906', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W907', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN GRANJA'},
{ codigo: 'W908', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W909', descripcion: 'EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W91', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO'},
{ codigo: 'W910', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN VIVIENDA'},
{ codigo: 'W911', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W912', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W913', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W914', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN CALLES Y CARRETERAS'},
{ codigo: 'W915', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W916', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W917', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN GRANJA'},
{ codigo: 'W918', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W919', descripcion: 'EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W92', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL'},
{ codigo: 'W920', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN VIVIENDA'},
{ codigo: 'W921', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W922', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W923', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W924', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN CALLES Y CARRETERAS'},
{ codigo: 'W925', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN COMERCIO Y AREA DE SERVICIOS'},
{ codigo: 'W926', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W927', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN GRANJA'},
{ codigo: 'W928', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W929', descripcion: 'EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W93', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL'},
{ codigo: 'W930', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN VIVIENDA'},
{ codigo: 'W931', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W932', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W933', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W934', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN CALLES Y CARRETERAS'},
{ codigo: 'W935', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W936', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W937', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN GRANJA'},
{ codigo: 'W938', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W939', descripcion: 'EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W94', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE'},
{ codigo: 'W940', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN VIVIENDA'},
{ codigo: 'W941', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W942', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W943', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W944', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN CALLES Y CARRETERAS'},
{ codigo: 'W945', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W946', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W947', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN GRANJA'},
{ codigo: 'W948', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W949', descripcion: 'EXPOSICION A PRESION AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'W99', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL'},
{ codigo: 'W990', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN VIVIENDA'},
{ codigo: 'W991', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'W992', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'W993', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'W994', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN CALLES Y CARRETERAS'},
{ codigo: 'W995', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'W996', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'W997', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN GRANJA'},
{ codigo: 'W998', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'W999', descripcion: 'EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS DE ORIGEN ARTIFICIAL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X00', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION'},
{ codigo: 'X000', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN VIVIENDA'},
{ codigo: 'X001', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X002', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X003', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X004', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN CALLES Y CARRETERAS'},
{ codigo: 'X005', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X006', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X007', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN GRANJA'},
{ codigo: 'X008', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X009', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X01', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION'},
{ codigo: 'X010', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN VIVIENDA'},
{ codigo: 'X011', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X012', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X013', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X014', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN CALLES Y CARRETERAS'},
{ codigo: 'X015', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X016', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X017', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN GRANJA'},
{ codigo: 'X018', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X019', descripcion: 'EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X02', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION'},
{ codigo: 'X020', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN VIVIENDA'},
{ codigo: 'X021', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X022', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X023', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X024', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN CALLES Y CARRETERAS'},
{ codigo: 'X025', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X026', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X027', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN GRANJA'},
{ codigo: 'X028', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X029', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X03', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION'},
{ codigo: 'X030', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN VIVIENDA'},
{ codigo: 'X031', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X032', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X033', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X034', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN CALLES Y CARRETERAS'},
{ codigo: 'X035', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X036', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X037', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN GRANJA'},
{ codigo: 'X038', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X039', descripcion: 'EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U CONSTRUCCION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X04', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMANTE'},
{ codigo: 'X040', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN VIVIENDA'},
{ codigo: 'X041', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X042', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X043', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X044', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN CALLES Y CARRETERAS'},
{ codigo: 'X045', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X046', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X047', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN GRANJA'},
{ codigo: 'X048', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X049', descripcion: 'EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X05', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR'},
{ codigo: 'X050', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN VIVIENDA'},
{ codigo: 'X051', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X052', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X053', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X054', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN CALLES Y CARRETERAS'},
{ codigo: 'X055', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X056', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X057', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN GRANJA'},
{ codigo: 'X058', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X059', descripcion: 'EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X06', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS'},
{ codigo: 'X060', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN VIVIENDA'},
{ codigo: 'X061', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X062', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X063', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X064', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X065', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X066', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X067', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN GRANJA'},
{ codigo: 'X068', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN OTR LUGAR ESPECIFICADO'},
{ codigo: 'X069', descripcion: 'EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X08', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS'},
{ codigo: 'X080', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X081', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X082', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X083', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X084', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X085', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X086', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X087', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X088', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X089', descripcion: 'EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS, EN LUGAR NO ESPECIFICADOS'},
{ codigo: 'X09', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS'},
{ codigo: 'X090', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X091', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X092', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X093', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X094', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X095', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X096', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X097', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X098', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X099', descripcion: 'EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X10', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES'},
{ codigo: 'X100', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN VIVIENDA'},
{ codigo: 'X101', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X102', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X103', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X104', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X105', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X106', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X107', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN GRANJA'},
{ codigo: 'X108', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X109', descripcion: 'CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X11', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE'},
{ codigo: 'X110', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN VIVIENDA'},
{ codigo: 'X111', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X112', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X113', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X114', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN CALLES Y CARRETERAS'},
{ codigo: 'X115', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X116', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X117', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN GRANJA'},
{ codigo: 'X118', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X119', descripcion: 'CONTACTO CON AGUA CALIENTE CORRIENTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X12', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES'},
{ codigo: 'X120', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN VIVIENDA'},
{ codigo: 'X121', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X122', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X123', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X124', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X125', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X126', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X127', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN GRANJA'},
{ codigo: 'X128', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X129', descripcion: 'CONTACTO CON OTROS LIQUIDOS CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X13', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES'},
{ codigo: 'X130', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN VIVIENDA'},
{ codigo: 'X131', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X132', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X133', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X134', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X135', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X136', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X137', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN GRANJA'},
{ codigo: 'X138', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X139', descripcion: 'CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X14', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES'},
{ codigo: 'X140', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN VIVIENDA'},
{ codigo: 'X141', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X142', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X143', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X144', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X145', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X146', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X147', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN GRANJA'},
{ codigo: 'X148', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X149', descripcion: 'CONTACTO CON AIRE Y GASES CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X15', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES'},
{ codigo: 'X150', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN VIVIENDA'},
{ codigo: 'X151', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X152', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X153', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X154', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X155', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X156', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X157', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN GRANJA'},
{ codigo: 'X158', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X159', descripcion: 'CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X16', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES'},
{ codigo: 'X160', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN VIVIENDA'},
{ codigo: 'X161', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X162', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X163', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X164', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN CALLES Y CARRETERA'},
{ codigo: 'X165', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X166', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X167', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN GRANJA'},
{ codigo: 'X168', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X169', descripcion: 'CONTACTO CON RADIADORES, CA—ERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X17', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES'},
{ codigo: 'X170', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN VIVIENDA'},
{ codigo: 'X171', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X172', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X173', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X174', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X175', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X176', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X177', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN GRANJA'},
{ codigo: 'X178', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X179', descripcion: 'CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X18', descripcion: 'CONTACTO CON OTROS METALES CALIENTES'},
{ codigo: 'X180', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN VIVIENDA'},
{ codigo: 'X181', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X182', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X183', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X184', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X185', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X186', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X187', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN GRANJA'},
{ codigo: 'X188', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X189', descripcion: 'CONTACTO CON OTROS METALES CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X19', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS'},
{ codigo: 'X190', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'X191', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X192', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X193', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X194', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X195', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X196', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X197', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'X198', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X199', descripcion: 'CONTACTO CON OTRAS SUSTANCIAS CALIENTES Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X20', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS'},
{ codigo: 'X200', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN VIVIENDA'},
{ codigo: 'X201', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X202', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X203', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X204', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X205', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X206', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X207', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN GRANJA'},
{ codigo: 'X208', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X209', descripcion: 'CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X21', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS'},
{ codigo: 'X210', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN VIVIENDA'},
{ codigo: 'X211', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X212', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X213', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X214', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X215', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X216', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X217', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN GRANJA'},
{ codigo: 'X218', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X219', descripcion: 'CONTACTO TRAUMATICO CON ARA—AS VENENOSAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X22', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION'},
{ codigo: 'X220', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN VIVIENDA'},
{ codigo: 'X221', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X222', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X223', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X224', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN CALLES Y CARRETERAS'},
{ codigo: 'X225', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X226', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X227', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN GRANJA'},
{ codigo: 'X228', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X229', descripcion: 'CONTACTO TRAUMATICO CON ESCORPION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X23', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS'},
{ codigo: 'X230', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN VIVIENDA'},
{ codigo: 'X231', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X232', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X233', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X234', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X235', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X236', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X237', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN GRANJA'},
{ codigo: 'X238', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X239', descripcion: 'CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X24', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES)'},
{ codigo: 'X240', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN VIVIENDA'},
{ codigo: 'X241', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X242', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X243', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X244', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN CALLES Y CARRETERAS'},
{ codigo: 'X245', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X246', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X247', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN GRANJA'},
{ codigo: 'X248', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X249', descripcion: 'CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES), EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X25', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS'},
{ codigo: 'X250', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X251', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X252', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X253', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X254', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X255', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X256', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X257', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X258', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X259', descripcion: 'CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X26', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS'},
{ codigo: 'X260', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN VIVIENDA'},
{ codigo: 'X261', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X262', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X263', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X264', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X265', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X266', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X267', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN GRANJA'},
{ codigo: 'X268', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X269', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X27', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS'},
{ codigo: 'X270', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X271', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X272', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X273', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X274', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X275', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X276', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X277', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X278', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X279', descripcion: 'CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X28', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS'},
{ codigo: 'X280', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'X281', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X282', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X283', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X284', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X285', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X286', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X287', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN GRANJA'},
{ codigo: 'X288', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X289', descripcion: 'CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X29', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS'},
{ codigo: 'X290', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X291', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X292', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X293', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X294', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X295', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X296', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X297', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X298', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X299', descripcion: 'CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X30', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO'},
{ codigo: 'X300', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN VIVIENDA'},
{ codigo: 'X301', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X302', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X303', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X304', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN CALLES Y CARRETERAS'},
{ codigo: 'X305', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X306', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X307', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN GRANJA'},
{ codigo: 'X308', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X309', descripcion: 'EXPOSICION AL CALOR NATURAL EXCESIVO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X31', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO'},
{ codigo: 'X310', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN VIVIENDA'},
{ codigo: 'X311', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X312', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X313', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X314', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN CALLES Y CARRETERAS'},
{ codigo: 'X315', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X316', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X317', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN GRANJA'},
{ codigo: 'X318', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X319', descripcion: 'EXPOSICION AL FRIO NATURAL EXCESIVO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X32', descripcion: 'EXPOSICION A RAYOS SOLARES'},
{ codigo: 'X320', descripcion: 'EXPOSICION A RAYOS SOLARES, EN VIVIENDA'},
{ codigo: 'X321', descripcion: 'EXPOSICION A RAYOS SOLARES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X322', descripcion: 'EXPOSICION A RAYOS SOLARES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X323', descripcion: 'EXPOSICION A RAYOS SOLARES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X324', descripcion: 'EXPOSICION A RAYOS SOLARES, EN CALLES Y CARRETERAS'},
{ codigo: 'X325', descripcion: 'EXPOSICION A RAYOS SOLARES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X326', descripcion: 'EXPOSICION A RAYOS SOLARES, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X327', descripcion: 'EXPOSICION A RAYOS SOLARES, EN GRANJA'},
{ codigo: 'X328', descripcion: 'EXPOSICION A RAYOS SOLARES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X329', descripcion: 'EXPOSICION A RAYOS SOLARES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X33', descripcion: 'VICTIMA DE RAYO'},
{ codigo: 'X330', descripcion: 'VICTIMA DE RAYO, EN VIVIENDA'},
{ codigo: 'X331', descripcion: 'VICTIMA DE RAYO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X332', descripcion: 'VICTIMA DE RAYO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X333', descripcion: 'VICTIMA DE RAYO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X334', descripcion: 'VICTIMA DE RAYO, EN CALLES Y CARRETERAS'},
{ codigo: 'X335', descripcion: 'VICTIMA DE RAYO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X336', descripcion: 'VICTIMA DE RAYO, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X337', descripcion: 'VICTIMA DE RAYO, EN GRANJA'},
{ codigo: 'X338', descripcion: 'VICTIMA DE RAYO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X339', descripcion: 'VICTIMA DE RAYO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X34', descripcion: 'VICTIMA DE TERREMOTO'},
{ codigo: 'X340', descripcion: 'VICTIMA DE TERREMOTO, EN VIVIENDA'},
{ codigo: 'X341', descripcion: 'VICTIMA DE TERREMOTO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X342', descripcion: 'VICTIMA DE TERREMOTO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X343', descripcion: 'VICTIMA DE TERREMOTO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X344', descripcion: 'VICTIMA DE TERREMOTO, EN CALLES Y CARRETERAS'},
{ codigo: 'X345', descripcion: 'VICTIMA DE TERREMOTO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X346', descripcion: 'VICTIMA DE TERREMOTO, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X347', descripcion: 'VICTIMA DE TERREMOTO, EN GRANJA'},
{ codigo: 'X348', descripcion: 'VICTIMA DE TERREMOTO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X349', descripcion: 'VICTIMA DE TERREMOTO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X35', descripcion: 'VICTIMA DE ERUPCION VOLCANICA'},
{ codigo: 'X350', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN VIVIENDA'},
{ codigo: 'X351', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X352', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X353', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X354', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN CALLES Y CARRETERAS'},
{ codigo: 'X355', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X356', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X357', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN GRANJA'},
{ codigo: 'X358', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X359', descripcion: 'VICTIMA DE ERUPCION VOLCANICA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X36', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA'},
{ codigo: 'X360', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN VIVIENDA'},
{ codigo: 'X361', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X362', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X363', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X364', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN CALLES Y CARRETERAS'},
{ codigo: 'X365', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X366', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X367', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN GRANJA'},
{ codigo: 'X368', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X369', descripcion: 'VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X37', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA'},
{ codigo: 'X370', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN VIVIENDA'},
{ codigo: 'X371', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X372', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X373', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X374', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN CALLES Y CARRETERAS'},
{ codigo: 'X375', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X376', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X377', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN GRANJA'},
{ codigo: 'X378', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X379', descripcion: 'VICTIMA DE TORMENTA CATACLISMICA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X38', descripcion: 'VICTIMA DE INUNDACION'},
{ codigo: 'X380', descripcion: 'VICTIMA DE INUNDACION, EN VIVIENDA'},
{ codigo: 'X381', descripcion: 'VICTIMA DE INUNDACION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X2', descripcion: 'VICTIMA DE INUNDACION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X383', descripcion: 'VICTIMA DE INUNDACION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X384', descripcion: 'VICTIMA DE INUNDACION, EN CALLES Y CARRETERAS'},
{ codigo: 'X385', descripcion: 'VICTIMA DE INUNDACION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X386', descripcion: 'VICTIMA DE INUNDACION, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X387', descripcion: 'VICTIMA DE INUNDACION, EN GRANJA'},
{ codigo: 'X388', descripcion: 'VICTIMA DE INUNDACION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X389', descripcion: 'VICTIMA DE INUNDACION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X39', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS'},
{ codigo: 'X390', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'X391', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X392', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTTRACION PUBLICA'},
{ codigo: 'X393', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X394', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X395', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X396', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X397', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'X398', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X399', descripcion: 'EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X40', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS'},
{ codigo: 'X400', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN VIVIENDA'},
{ codigo: 'X401', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X402', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X403', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X404', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X405', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X406', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X407', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN GRANJA'},
{ codigo: 'X408', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X409', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X41', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'X410', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN VIVIENDA'},
{ codigo: 'X411', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X412', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS'},
{ codigo: 'X413', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X414', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN CALLES Y CARRETERAS'},
{ codigo: 'X415', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X416', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X417', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN GRANJA'},
{ codigo: 'X418', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X419', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X42', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'X420', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN VIVIENDA'},
{ codigo: 'X421', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X422', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X423', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X424', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN CALLES Y CARRETERAS'},
{ codigo: 'X425', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X426', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X427', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN GRANJA'},
{ codigo: 'X428', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X429', descripcion: 'ENVENENAMIENTO ACCIDENTEL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS) NO CLASIFICADOS EN OTRA PARTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X43', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'X430', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN VIVIENDA'},
{ codigo: 'X431', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X432', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X433', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X434', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN CALLES Y CARRETERAS'},
{ codigo: 'X435', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X436', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X437', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN GRANJA'},
{ codigo: 'X438', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X439', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X44', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS'},
{ codigo: 'X440', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X441', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X442', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X443', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X444', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X445', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X446', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X447', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X448', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X449', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X45', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL'},
{ codigo: 'X450', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN VIVIENDA'},
{ codigo: 'X451', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X452', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X453', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X454', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN CALLES Y CARRETERAS'},
{ codigo: 'X455', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X456', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X457', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN GRANJA'},
{ codigo: 'X458', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X459', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X46', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES'},
{ codigo: 'X460', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN VIVIENDA'},
{ codigo: 'X461', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X462', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X463', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X464', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN CALLES Y CARRETERAS'},
{ codigo: 'X465', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X466', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X467', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN GRANJA'},
{ codigo: 'X468', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X469', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X47', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES'},
{ codigo: 'X470', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN VIVIENDA'},
{ codigo: 'X471', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X472', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X473', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X474', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN CALLES Y CARRETERAS'},
{ codigo: 'X475', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X476', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X477', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN GRANJA'},
{ codigo: 'X478', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X479', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X48', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS'},
{ codigo: 'X480', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN VIVIENDA'},
{ codigo: 'X481', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X482', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X483', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X484', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X485', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X486', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X487', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN GRANJA'},
{ codigo: 'X488', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X489', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X49', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS'},
{ codigo: 'X490', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X491', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X492', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X493', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X494', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X495', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X496', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X497', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X498', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X499', descripcion: 'ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X50', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS'},
{ codigo: 'X500', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN VIVIENDA'},
{ codigo: 'X501', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X502', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X503', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X504', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X505', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X506', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X507', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN GRANJA'},
{ codigo: 'X508', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X509', descripcion: 'EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X51', descripcion: 'VIAJES Y DESPLAZAMIENTOS'},
{ codigo: 'X510', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN VIVIENDA'},
{ codigo: 'X511', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X512', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X513', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X514', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X515', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X516', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X517', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN GRANJA'},
{ codigo: 'X518', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X519', descripcion: 'VIAJES Y DESPLAZAMIENTOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X52', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD'},
{ codigo: 'X520', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN VIVIENDA'},
{ codigo: 'X521', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X522', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X523', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X524', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN CALLES Y CARRETERAS'},
{ codigo: 'X525', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X526', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X527', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN GRANJA'},
{ codigo: 'X528', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X529', descripcion: 'PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X53', descripcion: 'PRIVACION DE ALIMENTOS'},
{ codigo: 'X530', descripcion: 'PRIVACION DE ALIMENTOS, EN VIVIENDA'},
{ codigo: 'X531', descripcion: 'PRIVACION DE ALIMENTOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X532', descripcion: 'PRIVACION DE ALIMENTOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X533', descripcion: 'PRIVACION DE ALIMENTOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X534', descripcion: 'PRIVACION DE ALIMENTOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X535', descripcion: 'PRIVACION DE ALIMENTOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X536', descripcion: 'PRIVACION DE ALIMENTOS, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X537', descripcion: 'PRIVACION DE ALIMENTOS, EN GRANJA'},
{ codigo: 'X538', descripcion: 'PRIVACION DE ALIMENTOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X539', descripcion: 'PRIVACION DE ALIMENTOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X54', descripcion: 'PRIVACION DE AGUA'},
{ codigo: 'X540', descripcion: 'PRIVACION DE AGUA, EN VIVIENDA'},
{ codigo: 'X541', descripcion: 'PRIVACION DE AGUA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X542', descripcion: 'PRIVACION DE AGUA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X543', descripcion: 'PRIVACION DE AGUA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X544', descripcion: 'PRIVACION DE AGUA, EN CALLES Y CARRETERAS'},
{ codigo: 'X545', descripcion: 'PRIVACION DE AGUA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X546', descripcion: 'PRIVACION DE AGUA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X547', descripcion: 'PRIVACION DE AGUA, EN GRANJA'},
{ codigo: 'X548', descripcion: 'PRIVACION DE AGUA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X549', descripcion: 'PRIVACION DE AGUA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X57', descripcion: 'PRIVACION NO ESPECIFICADA'},
{ codigo: 'X570', descripcion: 'PRIVACION NO ESPECIFICADA, EN VIVIENDA'},
{ codigo: 'X571', descripcion: 'PRIVACION NO ESPECIFICADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X572', descripcion: 'PRIVACION NO ESPECIFICADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X573', descripcion: 'PRIVACION NO ESPECIFICADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X574', descripcion: 'PRIVACION NO ESPECIFICADA, EN CALLES Y CARRETERAS'},
{ codigo: 'X575', descripcion: 'PRIVACION NO ESPECIFICADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X576', descripcion: 'PRIVACION NO ESPECIFICADA, EN AREA INDUSTRIAL Y DE LA CONSTRUCCION'},
{ codigo: 'X577', descripcion: 'PRIVACION NO ESPECIFICADA, EN GRANJA'},
{ codigo: 'X578', descripcion: 'PRIVACION NO ESPECIFICADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X579', descripcion: 'PRIVACION NO ESPECIFICADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X58', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS'},
{ codigo: 'X580', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X581', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X582', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X583', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X584', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X585', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X586', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X587', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X588', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X589', descripcion: 'EXPOSICION A OTROS FACTORES ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X59', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS'},
{ codigo: 'X590', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X591', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X592', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X593', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X594', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X595', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X596', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X597', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X598', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X599', descripcion: 'EXPOSICION A FACTORES NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X60', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS'},
{ codigo: 'X600', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN VIVIENDA'},
{ codigo: 'X601', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X602', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X603', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X604', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X605', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X606', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X607', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN GRANJA'},
{ codigo: 'X608', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X609', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X61', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HINOPTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'X610', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HINOPTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN VIVIENDA'},
{ codigo: 'X611', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR,Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HINOPTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS OTRA PARTE,EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X612', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR,Y EXPOSICION DROGAS ANTIEPILEPTICAS,SEDANTES,HINOPTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP,EN ESCUELA Y OTRAS INSTITUCIONES Y AREAS ADMON PUBLIC'},
{ codigo: 'X613', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION DROGAS ANTIEPILEPTICAS,SEDANTES,HINOPTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X614', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HINOPTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE,EN CALLES Y CARRETERAS'},
{ codigo: 'X615', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR,Y EXPOSICION DROGAS ANTIEPILEPTICAS,SEDANTES,HINOPTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE,EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X616', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HINOPTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X617', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HINOPTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE, EN GRANJA'},
{ codigo: 'X618', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HINOPTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP,EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X619', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR,Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HINOPTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE,EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X62', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'X620', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN VIVIENDA'},
{ codigo: 'X621', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X622', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS ADMON PUBLICA'},
{ codigo: 'X623', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X624', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN CALLES Y CARRETERAS'},
{ codigo: 'X625', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X626', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X627', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN GRANJA'},
{ codigo: 'X628', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X629', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS (ALUCINOGENOS), NO CLASIFICADOS EN OTRA PARTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X63', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'X630', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN VIVIENDA'},
{ codigo: 'X631', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X632', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X633', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X634', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN CALLES Y CARRETERAS'},
{ codigo: 'X635', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X636', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X637', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN GRANJA'},
{ codigo: 'X638', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X639', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X64', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS'},
{ codigo: 'X640', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X641', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X642', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMON PUBLICA'},
{ codigo: 'X643', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X644', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X645', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X646', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X647', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X648', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X649', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LOS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X65', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL'},
{ codigo: 'X650', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN VIVIENDA'},
{ codigo: 'X651', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X652', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X653', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X654', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN CALLES Y CARRETERAS'},
{ codigo: 'X655', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X656', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X657', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN GRANJA'},
{ codigo: 'X658', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X659', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X66', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES'},
{ codigo: 'X660', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN VIVIENDA'},
{ codigo: 'X661', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X662', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X663', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X664', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN CALLES Y CARRETERAS'},
{ codigo: 'X665', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X666', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X667', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN GRANJA'},
{ codigo: 'X668', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X669', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X67', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES'},
{ codigo: 'X670', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN VIVIENDA'},
{ codigo: 'X671', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X672', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X673', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X674', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN CALLES Y CARRETERAS'},
{ codigo: 'X675', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X676', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X677', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN GRANJA'},
{ codigo: 'X678', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X679', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X68', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS'},
{ codigo: 'X680', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN VIVIENDA'},
{ codigo: 'X681', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X682', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X683', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X684', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X685', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X686', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X687', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN GRANJA'},
{ codigo: 'X688', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X689', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X69', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS'},
{ codigo: 'X690', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X691', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X692', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMON PUBLICA'},
{ codigo: 'X693', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X694', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X695', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X696', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X697', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X698', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X699', descripcion: 'ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X70', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION'},
{ codigo: 'X700', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN VIVIENDA'},
{ codigo: 'X701', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X702', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X703', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X704', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN CALLES Y CARRETERAS'},
{ codigo: 'X705', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X706', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN AREAS INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X707', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN GRANJA'},
{ codigo: 'X708', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X709', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION, EN LUGAR ESPECIFICADO'},
{ codigo: 'X71', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION'},
{ codigo: 'X710', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN VIVIENDA'},
{ codigo: 'X711', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X712', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X713', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X714', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN CALLES Y CARRETERAS'},
{ codigo: 'X715', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X716', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X717', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN GRANJA'},
{ codigo: 'X718', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X719', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X72', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA'},
{ codigo: 'X720', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN VIVIENDA'},
{ codigo: 'X721', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X722', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X723', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X724', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN CALLES Y CARRETERAS'},
{ codigo: 'X725', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X726', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN AREAS INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X727', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN GRANJA'},
{ codigo: 'X728', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X729', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X73', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA'},
{ codigo: 'X730', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN VIVIENDA'},
{ codigo: 'X731', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X732', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X733', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X734', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN CALLES Y CARRETERAS'},
{ codigo: 'X735', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X736', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X737', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN GRANJA'},
{ codigo: 'X738', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X739', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X74', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS'},
{ codigo: 'X740', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'X741', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDDENCIAL'},
{ codigo: 'X742', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X743', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X744', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X745', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X746', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X747', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'X748', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X749', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X75', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO'},
{ codigo: 'X750', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN VIVIENDA'},
{ codigo: 'X751', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X752', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X753', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X754', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN CALLES Y CARRETERAS'},
{ codigo: 'X755', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X756', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X757', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN GRANJA'},
{ codigo: 'X758', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X759', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X76', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR HUMO, FUEGO Y LLAMAS'},
{ codigo: 'X760', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN VIVIENDA'},
{ codigo: 'X761', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X762', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X763', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X764', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X765', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X766', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X767', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN GRANJA'},
{ codigo: 'X768', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X769', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X77', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES'},
{ codigo: 'X770', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN VIVIENDA'},
{ codigo: 'X771', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X772', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X773', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X774', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X775', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X776', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X777', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN GRANJA'},
{ codigo: 'X778', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X779', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN LUGAR ESPECIFICADO'},
{ codigo: 'X78', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE'},
{ codigo: 'X780', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN VIVIENDA'},
{ codigo: 'X781', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X782', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X783', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X784', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN CALLES Y CARRETERAS'},
{ codigo: 'X785', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X786', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X787', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN GRANJA'},
{ codigo: 'X788', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X789', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X79', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO'},
{ codigo: 'X790', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN VIVIENDA'},
{ codigo: 'X791', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X792', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X793', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X794', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN CALLES Y CARRETERAS'},
{ codigo: 'X795', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X796', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X797', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN GRANJA'},
{ codigo: 'X798', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X799', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X80', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMETE AL SALTAR DESDE UN LUGAR ELEVADO'},
{ codigo: 'X800', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN VIV'},
{ codigo: 'X801', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X802', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X803', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X804', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN CALLES Y CARRETERAS'},
{ codigo: 'X805', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X806', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X807', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN GRANJA'},
{ codigo: 'X808', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X809', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X81', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO'},
{ codigo: 'X810', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN VIVIENDA'},
{ codigo: 'X811', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X812', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X813', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X814', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN CALLES Y CARRETERAS'},
{ codigo: 'X815', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X816', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN AREA INDUSTRIA Y CONSTRUCCION'},
{ codigo: 'X817', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN GRANJA'},
{ codigo: 'X818', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X819', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X82', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR'},
{ codigo: 'X820', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN VIVIENDA'},
{ codigo: 'X821', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X822', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X823', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X824', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN CALLES Y CARRETERAS'},
{ codigo: 'X825', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X826', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X827', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN GRANJA'},
{ codigo: 'X828', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X829', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO MOTOR, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X83', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS'},
{ codigo: 'X830', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X831', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X832', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X833', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X834', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X835', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X836', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X837', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X838', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X839', descripcion: 'LESION AUTOINFLIGIDA INTECIONALMENTE POR OTROS MEDIOS ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X84', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS'},
{ codigo: 'X840', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'X841', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X842', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X843', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X844', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'X845', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X846', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X847', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'X848', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X849', descripcion: 'LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X85', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'X850', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN VIVIENDA'},
{ codigo: 'X851', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X852', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X853', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X854', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X855', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X856', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X857', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN GRANJA'},
{ codigo: 'X858', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X859', descripcion: 'AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X86', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA,'},
{ codigo: 'X860', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN VIVIENDA'},
{ codigo: 'X861', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X862', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X863', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X864', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN CALLES Y CARRETERAS'},
{ codigo: 'X865', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X866', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X867', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN GRANJA'},
{ codigo: 'X868', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X869', descripcion: 'AGRESION CON SUSTANCIA CORROSIVA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X87', descripcion: 'AGRESION CON PLAGUICIDAS'},
{ codigo: 'X870', descripcion: 'AGRESION CON PLAGUICIDAS, EN VIVIENDA'},
{ codigo: 'X871', descripcion: 'AGRESION CON PLAGUICIDAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X872', descripcion: 'AGRESION CON PLAGUICIDAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X873', descripcion: 'AGRESION CON PLAGUICIDAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X874', descripcion: 'AGRESION CON PLAGUICIDAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X875', descripcion: 'AGRESION CON PLAGUICIDAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X876', descripcion: 'AGRESION CON PLAGUICIDAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X877', descripcion: 'AGRESION CON PLAGUICIDAS, EN GRANJA'},
{ codigo: 'X878', descripcion: 'AGRESION CON PLAGUICIDAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X879', descripcion: 'AGRESION CON PLAGUICIDAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X88', descripcion: 'AGRESION CON GASES Y VAPORES'},
{ codigo: 'X880', descripcion: 'AGRESION CON GASES Y VAPORES, EN VIVIENDA'},
{ codigo: 'X881', descripcion: 'AGRESION CON GASES Y VAPORES, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X882', descripcion: 'AGRESION CON GASES Y VAPORES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X883', descripcion: 'AGRESION CON GASES Y VAPORES, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X884', descripcion: 'AGRESION CON GASES Y VAPORES, EN CALLES Y CARRETERAS'},
{ codigo: 'X885', descripcion: 'AGRESION CON GASES Y VAPORES, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X886', descripcion: 'AGRESION CON GASES Y VAPORES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X887', descripcion: 'AGRESION CON GASES Y VAPORES, EN GRANJA'},
{ codigo: 'X888', descripcion: 'AGRESION CON GASES Y VAPORES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X889', descripcion: 'AGRESION CON GASES Y VAPORES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X89', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS'},
{ codigo: 'X890', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'X891', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X892', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X893', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X894', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X895', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X896', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN AREA IDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X897', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN GRANJA'},
{ codigo: 'X898', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X899', descripcion: 'AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X90', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS'},
{ codigo: 'X900', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'X901', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X902', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X903', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X904', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X905', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X906', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN AREAS INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X907', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'X908', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X909', descripcion: 'AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X91', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION'},
{ codigo: 'X910', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN VIVIENDA'},
{ codigo: 'X911', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X912', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X913', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X914', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN CALLES Y CARRETERAS'},
{ codigo: 'X915', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X916', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X917', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN GRANJA'},
{ codigo: 'X918', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X919', descripcion: 'AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X92', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION'},
{ codigo: 'X920', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN VIVIENDA'},
{ codigo: 'X921', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X922', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X923', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X924', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN CALLES Y CARRETERAS'},
{ codigo: 'X925', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X926', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X927', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN GRANJA'},
{ codigo: 'X928', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X929', descripcion: 'AGRESION POR AHOGAMIENTO Y SUMERSION, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X93', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA'},
{ codigo: 'X930', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN VIVIENDA'},
{ codigo: 'X931', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X932', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X933', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X934', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN CALLES Y CARRETERAS'},
{ codigo: 'X935', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X936', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X937', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN GRANJA'},
{ codigo: 'X938', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X939', descripcion: 'AGRESION CON DISPARO DE ARMA CORTA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X94', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA'},
{ codigo: 'X940', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN VIVIENDA'},
{ codigo: 'X941', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X942', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X943', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X944', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN CALLES Y CARRETERAS'},
{ codigo: 'X945', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X946', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X947', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN GRANJA'},
{ codigo: 'X948', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X949', descripcion: 'AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X95', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS'},
{ codigo: 'X950', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN VIVIENDA'},
{ codigo: 'X951', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X952', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X953', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X954', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X955', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X956', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X957', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN GRANJA'},
{ codigo: 'X958', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X959', descripcion: 'AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X96', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO'},
{ codigo: 'X960', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN VIVIENDA'},
{ codigo: 'X961', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X962', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X963', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X964', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN CALLES Y CARRETERAS'},
{ codigo: 'X965', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X966', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X967', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN GRANJA'},
{ codigo: 'X968', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X969', descripcion: 'AGRESION CON MATERIAL EXPLOSIVO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X97', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS'},
{ codigo: 'X970', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN VIVIENDA'},
{ codigo: 'X971', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X972', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X973', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X974', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN CALLES Y CARRETERAS'},
{ codigo: 'X975', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X976', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X977', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN GRANJA'},
{ codigo: 'X978', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X979', descripcion: 'AGRESION CON HUMO, FUEGO Y LLAMAS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X98', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES'},
{ codigo: 'X980', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN VIVIENDA'},
{ codigo: 'X981', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN INSTUCION RESIDENCIAL'},
{ codigo: 'X982', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X983', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'X984', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN CALLES Y CARRETERAS'},
{ codigo: 'X985', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN COMERCIO Y AREA SERVICIO'},
{ codigo: 'X986', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X987', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN GRANJA'},
{ codigo: 'X988', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X989', descripcion: 'AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'X99', descripcion: 'AGRESION CON OBJETO CORTANTE'},
{ codigo: 'X990', descripcion: 'AGRESION CON OBJETO CORTANTE, EN VIVIENDA'},
{ codigo: 'X991', descripcion: 'AGRESION CON OBJETO CORTANTE, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'X992', descripcion: 'AGRESION CON OBJETO CORTANTE, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'X993', descripcion: 'AGRESION CON OBJETO CORTANTE, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'X994', descripcion: 'AGRESION CON OBJETO CORTANTE, EN CALLES Y CARRETERAS'},
{ codigo: 'X995', descripcion: 'AGRESION CON OBJETO CORTANTE, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'X996', descripcion: 'AGRESION CON OBJETO CORTANTE, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'X997', descripcion: 'AGRESION CON OBJETO CORTANTE, EN GRANJA'},
{ codigo: 'X998', descripcion: 'AGRESION CON OBJETO CORTANTE, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'X999', descripcion: 'AGRESION CON OBJETO CORTANTE, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y00', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO'},
{ codigo: 'Y000', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN VIVIENDA'},
{ codigo: 'Y001', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y002', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y003', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y004', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN CALLES Y CARRETERAS'},
{ codigo: 'Y005', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y006', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y007', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN GRANJA'},
{ codigo: 'Y008', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y009', descripcion: 'AGRESION CON OBJETO ROMO O SIN FILO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y01', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO'},
{ codigo: 'Y010', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN VIVIENDA'},
{ codigo: 'Y011', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y012', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y013', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y014', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN CALLES Y CARRETERAS'},
{ codigo: 'Y015', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN COMERCIO Y AREA SERVICIO'},
{ codigo: 'Y016', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y017', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN GRANJA'},
{ codigo: 'Y018', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN OTRO LUGAR ESPECIFICO'},
{ codigo: 'Y019', descripcion: 'AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y02', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO'},
{ codigo: 'Y020', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN VIVIENDA'},
{ codigo: 'Y021', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y022', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y023', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y024', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN CALLES Y CARRETERAS'},
{ codigo: 'Y025', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y026', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y027', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN GRANJA'},
{ codigo: 'Y028', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y029', descripcion: 'AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y03', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR'},
{ codigo: 'Y030', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN VIVIENDA'},
{ codigo: 'Y031', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y032', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y033', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y034', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN CALLES Y CARRETERAS'},
{ codigo: 'Y035', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y036', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y037', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN GRANJA'},
{ codigo: 'Y038', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y039', descripcion: 'AGRESION POR COLISION DE VEHICULO DE MOTOR, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y04', descripcion: 'AGRESION CON FUERZA CORPORAL'},
{ codigo: 'Y040', descripcion: 'AGRESION CON FUERZA CORPORAL, EN VIVIENDA'},
{ codigo: 'Y041', descripcion: 'AGRESION CON FUERZA CORPORAL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y042', descripcion: 'AGRESION CON FUERZA CORPORAL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y043', descripcion: 'AGRESION CON FUERZA CORPORAL, EN AREAS DE DEPORTE Y ATLETISMO'},
{ codigo: 'Y044', descripcion: 'AGRESION CON FUERZA CORPORAL, EN CALLES Y CARRETERAS'},
{ codigo: 'Y045', descripcion: 'AGRESION CON FUERZA CORPORAL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y046', descripcion: 'AGRESION CON FUERZA CORPORAL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y047', descripcion: 'AGRESION CON FUERZA CORPORAL, EN GRANJA'},
{ codigo: 'Y048', descripcion: 'AGRESION CON FUERZA CORPORAL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y049', descripcion: 'AGRESION CON FUERZA CORPORAL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y05', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL'},
{ codigo: 'Y050', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN VIVIENDA'},
{ codigo: 'Y051', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y052', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y053', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y054', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN CALLES Y CARRETERAS'},
{ codigo: 'Y055', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y056', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y057', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN GRANJA'},
{ codigo: 'Y058', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y059', descripcion: 'AGRESION SEXUAL CON FUERZA CORPORAL, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y06', descripcion: 'NEGLIGENCIA Y ABANDONO'},
{ codigo: 'Y060', descripcion: 'NEGLIGENCIA Y ABANDONO POR ESPOSO O PAREJA'},
{ codigo: 'Y061', descripcion: 'NEGLIGENCIA Y ABANDONO POR PADRE O MADRE'},
{ codigo: 'Y062', descripcion: 'NEGLIGENCIA Y ABANDONO POR CONOCIDO O AMIGO'},
{ codigo: 'Y068', descripcion: 'NEGLIGENCIA Y ABANDONO POR OTRA PERSONA ESPECIFICADA'},
{ codigo: 'Y069', descripcion: 'NEGLIGENCIA Y ABANDONO POR PERSONA NO ESPECIFICADA'},
{ codigo: 'Y07', descripcion: 'OTROS SINDROMES DE MALTRATO'},
{ codigo: 'Y070', descripcion: 'SINDROMES DE MALTRATO POR ESPOSO O PAREJA'},
{ codigo: 'Y071', descripcion: 'SINDROMES DE MALTRATO POR PADRE O MADRE'},
{ codigo: 'Y072', descripcion: 'SINDROMES DE MALTRATO POR CONOCIDO O AMIGO'},
{ codigo: 'Y073', descripcion: 'SINDROMES DE MALTRATO POR AUTORIDADES OFICIALES'},
{ codigo: 'Y078', descripcion: 'SINDROMES DE MALTRATO POR OTRA PERSONA ESPECIFICADA'},
{ codigo: 'Y079', descripcion: 'SINDROMES DE MALTRATO POR PERSONA NO ESPECIFICADA'},
{ codigo: 'Y08', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS'},
{ codigo: 'Y080', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'Y081', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y082', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y083', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y084', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'Y085', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y086', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y087', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN GRANJA'},
{ codigo: 'Y088', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y089', descripcion: 'AGRESION POR OTROS MEDIOS ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y09', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS'},
{ codigo: 'Y090', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN VIVIENDA'},
{ codigo: 'Y091', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y092', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y093', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y094', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN CALLES Y CARRETERAS'},
{ codigo: 'Y095', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y096', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y097', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN GRANJA'},
{ codigo: 'Y098', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y099', descripcion: 'AGRESION POR MEDIOS NO ESPECIFICADOS, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y10', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA'},
{ codigo: 'Y100', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y101', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y102', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y103', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y104', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y105', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y106', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y107', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y108', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y109', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y11', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA'},
{ codigo: 'Y110', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y111', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y112', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP,SIN INTENCION DETERMINADA,EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS ADMON PUBLICA'},
{ codigo: 'Y113', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y114', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y115', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y116', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN AREAS INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y117', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN GRANJA'},
{ codigo: 'Y118', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y119', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS,SEDANTES,HIPNOTICAS,ANTIPARKINSONIANAS Y PSICOTROPICAS NCOP Y SIN INTENCION DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y12', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y120', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y121', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y122', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y123', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y124', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y125', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y126', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y127', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y128', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN OTRO LUGAR NO ESPECIFICADO'},
{ codigo: 'Y129', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A NARCOTICOS PSICODISLEPTICOS (ALUCINOGENOS) NCOP, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y13', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y130', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y131', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y132', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y133', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y134', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y135', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y136', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y137', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y138', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y139', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y14', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y140', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y141', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y142', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION P'},
{ codigo: 'Y143', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'Y144', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y145', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y146', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y147', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y148', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y149', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y15', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y150', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y151', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y152', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y153', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN AREA DEPORTE Y ATLETISMO'},
{ codigo: 'Y154', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y155', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y156', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y157', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y158', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y159', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y16', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA'},
{ codigo: 'Y160', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN VIVIENDA'},
{ codigo: 'Y161', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y162', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y163', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y164', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y165', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y166', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y167', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN GRANJA'},
{ codigo: 'Y168', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y169', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETEERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y17', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y170', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y171', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y172', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y173', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y174', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y175', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y176', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y177', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y178', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y179', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y18', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y180', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y181', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y182', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN ESCUEALAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y183', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y184', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y185', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y186', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y187', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y188', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y189', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y19', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y190', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y191', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y192', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA,EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y193', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y194', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y195', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y196', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y197', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y198', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y199', descripcion: 'ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y20', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y200', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y201', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y202', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y203', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y204', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y205', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y206', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y207', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y208', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y209', descripcion: 'AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y21', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y210', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y211', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y212', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y213', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y214', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y215', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y216', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y217', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y218', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y219', descripcion: 'AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y22', descripcion: 'DISPARO DE ARMA CORTA, DE INTECION NO DETERMINADA'},
{ codigo: 'Y220', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y221', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y222', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y223', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y224', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y225', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y226', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y227', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y228', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y229', descripcion: 'DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y23', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y230', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y231', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y232', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y233', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y234', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y235', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y236', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y237', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y238', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y239', descripcion: 'DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y24', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y240', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y241', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y242', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y243', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y244', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y245', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y246', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y247', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y248', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y249', descripcion: 'DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y25', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA'},
{ codigo: 'Y250', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y251', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y252', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y253', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y254', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y255', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y256', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y257', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y258', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y259', descripcion: 'CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTECION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y26', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y260', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y261', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y262', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y263', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y264', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y265', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y266', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y267', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y268', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y269', descripcion: 'EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y27', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y270', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y271', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y272', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y273', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y274', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y275', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y276', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y277', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y278', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y279', descripcion: 'CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y28', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y280', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y281', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y282', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y283', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y284', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y285', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y286', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y287', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y288', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y289', descripcion: 'CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y29', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y290', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y291', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y292', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y293', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y294', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y295', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y296', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y297', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y298', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y299', descripcion: 'CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y30', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y300', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y301', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y302', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREA DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y303', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y304', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y305', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y306', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y307', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y308', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y309', descripcion: 'CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y31', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y310', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADACAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN VIVIE'},
{ codigo: 'Y311', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y312', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y313', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y314', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y315', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y316', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y317', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y318', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y319', descripcion: 'CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y32', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y320', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y321', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y322', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS DE ADMINISTRACION PUBLICA'},
{ codigo: 'Y323', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y324', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y325', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y326', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y327', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y328', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y329', descripcion: 'COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y33', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y330', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y331', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y332', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTUTCIONES Y AREAS ADMINISTRACION PUBLICA'},
{ codigo: 'Y333', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y334', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y335', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y336', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y337', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y338', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y339', descripcion: 'OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y34', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA'},
{ codigo: 'Y340', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN VIVIENDA'},
{ codigo: 'Y341', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN INSTITUCION RESIDENCIAL'},
{ codigo: 'Y342', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN ESCUELAS Y OTRAS INSTITUCIONES Y AREAS ADMINISTRACION PUBLICA'},
{ codigo: 'Y343', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN AREAS DEPORTE Y ATLETISMO'},
{ codigo: 'Y344', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN CALLES Y CARRETERAS'},
{ codigo: 'Y345', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN COMERCIO Y AREA SERVICIOS'},
{ codigo: 'Y346', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN AREA INDUSTRIAL Y CONSTRUCCION'},
{ codigo: 'Y347', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN GRANJA'},
{ codigo: 'Y348', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN OTRO LUGAR ESPECIFICADO'},
{ codigo: 'Y349', descripcion: 'EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA, EN LUGAR NO ESPECIFICADO'},
{ codigo: 'Y35', descripcion: 'INTERVENCION LEGAL'},
{ codigo: 'Y350', descripcion: 'INTERVENCION LEGAL CON DISPARO DE ARMA DE FUEGO'},
{ codigo: 'Y351', descripcion: 'INTERVENCION LEGAL CON EXPLOSIVOS'},
{ codigo: 'Y352', descripcion: 'INTERVENCION LEGAL CON GAS'},
{ codigo: 'Y353', descripcion: 'INTERVENCION LEGAL CON OBJETOS ROMOS O SIN FILO'},
{ codigo: 'Y354', descripcion: 'INTERVENCION LEGAL CON OBJETOS CORTANTES'},
{ codigo: 'Y355', descripcion: 'EJECUCION LEGAL'},
{ codigo: 'Y356', descripcion: 'INTERVENCION LEGAL CON OTROS MEDIOS ESPECIFICADOS'},
{ codigo: 'Y357', descripcion: 'INTERVENCION LEGAL, MEDIOS NO ESPECIFICADOS'},
{ codigo: 'Y36', descripcion: 'OPERACIONES DE GUERRA'},
{ codigo: 'Y360', descripcion: 'OPERACIONES DE GUERRA CON EXPLOSION DE ARMAMENTO NAVAL'},
{ codigo: 'Y361', descripcion: 'OPERACIONES DE GUERRA CON DESTRUCCION DE AERONAVE'},
{ codigo: 'Y362', descripcion: 'OPERACIONES DE GUERRA CON OTRAS EXPLOSIONES Y ESQUIRLAS'},
{ codigo: 'Y363', descripcion: 'OPERACIONES DE GUERRA CON FUEGO Y SUSTANCIAS INCENDIARIAS Y CALIENTES'},
{ codigo: 'Y364', descripcion: 'OPERACIONES DE GUERRA CON DISPARO DE ARMA FUEGO Y OTRAS FORMAS DE GUERRA CONVENCIONAL'},
{ codigo: 'Y365', descripcion: 'OPERACIONES DE GUERRA CON ARMAS NUCLEARES'},
{ codigo: 'Y366', descripcion: 'OPERACIONES DE GUERRA CON ARMAS BIOLOGICAS'},
{ codigo: 'Y367', descripcion: 'OPERACIONES DE GUERRA CON ARMAS QUIMICAS Y OTRAS FORMAS DE GUERRA NO CONVENCIONAL'},
{ codigo: 'Y368', descripcion: 'OPERACIONES DE GUERRA QUE OCURREN DESPUES DEL CESE DE HOSTILIDADES'},
{ codigo: 'Y369', descripcion: 'OPERACION DE GUERRA NO ESPECIFICADA'},
{ codigo: 'Y40', descripcion: 'EFECTOS ADVERSOS DE ANTIBIOTICOS SISTEMICOS'},
{ codigo: 'Y400', descripcion: 'EFECTOS ADVERSOS DE PENICILINAS'},
{ codigo: 'Y401', descripcion: 'EFECTOS ADVERSOS DE CEFALOSPORINAS Y OTR ANTIBIOTICOS BETA-LACTAMICOS'},
{ codigo: 'Y402', descripcion: 'EFECTOS ADVERSOS DEL GRUPO DE CLORAMFENICOL'},
{ codigo: 'Y403', descripcion: 'EFECTOS ADVERSOS DE LOS MACROLIDOS'},
{ codigo: 'Y404', descripcion: 'EFECTOS ADVERSOS DE TETRACICLINAS'},
{ codigo: 'Y405', descripcion: 'EFECTOS ADVERSOS DE AMINOGLUCOSIDOS'},
{ codigo: 'Y406', descripcion: 'EFECTOS ADVERSOS DE RIFAMICINAS'},
{ codigo: 'Y407', descripcion: 'EFECTOS ADVERSOS DE ANTIBIOTICOS ANTIMICOTICOS USADOS SISTEMICAMENTE'},
{ codigo: 'Y408', descripcion: 'EFECTOS ADVERSOS DE OTROS ANTIBIOTICOS SISTEMICOS'},
{ codigo: 'Y409', descripcion: 'EFECTOS ADVERSOS DE ANTIBIOTICO SISTEMICO NO ESPECIFICADO'},
{ codigo: 'Y41', descripcion: 'EFECTOS ADVERSOS DE OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS'},
{ codigo: 'Y410', descripcion: 'EFECTOS ADVERSOS DE SULFONAMIDAS'},
{ codigo: 'Y411', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIMICOBACTERIANAS'},
{ codigo: 'Y412', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIPALUDICAS Y AGENTES QUE ACTUAN SOBRE OTROS PROTOZOARIOS DE LA SANGRE'},
{ codigo: 'Y413', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS ANTIPROTOZOARIAS'},
{ codigo: 'Y414', descripcion: 'EFECTOS ADVERSOS DE ANTIHELMINTICOS'},
{ codigo: 'Y415', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIVIRALES'},
{ codigo: 'Y418', descripcion: 'EFECTOS ADVERSOS DE OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS ESPECIFICADOS'},
{ codigo: 'Y419', descripcion: 'EFECTOS ADVERSOS DE ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS NO ESPECIFICADOS'},
{ codigo: 'Y42', descripcion: 'EFECTOS ADVERSOS DE HORMONAS Y SUS SUSTITUTOS SINTETICOS Y ANTAGONISTAS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y420', descripcion: 'EFECTOS ADVERSOS DE GLUCOCORTICOIDES Y ANALOGOS SINTETICOS'},
{ codigo: 'Y421', descripcion: 'EFECTOS ADVERSOS DE HORMONAS TIROIDEAS Y SUSTITUTOS'},
{ codigo: 'Y422', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTITIROIDEAS'},
{ codigo: 'Y423', descripcion: 'EFECTOS ADVERSOS DE DROGAS HIPOGLUCEMIANTES ORALES E INSULINA (ANTIDIABETICAS)'},
{ codigo: 'Y424', descripcion: 'EFECTOS ADVERSOS DE ANTICONCEPTIVOS ORALES'},
{ codigo: 'Y425', descripcion: 'EFECTOS ADVERSOS DE OTROS ESTROGENOS Y PROGESTAGENOS'},
{ codigo: 'Y426', descripcion: 'EFECTOS ADVERSOS DE ANTIGONADOTROPINAS, ANTIESTROGENOS Y ANTIANDROGENOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y427', descripcion: 'EFECTOS ADVERSOS DE ANDROGENOS Y CONGENERES ANABOLICOS'},
{ codigo: 'Y428', descripcion: 'EFECTOS ADVERSOS DE OTRAS HORMONAS Y SUS SUSTITUTOS SINTETICOS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'Y429', descripcion: 'EFECTOS ADVERSOS DE OTRAS HORMONAS ANTAGONISTAS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'Y43', descripcion: 'EFECTOS ADVERSOS DE AGENTES SISTEMICOS PRIMARIOS'},
{ codigo: 'Y430', descripcion: 'EFECTOS ADVERSOS DE ANTIALERGICOS Y ANTIEMETICOS'},
{ codigo: 'Y431', descripcion: 'EFECTOS ADVERSOS DE ANTIMETABOLITOS ANTINEOPLASICOS'},
{ codigo: 'Y432', descripcion: 'EFECTOS ADVERSOS DE PRODUCTOS NATURALES ANTINEOPLASICOS'},
{ codigo: 'Y433', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS ANTINEOPLASICAS'},
{ codigo: 'Y434', descripcion: 'EFECTOS ADVERSOS DE AGENTES INMUNOSUPRESORES'},
{ codigo: 'Y435', descripcion: 'EFECTOS ADVERSOS DE AGENTES ACIDIFICANTES Y ALCALINIZANTES'},
{ codigo: 'Y436', descripcion: 'EFECTOS ADVERSOS DE ENZIMAS NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y438', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES SISTEMICOS PRIMARIOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y439', descripcion: 'EFECTOS ADVERSOS DE AGENTE SISTEMICO PRIMARIO NO ESPECIFICADO'},
{ codigo: 'Y44', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE LOS CONSTITUYENTES DE LA SANGRE'},
{ codigo: 'Y440', descripcion: 'EFECTOS ADVERSOS DE PREPARACIONES CON HIERRO Y OTROS PREPARADOS CONTRA ANEMIA HIPOCROMICA'},
{ codigo: 'Y441', descripcion: 'EFECTOS ADVERSOS DE VITAMINA B12, ACIDO FOLICO Y OTROS PREPARADOS CONTRA ANEMIA MEGALOBLASTICA'},
{ codigo: 'Y442', descripcion: 'EFECTOS ADVERSOS DE ANTICOAGULANTES'},
{ codigo: 'Y443', descripcion: 'EFECTOS ADVERSOS DE ANTAGONISTAS DE ANTICOAGULANTES, VITAMINA K Y OTROS COAGULANTES'},
{ codigo: 'Y444', descripcion: 'EFECTOS ADVERSOS DE DROG ANTITROMBOTICAS (INHIBIDORAS DE LA AGREGACION PLAQUETARIA)'},
{ codigo: 'Y445', descripcion: 'EFECTOS ADVERSOS DE DROGAS TROMBOLITICAS'},
{ codigo: 'Y446', descripcion: 'EFECTOS ADVERSOS DE SANGRE NATURAL Y PRODUCTOS SANGUINEOS'},
{ codigo: 'Y447', descripcion: 'EFECTOS ADVERSOS DE LOS SUSTITUTOS DEL PLASMA'},
{ codigo: 'Y449', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN LOS CONSTITUYENTES DE LA SANGRE Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y45', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANALGESICAS, ANTIPIRETICAS Y ANTIINFLAMATORIAS'},
{ codigo: 'Y450', descripcion: 'EFECTOS ADVERSOS DE OPIACEOS Y ANALGESICOS RELACIONADOS'},
{ codigo: 'Y451', descripcion: 'EFECTOS ADVERSOS DE SALICILATOS'},
{ codigo: 'Y452', descripcion: 'EFECTOS ADVERSOS DE DERIVADOS DEL ACIDO PROPIONICO'},
{ codigo: 'Y453', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS ANTIINFLAMATORIAS NO ESTEROIDES (DAINE)'},
{ codigo: 'Y454', descripcion: 'EFECTOS ADVERSOS DE LOS ANTIRREUMATICOS'},
{ codigo: 'Y455', descripcion: 'EFECTOS ADVERSOS DE LOS DERIVADOS DEL 4-AMINOFENOL'},
{ codigo: 'Y458', descripcion: 'EFECTOS ADVERSOS DE OTROS ANALGESICOS Y ANTIPIRETICOS'},
{ codigo: 'Y459', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANALGESICAS, ANTIPIRETICAS Y ANTIINFLAMATORIAS NO ESPECIFICADAS'},
{ codigo: 'Y46', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIEPILEPTICAS Y ANTIPARKINSONIANAS'},
{ codigo: 'Y460', descripcion: 'EFECTOS ADVERSOS DE SUCCINAMIDAS'},
{ codigo: 'Y461', descripcion: 'EFECTOS ADVERSOS DE OXAZOLIDINADIONAS'},
{ codigo: 'Y462', descripcion: 'EFECTOS ADVERSOS DE DERIVADOS DE LA HIDANTOINA'},
{ codigo: 'Y463', descripcion: 'EFECTOS ADVERSOS DE DESOXIBARBITURICOS'},
{ codigo: 'Y464', descripcion: 'EFECTOS ADVERSOS DE IMINOESTILBENOS'},
{ codigo: 'Y465', descripcion: 'EFECTOS ADVERSOS DEL ACIDO VALPROICO'},
{ codigo: 'Y466', descripcion: 'EFECTOS ADVERSOS DE OTROS ANTIEPILEPTICOS, Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y467', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIPARKINSONIANAS'},
{ codigo: 'Y468', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIESPASTICAS'},
{ codigo: 'Y47', descripcion: 'EFECTOS ADVERSOS DE DROGAS SEDANTES, HIPNOTICAS Y ANSIOLITICAS'},
{ codigo: 'Y470', descripcion: 'EFECTOS ADVERSOS DE BARBITURICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y471', descripcion: 'EFECTOS ADVERSOS DE BENZODIAZEPINAS'},
{ codigo: 'Y472', descripcion: 'EFECTOS ADVERSOS DE DERIVADOS CLORALES'},
{ codigo: 'Y473', descripcion: 'EFECTOS ADVERSOS DE PARALDEHIDO'},
{ codigo: 'Y474', descripcion: 'EFECTOS ADVERSOS DE COMPUESTOS DEL BROMO'},
{ codigo: 'Y475', descripcion: 'EFECTOS ADVERSOS DE MEZCLAS SEDANTES E HIPNOTICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y478', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS SEDANTES, HIPNOTICAS Y ANSIOLITICAS'},
{ codigo: 'Y479', descripcion: 'EFECTOS ADVERSOS DE DROGAS SEDANTES, HIPNOTICAS Y ANSIOLITICAS NO ESPECIFICADAS'},
{ codigo: 'Y48', descripcion: 'EFECTOS ADVERSOS DE GASES ANESTESICOS Y TERAPEUTICOS'},
{ codigo: 'Y480', descripcion: 'EFECTOS ADVERSOS DE GASES ANESTESICOS POR INHALACION'},
{ codigo: 'Y481', descripcion: 'EFECTOS ADVERSOS DE GASES ANESTESICOS PARENTERALES'},
{ codigo: 'Y482', descripcion: 'EFECTOS ADVERSOS DE OTROS GASES ANESTESICOS GENERALES, Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y483', descripcion: 'EFECTOS ADVERSOS DE GASES ANESTESICOS LOCALES'},
{ codigo: 'Y484', descripcion: 'EFECTOS ADVERSOS DE ANESTESICOS NO ESPECIFICADOS'},
{ codigo: 'Y485', descripcion: 'EFECTOS ADVERSOS DE GASES TERAPEUTICOS'},
{ codigo: 'Y49', descripcion: 'EFECTOS ADVERSOS DE DROGAS PSICOTROPICAS NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y490', descripcion: 'EFECTOS ADVERSOS DE ANTIDEPRESIVOS TRICICLICOS Y TETRACICLICOS'},
{ codigo: 'Y491', descripcion: 'EFECTOS ADVERSOS DE ANTIDEPRESIVOS INHIBIDORES DE LA MONOAMINOOXIDASA'},
{ codigo: 'Y492', descripcion: 'EFECTOS ADVERSOS DE OTROS ANTIDEPRESIVOS, Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y493', descripcion: 'EFECTOS ADVERSOS DE ANTIPSICOTICOS Y NEUROLEPTICOS FENOTIAZINICOS'},
{ codigo: 'Y494', descripcion: 'EFECTOS ADVERSOS DE NEUROLEPTICOS DE LA BUTIROFENONA Y TIOXANTINA'},
{ codigo: 'Y495', descripcion: 'EFECTOS ADVERSOS DE OTROS ANTIPSICOTICOS Y NEUROLEPTICOS'},
{ codigo: 'Y496', descripcion: 'EFECTOS ADVERSOS DE PSICODISLEPTICOS [ALUCINOGENOS]'},
{ codigo: 'Y497', descripcion: 'EFECTOS ADVERSOS DE PSICOESTIMULANTES CON ABUSO POTENCIAL'},
{ codigo: 'Y498', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y499', descripcion: 'EFECTOS ADVERSOS DE DROGAS PSICOTROPICAS NO ESPECIFICADAS'},
{ codigo: 'Y50', descripcion: 'EFECTOS ADVERSOS DE ESTIMULANTES DEL SISTEMA NERVIOSO CENTRAL, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y500', descripcion: 'EFECTOS ADVERSOS DE ANALEPTICOS'},
{ codigo: 'Y501', descripcion: 'EFECTOS ADVERSOS DE ANTAGONISTAS DE OPIACEOS'},
{ codigo: 'Y502', descripcion: 'EFECTOS ADVERSOS DE METILXANTINAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y508', descripcion: 'EFECTOS ADVERSOS DE OTROS ESTIMULANTES DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'Y509', descripcion: 'EFECTOS ADVERSOS DE ESTIMULANTE NO ESPECIFICADO DEL SISTEMA NERVIOSO CENTRAL'},
{ codigo: 'Y51', descripcion: 'EFECTOS ADVERSOS DE DROGAS QUE AFECTAN PRIMARIAMENTE AL SISTEMA NERVIOSO AUTONOMO'},
{ codigo: 'Y510', descripcion: 'EFECTOS ADVERSOS DE AGENTES ANTICOLINESTERASA'},
{ codigo: 'Y511', descripcion: 'EFECTOS ADVERSOS DE OTROS PARASIMPATICOMIMETICOS (COLINERGICOS)'},
{ codigo: 'Y512', descripcion: 'EFECTOS ADVERSOS DE DROGAS BLOQUEADORAS GANGLIONARES, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y513', descripcion: 'EFECTOS ADVERSOS DE OTROS PARASIMPATICOLITICO (ANTICOLINERGICOS Y ANTIMUSCARINICOS) Y ESPASMOLITICOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y514', descripcion: 'EFECTOS ADVERSOS DE AGONISTAS (ESTIMULANTES) PREDOMINANTEMENTE ALFA-ADRENERGICOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y515', descripcion: 'EFECTOS ADVERSOS DE AGONISTAS (ESTIMULANTES) PREDOMINANTEMENTE BETA-ADRENERGICO NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y516', descripcion: 'EFECTOS ADVERSOS DE ANTAGONISTAS (BLOQUEADORES) ALFA-ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y517', descripcion: 'EFECTOS ADVERSOS DE ANTAGONISTAS (BLOQUEADORES) BETA-ADRENERGICOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y518', descripcion: 'EFECTOS ADVERSOS DE AGENTES BLOQUEADORES NEURO-ADRENERGICOS QUE ACTUAN CENTRALMENTE NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y519', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS QUE AFECTAN PRIMARIAMENTE EL SISTEMA NERVIOSO AUTONOMO, Y LAS NO ESPECIFICADAS'},
{ codigo: 'Y52', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE EL SISTEMA CARDIOVASCULAR'},
{ codigo: 'Y520', descripcion: 'EFECTOS ADVERSOS DE GLUCOSIDOS CARDIOTONICOS Y DROGAS DE ACCION SIMILAR'},
{ codigo: 'Y521', descripcion: 'EFECTOS ADVERSOS DE BLOQUEADORES DEL CANAL DEL CALCIO'},
{ codigo: 'Y522', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS ANTIARRITMICAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y523', descripcion: 'EFECTOS ADVERSOS DE VASODILATADORES CORONARIOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y524', descripcion: 'EFECTOS ADVERSOS INHIBIDORES DE LA ENZIMA CONVERTIDORA DE ANGIOTENSINA'},
{ codigo: 'Y525', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS ANTIHIPERTENSIVAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y526', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIHIPERLIPIDEMICAS Y ANTIARTERIOSCLEROTICAS'},
{ codigo: 'Y527', descripcion: 'EFECTOS ADVERSOS DE VASODILATADORES PERIFERICOS'},
{ codigo: 'Y528', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIVARICOSAS, INCLUSIVE AGENTES ESCLEROSANTES'},
{ codigo: 'Y529', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN PRIMARIAMENTE AL SISTEMA CARDIOVASCULAR, Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y53', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE SISTEMA GASTROINTESTINAL'},
{ codigo: 'Y530', descripcion: 'EFECTOS ADVERSOS DE BLOQUEADORES DE LOS RECEPTORES H2 DE HISTAMINA'},
{ codigo: 'Y531', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS ANTIACIDAS E INHIBIDORAS SECRECION GASTRICA'},
{ codigo: 'Y532', descripcion: 'EFECTOS ADVERSOS DE LAXANTES ESTIMULANTES'},
{ codigo: 'Y533', descripcion: 'EFECTOS ADVERSOS DE LAXANTES SALINOS Y OSMOTICOS'},
{ codigo: 'Y534', descripcion: 'EFECTOS ADVERSOS DE OTROS LAXANTES'},
{ codigo: 'Y535', descripcion: 'EFECTOS ADVERSOS DE DIGESTIVOS'},
{ codigo: 'Y536', descripcion: 'EFECTOS ADVERSOS DE DROGAS ANTIDIARREICAS'},
{ codigo: 'Y537', descripcion: 'EFECTOS ADVERSOS DE EMETICOS'},
{ codigo: 'Y538', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN PRIMARIAMENTE SISTEMA GASTROINTESTINAL'},
{ codigo: 'Y539', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE AFECTAN SISTEMA GASTROINTESTINAL, NO ESPECIFICADOS'},
{ codigo: 'Y54', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE EL EQUILIBRIO HIDRICO Y EL METABOLISMO MINERAL Y DEL ACIDO URICO'},
{ codigo: 'Y540', descripcion: 'EFECTOS ADVERSOS DE MINERALOCORTICOIDES'},
{ codigo: 'Y541', descripcion: 'EFECTOS ADVERSOS DE LOS BLOQUEADORES DE MINERALOCORTICOIDES (ANTAGONISTAS DE LA ALDOSTERONA)'},
{ codigo: 'Y542', descripcion: 'EFECTOS ADVERSOS DE LOS INHIBIDORES DE LA ANHIDRASA CARBONICA'},
{ codigo: 'Y543', descripcion: 'EFECTOS ADVERSOS DE LOS DERIVADOS DE LA BENZOTIADIAZINA'},
{ codigo: 'Y544', descripcion: '"EFECTOS ADVERSOS DE DIURETICOS DE ASA (""HIGH-CEILING"")"'},
{ codigo: 'Y545', descripcion: 'EFECTOS ADVERSOS DE OTROS DIURETICOS'},
{ codigo: 'Y546', descripcion: 'EFECTOS ADVERSOS DE AGENTES ELECTROLITICOS, CALORICOS Y DEL EQUILIBRIO HIDRICO'},
{ codigo: 'Y547', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE AFECTAN LA CALCIFICACION'},
{ codigo: 'Y548', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE AFECTAN EL METABOLISMO DEL ACIDO URICO'},
{ codigo: 'Y549', descripcion: 'EFECTOS ADVERSOS DE SALES MINERALES, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y55', descripcion: 'EFECTOS ADVERSOS DE AGENTES QUE ACTUAN PRIMARIAMENTE SOBRE LOS MUSCULOS LISOS Y ESTRIADOS Y SOBRE EL SISTEMA RESPIRATORIO'},
{ codigo: 'Y550', descripcion: 'EFECTOS ADVERSOS DE DROGAS OXITOCICAS'},
{ codigo: 'Y551', descripcion: 'EFECTOS ADVERSOS DE RELAJANTES DE LOS MUSCULOS ESTRIADOS (AGENTES BLOQUEADORES NEUROMUSCULARES)'},
{ codigo: 'Y552', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES QUE ACTUAN PRIMARIAMENTE SOBRE LOS MUSCULOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y553', descripcion: 'EFECTOS ADVERSOS DE ANTITUSIGENOS'},
{ codigo: 'Y554', descripcion: 'EFECTOS ADVERSOS DE EXPECTORANTES'},
{ codigo: 'Y555', descripcion: 'EFECTOS ADVERSOS DE DROGAS CONTRA EL RESFRIADO COMUN'},
{ codigo: 'Y556', descripcion: 'EFECTOS ADVERSOS DE ANTIASMATICOS, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y557', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES QUE ACTUAN PRIMARIAMENTE SOBRE EL SISTEMA RESPIRATORIO Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y56', descripcion: 'EFECTOS ADVERSOS DE AGENTES TOPICOS QUE AFECTAN PRIMARIAMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS, Y DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES'},
{ codigo: 'Y560', descripcion: 'EFECTOS ADVERSOS DE DROG ANTIMICOTICAS, ANTIINFECCIOSAS Y ANTIINFLAMATORIAS DE USO LOCAL NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y561', descripcion: 'EFECTOS ADVERSOS DE ANTIPRURIGINOSOS'},
{ codigo: 'Y562', descripcion: 'EFECTOS ADVERSOS DE ASTRINGENTES Y DETERGENTES LOCALES'},
{ codigo: 'Y563', descripcion: 'EFECTOS ADVERSOS DE EMOLIENTES, DEMULCENTES Y PROTECTORES'},
{ codigo: 'Y564', descripcion: 'EFECTOS ADVERSOS DE DROGAS Y PREPARADOS QUERATOLITICOS, QUERATOPLASTICOS Y OTROS PARA EL TRATAMIENTO DEL CABELLO'},
{ codigo: 'Y565', descripcion: 'EFECTOS ADVERSOS DE DROGAS Y PREPARADOS OFTALMOLOGICOS'},
{ codigo: 'Y566', descripcion: 'EFECTOS ADVERSOS DE DROGAS Y PREPARADOS OTORRINOLARINGOLOGICOS'},
{ codigo: 'Y567', descripcion: 'EFECTOS ADVERSOS DE DROGAS DENTALES, DE APLICACION TOPICA'},
{ codigo: 'Y568', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES TOPICOS'},
{ codigo: 'Y569', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES TOPICOS NO ESPECIFICADOS'},
{ codigo: 'Y57', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS Y MEDICAMENTOS Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y570', descripcion: 'EFECTOS ADVERSOS DE DEPRESORES DEL APETITO [ANOREXICOS]'},
{ codigo: 'Y571', descripcion: 'EFECTOS ADVERSOS DE DROGAS LIPOTROPICAS'},
{ codigo: 'Y572', descripcion: 'EFECTOS ADVERSOS DE ANTIDOTOS Y AGENTES QUELANTES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y573', descripcion: 'EFECTOS ADVERSOS DE DISUASIVOS DEL ALCOHOL'},
{ codigo: 'Y574', descripcion: 'EFECTOS ADVERSOS DE EXCIPIENTES FARMACEUTICOS'},
{ codigo: 'Y575', descripcion: 'EFECTOS ADVERSOS DE MEDIOS DE CONTRASTE PARA RAYOS X'},
{ codigo: 'Y576', descripcion: 'EFECTOS ADVERSOS DE OTROS AGENTES DIAGNOSTICOS'},
{ codigo: 'Y577', descripcion: 'EFECTOS ADVERSOS DE VITAMINAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Y578', descripcion: 'EFECTOS ADVERSOS DE OTRAS DROGAS Y MEDICAMENTOS'},
{ codigo: 'Y579', descripcion: 'EFECTOS ADVERSOS DE DROGAS O MEDICAMENTOS NO ESPECIFICADOS'},
{ codigo: 'Y58', descripcion: 'EFECTOS ADVERSOS DE VACUNAS BACTERIANAS'},
{ codigo: 'Y580', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA BCG'},
{ codigo: 'Y581', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA TIFOIDEA Y PARATIFOIDEA'},
{ codigo: 'Y582', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA CONTRA EL COLERA'},
{ codigo: 'Y583', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA CONTRA LA PESTE'},
{ codigo: 'Y584', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA CONTRA EL TETANOS'},
{ codigo: 'Y585', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA CONTRA LA DIFTERIA'},
{ codigo: 'Y586', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA CONTRA TOS FERINA, INCLUSIVE COMBINACIONES CON UN COMPONENTE PERTUSIS'},
{ codigo: 'Y588', descripcion: 'EFECTOS ADVERSOS DE LA VACUNA BACTERIANA MIXTA, EXCEPTO COMBINACIONES CON UN COMPONENTE PERTUSIS'},
{ codigo: 'Y589', descripcion: 'EFECTOS ADVERSOS DE OTRAS VACUNAS BACTERIANAS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'Y59', descripcion: 'EFECTOS ADVERSOS DE OTRAS VACUNAS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS'},
{ codigo: 'Y590', descripcion: 'EFECTOS ADVERSOS DE VACUNAS VIRALES'},
{ codigo: 'Y591', descripcion: 'EFECTOS ADVERSOS DE VACUNAS CONTRA RICKETTSIAS'},
{ codigo: 'Y592', descripcion: 'EFECTOS ADVERSOS DE VACUNAS ANTIPROTOZOARIAS'},
{ codigo: 'Y593', descripcion: 'EFECTOS ADVERSOS DE LA INMUNOGLOBULINA'},
{ codigo: 'Y598', descripcion: 'EFECTOS ADVERSOS DE OTRAS VACUNAS Y SUSTANCIAS BIOLOGICAS ESPECIFICADAS'},
{ codigo: 'Y599', descripcion: 'EFECTOS ADVERSOS DE VACUNAS O SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS'},
{ codigo: 'Y60', descripcion: 'CORTE, PUNCION, PERFORACION O HEMORRAGIA NO INTENCIONAL DURANTE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y600', descripcion: 'INCIDENTE DURANTE OPERACION QUIRURGICA'},
{ codigo: 'Y601', descripcion: 'INCIDENTE DURANTE INFUSION O TRANSFUSION'},
{ codigo: 'Y602', descripcion: 'INCIDENTE DURANTE DIALISIS RENAL U OTRA PERFUSION'},
{ codigo: 'Y603', descripcion: 'INCIDENTE DURANTE INYECCION O INMUNIZACION'},
{ codigo: 'Y604', descripcion: 'INCIDENTE DURANTE EXAMEN ENDOSCOPICO'},
{ codigo: 'Y605', descripcion: 'INCIDENTE DURANTE CATETERIZACION CARDIACA'},
{ codigo: 'Y606', descripcion: 'INCIDENTE DURANTE ASPIRACION, PUNCION Y OTRA CATETERIZACION'},
{ codigo: 'Y607', descripcion: 'INCIDENTE DURANTE ADMINISTRACION DE ENEMA'},
{ codigo: 'Y608', descripcion: 'INCIDENTE DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS'},
{ codigo: 'Y609', descripcion: 'INCIDENTE DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA'},
{ codigo: 'Y61', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y610', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE OPERACION QUIRURGICA'},
{ codigo: 'Y611', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE INFUSION O TRANSFUSION'},
{ codigo: 'Y612', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE DIALISIS RENAL U OTRA PERFUSION'},
{ codigo: 'Y613', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE INYECCION O INMUNIZACION'},
{ codigo: 'Y614', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE EXAMEN ENDOSCOPICO'},
{ codigo: 'Y615', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE CATETERIZACION CARDIACA'},
{ codigo: 'Y616', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE ASPIRACION, PUNCION Y OTRA CATETERIZACION'},
{ codigo: 'Y617', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE REMOCION CATETER O TAPONAMIENTO'},
{ codigo: 'Y618', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS'},
{ codigo: 'Y619', descripcion: 'OBJETO EXTRA—O DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA'},
{ codigo: 'Y62', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y620', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE OPERACION QUIRURGICA'},
{ codigo: 'Y621', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE INFUSION O TRANSFUSION'},
{ codigo: 'Y622', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE DIALISIS RENAL U OTRA PERFUSION'},
{ codigo: 'Y623', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE INYECCION O INMUNIZACION'},
{ codigo: 'Y624', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE EXAMEN ENDOSCOPICO'},
{ codigo: 'Y625', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE CATETERIZACION CARDIACA'},
{ codigo: 'Y626', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE ASPIRACION, PUNCION Y OTRA CATETERIZACION'},
{ codigo: 'Y628', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS'},
{ codigo: 'Y629', descripcion: 'FALLAS EN LA ESTERILIZACION DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA'},
{ codigo: 'Y63', descripcion: 'FALLA EN LA DOSIFICACION DURANTE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y630', descripcion: 'EXCESIVA CANTIDAD DE SANGRE U OTRO LIQUIDO ADMINISTRADO DURANTE UNA INFUSION O TRANSFUSION'},
{ codigo: 'Y631', descripcion: 'DILUCION INCORRECTA DE LIQUIDO DURANTE UNA INFUSION'},
{ codigo: 'Y632', descripcion: 'SOBREDOSIS DE RADIACION ADMINISTRADA DURANTE TERAPIA'},
{ codigo: 'Y633', descripcion: 'EXPOSICION INADVERTIDA DEL PACIENTE A RADIACION EN LA ATENCION MEDICA'},
{ codigo: 'Y634', descripcion: 'FALLA EN LA DOSIFICACION EN ELECTROCHOQUE O EN CHOQUE INSULINICO'},
{ codigo: 'Y635', descripcion: 'FALLA EN EL CONTROL DE LA TEMPERATURA, EN TAPONAMIENTOS Y APLICACIONES LOCALES'},
{ codigo: 'Y636', descripcion: 'NO ADMINISTRACION DE DROGAS, MEDICAMENTOS O SUSTANCIAS BIOLOGICAS NECESARIAS'},
{ codigo: 'Y638', descripcion: 'FALLA EN LA DOSIFICACION DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS'},
{ codigo: 'Y639', descripcion: 'FALLA EN LA DOSIFICACION DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA'},
{ codigo: 'Y64', descripcion: 'MEDICAMENTOS O SUSTANCIAS BIOLOGICAS CONTAMINADOS'},
{ codigo: 'Y640', descripcion: 'MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO EN INFUSION O TRANSFUSION'},
{ codigo: 'Y641', descripcion: 'MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO, INYECTADO O USADO PARA INMUNIZACION'},
{ codigo: 'Y648', descripcion: 'MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO ADMINISTRADO POR OTROS MEDIOS'},
{ codigo: 'Y649', descripcion: 'MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO, ADMINISTRADO POR MEDIOS NO ESPECIFICADOS'},
{ codigo: 'Y65', descripcion: 'OTROS INCIDENTES DURANTE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y650', descripcion: 'SANGRE INCOMPATIBLE USADA EN TRANSFUSION'},
{ codigo: 'Y651', descripcion: 'LIQUIDO ERRONEO USADO EN INFUSION'},
{ codigo: 'Y652', descripcion: 'FALLA EN LA SUTURA O LIGADURA DURANTE OPERACION QUIRURGICA'},
{ codigo: 'Y653', descripcion: 'TUBO ENDOTRAQUEAL COLOCADO ERRONEAMENTE EN PROCEDIMIENTO ANESTESICO'},
{ codigo: 'Y654', descripcion: 'FALLA EN LA INTRODUCCION O REMOCION DE OTRO TUBO O INSTRUMENTO'},
{ codigo: 'Y655', descripcion: 'REALIZACION DE UNA OPERACION INADECUADA'},
{ codigo: 'Y658', descripcion: 'OTROS INCIDENTES ESPECIFICADOS DURANTE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y66', descripcion: 'NO ADMINISTRACION DE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y69', descripcion: 'INCIDENTES NO ESPECIFICADOS DURANTE LA ATENCION MEDICA Y QUIRURGICA'},
{ codigo: 'Y70', descripcion: 'DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y700', descripcion: 'DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y701', descripcion: 'DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y702', descripcion: 'DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y703', descripcion: 'DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y708', descripcion: 'DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y71', descripcion: 'DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y710', descripcion: 'DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y711', descripcion: 'DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y712', descripcion: 'DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y713', descripcion: 'DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURA)'},
{ codigo: 'Y718', descripcion: 'DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y72', descripcion: 'DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y720', descripcion: 'DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y721', descripcion: 'DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y722', descripcion: 'DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTS, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y723', descripcion: 'DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y728', descripcion: 'DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y73', descripcion: 'DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y730', descripcion: 'DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y731', descripcion: 'DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICO (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y732', descripcion: 'DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y733', descripcion: 'DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y738', descripcion: 'DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y74', descripcion: 'DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y740', descripcion: 'DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y741', descripcion: 'DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y742', descripcion: 'DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y743', descripcion: 'DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y748', descripcion: 'DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y75', descripcion: 'DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y750', descripcion: 'DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y751', descripcion: 'DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICO (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y752', descripcion: 'DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y753', descripcion: 'DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y758', descripcion: 'DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y76', descripcion: 'DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y760', descripcion: 'DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y761', descripcion: 'DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y762', descripcion: 'DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y763', descripcion: 'DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICO, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y768', descripcion: 'DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y77', descripcion: 'DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y770', descripcion: 'DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y771', descripcion: 'DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y772', descripcion: 'DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y773', descripcion: 'DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y778', descripcion: 'DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y78', descripcion: 'APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y780', descripcion: 'APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y781', descripcion: 'APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y782', descripcion: 'APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y783', descripcion: 'APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y788', descripcion: 'APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y79', descripcion: 'DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y790', descripcion: 'DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y791', descripcion: 'DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y792', descripcion: 'DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y793', descripcion: 'DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y798', descripcion: 'DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y80', descripcion: 'APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y800', descripcion: 'APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y801', descripcion: 'APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y802', descripcion: 'APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y803', descripcion: 'APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y808', descripcion: 'APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y81', descripcion: 'DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y810', descripcion: 'DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y811', descripcion: 'DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y812', descripcion: 'DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y813', descripcion: 'DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y818', descripcion: 'DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y82', descripcion: 'OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS'},
{ codigo: 'Y820', descripcion: 'OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO'},
{ codigo: 'Y821', descripcion: 'OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION'},
{ codigo: 'Y822', descripcion: 'OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS'},
{ codigo: 'Y823', descripcion: 'OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)'},
{ codigo: 'Y828', descripcion: 'OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS, DISPOSITIVOS DIVERSOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Y83', descripcion: 'CIRUGIA Y OTROS PROCEDIMIENTOS QUIRURGICOS COMO LA CAUSA DE REACCION ANORMAL EN EL PACIENTE O DE COMPLICACION POSTERIOR SIN MENCION DE INCIDENTE EN EL MOMENTO DE EFECTUAR EL PROCEDIMIENTO'},
{ codigo: 'Y830', descripcion: 'OPERACION QUIRURGICA CON TRASPLANTE DE UN ORGANO COMPLETO'},
{ codigo: 'Y831', descripcion: 'OPERACION QUIRURGICA CON IMPLANTE DE UN DISPOSITIVO ARTIFICIAL INTERNO'},
{ codigo: 'Y832', descripcion: 'OPERACION QUIRURGICA CON ANASTOMOSIS, DERIVACION O INJERTO'},
{ codigo: 'Y833', descripcion: 'OPERACION QUIRURGICA CON FORMACION DE ESTOMA EXTERNO'},
{ codigo: 'Y834', descripcion: 'OTRA CIRUGIA RECONSTRUCTIVA'},
{ codigo: 'Y835', descripcion: 'AMPUTACION DE MIEMBRO(S)'},
{ codigo: 'Y836', descripcion: 'REMOCION DE OTRO ORGANO (PARCIAL) (TOTAL)'},
{ codigo: 'Y838', descripcion: 'OTROS PROCEDIMIENTOS QUIRURGICOS'},
{ codigo: 'Y839', descripcion: 'PROCEDIMIENTO QUIRURGICO NO ESPECIFICADO'},
{ codigo: 'Y84', descripcion: 'OTROS PROCEDIMIENTOS MEDICOS COMO LA CAUSA DE REACCION ANORMAL DEL PACIENTE O DE COMPLICACION POSTERIOR, SIN MENCION DE INCIDENTE EN EL MOMENTO DE EFECTUAR EL PROCEDIMIENTO'},
{ codigo: 'Y840', descripcion: 'CATETERIZACION CARDIACA'},
{ codigo: 'Y841', descripcion: 'DIALISIS RENAL'},
{ codigo: 'Y842', descripcion: 'PROCEDIMIENTO RADIOLOGICO Y RADIOTERAPIA'},
{ codigo: 'Y843', descripcion: 'TERAPIA POR CHOQUE'},
{ codigo: 'Y844', descripcion: 'ASPIRACION DE LIQUIDOS'},
{ codigo: 'Y845', descripcion: 'INSERCION DE SONDA GASTRICA O DUODENAL'},
{ codigo: 'Y846', descripcion: 'CATETERIZACION URINARIA'},
{ codigo: 'Y847', descripcion: 'MUESTRA DE SANGRE'},
{ codigo: 'Y848', descripcion: 'OTROS PROCEDIMIENTOS MEDICOS'},
{ codigo: 'Y849', descripcion: 'PROCEDIMIENTO MEDICO NO ESPECIFICADO'},
{ codigo: 'Y85', descripcion: 'SECUELAS DE ACCIDENTES DE TRANSPORTE'},
{ codigo: 'Y850', descripcion: 'SECUELAS DE ACCIDENTE DE VEHICULO DE MOTOR'},
{ codigo: 'Y859', descripcion: 'SECUELAS DE OTROS ACCIDENTES DE TRANSPORTE, Y LOS NO ESPECIFICADOS'},
{ codigo: 'Y86', descripcion: 'SECUELAS DE OTROS ACCIDENTES'},
{ codigo: 'Y87', descripcion: 'SECUELAS DE LESIONES AUTOINFLIGIDAS INTENCIONALMENTE, AGRESIONES Y EVENTOS DE INTENCION NO DETERMINADA'},
{ codigo: 'Y870', descripcion: 'SECUELAS DE LESIONES AUTOINFLIGIDAS'},
{ codigo: 'Y871', descripcion: 'SECUELAS DE AGRESIONES'},
{ codigo: 'Y872', descripcion: 'SECUELAS DE EVENTOS DE INTENCION NO DETERMINADA'},
{ codigo: 'Y88', descripcion: 'SECUELAS CON ATENCION MEDICA Y QUIRURGICA COMO CAUSA EXTERNA'},
{ codigo: 'Y880', descripcion: 'SECUELAS DE EFECTOS ADVERSOS CAUSADOS POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGOGICAS EN SU USO TERAPEUTICO'},
{ codigo: 'Y881', descripcion: 'SECUELAS DE INCIDENTES OCURRIDOS AL PACIENTE DURANTE PROCEDIMIENTOS MEDICOS Y QUIRURGICOS'},
{ codigo: 'Y882', descripcion: 'SECUELAS DE INCIDENTES ADVER ASOCIADOS CON DISPOSITIVOS MEDICOS EN USO DIAGNOSTICO Y TERAPEUTICO'},
{ codigo: 'Y883', descripcion: 'SECUELAS DE PROCEDIMIENTOS MEDICOS Y QUIRURGICOS COMO LA CAUSA DE REACCION ANORMAL DEL PACIENTE O DE COMPLICACION POSTERIOR SIN MENCION DE INCIDENTE EN EL MOMENTO DE EFECTUAR EL PROCEDIMIENTO'},
{ codigo: 'Y89', descripcion: 'SECUELAS DE OTRAS CAUSAS EXTERNAS'},
{ codigo: 'Y890', descripcion: 'SECUELAS DE INTERVENCION LEGAL'},
{ codigo: 'Y891', descripcion: 'SECUELAS DE OPERACIONES DE GUERRA'},
{ codigo: 'Y899', descripcion: 'SECUELAS DE CAUSA EXTERNA NO ESPECIFICADA'},
{ codigo: 'Y90', descripcion: 'EVIDENCIA DE ALCOHOLISMO DETERMINADA POR NIVEL DE ALCOHOL EN LA SANGRE'},
{ codigo: 'Y900', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE MENOR DE 20 MG/100 ML'},
{ codigo: 'Y901', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 20 A 39 MG/100 ML'},
{ codigo: 'Y902', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 40 A 59 MG/100 ML'},
{ codigo: 'Y903', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 60 A 79 MG/100 ML'},
{ codigo: 'Y904', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 80 A 99 MG/100 ML'},
{ codigo: 'Y905', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 100 A 119 MG/100 ML'},
{ codigo: 'Y906', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 120 A 199 MG/100 ML'},
{ codigo: 'Y907', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 200 A 239 MG/100 ML'},
{ codigo: 'Y908', descripcion: 'NIVEL DE ALCOHOL EN LA SANGRE DE 240 MG/100 ML O MAS'},
{ codigo: 'Y909', descripcion: 'PRESENCIA DE ALCOHOL EN LA SANGRE, NIVEL NO ESPECIFICADO'},
{ codigo: 'Y91', descripcion: 'EVIDENCIA DE ALCOHOLISMO DETERMINADA POR EL NIVEL DE INTOXICACION'},
{ codigo: 'Y910', descripcion: 'INTOXICACION ALCOHOLICA LEVE'},
{ codigo: 'Y911', descripcion: 'INTOXICACION ALCOHOLICA MODERADA'},
{ codigo: 'Y912', descripcion: 'INTOXICACION ALCOHOLICA SEVERA'},
{ codigo: 'Y913', descripcion: 'INTOXICACION ALCOHOLICA MUY SEVERA'},
{ codigo: 'Y919', descripcion: 'ALCOHOLISMO, NIVEL DE INTOXICACION NO ESPECIFICADO'},
{ codigo: 'Y95', descripcion: 'AFECCION NOSOCOMIAL'},
{ codigo: 'Y96', descripcion: 'AFECCION RELACIONADA CON EL TRABAJO'},
{ codigo: 'Y97', descripcion: 'AFECCION RELACIONADA CON LA CONTAMINACION AMBIENTAL'},
{ codigo: 'Y98', descripcion: 'AFECCION RELACIONADA CON EL ESTILO DE VIDA'},
{ codigo: 'Z00', descripcion: 'EXAMEN GENERAL E INVESTIGACION DE PERSONAS SIN QUEJAS O SIN DIAGNOSTICO INFORMADO'},
{ codigo: 'Z000', descripcion: 'EXAMEN MEDICO GENERAL'},
{ codigo: 'Z001', descripcion: 'CONTROL DE SALUD DE RUTINA DEL NI—O'},
{ codigo: 'Z002', descripcion: 'EXAMEN DURANTE EL PERIODO DE CRECIMIENTO RAPIDO EN LA INFANCIA'},
{ codigo: 'Z003', descripcion: 'EXAMEN DEL ESTADO DE DESARROLLO DEL ADOLESCENTE'},
{ codigo: 'Z004', descripcion: 'EXAMEN PSIQUIATRICO GENERAL, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'Z005', descripcion: 'EXAMEN DE DONANTE POTENCIAL DE ORGANO O TEJIDO'},
{ codigo: 'Z006', descripcion: 'EXAMEN PARA COMPARACION Y CONTROL NORMALES EN PROGRAMA DE INVESTIGACION CLINICA'},
{ codigo: 'Z008', descripcion: 'OTROS EXAMENES GENERALES'},
{ codigo: 'Z01', descripcion: 'OTROS EXAMENES ESPECIALIALES E INVESTIGACIONES EN PERSONAS SIN QUEJAS O SIN DIAGNOSTICO INFORMADO'},
{ codigo: 'Z010', descripcion: 'EXAMEN DE OJOS Y DE LA VISION'},
{ codigo: 'Z011', descripcion: 'EXAMEN DE OIDOS Y DE LA AUDICION'},
{ codigo: 'Z012', descripcion: 'EXAMEN ODONTOLOGICO'},
{ codigo: 'Z013', descripcion: 'EXAMEN DE LA PRESION SANGUINEA'},
{ codigo: 'Z014', descripcion: 'EXAMEN GINECOLOGICO (GENERAL) (DE RUTINA)'},
{ codigo: 'Z015', descripcion: 'PRUEBAS DE SENSIBILIZACION Y DIAGNOSTICO CUTANEO'},
{ codigo: 'Z016', descripcion: 'EXAMEN RADIOLOGICO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'Z017', descripcion: 'EXAMEN DE LABORATORIO'},
{ codigo: 'Z018', descripcion: 'OTROS EXAMENES ESPECIALES ESPECIFICADOS'},
{ codigo: 'Z019', descripcion: 'EXAMEN ESPECIAL NO ESPECIFICADO'},
{ codigo: 'Z02', descripcion: 'EXAMENES Y CONTACTOS PARA FINES ADMINISTRATIVOS'},
{ codigo: 'Z020', descripcion: 'EXAMEN PARA ADMISION A INSTITUCIONES EDUCATIVAS'},
{ codigo: 'Z021', descripcion: 'EXAMEN PREEMPLEO'},
{ codigo: 'Z022', descripcion: 'EXAMEN PARA ADMISION A INSTITUCIONES RESIDENCIALES'},
{ codigo: 'Z023', descripcion: 'EXAMEN PARA RECLUTAMIENTO EN LAS FUERZAS ARMADAS'},
{ codigo: 'Z024', descripcion: 'EXAMEN PARA OBTENCION DE LICENCIA DE CONDUCIR'},
{ codigo: 'Z025', descripcion: 'EXAMEN PARA PARTICIPACION EN COMPETENCIAS DEPORTIVAS'},
{ codigo: 'Z026', descripcion: 'EXAMEN PARA FINES DE SEGUROS'},
{ codigo: 'Z027', descripcion: 'EXTENSION DE CERTIFICADO MEDICO'},
{ codigo: 'Z028', descripcion: 'OTROS EXAMENES PARA FINES ADMINISTRATIVOS'},
{ codigo: 'Z029', descripcion: 'EXAMEN PARA FINES ADMINISTRATIVOS, NO ESPECIFICADO'},
{ codigo: 'Z03', descripcion: 'OBSERVACION Y EVALUACION MEDICAS POR SOSPECHA ENFERMEDADES AFECCIONES'},
{ codigo: 'Z030', descripcion: 'OBSERVACION POR SOSPECHA DE TUBERCULOSIS'},
{ codigo: 'Z031', descripcion: 'OBSERVACION POR SOSPECHA DE TUMOR MALIGNO'},
{ codigo: 'Z032', descripcion: 'OBSERVACION POR SOSPECHA DE TRASTORNO MENTAL Y DEL COMPORTAMIENTO'},
{ codigo: 'Z033', descripcion: 'OBSERVACION POR SOSPECHA DE TRASTORNO DEL SISTEMA NERVIOSO'},
{ codigo: 'Z034', descripcion: 'OBSERVACION POR SOSPECHA DE INFARTO DE MIOCARDIO'},
{ codigo: 'Z035', descripcion: 'OBSERVACION POR SOSPECHA DE OTRAS ENFERMEDADES CARDIOVASCULARES'},
{ codigo: 'Z036', descripcion: 'OBSERVACION POR SOSPECHA DE EFECTOS TOXICOS DE SUSTANCIAS INGERIDAS'},
{ codigo: 'Z038', descripcion: 'OBSERVACION POR SOSPECHA DE OTRAS ENFERMEDADES Y AFECCIONES'},
{ codigo: 'Z039', descripcion: 'OBSERVACION POR SOSPECHA DE ENFERMEDAD O AFECCION NO ESPECIFICADA'},
{ codigo: 'Z04', descripcion: 'EXAMEN Y OBSERVACION POR OTRAS RAZONES'},
{ codigo: 'Z040', descripcion: 'PRUEBA DE ALCOHOL O DROGAS EN LA SANGRE'},
{ codigo: 'Z041', descripcion: 'EXAMEN Y OBSERVACION CONSECUTIVOS A ACCIDENTE DE TRANSPORTE'},
{ codigo: 'Z042', descripcion: 'EXAMEN Y OBSERVACION CONSECUTIVOS A ACCIDENTE DE TRABAJO'},
{ codigo: 'Z043', descripcion: 'EXAMEN Y OBSERVACION CONSECUTIVOS A OTRO ACCIDENTE'},
{ codigo: 'Z044', descripcion: 'EXAMEN Y OBSERVACION CONSECUTIVOS A DENUNCIA DE VIOLACION Y SEDUCCION'},
{ codigo: 'Z045', descripcion: 'EXAMEN Y OBSERVACION CONSECUTIVOS A OTRA LESION INFLIGIDA'},
{ codigo: 'Z046', descripcion: 'EXAMEN PSIQUIATRICO GENERAL, SOLICITADO POR UNA AUTORIDAD'},
{ codigo: 'Z048', descripcion: 'EXAMEN Y OBSERVACION POR OTRAS RAZONES ESPECIFICADAS'},
{ codigo: 'Z049', descripcion: 'EXAMEN Y OBSERVACION POR RAZONES NO ESPECIFICADAS'},
{ codigo: 'Z08', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO AL TRATAMIENTO POR TUMOR MALIGNO'},
{ codigo: 'Z080', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A CIRUGIA POR TUMOR MALIGNO'},
{ codigo: 'Z081', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A RADIOTERAPIA POR TUMOR MALIGNO'},
{ codigo: 'Z082', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A QUIMIOTERAPIA POR TUMOR MALIGNO'},
{ codigo: 'Z087', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO COMBINADO POR TUMOR MALIGNO'},
{ codigo: 'Z088', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO POR TUMOR MALIGNO'},
{ codigo: 'Z089', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO NO ESPECIFICADO POR TUMOR MALIGNO'},
{ codigo: 'Z09', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMAMIENTO POR OTRAS AFECCIONES DIFERENTES A TUMOR MALIGNO'},
{ codigo: 'Z090', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A CIRUGIA POR OTRAS AFECCIONES'},
{ codigo: 'Z091', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A RADIOTERAPIA POR OTRAS AFECCIONES'},
{ codigo: 'Z092', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A QUIMIOTERAPIA POR OTRAS AFECCIONES'},
{ codigo: 'Z093', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A PSICOTERAPIA'},
{ codigo: 'Z094', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO DE FRACTURA'},
{ codigo: 'Z097', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO COMBINADO POR OTRAS AFECCIONES'},
{ codigo: 'Z098', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO POR OTRAS AFECCIONES'},
{ codigo: 'Z099', descripcion: 'EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO NO ESPECIFICADO POR OTRAS AFECCIONES'},
{ codigo: 'Z10', descripcion: 'CONTROL GENERAL DE SALUD DE RUTINA DE SUBPOBLACIONES DEFINIDAS'},
{ codigo: 'Z100', descripcion: 'EXAMEN DE SALUD OCUPACIONAL'},
{ codigo: 'Z101', descripcion: 'CONTROL GENERAL DE SALUD DE RUTINA DE RESIDENTES DE INSTITUCIONES'},
{ codigo: 'Z102', descripcion: 'CONTROL GENERAL DE SALUD DE RUTINA A MIEMBROS DE LAS FUERZAS ARMADAS'},
{ codigo: 'Z103', descripcion: 'CONTROL GENERAL DE SALUD DE RUTINA A INTEGRANTES DE EQUIPOS DEPORTIVOS'},
{ codigo: 'Z108', descripcion: 'OTROS CONTROLES GENERALES DE SALUD DE RUTINA DE OTRAS SUBPOBLACIONES DEFINIDAS'},
{ codigo: 'Z11', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES INFECCIOSAS Y PARASITARIAS'},
{ codigo: 'Z110', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES INFECCIOSAS INTESTINALES'},
{ codigo: 'Z111', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUBERCULOSIS RESPIRATORIA'},
{ codigo: 'Z112', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES BACTERIANAS'},
{ codigo: 'Z113', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA INFECCIONES DE TRANSMISION PREDOMINANTEMENTE SEXUAL'},
{ codigo: 'Z114', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH)'},
{ codigo: 'Z115', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES VIRALES'},
{ codigo: 'Z116', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES POR PROTOZOARIOS Y HELMINTOS'},
{ codigo: 'Z118', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS'},
{ codigo: 'Z119', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADADES INFECCIOSAS Y PARASITARIAS NO ESPECIFICADAS'},
{ codigo: 'Z12', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMORES'},
{ codigo: 'Z120', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE ESTOMAGO'},
{ codigo: 'Z121', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DEL INTESTINO'},
{ codigo: 'Z122', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMORES DE ORGANOS RESPIRATORIOS'},
{ codigo: 'Z123', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA MAMA'},
{ codigo: 'Z124', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DEL CUELLO UTERINO'},
{ codigo: 'Z125', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA PROSTATA'},
{ codigo: 'Z126', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA VEJIGA'},
{ codigo: 'Z128', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMORES DE OTROS SITIOS'},
{ codigo: 'Z129', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE SITIO NO ESPECIFICADO'},
{ codigo: 'Z13', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES Y TRASTORNOS'},
{ codigo: 'Z130', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES DE LA SANGRE Y ORGANOS HEMATOPOYETICOS Y CIERTOS TRANSTORNOS DEL MECANISMO DE LA INMUNIDAD'},
{ codigo: 'Z131', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA DIABETES MELLITUS'},
{ codigo: 'Z132', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DE LA NUTRICION'},
{ codigo: 'Z133', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS MENTALES Y DEL COMPORTAMIENTO'},
{ codigo: 'Z134', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA CIERTOS TRASTORNOS DE DESARROLLO EN NI—O'},
{ codigo: 'Z135', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DEL OJO Y DEL OIDO'},
{ codigo: 'Z136', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS CARDIOVASCULARES'},
{ codigo: 'Z137', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA MALFORMACIONES CONGENITAS, DEFORMIDADES Y ANOMALIAS CROMOSOMICAS'},
{ codigo: 'Z138', descripcion: 'EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES Y TRASTORNOS ESPECIFICADOS'},
{ codigo: 'Z139', descripcion: 'EXAMEN DE PESQUISA ESPECIAL, NO ESPECIFICADO'},
{ codigo: 'Z20', descripcion: 'CONTACTO CON Y EXPOSICION A ENFERMEDADES TRANSMISIBLES'},
{ codigo: 'Z200', descripcion: 'CONTACTO CON Y EXPOSICION A ENFERMEDADES INFECCIOSAS INTESTINALES'},
{ codigo: 'Z201', descripcion: 'CONTACTO CON Y EXPOSICION A TUBERCULOSIS'},
{ codigo: 'Z202', descripcion: 'CONTACTO CON Y EXPOSICION A ENFERMEDADES INFECCIOSAS CON MODO DE TRANSMISION PREDOMINANTE SEXUAL'},
{ codigo: 'Z203', descripcion: 'CONTACTO CON Y EXPOSICION A RABIA'},
{ codigo: 'Z204', descripcion: 'CONTACTO CON Y EXPOSICION A RUBEOLA'},
{ codigo: 'Z205', descripcion: 'CONTACTO CON Y EXPOSICION A HEPATITIS VIRAL'},
{ codigo: 'Z206', descripcion: 'CONTACTO CON Y EXPOSICION AL VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH)'},
{ codigo: 'Z207', descripcion: 'CONTACTO CON Y EXPOSICION A PEDICULOSIS, ACARIASIS Y OTRAS INFESTACIONES'},
{ codigo: 'Z208', descripcion: 'CONTACTO CON Y EXPOSICION A OTRAS ENFERMEDADES TRANSMISIBLES'},
{ codigo: 'Z209', descripcion: 'CONTACTO CON Y EXPOSICION A ENFERMEDADES TRANSMISIBLES NO ESPECIFICADAS'},
{ codigo: 'Z21', descripcion: 'ESTADO DE INFECCION ASINTOMATICA POR EL VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH)'},
{ codigo: 'Z22', descripcion: 'PORTADOR DE ENFERMEDAD INFECCIOSA'},
{ codigo: 'Z220', descripcion: 'PORTADOR DE FIEBRE TIFOIDEA'},
{ codigo: 'Z221', descripcion: 'PORTADOR DE OTRAS ENFERMEDADES INFECCIOSAS INTESTINALES'},
{ codigo: 'Z222', descripcion: 'PORTADOR DE DIFTERIA'},
{ codigo: 'Z223', descripcion: 'PORTADOR DE OTRAS ENFERMEDADES BACTERIANAS ESPECIFICADAS'},
{ codigo: 'Z224', descripcion: 'PORTADOR DE ENFERMEDADES INFECCIOSAS CON UN MODO DE TRANSMISION PREDOMINANTEMENTE SEXUAL'},
{ codigo: 'Z225', descripcion: 'PORTADOR DE HEPATITIS VIRAL'},
{ codigo: 'Z226', descripcion: 'PORTADOR DE ENFERMEDAD INFECCIOSA DEBIDA AL VIRUS HUMANO T-LINFOTROPICO TIPO 1 (VHTL-1)'},
{ codigo: 'Z228', descripcion: 'PORTADOR DE OTRAS ENFERMEDADES INFECCIOSAS'},
{ codigo: 'Z229', descripcion: 'PORTADOR DE ENFERMEDAD INFECCIOSA NO ESPECIFICADA'},
{ codigo: 'Z23', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA ENFERMEDAD BACTERIANA UNICA'},
{ codigo: 'Z230', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA EL COLERA'},
{ codigo: 'Z231', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA LA TIFOIDEA-PARATIFOIDEA (TAB)'},
{ codigo: 'Z232', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA TUBERCULOSIS (BCG)'},
{ codigo: 'Z233', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA PESTE'},
{ codigo: 'Z234', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA TULAREMIA'},
{ codigo: 'Z235', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA EL TETANOS'},
{ codigo: 'Z236', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA LA DIFTERIA'},
{ codigo: 'Z237', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA LA TOS FERINA'},
{ codigo: 'Z238', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA OTRA ENFERMEDAD BACTERIANA'},
{ codigo: 'Z24', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA CIERTAS ENFERMEDADES VIRALES'},
{ codigo: 'Z240', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA POLIOMIELITIS'},
{ codigo: 'Z241', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA ENCEFALITIS VIRAL TRANSMITIDA POR ARTROPODOS'},
{ codigo: 'Z242', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA RABIA'},
{ codigo: 'Z243', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA FIEBRE AMARILLA'},
{ codigo: 'Z244', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA EL SARAMPION'},
{ codigo: 'Z245', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA LA RUBEOLA'},
{ codigo: 'Z246', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA HEPATITIS VIRAL'},
{ codigo: 'Z25', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA OTRAS ENFERMEDADES VIRALES UNICAS'},
{ codigo: 'Z250', descripcion: 'NECESIDAD DE INMUNIZACION SOLO CONTRA LA PAROTIDITIS'},
{ codigo: 'Z251', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA INFLUENZA (GRIPE)'},
{ codigo: 'Z258', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA OTRAS ENFERMEDADES VIRALES UNICAS ESPECIFICADAS'},
{ codigo: 'Z26', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA OTRAS ENFERMEDADES INFECCIOSAS UNICAS'},
{ codigo: 'Z260', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA LA LEISHMANIASIS'},
{ codigo: 'Z268', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA OTRAS ENFERMEDADES INFECCIOSAS UNICAS ESPECIFICADAS'},
{ codigo: 'Z269', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA ENFERMEDAD INFECCIOSA NO ESPECIFICADA'},
{ codigo: 'Z27', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA COMBINACIONES DE ENFERMEDADES INFECCIOSAS'},
{ codigo: 'Z270', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA EL COLERA Y LA TIFOIDEAPARATIFOIDEA (COLERA + TAB)'},
{ codigo: 'Z271', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA DIFTERIA-PERTUSSIS-TETANOS COMBINADOS (DPT)'},
{ codigo: 'Z272', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA DIFTERIA-PERTUSSIS-TETANOS Y TIFOIDEA-PARATIFOIDEA (DPT + TAB)'},
{ codigo: 'Z273', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA DIFTERIA-PERTUSSIS-TETANOS Y POLIOMIELITIS (DPT+POLIO)'},
{ codigo: 'Z274', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA SARAMPION-PAROTIDITIS-RUBEOLA (SPR)(MMR)'},
{ codigo: 'Z278', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA OTRAS COMBINACIONES DE ENFERMEDADES INFECCIOSAS'},
{ codigo: 'Z279', descripcion: 'NECESIDAD DE INMUNIZACION CONTRA COMBINACIONES NO ESPECIFICADAS DE ENFERMEDADES INFECCIOSAS'},
{ codigo: 'Z28', descripcion: 'INMUNIZACION NO REALIZADA'},
{ codigo: 'Z280', descripcion: 'INMUNIZACION NO REALIZADA POR CONTRAINDICACION'},
{ codigo: 'Z281', descripcion: 'INMUNIZACION NO REALIZADA POR DECISION DEL PACIENTE, POR MOTIVOS DE CREENCIA O PRESION DE GRUPO'},
{ codigo: 'Z282', descripcion: 'INMUNIZACION NO REALIZADA POR DECISION DEL PACIENTE, POR OTRAS RAZONES Y LAS NO ESPECIFICADAS'},
{ codigo: 'Z288', descripcion: 'INMUNIZACION NO REALIZADA POR OTRAS RAZONES'},
{ codigo: 'Z289', descripcion: 'INMUNIZACION NO REALIZADA POR RAZON NO ESPECIFICADA'},
{ codigo: 'Z29', descripcion: 'NECESIDAD DE OTRAS MEDIDAS PROFILACTICAS'},
{ codigo: 'Z290', descripcion: 'AISLAMIENTO'},
{ codigo: 'Z291', descripcion: 'INMUNOTERAPIA PROFILACTICA'},
{ codigo: 'Z292', descripcion: 'OTRA QUIMIOTERAPIA PROFILACTICA'},
{ codigo: 'Z298', descripcion: 'OTRAS MEDIDAS PROFILATICAS ESPECIFICADAS'},
{ codigo: 'Z299', descripcion: 'MEDIDA PROFILACTICA NO ESPECIFICADA'},
{ codigo: 'Z30', descripcion: 'ATENCION PARA LA ANTICONCEPCION'},
{ codigo: 'Z300', descripcion: 'CONSEJO Y ASESORAMIENTO GENERAL SOBRE LA ANTICONCEPCION'},
{ codigo: 'Z301', descripcion: 'INSERCION DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)'},
{ codigo: 'Z302', descripcion: 'ESTERILIZACION'},
{ codigo: 'Z303', descripcion: 'EXTRACCION MENSTRUAL'},
{ codigo: 'Z304', descripcion: 'SUPERVISION DEL USO DE DROGAS ANTICONCEPTIVAS'},
{ codigo: 'Z305', descripcion: 'SUPERVISION DEL USO DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)'},
{ codigo: 'Z308', descripcion: 'OTRAS ATENCIONES ESPECIFICADAS PARA LA ANTICONCEPCION'},
{ codigo: 'Z309', descripcion: 'ASISTENCIA PARA LA ANTICONCEPCION, NO ESPECIFICADA'},
{ codigo: 'Z31', descripcion: 'ATENCION PARA LA PROCREACION'},
{ codigo: 'Z310', descripcion: 'TUBOPLASTIA O VASOPLASTIA POSTERIOR A ESTERILIZACION'},
{ codigo: 'Z311', descripcion: 'INSEMINACION ARTIFICIAL'},
{ codigo: 'Z312', descripcion: 'FECUNDACION IN VITRO'},
{ codigo: 'Z313', descripcion: 'OTROS METODOS DE ATENCION PARA LA FECUNDACION'},
{ codigo: 'Z314', descripcion: 'INVESTIGACION Y PRUEBA PARA LA PROCREACION'},
{ codigo: 'Z315', descripcion: 'ASESORAMIENTO GENETICO'},
{ codigo: 'Z316', descripcion: 'CONSEJO Y ASESORAMIENTO GENERAL SOBRE LA PROCREACION'},
{ codigo: 'Z318', descripcion: 'OTRA ATENCION ESPECIFICADA PARA LA PROCREACION'},
{ codigo: 'Z319', descripcion: 'ATENCION NO ESPECIFICADA RELACIONADA CON LA PROCREACION'},
{ codigo: 'Z32', descripcion: 'EXAMEN Y PRUEBA DEL EMBARAZO'},
{ codigo: 'Z320', descripcion: 'EMBARAZO (AUN) NO CONFIRMADO'},
{ codigo: 'Z321', descripcion: 'EMBARAZO CONFIRMADO'},
{ codigo: 'Z33', descripcion: 'ESTADO DE EMBARAZO, INCIDENTAL'},
{ codigo: 'Z34', descripcion: 'SUPERVISION DE EMBARAZO NORMAL'},
{ codigo: 'Z340', descripcion: 'SUPERVISION DE PRIMER EMBARAZO NORMAL'},
{ codigo: 'Z348', descripcion: 'SUPERVISION DE OTROS EMBARAZOS NORMALES'},
{ codigo: 'Z349', descripcion: 'SUPERVISION DE EMBARAZO NORMAL NO ESPECIFICADO'},
{ codigo: 'Z35', descripcion: 'SUPERVISION DE EMBARAZO DE ALTO RIESGO'},
{ codigo: 'Z350', descripcion: 'SUPERVISION DE EMBARAZO CON HISTORIA DE ESTERILIDAD'},
{ codigo: 'Z351', descripcion: 'SUPERVISION DE EMBARAZO CON HISTORIA DE ABORTO'},
{ codigo: 'Z352', descripcion: 'SUPERVISION EMBARAZO OTRO RIESGO EN HISTORIA OBSTETRICA O REPRODUCTIVA'},
{ codigo: 'Z353', descripcion: 'SUPERVISION DE EMBARAZO CON HISTORIA DE INSUFICIENTE ATENCION PRENATAL'},
{ codigo: 'Z354', descripcion: 'SUPERVISION DE EMBARAZO CON GRAN MULTIPARIDAD'},
{ codigo: 'Z355', descripcion: 'SUPERVISION DE PRIMIGESTA A—OSA'},
{ codigo: 'Z356', descripcion: 'SUPERVISION DE PRIMIGESTA MUY JOVEN'},
{ codigo: 'Z357', descripcion: 'SUPERVISION DE EMBARAZO DE ALTO RIESGO DEBIDO A PROBLEMAS SOCIALES'},
{ codigo: 'Z358', descripcion: 'SUPERVISION DE OTROS EMBARAZOS DE ALTO RIESGO'},
{ codigo: 'Z359', descripcion: 'SUPERVISION DE EMBARAZO DE ALTO RIESGO, SIN OTRA ESPECIFICACION'},
{ codigo: 'Z36', descripcion: 'PESQUISAS PRENATALES'},
{ codigo: 'Z360', descripcion: 'PESQUISA PRENATAL PARA ANOMALIAS CROMOSOMICAS'},
{ codigo: 'Z361', descripcion: 'PESQUISA PRENATAL PARA MEDIR NIVELES ELEVADOS DE ALFAFETOPROTEINAS'},
{ codigo: 'Z362', descripcion: 'OTRAS PESQUISAS PRENATALES BASADAS EN AMNIOCENTESIS'},
{ codigo: 'Z363', descripcion: 'PESQUISA PRENATAL DE MALFORMACIONES USANDO ULTRASONIDO Y OTROS METODOS FISICOS'},
{ codigo: 'Z364', descripcion: 'PESQUISA PRENATAL DEL RETARDO DEL CRECIMIENTO FETAL USANDO ULTRASONIDO Y OTROS METODOS FISICOS'},
{ codigo: 'Z365', descripcion: 'PESQUISA PRENATAL PARA ISOINMUNIZACION'},
{ codigo: 'Z368', descripcion: 'OTRAS PESQUISAS PRENATALES ESPECIFICAS'},
{ codigo: 'Z369', descripcion: 'PESQUISA PRENATAL, SIN OTRA ESPECIFICACION'},
{ codigo: 'Z37', descripcion: 'PRODUCTO DEL PARTO'},
{ codigo: 'Z370', descripcion: 'NACIDO VIVO, UNICO'},
{ codigo: 'Z371', descripcion: 'NACIDO MUERTO, UNICO'},
{ codigo: 'Z372', descripcion: 'GEMELOS, AMBOS NACIDOS VIVOS'},
{ codigo: 'Z373', descripcion: 'GEMELOS, UN NACIDO VIVO Y UN NACIDO MUERTO'},
{ codigo: 'Z374', descripcion: 'GEMELOS, AMBOS NACIDOS MUERTOS'},
{ codigo: 'Z375', descripcion: 'OTROS NACIMIENTOS MULTIPLES, TODOS NACIDOS VIVOS'},
{ codigo: 'Z376', descripcion: 'OTROS NACIMIENTOS MULTIPLES, ALGUNOS NACIDOS VIVOS'},
{ codigo: 'Z377', descripcion: 'OTROS NACIMIENTOS MULTIPLES, TODOS NACIDOS MUERTOS'},
{ codigo: 'Z379', descripcion: 'PRODUCTO DEL PARTO NO ESPECIFICADO'},
{ codigo: 'Z38', descripcion: 'NACIDOS VIVOS SEGUN LUGAR DE NACIMIENTO'},
{ codigo: 'Z380', descripcion: 'PRODUCTO UNICO, NACIDO EN HOSPITAL'},
{ codigo: 'Z381', descripcion: 'PRODUCTO UNICO, NACIDO FUERA DE HOSPITAL'},
{ codigo: 'Z382', descripcion: 'PRODUCTO UNICO, LUGAR DE NACIMIENTO NO ESPECIFICADO'},
{ codigo: 'Z383', descripcion: 'GEMELOS, NACIDOS EN HOSPITAL'},
{ codigo: 'Z384', descripcion: 'GEMELOS, NACIDOS FUERA DE HOSPITAL'},
{ codigo: 'Z385', descripcion: 'GEMELOS, LUGAR DE NACIMIENTO NO ESPECIFICADO'},
{ codigo: 'Z386', descripcion: 'OTROS NACIMIENTOS MULTIPLES, EN HOSPITAL'},
{ codigo: 'Z387', descripcion: 'OTROS NACIMIENTOS MULTIPLES, FUERA DEL HOSPITAL'},
{ codigo: 'Z388', descripcion: 'OTROS NACIMIENTOS MULTIPLES, LUGAR DE NACIMIENTO NO ESPECIFICADO'},
{ codigo: 'Z39', descripcion: 'EXAMEN Y ATENCION DEL POSTPARTO'},
{ codigo: 'Z390', descripcion: 'ATENCION Y EXAMEN INMEDIATAMENTE DESPUES DEL PARTO'},
{ codigo: 'Z391', descripcion: 'ATENCION Y EXAMEN DE MADRE EN PERIODO DE LACTANCIA'},
{ codigo: 'Z392', descripcion: 'SEGUIMIENTO POSTPARTO, DE RUTINA'},
{ codigo: 'Z40', descripcion: 'CIRUGIA PROFILACTICA'},
{ codigo: 'Z400', descripcion: 'CIRUGIA PROFILACTICA POR FACTORES DE RIESGO RELACIONADOS CON TUMORES MALIGNOS'},
{ codigo: 'Z408', descripcion: 'OTRA CIRUGIA PROFILACTICA'},
{ codigo: 'Z409', descripcion: 'CIRUGIA PROFILACTICA NO ESPECIFICADA'},
{ codigo: 'Z41', descripcion: 'PROCEDIMIENTOS PARA OTROS PROPOSITOS QUE NO SEAN MEJORAR EL ESTADO DE SALUD'},
{ codigo: 'Z410', descripcion: 'TRASPLANTE DE PELO'},
{ codigo: 'Z411', descripcion: 'OTRAS CIRUGIAS PLASTICAS POR RAZONES ESTETICAS'},
{ codigo: 'Z412', descripcion: 'CIRCUNCISION RITUAL O DE RUTINA'},
{ codigo: 'Z413', descripcion: 'PERFORACION DE LA OREJA'},
{ codigo: 'Z418', descripcion: 'OTROS PROCEDIMIENTOS PARA OTROS PROPOSITOS QUE NO SEAN MEJORAR EL ESTADO DE SALUD'},
{ codigo: 'Z419', descripcion: 'PROCEDIMIENTO NO ESPECIFICADO PARA OTROS PROPOSITOS QUE NO SEAN MEJORAR EL ESTADO DE SALUD'},
{ codigo: 'Z42', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA'},
{ codigo: 'Z420', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE LA CABEZA Y DEL CUELLO'},
{ codigo: 'Z421', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE LA MAMA'},
{ codigo: 'Z422', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE OTRAS PARTES ESPECIFICAS DEL TRONCO'},
{ codigo: 'Z423', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE EXTREMIDADES SUPERIORES'},
{ codigo: 'Z424', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE EXTREMIDADES INFERIORES'},
{ codigo: 'Z428', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE OTRAS PARTES ESPECIFICAS DEL CUERPO'},
{ codigo: 'Z429', descripcion: 'CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA NO ESPECIFICADA'},
{ codigo: 'Z43', descripcion: 'ATENCION DE ORIFICIOS ARTIFICIALES'},
{ codigo: 'Z430', descripcion: 'ATENCION DE TRAQUEOSTOMIA'},
{ codigo: 'Z431', descripcion: 'ATENCION DE GASTROSTOMIA'},
{ codigo: 'Z432', descripcion: 'ATENCION DE ILEOSTOMIA'},
{ codigo: 'Z433', descripcion: 'ATENCION DE COLOSTOMIA'},
{ codigo: 'Z434', descripcion: 'ATENCION DE OTROS ORIFICIOS ARTIFICIALES DE LAS VIAS DIGESTIVAS'},
{ codigo: 'Z435', descripcion: 'ATENCION DE CISTOSTOMIA'},
{ codigo: 'Z436', descripcion: 'ATENCION DE OTROS ORIFICIOS ARTIFICIALES DE LAS VIAS URINARIAS'},
{ codigo: 'Z437', descripcion: 'ATENCION DE VAGINA ARTIFICIAL'},
{ codigo: 'Z438', descripcion: 'ATENCION DE OTROS ORIFICIOS ARTIFICIALES'},
{ codigo: 'Z439', descripcion: 'ATENCION DE ORIFICIO ARTIFICIAL NO ESPECIFICADO'},
{ codigo: 'Z44', descripcion: 'PRUEBA Y AJUSTE DE DISPOSITIVOS PROTESICOS EXTERNOS'},
{ codigo: 'Z440', descripcion: 'PRUEBA Y AJUSTE DE BRAZO ARTIFICIAL (COMPLETO) (PARCIAL)'},
{ codigo: 'Z441', descripcion: 'PRUEBA Y AJUSTE DE PIERNA ARTIFICIAL (COMPLETA) (PARCIAL)'},
{ codigo: 'Z442', descripcion: 'PRUEBA Y AJUSTE DE OJO ARTIFICIAL'},
{ codigo: 'Z443', descripcion: 'PRUEBA Y AJUSTE DE PROTESIS MAMARIA EXTERNA'},
{ codigo: 'Z448', descripcion: 'PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS PROTESICOS EXTERNOS'},
{ codigo: 'Z449', descripcion: 'PRUEBA Y AJUSTE DE DISPOSITIVO PROTESICO EXTERNO NO ESPECIFICADO'},
{ codigo: 'Z45', descripcion: 'ASISTENCIA Y AJUSTE DE DISPOSITIVOS IMPLANTADOS'},
{ codigo: 'Z450', descripcion: 'ASISTENCIA Y AJUSTE DE MARCAPASO CARDIACO'},
{ codigo: 'Z451', descripcion: 'ASISTENCIA Y AJUSTE DE BOMBA DE INFUSION'},
{ codigo: 'Z452', descripcion: 'ASISTENCIA Y AJUSTE DE DISPOSITIVOS DE ACCESO VASCULAR'},
{ codigo: 'Z453', descripcion: 'ASISTENCIA Y AJUSTE DE DISPOSITIVO AUDITIVO IMPLANTADO'},
{ codigo: 'Z458', descripcion: 'ASISTENCIA Y AJUSTE DE OTROS DISPOSITIVOS IMPLANTADOS'},
{ codigo: 'Z459', descripcion: 'ASISTENCIA Y AJUSTE DE DISPOSITIVO IMPLANTADO NO ESPECIFICADO'},
{ codigo: 'Z46', descripcion: 'PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS'},
{ codigo: 'Z460', descripcion: 'PRUEBA Y AJUSTE DE ANTEOJOS Y LENTES DE CONTACTO'},
{ codigo: 'Z461', descripcion: 'PRUEBA Y AJUSTE DE AUDIFONOS'},
{ codigo: 'Z462', descripcion: 'PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS RELACIONADOS CON EL SISTEMA NERVIOSO Y LOS SENTIDOS ESPECIALES'},
{ codigo: 'Z463', descripcion: 'PRUEBA Y AJUSTE DE PROTESIS DENTAL'},
{ codigo: 'Z464', descripcion: 'PRUEBA Y AJUSTE DE DISPOSITIVO ORTODONCICO'},
{ codigo: 'Z465', descripcion: 'PRUEBA Y AJUSTE DE ILEOSTOMIA U OTRO DISPOSITIVO INTESTINAL'},
{ codigo: 'Z466', descripcion: 'PRUEBA Y AJUSTE DE DISPOSITIVO URINARIO'},
{ codigo: 'Z467', descripcion: 'PRUEBA Y AJUSTE DE DISPOSITIVO ORTOPEDICO'},
{ codigo: 'Z468', descripcion: 'PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS ESPECIFICADOS'},
{ codigo: 'Z469', descripcion: 'PRUEBA Y AJUSTE DE DISPOSITIVO NO ESPECIFICADO'},
{ codigo: 'Z47', descripcion: 'OTROS CUIDADOS POSTERIORES A LA ORTOPEDIA'},
{ codigo: 'Z470', descripcion: 'CUIDADOS POSTERIORES A LA EXTRACCION DE PLACA U OTRO DISPOSITIVO DE FIJACION INTERNA EN FRACTURA'},
{ codigo: 'Z478', descripcion: 'OTROS CUIDADOS ESPECIFICADOS POSTERIORES A LA ORTOPEDIA'},
{ codigo: 'Z479', descripcion: 'CUIDADO POSTERIOR A LA ORTOPEDIA, NO ESPECIFICADO'},
{ codigo: 'Z48', descripcion: 'OTROS CUIDADOS POSTERIORES A LA CIRUGIA'},
{ codigo: 'Z480', descripcion: 'ATENCION DE LOS APOSITOS Y SUTURAS'},
{ codigo: 'Z488', descripcion: 'OTROS CUIDADOS ESPECIFICADOS POSTERIORES A LA CIRUGIA'},
{ codigo: 'Z489', descripcion: 'CUIDADO POSTERIOR A LA CIRUGIA, NO ESPECIFICADO'},
{ codigo: 'Z49', descripcion: 'CUIDADOS RELATIVOS AL PROCEDIMIENTO DE DIALISIS'},
{ codigo: 'Z490', descripcion: 'CUIDADOS PREPARATORIOS PARA DIALISIS'},
{ codigo: 'Z491', descripcion: 'DIALISIS EXTRACORPOREA'},
{ codigo: 'Z492', descripcion: 'OTRAS DIALISIS'},
{ codigo: 'Z50', descripcion: 'ATENCION POR EL USO DE PROCEDIMIENTOS DE REHABILITACION'},
{ codigo: 'Z500', descripcion: 'REHABILITACION CARDIACA'},
{ codigo: 'Z501', descripcion: 'OTRAS TERAPIAS FISICAS'},
{ codigo: 'Z502', descripcion: 'REHABILITACION DEL ALCOHOLICO'},
{ codigo: 'Z503', descripcion: 'REHABILITACION DEL DROGADICTO'},
{ codigo: 'Z504', descripcion: 'PSICOTERAPIA, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'Z505', descripcion: 'TERAPIA DEL LENGUAJE'},
{ codigo: 'Z506', descripcion: 'ADIESTRAMIENTO ORTOPTICO'},
{ codigo: 'Z507', descripcion: 'TERAPIA OCUPACIONAL Y REHABILITACION VOCACIONAL, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'Z508', descripcion: 'ATENCION POR OTROS PROCEDIMIENTOS DE REHABILITACION'},
{ codigo: 'Z509', descripcion: 'ATENCION POR PROCEDIMIENTO DE REHABILITACION, NO ESPECIFICADA'},
{ codigo: 'Z51', descripcion: 'OTRA ATENCION MEDICA'},
{ codigo: 'Z510', descripcion: 'SESION DE RADIOTERAPIA'},
{ codigo: 'Z511', descripcion: 'SESION DE QUIMIOTERAPIA POR TUMOR'},
{ codigo: 'Z512', descripcion: 'OTRA QUIMIOTERAPIA'},
{ codigo: 'Z513', descripcion: 'TRANSFUSION DE SANGRE, SIN DIAGNOSTICO INFORMADO'},
{ codigo: 'Z514', descripcion: 'ATENCION PREPARATORIA PARA TRATAMIENTO SUBSECUENTE, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'Z515', descripcion: 'ATENCION PALIATIVA'},
{ codigo: 'Z516', descripcion: 'DESENSIBILIZACION A ALERGENOS'},
{ codigo: 'Z518', descripcion: 'OTRAS ATENCIONES MEDICAS ESPECIFICADAS'},
{ codigo: 'Z519', descripcion: 'ATENCION MEDICA, NO ESPECIFICADA'},
{ codigo: 'Z52', descripcion: 'DONANTES DE ORGANOS Y TEJIDOS'},
{ codigo: 'Z520', descripcion: 'DONANTE DE SANGRE'},
{ codigo: 'Z521', descripcion: 'DONANTE DE PIEL'},
{ codigo: 'Z522', descripcion: 'DONANTE DE HUESO'},
{ codigo: 'Z523', descripcion: 'DONANTE DE MEDULA OSEA'},
{ codigo: 'Z524', descripcion: 'DONANTE DE RI—ON'},
{ codigo: 'Z525', descripcion: 'DONANTE DE CORNEA'},
{ codigo: 'Z528', descripcion: 'DONANTE DE OTROS ORGANOS O TEJIDOS'},
{ codigo: 'Z529', descripcion: 'DONANTE DE ORGANO O TEJIDO NO ESPECIFICADO'},
{ codigo: 'Z53', descripcion: 'PERSONA EN CONTACTO CON SERVICIOS DE SALUD PARA PROCEDIMIENTOS ESPECIFICOS NO REALIZADOS'},
{ codigo: 'Z530', descripcion: 'PROCEDIMIENTO NO REALIZADO POR CONTRAINDICACION'},
{ codigo: 'Z531', descripcion: 'PROCEDIMIENTO NO REALIZADO POR DECISION DEL PACIENTE, POR RAZONES DE CREENCIA O PRESION DE GRUPO'},
{ codigo: 'Z532', descripcion: 'PROCEDIMIENTO NO REALIZADO POR DECISION DEL PACIENTE, POR OTRAS RAZONES Y LAS NO ESPECIFICADAS'},
{ codigo: 'Z538', descripcion: 'PROCEDIMIENTO NO REALIZADO POR OTRAS RAZONES'},
{ codigo: 'Z539', descripcion: 'PROCEDIMIENTO NO REALIZADO POR RAZON NO ESPECIFICADA'},
{ codigo: 'Z54', descripcion: 'CONVALECENCIA'},
{ codigo: 'Z540', descripcion: 'CONVALECENCIA CONSECUTIVA A CIRUGIA'},
{ codigo: 'Z541', descripcion: 'CONVALECENCIA CONSECUTIVA A RADIOTERAPIA'},
{ codigo: 'Z542', descripcion: 'CONVALECENCIA CONSECUTIVA A QUIMIOTERAPIA'},
{ codigo: 'Z543', descripcion: 'CONVALECENCIA CONSECUTIVA A PSICOTERAPIA'},
{ codigo: 'Z544', descripcion: 'CONVALECENCIA CONSECUTIVA A TRATAMIENTO DE FRACTURA'},
{ codigo: 'Z547', descripcion: 'CONVALECENCIA CONSECUTIVA A TRATAMIENTO COMBINADO'},
{ codigo: 'Z548', descripcion: 'CONVALECENCIA CONSECUTIVA A OTROS TRATAMIENTOS'},
{ codigo: 'Z549', descripcion: 'CONVALECENCIA CONSECUTIVA A TRATAMIENTO NO ESPECIFICADO'},
{ codigo: 'Z55', descripcion: 'PROBLEMAS RELACIONADOS CON LA EDUCACION Y LA ALFABETIZACION'},
{ codigo: 'Z550', descripcion: 'PROBLEMAS RELACIONADOS CON ANALFABETISMO O BAJO NIVEL DE INSTRUCCION'},
{ codigo: 'Z551', descripcion: 'PROBLEMAS RELACIONADOS CON LA EDUCACION NO DISPONIBLE O INACCESIBLE'},
{ codigo: 'Z552', descripcion: 'PROBLEMAS RELACIONADOS CON LA FALLA EN LOS EXAMENES'},
{ codigo: 'Z553', descripcion: 'PROBLEMAS RELACIONADOS CON EL BAJO RENDIMIENTO ESCOLAR'},
{ codigo: 'Z554', descripcion: 'PROBLEMAS RELACIONADOS CON LA INADAPTACION EDUCACIONAL Y DESAVENENCIAS CON MAESTROS Y COMPA—EROS'},
{ codigo: 'Z558', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON LA EDUCACION Y LA ALFABETIZACION'},
{ codigo: 'Z559', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON LA EDUCACION Y ALFABETIZACION'},
{ codigo: 'Z56', descripcion: 'PROBLEMAS RELACIONADOS CON EL EMPLEO Y EL DESEMPLEO'},
{ codigo: 'Z560', descripcion: 'PROBLEMAS RELACIONADOS CON EL DESEMPLEO, NO ESPECIFICADOS'},
{ codigo: 'Z561', descripcion: 'PROBLEMAS RELACIONADOS CON EL CAMBIO DE EMPLEO'},
{ codigo: 'Z562', descripcion: 'PROBLEMAS RELACIONADOS CON AMENAZA DE PERDIDA DEL EMPLEO'},
{ codigo: 'Z563', descripcion: 'PROBLEMAS RELACIONADOS CON HORARIO ESTRESANTE DE TRABAJO'},
{ codigo: 'Z564', descripcion: 'PROBLEMAS RELACIONADOS CON DESAVENENCIAS CON EL JEFE Y COMPA—EROS DE TRABAJO'},
{ codigo: 'Z565', descripcion: 'PROBLEMAS RELACIONADOS CON EL TRABAJO INCOMPATIBLE'},
{ codigo: 'Z566', descripcion: 'OTROS PROBLEMAS DE TENSION FISICA O MENTAL RELACIONADAS CON EL TRABAJO'},
{ codigo: 'Z567', descripcion: 'OTROS PROBLEMAS Y LOS NO ESPECIFICADOS RELACIONADOS CON EL EMPLEO'},
{ codigo: 'Z57', descripcion: 'EXPOSICION A FACTORES DE RIESGO OCUPACIONAL'},
{ codigo: 'Z570', descripcion: 'EXPOSICION OCUPACIONAL AL RUIDO'},
{ codigo: 'Z571', descripcion: 'EXPOSICION OCUPACIONAL A LA RADIACION'},
{ codigo: 'Z572', descripcion: 'EXPOSICION OCUPACIONAL AL POLVO'},
{ codigo: 'Z573', descripcion: 'EXPOSICION OCUPACIONAL A OTRO CONTAMINANTE DEL AIRE'},
{ codigo: 'Z574', descripcion: 'EXPOSICION OCUPACIONAL A AGENTES TOXICOS EN AGRICULTURA'},
{ codigo: 'Z575', descripcion: 'EXPOSICION OCUPACIONAL A AGENTES TOXICOS EN OTRAS INDUSTRIAS'},
{ codigo: 'Z576', descripcion: 'EXPOSICION OCUPACIONAL A TEMPERATURA EXTREMA'},
{ codigo: 'Z577', descripcion: 'EXPOSICION OCUPACIONAL A LA VIBRACION'},
{ codigo: 'Z578', descripcion: 'EXPOSICION OCUPACIONAL A OTROS FACTORES DE RIESGO'},
{ codigo: 'Z579', descripcion: 'EXPOSICION OCUPACIONAL A FACTOR DE RIESGO NO ESPECIFICADO'},
{ codigo: 'Z58', descripcion: 'PROBLEMAS RELACIONADOS CON EL AMBIENTE FISICO'},
{ codigo: 'Z580', descripcion: 'EXPOSICION AL RUIDO'},
{ codigo: 'Z581', descripcion: 'EXPOSICION AL AIRE CONTAMINADO'},
{ codigo: 'Z582', descripcion: 'EXPOSICION AL AGUA CONTAMINADA'},
{ codigo: 'Z583', descripcion: 'EXPOSICION AL SUELO CONTAMINADO'},
{ codigo: 'Z584', descripcion: 'EXPOSICION A LA RADIACION'},
{ codigo: 'Z585', descripcion: 'EXPOSICION A OTRAS CONTAMINACIONES DEL AMBIENTE FISICO'},
{ codigo: 'Z586', descripcion: 'SUMINISTRO INADECUADO DE AGUA POTABLE'},
{ codigo: 'Z588', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON EL AMBIENTE FISICO'},
{ codigo: 'Z589', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON EL AMBIENTE FISICO'},
{ codigo: 'Z59', descripcion: 'PROBLEMAS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONOMICAS'},
{ codigo: 'Z590', descripcion: 'PROBLEMAS RELACIONADOS CON LA FALTA DE VIVIENDA'},
{ codigo: 'Z591', descripcion: 'PROBLEMAS RELACIONADOS CON VIVIENDA INADECUADA'},
{ codigo: 'Z592', descripcion: 'PROBLEMAS CASEROS Y CON VECINOS E INQUILINOS'},
{ codigo: 'Z593', descripcion: 'PROBLEMAS RELACIONADOS CON PERSONA QUE RESIDE EN UNA INSTITUCION'},
{ codigo: 'Z594', descripcion: 'PROBLEMAS RELACIONADOS CON LA FALTA DE ALIMENTOS ADECUADOS'},
{ codigo: 'Z595', descripcion: 'PROBLEMAS RELACIONADOS CON POBREZA EXTREMA'},
{ codigo: 'Z596', descripcion: 'PROBLEMAS RELACIONADOS CON BAJOS INGRESOS'},
{ codigo: 'Z597', descripcion: 'PROBLEMAS RELACIONADOS CON SEGURIDAD SOCIAL Y SOSTENIMIENTO INSUFICIENTE PARA EL BIENESTAR'},
{ codigo: 'Z598', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONOMICAS'},
{ codigo: 'Z599', descripcion: 'PROBLEMAS NO ESPECIFICADOS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONOMICAS'},
{ codigo: 'Z60', descripcion: 'PROBLEMAS RELACIONADOS CON EL AMBIENTE SOCIAL'},
{ codigo: 'Z600', descripcion: 'PROBLEMAS RELACIONADOS CON EL AJUSTE A TRANSICIONES DEL CICLO VITAL'},
{ codigo: 'Z601', descripcion: 'PROBLEMAS RELACIONADOS CON SITUACION FAMILIAR ATIPICA'},
{ codigo: 'Z602', descripcion: 'PROBLEMAS RELACIONADOS CON PERSONA QUE VIVE SOLA'},
{ codigo: 'Z603', descripcion: 'PROBLEMAS RELACIONADOS CON LA ADAPTACION CULTURAL'},
{ codigo: 'Z604', descripcion: 'PROBLEMAS RELACIONADOS CON EXCLUSION Y RECHAZO SOCIAL'},
{ codigo: 'Z605', descripcion: 'PROBLEMAS RELACIONADOS CON LA DISCRIMINACION Y PERSECUCION PERCIBIDAS'},
{ codigo: 'Z608', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON EL AMBIENTE SOCIAL'},
{ codigo: 'Z609', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON EL AMBIENTE SOCIAL'},
{ codigo: 'Z61', descripcion: 'PROBLEMAS RELACIONADOS CON HECHOS NEGATIVOS EN LA NI—EZ'},
{ codigo: 'Z610', descripcion: 'PROBLEMAS RELACIONADOS CON LA PERDIDA DE RELACION AFECTIVA EN INFANCIA'},
{ codigo: 'Z611', descripcion: 'PROBLEMAS RELACIONADOS CON EL ALEJAMIENTO DEL HOGAR EN LA INFANCIA'},
{ codigo: 'Z612', descripcion: 'PROBLEMAS RELACIONADOS CON ALTERACION EN EL PATRON DE LA RELACION FAMILIAR EN LA INFANCIA'},
{ codigo: 'Z613', descripcion: 'PROBLEMAS RELACIONADOS CON EVENTOS QUE LLEVARON A PERDIDA DE LA AUTESTIMA EN INFANCIA'},
{ codigo: 'Z614', descripcion: 'PROBLEMAS RELACIONADOS CON EL ABUSO SEXUAL DEL NI—O POR PERSONA DENTRO DEL GRUPO DE APOYO PRIMARIO'},
{ codigo: 'Z615', descripcion: 'PROBLEMAS RELACIONADOS CON EL ABUSO SEXUAL DEL NI—O POR PERSONA AJENA AL GRUPO DE APOYO PRIMARIO'},
{ codigo: 'Z616', descripcion: 'PROBLEMAS RELACIONADOS CON ABUSO FISICO DEL NI—O'},
{ codigo: 'Z617', descripcion: 'PROBLEMAS RELACIONADOS CON EXPERIENCIAS PERSONALES ATEMORIZANTES EN INFANCIA'},
{ codigo: 'Z618', descripcion: 'PROBLEMAS RELACIONADOS CON OTRAS EXPERIENCIAS NEGATIVAS EN LA INFANCIA'},
{ codigo: 'Z619', descripcion: 'PROBLEMAS RELACIONADOS CON EXPERIENCIA NEGATIVA NO ESPECIFICADA EN LA INFANCIA'},
{ codigo: 'Z62', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON LA CRIANZA DEL NI—O'},
{ codigo: 'Z620', descripcion: 'PROBLEMAS RELACIONADOS CON SUPERVISION O EL CONTROL INADECUADOS DE LOS PADRES'},
{ codigo: 'Z621', descripcion: 'PROBLEMAS RELACIONADOS CON LA SOBREPROTECCION DE LOS PADRES'},
{ codigo: 'Z622', descripcion: 'PROBLEMAS RELACIONADOS CON LA CRIANZA EN INSTITUCION'},
{ codigo: 'Z623', descripcion: 'PROBLEMAS RELACIONADOS CON HOSTILIDAD Y REPROBACION AL NI—O'},
{ codigo: 'Z624', descripcion: 'PROBLEMAS RELACIONADOS CON EL ABANDONO EMOCIONAL DEL NI—O'},
{ codigo: 'Z625', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON NEGLIGENCIA EN LA CRIANZA DEL NI—O'},
{ codigo: 'Z626', descripcion: 'PROBLEMAS RELACIONADOS CON PRESIONES INAPROPIADAS DE LOS PADRES Y OTRAS ANORMALIDADES EN LA CALIDAD DE LA CRIANZA'},
{ codigo: 'Z628', descripcion: 'OTROS PROBLEMAS ESPECIFICADOS Y RELACIONADOS CON LA CRIANZA DEL NI—O'},
{ codigo: 'Z629', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON LA CRIANZA DEL NI—O'},
{ codigo: 'Z63', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON EL GRUPO PRIMARIO DE APOYO INCLUSIVE CIRCUNSTANCIAS FAMILIARES'},
{ codigo: 'Z630', descripcion: 'PROBLEMAS EN LA RELACION ENTRE ESPOSOS O PAREJA'},
{ codigo: 'Z631', descripcion: 'PROBLEMAS EN LA RELACION CON LOS PADRES Y LOS FAMILIARES POLITICOS'},
{ codigo: 'Z632', descripcion: 'PROBLEMAS RELACIONADOS CON EL APOYO FAMILIAR INADECUADO'},
{ codigo: 'Z633', descripcion: 'PROBLEMAS RELACIONADOS CON LA AUSENCIA DE UN MIEMBRO DE LA FAMILIA'},
{ codigo: 'Z634', descripcion: 'PROBLEMAS RELACIONADOS CON LA DESAPARICION O MUERTE DE UN MIEMBRO DE LA FAMILIA'},
{ codigo: 'Z635', descripcion: 'PROBLEMAS RELACIONADOS CON RUPTURA FAMILIAR POR SEPARACION O DIVORCIO'},
{ codigo: 'Z636', descripcion: 'PROBLEMAS RELACIONADOS CON FAMILIAR DEPENDIENTE, NECESITADO DE CUIDADO EN CASA'},
{ codigo: 'Z637', descripcion: 'PROBLEMAS RELACIONADOS CON OTROS HECHOS ESTRESANTES QUE AFECTAN A LA FAMILIA Y AL HOGAR'},
{ codigo: 'Z638', descripcion: 'OTROS PROBLEMAS ESPECIFICADOS RELACIONADOS CON GRUPO PRIMARIO DE APOYO'},
{ codigo: 'Z639', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON EL GRUPO PRIMARIO DE APOYO'},
{ codigo: 'Z64', descripcion: 'PROBLEMAS RELACIONADOS CON CIERTAS CIRCUNSTANCIAS PSICOSOCIALES'},
{ codigo: 'Z640', descripcion: 'PROBLEMAS RELACIONADOS CON EMBARAZO NO DESEADO'},
{ codigo: 'Z641', descripcion: 'PROBLEMAS RELACIONADOS CON LA MULTIPARIDAD'},
{ codigo: 'Z642', descripcion: 'PROBLEMAS RELACIONADOS CON LA SOLICITUD O ACEPTACION DE INTERVENCIONES FISICAS, NUTRICIONALES Y QUIMICAS, CONOCIENDO SU RIESGO Y PELlGRO'},
{ codigo: 'Z643', descripcion: 'PROBLEMAS RELACIONADOS CON LA SOLICITUD O ACEPTACION DE INTERVENCIONES PSICOLOGICAS O DE LA CONDUCTA, CONOCIENDO SU RIESGO Y PELIGRO'},
{ codigo: 'Z644', descripcion: 'PROBLEMAS RELACIONADOS CON EL DESACUERDO CON CONSEJEROS'},
{ codigo: 'Z65', descripcion: 'PROBLEMAS RELACIONADOS CON OTRAS CIRCUNSTANCIAS PSICOSOCIALES'},
{ codigo: 'Z650', descripcion: 'PROBLEMAS RELACIONADOS CON CULPABILIDAD EN PROCEDIM CIVILES O CRIMINALES SIN PRISION'},
{ codigo: 'Z651', descripcion: 'PROBLEMAS RELACIONADOS CON PRISION Y OTRO ENCARCELAMIENTO'},
{ codigo: 'Z652', descripcion: 'PROBLEMAS RELACIONADOS CON LA LIBERACION DE LA PRISION'},
{ codigo: 'Z653', descripcion: 'PROBLEMAS RELACIONADOS CON OTRAS CIRCUNSTANCIAS LEGALES'},
{ codigo: 'Z654', descripcion: 'PROBLEMAS RELACIONADOS CON VICTIMA DE CRIMEN O TERRORISMO'},
{ codigo: 'Z655', descripcion: 'PROBLEMAS RELACIONADOS CON LA EXPOSICION A DESASTRE, GUERRA U OTRAS HOSTILIDADES'},
{ codigo: 'Z658', descripcion: 'OTROS PROBLEMAS ESPECIFICADOS RELACIONADOS CON CIRCUNSTANCIAS PSICOSOCIALES'},
{ codigo: 'Z659', descripcion: 'PROBLEMAS RELACIONADOS CON CIRCUNSTANCIAS PSICOSOCIALES NO ESPECIFICADAS'},
{ codigo: 'Z70', descripcion: 'CONSULTA RELACIONADA CON ACTITUD, CONDUCTA U ORIENTACION SEXUAL'},
{ codigo: 'Z700', descripcion: 'CONSULTA RELACIONADA CON LA ACTITUD SEXUAL'},
{ codigo: 'Z701', descripcion: 'CONSULTA RELACIONADA CON LA ORIENTACION Y CONDUCTA SEXUAL DEL PACIENTE'},
{ codigo: 'Z702', descripcion: 'CONSULTA RELACIONADA CON ORIENTACION Y CONDUCTA SEXUAL DE UNA TERCERA PERSONA'},
{ codigo: 'Z703', descripcion: 'CONSULTA RELACIONADA CON PREOCUPACIONES COMBINADAS SOBRE LA ACTITUD, CONDUCTA Y ORIENTACION SEXUAL'},
{ codigo: 'Z708', descripcion: 'OTRAS CONSULTAS SEXUALES ESPECIFICAS'},
{ codigo: 'Z709', descripcion: 'CONSULTA SEXUAL, NO ESPECIFICADA'},
{ codigo: 'Z71', descripcion: 'PERSONAS EN CONTACTO CON LOS SERVICIOS DE SALUD POR OTRAS CONSULTAS Y CONSEJOS MEDICOS NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Z710', descripcion: 'PERSONA QUE CONSULTA EN NOMBRE DE OTRA PERSONA'},
{ codigo: 'Z711', descripcion: 'PERSONA QUE TEME ESTAR ENFERMA, A QUIEN NO SE HACE DIAGNOSTICO'},
{ codigo: 'Z712', descripcion: 'PERSONA QUE CONSULTA PARA LA EXPLICACION DE HALLAZGOS DE INVESTIGACION'},
{ codigo: 'Z713', descripcion: 'CONSULTA PARA INSTRUCCION Y VIGILANCIA DE LA DIETA'},
{ codigo: 'Z714', descripcion: 'CONSULTA PARA ASESORIA Y VIGILANCIA POR ABUSO DE ALCOHOL'},
{ codigo: 'Z715', descripcion: 'CONSULTA PARA ASESORIA Y VIGILANCIA POR ABUSO DE DROGAS'},
{ codigo: 'Z716', descripcion: 'CONSULTA PARA ASESORIA POR ABUSO DE TABACO'},
{ codigo: 'Z717', descripcion: 'CONSULTA PARA ASESORIA SOBRE VIRUS DE INMUNODEFICIENCIA HUMANA (VIH)'},
{ codigo: 'Z718', descripcion: 'OTRAS CONSULTAS ESPECIFICADAS'},
{ codigo: 'Z719', descripcion: 'CONSULTA, NO ESPECIFICADA'},
{ codigo: 'Z72', descripcion: 'PROBLEMAS RELACIONADOS CON EL ESTILO DE VIDA'},
{ codigo: 'Z720', descripcion: 'PROBLEMAS RELACIONADOS CON EL USO DEL TABACO'},
{ codigo: 'Z721', descripcion: 'PROBLEMAS RELACIONADOS CON EL USO DEL ALCOHOL'},
{ codigo: 'Z722', descripcion: 'PROBLEMAS RELACIONADOS CON EL USO DE DROGAS'},
{ codigo: 'Z723', descripcion: 'PROBLEMAS RELACIONADOS CON LA FALTA DE EJERCICIO FISICO'},
{ codigo: 'Z724', descripcion: 'PROBLEMAS RELACIONADOS CON LA DIETA Y HABITOS ALIMENTARIOS INAPROPIADOS'},
{ codigo: 'Z725', descripcion: 'PROBLEMAS RELACIONADOS CON LA CONDUCTA SEXUAL DE ALTO RIESGO'},
{ codigo: 'Z726', descripcion: 'PROBLEMAS RELACIONADOS CON EL JUEGO Y LAS APUESTAS'},
{ codigo: 'Z728', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON EL ESTILO DE VIDA'},
{ codigo: 'Z729', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON EL ESTILO DE VIDA'},
{ codigo: 'Z73', descripcion: 'PROBLEMAS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA'},
{ codigo: 'Z730', descripcion: 'PROBLEMAS RELACIONADOS CON LA ENFERMEDAD CONSUNTIVA'},
{ codigo: 'Z731', descripcion: 'PROBLEMAS RELACIONADOS CON LA ACENTUACION DE RASGOS DE LA PERSONALIDAD'},
{ codigo: 'Z732', descripcion: 'PROBLEMAS RELACIONADOS CON LA FALTA DE RELAJACION Y DESCANSO'},
{ codigo: 'Z733', descripcion: 'PROBLEMAS RELACIONADOS CON EL ESTRES, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Z734', descripcion: 'PROBLEMAS RELACIONADOS CON HABILIDADES SOCIALES INADECUADAS, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Z735', descripcion: 'PROBLEMAS RELACIONADOS CON EL CONFLICTO DEL ROL SOCIAL, NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Z736', descripcion: 'PROBLEMAS RELACIONADOS CON LIMITACION DE ACTIVIDADES POR DISCAPACIDAD'},
{ codigo: 'Z738', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA'},
{ codigo: 'Z739', descripcion: 'PROBLEMAS NO ESPECIFICADOS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA'},
{ codigo: 'Z74', descripcion: 'PROBLEMAS RELACIONADOS CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS'},
{ codigo: 'Z740', descripcion: 'PROBLEMAS RELACIONADOS CON MOVILIDAD REDUCIDA'},
{ codigo: 'Z741', descripcion: 'PROBLEMAS RELACIONADOS CON NECESIDAD DE AYUDA PARA EL CUIDADO PERSONAL'},
{ codigo: 'Z742', descripcion: 'PROBLEMAS RELACIONADOS CON NECESIDAD DE ASISTENCIA DOMICILIARIA Y QUE NINGUN OTRO MIEMBRO DEL HOGAR PUEDE PROPORCIONAR'},
{ codigo: 'Z743', descripcion: 'PROBLEMAS RELACIONADOS CON LA NECESIDAD DE SUPERVISION CONTINUA'},
{ codigo: 'Z748', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS'},
{ codigo: 'Z749', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS'},
{ codigo: 'Z75', descripcion: 'PROBLEMAS RELACIONADOS CON FACILIDADES DE ATENCION MEDICA U OTROS SERVICIOS DE SALUD'},
{ codigo: 'Z750', descripcion: 'PROBLEMAS RELACIONADOS CON SERVICIO MEDICO NO DISPONIBLE EN DOMICILIO'},
{ codigo: 'Z751', descripcion: 'PROBLEMAS RELACIONADOS CON PERSONA ESPERANDO ADMISION EN UNA INSTITUCION APROPIADA EN OTRO LUGAR'},
{ codigo: 'Z752', descripcion: 'PROBLEMAS RELACIONADOS CON PERSONA EN OTRO PERIODO DE ESPERA PARA INVESTIGACION Y TRATAMIENTO'},
{ codigo: 'Z753', descripcion: 'PROBLEMAS RELACIONADOS CON ATENCION DE SALUD NO DISPONIBLE O INACCESIBLE'},
{ codigo: 'Z754', descripcion: 'PROBLEMAS RELACIONADOS CON OTROS SERVICIOS ASISTENCIALES NO DISPONIBLES O INACCESIBLES'},
{ codigo: 'Z755', descripcion: 'PROBLEMAS RELACIONADOS CON LA ATENCION DURANTE VACACIONES DE FAMILIA'},
{ codigo: 'Z758', descripcion: 'OTROS PROBLEMAS RELACIONADOS CON SERVICIOS MEDICOS Y DE SALUD'},
{ codigo: 'Z759', descripcion: 'PROBLEMA NO ESPECIFICADO RELACIONADO CON SERVICIOS MEDICOS Y DE SALUD'},
{ codigo: 'Z76', descripcion: 'PERSONAS EN CONTACTO CON LOS SERVICIOS DE SALUD POR OTRAS CIRCUNSTANCIAS'},
{ codigo: 'Z760', descripcion: 'CONSULTA PARA REPETICION DE RECETA'},
{ codigo: 'Z761', descripcion: 'CONSULTA PARA ATENCION Y SUPERVISION DE LA SALUD DEL NI—O ABANDONADO'},
{ codigo: 'Z762', descripcion: 'CONSULTA PARA ATENCION Y SUPERVISION DE SALUD DE OTROS NI—OS O LACTANTES SANO'},
{ codigo: 'Z763', descripcion: 'PERSONA SANA QUE ACOMPA—A AL ENFERMO'},
{ codigo: 'Z764', descripcion: 'OTRO HUESPED EN SERVICIOS DE SALUD'},
{ codigo: 'Z765', descripcion: 'PERSONA QUE CONSULTA CON SIMULACION CONSCIENTE (SIMULADOR)'},
{ codigo: 'Z768', descripcion: 'PERSONAS EN CONTACTO CON SERVICIOS DE SALUD EN OTRAS CIRCUNSTANCIAS ESPECIFICADAS'},
{ codigo: 'Z769', descripcion: 'PERSONAS EN CONTACTO CON LOS SERVICIOS DE SALUD EN CIRCUNSTANCIAS NO ESPECIFICADAS'},
{ codigo: 'Z80', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO'},
{ codigo: 'Z800', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO DE ORGANOS DIGESTIVOS'},
{ codigo: 'Z801', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO DE TRAQUEA, BRONQUIOS Y PULMON'},
{ codigo: 'Z802', descripcion: 'HISTORIA FAMILIAR DE TUMOR DE OTROS ORGANOS RESPIRATORIOS E INTRATORACICOS'},
{ codigo: 'Z803', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO DE MAMA'},
{ codigo: 'Z804', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO DE ORGANOS GENITALES'},
{ codigo: 'Z805', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO DE VIAS URINARIAS'},
{ codigo: 'Z806', descripcion: 'HISTORIA FAMILIAR DE LEUCEMIA'},
{ codigo: 'Z807', descripcion: 'HISTORIA FAMILIAR DE OTROS TUMORES DEL TEJIDO LINFOIDE, HEMATOPOYETICO Y TEJIDOS RELACIONADOS'},
{ codigo: 'Z808', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO DE OTROS ORGANOS O SISTEMAS ESPECIFICADOS'},
{ codigo: 'Z809', descripcion: 'HISTORIA FAMILIAR DE TUMOR MALIGNO, DE SITIO NO ESPECIFICADO'},
{ codigo: 'Z81', descripcion: 'HISTORIA FAMILIAR DE TRASTORNOS MENTALES Y DEL COMPORTAMIENTO'},
{ codigo: 'Z810', descripcion: 'HISTORIA FAMILIAR DE RETARDO MENTAL'},
{ codigo: 'Z811', descripcion: 'HISTORIA FAMILIAR DE ABUSO DE ALCOHOL'},
{ codigo: 'Z812', descripcion: 'HISTORIA FAMILIAR DE ABUSO DEL TABACO'},
{ codigo: 'Z813', descripcion: 'HISTORIA FAMILIAR DE ABUSO DE OTRAS SUSTANCIAS PSICOACTIVAS'},
{ codigo: 'Z814', descripcion: 'HISTORIA FAMILIAR DE ABUSO DE OTRAS SUSTANCIAS'},
{ codigo: 'Z818', descripcion: 'HISTORIA FAMILIAR DE OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO'},
{ codigo: 'Z82', descripcion: 'HISTORIA FAMILIAR DE CIERTAS DISCAPACIDADES Y ENFERMEDADES CRONICAS INCAPACITANTES'},
{ codigo: 'Z820', descripcion: 'HISTORIA FAMILIAR EPILEPSIA Y OTRAS ENFERMEDADES DEL SISTEMA NERVIOSO'},
{ codigo: 'Z821', descripcion: 'HISTORIA FAMILIAR DE CEGUERA O PERDIDA DE LA VISION'},
{ codigo: 'Z822', descripcion: 'HISTORIA FAMILIAR DE SORDERA O PERDIDA DE LA AUDICION'},
{ codigo: 'Z823', descripcion: 'HISTORIA FAMILIAR DE APOPLEJIA'},
{ codigo: 'Z824', descripcion: 'HISTORIA FAMILIAR DE ENFERMEDAD ISQUEMICA DEL CORAZON Y OTRAS ENFERMEDADES DEL SISTEMA CIRCULATORIO'},
{ codigo: 'Z825', descripcion: 'HISTORIA FAMILIAR DE ASMA Y OTRAS ENFERMEDADES CRONICAS DE VIAS RESPIRATORIAS INFERIORES'},
{ codigo: 'Z826', descripcion: 'HISTORIA FAMILIAR DE ARTRITIS Y OTRAS ENFERMEDADES DEL SISTEMA OSTEOMUSCULAR Y TEJIDO CONJUNTIVO'},
{ codigo: 'Z827', descripcion: 'HISTORIA FAMILIAR DE MALFORMACIONES CONGENITAS, DEFORMIDADADES Y OTRAS ANOMALIAS CROMOSOMICAS'},
{ codigo: 'Z828', descripcion: 'HISTORIA FAMILIAR DE OTRAS DISCAPACIDADADES Y ENFERMEDADES CRONICAS INCAPACITANTES NO CLASIFICADAS EN OTRA PARTE'},
{ codigo: 'Z83', descripcion: 'HISTORIA FAMILIAR DE OTROS TRASTORNOS ESPECIFICOS'},
{ codigo: 'Z830', descripcion: 'HISTORIA FAMILIAR DE INFECCION POR VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH)'},
{ codigo: 'Z831', descripcion: 'HISTORIA FAMILIAR DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS'},
{ codigo: 'Z832', descripcion: 'HISTORIA FAMILIAR DE ENFERMEDADES DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS Y CIERTOS TRASTORNOS DEL MECANISMO INMUNOLOGICO'},
{ codigo: 'Z833', descripcion: 'HISTORIA FAMILIAR DE DIABETES MELLITUS'},
{ codigo: 'Z834', descripcion: 'HISTORIA FAMILIAR DE OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS'},
{ codigo: 'Z835', descripcion: 'HISTORIA FAMILIAR DE TRASTORNOS DE LOS OJOS Y DE LOS OIDOS'},
{ codigo: 'Z836', descripcion: 'HISTORIA FAMILIAR DE ENFERMEDADES DEL SISTEMA RESPIRATORIO'},
{ codigo: 'Z837', descripcion: 'HISTORIA FAMILIAR DE ENFERMEDADES DEL SISTEMA DIGESTIVO'},
{ codigo: 'Z84', descripcion: 'HISTORIA FAMILIAR DE OTRAS AFECCIONES'},
{ codigo: 'Z840', descripcion: 'HISTORIA FAMILIAR DE ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'Z841', descripcion: 'HISTORIA FAMILIAR DE TRASTORNOS DEL RI—ON Y DEL URETER'},
{ codigo: 'Z842', descripcion: 'HISTORIA FAMILIAR DE OTRAS ENFERMEDADES DEL SISTEMA GENITOURINARIO'},
{ codigo: 'Z843', descripcion: 'HISTORIA FAMILIAR DE CONSANGUINIDAD'},
{ codigo: 'Z848', descripcion: 'HISTORIA FAMILIAR DE OTRAS AFECCIONES ESPECIFICADAS'},
{ codigo: 'Z85', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO'},
{ codigo: 'Z850', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO DE ORGANOS DIGESTIVOS'},
{ codigo: 'Z851', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO DE TRAQUEA, BRONQUIOS Y PULMON'},
{ codigo: 'Z852', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO DE OTROS ORGANOS RESPIRATORIOS E INTRATORACICOS'},
{ codigo: 'Z853', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO DE MAMA'},
{ codigo: 'Z854', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO DE ORGANOS GENITALES'},
{ codigo: 'Z855', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO DE VIAS URINARIAS'},
{ codigo: 'Z856', descripcion: 'HISTORIA PERSONAL DE LEUCEMIA'},
{ codigo: 'Z857', descripcion: 'HISTORIA PERSONAL DE OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYETICO Y TEJIDOS RELACIONADOS'},
{ codigo: 'Z858', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO DE OTROS ORGANOS Y SISTEMAS'},
{ codigo: 'Z859', descripcion: 'HISTORIA PERSONAL DE TUMOR MALIGNO, DE SITIO NO ESPECIFICADO'},
{ codigo: 'Z86', descripcion: 'HISTORIA PERSONAL DE ALGUNAS OTRAS ENFERMEDADES'},
{ codigo: 'Z860', descripcion: 'HISTORIA PERSONAL DE OTROS TUMORES'},
{ codigo: 'Z861', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES INFECCIOSAS Y PARASITARIAS'},
{ codigo: 'Z862', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DE LA SANGRE Y ORGANOS HEMATOPOYETICOS Y DE CIERTOS TRASTORNOS DEL MECANISMO INMUNOLOGICO'},
{ codigo: 'Z863', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS'},
{ codigo: 'Z864', descripcion: 'HISTORIA PERSONAL DE ABUSO DE SUSTANCIAS PSICOACTIVAS'},
{ codigo: 'Z865', descripcion: 'HISTORIA PERSONAL DE OTROS TRASTORNOS MENTALES O DEL COMPORTAMIENTO'},
{ codigo: 'Z866', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA NERVIOSO Y DE LOS ORGANOS DE LOS SENTIDOS'},
{ codigo: 'Z867', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA CIRCULATORIO'},
{ codigo: 'Z87', descripcion: 'HISTORIA PERSONAL DE OTRAS ENFERMEDADES Y AFECCIONES'},
{ codigo: 'Z870', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA RESPIRATORIO'},
{ codigo: 'Z871', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA DIGESTIVO'},
{ codigo: 'Z872', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTANEO'},
{ codigo: 'Z873', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA OSTEOMUSCULAR Y DEL TEJIDO CONJUNTIVO'},
{ codigo: 'Z874', descripcion: 'HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA GENITOURINARIO'},
{ codigo: 'Z875', descripcion: 'HISTORIA PERSONAL DE COMPLICACIONES DEL EMBARAZO, DEL PARTO Y DEL PUERPERIO«'},
{ codigo: 'Z876', descripcion: 'HISTORIA PERSONAL DE CIERTAS AFECCIONES ORIGINADAS EN EL PERIODO PERINATAL'},
{ codigo: 'Z877', descripcion: 'HISTORIA PERSONAL DE MALFORMACIONES CONGENITAS, DEFORMIDADES Y ANOMALIAS CROMOSOMICAS'},
{ codigo: 'Z878', descripcion: 'HISTORIA PERSONAL DE OTRAS AFECCIONES ESPECIFICADAS'},
{ codigo: 'Z88', descripcion: 'HISTORIA PERSONAL DE ALERGIA A DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'Z880', descripcion: 'HISTORIA PERSONAL DE ALERGIA A PENICILINA'},
{ codigo: 'Z881', descripcion: 'HISTORIA PERSONAL DE ALERGIA A OTROS AGENTES ANTIBIOTICOS'},
{ codigo: 'Z882', descripcion: 'HISTORIA PERSONAL DE ALERGIA A SULFONAMIDAS'},
{ codigo: 'Z883', descripcion: 'HISTORIA PERSONAL DE ALERGIA A OTROS AGENTES ANTIINFECCIOSOS'},
{ codigo: 'Z884', descripcion: 'HISTORIA PERSONAL DE ALERGIA A AGENTE ANESTESICO'},
{ codigo: 'Z885', descripcion: 'HISTORIA PERSONAL DE ALERGIA A AGENTE NARCOTICO'},
{ codigo: 'Z886', descripcion: 'HISTORIA PERSONAL DE ALERGIA A AGENTE ANALGESICO'},
{ codigo: 'Z887', descripcion: 'HISTORIA PERSONAL DE ALERGIA A SUERO O VACUNA'},
{ codigo: 'Z888', descripcion: 'HISTORIA PERSONAL DE ALERGIA A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS'},
{ codigo: 'Z889', descripcion: 'HISTORIA PERSONAL DE ALERGIA A DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS'},
{ codigo: 'Z89', descripcion: 'AUSENCIA ADQUIRIDA DE MIEMBROS'},
{ codigo: 'Z890', descripcion: 'AUSENCIA ADQUIRIDA DE DEDO(S), (INCLUIDO EL PULGAR), UNILATERAL'},
{ codigo: 'Z891', descripcion: 'AUSENCIA ADQUIRIDA DE MANO Y MU—ECA'},
{ codigo: 'Z892', descripcion: 'AUSENCIA ADQUIRIDA DE MIEMBRO SUPERIOR POR ARRIBA DE LA MU—ECA'},
{ codigo: 'Z893', descripcion: 'AUSENCIA ADQUIRIDA DE AMBOS MIEMBROS SUPERIORES (CUALQUIER NIVEL)'},
{ codigo: 'Z894', descripcion: 'AUSENCIA ADQUIRIDA DE PIE Y TOBILLO'},
{ codigo: 'Z895', descripcion: 'AUSENCIA ADQUIRIDA DE PIERNA A NIVEL DE O DEBAJO DE LA RODILLA'},
{ codigo: 'Z896', descripcion: 'AUSENCIA ADQUIRIDA DE PIERNA POR ARRIBA DE LA RODILLA'},
{ codigo: 'Z897', descripcion: 'AUSENCIA ADQUIRIDA DE AMBOS MIEMBROS INFERIORES (CUALQUIER NIVEL, EXCEPTO DEDOS DEL PIE SOLAMENTE)'},
{ codigo: 'Z898', descripcion: 'AUSENCIA ADQUIRIDA DE MIEMBROS SUPERIORES E INFERIORES (CUALQUIER NIVEL)'},
{ codigo: 'Z899', descripcion: 'AUSENCIA ADQUIRIDA DE MIEMBROS NO ESPECIFICADOS'},
{ codigo: 'Z90', descripcion: 'AUSENCIA ADQUIRIDA DE ORGANOS, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'Z900', descripcion: 'AUSENCIA ADQUIRIDA DE PARTE DE LA CABEZA Y DEL CUELLO'},
{ codigo: 'Z901', descripcion: 'AUSENCIA ADQUIRIDA DE MAMA(S)'},
{ codigo: 'Z902', descripcion: 'AUSENCIA ADQUIRIDA (DE PARTE) DEL PULMON'},
{ codigo: 'Z903', descripcion: 'AUSENCIA ADQUIRIDA DE PARTE DEL ESTOMAGO'},
{ codigo: 'Z904', descripcion: 'AUSENCIA ADQUIRIDA DE OTRAS PARTES DEL TUBO DIGESTIVO'},
{ codigo: 'Z905', descripcion: 'AUSENCIA ADQUIRIDA DE RI—ON'},
{ codigo: 'Z906', descripcion: 'AUSENCIA ADQUIRIDA DE OTRAS PARTES DE LAS VIAS URINARIAS'},
{ codigo: 'Z907', descripcion: 'AUSENCIA ADQUIRIDA DE ORGANO(S) GENITAL(ES)'},
{ codigo: 'Z908', descripcion: 'AUSENCIA ADQUIRIDA DE OTROS ORGANOS'},
{ codigo: 'Z91', descripcion: 'HISTORIA PERSONAL DE FACTORES DE RIESGO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Z910', descripcion: 'HISTORIA PERSONAL DE ALERGIA, NO DEBIDA A DROGAS NI A SUSTANCIAS BIOLOGICAS'},
{ codigo: 'Z911', descripcion: 'HISTORIA PERSONAL DE INCUMPLIMIENTO DEL REGIMEN O TRATAMIENTO MEDICO'},
{ codigo: 'Z912', descripcion: 'HISTORIA PERSONAL DE HIGIENE PERSONAL DEFICIENTE'},
{ codigo: 'Z913', descripcion: 'HISTORIA PERSONAL DE CICLO SUE—O-VIGILIA NO SALUDABLE'},
{ codigo: 'Z914', descripcion: 'HISTORIA PERSONAL DE TRAUMA PSICOLOGICO, NO CLASIFICADO EN OTRA PARTE'},
{ codigo: 'Z915', descripcion: 'HISTORIA PERSONAL DE LESION AUTOINFLIGIDA INTENCIONALMENTE'},
{ codigo: 'Z916', descripcion: 'HISTORIA PERSONAL DE OTRO TRAUMA FISICO'},
{ codigo: 'Z918', descripcion: 'HISTORIA PERSONAL DE OTROS FACTORES DE RIESGO, NO CLASIFICADOS EN OTRA PARTE'},
{ codigo: 'Z92', descripcion: 'HISTORIA PERSONAL DE TRATAMIENTO MEDICO'},
{ codigo: 'Z920', descripcion: 'HISTORIA PERSONAL DE ANTICONCEPCION'},
{ codigo: 'Z921', descripcion: 'HISTORIA PERSONAL DE USO (PRESENTE) DE ANTICOAGULANTES POR LARGO TIEMPO'},
{ codigo: 'Z922', descripcion: 'HISTORIA PERSONAL DE USO (PRESENTE) DE OTROS MEDICAMENTOS POR LARGO TIEMPO'},
{ codigo: 'Z923', descripcion: 'HISTORIA PERSONAL DE IRRADIACION'},
{ codigo: 'Z924', descripcion: 'HISTORIA PERSONAL DE CIRUGIA MAYOR, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'Z925', descripcion: 'HISTORIA PERSONAL DE MEDIDAS DE REHABILITACION'},
{ codigo: 'Z928', descripcion: 'HISTORIA PERSONAL DE OTROS TRATAMIENTOS MEDICOS'},
{ codigo: 'Z929', descripcion: 'HISTORIA PERSONAL DE TRATAMIENTO MEDICO NO ESPECIFICADO'},
{ codigo: 'Z93', descripcion: 'ABERTURAS ARTIFICIALES'},
{ codigo: 'Z930', descripcion: 'TRAQUEOSTOMIA'},
{ codigo: 'Z931', descripcion: 'GASTROSTOMIA'},
{ codigo: 'Z932', descripcion: 'ILEOSTOMIA'},
{ codigo: 'Z933', descripcion: 'COLOSTOMIA'},
{ codigo: 'Z934', descripcion: 'OTROS ORIFICIOS ARTIFICIALES DEL TUBO GASTROINTESTINAL'},
{ codigo: 'Z935', descripcion: 'CISTOSTOMIA'},
{ codigo: 'Z936', descripcion: 'OTROS ORIFICIOS ARTIFICIALES DE LAS VIAS URINARIAS'},
{ codigo: 'Z938', descripcion: 'OTRAS ABERTURAS ARTIFICIALES'},
{ codigo: 'Z939', descripcion: 'ABERTURA ARTIFICIAL, NO ESPECIFICADA'},
{ codigo: 'Z94', descripcion: 'ORGANOS Y TEJIDOS TRASPLANTADOS'},
{ codigo: 'Z940', descripcion: 'TRASPLANTE DE RI—ON'},
{ codigo: 'Z941', descripcion: 'TRASPLANTE DE CORAZON'},
{ codigo: 'Z942', descripcion: 'TRASPLANTE DE PULMON'},
{ codigo: 'Z943', descripcion: 'TRASPLANTE DE CORAZON Y PULMONES'},
{ codigo: 'Z944', descripcion: 'TRASPLANTE DE HIGADO'},
{ codigo: 'Z945', descripcion: 'TRASPLANTE DE PIEL'},
{ codigo: 'Z946', descripcion: 'TRASPLANTE DE HUESO'},
{ codigo: 'Z947', descripcion: 'TRASPLANTE DE CORNEA'},
{ codigo: 'Z948', descripcion: 'OTROS ORGANOS Y TEJIDOS TRASPLANTADOS'},
{ codigo: 'Z949', descripcion: 'ORGANO O TEJIDO TRASPLANTADO NO ESPECIFICADO'},
{ codigo: 'Z95', descripcion: 'PRESENCIA DE IMPLANTES E INJERTOS CARDIOVASCULARES'},
{ codigo: 'Z950', descripcion: 'PRESENCIA DE MARCAPASO CARDIACO'},
{ codigo: 'Z951', descripcion: 'PRESENCIA DE DERIVACION AORTOCORONARIA'},
{ codigo: 'Z952', descripcion: 'PRESENCIA DE VALVULA CARDIACA PROTESICA'},
{ codigo: 'Z953', descripcion: 'PRESENCIA DE VALVULA CARDIACA XENOGENICA'},
{ codigo: 'Z954', descripcion: 'PRESENCIA DE OTROS REEMPLAZOS DE VALVULA CARDIACA'},
{ codigo: 'Z955', descripcion: 'PRESENCIA DE ANGIOPLASTIA, INJERTOS Y PROTESIS CORONARIAS'},
{ codigo: 'Z958', descripcion: 'PRESENCIA DE OTROS INJERTOS Y PROTESIS CARDIOVASCULARES'},
{ codigo: 'Z959', descripcion: 'PRESENCIA DE INJERTOS E IMPLANTES CARDIOVASCULARES NO ESPECIFICADOS'},
{ codigo: 'Z96', descripcion: 'PRESENCIA DE OTROS IMPLANTES FUNCIONALES'},
{ codigo: 'Z960', descripcion: 'PRESENCIA DE IMPLANTE UROGENITAL'},
{ codigo: 'Z961', descripcion: 'PRESENCIA DE LENTES INTRAOCULARES'},
{ codigo: 'Z962', descripcion: 'PRESENCIA DE IMPLANTES OTICOS Y AUDITIVOS'},
{ codigo: 'Z963', descripcion: 'PRESENCIA DE LARINGE ARTIFICIAL'},
{ codigo: 'Z964', descripcion: 'PRESENCIA DE IMPLANTES ENDOCRINOS'},
{ codigo: 'Z965', descripcion: 'PRESENCIA DE IMPLANTES DE RAIZ DE DIENTE Y DE MANDIBULA'},
{ codigo: 'Z966', descripcion: 'PRESENCIA DE IMPLANTE ORTOPEDICO ARTICULAR'},
{ codigo: 'Z967', descripcion: 'PRESENCIA DE OTROS IMPLANTES DE TENDONES Y HUESOS'},
{ codigo: 'Z968', descripcion: 'PRESENCIA DE OTROS IMPLANTES FUNCIONALES ESPECIFICADOS'},
{ codigo: 'Z969', descripcion: 'PRESENCIA DE IMPLANTES FUNCIONALES NO ESPECIFICADOS'},
{ codigo: 'Z97', descripcion: 'PRESENCIA DE OTROS DISPOSITIVOS'},
{ codigo: 'Z970', descripcion: 'PRESENCIA DE OJO ARTIFICIAL'},
{ codigo: 'Z971', descripcion: 'PRESENCIA DE MIEMBRO ARTIFICIAL (COMPLETO) (PARCIAL)'},
{ codigo: 'Z972', descripcion: 'PRESENCIA DE DISPOSITIVO PROTESICO DENTAL (COMPLETO) (PARCIAL)'},
{ codigo: 'Z973', descripcion: 'PRESENCIA DE ANTEOJOS Y LENTES DE CONTACTO'},
{ codigo: 'Z974', descripcion: 'PRESENCIA DE AUDIFONO EXTERNO'},
{ codigo: 'Z975', descripcion: 'PRESENCIA DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)'},
{ codigo: 'Z978', descripcion: 'PRESENCIA DE OTROS DISPOSITIVOS ESPECIFICADOS'},
{ codigo: 'Z98', descripcion: 'OTROS ESTADOS POSTQUIRURGICOS'},
{ codigo: 'Z980', descripcion: 'ESTADO DE DERIVACION INTESTINAL O ANASTOMOSIS'},
{ codigo: 'Z981', descripcion: 'ESTADO DE ARTRODESIS'},
{ codigo: 'Z982', descripcion: 'PRESENCIA DE DISPOSITIVO PARA DRENAJE DE LIQUIDO CEFALORRAQUIDEO'},
{ codigo: 'Z988', descripcion: 'OTROS ESTADOS POSTQUIRURGICOS ESPECIFICADOS'},
{ codigo: 'Z99', descripcion: 'DEPENDENCIA DE MAQUINAS Y DISPOSITIVOS CAPACITANTES, NO CLASIFICADA EN OTRA PARTE'},
{ codigo: 'Z990', descripcion: 'DEPENDENCIA DE ASPIRADOR'},
{ codigo: 'Z991', descripcion: 'DEPENDENCIA DE RESPIRADOR'},
{ codigo: 'Z992', descripcion: 'DEPENDENCIA DE DIALISIS RENAL'},
{ codigo: 'Z993', descripcion: 'DEPENDENCIA DE SILLA DE RUEDAS'},
{ codigo: 'Z998', descripcion: 'DEPENDENCIA DE OTRAS MAQUINAS Y DISPOSITIVOS CAPACITANTES'},
{ codigo: 'Z999', descripcion: 'DEPENDENCIA DE MAQUINA Y DISPOSITIVO CAPACITANTE, NO ESPECIFICADA'},



    
];