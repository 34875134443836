import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { redirectClass } from '@models/global.models';
import {
  DatosUsuarioModificado,
  modificarModel,
} from '@models/operaciones.models';
import { Prestador } from '@models/prestador.model';
import { roles } from '@models/signup.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import {
  ActivarLoading,
  DesactivarLoading,
  removeDatosUsuario,
  setUsuarioActual,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { errorGenerico } from 'errores';
import { Subscription } from 'rxjs';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';

@Component({
  selector: 'app-modificar-usuario',
  templateUrl: './modificar-usuario.component.html',
  styleUrls: ['./modificar-usuario.component.scss'],
})
export class ModificarUsuarioComponent implements OnInit, OnDestroy {
  private subscriptionUI: Subscription = new Subscription();
  private subscriptionConfiguracion: Subscription = new Subscription();
  private subscriptionRol: Subscription = new Subscription();
  submitted: boolean = false;
  cargando: boolean = false;
  usuario: DatosUsuarioModificado | null = null;
  roles: Array<roles> = [];
  codigoInstalacion: string = '';
  prestadores: Array<Prestador> = [];
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<modificarModel> = this.formBuilder.group<modificarModel>({
    nombre: new UntypedFormControl(<ControlState<string>>null, [
      Validators.required,
      blankSpaceValidator,
    ]),
    apellido: new UntypedFormControl(<ControlState<string>>null, [
      Validators.required,
      blankSpaceValidator,
    ]),
    email: new UntypedFormControl(
      <ControlState<string>>{ value: null, disabled: true },
      [Validators.required, Validators.email, blankSpaceValidator]
    ),
    rol: new UntypedFormControl(<ControlState<string>>null, [
      Validators.required,
      blankSpaceValidator,
    ]),
    cuitPrestador: new UntypedFormControl(<ControlState<string>>null, [
      blankSpaceValidator,
    ]),
  });

  constructor(
    private store: Store<AppState>,
    private location: Location,
    private operacionesPerfilService: OperacionesPerfilService,
    private dialogsService: DialogsService,
    private globalService: GlobalService,
    private router: Router
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.listenCuitPrestador();
    this.subscriptionUI = this.store.select('ui').subscribe((uiState) => {
      this.cargando = uiState.loading;
    });

    this.subscriptionConfiguracion = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.roles = this.globalService.getRolesAMostrarByTipoInstalacion(
            configuracionState.usuarioActual.role,
            configuracionState.usuarioActual.tipoInstalacion
          );
          this.codigoInstalacion =
            configuracionState.usuarioActual.codigoInstalacion;
          this.getPrestadores();
        } else {
          this.globalService.redirect(
            new redirectClass(
              'contactanos',
              errorGenerico,
              'No hay usuario actual al modificar usuario'
            )
          );
        }
      });
    this.refresh();
  }

  getPrestadores() {
    this.operacionesPerfilService
      .getPrestadoresDeLugarAtencion(this.codigoInstalacion)
      .subscribe(
        (prestadores) => {
          this.prestadores = prestadores;
        },
        (error) => {
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }

  ngOnDestroy() {
    this.subscriptionUI.unsubscribe();
    this.subscriptionConfiguracion.unsubscribe();
    this.subscriptionRol.unsubscribe();
  }

  irAtras() {
    this.location.back();
  }

  refresh() {
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService.getDatosUsuarioModificados().subscribe(
      (respuesta) => {
        console.log(respuesta);

        this.usuario = respuesta;
        if (respuesta.usuarioModificado) {
          this.form.controls['email'].setValue(
            respuesta.usuarioModificado.email
          );
          this.form.controls['nombre'].setValue(
            respuesta.usuarioModificado.nombre
          );
          this.form.controls['apellido'].setValue(
            respuesta.usuarioModificado.apellido
          );
          if (respuesta.usuarioModificado.rol == 'Prestador') {
            this.form.controls['cuitPrestador'].setValue(
              respuesta.usuarioModificado.cuitPrestador
            );
            this.form.controls['cuitPrestador'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);
          }

          this.form.controls['rol'].setValue(
            this.roles[
              this.roles.findIndex((x) => x == respuesta.usuarioModificado.rol)
            ]
          );
          this.dialogsService
            .advertencia(
              '',
              'Los datos de su ultima modificación todavia no han sido aceptados por el administrador, si vuelve a modificar los datos, se sobreescribiran los anteriores y se le volverá a notificar al administrador'
            )
            .then(() => {
              this.dialogsService.cerrar();
              this.store.dispatch(new DesactivarLoading());
            });
        } else {
          this.form.controls['email'].setValue(respuesta.usuario.email);
          this.form.controls['nombre'].setValue(respuesta.usuario.nombre);
          this.form.controls['apellido'].setValue(respuesta.usuario.apellido);

          if (respuesta.usuario.rol == 'Prestador') {
            this.form.controls['cuitPrestador'].setValue(
              respuesta.usuario.cuitPrestador
            );
            this.form.controls['cuitPrestador'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);
          }
          this.form.controls['rol'].setValue(
            this.roles[this.roles.findIndex((x) => x == respuesta.usuario.rol)]
          );
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
        }
      },
      (error: HttpErrorResponse) => {
        this.dialogsService.cerrar();
        this.store.dispatch(new DesactivarLoading());
        this.globalService.manageError(error);
      }
    );
  }

  listenCuitPrestador() {
    this.subscriptionRol = this.form.controls['rol'].valueChanges.subscribe(
      (rol) => {
        if (rol == 'Prestador') {
          this.form.controls['cuitPrestador'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
        } else {
          this.form.controls['cuitPrestador'].setValidators(null);
          this.form.controls['cuitPrestador'].updateValueAndValidity();
        }
      }
    );
  }

  cambiarDatos() {
    console.log(this.form);

    this.submitted = true;
    if (this.form.invalid) {
      this.dialogsService.toastError('Error en el formulario');
      return;
    }
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService
      .modificarDatos(this.form.getRawValue())
      .subscribe(
        (respuesta) => {
          this.store.dispatch(new DesactivarLoading());
          if (respuesta.token) {
            this.store.dispatch(new setUsuarioActual(respuesta));
            this.dialogsService
              .success('', 'Se modifico el usuario correctamente.')
              .then(() => {
                this.router.navigate(['perfil']);
              });
          } else {
            this.dialogsService.success('', respuesta.message).then(() => {
              this.router.navigate(['perfil']);
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }

  actualizarEmail() {
    this.dialogsService
      .solicitarInfoModal(
        'Ingrese su nuevo email, tenga en cuenta que esté será su nuevo email en todos sus lugares de atención',
        'Email'
      )
      .result.then((respuesta: any) => {
        if (respuesta && respuesta.respuesta) {
          let email = respuesta.respuesta;
          this.dialogsService
            .confirmarModal(
              'Este email se usara en todos los lugares de atención, si desea tener un email diferente solo en este lugar de atención, cree una nueva cuenta. Desea continuar?'
            )
            .result.then((respuesta: any) => {
              if (respuesta && respuesta.respuesta) {
                this.store.dispatch(new ActivarLoading());
                this.operacionesPerfilService
                  .cambiarEmailUsuario(email)
                  .subscribe(
                    (respuesta) => {
                      this.store.dispatch(new DesactivarLoading());
                      this.dialogsService
                        .success(
                          '',
                          'Se envio un mail de confirmación a su nuevo email, para que se hagan efectivos los cambios debe confirmar el mismo y volver a logearse en el sistema con el nuevo email.'
                        )
                        .then(() => {
                          this.store.dispatch(new removeDatosUsuario());
                        });
                    },
                    (error) => {
                      this.store.dispatch(new DesactivarLoading());
                      this.globalService.manageError(error);
                    }
                  );
              }
            })
            .catch(() => {
              this.store.dispatch(new DesactivarLoading());
              this.globalService.redirect(
                new redirectClass('contactanos', errorGenerico)
              );
            });
        }
      })
      .catch(() => {
        this.store.dispatch(new DesactivarLoading());
        this.globalService.redirect(
          new redirectClass('contactanos', errorGenerico)
        );
      });
  }

  actualizarContrasenna() {
    this.dialogsService
      .cambiarContrasennaModal()
      .result.then((respuesta: any) => {
        if (respuesta && respuesta.respuesta) {
          this.store.dispatch(new ActivarLoading());
          this.operacionesPerfilService
            .cambiarPassword(respuesta.respuesta)
            .subscribe(
              (respuesta) => {
                this.store.dispatch(new DesactivarLoading());
                this.dialogsService.toastSuccess(
                  'Se cambio la contraseña correctamente'
                );
                this.router.navigate(['perfil']);
              },
              (error) => {
                this.store.dispatch(new DesactivarLoading());
                this.globalService.manageError(error);
              }
            );
        }
      })
      .catch(() => {
        this.store.dispatch(new DesactivarLoading());
        this.globalService.redirect(
          new redirectClass('contactanos', errorGenerico)
        );
      });
  }
}
