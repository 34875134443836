import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { Prestador } from '@models/prestador.model';
import { setAnulacionRequest, setEstadoFormNroReferenciaCancelRequest } from '@store/actions';
import { FinanciadorRequestModel, TransaccionRequestModel } from '@models/datosInicio.models';
import { AnulacionRequestModel } from '../../../../models/datosInicio.models';
import { setNroReferenciaCancelRequest } from '../../../../store/actions/datosTransacciones.actions';
import { PrestadorSelecionado } from '../../../../models/prestador.model';

import {
  setEstadoFormPrescriptorRequest,
  unsetPrescriptorRequest,
} from '@store/actions';


@Component({
  selector: 'app-datos-anulacion-request',
  templateUrl: './datos-anulacion-request.component.html',
  styleUrls: ['./datos-anulacion-request.component.scss']
})
export class DatosAnulacionRequestComponent implements OnInit {

  // INICIO MASK
  mascarasValidas: Array<any> = [
    'OSDE',
    'SWISS',
  ];
  osdeMask = {
    mask: '000000000',
    lazy: false,
    unmask: 'typed',
  };
  swissMask = {
    mask: '000000000',
    lazy: false,
    unmask: 'typed',
  };
  // FIN MASK

  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  private subscriptionDatosInicio: Subscription = new Subscription();
  private subscriptionFormStatus: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;
  financiadorSeleccionado: FinanciadorRequestModel | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<AnulacionRequestModel> =
    this.formBuilder.group<AnulacionRequestModel>({
      NroReferenciaCancelRequest: new UntypedFormControl(<ControlState<number>>null, [

        Validators.pattern('[0-9]+'),
        Validators.required,
        blankSpaceValidator,
      ]),
    });

  codigoTransaccion: string | null = null;
  NroReferenciaCancel: string | null = null;

  mostrarCargarPrescriptor: boolean = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();


    this.addValidators();
  }

  addValidators(){
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '04A':
          this.form.controls['NroReferenciaCancelRequest'].setValidators([
            Validators.pattern('[0-9]+'),
            Validators.required,
            blankSpaceValidator
          ]);

          this.form.updateValueAndValidity();
          this.store.dispatch(new setEstadoFormNroReferenciaCancelRequest(false));
          break;

        default:
          this.store.dispatch(new setEstadoFormNroReferenciaCancelRequest(null));
          break;
      }

    }
  }
  getDatos() {

    this.subscriptionDatosInicio = this.store.select('datosInicio').subscribe((datos) => {
      this.financiadorSeleccionado = datos.FinanciadorRequest;
    });

    this.subscriptionStore = this.store.subscribe((datos) => {
      this.NroReferenciaCancel =
        datos.datosTransaccion.NroReferenciaCancelRequest;
        this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
    this.store.dispatch(new setEstadoFormNroReferenciaCancelRequest(null));
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });

  }

  guardar() {
    let datos = this.form.getRawValue();
    let anulacionRequest = new AnulacionRequestModel(
      datos.NroReferenciaCancelRequest
    );
    this.store.dispatch(new setAnulacionRequest(anulacionRequest));
    this.store.dispatch(new setEstadoFormNroReferenciaCancelRequest(this.form.valid));
    this.store.dispatch(new setNroReferenciaCancelRequest(datos.NroReferenciaCancelRequest));
  }


}
