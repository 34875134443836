export class inputAgendaFormModel {
  public inputAgenda: string;

  constructor(inputAgenda: string) {
    this.inputAgenda = inputAgenda;
  }
}

export class DatosAfiliado {
  public numeroAfiliado: string;
  public nombreFinanciador: string;

  constructor(numeroAfiliado: string, nombreFinanciador: string) {
    this.numeroAfiliado = numeroAfiliado;
    this.nombreFinanciador = nombreFinanciador;
  }
}

export class unAfiliadoAgenda {
  public DNI: string;
  public id: number;
  public nombre: string;
  public apellido: string;
  public email: string;
  public telefono: string;
  public numeroAfiliado: string;
  public nombreFinanciador: string;

  constructor(
    DNI: string,
    id: number,
    nombre: string,
    apellido: string,
    email: string,
    telefono: string,
    numeroAfiliado: string,
    nombreFinanciador: string
  ) {
    this.DNI = DNI;
    this.id = id;
    this.nombre = nombre;
    this.apellido = apellido;
    this.email = email;
    this.telefono = telefono;
    this.numeroAfiliado = numeroAfiliado;
    this.nombreFinanciador = nombreFinanciador;
  }
}

export class DatosAfiliadoAgendaModel {
  public DNI: string;
  public id: number;
  public nombre: string;
  public apellido: string;
  public email: string;
  public telefono: string;
  public obras: Array<DatosAfiliado>;

  constructor(
    DNI: string,
    id: number,
    nombre: string,
    apellido: string,
    email: string,
    telefono: string,
    obras: Array<DatosAfiliado>
  ) {
    this.DNI = DNI;
    this.id = id;
    this.nombre = nombre;
    this.apellido = apellido;
    this.email = email;
    this.telefono = telefono;
    this.obras = obras;
  }
}
export class GetDatosAgenda {
  totalItems: number;
  totalPages: number;
  afiliados: Array<DatosAfiliadoAgendaModel>;

  constructor(
    totalItems: number,
    totalPages: number,
    afiliados: Array<DatosAfiliadoAgendaModel>
  ) {
    this.totalItems = totalItems;
    this.totalPages = totalPages;
    this.afiliados = afiliados;
  }
}
