import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { codigoInstalacion, tokenModel } from '@models/global.models';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { ConfiguracionState } from '@store/models/store.models';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PersistenciaService } from './persistencia.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  headers: HttpHeaders = new HttpHeaders({});
  codigosInstalacion: Array<codigoInstalacion> | null = null;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private persistenciaService: PersistenciaService
  ) {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState: ConfiguracionState) => {
        this.codigosInstalacion = configuracionState.codigosInstalacion;
        if (configuracionState.usuarioActual) {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  esTokenValido(): Observable<tokenModel> {
    //Si se esta por vencer el token se me envia uno nuevo
    return this.http
      .get<tokenModel>(environment.url + '/action', {
        headers: this.headers,
      })
      .pipe(take(1));
  }
}
