let environment = {
  production: false,
  url: 'https://nb-dev.tx.puntosalud.ar/api/v1/nuvalid',
  urlturnea: 'https://app.dev.turnea.com.ar'
  /*urlturnea: 'http://localhost:4201/'*/
};

let environmentReceta = {
  production: false,
  url: '/api/v1',
};

let environmentDispensa = {
  production: false,
  url: '/jsitelbaseservlet/Consultas'
  //url: 'https://servicios.itcsoluciones.ar/jsitelbaseservlet/Consultas',
  //url: 'https://jsbs.testing.itcsoluciones.ar/jsitelbaseservlet/Consultas',
};
/*
let environment = {
  production: true,
  url: 'try',
};

var userAgent = navigator.userAgent.toLowerCase();
if (userAgent.indexOf(' electron/') > -1) {
  // Electron-specific code
  environment = {
    production: true,
    //url: 'http://192.168.48.155:8080/api/v1/nuvalid',
    url: 'https://nuvalid.tx.puntosalud.ar/api/v1/nuvalid'
  };
}*/

export { environment, environmentReceta, environmentDispensa };
