import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppState } from '@store/app.reducers';
import { Store } from '@ngrx/store';
import { ConfiguracionState, DatosIniciales } from '@store/models/store.models';
//import { environmentDispensa } from '@environments/environment';
import { Monodroga } from '@models/monodrogas';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environmentDispensa, environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispensaaService {
  private subscriptionStore: Subscription = new Subscription();
  private headers: HttpHeaders = new HttpHeaders({});
  private datosIniciales: DatosIniciales | null = null;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,

  ) {

    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState: ConfiguracionState) => {
        if (configuracionState.usuarioActual) {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
        }
      });

  }

  //-----------------CONEXIÓN CON JSITELBASESERVLET-----------------

  // getMonoDrogaASD(
  //   parametros: any
  // ): Observable<Monodroga> {
  //   /*
  //   let formData = {
  //     op: 'ranking',
  //     especialidad: parametros.especialidad ? encodeURI(parametros.especialidad) : '',
  //     rango: parametros.rango ? parametros.rango : 10,
  //     edad: parametros.edad ? parametros.edad : '',
  //     sexo: parametros.sexo ? parametros.sexo : ''
  //   };
  //   */
  //   const formData = new FormData();
  //   //let url_desarrollo = '/jsitelbaseservlet/Consultas'; //Produccion con proxy;;
  //   //let url_desarrollo = '/jSitelBaseServlet/Consultas'; //pathMarcos;
  //   let url = environmentDispensa.url;
  //   formData.append('op', parametros.operacion);
  //   console.log('parametros operación!!!', parametros.operacion);
  //   formData.append('descripcion', parametros?.monodroga ? encodeURI(parametros.monodroga) : '');
  //   formData.append('monodroga', parametros.codigo_droga ? encodeURI(parametros.codigo_droga) : '');
  //   formData.append('codigo_droga', parametros?.codigo_droga ? parametros.codigo_droga : '');
  //   formData.append('especialidad', parametros?.especialidad ? encodeURI(parametros.especialidad) : '');
  //   formData.append('presentacion', parametros.presentacion ? encodeURI(parametros.presentacion) : '');
  //   formData.append('nombre', parametros?.nombre ? encodeURI(parametros.nombre) : '');
  //   formData.append('rango', parametros?.rango ? parametros.rango : 10);
  //   formData.append('edad', parametros?.edad ? parametros.edad : '');
  //   formData.append('sexo', parametros?.sexo ? parametros.sexo : '');

  //   return this.http
  //     .post<Monodroga>(`${url}`, formData, { headers: this.headers }).pipe(map((res: any) => {
  //       if (parametros.operacion === 'medicamentos') {
  //         return res['medicamentos']; //devuelve array de medicamentos
  //       } else {
  //         return res['monodrogas'];
  //       }
  //     }));
  // }

  //-----------------CONEXIÓN CON NUVALID-----------------

  getMonoDroga(parametros: any): Observable<Monodroga> {
    let urlNuvalid = environment.url
    // OBJETO PARA PASARLE AL SERVICIO
    const formData = new FormData();
    formData.append('op', parametros.operacion);
    console.log('parametros operación!!!', parametros.operacion);
    formData.append('descripcion', parametros?.monodroga ? encodeURI(parametros.monodroga) : '');
    formData.append('determinacion', parametros?.determinacion ? encodeURI(parametros.determinacion) : '');
    formData.append('monodrogas', parametros.codigo_droga ? encodeURI(parametros.codigo_droga) : '');
    formData.append('codigo_droga', parametros?.codigo_droga ? parametros.codigo_droga : '');
    formData.append('especialidad', parametros?.especialidad ? encodeURI(parametros.especialidad) : '');
    formData.append('presentacion', parametros.presentacion ? encodeURI(parametros.presentacion) : '');
    formData.append('nombre', parametros?.nombre ? encodeURI(parametros.nombre) : '');
    formData.append('grupo', parametros.grupo ? encodeURI(parametros.grupo) : '');
    formData.append('rango', parametros?.rango ? parametros.rango : 10);
    formData.append('edad', parametros?.edad ? parametros.edad : '');
    formData.append('sexo', parametros?.sexo ? parametros.sexo : '');

    return this.http
      .post<Monodroga>(`${urlNuvalid}/mpos/consultas`, formData, { headers: this.headers }).pipe(map((res: any) => {
        return res;
      }));

  }

  // getEstudios(): Observable<any> {
  //   let urlNuvalid = environment.url

  //   //TODO hacer el servicio para consultar el paso dos el detalle de los estudios
  //   //TODO este >>>>>> .get<any>(`${urlNuvalid}/mpos/consultas?op=facuEstudios`, { headers: this.headers }).pipe(map((res: any) => {
  //   return this.http
  //   .get<any>(`${urlNuvalid}/mpos/consultas?op=facuGrupos_estudio`, { headers: this.headers }).pipe(map((res: any) => {
  //     return res;
  //   }));
  // }

}
