import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { redirectClass } from '@models/global.models';
import { datosPerfilModel } from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import { DesactivarLoading, removeDatosUsuario } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { errorGenerico } from 'errores';
import { Subscription } from 'rxjs';
import { ActivarLoading } from '../../store/actions/ui.actions';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  operaciones: Array<any> | null = null;
  datosPerfil: datosPerfilModel | null = null;
  lugarAtencion: string = 'Lugar de atención';

  constructor(
    private store: Store<AppState>,
    private globalService: GlobalService,
    private operacionesPerfilService: OperacionesPerfilService,
    private dialogsService: DialogsService,
    private router: Router
  ) {
    this.dialogsService.cargando();
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual?.role) {
          this.lugarAtencion = configuracionState.usuarioActual?.domicilio;
          this.operacionesPerfilService.getDatosPerfil().subscribe(
            (respuesta) => {
              if (respuesta) {
                this.datosPerfil = respuesta;
                this.dialogsService.cerrar();
              } else {
                this.dialogsService.cerrar();
                this.globalService.redirect(
                  new redirectClass(
                    'contactanos',
                    'Se produjo un error al buscar los datos del usuario, por favor comuniquese con nosotros',
                    'El body no traia los datos del perfil'
                  )
                );
              }
            },
            (error: HttpErrorResponse) => {
              this.dialogsService.cerrar();
              this.globalService.manageError(error);
            }
          );
          this.operaciones = this.setOPeraciones(
            configuracionState.usuarioActual?.role
          );
          if (this.operaciones.length == 0) {
            this.globalService.redirect(
              new redirectClass(
                'contactanos',
                'Se produjo un error al cargar el perfil, por favor comuniquese con nosotros',
                'No hay operaciones'
              )
            );
          }
        } else {
          this.dialogsService.cerrar();
          this.globalService.redirect(
            new redirectClass(
              'contactanos',
              'Se produjo un error al cargar el perfil, por favor comuniquese con nosotros',
              'No esta el rol del usuario'
            )
          );
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  verAdministradores() {
    //Boton para ver administradores cuando el usuario tiene cambios no aceptados
    this.operacionesPerfilService.getDatosAdministradores().subscribe(
      (respuesta) => {
        this.dialogsService.verAdministradoresModal(respuesta);
      },
      (error: HttpErrorResponse) => {
        this.globalService.manageError(error);
      }
    );
  }

  setOPeraciones(rol: string) {
    let ArrayConOperaciones = this.getOperacionesGenerales();
    switch (rol) {
      //Se buscan los nombres y funciones de las operaciones por rol del usuario
      case 'Prestador':
        return ArrayConOperaciones.concat(this.getOperacionesPrestador()).sort(
          (a, b) => (a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0)
        );

      case 'Recepcion':
        return ArrayConOperaciones.concat(this.getOperacionesRecepcion()).sort(
          (a, b) => (a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0)
        );
      case 'Administrador':
        return ArrayConOperaciones.concat(
          this.getOperacionesAdministrador()
        ).sort((a, b) =>
          a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0
        );

      default:
        return [];
    }
  }

  private getOperacionesGenerales(): Array<any> {
    return [
      {
        nombre: 'Actualizar datos',
        operacion: () => {
          this.router.navigate(['perfil/modificardatos']);
        },
      },
      {
        nombre: 'Ordenar Transacciones',
        operacion: () => {
          this.router.navigate(['perfil/ordenartransacciones']);
        },
      },
      {
        nombre: 'Ver administradores del lugar',
        operacion: () => {
          this.verAdministradores();
        },
      },
      {
        nombre: 'Darse de baja',
        operacion: () => {
          this.operacionDarseDeBaja();
        },
      },
    ];
  }

  private getOperacionesPrestador(): Array<any> {
    return [
      {
        nombre: 'Ver financiadores del usuario',
        operacion: () => {
          this.dialogsService.cargando('Buscando financiadores de prestador');
          this.store.dispatch(new ActivarLoading());
          this.operacionesPerfilService
            .getFinanciadoresPrestador(this.datosPerfil?.cuitPrestador)
            .subscribe(
              (respuesta) => {
                this.dialogsService.cerrar();
                this.store.dispatch(new DesactivarLoading());
                this.dialogsService.verFinanciadoresModal(respuesta);
              },
              (error: HttpErrorResponse) => {
                this.dialogsService.cerrar();
                this.store.dispatch(new DesactivarLoading());
                this.globalService.manageError(error);
              }
            );
        },
      },
    ];
  }

  private getOperacionesRecepcion(): Array<any> {
    return [
      {
        nombre: 'Seleccionar prestadores que no se desean ver',
        operacion: () => {
          this.router.navigate(['perfil/prestadoresnodeseados']);
        },
      },
    ];
  }

  private getOperacionesAdministrador(): Array<any> {
    return [
      {
        nombre: 'Cambiar rol de los usuarios',
        operacion: () => {
          this.router.navigate(['perfil/cambiarrol']);
        },
      },
      {
        nombre: 'Ver usuarios pendientes de aceptación',
        operacion: () => {
          this.router.navigate(['perfil/verpendientes']);
        },
      },
      {
        nombre: 'Ver cambios de usuarios',
        operacion: () => {
          this.router.navigate(['perfil/vercambios']);
        },
      },
      {
        nombre: 'Volver a activar usuario',
        operacion: () => {
          this.router.navigate(['perfil/activarusuarios']);
        },
      },
      {
        nombre: 'Eliminar usuarios',
        operacion: () => {
          this.router.navigate(['perfil/eliminarusuarios']);
        },
      },
    ];
  }

  operacionDarseDeBaja() {
    this.dialogsService
      .confirmarModal(
        '¿Esta seguro que desea darse de baja de este lugar de atención?'
      )
      .result.then((respuesta: any) => {
        if (respuesta && respuesta.respuesta) {
          if (respuesta && respuesta.respuesta) {
            this.dialogsService.cargando();
            this.operacionesPerfilService.darBajaUsuario().subscribe(
              (respuesta) => {
                this.dialogsService.cerrar();
                this.dialogsService.success(respuesta.message).then(() => {
                  this.store.dispatch(new removeDatosUsuario());
                });
              },
              (error: HttpErrorResponse) => {
                this.dialogsService.cerrar();
                this.globalService.manageError(error);
              }
            );
          }
        }
      })
      .catch(() => {
        this.store.dispatch(new DesactivarLoading());
        this.globalService.redirect(
          new redirectClass('contactanos', errorGenerico)
        );
      });
  }
}
