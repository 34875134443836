export class SugerenciaModel {
  nombre: String | null;
  apellido: String | null;
  email: String | null;
  telefono: String | null;
  mensaje: String | null;

  constructor(
    nombre: String | null,
    apellido: String | null,
    email: String | null,
    telefono: String | null,
    mensaje: String | null
  ) {
    this.nombre = nombre;
    this.apellido = apellido;
    this.email = email;
    this.telefono = telefono;
    this.mensaje = mensaje;
  }
}
