import { Action } from '@ngrx/store';
import * as models from '@store/models/store.models';
import { lugarAtencion } from '@models/lugarAtencion.model';
import { searchReturn } from '@models/global.models';

export const CARGAR_USUARIOINSTALACIONSUCCESS =
  '[Instalacion] Cargar usuario-instalacion success';

export const SELECCIONARLUGARATENCION =
  '[Instalacion] Seleccionar lugar atencion';

export const BUSQUEDA_REALIZADA = '[Instalacion] Busqueda realizada';
export const DESACTIVAR_BUSQUEDA = '[Instalacion] Desactivar busqueda';

export const BORRARDATOSINSTALACION = '[Instalacion] Borrar datos instalacion';
export class CargarUsuarioInstalacionSuccess implements Action {
  readonly type = CARGAR_USUARIOINSTALACIONSUCCESS;

  constructor(public usuarioInstalacion: models.InstalacionUsuario) {}
}

export class SeleccionarLugarAtencion implements Action {
  readonly type = SELECCIONARLUGARATENCION;

  constructor(public lugarAtencion: lugarAtencion | null) {}
}

export class busquedaRealizada implements Action {
  readonly type = BUSQUEDA_REALIZADA;

  constructor(public searchReturn: searchReturn) {}
}

export class desactivarBusqueda implements Action {
  readonly type = DESACTIVAR_BUSQUEDA;
}

export class borrarDatosInstalacion implements Action {
  readonly type = BORRARDATOSINSTALACION;
}

export type instalacionActions =
  | CargarUsuarioInstalacionSuccess
  | SeleccionarLugarAtencion
  | busquedaRealizada
  | desactivarBusqueda
  | borrarDatosInstalacion;
