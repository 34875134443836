import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { codigoInstalacion, redirectClass } from '@models/global.models';
import {
  DataUsuario,
  DatosUsuarioModificado,
} from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { errorGenerico } from 'errores';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cambiar-rol-usuarios',
  templateUrl: './cambiar-rol-usuarios.component.html',
})
export class CambiarRolUsuariosComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  titulo: string = 'Cambiar rol de los usuarios ';
  operacion: string = 'cambiarRolUsuarios'; // De esto depende los botones que se le mostraran al usuario
  usuarios: Array<DataUsuario> | null = null;
  codigoInstalacion: codigoInstalacion | null = null;

  constructor(
    private operacionesPerfilService: OperacionesPerfilService,
    private store: Store<AppState>,
    private globalService: GlobalService,
    private dialogsService: DialogsService
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (
          configuracionState.usuarioActual &&
          configuracionState.codigosInstalacion
        ) {
          let codInst = configuracionState.usuarioActual.codigoInstalacion;
          let codigoInstalacion = configuracionState.codigosInstalacion.find(
            (x) => x.codigoInstalacion == codInst
          );
          if (codigoInstalacion) {
            this.codigoInstalacion = codigoInstalacion;
          } else {
            this.globalService.redirect(
              new redirectClass(
                'contactanos',
                errorGenerico,
                'cambiar rol usuario No se encontro el codigo de instalacion actual'
              )
            );
          }
        } else {
          this.globalService.redirect(
            new redirectClass(
              'contactanos',
              errorGenerico,
              'cambiar rol usuario no hay datos en redux'
            )
          );
        }
      });
    this.refresh();
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  refresh(seAceptoUsuario = false) {
    //Se acepto usuario sirve para que no tire error si se acepto todos los usuarios
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService.getDatosTodosUsuarios().subscribe(
      (respuesta) => {
        this.usuarios = respuesta;
        this.dialogsService.cerrar();
        this.store.dispatch(new DesactivarLoading());
      },
      (error: HttpErrorResponse) => {
        if (seAceptoUsuario) {
          //Para que no se le muestre error si es que acepto todos los usuarios
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.usuarios = null;
        } else {
          this.usuarios = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      }
    );
  }

  cambiarRolUsuario(usuario: DataUsuario) {
    if (this.codigoInstalacion) {
      //Modal que pide el rol
      this.dialogsService
        .solicitarInfoModal(
          'Seleccione el rol que desea para este lugar de atención',
          'RolTodos',
          this.codigoInstalacion
        )
        .result.then((respuesta: any) => {
          if (respuesta && respuesta.respuesta) {
            //respuesta.respuesta trae el rol que se selecciono en el modal
            console.log(respuesta);

            this.store.dispatch(new ActivarLoading());
            this.operacionesPerfilService
              .cambiarRolUsuario(
                usuario.email,
                respuesta.respuesta.rol,
                respuesta.respuesta.cuitPrestadorSeleccionado
              )
              .subscribe(
                (respuesta) => {
                  this.store.dispatch(new DesactivarLoading());
                  this.dialogsService.toastSuccess(respuesta.message);
                  this.refresh(true);
                },
                (error: HttpErrorResponse) => {
                  this.store.dispatch(new DesactivarLoading());
                  this.globalService.manageError(error);
                  this.refresh(true);
                }
              );
          }
        })
        .catch(() => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.redirect(
            new redirectClass('contactanos', errorGenerico)
          );
        });
    } else {
      this.globalService.redirect(
        new redirectClass(
          'contactanos',
          errorGenerico,
          'No hay codigo instalacion'
        )
      );
    }
  }
}
