import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { redirectClass } from '@models/global.models';
import {
  DataUsuario,
  DatosUsuarioModificado,
} from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { errorGenerico } from 'errores';

@Component({
  selector: 'app-aceptar-cambios-usuario',
  templateUrl: './aceptar-cambios-usuario.component.html',
})
export class AceptarCambiosUsuarioComponent implements OnInit {
  titulo: string = 'Ver cambios de usuarios';
  operacion: string = 'aceptarCambiosUsuarios'; // De esto depende los botones que se le mostraran al usuario
  usuarios: Array<DatosUsuarioModificado> | null = null;
  usuariosDatosViejos: Array<DataUsuario> | null = null;

  constructor(
    private operacionesPerfilService: OperacionesPerfilService,
    private store: Store<AppState>,
    private globalService: GlobalService,
    private dialogsService: DialogsService
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(seAceptoUsuario = false) {
    //Se acepto usuario sirve para que no tire error si se acepto todos los usuarios
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService
      .getDatosUsuariosModificados()
      .subscribe(
        (respuesta) => {
          this.usuarios = respuesta;
          this.usuariosDatosViejos = respuesta.map((x) => x.usuario);
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
        },
        (error: HttpErrorResponse) => {
          if (seAceptoUsuario) {
            //Para que no se le muestre error si es que acepto todos los usuarios
            this.dialogsService.cerrar();
            this.store.dispatch(new DesactivarLoading());
            this.usuarios = null;
            this.usuariosDatosViejos = null;
          } else {
            this.usuarios = null;
            this.usuariosDatosViejos = null;
            this.dialogsService.cerrar();
            this.store.dispatch(new DesactivarLoading());
            this.globalService.manageError(error);
          }
        }
      );
  }

  verModificacionUsuario(usuario: DataUsuario) {
    try {
      if (this.usuarios) {
        let datos = this.usuarios.find((x) => x.usuario == usuario);
        if (datos) {
          let datosNuevos: DataUsuario = datos.usuarioModificado;
          this.dialogsService
            .verDatosDeUsuarioModificado(
              [datosNuevos],
              'Estos son los cambios que solicito el usuario, ¿Desea aceptarlos?',
              'verDatosSolicitados'
            )
            .result.then((respuesta: any) => {
              if (respuesta) {
                this.store.dispatch(new ActivarLoading());
                this.dialogsService.cargando();
                if (respuesta.aceptar) {
                  this.operacionesPerfilService
                    .aceptarCambiosUsuarios(respuesta.respuesta.id)
                    .subscribe(
                      (respuestaObtenida) => {
                        this.store.dispatch(new DesactivarLoading());
                        this.dialogsService.toastSuccess(
                          respuestaObtenida.message
                        );
                        this.refresh(true);
                      },
                      (error: HttpErrorResponse) => {
                        this.store.dispatch(new DesactivarLoading());
                        this.dialogsService.cerrar();
                        this.globalService.manageError(error);
                      }
                    );
                } else {
                  this.operacionesPerfilService
                    .rechazarCambiosUsuarios(respuesta.respuesta.id)
                    .subscribe(
                      (respuestaObtenida) => {
                        this.store.dispatch(new DesactivarLoading());
                        this.dialogsService.toastSuccess(
                          respuestaObtenida.message
                        );
                        this.refresh(true);
                      },
                      (error: HttpErrorResponse) => {
                        this.store.dispatch(new DesactivarLoading());
                        this.dialogsService.cerrar();
                        this.globalService.manageError(error);
                      }
                    );
                }
              }
            })
            .catch((error: any) => {
              this.globalService.redirect(
                new redirectClass('contactanos', errorGenerico, error)
              );
            });
        } else {
          throw 'No hay informacion sobre los datos nuevos del usuario';
        }
      } else {
        throw 'No hay usuarios';
      }
    } catch (error) {
      this.globalService.redirect(
        new redirectClass('contactanos', errorGenerico, error)
      );
    }

    // this.operacionesAdministradorService
    //   .eliminarUsuarios(arrayAceptarUsuarios)
    //   .subscribe(
    //     (respuesta) => {
    //       this.dialogsService.toastSuccess(respuesta.message);
    //       this.refresh(true);
    //     },
    //     (error: HttpErrorResponse) => {
    //       if (error.status == 406) {
    //         this.dialogsService.errorHTML(error.error.message).then(() => {
    //           this.location.back();
    //           this.store.dispatch(new DesactivarLoading());
    //         });
    //       } else {
    //         this.store.dispatch(new DesactivarLoading());
    //         this.globalService.manageError(error);
    //       }
    //     }
    //   );
  }
}
