import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MapComponent } from '@components/instalacion/map/map.component';
import {
  codigoInstalacion,
  filteredMarkers,
  redirectClass,
  showAlert,
} from '@models/global.models';
import { lugarAtencion } from '@models/lugarAtencion.model';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { GlobalService } from '@services/global.service';
import { InstalacionService } from '@services/instalacion.service';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ActivarLoading,
  DesactivarLoading,
  setCodigoInstalacion,
} from '@store/actions';
import { DialogsService } from '@services/dialogs.service';
import { erroresSelecccionarLugarAtencion } from 'errores';
import { Router } from '@angular/router';
import { InstalacionUsuario } from '@store/models/store.models';
import { SeleccionarLugarAtencion, desactivarBusqueda, borrarDatosInstalacion } from '@store/actions';
import { SELECCIONARLUGARATENCION } from '../../../store/actions/instalacion.actions';
import { Form, FormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-lugar-atencion',
  templateUrl: './select-lugar-atencion.component.html',
  styleUrls: ['./select-lugar-atencion.component.scss'],
})
export class SelectLugarAtencionComponent implements OnInit, OnDestroy {

  cargando: boolean = false;
  lugarAtencionSeleccionado: lugarAtencion | null = null;
  usuarioInstalacion: InstalacionUsuario | null = null;
  lugaresAtencion: Array<lugarAtencion> = [];
  prestadores: Array<Prestador> = [];
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionStoreInstalacion: Subscription = new Subscription();
  lugaresAMostrar: Array<lugarAtencion> | null = null;
  tipoPrestador: string = ''

  volverALogin: boolean = false;
  mostrarSiguiente: boolean = false;

  //TODO consultar al back los tipos de prestadores para instalar un cuit
  prestadorRaro: string = '';


  @ViewChild('map') map: MapComponent | null = null;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private instalacionService: InstalacionService,
    private globalService: GlobalService,
    private router: Router,
    private dialogsService: DialogsService
  ) {
    this.subscriptionStore = this.store.subscribe((state: AppState) => {
      this.cargando = state.ui.loading;
      let domicilios = state.configuracion?.codigosInstalacion || [];
      console.log('Domicilios ya cargados:', domicilios);


      this.lugaresAtencion =
        state.instalacion.usuarioInstalacion?.lugares_atencion || [];

      let aMostrar = [];
      for (var i = 0; i < this.lugaresAtencion.length; i++) {
        let igual = false;
        for (var j = 0; j < domicilios.length && !igual; j++) {
          if (this.lugaresAtencion[i]['domicilio'] == domicilios[j]['domicilio'])
            igual = true;
        }
        if (!igual) aMostrar.push(this.lugaresAtencion[i]);
      }
      console.log('A mostrar: ', aMostrar);

      this.lugaresAMostrar = aMostrar;


      console.log('lugaresAtencion: ', this.lugaresAtencion);


      if (
        state.instalacion.usuarioInstalacion == null ||
        state.instalacion.usuarioInstalacion.lugares_atencion == null
      ) {
        this.globalService.redirect(
          new redirectClass(
            'contactanos',
            erroresSelecccionarLugarAtencion.mensaje1,
            'No se encontraron lugares de atención activos para el prestador selecionado',
            new showAlert(
              true,
              'No se encontraron lugares de atención activos para el prestador selecionado'
            )
          )
        );
      } else {
        this.usuarioInstalacion = state.instalacion.usuarioInstalacion;
      }
    });

  }

  ngOnInit(): void {

    this.subscriptionStoreInstalacion = this.store.select('instalacion').subscribe((datos) => {
      console.log('hay algo acá??', datos.usuarioInstalacion);
      this.tipoPrestador = datos.usuarioInstalacion?.tipo_prestador!
    })

  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreInstalacion.unsubscribe();
  }



  elegirLugar(lugarSelecc: lugarAtencion) {
    console.log('ELEGIR LUGAR CLIQUEADO');
    this.store.dispatch(new SeleccionarLugarAtencion(lugarSelecc));

    console.log('Lugar atencion seleccionado');

    this.instalacionService.getPrestadoresDeLugarAtencion(lugarSelecc.licencias)
      .pipe(take(1))
      .subscribe(
        (respuesta: Array<Prestador>) => {
          this.prestadores = respuesta;
          this.dialogsService.confirmarPrestadoresModal(this.prestadores)
            .result.then((respuesta: string) => {
              //Las respuesta que puede devolver
              switch (respuesta) {
                case 'error':
                  console.log('Entro al if error');
                  this.store.dispatch(new DesactivarLoading());
                  this.globalService.redirect(
                    new redirectClass(
                      'contactanos',
                      erroresSelecccionarLugarAtencion.mensaje3,
                      'No encontro los prestadores correctos en el lugar de atención',
                      new showAlert(false, '')
                    )
                  );
                  break;

                case 'si':
                  if (
                    lugarSelecc == null ||
                    this.usuarioInstalacion == null
                  ) {
                    this.store.dispatch(new borrarDatosInstalacion());
                    return;
                  }
                  // manganeta para instalar un tipo_prestador raro
                  if (this.usuarioInstalacion.tipo_prestador === 'Bioquimico Sin NBU') {
                    this.prestadorRaro = 'Medicina'
                    this.instalacionService.getCodigoInstalacion(lugarSelecc, this.prestadorRaro)
                    .pipe(take(1))
                    .subscribe((respuesta: codigoInstalacion) => {
                      this.store.dispatch(new setCodigoInstalacion([respuesta]));
                      //Mete el codigo de instalacion y la direccion en el redux y en el localstorage
                      // map.stopListeners(); //Dejo de escuchar los markers del componenete hijo //modificacion comentada por MICA
                      // this.store.dispatch(new DesactivarLoading()); //comentado por MICA
                      if (this.tipoPrestador !== 'Farmacia') {
                        this.router.navigate(['login']);
                      }else{
                        // this.router.navigate(['loginFarmacia']);
                        this.router.navigate(['login']);
                      }
                    },
                      (error: HttpErrorResponse) => {
                        // this.store.dispatch(new DesactivarLoading());//comentado por MICA
                        this.globalService.manageError(error);
                      }
                    );

                  } else {
                    this.instalacionService.getCodigoInstalacion(lugarSelecc, this.usuarioInstalacion.tipo_prestador)
                      .pipe(take(1))
                      .subscribe((respuesta: codigoInstalacion) => {
                        this.store.dispatch(new setCodigoInstalacion([respuesta]));
                        //Mete el codigo de instalacion y la direccion en el redux y en el localstorage
                        // map.stopListeners(); //Dejo de escuchar los markers del componenete hijo //modificacion comentada por MICA
                        // this.store.dispatch(new DesactivarLoading()); //comentado por MICA
                        if (this.tipoPrestador !== 'Farmacia') {
                          this.router.navigate(['login']);
                        }else{
                          // this.router.navigate(['loginFarmacia']);
                          this.router.navigate(['login']);
                        }
                      },
                        (error: HttpErrorResponse) => {
                          // this.store.dispatch(new DesactivarLoading());//comentado por MICA
                          this.globalService.manageError(error);
                        }
                      );
                    }
                    break;

                default:
                  // this.store.dispatch(new DesactivarLoading());
                  break;
              }
            })
            .catch((error) => {
              this.store.dispatch(new DesactivarLoading());
              this.globalService.redirect(
                new redirectClass(
                  'contactanos',
                  erroresSelecccionarLugarAtencion.mensaje4,
                  error.error + ' , ' + error.message,
                  new showAlert(false, '')
                )
              );
            });
        },
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }
  contacto() {
    // this.router.navigate(['contactanos'])
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }

  irALogin() {
    this.router.navigate(['login']);
  }
}
