import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import {
  setEstadoFormEfectorRequest,
  setEstadoFormPrescriptorRequest,
  setNuevoToken,
  unsetCasiTodosDatos,
  unsetEfectorRequest,
  unsetEstadoFormDatosCasiTodos,
  unsetPrescriptorRequest,
} from '@store/actions';
import { take } from 'rxjs/operators';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { codigoNombreModel } from '../../../../models/datosInicialesExtras.models';
import { unsetPreautorizacionRequest } from '../../../../store/actions/datosTransacciones.actions';
import { setEstadoFormPreautorizacionRequest } from '../../../../store/actions/estadoFormsinicio.action';
import { GlobalService } from '../../../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-informar-transaccion',
  templateUrl: './informar-transaccion.component.html',
  styleUrls: ['./informar-transaccion.component.scss'],
})
export class InformarTransaccionComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();

  @Input() transaccion: codigoNombreModel | null = null;
  esRescate: boolean = false;

  mostrarCargarPreautorizacion: boolean = false;
  mostrarCargarOtros: boolean = false;
  mostrarCargarPrescriptor: boolean = false;
  mostrarCargarEfector: boolean = false;
  tipoPrestador: string = '';
  tituloCard: string = '';
  error!: HttpErrorResponse;

  codigoTransaccion: any;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private globalService: GlobalService) { }

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion = datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.tituloCard = datos.datosInicio.TransaccionRequest?.NombreTransaccion || '';
      this.esRescate = datos.recetas.EsRecetaRequest ?? false;
    });
  }


  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();

  }
  toogleCargarPrescriptor() {
    this.mostrarCargarPrescriptor = !this.mostrarCargarPrescriptor;
    if (!this.mostrarCargarPrescriptor) {
      this.store.dispatch(new unsetPrescriptorRequest());
      this.store.dispatch(new setEstadoFormPrescriptorRequest(null));
    }
  }

  toogleCargarEfector() {
    this.mostrarCargarEfector = !this.mostrarCargarEfector;
    if (!this.mostrarCargarEfector) {
      this.store.dispatch(new unsetEfectorRequest());
      this.store.dispatch(new setEstadoFormEfectorRequest(null));
    }
  }

  toogleCargarOtros() {
    this.mostrarCargarOtros = !this.mostrarCargarOtros;
    if (!this.mostrarCargarOtros) {
      this.store.dispatch(new unsetCasiTodosDatos());
      this.store.dispatch(new unsetEstadoFormDatosCasiTodos());
    }
  }

  toogleCargarPreautorizacion() {
    this.mostrarCargarPreautorizacion = !this.mostrarCargarPreautorizacion;
    if (!this.mostrarCargarPreautorizacion) {
      this.store.dispatch(new unsetPreautorizacionRequest());
      this.store.dispatch(new setEstadoFormPreautorizacionRequest(null));
    }
  }

  renovarToken() {
    this.subscriptionStore = this.store
      .select('configuracion')
      .pipe(take(1))
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.authService.esTokenValido().subscribe(
            (respuesta) => {
              if (respuesta.token) {
                this.store.dispatch(new setNuevoToken(respuesta.token));
              }
            },
            (error: HttpErrorResponse) => {
              this.globalService.manageError(error);
            }
          );
        }
      });
  }

}
