import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { codigoInstalacion } from '@models/global.models';
import { Prestador } from '@models/prestador.model';
import { roles, SignUpModel } from '@models/signup.models';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RegistracionService implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  codigosInstalacion: Array<codigoInstalacion> | null = null;
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  ngOnInit() {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((appState) => {
        this.codigosInstalacion = appState.codigosInstalacion;
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  registrarUsuario(userNuevo: SignUpModel): Observable<any> {
    console.log(userNuevo);

    return this.http
      .post(environment.url + '/usuarios/signup', userNuevo)
      .pipe(take(1));
  }

  getRolesAMostrar(
    codigoInstalacion: codigoInstalacion
  ): Observable<Array<roles>> {
    return this.http
      .get(
        environment.url + `/usuarios/${codigoInstalacion.codigoInstalacion}/all`
      )
      .pipe(
        map((res) => {
          let array: Array<roles> = [];
          if (res) {
            if (codigoInstalacion.tipoInstalacion == 'NUVAMED') {
              array = ['Recepcion', 'Prestador'];
            } else if (codigoInstalacion.tipoInstalacion == 'NUVAFARM') {
              array = ['Administracion', 'Farmaceutico'];
            }
            return array;
          } else {
            array = ['Administrador'];
            return array;
          }
        })
      )
      .pipe(take(1));
  }

  getPrestadoresDeLugarAtencion(
    codigoInstalacion: string
  ): Observable<Array<Prestador>> {
    return this.http
      .get<Array<Prestador>>(
        environment.url +
          `/config/prestadores/by_instalacion/${codigoInstalacion}`
      )
      .pipe(take(1));
  }
}
