<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../../sass/estilos.scss"> -->
</head>
<div class="container__panel1">
    <div class="container__afiliado">
        <p class="tittle__dashboard tittle__financiers">Ingrese afiliado/a <span class="span-required">*</span>
        </p>
        <div>

            <form [formGroup]="form" action="aplicaciones" class="form-infoN">

                <div class="containerInputA">
                    <label for="input-credencial" style="margin-right: 4em !important;">N° de Credencial</label>

                    <ng-container
                        *ngIf="financiadorSeleccionado && mascarasValidas.includes(financiadorSeleccionado.SiglaFinanciador) ; then formEspecial else formNormal">
                    </ng-container>

                    <!--  <small class="text-danger animated fadeIn" *ngIf="form.controls['numeroAfiliado'].touched && form.controls['numeroAfiliado'].errors">El
            número del afiliado ingresado no es válido</small> -->


                    <ng-template #formNormal>
                        <input type="text" id="input-credencial" class="form-controlN" name="numeroAfiliado" formControlName="numeroAfiliado" autocomplete=off placeholder="Número de afiliado" [ngClass]="{'form-controlNError': form.controls['numeroAfiliado'].touched && form.controls['numeroAfiliado'].errors}">
                    </ng-template>

                    <ng-template #formEspecial>
                        <ng-container *ngIf="financiadorSeleccionado?.SiglaFinanciador == 'OSDE'">
                            <ng-container *ngTemplateOutlet="osdeInput"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="financiadorSeleccionado?.SiglaFinanciador == 'SWISS'">
                            <ng-container *ngTemplateOutlet="swissInput"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="financiadorSeleccionado?.SiglaFinanciador == 'OMINT'">
                            <ng-container *ngTemplateOutlet="omintInput"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="financiadorSeleccionado?.SiglaFinanciador == 'SERVESALUD'">
                            <ng-container *ngTemplateOutlet="servesaludInput"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="financiadorSeleccionado?.SiglaFinanciador == 'HOPE'">
                            <ng-container *ngTemplateOutlet="hopeInput"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="financiadorSeleccionado?.SiglaFinanciador == 'ACA Salud'">
                            <ng-container *ngTemplateOutlet="acaInput"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="financiadorSeleccionado?.SiglaFinanciador == 'PREMED'">
                            <ng-container *ngTemplateOutlet="premedicInput"></ng-container>
                        </ng-container>
                    </ng-template>


                    <ng-template #osdeInput>
                        <input id="input-credencial" class="form-controlN input-credencialAlert" [imask]=osdeMask
                            [unmask]="true" formControlName="numeroAfiliado" placeholder="__ | ______ | _ | __" />
                    </ng-template>

                    <ng-template #swissInput>
                        <input id="input-credencial" class="form-controlN input-credencialAlert" [imask]=swissMask
                            [unmask]="true" formControlName="numeroAfiliado" placeholder="800006 _______ __ ____" />
                    </ng-template>

                    <ng-template #omintInput>
                        <input id="input-credencial" class="form-controlN input-credencialAlert" [imask]=omintMask
                            [unmask]="true" formControlName="numeroAfiliado" placeholder="_ _______ __ __ _" />
                    </ng-template>

                    <ng-template #servesaludInput>
                        <input id="input-credencial" class="form-controlN input-credencialAlert" [imask]=servesaludMask
                            [unmask]="true" formControlName="numeroAfiliado" placeholder="________" />
                    </ng-template>

                    <ng-template #hopeInput>
                        <input id="input-credencial" class="form-controlN input-credencialAlert" [imask]=hopeMask
                            [unmask]="true" formControlName="numeroAfiliado" placeholder="_-________" />
                    </ng-template>

                    <ng-template #acaInput>
                        <input id="input-credencial" class="form-control" [imask]=acaMask [unmask]="true"
                            formControlName="numeroAfiliado" placeholder="________" />
                    </ng-template>

                    <ng-template #premedicInput>
                        <input id="input-credencial" class="form-controlN input-credencialAlert" [imask]=premedicMask
                            [unmask]="true" formControlName="numeroAfiliado" placeholder="_____________" />
                    </ng-template>
                </div>


            </form>

            <ng-container
                *ngIf="form.controls['numeroAfiliado'].touched && form.controls['numeroAfiliado'].errors; then reminderError">
            </ng-container>
            <ng-template #reminderError>
                <div class="reminder reminder__alertA">
                    <div>
                        <img src="./assets/design/alert.svg" alt="alert" id="alert.svg__img">
                    </div>
                    <p class="reminder__text reminder__textAlert reminder__textAlertAfiliado">
                        El número del afiliado ingresado no es válido. Puede comunicarse con nuestro centro de soporte
                        haciendo <a target="_blank" class="reminder__link" (click)="contacto()">
                            click aquí.</a>
                    </p>
                </div>
            </ng-template>
        </div>
    </div>
</div>

</html>
