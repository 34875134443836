import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';

interface cambiarContrasennaForm {
  newPassword: string;
  confirmNewPassword: string;
  oldPassword: string;
}
@Component({
  selector: 'app-cambiar-contrasenna-modal',
  templateUrl: './cambiar-contrasenna-modal.component.html',
  styleUrls: ['./cambiar-contrasenna-modal.component.scss'],
})
export class CambiarContrasennaModalComponent implements OnInit {
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<cambiarContrasennaForm> =
    this.formBuilder.group<cambiarContrasennaForm>(
      {
        newPassword: new UntypedFormControl(<ControlState<string>>'', [
          Validators.required,
          blankSpaceValidator,
          Validators.minLength(6),
        ]),
        confirmNewPassword: new UntypedFormControl(<ControlState<string>>'', [
          Validators.required,
          blankSpaceValidator,
          Validators.minLength(6),
        ]),
        oldPassword: new UntypedFormControl(<ControlState<string>>'', [
          Validators.required,
          blankSpaceValidator,
          Validators.minLength(6),
        ]),
      },
      { validator: matchingPasswords('newPassword', 'confirmNewPassword') }
    );
  submitted: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  devolverRespuesta(rta: boolean) {
    this.submitted = true;
    if (rta) {
      if (this.form.invalid) {
        return;
      }
      this.activeModal.close({
        respuesta: {
          oldPassword: this.form.getRawValue().oldPassword,
          newPassword: this.form.getRawValue().newPassword,
        },
      });
    } else {
      this.activeModal.close({ respuesta: null });
    }
  }
}

//Metodo que verifica que las los dos campos donde se ingresa
//la contraseña coincidan
function matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
  return (group: UntypedFormGroup): { [key: string]: any } | null => {
    let password = group.controls[passwordKey];
    let confirmPassword = group.controls[confirmPasswordKey];
    if (password.value !== confirmPassword.value) {
      return {
        mismatchedPasswords: true,
      };
    } else {
      return null;
    }
  };
}
