import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { setEstadoFormAcompannanteRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { setMensajeParaFinanciador } from '../../../../store/actions/datosTransacciones.actions';
@Component({
  selector: 'app-datos-mensaje-para-financiador',
  templateUrl: './datos-mensaje-para-financiador.component.html',
  styleUrls: ['./datos-mensaje-para-financiador.component.scss'],
})
export class DatosMensajeParaFinanciadorComponent implements OnInit {
  private subscriptionForm: Subscription = new Subscription();
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<any> = this.formBuilder.group<any>({
    mensajeFinanciador: new UntypedFormControl(<ControlState<string>>null),
  });
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(new setEstadoFormAcompannanteRequest(true));
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.store.dispatch(
        new setMensajeParaFinanciador(
          this.form.getRawValue().mensajeFinanciador
        )
      );
    });
  }
}
