import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DatosTicketModel, TicketFiltrosModel } from '@models/ticket.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { TicketService } from '@services/ticket.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { setFilterTicketsRequest } from '../../store/actions/filterTickets.action';
import { Subscription } from 'rxjs';
import { Financiador } from '@models/financiador.model';
import { Prestador } from '@models/prestador.model';
import { IpcService } from '@services/ipc.service';
import { datosInicioState, datosTransaccionesState } from '@store/models/store.models';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  private subscriptionForm: Subscription = new Subscription();
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionStoreConfiguracion: Subscription = new Subscription();

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<TicketFiltrosModel> =
    this.formBuilder.group<TicketFiltrosModel>({
      FechaDesde: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator
      ]),
      FechaHasta: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator
      ]),
      Financiador: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator,
      ]),
      Prestador: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator,
      ]),
      Afiliado: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator,
      ])
    });

  tickets: Array<DatosTicketModel> | null = null;
  cuitFinanciadorSeleccionado: string | null = null;
  financiadores: Array<Financiador> = [];
  finanTicket: Array<Financiador> = [];
  codigoInstalacion: string = '';
  prestadores: Array<Prestador> = [];
  datosTransaccion: datosTransaccionesState | null = null;
  datosInicio: datosInicioState | null = null
  fd: any = null;
  fh: any = null;
  page: number = 1;
  pageSize: number = 10;
  totalElements: number = 1;
  fchDesdeTicket: string = '';
  fchHastaTicket: string = '';
  financiadorTicket: string = '';
  prestadorTicket: string = '';
  afiliadoTicket: string = '';

  constructor(
    private store: Store<AppState>,
    private ticketService: TicketService,
    private dialogsService: DialogsService,
    private globalService: GlobalService,
    private ipcService: IpcService,
    private localeService: BsLocaleService,
  ) {
    this.dialogsService.cargando();
    defineLocale('es', esLocale);
  }

  ngOnInit(): void {
    this.getTickets();
    this.getFinanciadoresTickets();
    this.cargarFinanciadores();
    this.setDefaultValues();
    this.localeService.use('es');

    this.subscriptionStore = this.store.subscribe(
      (datos) => {
        this.datosTransaccion = datos.datosTransaccion
        this.datosInicio = datos.datosInicio
      }
    )
  }

  setDefaultValues() {

    this.fd = new Date();
    //setea fecha 1 mes atrás
    this.fd.setMonth(this.fd.getMonth() - 1);
    this.form.controls['FechaDesde'].setValue(this.fd);
    this.form.controls['FechaDesde'].setValidators(Validators.required);

    this.fh = new Date();
    //setea fecha de hoy
    this.fh.setDate(this.fh.getDate());
    this.form.controls['FechaHasta'].setValue(this.fh);
    this.form.controls['FechaHasta'].setValidators(Validators.required);

  }

  getTickets(sFinanciador: string = '') {
    this.store.dispatch(new ActivarLoading());
    this.ticketService.getDatosTickets(this.page - 1, this.pageSize, this.financiadorTicket,
      this.fchDesdeTicket, this.fchHastaTicket, this.prestadorTicket, this.afiliadoTicket, sFinanciador)
      .subscribe(
        (resp) => {
          console.log('el ticket', resp);
          this.tickets = resp.content;
          this.totalElements = resp.totalElements;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
        },
        (error: HttpErrorResponse) => {
          this.tickets = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          // this.globalService.manageError(error);
        }
      )
  }

  getFinanciadoresTickets(){
    this.ticketService.getFinanciadoresTickets().subscribe(
      (resp) => {
        this.finanTicket = resp
      }
    )
  }

  cargarFinanciadores() {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((datos) => {
        this.financiadores = datos.datosIniciales?.financiadores!
        this.prestadores = datos.datosIniciales?.prestadores!
      });
  }

  guardar() {
    let datos = this.form.getRawValue();

    //Convierto la fecha en AAAA-MM-DD
    let datefd = new Date('' + datos.FechaDesde);
    let mnthfd = ("0" + (datefd.getMonth() + 1)).slice(-2);
    let dayfd = ("0" + datefd.getDate()).slice(-2);
    let fechaDesde = [datefd.getFullYear(), mnthfd, dayfd].join("");
    this.fchDesdeTicket = fechaDesde

    //Convierto la fecha en AAAA-MM-DD
    let datefh = new Date('' + datos.FechaHasta);
    let mnthfh = ("0" + (datefh.getMonth() + 1)).slice(-2);
    let dayfh = ("0" + datefh.getDate()).slice(-2);
    let fechaHasta = [datefh.getFullYear(), mnthfh, dayfh].join("");
    this.fchHastaTicket = fechaHasta

    let financiador = this.form.controls['Financiador'].getRawValue();
    this.financiadorTicket = financiador;

    let prestador = this.form.controls['Prestador'].getRawValue();
    this.prestadorTicket = prestador;

    let afiliado = this.form.controls['Afiliado'].getRawValue();
    this.afiliadoTicket = afiliado;

    let ticketRequest = new TicketFiltrosModel(
      fechaDesde,
      fechaHasta,
      financiador,
      prestador,
      afiliado
    );
    this.store.dispatch(new setFilterTicketsRequest(ticketRequest));
    this.getTickets();
  }

  anularTicket(ticket: any) {
    this.dialogsService.confirmarModal(
      '¿Está seguro que desea anular el ticket?'
    ).result.then((respuesta: any) => {
      if (respuesta && respuesta.respuesta) {
        this.store.dispatch(new ActivarLoading());
        this.dialogsService.timeLoading();
        this.dialogsService.anularTransaccion(
          this.datosTransaccion!,
          this.datosInicio!,
          this.prestadores,
          ticket
        )
      } else {
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.dialogsService.cerrar();
          this.globalService.manageError(error);
        }
      }
    });
    this.getTickets();
    // this.dialogsService.anularTransaccion(
    //   this.datosTransaccion!,
    //   this.datosInicio!,
    //   this.prestadores,
    //   ticket
    // );
  }
  imprimirTicket(ticket: any) {
    this.ipcService.imprimirListadoTicket(ticket);
  }

  ngOnDestroy() {
    this.subscriptionForm.unsubscribe();
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreConfiguracion.unsubscribe();
  }

  openModal(){
    this.dialogsService.downloadModal();
  }

}
