import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { InputPrestacionesComponent } from '@components/input-prestaciones/input-prestaciones.component';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DetalleProcedimientoRequestModel } from '@models/datosTransaccion.models';
import { prestacionCodigoDescModel } from '@models/global.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { setDetallesProcedimientosRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { setEstadoFormDetallesProcedimientosRequest, setEstadoFormCodigoDeSeguridadRequest } from '../../../../../store/actions/estadoFormsinicio.action';
import { DialogsService } from '../../../../../services/dialogs.service';

@Component({
  selector: 'app-datos-detalle-procedimiento-request-form',
  templateUrl: './datos-detalle-procedimiento-request-form.component.html',
  styleUrls: ['./datos-detalle-procedimiento-request-form.component.scss'],
})
export class DatosDetalleProcedimientoRequestFormComponent
  implements OnInit, OnDestroy {
  @ViewChild(InputPrestacionesComponent)
  inputPrestacionesComponent!: InputPrestacionesComponent;
  submitted: boolean = false;
  @ViewChild("cantidadSolicitada")
  inputCantidadSolicitada!: ElementRef;


  mostrarInputDescripcion: boolean = false;


  private subscriptionStore: Subscription = new Subscription();
  private subscriptionStoreUI: Subscription = new Subscription();

  codigoTransaccion: string | null = null;
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<DetalleProcedimientoRequestModel> =
    this.formBuilder.group<DetalleProcedimientoRequestModel>({
      NroItem: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CodPrestacion: new UntypedFormControl(<ControlState<string>>{ value: null, disabled: true }, [
        blankSpaceValidator,
      ]),
      CodAlternativo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      PiezaDental: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CaraDental: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoPrestacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      ArancelPrestacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CantidadSolicitada: new UntypedFormControl(<ControlState<string>>'1' || null, [
        Validators.required,
        blankSpaceValidator,
        // Validators.min(1),
        Validators.pattern('[0-9]+'),
      ]),
      CodDiagnosticoPrestacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CodRta: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      DescripcionPrestacion: new UntypedFormControl({ value: null, disabled: true }),
      Facturacion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  detallesProcediminetos: Array<DetalleProcedimientoRequestModel> = [];
  tiposPrestaciones: Array<any> = [];
  tiposAranceles: Array<any> = [];
  tiposCaraDental: Array<any> = [];
  indexDetalleProcediminetoAEditar: number | null = null;
  errorLength = false;
  constructor(
    private store: Store<AppState>,
    private dialogsServices: DialogsService
  ) {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.detallesProcediminetos =
        datos.datosTransaccion.DetalleProcedimientoRequest || [];
      this.tiposPrestaciones =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoPrestacion || [];
      this.tiposAranceles =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoArancel || [];
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreUI.unsubscribe();
  }

  ngOnInit(): void {
    this.listenForEdit();
    this.setDefaultValues();
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          this.form.controls['CodPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['ArancelPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['CantidadSolicitada'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+'),
          ]);
          break;
        case '02B':
          this.form.controls['CodPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['ArancelPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['CantidadSolicitada'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+'),
          ]);
          break;
        case '02L':
          this.form.controls['CodPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['ArancelPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['CantidadSolicitada'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+'),
          ]);
          break;
        case '02D':
          this.form.controls['CodPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['ArancelPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['CantidadSolicitada'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+'),
          ]);
          break;
        case '02C':
          this.form.controls['CodPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['ArancelPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['CantidadSolicitada'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+'),
          ]);
          break;
        case '02S':
          this.form.controls['CodPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['ArancelPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['CantidadSolicitada'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+'),
          ]);
          break;
        case '02Q':
          this.form.controls['CodPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['ArancelPrestacion'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['CantidadSolicitada'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+'),
          ]);
          break;
        default:
          break;
      }

      /*
       this.store.dispatch(
         new setEstadoFormDetallesProcedimientosRequest(this.form.valid)
       );
       */
    }
  }

  setDefaultValues() {
    let prestacion = this.tiposPrestaciones.find(
      (x) => x.nombre == 'Ambulatoria'
    );
    this.form.controls['TipoPrestacion'].setValue(prestacion?.codigo || null);

    let arancel = this.tiposAranceles.find(
      (x) => x.nombre == 'Todos los honorarios que correspondan a la prestación'
    );
    this.form.controls['ArancelPrestacion'].setValue(arancel?.codigo || null);
  }

  selectPrestacion(prestacion: prestacionCodigoDescModel | null) {
    //Evento emitido desde el typeahead
    console.log('Prestacion1: ', prestacion?.codigo);
    if (prestacion) {
      console.log('Prestacion: ', prestacion.descripcion);
      this.form.controls['CodPrestacion'].setValue(prestacion.codigo);
      this.form.controls['DescripcionPrestacion'].setValue(
        (prestacion.descripcion ? prestacion.descripcion : '')
      );
      this.inputCantidadSolicitada.nativeElement.focus();
    } else {
      this.form.controls['CodPrestacion'].setValue(null);
      this.form.controls['DescripcionPrestacion'].setValue(null);
      this.form.controls['CantidadSolicitada'].setValue('1');
      this.errorLength = false;
    }
  }

  cambioCodigoPrestacion() {
    this.form.controls['DescripcionPrestacion'].setValue(null);
    this.inputPrestacionesComponent.clean();
  }

  listenForEdit() {
    //Si se quiere modificar un detalleProcedimiento se enviara el index por el store
    this.subscriptionStoreUI = this.store.select('ui').subscribe((datos) => {
      let index = datos.indexDetalleProcediminetoAEditar;
      if (index != null && index != this.indexDetalleProcediminetoAEditar) {
        this.indexDetalleProcediminetoAEditar = index;
        this.modificarDetalleProcedimineto(index);
      }
    });
  }

  modificarDetalleProcedimineto(index: number) {
    let detalleProcedimineto = this.detallesProcediminetos[index];

    this.form.controls['NroItem'].setValue(
      detalleProcedimineto.NroItem || null
    );
    this.form.controls['CodPrestacion'].setValue(
      detalleProcedimineto.CodPrestacion || null
    );
    this.form.controls['CodAlternativo'].setValue(
      detalleProcedimineto.CodAlternativo || null
    );
    this.form.controls['PiezaDental'].setValue(
      detalleProcedimineto.PiezaDental || null
    );
    this.form.controls['CaraDental'].setValue(
      detalleProcedimineto.CaraDental || null
    );
    this.form.controls['TipoPrestacion'].setValue(
      detalleProcedimineto.TipoPrestacion || null
    );
    this.form.controls['ArancelPrestacion'].setValue(
      detalleProcedimineto.ArancelPrestacion || null
    );
    this.form.controls['CantidadSolicitada'].setValue(
      detalleProcedimineto.CantidadSolicitada || null
    );
    this.form.controls['CodDiagnosticoPrestacion'].setValue(
      detalleProcedimineto.CodDiagnosticoPrestacion || null
    );
    this.form.controls['CodRta'].setValue(detalleProcedimineto.CodRta || null);
    this.form.controls['DescripcionPrestacion'].setValue(
      detalleProcedimineto.DescripcionPrestacion
    );
    this.form.controls['Facturacion'].setValue(
      detalleProcedimineto.Facturacion || null
    );
  }

  guardar() {
    this.submitted = true;
    if (!this.form.controls['CodPrestacion'].value?.length) {
      this.errorLength = true;
    }
    if (this.form.invalid || !this.form.controls['CodPrestacion'].value?.length) {
      this.dialogsServices.toastError('Los datos ingresados son incorrectos');
      return;
    }
    let datos = this.form.getRawValue();
    let detalleProcedimiento = new DetalleProcedimientoRequestModel(
      datos.NroItem,
      datos.CodPrestacion,
      datos.CodAlternativo,
      datos.PiezaDental,
      datos.CaraDental,
      datos.TipoPrestacion,
      datos.ArancelPrestacion,
      datos.CantidadSolicitada,
      datos.CodDiagnosticoPrestacion,
      datos.CodRta,
      datos.DescripcionPrestacion,
      datos.Facturacion
    );

    if (this.indexDetalleProcediminetoAEditar != null) {
      //Se esta modificando
      let detallesProcedimientos: Array<DetalleProcedimientoRequestModel> = [
        ...this.detallesProcediminetos,
      ];
      detallesProcedimientos[this.indexDetalleProcediminetoAEditar] =
        detalleProcedimiento;
      this.store.dispatch(
        new setDetallesProcedimientosRequest(detallesProcedimientos)
      );
      this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
    } else {
      //Se esta creando
      this.store.dispatch(
        new setDetallesProcedimientosRequest([
          ...this.detallesProcediminetos,
          detalleProcedimiento,
        ])
      );
      this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
    }
    this.reset();
  }

  reset() {
    this.form.reset();
    this.inputPrestacionesComponent.clean();
    this.setDefaultValues();
    this.indexDetalleProcediminetoAEditar = null;
    this.submitted = false;
    this.inputPrestacionesComponent.focus();
  }
}
