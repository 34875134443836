<div class="containerNNav">
  <app-navbar-windows class="app-navbar-windows"></app-navbar-windows>
  <main class="cardN cardN-loginP cardN-login" [ngClass]="{'cardN-register ':register==true}">
    <div class="card__columnL" id="card__columnL-login">
      <figure class="card__logoNuvalidC">
        <a href="#"><img src="./assets/design/logoNuvalid.svg" alt="logoNuvalid" class="card__logoNuvalid"></a>
        <!-- <div id="logoN"> </div> -->
      </figure>
      <figure class="card__phoneC">
        <!-- <a href="#" id="phone"></a> -->
        <a id="phone"><img src="./assets/design/phone.svg" alt="phoneNuvalid" class="card__phone"
            (click)="contacto()"></a>
      </figure>
    </div>
    <div class="card__columnR " id="card__columnR-login">

      <div class="illustrations illustrations-login">
        <img src="./assets/design/loginDoctor.svg" alt="" class="img__login">
      </div>

      <!-- ----------Botones---------- -->
      <div class="button-box" id="button-box">
        <div id="btn" class="btnSelectedLogin"
          [ngClass]="{'btnSelectedRegister': register==true, 'btnSelectedLogin': register==false}"></div>
        <button type="button" class="toggle-btn" id="button-login" (click)="inicio()"
          [ngClass]="{'buttonSelected ':register==false, 'buttonUnselected': register==true }">Iniciar
          sesión</button>
        <!-- <button type="button" class="toggle-btn" id="button-register">Crear cuenta</button> -->
        <button type="button" class="toggle-btn" id="button-register" (click)="registrar()"
          [ngClass]="{'buttonSelected ':register==true, 'buttonUnselected': register==false}">Crear
          cuenta</button>

      </div>
      <!-- ----------Login formulario---------- -->
      <div *ngIf="register == false">

        <form action="" [formGroup]="form" class="input-group-login" id="login" action="aplicaciones ">
          <div class="data-login">
            <!-- ----------Directions---------- -->

            <div *ngIf="codigosInstalacion">
              <div class="form-group">
                <div class="form-row">
                  <label id="label-datalist" class="labelForm"></label>
                  <select id="location" [ngClass]="{disabled: codigosInstalacion.length <= 1}"
                    formControlName="codigoInstalacion" aria-label="Lugares de atencion">
                    <option *ngFor="let codigoInstalacion of codigosInstalacion"
                      value="{{codigoInstalacion.codigoInstalacion}}">
                      {{codigoInstalacion.domicilio | uppercase}}
                    </option>
                  </select>
                </div>
              </div>

              <!-- <div *ngIf="codigosInstalacion">
                            <label id="label-datalist" class="labelForm">

                              <input type="text" name="location" list="location" placeholder="Dirección"></label>

                            <datalist id="location">
                              <option *ngFor="let codigoInstalacion of codigosInstalacion"
                          value="{{codigoInstalacion.codigoInstalacion}}">{{codigoInstalacion.domicilio}}
                        </option>
                            </datalist> -->

            </div>

          </div>
          <!-- ----------Email---------- -->
          <div class="data-login">
            <app-input-login (username)="setUsername($event)" formControlName="username" ngDefaultControl></app-input-login>
            <div class="reminder reminder__loginE" *ngIf="submitted && form.controls['username'].errors && form.controls['username'].touched">
              <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger" id="warning__img">
              </div>
              <p class=" reminder__textLoginE">El email ingresado no es v&aacute;lido
              </p>
            </div>
          </div>
          <!-- <div class="data-login">
            <label for="email" id="label-email" class="labelForm"><input type="text" placeholder="Email" name="email"
                formControlName="username" autocomplete="on" (keyup.enter)="login($event)" required></label>

            <div class="reminder reminder__loginE" *ngIf="submitted && form.controls['username'].errors">
              <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger"
                  id="warning__img">
              </div>
              <p class=" reminder__textLoginE">El email ingresado no es v&aacute;lido
              </p>
            </div>
          </div> -->
          <!-- ----------Password---------- -->
          <div class="data-login">
            <label for="password" id="label-password" class="labelForm">
              <input [type]="fieldTextType ? 'text' : 'password'" placeholder="Contraseña" name="password" required
                id="inputPass" formControlName="password" (keyup.enter)="login($event)">
              <div class="password">
                <span class="pointer eye" (click)="fieldTextType = !fieldTextType">
                  <ng-container *ngIf="fieldTextType">
                    <fa-icon [icon]='["fas", "eye"]'></fa-icon>
                  </ng-container>
                  <ng-container *ngIf="!fieldTextType">
                    <fa-icon [icon]='["fas", "eye-slash"]' class="mr-1">
                    </fa-icon>
                  </ng-container>
                </span>
              </div>
            </label>
            <div class="reminder__loginE" *ngIf="submitted && form.controls['password'].errors">
              <div class="reminder__warning reminder__warning-login"><img src="./assets/design/alert_invested.svg"
                  alt="danger" id="warning__img">
              </div>
              <div>
                <p class=" reminder__textLoginE">
                  Debe ingresar una contraseña
                </p>
              </div>
            </div>
          </div>
          <!-- ----------Add directions---------- -->
          <div class="reminder reminder-loginHelp">
            <div class="reminder__warning"><img src="./assets/design/warning.svg" alt="danger" id="warning__img">
            </div>
            <p class="reminder__text">
              ¿No encuentra su domicilio? Puede agregarlo haciendo <a target="_blank" class="reminder__link"
                (click)="nuevoLugarAtencion()">
                click aquí.</a>
            </p>
          </div>
          <!-- ----------Reset password---------- -->
          <div class="form-textN form-textNP">
            <div class="reminder reminder-loginPassword">
              <a href="../password/index.html" class="reminder__link reminder__link-login"
                (click)="recuperarContrasenna($event)">¿Ha olvidado su
                contraseña?</a>
            </div>
            <!-- ----------Button---------- -->
            <div class="components components-login components-loginRestablecer">
              <app-navegation [volverAtrasAuth]="false" [volverALoginAuth]="false" (siguienteClick)="login($event)"
                [nombreBoton]="'Entrar'" id="btnentrar">
              </app-navegation>
            </div>
          </div>
        </form>
      </div>

      <!-- Intento register Mica  -->

      <div *ngIf="register">
        <app-signup></app-signup>
      </div>

    </div>

  </main>
</div>
<!-- tempale temporal para actualizar prestadores
<ng-template #other_content>
  <div class="container d-flex align-items-center justify-content-center" style="height: 60vh !important;">
    <div class="card " style="width: 50rem;">
      <div class="card-body">
        <h5 class="form-textN"><span class="form-text__bold">Bienvenidos a NUVALID</span>
        </h5>
        <h5 class="form-textN">Nuvalid necesita una nueva versi&oacute;n para poder operar.</h5>

        <h5 class="form-textN"><span class="form-text__bold">IMPORTANTE:</span> Con la nueva instalación puede visualizarse una ventana con la leyenda: "Si ejecutas esta aplicación, podrías poner en riesgo el PC.", ignorarla y hacer click en <strong>Más información</strong>, seguido hacer click en el botón <strong>Ejecutar de todas formas.</strong>
        </h5>

        <a *ngIf="!descarga" href="#" (click)="cerrarAplicacion()" class="card-link">Click para descargar</a>
        <div *ngIf="descarga">
          <p>{{msj}}</p>
          <p><ngb-progressbar [value]="1000" type="secondary"></ngb-progressbar></p>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->
