export class TicketFiltrosModel {
  FechaDesde: String | null;
  FechaHasta: String | null;
  Financiador: String | null;
  Prestador: String | null;
  Afiliado: String | null;

  constructor(
    FechaDesde: String | null,
    FechaHasta: String | null,
    Financiador: String | null,
    Prestador: String | null,
    Afiliado: String | null
  ) {
    this.FechaDesde = FechaDesde;
    this.FechaHasta = FechaHasta;
    this.Financiador = Financiador;
    this.Prestador = Prestador;
    this.Afiliado = Afiliado;
  }
}

export class DownloadFiltrosModel {
  FechaDesde: string | null;
  FechaHasta: string | null;
  Financiador: string | null;
  Prestador: string | null;
  TxtFormat: boolean;
  CsvFormat: boolean;

  constructor(
    FechaDesde: string | null,
    FechaHasta: string | null,
    Financiador: string | null,
    Prestador: string | null,
    TxtFormat: boolean,
    CsvFormat: boolean,
  ) {
    this.FechaDesde = FechaDesde;
    this.FechaHasta = FechaHasta;
    this.Financiador = Financiador;
    this.Prestador = Prestador;
    this.TxtFormat = TxtFormat;
    this.CsvFormat = CsvFormat;
  }
}

export class DatosTicketModel {
  id: number;
  fecha: Date;
  anulado: string;
  mensajePrinter: string;
  cuitFinanciador: string;
  credencialAfiliado: string;
  siglaFinanciador: string;
  razonSocial: string;
  cuitPrestador: string;
  codInstalacion: string;
  codOperacion: string;
  descripcionOperacion: string;
  nroReferencia: string;
  transaccionCancelId: string;

  constructor(
    id: number,
    fecha: Date,
    anulado: string,
    mensajePrinter: string,
    cuitFinanciador: string,
    credencialAfiliado: string,
    siglaFinanciador: string,
    razonSocial: string,
    cuitPrestador: string,
    codInstalacion: string,
    codOperacion: string,
    descripcionOperacion: string,
    nroReferencia: string,
    transaccionCancelId: string
  ) {
    this.id = id;
    this.fecha = fecha;
    this.anulado = anulado;
    this.mensajePrinter = mensajePrinter;
    this.cuitFinanciador = cuitFinanciador;
    this.credencialAfiliado = credencialAfiliado;
    this.siglaFinanciador = siglaFinanciador;
    this.razonSocial = razonSocial;
    this.cuitPrestador = cuitPrestador;
    this.codInstalacion = codInstalacion;
    this.codOperacion = codOperacion;
    this.descripcionOperacion = descripcionOperacion;
    this.nroReferencia = nroReferencia;
    this.transaccionCancelId = transaccionCancelId;
  }
}

export class GetDatosTicket {
  totalElements: number;
  content: Array<DatosTicketModel>;

  constructor(
    totalElements: number,
    content: Array<DatosTicketModel>
  ) {
    this.totalElements = totalElements;
    this.content = content;
  }
}
