import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import * as storeActions from '@store/actions';
@Injectable()
export class datosInicioEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
  ) {}

  @Effect({ dispatch: false })
  setCodigoInstalacion$ = this.actions$
    .pipe(ofType(storeActions.SETTRANSACCIONREQUEST))
    .pipe(
      tap((accion: storeActions.setTransaccionRequest) => {
        this.store.dispatch(new storeActions.unsetEstadoFormDatosCasiTodos());
        this.store.dispatch(new storeActions.unsetDatosTransacciones());
      })
    );
}
