<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Nuvalid</title>
    <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
    <!-- <script src="https://kit.fontawesome.com/8154432079.js" crossorigin="anonymous"></script> -->
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">
</head>


<!--
    <ng-container *ngIf="lugar != null">
        <div class="alert alert-pcpos mt-1 text-center">
            <input class="form-check-input" type="radio" name="lugar" id="{{lugar.domicilio}}" (change)="selectDireccion()" [checked]="checked">
            <label class="form-check-label" for="{{lugar.domicilio}}">
            {{lugar.domicilio}}
        </label>
        </div>
    </ng-container>-->

<ng-container *ngIf="lugar != null">

    <label class="label-directions">
        <input type="radio" name="lugar" id="{{lugar.domicilio}}" (change)="selectDireccion()" [checked]="checked">
        <!-- <fa-icon [icon]="['fab', 'circle']"></fa-icon><fa-icon [icon]="['fab', 'dot-circle']"></fa-icon> -->
        <i
            class="fa fa-circle-o fa-1x"></i><i class="fa fa-dot-circle-o fa-1x"></i>
        <span> {{lugar.domicilio}}</span>
    </label>

    <!-- <input class="form-check-input" type="radio" name="lugar" id="{{lugar.domicilio}}" (change)="selectDireccion()" [checked]="checked">

    <label class="form-check-label" for="{{lugar.domicilio}}">
      {{lugar.domicilio}}
    </label> -->

</ng-container>



</html>
