import * as models from '@store/models/store.models';
import * as fromDatosInicio from '@store/actions/datosInicio.actions';

const estadoInicial: models.datosInicioState = {
  CredencialRequest: null,
  FinanciadorRequest: null,
  PrestadorRequest: null,
  TransaccionRequest: null,
  PrescripcionDispensaRequest: null,
  AfiliadoRequest: null
};

export function datosInicioReducer(
  state = estadoInicial,
  action: fromDatosInicio.datosInicioActions
): models.datosInicioState {
  switch (action.type) {
    case fromDatosInicio.SETCREDENCIALREQUEST:
      return {
        ...state,
        CredencialRequest: action.CredencialRequest,
      };

    case fromDatosInicio.UNSETCREDENCIALREQUEST:
      return {
        ...state,
        CredencialRequest: null,
      };

      case fromDatosInicio.SETCODIGODESEGURIDADREQUEST:
        if(state.CredencialRequest){
          return {
            ...state,
            CredencialRequest:{
              ...state.CredencialRequest,
              VersionCredencial: action.CodigoDeSeguridadRequest,
              csc: action.CodigoDeSeguridadRequest,
            }
          };
        }else{
          return state;
        }
      

    case fromDatosInicio.UNSETCODIGODESEGURIDADREQUEST:
      if(state.CredencialRequest){
        return {
          ...state,
          CredencialRequest:{
            ...state.CredencialRequest,
            VersionCredencial: "",
            csc: "",
          }
        };
      }else{
        return state;
      }

    case fromDatosInicio.SETFINANCIADORREQUEST:
      return {
        ...state,
        FinanciadorRequest: action.FinanciadorRequest,
      };

    case fromDatosInicio.UNSETFINANCIADORREQUEST:
      return {
        ...state,
        FinanciadorRequest: null,
      };

    case fromDatosInicio.SETPRESTADORREQUEST:
      return {
        ...state,
        PrestadorRequest: action.PrestadorRequest,
      };

    case fromDatosInicio.UNSETPRESTADORREQUEST:
      return {
        ...state,
        PrestadorRequest: null,
      };

    case fromDatosInicio.SETTRANSACCIONREQUEST:
      return {
        ...state,
        TransaccionRequest: action.TransaccionRequest,
      };

    case fromDatosInicio.UNSETTRANSACCIONREQUEST:
      return {
        ...state,
        TransaccionRequest: null,
      };
    
    case fromDatosInicio.SETPRESCRIPCIONDISPENSAREQUEST:
      return {
        ...state,
        PrescripcionDispensaRequest: action.PrescripcionDispensaRequest,
      };

    case fromDatosInicio.UNSETPRESCRIPCIONDISPENSAREQUEST:
      return {
        ...state,
        PrescripcionDispensaRequest: null,
      };

    case fromDatosInicio.SETAFILIADOREQUEST:
      return {
        ...state,
        AfiliadoRequest: action.AfiliadoRequest,
      };

    case fromDatosInicio.UNSETAFILIADOREQUEST:
      return {
        ...state,
        AfiliadoRequest: null,
      };

    case fromDatosInicio.UNSETDATOS:
      return estadoInicial;

    default:
      return state;
  }
}
