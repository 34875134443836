import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { PrescriptorRequestModel } from '@models/datosTransaccion.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setEstadoFormPrescriptorRequest,
  setPrescriptorRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-prescriptor-request',
  templateUrl: './datos-prescriptor-request.component.html',
  styleUrls: ['./datos-prescriptor-request.component.scss'],
})
export class DatosPrescriptorRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  private datosPrecargados: boolean = false;
  esRescate: boolean = false;

  especialidadesPrescriptor: Array<any> = [];
  provinciasPrescriptor: Array<any> = [];
  tiposPrescriptor: Array<any> = [];
  codigoTransaccion: string | null = null;
  tipoPrestador: string = '';
  provincia?: string = '';
  tipoMatricula: string = '';

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<PrescriptorRequestModel> =
    this.formBuilder.group<PrescriptorRequestModel>({
      ApellidoPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CuitPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      EspecialidadPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      FechaReceta: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NombrePrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroMatriculaPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
        Validators.pattern('[0-9]+')
      ]),
      ProvinciaPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  constructor(private fb: UntypedFormBuilder, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    this.addValidators();
    this.setDefaultValues();
  }

  addValidators() {

    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          if (
            this.tipoPrestador == 'KINESIOLOGIA' ||
            this.tipoPrestador == 'RADIOLOGIA' ||
            this.tipoPrestador == 'PSICOLOGIA' ||
            this.tipoPrestador == 'FONOAUDIOLOGIA'
          ) {

            this.form.controls['NroMatriculaPrescriptor'].setValidators([
              Validators.required,
              blankSpaceValidator,
              Validators.pattern('[0-9]+')
            ]);
            this.form.controls['ProvinciaPrescriptor'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);
            this.form.controls['TipoPrescriptor'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);

            this.form.updateValueAndValidity();
            this.store.dispatch(new setEstadoFormPrescriptorRequest(false));
          } else {
            this.store.dispatch(new setEstadoFormPrescriptorRequest(null));
          }
          break;

        case '02D':
          if (
            this.tipoPrestador == 'KINESIOLOGIA' ||
            this.tipoPrestador == 'RADIOLOGIA' ||
            this.tipoPrestador == 'PSICOLOGIA' ||
            this.tipoPrestador == 'FONOAUDIOLOGIA'
          ) {
            this.form.controls['NroMatriculaPrescriptor'].setValidators([
              Validators.required,
              blankSpaceValidator,
              Validators.pattern('[0-9]+')
            ]);
            this.form.controls['ProvinciaPrescriptor'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);
            this.form.controls['TipoPrescriptor'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);
            this.store.dispatch(new setEstadoFormPrescriptorRequest(false));
          } else {
            this.store.dispatch(new setEstadoFormPrescriptorRequest(null));
          }
          break;

        case '02C':
          this.form.controls['NroMatriculaPrescriptor'].setValidators([
            Validators.required,
            blankSpaceValidator,
            Validators.pattern('[0-9]+')
          ]);
          this.form.controls['ProvinciaPrescriptor'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.form.controls['TipoPrescriptor'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          this.store.dispatch(new setEstadoFormPrescriptorRequest(false));
          break;

        default:
          this.store.dispatch(new setEstadoFormPrescriptorRequest(null));
          break;
      }


    }
  }


  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.tipoPrestador =
        datos.datosInicio.PrestadorRequest?.TipoPrestador.toUpperCase() || '';
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.provinciasPrescriptor =
        datos.datosInicialesExtra.datosInicialesExtra?.provincia || [];
      this.tiposPrescriptor =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoMatricula || [];
      let especialidades =
        datos.datosInicialesExtra.datosInicialesExtra?.especialidades;
      let especialidadesUnidas = especialidades?.Bioquimica.concat(
        especialidades.Fisioterapia,
        especialidades.Kinesiologia,
        especialidades.Medicina,
        especialidades.Odontologia,
        especialidades.Psicologia
      );
      this.especialidadesPrescriptor = especialidadesUnidas || [];

      if (datos.recetas.EsRecetaRequest && !this.datosPrecargados) {
        this.esRescate = true;
        this.preloadPrescriptorData(datos.recetas.RecetasRequest!.Prescriptor);
        this.datosPrecargados = true;
      }
    });
  }

  preloadPrescriptorData(prescriptor: any) {
    console.log(prescriptor.Matricula);
    if (prescriptor.Matricula.Tipo === 'P') {
      this.tipoMatricula = 'Provincial'
    } else if (prescriptor.Matricula.Tipo === 'N') {
      this.tipoMatricula = 'Nacional'
    }
    this.form.patchValue({
      ApellidoPrescriptor: prescriptor.ApellidoPrescriptor || '',
      CuitPrescriptor: prescriptor.CuitPrescriptor || '',
      EspecialidadPrescriptor: prescriptor.EspecialidadPrescriptor || '',
      FechaReceta: prescriptor.FechaReceta || '',
      NombrePrescriptor: prescriptor.NombrePrescriptor || '',
      // NroMatriculaPrescriptor: prescriptor.NroMatriculaPrescriptor || '',
      // ProvinciaPrescriptor: prescriptor.ProvinciaPrescriptor || '',
      // TipoPrescriptor: prescriptor.TipoPrescriptor || '',
      NroMatriculaPrescriptor: prescriptor.Matricula.Numero || '',
      ProvinciaPrescriptor: this.tipoMatricula || '',
      TipoPrescriptor: prescriptor.TipoPrescriptor.Descripcion || '',
    });

    this.addValidators();
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
    this.datosPrecargados = false;
  }

  selectEspecialidad(especialidad: any) {
    //Evento emitido desde el typeahead
    if (especialidad) {
      this.form.controls['EspecialidadPrescriptor'].setValue(
        especialidad.codigo
      );
    } else {
      this.form.controls['EspecialidadPrescriptor'].setValue(null);
    }
  }

  setDefaultValues() {
    if (!this.esRescate) {
      let tipo = this.tiposPrescriptor.find(
        (x) => x.nombre == 'Médico'
      );
      this.form.controls['TipoPrescriptor'].setValue(tipo?.codigo || null);

      this.subscriptionStore = this.store.subscribe((datos) => {
        this.provincia = datos.datosInicio.PrestadorRequest?.matriculas![0].provincia
      });
      this.form.controls['ProvinciaPrescriptor'].setValue(this.provincia || null);
    }

    if (this.esRescate) {
      this.form.controls['NroMatriculaPrescriptor'].disable();
      this.form.controls['ProvinciaPrescriptor'].disable();
      this.form.controls['TipoPrescriptor'].disable();
    }
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    let prescriptorRequest = new PrescriptorRequestModel(
      datos.ApellidoPrescriptor,
      datos.CuitPrescriptor,
      datos.EspecialidadPrescriptor,
      datos.FechaReceta,
      datos.NombrePrescriptor,
      datos.NroMatriculaPrescriptor,
      datos.ProvinciaPrescriptor,
      datos.TipoPrescriptor
    );
    this.store.dispatch(new setPrescriptorRequest(prescriptorRequest));
    this.store.dispatch(new setEstadoFormPrescriptorRequest(this.form.valid));
  }
}
