import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { codigoNombreModel } from '@models/datosInicialesExtras.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  ActivarLoading,
  DesactivarLoading,
  setDatosTiposTransacciones,
  setUsuarioActual,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ordenar-transacciones',
  templateUrl: './ordenar-transacciones.component.html',
  styleUrls: ['./ordenar-transacciones.component.scss'],
})
export class OrdenarTransaccionesComponent implements OnInit, OnDestroy {
  private subscriptionUI: Subscription = new Subscription();
  submitted: boolean = false;
  cargando: boolean = false;

  ordenNuevoTransacciones: Array<codigoNombreModel> = [];
  transacciones: Array<codigoNombreModel> = [];

  constructor(
    private store: Store<AppState>,
    private location: Location,
    private operacionesPerfilService: OperacionesPerfilService,
    private dialogsService: DialogsService,
    private globalService: GlobalService,
    private router: Router
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.subscriptionUI = this.store.subscribe((datos) => {
      this.cargando = datos.ui.loading;
    });
    this.operacionesPerfilService.getOrdenTransacciones().subscribe(
      (respuesta) => {
        console.log('soy el getOrdenTransacciones', respuesta.tiposTransacciones);
        this.ordenNuevoTransacciones = respuesta.tiposTransaccionesPrestador;
        let idsTransaccionesPrestador = new Set(
          this.ordenNuevoTransacciones.map((d) => d.codigo)
        );
        this.transacciones = respuesta.tiposTransacciones.filter(
          (x) => !idsTransaccionesPrestador.has(x.codigo)
        );
        this.dialogsService.cerrar();
      },
      (error) => {
        this.dialogsService.cerrar();
        this.globalService.manageError(error);
      }
    );
  }

  drop(event: CdkDragDrop<codigoNombreModel[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  ngOnDestroy() {
    this.subscriptionUI.unsubscribe();
  }

  irAtras() {
    this.location.back();
  }

  guardarCambios() {
    if (this.ordenNuevoTransacciones.length == 0) {
      this.dialogsService.toastError(
        'Debe dejar al menos una transacción para realizar'
      );
      return;
    }
    this.submitted = true;
    this.store.dispatch(new ActivarLoading());
    console.log("activar loading despachado");

    /*
    let prescripcion = {
      id: 99,
      codigo: "99P",
      descripcion: "Informar que el prestador CUIT \"C\", efector \"E\", efectuo prescripción medica \"P\" al asociado \"A\" el dia \"D\".",
      nombre: "REALIZAR PRESCRIPCIÓN MEDICA",
      baja: false
    };
    */
    this.operacionesPerfilService
      .cambiarOrdenTransacciones(this.ordenNuevoTransacciones)
      .subscribe(
        (respuesta) => {
          this.store.dispatch(
            new setDatosTiposTransacciones([...this.ordenNuevoTransacciones])
          );
          console.log("set datos tipos transacciones");
          this.store.dispatch(new DesactivarLoading());
          this.dialogsService.toastSuccess(
            'Orden de transacciones actualizado correctamente'
          );
          console.log("desacdtivar loading");

          this.irAtras();
        },
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }
}
