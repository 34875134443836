<form [formGroup]="form">
  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Seleccione el tipo de archivo <span class="text-danger"
          *ngIf="form.get('TipoArchivo') | controlRequired">*</span></label>

      <select class="form-control" formControlName="TipoArchivo">
        <option [ngValue]="tipoArchivo.codigo" *ngFor="let tipoArchivo of tipoArchivos">
          {{tipoArchivo.nombre}}
        </option>
      </select>
    </div>

    <div class="form-group col-12 col-md-6 mb">
      <label>Seleccione el archivo </label>
      <div class="custom-file">
        <input type="file" class="custom-file-input" multiple id="importFile" lang="es"
          (change)="handleFileInput($event)">
        <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i>Elegir
          archivo</label>
      </div>
    </div>
  </div>

</form>
