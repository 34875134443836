import { Injectable } from '@angular/core';
declare const window: any;
import { Observable, of, throwError } from 'rxjs';
import * as Jsbarcode from 'jsbarcode';
@Injectable({
  providedIn: 'root',
})
export class IpcService {

  nroRef: string = '';
  msjPrnt: string = '';
  constructor() { }

  get isElectron(): boolean {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      // Electron-specific code
      return true;
    } else {
      return false;
    }
  }

  checkUpdates(): Observable<any> {
    if (this.isElectron) {
      return new Observable((subscriber) => {
        window.api.electronIpcOnce(
          'update_downloaded',
          (_event: any, arg: any) => {
            subscriber.next(arg);
            subscriber.complete();
          }
        );
      });
    } else {
      throw throwError('error');
    }
  }

  checkPosition(position: any) {
    if (this.isElectron) {
      window.api.electronIpcSend('googlePosition', { position });
    } else {
      throw throwError('error');
    }
  }

  cerrarAplicacion() {
    window.api.electronIpcSend('cerrarAplicacion');
  }

  redirecExe() {
    let objetoInfo: any = {
      info: {
        url: 'https://nuvalid-exe.tx.puntosalud.ar'
      }
    };
    window.api.electronIpcSend('redirecExe', JSON.stringify(objetoInfo));
  }

  abrirWhatsapp(numero: string, msj: string) {
    if (this.isElectron) {
      const encodedMessage = encodeURIComponent(msj);
      const url = `https://wa.me/${numero}?text=${encodedMessage}`;
      // const urljson = JSON.stringify(url)
      window.api.electronIpcSend('abrirWhatsapp', url);
    } else {
      const encodedMessage = encodeURIComponent(msj);
      window.open(`https://wa.me/${numero}?text=${encodedMessage}`);
    }
  }

  downloadNuvalidExe(url: string){
    if (this.isElectron) {
      window.api.electronIpcSend('downloadNuvalidLastestVersion', url);
    }else{
      window.open('url');
    }
  }

  cerrarSplash() {
    console.log('Cerrar splash');
    if (this.isElectron) {
      window.api.electronIpcSend('cerrarSplash');
    }
  }

  imprimirTicket(ticket: any) {
    const barcodeData = ticket.EncabezadoMensaje.Rta.CodigoBarras;

    const canvas = document.createElement('canvas');
    Jsbarcode(canvas, barcodeData, {
      format: 'CODE128',
      width: 2,
      height: 50,
      fontSize: 12,
      font: 'courier'
    });
    const barcodeDataURL = canvas.toDataURL();

    if (this.isElectron) {
      // Electron-specific code
      window.api.electronIpcSend('imprimirTicket', {
        ticket: ticket.EncabezadoMensaje.Rta.MensajePrinter,
        numeroTransaccion: ticket.EncabezadoMensaje.NroReferencia,
        barcode: barcodeDataURL,
      });
    } else {
      let params = `status=no,location=no,toolbar=no,menubar=no,
                    width=400,height=700,left=500,top=500`;
      let myWindow = window.open('', 'Ticket', params);
      myWindow!.document.head.innerHTML = '<title>Ticket</title></head>';
      myWindow!.document.body.innerText = ticket.EncabezadoMensaje.Rta.MensajePrinter;
      myWindow!.document.body.style.fontFamily = 'courier';
      myWindow!.document.body.style.fontSize = '12px';
      myWindow!.document.body.style.fontStyle = 'normal';

      const img = document.createElement('img');
      img.src = barcodeDataURL;
      myWindow!.document.body.appendChild(img);
    }
  }

  imprimirListadoTicket(ticket: any) {

    const barcodeData = ticket.CodigoBarras;

    const canvas = document.createElement('canvas');
    Jsbarcode(canvas, barcodeData, {
      format: 'CODE128',
      width: 2,
      height: 50
    });
    const barcodeDataURL = canvas.toDataURL();

    if (this.isElectron) {
      // Electron-specific code
      window.api.electronIpcSend('imprimirTicket', {
        ticket: ticket.mensajePrinter,
        numeroTransaccion: ticket.nroReferencia,
        barcode: barcodeDataURL
      });
    } else {
      let params = `status=no,location=no,toolbar=no,menubar=no,
                    width=400,height=700,left=500,top=500`;
      let myWindow = window.open('', 'Ticket', params);
      myWindow!.document.head.innerHTML = '<title>Ticket</title></head>';
      myWindow!.document.body.innerText = ticket.mensajePrinter
      myWindow!.document.body.style.fontFamily = 'courier';
      myWindow!.document.body.style.fontSize = '12px';
      myWindow!.document.body.style.fontStyle = 'normal';

      const img = document.createElement('img');
      img.src = barcodeDataURL;
      myWindow!.document.body.appendChild(img);
    }
  }

  getElectronVersion(): string {
    console.log('electronVersion', window.api.electronIpcSendSync('getElectronVersion'));
    return window.api.electronIpcSendSync('getElectronVersion');
  }

}
