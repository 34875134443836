import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactanosComponent } from '@configuracioninicial/configuracionInicial.index';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { instalacionFormModel, redirectClass, showAlert } from '@models/global.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { GlobalService } from '@services/global.service';
import { InstalacionService } from '@services/instalacion.service';
import { IpcService } from '@services/ipc.service';
import {NgbProgressbarConfig} from '@ng-bootstrap/ng-bootstrap';

import {
  ActivarLoading,
  borrarDatosInstalacion,
  CargarUsuarioInstalacionSuccess,
  DesactivarLoading,
  SetCoordenadas,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { erroresSelecccionarLugarAtencion } from 'errores';
import { Subscription } from 'rxjs';
import { DialogsService } from '@services/dialogs.service';
@Component({
  selector: 'app-instalacion',
  templateUrl: './instalacion.component.html',
  styleUrls: ['./instalacion.component.scss'],
})
export class InstalacionComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  submitted: boolean = false;
  cargando: boolean = false;
  mostrando:boolean=true;
  mostrando_atras:boolean=false;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<instalacionFormModel> =
    this.formBuilder.group<instalacionFormModel>({
      cuit: new UntypedFormControl('', [
        Validators.required,
        blankSpaceValidator,
        Validators.minLength(11),
        Validators.maxLength(11),
        Validators.pattern('[0-9]+'),
      ]),
  });

  constructor(
    private store: Store<AppState>,
    private instalacionService: InstalacionService,
    private globalService: GlobalService,
    private router: Router,
    private dialogsService: DialogsService,
    ) {
      this.store.dispatch(new borrarDatosInstalacion());
    }

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe((resp) => {
      this.cargando = resp.ui.loading;
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }




  getCodigo() {
    this.submitted = true;

    if (this.form.controls['cuit'].invalid || this.cargando) {
      this.mostrando=false;
      return;
    }

    this.store.dispatch(new ActivarLoading());

    //Buscar lugares de atencion del prestador
    this.instalacionService
      .getUsuarioInstalacion(this.form.getRawValue().cuit)
      .subscribe(
        (resp) => {
          this.store.dispatch(new CargarUsuarioInstalacionSuccess(resp)); //Carga la respuesta en redux y desactiva el loading
          //Busco la posicion actual del usuario
          this.instalacionService
            .getPosition()
            .then((resp: google.maps.LatLng) => {
              this.store.dispatch(new SetCoordenadas(resp));
              this.router.navigate(['instalacion/seleccionarLugarAtencion']);
            });
        },
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
          this.mostrando_atras=true;
          this.mostrando=false;
        }
      );
  }

  irALogin() {


    this.router.navigate(['login']);
  }

  contacto(){

/*
     let modal = this.modalService.open(ContactanosComponent,{ size: 'lg',centered:true});

    return modal;  */


     this.globalService.redirect(
       new redirectClass(
        'contactanos'
      )
     )
  }
}
