import * as datosTransaccion from '@models/datosInicio.models';
import { Action } from '@ngrx/store';

export const SETCREDENCIALREQUEST = '[datosInicio] Set credencialRequestForm';
export const UNSETCREDENCIALREQUEST = '[datosInicio] Unset credencialRequestForm';


export const SETCODIGODESEGURIDADREQUEST = '[datosInicio] Set CODIGODESEGURIDADRequestForm';
export const UNSETCODIGODESEGURIDADREQUEST = '[datosInicio] Unset cODIGODESEGURIDADRequestForm';


export const SETFINANCIADORREQUEST = '[datosInicio] Set financiador';
export const UNSETFINANCIADORREQUEST = '[datosInicio] Unset financiador';

export const SETPRESTADORREQUEST = '[datosInicio] Set prestador';
export const UNSETPRESTADORREQUEST = '[datosInicio] Unset prestador';

export const SETTRANSACCIONREQUEST = '[datosInicio] Set transaccion';
export const UNSETTRANSACCIONREQUEST = '[datosInicio] Unset trasaccion';

export const SETPRESCRIPCIONDISPENSAREQUEST = '[datosInicio] Set prestacion dispensa';
export const UNSETPRESCRIPCIONDISPENSAREQUEST = '[datosInicio] Unset prestacion dispensa';

export const SETAFILIADOREQUEST = '[datosInicio] Set afiliado';
export const UNSETAFILIADOREQUEST = '[datosInicio] Unset afiliado';

export const UNSETDATOS = '[datosInicio] Unset datos';

export class setCredencialRequest implements Action {
  readonly type = SETCREDENCIALREQUEST;
  constructor(
    public CredencialRequest: datosTransaccion.CredencialRequestModel
  ) {}
}
export class unsetCredencialRequest implements Action {
  readonly type = UNSETCREDENCIALREQUEST;
}

export class setCodigoDeSeguridadRequest implements Action {
  readonly type = SETCODIGODESEGURIDADREQUEST;
  constructor(
    public CodigoDeSeguridadRequest: string
  ) {}
}

export class unsetCodigoDeSeguridadRequest implements Action {
  readonly type = UNSETCODIGODESEGURIDADREQUEST;
}
export class setFinanciadorRequest implements Action {
  readonly type = SETFINANCIADORREQUEST;
  constructor(
    public FinanciadorRequest: datosTransaccion.FinanciadorRequestModel
  ) {}
}

export class unsetFinanciadorRequest implements Action {
  readonly type = UNSETFINANCIADORREQUEST;
}

export class setPrestadorRequest implements Action {
  readonly type = SETPRESTADORREQUEST;
  constructor(
    public PrestadorRequest: datosTransaccion.PrestadorRequestModel
  ) {}
}

export class unsetPrestadorRequest implements Action {
  readonly type = UNSETPRESTADORREQUEST;
}

export class setTransaccionRequest implements Action {
  readonly type = SETTRANSACCIONREQUEST;
  constructor(
    public TransaccionRequest: datosTransaccion.TransaccionRequestModel
  ) {}
}

export class unsetTransaccioRequest implements Action {
  readonly type = UNSETTRANSACCIONREQUEST;
}

export class setPrescripcionDispensaRequest implements Action {
  readonly type = SETPRESCRIPCIONDISPENSAREQUEST;
  constructor(
    public PrescripcionDispensaRequest: datosTransaccion.PrescripcionDispensaRequestModel
  ) {}
}

export class unsetPrescripcionDispensaRequest implements Action {
  readonly type = UNSETPRESCRIPCIONDISPENSAREQUEST;
}

export class setAfiliadoRequest implements Action {
  readonly type = SETAFILIADOREQUEST;
  constructor(
    public AfiliadoRequest: datosTransaccion.AfiliadoRequestModel
  ) {}
}

export class unsetAfiliadoRequest implements Action {
  readonly type = UNSETAFILIADOREQUEST;
}

export class unsetDatosInicio implements Action {
  readonly type = UNSETDATOS;
}

export type datosInicioActions =
  | setCredencialRequest
  | unsetCredencialRequest
  | setCodigoDeSeguridadRequest
  | unsetCodigoDeSeguridadRequest
  | setFinanciadorRequest
  | unsetFinanciadorRequest
  | setPrestadorRequest
  | unsetPrestadorRequest
  | setTransaccionRequest
  | unsetTransaccioRequest
  | unsetDatosInicio
  | setPrescripcionDispensaRequest
  | unsetPrescripcionDispensaRequest
  | setAfiliadoRequest
  | unsetAfiliadoRequest;
