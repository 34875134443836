import { Component, OnInit } from '@angular/core';
import { DatosAfiliado, DatosAfiliadoAgendaModel } from '@models/agenda.models';
import { Ticket } from '@models/datosTicket.models';
import { DetalleProcedimientoRequestModel } from '@models/datosTransaccion.models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AgendaService } from '@services/agenda.service';
import { GlobalService } from '@services/global.service';
import { TransaccionesService } from '@services/transacciones.service';
import { AppState } from '@store/app.reducers';
import {datosInicioState, datosTransaccionesState } from '@store/models/store.models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss'],
})
export class TicketModalComponent implements OnInit {

  datosInicio: datosInicioState | null = null;
  datosTransaccion: datosTransaccionesState | null = null;
  detallesProcedimientos: Array<DetalleProcedimientoRequestModel> | null = null;
  datosTicket: Ticket | null = null;
  nombreTransaccion: string = '';
  date: any;
  dateA: any;
  time!: Date;

  page: number = 0;
  pageSize: number = 10;
  searchText: string = '';
  contactos: Array<DatosAfiliadoAgendaModel> | null = null;
  obras: string = '';
  obras2: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private agendaService: AgendaService,
    private store: Store<AppState>,
    private transaccionesService: TransaccionesService,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {

    this.getContactos();

    if (this.datosTicket) {
      console.log('Fecha y Hora:', this.datosTicket.EncabezadoMensaje.InicioTrx);

      if (this.datosTicket.EncabezadoAtencion.Atencion) {
        let dateAtencion = this.datosTicket.EncabezadoAtencion.Atencion.FechaAtencion;

        let yAtencion = dateAtencion.substring(0, 4);
        let mAtencion = dateAtencion.substring(4, 6);
        let dAtencion = dateAtencion.substring(6, 8);

        this.dateA = new Date(Number(yAtencion), Number(mAtencion) - 1, Number(dAtencion));
      }

      let dateString = this.datosTicket.EncabezadoMensaje.InicioTrx.FechaTrx;

      let year = dateString.substring(0, 4);
      let month = dateString.substring(4, 6);
      let day = dateString.substring(6, 8);

      this.date = new Date(Number(year), Number(month) - 1, Number(day));
      this.time = new Date();

    }
  }

  devolverRespuesta(accion: string) {
    this.activeModal.close({ respuesta: accion });
  }

  getContactos( searchText: string = '' ){
    this.agendaService.getContactosAgenda( this.page, this.pageSize, searchText ).subscribe(
      (respuesta) => {
        console.log('las obras', respuesta);
        this.obras = respuesta.afiliados[0].obras[0].numeroAfiliado
      }
    );
  }

}
