/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */

import {
  faSync,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faSignInAlt,
  faUsersCog,
  faTimes,
  faUserEdit,
  faEye,
  faEyeSlash,
  faPlus,
  faMinus,
  faEdit,
  faTrashAlt,
  faSearch,
  faCalendarPlus,
  faCalendar,
  faStickyNote,
  faSave,
  faEraser,
  faSignature,
  faBars,
  faWindowMinimize,
  faWindowMaximize,
  faSquare,
  faCircle,
  faDotCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
  faSync,
  faChevronRight,
  faSignInAlt,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faUsersCog,
  faTimes,
  faEye,
  faEyeSlash,
  faUserEdit,
  faPlus,
  faMinus,
  faEdit,
  faTrashAlt,
  faSearch,
  faCalendarPlus,
  faCalendar,
  faStickyNote,
  faSave,
  faEraser,
  faSignature,
  faBars,
  faWindowMinimize,
  faWindowMaximize,
  faSquare,
  faCircle,
  faDotCircle,
  faInfoCircle
};
