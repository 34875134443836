import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setCodigoDeSeguridadRequest,
  setEstadoFormCodigoDeSeguridadRequest,
  unsetCodigoDeSeguridadRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';

@Component({
  selector: 'app-codigo-de-seguridad',
  templateUrl: './codigo-de-seguridad.component.html',
  styleUrls: ['./codigo-de-seguridad.component.scss']
})
export class CodigoDeSeguridadComponent implements OnInit, OnDestroy {
  private subscriptionDatosInicio: Subscription = new Subscription();
  private subscriptionFormStatus: Subscription = new Subscription();

  codigoTransaccion:any;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<any> =
    this.formBuilder.group<any>({
      codigoDeSeguridad: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
        blankSpaceValidator,
      ]),
    });

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.listenCodigoDeSeguridad();

    this.subscriptionDatosInicio = this.store
      .select('datosInicio')
      .subscribe((datos) => {
        
          this.codigoTransaccion=datos.TransaccionRequest?.CodigoTransaccion;
      });

      this.addValidators();

  }

  ngOnDestroy() {
    this.subscriptionFormStatus.unsubscribe();
    this.subscriptionDatosInicio.unsubscribe();
  }

  addValidators() {
    
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          
            this.form.controls['codigoDeSeguridad'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);
          break;
        case '03A':
          
            this.form.controls['codigoDeSeguridad'].setValidators([
              Validators.required,
              blankSpaceValidator,
            ]);
          break;
          
        
        default:
         
          break;
      }
      
      this.store.dispatch(
        new setEstadoFormCodigoDeSeguridadRequest(this.form.valid)
      );
    }
  }

  listenCodigoDeSeguridad() {
    this.subscriptionFormStatus = this.form.controls[
      'codigoDeSeguridad'
    ].statusChanges.subscribe((status) => {
      this.form.controls['codigoDeSeguridad'].markAsTouched();
      if (this.form.controls['codigoDeSeguridad'].value) {
        this.store.dispatch(new setCodigoDeSeguridadRequest(this.form.controls['codigoDeSeguridad'].value));
        if (status == 'VALID') {
          this.store.dispatch(new setEstadoFormCodigoDeSeguridadRequest(true));
        } else {
          this.store.dispatch(new setEstadoFormCodigoDeSeguridadRequest(false));
        }
      } else {
        this.store.dispatch(new unsetCodigoDeSeguridadRequest());
        this.store.dispatch(new setEstadoFormCodigoDeSeguridadRequest(false));
      }
    });
  }
}
