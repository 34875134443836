<div class="container-xxxl">
  <div class="row justify-content-center p-5">
    <div class="col-12 card p-0 border-0 boxShadow">
      <div class="card-header p-0 bg-navegation rounded-top p-3 text-center" style="color: white;">
        <div class="row">
          <div class="col-12 text-center my-auto">
            {{titulo}}
          </div>
        </div>
      </div>
      <div class="card-body p-5 rounded">

        <form [formGroup]="form" class="pb-3 pl-3 pr-3" (ngSubmit)="guardar()" action="aplicaciones" autocomplete="off">

          <!-- START DATOS CONTACTOS -->

          <div>
            <div class="row">
              <ng-container *ngIf="nombreAfiliado === '' || apellidoAfiliado === ''; else inputSeparados">
                <!-- START APELLIDO Y NOMBRE -->
                <div class="col form-infoN">
                  <label>Apellido y Nombre</label>
                  <input autocomplete="off" class="form-control" autocorrect="off" spellcheck="false" autocomplete="off"
                    type="text" formControlName="apellido" placeholder="Ingrese apellido y nombre...">
                  <small *ngIf="submitted && form.controls['apellido'].errors" class="text-danger animated fadeIn">Ingrese mínimo 4 y máximo 32 caracteres</small>
                </div>
                <!-- END APELLIDO Y NOMBRE -->
              </ng-container>
              <ng-template #inputSeparados>
                <!-- START NOMBRE -->
                <div class="col form-infoN">
                  <label>Nombre</label>
                  <input autocomplete="off" class="form-control" autocorrect="off" spellcheck="false" autocomplete="off"
                    type="text" formControlName="nombre" placeholder="Ingrese el nombre...">
                  <small *ngIf="submitted && form.controls['nombre'].errors" class="text-danger animated fadeIn">Ingrese
                    el
                    nombre mínimo 4 y máximo 32
                    caracteres</small>
                </div>
                <!-- END NOMBRE -->
                <!-- START APELLIDO -->
                <div class="col form-infoN">
                  <label>Apellido</label>
                  <input autocomplete="off" class="form-control" autocorrect="off" spellcheck="false" autocomplete="off"
                    type="text" formControlName="apellido" placeholder="Ingrese el apellido...">
                  <small *ngIf="submitted && form.controls['apellido'].errors"
                    class="text-danger animated fadeIn">Ingrese
                    el
                    apellido
                    mínimo 4 y máximo 32
                    caracteres</small>
                </div>
                <!-- END APELLIDO -->
              </ng-template>
            </div>

            <div class="row" style="margin-top: 1em;">
              <div class="col form-infoN">
                <label>DNI</label>
                <input autocomplete="off" class="form-control" autocorrect="off" spellcheck="false" autocomplete="off"
                  type="text" formControlName="DNI" placeholder="Ingrese el DNI...">
                <small *ngIf="submitted && form.controls['DNI'].errors" class="text-danger animated fadeIn">Ingrese un
                  DNI
                  válido</small>
              </div>


              <div class="col form-infoN">
                <label>Email</label>
                <input autocomplete="off" class="form-control" autocorrect="off" spellcheck="false" autocomplete="off"
                  type="text" formControlName="email" placeholder="Ingrese el email...">
                <small *ngIf="submitted && form.controls['email'].errors" class="text-danger animated fadeIn">El
                  email ingresado no es válido</small>
              </div>



              <div class="col form-infoN">
                <label>Teléfono</label>
                <input autocomplete="off" class="form-control" autocorrect="off" spellcheck="false" autocomplete="off"
                  type="text" formControlName="telefono" placeholder="Ingrese el teléfono... ">
                <small *ngIf="submitted && form.controls['telefono'].errors" class="text-danger animated fadeIn">El
                  teléfono ingresado no es válido</small>
              </div>
            </div>
          </div>

          <!-- END DATOS CONTACTOS -->

          <!-- START DATOS FINANCIADORES -->

          <div class="bg-form mt-3">
            <h5 class="pl-0 ml-2">Financiadores</h5>
            <hr>
            <div class="p-5 mt-4 shadow-sm rounded cuadrado" formArrayName="obras"
              *ngFor="let obra of getObras(); let i = index">
              <div class="row">
                <div class="col-12">
                  <h5 class="pl-0">Financiador {{i+1}} </h5>
                  <hr>
                </div>
              </div>

              <div class="row p-3" [formGroupName]="i">
                <div class="form-group col-md-6">
                  <label>Financiador</label>
                  <select class="form-control" formControlName="nombreFinanciador">
                    <ng-container *ngFor="let financiador of financiadores">
                      <option [ngValue]="financiador.nombre"
                        [disabled]="obraFinanaciadoresSeleccionados.includes(financiador.nombre) ? true : null">
                        {{financiador.nombre}}</option>
                    </ng-container>
                  </select>
                  <small class="text-danger"
                    *ngIf="form.controls['obras'].touched && form.controls['obras'].errors">Estos campos no pueden
                    quedar vacios</small>
                </div>

                <div class="form-group col-md-6">
                  <label>Número de afiliado</label>
                  <input autocomplete="off" autocorrect="off" spellcheck="false" autocomplete="off" type="text"
                    class="form-control" placeholder="Ingrese el número de afiliado..." formControlName="numeroAfiliado"
                    min="0">
                </div>
              </div>

              <div class="row pl-3 pr-3">

                <div class="col-12 col-sm-6 text-left my-auto">

                </div>

                <div class="col-12 col-sm-6 text-right">
                  <ng-container *ngIf="i===0">
                    <button type="button" class="btn btn-pcpos">Borrar</button>
                  </ng-container>
                  <ng-container *ngIf="i!==0">
                    <button type="button" class="btn btn-pcpos" (click)="deleteObraFinanciador(i)">Borrar</button>
                  </ng-container>

                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 text-right">
                <ng-container
                  *ngIf="form.controls['obras'].invalid || (financiadores?.length === obraFinanaciadoresSeleccionados.length)">
                  <button type="button" class="btn btn-pcpos">
                    Agregar <fa-icon [icon]='["fas", "plus"]' class="ml-1"></fa-icon>
                  </button>
                </ng-container>

                <ng-container
                  *ngIf="!form.controls['obras'].invalid && (financiadores?.length !== obraFinanaciadoresSeleccionados.length)">
                  <button type="button" class="btn btn-pcpos" (click)="addObraFinanciador()">
                    Agregar <fa-icon [icon]='["fas", "plus"]' class="ml-1"></fa-icon>
                  </button>
                </ng-container>

              </div>
            </div>
          </div>

          <!-- END DATOS FINANCIADORES -->


        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-6 text-left">
            <button type="button" class="btn btn-pcpos" (click)="atras()">
              <fa-icon [icon]='["fas", "chevron-left"]' class="mr-1"></fa-icon> Atras
            </button>
          </div>
          <div class="col" style="display: flex; justify-content: end;">
            <ng-container>
              <button class="btn btn-pcpos" (click)="guardar()">
                Guardar contacto <fa-icon [icon]='["fas", "chevron-right"]' class="ml-1"></fa-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
