import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { IpcService } from '@services/ipc.service';
import { PersistenciaService } from '@services/persistencia.service';
import { setDatosConfiguracion } from '@store/actions';
import { AppState } from '@store/app.reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  mostrarChatAsistente: boolean = false;
  newElectronVersion: string = '';
  oldElectronVersion: any;
  constructor(private persistenciaService: PersistenciaService, private store: Store<AppState>,
    private ipcService: IpcService,
    private dialogsService: DialogsService) {
    this.store.dispatch(
      new setDatosConfiguracion(this.persistenciaService.recuperarDatos())
    );
    // this.newElectronVersion = '2.0.16';
  }

  ngOnInit() {
    // this.getComparedVersion();
    // if (this.oldElectronVersion && this.compareVersions(this.newElectronVersion, this.oldElectronVersion) > 0) {
    //   this.dialogsService.newVersionModal();
    // }
  }

  // getComparedVersion() {
  //   try {
  //     this.oldElectronVersion = this.ipcService.getElectronVersion();
  //   } catch (error) {
  //   }
  // }

  // compareVersions(versionA: string, versionB: string): number {
  //   const nElectronVersion = versionA.split('.');
  //   const oElectronVersion = versionB.split('.');

  //   for (let i = 0; i < nElectronVersion.length; i++) {
  //     const nVersion = parseInt(nElectronVersion[i]);
  //     const oVersion = parseInt(oElectronVersion[i]);

  //     if (nVersion < oVersion) {
  //       return -1;
  //     } else if (nVersion > oVersion) {
  //       return 1;
  //     }
  //   }
  //   return 0;
  // }

  toggleAsistente(respuesta: boolean) {
    this.mostrarChatAsistente = respuesta;
  }

}
