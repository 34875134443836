import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { codigoInstalacion, redirectClass } from '@models/global.models';
import { Prestador } from '@models/prestador.model';
import { roles } from '@models/signup.models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GlobalService } from '@services/global.service';
import { RegistracionService } from '@services/registracion.service';
import { AppState } from '@store/app.reducers';
import { UIState } from '@store/models/store.models';
import { errorGenerico } from 'errores';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

type DatosAPedir = 'Email' | 'Rol' | 'RolTodos' | 'LugaresAtencion';

@Component({
  selector: 'app-solicitar-info-modal',
  templateUrl: './solicitar-info-modal.component.html',
  styleUrls: ['./solicitar-info-modal.component.scss'],
})
export class solicitarInfoModalComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  mensajeAMostrar: string | null = null;
  tipoDatosAPedir: DatosAPedir | null = null;
  cargando: boolean = false;
  form: UntypedFormGroup = new UntypedFormGroup({});
  submitted: boolean = false;
  datos: codigoInstalacion | null = null;
  roles: Array<roles> | null = null;
  lugaresDeAtencion: Array<codigoInstalacion> | null = null;
  prestadoresLugarAtencion: Array<Prestador> = [];
  MostrarSeleccionarPrestador: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private registracionService: RegistracionService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    console.log('entra');
    if (this.mensajeAMostrar == null || this.tipoDatosAPedir == null) {
      this.redirectError();
    } else {
      if (this.tipoDatosAPedir == 'Email') {
        this.form.setControl(
          'email',
          new UntypedFormControl('', [Validators.required, Validators.email])
        );
      } else if (this.tipoDatosAPedir == 'Rol') {
        if (this.datos) {
          this.registracionService.getRolesAMostrar(this.datos).subscribe(
            (respuesta) => {
              this.roles = respuesta;
              this.form.setControl(
                'rol',
                new UntypedFormControl(this.roles[0], [Validators.required])
              );
              this.form.setControl(
                'cuitPrestadorSeleccionado',
                new UntypedFormControl(null)
              );
              this.cambiarControls(this.form.getRawValue().rol);
              this.escucharRol();
            },
            (error: HttpErrorResponse) => {
              this.activeModal.close({ respuesta: null });
              this.globalService.manageError(error);
            }
          );

          this.registracionService
            .getPrestadoresDeLugarAtencion(this.datos.codigoInstalacion)
            .pipe(take(1))
            .subscribe(
              (respuesta) => {
                this.prestadoresLugarAtencion = respuesta;
              },
              (error) => {
                this.globalService.manageError(error);
              }
            );
        } else {
          this.redirectError();
        }
      } else if (this.tipoDatosAPedir == 'RolTodos') {
        if (this.datos) {
          this.roles = this.globalService.getRolesAMostrarByTipoInstalacion(
            'Administrador',
            this.datos.tipoInstalacion
          );
          this.form.setControl(
            'rol',
            new UntypedFormControl(this.roles[0], [Validators.required])
          );
          this.form.setControl(
            'cuitPrestadorSeleccionado',
            new UntypedFormControl(null)
          );

          this.registracionService
            .getPrestadoresDeLugarAtencion(this.datos.codigoInstalacion)
            .pipe(take(1))
            .subscribe(
              (respuesta) => {
                this.prestadoresLugarAtencion = respuesta;
              },
              (error) => {
                this.globalService.manageError(error);
              }
            );
          this.cambiarControls(this.form.getRawValue().rol);

          this.escucharRol();
        } else {
          this.redirectError();
        }
      } else if (this.tipoDatosAPedir == 'LugaresAtencion') {
        if (this.lugaresDeAtencion) {
          this.form.setControl(
            'lugarAtencion',
            new UntypedFormControl(this.lugaresDeAtencion[0].codigoInstalacion, [
              Validators.required,
            ])
          );
        } else {
          this.redirectError();
        }
      } else {
        this.redirectError();
      }
      this.subscriptionStore = this.store
        .select('ui')
        .subscribe((uiState: UIState) => {
          this.cargando = uiState.loading;
        });
    }
  }

  escucharRol() {
    if (this.tipoDatosAPedir == 'RolTodos' || this.tipoDatosAPedir == 'Rol') {
      this.form.controls['rol'].valueChanges.subscribe((rol) => {
        this.cambiarControls(rol);
      });
    }
  }

  cambiarControls(rol: string) {
    if (rol == 'Prestador') {
      this.MostrarSeleccionarPrestador = true;
      this.form.controls['cuitPrestadorSeleccionado'].setValidators(
        Validators.required
      );
    } else {
      this.MostrarSeleccionarPrestador = false;
      this.form.controls['cuitPrestadorSeleccionado'].setValue(null);
      this.form.controls['cuitPrestadorSeleccionado'].setValidators(null);
      this.form.controls['cuitPrestadorSeleccionado'].updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  devolverRespuesta(rta: boolean) {
    console.log(rta, this.form);

    this.submitted = true;
    if (rta) {
      if (this.form.invalid) {
        return;
      }
      if (this.tipoDatosAPedir == 'Email') {
        this.activeModal.close({ respuesta: this.form.getRawValue().email });
      } else if (this.tipoDatosAPedir == 'Rol') {
        this.activeModal.close({ respuesta: this.form.getRawValue() });
      } else if (this.tipoDatosAPedir == 'RolTodos') {
        this.activeModal.close({ respuesta: this.form.getRawValue() });
      } else if (this.tipoDatosAPedir == 'LugaresAtencion') {
        this.activeModal.close({
          respuesta: this.form.getRawValue().lugarAtencion,
        });
      }
    } else {
      this.activeModal.close({ respuesta: null });
    }
  }

  redirectError() {
    this.activeModal.close({ respuesta: null });
    
    this.globalService.redirect(
      new redirectClass('contactanos', errorGenerico)
    );
  }
}
