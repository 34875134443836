<head>
    <!-- <link rel="stylesheet" href="../../../../sass/estilos.scss"> -->
</head>

<!-- <nav class="navbar navbar-expand navbar-light bg-navegation rounded-top"> -->
<!-- <nav> -->



<!-- <div class="navbar-nav w-100 justify-content-start"> -->
<div class="components">
    <button *ngIf="volverAtrasAuth" type="submit" class="botton" (click)="irAtras()" [disabled]="cargando">
        Atr&aacute;s
    </button>
</div>

<!-- <div class="navbar-nav w-100 justify-content-center"> -->
<div class="components">
    <button *ngIf="volverALogin && volverALoginAuth" type="submit" class="botton" (click)="irALogin()">Volver</button>
</div>


<!-- <div class="navbar-nav w-100 justify-content-end"> -->
<div class="components">

    <button *ngIf="!cargando && mostrarSiguiente && next" class="botton" (click)="siguiente()">

        {{nombreBoton}}
    </button>
    <button *ngIf="cargando && !volverALoginAuth" type="submit" class="botton" [disabled]="true">
        <fa-icon class="botton" [icon]='["fas", "sync"]' [spin]="true">
        </fa-icon>
        Espere...
    </button>
</div>


<!-- </nav> -->