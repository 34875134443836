import * as models from '../../store/models/store.models';
import * as recetas from '../../store/actions/recetas.action';

const estadoInicial: models.recetasState = {
  RecetasRequest: null,
  EsRecetaRequest: null
};

export function recetasReducer(
  state = estadoInicial,
  action: recetas.recetasActions
): models.recetasState {
  switch (action.type) {
    case recetas.SETRECETASREQUEST:
      return {
        ...state,
        RecetasRequest: action.RecetasRequest,
      };
    case recetas.UNSETRECETASREQUEST:
      return {
        ...state,
        RecetasRequest: null,
      };
    case recetas.SETESRECETAREQUEST:
      return {
        ...state,
        EsRecetaRequest: action.EsRecetaRequest,
      };
    case recetas.UNSETESRECETAREQUEST:
      return {
        ...state,
        EsRecetaRequest: null,
      };
    default:
      return state;
  }
}
