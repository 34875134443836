import * as models from '@store/models/store.models';
import * as fromUI from '@store/actions/datosInicialesExtra.actions';

const estadoInicial: models.datosInicialesExtraState = {
  datosInicialesExtra: null,
};

export function datosInicialesExtraReducer(
  state = estadoInicial,
  action: fromUI.datosInicialesExtraActions
): models.datosInicialesExtraState {
  switch (action.type) {
    case fromUI.SETDATOSINICIOEXTRA:
      return {
        ...state,
        datosInicialesExtra: action.datosExtra,
      };

    case fromUI.UNSETDATOSINICIOEXTRA:
      return {
        ...state,
        datosInicialesExtra: null,
      };

    default:
      return state;
  }
}
