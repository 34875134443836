import * as datosDispensa from '@models/datosDispensa.models';
import { Action } from '@ngrx/store';
import * as datosTransaccion from '@models/datosTransaccion.models';

export const SETSTEPMONODROGAREQUEST = '[datosDispensa] set STEPMONODROGAREQUEST';

export const UNSETSTEPMONODROGAREQUEST = '[datosDispensa] Unset STEPMONODROGAREQUEST';

export const SETSTEPESTUDIOREQUEST = '[datosDispensa] set STEPESTUDIOREQUEST';
export const UNSETSTEPESTUDIOREQUEST = '[datosDispensa] Unset STEPESTUDIOREQUEST';

export const SETMONODROGAREQUEST = '[datosDispensa] Set MONODROGAREQUEST';
export const UNSETMONODROGAREQUEST = '[datosDispensa] Unset MONODROGAREQUEST';

export const SETESTUDIOREQUEST = '[datosDispensa] Set ESTUDIOREQUEST';
export const UNSETESTUDIOREQUEST = '[datosDispensa] Unset ESTUDIOREQUEST';

export const SETMEDICAMENTOSELECCIONADOREQUEST = '[datosDispensa] Set MEDICAMENTOSELECCIONADOREQUEST';
export const UNSETMEDICAMENTOSELECCIONADOREQUEST = '[datosDispensa] Unset MEDICAMENTOSELECCIONADOREQUEST';

export const SETESTUDIOSELECCIONADOREQUEST = '[datosDispensa] Set ESTUDIOSELECCIONADOREQUEST';
export const UNSETESTUDIOSELECCIONADOREQUEST = '[datosDispensa] Unset ESTUDIOSELECCIONADOREQUEST';

export class setStepMonodrogaRequest implements Action {
  readonly type = SETSTEPMONODROGAREQUEST;
  constructor(public valorStep: datosDispensa.NumeroStepModel) {}
}

export class unsetStepMonodrogaRequest implements Action {
  readonly type = UNSETSTEPMONODROGAREQUEST;
}

export class setStepEstudioRequest implements Action {
  readonly type = SETSTEPESTUDIOREQUEST;
  constructor(public valorStep: datosDispensa.NumeroStepModel) {}
}

export class unsetStepEstudioRequest implements Action {
  readonly type = UNSETSTEPESTUDIOREQUEST;
}

export class setMonodrogaRequest implements Action {
  readonly type = SETMONODROGAREQUEST;
  constructor(public monodrogaRequest: datosDispensa.MonodrogaRequestModel) {}
}

export class unsetMonodrogaRequest implements Action {
  readonly type = UNSETMONODROGAREQUEST;
}

export class setEstudioRequest implements Action {
  readonly type = SETESTUDIOREQUEST;
  constructor(public estudioRequest: datosDispensa.EstudiosRequestModel) {}
}

export class unsetEstudioRequest implements Action {
  readonly type = UNSETESTUDIOREQUEST;
}

export class setMedicamentoSeleccionadoRequest implements Action {
  readonly type = SETMEDICAMENTOSELECCIONADOREQUEST;
  constructor(public medicamentoSeleccionadoRequest: datosTransaccion.DetalleMedicamentosRequestModel) {}
}

export class unsetMedicamentoSeleccionadoRequest implements Action {
  readonly type = UNSETMEDICAMENTOSELECCIONADOREQUEST;
}

export class setEstudioSeleccionadoRequest implements Action {
  readonly type = SETESTUDIOSELECCIONADOREQUEST;
  constructor(public estudioSeleccionadoRequest: datosTransaccion.DetalleEstudiosRequestModel) {}
}

export class unsetEstudioSeleccionadoRequest implements Action {
  readonly type = UNSETESTUDIOSELECCIONADOREQUEST;
}

export type informarDatosDispensaActions =
  | setStepMonodrogaRequest
  | unsetStepMonodrogaRequest
  | setMonodrogaRequest
  | unsetMonodrogaRequest
  | setEstudioRequest
  | unsetEstudioRequest
  | setStepEstudioRequest
  | unsetStepEstudioRequest
  | setMedicamentoSeleccionadoRequest
  | unsetMedicamentoSeleccionadoRequest
  | setEstudioSeleccionadoRequest
  | unsetEstudioSeleccionadoRequest;
