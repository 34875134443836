<div class="container-xxxl">
    <div class="row justify-content-center p-5">
        <div class="col-12 card p-0 border-0 boxShadow">
            <div class="card-header p-0 bg-navegation rounded-top p-3 text-center" style="color: white;">
                <div class="row">
                    <div id="buscador" class="col-12 col-md-4 text-left my-auto">
                        <div class="col-12 col-md-12 col-lg-10">
                            <app-buscador-filter (changeSearch)="refresh($event)"></app-buscador-filter>
                        </div>

                    </div>
                    <div class="col-12 col-md-4 text-center my-auto">
                        Agenda
                    </div>
                    <div id="agregarContacto" class="col-12 col-md-4 text-right">
                        <button class="btn btn-pcposalt text-center text-right-md" (click)="agregarContacto()">
                            Agregar contacto <fa-icon [icon]='["fas", "plus"]' class="ml-1" [attr.disabled]="cargando">
                            </fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body rounded p-5">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="bg-light">
                            <th class="text-center">#</th>
                            <th class="text-center">Apellido y nombre</th>
                            <th class="text-center">DNI</th>
                            <th class="text-center">Email</th>
                            <th class="text-center">Teléfono</th>
                            <th class="text-center">Financiadores y número de afiliado</th>
                            <th class="text-center">Modificar</th>
                            <th class="text-center">Eliminar</th>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let contacto of contactos; let indice = index;">
                                <tr>
                                    <td class="text-center">{{(10*(page-1))+indice+1}}</td>
                                    <td class="text-center">
                                        <ngb-highlight [result]="contacto.apellido" [term]="searchText">
                                        </ngb-highlight>,
                                        <ngb-highlight [result]="contacto.nombre" [term]="searchText">
                                        </ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <ngb-highlight [result]="contacto.DNI" [term]="searchText">
                                        </ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <ngb-highlight [result]="contacto.email" [term]="searchText">
                                        </ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <ngb-highlight [result]="contacto.telefono" [term]="searchText">
                                        </ngb-highlight>
                                    </td>
                                    <ng-container
                                        *ngIf="contacto.obras && contacto.obras.length > 0; then tieneObrasBlock else noTieneObrasBlock">
                                    </ng-container>

                                    <!-- Tiene obras cargadas -->
                                    <ng-template #tieneObrasBlock>
                                        <td data-toggle="collapse" [attr.data-target]="'#collapse-' + indice"
                                            class="text-center" [attr.data-target]="'#collapse-' + indice">
                                            <fa-icon [icon]='["fas", "eye"]' class="text-success pointer"></fa-icon>
                                        </td>
                                    </ng-template>

                                    <!-- No tiene obras cargadas -->
                                    <ng-template #noTieneObrasBlock>
                                        <td class="text-center">
                                            <fa-icon [icon]='["fas", "eye"]' class="text-secondary"></fa-icon>
                                        </td>
                                    </ng-template>

                                    <td class="text-center">
                                        <fa-icon [icon]='["fas", "edit"]' class="text-success pointer"
                                            (click)="editContacto(contacto)" [attr.disabled]="cargando"></fa-icon>
                                    </td>

                                    <td class="text-center">
                                        <fa-icon [icon]='["fas", "trash-alt"]' class="text-danger pointer"
                                            (click)="eliminarContacto(contacto)" [attr.disabled]="cargando"></fa-icon>
                                    </td>
                                </tr>


                                <ng-container *ngFor=" let afiliadoFinanciador of contacto.obras">
                                    <tr [attr.id]="'collapse-' + indice"
                                        class="container collapse  animated fadeInLeft table-secondary">
                                        <td colspan="8" class="text-center">
                                            Financiador:<strong> {{afiliadoFinanciador.nombreFinanciador}}</strong> |
                                            Número
                                            de
                                            afiliado:<strong> {{afiliadoFinanciador.numeroAfiliado}}</strong>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div *ngIf="contactos" class="col-12">
                        <ngb-pagination class="d-flex justify-content-end" [maxSize]="4" [(page)]="page"
                            [pageSize]="pageSize" [collectionSize]="totalItems" (pageChange)="refresh()"
                            ng-show="blocked">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
