import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import {
  codigoInstalacion,
  redirectClass,
  showAlert,
} from '@models/global.models';
import { LoginModel, UsuarioActualModel } from '@models/login.models';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { GlobalService } from '@services/global.service';
import { LoginService } from '@services/login.service';
import { AppState } from '@store/app.reducers';
import { Observable, Subscription } from 'rxjs';
import { IFormGroup, IFormBuilder, ControlState } from '@rxweb/types';
import {
  ActivarLoading,
  DesactivarLoading,
  removeDatosUsuario,
  setNuevoToken,
  setUsuarioActual,
} from '@store/actions';
import { DialogsService } from '@services/dialogs.service';
import { Router } from '@angular/router';
import { errorGenerico } from 'errores';
import { take } from 'rxjs/operators';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { RegistracionService } from '@services/registracion.service';
import { PersistenciaService } from '../../services/persistencia.service';
import { InputLoginComponent } from '../../components/input-login/input-login.component';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild(InputLoginComponent)
  InputLoginComponent!: InputLoginComponent;

  private subscriptionStore: Subscription = new Subscription();
  fieldTextType: boolean = false;
  submitted: boolean = false;
  codigosInstalacion: Array<codigoInstalacion> | null = null;
  register: boolean = false;
  rolAdmin: string = '';
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<LoginModel> = this.formBuilder.group<LoginModel>({
    codigoInstalacion: new UntypedFormControl(<ControlState<string>>null, [
      Validators.required,
      blankSpaceValidator,
    ]),
    username: new UntypedFormControl(<ControlState<string>>null, [
      Validators.required,
      blankSpaceValidator,
      Validators.email,
    ]),
    password: new UntypedFormControl(<ControlState<string>>null, [
      Validators.required,
      blankSpaceValidator,
    ]),
  });
  url = 'https://nuvalid.dev.puntosalud.ar';


  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private loginService: LoginService,
    private dialogsService: DialogsService,
    private globalService: GlobalService,
    private registracionService: RegistracionService,
    private persistenciaService: PersistenciaService,
    private router: Router
  ) {}

  ngOnInit() {
    if (window.location.origin == this.url) {
      this.dialogsService.infoHTML('IMPORTANTE', '<p>A partir del 14 de Octubre la versión de Nuvalid que esta utilizando dejara de funcionar.</p><p>Comunicarse con el Area de Soporte y Analisis para instalar la nueva versión.</p><p><b>0810-8102-482</b><p><p>Saludos Cordiales.</p><p>El equipo de Nuvalid.</p>');
    }

    this.subscriptionStore = this.store
      .select('configuracion')
      .pipe(take(1))
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          //Si habian datos de un usuario que se habia logeado anteriormente
          let userActual = configuracionState!.usuarioActual!;
          this.authService.esTokenValido().subscribe(
            (respuesta) => {
              this.store.dispatch(new setUsuarioActual({...userActual, esTurnea: false}));
              if (respuesta.token) {
                this.store.dispatch(new setNuevoToken(respuesta.token));
                this.globalService.redirect(new redirectClass('inicio'));
              } else {
                this.globalService.redirect(new redirectClass('inicio'));
              }
            },
            (error: HttpErrorResponse) => {
              this.globalService.manageError(error);
            }
          );
        }
        if (!configuracionState.codigosInstalacion) {
          //No hay codigos de instalacion
          this.globalService.redirect(
            new redirectClass(
              'contactanos',
              '',
              'No hay codigos de instalacion guardados'
            )
          );
          return;
        }
        this.codigosInstalacion = configuracionState.codigosInstalacion;
        this.form.controls['codigoInstalacion'].setValue(
          this.codigosInstalacion[0].codigoInstalacion
        );
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  setUsername(userEmail: UsuarioActualModel | null) {
    if (userEmail) {
      this.form.controls['username'].setValue(userEmail?.email);
    } else {
      this.form.controls['username'].setValue(null);
    }
  }

  login(event: any) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.store.dispatch(new ActivarLoading());
    this.loginService.login(this.form.getRawValue() as LoginModel).subscribe(
      (respuesta: UsuarioActualModel) => {
        console.log('la respuesta del login service', respuesta);
        this.globalService.redirect(new redirectClass('inicio'));
        this.store.dispatch(new setUsuarioActual({...respuesta,esTurnea: false}));
        this.persistenciaService.setEmailUsuarioActual([respuesta]);
      },
      (error: HttpErrorResponse) => {
        this.store.dispatch(new DesactivarLoading());
        if (error.status == 412 && this.codigosInstalacion) {
          let codigoInstalacion = this.codigosInstalacion.find(
            (x) =>
              x.codigoInstalacion == this.form.getRawValue().codigoInstalacion
          );
          if (event && event.srcElement) {
            event.srcElement.blur();
            event.preventDefault();
          }
          console.log('isInstace result.length: ', this.dialogsService.isInstace);
          // // this.dialogsService.isInstace.subscribe((result:any) => {
          //   console.log('isInstace result.length: ', result.observers.length);
          if (!this.dialogsService.isInstace) {
            //     console.log('isInstace result.length: ', result);
            this.dialogsService
              .solicitarInfoModal(
                'Seleccione el rol que desea para este lugar de atención',
                'Rol',
                codigoInstalacion
              )
              .result.then((respuesta: any) => {
                if (respuesta && respuesta.respuesta) {
                  this.store.dispatch(new ActivarLoading());
                  this.loginService
                    .crearCuentaInstalacion(
                      this.form.getRawValue(),
                      respuesta.respuesta.rol,
                      respuesta.respuesta.cuitPrestadorSeleccionado
                    )
                    .subscribe(
                      (respuestaObtenida) => {
                        this.store.dispatch(new DesactivarLoading());
                        this.dialogsService.toastSuccess(
                          respuestaObtenida.message
                        );
                        this.dialogsService.cerrar();
                      },
                      (error: HttpErrorResponse) => {
                        this.store.dispatch(new DesactivarLoading());
                        this.globalService.manageError(error);
                      }
                    );
                }
              })
              .catch(() => {
                this.store.dispatch(new DesactivarLoading());
                this.globalService.redirect(
                  new redirectClass('contactanos', errorGenerico)
                );
              });
          }
        } else {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      }
    );
  }

  goToSignUp() {
    this.globalService.redirect(new redirectClass('signup'));
  }

  verAdministradores() {
    if (this.codigosInstalacion) {
      this.loginService.mostrarAdministradores(this.codigosInstalacion);
    } else {
      this.globalService.redirect(
        new redirectClass('contactanos', errorGenerico)
      );
    }
  }

  recuperarContrasenna(event: any) {
    //Dispara el modal, si ingresa un email lo retorna
    if (event && event.srcElement) {
      event.srcElement.blur();
      event.preventDefault();
    }
    this.dialogsService
      .solicitarInfoModal(
        'Al ingresar su correo recibirá instrucciones sobre como cambiar su contraseña.',
        'Email'
      )
      .result.then((respuesta: any) => {
        if (respuesta && respuesta.respuesta) {
          this.store.dispatch(new ActivarLoading());
          this.loginService.recuperarContrasenna(respuesta.respuesta).subscribe(
            (respuestaObtenida) => {
              this.store.dispatch(new DesactivarLoading());
              this.dialogsService.success('', respuestaObtenida.message);
            },
            (error: HttpErrorResponse) => {
              this.store.dispatch(new DesactivarLoading());
              this.globalService.manageError(error);
            }
          );
        }
      })
      .catch(() => {

        this.store.dispatch(new DesactivarLoading());
        this.globalService.redirect(
          new redirectClass('contactanos', errorGenerico)
        );
      });
  }

  nuevoLugarAtencion() {
    this.router.navigate(['instalacion']);
  }

  contacto() {
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }

  registrar() {
    this.register = true;
  }

  inicio() {
    this.register = false;
  }

}
