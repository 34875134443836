<div class="container__panelPrestacion">
  <div class="container-diagnostico">
    <p class="title__diagnostico">Diagnóstico</p>
    <form [formGroup]="form" class="form__diagnostico">

      <ng-container formArrayName="diagnosticos" *ngFor="let diagnostico of getDiagnosticos(); let i = index">
        <ng-container [formGroupName]="i">

          <ng-container *ngIf="esRescate; else noRescate">
            <div class="form-infoN">
              <label for="codigoDiagnostico">Código<span *ngIf="diagnostico.get('CodDiagnostico') | controlRequired"
                  class="span-required">*</span></label>
              <input type="text" class="form-controlN" formControlName="CodDiagnostico">
            </div>

            <div class="form-infoN" id="descripcionContainer">
              <label for="descripcionDiagnostico">Descripción<span
                  *ngIf="diagnostico.get('DescripcionDiagnostico') | controlRequired"
                  class="span-required">*</span></label>
              <input type="text" class="form-controlN" formControlName="DescripcionDiagnostico">
            </div>
          </ng-container>

          <ng-template #noRescate>
            <div class="form-infoN">
              <label for="codigoDiagnostico">Código<span *ngIf="diagnostico.get('CodDiagnostico') | controlRequired"
                  class="span-required">*</span></label>
              <input id="codigoDiagnostico" maxlength="10" autocomplete="off" autocorrect="off" spellcheck="false"
                autocomplete="off" type="text" class="form-controlN" formControlName="CodDiagnostico">
            </div>

            <div class="form-infoN" id="descripcionContainer">
              <label for="descripcionDiagnostico">Descripción<span
                  *ngIf="diagnostico.get('DescripcionDiagnostico') | controlRequired"
                  class="span-required">*</span></label>
              <input maxlength="50" id="descripcionDiagnostico" autocomplete="off" autocorrect="off" spellcheck="false"
                autocomplete="off" type="text" class="form-controlN" formControlName="DescripcionDiagnostico">
            </div>
          </ng-template>


        </ng-container>
      </ng-container>
    </form>
  </div>
</div>
