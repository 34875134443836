//Error generico
export const errorGenerico =
  'Se produjo un error, por favor comuníquese con nosotros';

//Errores que se usan en los catch de instalacion.component.ts
export const erroresInstalacion = {
  mensaje1:
    'No tenemos registrado ningún prestador con ese CUIT, comuniquese con nosotros',
};

//Errores que se usan en los catch de select-lugar-atencion.component.ts
export const erroresSelecccionarLugarAtencion = {
  mensaje1:
    'No se encontraron lugares de atención activos para el prestador selecionado,por favor comuniquese con nosotros',
  mensaje3: errorGenerico,
  mensaje4:
    'Se produjo un error al buscar los prestadores del lugar de atención seleccionado, por favor comuniquese con nosotros',
};

//Errores que se usan en los catch de map.component.ts
export const erroresMap = {
  mensaje1:
    'No se encontraron lugares de atención activos para el prestador selecionado,por favor comuniquese con nosotros',
  mensaje2:
    'Lamentamos que no se no haya podido encontrar su lugar de atención por favor complete el siguiente formulario con información pertinente para que nuestros analistas puedan solucionar el inconveniente.',
};
