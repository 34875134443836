import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DatosAfiliado, DatosAfiliadoAgendaModel } from '@models/agenda.models';
import { Store } from '@ngrx/store';
import { AgendaService } from '@services/agenda.service';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { UIState } from '@store/models/store.models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  page: number = 1;
  pageSize: number = 10;
  totalItems: number = 1;
  cargando: boolean = false;
  searchText: string = '';
  contactos: Array<DatosAfiliadoAgendaModel> | null = null;

  constructor(
    private agendaService: AgendaService,
    private store: Store<AppState>,
    private globalService: GlobalService,
    private dialogsService: DialogsService,
    private router: Router
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  setStore() {
    this.subscriptionStore = this.store
      .select('ui')
      .subscribe((uiState: UIState) => {
        this.cargando = uiState.loading;
      });
  }

  refresh(searchText: string = '') {
    this.store.dispatch(new ActivarLoading());
    this.agendaService
      .getContactosAgenda(this.page - 1, this.pageSize, searchText)
      .subscribe(
        (respuesta) => {
          console.log(respuesta);

          this.contactos = respuesta.afiliados;
          this.totalItems = respuesta.totalItems;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
        },
        (error: HttpErrorResponse) => {
          this.contactos = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }

  agregarContacto() {
    this.router.navigate(['agenda/CrearContacto']);
  }

  editContacto(contacto: DatosAfiliadoAgendaModel) {
    let navigationExtras: NavigationExtras = {
      state: {
        contacto: contacto,
      },
    };
    this.router.navigate(['agenda/CrearContacto'], navigationExtras);
  }

  eliminarContacto(contacto: DatosAfiliadoAgendaModel) {
    this.dialogsService
      .confirmarModal(
        `¿Esta seguro que desea eliminar a ${contacto.apellido},${contacto.nombre} de la agenda?`
      )
      .result.then((respuesta: any) => {
        if (respuesta && respuesta.respuesta) {
          this.store.dispatch(new ActivarLoading());
          this.dialogsService.cargando();
          this.agendaService.eliminarContacto([{ id: contacto.id }]).subscribe(
            (respuesta) => {
              this.dialogsService.toastSuccess(respuesta.message);
              this.refresh();
            },
            (error: HttpErrorResponse) => {
              this.store.dispatch(new DesactivarLoading());
              this.dialogsService.cerrar();
              this.globalService.manageError(error);
            }
          );
        }
      });
  }
}
