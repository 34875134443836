<div class="container-xxxl">
  <div class="row justify-content-center p-5">
    <div class="col-12 card p-0 border-0 boxShadow">
      <div class="card-header p-0 bg-navegation rounded-top p-3 text-center pointer" style="color: white;">
        Seleccionar prestadores no deseados
      </div>
      <div class="card-body rounded p-5">
        <form [formGroup]="form" class="pb-3 pl-3 pr-3" (ngSubmit)="guardarCambios()" action="aplicaciones ">
          <div class="bg-form mt-3">
            <div class="p-5 mt-4 shadow-sm rounded cuadrado" formArrayName="cuits"
              *ngFor="let diagnostico of getCuits(); let i = index">
              <div class="row">
                <div class="col-12">
                  <h5 class="pl-0">Prestador no deseado {{i+1}} </h5>
                  <hr>
                </div>
              </div>

              <div class="row p-3" [formGroupName]="i">

                <div class="form-group col-md-6">
                  <label>Selecciones prestador no deseado</label>
                  <select formControlName="cuitPrestador" class="form-select form-control">
                    <option *ngFor="let prestador of prestadores" value="{{prestador.cuit}}"
                      [disabled]="prestadoresNoDeseadosSeleccionados.includes(prestador.cuit) ? true : null">
                      {{prestador.razon_social}}</option>
                  </select>
                </div>
              </div>

              <div class="row pl-3 pr-3">
                <div class="col-12  text-right">
                  <button type="button" class="btn btn-pcpos" (click)="deletePrestador(i)">Borrar</button>


                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 text-right">
                <ng-container *ngIf="form.controls['cuits'].invalid">
                  <button type="button" class="btn btn-secondary">
                    Agregar <fa-icon [icon]='["fas", "plus"]' class="ml-1"></fa-icon>
                  </button>
                </ng-container>

                <ng-container *ngIf="!form.controls['cuits'].invalid">
                  <button type="button" class="btn btn-pcpos" (click)="addPrestador()">
                    Agregar <fa-icon [icon]='["fas", "plus"]' class="ml-1"></fa-icon>
                  </button>
                </ng-container>

              </div>
            </div>
          </div>

        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-6 text-left">
            <button class="btn btn-pcpos text-left" (click)="irAtras()" [disabled]="cargando">
              <fa-icon [icon]='["fas", "chevron-left"]' class="mr-1"></fa-icon>Atras
            </button>
          </div>
          <div class="col-6 text-right">
            <button *ngIf="!cargando" type="submit" class="btn btn-pcpos text-right" (click)="guardarCambios()">Guardar
              <fa-icon [icon]='["fas", "chevron-right"]'></fa-icon>
            </button>
            <button *ngIf="cargando" type="submit" class="btn btn-pcpos text-right" [disabled]="true">
              <fa-icon class="mr-1 fa-spin" [icon]='["fas", "sync"]' [spin]="true"></fa-icon>
              Espere...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
