<div id="downloadModal">
  <div class="" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="downloadModalLabel">Descargar reporte</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" style="display: flex; gap: 20px; flex-direction: column; flex-wrap: wrap;">
          <div class="form-group">
            <div class="form-infoN">
              <label for="Financiador">Reporte </label>
              <select class="form-controlN" id="Financiador" formControlName="Financiador">
                <option [ngValue]="ft.cuit" *ngFor="let ft of finanDownload" [selected]="ft.sigla === 'OSDE'">
                  {{ ft.sigla }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="form-infoN">
                  <label for="FechaDesde">Fecha desde </label>
                  <input type="text" placeholder="dd/mm/yyyy" class="form-controlN form-controlN-dashboardP"
                    bsDatepicker [bsConfig]="{containerClass: 'theme-blue', adaptivePosition: true, isAnimated: true, minDate: minDateDesde()}"
                    formControlName="FechaDesde" readonly>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="form-infoN">
                  <label for="FechaHasta">Fecha hasta </label>
                  <input type="text" placeholder="dd/mm/yyyy" class="form-controlN form-controlN-dashboardP"
                    bsDatepicker [bsConfig]="{containerClass: 'theme-blue', adaptivePosition: true, isAnimated: true }"
                    formControlName="FechaHasta" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-infoN">
              <label for="Prestador">Prestador </label>
              <select class="form-controlN" id="Prestador" formControlName="Prestador">
                <option value="">Todos</option>
                <option [ngValue]="prestador.cuit" *ngFor="let prestador of prestadores">
                  {{ prestador.razon_social }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group" style="display: flex;">
            <div class="form-infoN">
              <label for="txtFormat">Formato TXT </label>
              <input type="checkbox" class="form-check-input" id="txtFormat" formControlName="TxtFormat" (change)="onCheckboxChange('TxtFormat')">
            </div>
            <div class="form-infoN">
              <label for="csvFormat">Formato CSV </label>
              <input type="checkbox" class="form-check-input" id="csvFormat" formControlName="CsvFormat" (change)="onCheckboxChange('CsvFormat')">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="buttonBuscarTickets" (click)="downloadFile()">Descargar</button>
        <button class="buttonAnularTicket" (click)="devolverRespuesta('CERRAR')">Cerrar</button>
      </div>
    </div>
  </div>
</div>
