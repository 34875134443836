import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EstudiosRequestModel, GrupoEstudiosRequest, NumeroStepModel } from '@models/datosDispensa.models';
import { DetalleEstudiosRequestModel, DetalleMedicamentosRequestModel } from '@models/datosTransaccion.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { DispensaaService } from '@services/dispensaa.service';
import { setDetallesEstudiosRequest, setDetallesMedicamentosRequest, setEstadoFormDetallesMedicamentosRequest, setEstudioRequest, setStepEstudioRequest, unsetEstudioRequest, unsetStepEstudioRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription, fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-input-estudio',
  templateUrl: './input-estudio.component.html',
  styleUrls: ['./input-estudio.component.scss']
})
export class InputEstudioComponent implements OnInit {
  @ViewChild('monodrogaSearchInput', { static: true }) monodrogaSearchInput!: ElementRef;
  @ViewChild('estudioSearchInput', { static: true }) estudioSearchInput!: ElementRef;
  searchText = '';
  searchTextEstudio = '';
  apiResponse: any;
  isSearching: boolean;
  isCodigoDroga: boolean = false;

  arrayEstudios: Array<GrupoEstudiosRequest> = [];
  arrayEstudioDetalles: Array<EstudiosRequestModel> = [];
  arrayPresentacionesDetalles: Array<any> = [];

  subscriptionStore: Subscription = new Subscription();
  subscriptionStoreMedicamentos: Subscription = new Subscription();
  step: NumeroStepModel = { numero: '1', direccion: 'next' };
  arrayDetalleEstudios: Array<DetalleEstudiosRequestModel> = [];
  arrayDetalleMedicamentos: Array<DetalleMedicamentosRequestModel> = [];

  analisisClicked: boolean = true;
  //TODO hacer la lógica para que buscar estos estudios cuando se usen
  diagnosticoClicked: boolean = false;
  certificadoClicked: boolean = false;

  constructor(
    private dispensaaService: DispensaaService,
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {
    this.isSearching = false;
    this.store.dispatch(new setStepEstudioRequest(this.step));
    this.getEstudios({ operacion: 'grupos_estudio', rango: '20' });
  }

  async ngOnInit() {
    this.subscriptionStore = this.store
      .select('datosDispensa')
      .subscribe((datos: any) => {
        this.step = datos.stepEstudioRequest || '1';
        this.searchText = (this.step.numero === '2') ? this.searchText : '';

        if (this.step.numero === '2' && this.step.direccion === 'prev') {
          this.siguiente('2', datos.estudioRequest);
        }

        this.subscriptionStoreMedicamentos = this.store.select('datosTransaccion').subscribe((datos) => {
          this.arrayDetalleEstudios =
            datos.DetalleEstudiosRequest || [];
        });
      });

    fromEvent(this.estudioSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.store.dispatch(new setStepEstudioRequest({ numero: '2', direccion: 'next' }));
      this.isSearching = true;
      let param = { operacion: 'estudios', determinacion: text };
      this.dispensaaService.getMonoDroga(param).subscribe((res: any) => {
        this.isSearching = false;
        this.arrayPresentacionesDetalles = res;
        console.log('response from getMonoDroga:', res);
      }, (err: any) => {
        this.isSearching = false;
        console.log('error', err);
      });
    });

  }

  searchGetCall(term: string) {
    if (term === '') {
      return of([]);
    }
    let param = { operacion: 'grupos_estudio', descripcion: term };
    return this.getEstudios(param);
  }

  ngOnDestroy(): void {
    this.subscriptionStore.unsubscribe();
    this.store.dispatch(new unsetStepEstudioRequest());
    this.store.dispatch(new unsetEstudioRequest());
  }

  agregarEstudio(estudio: EstudiosRequestModel) {
    let estudioItem: DetalleEstudiosRequestModel = {
      determinacion: estudio.determinacion,
      grupo: estudio.grupo,
      nbu: estudio.nbu
    }

    this.store.dispatch(new setEstadoFormDetallesMedicamentosRequest(true));
    this.store.dispatch(new setDetallesEstudiosRequest([...this.arrayDetalleEstudios, estudioItem]));
    this.modalService.dismissAll();
  }

  siguiente(numero: string, estudio: any = {}) {
    this.step = { numero, direccion: 'next' };
    this.store.dispatch(new setStepEstudioRequest(this.step));
    console.log('estudio', estudio);
    if (this.step.numero === '2') {
      this.store.dispatch(new setEstudioRequest(estudio));
      let param = { operacion: 'estudios', grupo: estudio.nombre };
      this.isSearching = true;
      this.getEstudios(param);
    }
  }

  getEstudios(param: any) {
    console.log('param', param);
    this.arrayEstudioDetalles = [];
    this.arrayPresentacionesDetalles = [];
    this.dispensaaService.getMonoDroga(param).subscribe((result: any) => {
      if (param.operacion === 'grupos_estudio') {
        this.arrayEstudios = result || [];
      } else if (param.operacion === 'estudios') {
        this.isSearching = false;
        this.arrayEstudioDetalles = result;
      }
    }, (err: any) => {
      this.isSearching = false;
      console.log('error', err);
    });
  }

  cambiarStep() {
    this.step = { numero: (Number(this.step.numero) - 1) + '', direccion: 'prev' };
    this.store.dispatch(new setStepEstudioRequest(this.step));
  }

  closeModal() {
    this.store.dispatch(new unsetStepEstudioRequest());
    this.store.dispatch(new unsetEstudioRequest());
    this.modalService.dismissAll();
  }

}
