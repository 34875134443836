<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>
<form [formGroup]="form" class="form-infoN">

  <div *ngIf="fechaAtencion">
    <div class="form-infoN prestacion__fecha">
      <label for="fechaPrestacion">Fecha <span *ngIf="form.get('FechaAtencion') | controlRequired"
          class="span-required">*</span></label>
      <input id="cFecha" type="text" placeholder="DD/MM/YYYY" class="form-controlN form-controlN-dashboardP"
        bsDatepicker [maxDate]='fechaDiferida'
        [bsConfig]="{containerClass: 'theme-blue', adaptivePosition: true, isAnimated: true}"
        formControlName="FechaAtencion">
    </div>
  </div>

  <div *ngIf="atencionTipo">
    <div class="form-infoN">
      <label>Lugar de atención <span class="text-danger" *ngIf="form.get('TipoAtencion')">*</span></label>
      <select id="cFecha" class="form-controlN form-controlN-dashboardP" formControlName="TipoAtencion">
        <option [ngValue]="null">
          Seleccione
        </option>
        <option value="C" >
          Consultorio
        </option>
        <option value="I" >
          Inst. ambulatoria
        </option>
        <option value="P" >
          Inst. con internación
        </option>
        <option value="B" >
          Inst. internación breve
        </option>
      </select>
    </div>
  </div>

</form>

</html>

<!-- <form [formGroup]="form">
    <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label>Fecha de atención <span class="text-danger"
          *ngIf="form.get('FechaAtencion') | controlRequired">*</span></label>
            <div class="input-group">
                <input type="text" placeholder="DD/MM/YYYY" class="form-control" bsDatepicker [maxDate]='fechaDiferida' [bsConfig]="{containerClass: 'theme-blue', adaptivePosition: true, isAnimated: true}" formControlName="FechaAtencion">

            </div>
        </div>
        <div class="form-group col-12 col-md-6">
            <label>Hora de atención <span class="text-danger"
          *ngIf="form.get('HoraAtencion') | controlRequired">*</span></label>
            <input type="time" formControlName="HoraAtencion" class="form-control">
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label>Cama <span class="text-danger" *ngIf="form.get('Cama') | controlRequired">*</span></label>
            <input type="text" class="form-control" formControlName="Cama" placeholder="Cama">
        </div>

        <div class="form-group col-12 col-md-6">
            <label>Sector <span class="text-danger" *ngIf="form.get('Sector') | controlRequired">*</span></label>
            <input type="text" class="form-control" formControlName="Sector" placeholder="Sector">
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label>Tipo de atención <span class="text-danger"
          *ngIf="form.get('TipoAtencion') | controlRequired">*</span></label>
            <select class="form-control" formControlName="TipoAtencion">
              <option [ngValue]="tipoAtencion.codigo" *ngFor="let tipoAtencion of tiposAtencion">
                {{tipoAtencion.nombre}}
              </option>
            </select>
        </div>
    </div>

</form> -->
