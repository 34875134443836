import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { unAfiliadoAgenda } from '@models/agenda.models';
import { redirectClass } from '@models/global.models';
import { GlobalService } from '@services/global.service';
import { InicioService } from '@services/inicio.service';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-input-agenda',
  templateUrl: './input-agenda.component.html',
  styleUrls: ['./input-agenda.component.scss'],
})
export class InputAgendaComponent implements OnInit {
  model: unAfiliadoAgenda | null = null;
  searching = false;
  searchFailed = false;

  @Output()
  selectAfiliadoAgenda: EventEmitter<unAfiliadoAgenda> = new EventEmitter();
  constructor(private inicioService: InicioService,
              private globalService: GlobalService) {}

  ngOnInit(): void {}

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.inicioService.search(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  formatter = (x: unAfiliadoAgenda) => `${x.nombre}, ${x.apellido}`;

  submit(e: any) {
    if (e.item) {
      this.selectAfiliadoAgenda.emit(e.item);
    }
  }

  limpiarDatos() {
    this.searching = false;
    this.searchFailed = false;
    this.model = null;
  }

  contacto() {
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }
}
