<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<form [formGroup]="form" class="formContainer-anulacion">

  <ng-container *ngIf="tipoC">
    <div class="form-infoN">
      <label for="nTicket">Código de Autorización <span class="span-required"
          *ngIf="form.get('NroReferenciaRequest') | controlRequired">*</span></label>
      <input type="text" maxlength="8" class="form-controlN" formControlName="NroReferenciaRequest">
      <small *ngIf="form.controls['NroReferenciaRequest'].errors && form.controls['NroReferenciaRequest'].touched"
        class="text-danger animated fadeIn form-text sangria">Debe ingresar 8 caracteres numéricos sin guiones.</small>
    </div>
  </ng-container>
  <ng-container *ngIf="tipoI">
    <div class="form-infoN">
      <label for="nTicket">N° de Ticket <span class="span-required"
          *ngIf="form.get('NroReferenciaRequest') | controlRequired">*</span></label>
      <input type="text" maxlength="6" class="form-controlN" formControlName="NroReferenciaRequest">
      <small *ngIf="form.controls['NroReferenciaRequest'].errors && form.controls['NroReferenciaRequest'].touched"
        class="text-danger animated fadeIn form-text sangria">Debe ingresar 6 caracteres numéricos sin guiones.</small>
    </div>
  </ng-container>
  <ng-container *ngIf="nOrden">
    <div class="form-infoN">
      <label for="nTicket">N° de Orden <span class="span-required"
          *ngIf="form.get('NroReferenciaRequest') | controlRequired">*</span></label>
      <input type="text" maxlength="8" class="form-controlN" formControlName="NroReferenciaRequest">
      <small *ngIf="form.controls['NroReferenciaRequest'].errors && form.controls['NroReferenciaRequest'].touched"
        class="text-danger animated fadeIn form-text sangria">Debe ingresar 8 caracteres numéricos sin guiones.</small>
    </div>
  </ng-container>
</form>

</html>
