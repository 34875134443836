import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { removeDatosUsuario } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { version } from '../../../../package.json';
import { Router } from '@angular/router';
import { UsuarioActualModel } from '@models/login.models';
import { setUsuarioActual } from '../../store/actions/configuracion.action';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private subscriptionConfiguracion: Subscription = new Subscription();
  nombreUsuario: string | null = null;
  apellidoUsuario: string | null = null;
  domicilioUsuario: string | null = null;
  versionActual: string = version;
  nombreFarmacia?: string = '';
  nuvaFarmacia: boolean = false;
  userActual!: UsuarioActualModel;
  esTurnea!: boolean;

  constructor(
    private store: Store<AppState>,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.subscriptionConfiguracion = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        this.userActual = configuracionState!.usuarioActual!;
        if (configuracionState.usuarioActual?.tipoInstalacion === 'NUVAFARM') {
          this.nuvaFarmacia = true;
        }

        if (this.nuvaFarmacia) {
          this.nombreFarmacia = configuracionState.datosIniciales?.prestadores![0].razon_social
        }

        if (configuracionState.usuarioActual) {
          this.nombreUsuario = configuracionState.usuarioActual.nombre;
        } else {
          this.nombreUsuario = null;
        }

        if (configuracionState.usuarioActual) {
          this.apellidoUsuario = configuracionState.usuarioActual.apellido;
        } else {
          this.apellidoUsuario = null;
        }

        if (configuracionState.usuarioActual) {
          this.domicilioUsuario = configuracionState.usuarioActual.domicilio;
        } else {
          this.domicilioUsuario = null;
        }

        if (configuracionState.usuarioActual) {
          this.esTurnea! = configuracionState.usuarioActual.esTurnea;
        }
      });

  }

  ngOnDestroy() {
    this.subscriptionConfiguracion.unsubscribe();
  }

  salir() {
    this.store.dispatch(new removeDatosUsuario());
  }

  nuvalid() {
    this.store.dispatch(new setUsuarioActual({...this.userActual,esTurnea: false}));
    this.router.navigate(['inicio']);
    if (this.router.url == '/inicio') {
      window.location.reload();
    }
  }
}
