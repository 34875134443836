import { PrestadorRequestModel } from './../models/datosInicio.models';
import { datosInicioState } from './../store/models/store.models';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import {
  DatosAfiliadoAgendaModel,
  unAfiliadoAgenda,
} from '@models/agenda.models';
import { DatosInicialesExtra } from '@models/datosInicialesExtras.models';
import { prestacionCodigoDescModel } from '@models/global.models';
import { resultMemoize, Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { ConfiguracionState, DatosIniciales, datosTransaccionesState } from '@store/models/store.models';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { diagnosticosCIE_10 } from 'assets/diagnosticosHarcodeados';
import { codigoInstalacion } from '../models/global.models';;
// import * as datosInicio from '@models/datosInicio.models';
import { UsuarioActualModel } from '../models/login.models';

@Injectable({
  providedIn: 'root',
})
export class InicioService implements OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionStoreDatosExtra: Subscription = new Subscription();
  private headers: HttpHeaders = new HttpHeaders({});
  private codigoInstalacion: string | null = null;
  private datosIniciales: DatosIniciales | null = null;
  private datosInicialesExtra: DatosInicialesExtra | null = null;
  private datosInicio: datosInicioState | null = null;
  private prestador: PrestadorRequestModel | undefined | null = null;
  // cuitF : string | undefined;
  // cuitP : string | undefined;
  // licencia : string | undefined;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private globalService: GlobalService
  ) {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState: ConfiguracionState) => {
        if (configuracionState.usuarioActual) {
          //console.log(`Bearer ${configuracionState.usuarioActual.token}`);
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
          this.codigoInstalacion =
            configuracionState.usuarioActual.codigoInstalacion;
        }
        this.datosIniciales = configuracionState.datosIniciales;
        // this.prestador = this.datosInicio?.PrestadorRequest;
        // this.cuitF = this.prestador?.CuitFinanciador;
        // this.cuitP = this.prestador?.CuitPrestador;
        // this.licencia = this.prestador?.Licencia;
      });

    this.subscriptionStoreDatosExtra = this.store
      .select('datosInicialesExtra')
      .subscribe((datos) => {
        this.datosInicialesExtra = datos.datosInicialesExtra;
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreDatosExtra.unsubscribe();
  }

  configuracionInicial(): Observable<any> {
    if (!this.datosIniciales) {
      console.log('Inicio.......');
      return this.http
        .get<any>(environment.url + `/config`, {
          headers: this.headers,
        })
        .pipe(take(1));
    } else {
      return of(false);
    }
  }

  getDatosInicialesExtra(): Observable<any> {
    if (!this.datosInicialesExtra) {
      return this.http
        .get<any>(environment.url + `/config/data`, {
          headers: this.headers,
        })
        .pipe(take(1));
    } else {
      return of(false);
    }
  }

  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get<Array<unAfiliadoAgenda>>(environment.url + `/afiliados/${term}`, {
        headers: this.headers,
      })
      .pipe(
        map((response: any) => {
          let respuesta: Array<unAfiliadoAgenda> = [];
          response.forEach((datos: DatosAfiliadoAgendaModel) => {
            datos.obras.forEach((obra) => {
              respuesta.push({
                id: datos.id,
                DNI: datos.DNI,
                nombre: datos.nombre,
                apellido: datos.apellido,
                email: datos.email,
                telefono: datos.telefono,
                nombreFinanciador: obra.nombreFinanciador,
                numeroAfiliado: obra.numeroAfiliado,
              });
            });
          });
          return respuesta;
        })
      )
      .pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }

  searchPrestaciones(term: string) {
    if (term === '') {
      return of([]);
    }
    return this.http
      .get<Array<prestacionCodigoDescModel>>(
        environment.url + `/prestaciones/${term}`,
        {
          headers: this.headers,
        }
      )
      .pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }

  searchDiagnosticos() {
    return diagnosticosCIE_10;
  }


  saveMatriculas(datosInicio: datosInicioState, datosTransaccion: datosTransaccionesState): Observable<any> {
    let matriculaAEnviar = {
      NroMatricula: datosTransaccion.PrescriptorRequest?.NroMatriculaPrescriptor,
      Provincia: datosTransaccion.PrescriptorRequest?.ProvinciaPrescriptor,
      Tipo: datosTransaccion.PrescriptorRequest?.TipoPrescriptor,
      Licencia: datosInicio.PrestadorRequest?.Licencia,
      CuitPrestador: datosInicio.PrestadorRequest?.CuitPrestador,
      CuitFinanciador: datosInicio.FinanciadorRequest?.CuitFinanciador,
    }

    console.log('matriculaAEnviar', matriculaAEnviar);
    return this.http.post<any>(
      environment.url + `/usuarios/saveMatricula`, matriculaAEnviar,
      { headers: this.headers }
    ).pipe(take(1));
  }


  getTickets(fd: string | null = '', fh: string | null = '', cuitP: string | undefined, licencia: string | undefined, cuitF: string | undefined, afiliado: string | null = ''): Observable<any> {
    return this.http.get('http://192.168.48.114:9001/api/v1/nuvalid' + `/tickets?fd=${fd}&fh=${fh}&cuitp=${cuitP}&licencia=${licencia}&cuitf=${cuitF}&afiliado=${afiliado}`, {
      headers: this.headers
    }
    )
      .pipe(take(10))
      .pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }
}
