import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-input-especialidades',
  templateUrl: './input-especialidades.component.html',
  styleUrls: ['./input-especialidades.component.scss'],
})
export class InputEspecialidadesComponent implements OnInit {
  @Input() especialidades: Array<any> = [];
  model: any;
  @Output()
  selectEspecialidad: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      map((term) =>
        term === ''
          ? []
          : this.especialidades
              .filter(
                (x) => x.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );

  formatter = (x: any) => `${x.nombre}`;

  submit(e: any) {
    if (this.model?.codigo) {
      this.selectEspecialidad.emit(this.model);
    } else {
      this.selectEspecialidad.emit(null);
    }
  }
}
