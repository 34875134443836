import { Component, OnInit } from '@angular/core';
import { NumeroStepModel } from '@models/datosDispensa.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { setStepEstudioRequest, unsetEstudioRequest, unsetStepEstudioRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-estudio-request',
  templateUrl: './datos-estudio-request.component.html',
  styleUrls: ['./datos-estudio-request.component.scss']
})
export class DatosEstudioRequestComponent implements OnInit {

  subscriptionStore: Subscription = new Subscription();
  subscriptionDatosInicioStore: Subscription = new Subscription();
  step: NumeroStepModel = {numero: '1', direccion: 'prev'};
  credencialSelecionada: string | undefined;
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.subscriptionStore = this.store
    .select('datosDispensa')
    .subscribe((datos:any) => {
      this.step = datos.stepEstudioRequest || '1';
    });

    this.subscriptionDatosInicioStore = this.store
      .select('datosInicio')
      .subscribe(async (datos) => {
        this.credencialSelecionada = datos.CredencialRequest?.NumeroCredencial;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionStore.unsubscribe();
  }

  closeModal() {
    this.store.dispatch(new unsetStepEstudioRequest());
    this.store.dispatch(new unsetEstudioRequest());
    this.modalService.dismissAll();
  }

  cambiarStep() {
    this.step = {numero: (Number(this.step.numero)-1)+'', direccion: 'prev'};
    this.store.dispatch(new setStepEstudioRequest(this.step));
  }

}
