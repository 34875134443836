import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  DatosAfiliado,
  DatosAfiliadoAgendaModel,
  GetDatosAgenda,
} from '@models/agenda.models';
import { FinaciadorNuvalid } from '@models/financiador.model';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class AgendaService {
  private subscriptionStore: Subscription = new Subscription();
  headers: HttpHeaders = new HttpHeaders({});

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private globalService: GlobalService
  ) {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
        } else {
          this.headers = new HttpHeaders({});
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  getContactosAgenda(
    page: number,
    pageSize: number,
    searchText: string
  ): Observable<GetDatosAgenda> {
    return this.http
      .get<GetDatosAgenda>(
        environment.url +
          `/afiliados?page=${page}&size=${pageSize}&searchText=${searchText}`,
        {
          headers: this.headers,
        }
      )
      .pipe(take(1))
      .pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }

  getContactoPorCredencial(credencial:string, cuitFinanciador:string): Observable<DatosAfiliadoAgendaModel> {
    return this.http
      .get<DatosAfiliadoAgendaModel>(environment.url + `/afiliados/${credencial}?financiador=${cuitFinanciador}`, {
        headers: this.headers,
      })
      .pipe(take(1))
      .pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }

  getFinanciadores(): Observable<Array<FinaciadorNuvalid>> {
    return this.http
      .get<Array<FinaciadorNuvalid>>(environment.url + '/financiadores', {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  guardarContacto(datos: DatosAfiliadoAgendaModel) {
    return this.http
      .post<any>(environment.url + '/afiliados', datos, {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  modificarContacto(datos: DatosAfiliadoAgendaModel) {
    return this.http
      .put<any>(environment.url + '/afiliados', datos, {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  eliminarContacto(arrayIDs: Array<any>) {
    return this.http
      .put<any>(
        environment.url + '/afiliados/delete',
        { ids: arrayIDs },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }
}
