import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { PrescriptorRequestModel } from '@models/datosTransaccion.models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { setPrescriptorRequest } from '@store/actions';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { DialogsService } from '@services/dialogs.service';
import { InicioService } from '@services/inicio.service';
import { datosInicioState, datosTransaccionesState } from '@store/models/store.models';
import { GlobalService } from '@services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-matricula-modal',
  templateUrl: './save-matricula-modal.component.html',
  styleUrls: ['./save-matricula-modal.component.scss']
})
export class SaveMatriculaModalComponent implements OnInit {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();

  especialidadesPrescriptor: Array<any> = [];
  provinciasPrescriptor: Array<any> = [];
  tiposPrescriptor: Array<any> = [];
  codigoTransaccion: string | null = null;
  tipoPrestador: string = '';
  provincia?: string = '';
  datosInicio: datosInicioState | null = null;
  datosTransaccion: datosTransaccionesState | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<PrescriptorRequestModel> =
    this.formBuilder.group<PrescriptorRequestModel>({
      ApellidoPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      CuitPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      EspecialidadPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      FechaReceta: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NombrePrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroMatriculaPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
        Validators.pattern('[0-9]+')
      ]),
      ProvinciaPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoPrescriptor: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  constructor(private store: Store<AppState>,
    public activeModal: NgbActiveModal,
    private dialogsService: DialogsService,
    private inicioService: InicioService,
    private globalService: GlobalService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.getDatos();
    this.setDefaultValues();
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.tipoPrestador = datos.datosInicio.PrestadorRequest?.TipoPrestador.toUpperCase() || '';
      this.codigoTransaccion = datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.provinciasPrescriptor = datos.datosInicialesExtra.datosInicialesExtra?.provincia || [];
      this.tiposPrescriptor = datos.datosInicialesExtra.datosInicialesExtra?.tipoMatricula || [];

      this.datosInicio = datos.datosInicio;
      this.datosTransaccion = datos.datosTransaccion;
    });
  }

  setDefaultValues() {
    let tipo = this.tiposPrescriptor.find(
      (x) => x.nombre == 'Médico'
    );
    this.form.controls['TipoPrescriptor'].setValue(tipo?.codigo || null);

    this.subscriptionStore = this.store.subscribe((datos) => {
      this.provincia = datos.datosInicio.PrestadorRequest?.matriculas![0].provincia
    });
    this.form.controls['ProvinciaPrescriptor'].setValue(this.provincia || null);
  }

  async guardar() {
    try {
      let datos = this.form.getRawValue();
      let prescriptorRequest = new PrescriptorRequestModel(
        datos.ApellidoPrescriptor,
        datos.CuitPrescriptor,
        datos.EspecialidadPrescriptor,
        datos.FechaReceta,
        datos.NombrePrescriptor,
        datos.NroMatriculaPrescriptor,
        datos.ProvinciaPrescriptor,
        datos.TipoPrescriptor
      );
      this.store.dispatch(new setPrescriptorRequest(prescriptorRequest));
      this.store.dispatch(new ActivarLoading());
      this.dialogsService.cargando('Guardando matricula, por favor espere.');

      await this.saveMatricula();

      this.store.dispatch(new DesactivarLoading());
      this.dialogsService.successMatricula('', 'Matrícula guardada con éxito!');
      // this.router.navigate(['inicio']);
      // if (this.router.url === '/inicio') {
      //   window.location.reload();
      // }
    } catch (error) {
      this.dialogsService.cerrar();
      this.dialogsService.toastError(
        'Se produjo un error, vuelva a intentarlo más tarde'
      );
    }
  }

  async saveMatricula() {
    try {
      await this.inicioService
        .saveMatriculas(this.datosInicio!, this.datosTransaccion!)
        .toPromise();
    } catch (error) {

    }
  }




  devolverRespuesta(accion: string) {
    this.activeModal.close({ respuesta: accion });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }



}
