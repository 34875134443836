import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as actions from '@store/actions';

@Injectable()
export class instalacionEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  cargarUsuariosSuccess$ = this.actions$
    .pipe(ofType(actions.CARGAR_USUARIOINSTALACIONSUCCESS))
    .pipe(
      switchMap(() => {
        return of(new actions.DesactivarLoading());
      })
    );

  @Effect()
  busquedaRealizada$ = this.actions$
    .pipe(ofType(actions.BUSQUEDA_REALIZADA))
    .pipe(
      switchMap(() => {
        return of(new actions.SeleccionarLugarAtencion(null));
      })
    );
}
