import { Pipe, PipeTransform } from '@angular/core';
import { Financiador } from '@models/financiador.model';
import { UsuarioActualModel } from '@models/login.models';
import { Prestador, PrestadorSelecionado } from '@models/prestador.model';

@Pipe({
  name: 'filterPrestadoresByFinanciadorSelec',
})
export class FilterPrestadoresByFinanciadorSelecPipe implements PipeTransform {
  transform(
    prestadores: Array<Prestador>,
    cuitFinanciador: string | null,
    usuarioActual: UsuarioActualModel | null,
    prestadoresSeleccionados: Array<PrestadorSelecionado> //Prestadores que se quieren ver o no dependiendo del rol del usuario
  ): Array<Prestador> {

    if (!prestadores || !usuarioActual) {
      return [];
    }

    if (usuarioActual.role) {
      if (usuarioActual.role == 'Recepcion') {
        //Los prestadores seleccionados son los que no quiere ver
        prestadores = prestadores.filter(
          (x) => prestadoresSeleccionados.filter((y) => y.cuitPrestador == x.cuit).length == 0
        );
      }
      if (usuarioActual.role == 'Prestador') {
        //Los prestadores seleccionados son los que quiere ver
        prestadores = prestadores.filter(
          (x) => prestadoresSeleccionados.filter((y) => y.cuitPrestador == x.cuit).length > 0
        );
      }
      let prestadoreADevolver: Array<Prestador> = [];
      if (!cuitFinanciador) {
        prestadores.forEach((prestador) => {
          if (prestador.financiadores) {
            prestador.financiadores.forEach((financiador) => {
              let pres = { ...prestador, financiadores: [financiador] };
              prestadoreADevolver.push(pres);
            });
          }
        });
        return prestadoreADevolver;
      }

      prestadores.forEach((prestador) => {
        if (prestador.financiadores) {
          let financiadorPrestador = prestador.financiadores.find(
            (x) => x.cuit_financiador == cuitFinanciador
          );
          if (financiadorPrestador) {
            let pres = { ...prestador, financiadores: [financiadorPrestador] };
            prestadoreADevolver.push(pres);
          }
        }
      });
      return prestadoreADevolver;
    }

    return [];
  }
}
