import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { codigoNombreModel } from '@models/datosInicialesExtras.models';
import { redirectClass } from '@models/global.models';
import {
  DataUsuario,
  datosPerfilModel,
  DatosUsuarioModificado,
  modificarModel,
  respuestaOrdenTransaccionPrestador,
} from '@models/operaciones.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { DesactivarLoading, removeDatosUsuario } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { ConfiguracionState } from '@store/models/store.models';
import { errorGenerico } from 'errores';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogsService } from './dialogs.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class OperacionesPerfilService {
  private subscriptionStore: Subscription = new Subscription();
  headers: HttpHeaders = new HttpHeaders({});
  codigoInstalacion: string | null = null;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private dialogsService: DialogsService,
    private globalService: GlobalService
  ) {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState: ConfiguracionState) => {
        if (configuracionState.usuarioActual) {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
          this.codigoInstalacion =
            configuracionState.usuarioActual.codigoInstalacion;
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  getDatosPerfil(): Observable<datosPerfilModel> {
    return this.http
      .get<datosPerfilModel>(environment.url + '/usuarios/by_token', {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  //Acciones para varios metodos
  getDatosTodosUsuarios(): Observable<Array<DataUsuario>> {
    return this.http
      .get<Array<DataUsuario>>(
        environment.url + '/usuarios/instalacion/by_token',
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  //Acciones para usuarios pendientes
  getDatosUsuariosPendientes(): Observable<Array<DataUsuario>> {
    return this.http
      .get<Array<DataUsuario>>(environment.url + '/usuarios/pendientes', {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  aceptarUsuariosPendientes(usuariosAAceptar: any): Observable<any> {
    return this.http
      .put(
        environment.url + '/usuarios/pendientes/aceptar',
        { usuarios: usuariosAAceptar },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  rechazarUsuariosPendientes(usuariosAAceptar: any): Observable<any> {
    return this.http
      .put(
        environment.url + '/usuarios/pendientes/rechazar',
        { usuarios: usuariosAAceptar },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  //Acciones para usuarios pendientes
  getDatosUsuariosInactivos(): Observable<Array<DataUsuario>> {
    return this.http
      .get<Array<DataUsuario>>(
        environment.url + '/usuarios/inactivos/by_token',
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  activarUsuarios(usuariosAActivar: any): Observable<any> {
    return this.http
      .put(
        environment.url + '/usuarios/set-up',
        { usuarios: usuariosAActivar },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  //Acciones para eliminar usuarios
  eliminarUsuarios(usuariosAEliminar: any): Observable<any> {
    return this.http
      .put(
        environment.url + '/usuarios/delete',
        { usuarios: usuariosAEliminar },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  //Acciones para aceptar cambios usuarios
  getDatosUsuariosModificados(): Observable<Array<DatosUsuarioModificado>> {
    return this.http
      .get<Array<DatosUsuarioModificado>>(
        environment.url + '/usuarios/modificados/by_token',
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  aceptarCambiosUsuarios(id: number): Observable<any> {
    return this.http
      .put(environment.url + '/usuarios/modificado/aceptar/' + id, null, {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  rechazarCambiosUsuarios(id: number): Observable<any> {
    return this.http
      .put(environment.url + '/usuarios/modificado/rechazar/' + id, null, {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  //Acciones para modificar un usuario
  getDatosUsuarioModificados(): Observable<DatosUsuarioModificado> {
    return this.http
      .get<DatosUsuarioModificado>(
        environment.url + '/usuarios/modificado/by_token',
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  getPrestadoresDeLugarAtencion(
    codigoInstalacion: string
  ): Observable<Array<Prestador>> {
    return this.http
      .get<Array<Prestador>>(
        environment.url +
          `/config/prestadores/by_instalacion/${codigoInstalacion}`
      )
      .pipe(take(1));
  }

  modificarDatos(datosModificados: modificarModel): Observable<any> {
    return this.http
      .post(
        environment.url + '/usuarios/modificar/by_token',
        datosModificados,
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  //Acciones para que el administrador le cambie los roles a los usuarios
  cambiarRolUsuario(
    email: string,
    rol: string,
    cuitSeleccionado: string
  ): Observable<any> {
    return this.http
      .put(
        environment.url + '/usuarios/modificar/rol',
        {
          email: email,
          rol: rol,
          cuitPrestador: cuitSeleccionado,
        },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  getDatosAdministradores(): Observable<Array<DataUsuario>> {
    return this.http
      .get<Array<DataUsuario>>(
        environment.url + '/usuarios/administrador/by_token',
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  getFinanciadoresPrestador(
    cuitPrestador: any
  ): Observable<Array<DataUsuario>> {
    return this.http
      .get<Array<DataUsuario>>(
        environment.url + `config/prestadores/${cuitPrestador}/financiadores`,
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  darBajaUsuario(): Observable<any> {
    return this.http
      .put(environment.url + '/usuarios/delete/by_token', null, {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  cambiarEmailUsuario(email: string) {
    return this.http
      .put(
        environment.url + '/usuarios/change-mail',
        { newEmail: email },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  cambiarPassword(datos: any) {
    return this.http
      .put(environment.url + '/usuarios/password', datos, {
        headers: this.headers,
      })
      .pipe(take(1));
  }

  getOrdenTransacciones(): Observable<respuestaOrdenTransaccionPrestador> {
    return this.http
      .get<respuestaOrdenTransaccionPrestador>(
        environment.url + `/transaccion`,
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  cambiarOrdenTransacciones(datos: Array<codigoNombreModel>) {
    return this.http
      .post(
        environment.url + '/transaccion',
        { tipoTransacciones: datos },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  //Accion setear prestadores no deseados
  setearPrestadoresNoDeseados(
    cuitPrestadoresNoDeseados: modificarModel
  ): Observable<any> {
    return this.http
      .post(
        environment.url + '/usuarios/prestadores-no-deseados',
        { prestadoresNoDeseados: cuitPrestadoresNoDeseados },
        {
          headers: this.headers,
        }
      )
      .pipe(take(1));
  }

  getPrestadoresNoDeseados(): Observable<any> {
    return this.http
      .get(environment.url + '/usuarios/prestadores-no-deseados', {
        headers: this.headers,
      })
      .pipe(take(1));
  }
}
