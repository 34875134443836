import { instalacionEffects } from './instalacion.effect';
import { configuracionEffects } from './configuracion.effects';
import { datosInicioEffects } from './datosInicio.effects';
export const effectsArr: Array<any> = [
  instalacionEffects,
  configuracionEffects,
  datosInicioEffects,
];

export * from './instalacion.effect';
export * from './configuracion.effects';
export * from './datosInicio.effects';
