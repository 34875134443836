<form [formGroup]="form">

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Intrucciones prescripción <span class="text-danger"
          *ngIf="form.get('Intrucciones') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="Intrucciones" placeholder="Intrucciones prescripción">
    </div>

    <div class="form-group col-12 col-md-6">
      <label>Número prescripción <span class="text-danger" *ngIf="form.get('Numero') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="Numero" placeholder="Número prescripción">
    </div>

  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Indicación que el paciente solicita atención o entrega a domicilio <span class="text-danger"
          *ngIf="form.get('SolicitaAtencionDomiliciaria') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="SolicitaAtencionDomiliciaria"
        placeholder="Solicitud atención domiciliaria">
    </div>
  </div>
</form>
