<div class="modal-header">
    <h4>Ingrese su contraseña actual y la nueva, tenga en cuenta que la contraseña se cambiara para todos los lugares donde trabaje.</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group">
            <div class="form-row">
                <label for="oldPassword">Contraseña anterior</label>
                <input type="password" placeholder="Contraseña anterior" autocomplete="off" class="form-control" formControlName="oldPassword" id="oldPassword">
                <small *ngIf="submitted && form.controls['oldPassword'].errors" class="text-danger animated fadeIn form-text ">La
                    contraseña debe tener como mínimo 6 caracteres.</small>
            </div>
            <div class="form-row mt-3">
                <label for="newPassword">Contraseña nueva</label>
                <input type="password" placeholder="Contraseña nueva" autocomplete="off" class="form-control" formControlName="newPassword" id="newPassword">
                <small *ngIf="submitted && form.controls['newPassword'].errors" class="text-danger animated fadeIn form-text ">La contraseña debe tener como mínimo 6
                    caracteres.</small>
            </div>
            <div class="form-row mt-3">
                <label for="confirmNewPassword">Repetir contraseña nueva</label>
                <input type="password" placeholder="Confirmar contraseña nueva" autocomplete="off" class="form-control" formControlName="confirmNewPassword" id="confirmNewPassword">
                <small *ngIf="submitted && form.hasError('mismatchedPasswords')" class="text-danger animated fadeIn">Las
                    contraseñas no coinciden</small>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button (click)="devolverRespuesta(false)" type="submit" class="btn btn-danger mr-2">Cancelar</button>
    <button (click)="devolverRespuesta(true)" type="submit" class="btn btn-pcpos" ngbAutofocus>Confirmar</button>
</div>