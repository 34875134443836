import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormGroup } from '@angular/forms';
import { unAfiliadoAgenda } from '@models/agenda.models';
import { CredencialRequestModel } from '@models/datosInicio.models';
import { Financiador } from '@models/financiador.model';
import { AfiliadoFormModel, redirectClass } from '@models/global.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setCredencialRequest,
  setEstadoFormCredencialRequest,
  unsetCredencialRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { FinanciadorRequestModel } from '../../../models/datosInicio.models';
import { GlobalService } from '@services/global.service';
import { AuthService } from '@services/auth.service';
@Component({
  selector: 'app-afiliado',
  templateUrl: './afiliado.component.html',
  styleUrls: ['./afiliado.component.scss'],
})
export class AfiliadoComponent implements OnInit, OnDestroy {
  // INICIO MASK
  mascarasValidas: Array<any> = [
    'OSDE',
    'SWISS',
    'OMINT',
    'SERVESALUD',
    'HOPE',
    'ACA Salud',
    'PREMED',
  ];
  osdeMask = {
    mask: '00 | 000000 | 0 | 00',
    lazy: false,
    unmask: 'typed',
  };
  swissMask = {
    mask: '{8\\0\\0\\0\\06} 0000000 00 0000',
    lazy: false,
    unmask: 'typed',
  };
  omintMask = {
    mask: '0 0000000 00 00 0',
    lazy: false,
    unmask: 'typed',
  };
  servesaludMask = {
    mask: '00000000',
    lazy: false,
    unmask: 'typed',
  };
  hopeMask = {
    mask: '0-00000000',
    lazy: false,
    unmask: 'typed',
  };
  acaMask = {
    mask: '00000000',
    lazy: false,
    unmask: 'typed',
  };
  premedicMask = {
    mask: '0000000000000',
    lazy: false,
    unmask: 'typed',
  };
  // FIN MASK

  private subscriptionDatosInicio: Subscription = new Subscription();
  private subscriptionFormStatus: Subscription = new Subscription();
  financiadorSeleccionado: FinanciadorRequestModel | null = null;
  financiadores: Array<Financiador> = [];

  mostrarAgenda: boolean = false;
  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<AfiliadoFormModel> =
    this.formBuilder.group<AfiliadoFormModel>({
      numeroAfiliado: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        blankSpaceValidator,
      ]),
    });
  constructor(private store: Store<AppState>,
              private globalService: GlobalService,
              private authService: AuthService) {}

  ngOnInit(): void {

    this.store.dispatch(new setEstadoFormCredencialRequest(false));
    this.listenNumeroAfiliado();
    this.subscriptionDatosInicio = this.store
    .select('datosInicio')
    .subscribe((datos) => {
      if (
        datos.FinanciadorRequest?.SiglaFinanciador !=
        this.financiadorSeleccionado?.SiglaFinanciador
        ) {
          this.financiadorSeleccionado = datos.FinanciadorRequest;
        }
      if (datos.CredencialRequest == null) {
        this.form.controls['numeroAfiliado'].markAsUntouched();
      }
      if (
        this.form.controls['numeroAfiliado'].value !=
        datos.CredencialRequest?.NumeroCredencial
        ) {
          console.log('setear nro');

          this.form.controls['numeroAfiliado'].setValue(
            datos.CredencialRequest?.NumeroCredencial || null
            );
          }
          });

  }

  ngOnDestroy() {
    this.subscriptionFormStatus.unsubscribe();
    this.subscriptionDatosInicio.unsubscribe();
  }

  listenNumeroAfiliado() {

    this.subscriptionFormStatus = this.form.controls[
      'numeroAfiliado'
    ].statusChanges.subscribe((status) => {
      this.form.controls['numeroAfiliado'].markAsTouched();
      this.authService.esTokenValido();
      if (this.form.controls['numeroAfiliado'].status == 'VALID') {
        this.store.dispatch(new setEstadoFormCredencialRequest(true));
      } else {
        this.store.dispatch(new setEstadoFormCredencialRequest(false));

      }

      if (this.form.controls['numeroAfiliado'].value) {
        let credencialModel = new CredencialRequestModel(
          'M',
          '',
          this.form.controls['numeroAfiliado'].value,
          '',
          '',
          ''
        );
        this.store.dispatch(new setCredencialRequest(credencialModel));
        this.store.dispatch(
          new setEstadoFormCredencialRequest(this.form.controls['numeroAfiliado'].valid)

        );

      } else {
        this.store.dispatch(new unsetCredencialRequest());
        this.store.dispatch(new setEstadoFormCredencialRequest(false));
      }
    });

  }

  contacto() {
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }
}
