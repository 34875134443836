import { lugarAtencion } from './lugarAtencion.model';

export class instalacionFormModel {
  public cuit: string;

  constructor(cuit: string) {
    this.cuit = cuit;
  }
}

export class AfiliadoFormModel {
  public numeroAfiliado: string;

  constructor(numeroAfiliado: string) {
    this.numeroAfiliado = numeroAfiliado;
  }
}

export class searchReturn {
  direccion: string;
  coordenadas: google.maps.LatLng;

  constructor(direccion: string, coordenadas: google.maps.LatLng) {
    this.coordenadas = coordenadas;
    this.direccion = direccion;
  }
}

export class showAlert {
  public showAlert: boolean;
  public mensajeAlert: string;

  constructor(showAlert: boolean, mensajeAlert: string) {
    this.mensajeAlert = mensajeAlert;
    this.showAlert = showAlert;
  }
}

export class redirectClass {
  public direccion: string;
  public mensajeError: string;
  public error: any;
  public showAlert: showAlert;

  constructor(
    direccion: string,
    mensajeError: string = '',
    error: any = '',
    showAlerta: showAlert = new showAlert(false, '')
  ) {
    this.direccion = direccion;
    this.mensajeError = mensajeError;
    this.error = error;
    this.showAlert = showAlerta;
  }
}

export class filteredMarkers {
  public marker: google.maps.Marker;
  public lugarAtencion: lugarAtencion;

  constructor(marker: google.maps.Marker, lugarAtencionn: lugarAtencion) {
    this.marker = marker;
    this.lugarAtencion = lugarAtencionn;
  }
}

export type tipoInstalacion = 'NUVAFARM' | 'NUVAMED';

export type tiposParentescosNombre =
  | 'Titular'
  | 'Cónyugue'
  | 'Hijo/a'
  | 'Madre'
  | 'Padre'
  | 'Dependiente';

export type tiposParentescosSigla = 'T' | 'C' | 'H' | 'M' | 'P' | 'D';
export class tiposParentescos {
  nombre: tiposParentescosNombre;
  sigla: tiposParentescosSigla;

  constructor(nombre: tiposParentescosNombre, sigla: tiposParentescosSigla) {
    this.nombre = nombre;
    this.sigla = sigla;
  }
}

export class codigoInstalacion {
  public codigoInstalacion: string;
  public domicilio: string;
  public tipoInstalacion: tipoInstalacion;

  constructor(
    codigoInstalacion: string,
    domicilio: string,
    tipoInstalacion: tipoInstalacion
  ) {
    this.codigoInstalacion = codigoInstalacion;
    this.domicilio = domicilio;
    this.tipoInstalacion = tipoInstalacion;
  }
}

export class usuarioEmail {
  public email: string;
  constructor(
    email: string,
  ) {
    this.email = email;
  }
}

export class tokenModel {
  email: string;
  token: string;
  codigoInstalacion: string;

  constructor(email: string, token: string, codigoInstalacion: string) {
    this.email = email;
    this.token = token;
    this.codigoInstalacion = codigoInstalacion;
  }
}

export class prestacionCodigoDescModel {
  codigo: string;
  descripcion?: string;

  constructor(codigo: string, descripcion?: string) {
    this.codigo = codigo;
    this.descripcion = descripcion || undefined;
  }
}

export class diagnosticoCodigoDescModel {
  codigo: string;
  descripcion: string;
  nomenclador: string;

  constructor(codigo: string, descripcion: string, nomenclador: string) {
    this.codigo = codigo;
    this.descripcion = descripcion;
    this.nomenclador = nomenclador;
  }
}
