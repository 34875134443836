import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'app-work-in-progress-modal',
  templateUrl: './work-in-progress-modal.component.html',
  styleUrls: ['./work-in-progress-modal.component.scss']
})
export class WorkInProgressModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private location: Location) { }

  ngOnInit(): void {
  }

  devolverRespuesta(accion: string) {
    this.activeModal.close({ respuesta: accion });
  }

}
