<div class="modal-header ">
    <h4 class="modal-title w-100 text-center">{{nombreTransaccion}}</h4>
</div>
<div class="modal-body">
    <ng-container *ngIf="datosTicket">
        <div class="row">
            <div class="col" style="display: flex; justify-content: center;">
                <img style="width: 110; height: 50px;" src="./assets/img/{{datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador}}_LOGO.png" title="{{datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador}}" alt="{{datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador}}">
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12 text-center" *ngIf="datosTicket.EncabezadoMensaje.Rta.CodRtaGeneral == '000'">
                APROBADA
            </div>
            <div class="col-12 text-center" *ngIf="datosTicket.EncabezadoMensaje.Rta.CodRtaGeneral != '000'">
                SOLICITUD RECHAZADA
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <ng-container *ngIf="datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador != 'OSDE' && datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador != 'GALENO'; else codigoGaleno">
                    <strong>Nro. Transacción: </strong>{{ datosTicket.EncabezadoMensaje.Financiador.SystemTrace }}
                </ng-container>
                <ng-template #codigoGaleno>
                    <ng-container *ngIf="datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador === 'GALENO'; else otroCodigo">
                        <strong>Nro. Transacción: </strong>{{ datosTicket.EncabezadoMensaje.Financiador.SystemTrace }}
                    </ng-container>
                </ng-template>
                <ng-template #otroCodigo>
                    <strong>Nro. Transacción: </strong>{{ datosTicket.EncabezadoMensaje.NroReferencia | slice:-6 }}
                </ng-template>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <strong>Prestador: </strong>{{ datosTicket.EncabezadoMensaje.Prestador.RazonSocial }}
                <br>
                <strong>Cuit: </strong>{{ datosTicket.EncabezadoMensaje.Prestador.CuitPrestador }}
                <br>
                <ng-container *ngIf="datosTicket.EncabezadoMensaje.Terminal && datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador === 'OSDE'">
                  <strong>Nro. Terminal: </strong>{{ datosTicket.EncabezadoMensaje.Terminal.NumeroTerminal }}
                </ng-container>
            </div>
        </div>
        <hr>
        <!-- Listado de prestaciones -->
        <ng-container *ngIf="datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador != 'OSDE' && datosTicket.DetalleProcedimientos">
            <div class="row">
                <div class="col-12" *ngFor="let detalleProcedimiento of detallesProcedimientos;">
                    <strong>Codigo de Prestación: </strong>{{ detalleProcedimiento.CodPrestacion }}
                    <br>
                    <strong>Detalle: </strong>{{ detalleProcedimiento.DescripcionPrestacion }}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="datosTicket.EncabezadoMensaje.TipoTransaccion != '03P' && datosTicket.EncabezadoMensaje.TipoTransaccion != '04A'">
            <div class="row">
                <div class="col-12">
                    <ng-container *ngIf="datosTicket.EncabezadoAtencion.Credencial.NumeroCredencial">
                        <strong>Afiliado: </strong>{{ datosTicket.EncabezadoAtencion.Credencial.NumeroCredencial }}
                        <ng-container *ngIf="datosTicket.EncabezadoAtencion.Credencial.NumeroCredencial != obras && datosTicket.EncabezadoMensaje.Rta.CodRtaGeneral === '000'">
                            <a routerLink="/agenda/CrearContacto" (click)="devolverRespuesta('CERRAR')" class="agenda">
                                Agendar <fa-icon [icon]='["fas", "plus"]' class="ml-1"></fa-icon></a>
                        </ng-container>
                        <br>
                    </ng-container>
                    <ng-container *ngIf=" datosTicket.EncabezadoAtencion.Beneficiario ">
                        <ng-container *ngIf="datosTicket.EncabezadoAtencion.Beneficiario.NombreBeneficiario">
                            <strong>Nombre: </strong>{{ datosTicket.EncabezadoAtencion.Beneficiario.NombreBeneficiario }}
                            <br>
                        </ng-container>
                        <ng-container *ngIf="datosTicket.EncabezadoAtencion.Beneficiario.ApellidoBeneficiario">
                            <strong>Apellido: </strong>{{ datosTicket.EncabezadoAtencion.Beneficiario.ApellidoBeneficiario }}
                            <br>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="datosTicket.EncabezadoAtencion.Credencial.PlanCredencial">
                        <strong>Plan: </strong>{{ datosTicket.EncabezadoAtencion.Credencial.PlanCredencial }}
                        <br>
                    </ng-container>
                    <ng-container *ngIf="datosTicket.EncabezadoAtencion.Credencial.CondicionIVA">
                        <strong>Cond. IVA: </strong>{{ datosTicket.EncabezadoAtencion.Credencial.CondicionIVA }}
                    </ng-container>
                </div>
            </div>
            <hr>
        </ng-container>

        <!-- START PRESCRIPCIONES -->
        <ng-container *ngIf="datosTicket.EncabezadoMensaje.TipoTransaccion != '01A'">
            <div class="row">
                <div class="col">
                    <strong>Prestacion</strong>
                </div>
                <div class="col">
                    <strong>Tipo</strong>
                </div>
                <div class="col">
                    <strong>Arancel</strong>
                </div>
                <div class="col">
                    <strong>Cantidad</strong>
                </div>
                <div class="col">
                    <strong>Observación</strong>
                </div>
            </div>
            <div class="procedimientoDesc">
                <div class="row" *ngFor="let procedimientos of datosTicket.DetalleProcedimientos">
                    <div class="col procedimientosTicket">
                        {{ procedimientos.CodPrestacion }}
                    </div>
                    <div class="col procedimientosTicket">
                        {{ procedimientos.TipoPrestacion }}
                    </div>
                    <div class="col procedimientosTicket">
                        {{ procedimientos.ArancelPrestacion }}
                    </div>
                    <div class="col procedimientosTicket">
                        {{ procedimientos.CantidadSolicitada }}
                    </div>
                    <ng-container *ngIf="procedimientos.CodRta != '000'; else sinError">
                        <div class="col procedimientosTicket">
                            Error
                        </div>
                    </ng-container>
                    <ng-template #sinError>
                        <div class="col procedimientosTicket">
                        </div>
                    </ng-template>
                    <div class="row" style="margin-left: 10px; margin-top: 1em;">
                        {{ procedimientos.DescripcionPrestacion }}
                    </div>
                    <div class="row" style="margin-left: 10px;" *ngIf="procedimientos.ImporteACargoAfiliado && procedimientos.ImporteACargoAfiliado != '0 00' && procedimientos.ImporteACargoAfiliado != '0'">
                        Copago: ${{ procedimientos.ImporteACargoAfiliado | replace : ' ' : '.' }}
                    </div>
                </div>
            </div>
            <hr>
        </ng-container>
        <!-- END PRESCRIPCIONES -->

        <!-- Coseguro -->
        <ng-container *ngIf="datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador != 'OSDE' && datosTicket.EncabezadoAtencion.RtaAdicional && datosTicket.EncabezadoMensaje.TipoTransaccion != '04A'
            && datosTicket.EncabezadoAtencion.RtaAdicional.Coseguro && datosTicket.EncabezadoAtencion.RtaAdicional.Coseguro != '0'">
            <div class="row">
                <div class="col-12">
                    <strong>Copago: </strong>${{ datosTicket.EncabezadoAtencion.RtaAdicional.Coseguro }}
                </div>
            </div>
            <hr>
        </ng-container>
        <!-- Fecha y Hora -->
        <div class="row">
            <div class="col-12">
              <ng-container *ngIf="datosTicket.EncabezadoMensaje.TipoTransaccion === '02D'
              || datosTicket.EncabezadoMensaje.TipoTransaccion === '02E' || datosTicket.EncabezadoMensaje.TipoTransaccion === '02I'
              || datosTicket.EncabezadoMensaje.TipoTransaccion === '02Q'; else fechaHoy">
                <strong>Fecha Atención: </strong>{{ dateA | date: 'dd/MM/yyyy' }}
              </ng-container>
              <ng-template #fechaHoy>
                <strong>Fecha Atención: </strong>{{ date | date: 'dd/MM/yyyy' }}
              </ng-template>
                <br>
                <strong>Hora Atención: </strong>{{ time | date:'HH:mm':'-0300' }}
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                {{datosTicket.EncabezadoMensaje.Rta.MensajeDisplay}}
            </div>
        </div>
        <!-- Descripción del error -->
        <hr>
        <div class="row">
            <div class="col-12" *ngIf="datosTicket.EncabezadoMensaje.Financiador.SiglaFinanciador != 'OSDE' && datosTicket.EncabezadoAtencion.RtaAdicional && datosTicket.EncabezadoAtencion.RtaAdicional.MensajeAdicional">
                {{ datosTicket.EncabezadoAtencion.RtaAdicional.MensajeAdicional }}
            </div>
        </div>
    </ng-container>

</div>

<div class="modal-footer">

    <ng-container *ngIf="nombreTransaccion!='ANULAR TRANSACCIÓN' && nombreTransaccion!='Anulación' && nombreTransaccion!='Verificar afiliado' && nombreTransaccion!= 'Informar diagnóstico'">
        <button (click)="devolverRespuesta('ANULAR')" class="btn btn-danger" style="background-color: #f84343;">Anular</button>
    </ng-container>

    <button (click)="devolverRespuesta('IMPRIMIR')" class="btn btn-pcpos" style="background-color: #3662ff;">Imprimir</button>
    <button (click)="devolverRespuesta('CERRAR')" type="submit" class="btn btn-pcpos" style="background-color: #3662ff;">Cerrar</button>
</div>
