import { Component, OnInit } from '@angular/core';
import { redirectClass } from '../../models/global.models';
import { Prestador } from '../../models/prestador.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-confirmar-prestadores-modal',
  templateUrl: './confirmar-prestadores-modal.component.html',
  styleUrls: ['./confirmar-prestadores-modal.component.scss'],
})
export class ConfirmarPrestadoresModalComponent implements OnInit {
  prestadores: Array<Prestador> = [];

  constructor(public activeModal: NgbActiveModal,
              private globalService: GlobalService) { }

  ngOnInit(): void { 
    this.prestadores.sort((a, b) => a.razon_social > b.razon_social ? 1 : b.razon_social > a.razon_social ? -1 : 0);
  }

  devolverRespuesta(rta: string) {
    this.activeModal.close(rta);
  }
  contacto() {
    // this.router.navigate(['contactanos'])
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }
}


