<ng-template #diagnostico let-r="result" let-t="term">
    <ngb-highlight [result]="r.descripcion" [term]="t"></ngb-highlight>
</ng-template>



<!-- Revisar esta clase entera -->
<!-- <div class="input-group mb-1">
    <input id="typeahead" type="text" class="form-control" [ngbTypeahead]="search" placeholder="Código/Nombre diagnostico" [resultTemplate]="diagnostico" [inputFormatter]="formatter" [(ngModel)]="model" (ngModelChange)="submit(null)" (ngModelChange)="submit(null)"
    />
    <div class="input-group-append">
        <span class="input-group-text">
      <fa-icon [icon]='["fas", "search"]' class="text-secondary"></fa-icon>
    </span>
    </div>
</div> -->

<div class="form-infoN">
  <label for="codigoDiagnostico">Diagnostico <span
      class="span-required">*</span></label>


  <input id="typeahead" type="text" style="width:30vh" class="form-controlN" [ngbTypeahead]="search" placeholder="Código/Nombre diagnostico" [resultTemplate]="diagnostico" [inputFormatter]="formatter" [(ngModel)]="model" (ngModelChange)="submit(null)" (ngModelChange)="submit(null)"
  />
</div>
