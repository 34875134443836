import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataUsuario } from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';

@Component({
  selector: 'app-aceptar-usuario',
  templateUrl: './aceptar-usuario.component.html',
})
export class AceptarUsuarioComponent implements OnInit {
  titulo: string = 'Ver usuarios pendientes de aceptación ';
  usuariosAAceptar: Array<DataUsuario> | null = null;

  constructor(
    private operacionesPerfilService: OperacionesPerfilService,
    private store: Store<AppState>,
    private globalService: GlobalService,
    private dialogsService: DialogsService
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(seAceptoUsuario = false) {
    //Se acepto usuario sirve para que no tire error si se acepto todos los usuarios
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService.getDatosUsuariosPendientes().subscribe(
      (respuesta) => {
        this.usuariosAAceptar = respuesta;
        this.dialogsService.cerrar();
        this.store.dispatch(new DesactivarLoading());
      },
      (error: HttpErrorResponse) => {
        if (seAceptoUsuario) {
          this.usuariosAAceptar = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
        } else {
          this.usuariosAAceptar = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      }
    );
  }

  aceptarUsuarios(arrayAceptarUsuarios: any) {
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService
      .aceptarUsuariosPendientes(arrayAceptarUsuarios)
      .subscribe(
        (respuesta) => {
          this.dialogsService.toastSuccess(respuesta.message);
          this.refresh(true);
        },
        (error: HttpErrorResponse) => {
          this.usuariosAAceptar = null;
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }

  rechazarUsuarios(arrayRechazarUsuarios: any) {
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService
      .rechazarUsuariosPendientes(arrayRechazarUsuarios)
      .subscribe(
        (respuesta) => {
          this.dialogsService.toastSuccess(respuesta.message);
          this.refresh(true);
        },
        (error: HttpErrorResponse) => {
          this.usuariosAAceptar = null;
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }
}
