<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>

<div class="container__panelPrestacion">
  <p class="title__acompannante">Acompañante</p>
  <form [formGroup]="form" class="form__acompannante">

    <div>
      <div class="row">
        <!-- NOMBRE DEL ACOMPAÑANTE -->
        <div class="col form-infoN">
          <label>Nombre <span class="text-danger"
              *ngIf="form.get('NombreAcompannante') | controlRequired">*</span></label>
          <input style="width: 100%;" id="finTratamietno" type="text" class="form-controlN"
            formControlName="NombreAcompannante" placeholder="Nombre del acompañante">
        </div>

        <!-- APELLIDO DEL ACOMPAÑANTE -->
        <div class="col form-infoN">
          <label>Apellido <span class="text-danger" *ngIf="form.get('ApellidoAcompannante') | controlRequired">*</span>
          </label>
          <input style="width: 100%;" id="finTratamietno" type="text" class="form-controlN"
            formControlName="ApellidoAcompannante" placeholder="Apellido del acompañante">
        </div>
      </div>

      <div class="row" style="margin-top: 15px;">
        <!-- TIPO DOCUMENTO ACOMPAÑANTE -->
        <div class="col form-infoN">
          <label>Tipo documento <span class="text-danger"
              *ngIf="form.get('TipoDocAcompannante') | controlRequired">*</span></label>

          <select style="width: 100%;" id="finTratamietno" class="form-controlN" formControlName="TipoDocAcompannante">
            <option [ngValue]="null">
              Seleccionar tipo de documento
            </option>
            <option [ngValue]="tipoDocumentos.codigo" *ngFor="let tipoDocumentos of tiposDocumentos">
              {{tipoDocumentos.nombre}}
            </option>
          </select>
        </div>

        <!-- NúMERO DE DOCUMENTO DEL ACOMPAÑANTE -->
        <div class="col form-infoN">
          <label>N° de documento <span class="text-danger"
              *ngIf="form.get('NroDocAcompannante') | controlRequired">*</span></label>
          <input style="width: 100%;" id="finTratamietno" type="text" maxlength="8" class="form-controlN"
            formControlName="NroDocAcompannante" placeholder="Número de documento">
        </div>

        <!-- NÚMERO DE TELFONO DEL ACOMPAÑANTE -->
        <div class="col form-infoN">
          <label>N° de teléfono <span class="text-danger"
              *ngIf="form.get('NroTelefono') | controlRequired">*</span></label>
          <input style="width: 100%;" id="finTratamietno" type="text" class="form-controlN"
            formControlName="NroTelefono" placeholder="Número de teléfono">
        </div>
      </div>
    </div>

  </form>
</div>


</html>






<!-- <!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title> -->
<!-- <link rel="stylesheet" href="css/style.css"> -->
<!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
<!-- </head>
<div class="container__panelPrestacion">
  <div class="container-diagnostico">
    <p class="title__diagnostico">Diagnóstico</p>
    <form [formGroup]="form" class="form__diagnostico">

      <ng-container formArrayName="diagnosticos" *ngFor="let diagnostico of getDiagnosticos(); let i = index">
        <ng-container [formGroupName]="i">

          <div class="form-infoN">
            <label for="codigoDiagnostico">Código<span *ngIf="diagnostico.get('CodDiagnostico') | controlRequired"
                class="span-required">*</span></label>


            <input id="codigoDiagnostico" maxlength="10" autocomplete="off" autocorrect="off" spellcheck="false"
              autocomplete="off" type="text" class="form-controlN" formControlName="CodDiagnostico">
          </div>



          <div class="form-infoN" id="descripcionContainer">
            <label for="descripcionDiagnostico">Descripción<span
                *ngIf="diagnostico.get('DescripcionDiagnostico') | controlRequired"
                class="span-required">*</span></label>


            <input maxlength="50" id="descripcionDiagnostico" autocomplete="off" autocorrect="off" spellcheck="false"
              autocomplete="off" type="text" class="form-controlN" formControlName="DescripcionDiagnostico">
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>

</html> -->
