import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-changes-modal',
  templateUrl: './new-changes-modal.component.html',
  styleUrls: ['./new-changes-modal.component.scss']
})
export class NewChangesModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  devolverRespuesta(accion: string) {
    this.activeModal.close({ respuesta: accion });
  }

  noVolverAMostrar() {
    localStorage.setItem('noMostrarModal', 'true');
    this.activeModal.close({ respuesta: 'NO_MOSTRAR' });
  }

}
