import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { codigoInstalacion } from '@models/global.models';
import { UsuarioActualModel } from '@models/login.models';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { GlobalService } from '@services/global.service';
import { setNuevoToken } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { InstalacionUsuario } from '@store/models/store.models';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthPagesGuard implements CanActivate, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  codigosInstalacion: Array<codigoInstalacion> | null = null;
  usuarioInstalacion: InstalacionUsuario | null = null;
  usuarioActual: UsuarioActualModel | null = null;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private globalService: GlobalService
  ) {
    this.subscriptionStore = this.store.subscribe((appState: AppState) => {
      this.codigosInstalacion = appState.configuracion.codigosInstalacion;
      this.usuarioInstalacion = appState.instalacion.usuarioInstalacion;
      this.usuarioActual = appState.configuracion.usuarioActual;
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.usuarioActual) {
      //Si habian datos de un usuario que se habia logeado anteriormente
      return this.authService.esTokenValido().pipe(
        map((respuesta) => {
          if (respuesta.token) {
            this.store.dispatch(new setNuevoToken(respuesta.token));
            return true;
          } else {
            return true;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.globalService.manageError(err);
          return of(false);
        })
      );
    } else {
      this.redirect();
      return of(false);
    }
  }

  redirect() {
    if (
      this.codigosInstalacion == null ||
      this.codigosInstalacion.length == 0
    ) {
      this.router.navigate(['instalacion']);
    } else {
      this.router.navigate(['login']);
    }
  }
}
