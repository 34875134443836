import { Action } from '@ngrx/store';

export const ACTIVAR_LOADING = '[ui] Activar loading';
export const DESACTIVAR_LOADING = '[ui] Desactivar loading';

export const SETINDEXDETALLEPROCEDIMINETOAEDITAR =
  '[ui] SETINDEXDETALLEPROCEDIMINETOAEDITAR';
export const UNSETINDEXDETALLEPROCEDIMINETOAEDITAR =
  '[ui] UNSETINDEXDETALLEPROCEDIMINETOAEDITAR';

export const SET_COORDENADAS = '[ui] Set coordenadas';

export const BORRARDATOSUI = '[ui] Borrar datos ui';

export class setIndexDetalleProcediminetoAEditar implements Action {
  readonly type = SETINDEXDETALLEPROCEDIMINETOAEDITAR;

  constructor(public indexDetalleProcediminetoAEditar: number) {}
}

export class unsetIndexDetalleProcediminetoAEditar implements Action {
  readonly type = UNSETINDEXDETALLEPROCEDIMINETOAEDITAR;
}

export class ActivarLoading implements Action {
  readonly type = ACTIVAR_LOADING;
}

export class DesactivarLoading implements Action {
  readonly type = DESACTIVAR_LOADING;
}

export class SetCoordenadas implements Action {
  readonly type = SET_COORDENADAS;

  constructor(public coordenadas: google.maps.LatLng) {}
}

export class borrarDatosUI implements Action {
  readonly type = BORRARDATOSUI;
}

export type uiActions =
  | setIndexDetalleProcediminetoAEditar
  | unsetIndexDetalleProcediminetoAEditar
  | ActivarLoading
  | DesactivarLoading
  | SetCoordenadas
  | borrarDatosUI;
