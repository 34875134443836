<div class="container__panelPrestacion">
  <div class="container-prescriptor">
    <p class="prescriptor__title">Prescriptor</p>
    <form [formGroup]="form" class="form__prescriptor">

      <ng-container *ngIf="esRescate; else noRescate">
        <div class="form-infoN">
          <label for="matriculaPrescriptor">Matrícula <span
              *ngIf="form.get('NroMatriculaPrescriptor') | controlRequired" class="span-required">*</span></label>
          <input type="text" class="form-controlN" formControlName="NroMatriculaPrescriptor">
        </div>

        <div class="form-infoN">
          <label for="provinciaPrescriptor">Tipo Matrícula <span *ngIf="form.get('ProvinciaPrescriptor') | controlRequired"
              class="span-required">*</span></label>
          <input type="text" class="form-controlN" formControlName="ProvinciaPrescriptor">
        </div>

        <div class="form-infoN">
          <label for="tipoPrescriptor">Tipo <span *ngIf="form.get('TipoPrescriptor') | controlRequired"
              class="span-required">*</span></label>
          <input type="text" class="form-controlN" formControlName="TipoPrescriptor">
        </div>
      </ng-container>

      <ng-template #noRescate>

        <div class="form-infoN">
          <label for="matriculaPrescriptor">Matrícula <span
              *ngIf="form.get('NroMatriculaPrescriptor') | controlRequired" class="span-required">*</span></label>
          <input type="text" class="form-controlN" formControlName="NroMatriculaPrescriptor" id="matriculaPrescriptor"
            maxlength="6">
          <small class="text-danger animated fadeIn"
            *ngIf="form.controls['NroMatriculaPrescriptor'].touched && form.controls['NroMatriculaPrescriptor'].errors">Ingrese
            un número de matricula válido</small>
        </div>

        <div class="form-infoN">
          <label for="provinciaPrescriptor">Provincia <span *ngIf="form.get('ProvinciaPrescriptor') | controlRequired"
              class="span-required">*</span></label>
          <select class="form-controlN" formControlName="ProvinciaPrescriptor" id="provinciaPrescriptor">
            <option [ngValue]="provinciaPrescriptor.codigo" *ngFor="let provinciaPrescriptor of provinciasPrescriptor">
              {{provinciaPrescriptor.nombre}}
            </option>
          </select>
        </div>

        <div class="form-infoN">
          <label for="tipoPrescriptor">Tipo <span *ngIf="form.get('TipoPrescriptor') | controlRequired"
              class="span-required">*</span></label>
          <select class="form-controlN" formControlName="TipoPrescriptor" id="tipoPrescriptor">
            <option [ngValue]="tipoPrescriptor.codigo" *ngFor="let tipoPrescriptor of tiposPrescriptor">
              {{tipoPrescriptor.nombre}}
            </option>
          </select>
          <small class="text-danger animated fadeIn"
            *ngIf="form.controls['TipoPrescriptor'].touched && form.controls['TipoPrescriptor'].errors">Campo
            requerido</small>
        </div>

      </ng-template>


    </form>
  </div>
</div>
