<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
</head>

<main class="cardN" id="cardNP">
  <div class="card__columnL" id="card__columnLP">
    <figure class="card__logoNuvalidC">
      <img src="/assets/design/logoNuvalid.svg" alt="logoNuvalid" class="card__logoNuvalid">
      <!-- <div id="logoN"> </div> -->
    </figure>
    <figure class="card__phoneC">
      <a href="#" id="phone"></a>
      <!-- <a href="#"><img src="/assets/phone.svg" alt="phoneNuvalid" class="card__phone"></a> -->
    </figure>
  </div>

  <div class="card__columnR " id="card__columnR-loginP">

    <div class="illustrations">
      <img src="./assets/design/docPassword.svg" alt="" class="img__loginP" id="docPassword">
    </div>
    <div class="text__password">
      <h3 class="tittleP">Administardores</h3>
    </div>

    <div class="reminder-passwordC">
      <div class="lineP"></div>

      <p class="reminder-password">{{titulo}}</p>
    </div>
    <div class="data-login" id="data-loginP">

      <table class="table table-striped table-bordered table-sm">
        <thead>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Email</th>
          <th>Rol</th>
        </thead>
        <tbody>
          <tr *ngFor="let administrador of administradores">
            <td>{{administrador.nombre}}</td>
            <td>{{administrador.apellido}}</td>
            <td>{{administrador.email}}</td>
            <td>{{administrador.rol}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="components components-loginP">
      <ng-container *ngIf=" funcion == 'verAdministradores'">
        <button (click)="devolverRespuesta('true')" type="submit" class="botton bottonP" ngbAutofocus>OK</button>
      </ng-container>

      <ng-container *ngIf=" funcion == 'verDatosSolicitados'">
        <button (click)="devolverRespuesta('cancelar')" type="submit" class="botton bottonP">Cancelar</button>
        <button (click)="devolverRespuesta('rechazar')" type="submit" class="botton bottonP">Rechazar</button>
        <button (click)="devolverRespuesta('aceptar')" type="submit" class="botton bottonP"
          ngbAutofocus>Aceptar</button>
      </ng-container>
    </div>
  </div>
</main>

<script src="js/lottiePhone.js"></script>
<script src="js/index.js"></script>

</html>
