<form [formGroup]="form">
  <div class="container-prestacion">
    <div class="container-prestacion1">
      <div class="form-infoN prestacion__arancel">
        <label>Motivo fin tratamiento <span *ngIf="form.get('CodFinalizacionTratamiento')"
            class="span-required">*</span></label>
        <select class="form-infoN" style="width: 100%;" formControlName="CodFinalizacionTratamiento" id="finTratamietno">
          <option [ngValue]="null">
            Seleccionar
          </option>
          <option [ngValue]="finTratamiento.codigo" *ngFor="let finTratamiento of CodFinalizacionTratamiento">
            {{finTratamiento.nombre}}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>

<!-- <form [formGroup]="form">
  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label for="nombrePresta">Motivo fin tratamiento <span class="text-danger"
          *ngIf="form.get('CodFinalizacionTratamiento') | controlRequired">*</span></label>
      <select class="form-control" formControlName="CodFinalizacionTratamiento">
        <option [ngValue]="finTratamiento.codigo" *ngFor="let finTratamiento of CodFinalizacionTratamiento">
          {{finTratamiento.nombre}}
        </option>
      </select>
    </div>
  </div>
</form> -->
