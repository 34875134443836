import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DocumentacionRequestModel } from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setDocumentacionRequest,
  setEstadoFormDocumentacionRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-documentacion-request',
  templateUrl: './datos-documentacion-request.component.html',
  styleUrls: ['./datos-documentacion-request.component.scss'],
})
export class DatosDocumentacionRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<DocumentacionRequestModel> =
    this.formBuilder.group<DocumentacionRequestModel>({
      NombreArchivo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      OrigenArchivo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoArchivo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  fileToUpload: File | null = null;
  codigoTransaccion: string | null = null;
  tipoArchivos: Array<any> = [];

  @ViewChild('labelImport') labelImport!: ElementRef;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          // this.form.controls['TipoPrescriptor'].setValidators(
          //   Validators.required
          // );
          break;

        default:
          break;
      }
      this.store.dispatch(
        new setEstadoFormDocumentacionRequest(this.form.valid)
      );
    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.tipoArchivos =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoArchivo || [];
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  handleFileInput(e: any) {
    let files: FileList = e.target.files;
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map((f) => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
    this.guardar();
  }

  guardar() {
    let documentacionRequest = new DocumentacionRequestModel(
      this.fileToUpload?.name || '',
      '',
      this.form.getRawValue().TipoArchivo
    );
    this.store.dispatch(new setDocumentacionRequest(documentacionRequest));
    this.store.dispatch(new setEstadoFormDocumentacionRequest(this.form.valid));
  }
}
