import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AcompannanteRequestModel } from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setAcompannanteRequest,
  setEstadoFormAcompannanteRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
@Component({
  selector: 'app-acompannante-request',
  templateUrl: './acompannante-request.component.html',
  styleUrls: ['./acompannante-request.component.scss'],
})
export class AcompannanteRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<AcompannanteRequestModel> =
    this.formBuilder.group<AcompannanteRequestModel>({
      ApellidoAcompannante: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NombreAcompannante: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroDocAcompannante: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroTelefono: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoDocAcompannante: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  tiposDocumentos: Array<any> = [];
  codigoTransaccion: string | null = null;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          this.form.controls['NombreAcompannante'].setValidators(
            Validators.required
          );
          this.form.updateValueAndValidity();
          break;

        default:
          break;
      }
      this.store.dispatch(
        new setEstadoFormAcompannanteRequest(this.form.valid)
      );
    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.tiposDocumentos =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoDocumento || [];
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    let acompannanteRequest = new AcompannanteRequestModel(
      datos.ApellidoAcompannante,
      datos.NombreAcompannante,
      datos.NroDocAcompannante,
      datos.NroTelefono,
      datos.TipoDocAcompannante
    );
    this.store.dispatch(new setAcompannanteRequest(acompannanteRequest));
    this.store.dispatch(new setEstadoFormAcompannanteRequest(this.form.valid));
  }
}
