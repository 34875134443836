import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IpcService } from '@services/ipc.service';

@Component({
  selector: 'app-new-version-modal',
  templateUrl: './new-version-modal.component.html',
  styleUrls: ['./new-version-modal.component.scss']
})
export class NewVersionModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private ipcService: IpcService) {}

  ngOnInit() {

  }

  downloadNuvalid(){
    const url = 'https://descargas.itcsoluciones.com/NUVALID%20Setup%202.0.16.exe'
    this.ipcService.downloadNuvalidExe(url);
  }

}
