import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { AltaInternacionRequestModel } from '@models/datosTransaccion.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-alta-internacion-request',
  templateUrl: './datos-alta-internacion-request.component.html',
  styleUrls: ['./datos-alta-internacion-request.component.scss']
})
export class DatosAltaInternacionRequestComponent implements OnInit {


  private subscriptionStore: Subscription = new Subscription();
  codigoTransaccion: string | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<AltaInternacionRequestModel> =
    this.formBuilder.group<AltaInternacionRequestModel>({
      NroTicket: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
    });

  constructor(private store: Store<AppState>) {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }


  // guardar() {
  //   this.submitted = true;
  //   if(!this.form.controls['CodPrestacion'].value?.length) {
  //     this.errorLength = true;
  //   }
  //   if (this.form.invalid || !this.form.controls['CodPrestacion'].value?.length) {
  //     this.dialogsServices.toastError('Los datos ingresados son incorrectos');
  //     return;
  //   }
  //   let datos = this.form.getRawValue();
  //   let detalleProcedimiento = new DetalleProcedimientoRequestModel(
  //     datos.NroItem,

  //   );

  //   if (this.indexDetalleProcediminetoAEditar != null) {
  //     //Se esta modificando
  //     let detallesProcedimientos: Array<DetalleProcedimientoRequestModel> = [
  //       ...this.detallesProcediminetos,
  //     ];
  //     detallesProcedimientos[this.indexDetalleProcediminetoAEditar] =
  //       detalleProcedimiento;
  //     this.store.dispatch(
  //       new setDetallesProcedimientosRequest(detallesProcedimientos)
  //     );
  //     this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
  //   } else {
  //     //Se esta creando
  //     this.store.dispatch(
  //       new setDetallesProcedimientosRequest([
  //         ...this.detallesProcediminetos,
  //         detalleProcedimiento,
  //       ])
  //     );
  //     this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
  //   }
  //   this.reset();
  // }

  // reset() {
  //   this.form.reset();
  //   this.inputPrestacionesComponent.clean();
  //   this.setDefaultValues();
  //   this.indexDetalleProcediminetoAEditar = null;
  //   this.submitted = false;
  //   this.inputPrestacionesComponent.focus();
  // }

}
