<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <div class="form-row">
        <ng-container *ngIf="numeroCredencial">
          <div class="row">
            <div class="col">
              <p>Credencial: {{ numeroCredencial }}</p>
              <p>Ingrese los 3 dígitos de seguridad</p>
            </div>
          </div>
        </ng-container>
        <div class="form-infoN">
          <label for="codigoDeSeguridad">CSC <span class="span-required">*</span></label>
          <input maxlength="3" id="nCSC" type="text" class="form-controlN" name="codigoDeSeguridad"
            formControlName="codigoDeSeguridad" autocomplete=off placeholder="Código de seguridad"
            (keyup.enter)="devolverRespuesta(true)">
          <small *ngIf="form.controls['codigoDeSeguridad'].errors && form.controls['codigoDeSeguridad'].touched"
            class="text-danger animated fadeIn form-text ">El código de seguridad no es válido</small>
          <!-- <button (click)="devolverRespuesta(true)" type="submit" class="buttonImprimirTickets">Confirmar</button> -->
        </div>
        <div class="validarSinCsc">
          <small *ngIf="siglaSwiss || siglaScis">
            <a target="_blank" class="reminder__link" (click)="devolverSinCsc(true)">Validar sin CSC</a>
          </small>
        </div>
        <hr>
        <ng-container *ngIf="logoTicket">
          <div class="col" style="display: flex;">
            <img style="width: 110; height: 50px;" src="./assets/img/{{logoTicket}}_LOGO.png" title="{{logoTicket}}"
              alt="{{logoTicket}}">
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
