import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthConfiguracionInicialGuard } from '@guards/authConfiguracionInicial.guards';
import { AuthPagesGuard } from '@guards/authPages.guards';
import * as pages from '@pages/pages.index';

const routes: Routes = [
  {
    path: '',
    component: pages.PagesComponent,
    canActivate: [AuthPagesGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'inicio',
      },
      {
        path: 'inicio',
        component: pages.InicioComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'turnea',
        component: pages.TurneaComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'agenda',
        component: pages.AgendaComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'agenda/CrearContacto',
        component: pages.CrearContactoComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil',
        component: pages.PerfilComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/vercambios',
        component: pages.AceptarCambiosUsuarioComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/cambiarrol',
        component: pages.CambiarRolUsuariosComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/verpendientes',
        component: pages.AceptarUsuarioComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/activarusuarios',
        component: pages.ActivarUsuariosComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/modificardatos',
        component: pages.ModificarUsuarioComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/ordenartransacciones',
        component: pages.OrdenarTransaccionesComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/prestadoresnodeseados',
        component: pages.SeleccionarPrestadoresNoDeseadosComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'perfil/eliminarusuarios',
        component: pages.EliminarUsuarioComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'sugerencia',
        component: pages.SugerenciaComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'ticket',
        component: pages.TicketComponent,
        canActivate: [AuthPagesGuard],
      },
      {
        path: 'turnea',
        component: pages.TurneaComponent,
        canActivate: [AuthPagesGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
