<!--<div class="fondo">
    <div class="container" style="margin-top: 10vh;">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 text-center"><img src="./assets/img/logo.svg" style="height: 70px;"></div>
        </div>

        <div class="row mt-5 justify-content-center">
            <div class="card col-12 col-md-10 p-0 border-0 boxShadow">
                <div class="card-header p-0 bg-navegation rounded-top">

                    <app-navegation [volverAtrasAuth]="true" [volverALoginAuth]="true" [seleccionar]="true">
                    </app-navegation>

                </div>
                <div class="card-body bg-login p-5 rounded">
                    <h5 class="mb-3">
                        Elegir la dirección en donde se quiere hacer uso del sistema
                    </h5>

                    <ng-container *ngFor="let lugar of lugaresAMostrar">
                        <app-radiocheckbox [lugar]='lugar' [checked]="lugar == lugarAtencionSeleccionado ? true : false" (seleccionarLugar)="elegirLugar(lugar)">
                        </app-radiocheckbox>

                    </ng-container>
                    <a class="link" (click)="contacto()">Si falta cargar algún domicilio haga click aquí para poder darlo de alta.</a>
                </div>
            </div>
        </div>
    </div>
</div>

-->

<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="../../../../sass/estilos.scss"> -->
  <!-- <script src="https://kit.fontawesome.com/8154432079.js" crossorigin="anonymous"></script> -->
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">
</head>

<body>
  <div class="containerNNav">
    <app-navbar-windows class="app-navbar-windows"></app-navbar-windows>

    <main class="cardN container-direction" id="cardNInst">
      <div class="card__columnL">
        <figure class="card__logoNuvalidC">
          <img src="./assets/design/logoNuvalid.svg" alt="logoNuvalid" class="card__logoNuvalid" (click)="irALogin()">
          <!-- <div id="logoN"> </div> -->
        </figure>
        <figure class="card__phoneC">
          <!-- <a href="#" id="phone"></a> -->
          <a><img src="./assets/design/phone.svg" alt="phoneNuvalid" class="card__phone" (click)="contacto()"></a>
        </figure>
      </div>
      <div class="card__columnR card__columnR--direction">
        <form>
          <div>
            <h5 class="form-textN">A continuaci&oacute;n ver&aacute; los domicilios donde est&aacute; dado
              de alta el Prestador. Por favor seleccione <span class="form-text__bold">una &uacute;nica
                direcci&oacute;n</span> para hacer uso de Nuvalid.
            </h5>
            <div class="container__list-directions">
              <ul class="list-directions">
                <ng-container *ngFor="let lugar of lugaresAMostrar">
                  <li class="custom-radio">
                    <app-radiocheckbox style="text-transform: uppercase;" [lugar]='lugar'
                      [checked]="lugar == lugarAtencionSeleccionado ? true : false"
                      (seleccionarLugar)="elegirLugar(lugar)" class="custom-radio-text">
                    </app-radiocheckbox>
                  </li>
                </ng-container>
              </ul>
            </div>

            <div class="reminder reminder-direction">
              <div class="reminder__warning reminder__warningDirections"><img src="./assets/design/warning.svg"
                  alt="danger" id="warning__img">
              </div>
              <p class="reminder__text reminder__textDirections">
                Si la direcci&oacute;n no se encuentra en la lista debe comunicarse con su financiador
                haciendo
                <a target="_blank" class="reminder__link" (click)="contacto()">
                  click aqu&iacute;.</a>
              </p>
            </div>
          </div>
        </form>

        <div class="components components-directions">
          <app-navegation [volverAtrasAuth]="true" [seleccionar]="true">
          </app-navegation>
          <div></div>
        </div>
      </div>

    </main>
  </div>

  <script src="js/lottiedDoctora.js"></script>
  <script src="js/scripts/lottiePhone.js"></script>
  <script src="scripts/lottieLogoN.js"></script>

  <script src="js/index.js"></script>

</body>

</html>
