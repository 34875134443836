import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-datos-informar-diagnostico-request',
  templateUrl: './datos-informar-diagnostico-request.component.html',
  styleUrls: ['./datos-informar-diagnostico-request.component.scss']
})
export class DatosInformarDiagnosticoRequestComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
    
  }

  ngOnDestroy() {

  }

}
