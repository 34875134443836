import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators} from '@angular/forms';
import { setEstadoFormDatosEnvioPrescripcionDiagRequest, setExtraDispensaRequest } from '@store/actions';
import { diagnosticoCodigoDescModel } from '@models/global.models';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { ExtrasDispensa } from '@models/datosTransaccion.models';
import { DiagnosticosRequestModel } from '../../../../models/datosTransaccion.models';
import { setDiagnosticosRequest } from '../../../../store/actions/datosTransacciones.actions';

@Component({
  selector: 'app-datos-diagnostico-disp-request',
  templateUrl: './datos-diagnostico-disp-request.component.html',
  styleUrls: ['./datos-diagnostico-disp-request.component.scss']
})
export class DatosDiagnosticoDispRequestComponent implements OnInit {

  @Input() nomenclador: boolean = false;
  private subscriptionFormStatus: Subscription = new Subscription();
  //form!:  FormGroup;
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  extraDispensa: ExtrasDispensa | undefined;
  infoDiagnosticos: Array<DiagnosticosRequestModel> = [];
  private subscriptionStore: Subscription = new Subscription();
  form: IFormGroup<any> =
    this.formBuilder.group({
      CodDiagnostico: new UntypedFormControl(<ControlState<string>>null, [Validators.required]),
      DescripcionDiagnostico: new UntypedFormControl(<ControlState<string>>null, [Validators.required])
    });

  codigoTransaccion: string | null = null;

  constructor(
    private store: Store<AppState>
  ){ }

  ngOnInit(): void {
    this.listenEnviosPrescripcion();
    this.subscriptionStore = this.store
    .subscribe((datos) => {
      this.extraDispensa = datos.datosTransaccion.extraDispensaRequest || undefined;
      this.codigoTransaccion = datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
    });
    this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionDiagRequest( this.form.valid));
  }

  selectDiagnostico(diagnostico: diagnosticoCodigoDescModel | null) {
    //Evento emitido desde el typeahead
    if (this.codigoTransaccion != '02R') {
      if (diagnostico) {
        this.form.controls['CodDiagnostico'].setValue(diagnostico?.codigo);
        this.form.controls['DescripcionDiagnostico'].setValue(diagnostico?.descripcion);
        let datos = this.form.getRawValue();
        let InfoDiag = new DiagnosticosRequestModel(
          datos.CodDiagnostico,
          datos.DescripcionDiagnostico,
          datos.Nomenclador,
        );
        let infoDiagnosticos: Array<DiagnosticosRequestModel> = [
          ...this.infoDiagnosticos,
        ];
        this.store.dispatch(new setDiagnosticosRequest([
          ...this.infoDiagnosticos,
          InfoDiag,
        ]));
      }else{
        this.form.controls['CodDiagnostico'].setValue(null);
      }
    }else{
      if (diagnostico) {
        this.form.controls['CodDiagnostico'].setValue(diagnostico?.codigo);
        let extDispensa: ExtrasDispensa = {
          ...this.extraDispensa,
         diagnostico: diagnostico?.codigo + ' - ' +diagnostico?.descripcion
        };
        this.store.dispatch(new setExtraDispensaRequest(extDispensa));
      } else {
        this.form.controls['CodDiagnostico'].setValue(null);
      }
    }

  }

  listenEnviosPrescripcion() {
    this.subscriptionFormStatus = this.form
    .statusChanges.subscribe((status) => {
      console.log('status diag', status);

      this.form.controls['CodDiagnostico'].markAsTouched();
      this.form.controls['DescripcionDiagnostico'].markAsTouched();
      if (status == 'VALID') {
        this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionDiagRequest(true));
      } else {
        this.store.dispatch(new setEstadoFormDatosEnvioPrescripcionDiagRequest(false));
      }
    });
}

ngOnDestroy() {
  this.subscriptionFormStatus.unsubscribe();
  this.subscriptionStore.unsubscribe();
}

}
