<!-- <div class="modal-header">
    <h4>¿Los prestadores del lugar son los siguientes?</h4>
</div>
<div class="modal-body">
    <div class="table-responsive mt-2">
        <table class="table table-striped table-bordered table-sm">
            <tbody>
                <tr *ngFor="let prestador of prestadores">
                    <td>{{prestador.razon_social}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button (click)="devolverRespuesta('error')" type="submit" class="btn btn-danger mr-2">Reportar
        error</button>
    <button (click)="devolverRespuesta('no')" type="submit" class="btn btn-pcpos mr-2">No</button>
    <button (click)="devolverRespuesta('si')" type="submit" class="btn btn-pcpos" ngbAutofocus>Sí</button>
</div>  -->



<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
    <!-- <script src="https://kit.fontawesome.com/8154432079.js" crossorigin="anonymous"></script> -->
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">

</head>


<main class=" container-direction cardN-modal">
    <!-- <div class="card__columnL card__columnL-modal">
        <figure class="card__logoNuvalidC">
            <img src="/assets/design/logoNuvalid.svg" alt="logoNuvalid" class="card__logoNuvalid">
            <div id="logoN"> </div>
        </figure>
        <figure class="card__phoneC">
            <a href="#" id="phone"></a>
            <a href="#"><img src="/assets/phone.svg" alt="phoneNuvalid" class="card__phone"></a>
        </figure>
    </div> -->
    <div class="card__columnR card__columnR--modal">

        <div>
            <h5 class="form-textN">Los <span class="form-text__bold">Prestadores</span> dados de alta en el lugar son los siguientes:
            </h5>
            <div class="container__list-directions container__list-directions-modal">
                <ul class="list-directions-prestadores list-directions-modal">
                    <li class="custom-radio" *ngFor="let prestador of prestadores">
                        <label>
                            <!-- <input type="radio" name='direction'> <i class="fa fa-circle-o fa-1x"></i><i
                                class="fa fa-dot-circle-o fa-1x"></i> -->
                            <span class="label-directions"> {{prestador.razon_social}}</span>
                        </label>
                    </li>
                </ul>
            </div>

            <div class="reminder reminder-modal">
                <div class="reminder__warning reminder__warning-modal"><img src="./assets/design/warning.svg" alt="danger" id="warning__img">
                </div>
                <p class="reminder__text reminder__text-modal">
                    Si el Prestador no se encuentra en la lista puede comunicarse con nuestro centro de soporte haciendo
                    <a target="_blank" class="reminder__link" (click)="contacto()">
                        click aquí.</a>
                </p>
            </div>
        </div>
        <div class="components components-modal">

            <button (click)="devolverRespuesta('no')" type="submit" class="botton">Atrás</button>
            <button (click)="devolverRespuesta('si')" type="submit" class="botton" ngbAutofocus>Continuar</button>
        </div>
        <!-- <div class="table-responsive mt-2">
        <table class="table table-striped table-bordered table-sm">
            <tbody>
                <tr *ngFor="let prestador of prestadores">
                    <td>{{prestador.razon_social}}</td>
                </tr>
            </tbody>
        </table>
    </div> -->
    </div>
</main>








<script src="js/lottiedDoctora.js"></script>
<script src="js/scripts/lottiePhone.js"></script>
<script src="scripts/lottieLogoN.js"></script>

<script src="js/index.js"></script>



</html>
