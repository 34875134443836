import { ActionReducerMap } from '@ngrx/store';
import * as models from '@store/models/store.models';
import * as reducers from '@store/reducers';
import * as datosDispensa from '@models/datosDispensa.models';

export interface AppState {
  ui: models.UIState;
  instalacion: models.InstalacionState;
  // instalacion1: models.InstalacionUsuario; //modificacion Mica
  configuracion: models.ConfiguracionState;
  datosInicio: models.datosInicioState;
  datosTransaccion: models.datosTransaccionesState;
  filters: models.filterState;
  sugerencias: models.sugerenciasState;
  recetas: models.recetasState;
  estadoFormsInicio: models.estadoFormsState;
  datosInicialesExtra: models.datosInicialesExtraState;
  datosDispensa: models.datosDispensaState
}

export const appReducers: ActionReducerMap<AppState, any> = {
  ui: reducers.uiReducer,
  instalacion: reducers.instalacionReducer,
  configuracion: reducers.configuracionReducer,
  datosInicio: reducers.datosInicioReducer,
  datosTransaccion: reducers.datosTransaccionesReducer,
  filters: reducers.filterReducer,
  sugerencias: reducers.sugerenciasReducer,
  recetas: reducers.recetasReducer,
  estadoFormsInicio: reducers.estadoFormsReducer,
  datosInicialesExtra: reducers.datosInicialesExtraReducer,
  datosDispensa: reducers.datosDispensaReducer
};
