<div class="containerContactosModal">
  <main class="cardModalContacto">
    <div class="card__content" id="card__content-modal">

      <form>
        <div class="crossContainer">
          <div class="contactoHeader">
            <p class="contactoTitle">Disculpa las molestias</p>
          </div>
        </div>
        <div class="form__data__contacto">
          <div>
            <div class="form__items">
              <div>
                <p class="form__items__text">Estamos trabajando en mejorar esta funcionalidad para brindar una experiencia mejor. Pronto estará disponible.</p>
                <p class="form__items__text" style="padding-top: 1.5em;">Gracias por tu paciencia y comprensión.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="form__buttons">
          <button type="button" class="btnGoToHome" (click)="devolverRespuesta('CERRAR')">Aceptar</button>
        </div>
      </form>

    </div>
  </main>
</div>
