import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DiagnosticosRequestModel } from '@models/datosTransaccion.models';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setDiagnosticosRequest,
  setEstadoFormDiagnosticosRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { unsetDiagnosticosRequest } from '../../../../store/actions/datosTransacciones.actions';

@Component({
  selector: 'app-datos-diagnosticos-request',
  templateUrl: './datos-diagnosticos-request.component.html',
  styleUrls: ['./datos-diagnosticos-request.component.scss'],
})
export class DatosDiagnosticosRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  tipoPrestador: string = '';
  esRescate: boolean = false;
  private diagnosticosPrecargados: boolean = false;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<any> = this.formBuilder.group<any>({
    diagnosticos: this.formBuilder.array<DiagnosticosRequestModel>([
      this.formBuilder.group({
        CodDiagnostico: new UntypedFormControl(<ControlState<string>>'', [
          blankSpaceValidator,
        ]),
        DescripcionDiagnostico: new UntypedFormControl(<ControlState<string>>'', [
          blankSpaceValidator,
        ]),
        Nomenclador: new UntypedFormControl(<ControlState<string>>'', [
          blankSpaceValidator,
        ]),
      }),
    ]),
  });

  codigoTransaccion: string | null = null;
  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    this.addValidators();
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.tipoPrestador =
        datos.datosInicio.PrestadorRequest?.TipoPrestador.toUpperCase() || '';
      this.esRescate = datos.recetas.EsRecetaRequest ?? false;

      if (this.esRescate && !this.diagnosticosPrecargados) {
        this.preloadDiagnosticos(datos.recetas.RecetasRequest!.Diagnosticos.Diagnostico);
        this.diagnosticosPrecargados = true;
      }
    });
  }

  preloadDiagnosticos(diagnosticos: any[]) {
    const diagnosticosFormArray = this.form.controls.diagnosticos as UntypedFormArray;
    diagnosticosFormArray.clear();

    diagnosticos.forEach((diagnostico) => {
      diagnosticosFormArray.push(
        this.formBuilder.group<DiagnosticosRequestModel>({
          CodDiagnostico: new UntypedFormControl(diagnostico.Codigo || '', [blankSpaceValidator]),
          DescripcionDiagnostico: new UntypedFormControl(diagnostico.Descripcion || '', [blankSpaceValidator]),
          Nomenclador: new UntypedFormControl(diagnostico.Nomenclador || '', [blankSpaceValidator]),
        })
      );
    });

    this.addValidators();
    this.setDefaultValues();
  }

  setDefaultValues() {
    if (this.esRescate) {
      const diagnosticos = this.form.controls.diagnosticos as UntypedFormArray;
      diagnosticos.controls.forEach((control) => {
        control.get('CodDiagnostico')?.disable();
        control.get('DescripcionDiagnostico')?.disable();
      });
    }
  }

  getDiagnosticos() {
    let diagnosticos = this.form.controls.diagnosticos as UntypedFormArray;
    if (diagnosticos) {
      return diagnosticos.controls;
    } else {
      return null;
    }
  }

  addDiagnostico() {
    const diagnosticos = this.form.controls.diagnosticos as UntypedFormArray;
    diagnosticos.push(
      this.formBuilder.group<DiagnosticosRequestModel>({
        CodDiagnostico: new UntypedFormControl('', [blankSpaceValidator]),
        DescripcionDiagnostico: new UntypedFormControl('', [blankSpaceValidator]),
        Nomenclador: new UntypedFormControl('', [blankSpaceValidator]),
      })
    );
    this.addValidators();
  }

  addValidators() {
    const diagnosticos = <UntypedFormArray>this.form.controls['diagnosticos'];

    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          if (this.tipoPrestador == 'PSICOLOGIA') {
            diagnosticos.controls.forEach((control: any) => {
              control.controls['CodDiagnostico'].setValidators(Validators.required);
              control.controls['DescripcionDiagnostico'].setValidators(Validators.required);
            });
            this.store.dispatch(
              new setEstadoFormDiagnosticosRequest(false)
            );
          } else {
            this.store.dispatch(
              new setEstadoFormDiagnosticosRequest(null)
            );
          }
          break;
        default:
          this.store.dispatch(
            new setEstadoFormDiagnosticosRequest(null)
          );
          break;
      }
    }
  }

  deleteDiagnostico(i: number) {
    if (i != 0) {
      const control = <UntypedFormArray>this.form.controls['diagnosticos'];
      control.removeAt(i);
    }
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
    this.store.dispatch(new unsetDiagnosticosRequest());
    this.store.dispatch(new setEstadoFormDiagnosticosRequest(null));
    this.diagnosticosPrecargados = false;  // Reset flag
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    this.store.dispatch(
      new setDiagnosticosRequest(this.form.getRawValue().diagnosticos)
    );
    this.store.dispatch(new setEstadoFormDiagnosticosRequest(this.form.valid));
  }
}
