import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { PersistenciaService } from '@services/persistencia.service';
import * as storeActions from '@store/actions';
import { DesactivarLoading } from '@store/actions';
import { Router } from '@angular/router';

@Injectable()
export class configuracionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private persistenciaService: PersistenciaService,
    private router: Router
  ) {}

  @Effect({ dispatch: false })
  setCodigoInstalacion$ = this.actions$
    .pipe(ofType(storeActions.SET_CODIGOINSTALACION))
    .pipe(
      tap((accion: storeActions.setCodigoInstalacion) => {
        this.persistenciaService.setCodigoInstalacion(
          accion.codigosInstalacion
        );
        this.store.dispatch(new storeActions.borrarDatosInstalacion());
        this.store.dispatch(new storeActions.borrarDatosUI());
      })
    );

  @Effect({ dispatch: false })
  setUsuarioActual$ = this.actions$
    .pipe(ofType(storeActions.SETUSUARIOACTIVO))
    .pipe(
      tap((accion: storeActions.setUsuarioActual) => {
        this.persistenciaService.setUsuarioActual(accion.usuarioActual);
        this.store.dispatch(new DesactivarLoading());
      })
    );

  @Effect({ dispatch: false })
  removerUsuario$ = this.actions$
    .pipe(ofType(storeActions.REMOVEDATOSUSUARIO))
    .pipe(
      tap((accion: storeActions.removeDatosUsuario) => {
        this.persistenciaService.removeusuarioActual();
        this.router.navigateByUrl('login');
        window.location.reload();
      })
    );

  @Effect({ dispatch: false })
  removerCodigoInstalacion$ = this.actions$
    .pipe(ofType(storeActions.REMOVECODIGOINSTALACION))
    .pipe(
      tap((accion: storeActions.removeCodigoInstalacion) => {
        this.persistenciaService.removeInstalacion(accion.codigoInstalacion);
        this.router.navigate(['login']);
      })
    );
}
