<div class="mt-3">
    <div #mapContainer id="map"></div>
</div>


<div class="row mt-3">
    <div class="col-12" style="align-self: center; text-align: left" *ngIf="marcadoresFiltrados.length < 1">
        <p class="pointer mb-0" (click)="noEstaLugarAtencion()">
            <small class="text-secondary">Has click aquí si no encuentra su lugar de atención</small>
        </p>
    </div>

    <div class="col-12" style="align-self: center; text-align: left" *ngIf="marcadoresFiltrados.length >= 1">
        <div class="col-12 p-0">
            <div class="form-check p-0" *ngFor="let lugar of marcadoresFiltrados">
                <app-radiocheckbox [lugar]='lugar.lugarAtencion' [checked]="lugar.lugarAtencion == lugarAtencionSeleccionado ? true : false" (seleccionarLugar)="selectDireccion(lugar.marker)">
                </app-radiocheckbox>
            </div>
        </div>
    </div>
</div>