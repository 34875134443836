import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataUsuario } from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';

@Component({
  selector: 'app-activar-usuarios',
  templateUrl: './activar-usuarios.component.html',
})
export class ActivarUsuariosComponent implements OnInit {
  titulo: string = 'Ver usuarios inactivos ';
  usuariosAActivar: Array<DataUsuario> | null = null;

  constructor(
    private operacionesPerfilService: OperacionesPerfilService,
    private store: Store<AppState>,
    private globalService: GlobalService,
    private dialogsService: DialogsService
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(seActivoUsuario = false) {
    //Se acepto usuario sirve para que no tire error si se acepto todos los usuarios
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService.getDatosUsuariosInactivos().subscribe(
      (respuesta) => {
        this.usuariosAActivar = respuesta;
        this.dialogsService.cerrar();
        this.store.dispatch(new DesactivarLoading());
      },
      (error: HttpErrorResponse) => {
        if (seActivoUsuario) {
          this.usuariosAActivar = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
        } else {
          this.usuariosAActivar = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      }
    );
  }

  activarUsuarios(arrayActivarUsuarios: any) {
    this.store.dispatch(new ActivarLoading());
    console.log(arrayActivarUsuarios);

    this.operacionesPerfilService
      .activarUsuarios(arrayActivarUsuarios)
      .subscribe(
        (respuesta) => {
          this.dialogsService.toastSuccess(respuesta.message);
          this.refresh(true);
        },
        (error: HttpErrorResponse) => {
          this.usuariosAActivar = null;
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }
}
