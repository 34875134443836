import { Receta } from '@models/rescateResponse.model';
import { Action } from '@ngrx/store';

export const SETRECETASREQUEST =
  '[recetas] Set SETRECETASREQUEST';
export const UNSETRECETASREQUEST =
  '[recetas] UnSet SETRECETASREQUEST';

export const SETESRECETAREQUEST =
  '[recetas] Set SETESRECETAREQUEST';
export const UNSETESRECETAREQUEST =
  '[recetas] UnSet UNSETESRECETAREQUEST';

export class setRecetasRequest implements Action {
  readonly type = SETRECETASREQUEST;
  constructor(public RecetasRequest: Receta | null) { }
}

export class unsetRecetasRequest implements Action {
  readonly type = UNSETRECETASREQUEST;
}

export class setEsRecetaRequest implements Action {
  readonly type = SETESRECETAREQUEST;
  constructor(public EsRecetaRequest: boolean | null) { }
}

export class unsetEsRecetaRequest implements Action {
  readonly type = UNSETESRECETAREQUEST;
}

export type recetasActions =
  | setRecetasRequest
  | unsetRecetasRequest
  | setEsRecetaRequest
  | unsetEsRecetaRequest
