<!-- <div class="fondo">
    <div class="container" style="margin-top: 10vh;"> -->


<!-- <form action="" class="input-group groupUnselected" id="register"> -->


<!-- <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center"><img src="./assets/img/logo.svg" style="height: 70px;"></div>
    </div> -->

<!-- <div class="row mt-5 justify-content-center">
        <div class="card col-12 col-md-10 col-lg-8 p-0 border-0 boxShadow">
            <div class="card-header p-0 bg-navegation rounded-top">
                <app-navegation [volverAtrasAuth]="true" [volverALoginAuth]="false" (siguienteClick)="registrar()" [nombreBoton]="'Registrarse'">
                </app-navegation>
            </div> -->




<form [formGroup]="form" class="input-group" id="register" (ngSubmit)="registrar()" action="aplicaciones ">
    <div *ngIf="codigosInstalacion && roles">
        <!-- <h5>Nuevo Usuario</h5>
            <hr> -->

        <div class="data-login">
            <label>
                <select [ngClass]="{disabled: codigosInstalacion.length <= 1}" formControlName="codigoInstalacion"
                    class="labelForm" aria-label="Lugares de atencion">
                    <option *ngFor="let codigoInstalacion of codigosInstalacion"
                        value="{{codigoInstalacion.codigoInstalacion}}">
                        {{codigoInstalacion.domicilio | uppercase}}</option>
                </select>
            </label>
        </div>
        <div class="data-login">
            <label>
                <select [ngClass]="{disabled: roles.length <= 1}" formControlName="rol" class="labelForm">
                    <option *ngFor="let rol of roles" value="{{rol}}">
                        {{rol}}</option>
                </select>
            </label>
        </div>

        <div class="data-login" *ngIf="MostrarSeleccionarPrestador">
            <label class="labelForm">
                <select [ngClass]="{disabled: roles.length <= 1}" formControlName="cuitPrestadorSeleccionado">
                    <option *ngFor="let prestador of prestadoresLugarAtencion" value="{{prestador.cuit}}">
                        {{prestador.razon_social}}</option>
                </select>
            </label>
        </div>


        <div class="data-login">
            <label for="name" id="label-name" class="labelForm ">
                <input type="text " name="name" formControlName="nombre" placeholder="Nombre">
            </label>
            <div class="reminder reminder__loginE" *ngIf="submitted && form.controls['nombre'].errors">
                <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger" id="warning__img">
                </div>
                <p class="reminder__textCrear reminder__textLoginE">
                    Ingrese su nombre. Mínimo 4 y máximo 32 caracteres.
                </p>
            </div>
        </div>

        <div class="data-login">
            <label for="name" id="label-lastname" class="labelForm ">
                <input type="text " name="lastname" formControlName="apellido" placeholder="Apellido">
            </label>
            <div class="reminder reminder__loginE" *ngIf="submitted && form.controls['apellido'].errors">
                <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger" id="warning__img">
                </div>
                <p class="reminder__textCrear reminder__textLoginE">
                    Ingrese su apellido. Mínimo 4 y máximo 32 caracteres.
                </p>
            </div>
        </div>


        <div class="data-login">
            <label for="email" id="label-email" class="labelForm">
                <input type="email " name="email" formControlName="email" placeholder="E-mail ">
            </label>
            <div class="reminder reminder__loginE" *ngIf="submitted && form.controls['email'].errors">
                <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger" id="warning__img">
                </div>
                <p class="reminder__textCrear reminder__textLoginE">
                    El email ingresado no es válido
                </p>
            </div>

        </div>

        <div class="data-login">
            <!-- Change input type to be dynamic -->
            <label for="password" id="label-password" class="labelForm">
                <input id="inputPass1" [type]="fieldTextType ? 'text' : 'password'" formControlName="password"
                    placeholder="Contraseña " />

                <div class="password">
                    <span class="pointer eye" (click)="fieldTextType = !fieldTextType">
                        <ng-container *ngIf="fieldTextType">
                            <fa-icon [icon]='["fas", "eye"]' class="mr-1"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="!fieldTextType">
                            <fa-icon [icon]='["fas", "eye-slash"]' class="mr-1"></fa-icon>
                        </ng-container>
                    </span>

                </div>
            </label>
            <div class="reminder reminder__loginE" *ngIf="submitted && form.controls['password'].errors">
                <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger" id="warning__img">
                </div>
                <div>
                    <p class="reminder__textCrear reminder__textLoginE">
                        Utilice como mínimo <span class="reminder__grey"> 6 caracteres</span>
                    </p>
                    <p class="reminder__textCrear reminder__textLoginE">
                        Utilice al menos <span class="reminder__grey"> una Letra mayúscula (A-Z)</span>
                    </p>
                    <p class="reminder__textCrear reminder__textLoginE">
                        Utilice al menos <span class="reminder__grey"> un número (0-9)</span>
                    </p>
                    <p class="reminder__textCrear reminder__textLoginE">
                        Utilice al menos <span class="reminder__grey"> un caracter especial (ej. !@#$)</span>
                    </p>
                </div>
            </div>

        </div>

        <div class="data-login">

            <!-- Change input type to be dynamic -->
            <label for="password" id="label-password" class="labelForm">
                <input id="inputPass2" [type]="fieldTextType ? 'text' : 'password'" formControlName="confirmPassword"
                    name="passwordRep" placeholder="Repetir contraseña " />

                <div class="password">
                    <span class="pointer eye" (click)="fieldTextType=! fieldTextType ">
                        <ng-container *ngIf="fieldTextType ">
                            <fa-icon [icon]='["fas", "eye"]' class="mr-1 "></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="!fieldTextType ">
                            <fa-icon [icon]='["fas", "eye-slash"]' class="mr-1 "></fa-icon>
                        </ng-container>
                    </span>
                </div>
            </label>

            <div class="reminder reminder__loginE"
                *ngIf="submitted && form.controls[ 'password'].errors?.minLength && !form.hasError( 'mismatchedPasswords') ">
                <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger"
                        id="warning__img">
                </div>
                <p class="reminder__textCrear reminder__textLoginE">
                    Al menos 6 caracteres
                </p>
            </div>

            <div class="reminder reminder__loginE" *ngIf="submitted && form.hasError( 'mismatchedPasswords') ">
                <div class="reminder__warning"><img src="./assets/design/alert_invested.svg" alt="danger" id="warning__img">
                </div>
                <p class="reminder__textCrear reminder__textLoginE">
                    Las contraseñas no coinciden
                </p>
            </div>
        </div>
        <div id="form-textNP">
            <div class="components components-login" id="button-loginFinish">
                <app-navegation [volverAtrasAuth]="false" [volverALoginAuth]="false" (siguienteClick)="registrar()"
                    [nombreBoton]="'Crear cuenta'">
                </app-navegation>
            </div>
            <!-- <div class="reminder reminder-loginPassword">
                <p class="textCookies">Al registrar su cuenta, <a class="textCookies-link">acepta nuestros
              términos de
              servicio y politica de
              privacidad y
              cookies</a></p>
            </div> -->
        </div>
    </div>
</form>
<!-- </div>
    </div> -->
<!-- </form> -->
<!-- </div>
</div> -->
