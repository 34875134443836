import { InstalacionState } from '@store/models/store.models';
import * as fromInstalacion from '@store/actions';
import { lugarAtencion } from '@models/lugarAtencion.model';

const estadoInicialInstalacion: InstalacionState = {
  usuarioInstalacion: null,
  lugarAtencionSeleccionado: null,
  busquedaRealizada: null,
  lugares_atencion: Array<lugarAtencion>() //modificacion Mica
};

export function instalacionReducer(
  state = estadoInicialInstalacion,
  actions: fromInstalacion.instalacionActions
): InstalacionState {
  switch (actions.type) {
    case fromInstalacion.CARGAR_USUARIOINSTALACIONSUCCESS:
      return {
        ...state,
        usuarioInstalacion: { ...actions.usuarioInstalacion },
        lugarAtencionSeleccionado: null,
      };

    case fromInstalacion.SELECCIONARLUGARATENCION:
      return {
        ...state,
        lugarAtencionSeleccionado: actions.lugarAtencion,
      };

    case fromInstalacion.BUSQUEDA_REALIZADA:
      return {
        ...state,
        busquedaRealizada: actions.searchReturn,
      };

    case fromInstalacion.DESACTIVAR_BUSQUEDA:
      return {
        ...state,
        busquedaRealizada: null,
      };

    case fromInstalacion.BORRARDATOSINSTALACION:
      return {
        usuarioInstalacion: null,
        lugarAtencionSeleccionado: null,
        busquedaRealizada: null,
        lugares_atencion: Array<lugarAtencion>() //modificacion Mica

      };

    default:
      return state;
  }
}
