import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DialogsService } from '@services/dialogs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '@services/global.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { IpcService } from '@services/ipc.service';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss'],
})
export class ContactanosComponent implements OnInit {

  // @Output() contactar = new EventEmitter<any>();

  // modalRef: BsModalRef | undefined;

  private subscriptionStore: Subscription = new Subscription();
  emailContacto: string = '';
  domicilioContacto: string = '';
  licenciasContacto = new Set();


  mensaje: string =
    'Estamos para ayudarte:';
  error: any = '';
  submitted: boolean = false;

  form: UntypedFormGroup = new UntypedFormGroup({
    cuit: new UntypedFormControl('', [
      Validators.required,
      blankSpaceValidator,
      Validators.minLength(11),
      Validators.maxLength(11),
    ]),
    direccion: new UntypedFormControl('', [
      Validators.required,
      blankSpaceValidator,
      Validators.maxLength(999),
    ]),
    contexto: new UntypedFormControl('', [
      Validators.required,
      blankSpaceValidator,
      Validators.maxLength(999),
    ]),
    telefono: new UntypedFormControl('', [
      Validators.required,
      blankSpaceValidator,
      Validators.pattern('[0-9]{7,10}'),
    ]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  constructor(private store: Store<AppState>,
    private router: Router,
    public formBuilder: UntypedFormBuilder,
    private dialogsService: DialogsService,
    private ipcService: IpcService,
    private location: Location,
    public globalService:GlobalService
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      console.log(this.router.getCurrentNavigation()?.extras.state);

      let parameters = this.router.getCurrentNavigation()?.extras.state;

      if (parameters?.mensajeError) {
        this.mensaje = parameters.mensajeError;
      }

      if (parameters?.error) {
        this.error = parameters.error;
      }

      if (parameters?.showAlert.showAlert) {
        this.dialogsService.error('', parameters.showAlert.mensajeAlert);
      }
    }
  }

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe((resp) => {
      if (resp.configuracion.usuarioActual) {
        this.emailContacto = resp.configuracion.usuarioActual.email;
        this.domicilioContacto = resp.configuracion.usuarioActual.domicilio;
        if (resp.configuracion && resp.configuracion.datosIniciales) {
          const prestadores = resp.configuracion.datosIniciales.prestadores || [];
          prestadores.forEach(prestador => {
            const financiadores = prestador.financiadores || [];
            financiadores.forEach(financiador => {
              if (financiador.licencia) {
                this.licenciasContacto.add(financiador.licencia);
              }
            });
          });
        }
      }
    });

    console.log('emailContacto', this.emailContacto);
    console.log('domicilioContacto', this.domicilioContacto);
    console.log('licenciasContacto', Array.from(this.licenciasContacto));
  }

  enviarWhatsapp() {
    if (this.emailContacto && this.domicilioContacto && this.licenciasContacto.size > 0) {
      const numeroWhatsapp = '5492617543008';
      const licenciasArray = Array.from(this.licenciasContacto);
      const mensaje = `Hola, quiero hacer una consulta de NUVALID. Mi usuario es ${this.emailContacto} con domicilio en ${this.domicilioContacto}, y mis licencias son ${licenciasArray.join(', ')}`;
      console.log('el mensaje', mensaje);
      this.ipcService.abrirWhatsapp(numeroWhatsapp, mensaje);
    } else {
      // Usuario no está logueado o faltan datos
      const numeroWhatsapp = '5492617543008';
      const mensaje = 'Hola, quiero hacer una consulta de NUVALID.';
      this.ipcService.abrirWhatsapp(numeroWhatsapp, mensaje);
    }
  }

  enviarInformacion() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    //enviar informacion
  }

  volver() {
    // this.router.navigate(['inicio']);
    this.location.back();
  }

  abrirWhatsapp(){
    window.open('https://api.whatsapp.com/send?phone=5492617543008&amp;text=Hola,%20he%20tenido%20un%20problema');
  }

  cerrarModal(){

    this.globalService.cerrarModal();
    this.location.back();
  }




}
