import { Component, OnInit, OnDestroy } from '@angular/core';
import { FinanciadorRequestModel } from '@models/datosInicio.models';
import { Financiador } from '@models/financiador.model';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { setFinanciadorRequest } from '@store/actions';
import { setEstadoFormFinanciadorRequest } from '@store/actions/estadoFormsinicio.action';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-card-socios',
  templateUrl: './card-socios.component.html',
  styleUrls: ['./card-socios.component.scss'],
})
export class CardSociosComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionStoreConfiguracion: Subscription = new Subscription();
  private subscriptionStoreEstadoForms: Subscription = new Subscription();
  cuitFinanciadorSeleccionado: string | null = null;
  financiadores: Array<Financiador> = [];
  prestadores: Array<Prestador> = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.cargarDatos();
    this.store.dispatch(new setEstadoFormFinanciadorRequest(false));
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreConfiguracion.unsubscribe();
  }

  cargarDatos() {
    this.subscriptionStore = this.store
      .select('datosInicio')
      .subscribe((datos) => {
        if (
          this.cuitFinanciadorSeleccionado !=
          datos.FinanciadorRequest?.CuitFinanciador
        ) {
          this.cuitFinanciadorSeleccionado =
            datos.FinanciadorRequest?.CuitFinanciador || null;
        }
      });

    this.subscriptionStoreConfiguracion = this.store
      .select('configuracion')
      .subscribe((datos) => {
        if (datos.datosIniciales && datos.datosIniciales.financiadores) {
          datos.datosIniciales.financiadores.forEach(element => {
            if(element.baja == false && element.sigla != 'PAMI' && element.sigla != 'AMSS')
            // console.log('FINANCIADOR SIGLA', element.sigla);
            this.financiadores.push(element);
          });
        }
        if (datos.datosIniciales && datos.datosIniciales.prestadores) {
          this.prestadores = datos.datosIniciales.prestadores;
        }
      });
      this.subscriptionStoreEstadoForms = this.store.select('datosInicio').subscribe((data) => {
        if (data.FinanciadorRequest?.CuitFinanciador != null || data.FinanciadorRequest?.CodigoFinanciador != null || data.FinanciadorRequest?.SiglaFinanciador != null) {
          this.store.dispatch(new setEstadoFormFinanciadorRequest(true));
        }
      })
  }

  cargarPrestadores(financiador: Financiador) {
    let financiadorSeleccionado = new FinanciadorRequestModel(
      financiador.codigo_empresa,
      financiador.cuit,
      financiador.sigla
    );
    this.store.dispatch(new setFinanciadorRequest(financiadorSeleccionado));
    this.store.dispatch(new setEstadoFormFinanciadorRequest(true));
  }
}
