import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Financiador } from '@models/financiador.model';
import { DatosTicketModel, GetDatosTicket } from '@models/ticket.models';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private subscriptionStore: Subscription = new Subscription();
  headers: HttpHeaders = new HttpHeaders({});
  constructor(private http: HttpClient,
    private store: Store<AppState>,
    private globalService: GlobalService
  ) {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
        } else {
          this.headers = new HttpHeaders({});
        }
      });
  }

  getPrestacionesTransacciones(fechaInicial: string, fechaFinal: string, prestador: string, typeFile: string): Observable<any> {

    let params = {
      FechaInicial: fechaInicial,
      FechaFinal: fechaFinal,
      CuitPrestador: prestador,
      TypeFile: typeFile
    }

    return this.http
      .post(environment.url + '/prestaciones/downloads/FMXFile', params, {
        headers: this.headers,
        responseType: 'blob'
      })
      .pipe(take(1))
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }

  getDatosTickets(
    page: number,
    pageSize: number,
    financiador: string,
    fchDesde: string,
    fchHasta: string,
    prestador: string,
    afiliado: string,
    sFinanciador: string): Observable<GetDatosTicket> {
    return this.http
      .get<GetDatosTicket>(
        environment.url +
        `/ticket-all-filters?page=${page}&size=${pageSize}&financiador=${financiador}&fechaDesde=${fchDesde}&fechaHasta=${fchHasta}&prestador=${prestador}&afiliado=${afiliado}&siglaFinanciador=${sFinanciador}`,
        {
          headers: this.headers,
        }
      )
      .pipe(take(1))
      .pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }

  getFinanciadoresTickets() {
    return this.http
      .get<Array<Financiador>>(
        environment.url + `/ticket/financiadores`,
        {
          headers: this.headers,
        }
      )
      .pipe(take(1))
      .pipe(
        catchError((error) => {
          if (error.status == 401) {
            this.globalService.manageError(error);
          }
          return throwError(error);
        })
      );
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

}
