<div class="container-xxxl" *ngIf="titulo">
    <div class="row justify-content-center p-5">
        <div class="col-12 card p-0 border-0 boxShadow">
            <div class="card-header p-0 bg-navegation rounded-top p-3 text-center pointer" style="color: white;"
                (click)="actualizar()">
                {{titulo}}<fa-icon class="ml-1" [icon]='["fas", "sync"]'></fa-icon>
            </div>
            <div class="card-body rounded p-5">
                <div class="row">
                    <div class="table-responsive mt-2">
                        <table class="table table-striped table-bordered table-sm">
                            <thead style="vertical-align: middle;">
                                <th class="text-center">
                                    <input type="checkbox" id="checkSelectAll" (change)="selectAll($event)">
                                </th>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Email</th>
                                <th class="text-center">Rol</th>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="usuarios && usuarios.length > 0">
                                    <tr *ngFor="let usuario of usuarios" style="vertical-align: middle;">
                                        <td class="text-center">
                                            <input class="checkbox" type="checkbox" id="{{usuario.id}}"
                                                value="{{usuario.id}}">
                                        </td>
                                        <td class="text-center"> <label class="mb-0"
                                                for="{{usuario.id}}">{{usuario.nombre}}</label></td>
                                        <td class="text-center"> <label class="mb-0"
                                                for="{{usuario.id}}">{{usuario.email}}</label>
                                        </td>
                                        <td class="text-center"> <label class="mb-0"
                                                for="{{usuario.id}}">{{usuario.rol}}</label>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="!usuarios || usuarios.length == 0">
                                    <td colspan="4" class="text-center">No hay usuarios para la operación {{titulo}}
                                    </td>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-6 text-left">
                        <button class="buttonImprimirTickets text-left" (click)="irAtras()" [disabled]="cargando">
                            <fa-icon [icon]='["fas", "chevron-left"]' class="mr-1"></fa-icon> Atras
                        </button>
                    </div>
                    <div class="col-6 aceptarRechazarOperacion">
                        <ng-container *ngIf="botonSecundario">
                            <button *ngIf="usuarios && usuarios.length>0" type="submit" [disabled]="cargando"
                                class="buttonAnularTicket" (click)="siguiente(false)">{{botonSecundario}}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="botonPrimario">
                            <button *ngIf="usuarios && usuarios.length>0" type="submit" [disabled]="cargando"
                                class="buttonImprimirTickets" style="margin-left: 10px;" (click)="siguiente(true)">{{botonPrimario}}
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
