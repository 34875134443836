<div id="saveMatriculaModal">
  <div class="" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saveMatriculaModalLabel">Ingrese su matrícula</h5>
      </div>
      <div class="modal-body" style="padding: 3rem 1rem 3rem 1rem;">
          <form [formGroup]="form" class="col form-infoN" style="display: flex; gap: 15px; justify-content: center; flex-direction: column;">
            <div class="form-infoN">
              <label for="matriculaPrescriptor">Matrícula <span *ngIf="form.get('NroMatriculaPrescriptor')"
                class="span-required">*</span></label>
              <input type="text" class="form-controlN" formControlName="NroMatriculaPrescriptor" id="matriculaPrescriptor"
                maxlength="6">
              <label for="provinciaPrescriptor">Provincia <span *ngIf="form.get('ProvinciaPrescriptor')"
                  class="span-required">*</span></label>
              <select class="form-controlN" formControlName="ProvinciaPrescriptor" id="provinciaPrescriptor">
                <option [ngValue]="provinciaPrescriptor.codigo"
                  *ngFor="let provinciaPrescriptor of provinciasPrescriptor">
                  {{provinciaPrescriptor.nombre}}
                </option>
              </select>
            </div>
            <div class="form-infoN">
              <label for="tipoPrescriptor">Tipo <span *ngIf="form.get('TipoPrescriptor')"
                  class="span-required">*</span></label>
              <select class="form-controlN" formControlName="TipoPrescriptor" id="tipoPrescriptor">
                <option [ngValue]="tipoPrescriptor.codigo" *ngFor="let tipoPrescriptor of tiposPrescriptor">
                  {{tipoPrescriptor.nombre}}
                </option>
              </select>
            </div>
          </form>
      </div>
      <div class="modal-footer">
        <button class="buttonBuscarTickets" (click)="guardar()">Guardar</button>
        <button class="buttonAnularTicket" (click)="devolverRespuesta('CERRAR')">Cerrar</button>
      </div>
    </div>
  </div>
</div>
