import * as models from '@store/models/store.models';
import * as filterTickets from '@store/actions/filterTickets.action';

const estadoInicial: models.filterState = {
    FilterTicketsRequest: null,
};

export function filterReducer(
    state = estadoInicial,
    action: filterTickets.filterTicketsActions
): models.filterState {
    switch (action.type) {
        case filterTickets.SETFILTERTICKETSREQUEST:
            return {
                ...state,
                FilterTicketsRequest:
                    action.FilterTicketsRequest,
            };


        default:
            return state;
    }
}
