export class Fechas {
    hoy;
    sumarDias:number = 0;
    
    constructor(
        
    ){
        this.hoy = new Date();
    }
    
    setHoy(hoy: number){
        this.hoy = new Date(hoy);
    }

    setSumarDias(dias: number){
        this.sumarDias = dias;
    }

    get getHoy(){
        return this.hoy;
    }

    get getHoyMili(){
        return this.hoy.getTime();
    }

    get primerDiaMes(){
        let month = '' + (this.hoy.getMonth() + 1);
        let day = '' + (this.hoy.getDate() + this.sumarDias);
        let year = this.hoy.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;

        return month+'/'+'01'+'/'+year;
    }

    get fecha1(){ // yyyyMMdd
        //return this.datePipe.transform(this.hoy, 'yyyyMMdd');
        let month = '' + (this.hoy.getMonth() + 1);
        let day = '' + (this.hoy.getDate() + this.sumarDias);
        let year = this.hoy.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return year+month+day;
    }

    get fecha2(){ // yyyy/MM/dd
        //return this.datePipe.transform(this.hoy, 'dd/MM/yyyy');
        let month = '' + (this.hoy.getMonth() + 1);
        let day = '' + (this.hoy.getDate() + this.sumarDias);
        let year = this.hoy.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return year+'/'+month+'/'+day;
    }

     get fecha3(){ // dd/MM/yyyy
        //return this.datePipe.transform(this.hoy, 'dd/MM/yyyy');
        let month = '' + (this.hoy.getMonth() + 1);
        let day = '' + (this.hoy.getDate() + this.sumarDias);
        let year = this.hoy.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return day+'/'+month+'/'+year;
    }

    get fecha4(){ // MM/dd/yyyy
        //return this.datePipe.transform(this.hoy, 'dd/MM/yyyy');
        let month = '' + (this.hoy.getMonth() + 1);
        let day = '' + (this.hoy.getDate() + this.sumarDias);
        let year = this.hoy.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return month+'/'+day+'/'+year;
    }

    get fecha5(){ // yyyy-MM-dd
        //return this.datePipe.transform(this.hoy, 'dd/MM/yyyy');
        let month = '' + (this.hoy.getMonth() + 1);
        let day = '' + (this.hoy.getDate() + this.sumarDias);
        let year = this.hoy.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return year+'-'+month+'-'+day;
    }

    get fecha6() { //{year: number, month: number, day: number};
        let month = (this.hoy.getMonth() + 1);
        let day = this.hoy.getDate();
        let year = this.hoy.getFullYear();
        
        //console.log('fecha6: ', {year, month, day})
        return {year, month, day};
    }


    fechaPersonal(hoy: Date){
        let hoy1 = new Date(hoy);
        let month = '' + (hoy1.getMonth() + 1);
        let day = '' + hoy1.getDate();
        let year = hoy1.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        /*
        let fecha =  day+'/'+month+'/'+year;

        let hora = this.format_two_digits(''+hoy1.getHours());
        let minuto = this.format_two_digits(''+hoy1.getMinutes());
        let segundo = this.format_two_digits(''+hoy1.getSeconds());
        let horaHMS = hora+':'+minuto+':'+segundo;
        
        return fecha+' '+horaHMS;
        */
        return year+month+day;
    }

    get hora1(){ //000000
        let hora = ('0'+new Date().getHours()).slice(-2);
        let minuto = ('0'+new Date().getMinutes()).slice(-2);
        let segundo = ('0'+new Date().getSeconds()).slice(-2);
        return '' + hora + minuto +segundo;
    }

    get hora2(){ //00:00:00
        let hora = ('0'+new Date().getHours()).slice(-2);
        let minuto = ('0'+new Date().getMinutes()).slice(-2);
        let segundo = ('0'+new Date().getSeconds()).slice(-2);
        return hora+':'+minuto+':'+segundo;
    }

   

    calcularEdadFormat1(birthday: any) { //aaaammdd
        let anio = birthday.substring(0, 4);
        let mesDia = birthday.substring(birthday.length - 4, birthday.length);
        let mes = mesDia.substring(0, 2);
        let dia = mesDia.substring(mesDia.length - 2, mesDia.length);
        return this.calcularEdad(dia+'/'+mes+'/'+anio);
    }

    calcularEdad(birthday: any) { //fecha dd/mm/aaaa
        var birthday_arr = birthday.split("/");
        var birthday_date = new Date(birthday_arr[2], birthday_arr[1] - 1, birthday_arr[0]);
        var ageDifMs = Date.now() - birthday_date.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    
    }
}
