<div class="container__panelPrestacion" (click)="renovarToken()">
  <div class="data__prestacionOpen"></div>

  <!-- RESCATE -->
  <div *ngIf="esRescate && codigoTransaccion === '02A'">
    <app-informar-prestacion-rescate></app-informar-prestacion-rescate>
    <app-datos-prescriptor-request></app-datos-prescriptor-request>
    <div class="containerEfDiag">
      <app-datos-efector-request class="widthContainer"></app-datos-efector-request>
      <app-datos-diagnosticos-request class="widthContainer"></app-datos-diagnosticos-request>
    </div>
  </div>

  <!-- ANULACION -->
  <div class="container-anulacion" *ngIf="codigoTransaccion === '04A'">
    <app-datos-anulacion-request></app-datos-anulacion-request>
  </div>

  <!-- RESCATAR SOLICITUD -->
  <div class="container-anulacion" *ngIf="codigoTransaccion === '03A'">
    <app-datos-preautorizacion-request></app-datos-preautorizacion-request>
  </div>

  <!-- PRESTACION -->
  <div
    *ngIf="(codigoTransaccion === '02A' || codigoTransaccion === '02D' || codigoTransaccion === '02L' || codigoTransaccion === '02C' || codigoTransaccion === '02B' || codigoTransaccion === '01C') && !esRescate"
    class="prestacion__container">
    <div>
      <app-datos-detalle-procedimiento-request-form></app-datos-detalle-procedimiento-request-form>
    </div>
    <div class="prestacion__cuadroCarga">
      <app-datos-detalle-procedimiento-request></app-datos-detalle-procedimiento-request>
    </div>
  </div>

  <!-- INFORMAR DIAGNÓSTICO -->
  <div *ngIf="codigoTransaccion === '02Z'" class="prestacion__container">
    <div>
      <app-datos-informar-diagnostico-request></app-datos-informar-diagnostico-request>
    </div>
  </div>

  <!-- ENVIO DE PROTOCOLO QUIRURGICO -->
  <div *ngIf="codigoTransaccion === '02P'" class="prestacion__container">
    <div>
      <app-datos-prot-quirurgico-request></app-datos-prot-quirurgico-request>
    </div>
  </div>

  <!-- SOLICITAR AUTORIZACIÓN CIRUGÍA -->
  <div *ngIf="codigoTransaccion === '02S'" class="prestacion__container">
    <div>
      <app-datos-autorizacion-cirugia-request></app-datos-autorizacion-cirugia-request>
    </div>
    <div class="prestacion__cuadroCarga">
      <app-datos-detalle-procedimiento-request></app-datos-detalle-procedimiento-request>
    </div>
  </div>

  <!-- INFORMAR CIRUGÍA REALIZADA -->
  <div *ngIf="codigoTransaccion === '02Q'" class="prestacion__container">
    <div>
      <app-datos-informar-cirugia-request></app-datos-informar-cirugia-request>
    </div>
    <div class="prestacion__cuadroCarga">
      <app-datos-detalle-procedimiento-request></app-datos-detalle-procedimiento-request>
    </div>
  </div>

  <!-- ALTA INTERNACIÓN -->
  <div *ngIf="codigoTransaccion === '02E'" class="prestacion__container">
    <app-datos-alta-internacion-request></app-datos-alta-internacion-request>
  </div>

  <!-- INFORMAR INTERNACIÓN -->
  <div *ngIf="codigoTransaccion === '02I'" class="prestacion__container">
    <app-datos-informar-internacion-request></app-datos-informar-internacion-request>
  </div>

  <!-- PRESCRIPTOR -->
  <app-datos-prescriptor-request
    *ngIf="(codigoTransaccion === '02A' || codigoTransaccion === '02D' || codigoTransaccion === '02L' || codigoTransaccion === '02C' || codigoTransaccion === '01C') && !esRescate"></app-datos-prescriptor-request>

  <div class="containerEfDiag">
    <!-- EFECTOR -->
    <app-datos-efector-request class="widthContainer"
      *ngIf="(codigoTransaccion === '02A' || codigoTransaccion === '02D' || codigoTransaccion === '02L' || codigoTransaccion === '02Q' || codigoTransaccion === '02S' || codigoTransaccion === '01C') && !esRescate"></app-datos-efector-request>
    <!-- DIAGNOSTICO -->
    <app-datos-diagnosticos-request class="widthContainer"
      *ngIf="(codigoTransaccion === '02A' || codigoTransaccion === '02D' || codigoTransaccion === '02E' || codigoTransaccion === '02I' || codigoTransaccion === '01C') && !esRescate"></app-datos-diagnosticos-request>
  </div>

  <!-- DERIVACION -->
  <app-datos-derivacion-request *ngIf="codigoTransaccion === '02B'"></app-datos-derivacion-request>

  <!-- RESCATE RECETAS -->
  <app-datos-rescate-receta *ngIf="codigoTransaccion === '03S'"></app-datos-rescate-receta>
</div>
