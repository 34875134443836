<div class="modal-body">

  <div class="text-center">
    <p class="text-muted">Dibuja la firma usando el mouse o una pantalla táctil. Se recomienda utilizar "pantalla
      táctil".</p>
    <canvas #firmaDigital width="500" height="200"
      style="touch-action: none; border:1px solid #a3a3a3; border-radius: 15px;"></canvas>
  </div>
  <div class="modal-footer">
    <div>
      <button class="buttonImprimirTickets" (click)="guardarFirma()" type="button">Firmar</button>
    </div>
    <div>
      <button class="buttonImprimirTickets" (click)="limpiarFirma()" type="button">Limpiar</button>
    </div>
    <button class="buttonAnularTicket" (click)="closeModal()" type="button">Cerrar</button>
  </div>
</div>
