import * as models from '@store/models/store.models';
import * as fromUI from '@store/actions/ui.actions';

const estadoInicial: models.UIState = {
  loading: false,
  coordenadas: new google.maps.LatLng(-38.416097, -63.616672),
  indexDetalleProcediminetoAEditar: null,
};

export function uiReducer(
  state = estadoInicial,
  action: fromUI.uiActions
): models.UIState {
  switch (action.type) {
    case fromUI.SETINDEXDETALLEPROCEDIMINETOAEDITAR:
      return {
        ...state,
        indexDetalleProcediminetoAEditar:
          action.indexDetalleProcediminetoAEditar,
      };

    case fromUI.UNSETINDEXDETALLEPROCEDIMINETOAEDITAR:
      return {
        ...state,
        indexDetalleProcediminetoAEditar: null,
      };
    case fromUI.ACTIVAR_LOADING:
      return {
        ...state,
        loading: true,
      };

    case fromUI.DESACTIVAR_LOADING:
      return {
        ...state,
        loading: false,
      };

    case fromUI.SET_COORDENADAS:
      return {
        ...state,
        coordenadas: action.coordenadas,
      };

    case fromUI.BORRARDATOSUI:
      return estadoInicial;

    default:
      return state;
  }
}
