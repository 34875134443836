import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { usuarioEmail } from '@models/global.models';
import { LoginModel, UsuarioActualModel } from '@models/login.models';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PersistenciaService } from '../../services/persistencia.service';

@Component({
  selector: 'app-input-login',
  templateUrl: './input-login.component.html',
  styleUrls: ['./input-login.component.scss']
})
export class InputLoginComponent implements OnInit {

  model: UsuarioActualModel | null = null;
  // model: any;
  searching = false;
  searchFailed = false;
  @Output()
  username: EventEmitter<any> = new EventEmitter();

  constructor(
    private persistenciaService: PersistenciaService
  ) { }

  ngOnInit(): void {
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    // distinctUntilChanged(),
    map(term => term.length < 2 ? []
      : this.persistenciaService.getDatosUsuarioActual()!.filter(x => (x.email.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
  );

  formatter = (x: any) => `${x.email}`;

  submit(e: any) {
    if (this.model?.email) {
      this.username.emit(this.model);
      console.log('emito el email', this.model?.email);
    } else {
      this.username.emit(null);
    }
  }

  clean() {
    this.model = null;
  }

}
