import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ConfiguracionState } from '@store/models/store.models';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
import { DialogsService } from '@services/dialogs.service';
import { UsuarioActualModel } from '@models/login.models';
import { EncryptionService } from '../../services/encryption.service';

@Component({
  selector: 'app-turnea',
  templateUrl: './turnea.component.html',
  styleUrls: ['./turnea.component.scss']
})
export class TurneaComponent implements OnInit, AfterViewInit{
  url!:SafeResourceUrl;
  private subscriptionStore: Subscription = new Subscription();
  @ViewChild('miFrame') miFrame!: ElementRef;
  esTurnea: boolean = false;

  constructor(
    private store: Store<AppState>,
    private sanitizer: DomSanitizer,
    private dialogsService: DialogsService,
    private encryptionService: EncryptionService
  ) {

    this.subscriptionStore = this.store
    .select('configuracion')
    .subscribe((configuracionState: ConfiguracionState) => {
      let param:any = {
        token: configuracionState!.usuarioActual!.token,
        name: configuracionState!.usuarioActual!.nombre, //"Fernando",
        lastName: configuracionState!.usuarioActual!.apellido, //"Gonzalez",
        email: configuracionState!.usuarioActual!.email, //"fernandog@itcsoluciones.com",
        codeInstitution: configuracionState!.usuarioActual!.codigoInstalacion, //"ytQxSADLiOyMy0A",
        address: configuracionState!.usuarioActual!.domicilio //"MONTEAGUDO 716"
      }
      let paramEncryp = this.encryptionService.encrypt(JSON.stringify(param));
      //console.log('param decode: ', JSON.parse(this.encryptionService.decrypt(paramEncryp)));
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.urlturnea +'#'+ paramEncryp);
      this.esTurnea = configuracionState!.usuarioActual!.esTurnea;
    });
  }

  ngAfterViewInit() {
    // Obtén una referencia al elemento <iframe> utilizando ViewChild
    const iframeElement = this.miFrame.nativeElement as HTMLIFrameElement;

    // Agrega un evento "onload" al <iframe>
    iframeElement.onload = () => {
      // El contenido del <iframe> se ha cargado completamente
      // Ahora puedes realizar acciones como mostrar el contenido o ejecutar funciones adicionales.
      //this.dialogsService.cerrar();
    };
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();

  }
}
