import { Matricula } from './prestador.model';
export class Item {
  cod_troquel: string;
  descripcion: string;
  estado: string | null;
  item_nro: string;
  cod_barras?: string;

  cod_alfabeta?: string; // Objeto nuevo de prescripcion
  cod_snomed?: string;   // Objeto nuevo de prescripcion
  justificado?: boolean; // Objeto nuevo de prescripcion
  justificado_descripcion?: string; // Objeto nuevo de prescripcion
  constructor(
    cod_troquel: string,
    descripcion: string,
    estado: string | null,
    item_nro: string,
    cod_barras?: string,

    cod_alfabeta?: string, // Objeto nuevo de prescripcion
    cod_snomed?: string,   // Objeto nuevo de prescripcion
    justificado?: boolean, // Objeto nuevo de prescripcion
    justificado_descripcion?: string, // Objeto nuevo de prescripcion
  ) {

    this.cod_barras = cod_barras;
    this.cod_troquel = cod_troquel;
    this.descripcion = descripcion;
    this.estado = estado;
    this.item_nro = item_nro;

    this.cod_alfabeta = cod_alfabeta; // Objeto nuevo de prescripcion
    this.cod_snomed = cod_snomed;     // Objeto nuevo de prescripcion
    this.justificado = justificado;   // Objeto nuevo de prescripcion
    this.justificado_descripcion = justificado_descripcion; // Objeto nuevo de prescripcion
  }
}

export class DetalleMedicamentosRequestModel {
  cantidad_prescripta: string;
  droga_codigo: string;
  droga_descripcion: string;
  generico_presentacion: string;
  unidades: string;
  items: Array<Item>;
  nro_linea: string;
  laboratorio: string;
  accion: string;
  posologia?: string;
  precauciones?: string;

  constructor(
    cantidad_prescripta: string,
    droga_codigo: string,
    droga_descripcion: string,
    generico_presentacion: string,
    unidades: string,
    items: Array<Item>,
    nro_linea: string,
    laboratorio: string,
    accion: string,
    posologia?: string,
    precauciones?: string,


  ) {
    this.cantidad_prescripta = cantidad_prescripta;
    this.droga_codigo = droga_codigo;
    this.droga_descripcion = droga_descripcion;
    this.generico_presentacion = generico_presentacion;
    this.unidades = unidades;
    this.items = items;
    this.nro_linea = nro_linea;
    this.laboratorio = laboratorio;
    this.accion = accion;
    this.posologia = posologia;
    this.precauciones = precauciones;
  }
}

export class DetalleMedicamentosRequestModelOLD {
  CantidadSolicitada: string;
  CodBarras: string;
  CodMonodroga: string;
  CodProveedor: string;
  FechaFinTratamiento: string;
  FechaInicioTratamiento: string;
  Instrucciones: string;
  IntervaloDispensa: string;
  NroItem: string;
  NumeroPrescripcion: string;
  PorcentajeCobertura: string;
  TipoAutorizacion: string;
  Troquel: string;
  Unidades: string;
  UnidadesDispensa: string;

  constructor(
    CantidadSolicitada: string,
    CodBarras: string,
    CodMonodroga: string,
    CodProveedor: string,
    DechaFinTratamiento: string,
    DechaInicioTratamiento: string,
    Instrucciones: string,
    IntervaloDispensa: string,
    NroItem: string,
    NumeroPrescripcion: string,
    PorcentajeCobertura: string,
    TipoAutorizacion: string,
    Troquel: string,
    Unidades: string,
    UnidadesDispensa: string
  ) {
    this.CantidadSolicitada = CantidadSolicitada;
    this.CodBarras = CodBarras;
    this.CodMonodroga = CodMonodroga;
    this.CodProveedor = CodProveedor;
    this.FechaFinTratamiento = DechaFinTratamiento;
    this.FechaInicioTratamiento = DechaInicioTratamiento;
    this.Instrucciones = Instrucciones;
    this.IntervaloDispensa = IntervaloDispensa;
    this.NroItem = NroItem;
    this.NumeroPrescripcion = NumeroPrescripcion;
    this.PorcentajeCobertura = PorcentajeCobertura;
    this.TipoAutorizacion = TipoAutorizacion;
    this.Troquel = Troquel;
    this.Unidades = Unidades;
    this.UnidadesDispensa = UnidadesDispensa;
  }
}

export class DetalleProcedimientoRequestModel {
  NroItem: string;
  CodPrestacion: string;
  CodAlternativo: string;
  PiezaDental: string;
  CaraDental: string;
  TipoPrestacion: string;
  ArancelPrestacion: string;
  CantidadSolicitada: string;
  CodDiagnosticoPrestacion: string;
  CodRta: string;
  DescripcionPrestacion: string;
  Facturacion: string;

  constructor(
    NroItem: string,
    CodPrestacion: string,
    CodAlternativo: string,
    PiezaDental: string,
    CaraDental: string,
    TipoPrestacion: string,
    ArancelPrestacion: string,
    CantidadSolicitada: string,
    CodDiagnosticoPrestacion: string,
    CodRta: string,
    DescripcionPrestacion: string,
    Facturacion: string
  ) {
    this.NroItem = NroItem;
    this.CodPrestacion = CodPrestacion;
    this.CodAlternativo = CodAlternativo;
    this.PiezaDental = PiezaDental;
    this.CaraDental = CaraDental;
    this.TipoPrestacion = TipoPrestacion;
    this.ArancelPrestacion = ArancelPrestacion;
    this.CantidadSolicitada = CantidadSolicitada;
    this.CodDiagnosticoPrestacion = CodDiagnosticoPrestacion;
    this.CodRta = CodRta;
    this.DescripcionPrestacion = DescripcionPrestacion;
    this.Facturacion = Facturacion;
  }
}

export class DetalleEstudiosRequestModel {
  determinacion: string;
  grupo: string;
  nbu: string;

  constructor(
    determinacion: string,
    grupo: string,
    nbu: string,
  ) {
    this.determinacion = determinacion;
    this.grupo = grupo;
    this.nbu = nbu;
  }
}

export class AltaInternacionRequestModel {
  NroTicket: string | null;

  constructor(NroTicket: string | null) {
    this.NroTicket = NroTicket;
  }
}
export class AcompannanteRequestModel {
  ApellidoAcompannante: string | null;
  NombreAcompannante: string | null;
  NroDocAcompannante: string | null;
  NroTelefono: string | null;
  TipoDocAcompannante: string | null;

  constructor(
    ApellidoAcompannante: string | null,
    NombreAcompannante: string | null,
    NroDocAcompannante: string | null,
    NroTelefono: string | null,
    TipoDocAcompannante: string | null
  ) {
    this.ApellidoAcompannante = ApellidoAcompannante;
    this.NombreAcompannante = NombreAcompannante;
    this.NroDocAcompannante = NroDocAcompannante;
    this.NroTelefono = NroTelefono;
    this.TipoDocAcompannante = TipoDocAcompannante;
  }
}

export class AnulacionRequestModel {
  NroReferenciaCancelRequest: string | null;

  constructor(
    NroReferenciaCancelRequest: string | null
  ) {
    this.NroReferenciaCancelRequest = NroReferenciaCancelRequest;
  }
}
export class NroReferenciaRequestModel {
  NroReferenciaRequest: string | null;

  constructor(NroReferenciaRequest: string | null) {
    this.NroReferenciaRequest = NroReferenciaRequest;
  }
}

export class AtencionRequestModel {
  Cama: string | null;
  FechaAtencion: String | null;
  HoraAtencion: Date | null;
  Sector: string | null;
  TipoAtencion: string | null;

  constructor(
    Cama: string | null,
    FechaAtencion: String | null,
    HoraAtencion: Date | null,
    Sector: string | null,
    TipoAtencion: string | null
  ) {
    this.Cama = Cama;
    this.FechaAtencion = FechaAtencion;
    this.HoraAtencion = HoraAtencion;
    this.Sector = Sector;
    this.TipoAtencion = TipoAtencion;
  }
}

export class BeneficiarioRequestModel {
  ApellidoBeneficiario: string | null;
  Direccion: string | null;
  Email: string | null;
  NombreBeneficiario: string | null;
  NroDocBeneficiario: string | null;
  Parentesco: string | null;
  Telefono: string | null;
  TipoDocBeneficiario: string | null;
  Sexo?: string | null;
  FechaNacimiento?: string | null;
  PlanCredencial?: string | null;

  constructor(
    ApellidoBeneficiario: string | null,
    Direccion: string | null,
    Email: string | null,
    NombreBeneficiario: string | null,
    NroDocBeneficiario: string | null,
    Parentesco: string | null,
    Telefono: string | null,
    TipoDocBeneficiario: string | null,
    Sexo?: string | null,
    FechaNacimiento?: string | null,
    PlanCredencial?: string | null,
  ) {
    this.ApellidoBeneficiario = ApellidoBeneficiario;
    this.Direccion = Direccion;
    this.Email = Email;
    this.NombreBeneficiario = NombreBeneficiario;
    this.NroDocBeneficiario = NroDocBeneficiario;
    this.Parentesco = Parentesco;
    this.Telefono = Telefono;
    this.TipoDocBeneficiario = TipoDocBeneficiario;
    this.Sexo = Sexo;
    this.FechaNacimiento = FechaNacimiento;
    this.PlanCredencial = PlanCredencial;

  }
}
export class DerivacionRequestModel {
  ApellidoDerivacion: string;
  CodParaFinanciadorDerivacion: string;
  CuitDerivacion: string;
  DireccionDerivacion: string;
  EspecialidadDerivacion: string;
  NombreDerivacion: string;
  NroMatriculaDerivacion: string;
  ProvinciaDerivacion: string;
  TipoPrescriptorDerivacion: string;

  constructor(
    ApellidoDerivacion: string,
    CodParaFinanciadorDerivacion: string,
    CuitDerivacion: string,
    DireccionDerivacion: string,
    EspecialidadDerivacion: string,
    NombreDerivacion: string,
    NroMatriculaDerivacion: string,
    ProvinciaDerivacion: string,
    TipoPrescriptorDerivacion: string
  ) {
    this.ApellidoDerivacion = ApellidoDerivacion;
    this.CodParaFinanciadorDerivacion = CodParaFinanciadorDerivacion;
    this.CuitDerivacion = CuitDerivacion;
    this.DireccionDerivacion = DireccionDerivacion;
    this.EspecialidadDerivacion = EspecialidadDerivacion;
    this.NombreDerivacion = NombreDerivacion;
    this.NroMatriculaDerivacion = NroMatriculaDerivacion;
    this.ProvinciaDerivacion = ProvinciaDerivacion;
    this.TipoPrescriptorDerivacion = TipoPrescriptorDerivacion;
  }
}

export class DiagnosticosRequestModel {
  CodDiagnostico: string;
  DescripcionDiagnostico: string;
  Nomenclador: string;

  constructor(
    CodDiagnostico: string,
    DescripcionDiagnostico: string,
    Nomenclador: string
  ) {
    this.CodDiagnostico = CodDiagnostico;
    this.DescripcionDiagnostico = DescripcionDiagnostico;
    this.Nomenclador = 'CIE-10';
  }
}

export class InformarCirugiaRequestModel {
  NroItem: string;
  CodPrestacion: string;
  CodAlternativo: string;
  PiezaDental: string;
  CaraDental: string;
  TipoPrestacion: string;
  ArancelPrestacion: string;
  CantidadSolicitada: string;
  CodDiagnosticoPrestacion: string;
  CodRta: string;
  DescripcionPrestacion: string;
  Facturacion: string;
  OrdenPractica: string;

  constructor(
    NroItem: string,
    CodPrestacion: string,
    CodAlternativo: string,
    PiezaDental: string,
    CaraDental: string,
    TipoPrestacion: string,
    ArancelPrestacion: string,
    CantidadSolicitada: string,
    CodDiagnosticoPrestacion: string,
    CodRta: string,
    DescripcionPrestacion: string,
    Facturacion: string,
    OrdenPractica: string
  ) {
    this.NroItem = NroItem;
    this.CodPrestacion = CodPrestacion;
    this.CodAlternativo = CodAlternativo;
    this.PiezaDental = PiezaDental;
    this.CaraDental = CaraDental;
    this.TipoPrestacion = TipoPrestacion;
    this.ArancelPrestacion = ArancelPrestacion;
    this.CantidadSolicitada = CantidadSolicitada;
    this.CodDiagnosticoPrestacion = CodDiagnosticoPrestacion;
    this.CodRta = CodRta;
    this.DescripcionPrestacion = DescripcionPrestacion;
    this.Facturacion = Facturacion;
    this.OrdenPractica = OrdenPractica;
  }
}

export class DocumentacionRequestModel {
  NombreArchivo: string;
  TipoArchivo: string;
  OrigenArchivo?: string;

  constructor(
    NombreArchivo: string,
    TipoArchivo: string,
    OrigenArchivo?: string
  ) {
    this.NombreArchivo = NombreArchivo;
    this.TipoArchivo = TipoArchivo;
    this.OrigenArchivo = OrigenArchivo;
  }
}


export class EfectorRequestModel {
  ApellidoEfector: string;
  CuitEfector: string;
  Direccion: string;
  Email: string;
  EspecialidadEfector: string;
  NombreEfector: string;
  NroMatriculaEfector: string;
  ProvinciaEfector: string;
  TipoMatriculaEfector: string;

  constructor(
    ApellidoEfector: string,
    CuitEfector: string,
    Direccion: string,
    Email: string,
    EspecialidadEfector: string,
    NombreEfector: string,
    NroMatriculaEfector: string,
    ProvinciaEfector: string,
    TipoMatriculaEfector: string
  ) {
    this.ApellidoEfector = ApellidoEfector;
    this.CuitEfector = CuitEfector;
    this.Direccion = Direccion;
    this.Email = Email;
    this.EspecialidadEfector = EspecialidadEfector;
    this.NombreEfector = NombreEfector;
    this.NroMatriculaEfector = NroMatriculaEfector;
    this.ProvinciaEfector = ProvinciaEfector;
    this.TipoMatriculaEfector = TipoMatriculaEfector;
  }
}
export class PreautorizacionRequestModel {
  CodigoPreautorizacion: string;
  FechaPreautorizacion: string;

  constructor(CodigoPreautorizacion: string, FechaPreautorizacion: string) {
    this.CodigoPreautorizacion = CodigoPreautorizacion;
    this.FechaPreautorizacion = FechaPreautorizacion;
  }
}

export class PrescripcionRequestModel {
  Intrucciones: string;
  Numero: string;
  SolicitaAtencionDomiliciaria: string;

  constructor(
    Intrucciones: string,
    Numero: string,
    SolicitaAtencionDomiliciaria: string
  ) {
    this.Intrucciones = Intrucciones;
    this.Numero = Numero;
    this.SolicitaAtencionDomiliciaria = SolicitaAtencionDomiliciaria;
  }
}

export class PrescriptorRequestModel {
  ApellidoPrescriptor: string;
  CuitPrescriptor: string;
  EspecialidadPrescriptor: string;
  FechaReceta: Date;
  NombrePrescriptor: string;
  NroMatriculaPrescriptor: string;
  ProvinciaPrescriptor: string;
  TipoPrescriptor: string;

  constructor(
    ApellidoPrescriptor: string,
    CuitPrescriptor: string,
    EspecialidadPrescriptor: string,
    FechaReceta: Date,
    NombrePrescriptor: string,
    NroMatriculaPrescriptor: string,
    ProvinciaPrescriptor: string,
    TipoPrescriptor: string
  ) {
    this.ApellidoPrescriptor = ApellidoPrescriptor;
    this.CuitPrescriptor = CuitPrescriptor;
    this.EspecialidadPrescriptor = EspecialidadPrescriptor;
    this.FechaReceta = FechaReceta;
    this.NombrePrescriptor = NombrePrescriptor;
    this.NroMatriculaPrescriptor = NroMatriculaPrescriptor;
    this.ProvinciaPrescriptor = ProvinciaPrescriptor;
    this.TipoPrescriptor = TipoPrescriptor;
  }
}

export class InicioTrxRequestModel {
  FechaTrx: string;
  HoraTrx: string;

  constructor(FechaTrx: string, HoraTrx: string) {
    this.FechaTrx = FechaTrx;
    this.HoraTrx = HoraTrx;
  }
}

export class SoftwareRequestModel {
  NombreSoftware: string;
  VersionSoftware: string;

  constructor(NombreSoftware: string, VersionSoftware: string) {
    this.NombreSoftware = NombreSoftware;
    this.VersionSoftware = VersionSoftware;
  }
}

export class TerminalRequestModel {
  Latitud: string;
  Longitud: string;
  NumeroTerminal: string;
  Telefono: string;
  TipoTerminal: string;

  constructor(
    Latitud: string,
    Longitud: string,
    NumeroTerminal: string,
    Telefono: string,
    TipoTerminal: string
  ) {
    this.Latitud = Latitud;
    this.Longitud = Longitud;
    this.NumeroTerminal = NumeroTerminal;
    this.Telefono = Telefono;
    this.TipoTerminal = TipoTerminal;
  }
}

export class ExtrasDispensa {
  firma_olografa?: string | '';
  estado?: string | 'S';
  fecha_receta?: string | null;
  fecha_dispensa_fin?: string | null;
  multi_dispensa?: string | '0';
  prescriptor_copia?: string | '0';
  justifica?: string | '0';
  diagnostico?: string | '';
  matricula?: Matricula | null;
  tratamiento_prolongado?: string | '0';
  periodo_autorizado?: string | '';
  numero_copia?: string | '';
  beneficiario_email?: string | '';
  constructor(
    firma_olografa: string | '',
    estado: string | 'S',
    fecha_receta: string | null,
    fecha_dispensa_fin: string | null,
    multi_dispensa: string | '0',
    prescriptor_copia: string | '0',
    justifica: string | '0',
    diagnostico: string | '',
    matricula: Matricula | null,
    tratamiento_prolongado?: string | '0',
    periodo_autorizado?: string | '',
    numero_copia?: string | '',
    beneficiario_email?: string | ''
  ) {
    this.firma_olografa = firma_olografa;
    this.estado = estado;
    this.fecha_receta = fecha_receta;
    this.fecha_dispensa_fin = fecha_dispensa_fin;
    this.multi_dispensa = multi_dispensa;
    this.prescriptor_copia = prescriptor_copia;
    this.justifica = justifica;
    this.diagnostico = diagnostico;
    this.matricula = matricula;
    this.tratamiento_prolongado = tratamiento_prolongado;
    this.periodo_autorizado = periodo_autorizado;
    this.numero_copia = numero_copia;
    this.beneficiario_email = beneficiario_email
  }
}

export class Dispensa {

  numero_copia?: string | null; // Objeto nuevo de prescripcion
  periodo_autorizado?: string | null; // Objeto nuevo de prescripcion
  tratamiento_prolongado?: number | 0; // Objeto nuevo de prescripcion

  firma_olografa: string | null;
  diagnostico: string | null;
  beneficiario_nombre: string | null;
  beneficiario_apellido: string | null;
  beneficiario_nro_doc?: string | null;
  beneficiario_sexo?: string | null;
  beneficiario_fecha_nac?: string | null;
  beneficiario_plan: string | null;
  beneficiario_email?: string | null;
  beneficiario_dni: string | null;
  credencial_numero: string | null;
  estado: string | null;
  fecha_receta: string | null;
  fecha_dispensa_fin: string | null;
  multi_dispensa: number | 0;
  financiador_cuit: string | null;
  prescriptor_nombre: string | null;
  prescriptor_apellido: string | null;
  prescriptor_nro_matricula: string | null;
  prescriptor_tipo_matricula?: string | null;
  prescriptor_provincia?: string | null;
  prescriptor_email?: string | null;
  prescriptor_copia: number | 0;
  prescriptor_domicilio: string | null;
  prescriptor_telefono: string | null;
  prescriptor_especialidad_descripcion: string | null;
  prescriptor_cuit: string | null;
  prescriptor_tipo_prescriptor: string | null;
  location: {
    lat: string | null,
    lng: string | null
  };
  prescripcion: Prescripcion | null;
  constructor(
    firma_olografa: string | null,
    diagnostico: string | null,
    beneficiario_nombre: string | null,
    beneficiario_apellido: string | null,
    beneficiario_plan: string | null,
    beneficiario_dni: string | null,
    credencial_numero: string | null,
    estado: string | null,
    fecha_receta: string | null,
    fecha_dispensa_fin: string | null,
    multi_dispensa: number | 0,
    financiador_cuit: string | null,
    prescriptor_nombre: string | null,
    prescriptor_apellido: string | null,
    prescriptor_nro_matricula: string | null,
    prescriptor_tipo_matricula: string | null,
    prescriptor_copia: number | 0,
    prescriptor_domicilio: string | null,
    prescriptor_telefono: string | null,
    prescriptor_especialidad_descripcion: string | null,
    prescriptor_cuit: string | null,
    prescriptor_tipo_prescriptor: string | null,
    location: {
      lat: string | null,
      lng: string | null
    },
    prescripcion: Prescripcion | null,

    beneficiario_nro_doc?: string | null,
    beneficiario_sexo?: string | null,
    beneficiario_fecha_nac?: string | null,
    beneficiario_email?: string | null,
    prescriptor_provincia?: string | null,
    prescriptor_email?: string | null,
    numero_copia?: string | null, // Objeto nuevo de prescripcion
    periodo_autorizado?: string | null, // Objeto nuevo de prescripcion
  ) {
    this.firma_olografa = firma_olografa;
    this.diagnostico = diagnostico;
    this.beneficiario_nombre = beneficiario_nombre;
    this.beneficiario_apellido = beneficiario_apellido;
    this.beneficiario_nro_doc = beneficiario_nro_doc;
    this.beneficiario_sexo = beneficiario_sexo;
    this.beneficiario_fecha_nac = beneficiario_fecha_nac;
    this.beneficiario_plan = beneficiario_plan;
    this.beneficiario_email = beneficiario_email;
    this.beneficiario_dni = beneficiario_dni;
    this.credencial_numero = credencial_numero;
    this.estado = estado;
    this.fecha_receta = fecha_receta;
    this.fecha_dispensa_fin = fecha_dispensa_fin;
    this.multi_dispensa = multi_dispensa;
    this.financiador_cuit = financiador_cuit;
    this.prescriptor_nombre = prescriptor_nombre;
    this.prescriptor_apellido = prescriptor_apellido;
    this.prescriptor_nro_matricula = prescriptor_nro_matricula;
    this.prescriptor_tipo_matricula = prescriptor_tipo_matricula;
    this.prescriptor_provincia = prescriptor_provincia;
    this.prescriptor_email = prescriptor_email;
    this.prescriptor_copia = prescriptor_copia;
    this.prescriptor_domicilio = prescriptor_domicilio;
    this.prescriptor_telefono = prescriptor_telefono;
    this.prescriptor_especialidad_descripcion = prescriptor_especialidad_descripcion;
    this.prescriptor_cuit = prescriptor_cuit;
    this.prescriptor_tipo_prescriptor = prescriptor_tipo_prescriptor;
    this.location = location;
    this.prescripcion = prescripcion;

    this.numero_copia = numero_copia // Objeto nuevo de prescripcion
    this.periodo_autorizado = periodo_autorizado// Objeto nuevo de prescripcion
  }
}

export class Prescripcion {
  referencias_rx?: DetalleMedicamentosRequestModel[];
  determinaciones?: DetalleEstudiosRequestModel[];
  certificado?: string;
  diagnostico_img?: any;
  constructor(
    referencias_rx?: DetalleMedicamentosRequestModel[],
    determinaciones?: DetalleEstudiosRequestModel[],
    certificado?: string,
    diagnostico_img?: any,
  ) {
    this.referencias_rx = referencias_rx;
    this.determinaciones = determinaciones;
    this.certificado = certificado;
    this.diagnostico_img = diagnostico_img;
  }
}
