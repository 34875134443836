<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../sass/estilos.scss"> -->
</head>
<div class="container__panel1">
    <div class="container__afiliado">
        <form class="form-infoN">

            <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.nombre" [term]="t"></ngb-highlight>,
                <ngb-highlight [result]="r.apellido" [term]="t">
                </ngb-highlight> | {{r.nombreFinanciador}}
            </ng-template>

            <div class="containerInputA">
                <label for="typeahead-http" class="labelAgenda">Busque por agenda</label>
                <input style=" width: 80%; height: 2em;" id="typeahead-http" type="text" class="form-controlN"
                    [class.is-invalid]="searchFailed" [(ngModel)]="model" (selectItem)="submit($event)"
                    [ngbTypeahead]="search" placeholder="Nombre/DNI de afiliado" [resultTemplate]="rt"
                    [inputFormatter]="formatter" [editable]='false' [ngModelOptions]="{standalone: true}"
                    [ngClass]="{'form-controlNError': searchFailed}" />
                <!--agregue estilo, ver diana-->
                <!-- <small *ngIf="searching" class="form-text text-muted">Buscando...</small> -->
                <!-- <div class="invalid-feedback" *ngIf="searchFailed">No se encontro ningun afiliado.</div> -->
            </div>
        </form>


        <ng-container *ngIf="searchFailed; then reminderError else reminderOk">
        </ng-container>

        <ng-template #reminderOk>
            <div class="reminder reminder_afiliado ">
                <div><img src="./assets/design/warning.svg" alt="danger" id="warning.svg__img">
                </div>
                <div>
                    <p class="reminder__text reminder__textAfiliado ">
                        Si no encuentra al afiliado por su DNI es porque primero debe agendarlo, puede hacerlo
                        haciendo<a href="#" target="_blank" class="reminder__link">
                            click aquí.</a>
                    </p>
                </div>
            </div>
        </ng-template>

        <ng-template #reminderError>
            <div class="reminder reminder__alertA">
                <div>
                    <img src="./assets/design/alert.svg" alt="alert" id="alert.svg__img">
                </div>
                <p class="reminder__text reminder__textAlert reminder__textAlertAfiliado">
                    No se encontró ningun afiliado. Puede comunicarse con nuestro centro de soporte haciendo <a
                        target="_blank" class="reminder__link" (click)="contacto()">
                        click aquí.</a>
                </p>
            </div>
        </ng-template>

    </div>
</div>