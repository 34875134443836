<!-- Fecha -->
<div class="container-prestacion">
    <div class="container-prestacion2">
        <app-datos-atencion-request></app-datos-atencion-request>
    </div>
</div>

<div class="container-prestacion2">
    <div style="width: 100%; padding-right: 1em;">
        <div class="row">

            <div class="col">
                <!-- Nro de ticket -->
                <app-datos-nro-referencia-request [nOrden]="true"></app-datos-nro-referencia-request>
            </div>
            <!-- <div class="col" style="margin-top: 15px;"> -->
                <!-- Select cod tratamiento -->
                <!-- <app-datos-cod-finalizacion-tratamiento></app-datos-cod-finalizacion-tratamiento> -->
            <!-- </div> -->
        </div>
    </div>
</div>

<div class="container-prestacion">
    <div class="container-prestacion2">
        <app-acompannante-request></app-acompannante-request>
    </div>
</div>
