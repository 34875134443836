import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { codigoInstalacion, redirectClass } from '@models/global.models';
import { LoginModel, UsuarioActualModel } from '@models/login.models';
import { DataUsuario } from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { errorGenerico } from 'errores';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogsService } from './dialogs.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private dialogsService: DialogsService,
    private globalService: GlobalService,
    private store: Store<AppState>
  ) {}

  login(infoLogin: LoginModel): Observable<UsuarioActualModel> {
    return this.http
      .post<UsuarioActualModel>(environment.url + '/usuarios/signin', infoLogin)
      .pipe(take(1));
  }

  recuperarContrasenna(email: string): Observable<any> {
    let body = { email: email };
    return this.http
      .post<string>(environment.url + '/usuarios/forgot_password', body, {
        responseType: 'json',
      })
      .pipe(take(1));
  }

  crearCuentaInstalacion(
    infoLogin: LoginModel,
    rol: string,
    cuitPrestador: string
  ): Observable<any> {
    let informacion = {
      ...infoLogin,
      rol: rol,
      cuitPrestador: cuitPrestador,
    };
    return this.http
      .post(environment.url + '/usuarios/signin/instalacion', informacion)
      .pipe(take(1));
  }

  mostrarAdministradores(codigosInstalacion: Array<codigoInstalacion>) {
    this.dialogsService
      .solicitarInfoModal(
        'Seleccione el lugar de atención del cual desea ver los administradores',
        'LugaresAtencion',
        undefined,
        codigosInstalacion
      )
      .result.then((respuesta: any) => {
        if (respuesta && respuesta.respuesta) {
          this.store.dispatch(new ActivarLoading());
          this.http
            .get<Array<DataUsuario>>(
              environment.url + `/usuarios/${respuesta.respuesta}/administrador`
            )
            .pipe(take(1))
            .subscribe(
              (respuesta) => {
                this.store.dispatch(new DesactivarLoading());
                this.dialogsService.verAdministradoresModal(respuesta);
              },
              (error: HttpErrorResponse) => {
                this.globalService.manageError(error);
              }
            );
        }
      })
      .catch(() => {
        this.store.dispatch(new DesactivarLoading());
        this.globalService.redirect(
          new redirectClass('contactanos', errorGenerico)
        );
      });
  }
}
