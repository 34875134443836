import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { prestacionCodigoDescModel } from '@models/global.models';
import { Store } from '@ngrx/store';
import { InicioService } from '@services/inicio.service';
import { AppState } from '@store/app.reducers';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-input-prestaciones',
  templateUrl: './input-prestaciones.component.html',
  styleUrls: ['./input-prestaciones.component.scss'],
})
export class InputPrestacionesComponent implements OnInit {
  private subscriptionStore: Subscription = new Subscription();

  model: prestacionCodigoDescModel | null = null;
  searching = false;
  searchFailed = false;
  notOsde = true;

  @ViewChild("buscarPresta")
  buscarPresta!: ElementRef;

  @Output()
  selectPrestacion: EventEmitter<any> = new EventEmitter();
  constructor(
    private inicioService: InicioService,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe((datos) => {
      if (datos.datosInicio.FinanciadorRequest?.SiglaFinanciador === 'OSDE') {
        this.notOsde = false;
      }
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) => {
        if (this.notOsde) {
          this.searching = false;
          return of([{ codigo: term, descripcion: term }]); // No realizar búsqueda si es OSDE
        }
        return this.inicioService.searchPrestaciones(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            // Reemplazar con un objeto que tenga el término de búsqueda como código y descripción
            return of([{ codigo: term, descripcion: term }]);
          })
        );
      }),
      tap(() => (this.searching = false))
    );

  formatter = (x: any) => `${x.descripcion}`;

  submit(e: any) {
    if (this.model?.codigo) {
      this.selectPrestacion.emit(this.model);
    } else {
      this.selectPrestacion.emit(null);
    }
  }

  clean() {
    this.model = null;
  }

  focus() {
    this.buscarPresta.nativeElement.focus();
  }
}
