import { Financiador } from '@models/financiador.model';
import { codigoInstalacion, filteredMarkers, searchReturn } from '@models/global.models';
import { UsuarioActualModel } from '@models/login.models';
import { lugarAtencion } from '@models/lugarAtencion.model';
import { Prestador, PrestadorSelecionado } from '@models/prestador.model';
import { Transacciones } from '@models/transaccion.models';
import * as datosTransaccion from '@models/datosTransaccion.models';
import * as datosInicio from '@models/datosInicio.models';
import { codigoNombreModel, DatosInicialesExtra } from '@models/datosInicialesExtras.models';
import * as datosDispensa from '@models/datosDispensa.models';
import { TicketFiltrosModel } from '@models/ticket.models';
import { SugerenciaModel } from '@models/sugerencia.model';
import { Receta } from '@models/rescateResponse.model';


//Models para dispensar prescripciones
export class datosDispensaState {
  stepMonodrogaRequest: datosDispensa.NumeroStepModel | null;
  stepEstudioRequest: datosDispensa.NumeroStepModel | null;
  monodrogaRequest: datosDispensa.MonodrogaRequestModel | null;
  estudioRequest: datosDispensa.EstudiosRequestModel | null;
  medicamentoSeleccionadoRequest: datosTransaccion.DetalleMedicamentosRequestModel | null;

  constructor(
    stepMonodrogaRequest: datosDispensa.NumeroStepModel,
    stepEstudioRequest: datosDispensa.NumeroStepModel ,
    monodrogaRequest: datosDispensa.MonodrogaRequestModel,
    estudioRequest: datosDispensa.EstudiosRequestModel,
    medicamentoSeleccionadoRequest: datosTransaccion.DetalleMedicamentosRequestModel
  ) {
    this.stepMonodrogaRequest = stepMonodrogaRequest;
    this.stepEstudioRequest = stepEstudioRequest;
    this.monodrogaRequest = monodrogaRequest;
    this.estudioRequest = estudioRequest;
    this.medicamentoSeleccionadoRequest = medicamentoSeleccionadoRequest;
  }
}
//Models para estado forms
export class estadoFormsState {
  formEstadoCredencialRequest: boolean | null;
  formEstadoCodigoDeSeguridadRequest: boolean | null;
  formEstadoFinanciadorRequest: boolean | null;
  formEstadoPrestadorRequest: boolean | null;
  formEstadoTransaccionRequest: boolean | null;
  formEstadoDetalleMedicamentosRequest: boolean | null;
  formEstadoDetalleProcedimientoRequest: boolean | null;
  formEstadoAcompannanteRequest: boolean | null;
  formEstadoAtencionRequest: boolean | null;
  formEstadoBeneficiarioRequest: boolean | null;
  formEstadoCodFinalizacionTratamientoRequest: boolean | null;
  formEstadoDerivacionRequest: boolean | null;
  formEstadoDiagnosticosRequest: boolean | null;
  formEstadoDocumentacionRequest: boolean | null;
  formEstadoEfectorRequest: boolean | null;
  formEstadoMensajeParaFinanciadorRequest: boolean | null;
  formEstadoPreautorizacionRequest: boolean | null;
  formEstadoPrescripcionRequest: boolean | null;
  formEstadoPrescriptorRequest: boolean | null;
  formEstadoFechaReferenciaCancelRequest: boolean | null;
  formEstadoIdMsjRequest: boolean | null;
  formEstadoInicioTrxRequest: boolean | null;
  formEstadoNroReferenciaCancelRequest: boolean | null;
  formEstadoNroReferenciaRequest: boolean | null;
  formEstadoSoftwareRequest: boolean | null;
  formEstadoTerminalRequest: boolean | null;
  formEstadoTipoTransaccionRequest: boolean | null;
  formEstadoVersionMsjRequest: boolean | null;
  formEstadoPrescripcionDispensaRequest: boolean | null;
  fomrEstadoDatosEnvioPrescripcionEmailBeRequest: boolean | null;
  fomrEstadoDatosEnvioPrescripcionDiagRequest: boolean | null;
  formEstadoFirmaRequest: boolean | null;
  constructor(
    formEstadoCredencialRequest: boolean,
    formEstadoCodigoDeSeguridadRequest: boolean,
    formEstadoFinanciadorRequest: boolean,
    formEstadoPrestadorRequest: boolean,
    formEstadoTransaccionRequest: boolean,
    formEstadoDetalleMedicamentosRequest: boolean,
    formEstadoDetalleProcedimientoRequest: boolean,
    formEstadoAcompannanteRequest: boolean,
    formEstadoAtencionRequest: boolean,
    formEstadoBeneficiarioRequest: boolean,
    formEstadoCodFinalizacionTratamientoRequest: boolean,
    formEstadoDerivacionRequest: boolean,
    formEstadoDiagnosticosRequest: boolean,
    formEstadoDocumentacionRequest: boolean,
    formEstadoEfectorRequest: boolean,
    formEstadoMensajeParaFinanciadorRequest: boolean,
    formEstadoPreautorizacionRequest: boolean,
    formEstadoPrescripcionRequest: boolean,
    formEstadoPrescriptorRequest: boolean,
    formEstadoFechaReferenciaCancelRequest: boolean,
    formEstadoIdMsjRequest: boolean,
    formEstadoInicioTrxRequest: boolean,
    formEstadoNroReferenciaCancelRequest: boolean,
    formEstadoNroReferenciaRequest: boolean,
    formEstadoSoftwareRequest: boolean,
    formEstadoTerminalRequest: boolean,
    formEstadoTipoTransaccionRequest: boolean,
    formEstadoVersionMsjRequest: boolean,
    formEstadoPrescripcionDispensaRequest: boolean,
    fomrEstadoDatosEnvioPrescripcionEmailBeRequest: boolean,
    fomrEstadoDatosEnvioPrescripcionDiagRequest: boolean,
    formEstadoFirmaRequest: boolean,
  ) {
    this.formEstadoCredencialRequest = formEstadoCredencialRequest;
    this.formEstadoCodigoDeSeguridadRequest = formEstadoCodigoDeSeguridadRequest;
    this.formEstadoFinanciadorRequest = formEstadoFinanciadorRequest;
    this.formEstadoPrestadorRequest = formEstadoPrestadorRequest;
    this.formEstadoTransaccionRequest = formEstadoTransaccionRequest;
    this.formEstadoDetalleMedicamentosRequest =
      formEstadoDetalleMedicamentosRequest;
    this.formEstadoDetalleProcedimientoRequest =
      formEstadoDetalleProcedimientoRequest;
    this.formEstadoAcompannanteRequest = formEstadoAcompannanteRequest;
    this.formEstadoAtencionRequest = formEstadoAtencionRequest;
    this.formEstadoBeneficiarioRequest = formEstadoBeneficiarioRequest;
    this.formEstadoCodFinalizacionTratamientoRequest =
      formEstadoCodFinalizacionTratamientoRequest;
    this.formEstadoDerivacionRequest = formEstadoDerivacionRequest;
    this.formEstadoDiagnosticosRequest = formEstadoDiagnosticosRequest;
    this.formEstadoDocumentacionRequest = formEstadoDocumentacionRequest;
    this.formEstadoEfectorRequest = formEstadoEfectorRequest;
    this.formEstadoMensajeParaFinanciadorRequest =
      formEstadoMensajeParaFinanciadorRequest;
    this.formEstadoPreautorizacionRequest = formEstadoPreautorizacionRequest;
    this.formEstadoPrescripcionRequest = formEstadoPrescripcionRequest;
    this.formEstadoPrescriptorRequest = formEstadoPrescriptorRequest;
    this.formEstadoFechaReferenciaCancelRequest =
      formEstadoFechaReferenciaCancelRequest;
    this.formEstadoIdMsjRequest = formEstadoIdMsjRequest;
    this.formEstadoInicioTrxRequest = formEstadoInicioTrxRequest;
    this.formEstadoNroReferenciaCancelRequest = formEstadoNroReferenciaCancelRequest;
    this.formEstadoNroReferenciaRequest = formEstadoNroReferenciaRequest;
    this.formEstadoSoftwareRequest = formEstadoSoftwareRequest;
    this.formEstadoTerminalRequest = formEstadoTerminalRequest;
    this.formEstadoTipoTransaccionRequest = formEstadoTipoTransaccionRequest;
    this.formEstadoVersionMsjRequest = formEstadoVersionMsjRequest;
    this.formEstadoPrescripcionDispensaRequest = formEstadoPrescripcionDispensaRequest;
    this.fomrEstadoDatosEnvioPrescripcionEmailBeRequest = fomrEstadoDatosEnvioPrescripcionEmailBeRequest;
    this.fomrEstadoDatosEnvioPrescripcionDiagRequest = fomrEstadoDatosEnvioPrescripcionDiagRequest;
    this.formEstadoFirmaRequest = formEstadoFirmaRequest;
  }
}
//Models para transaccion informar prestacion
export class datosTransaccionesState {
  DetalleMedicamentosRequest: Array<datosTransaccion.DetalleMedicamentosRequestModel> | null;
  DetalleEstudiosRequest: Array<datosTransaccion.DetalleEstudiosRequestModel> | null;
  extraDispensaRequest: datosTransaccion.ExtrasDispensa | null | undefined;
  DetalleProcedimientoRequest: Array<datosTransaccion.DetalleProcedimientoRequestModel> | null;
  AcompannanteRequest: datosTransaccion.AcompannanteRequestModel | null;
  AtencionRequest: datosTransaccion.AtencionRequestModel | null;
  BeneficiarioRequest: datosTransaccion.BeneficiarioRequestModel | null;
  CodFinalizacionTratamientoRequest: string | null;
  DerivacionRequest: datosTransaccion.DerivacionRequestModel | null;
  DiagnosticosRequest: Array<datosTransaccion.DiagnosticosRequestModel> | null;
  DocumentacionRequest: datosTransaccion.DocumentacionRequestModel | null;
  EfectorRequest: datosTransaccion.EfectorRequestModel | null;
  MensajeParaFinanciadorRequest: string | null;
  PreautorizacionRequest: datosTransaccion.PreautorizacionRequestModel | null;
  PrescripcionRequest: datosTransaccion.PrescripcionRequestModel | null;
  PrescriptorRequest: datosTransaccion.PrescriptorRequestModel | null;
  FechaReferenciaCancelRequest: string | null;
  IdMsjRequest: string | null;
  InicioTrxRequest: datosTransaccion.InicioTrxRequestModel | null;
  NroReferenciaCancelRequest: string | null;
  NroReferenciaRequest: string | null;
  SoftwareRequest: datosTransaccion.SoftwareRequestModel | null;
  TerminalRequest: datosTransaccion.TerminalRequestModel | null;
  TipoTransaccionRequest: string | null;
  VersionMsjRequest: string | null;

  constructor(
    DetalleMedicamentosRequest: Array<datosTransaccion.DetalleMedicamentosRequestModel>,
    DetalleEstudiosRequest: Array<datosTransaccion.DetalleEstudiosRequestModel>,
    extraDispensaRequest: datosTransaccion.ExtrasDispensa,
    DetalleProcedimientoRequest: Array<datosTransaccion.DetalleProcedimientoRequestModel>,
    AcompannanteRequest: datosTransaccion.AcompannanteRequestModel,
    AtencionRequest: datosTransaccion.AtencionRequestModel,
    BeneficiarioRequest: datosTransaccion.BeneficiarioRequestModel,
    CodFinalizacionTratamientoRequest: string,
    DerivacionRequest: datosTransaccion.DerivacionRequestModel,
    DiagnosticosRequest: Array<datosTransaccion.DiagnosticosRequestModel>,
    DocumentacionRequest: datosTransaccion.DocumentacionRequestModel,
    EfectorRequest: datosTransaccion.EfectorRequestModel,
    MensajeParaFinanciadorRequest: string,
    PreautorizacionRequest: datosTransaccion.PreautorizacionRequestModel,
    PrescripcionRequest: datosTransaccion.PrescripcionRequestModel,
    PrescriptorRequest: datosTransaccion.PrescriptorRequestModel,
    FechaReferenciaCancelRequest: string,
    IdMsjRequest: string,
    InicioTrxRequest: datosTransaccion.InicioTrxRequestModel,
    NroReferenciaCancelRequest: string,
    NroReferenciaRequest: string,
    SoftwareRequest: datosTransaccion.SoftwareRequestModel,
    TerminalRequest: datosTransaccion.TerminalRequestModel,
    TipoTransaccionRequest: string,
    VersionMsjRequest: string
  ) {
    this.DetalleMedicamentosRequest = DetalleMedicamentosRequest;
    this.DetalleEstudiosRequest = DetalleEstudiosRequest;
    this.extraDispensaRequest = extraDispensaRequest;
    this.DetalleProcedimientoRequest = DetalleProcedimientoRequest;
    this.AcompannanteRequest = AcompannanteRequest;
    this.AtencionRequest = AtencionRequest;
    this.BeneficiarioRequest = BeneficiarioRequest;
    this.CodFinalizacionTratamientoRequest = CodFinalizacionTratamientoRequest;
    this.DerivacionRequest = DerivacionRequest;
    this.DiagnosticosRequest = DiagnosticosRequest;
    this.DocumentacionRequest = DocumentacionRequest;
    this.EfectorRequest = EfectorRequest;
    this.MensajeParaFinanciadorRequest = MensajeParaFinanciadorRequest;
    this.PreautorizacionRequest = PreautorizacionRequest;
    this.PrescripcionRequest = PrescripcionRequest;
    this.PrescriptorRequest = PrescriptorRequest;
    this.FechaReferenciaCancelRequest = FechaReferenciaCancelRequest;
    this.IdMsjRequest = IdMsjRequest;
    this.InicioTrxRequest = InicioTrxRequest;
    this.NroReferenciaCancelRequest = NroReferenciaCancelRequest;
    this.NroReferenciaRequest = NroReferenciaRequest;
    this.SoftwareRequest = SoftwareRequest;
    this.TerminalRequest = TerminalRequest;
    this.TipoTransaccionRequest = TipoTransaccionRequest;
    this.VersionMsjRequest = VersionMsjRequest;
  }
}

//Models para datosInicio
export class datosInicioState {
  public CredencialRequest: datosInicio.CredencialRequestModel | null;
  public FinanciadorRequest: datosInicio.FinanciadorRequestModel | null;
  public PrestadorRequest: datosInicio.PrestadorRequestModel | null;
  public TransaccionRequest: datosInicio.TransaccionRequestModel | null;
  public PrescripcionDispensaRequest: datosInicio.PrescripcionDispensaRequestModel | null;
  public AfiliadoRequest: datosInicio.AfiliadoRequestModel | null;

  constructor(
    CredencialRequest: datosInicio.CredencialRequestModel,
    FinanciadorRequest: datosInicio.FinanciadorRequestModel,
    PrestadorRequest: datosInicio.PrestadorRequestModel,
    TransaccionRequest: datosInicio.TransaccionRequestModel,
    PrescripcionDispensaRequest: datosInicio.PrescripcionDispensaRequestModel,
    AfiliadoRequest: datosInicio.AfiliadoRequestModel
  ) {
    this.CredencialRequest = CredencialRequest;
    this.FinanciadorRequest = FinanciadorRequest;
    this.PrestadorRequest = PrestadorRequest;
    this.TransaccionRequest = TransaccionRequest;
    this.PrescripcionDispensaRequest = PrescripcionDispensaRequest;
    this.AfiliadoRequest = AfiliadoRequest;
  }
}

export class financiadorForm {
  financiador: Financiador;
  formValid: boolean;

  constructor(financiador: Financiador, formValid: boolean) {
    this.financiador = financiador;
    this.formValid = formValid;
  }
}

export class numeroAfiliadoForm {
  numeroAfiliado: string;
  formValid: boolean;

  constructor(numeroAfiliado: string, formValid: boolean) {
    this.numeroAfiliado = numeroAfiliado;
    this.formValid = formValid;
  }
}
export class prestadorForm {
  prestador: Prestador;
  formValid: boolean;

  constructor(prestador: Prestador, formValid: boolean) {
    this.prestador = prestador;
    this.formValid = formValid;
  }
}
export class transaccionForm {
  transaccion: Transacciones;
  formValid: boolean;

  constructor(transaccion: Transacciones, formValid: boolean) {
    this.transaccion = transaccion;
    this.formValid = formValid;
  }
}

//Models para ui

export class UIState {
  indexDetalleProcediminetoAEditar: number | null;
  public loading: boolean;
  public coordenadas: google.maps.LatLng;

  constructor(
    indexDetalleProcediminetoAEditar: number,
    loading: boolean,
    coordenadas: google.maps.LatLng
  ) {
    this.indexDetalleProcediminetoAEditar = indexDetalleProcediminetoAEditar;
    this.loading = loading;
    this.coordenadas = coordenadas;
  }
}

export class DatosIniciales {
  financiadores: Array<Financiador> | null;
  prestadores: Array<Prestador> | null;
  prestadoresSeleccionados: Array<PrestadorSelecionado> | null;
  tiposTransacciones: Array<codigoNombreModel> | null;
  tiposPrescripciones: Array<codigoNombreModel> | null;

  constructor(
    financiadores: Array<Financiador>,
    prestadores: Array<Prestador>,
    prestadoresSeleccionados: Array<PrestadorSelecionado>,
    tiposTransacciones: Array<codigoNombreModel>,
    tiposPrescripciones: Array<codigoNombreModel>
  ) {
    this.financiadores = financiadores;
    this.prestadores = prestadores;
    this.prestadoresSeleccionados = prestadoresSeleccionados;
    this.tiposTransacciones = tiposTransacciones;
    this.tiposPrescripciones = tiposPrescripciones;
  }
}

export class ConfiguracionUsuario { }

export class datosInicialesExtraState {
  public datosInicialesExtra: DatosInicialesExtra | null;

  constructor(datosInicialesExtra: DatosInicialesExtra) {
    this.datosInicialesExtra = datosInicialesExtra;
  }
}
export class ConfiguracionState {
  public codigosInstalacion: Array<codigoInstalacion> | null;
  public usuarioActual: UsuarioActualModel | null;
  public datosIniciales: DatosIniciales | null;
  public configuracionUsuario: ConfiguracionUsuario | null;

  constructor(
    codigosInstalacion: Array<codigoInstalacion> | null,
    usuarioActual: UsuarioActualModel | null,
    datosIniciales: DatosIniciales | null,
    configuracionUsuario: ConfiguracionUsuario | null
  ) {
    this.codigosInstalacion = codigosInstalacion;
    this.usuarioActual = usuarioActual;
    this.datosIniciales = datosIniciales;
    this.configuracionUsuario = configuracionUsuario;
  }
}
//Models para instalacion
export class InstalacionState {
  public usuarioInstalacion: InstalacionUsuario | null;
  public lugarAtencionSeleccionado: lugarAtencion | null;
  public busquedaRealizada: searchReturn | null;
  public lugares_atencion: Array<lugarAtencion>;//modificacion Mica


  constructor(
    usuarioInstalacion: InstalacionUsuario,
    lugarAtencionSeleccionado: lugarAtencion,
    busquedaRealizada: searchReturn,
    lugares_atencion: Array<lugarAtencion>,//modificacion Mica

  ) {
    this.usuarioInstalacion = usuarioInstalacion;
    this.lugarAtencionSeleccionado = lugarAtencionSeleccionado;
    this.busquedaRealizada = busquedaRealizada;
    this.lugares_atencion = lugares_atencion; //modificacion Mica

  }
}

export class filterState {
  FilterTicketsRequest: TicketFiltrosModel | null;
  constructor(
    FilterTicketsRequest: TicketFiltrosModel
  ) {
    this.FilterTicketsRequest = FilterTicketsRequest
  }
}

export class sugerenciasState {
  SugerenciasRequest: SugerenciaModel | null;
  constructor(
    SugerenciasRequest: SugerenciaModel
  ) {
    this.SugerenciasRequest = SugerenciasRequest
  }
}

// esta es la clase que se usa para listar los lugares de atención que ya tiene cargado un prestador
export class InstalacionUsuario {
  public baja: boolean;
  public cuit: string;
  public id: number;
  public lugares_atencion: Array<lugarAtencion>;
  public pais: string;
  public razon_social: string;
  public tipo_prestador: string;

  constructor(
    baja: boolean,
    cuit: string,
    id: number,
    lugares_atencion: Array<lugarAtencion>,
    pais: string,
    razon_social: string,
    tipo_prestador: string
  ) {
    this.baja = baja;
    this.cuit = cuit;
    this.id = id;
    this.lugares_atencion = lugares_atencion;
    this.pais = pais;
    this.razon_social = razon_social;
    this.tipo_prestador = tipo_prestador;
  }
}

export class recetasState {
  RecetasRequest: Receta | null;
  EsRecetaRequest: boolean | null;
  constructor(
    RecetasRequest: Receta,
    EsRecetaRequest: boolean
  ) {
    this.RecetasRequest = RecetasRequest
    this.EsRecetaRequest = EsRecetaRequest
  }
}
