import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { searchReturn } from '@models/global.models';
import { Store } from '@ngrx/store';
import { busquedaRealizada } from '@store/actions';
import { AppState } from '@store/app.reducers';

@Component({
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss'],
})
export class SearchAutocompleteComponent implements OnInit {
  @ViewChild('search') public searchElementRef!: ElementRef;

  constructor(private ngZone: NgZone, private store: Store<AppState>) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.setComponentRestrictions({ country: 'AR' });
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        let coordenates = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );

        this.store.dispatch(
          new busquedaRealizada(
            new searchReturn(
              this.searchElementRef.nativeElement.value,
              coordenates
            )
          )
        );
      });
    });
  }
}
