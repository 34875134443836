import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { diagnosticoCodigoDescModel } from '@models/global.models';
import { InicioService } from '@services/inicio.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-input-diagnosticos',
  templateUrl: './input-diagnosticos.component.html',
  styleUrls: ['./input-diagnosticos.component.scss']
})
export class InputDiagnosticosComponent implements OnInit {
  model: diagnosticoCodigoDescModel | null = null;
  searching = false;
  searchFailed = false;
  @Output()
  selectDiagnostico: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private inicioService: InicioService
  ) { }

  ngOnInit(): void {
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.inicioService.searchDiagnosticos().filter(v => (v.codigo.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.descripcion.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );

  formatter = (x: any) => `${x.descripcion}`;

  submit(e: any) {
    if (this.model?.codigo) {
      this.selectDiagnostico.emit(this.model);
    } else {
      this.selectDiagnostico.emit(null);
    }
  }

  clean() {
    this.model = null;
  }

}
