import { codigoInstalacion } from './global.models';

export class LoginModel {
  username: string;
  password: string;
  codigoInstalacion: string;

  constructor(username: string, password: string, codigoInstalacion: string) {
    this.username = username;
    this.password = password;
    this.codigoInstalacion = codigoInstalacion;
  }
}

export class UsuarioActualModel {
  token: string;
  email: string;
  nombre: string;
  apellido: string;
  id: number;
  tipoInstalacion: string;
  role: string;
  codigoInstalacion: string;
  domicilio: string;
  esTurnea: boolean = false;
  constructor(
    token: string,
    email: string,
    nombre: string,
    apellido: string,
    id: number,
    tipoInstalacion: string,
    role: string,
    codigoInstalacion: string,
    domicilio: string,
    esTurnea: boolean
  ) {
    this.token = token;
    this.email = email;
    this.nombre = nombre;
    this.apellido = apellido;
    this.id = id;
    this.tipoInstalacion = tipoInstalacion;
    this.role = role;
    this.codigoInstalacion = codigoInstalacion;
    this.domicilio = domicilio;
    this.esTurnea = esTurnea;
  }
}
