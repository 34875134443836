<form [formGroup]="form" (ngSubmit)="guardar()">
  <div class="container__panelPrestacion">
    <div class="container-prestacion">
      <div class="container-prestacionDatos">
        <div class="container-prestacion1" [ngClass]=" {'container-prestacion102D' : codigoTransaccion=='02D' } ">



          <div class="form-infoN prestacion__fecha " *ngIf="codigoTransaccion=='02D'">
            <app-datos-atencion-request></app-datos-atencion-request>
          </div>
          <div class="form-infoN prestacion__cPrestacion">
            <label id="labelCPrestacion" for="cPrestacion">Código o Prestación <span
                *ngIf="form.get('CodPrestacion') | controlRequired" class="span-required">*</span></label>
            <app-input-prestaciones (selectPrestacion)="selectPrestacion($event)" class="input__codigoPrestaciones">
            </app-input-prestaciones>

          </div>
          <div class="form-infoN prestacion__cantidad">
            <label for="cantidadPrestacion">Cantidad <span *ngIf="form.get('CantidadSolicitada') | controlRequired"
                class="span-required">*</span></label>
            <input id="cantidadPrestacion" type="number" min="1" #cantidadSolicitada
              class="form-controlN form-controlN-dashboardP" formControlName="CantidadSolicitada"
              [ngClass]="{'form-controlNError': form.controls['CantidadSolicitada'].errors}">

          </div>
          <div class="form-infoN prestacion__tipo">
            <label for="tipoPrestacion">Tipo <span *ngIf="form.get('TipoPrestacion') | controlRequired"
                class="span-required">*</span></label>
            <select class="form-control" formControlName="TipoPrestacion" id="tipoPrestacion">
              <option [ngValue]="tipoPrestacion.codigo" *ngFor="let tipoPrestacion of tiposPrestaciones">
                {{ tipoPrestacion.codigo }}-{{tipoPrestacion.nombre}}
              </option>
            </select>
          </div>
          <div class="form-infoN prestacion__arancel">
            <label for="arancelPrestacion">Arancel <span *ngIf="form.get('ArancelPrestacion') | controlRequired"
                class="span-required">*</span></label>
            <select class="form-control" formControlName="ArancelPrestacion" id="arancelPrestacion">
              <option [ngValue]="tipoArancel.codigo" *ngFor="let tipoArancel of tiposAranceles">
                {{ tipoArancel.codigo }}-{{tipoArancel.nombre}}
              </option>
            </select>
          </div>

          <ng-container *ngIf="codigoTransaccion === '02C'; else infoCirugia">
            <app-datos-preautorizacion-request [mostrar]="false" nombreCampo="N° Autorización"
              class="prestacion__autorizacion">
            </app-datos-preautorizacion-request>
          </ng-container>

          <ng-template #infoCirugia>
            <ng-container *ngIf="codigoTransaccion == '02Q' || codigoTransaccion == '02S'; else otraOperacion">
              <app-datos-preautorizacion-request nombreCampo="N° de Orden " class="prestacion__autorizacion">
              </app-datos-preautorizacion-request>
            </ng-container>
          </ng-template>

          <ng-template #otraOperacion>
            <app-datos-preautorizacion-request nombreCampo="N° Autorización" class="prestacion__autorizacion">
            </app-datos-preautorizacion-request>
          </ng-template>

        </div>

        <figure class="prestacion_butonMore" id="butonMore">
          <!-- <p><a href="#" data-title="Cargar nueva
          prestación"><img src="../../assets/add.svg" alt="Add"></a></p> -->
          <p>
            <button type="submit" data-title="Cargar nueva
              prestación" class="butonMore">
              <img src="./assets/design/addButton.svg" alt="Add">
            </button>
          </p>
        </figure>

      </div>

    </div>
  </div>
</form>
