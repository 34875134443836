<!-- <div class="modal-header row" style="margin-right: 95px;">
  <div class="col-1">
    <button *ngIf="step.numero !== '1'" class="btn" (click)="cambiarStep()">
      <fa-icon [icon]='["fas", "chevron-left"]' class="text-secondary"></fa-icon>
    </button>
  </div>
  <div class="col-10">
    <h5 class="modal-title" style="display: flex; justify-content: center;">Credencial N° {{credencialSelecionada}}</h5>
  </div>
</div> -->

<app-input-monodroga></app-input-monodroga>
<!-- <div class="modal-body">
</div> -->

<!-- <div class="modal-footer">
  <button type="button" class="buttonAnularTicket" (click)="closeModal()" >Cerrar</button>
</div> -->
