import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { codigoNombreModel } from '@models/datosInicialesExtras.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AppState } from '@store/app.reducers';
import { Store } from '@ngrx/store';
import { PrescripcionDispensaRequestModel } from '@models/datosInicio.models';
import { setPrescripcionDispensaRequest } from '@store/actions';
import { DialogsService } from '@services/dialogs.service';
import { DispensaaService } from '@services/dispensaa.service';

@Component({
  selector: 'app-card-prescripcion',
  templateUrl: './card-prescripcion.component.html',
  styleUrls: ['./card-prescripcion.component.scss']
})
export class CardPrescripcionComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionStoreMedicamentos: Subscription = new Subscription();
  @Input() prescripcion: codigoNombreModel | null = null;
  @Input() agregarMedicamento: boolean = false;
  seleccionado: boolean = false; // Para saber si es la transaccion seleccionada y pinta el cuadro
  nroMatricula: any;
  prestadorApto: boolean = false;
  existeMatricula: boolean = false;

  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>,
    private dialogsService: DialogsService,
    private dispensaaService: DispensaaService
  ) { }

  ngOnInit(): void {
    this.cargarDatos();
    //this.store.dispatch(new setEstadoFormPrescripcionDispensaRequest(false));
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreMedicamentos.unsubscribe();
  }

  cargarDatos() {
    this.subscriptionStore = this.store
      .select('datosInicio')
      .subscribe((datos) => {
        this.seleccionado = datos.PrescripcionDispensaRequest?.NombrePrescripcion == this.prescripcion?.nombre;
        if (datos.PrestadorRequest?.CuitPrestador.startsWith('30')) {
          //controla el modal de cannotPrescribeModal
          this.prestadorApto = false;
        } else {
          this.prestadorApto = true;
        }
        if (datos.PrestadorRequest?.matriculas && datos.PrestadorRequest.matriculas.length > 0) {
          this.nroMatricula = datos.PrestadorRequest.matriculas[0].numero;
          this.existeMatricula = true;
        } else {
          this.nroMatricula = '';
        }
      });
  }

  abrirModal(longContent: any) {
    this.modalService.open(longContent, { centered: true, size: 'lg', scrollable: true });
  }

  abrirModalEstudios(estudioContent: any) {
    this.modalService.open(estudioContent, { centered: true, size: 'lg', scrollable: true });
  }

  seleccionarPrescripcion(content: any) {
    if (this.prescripcion) {
      console.log('soy la prescripción', this.prescripcion);
      let prescripcion = new PrescripcionDispensaRequestModel(
        this.prescripcion.nombre,
        this.prescripcion.codigo
      );
      //this.store.dispatch(new setEstadoFormPrescripcionDispensaRequest(true));
      this.store.dispatch(new setPrescripcionDispensaRequest(prescripcion));
      // TODO se bajo el config de la matricula por
      // if (this.existeMatricula) {
      this.abrirModal(content);
      // } else {
      // Llamar a guardarMatricula si no existe matrícula
      // this.guardarMatricula();
      // }
    } else {
      //this.store.dispatch(new setEstadoFormPrescripcionDispensaRequest(false));
      let prescripcion = new PrescripcionDispensaRequestModel('', '');
      this.store.dispatch(new setPrescripcionDispensaRequest(prescripcion));
    }
  }

  seleccionarEstudios(content: any){

    let prescripcion = new PrescripcionDispensaRequestModel(
      'Estudios',
      '5'
    );
    console.log('soy la prescripción', prescripcion);
    this.store.dispatch(new setPrescripcionDispensaRequest(prescripcion));

    this.abrirModalEstudios(content);

    // this.dispensaaService.getEstudios().subscribe((result: any) => {
    //   console.log('result del getEstudios', result);
    // });
  }

  noPuedePrescribir() {
    this.dialogsService.cannotPrescribeModal();
  }

  get noExisteMatricula(): boolean {
    return !this.existeMatricula;
  }

  guardarMatricula() {
    this.dialogsService.saveMatriculaModal();
  }
}
