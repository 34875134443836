import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { setCodigoDeSeguridadRequest, setEstadoFormCodigoDeSeguridadRequest, unsetCodigoDeSeguridadRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-csc-modal',
  templateUrl: './csc-modal.component.html',
  styleUrls: ['./csc-modal.component.scss']
})
export class CscModalComponent implements OnInit {
  @Input() validacionSinCSC: boolean = false;

  private subscriptionDatosInicio: Subscription = new Subscription();
  private subscriptionFormStatus: Subscription = new Subscription();

  codigoTransaccion: any;
  logoTicket: any;
  numeroCredencial: any;
  siglaSwiss: any;
  siglaScis: any;


  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<any> =
    this.formBuilder.group<any>({
      codigoDeSeguridad: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        blankSpaceValidator,
      ]),
    });

  constructor(private store: Store<AppState>,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.listenCodigoDeSeguridad();
    this.subscriptionDatosInicio = this.store
      .select('datosInicio')
      .subscribe((datos) => {
        this.codigoTransaccion = datos.TransaccionRequest?.CodigoTransaccion;
        this.logoTicket = datos.FinanciadorRequest?.SiglaFinanciador;
        this.numeroCredencial = datos.CredencialRequest?.NumeroCredencial;
        this.siglaSwiss = datos.FinanciadorRequest?.SiglaFinanciador === 'SWISS';
        this.siglaScis = datos.FinanciadorRequest?.SiglaFinanciador === 'SCIS';

      });

    if (this.siglaScis && this.codigoTransaccion === '02A') {
      this.addLessValidators();
    } else {
      this.addValidators();
    }
  }

  ngOnDestroy() {
    this.subscriptionFormStatus.unsubscribe();
    this.subscriptionDatosInicio.unsubscribe();
  }

  addValidators() {
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          this.form.controls['codigoDeSeguridad'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          break;
        case '03A':
          this.form.controls['codigoDeSeguridad'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          break;

        default:
          break;
      }
      this.store.dispatch(
        new setEstadoFormCodigoDeSeguridadRequest(this.form.valid)
      );
    }
  }

  addLessValidators() {
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          this.form.controls['codigoDeSeguridad'].setValidators([
            Validators.required,
            blankSpaceValidator,
          ]);
          break;
        default:
          break;
      }
      this.store.dispatch(
        new setEstadoFormCodigoDeSeguridadRequest(this.form.valid)
      );
    }
  }

  listenCodigoDeSeguridad() {
    this.subscriptionFormStatus = this.form.controls['codigoDeSeguridad'].statusChanges.subscribe((status) => {
      this.form.controls['codigoDeSeguridad'].markAsTouched();
      if (this.form.controls['codigoDeSeguridad'].value) {
        this.store.dispatch(new setCodigoDeSeguridadRequest(this.form.controls['codigoDeSeguridad'].value));
        if (status == 'VALID' && this.form.controls['codigoDeSeguridad'].value?.length === 3) {
          this.store.dispatch(new setEstadoFormCodigoDeSeguridadRequest(true));
          this.devolverRespuesta(true);
        } else {
          this.store.dispatch(new setEstadoFormCodigoDeSeguridadRequest(false));
        }
      } else {
        this.store.dispatch(new unsetCodigoDeSeguridadRequest());
        this.store.dispatch(new setEstadoFormCodigoDeSeguridadRequest(false));
      }
    });
  }

  devolverRespuesta(rta: boolean) {
    let codSeg = this.form.controls['codigoDeSeguridad'].value?.length
    console.log('largo', codSeg);
    if (rta) {
      this.activeModal.close({
        respuesta: true,
      });
    } else {
      this.activeModal.close({ respuesta: false });
    }
  }

  devolverSinCsc(rta: boolean) {
    if (rta) {
      this.activeModal.close({ respuesta: true });
      this.store.dispatch(new setEstadoFormCodigoDeSeguridadRequest(true));
    } else {
      this.activeModal.close({ respuesta: false });
    }
  }
}
