<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>
<div class="container__panelPrestacion">
  <div class="container-derivacion">
    <p class="title__derivacion">Derivación</p>
    <form [formGroup]="form" class="form__derivacion">
      <div class="form-infoN form-infoN-nombreDerivacion">
        <label for="nameDoctorDerivacion">Nombre <span *ngIf="form.get('NombreDerivacion') | controlRequired"
            class="span-required">*</span></label>

        <input type="text" class="form-controlN" formControlName="NombreDerivacion">
      </div>

      <div class="form-infoN">
        <label for="codigoPrestador">Código prestador <span *ngIf="form.get('NroMatriculaDerivacion') | controlRequired"
            class="span-required">*</span></label>

        <input id="codigoPrestador" type="text" class="form-controlN" formControlName="NroMatriculaDerivacion"
          maxlength="6">
        <small class="text-danger animated fadeIn"
          *ngIf="form.controls['NroMatriculaDerivacion'].touched && form.controls['NroMatriculaDerivacion'].errors">El
          código no es válido</small>
      </div>
    </form>
  </div>
</div>

</html>

<!--  <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label>Nombre <span class="text-danger" *ngIf="form.get('NombreDerivacion') | controlRequired">*</span></label>
            <input type="text" class="form-control" formControlName="NombreDerivacion" placeholder="Nombre">
        </div>

        <div class="form-group col-12 col-md-6">
            <label>Apellido <span class="text-danger"
          *ngIf="form.get('ApellidoDerivacion') | controlRequired">*</span></label>
            <input type="text" class="form-control" formControlName="ApellidoDerivacion" placeholder="Apellido">
        </div>
    </div> -->

<!-- <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Matricula de derivación <span class="text-danger"
          *ngIf="form.get('NroMatriculaDerivacion') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="NroMatriculaDerivacion"
        placeholder="Matricula de derivación">
    </div>

    <div class="form-group col-12 col-md-6">
      <label>CUIT de derivación <span class="text-danger"
          *ngIf="form.get('CuitDerivacion') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="CuitDerivacion" placeholder="CUIT de derivación">
    </div>
  </div> -->

<!-- <div class="form-row">
        <div class="form-group col-12 col-md-6"> -->
<!-- <label>Tipo prescriptor derivación <span class="text-danger"
          *ngIf="form.get('TipoPrescriptorDerivacion') | controlRequired">*</span></label> -->
<!--    <label>Tipo prescriptor derivación <span class="text-danger">*</span></label>

            <select class="form-control" formControlName="TipoPrescriptorDerivacion">
        <option [ngValue]="tipoMatriculaDerivacion.codigo"
          *ngFor="let tipoMatriculaDerivacion of tipoMatriculasDerivacion">
          {{tipoMatriculaDerivacion.nombre}}
        </option>
      </select>
        </div>

        <div class="form-group col-12 col-md-6"> -->
<!-- <label>Tipo provincia derivación <span class="text-danger"
          *ngIf="form.get('ProvinciaDerivacion') | controlRequired">*</span></label> -->
<!--  <label>Provincia derivación <span class="text-danger">*</span></label>
 -->
<!-- <select class="form-control" formControlName="ProvinciaDerivacion">
        <option [ngValue]="provinciaDerivacion.codigo" *ngFor="let provinciaDerivacion of provinciasDerivacion">
          {{provinciaDerivacion.nombre}}
        </option>
      </select>
        </div>
    </div> -->

<!-- <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Seleccione la especialidad de la derivación <span class="text-danger"
          *ngIf="form.get('EspecialidadDerivacion') | controlRequired">*</span></label>
      <app-input-especialidades [especialidades]="especialidadesDerivacion"
        (selectEspecialidad)="selectEspecialidad($event)">
      </app-input-especialidades>
    </div>
  </div> -->
<!-- <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label>Código prestador <span class="text-danger">*</span></label> -->
<!-- <input type="text" class="form-control" formControlName="CodParaFinanciadorDerivacion" placeholder="Código"> -->
<!-- <input type="text" class="form-control" formControlName="NroMatriculaDerivacion" placeholder="Código">

        </div>
    </div>
</form> -->