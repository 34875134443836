import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataUsuario } from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-operaciones-usuarios',
  templateUrl: './operaciones-usuarios.component.html',
  styleUrls: ['./operaciones-usuarios.component.scss'],
})
export class OperacionesUsuariosComponent implements OnInit {
  private subscriptionStore: Subscription = new Subscription();
  cargando: boolean = false;

  @Input() titulo: string | null = null;
  @Input() usuarios: Array<DataUsuario> | null = null;
  @Input() botonPrimario: string | null = null;
  @Input() botonSecundario: string | null = null;
  @Output() funcion = new EventEmitter<any>();
  @Output() funcionSecundaria = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  constructor(
    private location: Location,
    private store: Store<AppState>,
    private dialogsService: DialogsService
  ) {}

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe(
      (configuracionState: AppState) => {
        this.cargando = configuracionState.ui.loading;
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  selectAll(event: any) {
    let checkboxes = document.getElementsByClassName('checkbox');
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      (<any>checkboxes[i]).checked = event.target.checked;
    }
  }

  irAtras() {
    this.location.back();
  }

  actualizar() {
    this.refresh.emit();
  }

  siguiente(accion: boolean) {
    let arrayUsuariosAAceptar: HTMLInputElement[] = [];
    let checkboxes = document.getElementsByClassName(
      'checkbox'
    ) as HTMLCollectionOf<HTMLInputElement>;
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if ((<any>checkboxes[i]).checked == true) {
        arrayUsuariosAAceptar.push(checkboxes[i]);
      }
    }
    if (arrayUsuariosAAceptar.length == 0) {
      this.dialogsService.toastError('Debe seleccionar usuarios');
      return;
    } else {
      let array: { id: number }[] = [];
      arrayUsuariosAAceptar.forEach((elem) => {
        array.push({ id: parseInt(elem.value) });
      });
      if (accion) {
        this.funcion.emit(array);
      } else {
        this.funcionSecundaria.emit(array);
      }
    }
  }
}
