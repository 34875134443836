/// <reference types="@types/googlemaps" />
// La linea de arriba es para importar los type de google no eliminar
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  filteredMarkers,
  redirectClass,
  searchReturn,
  showAlert,
} from '@models/global.models';
import { lugarAtencion } from '@models/lugarAtencion.model';
import { Store } from '@ngrx/store';
import { GlobalService } from '@services/global.service';
import { desactivarBusqueda, SeleccionarLugarAtencion } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { erroresMap } from 'errores';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  map: google.maps.Map | null = null;

  mapOptions: google.maps.MapOptions = {
    zoom: 13,
    mapTypeId: 'roadmap',
    streetViewControl: false,
    clickableIcons: false,
    mapTypeControl: false,
  };

  radio: google.maps.Circle = new google.maps.Circle({
    strokeColor: '#81a0c4',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#81a0c4',
    fillOpacity: 0.35,
    radius: 3000,
  });

  lugaresAtencion: Array<lugarAtencion> = [];
  marcadoresFiltrados: Array<filteredMarkers> = [];
  lugarAtencionSeleccionado: lugarAtencion | null = null;
  coordenates: google.maps.LatLng = new google.maps.LatLng(
    -34.61315,
    -58.37723
  );
  previousInfoWindow: google.maps.InfoWindow = new google.maps.InfoWindow(); //sirve para saber el marcador que se abrio anteriormente y cerrarlo al abrir uno nuevo

  @ViewChild('mapContainer', { static: false })
  gmap: ElementRef | null = null;

  constructor(
    private store: Store<AppState>,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.subscriptionStore = this.store.subscribe((state) => {
      this.coordenates = state.ui.coordenadas;

    //   if (state.instalacion.lugarAtencionSeleccionado) {
    //     this.lugarAtencionSeleccionado =
    //       state.instalacion.lugarAtencionSeleccionado;
    //   }
    //   if (state.instalacion.busquedaRealizada) {
    //     let busqueda = state.instalacion.busquedaRealizada;
    //     this.store.dispatch(new desactivarBusqueda());
    //     this.cambiarCoordenadas(busqueda);
    //   }

      if (
          state.instalacion.usuarioInstalacion != null &&
          state.instalacion.usuarioInstalacion.lugares_atencion != null
        ) {
      this.lugaresAtencion = state.instalacion.usuarioInstalacion.lugares_atencion;
      }

      // if (
      //   state.instalacion.usuarioInstalacion != null &&
      //   state.instalacion.usuarioInstalacion.lugares_atencion != null
      // ) {
      //   this.lugaresAtencion =
      //     state.instalacion.usuarioInstalacion.lugares_atencion;
      // } else {
      //   this.globalService.redirect(
      //     new redirectClass(
      //       'contactanos',
      //       erroresMap.mensaje1,
      //       'No se encontraron lugares de atención activos para el prestador selecionado',
      //       new showAlert(
      //         true,
      //         'No se encontraron lugares de atención activos para el prestador selecionado'
      //       )
      //     )
      //   );
      // }
    });
  }

  ngAfterViewInit() {
    //Setea la ubicacion actual que tiene el usuario
    if (!this.gmap) {
      this.errorRedirect('No esta el elementRef gmap');
      return;
    }
    this.mapOptions.center = this.coordenates;
    if (
      this.coordenates.lat() == -38.416097 &&
      this.coordenates.lng() == -63.616672
    ) {
      this.mapOptions.zoom = 5;
    } else {
      this.mapOptions.zoom = 13;
    }
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.radio.setMap(this.map);
    this.radio.setCenter(this.coordenates);
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  cambiarCoordenadas(busqueda: searchReturn) {
    if (!this.map) {
      this.errorRedirect('No esta cargado el mapa');
      return;
    }
    if (
      busqueda.coordenadas.lat() == -38.416097 &&
      busqueda.coordenadas.lng() == -63.616672
    ) {
      this.map.setZoom(5);
    } else {
      this.map.setZoom(13);
    }

    this.map.setCenter(busqueda.coordenadas); //Se setea el centro del mapa
    this.radio.setCenter(busqueda.coordenadas); //Se cambia de ubicacion el radio
    this.setMarkers(busqueda);
  }

  setMarkers(busqueda: searchReturn) {
    //filtra los lugares de atencion dependiendo de la ubicacion actual o de la ubicacion que busco

    this.previousInfoWindow.close(); //Cierro marker abierto
    this.stopListeners(); //Dejo de escuchar los markers anteriores
    this.marcadoresFiltrados = [];

    let lugaresAtencionFiltrado: Array<lugarAtencion> = [];

    if (google.maps.geometry != null) {
      //Al principio es null y tira error por eso esta verificacion

      //Busca los lugares de atencion que se encuentre en un rango de 3km de la ubicacion seleccionada
      lugaresAtencionFiltrado = this.lugaresAtencion.filter(
        (lugarAtencion: lugarAtencion) => {
          const markerLoc = new google.maps.LatLng(
            lugarAtencion.latitud!,
            lugarAtencion.longitud!
          );

          const distanceInKm =
            google.maps.geometry.spherical.computeDistanceBetween(
              markerLoc,
              busqueda.coordenadas
            ) / 1000;

          if (distanceInKm < 3.0) {
            return lugarAtencion;
          } else {
            return;
          }
        }
      );
    }

    //se fija si el nombre ingrasado es exactamente igual al que tenemos en la bd y lo mete en el array de lugares de atencion filtrado(Si es que no se encontro por la lat lng)
    // if (
    //   busqueda.direccion != null &&
    //   busqueda.direccion != undefined &&
    //   busqueda.direccion != ''
    // ) {
    //   this.lugaresAtencion.forEach((lugarAtencion: lugarAtencion) => {
    //     if (
    //       lugarAtencion.domicilio!.toUpperCase() ==
    //       busqueda.direccion
    //         .substr(0, busqueda.direccion.indexOf(','))
    //         .toUpperCase()
    //     ) {
    //       if (!lugaresAtencionFiltrado.includes(lugarAtencion)) {
    //         lugaresAtencionFiltrado.push(lugarAtencion);
    //       }
    //     }
    //   });
    // }

    lugaresAtencionFiltrado.forEach((lugarAtencion) => {
      if (!this.map) {
        this.errorRedirect('No hay mapa cargado a la hora de crear markers');
        return;
      }
      //Se crean los markers para el mapa
      let marker = new google.maps.Marker({
        position: {
          lat: lugarAtencion.latitud!,
          lng: lugarAtencion.longitud!,
        },
        map: this.map,
      });

      this.marcadoresFiltrados.push(new filteredMarkers(marker, lugarAtencion)); //Se cargan juntos el marker con el lugar de atencion para poder disparar eventos desde el checkbox
      let infoWindow = new google.maps.InfoWindow({
        //Mensaje que se muestra cuando se clickea el marker
        content: `Usted seleccionó el lugar de atención:
          <hr>
          <strong>${lugarAtencion.domicilio}</strong>`,
      });
      marker.addListener('click', () => {
        //cierro infoWindow anterior
        if (this.previousInfoWindow) {
          this.previousInfoWindow.close();
        }
        this.previousInfoWindow = infoWindow;
        infoWindow.open(marker.get('map'), marker);
        this.store.dispatch(new SeleccionarLugarAtencion(lugarAtencion));
      });
    });
  }

  stopListeners() {
    //Dejo de escuchar los markers anteriores
    this.marcadoresFiltrados.forEach((marker) => {
      marker.marker.setMap(null); //Elimino los markers del mapa
      google.maps.event.clearListeners(marker.marker, 'click');
    });
  }

  selectDireccion(marker: google.maps.Marker) {
    //Evento que se lllama cuando se selecciona algun checkbox y emite un evento como si se hubiera apretado un marker
    google.maps.event.trigger(marker, 'click');
  }

  noEstaLugarAtencion() {
    this.globalService.redirect(
      new redirectClass(
        'contactanos',
        erroresMap.mensaje2,
        'El prestador no encontro su lugar de atención'
      )
    );
  }

  errorRedirect(error: string) {
    this.globalService.redirect(
      new redirectClass('contactanos', '', error, {
        showAlert: true,
        mensajeAlert: 'Se produjo un error, por favor vuelva a intentarlo',
      })
    );
  }
}
