import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { DatosAfiliado, DatosAfiliadoAgendaModel } from '@models/agenda.models';
import { FinaciadorNuvalid } from '@models/financiador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { AgendaService } from '@services/agenda.service';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { unsetAfiliadoRequest } from '../../../store/actions/datosInicio.actions';

@Component({
  selector: 'app-crear-contacto',
  templateUrl: './crear-contacto.component.html',
  styleUrls: ['./crear-contacto.component.scss'],
})
export class CrearContactoComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscription: Subscription = new Subscription();

  contactoAEditar: DatosAfiliadoAgendaModel | null = null;
  titulo = 'Crear contacto';
  submitted: boolean = false;
  financiadores: Array<FinaciadorNuvalid> | null = null;
  obraFinanaciadoresSeleccionados: Array<string> = [];

  nombreAfiliado?: string = '';
  apellidoAfiliado?: string = '';
  dniAfiliado?: string = '';
  emailAfiliado?: string = '';
  telefonoAfiliado?: string = '';
  credencialAfiliado?: string = '';
  financiadorAfiliado?: string = '';


  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<DatosAfiliadoAgendaModel> =
    this.formBuilder.group<DatosAfiliadoAgendaModel>({
      id: new UntypedFormControl(<ControlState<string>>null),
      DNI: new UntypedFormControl(<ControlState<string>>'', [
        Validators.required,
        blankSpaceValidator,
        Validators.minLength(7),
        Validators.maxLength(10),
      ]),
      nombre: new UntypedFormControl(<ControlState<string>>'', [
        blankSpaceValidator,
        Validators.minLength(4),
        Validators.maxLength(64),
      ]),
      apellido: new UntypedFormControl(<ControlState<string>>'', [
        Validators.required,
        blankSpaceValidator,
        Validators.minLength(4),
        Validators.maxLength(32),
      ]),
      email: new UntypedFormControl(<ControlState<string>>'', [
        Validators.required,
        Validators.email,
        blankSpaceValidator,
      ]),
      telefono: new UntypedFormControl(<ControlState<string>>'', [
        Validators.required,
        blankSpaceValidator,
        Validators.pattern('[0-9 .?]+'),
      ]),
      obras: this.formBuilder.array<DatosAfiliado>([
        this.formBuilder.group({
          numeroAfiliado: new UntypedFormControl(<ControlState<string>>'', [
            Validators.required,
            blankSpaceValidator,
          ]),
          nombreFinanciador: new UntypedFormControl(<ControlState<string>>'', [
            Validators.required,
            blankSpaceValidator,
          ]),
        }),
      ]),
    });
  getObras() {
    let obras = this.form.controls.obras as UntypedFormArray;
    if (obras) {
      return obras.controls;
    } else {
      return null;
    }
  }

  constructor(
    private agendaService: AgendaService,
    private globalService: GlobalService,
    private dialogsService: DialogsService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.dialogsService.cargando();
    if (this.router.getCurrentNavigation()?.extras?.state) {
      let parameters = this.router.getCurrentNavigation()?.extras.state;

      if (parameters?.contacto) {
        //Si se envia un parametro es el contacto que hay que editar
        this.titulo = 'Editar contacto';
        this.contactoAEditar = parameters.contacto;
      }
    }
  }

  ngOnInit(): void {
    this.setFinanciadoresSeleccionados();
    this.getFinanciadores();
    this.setDefaultValues();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionStore.unsubscribe();
  }

  setFinanciadoresSeleccionados() {
    //Mete los financiadores que ya fueron seleccionados en un array para que no se pueda volver a selec
    this.form.controls.obras.valueChanges.subscribe((respuesta) => {
      if (respuesta) {
        let array: Array<string> = [];
        respuesta.forEach((resp) => {
          array.push(resp.nombreFinanciador);
        });
        this.obraFinanaciadoresSeleccionados = array;
      } else {
        this.obraFinanaciadoresSeleccionados = [];
      }
    });
  }

  getFinanciadores() {
    //Busca los financiadores en la base de datos
    this.agendaService.getFinanciadores().subscribe(
      (respuesta) => {
        this.financiadores = respuesta;
        if (this.contactoAEditar) {
          this.cargarDatosContactoAModificar();
        }
        this.dialogsService.cerrar();
      },
      (error: HttpErrorResponse) => {
        this.globalService.manageError(error);
      }
    );
  }

  cargarDatosContactoAModificar() {
    //Se cargan los datos anteriores del contacto
    if (this.contactoAEditar) {
      if (this.contactoAEditar.id) {
        this.form.controls['id'].setValue(this.contactoAEditar.id);
      }
      if (this.contactoAEditar.DNI) {
        this.form.controls['DNI'].setValue(this.contactoAEditar.DNI);
      }

      if (this.contactoAEditar.apellido) {
        this.form.controls['apellido'].setValue(this.contactoAEditar.apellido);
      }

      if (this.contactoAEditar.nombre) {
        this.form.controls['nombre'].setValue(this.contactoAEditar.nombre);
      }

      if (this.contactoAEditar.telefono) {
        this.form.controls['telefono'].setValue(this.contactoAEditar.telefono);
      }

      if (this.contactoAEditar.email) {
        this.form.controls['email'].setValue(this.contactoAEditar.email);
      }

      if (this.contactoAEditar.obras) {
        let obrasArray = new UntypedFormArray([]);
        this.contactoAEditar.obras.forEach((obra, index) => {
          let elem = this.formBuilder.group({
            numeroAfiliado: new UntypedFormControl(
              <ControlState<string>>obra.numeroAfiliado,
              [Validators.required, blankSpaceValidator]
            ),
            nombreFinanciador: new UntypedFormControl(
              <ControlState<string>>obra.nombreFinanciador,
              [Validators.required, blankSpaceValidator]
            ),
          });
          obrasArray.push(elem);
        });
        this.form.controls['obras'] = obrasArray;
      }
      this.setFinanciadoresSeleccionados();
    }
    this.form.updateValueAndValidity();
  }

  addObraFinanciador() {
    const obras = this.form.controls.obras as UntypedFormArray;
    obras.push(
      this.formBuilder.group<DatosAfiliado>({
        numeroAfiliado: new UntypedFormControl('', [
          Validators.required,
          blankSpaceValidator,
        ]),
        nombreFinanciador: new UntypedFormControl(null, [
          Validators.required,
          blankSpaceValidator,
        ]),
      })
    );
  }

  deleteObraFinanciador(i: number) {
    if (i != 0) {
      const control = <UntypedFormArray>this.form.controls['obras'];
      control.removeAt(i);
    }
  }

  atras() {
    this.router.navigate(['agenda']);
    this.store.dispatch(new unsetAfiliadoRequest());
  }

  setDefaultValues(){
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.nombreAfiliado = datos.datosInicio.AfiliadoRequest?.NombreAfiliado;
      this.apellidoAfiliado = datos.datosInicio.AfiliadoRequest?.ApellidoAfiliado;
      this.dniAfiliado = datos.datosInicio.AfiliadoRequest?.DniAfiliado;
      this.emailAfiliado = datos.datosInicio.AfiliadoRequest?.EmailAfiliado;
      this.telefonoAfiliado = datos.datosInicio.AfiliadoRequest?.TelefonoAfiliado;
      this.credencialAfiliado = datos.datosInicio.AfiliadoRequest?.NumeroCredencial;
      this.financiadorAfiliado = datos.datosInicio.AfiliadoRequest?.SiglaFinanciador;
    });
    this.form.controls['nombre'].setValue(this.nombreAfiliado || '');
    this.form.controls['apellido'].setValue(this.apellidoAfiliado || '');
    this.form.controls['DNI'].setValue(this.dniAfiliado || '');
    this.form.controls['email'].setValue(this.emailAfiliado || '');
    this.form.controls['telefono'].setValue(this.telefonoAfiliado || '');
    this.form.controls['obras'].setValue([{ numeroAfiliado: this.credencialAfiliado!,  nombreFinanciador: this.financiadorAfiliado! }]);
    // this.form.controls['nombreFinanciador'].setValue(this.financiadorAfiliado || null);
  }

  guardar() {
    this.form.updateValueAndValidity(); //El status del obras no se actualiza solo hay que usar este metodo para estar seguro
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(new ActivarLoading());
    this.dialogsService.cargando();
    if (this.contactoAEditar) {
      //Se esta modificando
      this.agendaService.modificarContacto(this.form.getRawValue()).subscribe(
        (respuesta) => {
          this.dialogsService.toastSuccess(respuesta.message);
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.router.navigate(['agenda']);
        },
        (error: HttpErrorResponse) => {
          if (error.status == 406) {
            this.dialogsService.errorHTML(error.error.message).then(() => {
              this.dialogsService.cerrar();
              this.store.dispatch(new DesactivarLoading());
              this.router.navigate(['agenda']);
            });
          } else {
            this.dialogsService.cerrar();
            this.store.dispatch(new DesactivarLoading());
            this.globalService.manageError(error);
          }
        }
      );
    } else {
      //Creando nuevo usuario
      this.agendaService.guardarContacto(this.form.getRawValue()).subscribe(
        (respuesta) => {
          this.dialogsService.toastSuccess(respuesta.message);
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.router.navigate(['agenda']);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error.status == 406) {
            this.dialogsService.errorHTML(error.error.message).then(() => {
              this.dialogsService.cerrar();
              this.store.dispatch(new DesactivarLoading());
              this.router.navigate(['agenda']);
            });
          } else {
            this.dialogsService.cerrar();
            this.store.dispatch(new DesactivarLoading());
            this.globalService.manageError(error);
          }
        }
      );
    }
  }
}
