import { AbstractControl } from '@angular/forms';

export function blankSpaceValidator(control: AbstractControl) {
  if (control.value) {
    let value = control.value.toString().trim();
    if (value.length == 0) {
      return {
        errorespacioBlacn: {
          errorespacioBlacn: value,
        },
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
}
