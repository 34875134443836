import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SugerenciaModel } from '@models/sugerencia.model';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SugerenciaService {
  private subscriptionStore: Subscription = new Subscription();
  headers: HttpHeaders = new HttpHeaders({});

  constructor(private http: HttpClient,
    private store: Store<AppState>) {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (configuracionState.usuarioActual) {
          this.headers = new HttpHeaders({
            Authorization: `Bearer ${configuracionState.usuarioActual.token}`,
          });
        } else {
          this.headers = new HttpHeaders({});
        }
      });
  }

  sendSugerencia(sugerencia: SugerenciaModel): Observable<any> {
    return this.http
      .post(
        environment.url + '/usuarios/sugerencia',
        sugerencia,
        {
          headers: this.headers,
          responseType: 'text'
        }
      )
      .pipe(take(1));
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

}
