<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>

<div class="container__panelPrestacion">
  <div class="container-informar-diagnostico">
    <form [formGroup]="form" class="protoQuir">
      <div class="row">
        <div class="col">
          <div style="margin-top: 9px;">
            <input type="file" class="form-infoN" (change)="handleInputChange($event)"
              accept=".bmp, .pdf, .tif, .tiff, .jpg, .jpeg, .txt, .doc, .docx" formControlName="NombreArchivo">
          </div>
          <ng-container *ngIf="fileSize >= 2000000; then reminderError"></ng-container>
          <ng-template #reminderError>
              <small class="text-danger animated fadeIn form-text sangria">
                Solo puede subir un archivo con un tamaño menor a <strong>2Mb</strong>
              </small>
          </ng-template>
        </div>
        <div class="col">
          <div class="form-infoN">
            <label>Tipo <span class="text-danger" *ngIf="form.get('TipoArchivo')">*</span></label>
            <select class="form-controlN" formControlName="TipoArchivo">
              <!-- parche para salir a prod -->
              <option value="C" >
                Protocolo Quirúrgico
              </option>
              <option value="I" >
                Informe Médico
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <ng-container *ngIf="fileType === 'C';">
      <app-datos-nro-referencia-request [tipoC]="true"></app-datos-nro-referencia-request>
    </ng-container>
    <ng-container *ngIf="fileType === 'I'">
      <app-datos-nro-referencia-request [tipoI]="true"></app-datos-nro-referencia-request>
    </ng-container>

  </div>
</div>
