import { codigoNombreModel } from './datosInicialesExtras.models';

export class datosPerfilModel {
  nombre: string;
  email: string;
  rol: string;
  modificar: boolean;
  cuitPrestador: string;

  constructor(
    nombre: string,
    email: string,
    rol: string,
    modificar: boolean,
    cuitPrestador: string
  ) {
    this.nombre = nombre;
    this.email = email;
    this.rol = rol;
    this.modificar = modificar;
    this.cuitPrestador = cuitPrestador;
  }
}

export class modificarModel {
  nombre: string;
  apellido: string;
  email: string;
  rol: string;
  cuitPrestador: string;

  constructor(
    nombre: string,
    apellido: string,
    email: string,
    rol: string,
    cuitPrestador: string
  ) {
    this.nombre = nombre;
    this.apellido = apellido;
    this.email = email;
    this.rol = rol;
    this.cuitPrestador = cuitPrestador;
  }
}

export class DataUsuario {
  id: number;
  nombre: string;
  apellido: string;
  email: string;
  rol: string;
  cuitPrestador: string;

  constructor(
    id: number,
    nombre: string,
    apellido: string,
    email: string,
    rol: string,
    cuitPrestador: string
  ) {
    this.id = id;
    this.nombre = nombre;
    this.apellido = apellido;
    this.email = email;
    this.rol = rol;
    this.cuitPrestador = cuitPrestador;
  }
}

export class respuestaOrdenTransaccionPrestador {
  tiposTransacciones: Array<codigoNombreModel>;
  tiposTransaccionesPrestador: Array<codigoNombreModel>;

  constructor(
    tiposTransacciones: Array<codigoNombreModel>,
    tiposTransaccionesPrestador: Array<codigoNombreModel>
  ) {
    this.tiposTransacciones = tiposTransacciones;
    this.tiposTransaccionesPrestador = tiposTransaccionesPrestador;
  }
}

export class DatosUsuarioModificado {
  usuario: DataUsuario;
  usuarioModificado: DataUsuario;

  constructor(usuario: DataUsuario, usuarioModificado: DataUsuario) {
    this.usuario = usuario;
    this.usuarioModificado = usuarioModificado;
  }
}

export type operacionesModel =
  | 'EliminarUsuario'
  | 'AceptarPendiente'
  | 'AceptarCambios'
  | 'ActivarUsuario';
