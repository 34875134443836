<div id="saveMatriculaModal">
  <div class="" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text">
          El prestador "{{ prestadorNombre | lowercase }}" no puede prescribir.
          Si necesita ayuda comuníquese con soporte
        </div>
      </div>
      <div class="modal-footer">
        <button class="buttonImprimirTickets" (click)="devolverRespuesta('CERRAR')">Aceptar</button>
      </div>
    </div>
  </div>
</div>
