import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GlobalService } from '@services/global.service';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cannot-prescribe-modal',
  templateUrl: './cannot-prescribe-modal.component.html',
  styleUrls: ['./cannot-prescribe-modal.component.scss']
})
export class CannotPrescribeModalComponent implements OnInit {

  private subscriptionStore: Subscription = new Subscription();
  prestadorNombre: string = '';

  constructor(public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private location: Location,
    private store: Store<AppState>,) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.prestadorNombre = datos.datosInicio.PrestadorRequest?.RazonSocial!;
    });
  }

  devolverRespuesta(accion: string) {
    this.activeModal.close({ respuesta: accion });
  }

}
