import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { AnulacionRequestModel, DocumentacionRequestModel } from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { setDocumentacionRequest, setEstadoFormDocumentacionRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { fileExtensionValidator } from '../../../../validators/fileExtensionValidator.directive';

@Component({
  selector: 'app-datos-prot-quirurgico-request',
  templateUrl: './datos-prot-quirurgico-request.component.html',
  styleUrls: ['./datos-prot-quirurgico-request.component.scss']
})
export class DatosProtQuirurgicoRequestComponent implements OnInit {

  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;
  acceptedExtensions: string = '.bmp, .pdf, .tif, .tiff, .jpg, .jpeg, .txt, .doc, .docx';

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<DocumentacionRequestModel> =
    this.formBuilder.group<DocumentacionRequestModel>({
      NombreArchivo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator
      ]),
      OrigenArchivo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator
      ]),
      TipoArchivo: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator
      ]),
    });

  imageSrc: string = '';
  codigoTransaccion: string | null = null;
  tipoArchivos: Array<any> = [];
  fileType: string = '';
  fileSize: number = 0;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    // this.addValidators();
    this.store.dispatch(new setEstadoFormDocumentacionRequest(this.form.invalid));
  }

  addValidators(){
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02P':
          this.form.controls['NombreArchivo'].setValidators([
            Validators.required,
            blankSpaceValidator
          ]);
          this.imageSrc === '';
          this.form.updateValueAndValidity();
          this.store.dispatch(new setEstadoFormDocumentacionRequest(false));
          break;

        default:
          this.store.dispatch(new setEstadoFormDocumentacionRequest(null));
          break;
      }

    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.tipoArchivos =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoArchivo || [];
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  handleInputChange(e: any) {
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.fileSize = file.size;
    let reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    let reader = e.target;
    this.imageSrc = reader.result;

    let documentacionRequest = new DocumentacionRequestModel(
      this.imageSrc,
      this.fileType,
      '',
    );
    this.store.dispatch(new setDocumentacionRequest(documentacionRequest));
    this.store.dispatch(new setEstadoFormDocumentacionRequest(this.form.valid));
  }

  guardar() {
    let datos = this.form.getRawValue();
    this.fileType = datos.TipoArchivo;
    let documentacionRequest = new DocumentacionRequestModel(
      this.imageSrc,
      this.fileType,
      '',
    );
    this.store.dispatch(new setDocumentacionRequest(documentacionRequest));
    this.store.dispatch(new setEstadoFormDocumentacionRequest(this.form.valid));
  }

}
