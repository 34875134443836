import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-buscador-filter',
  templateUrl: './buscador-filter.component.html',
  styleUrls: ['./buscador-filter.component.scss'],
})
export class BuscadorFilterComponent implements OnInit, OnDestroy {
  @Output() changeSearch = new EventEmitter<string>();
  @ViewChild('search', { static: false }) search?: ElementRef; //Buscador
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {}

  //Metodo para el buscador
  ngAfterViewInit() {
    if (this.search) {
      fromEvent(this.search.nativeElement, 'keyup')
        .pipe(debounceTime(500))
        .pipe(takeUntil(this.destroy$))
        .subscribe((keyboardEvent) => {
          let searchTerm = (<any>keyboardEvent).target.value;
          this.changeSearch.emit(searchTerm);
          this.ref.markForCheck();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
