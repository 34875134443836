import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { InstalacionUsuario } from '@store/models/store.models';
import { lugarAtencion } from '@models/lugarAtencion.model';
import { environment } from '@environments/environment';
import { Prestador } from '@models/prestador.model';
import { codigoInstalacion } from '@models/global.models';
import { DialogsService } from './dialogs.service';
import { take } from 'rxjs/operators';
declare const window: any;
import { Observable, of, throwError } from 'rxjs';
import { IpcService } from './ipc.service';

@Injectable({
  providedIn: 'root',
})
export class InstalacionService {
  constructor(
    private http: HttpClient,
    private dialogsService: DialogsService,
    private ipcService: IpcService,
  ) {}

  get isElectron(): boolean {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      // Electron-specific code
      return true;
    } else {
      return false;
    }
  }

  getPosition(): Promise<google.maps.LatLng> {
      return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (resp) => {
            //this.ipcService.checkPosition(resp);
            resolve(
              new google.maps.LatLng(
                resp.coords.latitude,
                resp.coords.longitude
              )
            );
          },
          (err) => {
            // if (err.PERMISSION_DENIED) {
            //   this.dialogsService.advertencia(
            //     'Permiso para acceder a su ubicación',
            //     'Se necesita acceso a su ubicación para que la aplicación funcione de manera correcta'
            //   );
            // }
            console.log(err,"entro en geo");

            resolve(new google.maps.LatLng(-38.416097, -63.616672));
          },
          { maximumAge: 0, timeout: 5000, enableHighAccuracy: true }
        );
      } else {
        console.log("no entro en geo");
        resolve(new google.maps.LatLng(-38.416097, -63.616672));
      }
    });

  }

  getUsuarioInstalacion(cuit: string): Observable<InstalacionUsuario> {
    return this.http
      .get<InstalacionUsuario>(environment.url + '/config/prestadores/' + cuit)
      .pipe(take(1));
  }

  getPrestadoresDeLugarAtencion(
    licencias: Array<any>
  ): Observable<Array<Prestador>> {
    return this.http
      .post<Array<Prestador>>(environment.url + '/config/prestadores', {
        licencias: licencias,
      })
      .pipe(take(1));
  }

  getCodigoInstalacion(
    lugarAtencionSeleccionado: lugarAtencion,
    tipoPrestador: string
  ): Observable<codigoInstalacion> {
    let informacion = {
      domicilio: lugarAtencionSeleccionado.domicilio,
      licencias: lugarAtencionSeleccionado.licencias,
      tipoPrestador: tipoPrestador,
    };
    return this.http
      .post<codigoInstalacion>(environment.url + '/instalacion/', informacion)
      .pipe(take(1));
  }
}
