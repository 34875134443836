<div class="containerContactosModal">
  <main class="cardModalContacto">
    <div class="card__content" id="card__content-modal">

      <form>
        <div class="crossContainer">
          <div class="contactoHeader">
            <p class="contactoTitle">¡Nueva versión disponible!</p>
          </div>
        </div>
        <div class="form__data__contacto">
          <div>
            <div class="form__items">
              <p class="form__items__text">Una nueva actualización de Nuvalid está ahora disponible. Para continuar
                utilizando Nuvalid, por favor descargue la última versión.</p>
            </div>
          </div>
        </div>
        <div class="form__buttons">
          <button class="btnDescargar" (click)="downloadNuvalid()">Descargar</button>
        </div>
      </form>

    </div>
  </main>
</div>
