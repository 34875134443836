import * as models from '@store/models/store.models';
import * as datosDispensaActions from '@store/actions/datosDispensa.actions';

const estadoInicial: models.datosDispensaState = {
  stepMonodrogaRequest: null,
  stepEstudioRequest: null,
  monodrogaRequest: null,
  estudioRequest: null,
  medicamentoSeleccionadoRequest: null,
};

export function datosDispensaReducer(
  state = estadoInicial,
  action: datosDispensaActions.informarDatosDispensaActions): models.datosDispensaState {
  switch (action.type) {
    case datosDispensaActions.SETSTEPMONODROGAREQUEST:
      return {
        ...state,
        stepMonodrogaRequest: action.valorStep,
      };

    case datosDispensaActions.UNSETSTEPMONODROGAREQUEST:
      return {
        ...state,
        stepMonodrogaRequest: null,
      };

    case datosDispensaActions.SETSTEPESTUDIOREQUEST:
      return {
        ...state,
        stepEstudioRequest: action.valorStep,
      };

    case datosDispensaActions.UNSETSTEPESTUDIOREQUEST:
      return {
        ...state,
        stepEstudioRequest: null,
      };

    case datosDispensaActions.SETMONODROGAREQUEST:
      return {
        ...state,
        monodrogaRequest: action.monodrogaRequest,
      };

    case datosDispensaActions.UNSETMONODROGAREQUEST:
      return {
        ...state,
        monodrogaRequest: null,
      };

    case datosDispensaActions.SETESTUDIOREQUEST:
      return {
        ...state,
        estudioRequest: action.estudioRequest,
      };

    case datosDispensaActions.UNSETESTUDIOREQUEST:
      return {
        ...state,
        estudioRequest: null,
      };

    case datosDispensaActions.SETMEDICAMENTOSELECCIONADOREQUEST:
      return {
        ...state,
        medicamentoSeleccionadoRequest: action.medicamentoSeleccionadoRequest,
      };

    case datosDispensaActions.UNSETMEDICAMENTOSELECCIONADOREQUEST:
      return {
        ...state,
        medicamentoSeleccionadoRequest: null,
      };

    default:
      return state;
  }
}
