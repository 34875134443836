import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: Array<any> | null, searchText: string): Array<any> {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((element) => {
      return Object.values(element).some((val) =>
        String(val).toLowerCase().includes(searchText)
      );
    });
  }
}
