import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { roles, SignUpModel } from '@models/signup.models';
import {
  codigoInstalacion,
  redirectClass,
  tipoInstalacion,
} from '@models/global.models';
import { AppState } from '@store/app.reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GlobalService } from '@services/global.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { RegistracionService } from '@services/registracion.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogsService } from '@services/dialogs.service';
import { Router } from '@angular/router';
import { errorGenerico } from 'errores';
import { take } from 'rxjs/operators';
import { Prestador } from '@models/prestador.model';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['../../../sass/estilos.scss'],
})
export class SignupComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptionStore: Subscription = new Subscription();
  fieldTextType: boolean = false;
  submitted: boolean = false;
  MostrarSeleccionarPrestador: boolean = false;
  codigosInstalacion: Array<codigoInstalacion> | null = null;
  tipoInstalacion: tipoInstalacion | null = null;
  prestadoresLugarAtencion: Array<Prestador> = [];

  roles: Array<roles> = [];

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();
  form: IFormGroup<SignUpModel> = this.formBuilder.group<SignUpModel>(
    {
      nombre: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        blankSpaceValidator,
        Validators.minLength(4),
        Validators.maxLength(64),
      ]),
      apellido: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        blankSpaceValidator,
        Validators.minLength(4),
        Validators.maxLength(32),
      ]),
      email: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        blankSpaceValidator,
        Validators.email,
      ]),
      rol: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        blankSpaceValidator,
      ]),
      cuitPrestadorSeleccionado: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      password: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        blankSpaceValidator,
        Validators.minLength(6),
      ]),
      confirmPassword: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        blankSpaceValidator,
        Validators.minLength(6),
      ]),
      codigoInstalacion: new UntypedFormControl(<ControlState<string>>null, [
        Validators.required,
        blankSpaceValidator,
      ]),
    },
    { validator: matchingPasswords('password', 'confirmPassword') }
  );

  constructor(
    private store: Store<AppState>,
    private globalService: GlobalService,
    private registracionService: RegistracionService,
    private router: Router,
    private dialogService: DialogsService
  ) {}

  ngOnInit() {
    this.subscriptionStore = this.store
      .select('configuracion')
      .subscribe((configuracionState) => {
        if (!configuracionState.codigosInstalacion) {
          //No hay codigos de instalacion
          this.globalService.redirect(
            new redirectClass(
              'contactanos',
              errorGenerico,
              'No hay codigos de instalacion guardados en signup'
            )
          );
          return;
        }
        this.codigosInstalacion = configuracionState.codigosInstalacion;
        this.form.controls['codigoInstalacion'].setValue(
          this.codigosInstalacion[0].codigoInstalacion
        );
        this.getRoles();
        this.getPrestadoresLugarAtencion();
      });
  }

  ngAfterViewInit() {
    this.form.controls['codigoInstalacion'].valueChanges.subscribe(() => {
      this.getRoles();
      this.getPrestadoresLugarAtencion();
    });

    this.form.controls['rol'].valueChanges.subscribe((rol) => {
      if (rol == 'Prestador') {
        this.MostrarSeleccionarPrestador = true;
        this.form.controls['cuitPrestadorSeleccionado'].setValidators([
          Validators.required,
          blankSpaceValidator,
        ]);
      } else {
        this.MostrarSeleccionarPrestador = false;
        this.form.controls['cuitPrestadorSeleccionado'].setValue(null);
        this.form.controls['cuitPrestadorSeleccionado'].setValidators(null);
        this.form.controls[
          'cuitPrestadorSeleccionado'
        ].updateValueAndValidity();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  getRoles() {
    let codigoInstalacion = this.codigosInstalacion?.find(
      (x) => x.codigoInstalacion == this.form.getRawValue().codigoInstalacion
    );
    if (codigoInstalacion) {
      this.registracionService.getRolesAMostrar(codigoInstalacion).subscribe(
        (respuesta) => {
          this.roles = respuesta;
          this.form.controls['rol'].setValue(this.roles[0]);
        },
        (error: HttpErrorResponse) => {
          this.globalService.manageError(error);
        }
      );
    } else {
      this.globalService.redirect(
        new redirectClass('contactanos', errorGenerico)
      );
    }
  }

  getPrestadoresLugarAtencion() {
    this.prestadoresLugarAtencion = [];
    this.registracionService
      .getPrestadoresDeLugarAtencion(this.form.getRawValue().codigoInstalacion)
      .pipe(take(1))
      .subscribe(
        (respuesta) => {
          this.prestadoresLugarAtencion = respuesta;
        },
        (error) => {
          this.globalService.manageError(error);
        }
      );
  }

  registrar() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.getRawValue());

    this.store.dispatch(new ActivarLoading());
    this.registracionService
      .registrarUsuario(this.form.getRawValue() as SignUpModel)
      .subscribe(
        (respuesta) => {
          this.store.dispatch(new DesactivarLoading());
          if (respuesta) {
            this.dialogService.success('', respuesta.message);
          } else {
            this.dialogService.success(
              'Usuario creado',
              'Comuniquese con su administrador para que acepte su usuario'
            );
          }
          this.globalService.redirect(new redirectClass('login'));
        },
        (error: HttpErrorResponse) => {
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      );
  }
}

//Metodo que verifica que las los dos campos donde se ingresa
//la contraseña coincidan
function matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
  return (group: UntypedFormGroup): { [key: string]: any } | null => {
    let password = group.controls[passwordKey];
    let confirmPassword = group.controls[confirmPasswordKey];
    // console.log("password: " + password.value);
    // console.log("confirmPassword: " + confirmPassword.value);
    if (password.value !== confirmPassword.value) {
      return {
        mismatchedPasswords: true,
      };
    } else {
      return null;
    }
  };
}
