import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datos-autorizacion-cirugia-request',
  templateUrl: './datos-autorizacion-cirugia-request.component.html',
  styleUrls: ['./datos-autorizacion-cirugia-request.component.scss']
})
export class DatosAutorizacionCirugiaRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
