import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { Prestador } from '@models/prestador.model';
import { FinanciadorRequestModel, NroReferenciaRequestModel } from '@models/datosInicio.models';
import { setEstadoFormNroReferenciaRequest, setNroReferenciaRequest, setReferenciaRequest } from '@store/actions';


@Component({
  selector: 'app-datos-nro-referencia-request',
  templateUrl: './datos-nro-referencia-request.component.html',
  styleUrls: ['./datos-nro-referencia-request.component.scss']
})
export class DatosNroReferenciaRequestComponent implements OnInit {

  @Input() tipoC: boolean = false;
  @Input() tipoI: boolean = false;
  @Input() nOrden: boolean = false;
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  private subscriptionDatosInicio: Subscription = new Subscription();
  private subscriptionFormStatus: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;
  financiadorSeleccionado: FinanciadorRequestModel | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<NroReferenciaRequestModel> =
    this.formBuilder.group<NroReferenciaRequestModel>({
      NroReferenciaRequest: new UntypedFormControl(<ControlState<number>>null, [
        Validators.pattern('[0-9]+'),
        Validators.required,
        blankSpaceValidator,
      ]),
    });

  codigoTransaccion: string | null = null;
  NroReferencia: string | null = null;
  mostrarCargarPrescriptor: boolean = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    this.addValidators();
  }

  addValidators(){
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02P':
          this.form.controls['NroReferenciaRequest'].setValidators([
            Validators.pattern('[0-9]+'),
            Validators.required,
            blankSpaceValidator
          ]);

          this.form.updateValueAndValidity();
          this.store.dispatch(new setEstadoFormNroReferenciaRequest(false));
          break;

        default:
          this.store.dispatch(new setEstadoFormNroReferenciaRequest(null));
          break;
      }

    }
  }
  getDatos() {

    this.subscriptionDatosInicio = this.store.select('datosInicio').subscribe((datos) => {
      this.financiadorSeleccionado = datos.FinanciadorRequest;
    });

    this.subscriptionStore = this.store.subscribe((datos) => {
      this.NroReferencia =
        datos.datosTransaccion.NroReferenciaRequest;
        this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
    this.store.dispatch(new setEstadoFormNroReferenciaRequest(null));
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });

  }

  guardar() {
    let datos = this.form.getRawValue();
    let nroRequest = new NroReferenciaRequestModel(
      datos.NroReferenciaRequest
    );
    this.store.dispatch(new setReferenciaRequest(nroRequest));
    this.store.dispatch(new setEstadoFormNroReferenciaRequest(this.form.valid));
    this.store.dispatch(new setNroReferenciaRequest(datos.NroReferenciaRequest));
  }

}
