export class CredencialRequestModel {
  ModoIngreso: string;
  NroInternoPaciente: string;
  NumeroCredencial: string;
  Track: string;
  VersionCredencial: string;
  csc:string;

  constructor(
    ModoIngreso: string,
    NroInternoPaciente: string,
    NumeroCredencial: string,
    Track: string,
    VersionCredencial: string,
    csc: string
  ) {
    this.ModoIngreso = ModoIngreso;
    this.NroInternoPaciente = NroInternoPaciente;
    this.NumeroCredencial = NumeroCredencial;
    this.Track = Track;
    this.csc = csc;
    this.VersionCredencial = VersionCredencial;
  }
}

export class AfiliadoRequestModel {
  NombreAfiliado: string;
  ApellidoAfiliado: string;
  DniAfiliado: string;
  EmailAfiliado: string;
  TelefonoAfiliado: string;
  NumeroCredencial: string;
  CodigoFinanciador: string;
  CuitFinanciador: string;
  SiglaFinanciador: string;

  constructor(
    NombreAfiliado: string,
    ApellidoAfiliado: string,
    DniAfiliado: string,
    EmailAfiliado: string,
    TelefonoAfiliado: string,
    NumeroCredencial: string,
    CodigoFinanciador: string,
    CuitFinanciador: string,
    SiglaFinanciador: string
  ){
    this.NombreAfiliado = NombreAfiliado;
    this.ApellidoAfiliado = ApellidoAfiliado;
    this.DniAfiliado = DniAfiliado;
    this.EmailAfiliado = EmailAfiliado;
    this.TelefonoAfiliado = TelefonoAfiliado;
    this.NumeroCredencial = NumeroCredencial;
    this.CodigoFinanciador = CodigoFinanciador;
    this.CuitFinanciador = CuitFinanciador;
    this.SiglaFinanciador = SiglaFinanciador;
  }
}

export class FinanciadorRequestModel {
  CodigoFinanciador: string;
  CuitFinanciador: string;
  SiglaFinanciador: string;

  constructor(
    CodigoFinanciador: string,
    CuitFinanciador: string,
    SiglaFinanciador: string
  ) {
    this.CodigoFinanciador = CodigoFinanciador;
    this.CuitFinanciador = CuitFinanciador;
    this.SiglaFinanciador = SiglaFinanciador;
  }
}

export class Matricula {
  provincia: string;
  tipo: string;
  numero: string;
  descripcion: string;
  constructor(
    provincia: string,
    tipo: string,
    numero: string,
    descripcion: string,
  ) {
    this.provincia = provincia;
    this.tipo = tipo;
    this.numero = numero;
    this.descripcion = descripcion;
  }
}

export class PrestadorRequestModel {
  CodigoParaFinanciador: string;
  Licencia: string;
  CuitFinanciador: string;
  CodigoPrestador: string;
  CuitPrestador: string;
  Direccion: string;
  NroTransaccionInterno: string;
  RazonSocial: string;
  SucursalPrestador: string;
  TipoPrestador: string;
  pescribir: string;
  matriculas?: Array<Matricula>;
  telefono_verificacion?: string;
  email_verificacion?: string;
  constructor(
    CodigoParaFinanciador: string,
    Licencia: string,
    CuitFinanciador: string,
    CodigoPrestador: string,
    CuitPrestador: string,
    Direccion: string,
    NroTransaccionInterno: string,
    RazonSocial: string,
    SucursalPrestador: string,
    TipoPrestador: string,
    pescribir: string,
    matriculas: Array<Matricula>,
    telefono_verificacion: string,
    email_verificacion: string
  ) {
    this.CodigoParaFinanciador = CodigoParaFinanciador;
    this.Licencia = Licencia;
    this.CuitFinanciador = CuitFinanciador;
    this.CodigoPrestador = CodigoPrestador;
    this.CuitPrestador = CuitPrestador;
    this.Direccion = Direccion;
    this.NroTransaccionInterno = NroTransaccionInterno;
    this.RazonSocial = RazonSocial;
    this.SucursalPrestador = SucursalPrestador;
    this.TipoPrestador = TipoPrestador;
    this.pescribir = pescribir;
    this.matriculas = matriculas;
    this.telefono_verificacion = telefono_verificacion;
    this.email_verificacion = email_verificacion;
  }
}

export class TransaccionRequestModel {
  NombreTransaccion: string;
  CodigoTransaccion: string;

  constructor(NombreTransaccion: string, CodigoTransaccion: string) {
    this.NombreTransaccion = NombreTransaccion;
    this.CodigoTransaccion = CodigoTransaccion;
  }
}

export class AnulacionRequestModel {
  NroReferenciaCancelRequest: string;

  constructor(NroReferenciaCancelRequest: string) {
    this.NroReferenciaCancelRequest = NroReferenciaCancelRequest;
  }
}

export class NroReferenciaRequestModel {
  NroReferenciaRequest: string;

  constructor(NroReferenciaRequest: string) {
    this.NroReferenciaRequest = NroReferenciaRequest;
  }
}

export class PrescripcionDispensaRequestModel {
  NombrePrescripcion: string;
  CodigoPrescripcion: string;

  constructor(NombrePrescripcion: string, CodigoPrescripcion: string) {
    this.NombrePrescripcion = NombrePrescripcion;
    this.CodigoPrescripcion = CodigoPrescripcion;
  }
}
