import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataUsuario } from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-operaciones-usuario',
  templateUrl: './operaciones-usuario.component.html',
  styleUrls: ['./operaciones-usuario.component.scss'],
})
export class OperacionesUsuarioComponent implements OnInit {
  private subscriptionStore: Subscription = new Subscription();
  cargando: boolean = false;

  @Input() titulo: string | null = null;
  @Input() operacion: string | null = null;
  @Input() usuarios: Array<DataUsuario> | null = null;
  @Output() funcion = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  constructor(private location: Location, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptionStore = this.store.subscribe(
      (configuracionState: AppState) => {
        this.cargando = configuracionState.ui.loading;
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  irAtras() {
    this.location.back();
  }

  actualizar() {
    if (this.cargando) {
      return;
    }
    this.refresh.emit();
  }

  siguiente(usuario: DataUsuario) {
    this.funcion.emit(usuario);
  }
}
