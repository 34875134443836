import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { setDetallesMedicamentosRequest, unsetMedicamentoSeleccionadoRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { DetalleMedicamentosRequestModel, Item } from '../../../../models/datosTransaccion.models';

@Component({
  selector: 'app-datos-indicaciones',
  templateUrl: './datos-indicaciones.component.html',
  styleUrls: ['./datos-indicaciones.component.scss']
})
export class DatosIndicacionesComponent implements OnInit {
  subscriptionStore: Subscription = new Subscription();
  subscriptionStoreTransac: Subscription = new Subscription();
  medicamento: DetalleMedicamentosRequestModel | null = null;
  item: Item | undefined;
  precaucion: string = '';
  indicacion: string = '';
  detallesMedicamentos: Array<DetalleMedicamentosRequestModel> | any = null;
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.subscriptionStore = this.store
    .select('datosDispensa')
    .subscribe(datos => {
      this.medicamento = datos.medicamentoSeleccionadoRequest || null;
      this.item = datos.medicamentoSeleccionadoRequest?.items[0] || undefined;
      this.precaucion = datos.medicamentoSeleccionadoRequest?.precauciones || '';
      this.indicacion = datos.medicamentoSeleccionadoRequest?.posologia || '';
    });

    this.subscriptionStoreTransac = this.store
    .select('datosTransaccion')
    .subscribe(datos => {
      this.detallesMedicamentos = datos.DetalleMedicamentosRequest || [];
    });
  }

  closeModal() {
    
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this.subscriptionStore.unsubscribe();
    this.subscriptionStoreTransac.unsubscribe();
    this.store.dispatch(new unsetMedicamentoSeleccionadoRequest());
  }

  grabarIndicaciones() {
    if(this.precaucion.length > 0 || this.indicacion.length > 0) {
     /*
      let nuevoSelecionado: any = {
        ...this.medicamento, precauciones: this.precaucion, posologia: this.indicacion
      }
*/
      let nuevoArray: DetalleMedicamentosRequestModel[] = [];
     
      let index = this.detallesMedicamentos!.indexOf(this.medicamento);
      var i = 0;
      
      this.detallesMedicamentos!.forEach((item: DetalleMedicamentosRequestModel) => {
        if(i == index) {
          nuevoArray.push({...item, precauciones: this.precaucion, posologia: this.indicacion});
        } else {
          nuevoArray.push(item);
        }
        i++;
      });
      
      this.store.dispatch(new setDetallesMedicamentosRequest(nuevoArray));
      this.closeModal();
    } else {
      this.closeModal();
    }
  }
}
