<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <!-- <link rel="stylesheet" href="css/style.css"> -->
    <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>

<form [formGroup]="form" [ngClass]=" {'formContainer-anulacion' : codigoTransaccion == '03A' } ">

    <div class="form-infoN prestacion__autorizacion">
        <ng-container *ngIf="codigoTransaccion != '02C'">
            <label id="labelNAutorizacion" for="nAutorizacion">{{ nombreCampo }}<span class="span-required"
          *ngIf="form.get('CodigoPreautorizacion') | controlRequired">*</span></label>
            <input type="text" id="nAutorizacion" class="form-controlN form-controlN-dashboardP" formControlName="CodigoPreautorizacion" #codigoPreautoriza (blur)="[rellenarCampo(codigoPreautoriza.value)]" maxlength="8">
        </ng-container>
        <ng-container *ngIf="codigoTransaccion === '02Q'" ; else #otraTransaccion>
            <small class="text-danger animated fadeIn" *ngIf="form.controls['CodigoPreautorizacion'].touched && form.controls['CodigoPreautorizacion'].errors">El
      n° de orden no es válido</small>
        </ng-container>
        <ng-template #otraTransaccion>
            <small class="text-danger animated fadeIn" *ngIf="form.controls['CodigoPreautorizacion'].touched && form.controls['CodigoPreautorizacion'].errors">El
        código de preautorización no es válido</small>
        </ng-template>
    </div>

    <!--  <div class="form-row"> -->
    <!-- <div class="form-group col-12 col-md-6">
      <label>Fecha de preautorización <span class="text-danger"
          *ngIf="form.get('FechaPreautorizacion') | controlRequired">*</span></label>
      <div class="input-group">
        <input type="text" placeholder="DD/MM/YYYY" class="form-control" bsDatepicker
          [bsConfig]="{containerClass: 'theme-blue', adaptivePosition: true, isAnimated: true}"
          formControlName="FechaPreautorizacion">
      </div>
    </div> -->

    <!--  <div class="form-group col-12 col-md-6">
            <label>Código de preautorización <span class="text-danger"
    *ngIf="form.get('CodigoPreautorizacion') | controlRequired">*</span></label>
            <input type="text" class="form-control" formControlName="CodigoPreautorizacion" #codigoPreautoriza (blur)="[rellenarCampo(codigoPreautoriza.value)]" placeholder="Código de preautorización">
            <small class="text-danger animated fadeIn" *ngIf="form.controls['CodigoPreautorizacion'].touched && form.controls['CodigoPreautorizacion'].errors">El
              código de preautorización no es válido</small>
        </div> -->

</form>

</html>