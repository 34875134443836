import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { redirectClass } from '@models/global.models';
import { DataUsuario } from '@models/operaciones.models';
import { Store } from '@ngrx/store';
import { DialogsService } from '@services/dialogs.service';
import { GlobalService } from '@services/global.service';
import { OperacionesPerfilService } from '@services/operaciones-perfil.service';
import { ActivarLoading, DesactivarLoading } from '@store/actions';
import { AppState } from '@store/app.reducers';

@Component({
  selector: 'app-eliminar-usuario',
  templateUrl: './eliminar-usuario.component.html',
})
export class EliminarUsuarioComponent implements OnInit {
  titulo: string = 'Eliminar usuarios ';
  usuariosAEliminar: Array<DataUsuario> | null = null;

  constructor(
    private operacionesPerfilService: OperacionesPerfilService,
    private store: Store<AppState>,
    private globalService: GlobalService,
    private dialogsService: DialogsService,
    private location: Location
  ) {
    this.dialogsService.cargando();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(seAceptoUsuario = false) {
    //Se acepto usuario sirve para que no tire error si se acepto todos los usuarios
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService.getDatosTodosUsuarios().subscribe(
      (respuesta) => {
        this.usuariosAEliminar = respuesta;
        this.dialogsService.cerrar();
        this.store.dispatch(new DesactivarLoading());
      },
      (error: HttpErrorResponse) => {
        if (seAceptoUsuario) {
          this.usuariosAEliminar = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
        } else {
          this.usuariosAEliminar = null;
          this.dialogsService.cerrar();
          this.store.dispatch(new DesactivarLoading());
          this.globalService.manageError(error);
        }
      }
    );
  }

  eliminarUsuarios(arrayAceptarUsuarios: any) {
    this.store.dispatch(new ActivarLoading());
    this.operacionesPerfilService
      .eliminarUsuarios(arrayAceptarUsuarios)
      .subscribe(
        (respuesta) => {
          this.dialogsService.toastSuccess(respuesta.message);
          this.refresh(true);
        },
        (error: HttpErrorResponse) => {
          if (error.status == 406) {
            this.dialogsService.errorHTML(error.error.message).then(() => {
              this.location.back();
              this.store.dispatch(new DesactivarLoading());
            });
          } else {
            this.store.dispatch(new DesactivarLoading());
            this.globalService.manageError(error);
          }
        }
      );
  }
}
