import { Component, OnDestroy, OnInit } from '@angular/core';
import { DetalleProcedimientoRequestModel } from '@models/datosTransaccion.models';
import { Store } from '@ngrx/store';
import {
  setDetallesProcedimientosRequest,
  setIndexDetalleProcediminetoAEditar,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { setEstadoFormDetallesProcedimientosRequest } from '../../../../store/actions/estadoFormsinicio.action';

@Component({
  selector: 'app-datos-detalle-procedimiento-request',
  templateUrl: './datos-detalle-procedimiento-request.component.html',
  styleUrls: ['./datos-detalle-procedimiento-request.component.scss'],
})
export class DatosDetalleProcedimientoRequestComponent
  implements OnInit, OnDestroy
{
  private subscriptionStore: Subscription = new Subscription();
  detallesProcedimientos: Array<DetalleProcedimientoRequestModel> | null = null;
  constructor(private store: Store<AppState>) {
    this.store.dispatch(
      new setEstadoFormDetallesProcedimientosRequest(false)
    );
  }

  ngOnInit(): void {
    this.subscriptionStore = this.store
      .select('datosTransaccion')
      .subscribe((datos) => {
        this.detallesProcedimientos = datos.DetalleProcedimientoRequest;
      });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.store.dispatch(
      new setEstadoFormDetallesProcedimientosRequest(null)
    );
  }

  editar(index: number) {
    this.store.dispatch(new setIndexDetalleProcediminetoAEditar(index));
  }

  eliminar(index: number) {
    let detallesProcedimientos: Array<DetalleProcedimientoRequestModel> = [
      ...this.detallesProcedimientos!,
    ];
    detallesProcedimientos.splice(index, 1);
    this.store.dispatch(
      new setDetallesProcedimientosRequest(detallesProcedimientos)
    );
    if (detallesProcedimientos.length > 0) {
      this.store.dispatch(new setEstadoFormDetallesProcedimientosRequest(true));
    } else {
      this.store.dispatch(
        new setEstadoFormDetallesProcedimientosRequest(false)
      );
    }
  }
}
