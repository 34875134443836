<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>
<div class="container__panelPrestacion">
  <div class="container-efector">
    <p class="title__efector">Efector</p>
    <form [formGroup]="form" class="form__efector">
      <div class="form-infoN">
        <label for="nombreEfector">Nombre <span *ngIf="form.get('CuitEfector') | controlRequired"
            class="span-Norequired">*</span></label>
        <!-- <input type="text" id="nombreEfector" placeholder="nombres de los prestadores del lugar"
          class="form-controlN"> -->


        <select id="nombreEfector" class="form-controlN" (ngModelChange)="selectChange($event)"
          [(ngModel)]="prestadorSeleccionado" [ngModelOptions]="{standalone: true}">
          <!-- <option [ngValue]="null">
              Seleccionar un prestador
            </option> -->
          <ng-container *ngFor="let presta of prestadores">
            <option [ngValue]="presta">
              {{presta.razon_social}}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="form-infoN">
        <label for="CUITEfector">CUIT <span *ngIf="form.get('CuitEfector') | controlRequired"
            class="span-Norequired">*</span></label>

        <input readonly type="text" class="form-controlN" formControlName="CuitEfector" id="CUITEfector">
      </div>

    </form>
  </div>
</div>
<!-- <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label for="nombrePresta">Seleccione el efector <span class="text-danger"
        *ngIf="form.get('CuitEfector') | controlRequired">*</span></label>
            <select class="form-control" (ngModelChange)="selectChange($event)" [(ngModel)]="prestadorSeleccionado" [ngModelOptions]="{standalone: true}">
          <option [ngValue]="null">
            Seleccionar un prestador
          </option>
          <ng-container *ngFor="let presta of prestadores">
            <option [ngValue]="presta">
              {{presta.razon_social}}
            </option>
          </ng-container>
        </select>
        </div>

        <div class="form-group col-12 col-md-6">
            <label>CUIT del efector <span class="text-danger" *ngIf="form.get('CuitEfector') | controlRequired">*</span></label>
            <input readonly type="text" class="form-control" placeholder="CUIT del efector" formControlName="CuitEfector">
        </div> -->

<!-- <div class="form-group col-12 col-md-6">
    <form [formGroup]="form">
      <label>Provincia del efector <span class="text-danger"
          *ngIf="form.get('ProvinciaEfector') | controlRequired">*</span></label>

      <select class="form-control" formControlName="ProvinciaEfector">
        <option [ngValue]="provincia.codigo" *ngFor="let provincia of provincias">
          {{provincia.nombre}}
        </option>
      </select>
    </form>

  </div> -->

<!-- </div> -->


<!-- <form [formGroup]="form">

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Dirección del efector <span class="text-danger"
          *ngIf="form.get('Direccion') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="Direccion" placeholder="Dirección del efector">
    </div>

    <div class="form-group col-12 col-md-6">
      <label>Email del efector <span class="text-danger" *ngIf="form.get('Email') | controlRequired">*</span></label>
      <input type="text" class="form-control" formControlName="Email" placeholder="Email del efector">
    </div>

  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <label>Seleccione el tipo del efector <span class="text-danger"
          *ngIf="form.get('TipoMatriculaEfector') | controlRequired">*</span></label>

      <select class="form-control" formControlName="TipoMatriculaEfector">
        <option [ngValue]="tipoMatricula.codigo" *ngFor="let tipoMatricula of tiposMatricula">
          {{tipoMatricula.nombre}}
        </option>
      </select>
      <small class="text-danger animated fadeIn"
        *ngIf="form.controls['TipoMatriculaEfector'].touched && form.controls['TipoMatriculaEfector'].errors">Campo
        requerido</small>
    </div>

    <div class="form-group col-12 col-md-6">
      <label>Seleccione la especialidad del efector <span class="text-danger"
          *ngIf="form.get('EspecialidadEfector') | controlRequired">*</span></label>
      <app-input-especialidades [especialidades]="especialidades" (selectEspecialidad)="selectEspecialidad($event)">
      </app-input-especialidades>
    </div>
  </div>
</form> -->


</html>