<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <!-- <link rel="stylesheet" href="../../../../../sass/estilos.scss"> -->
</head>

<form [formGroup]="form" class="formContainer-anulacion">


  <div class="form-infoN">
    <label for="nTicket">N° de Ticket <span class="span-required"
        *ngIf="form.get('NroReferenciaCancelRequest') | controlRequired">*</span></label>
    <input type="text" maxlength="9" class="form-controlN" formControlName="NroReferenciaCancelRequest">
    <small *ngIf="form.controls['NroReferenciaCancelRequest'].errors && form.controls['NroReferenciaCancelRequest'].touched"
      class="text-danger animated fadeIn form-text sangria">Debe ingresar 9 caracteres numéricos sin guiones.</small>
  </div>
  <!-- <figure>
    <img src="./assets/design/docAnulacion.svg" alt="docAnulacion">
  </figure> -->


  <!-- <div class="boxShadow p-3 mt-4 bg-white rounded">
        <h5>Anular transacción</h5>
        <hr>
        <div class="form-row">
            <label class="sangria">Número del ticket a anular <span class="text-danger"
          *ngIf="form.get('NroReferenciaCancelRequest') | controlRequired">*</span></label>
            <input type="text" maxlength="9" class="form-control sangria" pattern="[0-9]+" formControlName="NroReferenciaCancelRequest">
            <small *ngIf="form.controls['NroReferenciaCancelRequest'].errors" class="text-danger animated fadeIn form-text sangria">Debe ingresar al menos 6 caracteres numéricos sin guiones.</small>
        </div> -->
  <!-- <ng-template #default> -->
  <!-- <div class="form-row colorBackground">
            <div class="form-group col-xm-12 col-lg-12">
                <label class="form-label titulo">Últimas operaciones realizadas<span class="text-danger"
              *ngIf="form.get('NroReferenciaCancelRequest') | controlRequired">*</span></label>
                <br><br> -->
  <!-- <input type="text" maxlength="9" class="form-control" pattern="[0-9]+" formControlName="NroReferenciaCancelRequest"> -->
  <!-- <select class="form-control seleccionar" aria-label="Default select example">
                <option selected>Última</option>
                <option value="1">Anterior</option>
                <option value="2">Previa</option>
                <option value="3">Antecedenteeeeeeeeeeeeeeeeeeeeeeeeee</option>
              </select> -->
  <!-- <small *ngIf="form.controls['NroReferenciaCancelRequest'].errors" class="text-danger animated fadeIn form-text ">Debe ingresar al menos 6 caracteres numéricos sin guiones.</small> -->
  <!-- </div>
        </div> -->
  <!-- </ng-template> -->

  <!-- </div> -->
</form>

</html>