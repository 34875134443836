import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { codigoNombreModel } from '@models/datosInicialesExtras.models';
import { TransaccionRequestModel } from '@models/datosInicio.models';
import { Transacciones } from '@models/transaccion.models';
import { Store } from '@ngrx/store';
import { setTransaccionRequest } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { setEstadoFormTransaccionRequest, setEstadoFormPrescripcionDispensaRequest } from '../../../store/actions/estadoFormsinicio.action';

@Component({
  selector: 'app-card-transaccion',
  templateUrl: './card-transaccion.component.html',
  styleUrls: ['./card-transaccion.component.scss'],
})
export class CardTransaccionComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  @Input() transaccion: codigoNombreModel | null = null;
  seleccionado: boolean = false; // Para saber si es la transaccion seleccionada y pinta el cuadro
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {

    this.cargarDatos();
    this.store.dispatch(new setEstadoFormTransaccionRequest(false));
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    console.log('holaaaaaaa destroyyyyyyyyyyy');
  }

  cargarDatos() {
    this.subscriptionStore = this.store
      .select('datosInicio')
      .subscribe((datos) => {
        this.seleccionado =
          datos.TransaccionRequest?.NombreTransaccion ==
          this.transaccion?.nombre;
      });
  }

  seleccionarTransaccion() {
    if (this.transaccion) {
      let transaccion = new TransaccionRequestModel(
        this.transaccion.nombre,
        this.transaccion.codigo
      );
      if(this.transaccion?.codigo === '02R') {
        this.store.dispatch(new setEstadoFormPrescripcionDispensaRequest(true));
      } else {
        this.store.dispatch(new setEstadoFormPrescripcionDispensaRequest(null));
      }
      this.store.dispatch(new setEstadoFormTransaccionRequest(true));
      this.store.dispatch(new setTransaccionRequest(transaccion));
    } else {
      this.store.dispatch(new setEstadoFormPrescripcionDispensaRequest(false));
      this.store.dispatch(new setEstadoFormTransaccionRequest(false));
      let transaccion = new TransaccionRequestModel('', '');
      this.store.dispatch(new setTransaccionRequest(transaccion));
    }
  }
}
