<div *ngIf="isElectron" [ngClass]="{'menu-bar-turnea': esTurnea, 'menu-bar': !esTurnea}">
      <div class="left" role="menu"  *ngIf="!esTurnea">
          <button *ngIf="isLogin && mostrandoMenu" class="menubar-btn" id="menu-btn" (click)="cerrarMenu()">
              <!-- <fa-icon [icon]='["fas", "bars"]'></fa-icon> -->
              <img src="./assets/design/arrowOpenNav.svg" alt="MenuBurger" id="MenuBurger">

          </button>
          <button *ngIf="isLogin && !mostrandoMenu" class="menubar-btn" id="menu-btn" (click)="abrirMenu()">
              <!-- <fa-icon [icon]='["fas", "bars"]'></fa-icon> -->
              <img src="./assets/design/burger.svg" alt="MenuBurger" id="MenuBurger">

          </button>

          <div class="container__DataUser">
              <div *ngIf="isLogin" class="dataUserL">
                  <h2 class="dataUser__title">Hola, <span class="dataUser__titleU">{{nombreUsuario}}
                          {{apellidoUsuario}}</span></h2>
                  <!-- <figure>
                      <div class="dropdown" id="dropdown">
                          <a type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false"><img src="./assets/design/user.svg" alt="User" class="DataUserR-user"
                                  title="Usuario"></a>
                          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item">Perfil</a>
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" (click)="salir()">Cerrar sesión</a>
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item disabled">Versión: {{versionActual}}</a>
                          </div>
                      </div>
                  </figure> -->
              </div>

          </div>

      </div>
      <div class="left" role="menu" *ngIf="esTurnea">
        <button class="menubar-btn" id="menu-btn" (click)="nuvalid()">
          <!-- <fa-icon [icon]='["fas", "bars"]'></fa-icon> -->
          <img src="./assets/design/arrowOpenNavTurnea.svg" alt="MenuBurger" id="MenuBurger">

        </button>
        <div class="container__DataUser">
          <h2 class="dataUser__title">Ir a Nuvalid</h2>
        </div>


      </div>
      <div class="right ">
          <div *ngIf="isLogin && !esTurnea" class="dataUserR dataUserR_domicilio">
              <figure>
                  <img src="./assets/design/map.svg" alt="Map" title="Dirección">
              </figure>
              <p>{{domicilioUsuario}}</p>

          </div>
          <button class="menubar-btn" id="minimize-btn" (click)="minimizarAplicacion()">
              <!-- <fa-icon [icon]='["fas", "window-minimize"]'></fa-icon> -->
              <img *ngIf="!esTurnea" src="./assets/design/min.svg" alt="MenuMin">
              <img *ngIf="esTurnea" src="./assets/design/minTurnea.svg" alt="MenuMin">

          </button>
          <button class="menubar-btn" id="max-unmax-btn" (click)="maximizarAplicacion()">
              <!-- <fa-icon [icon]='["fas", "window-maximize"]'></fa-icon> -->
              <img *ngIf="!esTurnea" src="./assets/design/max.svg" alt="MenuMax">
              <img *ngIf="esTurnea" src="./assets/design/maxTurnea.svg" alt="MenuMax">

          </button>
          <button class="menubar-btn" id="close-btn" (click)="cerrarAplicacion()">
              <!-- <fa-icon [icon]='["fas", "times"]'></fa-icon> -->
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                  id="iconClosed">
                  <g clip-path="url(#clip0_1555_8129)">
                      <path
                          d="M10.2027 7.49678L14.4387 3.26389C14.7976 2.90504 14.9992 2.41833 14.9992 1.91083C14.9992 1.40333 14.7976 0.916625 14.4387 0.557771C14.0799 0.198917 13.5932 -0.00268554 13.0857 -0.00268555C12.5782 -0.00268556 12.0915 0.198915 11.7326 0.557769L7.49973 4.79385L3.26365 0.557769C2.90479 0.198915 2.41808 -0.00268556 1.91059 -0.00268555C1.40309 -0.00268554 0.916378 0.198917 0.557524 0.557771C0.198671 0.916625 -0.00292968 1.40333 -0.00292969 1.91083C-0.0029297 2.41833 0.198671 2.90504 0.557524 3.26389L4.7936 7.49678L0.557524 11.7329C0.198671 12.0917 -0.00292969 12.5784 -0.00292969 13.0859C-0.00292969 13.5934 0.198671 14.0801 0.557524 14.439C0.916378 14.7978 1.40309 14.9994 1.91059 14.9994C2.41808 14.9994 2.90479 14.7978 3.26365 14.439L7.49973 10.2029L11.7326 14.439C12.0915 14.7978 12.5782 14.9994 13.0857 14.9994C13.5932 14.9994 14.0799 14.7978 14.4387 14.439C14.7976 14.0801 14.9992 13.5934 14.9992 13.0859C14.9992 12.5784 14.7976 12.0917 14.4387 11.7329L10.2027 7.49678Z"
                          fill="currentcolor" />
                  </g>
                  <defs>
                      <clipPath id="clip0_1555_8129">
                          <rect width="15" height="15" fill="white" />
                      </clipPath>
                  </defs>
              </svg>

          </button>
      </div>
</div>

