import { Action } from '@ngrx/store';

export const SETESTADOFORMCREDENCIALREQUESTFORM =
  '[datosInicio] Set estadoFormcredencialRequestForm';

export const SETESTADOFORMCODIGODESEGURIDADREQUESTFORM =
  '[datosInicio] Set SETESTADOFORMCODIGODESEGURIDADREQUESTFORM';

export const SETESTADOFORMFINANCIADORREQUEST =
  '[datosInicio] Set estadoFormfinanciador';

export const SETESTADOFORMPRESTADORREQUEST =
  '[datosInicio] Set estadoFormprestador';

export const SETESTADOFORMTRANSACCIONREQUEST =
  '[datosInicio] Set estadoFormtransaccion';

export const SETESTADOFORMDETALLEMEDICAMENTOREQUEST =
  '[datosTransacciones] SetESTADOFORM DETALLEMEDICAMENTOREQUEST';

export const SETESTADOFORMDETALLEPROCEDIMIENTOSREQUEST =
  '[datosTransacciones] SetESTADOFORM DETALLEPROCEDIMIENTOSREQUEST';

export const SETESTADOFORMACOMPANNANTEREQUEST =
  '[datosTransacciones] SetESTADOFORM ACOMPANNANTEREQUEST';

export const SETESTADOFORMATENCIONREQUEST =
  '[datosTransacciones] SetESTADOFORM ATENCIONREQUEST';

export const SETESTADOFORMBENEFICIARIOREQUEST =
  '[datosTransacciones] SetESTADOFORM BENEFICIARIOREQUEST';

export const SETESTADOFORMCODFINALIZACIONTRATAMIENTO =
  '[datosTransacciones] SetESTADOFORM CODFINALIZACIONTRATAMIENTO';

export const SETESTADOFORMDERIVACIONREQUEST =
  '[datosTransacciones] SetESTADOFORM DERIVACIONREQUEST';

export const SETESTADOFORMDIAGNOSTICOSREQUEST =
  '[datosTransacciones] SetESTADOFORM DIAGNOSTICOSREQUEST';

export const SETESTADOFORMINFORMARDIAGNOSTICOREQUEST =
  '[datosTransacciones] SetESTADOFORM INFORMARDIAGNOSTICOREQUEST';

export const SETESTADOFORMDOCUMENTACIONREQUEST =
  '[datosTransacciones] SetESTADOFORM DOCUMENTACIONREQUEST';

export const SETESTADOFORMEFECTORREQUEST =
  '[datosTransacciones] SetESTADOFORM EFECTORREQUEST';

export const SETESTADOFORMMENSAJEPARAFINANCIADOR =
  '[datosTransacciones] SetESTADOFORM MENSAJEPARAFINANCIADOR';

export const SETESTADOFORMPREAUTORIZACIONREQUEST =
  '[datosTransacciones] SetESTADOFORM PREAUTORIZACIONREQUEST';

export const SETESTADOFORMPRESCRIPCIONREQUEST =
  '[datosTransacciones] SetESTADOFORM PRESCRIPCIONREQUEST';

export const SETESTADOFORMPRESCRIPTORREQUEST =
  '[datosTransacciones] SetESTADOFORM PRESCRIPTORREQUEST';

export const SETESTADOFORMFECHAREFERENCIACANCELREQUEST =
  '[datosTransacciones] SetESTADOFORM FECHAREFERENCIACANCELREQUEST';

export const SETESTADOFORMIDMSJREQUEST =
  '[datosTransacciones] SetESTADOFORM IDMSJREQUEST';

export const SETESTADOFORMINICIOTRXREQUEST =
  '[datosTransacciones] SetESTADOFORM INICIOTRXREQUEST';

export const SETESTADOFORMNROREFERENCIACANCELREQUEST =
  '[datosTransacciones] SetESTADOFORM NROREFERENCIACANCELREQUEST';

export const SETESTADOFORMNROREFERENCIAREQUEST =
  '[datosTransacciones] SetESTADOFORM NROREFERENCIAREQUEST';

export const SETESTADOFORMSOFTWAREREQUEST =
  '[datosTransacciones] SetESTADOFORM SOFTWAREREQUEST';

export const SETESTADOFORMTERMINALREQUEST =
  '[datosTransacciones] SetESTADOFORM TERMINALREQUEST';

export const SETESTADOFORMTIPOTRANSACCIONREQUEST =
  '[datosTransacciones] SetESTADOFORM TIPOTRANSACCIONREQUEST';

export const SETESTADOFORMVERSIONMSJREQUEST =
  '[datosTransacciones] SetESTADOFORM VERSIONMSJREQUEST';

export const UNSETESTADOFORMDATOSCASITODOS =
  '[datosTransacciones] unsetESTADOFROM datos casi todos';

export const UNSETESTADOFORMDATOS =
  '[datosTransacciones] unsetESTADOFROM datos';

export const SETESTADOFORMPRESCRIPCIONDISPENSAREQUEST =
  '[datosTransacciones] SetESTADOFORM SETESTADOFORMPRESCRIPCIONDISPENSAREQUEST';

export const SETESTADOFORMDATOSENVIOPRESCRIPCIONEMAILBEREQUEST =
  '[datosTransacciones] SetESTADOFORM SETESTADOFORMDATOSENVIOPRESCRIPCIONEMAILBEREQUEST';

export const SETESTADOFORMDATOSENVIOPRESCRIPCIONDIAGREQUEST =
  '[datosTransacciones] SetESTADOFORM SETESTADOFORMDATOSENVIOPRESCRIPCIONDIAGREQUEST';

export const SETESTADOFORMFIRMAREQUEST =
  '[datosTransacciones] SetESTADOFORM SETESTADOFORMFIRMAREQUEST';


export class setEstadoFormCredencialRequest implements Action {
  readonly type = SETESTADOFORMCREDENCIALREQUESTFORM;
  constructor(public formEstadoCredencialRequest: boolean | null) { }
}

export class setEstadoFormCodigoDeSeguridadRequest implements Action {
  readonly type = SETESTADOFORMCODIGODESEGURIDADREQUESTFORM;
  constructor(public formEstadoCodigoDeSeguridadRequest: boolean | null) { }
}
export class setEstadoFormFinanciadorRequest implements Action {
  readonly type = SETESTADOFORMFINANCIADORREQUEST;
  constructor(public formEstadoFinanciadorRequest: boolean | null) { }
}
export class setEstadoFormPrestadorRequest implements Action {
  readonly type = SETESTADOFORMPRESTADORREQUEST;
  constructor(public formEstadoPrestadorRequest: boolean | null) { }
}
export class setEstadoFormTransaccionRequest implements Action {
  readonly type = SETESTADOFORMTRANSACCIONREQUEST;
  constructor(public formEstadoTransaccionRequest: boolean | null) { }
}

export class setEstadoFormDetallesMedicamentosRequest implements Action {
  readonly type = SETESTADOFORMDETALLEMEDICAMENTOREQUEST;
  constructor(public formEstadoDetalleMedicamentosRequest: boolean | null) { }
}

export class setEstadoFormDetallesProcedimientosRequest implements Action {
  readonly type = SETESTADOFORMDETALLEPROCEDIMIENTOSREQUEST;
  constructor(public formEstadoDetalleProcedimientoRequest: boolean | null) { }
}

export class setEstadoFormAcompannanteRequest implements Action {
  readonly type = SETESTADOFORMACOMPANNANTEREQUEST;
  constructor(public formEstadoAcompannanteRequest: boolean | null) { }
}

export class setEstadoFormAtencionRequest implements Action {
  readonly type = SETESTADOFORMATENCIONREQUEST;
  constructor(public formEstadoAtencionRequest: boolean | null) { }
}
export class setEstadoFormBeneficiarioRequest implements Action {
  readonly type = SETESTADOFORMBENEFICIARIOREQUEST;
  constructor(public formEstadoBeneficiarioRequest: boolean | null) { }
}

export class setEstadoFormCodFinalizacionTratamiento implements Action {
  readonly type = SETESTADOFORMCODFINALIZACIONTRATAMIENTO;
  constructor(
    public formEstadoCodFinalizacionTratamientoRequest: boolean | null
  ) { }
}

export class setEstadoFormDerivacionRequest implements Action {
  readonly type = SETESTADOFORMDERIVACIONREQUEST;
  constructor(public formEstadoDerivacionRequest: boolean | null) { }
}

export class setEstadoFormDiagnosticosRequest implements Action {
  readonly type = SETESTADOFORMDIAGNOSTICOSREQUEST;
  constructor(public formEstadoDiagnosticosRequest: boolean | null) { }
}

export class setEstadoFormDocumentacionRequest implements Action {
  readonly type = SETESTADOFORMDOCUMENTACIONREQUEST;
  constructor(public formEstadoDocumentacionRequest: boolean | null) { }
}

export class setEstadoFormEfectorRequest implements Action {
  readonly type = SETESTADOFORMEFECTORREQUEST;
  constructor(public formEstadoEfectorRequest: boolean | null) { }
}

export class setEstadoFormInformarDiagnosticoRequest implements Action {
  readonly type = SETESTADOFORMINFORMARDIAGNOSTICOREQUEST;
  constructor(public formEstadoInformarDiagnosticoRequest: boolean | null) { }
}

export class setEstadoFormMensajeParaFinanciador implements Action {
  readonly type = SETESTADOFORMMENSAJEPARAFINANCIADOR;
  constructor(public formEstadoMensajeParaFinanciadorRequest: boolean | null) { }
}

export class setEstadoFormPreautorizacionRequest implements Action {
  readonly type = SETESTADOFORMPREAUTORIZACIONREQUEST;
  constructor(public formEstadoPreautorizacionRequest: boolean | null) { }
}

export class setEstadoFormPrescripcionRequest implements Action {
  readonly type = SETESTADOFORMPRESCRIPCIONREQUEST;
  constructor(public formEstadoPrescripcionRequest: boolean | null) { }
}

export class setEstadoFormPrescriptorRequest implements Action {
  readonly type = SETESTADOFORMPRESCRIPTORREQUEST;
  constructor(public formEstadoPrescriptorRequest: boolean | null) { }
}

export class setEstadoFormfechaReferenciaCancelRequest implements Action {
  readonly type = SETESTADOFORMFECHAREFERENCIACANCELREQUEST;
  constructor(public formEstadoFechaReferenciaCancelRequest: boolean | null) { }
}

export class setEstadoFormIdMsjRequest implements Action {
  readonly type = SETESTADOFORMIDMSJREQUEST;
  constructor(public formEstadoIdMsjRequest: boolean | null) { }
}

export class setEstadoFormInicioTrxRequest implements Action {
  readonly type = SETESTADOFORMINICIOTRXREQUEST;
  constructor(public formEstadoInicioTrxRequest: boolean | null) { }
}

export class setEstadoFormNroReferenciaCancelRequest implements Action {
  readonly type = SETESTADOFORMNROREFERENCIACANCELREQUEST;
  constructor(public formEstadoNroReferenciaCancelRequest: boolean | null) { }
}
export class setEstadoFormNroReferenciaRequest implements Action {
  readonly type = SETESTADOFORMNROREFERENCIAREQUEST;
  constructor(public formEstadoNroReferenciaRequest: boolean | null) { }
}
export class setEstadoFormSoftwareRequest implements Action {
  readonly type = SETESTADOFORMSOFTWAREREQUEST;
  constructor(public formEstadoSoftwareRequest: boolean | null) { }
}

export class setEstadoFormTerminalRequest implements Action {
  readonly type = SETESTADOFORMTERMINALREQUEST;
  constructor(public formEstadoTerminalRequest: boolean | null) { }
}

export class setEstadoFormTipoTransaccionRequest implements Action {
  readonly type = SETESTADOFORMTIPOTRANSACCIONREQUEST;
  constructor(public formEstadoTipoTransaccionRequest: boolean | null) { }
}

export class setEstadoFormVersionMsjRequest implements Action {
  readonly type = SETESTADOFORMVERSIONMSJREQUEST;
  constructor(public formEstadoVersionMsjRequest: boolean | null) { }
}

export class unsetEstadoFormDatosCasiTodos implements Action {
  readonly type = UNSETESTADOFORMDATOSCASITODOS;
}
export class unsetEstadoFormDatos implements Action {
  readonly type = UNSETESTADOFORMDATOS;
}
export class setEstadoFormPrescripcionDispensaRequest implements Action {
  readonly type = SETESTADOFORMPRESCRIPCIONDISPENSAREQUEST;
  constructor(public formEstadoPrescripcionDispensaRequest: boolean | null) { }
}

export class setEstadoFormDatosEnvioPrescripcionEmailBeRequest implements Action {
  readonly type = SETESTADOFORMDATOSENVIOPRESCRIPCIONEMAILBEREQUEST;
  constructor(public formEstadoDatosEnvioPrescripcionEmailBeRequest: boolean | null) { }
}

export class setEstadoFormDatosEnvioPrescripcionDiagRequest implements Action {
  readonly type = SETESTADOFORMDATOSENVIOPRESCRIPCIONDIAGREQUEST;
  constructor(public formEstadoDatosEnvioPrescripcionDiagRequest: boolean | null) { }
}

export class setEstadoFormFirmaRequest implements Action {
  readonly type = SETESTADOFORMFIRMAREQUEST;
  constructor(public formEstadoFirmaRequest: boolean | null) { }
}

export type estadoFormsActions =
  | setEstadoFormCredencialRequest
  | setEstadoFormCodigoDeSeguridadRequest
  | setEstadoFormFinanciadorRequest
  | setEstadoFormPrestadorRequest
  | setEstadoFormTransaccionRequest
  | setEstadoFormDetallesMedicamentosRequest
  | setEstadoFormDetallesProcedimientosRequest
  | setEstadoFormAcompannanteRequest
  | setEstadoFormAtencionRequest
  | setEstadoFormBeneficiarioRequest
  | setEstadoFormCodFinalizacionTratamiento
  | setEstadoFormDerivacionRequest
  | setEstadoFormDiagnosticosRequest
  | setEstadoFormDocumentacionRequest
  | setEstadoFormEfectorRequest
  | setEstadoFormInformarDiagnosticoRequest
  | setEstadoFormMensajeParaFinanciador
  | setEstadoFormPreautorizacionRequest
  | setEstadoFormPrescripcionRequest
  | setEstadoFormPrescriptorRequest
  | setEstadoFormfechaReferenciaCancelRequest
  | setEstadoFormIdMsjRequest
  | setEstadoFormInicioTrxRequest
  | setEstadoFormNroReferenciaCancelRequest
  | setEstadoFormNroReferenciaRequest
  | setEstadoFormSoftwareRequest
  | setEstadoFormTerminalRequest
  | setEstadoFormTipoTransaccionRequest
  | setEstadoFormVersionMsjRequest
  | unsetEstadoFormDatosCasiTodos
  | unsetEstadoFormDatos
  | setEstadoFormPrescripcionDispensaRequest
  | setEstadoFormDatosEnvioPrescripcionEmailBeRequest
  | setEstadoFormDatosEnvioPrescripcionDiagRequest
  | setEstadoFormFirmaRequest;
