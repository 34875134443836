import * as models from '@store/models/store.models';
import * as datosTransaccionesActions from '@store/actions/datosTransacciones.actions';

const estadoInicial: models.datosTransaccionesState = {
  DetalleMedicamentosRequest: null,
  DetalleEstudiosRequest: null,
  extraDispensaRequest: null,
  DetalleProcedimientoRequest: null,
  AcompannanteRequest: null,
  AtencionRequest: null,
  BeneficiarioRequest: null,
  CodFinalizacionTratamientoRequest: null,
  DerivacionRequest: null,
  DiagnosticosRequest: null,
  DocumentacionRequest: null,
  EfectorRequest: null,
  MensajeParaFinanciadorRequest: null,
  PreautorizacionRequest: null,
  PrescripcionRequest: null,
  PrescriptorRequest: null,
  FechaReferenciaCancelRequest: null,
  IdMsjRequest: null,
  InicioTrxRequest: null,
  NroReferenciaCancelRequest: null,
  NroReferenciaRequest: null,
  SoftwareRequest: null,
  TerminalRequest: null,
  TipoTransaccionRequest: null,
  VersionMsjRequest: null
};

export function datosTransaccionesReducer(
  state = estadoInicial,
  action: datosTransaccionesActions.informarPrestacionActions
): models.datosTransaccionesState {
  switch (action.type) {
    case datosTransaccionesActions.SETEXTRADISPENSAREQUEST:
      return {
        ...state,
        extraDispensaRequest: action.extraDispensaRequest,
      };

    case datosTransaccionesActions.UNSETEXTRADISPENSAREQUEST:
      return {
        ...state,
        extraDispensaRequest: null,
      };

    case datosTransaccionesActions.SETDETALLEMEDICAMENTOREQUEST:
      return {
        ...state,
        DetalleMedicamentosRequest: action.DetalleMedicamentosRequest,
      };

    case datosTransaccionesActions.UNSETDETALLEMEDICAMENTOREQUEST:
      return {
        ...state,
        DetalleMedicamentosRequest: null,
      };

    case datosTransaccionesActions.SETDETALLESESTUDIOSREQUEST:
      return {
        ...state,
        DetalleEstudiosRequest: action.DetalleEstudiosRequest,
      };

    case datosTransaccionesActions.UNSETDETALLESESTUDIOSREQUEST:
      return {
        ...state,
        DetalleEstudiosRequest: null,
      };

    case datosTransaccionesActions.SETDETALLEPROCEDIMIENTOSREQUEST:
      return {
        ...state,
        DetalleProcedimientoRequest: action.DetalleProcedimientoRequest,
      };

    case datosTransaccionesActions.UNSETDETALLEMEDICAMENTOREQUEST:
      return {
        ...state,
        DetalleProcedimientoRequest: null,
      };

    case datosTransaccionesActions.SETACOMPANNANTEREQUEST:
      return {
        ...state,
        AcompannanteRequest: action.AcompannanteRequest,
      };

    case datosTransaccionesActions.UNSETACOMPANNANTEREQUEST:
      return {
        ...state,
        AcompannanteRequest: null,
      };

    case datosTransaccionesActions.SETATENCIONREQUEST:
      return {
        ...state,
        AtencionRequest: action.AtencionRequest,
      };

    case datosTransaccionesActions.UNSETATENCIONREQUEST:
      return {
        ...state,
        AtencionRequest: null,
      };

    case datosTransaccionesActions.SETBENEFICIARIOREQUEST:
      return {
        ...state,
        BeneficiarioRequest: action.BeneficiarioRequest,
      };

    case datosTransaccionesActions.UNSETBENEFICIARIOREQUEST:
      return {
        ...state,
        BeneficiarioRequest: null,
      };

    case datosTransaccionesActions.SETCODFINALIZACIONTRATAMIENTO:
      return {
        ...state,
        CodFinalizacionTratamientoRequest:
          action.CodFinalizacionTratamientoRequest,
      };

    case datosTransaccionesActions.SETDERIVACIONREQUEST:
      return {
        ...state,
        DerivacionRequest: action.DerivacionRequest,
      };

    case datosTransaccionesActions.UNSETDERIVACIONREQUEST:
      return {
        ...state,
        DerivacionRequest: null,
      };

    case datosTransaccionesActions.SETDIAGNOSTICOSREQUEST:
      return {
        ...state,
        DiagnosticosRequest: action.DiagnosticosRequest,
      };

    case datosTransaccionesActions.UNSETDIAGNOSTICOSREQUEST:
      return {
        ...state,
        DiagnosticosRequest: null,
      };

    case datosTransaccionesActions.SETDOCUMENTACIONREQUEST:
      return {
        ...state,
        DocumentacionRequest: action.DocumentacionRequest,
      };

    case datosTransaccionesActions.UNSETDOCUMENTACIONREQUEST:
      return {
        ...state,
        DocumentacionRequest: null,
      };

    case datosTransaccionesActions.SETEFECTORREQUEST:
      return {
        ...state,
        EfectorRequest: action.EfectorRequest,
      };

    case datosTransaccionesActions.UNSETEFECTORREQUEST:
      return {
        ...state,
        EfectorRequest: null,
      };

    case datosTransaccionesActions.SETMENSAJEPARAFINANCIADOR:
      return {
        ...state,
        MensajeParaFinanciadorRequest: action.MensajeParaFinanciadorRequest,
      };

    case datosTransaccionesActions.SETPREAUTORIZACIONREQUEST:
      return {
        ...state,
        PreautorizacionRequest: action.PreautorizacionRequest,
      };

    case datosTransaccionesActions.UNSETPREAUTORIZACIONREQUEST:
      return {
        ...state,
        PreautorizacionRequest: null,
      };

    case datosTransaccionesActions.SETPRESCRIPCIONREQUEST:
      return {
        ...state,
        PrescripcionRequest: action.PrescripcionRequest,
      };

    case datosTransaccionesActions.UNSETPRESCRIPCIONREQUEST:
      return {
        ...state,
        PrescripcionRequest: null,
      };

    case datosTransaccionesActions.SETPRESCRIPTORREQUEST:
      return {
        ...state,
        PrescriptorRequest: action.PrescriptorRequest,
      };

    case datosTransaccionesActions.UNSETPRESCRIPTORREQUEST:
      return {
        ...state,
        PrescriptorRequest: null,
      };

    case datosTransaccionesActions.SETFECHAREFERENCIACANCELREQUEST:
      return {
        ...state,
        FechaReferenciaCancelRequest: action.FechaReferenciaCancelRequest,
      };

    case datosTransaccionesActions.UNSETFECHAREFERENCIACANCELREQUEST:
      return {
        ...state,
        FechaReferenciaCancelRequest: null,
      };

    case datosTransaccionesActions.SETIDMSJREQUEST:
      return {
        ...state,
        IdMsjRequest: action.IdMsjRequest,
      };

    case datosTransaccionesActions.UNSETIDMSJREQUEST:
      return {
        ...state,
        IdMsjRequest: null,
      };

    case datosTransaccionesActions.SETINICIOTRXREQUEST:
      return {
        ...state,
        InicioTrxRequest: action.InicioTrxRequest,
      };

    case datosTransaccionesActions.UNSETINICIOTRXREQUEST:
      return {
        ...state,
        InicioTrxRequest: null,
      };

    case datosTransaccionesActions.SETNROREFERENCIACANCELREQUEST:
      return {
        ...state,
        NroReferenciaCancelRequest: action.NroReferenciaCancelRequest,
      };

    case datosTransaccionesActions.UNSETNROREFERENCIACANCELREQUEST:
      return {
        ...state,
        NroReferenciaCancelRequest: null,
      };

    case datosTransaccionesActions.SETNROREFERENCIAREQUEST:
      return {
        ...state,
        NroReferenciaRequest: action.NroReferenciaRequest,
      };

    case datosTransaccionesActions.UNSETNROREFERENCIAREQUEST:
      return {
        ...state,
        NroReferenciaRequest: null,
      };

    case datosTransaccionesActions.SETSOFTWAREREQUEST:
      return {
        ...state,
        SoftwareRequest: action.SoftwareRequest,
      };

    case datosTransaccionesActions.UNSETSOFTWAREREQUEST:
      return {
        ...state,
        SoftwareRequest: null,
      };

    case datosTransaccionesActions.SETTERMINALREQUEST:
      return {
        ...state,
        TerminalRequest: action.TerminalRequest,
      };

    case datosTransaccionesActions.UNSETTERMINALREQUEST:
      return {
        ...state,
        TerminalRequest: null,
      };

    case datosTransaccionesActions.SETTIPOTRANSACCIONREQUEST:
      return {
        ...state,
        TipoTransaccionRequest: action.TipoTransaccionRequest,
      };

    case datosTransaccionesActions.SETVERSIONMSJREQUEST:
      return {
        ...state,
        VersionMsjRequest: action.VersionMsjRequest,
      };

    case datosTransaccionesActions.UNSETDATOSCASITODOS:
      return {
        ...estadoInicial,
        DetalleProcedimientoRequest: state.DetalleProcedimientoRequest,
        PrescriptorRequest: state.PrescriptorRequest,
        EfectorRequest: state.EfectorRequest,
      };

    case datosTransaccionesActions.UNSETDATOSTRANSACCIONES:
      return estadoInicial;

    default:
      return state;
  }
}
