import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import {
  AcompannanteRequestModel,
  BeneficiarioRequestModel,
} from '@models/datosTransaccion.models';
import { Prestador } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { ControlState, IFormBuilder, IFormGroup } from '@rxweb/types';
import {
  setAcompannanteRequest,
  setBeneficiarioRequest,
  setEstadoFormAcompannanteRequest,
  setEstadoFormBeneficiarioRequest,
} from '@store/actions';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-beneficiario-request',
  templateUrl: './datos-beneficiario-request.component.html',
  styleUrls: ['./datos-beneficiario-request.component.scss'],
})
export class DatosBeneficiarioRequestComponent implements OnInit, OnDestroy {
  private subscriptionStore: Subscription = new Subscription();
  private subscriptionForm: Subscription = new Subscription();
  prestadorSeleccionado: Prestador | null = null;

  //Reactive forms tipeados
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  form: IFormGroup<BeneficiarioRequestModel> =
    this.formBuilder.group<BeneficiarioRequestModel>({
      ApellidoBeneficiario: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Direccion: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Email: new UntypedFormControl(<ControlState<string>>null, [blankSpaceValidator]),
      NombreBeneficiario: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      NroDocBeneficiario: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Parentesco: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      Telefono: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ]),
      TipoDocBeneficiario: new UntypedFormControl(<ControlState<string>>null, [
        blankSpaceValidator,
      ])
    });

  tiposDocumentos: Array<any> = [];
  tiposParentescos: Array<any> = [];
  codigoTransaccion: string | null = null;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.getDatos();
    this.listenCambios();
    if (this.codigoTransaccion) {
      switch (this.codigoTransaccion) {
        case '02A':
          // this.form.controls['TipoPrescriptor'].setValidators(
          //   Validators.required
          // );
          break;

        default:
          break;
      }
      this.store.dispatch(
        new setEstadoFormBeneficiarioRequest(this.form.valid)
      );
    }
  }

  getDatos() {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
      this.tiposDocumentos =
        datos.datosInicialesExtra.datosInicialesExtra?.tipoDocumento || [];
      this.tiposParentescos =
        datos.datosInicialesExtra.datosInicialesExtra?.parentesco || [];
    });
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
    this.subscriptionForm.unsubscribe();
  }

  listenCambios() {
    this.subscriptionForm = this.form.valueChanges.subscribe((form) => {
      this.guardar();
    });
  }

  guardar() {
    let datos = this.form.getRawValue();
    let beneficiarioRequest = new BeneficiarioRequestModel(
      datos.ApellidoBeneficiario,
      datos.Direccion,
      datos.Email,
      datos.NombreBeneficiario,
      datos.NroDocBeneficiario,
      datos.Parentesco,
      datos.Telefono,
      datos.TipoDocBeneficiario
    );
    this.store.dispatch(new setBeneficiarioRequest(beneficiarioRequest));
    this.store.dispatch(new setEstadoFormBeneficiarioRequest(this.form.valid));
  }
}
