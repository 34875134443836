import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-informar-cirugia-request',
  templateUrl: './datos-informar-cirugia-request.component.html',
  styleUrls: ['./datos-informar-cirugia-request.component.scss']
})
export class DatosInformarCirugiaRequestComponent implements OnInit {

  private subscriptionStore: Subscription = new Subscription();
  codigoTransaccion: string | null = null;

  constructor( private store: Store<AppState> ) {
    this.subscriptionStore = this.store.subscribe((datos) => {
      this.codigoTransaccion =
        datos.datosInicio.TransaccionRequest?.CodigoTransaccion || null;
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }
  
}
