import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { blankSpaceValidator } from '@helpers/blankspaceValidator';
import { FinanciadorRequestModel, PrestadorRequestModel } from '@models/datosInicio.models';
import { Financiador } from '@models/financiador.model';
import { redirectClass } from '@models/global.models';
import { UsuarioActualModel } from '@models/login.models';
import { Prestador, PrestadorSelecionado } from '@models/prestador.model';
import { Store } from '@ngrx/store';
import { GlobalService } from '@services/global.service';
import { removeDatosUsuario, setFinanciadorRequest, setPrestadorRequest, unsetPrestadorRequest } from '@store/actions';
import { setEstadoFormFinanciadorRequest, setEstadoFormPrestadorRequest } from '@store/actions/estadoFormsinicio.action';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { unsetFinanciadorRequest } from '../../../store/actions/datosInicio.actions';

@Component({
  selector: 'app-prestador',
  templateUrl: './prestador.component.html',
  styleUrls: ['./prestador.component.scss'],
})
export class PrestadorComponent implements OnInit, OnDestroy {
  private subscriptionPrestador: Subscription = new Subscription();
  private subscriptionStoreConfiguracion: Subscription = new Subscription();
  private subscriptionStoreDatosInicio: Subscription = new Subscription(); //Datos de la pantalla de inicio
  private subscriptionStoreEstadoForms: Subscription = new Subscription();

  form: UntypedFormGroup = this.fb.group({
    prestador: new UntypedFormControl(null, [
      Validators.required,
      blankSpaceValidator,
    ]),
  });
  prestadores: Array<Prestador> = [];
  prestadoresSeleccionados: Array<PrestadorSelecionado> = [];
  mostrar: boolean = false;
  financiadores: Array<Financiador> = [];
  tiposTransacciones: Array<any> = [];
  cuitFinanciadorSeleccionado: string | null = null;
  cuitPrestadorSeleccionado: string | null = null;
  usuarioActual: UsuarioActualModel | null = null;
  prestaOrdenados: any;
  constructor(private fb: UntypedFormBuilder, private store: Store<AppState>, private globalService: GlobalService) { }

  ngOnInit(): void {
    console.log('OnInitPrestador');
    //Unsubscribe
    this.subscriptionStoreEstadoForms = this.store
      .select('estadoFormsInicio')
      .subscribe((datos) => {
        if (datos.formEstadoCredencialRequest && datos.formEstadoFinanciadorRequest) {
          this.mostrar = true;
        } else {
          this.mostrar = false;
        }
      });
    this.selectPrestador();
    this.getDatos();
    this.store.dispatch(new setEstadoFormPrestadorRequest(false));
    // this.setDefaultValues();
  }

  ngOnDestroy() {
    this.subscriptionPrestador.unsubscribe();
    this.subscriptionStoreConfiguracion.unsubscribe();
    this.subscriptionStoreDatosInicio.unsubscribe();
    this.subscriptionStoreEstadoForms.unsubscribe();
  }

  // setDefaultValues() {
  //   this.subscriptionStoreConfiguracion = this.store.select('configuracion')
  //     .subscribe((datos) => {
  //       if (datos.datosIniciales && datos.datosIniciales.prestadores) {
  //         if (this.prestadores.length === 1) {
  //           this.form.controls['prestador'].setValue(this.prestadores[0]);
  //         }
  //       }
  //     });
  // }

  getDatos() {
    if (this.subscriptionPrestador && this.subscriptionStoreDatosInicio)
      this.subscriptionStoreConfiguracion = this.store.select('configuracion')
        .subscribe((datos) => {
          if (datos.datosIniciales && datos.datosIniciales.prestadores) {
            this.prestadores = [...datos.datosIniciales.prestadores]
              .sort((a, b) => (a.razon_social > b.razon_social ? 1 : b.razon_social > a.razon_social ? -1 : 0));
            this.tiposTransacciones = datos.datosIniciales.tiposTransacciones || [];

          }
          if (datos.datosIniciales && datos.datosIniciales.prestadoresSeleccionados) {
            this.prestadoresSeleccionados =
              datos.datosIniciales.prestadoresSeleccionados;
          }
          if (datos.datosIniciales && datos.datosIniciales.financiadores) {
            this.financiadores = datos.datosIniciales.financiadores;
          }
          if (datos.usuarioActual) {
            this.usuarioActual = datos.usuarioActual;
          } else {
            this.store.dispatch(new removeDatosUsuario());
          }
        });

    this.subscriptionStoreDatosInicio = this.store
      .select('datosInicio')
      .subscribe((datos) => {
        if (datos.PrestadorRequest == null && this.form.controls['prestador'].value != null) {
          this.form.controls['prestador'].setValue(null);
        }
        this.cuitFinanciadorSeleccionado = datos.FinanciadorRequest?.CuitFinanciador || null;
      });
  }

  selectPrestador() {
    this.subscriptionPrestador = this.form.controls['prestador']
      .valueChanges.subscribe((prestador: Prestador | null) => {
        if (prestador) {
          let prescribir = prestador.cuit.startsWith('30') ? 'false' : 'true';
          let prestadorSeleccionado = new PrestadorRequestModel(
            prestador.financiadores[0].codigo_para_financiador,
            prestador.financiadores[0].licencia,
            prestador.financiadores[0].cuit_financiador,
            '',
            prestador.cuit,
            this.usuarioActual?.domicilio || '',
            '',
            prestador.razon_social,
            '',
            prestador.tipo_prestador,
            prescribir,
            prestador.matriculas || [],
            prestador.telefono_verificacion || '',
            prestador.email_verificacion || this.usuarioActual?.email!,
          );
          //console.log('Puede prescribir: ', prestador.financiadores[0].pescribir)
          //this.store.dispatch(new setDatosTiposTransacciones(this.tiposTransacciones, prestador.financiadores[0].pescribir));
          this.store.dispatch(new setPrestadorRequest(prestadorSeleccionado));
          this.store.dispatch(new setEstadoFormPrestadorRequest(true));
          let financiadorSeleccionado = new FinanciadorRequestModel(
            prestador.financiadores[0].codigo_empresa,
            prestador.financiadores[0].cuit_financiador,
            prestador.financiadores[0].sigla
          );
          this.store.dispatch(new setFinanciadorRequest(financiadorSeleccionado));
          this.store.dispatch(new setEstadoFormFinanciadorRequest(true));
        } else {
          this.store.dispatch(new unsetPrestadorRequest());
          this.store.dispatch(new setEstadoFormPrestadorRequest(false));
          this.store.dispatch(new unsetFinanciadorRequest());
          this.store.dispatch(new setEstadoFormFinanciadorRequest(false));
        }
      });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  contacto() {
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }
}
