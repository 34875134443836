import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { codigoNombreModel } from '@models/datosInicialesExtras.models';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducers';
import { Subscription } from 'rxjs';
import { setCodFinalizacionTratamiento } from '../../../../store/actions/datosTransacciones.actions';
import { setEstadoFormCodFinalizacionTratamiento } from '../../../../store/actions/estadoFormsinicio.action';

@Component({
  selector: 'app-datos-cod-finalizacion-tratamiento',
  templateUrl: './datos-cod-finalizacion-tratamiento.component.html',
  styleUrls: ['./datos-cod-finalizacion-tratamiento.component.scss'],
})
export class DatosCodFinalizacionTratamientoComponent
  implements OnInit, OnDestroy
{
  private subscriptionPrestador: Subscription = new Subscription();
  private subscriptionStore: Subscription = new Subscription();
  CodFinalizacionTratamiento: Array<codigoNombreModel> | null = null;
  form: UntypedFormGroup = this.fb.group({
    CodFinalizacionTratamiento: new UntypedFormControl(null),
  });
  constructor(private fb: UntypedFormBuilder, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.selectPrestador();
    this.subscriptionStore = this.store
      .select('datosInicialesExtra')
      .subscribe((datos) => {
        this.CodFinalizacionTratamiento =
          datos.datosInicialesExtra?.codFinalizacionTratamiento || [];
      });
    this.store.dispatch(
      new setEstadoFormCodFinalizacionTratamiento(this.form.valid)
    );
  }

  ngOnDestroy() {
    this.subscriptionPrestador.unsubscribe();
    this.subscriptionStore.unsubscribe();
  }

  selectPrestador() {
    this.subscriptionPrestador = this.form.controls[
      'CodFinalizacionTratamiento'
    ].valueChanges.subscribe(() => {
      this.store.dispatch(
        new setCodFinalizacionTratamiento(
          this.form.getRawValue().CodFinalizacionTratamiento
        )
      );
      this.store.dispatch(
        new setEstadoFormCodFinalizacionTratamiento(this.form.valid)
      );
    });
  }
}
