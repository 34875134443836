import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { redirectClass } from '@models/global.models';
import { Store } from '@ngrx/store';
import { GlobalService } from '@services/global.service';
import { removeDatosUsuario, unsetDatosTransacciones } from '@store/actions';
import { AppState } from '@store/app.reducers';
import { version } from '../../../package.json';
import { unsetAfiliadoRequest, unsetDatosInicio } from '../store/actions/datosInicio.actions';
import { setUsuarioActual } from '../store/actions/configuracion.action';
import { unsetFilterTicketsRequest } from '@store/actions/filterTickets.action';
import { DesplegableComponent } from '@shared/desplegable/desplegable.component';
import { DialogsService } from '@services/dialogs.service';
import { ConfiguracionState } from '@store/models/store.models';
import { Subscription} from 'rxjs';
import { UsuarioActualModel } from '@models/login.models';
import { environment } from '@environments/environment';
declare const window: any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  versionActual: string = version;
  @ViewChild('datosIniciales') datosInicialesDesplegable!: DesplegableComponent;
  private subscriptionStore: Subscription = new Subscription();
  farmacia: boolean = false; //para controlar que mostrar en el sidebar
  token = '';
  email = '';
  userActual!: UsuarioActualModel;
  esTurnea!: boolean;

  constructor(
    private store: Store<AppState>,
    public router: Router,
    public globalService: GlobalService,
    private dialogsService: DialogsService,
  ) {
    this.subscriptionStore = this.store
    .select('configuracion')
    .subscribe((configuracionState: ConfiguracionState) => {
      this.userActual = configuracionState!.usuarioActual!;
      this.email = configuracionState!.usuarioActual!.email;
      this.esTurnea = configuracionState!.usuarioActual!.esTurnea;
    });
    //this.store.dispatch(new setUsuarioActual({...this.userActual,esTurnea: false}));
   }

  get isElectron(): boolean {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      // Electron-specific code
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  ngOnInit(): void {
  }

  contacto() {
    this.globalService.redirect(
      new redirectClass(
        'contactanos'
      )
    )
  }

  inicio() {
    this.router.navigate(['inicio']);
    if (this.router.url == '/inicio') {
      window.location.reload();
    }
  }

  agenda() {
    this.router.navigate(['agenda']);
    this.store.dispatch(new unsetAfiliadoRequest());
    this.store.dispatch(new unsetFilterTicketsRequest());
  }

  perfil() {
    this.router.navigate(['perfil']);
    this.store.dispatch(new unsetAfiliadoRequest());
    this.store.dispatch(new unsetFilterTicketsRequest());
  }

  sugerencia() {
    this.router.navigate(['sugerencia']);
    this.store.dispatch(new unsetAfiliadoRequest());
    this.store.dispatch(new unsetFilterTicketsRequest());
  }

  ticket() {
    this.router.navigate(['ticket']);
    this.store.dispatch(new unsetAfiliadoRequest());
    this.store.dispatch(new unsetFilterTicketsRequest());
  }

  turnea() {
    //this.dialogsService.cargando('Buscando información, por favor espere.');
    this.store.dispatch(new setUsuarioActual({...this.userActual,esTurnea: true}));
    this.router.navigate(['turnea']);
    this.store.dispatch(new unsetAfiliadoRequest());
    this.store.dispatch(new unsetFilterTicketsRequest());
    //window.api.electronIpcSend('verTurnea',{url: environment.urlturnea + `#${this.token}`});
    //window.location.href = environment.urlturnea + `#${this.token}`;
  }

  salir() {
    this.store.dispatch(new removeDatosUsuario());
  }
}
