import * as models from '@store/models/store.models';
import * as datosTransaccionesActions from '@store/actions/estadoFormsinicio.action';

const estadoInicial: models.estadoFormsState = {
  formEstadoCredencialRequest: null,
  formEstadoCodigoDeSeguridadRequest: null,
  formEstadoFinanciadorRequest: null,
  formEstadoPrestadorRequest: null,
  formEstadoTransaccionRequest: null,
  formEstadoDetalleMedicamentosRequest: null,
  formEstadoDetalleProcedimientoRequest: null,
  formEstadoAcompannanteRequest: null,
  formEstadoAtencionRequest: null,
  formEstadoBeneficiarioRequest: null,
  formEstadoCodFinalizacionTratamientoRequest: null,
  formEstadoDerivacionRequest: null,
  formEstadoDiagnosticosRequest: null,
  formEstadoDocumentacionRequest: null,
  formEstadoEfectorRequest: null,
  formEstadoMensajeParaFinanciadorRequest: null,
  formEstadoPreautorizacionRequest: null,
  formEstadoPrescripcionRequest: null,
  formEstadoPrescriptorRequest: null,
  formEstadoFechaReferenciaCancelRequest: null,
  formEstadoIdMsjRequest: null,
  formEstadoInicioTrxRequest: null,
  formEstadoNroReferenciaCancelRequest: null,
  formEstadoNroReferenciaRequest: null,
  formEstadoSoftwareRequest: null,
  formEstadoTerminalRequest: null,
  formEstadoTipoTransaccionRequest: null,
  formEstadoVersionMsjRequest: null,
  formEstadoPrescripcionDispensaRequest: null,
  fomrEstadoDatosEnvioPrescripcionEmailBeRequest: null,
  fomrEstadoDatosEnvioPrescripcionDiagRequest: null,
  formEstadoFirmaRequest: null
};

export function estadoFormsReducer(
  state = estadoInicial,
  action: datosTransaccionesActions.estadoFormsActions
): models.estadoFormsState {
  switch (action.type) {
    case datosTransaccionesActions.SETESTADOFORMCREDENCIALREQUESTFORM:
      return {
        ...state,
        formEstadoCredencialRequest: action.formEstadoCredencialRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMCODIGODESEGURIDADREQUESTFORM:
      return {
        ...state,
        formEstadoCodigoDeSeguridadRequest: action.formEstadoCodigoDeSeguridadRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMFINANCIADORREQUEST:
      return {
        ...state,
        formEstadoFinanciadorRequest: action.formEstadoFinanciadorRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMPRESTADORREQUEST:
      return {
        ...state,
        formEstadoPrestadorRequest: action.formEstadoPrestadorRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMTRANSACCIONREQUEST:
      return {
        ...state,
        formEstadoTransaccionRequest: action.formEstadoTransaccionRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMDETALLEMEDICAMENTOREQUEST:
      return {
        ...state,
        formEstadoDetalleMedicamentosRequest:
          action.formEstadoDetalleMedicamentosRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMDETALLEPROCEDIMIENTOSREQUEST:
      return {
        ...state,
        formEstadoDetalleProcedimientoRequest:
          action.formEstadoDetalleProcedimientoRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMACOMPANNANTEREQUEST:
      return {
        ...state,
        formEstadoAcompannanteRequest: action.formEstadoAcompannanteRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMATENCIONREQUEST:
      return {
        ...state,
        formEstadoAtencionRequest: action.formEstadoAtencionRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMBENEFICIARIOREQUEST:
      return {
        ...state,
        formEstadoBeneficiarioRequest: action.formEstadoBeneficiarioRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMCODFINALIZACIONTRATAMIENTO:
      return {
        ...state,
        formEstadoCodFinalizacionTratamientoRequest:
          action.formEstadoCodFinalizacionTratamientoRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMDERIVACIONREQUEST:
      return {
        ...state,
        formEstadoDerivacionRequest: action.formEstadoDerivacionRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMDIAGNOSTICOSREQUEST:
      return {
        ...state,
        formEstadoDiagnosticosRequest: action.formEstadoDiagnosticosRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMDOCUMENTACIONREQUEST:
      return {
        ...state,
        formEstadoDocumentacionRequest: action.formEstadoDocumentacionRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMEFECTORREQUEST:
      return {
        ...state,
        formEstadoEfectorRequest: action.formEstadoEfectorRequest,
      };


    case datosTransaccionesActions.SETESTADOFORMMENSAJEPARAFINANCIADOR:
      return {
        ...state,
        formEstadoMensajeParaFinanciadorRequest:
          action.formEstadoMensajeParaFinanciadorRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMPREAUTORIZACIONREQUEST:
      return {
        ...state,
        formEstadoPreautorizacionRequest:
          action.formEstadoPreautorizacionRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMPRESCRIPCIONREQUEST:
      return {
        ...state,
        formEstadoPrescripcionRequest: action.formEstadoPrescripcionRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMPRESCRIPTORREQUEST:
      return {
        ...state,
        formEstadoPrescriptorRequest: action.formEstadoPrescriptorRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMFECHAREFERENCIACANCELREQUEST:
      return {
        ...state,
        formEstadoFechaReferenciaCancelRequest:
          action.formEstadoFechaReferenciaCancelRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMIDMSJREQUEST:
      return {
        ...state,
        formEstadoIdMsjRequest: action.formEstadoIdMsjRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMINICIOTRXREQUEST:
      return {
        ...state,
        formEstadoInicioTrxRequest: action.formEstadoInicioTrxRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMNROREFERENCIACANCELREQUEST:
      return {
        ...state,
        formEstadoNroReferenciaCancelRequest:
          action.formEstadoNroReferenciaCancelRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMNROREFERENCIAREQUEST:
      return {
        ...state,
        formEstadoNroReferenciaRequest:
          action.formEstadoNroReferenciaRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMSOFTWAREREQUEST:
      return {
        ...state,
        formEstadoSoftwareRequest: action.formEstadoSoftwareRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMTERMINALREQUEST:
      return {
        ...state,
        formEstadoTerminalRequest: action.formEstadoTerminalRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMTIPOTRANSACCIONREQUEST:
      return {
        ...state,
        formEstadoTipoTransaccionRequest:
          action.formEstadoTipoTransaccionRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMVERSIONMSJREQUEST:
      return {
        ...state,
        formEstadoVersionMsjRequest: action.formEstadoVersionMsjRequest,
      };

    case datosTransaccionesActions.UNSETESTADOFORMDATOSCASITODOS:
      return {
        ...estadoInicial,
        formEstadoCredencialRequest: state.formEstadoCredencialRequest,
        formEstadoFinanciadorRequest: state.formEstadoFinanciadorRequest,
        formEstadoPrestadorRequest: state.formEstadoPrestadorRequest,
        formEstadoTransaccionRequest: state.formEstadoTransaccionRequest,
        formEstadoPrescripcionDispensaRequest: state.formEstadoPrescripcionDispensaRequest,
      };
    case datosTransaccionesActions.UNSETESTADOFORMDATOS:
      return estadoInicial;

    case datosTransaccionesActions.SETESTADOFORMPRESCRIPCIONDISPENSAREQUEST:
      return {
        ...state,
        formEstadoPrescripcionDispensaRequest:
          action.formEstadoPrescripcionDispensaRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMDATOSENVIOPRESCRIPCIONEMAILBEREQUEST:
      return {
        ...state,
        fomrEstadoDatosEnvioPrescripcionEmailBeRequest:
          action.formEstadoDatosEnvioPrescripcionEmailBeRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMDATOSENVIOPRESCRIPCIONDIAGREQUEST:
      return {
        ...state,
        fomrEstadoDatosEnvioPrescripcionDiagRequest:
          action.formEstadoDatosEnvioPrescripcionDiagRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMFIRMAREQUEST:
      return {
        ...state,
        formEstadoFirmaRequest:
          action.formEstadoFirmaRequest,
      };
    default:
      return state;
  }
}
